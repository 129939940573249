import { styled } from 'linaria/react';
import { COLORS } from '../../../styles/constants';

export const StyledBlockedAlert = styled.div`
  border: 1px solid ${COLORS.white.off[100]};
  border-radius: 20px;
  margin: 16px 24px 42px;
  padding: 16px;
`;

export const StyledButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;

  > button {
    max-width: 150px;
    width: 100%;
  }
`;

export const StyledConfirmationWrap = styled.div`
  .first-button {
    font-weight: 400;
  }

  .second-button {
    color: ${COLORS.blue};
  }

  .cancel-button {
    font-weight: 600;
  }
`;
