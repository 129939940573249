import { format } from 'date-fns';
import { EMPTY_ITEMS } from 'constants/common';
import { GetQuestionCardsQuery, OtherAnswersQuery } from 'constants/graphqlTypes';

export const updateOtherAnswers = (prev: OtherAnswersQuery, next?: OtherAnswersQuery): OtherAnswersQuery =>
  prev.allCards?.items?.[0].otherAnswers && next?.allCards?.items?.[0].otherAnswers
    ? {
        allCards: {
          __typename: 'CardListObjectType',
          totalCount: 1,
          items: [
            {
              ...prev.allCards.items[0],
              otherAnswers: {
                ...prev.allCards.items[0].otherAnswers,
                items: [...prev.allCards.items[0].otherAnswers.items, ...next?.allCards.items[0].otherAnswers.items],
              },
            },
          ],
        },
      }
    : prev;

export const mergeCards = (prev: GetQuestionCardsQuery, next?: GetQuestionCardsQuery): GetQuestionCardsQuery => {
  const allCards = prev.allCards ?? EMPTY_ITEMS;
  const prevCards = allCards?.items;
  const nextCards = next?.allCards?.items ?? [];

  return {
    ...prev,
    allCards: {
      ...allCards,
      items: [...prevCards, ...nextCards],
    },
  };
};

export const getCurrentDate = (monthOffset: number): { dateFilter: string; dateTitle: string } => {
  const currentDate = new Date();

  const offsettedDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + monthOffset);

  const dateFilter = format(new Date(`${offsettedDate}`), 'yyyy-MM');
  const dateTitle = format(new Date(`${offsettedDate}`), 'MMMM yyyy');

  return { dateFilter, dateTitle };
};
