import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 24px;
  cursor: pointer;
  margin-top: 100%;
`;

export const StyledCaption = styled.p`
  color: ${COLORS.white[100]};
  font-family: ${FONTS.primary};
  font-size: 22px;
  line-height: 130%;
  margin: 0;
  padding: 16px 0;
`;

export const StyledEntity = styled.div`
  display: flex;
  align-items: center;

  > :last-child {
    margin-left: auto;
  }
`;

export const StyledActionName = styled.span`
  color: ${COLORS.white[100]};
  font-family: ${FONTS.primary};
  font-size: 14px;
  line-height: 140%;
  margin-left: 14px;
`;
