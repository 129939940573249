import { useHistory } from 'react-router-dom';

import { CollaborationInviteSchema, CardSchema, UserObjectType } from 'constants/graphqlTypes';
import useModalNavigation from './useModalNavigation';

export type Variable =
  | string
  | number
  | boolean
  | CollaborationInviteSchema
  | UserObjectType
  | CardSchema
  | CardSchema[]
  | null
  | undefined;

const useOpenModal = (
  pathname: string,
  isSingleModal?: boolean,
  variable?: { [key: string]: Variable },
  shouldClearSearchParams?: boolean
): (() => void) => {
  const { push } = useHistory();

  const link = useModalNavigation(pathname, isSingleModal, variable, shouldClearSearchParams);

  return () => push(link);
};

export default useOpenModal;
