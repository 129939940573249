import { styled } from 'linaria/react';

import { COLORS } from '../../../styles/constants';

export const StyledInputWrapper = styled.div`
  padding: 32px 0 8px;
`;

export const StyledFooter = styled.footer`
  border-top: 1px solid ${COLORS.white[30]};
  margin-top: auto;
  padding-bottom: var(--keyboard-offset);
`;
