import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { EOnboardingStepOptions, useUpdateUserMutation } from 'constants/graphqlTypes';
import { useUpdateUserData } from 'graphQL/profile/update/hooks';
import usePageType from 'helpers/usePageType';
import config from 'constants/config';

interface IUseChangeProfileBio {
  shortBio: string;
  loadingUserUpdate: boolean;
  error: string;
  handleChange: Dispatch<SetStateAction<string>>;
  handleError: Dispatch<SetStateAction<string>>;
  handleDone: () => void;
}

const useChangeProfileBio = (handleNext: () => void): IUseChangeProfileBio => {
  const posthogCapture = usePostHogCapture();
  const { isOnboarding } = usePageType();
  const { isNewOnboardingEnabled } = config;
  const userData = useUpdateUserData();
  const { userInfo } = userData ?? {};
  const { bio } = userInfo ?? {};
  const [updateUser, { loading: loadingUserUpdate }] = useUpdateUserMutation();

  const [shortBio, setShortBio] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (bio) {
      setShortBio(bio);
    }
  }, [bio]);

  const handleDone = () =>
    bio === shortBio.trimEnd()
      ? handleNext()
      : updateUser({
          variables: {
            userInfo: {
              bio: shortBio.trimEnd(),
            },
            onboardingStep: isOnboarding && !isNewOnboardingEnabled ? EOnboardingStepOptions.Description : undefined,
          },
        }).then(() => {
          handleNext();
          if (isOnboarding) {
            posthogCapture(POSTHOG_EVENTS.OnboardingProfileParametersSave, {
              screen: 'intro',
            });
          } else {
            posthogCapture(POSTHOG_EVENTS.EditProfile);
            posthogCapture(POSTHOG_EVENTS.ChangeShortBio);
          }
        });

  return { shortBio, error, loadingUserUpdate, handleError: setError, handleChange: setShortBio, handleDone };
};

export default useChangeProfileBio;
