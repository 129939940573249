import React, { FC } from 'react';

import { useItemUrl } from 'components/Card/helpers/hooks';
import { StyledLink } from './styled';

const ListTitleWrapper: FC<{ id: string; isDisabled: boolean; children: JSX.Element }> = ({
  id,
  isDisabled,
  children,
}) => {
  const url = useItemUrl(id, true);

  return isDisabled ? <>{children}</> : <StyledLink to={url}>{children}</StyledLink>;
};

export default ListTitleWrapper;
