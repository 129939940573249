import React, { FC } from 'react';

import { ROUTE_USER_PROFILE } from 'routes';
import { UserObjectType, UserShortSchema } from 'constants/graphqlTypes';
import { generateFullName, getUserInfo } from 'components/Profile/ProfileTop/helpers';

import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';
import { StyledFollowingStatus } from 'components/Card/Actions/styled';
import Avatar from '../Avatar';
import UILink from '../Link';
import { StyledWrap, StyledWrapper } from '../Badge/styled';
import { StyledAvatarWrap, StyledBio, StyledName, StyledTextWrap, StyledUsername, StyledUsernameWrap } from './styled';

interface IBadgeWithFollowingProps {
  user: UserObjectType | UserShortSchema;
  isDark?: boolean;
}

const BadgeWithFollowing: FC<IBadgeWithFollowingProps> = ({ user, isDark = true }) => {
  const { isVerified, isFollowedByMe } = user;
  const { username, bio } = getUserInfo(user);
  const name = generateFullName(user);

  return (
    <StyledWrap>
      <StyledWrapper>
        <StyledAvatarWrap>
          <UILink route={ROUTE_USER_PROFILE} params={{ profileName: username }}>
            <Avatar user={user} />
          </UILink>
        </StyledAvatarWrap>
        <StyledTextWrap isDark={isDark}>
          <StyledName>
            <UILink route={ROUTE_USER_PROFILE} params={{ profileName: username }}>
              <StyledUsernameWrap>
                <StyledUsername>{name}</StyledUsername>
                {isVerified && <VerificationBadge />}
              </StyledUsernameWrap>
            </UILink>
            {isFollowedByMe && (
              <>
                <StyledFollowingStatus>&nbsp;·&nbsp;Following</StyledFollowingStatus>
              </>
            )}
          </StyledName>
          <StyledBio>{bio}</StyledBio>
        </StyledTextWrap>
      </StyledWrapper>
    </StyledWrap>
  );
};

export default BadgeWithFollowing;
