import { styled } from 'linaria/lib/react';
import { BREAKPOINTS } from 'styles/constants';

export const StyledSparkContainer = styled.li`
  padding: 0 12px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0;
  }
`;
