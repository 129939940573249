import { useContext } from 'react';

import { GuideTourContext } from 'providers/helpers/contexts';
import { IIsGuideTour } from 'providers/helpers/models';

const useGuideTour = (): IIsGuideTour => {
  const value = useContext<IIsGuideTour>(GuideTourContext);

  return {
    ...value,
    setIsGuideTour: (isGuideTour: boolean) => value.setIsGuideTour?.(isGuideTour),
    setGuideTourStep: (step: number) => value.setGuideTourStep?.(step),
  };
};

export default useGuideTour;
