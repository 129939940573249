import { styled } from 'linaria/react';

import { COLORS, FONTS } from './constants';
import { FIXED_GLOBAL_ERROR_CSS_CLASS } from '../helpers/errorHandler';

const StyledGlobalStyle = styled.div`
  :global() {
    html {
      --keyboard-offset: 0px;
      margin: 0;
      overflow: auto;
      box-sizing: border-box;
      -webkit-overflow-scrolling: touch;
      overscroll-behavior-x: none;
      font-family: ${FONTS.primary};
      font-size: 16px;
    }

    *,
    *:before,
    *:after {
      box-sizing: border-box;
      -webkit-tap-highlight-color: ${COLORS.transparent};
    }

    body {
      margin: 0;
      scroll-behavior: smooth;
      text-rendering: optimizeSpeed;
      width: 100%;

      &::-webkit-scrollbar {
        width: 0;
        background: ${COLORS.transparent};
      }
    }

    .disable-user-action {
      a {
        pointer-events: none !important;
        user-select: none !important;
        -webkit-touch-callout: none !important;
        touch-action: none !important;
      }

      .collection-preview-anchor,
      .link-preview-anchor,
      .guestSeries-preview-anchor,
      .thought-preview-anchor {
        display: block;
      }
    }

    ol,
    ul {
      padding: 0;
    }

    .fixed,
    .${FIXED_GLOBAL_ERROR_CSS_CLASS} {
      position: fixed;
      overflow: hidden;
      overscroll-behavior: none;
    }

    .dark {
      background-color: ${COLORS.black[100]};
      color: ${COLORS.white[100]};
    }

    .light {
      background-color: ${COLORS.white[100]};
      color: ${COLORS.brown.dark[100]};
    }

    .half-transparent {
      opacity: 0.5;
      transition: opacity 0.2s ease-in-out;
    }

    .hidden {
      visibility: hidden;
    }

    .removed {
      display: none;
    }

    .message.message-swiped {
      &::after {
        opacity: 1;
      }
    }

    .message-right.message-swiped {
      transform: translateX(-84px);
    }

    button {
      outline: none;
    }
  }
`;

export default StyledGlobalStyle;
