/* eslint-disable no-underscore-dangle */
import {
  CardStyleInput,
  CardStyleSchema,
  ColorSchema,
  ECardBackgroundStyleOptions,
  ECardLayoutOptions,
  ImageSetSchema,
} from 'constants/graphqlTypes';
import { IStyleItem } from './models';

export const createStyleItems = (items: (ColorSchema | ImageSetSchema)[]): IStyleItem[] =>
  items.map((item, index) => ({
    id: index,
    ...(item.__typename === 'ImageSetSchema' && { image: item, colors: item.colors?.sourceGradient?.items ?? [] }),
    ...(item.__typename === 'ColorSchema' && { colors: [item] }),
  }));

export const changeCardStyle = (
  layout: ECardLayoutOptions,
  style?: CardStyleSchema | null,
  bgColor?: ColorSchema,
  image?: ImageSetSchema
): CardStyleSchema | undefined =>
  style
    ? {
        ...style,
        bgColor,
        thumbnailImageSet: image,
        bgStyle: ECardBackgroundStyleOptions.Color,
        layout: image?.images?.length ? layout : ECardLayoutOptions.Plain,
      }
    : undefined;

export const findNextColor = (colors: ColorSchema[], currentColor: ColorSchema): ColorSchema => {
  const index = colors.reduce((acc: number, color, i) => (color.id === currentColor.id ? i : acc), 0);

  return colors[index < colors.length - 1 ? index + 1 : 0];
};

export const toggleIdInArray = (id: string, arr: string[]): string[] =>
  arr.includes(id) ? arr.filter((item) => item !== id) : [...arr, id];

export const excludeArrayItems = (arr1: string[], arr2: string[]): string[] =>
  arr1.reduce((acc: string[], item) => (!arr2.includes(item) ? acc.concat(item) : acc), []);

export const getPreExtractImage = (imageUrl?: string | null): ImageSetSchema => ({
  id: 'preview',
  imageId: 'preview_image',
  images: [{ id: 'preview_url', url: imageUrl }],
});

export const searchFileInUrl = (url: string): string | null => {
  const fileArr = url.split('.');
  const fileFormat = fileArr[fileArr.length - 1];
  const fileExtensions = /(jpeg|jpg|png|gif|bmp|svg|raw|webp|heic|mp4|avi|mov|wmv|mkv|flv|mpeg|mpg|webm|3gp|asf|doc|pdf|txt|tiff|mp3|ogg|wav|docx|xls|xlsx|ppt|pptx|zip|odt|rtf)$/i;
  return fileExtensions.test(fileFormat) ? fileFormat : null;
};

export const convertCardStyleToCardStyleInput = (style?: CardStyleSchema | null): CardStyleInput | undefined =>
  style
    ? {
        bgColor: style.bgColor?.colorValue,
        bgStyle: style.bgStyle,
        layout: style.layout,
        thumbnailImageSetId: style.thumbnailImageSet?.imageId,
        bgImageSetId: style.bgImageSet?.id,
        isDefaultStyle: style.isDefaultStyle,
      }
    : undefined;
