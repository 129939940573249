import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { MessageItemType, MessageContentStatus, MessageSchema } from 'constants/graphqlTypes';
import { ROUTE_ADD_TO_LIST } from 'routes';
import { useGetMessageContent } from 'graphQL/messages/hooks';
import useOpenModal from 'helpers/useOpenModal';
import Common from 'components/Card/Common/Common';
import HiddenWordsAlert from 'components/HiddenWordsAlert';
import { useFullScreenAnimation } from 'components/Reactions/Animations/Fullscreen/hooks';
import { EQueryFetchPolicy } from 'constants/common';
import UnavailableContent from '../UnavailableContent';
import { EMessageContentType } from '../../models';

import { StyledButton, StyledCardWrap } from './styled';
import { StyledMessageInfo } from '../styled';
import { getReactionContentText } from '../../helpers/helpers';

interface ICardProps {
  id: string;
  isDark: boolean;
  isMine?: boolean;
  messageRef: React.RefObject<HTMLDivElement>;
  setIsContentDeleted: Dispatch<SetStateAction<boolean>>;
  contentReactionId?: string;
  message: MessageSchema;
}

const Card: FC<ICardProps> = ({ id, isDark, isMine, messageRef, setIsContentDeleted, contentReactionId, message }) => {
  const [isHidden, setIsHidden] = useState(false);
  const { card, messageContentStatus } = useGetMessageContent({
    itemType: MessageItemType.Pick,
    entityId: id,
    contentReactionId,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  useEffect(() => {
    setIsContentDeleted(messageContentStatus === MessageContentStatus.Deleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageContentStatus]);

  const handlePick = useOpenModal(ROUTE_ADD_TO_LIST, false, { pickId: id, cardId: card?.cardId, isPickFlow: true });
  const contentReactionEmoji = card?.reactions?.items?.[0]?.symbol?.symbol;

  useFullScreenAnimation(message.id, contentReactionEmoji);

  useEffect(() => {
    if (!card) return;
    setIsHidden(!!card.isHidden);
  }, [card]);

  if (
    messageContentStatus === MessageContentStatus.Unavailable ||
    messageContentStatus === MessageContentStatus.Deleted
  ) {
    return (
      <UnavailableContent
        isDark={isDark}
        isMine={isMine}
        contentType={EMessageContentType.Pick}
        messageContentStatus={messageContentStatus}
      />
    );
  }

  return card ? (
    <StyledCardWrap
      isRight={!!isMine}
      isFullWidth={!card.isPickedByMe && !isHidden}
      ref={!isHidden ? messageRef : undefined}
    >
      {!isHidden ? (
        <>
          <div>
            {contentReactionEmoji && (
              <StyledMessageInfo>{getReactionContentText(!!isMine, EMessageContentType.Pick)}</StyledMessageInfo>
            )}
            <Common message={message} card={card} isDark={isDark} contentEmoji={contentReactionEmoji} />
          </div>
          {!card.isPickedByMe && (
            <StyledButton isDark={isDark} onClick={handlePick}>
              <PlusIcon height={28} width={28} viewBox="0 0 20 20" />
            </StyledButton>
          )}
        </>
      ) : (
        <HiddenWordsAlert showHiddenContent={() => setIsHidden(false)} isDark={isDark} isSmall hasBottomMargin />
      )}
    </StyledCardWrap>
  ) : null;
};

export default Card;
