import React, { FC } from 'react';

import ListMiniature from 'components/List/Miniature';
import UILink from 'components/UI/Link';

import { getListStyles } from 'components/Collection/helpers';
import { CardSchema } from 'constants/graphqlTypes';
import { ROUTE_LIST_DETAIL } from 'routes';
import { getActivityTestId } from 'constants/aqa/activity';

const ListPreview: FC<{ list: CardSchema; activityTestId?: string }> = ({ list, activityTestId }) => {
  const { urls, bgColor } = getListStyles(list);
  const { id, isPrivateList, permissions } = list;
  const dataTestId = activityTestId ? getActivityTestId(activityTestId, 'list-preview') : '';

  return (
    <UILink route={ROUTE_LIST_DETAIL} params={{ listId: id }} isDisabled={!!isPrivateList && !permissions?.length}>
      <ListMiniature dataTestId={dataTestId} gradient={bgColor} images={urls} />
    </UILink>
  );
};

export default ListPreview;
