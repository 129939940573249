import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal';
import Card from 'components/Card';
import ConfirmationModal from 'components/UI/ConfirmationModal';

import useGetExtractionCard from 'graphQL/addContent/addStyle/hook';
import { CardStyleSchema, ECardLayoutOptions } from 'constants/graphqlTypes';
import { useAddContentLinks } from '../helpers/hooks';
import { StyledFooter, StyledPreview, StyledWrap } from './styled';
import { StyledListTitle } from '../AddToList/styled';
import { findNextColor } from '../helpers/helpers';

import Colors from './Colors';
import Items from './Items';
import { useAddStyleLogic } from './hooks';

interface IAddStyleProps {
  setColor: Dispatch<SetStateAction<string>>;
  setItemId: Dispatch<SetStateAction<number>>;
  setStyle: Dispatch<SetStateAction<CardStyleSchema | undefined>>;
  handleClose: () => void;
  listTitle: string;
  cardId: string;
  pickId: string;
  preExtractTitle?: string | null;
  preExtractImage?: string | null;
  itemId: number;
  color: string;
  currentListId?: string;
  removeUrl: () => void;
}

const AddStyle: FC<IAddStyleProps> = ({
  preExtractTitle,
  preExtractImage,
  handleClose,
  listTitle,
  cardId,
  pickId,
  setStyle,
  itemId,
  setItemId,
  color,
  setColor,
  currentListId,
  removeUrl,
}) => {
  const { replace } = useHistory();
  const { addPick, addToList, addThought, home } = useAddContentLinks();

  const { isExtracting, currentColor, imageUrl, itemColors, updatedStyle, previewCard, items } = useAddStyleLogic({
    pickId,
    cardId,
    itemId,
    color,
    preExtractImage,
    preExtractTitle,
    setStyle,
  });

  const { card } = useGetExtractionCard(pickId);
  const { extractResult } = card ?? {};

  const handleAddAnotherPick = () => {
    removeUrl();
    replace(addPick);
  };

  useEffect(() => {
    if (!pickId) {
      replace(home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showExitWarning, setShowExitWarning] = useState(false);
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  return (
    <>
      <Modal
        fadeIn={false}
        secondaryText={listTitle}
        secondaryHandler={() => replace(addToList)}
        handleClose={handleClose}
        handleDone={() => replace(addThought)}
        handlePrev={() => replace(currentListId ? addPick : addToList)}
        buttonText="Next"
        isExitConfirmed={isExitConfirmed}
        setShowConfirm={setShowExitWarning}
      >
        <StyledPreview
          bgColor={currentColor?.colorValue}
          hasNoImage={updatedStyle?.layout !== ECardLayoutOptions.Plain && !imageUrl && isExtracting}
          onClick={() => currentColor && setColor(findNextColor(itemColors, currentColor).colorValue)}
        >
          <Card
            card={{ ...previewCard, cardStyle: updatedStyle, isSavedByMe: false }}
            shouldDisableLongTap
            disableLinks
          />
        </StyledPreview>
        <StyledFooter>
          <StyledWrap>
            <StyledListTitle>Select your style</StyledListTitle>
            {itemColors.length > 1 && (
              <Colors items={itemColors} color={currentColor?.colorValue} setColor={setColor} />
            )}
          </StyledWrap>
          <Items items={items} itemId={itemId} setItemId={setItemId} />
        </StyledFooter>
        {showExitWarning && (
          <ConfirmationModal
            handleCancel={() => setShowExitWarning(false)}
            handleClickOutside={() => setShowExitWarning(false)}
            handleAccept={() => setIsExitConfirmed(true)}
            isWarning
            buttonText="Discard"
            titleText="Discard pick?"
            subTitle="Your edits and thoughts will be discarded if you leave before posting."
          />
        )}
      </Modal>
      {extractResult && (
        <ConfirmationModal
          handleAccept={handleAddAnotherPick}
          buttonText="Add another pick"
          handleClickOutside={handleAddAnotherPick}
          subTitle="We’re sorry, something went wrong with adding your pick. Your attempt has been logged with support as we improve how we import."
          titleText="Adding error"
          isWarning={false}
        />
      )}
    </>
  );
};

export default AddStyle;
