import React, { FC } from 'react';

import List from 'components/List';
import Card from 'components/Card';
import useTheme from 'helpers/useTheme';

import { CardTypeOptions, DiscoverItemSchema } from 'constants/graphqlTypes';
import { RefIdProvider } from 'helpers/useContextRefID';
import { CardProvider } from 'helpers/useCardProvider';
import { StyledListItem } from './styled';
import { hasPaddings, REF_PLACEHOLDER } from './helpers';

const { Collection, UserGroup, SuggestedUsers } = CardTypeOptions;

const Item: FC<{ item: DiscoverItemSchema; dataTestId?: string }> = ({ item, dataTestId }) => {
  const { card, layout, referrerId, highlight, cardHeader } = item;
  const { type } = card;

  const isDark = useTheme();

  const isList = type === Collection || type === UserGroup || type === SuggestedUsers;

  return (
    <RefIdProvider value={{ referrerId: referrerId ?? REF_PLACEHOLDER }}>
      <CardProvider value={{ cardHeader }}>
        <StyledListItem key={card.id} hasPaddings={!!layout && hasPaddings(layout) && !isList}>
          {isList && layout && <List layout={layout} list={card} />}
          {type === CardTypeOptions.Card && (
            <Card dataTestId={dataTestId} layout={layout} isDark={isDark} card={card} highlight={highlight} />
          )}
        </StyledListItem>
      </CardProvider>
    </RefIdProvider>
  );
};

export default Item;
