import { NotificationSettingType } from 'constants/graphqlTypes';

export const SETTINGS_LABELS = {
  // Your picks and thoughts
  [NotificationSettingType.NewThoughts]: 'Thoughts added to your picks',
  [NotificationSettingType.NewAdds]: 'When others add your picks',
  // Social interactions
  [NotificationSettingType.NewFollowers]: 'New followers',
  [NotificationSettingType.AddedToGroupThread]: 'Added to a group message thread',
  [NotificationSettingType.NewMessages]: 'New messages',
  [NotificationSettingType.Reaction]: 'New reactions',
  [NotificationSettingType.NewSparkResponses]: 'New responses to sparks',
  // Collaborating on lists
  [NotificationSettingType.Invites]: 'Invites to collaborate on a list',
  // From guide:human
  [NotificationSettingType.ActivityRecaps]: 'Activity recaps',
  [NotificationSettingType.ContentYouMayLike]: 'Content you may like',
  [NotificationSettingType.ParticipationSuggestions]: 'Participation suggestions',
  [NotificationSettingType.ProductAnnouncements]: 'Product announcements',
  // Emails
  [NotificationSettingType.ProductNews]: 'Product news and updates',
  [NotificationSettingType.FeedbackSurveys]: 'Feedback and surveys',
  [NotificationSettingType.CuratedNewsletter]: 'Weekly ‘guide:human curates’ newsletter',
};
