import React, { FC } from 'react';

import SinglePageLayout from 'components/SinglePageLayout';
import SettingsSection from 'components/SettingsSection/SettingsSection';
import { NotificationSettingDomain } from 'constants/graphqlTypes';
import {
  TEST_EMAIL_NOTIFICATIONS_SETTINGS_ITEM,
  TEST_ID_EMAIL_NOTIFICATIONS_SETTINGS_VIEW_START,
} from 'constants/aqa/settings/accountManagement';

const EmailNotifications: FC = () => (
  <SinglePageLayout pageTestId={TEST_ID_EMAIL_NOTIFICATIONS_SETTINGS_VIEW_START} title="Email notifications">
    <SettingsSection dataTestId={TEST_EMAIL_NOTIFICATIONS_SETTINGS_ITEM} domain={NotificationSettingDomain.Emails} />
  </SinglePageLayout>
);

export default EmailNotifications;
