import React, { FC } from 'react';

import { CardSchemaWithIsViewed } from 'graphQL/savedPicks/models';
import usePageType from 'helpers/usePageType';

import { StyledText } from 'components/Collection/Collaborators/styled';

interface ISourceProps {
  card: CardSchemaWithIsViewed;
  dataTestId?: string;
  stopPropagationOnClick?: boolean;
}

const Source: FC<ISourceProps> = ({ card, dataTestId, stopPropagationOnClick }) => {
  const { externalSources, hasNewCollaboratorsCards, isViewed } = card;
  const { sourceDomainName, sourceUrl } = externalSources?.items?.[0] ?? {};
  const { isListDetail } = usePageType();

  const hasIndicator = !!(hasNewCollaboratorsCards && isListDetail) || isViewed === false;

  return sourceUrl ? (
    <StyledText hasIndicator={hasIndicator}>
      <a
        onClickCapture={(e) => stopPropagationOnClick && e.stopPropagation()}
        data-testid={dataTestId}
        className="source-link"
        target="_blank"
        rel="noopener noreferrer"
        href={sourceUrl}
      >
        {sourceDomainName ?? sourceUrl}
      </a>
    </StyledText>
  ) : null;
};

export default Source;
