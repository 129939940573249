import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import useDisableScroll from './useDisableScroll';

import { isSafariIos } from './useUserAgent';

const useChatKeyboard = (): {
  isKeyboardOpen: boolean;
  setIsKeyboardOpen: Dispatch<SetStateAction<boolean>>;
  setScroll: Dispatch<SetStateAction<boolean>>;
} => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [isScrollDisabled, setScroll] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setScroll(isSafariIos() && isKeyboardOpen);
    }, 50);
  }, [isKeyboardOpen]);

  useDisableScroll(!isScrollDisabled);

  return { isKeyboardOpen, setIsKeyboardOpen, setScroll };
};

export default useChatKeyboard;
