import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import GuestBg from 'assets/images/guestList/bg.png';

import { useGetMessageContent } from 'graphQL/messages/hooks';
import { MessageContentStatus, MessageItemType, MessageSchema } from 'constants/graphqlTypes';
import { ROUTE_GUEST_LIST_SERIES } from 'routes';
import Grain from 'components/UI/Grain';
import UILink from 'components/UI/Link';
import { EUiLinkClassNames } from 'components/UI/Link/constants';
import ReactionsToken from 'components/Messages/Message/ReactionsToken';
import { useMessagesChatData } from 'components/Messages/helpers/hooks';
import { EMessageContentType } from '../../models';
import UnavailableContent from '../UnavailableContent';
import { StyledTokenWrap } from '../../styled';
import { StyledWrap, StyledTitle, StyledPretitle, StyledImagesList, StyledImageItem, StyledRelWrap } from './styled';

interface IGuestListSeries {
  id: string;
  message: MessageSchema;
  isMine: boolean;
  messageRef: React.RefObject<HTMLDivElement>;
  isDark: boolean;
  setIsContentDeleted: Dispatch<SetStateAction<boolean>>;
  contentReactionId?: string;
}

const GuestListSeries: FC<IGuestListSeries> = ({
  id,
  isMine,
  messageRef,
  isDark,
  setIsContentDeleted,
  contentReactionId,
  message,
}) => {
  const { guestListSeries, messageContentStatus } = useGetMessageContent({
    itemType: MessageItemType.GuestListSeries,
    entityId: id,
    contentReactionId,
  });

  const { chatId } = useMessagesChatData();

  const hasFewItems = !!guestListSeries?.guestListItems?.length && guestListSeries?.guestListItems?.length >= 4;

  useEffect(() => {
    setIsContentDeleted(messageContentStatus === MessageContentStatus.Deleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageContentStatus]);

  if (
    messageContentStatus === MessageContentStatus.Unavailable ||
    messageContentStatus === MessageContentStatus.Deleted
  ) {
    return (
      <UnavailableContent
        isDark={isDark}
        isMine={isMine}
        contentType={EMessageContentType.List}
        messageContentStatus={messageContentStatus}
      />
    );
  }

  const contentIds = {
    chatId,
    messageId: message?.id || '',
  };

  return guestListSeries ? (
    <StyledRelWrap isRight={isMine}>
      <UILink
        route={ROUTE_GUEST_LIST_SERIES}
        params={{ id: guestListSeries?.guestListSeriesCard?.id }}
        classname={EUiLinkClassNames.GuestSeriesPreview}
      >
        <StyledWrap bg={GuestBg} hasFewItems={hasFewItems} ref={messageRef}>
          <Grain />
          <StyledPretitle hasFewItems={hasFewItems}>The</StyledPretitle>
          <StyledTitle hasFewItems={hasFewItems}>
            guest <br />
            :list
          </StyledTitle>
          {hasFewItems && guestListSeries?.guestListItems && (
            <StyledImagesList>
              {guestListSeries?.guestListItems.slice(0, 4).map(({ cardStyle }) => (
                <StyledImageItem
                  key={cardStyle?.thumbnailImageSet?.images?.[0]?.id}
                  src={cardStyle?.thumbnailImageSet?.images?.[0]?.url}
                />
              ))}
            </StyledImagesList>
          )}
        </StyledWrap>
      </UILink>
      <StyledTokenWrap className="token-wrap" isRight={isMine}>
        <ReactionsToken contentIds={contentIds} isDark={isDark} />
      </StyledTokenWrap>
    </StyledRelWrap>
  ) : null;
};

export default GuestListSeries;
