import React, { FC, MouseEvent, ReactNode } from 'react';
import Loader from '../Loader';

import StyledButton from './styled';
import { EUiButtonType } from './constants';

interface IButtonProps {
  text: string;
  handler?: (e: MouseEvent<HTMLButtonElement>) => void;
  type?: EUiButtonType;
  isDisabled?: boolean;
  loading?: boolean;
  isDark?: boolean;
  maxWidth?: string;
  name?: string;
  dataTestId?: string;
  id?: string;
  Icon?: ReactNode;
}

const Button: FC<IButtonProps> = ({
  isDark = true,
  type = EUiButtonType.Emphasized,
  text,
  handler,
  maxWidth,
  name,
  loading,
  isDisabled,
  dataTestId,
  Icon,
  id,
}) => (
  <StyledButton
    hasIcon={!!Icon}
    id={id}
    data-tour={name}
    buttonType={type}
    disabled={isDisabled || loading}
    isDark={isDark}
    onClick={handler}
    maxWidth={maxWidth}
    data-testid={dataTestId}
  >
    {loading ? <Loader isDark={isDark} /> : text}
    {Icon}
  </StyledButton>
);

export default Button;
