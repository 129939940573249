import React, { FC } from 'react';

import SinglePageLayout from 'components/SinglePageLayout';
import Warning from 'components/UI/Warning';
import useFixedViewport from 'helpers/useFixedViewport';
import useTheme from 'helpers/useTheme';
import getAuthUser from 'helpers/getAuthUser';
import {
  TEST_ACCOUNT_DETAILS_ALERT,
  TEST_ACCOUNT_DETAILS_EMAIL,
  TEST_ACCOUNT_DETAILS_USERNAME,
  TEST_ID_ACCOUNT_DETAILS_START,
} from 'constants/aqa/settings/accountManagement';

import { StyledInformation, StyledLabel, StyledText } from './styled';

const AccountDetails: FC = () => {
  const isDark = useTheme();
  const { email, username } = getAuthUser();

  useFixedViewport();

  return (
    <SinglePageLayout pageTestId={TEST_ID_ACCOUNT_DETAILS_START} title="Account details">
      <Warning dataTestId={TEST_ACCOUNT_DETAILS_ALERT} isDark={isDark}>
        You can&apos;t change your email or username just yet.
      </Warning>
      <StyledInformation>
        <StyledLabel isDark={isDark}>Email</StyledLabel>
        <StyledText data-testid={TEST_ACCOUNT_DETAILS_EMAIL}>{email}</StyledText>
      </StyledInformation>
      <StyledInformation>
        <StyledLabel isDark={isDark}>Username</StyledLabel>
        <StyledText data-testid={TEST_ACCOUNT_DETAILS_USERNAME}>:{username}</StyledText>
      </StyledInformation>
    </SinglePageLayout>
  );
};

export default AccountDetails;
