import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS } from '../../../../styles/constants';

export const StyledWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    padding-bottom: 40px;
  }

  .cropper-drag-box {
    background-color: ${COLORS.brown.dark[100]};
  }

  .cropper-dashed {
    border: 1px solid ${COLORS.white[100]};
    opacity: 0.3;
  }

  .cropper-point {
    opacity: 1;
    background: ${COLORS.white[100]};
  }

  .cropper-modal {
    opacity: 0.6;
  }

  .cropper-point.point-n,
  .cropper-point.point-s,
  .cropper-point.point-w,
  .cropper-point.point-e {
    display: none;
  }

  .cropper-point.point-se,
  .cropper-point.point-ne,
  .cropper-point.point-nw,
  .cropper-point.point-sw {
    height: 5px;
    width: 5px;
  }

  .cropper-view-box {
    border: 2px solid ${COLORS.white[100]};
    outline: none;
    outline-color: ${COLORS.transparent};
    opacity: 1;
  }

  .cropper-line {
    opacity: 0;
  }
`;

export const StyledFileInput = styled.input`
  cursor: pointer;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
`;

export const StyledTitleWrapper = styled.div`
  display: none;

  span {
    color: ${COLORS.white[100]};
    font-size: 22px;
    margin-left: 10px;
  }

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    align-items: center;
    display: flex;
    padding: 16px 0;
  }
`;
