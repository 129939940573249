import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import Truncate from 'react-truncate-markup';

import { CardSchema } from 'constants/graphqlTypes';
import Grain from 'components/UI/Grain';
import Avatars from 'components/UI/Avatars';
import { formatDateRange } from 'components/List/helpers';
import { StyledTitle } from '../styled';

import { StyledAvatarsContainer, StyledDescription, StyledDate } from './styled';

interface IContentCardProps {
  item: CardSchema;
}

const Description: FC<IContentCardProps> = ({ item }) => {
  const { profileName } = useParams<{ profileName: string }>();
  const { title, description, cardsFromCollection, creators, createdAt, updatedAt, user, isCollaborative } = item;
  const creator = profileName
    ? creators?.items.find((userItem) => userItem.user?.username === profileName)?.user
    : user;
  const titlesLine = description ? 2 : 3;

  return (
    <>
      <Grain />
      <StyledDescription>
        {createdAt && updatedAt && <StyledDate>{formatDateRange(createdAt, updatedAt)}</StyledDate>}
        {title && (
          <Truncate lines={titlesLine}>
            <StyledTitle isDark total={cardsFromCollection?.totalCount}>
              {title}
            </StyledTitle>
          </Truncate>
        )}
        {description && (
          <Truncate lines={2}>
            <div>{description}</div>
          </Truncate>
        )}
        {!!creator && !isCollaborative && (
          <StyledAvatarsContainer>
            <Avatars users={[creator]} limit={1} />
          </StyledAvatarsContainer>
        )}
      </StyledDescription>
    </>
  );
};

export default Description;
