export const getLines = (height: number): number => {
  if (height < 90) {
    return 0;
  }
  if (height < 105) {
    return 1;
  }
  if (height < 150) {
    return 2;
  }

  return 3;
};
