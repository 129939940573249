import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS } from 'styles/constants';
import { StyledWrap as ImportWrap } from 'components/Modal/styled';

export const StyledWrap = styled(ImportWrap)`
  backdrop-filter: none;
`;

interface IStyledButtonProps {
  height: number;
  width: number;
  top: number;
  left: number;
  borderRadius: string;
}

export const StyledButton = styled.button<IStyledButtonProps>`
  background-color: ${COLORS.transparent};
  backdrop-filter: brightness(1.5);
  border: 2px solid ${COLORS.white[100]};
  border-radius: ${({ borderRadius }) => borderRadius};
  position: fixed;
  height: ${({ height }) => height}px;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
  width: ${({ width }) => width}px;
`;

export const StyledChildWrap = styled.div<{ top: number | null; bottom: number | null }>`
  bottom: ${({ bottom }) => `${bottom}px` ?? 'unset'};
  max-width: ${BREAKPOINTS.s}px;
  position: ${({ bottom, top }) => (top || bottom ? 'fixed' : 'static')};
  padding: 0 12px;
  top: ${({ top }) => `${top}px` ?? 'unset'};
  width: 100%;
`;
