import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';

import ImportWrap from 'components/UI/Wrap/styled';

export const StyledBgWrap = styled.div`
  background: linear-gradient(180deg, #2a657e, #b977ad);
  height: 100%;
  min-height: 100vh;
  animation: gradient-animation 600ms ease-in-out forwards;

  @keyframes gradient-animation {
    0% {
      background-size: 100% 200%;
    }
    100% {
      background-size: 100% 100%;
    }
  }
`;

export const StyledWrap = styled(ImportWrap)<{ disableInnerScroll?: boolean }>`
  color: ${COLORS.white[100]};
  padding-top: 112px;
  width: calc(100% - 48px);
  overflow-y: ${({ disableInnerScroll }) => (disableInnerScroll ? 'visible' : 'auto')};
  position: relative;
  height: ${({ disableInnerScroll }) => (disableInnerScroll ? 'auto' : '100%')};
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTitle = styled.h1`
  font: 550 14px/140% ${FONTS.secondary};
`;

export const StyledText = styled.p<{ delay?: number; isAnimated?: boolean }>`
  animation: fade-in 600ms ease-in-out ${({ delay }) => (delay ? delay + 600 : 600)}ms forwards;
  color: ${COLORS.white[100]};
  font: 400 22px/130% ${FONTS.primary};
  margin: 22px 0 28px;
  opacity: ${({ isAnimated }) => (isAnimated ? 0 : 1)};

  @keyframes fade-in {
    0% {
      opacity: ${({ isAnimated }) => (isAnimated ? 0 : 1)};
      transform: translateY(${({ isAnimated }) => (isAnimated ? '30px' : '0')});
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
