import React from 'react';

import { StyledHeading, StylesSubHeating, StyledContent, StyledTextWrapper, StyledList, StyledLink } from './styled';
import { StyledSubHeading } from '../Welcome/styled';

const TermsAndConditionsText: React.FC = () => (
  <StyledTextWrapper>
    <StyledContent>Last updated and in effect as of December 7, 2023</StyledContent>
    <StyledContent>
      Guide Human (“guide:human,” “we,” “us,” or “our”) owns and operates the guide:human mobile application (“App”),
      related website available at [guidehuman.co] (this “Website”) and associated services (collectively, the
      “Service”). These Terms of Service (“Terms”) are an agreement between you and guide:human. These Terms apply to
      your access and use of any part of the Service.
      <br />
      Please Read The Following Terms Of Use Agreement Carefully Before Using The Service. By accessing or using our
      Website and our Services, you agree to be bound by the terms and all terms incorporated in them by reference. It
      is your responsibility to read the terms and conditions before using this website. If you do not expressly agree
      to all of the terms and conditions, then please do not access or use our Services.
      <br />
      If anything is unclear, or you don’t understand what’s being said, please reach out.
      <br />
      By accessing or using this Website in any way (including browsing), using any information, or submitting any
      content or personal information via this Website, you agree to be bound by these terms and conditions. You
      represent and warrant that you are at least eighteen (18) years old and are legally able to agree to these terms.
      <br />
      Please note that Section 10 contains an arbitration clause. By agreeing to these Terms of Service, you agree to
      resolve all disputes with us through binding individual arbitration, which means that you waive any right to have
      those disputes decided by a judge or jury.
    </StyledContent>
    <StyledHeading>Guide Human Services</StyledHeading>
    <StyledContent>
      Our goal is to bring you closer to the people and things you love. This goal is made up of the following aspects:
    </StyledContent>
    <StyledList>
      <li>
        <strong>
          Offering personalized opportunities to collect, share, and discover valuable information on the web based on
          personal interests.
        </strong>
      </li>
      <li>
        <strong>Fostering a positive, inclusive, and safe environment.</strong>
      </li>
    </StyledList>
    <StyledContent>
      Please note, we are always working on new ways to improve the Service. We reserve the right to modify or change
      the Services, temporarily or permanently, with or without notice to you, and we are not obligated to support or
      update the Service. You acknowledge and agree that we shall not be liable to you or any third party in the event
      that we exercise our right to modify, change or discontinue the Service.
    </StyledContent>
    <StyledHeading>Registration</StyledHeading>
    <StyledContent>
      In order to use the Service, you must register for an account (“Account”) by providing your name and your email
      address. You may also register by linking your Google® account or Apple® account. If You register by linking Your
      Apple® account, You will have the option to enable “Hide My Email.” Hide My Email will generate a random email
      address that then forwards messages to Your actual email address. You agree the information you give us has to be
      accurate and complete and that you are not impersonating anyone else or choosing a name that are offensive or that
      violate anyone’s rights. If you don’t follow these rules, we may cancel your account. You agree to take all
      reasonable precautions to protect your password and your Account and notify us at{' '}
      <StyledLink href="mailto:help@guidehuman.co">help@guidehuman.co</StyledLink> of any breach of security or
      unauthorized use of your Account. To have a “Verified” account, You must provide Your full legal name and proof of
      identify, for one time confirmation. You may only have one active Account regardless of how many mobile phone
      numbers or devices you have. You may only have one mobile device linked to your account.
      <br />
      To sign up for an account, you need to be at least 13 years old. If necessary, we may ask you for proof of age. If
      You are a parent or guardian and You are aware that Your child has provided us with Personal Information, please
      contact us so that we will be able to do necessary actions.
    </StyledContent>
    <StyledHeading>Third-party Content and Links</StyledHeading>
    <StyledSubHeading>Third-Party Content</StyledSubHeading>
    <StyledContent>
      Certain materials on the Service may be furnished by third parties. Certain product, service, or company
      designations for companies other than guide:human may be mentioned in the Service for identification purposes only
      and third-party links are provided for your convenience only. guide:human does not control such third-party
      websites (“Linked Websites”) and is not responsible for any content thereon, including with respect to any
      comments posted on such third-party websites. The inclusion of links to such third-party websites does not amount
      to or imply any endorsement or warranty of the material on such sites or any association with their owners or
      operators. You agree that guide:human is not responsible for any such third-party websites and services or any
      content thereon and agrees to hold guide:human harmless from any and all claims or liability arising from your use
      of such third-party websites or services. You will need to make your own independent judgment regarding your
      interaction with these Linked Websites. You should refer to the separate terms of use, privacy policies, and other
      rules posted on Linked Websites before you use them. Any concerns or questions related to third-party websites
      should be directed to the webmaster or other appropriate contact person for such third party. Third-party
      trademarks, trade names, logos, product or services names contained on this Website are the trademarks, registered
      or unregistered, of their respective owners.
    </StyledContent>
    <StylesSubHeating>User Content</StylesSubHeating>
    <StyledContent>
      You acknowledge that the Service may include content provided by users (“User Content”). Any opinions, advice,
      statements, judgments, services, offers, or other information that constitutes part of the content expressed or
      made available by content posted by our contributors or User Content, are those of the respective authors and not
      of guide:human or its affiliates or any of their officers, directors, employees, or agents. While we retain the
      right to filter or reject User Content and other content posted by third parties, we review User Content and other
      third party content in a limited gate-keeper fashion and have no obligation to investigate whether any content or
      other User Content violate any term of this Agreement and will not do so in most cases. We neither endorse nor are
      responsible for the User Content or any other content posted to the Service by anyone other than guide:human.
    </StyledContent>
    <StylesSubHeating>Apple App Store Terms</StylesSubHeating>
    <StyledContent>
      The following terms and conditions apply to you only if you acquired the App from the Apple App Store. To the
      extent the other terms and conditions of these Terms are less restrictive than, or otherwise conflict with, the
      terms and conditions of this paragraph, the more restrictive or conflicting terms and conditions in this paragraph
      apply. You acknowledge and agree that these Terms are solely between you and guide:human, not Apple, and that
      Apple has no responsibility for the App or the content thereof. Your use of the App must comply with the App Store
      Terms of Use. You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and/or support
      services with respect to the App. In the event of any failure of the App to conform to any applicable warranty,
      you may notify Apple, and Apple will refund to you the purchase price, if any, paid by you for the App; to the
      maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect
      to the App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to
      conform to any warranty will be solely governed by these Terms. You and guide:human acknowledge that Apple is not
      responsible for addressing any claims of you or any third party relating to the App or your possession and/or use
      of the App, including, but not limited to: (i) product liability claims; (ii) any claim that the App fails to
      conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or
      similar legislation. You and guide:human acknowledge that, in the event of any third party claim that the App or
      your possession and use of that App infringes that third party’s intellectual property rights, guide:human, not
      Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such
      intellectual property infringement claim to the extent required by these Terms. You must comply with any
      applicable third-party terms of agreement when using the App. You and guide:human acknowledge and agree that Apple
      and Apple’s subsidiaries are third party beneficiaries of these Terms as they relate to your license of the App,
      and that, upon your acceptance of the Terms, Apple will have the right (and will be deemed to have accepted the
      right) as a third party beneficiary to enforce these Terms against you.
    </StyledContent>
    <StyledHeading>Our Proprietary Rights</StyledHeading>
    <StyledContent>
      All materials contained in the Services, including all content, and the sound, audio, software, graphics, text and
      look and feel of the Website and services, and all trademarks, copyrights, patents and other intellectual property
      rights related thereto (“Proprietary Materials”), are owned or controlled by guide:human, our subsidiaries or
      affiliated companies, contributors, our third party licensors, and/or our advertising partners. All rights
      reserved. Neither these Terms nor your use of the Services transfers any right, title or interest in the Services,
      the Content, or Intellectual Property Rights to you, and guide:human and its third party licensors retain all of
      its and their respective right, title and interest to the Services and Content.
      <br />
      Except as provided in these Terms, you may not use, modify, republish, frame, print, display, perform, reproduce,
      license, transfer, sell, assign, post, transmit, distribute, reverse engineer, create derivative works from, or
      otherwise exploit any Content or information from the Services, in whole or in part, without the express written
      permission of guide:human. In addition, you agree not to use any data mining, robots, or similar data gathering
      and extraction methods in connection with the Services. To the extent any action or usage constituting a violation
      of the User Conduct is otherwise explicitly permitted or authorized pursuant to these Terms, such authorization is
      not intended to and shall not vest in you any ownership interests or other rights of any kind beyond those
      expressly granted herein. Unauthorized use of the Services, including usage of the Services in violation of the
      User Conduct, shall be a material breach of these Terms and may subject you to legal action.
      <br />
      Nothing in these Terms confers any other license or right, express or implied, under any of our intellectual
      property rights or under any third party&apos;s intellectual property rights. guide:human reserves all rights not
      expressly granted.
    </StyledContent>
    <StyledHeading>Your Proprietary Rights, Feedback, and Use Restrictions</StyledHeading>
    <StylesSubHeating>User Content</StylesSubHeating>
    <StyledContent>
      <StyledList>
        <li>
          You and all other users of the Service retain ownership of your User Content and you are free to share your
          content with anyone else, wherever you want. You retain copyright and any other rights you already hold in
          your User Content, but by uploading, posting, contributing, or otherwise providing your User Content, you
          grant to guide:human a nonexclusive, perpetual, irrevocable, royalty-free, worldwide, transferable, sublicense
          able license to access, use, host, cache, store, reproduce, publicly transmit, publicly display, publicly
          perform, publicly publish, publicly distribute, modify and adapt and create derivative works (either alone or
          as part of a collective work) from User Content. You also agree that (i) the other users of the services shall
          have the right to comment on and/or tag User Content and/or to use, publish, display, modify or include a copy
          of User Content as part of their own use of the Services, and (ii) we have the right to make any of User
          Content available to third parties, so that those third parties can distribute, make derivative works of,
          comment on and/or analyze your Content on other media and services (either alone or as part of a collective
          work).
        </li>
        <li>
          You give us permission to show your username, profile picture, and information about your actions (such as
          likes) or relationships (such as follows) next to or in connection with accounts and other content that you
          follow or engage with that are displayed on the Service, without any compensation to you.
        </li>
        <li>You agree that we can download and install updates to the Service on your device.</li>
        <li>guide:human is not liable for any errors or omissions in any User Content.</li>
      </StyledList>
    </StyledContent>
    <StylesSubHeating>Representation and Warranties</StylesSubHeating>
    <StyledContent>
      By posting or submitting User Content through the services, you represent and warrant that:
      <StyledList>
        <li>
          You have, or have obtained, all rights, licenses, consents, permissions, power and/or authority necessary to
          grant the rights granted herein for User Content;
        </li>
        <li>
          guide:human will not need to obtain licenses from any third party or pay royalties to any third party to use
          the User Content and Feedback in accordance with these Terms;
        </li>
        <li>
          the User Content and Feedback do not infringe any third party’s rights, including but not limited to
          intellectual property rights and privacy rights; and
        </li>
        <li>the User Content and Feedback comply with these Terms and all applicable laws.</li>
      </StyledList>
    </StyledContent>
    <StylesSubHeating>Feedback</StylesSubHeating>
    <StyledContent>
      guide:human will provide You with a mechanism to provide feedback, suggestions and ideas, if You choose, about its
      products, services and the Website and App (“Feedback”). You agree that guide:human may, in its sole discretion,
      use the Feedback you provide to guide:human in any way, including in future modifications of this Website and App
      or in other guide:human products or services. You hereby grant guide:human a perpetual, worldwide, irrevocable,
      fully-paid, royalty-free, sub-licensable and transferable license to use, modify, create derivative works from,
      publicly distribute, publicly display, and otherwise exploit the Feedback for any purpose. When you submit ideas,
      documents, suggestions and/or proposals (&quot;Suggestions&quot;) to our Service, you acknowledge and agree that:
      <StyledList>
        <li>Your Suggestions do not contain any type of confidential or proprietary information;</li>
        <li>
          guide:human shall not be liable or under any obligation to ensure or maintain confidentiality, expressed or
          implied, related to any Suggestions;
        </li>
        <li>
          guide:human shall be entitled to make use of and/or disclose any such Suggestions in any such manner as it
          sees fit; and
        </li>
        <li>
          guide:human is under no obligation to either compensate or provide any form of reimbursement in any manner or
          nature.
        </li>
      </StyledList>
    </StyledContent>
    <StylesSubHeating>Abide by the Law</StylesSubHeating>
    <StyledContent>
      Your use of the Service, including but not limited to the Content you post on the Service, must be in accordance
      with any and all applicable laws and regulations.{' '}
    </StyledContent>
    <StylesSubHeating>User Conduct</StylesSubHeating>
    <StyledContent>
      By using the Service, including any Content and services available through it, you agree to abide by our Community
      Guidelines and that you shall not:
      <StyledList>
        <li>
          Use, save or distribute the content of the Services without the express prior written consent of guide:human;
        </li>
        <li>Delete, modify, hack or attempt to change or alter any of the Content on this Website;</li>
        <li>
          Try to access accounts, computer systems, or networks connected to any of our servers or to this Website,
          through hacking, password mining, or any other means, or obtain or attempt to obtain any materials or
          information through any means not intentionally or readily made available through this Website;
        </li>
        <li>
          Use any device, software, or routine intended to damage or otherwise interfere with the proper functioning of
          this Website or servers or networks connected to this Website, or take any other action that interferes with
          other parties&apos; use of this Website;
        </li>
        <li>
          Use any robot, spider, or other automatic or manual device or process to harvest or compile information on
          this Website for purposes other than for a generally available search engine;
        </li>
        <li>
          Use any guide:human names, service marks, or trademarks, or logos without our prior written consent, including
          as metatags, search engine keywords, or hidden text;
        </li>
        <li>
          Use any material or information, including images or photographs, made available through this Website in any
          manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any party;
        </li>
        <li>
          Transmit or upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files,
          or any other similar software or programs that may damage the operation of a computer or property;
        </li>
        <li>
          Create a false identity to mislead others, breach any contractual or confidentiality obligations, or violate
          privacy rights;
        </li>
        <li>
          Defame, abuse, harass, stalk, threaten, intimidate, or otherwise violate the legal rights of anyone else; or
        </li>
        <li>
          Provide false information, impersonate another person or entity, or misrepresent your affiliation with any
          entity.
        </li>
      </StyledList>
    </StyledContent>
    <StyledHeading>Permissible Use</StyledHeading>
    <StyledContent>
      Except as otherwise prohibited on this Website, you may view, copy, retransmit, and print the Content available on
      this Website under the following conditions:
      <StyledList>
        <li>
          You may only use the Content for personal, informational, or non-commercial purposes, or to request further
          information about guide:human;
        </li>
        <li>
          You may not provide, sell, license, or lease the Content or access to the Content for any fee or other
          consideration;
        </li>
        <li>
          You must ensure all copyright, trademark and other proprietary rights notices included in the Content as
          presented on this Website appear on all copies;
        </li>
        <li>You may not modify or alter the Content in any way; and</li>
        <li>You may not use any graphics separately from accompanying text.</li>
      </StyledList>
    </StyledContent>
    <StyledHeading>Termination</StyledHeading>
    <StyledSubHeading>General</StyledSubHeading>
    <StyledContent>
      We reserve the right, but not the obligation, to monitor, terminate, suspend, or delete any User Account at any
      time in our sole discretion. We have no obligation to comment on any reasons for account monitoring, termination,
      suspension, or deletion.
      <br />
      We have no liability to You or third parties for any use of the Services associated with Your account. Upon
      suspension or termination of Your account, You must cease all use of the Services and User Content. Your
      representations and warranties shall survive termination.
    </StyledContent>
    <StylesSubHeating>Breach</StylesSubHeating>
    <StyledContent>
      Your breach of any obligations under these Terms may result in immediate suspension or termination of your access
      to the Services. Without limiting the foregoing, you acknowledge and agree that any action in violation of the
      User Conduct shall be an incurable breach of these Terms. If guide:human deletes your Account for any suspected
      Breach of these Terms by you, you are prohibited from re-registering for the Service whether you use the same or a
      different name or mobile telephone number.
    </StyledContent>
    <StylesSubHeating>Your Limited Rights after Termination</StylesSubHeating>
    <StyledContent>
      In the event of termination of these Terms for any reason other than a violation of the User Conduct, you may
      retain for its personal, noncommercial use, any materials or content of the Services that have already been
      delivered; provided, however, you agree to be bound by the restrictions on distribution and dissemination of the
      content of the Services provided herein (including, without limitation, the User Conduct) even after termination
      of the Services.
    </StyledContent>
    <StylesSubHeating>Terms Survival</StylesSubHeating>
    <StyledContent>
      All provisions of these Terms, which by their nature should survive the termination of these Terms, shall continue
      in full force and effect subsequent to and notwithstanding any termination of the Terms by guide:human or you. In
      the event of Account deletion for any reason, guide:human may, but is not obligated to, delete any of your Content
      (as defined in Section 5). guide:human shall not be responsible for the deletion of (or failure to delete) such
      Content.
    </StyledContent>
    <StyledHeading>Privacy</StyledHeading>
    <StyledContent>
      guide:human will treat any information it collects from you through the Service according to its Privacy Policy
      (the “Policy”), which is incorporated by reference into these Terms. Please review the Policy, and be sure you
      understand and agree to it, before you use the Service.{' '}
    </StyledContent>
    <StyledHeading>Representations and Warranties</StyledHeading>
    <StyledSubHeading>Warranty Disclaimers</StyledSubHeading>
    <StyledContent>
      YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT:
      <StyledList>
        <li>
          THE USE OF GUIDE:HUMAN SERVICES AND SOFTWARE ARE AT YOUR SOLE RISK. ALTHOUGH GUIDE:HUMAN MAKES EFFORTS TO
          PROVIDE AN ACCURATE WEBSITE AND SERVICES, OUR SERVICES AND SOFTWARE ARE PROVIDED ON AN &quot;AS IS&quot;,
          &quot;WITH ALL FAULTS&quot;, AND &quot;AS AVAILABLE&quot; BASIS.” GUIDE:HUMAN AND OUR SUBSIDIARIES,
          AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF
          ANY KIND WHETHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF TITLE,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        </li>
        <li>
          GUIDE:HUMAN AND OUR SUBSIDIARIES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS DO NOT WARRANT THAT (i)
          GUIDE:HUMAN SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS OR REQUIREMENTS; (ii) GUIDE:HUMAN SERVICES OR
          SOFTWARE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (iii) RESULTS YOU OBTAIN FROM THE USE OF THE
          GUIDE:HUMAN SERVICES OR SOFTWARE WILL BE ACCURATE OR RELIABLE; (iv) THE QUALITY OF THE PRODUCTS, SERVICES,
          INFORMATION OR OTHER MATERIAL YOU PURCHASE OR OBTAIN THROUGH OUR SERVICES OR SOFTWARE WILL MEET YOUR
          EXPECTATIONS; OR (v) ANY ERRORS CONTAINED IN THE WEBSITE OR SOFTWARE WILL BE CORRECTED.
        </li>
        <li>
          ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED BY WAY OF GUIDE:HUMAN SERVICES OR SOFTWARE WILL
          BE ACCESSED AT YOUR SOLE DISCRETION AND SOLE RISK, AND YOU ARE SOLELY RESPONSIBLE FOR, AND WAIVE ALL CLAIMS
          AND CAUSES OF ACTION WITH RESPECT TO, ANY DAMAGE TO YOUR COMPUTER AND/OR INTERNET ACCESS, DOWNLOADING, AND/OR
          DISPLAYING, OR FOR ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD.
        </li>
        <li>
          GUIDE:HUMAN MAKES EVERY REASONABLE EFFORT TO ASSURE THAT ALL INFORMATION PUBLISHED BY IT IS CORRECT; HOWEVER,
          GUIDE:HUMAN DISCLAIMS ANY LIABILITY FOR ERRORS IN THE SERVICES. AS A SUBSCRIBER, YOU ASSUME THE RISK OF
          POSSIBLE ERRORS CONTAINED IN THE SERVICES. YOU AGREE TO INDEPENDENTLY VERIFY ANY INFORMATION IT INTENDS TO
          RELY UPON.
        </li>
        <li>
          NO ADVICE AND/OR INFORMATION, WHETHER WRITTEN OR ORAL, THAT YOU OBTAINED FROM GUIDE:HUMAN, OR BY WAY OF OR
          FROM OUR SERVICES OR SOFTWARE, CREATES ANY WARRANTY NOT EXPRESSLY GRANTED IN THE TERMS OF SERVICE.
        </li>
      </StyledList>
    </StyledContent>
    <StylesSubHeating>Indemnity</StylesSubHeating>
    <StyledContent>
      You agree to indemnify and hold harmless guide:human, our subsidiaries, affiliates, agents, employees, officers,
      partners, directors, and/or licensors from any loss, damages, or costs, including without limitation reasonable
      attorneys’ fees, resulting from any third party claim, action, or demand resulting from your use of or access to
      the Service, your violation of any third party’s proprietary or other rights, or breach of these Terms. You
      further agree to indemnify guide:human for all costs and expenses that guide:human incurs in the event that you
      commit a Breach of these Terms or the terms of any other agreement with guide:human.
    </StyledContent>
    <StyledSubHeading>Limitation Of Liability</StyledSubHeading>
    <StyledContent>
      You understand and agree that guide:human and our subsidiaries, affiliates, officers, employees, agents, partners,
      and licensors ARE NOT LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
      DAMAGES—INCLUDING, BUT NOT LIMITED TO, DAMAGES FROM LOST PROFITS, GOODWILL, USE, DATA, AND/OR OTHER INTANGIBLE
      LOSSES—even if we were advised that such damages may occur, that result from:
      <StyledList>
        <li>The use of or inability to use our service;</li>
        <li>The cost of procuring substitute goods and services;</li>
        <li>Unauthorized access to or alteration of your transmissions and/or data;</li>
        <li>Statements or conduct of any third party on our service; and</li>
        <li>Any other matter related to our service.</li>
      </StyledList>
    </StyledContent>
    <StyledHeading>Copyright or Intellectual Property Infringement Claims Notice & Procedures</StyledHeading>
    <StyledContent>
      guide:human will always respect the intellectual property of others, and we ask that all of our users do the same.
      guide:human may disable and/or terminate the accounts of any user who violates these Terms and/or infringes the
      rights of others. Please contact us immediately if you feel that your intellectual property rights have been
      violated, you should provide to us the following information:
    </StyledContent>
    <StyledList>
      <li>
        The electronic or the physical signature of the individual that is authorized on behalf of the owner of the
        copyright or other intellectual property interest;{' '}
      </li>
      <li>
        A description of the copyrighted work or other intellectual property that you believe has been infringed upon;
      </li>
      <li>A description of the location of the site which you allege has been infringing upon your work;</li>
      <li>Your physical address, telephone number, and email address;</li>
      <li>
        A statement, in which you state that the alleged and disputed use of your work is not authorized by the
        copyright owner, its agents or the law;
      </li>
      <li>
        And finally, a statement, made under penalty of perjury, that the aforementioned information in your notice is
        truthful and accurate, and that you are the copyright or intellectual property owner, representative or agent
        authorized to act on the copyright or intellectual property owner&apos;s behalf.
      </li>
    </StyledList>
    <StyledHeading>Miscellaneous</StyledHeading>
    <StyledSubHeading>Cautions For Global Use And Export And Import Compliance</StyledSubHeading>
    <StyledContent>
      Due to the global nature of the Internet, through the use of our network you hereby agree to comply with all local
      rules relating to online conduct and that which is considered acceptable Content. Uploading, posting and/or
      transferring of software, technology and other technical data may be subject to the export and import laws of the
      United States and possibly other countries. Through the use of our network, you thus agree to comply with all
      applicable export and import laws, statutes and regulations.{' '}
    </StyledContent>
    <StylesSubHeating> Updates To These Terms</StylesSubHeating>
    <StyledContent>
      guide:human may change, modify, add, and/or delete all or portions of these Terms from time to time by posting
      updated Terms on this Website, which will apply to your use of this Website after the modifications have been
      posted. Please review these Terms regularly for any updates or changes. Your continued use of this Website after
      we post updates or changes to these Terms constitutes your acceptance of the updates or changes. If you object to
      any part of these Terms or any later modifications to these Terms, you should stop using this Website.
    </StyledContent>
    <StylesSubHeating>Errors On The Website</StylesSubHeating>
    <StyledContent>
      It is not possible to ensure that any website is free of human or technological errors. This Website may contain
      typographical mistakes, inaccuracies, or omissions, and some information may not be complete or current.
      guide:human reserves the right to correct any errors, inaccuracies, or omissions, and to change or update
      information at any time without notice.
    </StyledContent>
    <StylesSubHeating>Modifications Or Termination Of Website</StylesSubHeating>
    <StyledContent>
      guide:human may modify, alter, and/or discontinue, whether temporarily or permanently, our services or this
      Website without prior notice. guide:human may also—at its sole discretion—modify or terminate your access to this
      Website (or portions of this Website) at any time, temporarily or permanently, without notice to you. Sections of
      these terms entitled Website Content, User Conduct, and Contributions To Company Website, this Section, and
      Sections Accounts and Passwords, Links, Warranty Disclaimers, Limitation of Liability, Indemnity, Release,
      Copyright Or Intellectual Property Infringement Claims Notice & Procedures, and General Information survive any
      termination.
    </StyledContent>
    <StylesSubHeating> No Third Party Beneficiaries</StylesSubHeating>
    <StyledContent>Your agreement to these Terms does not create third party beneficiaries.</StyledContent>
    <StylesSubHeating>Use Safely</StylesSubHeating>
    <StyledContent>
      Some users may experience adverse effects when exposed to certain light patterns or backgrounds on a computer
      screen. Please consult a physician if you experience adverse effects such as dizziness, altered vision, eye or
      muscle twitches, loss of awareness, disorientation, or involuntary movement while using the website.
    </StyledContent>
    <StylesSubHeating>Entire Agreement </StylesSubHeating>
    <StyledContent>
      These Terms constitutes the entire agreement between you and guide:human regarding the use of our Services, and
      supersedes any prior version. You may also be subject to additional third-party terms and conditions when you use
      or purchase affiliate services, third-party content, or third-party software.
    </StyledContent>
    <StylesSubHeating>Choice Of Law And Forum</StylesSubHeating>
    <StyledContent>
      This Terms of Service shall be construed and governed under the laws of the United States and the Massachusetts
      (without regard to rules governing conflicts of laws provisions). You agree that venue for all actions, arising
      out of or relating in any way to your use of our Services, shall be in the federal court in Boston, Massachusetts
      or state court of competent jurisdiction located in Suffolk County, within one (1) year after the claim arises.
      Each party waives any objections based on forum non conveniens and waives any objection to venue of any action
      instituted hereunder to the extent that an action is brought in the courts identified above. Each party consents
      to personal jurisdiction in the courts identified above.
      <br />
      All such disputes are subject to binding arbitration by a United States Arbitration & Mediation (“USA&M”) neutral
      according to the USA&M Rules of Arbitration. The arbitrator’s decision shall be final and binding, and judgment
      may be entered thereon. If a party fails to proceed with arbitration, unsuccessfully challenges the arbitrator’s
      award, or fails to comply with arbitrator’s award, the other party is entitled of costs of suit including a
      reasonable attorney’s fee for having to compel arbitration or defend or enforce the award.
    </StyledContent>
    <StylesSubHeating>Waiver And Severability Of Terms</StylesSubHeating>
    <StyledContent>
      guide:human’s failure to exercise or enforce any right or provision of the Terms does not constitute a waiver. If
      any provision of this Terms is found by a court of competent jurisdiction to be invalid, you agree that the court
      should try to give effect to the parties&apos; intentions as reflected in the provision, and that the other
      provisions of the Terms remain in full force and effect.
    </StyledContent>
    <StylesSubHeating>Consent to Communication</StylesSubHeating>
    <StyledContent>
      Client agrees that guide:human reserves the right to send electronic or paper mail to you for the purpose of
      informing you of changes or additions to the Services or this Agreement. You further agrees that from time to time
      guide:human may contact you via electronic or paper mail for the purpose of soliciting feedback or participation
      in user surveys relating to the Services. Any information obtained from you will not be shared with any third
      parties as provided in the Policy.
    </StyledContent>
    <StylesSubHeating>Electronic Communications</StylesSubHeating>
    <StyledContent>
      When you use the Services or send emails to us, you are communicating with us electronically. You consent to
      receive communications from us electronically. We will communicate with you by email or by posting notices through
      the Services. You agree that all agreements, notices, disclosures and other communications that we provide you
      electronically satisfy any legal requirement that such communications be in writing.
    </StyledContent>
    <StyledHeading>Contact Information</StyledHeading>
    <StyledContent>
      If you have any questions about these Terms or guide:human’s information practices, or would like to update your
      personal information, please contact us at
      <StyledLink href="mailto:help@guidehuman.co">help@guidehuman.co</StyledLink>.
    </StyledContent>
    <StyledContent>
      <StyledLink href="https://guidehuman.co/2023.12.7 Terms of Service.pdf" target="_blank" download>
        Download file
      </StyledLink>
    </StyledContent>
  </StyledTextWrapper>
);

export default TermsAndConditionsText;
