import { useEffect, useState } from 'react';
import validUrl from 'valid-url';

import { INVALID_URL_ERROR_MESSAGE } from '../helpers/constants';

export const useCheckPickUrl = (url: string) => {
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    setValidationError(!url.length || validUrl.isUri(url) ? '' : INVALID_URL_ERROR_MESSAGE);
  }, [url]);

  return validationError;
};
