import { styled } from 'linaria/lib/react';

const DEFAULT_NUMBER_OF_ITEMS = 3;
const DEFAULT_PADDING = 3;

export const StyledImage = styled.img`
  border-radius: 2px;
  height: 38px;
  object-fit: cover;
  position: relative;
  width: 38px;
`;

export const StyledMiniatureWrap = styled.div<{ bgColor: string; index: number }>`
  bottom: ${({ index }) => index * 3}px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: ${({ index }) => DEFAULT_NUMBER_OF_ITEMS * 10 - index * 10};
  > div {
    align-items: center;
    background: ${({ bgColor }) => bgColor};
    border-radius: 4px;
    display: flex;
    height: 44px;
    justify-content: center;
    position: relative;
    width: ${({ index }) => 44 - index * DEFAULT_PADDING * 2}px;
  }
`;

export const StyledMiniatureContainer = styled.div`
  height: 50px;
  position: relative;
  width: 44px;
`;
