import { styled } from 'linaria/react';

import { StyledList as ImportList } from 'pages/Settings/styled';
import { BREAKPOINTS, FONTS, COLORS } from '../../../styles/constants';
import { PULSING_ANIMATION, PULSING_KEYFRAMES } from '../helpers/constants';

export const StyledPreview = styled.div<{ bgColor?: string; hasNoImage?: boolean }>`
  margin: 16px auto 0;
  overflow-y: auto;
  padding: 8px 0 84px;
  scrollbar-width: none;
  width: 80%;
  transform: scale3d(1, 1, 1);

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    width: 60%;
  }

  a {
    pointer-events: none;
  }

  .card-body {
    animation: ${PULSING_ANIMATION};
    background: ${({ bgColor }) => bgColor ?? COLORS.white[20]};

    ${PULSING_KEYFRAMES}

    &::before {
      background: ${({ bgColor }) => bgColor ?? COLORS.transparent};
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .grain {
    display: ${({ bgColor }) => (bgColor ? 'block' : 'none')};
  }

  .card-image-loader {
    display: ${({ hasNoImage }) => (hasNoImage ? 'block' : 'none')};
  }
`;

export const StyledFooter = styled.footer`
  margin-top: auto;
`;

export const StyledList = styled.ul`
  display: flex;
  gap: 4px;
  list-style: none;
  margin: 0 -12px 0 0;
  overflow: auto;
  padding: 12px 0 32px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledButton = styled.button<{
  image?: string | null;
  bgColor?: string;
  isCurrent: boolean;
  customImageUrl?: string;
}>`
  background-color: ${COLORS.transparent};
  border: 2px solid ${({ isCurrent }) => (isCurrent ? COLORS.white[100] : COLORS.transparent)};
  border-radius: 14px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  font-family: ${FONTS.secondary};
  font-size: 16px;
  overflow: hidden;
  padding: 2px;
  position: relative;

  &::before,
  &::after {
    align-items: center;
    border-radius: 10px;
    color: ${COLORS.white[100]};
    display: flex;
    justify-content: center;
    height: 70px;
    width: 70px;
  }

  &::before {
    animation: ${PULSING_ANIMATION};
    content: ${({ image }) => (image ? '""' : 'none')};
    background-image: ${({ image }) => (image ? `url('${image}')` : 'none')};
    background-color: ${COLORS.white[20]};
    background-position: center;
    background-size: cover;

    ${PULSING_KEYFRAMES}
  }

  &::after {
    content: ${({ image }) => (!image ? '"Aa"' : 'none')};
    background: ${({ bgColor, customImageUrl }) => customImageUrl ?? bgColor ?? COLORS.brown[100]};
    background-size: cover;
  }
`;

export const StyledColorsList = styled.ul`
  display: none;
  gap: 6px;
  justify-content: flex-end;
  list-style: none;
  margin: 0;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    display: flex;
  }
`;

export const StyledColorButton = styled.button<{ isCurrent: boolean; color: string }>`
  align-items: center;
  background-color: ${COLORS.transparent};
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  height: 22px;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 22px;

  &::before {
    background: ${({ color }) => color};
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    width: 16px;
  }

  &::after {
    border-radius: 50%;
    border: 1px solid ${({ isCurrent }) => (isCurrent ? COLORS.white[100] : COLORS.transparent)};
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
`;

export const StyledWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const StyledMeatballWrap = styled.li`
  border: 2px solid ${COLORS.transparent};
  padding: 2px;
`;

export const StyledMeatball = styled.button`
  align-items: center;
  backdrop-filter: blur(2px);
  background-color: ${COLORS.white[10]};
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 70px;

  svg > path {
    fill: ${COLORS.white[100]};
  }
`;

export const StyledReportQuestionsList = styled(ImportList)`
  justify-content: flex-start;
`;

export const StyledTitle = styled.span`
  padding: 32px 0 16px;
  font: 400 22px/130% ${FONTS.primary};
`;

export const StyledDescriptionWrap = styled.p`
  border-bottom: 1px solid ${COLORS.white[30]};
  font: 400 14px/140% ${FONTS.secondary};
  margin: 0;
  padding: 16px 0 32px;
`;
