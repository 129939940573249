import React, { FC, useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { XBlock, XMasonry } from 'react-xmasonry';

import LoaderContent from 'components/UI/LoaderContent';

import { BREAKPOINTS } from 'styles/constants';
import { CardSchema } from 'constants/graphqlTypes';
import { IUseCardsBaseSelection } from 'pages/OnboardingV2/ContentTutorial/ListFill/models';
import { StyledSavedPicksWrap } from './styled';

import EmptyPageContent from '../EmptyPageContent';
import SavedPicksItem from './SavedPicksItem';

interface ISavedPicksContentProps {
  isDark: boolean;
  cards?: CardSchema[];
  loading: boolean;
  loadMore: () => void;
  isSelectable: boolean;
  cardsSelection?: IUseCardsBaseSelection;
}

const chooseCardWidth = () => (window.innerWidth >= BREAKPOINTS.xxl ? BREAKPOINTS.retinaScale * 300 : 220);

const SavedPicksContent: FC<ISavedPicksContentProps> = ({
  isDark,
  cards,
  loading,
  loadMore,
  isSelectable,
  cardsSelection,
}) => {
  const [cardWidth, setCardWidth] = useState<number>(chooseCardWidth());

  const { selectedCards, handleCardClick } = cardsSelection ?? {};

  const handleWindowResize = () => setCardWidth(chooseCardWidth());

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return cards?.length ? (
    <StyledSavedPicksWrap>
      <XMasonry
        targetBlockWidth={cardWidth}
        center={false}
        responsive={false}
        maxColumns={2}
        smartUpdate
        smartUpdateCeil={50}
      >
        {cards.map((item) => (
          <XBlock key={item.id}>
            <SavedPicksItem
              item={item}
              isSelectable={isSelectable}
              isChecked={!!selectedCards?.includes(item.cardId)}
              isDark={isDark}
              handler={handleCardClick}
            />
          </XBlock>
        ))}
      </XMasonry>
      {!loading && <Waypoint onEnter={loadMore} />}
    </StyledSavedPicksWrap>
  ) : (
    <>{loading ? <LoaderContent isFullScreen isDark={isDark} /> : <EmptyPageContent isDark={isDark} />}</>
  );
};

export default SavedPicksContent;
