import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SwiperCore from 'swiper';

import { useNavColor } from 'components/Navigation/helpers/hooks';
import { COLORS } from 'styles/constants';
import { ROUTE_FEED_ITEMS, ROUTE_LIST_ITEM } from 'routes';
import { getIndex } from './functions';

export const useImmersiveBgIndex = (
  count: number
): {
  index: number;
  secondIndex: number;
  isSecondShown: boolean;
} => {
  const [index, setIndex] = useState(0);
  const [secondIndex, setSecondIndex] = useState(1);
  const [, setTrigger] = useState(false);

  const isSecondShown = useRef(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count > 1) {
        isSecondShown.current = !isSecondShown.current;
        setTrigger((prev) => !prev);

        setTimeout(() => {
          if (isSecondShown.current) {
            setIndex((prev) => getIndex(prev, count));
          } else {
            setSecondIndex((prev) => getIndex(prev, count));
          }
        }, 510);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [count]);

  return { index, secondIndex, isSecondShown: isSecondShown.current };
};

export const useTransparentNavigation = (): void => {
  const { setNavColor } = useNavColor();

  useEffect(() => {
    setNavColor({ sectionHeight: 3000, navColor: COLORS.white[100] });

    return () => setNavColor({ navColor: undefined, sectionHeight: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useHideMainNavigation = (): void => {
  useEffect(() => {
    const nav = document.querySelector('#navigation-container');

    nav?.classList.add('removed');

    return () => nav?.classList.remove('removed');
  }, []);
};

export const useImmersiveSwiper = (): {
  handleSwiper: (swiper: SwiperCore) => void;
  handleSwipe: () => void;
} => {
  const swiperRef = useRef<SwiperCore>();
  const handleSwiper = (swiper: SwiperCore) => {
    swiperRef.current = swiper;
  };

  const handleSwipe = () => swiperRef?.current?.slideTo(1);

  return { handleSwiper, handleSwipe };
};

export const useImmersiveExplore = (id: string, itemId?: string, isFeed?: boolean): (() => void) => {
  const { push } = useHistory();

  const baseRoute = isFeed ? ROUTE_FEED_ITEMS : ROUTE_LIST_ITEM;
  const handleExplore = () =>
    push(baseRoute.replace(isFeed ? ':feedId' : ':listId', id).replace(':pickId', itemId ?? ''));

  return handleExplore;
};
