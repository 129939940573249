import React, { FC, useState } from 'react';

import Modal from 'components/Modal';
import Button from 'components/UI/MenuButton';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { ReactComponent as TrashIcon } from 'assets/icons/TrashCan.svg';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';

import { StyledItem } from 'components/AddContent/Menu/styled';
import { StyledList } from 'components/ProfileSettings/Menu/styled';
import { useDeleteFeed } from 'graphQL/feeds/hooks';
import { ROUTE_EDIT_FEED } from 'routes';
import useOpenModal from 'helpers/useOpenModal';
import { ChannelSectionItemsSortTypeOptions } from 'constants/graphqlTypes';

interface ICustomFeedMenu {
  title: string;
  feedId: string;
  handleClose: () => void;
  sectionId: string;
  sorting?: ChannelSectionItemsSortTypeOptions;
}

const CustomFeedMenu: FC<ICustomFeedMenu> = ({ title, feedId, handleClose, sectionId, sorting }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { deleteFeed } = useDeleteFeed(feedId, sectionId, sorting);
  const gotoEditFeed = useOpenModal(ROUTE_EDIT_FEED, false, { feedId, title });

  const onCloseModal = () => {
    deleteFeed();
    setShowDeleteModal(false);
    handleClose();
  };

  return (
    <Modal fadeIn handleClose={handleClose}>
      <StyledList>
        <StyledItem>
          <Button text="Edit feed" Icon={UserIcon} handler={gotoEditFeed} />
        </StyledItem>
        <StyledItem>
          <Button isRed text="Delete" Icon={TrashIcon} handler={() => setShowDeleteModal(true)} />
        </StyledItem>
      </StyledList>
      {showDeleteModal && feedId && (
        <ConfirmationModal
          handleAccept={onCloseModal}
          buttonText="Delete this feed"
          handleClickOutside={() => setShowDeleteModal(false)}
          subTitle="This will permanently delete your feed."
          titleText="Delete this feed"
          isWarning
          handleCancel={() => setShowDeleteModal(false)}
        />
      )}
    </Modal>
  );
};

export default CustomFeedMenu;
