import { SearchChatUnionSchema } from 'constants/graphqlTypes';
import React, { FC } from 'react';
import LoaderContent from 'components/UI/LoaderContent';
import { Waypoint } from 'react-waypoint';

import { StyledNoContent } from 'components/List/Content/styled';
import SingleUserItem from './ListItems/SingleUserItem';
import { StyledList, StyledListItem } from './styled';
import GroupChatItem from './ListItems/GroupChatItem';

interface IChatListProps {
  items: SearchChatUnionSchema[];
  loadMore: () => void;
  loading: boolean;
}

const ChatList: FC<IChatListProps> = ({ items, loadMore, loading }) => (
  <StyledList>
    {items.map((item) => (
      <StyledListItem key={item.id}>
        {item.__typename === 'SingleChatSchema' && item?.member?.user && (
          <SingleUserItem user={item.member.user} chatId={item.id} />
        )}
        {item.__typename === 'UserShortSchema' && !!item.id && <SingleUserItem user={item} />}
        {item.__typename === 'GroupChatSchema' && !!item.members?.items.length && <GroupChatItem item={item} />}
      </StyledListItem>
    ))}
    {!items.length && !loading && (
      <StyledListItem>
        <StyledNoContent isDark>You don’t have any active conversations.</StyledNoContent>
      </StyledListItem>
    )}
    {loading ? <LoaderContent isFullScreen={!items.length} /> : <Waypoint onEnter={loadMore} />}
  </StyledList>
);

export default ChatList;
