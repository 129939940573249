import React, { FC, useEffect, useMemo } from 'react';
import { v4 } from 'uuid';

import { CardSchema, EnabledUserCoverSchema, Maybe } from 'constants/graphqlTypes';

import Grain from 'components/UI/Grain';
import StyledAvatar from 'components/UI/Avatar/styled';
import { useIsUserAllowedToReact, useItemUrl } from 'components/Card/helpers/hooks';
import { getListStyles } from 'components/Collection/helpers';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import InteractionBlock from 'components/InteractionBlock';
import ReactionsModal from 'components/Reactions/ReactionsModal';
import Collaborators from 'components/Collection/Collaborators';
import { ReactionsProvider, useReactionContextState } from 'components/Reactions/hooks';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';

import getAuthUser from 'helpers/getAuthUser';
import useTheme from 'helpers/useTheme';
import usePageType from 'helpers/usePageType';

import { COLORS } from 'styles/constants';
import { StyledList, StyledListItem } from 'components/UI/UrlAvatars/styled';

import {
  StyledBottom,
  StyledDescription,
  StyledImageItem,
  StyledImagesList,
  StyledInner,
  StyledLink,
  StyledReactionWrap,
  StyledTextItem,
  StyledTextsList,
  StyledTextWrap,
  StyledTitle,
  StyledWrap,
} from './styled';

interface ISmallCardProps {
  item: CardSchema;
  counterUnreadPicks?: number | null;
  enabledUserCovers?: Maybe<EnabledUserCoverSchema>[] | null;
  dataTestId?: string;
  disableLink?: boolean;
  isReplace?: boolean;
  showCreators?: boolean;
  hideFooter?: boolean;
  fullWidth?: boolean;
}

const TileSmall: FC<ISmallCardProps> = ({
  item,
  counterUnreadPicks,
  enabledUserCovers,
  dataTestId,
  disableLink,
  isReplace,
  fullWidth,
}) => {
  const { id, title, isCollaborative, creators, description } = item;

  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  const isDark = useTheme();
  const isUserAllowedToReact = useIsUserAllowedToReact(creators?.items[0]?.user);
  const { thoughtId, setThoughtId, pickerId, setPickerId, cardId, setCardId } = useReactionContextState();
  const { isInvite, isMessages } = usePageType();
  const url = useItemUrl(id, true);
  const { userId } = getAuthUser();
  const { images, titles, bgColor } = getListStyles(item);
  const isCurrentUserList = !!creators?.items?.find((data) => data?.user?.id === userId)?.user?.id;
  const isDisableReaction =
    isCurrentUserList || isCollaborative || isInvite || isMessages || !isUserAllowedToReact || disableLink;

  const titlesWithKey = useMemo(
    () =>
      titles.map((cover) => ({
        cover,
        key: v4(),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [titles.length, titles.join(','), v4]
  );

  useEffect(() => {
    setCardId(id);
    setPickerId(creators?.items?.[0]?.user?.id ?? '');
  }, []);

  return (
    <ReactionsProvider
      value={{
        isQuickReactionOpen,
        setThoughtId,
        thoughtId,
        setPickerId,
        pickerId,
        cardId,
        setCardId,
      }}
    >
      <StyledReactionWrap data-testid={dataTestId} ref={infoRef} disableLinks={isQuickReactionOpen || disableLink}>
        <InteractionBlock
          tapCallback={() => setIsQuickReactionOpen(false)}
          longTapCallback={() => !isDisableReaction && setIsQuickReactionOpen(true)}
          getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
        >
          <div>
            <StyledLink
              onClick={(e) => {
                if (disableLink) {
                  e.preventDefault();
                }
              }}
              className="card collection-preview-anchor"
              replace={isReplace}
              to={url}
            >
              <StyledWrap bgColor={bgColor} fullWidth={fullWidth} isDark={isDark} counter={counterUnreadPicks}>
                <StyledInner>
                  <Grain />
                  {!!images.length && (
                    <StyledImagesList isMultiple={images.length >= 4} fullWidth={fullWidth}>
                      {images.length >= 4 &&
                        images
                          .slice(0, 4)
                          .map((image) => <StyledImageItem key={image.id} src={image?.images?.[0]?.url} />)}
                      {images.length < 4 && <StyledImageItem key={images[0]?.id} src={images[0]?.images?.[0]?.url} />}
                    </StyledImagesList>
                  )}
                  {!!titlesWithKey.length && (
                    <StyledTextWrap bgColor={bgColor}>
                      <StyledTextsList bgColor={bgColor}>
                        {titlesWithKey.map(({ cover, key }, i) => (
                          <StyledTextItem
                            className="cover-text-item"
                            key={key}
                            opacity={1 - (1 / titlesWithKey.length) * i}
                            color={COLORS.white[100]}
                          >
                            {cover}
                          </StyledTextItem>
                        ))}
                      </StyledTextsList>
                    </StyledTextWrap>
                  )}
                  <StyledBottom>
                    {enabledUserCovers ? (
                      <StyledList>
                        {enabledUserCovers.map((userCover) => (
                          <StyledListItem key={userCover?.imageSet?.originalUrl}>
                            <StyledAvatar
                              dimension={20}
                              src={userCover?.imageSet?.images?.[0]?.url}
                              isGreenBorder={userCover?.isNotViewed}
                              className="avatar"
                              color={COLORS.white[100]}
                            />
                          </StyledListItem>
                        ))}
                      </StyledList>
                    ) : (
                      <Collaborators item={item} isSmall />
                    )}
                    <StyledTitle isDark={!!images.length || !!titlesWithKey.length}>{title}</StyledTitle>
                    {description && (
                      <StyledDescription isDark={!!images.length || !!titlesWithKey.length}>
                        {description}
                      </StyledDescription>
                    )}
                  </StyledBottom>
                </StyledInner>
              </StyledWrap>
            </StyledLink>
          </div>
        </InteractionBlock>
        {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && !isDisableReaction && (
          <ReactionMenu
            isShown={isQuickReactionOpen}
            reactionCoordinates={reactionCoordinates}
            handleClickOutside={() => setIsQuickReactionOpen(false)}
            minusTop={minusTop}
            setIsReactionsModalOpen={setIsReactionsModalOpen}
            setIsQuickReactionOpen={setIsQuickReactionOpen}
          />
        )}
        {isReactionsModalOpen && <ReactionsModal id={id} handleClose={() => setIsReactionsModalOpen(false)} />}
      </StyledReactionWrap>
    </ReactionsProvider>
  );
};

export default TileSmall;
