export const groupItems = <T>(cards: T[], numberOfCardsPerSlide = 2): T[][] => {
  const slides = cards.reduce((acc: T[][], card: T) => {
    if (acc[acc.length - 1]?.length < numberOfCardsPerSlide) {
      acc[acc.length - 1].push(card);
    } else {
      acc.push([card]);
    }
    return acc;
  }, []);

  return slides;
};
