import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MutationFunction } from '@apollo/client';

import useDebouncedValue from 'helpers/useDebounced';
import getAuthUser from 'helpers/getAuthUser';

import { useGetUserId } from 'graphQL/profile/hooks';
import { useMessagesTabs } from 'components/Messages/helpers/hooks';
import { CHAT_MESSAGES_LIMIT, EChatField } from 'graphQL/messages/constants';
import { EMPTY_ITEMS, EQueryFetchPolicy, REACTIONS_NUMBER_STEP } from 'constants/common';
import {
  ChatType,
  ChatUnionSchema,
  useCreateChatMutation,
  useGetAllChatsQuery,
  useGetMessagesQuery,
  useGetSingleChatQuery,
  useDeleteChatMutation,
  useReadMessageMutation,
  DeleteChatMutationVariables,
  DeleteChatMutation,
  useSendMessageMutation,
  useChangeTypingStatusMutation,
  EventTypeEnum,
  useDeleteMessageMutation,
  useGetUnreadChatsNotificationQuery,
  GetUnreadChatsNotificationDocument,
  useSearchChatsQuery,
  ChatInput,
  useUpdateGroupChatMutation,
  useLeaveGroupChatMutation,
  useBlockChatMutation,
  useUnblockChatMutation,
  useSendContentMutation,
  MessageItemType,
  useGetMessageContentQuery,
  useAddGroupChatMemberMutation,
  useGetUserBackgroundColorQuery,
  useRemoveReactionFromMessageMutation,
  UserShortSchema,
  useGetChatNotificationsSettingsQuery,
  useUpdateChatNotificationsSettingsMutation,
  useGetGroupChatMembersQuery,
  useGetUserNameQuery,
  GetGroupChatMembersDocument,
  useGetChatMessageReactionsQuery,
  useUpdateChatViewedAtMutation,
  useGetChatIdQuery,
  UserObjectType,
  useGetUpdateUserDataQuery,
  GetChatMessageReactionsDocument,
  GetChatMessageReactionsQuery,
  GetChatMessageReactionsQueryVariables,
} from 'constants/graphqlTypes';
import getStyles from 'helpers/getStyles';
import useMessagesThreadContext from 'helpers/useMessagesThreadProvider';
import { COLORS } from 'styles/constants';
import {
  IProfileMessageData,
  ISearchedChats,
  IUseAllChats,
  IUseCreateNewChat,
  IUseGetMessageContent,
  IUseGetMessageContentArgs,
  IUseMessages,
  IUseSendContent,
  IUseRemoveMessageReactionArgs,
  IGetChatMessageReactions,
  IUseSendContentArgs,
} from './models';
import { deleteChatFromCache, groupMessages, updateChats, updateMessages, updateReadMessage } from './helpers';

export const useAllChats = (): IUseAllChats => {
  const { tab } = useMessagesTabs();

  const { data, loading, fetchMore } = useGetAllChatsQuery({
    variables: { offset: 0, tab },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const { items, totalCount } = data?.allChats ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateChats(EChatField.AllChats, prev, fetchMoreResult),
    });

  return { items, totalCount, loading, loadMore };
};

export const useSingleChat = (id: string): ChatUnionSchema | undefined => {
  const { data } = useGetSingleChatQuery({ variables: { id }, skip: !id });

  return data?.allChats?.items[0];
};

export const useGetGroupChatMembers = (id: string, membersLimit?: number) => {
  const { data, loading, fetchMore } = useGetGroupChatMembersQuery({
    variables: { id, membersLimit, membersOffset: 0 },
  });

  const members =
    data?.allChats?.items[0].__typename === 'GroupChatSchema'
      ? data?.allChats?.items[0].members ?? EMPTY_ITEMS
      : EMPTY_ITEMS;
  const { items, totalCount } = members;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { membersOffset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        allChats: {
          ...prev.allChats,
          totalCount: prev?.allChats?.totalCount ?? 0,
          items: [
            {
              ...(prev?.allChats?.items[0]?.__typename === 'GroupChatSchema' &&
              fetchMoreResult?.allChats?.items[0]?.__typename === 'GroupChatSchema'
                ? {
                    ...prev?.allChats?.items[0],
                    members: {
                      ...prev.allChats.items[0].members,
                      items: [
                        ...(prev.allChats.items[0].members?.items ?? []),
                        ...(fetchMoreResult.allChats.items[0].members?.items ?? []),
                      ],
                      totalCount: prev.allChats.items[0].members?.totalCount ?? 0,
                    },
                  }
                : prev?.allChats?.items[0]),
            },
          ],
        },
      }),
    });

  return {
    members: items,
    loading,
    loadMore,
  };
};

export const useMessages = (chatId: string): IUseMessages => {
  const { data, loading, fetchMore, error } = useGetMessagesQuery({
    variables: { offset: 0, chatId, limit: CHAT_MESSAGES_LIMIT },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });
  const { items, totalCount } = data?.allMessages ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        allMessages: {
          ...prev.allMessages,
          items: [...(prev?.allMessages?.items ?? []), ...(fetchMoreResult?.allMessages?.items ?? [])],
          totalCount: prev?.allMessages?.totalCount ?? 0,
        },
      }),
    });

  return { items: groupMessages(items), totalCount, loading, loadMore, error };
};

export const useCreateNewChat = (
  handleNext: (id: string) => void,
  onError?: Dispatch<SetStateAction<boolean>>
): IUseCreateNewChat => {
  const [mutation, data] = useCreateChatMutation();

  const createChat = (userIds: string[], chatType: ChatType) =>
    mutation({
      variables: {
        userIds,
        chatType,
      },
      onCompleted: ({ startChat }) => {
        if (startChat?.chat.id) {
          handleNext(startChat?.chat.id);
        }
      },
      onError: () => onError?.(true),
    });

  return { createChat, data };
};

export const useReadMessage = (id?: string): (() => void) => {
  const [mutation] = useReadMessageMutation({
    variables: { id: id ?? '' },
    refetchQueries: [{ query: GetUnreadChatsNotificationDocument }],
    update: (cache) => updateReadMessage(id ?? '', cache),
  });

  const read = () => {
    if (id) {
      mutation();
    }
  };

  return read;
};

export const useUpdateChatViewedAt = (chatId: string) => {
  const [mutation] = useUpdateChatViewedAtMutation({ variables: { chatId } });

  return () => mutation();
};

export const useDeleteMessage = (messageId: string, onCompleted?: () => void): (() => void) => {
  const [mutation] = useDeleteMessageMutation({
    variables: {
      messageId,
    },
    onCompleted,
  });

  return () => mutation();
};

export const useDeleteChat = (chatId: string): MutationFunction<DeleteChatMutation, DeleteChatMutationVariables> => {
  const [deleteChat] = useDeleteChatMutation({
    variables: { chatId },
    update: (cache, { data }) => data?.deleteChat?.status && deleteChatFromCache(chatId, cache),
    refetchQueries: [{ query: GetUnreadChatsNotificationDocument }],
  });

  return deleteChat;
};

export const useLeaveGroupChat = (chatId: string, onCompleted?: () => void): (() => void) => {
  const [leaveChat] = useLeaveGroupChatMutation({
    variables: { chatId },
    onCompleted,
    update: (cache, { data }) => data?.leaveChat?.status && deleteChatFromCache(chatId, cache),
    refetchQueries: [{ query: GetUnreadChatsNotificationDocument }],
  });

  return () => leaveChat();
};

export const useSendMessage = (): ((text: string) => void) => {
  const { id } = useParams<{ id: string }>();

  const [mutation] = useSendMessageMutation({
    update: (cache, { data }) => {
      if (data?.sendMessage) {
        updateMessages(id, data.sendMessage, cache);
      }
    },
  });

  return (text: string) => mutation({ variables: { id, text } });
};

export const useSendContent = ({
  chatId,
  userId,
  handleComplete,
  profileGradientStart,
  profileGradientEnd,
}: IUseSendContentArgs): IUseSendContent => {
  const [mutation] = useSendContentMutation({
    update: (cache, { data }) => {
      if (data?.sendMessage && chatId) {
        updateMessages(chatId, data.sendMessage, cache);
      }
    },
  });

  const [createChat] = useCreateChatMutation();

  return async (entityId, messageType, commentCardId) => {
    let createdChatId;

    if (userId && !chatId) {
      const createdChat = await createChat({ variables: { userIds: [userId], chatType: ChatType.Single } });
      createdChatId = createdChat?.data?.startChat?.chat.id;
    }

    const id = chatId ?? createdChatId;
    const dataToSend = JSON.stringify({
      bgColor: { gradientStart: profileGradientStart, gradientEnd: profileGradientEnd },
    });

    if (id) {
      mutation({
        variables: {
          id,
          entityId,
          messageType,
          ...(commentCardId && { pickId: commentCardId }),
          data: dataToSend,
        },
        onCompleted: () => handleComplete?.(id),
      });
    }
  };
};

export const useTyping = (text: string): (() => void) => {
  const [isTyping, setIsTyping] = useState(false);

  const { id } = useParams<{ id: string }>();

  const debounced = useDebouncedValue(text, 1000);

  const [mutation] = useChangeTypingStatusMutation();

  const endTyping = useCallback(() => {
    mutation({ variables: { status: EventTypeEnum.TypingEnd, id } });
    setIsTyping(false);
  }, [id, mutation]);

  useEffect(() => {
    if (!isTyping && text) {
      mutation({ variables: { status: EventTypeEnum.TypingStart, id } });
      setIsTyping(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    endTyping();
  }, [debounced, endTyping]);

  return endTyping;
};

export const useUnreadedMessages = (): number => {
  const { username } = getAuthUser();
  const { data } = useGetUnreadChatsNotificationQuery({
    skip: !username,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  return data?.allChats?.totalCount ?? 0;
};

export const useSearchChat = (searchString: string, collectionId?: string): ISearchedChats => {
  const { data, loading, fetchMore } = useSearchChatsQuery({
    variables: { searchString, offset: 0, withOnlyFollowings: !searchString && !collectionId, collectionId },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const { items, totalCount } = data?.searchChats ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateChats(EChatField.SearchChats, prev, fetchMoreResult),
    });

  return { items, loading, loadMore };
};

export const useRemoveMessageReaction = (
  chatId?: string,
  messageId?: string
): {
  removeMessageReactionHandler: (args: IUseRemoveMessageReactionArgs) => void;
} => {
  const [removeReaction] = useRemoveReactionFromMessageMutation();

  const removeMessageReactionHandler = ({ reactionId }: IUseRemoveMessageReactionArgs) => {
    removeReaction({
      variables: { reactionId },
      update: (cache) => {
        if (!chatId || !messageId) {
          return;
        }

        cache.updateQuery<GetChatMessageReactionsQuery, GetChatMessageReactionsQueryVariables>(
          {
            query: GetChatMessageReactionsDocument,
            variables: {
              chatId,
              messageId,
              reactionsLimit: REACTIONS_NUMBER_STEP,
              reactionsOffset: undefined,
            },
          },
          (prev) => {
            if (!prev?.allMessages?.items?.[0]?.reactions) {
              return prev;
            }

            return {
              ...prev,
              allMessages: {
                ...prev.allMessages,
                items: [
                  {
                    ...prev.allMessages.items[0],
                    reactions: {
                      ...prev.allMessages.items[0].reactions,
                      items: prev.allMessages.items[0].reactions.items.filter((reaction) => reaction.id !== reactionId),
                    },
                  },
                ],
              },
            };
          }
        );
      },
    });
  };

  return { removeMessageReactionHandler };
};

export const useTypingTitle = (
  userIds: string[],
  members?: UserShortSchema[] | null
): { typingTitle: string; typingName: string } => {
  const [typingTitle, setTypingTitle] = useState('');
  const [typingName, setTypingName] = useState('');

  const user =
    members?.reduce((users: UserShortSchema[], member) => {
      if (member && userIds.includes(member.id)) {
        users.push(member);
      }
      return users;
    }, []) ?? [];

  const { username, firstName } = user?.[0] || {};

  const name = firstName ?? username;

  useEffect(() => {
    if (userIds.length) {
      setTypingTitle(userIds.length > 1 ? 'several people are typing...' : `is typing...`);
      setTypingName(name && userIds.length === 1 ? name : '');
    } else {
      setTypingName('');
      setTypingTitle('');
    }
  }, [userIds, name]);

  return { typingTitle, typingName };
};

export const useUpdateGroupChat = (chatId: string, chatData: ChatInput, onCompleted?: () => void): (() => void) => {
  const [updateChat] = useUpdateGroupChatMutation({
    variables: { chatData, chatId },
    onCompleted,
    update: (cache, { data: updatedChat }) => {
      const newChat = updatedChat?.updateChat;

      if (newChat?.__typename === 'GroupChatSchema') {
        cache.modify({
          id: cache.identify({ id: newChat?.id, __typename: 'GroupChatSchema' }),
          fields: {
            title: () => newChat.title,
          },
        });
      }
    },
  });

  return () => updateChat();
};

export const useBlockChat = (chatId: string, isBlockedByMe: boolean): (() => void) => {
  const [blockChat] = useBlockChatMutation({ variables: { chatId } });

  const [unblockChat] = useUnblockChatMutation({ variables: { chatId } });

  return () => (isBlockedByMe ? unblockChat() : blockChat());
};

export const useGetMessageContent = ({
  itemType,
  entityId,
  pickId,
  contentReactionId,
  fetchPolicy,
}: IUseGetMessageContentArgs): IUseGetMessageContent => {
  const { scrollThreadContent } = useMessagesThreadContext();

  const { data } = useGetMessageContentQuery({
    skip: !entityId,
    variables: {
      itemType,
      ...((itemType === MessageItemType.Pick || itemType === MessageItemType.Collection) && { pickId: entityId }),
      ...(itemType === MessageItemType.Comment && { commentId: entityId, pickId }),
      ...(itemType === MessageItemType.User && { userId: entityId }),
      ...(itemType === MessageItemType.GuestListSeries && { guestListSeriesId: entityId }),
      ...(itemType === MessageItemType.GuestList && { guestListId: entityId }),
      ...(itemType === MessageItemType.Invite && { inviteId: entityId, pickId }),
      ...(itemType === MessageItemType.Spark && { sparkId: entityId }),
      contentReactionId,
    },
    fetchPolicy,
    onCompleted: () => scrollThreadContent?.(),
  });
  const { messageContent, messageContentStatus } = data?.getMessageContent ?? {};

  return {
    messageContentStatus,
    ...(messageContent?.__typename === 'PickMessageContent' && { card: messageContent?.card }),
    ...(messageContent?.__typename === 'CollectionMessageContent' && { list: messageContent?.collection }),
    ...(messageContent?.__typename === 'UserMessageContent' && { user: messageContent?.user }),
    ...(messageContent?.__typename === 'CardCommentMessageContent' && { comment: messageContent?.comment }),
    ...(messageContent?.__typename === 'CardCommentMessageContent' && {
      reactions: messageContent?.comment?.reactions,
    }),
    ...(messageContent?.__typename === 'CardCommentMessageContent' && { commentList: messageContent?.collection }),
    ...(messageContent?.__typename === 'GuestListSeriesMessageContent' && {
      guestListSeries: messageContent?.guestListSeries,
    }),
    ...(messageContent?.__typename === 'GuestListMessageContent' && { guestList: messageContent?.guestList }),
    ...(messageContent?.__typename === 'InviteMessageContent' && {
      collection: messageContent?.collection,
      invitation: messageContent?.invitation,
    }),
    ...(messageContent?.__typename === 'SparkMessageContent' && {
      spark: messageContent.spark,
    }),
  };
};

interface IAddGroupMembersData {
  addMembers: () => void;
  loading: boolean;
}
export const useAddGroupMembers = (
  chatId: string,
  userIds: string[],
  onCompleted: () => void,
  onError: () => void
): IAddGroupMembersData => {
  const [addMembers, { loading }] = useAddGroupChatMemberMutation({
    variables: { chatId, userIds },
    refetchQueries: [
      { query: GetGroupChatMembersDocument, variables: { id: chatId, membersLimit: 10, membersOffset: 0 } },
    ],
    onError,
    onCompleted,
  });

  return {
    addMembers,
    loading,
  };
};

export const useGetProfileMessage = (userId: string): IProfileMessageData => {
  const { user } = useGetMessageContent({ itemType: MessageItemType.User, entityId: userId });
  const { data } = useGetUserBackgroundColorQuery({ variables: { userId } });
  const { spotlights, pickedCards } = data?.allUsers?.items[0] ?? {};

  const { userCounters } = user ?? {};
  const { connectionsCount } = userCounters ?? {};
  const { totalCount: picksCount } = pickedCards ?? {};
  const card = spotlights?.[0] ?? pickedCards?.items[0];

  const { gradientStart, gradientEnd } = getStyles(card?.cardStyle);
  const fontColor = card ? COLORS.white[100] : COLORS.brown.dark[100];

  return {
    gradientStart,
    gradientEnd,
    fontColor,
    picksCount: picksCount ?? 0,
    connectionsCount: connectionsCount ?? 0,
    user,
  };
};

export const useChatNotifications = (chatId: string) => {
  const { data } = useGetChatNotificationsSettingsQuery({ variables: { chatId } });

  const [mutation] = useUpdateChatNotificationsSettingsMutation();

  return {
    notificationSettings: data?.chatMemberNotificationsSettings?.items ?? [],
    updateNotificationSettings: (notificationSettingId: string) => mutation({ variables: { notificationSettingId } }),
  };
};

export const useGetUserById = (id?: string | null, skip?: boolean): UserObjectType | undefined => {
  const { data } = useGetUserNameQuery({ variables: { id }, skip });
  return data?.allUsers?.items[0];
};

export const useGetUserByUsername = (username: string, skip?: boolean): UserObjectType | undefined => {
  const { userId: id } = useGetUserId(username);
  const { data } = useGetUpdateUserDataQuery({ variables: { id: id ?? '' }, skip: skip || !id });

  return data?.allUsers?.items[0];
};

export const useGetChatMessageReactions = (
  chatId: string,
  messageId: string,
  skip?: boolean
): IGetChatMessageReactions => {
  const { data, loading, fetchMore, refetch } = useGetChatMessageReactionsQuery({
    variables: { chatId, messageId, reactionsOffset: undefined, reactionsLimit: REACTIONS_NUMBER_STEP },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    skip: skip || !chatId || !messageId,
  });

  const reactions = data?.allMessages?.items?.[0]?.reactions;
  const fetchedReactions = reactions?.items || [];
  const totalCount = reactions?.totalCount ?? 0;

  const loadMoreReactions = () =>
    fetchedReactions.length < totalCount &&
    fetchMore({
      variables: { offset: fetchedReactions.length, reactionsLimit: fetchedReactions.length + REACTIONS_NUMBER_STEP },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...prev,
          ...fetchMoreResult,
        };
      },
    });

  const refetchReactions = () => refetch();

  return {
    reactions,
    loading,
    loadMoreReactions,
    refetchReactions,
  };
};

export const useChatId = (userId?: string | null): { loading: boolean; chatId?: string } => {
  const { data, loading } = useGetChatIdQuery({
    skip: !userId,
    variables: { userId, offset: 0 },
  });

  const chatId = data?.allChats?.items?.filter((i) => i.__typename === 'SingleChatSchema')[0]?.id;

  return { chatId, loading };
};
