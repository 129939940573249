import React, { FC } from 'react';
import Animation from './Animation';

import { useFullScreenAnimationContext } from './hooks';

const Animations: FC = () => {
  const { animations } = useFullScreenAnimationContext() ?? {};

  return (
    <>
      {animations?.map(({ emoji, coords, id }) => (
        <Animation key={id} emoji={emoji} coords={coords} duration={1500} id={id} />
      ))}
    </>
  );
};

export default Animations;
