import {
  DATA_WORD_INDEX_ATTR,
  LETTER_ANIMATION_TIME,
  LETTER_VISIBLE_ANIMATION_TIME,
  LINE_HEIGHT,
  TRUNCATE_INNER_ID,
  getTruncateWrapId,
} from './constants';

import { IWordCharacterData } from './models';

/**
 * Calculates the line number based on the top position of the child element relative to the parent element.
 *
 * @param parentEl - The parent element.
 * @param childEl - The child element.
 * @param lineHeight - The height of a single line. Default value is LINE_HEIGHT.
 * @returns The line number or undefined if the parent element is not found.
 */
export const calculateLineBasedOnTop = (
  parentEl: Element | null,
  childEl: Element,
  lineHeight: number = LINE_HEIGHT
) => {
  const parentTop = parentEl?.getBoundingClientRect().top;
  const childTop = childEl.getBoundingClientRect().top;

  return parentTop ? Math.floor((childTop - parentTop) / lineHeight) : undefined;
};

/**
 * Retrieves the character data for each word in the specified element.
 * @param elementId - The ID of the element containing the words.
 * @returns An array of `IWordCharacterData` objects, each representing a word and its associated character data.
 */
export const getWordCharacterData = (elementId: string): IWordCharacterData[] => {
  const wrap = document.querySelector(`#${getTruncateWrapId(elementId)} #${TRUNCATE_INNER_ID}`);

  const charsWithWordIndexes = [
    ...document?.querySelectorAll(`#${getTruncateWrapId(elementId)} [${DATA_WORD_INDEX_ATTR}]`),
  ];
  const charsGroupedIntoWordsByWordIndex = charsWithWordIndexes.reduce((acc: Element[][], char: Element) => {
    const wordIndex = parseInt(char.getAttribute(DATA_WORD_INDEX_ATTR) ?? '0', 10);
    const prevWord = acc[acc.length - 1];

    const currCharLine = calculateLineBasedOnTop(wrap, char, LINE_HEIGHT);
    const prevCharLine =
      prevWord?.length &&
      prevWord[prevWord?.length - 1] &&
      calculateLineBasedOnTop(wrap, prevWord[prevWord?.length - 1], LINE_HEIGHT);

    const prevWordIndex = prevWord && parseInt(prevWord[0].getAttribute(DATA_WORD_INDEX_ATTR) ?? '0', 10);
    const isFirstWord = acc[acc.length - 1] === undefined;

    // If the current character is the first character of the first word,
    // or the previous wordIndex is different from the current wordIndex,
    // or the line of the previous character is different from the current character,
    //  then create a new word.
    if (isFirstWord || (prevWord && prevWordIndex !== wordIndex) || prevCharLine !== currCharLine) {
      acc.push([char]);
    } else if (prevWord && prevWordIndex === wordIndex) {
      // Otherwise, add the character to the previous word.
      acc[acc.length - 1].push(char);
    }

    return acc;
  }, []);

  return charsGroupedIntoWordsByWordIndex.map((word) => {
    const wordDelay = word.length * LETTER_ANIMATION_TIME;
    const wordVisibleDelay = word.length * LETTER_VISIBLE_ANIMATION_TIME;
    const wordLine = calculateLineBasedOnTop(wrap, word[0], LINE_HEIGHT);

    return {
      wordLine,
      chars: word,
      wordDelay,
      wordVisibleDelay,
    };
  });
};

/**
 * Calculates the total line delays for the given word elements.
 *
 * @param wordElements - An array of word character data.
 * @returns An array of total line delays for each word line.
 */
export const getTotalLineDelays = (wordElements: IWordCharacterData[], isVisibleAnimation?: boolean) =>
  wordElements.reduce(
    (acc: number[], { wordDelay, wordVisibleDelay, wordLine }) => {
      const delay = isVisibleAnimation ? wordVisibleDelay : wordDelay;
      if (wordLine !== undefined) {
        const updatedAcc = [...acc];
        updatedAcc[wordLine] = updatedAcc[wordLine] === undefined ? delay : updatedAcc[wordLine] + delay;
        return updatedAcc;
      }
      return acc;
    },
    [0]
  );

/**
 * Calculates the delay for a specific visible line in a thought animation.
 *
 * @param totalDelays - An array of total delays for each line.
 * @param wordLine - The line number for which to calculate the delay.
 * @returns The delay for the specified visible line.
 */
export const getVisibleLineDelay = (totalDelays: number[], wordLine: number) => {
  const delaysCopy = totalDelays.map((el, ind) => (ind === 0 ? 0 : el));
  if (wordLine === 0 || wordLine === 1) {
    return 0;
  }
  return delaysCopy.slice(0, wordLine).reduce((prev, curr) => prev + (curr / 2 || 0), 0);
};

/**
 * Calculates the delay for a filled line in a thought animation.
 *
 * @param totalLineDelays - An array of delays for each line in the animation.
 * @param wordLine - The line number of the current word.
 * @returns The delay for the filled line.
 */
export const getFilledLineDelay = (totalLineDelays: number[], wordLine: number) =>
  [...totalLineDelays].slice(0, wordLine).reduce((prev, curr) => prev + curr, 0) || 0;
