import React, { FC, useState } from 'react';
import { StyledEmoji } from './styled';

const Animation: FC<{ emoji: string; coords: { x: number; y: number }[]; id: string; duration: number }> = ({
  emoji,
  coords,
  duration,
  id,
}) => {
  const [wasNotPlayed, setWasNotPlayed] = useState(true);

  setTimeout(() => {
    setWasNotPlayed(false);
  }, duration);

  const getKey = (val: number) => `${id}-${val}`;

  return wasNotPlayed ? (
    <>
      {coords.map(({ x, y }, index) => (
        <StyledEmoji xCoord={x} yCoord={y} key={getKey(index)}>
          {emoji}
        </StyledEmoji>
      ))}
    </>
  ) : null;
};

export default Animation;
