import { styled } from 'linaria/react';

import { BREAKPOINTS, FONTS, COLORS } from '../../../styles/constants';
import { IInputStyle } from './helpers/helpers';

export const StyledTextarea = styled.textarea<{ isDark: boolean; inputStyle: IInputStyle; disabled?: boolean }>`
  background-color: ${COLORS.transparent};
  border: none;
  caret-color: ${({ isDark }) => (isDark ? COLORS.white.off[100] : COLORS.brown.dark[100])};
  color: ${({ inputStyle }) => inputStyle.color};
  font: ${({ inputStyle: { fontWeight, fontSize, lineHeight, fontFamily } }) =>
    `${fontWeight} ${fontSize}/${lineHeight} ${fontFamily}`};
  margin: ${({ inputStyle }) => inputStyle.margin};
  outline: none;
  overflow-y: auto;
  overscroll-behavior: none;
  padding: ${({ inputStyle }) => inputStyle.padding};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  resize: none;
  scrollbar-color: ${COLORS.brown.light[100]} ${COLORS.brown.dark[100]};
  scrollbar-width: thin;
  width: 100%;

  &::placeholder {
    color: ${({ inputStyle }) => inputStyle.placeholderColor};
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &:hover,
  &:focus {
    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: ${COLORS.white[30]};
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(${({ inputStyle }) => inputStyle.fontSize} * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledCounter = styled.span<{ isDark: boolean; isLengthLimitReached?: boolean }>`
  color: ${({ isDark, isLengthLimitReached }) => {
    if (isLengthLimitReached) {
      return COLORS.red[100];
    }
    return isDark ? COLORS.brown.light[100] : COLORS.brown.dark[100];
  }};
  font-family: ${FONTS.secondary};
  font-size: 12px;
  line-height: 140%;

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(12px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledError = styled.span`
  color: ${COLORS.red[100]};
  font-family: ${FONTS.secondary};
  font-size: 12px;
  line-height: 140%;

  a {
    color: ${COLORS.red[100]};
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(12px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledHint = styled(StyledError)<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.brown.light[100] : COLORS.brown.dark[100])};
  display: block;
`;
