import React, { FC, useRef, useState, useEffect } from 'react';

import ConfirmationModal from 'components/UI/ConfirmationModal';
import useTheme from 'helpers/useTheme';
import useClickOutside from 'helpers/useClickOutside';
import { useSignOut } from 'graphQL/profile/hooks';

import { ROUTE_DISCOVERY } from 'routes';
import { TEST_BURGER_MENU_CLOSE_BTN, TEST_BURGER_MENU_LOG_OUT_BTN } from 'constants/aqa/burgerMenu';
import { getCrossIcon } from '../../../helpers/helpers';
import { StyledSignOut, StyledSection, StyledButton, StyledCloseMenuButtonWrapper } from './styled';

import List from './List';

interface IPanelProps {
  handleClose: () => void;
  hasActivity: boolean;
  hasAbout: boolean;
  isShown: boolean;
}

const Panel: FC<IPanelProps> = ({ handleClose, isShown, hasAbout, hasActivity }) => {
  const [isSignOut, setIsSignOut] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  const isDark = useTheme();
  const signOut = useSignOut(ROUTE_DISCOVERY);

  const handleCancel = () => {
    handleClose();
    setIsSignOut(false);
  };

  useClickOutside(ref, handleClose);

  useEffect(() => {
    if (isShown) {
      document.body.classList.add('fixed');
    } else {
      document.body.classList.remove('fixed');
    }

    return () => {
      document.body.classList.remove('fixed');
    };
  }, [isShown]);

  return (
    <>
      <StyledSection ref={ref} isDark={isDark} isShown={isShown}>
        <StyledCloseMenuButtonWrapper onClick={handleClose}>
          <StyledButton data-testid={TEST_BURGER_MENU_CLOSE_BTN} icon={getCrossIcon(isDark)} />
        </StyledCloseMenuButtonWrapper>
        <List hasActivity={hasActivity} hasAbout={hasAbout} handleClose={handleClose} />
        <StyledSignOut data-testid={TEST_BURGER_MENU_LOG_OUT_BTN} isDark={isDark} onClick={() => setIsSignOut(true)}>
          Log out
        </StyledSignOut>
      </StyledSection>
      {isSignOut && (
        <ConfirmationModal
          handleCancel={handleCancel}
          handleAccept={signOut}
          buttonText="log out"
          titleText="log out"
        />
      )}
    </>
  );
};

export default Panel;
