import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import AddSparkPage from 'components/Sparks/Views/AddSpark';
import AddSparkStylePage from 'components/Sparks/Views/AddSparkStyle';
import { ROUTE_ADD_SPARK, ROUTE_ADD_SPARK_STYLE } from 'routes';
import { useGetSparksGradients } from 'graphQL/sparks/hooks';

const AddSpark = () => {
  const [sparkTitle, setSparkTitle] = useState('');
  const { items } = useGetSparksGradients();
  const shuffledGradients = [...items].sort(() => Math.random() - 0.5);

  return (
    <Switch>
      <Route exact path={ROUTE_ADD_SPARK}>
        <AddSparkPage sparkTitle={sparkTitle} setSparkTitle={setSparkTitle} />
      </Route>
      <Route exact path={ROUTE_ADD_SPARK_STYLE}>
        <AddSparkStylePage sparkTitle={sparkTitle} allGradients={shuffledGradients} />
      </Route>
    </Switch>
  );
};

export default AddSpark;
