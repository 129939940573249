import { ImageTypeOptions, Maybe, UserObjectType, UserShortSchema } from 'constants/graphqlTypes';
import { getImageUrl } from 'helpers/getImages';

interface IUserInfo {
  username: string;
  firstName?: string | null;
  lastName?: string | null;
  bio?: string | null;
  avatar?: string | null;
}

export const getUserInfo = (user?: UserObjectType | UserShortSchema): IUserInfo => {
  if (user?.__typename === 'UserShortSchema') {
    const { username, firstName, lastName, avatarUrl } = user;
    return { username: username ?? '', firstName, lastName, avatar: avatarUrl };
  }
  if (user?.__typename === 'UserObjectType') {
    const { username, userInfo, userStyle } = user ?? {};
    const { firstName, lastName, bio } = userInfo ?? {};
    const images = userStyle?.thumbnailImageSet?.images;
    const preview = getImageUrl({ images, type: ImageTypeOptions.Preview });
    const icon = getImageUrl({ images, type: ImageTypeOptions.Icon });
    const img = preview ?? icon;

    return { username, firstName, lastName, bio, avatar: img };
  }
  return { username: '' };
};

export const generateName = (username?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>): string => {
  const name = firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName;

  return name || username || '';
};

export const generateFullName = (user?: UserObjectType | UserShortSchema | null): string => {
  if (user) {
    const { firstName, lastName, username } = getUserInfo(user);
    return generateName(username, firstName, lastName);
  }
  return '';
};
