import { useState } from 'react';

import { useOnCounterEventsSubscription, useOnMessagesEventsSubscription } from 'constants/graphqlTypes';
import { useMessagesTabs } from 'components/Messages/helpers/hooks';
import getAuthUser from 'helpers/getAuthUser';

import {
  addChatToCache,
  deleteCachedMessage,
  deleteChatFromCache,
  updateMessages,
  updateMessageReactions,
  updateUnreadChatsCount,
} from './helpers';

export const useMessagesSubscription = (onNewMessage: () => void): { typingIds: string[] } => {
  const [typingIds, setTypingIds] = useState<string[]>([]);
  const { tab } = useMessagesTabs();
  const { userId: profileId } = getAuthUser();

  useOnMessagesEventsSubscription({
    onData: ({ data, client }) => {
      const { cache } = client;
      const { eventData } = data.data?.event ?? {};

      switch (eventData?.__typename) {
        case 'EventTypeNewMessage':
        case 'EventTypeMessageSentByCurrentUser': {
          const { message } = eventData;
          const { receiver, sender } = message ?? {};
          const { id: chatId } = receiver ?? {};
          const senderId = sender?.__typename === 'UserShortSchema' ? sender.id : undefined;

          if (receiver) {
            addChatToCache(receiver, tab, cache);
          }

          if (message && chatId && profileId !== senderId) {
            updateMessages(chatId, message, cache);
            onNewMessage();
          }

          break;
        }
        case 'EventTypeTypingStart': {
          const { userId } = eventData;

          setTypingIds((prev) => [...new Set([...prev, userId])]);

          break;
        }
        case 'EventTypeTypingEnd': {
          const { userId } = eventData;

          setTypingIds((prev) => prev.filter((typingId) => typingId !== userId));

          break;
        }
        case 'EventTypeDeleteMessage': {
          const { messageId } = eventData;

          if (messageId) {
            deleteCachedMessage(cache, messageId);
          }

          break;
        }
        case 'EventTypeDeleteReaction':
        case 'EventTypeNewReaction': {
          const { message } = eventData;

          if (message) {
            updateMessageReactions(message, cache);
          }

          break;
        }
        case 'EventTypeNewChat': {
          const { chat } = eventData;
          if (chat.__typename === 'GroupChatSchema') {
            addChatToCache(chat, tab, cache);
          }
          break;
        }
        case 'EventTypeDeleteChat': {
          const { chatId: deletedChatId } = eventData;
          deleteChatFromCache(deletedChatId, cache);
          break;
        }
        default:
          break;
      }
    },
  });

  return { typingIds };
};

export const useCountersSubscription = (): void => {
  useOnCounterEventsSubscription({
    onData: ({ data, client }) => {
      const { cache } = client;
      const { counterEventData } = data.data?.counterEvent ?? {};

      if (counterEventData?.__typename === 'CounterEventTypeUnreadChat') {
        const { unreadChatCounter } = counterEventData;
        updateUnreadChatsCount(unreadChatCounter, cache);
      }
    },
  });
};
