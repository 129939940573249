import { styled } from 'linaria/react';
import { COLORS, BREAKPOINTS, FONTS } from '../../../styles/constants';

export const StyledWrap = styled.li`
  display: block;
`;

export const StyledButton = styled.button<{ isSelected?: boolean }>`
  align-items: center;
  background-color: ${COLORS.transparent};
  border: none;
  cursor: ${({ isSelected }) => (isSelected ? 'auto' : 'pointer')};
  display: flex;
  padding: 8px 0;
  width: 100%;
  opacity: ${({ isSelected }) => (isSelected ? 0.3 : 1)};

  > :not(.icon):not(.avatarGH) {
    path {
      stroke: ${COLORS.white[100]};
      fill: none;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    padding: calc(8px * ${BREAKPOINTS.retinaScale}) 0;
  }
`;

export const StyledText = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  flex: 1;
  font-family: ${FONTS.primary};
  font-size: 14px;
  overflow: hidden;
  padding: 0 12px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(14px * ${BREAKPOINTS.retinaScale});
    padding: 0 calc(12px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledIconWrap = styled.div`
  align-items: center;
  background: ${COLORS.white[100]};
  border-radius: 50%;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 14.5px 9px;
  width: 44px;
`;
