import useWindowResize from 'helpers/useWindowResize';
import { useState } from 'react';
import { BREAKPOINTS } from 'styles/constants';

export const useSliderOffset = (customOffset: number): number => {
  const [offset, setOffset] = useState(customOffset);

  const handleWindowResize = () => {
    setOffset(window.innerWidth < BREAKPOINTS.s ? customOffset : 0);
  };

  useWindowResize(handleWindowResize);

  return offset;
};
