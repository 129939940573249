import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { UserObjectType } from 'constants/graphqlTypes';
import { MAX_DISPLAYED_PICKS } from 'constants/common';
import { EUiButtonType } from 'components/UI/Button/constants';
import { ROUTE_USER_PROFILE, ROUTE_USER_PROFILE_PREVIEW } from 'routes';

import { useGetSuggestedUserPicks } from 'graphQL/onboarding/hooks';

import getAuthUser from 'helpers/getAuthUser';
import useOpenModal from 'helpers/useOpenModal';
import { transformRoute } from 'helpers/routingHelper';

import Avatar from 'components/UI/Avatar';
import FollowButton from 'components/FollowButton';
import UserCardPick from 'components/Card/UserCard/UserCardPick';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';

import {
  StyledAvatarWrap,
  StyledBio,
  StyledButtonWrapper,
  StyledFollowsInfo,
  StyledImagesWrap,
  StyledName,
  StyledPreview,
  StyledProfileHeader,
  StyledUserInfoContainer,
  StyledUsername,
  StyledVerificationWrapper,
  StyledWrapper,
} from './styled';

const FOLLOW_BTN_CONTAINER_ID = 'follow-btn-container';

const UserCard: FC<{
  item: UserObjectType;
  enableOldBehaviour: boolean;
  isLandingView?: boolean;
  disableLinks?: boolean;
  disableBadgeInPreview?: boolean;
  isFullHeight?: boolean;
  isOnboardingView?: boolean;
  hasAnimatedImages?: boolean;
  isCurrent?: boolean;
  imagesKey?: string;
}> = ({
  item,
  isLandingView,
  disableLinks,
  disableBadgeInPreview,
  isFullHeight,
  isOnboardingView,
  hasAnimatedImages,
  isCurrent,
  imagesKey,
  enableOldBehaviour,
}) => {
  const { push } = useHistory();
  const { userPicks, isSpotlight } = useGetSuggestedUserPicks({ userId: item.id });
  const { userId: currentUserId } = getAuthUser();
  const { id: userId, userInfo, userCounters, username, pickedCards, isVerified } = item;
  const { firstName, lastName, bio } = userInfo ?? {};
  const { connectionsCount } = userCounters ?? {};
  const { totalCount } = pickedCards ?? {};

  const isUserWithPicks = () =>
    !!userPicks && !!userPicks.length && !!userPicks.find((pick) => pick.cardStyle?.thumbnailImageSet?.images?.[0]);

  const picks =
    totalCount && totalCount > MAX_DISPLAYED_PICKS
      ? `${MAX_DISPLAYED_PICKS}+ picks`
      : `${totalCount} pick${totalCount && totalCount === 1 ? '' : 's'}`;
  const connections = `${connectionsCount} connection${connectionsCount && connectionsCount === 1 ? '' : 's'}`;

  const openModal = useOpenModal(transformRoute(ROUTE_USER_PROFILE_PREVIEW, { profileName: username }), false, {
    userPicks,
    isSpotlight,
    userData: item,
    disableBadge: disableBadgeInPreview,
  });

  const onClickCapture = (e: React.MouseEvent) => {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }
    const isFollowButtonClicked = e.target.closest(`#${FOLLOW_BTN_CONTAINER_ID}`);

    if ((enableOldBehaviour || !userPicks.length) && !disableLinks && !isFollowButtonClicked) {
      push(transformRoute(ROUTE_USER_PROFILE, { profileName: username }));
    } else if (!enableOldBehaviour && !isFollowButtonClicked) {
      openModal();
    }
  };

  return (
    <StyledWrapper
      className="card"
      isLandingView={isLandingView}
      disableLinks={disableLinks}
      isFullHeight={isFullHeight}
      isOnboardingView={isOnboardingView}
      onClickCapture={onClickCapture}
    >
      <div>
        <StyledUserInfoContainer>
          {hasAnimatedImages ? (
            <StyledProfileHeader>
              <StyledAvatarWrap showPicks={!!isCurrent && isUserWithPicks()}>
                <Avatar user={item} dimension={isLandingView ? 96 : 92} />
              </StyledAvatarWrap>
              {isUserWithPicks() && !!isCurrent && (
                <StyledImagesWrap key={imagesKey} showPicks={!!isCurrent && isUserWithPicks()}>
                  {userPicks
                    .slice(0, 3)
                    .map(
                      (pick, index) =>
                        pick.cardStyle?.thumbnailImageSet?.images?.[0]?.url && (
                          <UserCardPick
                            index={index}
                            key={pick.id}
                            url={pick.cardStyle?.thumbnailImageSet?.images?.[0]?.url}
                          />
                        )
                    )}
                </StyledImagesWrap>
              )}
            </StyledProfileHeader>
          ) : (
            <Avatar user={item} dimension={isLandingView ? 96 : 92} />
          )}

          <StyledName isLandingView={isLandingView}>{`${firstName} ${lastName}`}</StyledName>
          {isVerified && (
            <StyledVerificationWrapper>
              <VerificationBadge hasCaption />
            </StyledVerificationWrapper>
          )}
          <StyledUsername isLandingView={isLandingView}>:{username}</StyledUsername>
          <StyledBio bio={bio} isLandingView={isLandingView}>
            {bio}
          </StyledBio>
        </StyledUserInfoContainer>
        {currentUserId !== userId && (
          <StyledButtonWrapper id={FOLLOW_BTN_CONTAINER_ID}>
            <FollowButton
              isDark={!!item.isFollowedByMe || !!isLandingView}
              user={item}
              buttonType={EUiButtonType.Emphasized}
              isDisabled={isLandingView}
            />
          </StyledButtonWrapper>
        )}
      </div>
      {!enableOldBehaviour && <StyledPreview>Preview</StyledPreview>}
      {enableOldBehaviour && !isLandingView && (
        <StyledFollowsInfo isOnboardingView={isOnboardingView}>
          {picks} · {connections}
        </StyledFollowsInfo>
      )}
    </StyledWrapper>
  );
};

export default UserCard;
