import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import EditTextFieldModal from 'components/EditTextFieldModal';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';

import { IEditTextFieldModalDataTestIds } from 'components/EditTextFieldModal/EditTextFieldModal';
import {
  TEST_ID_NAME_EDIT_VIEW_CANCEL_BUTTON,
  TEST_ID_NAME_EDIT_VIEW_DONE_BUTTON,
  TEST_ID_NAME_EDIT_VIEW_NAME_LABEL,
} from 'constants/aqa/profile';
import { useProfileSettingsLinks } from '../../hooks';
import useChangeProfileName from './hooks';

interface IChangeNameProps {
  handleClose: () => void;
  handlePrev?: () => void;
}

const dataTestIds: IEditTextFieldModalDataTestIds = {
  inputDataTestId: TEST_ID_NAME_EDIT_VIEW_NAME_LABEL,
  cancelBtnDataTestId: TEST_ID_NAME_EDIT_VIEW_CANCEL_BUTTON,
  doneBtnDataTestId: TEST_ID_NAME_EDIT_VIEW_DONE_BUTTON,
};

const ChangeName: FC<IChangeNameProps> = ({ handleClose, handlePrev }) => {
  const { editProfile } = useProfileSettingsLinks();
  const { replace } = useHistory();

  const handleNext = () => {
    replace(editProfile);
  };

  const { name, loadingUserUpdate, error, handleChange, handleError, handleDone } = useChangeProfileName(handleNext);

  return (
    <EditTextFieldModal
      text={name}
      setText={handleChange}
      label="Name"
      isSubmitDisabled={!!error}
      handleClose={handleClose}
      handleDone={handleDone}
      handlePrev={handlePrev}
      error={error}
      loading={loadingUserUpdate}
      setError={handleError}
      autoCapitalize={EAutoCapitalize.Words}
      isTextRequired
      dataTestIds={dataTestIds}
    />
  );
};

export default ChangeName;
