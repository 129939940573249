import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ROUTE_BEFORE_GO,
  ROUTE_CONFIRM_DELETION,
  ROUTE_DELETE_MY_ACCOUNT,
  ROUTE_DELETE_PICKS,
  ROUTE_REASSIGN_ADMINS,
} from 'routes';

import DeleteMyAccountPage from './DeleteMyAccountPage';
import BeforeYouGo from './BeforeYouGo';
import CollaborativeAdminLists from './CollaborativeAdminLists';
import DeleteAllPicks from './DeleteAllPicks';
import ConfirmAccountDeletion from './ConfirmAccountDeletion';

const DeleteAccount: FC = () => (
  <Switch>
    <Route exact path={ROUTE_DELETE_MY_ACCOUNT}>
      <DeleteMyAccountPage />
    </Route>
    <Route exact path={ROUTE_BEFORE_GO}>
      <BeforeYouGo />
    </Route>
    <Route exact path={ROUTE_REASSIGN_ADMINS}>
      <CollaborativeAdminLists />
    </Route>
    <Route exact path={ROUTE_DELETE_PICKS}>
      <DeleteAllPicks />
    </Route>
    <Route exact path={ROUTE_CONFIRM_DELETION}>
      <ConfirmAccountDeletion />
    </Route>
  </Switch>
);
export default DeleteAccount;
