import { styled } from 'linaria/lib/react';

export const StyledContainer = styled.ul`
  margin: 0;
  overflow-y: scroll;
  scrollbar-width: none;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  li:first-child {
    margin-top: 24px;
  }
`;
