import { Dispatch, FormEvent, SetStateAction, useState } from 'react';

import config from 'constants/config';
import useToast from 'helpers/useToast';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';

interface IUseSubscribeForm {
  name: string;
  email: string;
  setName: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

export const useSubscribeForm = (): IUseSubscribeForm => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const { landingGScriptApi, mailchimpUrl, isProd } = config;

  const posthogCapture = usePostHogCapture();
  const { setToast } = useToast();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);

    fetch(landingGScriptApi, {
      body: formData,
      method: 'POST',
    });

    if (isProd && mailchimpUrl) {
      const MCFormData = new FormData();
      MCFormData.append('FNAME', name);
      MCFormData.append('EMAIL', email);

      fetch(mailchimpUrl, {
        body: MCFormData,
        method: 'POST',
      });
    }

    setName('');
    setEmail('');
    setToast({
      isToastOpen: true,
      toastItemName: 'Subscribed Successfully!',
    });
    posthogCapture(POSTHOG_EVENTS.LandingJonWaitListSuccess);
  };

  return {
    name,
    setName,
    email,
    setEmail,
    handleSubmit,
  };
};
