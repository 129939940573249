import { styled } from 'linaria/react';
import { COLORS, FONTS } from '../../../styles/constants';

export const StyledPrivate = styled.span<{ isDark: boolean }>`
  backdrop-filter: blur(2px);
  background: ${({ isDark }) => (isDark ? COLORS.white[10] : COLORS.brown.dark[10])};
  border-radius: 4px;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 14px/140% ${FONTS.secondary};
  padding: 4px 8px;
  width: fit-content;
`;
