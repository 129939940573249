import { useContext } from 'react';

import { IIsModalOpen } from 'providers/helpers/models';
import { IsModalOpenContext } from 'providers/helpers/contexts';

const useIsModalOpen = (): boolean => {
  const { isOpen } = useContext<IIsModalOpen>(IsModalOpenContext);

  return !!isOpen;
};

export default useIsModalOpen;
