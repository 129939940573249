import { styled } from 'linaria/react';
import { COLORS, FONTS } from '../../styles/constants';

export const StyledLoaderContainer = styled.div<{ isDark?: boolean }>`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ isDark }) => (isDark ? COLORS.black[100] : COLORS.white[100])};
  z-index: 200;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledDeleteText = styled.p<{ isDark?: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font-family: ${FONTS.secondary};
  font-weight: 550;
`;
