import { getNotifications } from 'graphQL/cardOptions/helpers';
import { CreateListMutation, GetUserCardsDocument, useCreateListMutation } from 'constants/graphqlTypes';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';

import { updatePickedCards } from 'graphQL/profile/helper';
import getAuthUser from 'helpers/getAuthUser';
import useToast from 'helpers/useToast';
import getKeywordsFromTitle from 'helpers/getKeywordsFromTitle';

const useCreateList = (
  title: string,
  isPrivate: boolean,
  description: string,
  onCompleted: (data?: CreateListMutation | null) => void,
  showToast?: boolean
) => {
  const posthogCapture = usePostHogCapture();

  const { userId } = getAuthUser();

  const { setToast } = useToast();

  const [createList, { loading, error }] = useCreateListMutation({
    variables: { title, isPrivate, description },
    ...getNotifications({
      message: `List created`,
      onCompleted: () => {
        const keywords = getKeywordsFromTitle(title);
        posthogCapture(POSTHOG_EVENTS.ListAction);
        posthogCapture(POSTHOG_EVENTS.CreateList);
        keywords.split(' ').forEach((keyword) => {
          posthogCapture(POSTHOG_EVENTS.ListNameKeyword, {
            keyword,
          });
        });
      },
      isToastOpen: showToast,
    }),
    refetchQueries: [GetUserCardsDocument],
    update: (cache, { data }) => {
      updatePickedCards(cache, userId, true, data?.createCollection?.id);
      onCompleted(data);
      setToast({ toastId: data?.createCollection?.id });
    },
  });

  return { createList, loading, listError: error?.message };
};

export default useCreateList;
