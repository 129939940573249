import React, { FC, useEffect, useState } from 'react';

import { StyledEmpty } from '../styled';
import { StyledFadeOut } from './styled';

interface INoMessagesPlaceholder {
  isDark: boolean;
  shouldShow: boolean;
  isIosVirtualKeyboardOpen: boolean;
}

const NoMessagesPlaceholder: FC<INoMessagesPlaceholder> = ({ isDark, shouldShow, isIosVirtualKeyboardOpen }) => {
  const [shouldRender, setShouldRender] = useState<boolean | null>(null);
  const [shouldAnimate, setShouldAnimate] = useState<boolean | null>(null);

  useEffect(() => {
    if (shouldShow) {
      setShouldRender(true);
      setShouldAnimate(false);
    } else if (!shouldShow && shouldAnimate === false) {
      setShouldAnimate(true);
    }
  }, [shouldShow]);

  return shouldRender ? (
    <StyledEmpty
      isIosVirtualKeyboardOpen={isIosVirtualKeyboardOpen}
      isDark={isDark}
      className={shouldAnimate ? StyledFadeOut : undefined}
      onAnimationEnd={() => setShouldRender(false)}
    >
      <b>No messages yet!</b>
      Ask for some thoughts or say hey
    </StyledEmpty>
  ) : null;
};

export default NoMessagesPlaceholder;
