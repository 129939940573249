import { createContext, useContext } from 'react';

const RefIdContext = createContext({});
export const { Provider: RefIdProvider } = RefIdContext;

const useRefIdContext = () => {
  const value: { referrerId?: string } = useContext(RefIdContext);

  return value.referrerId;
};

export default useRefIdContext;
