import { styled } from 'linaria/react';

import { BREAKPOINTS } from 'styles/constants';

export const StyledContainer = styled.div`
  padding: 16px 0 0;
  display: flex;

  > div {
    flex: 1;
  }

  .swiper-slide {
    height: auto;
  }
`;

// StyledInfiniteMovingSliderContainer is essential to make slider infinite and autoplayable without snapping to specific slides
export const StyledInfiniteMovingSliderContainer = styled(StyledContainer)`
  .swiper-wrapper {
    transition-timing-function: linear !important;
  }

  .swiper-slide {
    max-width: 170px;
  }
`;

export const StyledSectionInfo = styled.div<{ hasOneItem?: boolean }>`
  padding: 0 ${({ hasOneItem }) => (hasOneItem ? 12 : 24)}px 16px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0 12px 16px;
  }

  > span {
    padding: 0;
  }
`;

export const StyledCardWrap = styled.div<{ hidePaddingTop?: boolean }>`
  padding: ${({ hidePaddingTop }) => (hidePaddingTop ? 0 : 16)}px 12px 0;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: ${({ hidePaddingTop }) => (hidePaddingTop ? 0 : 16)}px 0 0;
  }
`;

export const StyledSlideWrap = styled.div`
  height: 100%;
`;
