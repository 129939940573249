import React, { FC } from 'react';

import { UserReactionSchema } from 'constants/graphqlTypes';

import Emoji from 'components/UI/Emoji';
import BadgeWithChildren from 'components/UI/BadgeWithChildren';
import FollowButton from 'components/FollowButton';
import StyledList from './styled';

const ReactionsList: FC<{ items: UserReactionSchema[]; isDark: boolean }> = ({ items, isDark }) => (
  <StyledList>
    {items.map(
      ({ user, reactionTypes }) =>
        user &&
        reactionTypes && (
          <li key={user.id}>
            <BadgeWithChildren isDark={isDark} user={user}>
              <FollowButton isDark={isDark} user={user} />
            </BadgeWithChildren>
            <Emoji reactions={reactionTypes} isLeft />
          </li>
        )
    )}
  </StyledList>
);

export default ReactionsList;
