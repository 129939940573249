import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS, FONTS } from '../../../styles/constants';

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledLabel = styled.label<{ isDark: boolean; isLast?: boolean; isPointer?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  left: ${({ isLast }) => (isLast ? 'auto' : '12px')};
  right: ${({ isLast }) => (isLast ? '12px' : 'auto')};
  cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'auto')};
  position: absolute;
  top: 50%;
  height: 24px;
  width: 24px;
  transform: translateY(-50%);

  path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;

export const StyledInput = styled.input<{ isDark: boolean; hasPaddingLeft?: boolean; hasCrossIcon?: boolean }>`
  background: ${({ isDark }) => (isDark ? COLORS.white[10] : COLORS.white.off[100])};
  border-radius: 22px;
  border: none;
  box-sizing: border-box;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 16px/140% ${FONTS.secondary};
  height: 44px;
  outline: none;
  padding: 0 ${({ hasCrossIcon }) => (hasCrossIcon ? 40 : 20)}px 0
    ${({ hasPaddingLeft }) => (hasPaddingLeft ? 40 : 20)}px;
  text-overflow: ellipsis;
  width: 100%;

  &::placeholder {
    color: ${COLORS.brown.light[100]};
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(14px * ${BREAKPOINTS.retinaScale});
  }
`;
