import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  position: relative;
`;

export const StyledHeading = styled.p<{ hasBg: boolean; isDark: boolean }>`
  animation-delay: 50ms;
  animation: fadein 300ms ease-in forwards;
  color: ${({ hasBg, isDark }) => {
    if (hasBg) {
      return COLORS.white[100];
    }
    return isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  }};
  font: 400 22px/130% ${FONTS.primary};
  margin: 0;
  opacity: 0;
  padding: 64px 0 99px;
  text-align: center;
  width: 100%;

  @keyframes fadein {
    100% {
      opacity: 1;
    }
  }
`;

export const StyledCard = styled.div<{ hasBg: boolean; isDark: boolean }>`
  align-items: center;
  animation-delay: 50ms;
  animation: fadein 300ms ease-in forwards;
  background: ${({ hasBg, isDark }) => {
    if (!hasBg) {
      return isDark ? COLORS.white[10] : COLORS.white.off[100];
    }

    return COLORS.white[10];
  }};
  border-radius: 10px;
  color: ${({ hasBg, isDark }) => {
    if (hasBg) {
      return COLORS.white[100];
    }
    return isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  }};
  display: flex;
  flex-direction: column;
  height: 400px;
  margin-bottom: 171px;
  opacity: 0;
  overflow: hidden;
  padding: 62px 32px 0;
  transition: all 300ms ease-in-out;
  width: 326px;

  @keyframes fadein {
    100% {
      opacity: 1;
    }
  }
`;

export const StyledFullname = styled.p`
  font: 400 22px/130% ${FONTS.primary};
  margin: 0;
  padding: 16px 0 8px;
  text-align: center;
  word-break: break-word;
`;

export const StyledText = styled.p`
  font: 400 14px/140% ${FONTS.secondary};
  margin: 0;
  text-align: center;
`;

export const StyledUsername = styled.p`
  font: 400 14px/140% ${FONTS.secondary};
  margin: 0;
  text-align: center;
  line-break: anywhere;
`;

export const StyledVerificationWrap = styled.div`
  padding-bottom: 8px;
`;
