import { useLink } from 'helpers/routingHelper';
import {
  ROUTE_ADD_ANSWER,
  ROUTE_ANSWER_ADD_LIST,
  ROUTE_ANSWER_ADD_STYLE,
  ROUTE_ANSWER_ADD_THOUGHT,
  ROUTE_ANSWER_ADD_TO_LIST,
} from 'routes';

export const useAnswerLinks = () => {
  const addAnswerLink = useLink(ROUTE_ADD_ANSWER);
  const addAnswerStyle = useLink(ROUTE_ANSWER_ADD_STYLE);
  const addAnswerToList = useLink(ROUTE_ANSWER_ADD_TO_LIST);
  const addAnswerThought = useLink(ROUTE_ANSWER_ADD_THOUGHT);
  const addList = useLink(ROUTE_ANSWER_ADD_LIST);

  return { addAnswerLink, addAnswerStyle, addAnswerToList, addAnswerThought, addList };
};
