import React, { Dispatch, FC, SetStateAction } from 'react';

import { StyledEmoji } from 'components/UI/Emoji/styled';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { useSendReaction } from 'graphQL/reactions/hooks';

import { StyledReaction } from './styled';

interface IReactionButtonProps {
  emoji?: string;
  isPlus?: boolean;
  isEmojiOnly?: boolean;
  handleClick?: () => void;
  setIsQuickReactionOpen?: Dispatch<SetStateAction<boolean>>;
}

const ReactionButton: FC<IReactionButtonProps> = ({
  emoji,
  isPlus,
  isEmojiOnly,
  handleClick,
  setIsQuickReactionOpen,
}) => {
  const sendReaction = useSendReaction(emoji);

  const onClick = () => {
    if (emoji) {
      sendReaction();
    }
    handleClick?.();
    setIsQuickReactionOpen?.(false);
  };

  return isEmojiOnly ? (
    <StyledEmoji onClick={onClick}>{emoji}</StyledEmoji>
  ) : (
    <StyledReaction onClick={onClick}>{isPlus ? <PlusIcon /> : <StyledEmoji>{emoji}</StyledEmoji>}</StyledReaction>
  );
};

export default ReactionButton;
