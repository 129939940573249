import React, { FC, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import { ReactComponent as Chevron } from 'assets/icons/ChevronLeft.svg';

import { ChannelAdminFeedSchema, ChannelSectionListV2 } from 'constants/graphqlTypes';

import UILink from 'components/UI/Link';
import SliderInteractive from 'components/SliderInteractive';

import { useGetChannelSectionItems } from 'graphQL/channel/hooks';
import { ROUTE_CUSTOM_FEED_DETAIL } from 'routes';
import { COLORS } from 'styles/constants';

import {
  StyledSectionType,
  StyledSectionWrap,
  StyledSliderWrap,
  StyledText,
  StyledTextContainer,
  StyledTitle,
} from '../styled';
import { StyledCardTitle, StyledCoverWrap, StyledGradient, StyledImageUser } from './styled';

const SLIDES_PER_VIEW = 3.2;

const AdminFeedsSection: FC<{
  section: ChannelSectionListV2;
  feedType: string;
  isDark: boolean;
  handler: () => void;
}> = ({ section, feedType, isDark, handler }) => {
  const [slideIndex, setSlideInd] = useState(0);
  const { items, loadMore, loading } = useGetChannelSectionItems(section.id);

  const adminFeeds =
    items?.reduce(
      (prev: ChannelAdminFeedSchema[], curr) =>
        curr && curr.__typename === 'ChannelAdminFeedSchema' ? [...prev, curr] : prev,
      []
    ) ?? [];

  const handleLoadMore = () => {
    if (adminFeeds.length && (slideIndex === adminFeeds.length - 4 || slideIndex === adminFeeds.length - 1)) {
      loadMore();
    }
  };

  return (
    <StyledSectionWrap>
      <StyledTextContainer onClick={handler}>
        <StyledSectionType>{feedType}</StyledSectionType>
        <StyledTitle isDark={isDark}>
          <UILink isDisabled route="">
            <span>{section.title ?? ''}</span>
            {!!adminFeeds?.length && !loading && <Chevron viewBox="0 0 24 24" height={24} width={24} />}
          </UILink>
        </StyledTitle>
        {!adminFeeds?.length && !loading && <StyledText>You haven&apos;t subscribed to anyone yet</StyledText>}
      </StyledTextContainer>
      <StyledSliderWrap>
        <SliderInteractive
          onSlideChange={(ind) => setSlideInd(ind)}
          customSlidesPerView={SLIDES_PER_VIEW}
          spaceBetween={12}
          offset={12}
          fontColor={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
          showBtnsSm={!!adminFeeds.length}
          isInfinite={false}
          isPrevDisabled={slideIndex === 0}
          isNextDisabled={
            slideIndex === adminFeeds.length - Math.floor(SLIDES_PER_VIEW) || adminFeeds.length < SLIDES_PER_VIEW
          }
          onTransitionEnd={handleLoadMore}
          onAnimationEnd={handleLoadMore}
        >
          {adminFeeds.map(({ id, cover, bgColor, counterUnreadPicks, userCovers, item }) => (
            <SwiperSlide key={id}>
              <UILink route={ROUTE_CUSTOM_FEED_DETAIL} params={{ feedId: id }}>
                <StyledCoverWrap
                  image={cover?.images?.[0]?.url}
                  bgColor={bgColor?.colorValue}
                  counter={counterUnreadPicks}
                  isDark={isDark}
                >
                  <StyledGradient />
                  {userCovers?.items[0]?.images?.[0].url && (
                    <StyledImageUser
                      src={userCovers?.items[0]?.images?.[0]?.url}
                      hasUnreadPicks={!!counterUnreadPicks}
                    />
                  )}
                  <StyledCardTitle>{item.title}</StyledCardTitle>
                </StyledCoverWrap>
              </UILink>
            </SwiperSlide>
          ))}
        </SliderInteractive>
      </StyledSliderWrap>
    </StyledSectionWrap>
  );
};

export default AdminFeedsSection;
