import React, { FC } from 'react';

import getImages from 'helpers/getImages';

import Grain from 'components/UI/Grain';
import { CardSchema, CardTypeOptions } from 'constants/graphqlTypes';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_GUEST_LIST_DETAIL, ROUTE_GUEST_LIST_SERIES } from 'routes';
import { StyledLink, StyledTitle, StyledDescription } from './styled';

const Card: FC<{ card: CardSchema; dataTestId?: string }> = ({ card, dataTestId }) => {
  const { cardStyle, title, description, id, type } = card;
  const { imageUrl } = getImages(cardStyle);

  const isSeries = type === CardTypeOptions.GuestListSeries;

  return (
    <StyledLink
      data-testid={dataTestId}
      to={transformRoute(isSeries ? ROUTE_GUEST_LIST_SERIES : ROUTE_GUEST_LIST_DETAIL, { id })}
      bg={imageUrl}
    >
      {!imageUrl && <Grain />}
      {!isSeries && (
        <>
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription>
            <span>{description}</span>
          </StyledDescription>
        </>
      )}
    </StyledLink>
  );
};

export default Card;
