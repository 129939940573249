import { Dispatch, SetStateAction } from 'react';
import { v4 } from 'uuid';
import { IAnimationItem } from './models';

export const generateRandomValue = (lowerLimit = 0, upperLimit = 100) => {
  return Math.floor(Math.random() * (upperLimit - lowerLimit) + lowerLimit);
};

const getXCoordinate = (horizontalInterval: number, index: number) => {
  const x = horizontalInterval * index + generateRandomValue(-horizontalInterval / 3, horizontalInterval / 3);

  if (x > 95) {
    return 95;
  }
  return x < 5 ? 5 : x;
};

const getMosaicArray = (itemsCount: number): undefined[][] => {
  const emptyArray = [...Array(itemsCount)];

  return emptyArray.reduce((prev: undefined[][], curr) => {
    const lastSubArray = prev && prev[prev.length - 1];
    if (
      prev?.length &&
      (lastSubArray.length < 2 || (prev.length - 1 !== 0 && prev.length % 3 === 0 && lastSubArray.length < 3))
    ) {
      lastSubArray.push(curr);
      return prev;
    }
    return [...prev, [curr]];
  }, []);
};

export const getRandomCoords = (itemsCount: number): { x: number; y: number }[] => {
  const mosaicArray = getMosaicArray(itemsCount);
  return mosaicArray
    .map((el, xIndex) =>
      el.map((_val, yIndex) => {
        return {
          x: getXCoordinate(100 / mosaicArray.length + 1, xIndex),
          y: 100 + 10 * (yIndex + 1) + generateRandomValue(-5, 5),
        };
      })
    )
    .flat();
};
export const createAnimation = (
  emoji: string,
  emojiCount: number,
  setAnimations?: Dispatch<SetStateAction<IAnimationItem[]>>
) => {
  const id = v4();
  setAnimations?.((prev) => [...prev, { emoji, id, coords: getRandomCoords(emojiCount) }]);
  return id;
};
