import { styled } from 'linaria/react';

import { IInputStyle } from 'components/UI/TextInput/helpers/helpers';

export const StyledAnimatedLabel = styled.label<{ inputStyle: IInputStyle; itemsLength: number }>`
  color: ${({ inputStyle }) => inputStyle.placeholderColor};
  font: ${({ inputStyle: { fontWeight, fontSize, lineHeight, fontFamily } }) =>
    `${fontWeight} ${fontSize}/${lineHeight} ${fontFamily}`};
  margin: ${({ inputStyle }) => inputStyle.margin};
  max-height: ${({ inputStyle: { fontSize } }) => `calc(1.3 * ${fontSize})`};
  overflow: hidden;
  pointer-events: none;
  position: absolute;

  > div {
    height: ${({ inputStyle: { fontSize } }) => `calc( 1.3 * ${fontSize})`};
  }
`;

export const StyledTextWrapper = styled.div`
  position: relative;
`;
