import React from 'react';
import ReactDOM from 'react-dom';

import Toast from 'components/Toast/Toast';
import { IToast, IToastWithSetter } from 'providers/helpers/models';

const useToast = (): IToastWithSetter => {
  return {
    setToast: ({
      isToastOpen,
      toastAction,
      toastError,
      toastId,
      toastImg,
      toastEmoji,
      toastItemName,
      toastItemStart,
      toastItemEnding,
      toastItemAltEnding,
      toastType,
      linesCount,
      handleToastButton,
      toastLinkText,
      customFontWeight,
      toastHandler,
      customBottomCoord,
    }: IToast) => {
      const root = document.createElement('div');

      document.body.appendChild(root);
      const handleHide = () => root.remove();
      ReactDOM.render(
        <Toast
          isToastOpen={isToastOpen}
          toastAction={toastAction}
          toastError={toastError}
          toastId={toastId}
          toastImg={toastImg}
          toastEmoji={toastEmoji}
          toastItemName={toastItemName}
          toastItemStart={toastItemStart}
          toastItemEnding={toastItemEnding}
          toastItemAltEnding={toastItemAltEnding}
          toastType={toastType}
          handleHide={handleHide}
          linesCount={linesCount}
          handleToastButton={handleToastButton}
          toastLinkText={toastLinkText}
          customFontWeight={customFontWeight}
          toastHandler={toastHandler}
          customBottomCoord={customBottomCoord}
        />,
        root
      );
      setTimeout(() => root && handleHide(), 4600);
    },
  };
};

export default useToast;
