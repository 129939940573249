import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

export const StyledLinkContent = styled.div<{ isDark?: boolean }>`
  align-items: center;
  border-radius: 20px;
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.white.off[100])};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  gap: 12px;
  height: 78px;
  justify-items: space-between;
  padding: 16px;
  text-decoration: none;
  width: 100%;

  p {
    margin: 0;
  }

  svg > path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;

export const StyledLinkWrap = styled.div`
  padding: 16px 24px;
`;

export const StyledShareWrap = styled.div`
  height: 24px;
  padding-right: 8px;
  display: flex;
  align-items: center;
`;
