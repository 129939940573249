import { styled } from 'linaria/react';

import { StyledList } from 'components/ProfileSettings/Menu/styled';

export const StyledOption = styled.div`
  cursor: pointer;
`;

export const StyledActions = styled(StyledList)`
  padding-top: 16px;
  margin: 0;
  overflow: visible;
`;
