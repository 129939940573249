import React, { FC, useState } from 'react';

import useTheme from 'helpers/useTheme';
import Button from 'components/UI/Button';
import ContentTitle from 'components/UI/ContentTitle';
import TextInput from 'components/UI/TextInput';

import { useNavBehavior } from 'components/Navigation/helpers/hooks';
import { LIST_DESCRIPTION_LENGTH } from 'constants/common';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import { EUiButtonType } from 'components/UI/Button/constants';
import { StyledWrap } from 'components/SinglePageLayout/styled';
import { StyledNavigationWrap } from './styled';

const ExitFormCommentPage: FC<{
  existedText: string;
  handleAddComment: (comment: string) => void;
  handleCancel: () => void;
}> = ({ existedText, handleAddComment, handleCancel }) => {
  const [text, setText] = useState(existedText);
  const { bgColor } = useNavBehavior();
  const isDark = useTheme();

  return (
    <div>
      <StyledNavigationWrap bgColor={bgColor}>
        <Button text="Cancel" handler={handleCancel} isDark={isDark} type={EUiButtonType.Transparent} />
        <Button text="Add" handler={() => handleAddComment(text)} isDark={isDark} />
      </StyledNavigationWrap>
      <StyledWrap paddingTop={32}>
        <ContentTitle isDark={isDark}>Is there anything else we should know?</ContentTitle>
        <TextInput
          autoCapitalize={EAutoCapitalize.On}
          counterNumber={LIST_DESCRIPTION_LENGTH}
          isDark={isDark}
          placeholder="Add your comment"
          maxLength={LIST_DESCRIPTION_LENGTH}
          setText={setText}
          text={text}
        />
      </StyledWrap>
    </div>
  );
};

export default ExitFormCommentPage;
