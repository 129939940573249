import React, { cloneElement, FC, useState } from 'react';

import ConfirmationModal from 'components/UI/ConfirmationModal';

import { useMuteUser } from 'graphQL/muting/hooks';

interface IMuteUserButtonProps {
  handler?: () => void;
  fullname?: string;
  isMutedByMe: boolean;
  userId: string;
  children: JSX.Element;
}

const MuteUserButton: FC<IMuteUserButtonProps> = ({ userId, isMutedByMe, fullname, children, handler }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { muteUser, unmuteUser } = useMuteUser(userId, fullname, handler);

  const handleAccept = () => {
    if (isMutedByMe) {
      unmuteUser();
    } else {
      muteUser();
    }
    setIsOpen(false);
  };

  return (
    <>
      {cloneElement(children, { onClick: () => setIsOpen(true), handler: () => setIsOpen(true) })}
      {isOpen && (
        <ConfirmationModal
          buttonText={`${isMutedByMe ? 'un' : ''}mute`}
          handleAccept={handleAccept}
          handleCancel={() => setIsOpen(false)}
          isWarning={!isMutedByMe}
          subTitle={
            isMutedByMe
              ? 'Their picks will appear in your feed. Their reactions and thoughts on your picks will continue to be visible to your community.'
              : 'Their picks will no longer appear in your feed. They can still see, react to, and add thoughts on your picks. They will not know you muted them.'
          }
          titleText={`${isMutedByMe ? 'un' : ''}mute user?`}
        />
      )}
    </>
  );
};

export default MuteUserButton;
