import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledSharedContentReaction = styled.button<{
  zIndex: number;
}>`
  align-items: center;
  background: ${COLORS.white[100]};
  border-radius: 50%;
  border: 1px solid ${COLORS.white.off[100]};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font: 400 22px/130% ${FONTS.primary};
  height: 44px;
  justify-content: center;
  outline: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  width: 44px;
  z-index: ${({ zIndex }) => zIndex};
`;

export const StyledRelWrap = styled.div`
  position: relative;

  a {
    text-decoration: none;
  }
`;

export const StyledMessageInfo = styled.div`
  font: 400 14px/140% ${FONTS.secondary};
  color: ${COLORS.brown.light[100]};
  padding-bottom: 12px;
`;
