import React, { FC, useEffect, useRef } from 'react';
import { Swiper } from 'swiper/react';
import { Autoplay, Mousewheel, Navigation, Swiper as SwiperRef } from 'swiper';
import { v4 } from 'uuid';

import 'swiper/swiper.min.css';

import { ReactComponent as ArrowIcon } from 'assets/icons/ArrowRight.svg';

import { SWIPER_MOUSE_WHEEL_OPTIONS } from 'constants/common';
import { ISliderInteractive } from './models';
import { useSliderOffset } from './hooks';

import { StyledLeftArrowBtn, StyledRightArrowBtn, StyledSlider } from './styled';

const SliderInteractive: FC<ISliderInteractive> = ({
  children,
  fontColor,
  spaceBetween,
  customSlidesPerView,
  showBtnsSm,
  isInfinite = true,
  offset,
  constantOffset,
  isPrevDisabled,
  isNextDisabled,
  autoplayOptions,
  customSpeed,
  shouldPauseAutoplay,
  onSlideChange,
  onTransitionStart,
  onAnimationStart,
  onTransitionEnd,
  onAnimationEnd,
  slideToClickedSlide,
  touchStartPreventDefault,
  centeredSlides,
  arrowsOffset,
}) => {
  const prevBtnClassName = `prev-${v4()}`;
  const nextBtnClassName = `next-${v4()}`;
  const sliderOffset = useSliderOffset(offset ?? 0);

  const ref = useRef<SwiperRef | null>(null);

  const onSwiper = (swiper: SwiperRef) => {
    ref.current = swiper;
  };

  useEffect(() => {
    if (shouldPauseAutoplay) {
      ref.current?.autoplay?.stop();
    } else if (shouldPauseAutoplay === false) {
      ref.current?.autoplay?.start();
    }
  }, [shouldPauseAutoplay]);

  return (
    <StyledSlider arrowsOffset={arrowsOffset} showBtnsSm={showBtnsSm}>
      <StyledLeftArrowBtn isDisabled={isPrevDisabled} className={`${prevBtnClassName} slider-prev`} color={fontColor}>
        <ArrowIcon />
      </StyledLeftArrowBtn>
      <Swiper
        onSwiper={onSwiper}
        navigation={{
          prevEl: `.${prevBtnClassName}`,
          nextEl: `.${nextBtnClassName}`,
        }}
        onSlideChange={(swiper) => {
          onSlideChange?.(swiper.realIndex);
        }}
        grabCursor
        slidesPerView={customSlidesPerView}
        loop={isInfinite}
        speed={customSpeed ?? 600}
        spaceBetween={spaceBetween}
        modules={[Mousewheel, Navigation, Autoplay]}
        mousewheel={SWIPER_MOUSE_WHEEL_OPTIONS}
        slidesOffsetBefore={constantOffset ?? sliderOffset}
        slidesOffsetAfter={constantOffset ?? sliderOffset}
        onTransitionStart={onTransitionStart}
        onAnimationStart={onAnimationStart}
        noSwipingClass="no-swiping"
        autoplay={autoplayOptions}
        centeredSlides={centeredSlides}
        touchStartPreventDefault={touchStartPreventDefault}
        slideToClickedSlide={slideToClickedSlide}
        onTransitionEnd={onTransitionEnd}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </Swiper>
      <StyledRightArrowBtn isDisabled={isNextDisabled} className={`${nextBtnClassName} slider-next`} color={fontColor}>
        <ArrowIcon />
      </StyledRightArrowBtn>
    </StyledSlider>
  );
};

export default SliderInteractive;
