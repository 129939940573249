import React, { FC } from 'react';

import UILink from 'components/UI/Link';
import getImages from 'helpers/getImages';

import { CardSchema } from 'constants/graphqlTypes';
import { ROUTE_GUEST_LIST_DETAIL } from 'routes';
import { StyledImage, StyledText, StyledWrap } from './styled';

const SeriesItem: FC<{ item: CardSchema; isDark: boolean }> = ({ item, isDark }) => {
  const { title, description, cardStyle, id } = item;
  const { imageUrl } = getImages(cardStyle);

  return (
    <UILink route={ROUTE_GUEST_LIST_DETAIL} params={{ id }}>
      <StyledWrap isDark={isDark}>
        <StyledText isDark={isDark}>
          {title}
          <span>{description}</span>
        </StyledText>
        {imageUrl && <StyledImage src={imageUrl} />}
      </StyledWrap>
    </UILink>
  );
};

export default SeriesItem;
