import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { CardTypeOptions } from 'constants/graphqlTypes';
import { useGuestListHeader } from 'graphQL/guestList/hooks';
import { useContentNotFound } from 'helpers/routingHelper';

import useTheme from 'helpers/useTheme';
import Header from 'components/GuestList/Header';
import SeriesSelect from 'components/GuestList/SeriesSelect/SeriesSelect';
import SeriesList from 'components/GuestList/SeriesList/SeriesList';
import Wrap from 'components/UI/Wrap';

const GuestListSeries: FC = () => {
  const posthogCapture = usePostHogCapture();

  const { id } = useParams<{ id: string }>();
  const { card, loading } = useGuestListHeader(id, CardTypeOptions.GuestListSeries);
  const { guestListSeries } = card ?? {};

  const handlerContentNotFound = useContentNotFound();
  const isDark = useTheme();

  if (!guestListSeries && !loading) {
    handlerContentNotFound();
  }

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.GuestListAction);
    posthogCapture(POSTHOG_EVENTS.GuestListSeriesOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {card && <Header card={card} isDark={isDark} isSeries />}
      {guestListSeries && (
        <Wrap paddingTop={0}>
          <SeriesSelect isDark={isDark} current={guestListSeries} />
          <SeriesList isDark={isDark} series={guestListSeries} />
        </Wrap>
      )}
    </>
  );
};

export default GuestListSeries;
