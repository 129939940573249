import { styled } from 'linaria/react';

import MainImage from '../../../assets/images/landing/twoPhones.webp';
import { BREAKPOINTS, COLORS, FONTS } from '../../../styles/constants';

export const StyledWrapper = styled.div`
  background: ${COLORS.brown.dark[100]};
  padding-top: 20px;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    background: linear-gradient(180deg, ${COLORS.brown.dark[100]} 50%, ${COLORS.white[100]} 50%);
    padding-top: 30px;
  }

  @media screen and (min-width: ${BREAKPOINTS.l}px) {
    background: ${COLORS.brown.dark[100]};
  }
`;
export const StyledLogoWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  @media screen and (min-width: ${BREAKPOINTS.l}px) {
    padding: 0 50px;
  }
`;

export const StyledMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
`;

export const StyledMenu = styled.ul`
  display: none;
  list-style: none;

  a {
    font: 600 14px/140% ${FONTS.secondary};
    color: ${COLORS.white[100]};
    text-decoration: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    display: flex;
    gap: 27px;
  }
`;

export const StyledButton = styled.button<{ largePaddings?: boolean }>`
  background: ${COLORS.white[100]};
  border-radius: 22px;
  border: none;
  color: ${COLORS.brown.dark[100]};
  cursor: pointer;
  font: 600 14px/140% ${FONTS.secondary};
  padding: ${({ largePaddings }) => (largePaddings ? '12px 40px' : '6px 30px')};

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 12px 40px;
  }
`;

export const StyledLogo = styled.img`
  height: 26px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    height: 38px;
  }
`;

export const StyledGreenBlock = styled.div`
  background: ${COLORS.green.light};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    align-items: flex-end;
    border-radius: 0 0 20px 20px;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 30px 0 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.l}px) {
    border-radius: 20px;
    margin: 30px 50px 50px;
  }
`;

export const StyledMainImage = styled.div`
  background: url(${MainImage}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 350px;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    background-position: initial;
    height: 450px;
    max-width: 835px;
  }

  @media screen and (min-width: ${BREAKPOINTS.l}px) {
    height: 550px;
  }
`;
export const StyledTextWrap = styled.div`
  max-width: 254px;
  padding-bottom: 42px;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-width: 454px;
  }

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    max-width: 514px;
    padding-left: 60px;
    padding-bottom: 52px;
    text-align: start;
  }
`;

export const StyledText = styled.p`
  font: 600 14px/140% ${FONTS.secondary};
  margin: 0;
  padding: 13px 0;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    font-size: 16px;
  }

  @media screen and (min-width: ${BREAKPOINTS.l}px) {
    font-size: 20px;
    padding: 15px 0 30px;
  }
`;
