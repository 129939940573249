import React, { FC } from 'react';

import useTheme from 'helpers/useTheme';

import { EUiButtonType } from 'components/UI/Button/constants';
import { getFilterButtonTestId } from 'constants/aqa/search';
import { StyledFilterBtn } from './styled';

const FilterBtn: FC<{ Icon: FC; title: string; onSetValue: () => void }> = ({ Icon, title, onSetValue }) => {
  const isDark = useTheme();

  return (
    <StyledFilterBtn
      data-testid={getFilterButtonTestId(title)}
      buttonType={EUiButtonType.Transparent}
      key={title}
      isDark={isDark}
      onClick={onSetValue}
    >
      <Icon />
      {title}
    </StyledFilterBtn>
  );
};

export default FilterBtn;
