import React, { FC, useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import { ChannelListSchema } from 'constants/graphqlTypes';

import TileSmall from 'components/List/TileSmall';
import SliderInteractive from 'components/SliderInteractive';

import { COLORS } from 'styles/constants';

import { StyledSectionElement, StyledSliderWrap } from '../styled';

const ListsSlider: FC<{
  items: ChannelListSchema[];
  isDark: boolean;
  onWaypointEnter?: () => void;
}> = ({ items, isDark, onWaypointEnter }) => {
  const [slideIndex, setSlideInd] = useState(0);

  useEffect(() => {
    if (items.length && slideIndex === items.length - 3) {
      onWaypointEnter?.();
    }
  }, [slideIndex]);

  return items.length === 1 && items?.[0] ? (
    <StyledSectionElement>
      <TileSmall
        item={items[0].item}
        counterUnreadPicks={items[0].counterUnreadPicks}
        enabledUserCovers={items[0].enabledUserCovers}
      />
    </StyledSectionElement>
  ) : (
    <StyledSliderWrap>
      <SliderInteractive
        onSlideChange={(ind) => setSlideInd(ind)}
        customSlidesPerView="auto"
        spaceBetween={12}
        offset={12}
        fontColor={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
        showBtnsSm={!!items.length}
        isInfinite={false}
        isPrevDisabled={slideIndex === 0}
        isNextDisabled={slideIndex === items.length - 2}
      >
        {items.map(
          ({ item, counterUnreadPicks, enabledUserCovers }) =>
            item && (
              <SwiperSlide key={item.id}>
                <TileSmall item={item} counterUnreadPicks={counterUnreadPicks} enabledUserCovers={enabledUserCovers} />
              </SwiperSlide>
            )
        )}
      </SliderInteractive>
    </StyledSliderWrap>
  );
};

export default ListsSlider;
