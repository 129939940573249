import React, { FC, useState } from 'react';

import useTheme from 'helpers/useTheme';
import SinglePageLayout from 'components/SinglePageLayout';
import useSuggestedUsersSearch from 'helpers/useSuggestedUsersSearch';
import ContentSubtitle from 'components/UI/ContentSubtitle';
import UserList from 'components/UserList';
import Button from 'components/UI/Button';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import NavWrap from 'components/Messages/Navigation';

import { EUiButtonType } from 'components/UI/Button/constants';
import { ROUTE_MESSAGES_CHAT } from 'routes';
import { StyledSection, StyledUsersSection } from 'components/Messages/UserInput/styled';
import { ChatType, UserObjectType } from 'constants/graphqlTypes';
import { UserInput } from 'components/Messages';
import { EButtonType } from 'components/UserList/constants';
import { COLORS } from 'styles/constants';
import { useCreateNewChat } from 'graphQL/messages/hooks';
import { useHistory } from 'react-router-dom';
import { transformRoute } from 'helpers/routingHelper';
import { StyledContentWrap, StyledUsersWrap } from './styled';

const MessagesNew: FC = () => {
  const [error, setError] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<UserObjectType[]>([]);

  const { text, setText, items, loadMore, loading } = useSuggestedUsersSearch({
    countSymbolsForStartSearch: 0,
    withoutBlockedAndBlockers: true,
  });

  const { push } = useHistory();

  const isDark = useTheme();

  const removeUser = (user: UserObjectType) => setSelectedUsers((prev) => prev.filter(({ id }) => id !== user.id));
  const handleUser = (user: UserObjectType) => {
    if (selectedUsers.some(({ id }) => id === user.id)) {
      removeUser(user);
    } else {
      setSelectedUsers((prev) => [...prev, user]);
      setText('');
    }
  };

  const {
    createChat,
    data: { loading: createChatLoading },
  } = useCreateNewChat((id: string) => push(transformRoute(ROUTE_MESSAGES_CHAT, { id })), setError);

  const handleNext = () =>
    selectedUsers.length &&
    createChat(
      selectedUsers.map(({ id }) => id),
      selectedUsers.length === 1 ? ChatType.Single : ChatType.Group
    );

  return (
    <>
      <NavWrap>
        <Button
          type={EUiButtonType.Emphasized}
          text="Next"
          handler={handleNext}
          isDark={isDark}
          isDisabled={!selectedUsers.length || createChatLoading}
        />
      </NavWrap>
      <SinglePageLayout paddingTop={52} paddingBottom={0}>
        <StyledContentWrap>
          <StyledSection>
            <ContentSubtitle color={!isDark ? COLORS.brown.dark[100] : ''} isDark={isDark} text="To" />
            <UserInput
              users={selectedUsers}
              handleRemove={removeUser}
              isDark={isDark}
              text={text}
              setText={setText}
              autoFocus
            />
          </StyledSection>
          <StyledUsersSection>
            <ContentSubtitle color={!isDark ? COLORS.brown.dark[100] : ''} isDark={isDark} text="Suggested" />
            <StyledUsersWrap>
              <UserList
                selected={selectedUsers}
                handleUser={handleUser}
                buttonType={EButtonType.Select}
                users={items}
                loadMore={loadMore}
                loading={loading}
                isDark={isDark}
                hasUsername
                setError={setError}
              />
            </StyledUsersWrap>
          </StyledUsersSection>
        </StyledContentWrap>
        {error && (
          <ConfirmationModal
            buttonText="OK"
            handleAccept={() => setError(false)}
            handleClickOutside={() => setError(false)}
            isWarning={false}
            titleText="Unable to send message"
            subTitle="You can't message this person because they've chosen not to receive messages."
          />
        )}
      </SinglePageLayout>
    </>
  );
};

export default MessagesNew;
