import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_BEFORE_GO } from 'routes';

import useTheme from 'helpers/useTheme';
import { useCollaborativeListsCount, useDeleteMyProfile } from 'graphQL/profile/hooks';
import {
  TEST_DELETE_EMAIL_US_LABEL,
  TEST_DELETE_QUESTIONS_LABEL,
  TEST_DELETE_WARNING_LABEL,
  TEST_ID_DELETE_VIEW_START,
} from 'constants/aqa/settings/accountManagement';

import ConfirmationModal from 'components/UI/ConfirmationModal';
import FullScreenLoader from 'components/FullScreenLoader';
import Layout from 'components/Onboarding/Layout';

import { StyledContactInfo, StyledInfo, StyledText } from './styled';

const DeleteMyAccountPage: FC = () => {
  const [isModalShowed, setIsModalShowed] = useState(false);
  const isDark = useTheme();
  const { totalCount, loading } = useCollaborativeListsCount();
  const { deleteMyProfile, loading: isDeleteLoading } = useDeleteMyProfile();
  const { goBack, push } = useHistory();
  const buttonText = !totalCount ? 'Delete my account' : 'Proceed to delete';
  const pagesToMenu = -1;

  const handleDelete = () => {
    setIsModalShowed(false);
    deleteMyProfile();
  };

  return (
    <Layout
      heading="Delete my account"
      text="This is permanent and cannot be undone. Here is what will happen:"
      primaryBtnText={loading ? '' : buttonText}
      secondaryBtnText="Cancel"
      isPrimaryBtnRed={!totalCount}
      isSecondaryBtnTransparent
      isPageScrollable
      blockScroll={false}
      isWideContent
      handlePrimaryBtn={() =>
        !totalCount
          ? setIsModalShowed(true)
          : push({ pathname: ROUTE_BEFORE_GO, state: { pagesToMenu: pagesToMenu - 1 } })
      }
      handleSecondaryBtn={() => goBack()}
      pageTestId={TEST_ID_DELETE_VIEW_START}
    >
      <StyledInfo isDark={isDark}>
        <div data-testid={TEST_DELETE_WARNING_LABEL}>
          <p>
            We will permanently erase all your personal information, lists, picks and thoughts from your single-user
            lists.
          </p>
          <p>
            We will remove you as a member of all collaborative lists you&apos;re in. Your thoughts will also be
            removed. You will be given the option to keep your picks in the list; picks that only you have added will
            remain unattributed.
          </p>
          <p>
            We will permanently erase all message threads with individual users and erase all your messages in group
            threads.
          </p>
          <p>
            We will permanently erase your data stored in the app, such as search history, network connections and
            personal info.
          </p>
          <p>You will be unable to access or reactivate your guide:human account.</p>
        </div>
        <StyledContactInfo>
          <StyledText data-testid={TEST_DELETE_QUESTIONS_LABEL} isBold>
            Questions?
          </StyledText>
          <StyledText data-testid={TEST_DELETE_EMAIL_US_LABEL} isDark={isDark}>
            Email us at <a href="mailto:help@guidehuman.co">help@guidehuman.co</a>
          </StyledText>
        </StyledContactInfo>
      </StyledInfo>

      {isModalShowed && (
        <ConfirmationModal
          titleText="Confirm deletion"
          subTitle="Are you sure you want to delete your account? You cannot undo this."
          buttonText="delete"
          handleAccept={handleDelete}
          handleCancel={() => setIsModalShowed(false)}
        />
      )}
      {isDeleteLoading && <FullScreenLoader />}
    </Layout>
  );
};

export default DeleteMyAccountPage;
