import React, { Dispatch, FC, SetStateAction } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'components/UI/Button';
import useTheme from 'helpers/useTheme';
import usePageType from 'helpers/usePageType';
import { useGetProfileLists } from 'graphQL/cards/userCards/hooks';
import { transformRoute } from 'helpers/routingHelper';
import { EUiButtonType } from 'components/UI/Button/constants';
import { PrivacyOptions } from 'constants/graphqlTypes';
import { ROUTE_USER_ALL_PICKS } from 'routes';

import { StyledContainer, StyledSeeAllPicks, StyledToggles } from './styled';

const { Emphasized, Primary, Transparent } = EUiButtonType;

interface IProfilePrivacyButton {
  showPublicLists: boolean;
  picksTotalCount: number;
  setShowPublicLists: Dispatch<SetStateAction<boolean>>;
}

const ProfilePrivacyButtons: FC<IProfilePrivacyButton> = ({ showPublicLists, picksTotalCount, setShowPublicLists }) => {
  const { push } = useHistory();
  const { isMyProfile } = usePageType();
  const { profileName } = useParams<{ profileName: string }>();
  const isDark = useTheme();

  const { totalCount } = useGetProfileLists({ limit: 0, privacy: PrivacyOptions.Private });
  const hideButtons = totalCount === 0 && !isMyProfile;
  const showComponent = !!picksTotalCount || !hideButtons;

  return showComponent ? (
    <StyledContainer>
      {!hideButtons && (
        <StyledToggles>
          <Button
            isDark={isDark}
            text="Public"
            type={showPublicLists ? Emphasized : Primary}
            handler={() => setShowPublicLists(true)}
          />
          <Button
            isDark={isDark}
            text="Private"
            type={!showPublicLists ? Emphasized : Primary}
            handler={() => setShowPublicLists(false)}
          />
        </StyledToggles>
      )}
      <StyledSeeAllPicks>
        <Button
          isDark={isDark}
          text="See all picks"
          type={Transparent}
          handler={() => push(transformRoute(ROUTE_USER_ALL_PICKS, { profileName }))}
        />
      </StyledSeeAllPicks>
    </StyledContainer>
  ) : null;
};

export default ProfilePrivacyButtons;
