import { GetUserHiddenWordsQuery } from 'constants/graphqlTypes';

export const updateUserHiddenWords = (
  prev: GetUserHiddenWordsQuery,
  next?: GetUserHiddenWordsQuery
): GetUserHiddenWordsQuery =>
  prev.getUserHiddenWords?.items && next?.getUserHiddenWords?.items
    ? {
        ...prev,
        getUserHiddenWords: {
          ...prev.getUserHiddenWords,
          __typename: 'UserHiddenWordsListObjectType',
          items: [...prev.getUserHiddenWords?.items, ...next?.getUserHiddenWords?.items],
        },
      }
    : prev;
