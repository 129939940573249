import React, { FC, useState } from 'react';

import Grain from 'components/UI/Grain';
import getStyles from 'helpers/getStyles';
import handleVibrate from 'helpers/handleVibrate';
import Loader from 'components/UI/Loader';

import { CardSchema, ECardLayoutOptions } from 'constants/graphqlTypes';
import { TEST_CARD_FIRST_UP_IMAGE, TEST_CARD_COMMON_IMAGE, getCardImageId } from 'constants/aqa/card';
import { StyledBody, StyledLink, StyledLoader, StyledSpace } from './styled';
import { useListItemUrl } from '../helpers/hooks';

import Content from './Content';
import Image from './Image';
import { getTransformedGradient } from './helpers';

const Body: FC<{ card: CardSchema; isFirstUp?: boolean; dataTestId?: string }> = ({ card, isFirstUp, dataTestId }) => {
  const [isVertical, setIsVertical] = useState(false);
  const { cardStyle, id } = card;
  const { bgColor, layout } = getStyles(cardStyle);
  const url = useListItemUrl(id);
  const isBorderlessCard = layout === ECardLayoutOptions.Borderless;
  return (
    <StyledLink className="card" to={url} onClick={handleVibrate}>
      <StyledBody
        className="card-body"
        bg={isBorderlessCard ? getTransformedGradient(bgColor) : bgColor}
        isReverse={isBorderlessCard}
      >
        <Grain />
        <Content
          isImageVertical={isVertical}
          dataTestId={dataTestId ?? (isFirstUp ? TEST_CARD_FIRST_UP_IMAGE : TEST_CARD_COMMON_IMAGE)}
          bigTitle={isFirstUp}
          card={card}
        />
        <Image
          setIsVertical={setIsVertical}
          dataTestId={getCardImageId(dataTestId ?? (isFirstUp ? TEST_CARD_FIRST_UP_IMAGE : TEST_CARD_COMMON_IMAGE))}
          card={card}
        />
        <StyledLoader className="card-image-loader">
          <Loader />
        </StyledLoader>
        {layout === ECardLayoutOptions.Plain && <StyledSpace isFirstUp={isFirstUp} />}
      </StyledBody>
    </StyledLink>
  );
};

export default Body;
