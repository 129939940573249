import React, { FC } from 'react';

import useTheme from 'helpers/useTheme';
import LoaderContent from 'components/UI/LoaderContent/LoaderContent';

import { StyledDeleteText, StyledLoaderContainer } from './styled';

const FullScreenLoader: FC = () => {
  const isDark = useTheme();

  return (
    <StyledLoaderContainer isDark={isDark}>
      <StyledDeleteText isDark={isDark}>Deleting your account</StyledDeleteText>
      <LoaderContent isDark={isDark} />
    </StyledLoaderContainer>
  );
};

export default FullScreenLoader;
