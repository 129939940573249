import React, { FC, useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import useClickOutside from 'helpers/useClickOutside';
import useModal from 'helpers/useModal';
import useTheme from 'helpers/useTheme';
import { useGetMediaTagsFilters } from 'graphQL/feeds/hooks';
import { ESearchTag, SUGGESTED_TIMES } from 'components/Search/helpers/constants';
import { getSuggestionTypeItemTestId } from 'constants/aqa/search';
import { ISuggestionsProps } from './models';

import { StyledItem, StyledList, StyledSuggestionContainer } from './styled';

const SuggestionsText: FC<ISuggestionsProps> = ({ type, setText }) => {
  const [suggestedTypes, setSuggestedTypes] = useState<string[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const { tags: allMediaTags, loading, loadMoreTags } = useGetMediaTagsFilters();
  const textItems = type === ESearchTag.Type ? suggestedTypes : SUGGESTED_TIMES;
  const isDark = useTheme();

  const handleTextClick = (textItem: string) =>
    setText((prev) => {
      const formattedTextItem =
        type === ESearchTag.Time
          ? (textItem === 'Last 24 hours' ? 'today' : textItem).toLocaleLowerCase().replaceAll(/\s|last/g, '')
          : textItem.toLocaleLowerCase();

      return `${prev}${formattedTextItem} `;
    });

  useClickOutside(ref, () => setText((prev) => prev.concat(' ')));
  useModal();

  useEffect(() => {
    if (!!allMediaTags && allMediaTags.length) {
      setSuggestedTypes(allMediaTags.reduce((acc: string[], tag) => (tag.name ? acc.concat(tag.name) : acc), []));
    }
  }, [allMediaTags]);

  return (
    <StyledSuggestionContainer ref={ref} isDark={isDark}>
      <StyledList isDark={isDark}>
        {textItems.map((textItem, index) => (
          <StyledItem
            data-testid={getSuggestionTypeItemTestId(type, index, suggestedTypes)}
            key={textItem}
            onClick={() => handleTextClick(textItem)}
          >
            {textItem}
          </StyledItem>
        ))}
        {!loading && <Waypoint onEnter={loadMoreTags} />}
      </StyledList>
    </StyledSuggestionContainer>
  );
};

export default SuggestionsText;
