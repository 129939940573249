import { styled } from 'linaria/react';

import { StyledWrap as ImportWrap } from 'components/TabModal/styled';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled(ImportWrap)`
  padding-top: 32px;

  & > .dot-btn {
    display: none;
  }
`;

export const StyledListItem = styled.li`
  width: 100%;
  align-self: end;
  margin-bottom: 32px;
`;

export const StyledFilterItem = styled.div<{ isDark: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 32px;
  width: 100%;
  font-size: 22px;

  svg {
    cursor: pointer;
    margin-right: 10px;

    path {
      fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    }
  }
`;

export const StyledItemWrap = styled.div<{ isDark: boolean }>`
  display: flex;
  align-items: center;

  svg {
    path {
      stroke: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    }
  }
`;

export const StyledAlertWrap = styled.div`
  padding: 0 24px;
`;

export const StyledHiddenTitle = styled.p`
  margin: 0 24px 32px;
  font: 400 14px/140% ${FONTS.primary};
`;
