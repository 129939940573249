/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useRef, useState } from 'react';

export const useInputHeight = (
  text: string,
  height?: number,
  el?: HTMLDivElement | HTMLTextAreaElement | null,
  nodeScrollHeight?: number
): void => {
  useEffect(() => {
    if (el) {
      el.style.height = '0px';
      const { scrollHeight } = el;

      if (height && scrollHeight >= height) {
        el.style.height = `${height}px`;
      } else if (!height || (height && scrollHeight < height)) {
        el.style.height = '0px';
        el.style.height = `${scrollHeight + 1}px`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, text, nodeScrollHeight]);
};

export const useComputedRef = () => {
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const [scrollHeight, setScrollHeight] = useState(0);

  const handleRef = useCallback((node: HTMLTextAreaElement) => {
    ref.current = node;
    setScrollHeight(node?.scrollHeight);
  }, []);

  return { ref, handleRef, scrollHeight };
};
