import React, { FC, useRef, useState } from 'react';

import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import { EVisibilityStatusOptions, Maybe } from 'constants/graphqlTypes';
import useTheme from 'helpers/useTheme';
import useClickOutside from 'helpers/useClickOutside';
import RolesList from '../RolesList';
import { EVisibilityRoles } from '../constants';

import { StyledButtonName, StyledRolesButton } from '../styled';

interface IPermission {
  role?: Maybe<EVisibilityStatusOptions>;
  type: EVisibilityRoles;
  dataTestId?: string;
}

const { Everyone, Following, NoOne } = EVisibilityStatusOptions;

const RolesButton: FC<IPermission> = ({ role, type, dataTestId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDark = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside(ref, () => setIsOpen(false));

  return (
    <div ref={ref}>
      <StyledRolesButton
        data-testid={dataTestId}
        isDark={isDark}
        isOpen={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {!!role && (
          <StyledButtonName>
            {role === Everyone && 'Everyone'}
            {role === Following && 'Following'}
            {role === NoOne && 'No one'}
          </StyledButtonName>
        )}
        <ChevronLeft viewBox="0 0 24 24" height="14" width="14" />
      </StyledRolesButton>
      {isOpen && <RolesList type={type} role={role} setIsOpen={setIsOpen} />}
    </div>
  );
};

export default RolesButton;
