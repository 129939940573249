import { styled } from 'linaria/react';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/ChevronLeft.svg';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledButtonWrapper = styled.div`
  align-items: center;
  bottom: 130px;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;

  @media screen and (max-width: ${BREAKPOINTS.s}px) {
    bottom: 96px;
  }
`;

export const StyledScrollButton = styled.button`
  align-items: center;
  background-color: ${COLORS.white.off[100]};
  border: none;
  border-radius: 22px;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 0 10px;
`;

export const StyledCevronIcon = styled(ChevronLeftIcon)`
  transform: rotate(270deg);
`;

export const StyledScrollButtonText = styled.div`
  color: ${COLORS.brown.dark[100]};
  font-family: ${FONTS.secondary};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 20px;
  padding: 0 5px;
`;
