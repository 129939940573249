import React, { FC, useState } from 'react';
import { Mousewheel } from 'swiper';
import { SwiperSlide, Swiper } from 'swiper/react';

import useTheme from 'helpers/useTheme';
import CardDetails from 'components/CardDetails';
import ThoughtAnimation from 'components/Card/Actions/ThoughtAnimation';
import UrlAvatars from 'components/UI/UrlAvatars';
import ReactionsModal from 'components/Reactions/ReactionsModal';
import ReactionQuickButton from 'components/Reactions/ReactionQuickButton';
import LoaderContent from 'components/UI/LoaderContent';
import ReactionsToken from 'components/Reactions/ReactionsToken';

import { REF_PLACEHOLDER } from 'components/Explore/Item/helpers';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { ImageSetSchema } from 'constants/graphqlTypes';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';

import {
  StyledImmersiveDescription,
  StyledImmersiveReaction,
  StyledImmersiveSlider,
  StyledImmersiveWrap,
  StyledImmersiveCreators,
  StyledImmersiveTitle,
  StyledImmersivePage,
  StyledImmersiveTotal,
} from './styled';
import { useHideMainNavigation, useImmersiveExplore, useImmersiveSwiper } from './helpers/hooks';

import ImmersiveBackground from './ImmersiveBackground';
import ImmersiveNavigation from './ImmersiveNavigation';

interface IImmersionCoverProps {
  id: string;
  title?: string | null;
  description?: string | null;
  total?: number;
  bgItems: ImageSetSchema[];
  avatars: string[];
  totalAvatars: number;
  loading: boolean;
  firstItemId?: string;
  hasReaction?: boolean;
  isFeed?: boolean;
}

const ImmersiveCover: FC<IImmersionCoverProps> = ({
  id,
  title,
  description,
  total,
  bgItems,
  avatars,
  totalAvatars,
  loading,
  firstItemId,
  hasReaction,
  isFeed,
}) => {
  const [isReactionButtonMenuShown, setIsReactionButtonMenuShown] = useState(false);

  const { setIsReactionsModalOpen, isReactionsModalOpen } = useReactionMenu();
  const { card } = useGetDetailCard(firstItemId, id, undefined, REF_PLACEHOLDER);
  const { handleSwipe, handleSwiper } = useImmersiveSwiper();

  const isDark = useTheme();
  const handleExplore = useImmersiveExplore(id, firstItemId, isFeed);

  useHideMainNavigation();

  return (
    <StyledImmersiveSlider>
      <Swiper
        speed={600}
        followFinger
        direction="vertical"
        grabCursor
        mousewheel={{ sensitivity: 0.6, thresholdTime: 100 }}
        modules={[Mousewheel]}
        touchStartPreventDefault={false}
        onSwiper={handleSwiper}
        onTransitionEnd={({ realIndex }) => realIndex && handleExplore()}
      >
        <SwiperSlide>
          <StyledImmersivePage isDark={isDark} isEmpty={!loading && !bgItems.length}>
            {loading ? (
              <LoaderContent isDark={isDark} isFullScreen />
            ) : (
              <>
                <ImmersiveBackground handleTap={handleSwipe} items={bgItems} />
                <StyledImmersiveWrap>
                  <StyledImmersiveCreators>
                    <UrlAvatars urls={avatars} limit={3} total={totalAvatars} isBig />
                  </StyledImmersiveCreators>
                  <StyledImmersiveTitle>
                    {title}
                    {total !== undefined ? (
                      <StyledImmersiveTotal key={total}>{total}</StyledImmersiveTotal>
                    ) : (
                      <StyledImmersiveTotal />
                    )}
                  </StyledImmersiveTitle>
                  {description && (
                    <StyledImmersiveDescription>
                      <ThoughtAnimation lines={4} hasMoreButton={false} text={description} />
                    </StyledImmersiveDescription>
                  )}
                  {hasReaction && (
                    <StyledImmersiveReaction>
                      <ReactionQuickButton
                        isReactionButtonMenuShown={isReactionButtonMenuShown}
                        setIsReactionButtonMenuShown={setIsReactionButtonMenuShown}
                        setIsReactionsModalOpen={setIsReactionsModalOpen}
                        entityId={id}
                        isDark
                      />
                      <ReactionsToken contentIds={{ listId: id }} isDark={isDark} />
                    </StyledImmersiveReaction>
                  )}
                </StyledImmersiveWrap>
                <ImmersiveNavigation
                  isReactionsModalOpen={isReactionsModalOpen}
                  isReactionButtonMenuShown={isReactionButtonMenuShown}
                  isExploreAllowed={!!card}
                  isFeed={!!isFeed}
                  handleSwipe={handleSwipe}
                  id={id}
                />
              </>
            )}
            {isReactionsModalOpen && <ReactionsModal id={id} handleClose={() => setIsReactionsModalOpen(false)} />}
          </StyledImmersivePage>
        </SwiperSlide>
        {card && (
          <SwiperSlide>
            <CardDetails item={card} />
          </SwiperSlide>
        )}
      </Swiper>
    </StyledImmersiveSlider>
  );
};

export default ImmersiveCover;
