import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ROUTE_ABOUT } from 'routes';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';

import TitleAnimation from './TitleAnimation';

import {
  StyledWrapper,
  StyledGreenBlock,
  StyledLogo,
  StyledLogoWrap,
  StyledButton,
  StyledMenuWrapper,
  StyledMenu,
  StyledMainImage,
  StyledText,
  StyledTextWrap,
} from './styled';

const TopBlock: FC<{ handleGetStarted: () => void }> = ({ handleGetStarted }) => {
  const posthogCapture = usePostHogCapture();

  return (
    <StyledWrapper>
      <StyledLogoWrap>
        <StyledLogo src="https://ivysaur-b-gql-image.s3.amazonaws.com/logo_white_svg.svg" alt="Logo" />
        <StyledMenuWrapper>
          <StyledMenu>
            <li>
              <Link to={`${ROUTE_ABOUT}?welcome=true`} onClick={() => posthogCapture(POSTHOG_EVENTS.LandingAboutUs)}>
                About us
              </Link>
            </li>
            <li>
              <a href="mailto:hello@guidehuman.co" onClick={() => posthogCapture(POSTHOG_EVENTS.LandingContactUs)}>
                Contact
              </a>
            </li>
          </StyledMenu>
          <StyledButton
            onClick={() => {
              posthogCapture(POSTHOG_EVENTS.LandingGSHeaderButton);
              handleGetStarted();
            }}
          >
            Get started
          </StyledButton>
        </StyledMenuWrapper>
      </StyledLogoWrap>
      <StyledGreenBlock>
        <StyledMainImage />
        <StyledTextWrap>
          <TitleAnimation />
          <StyledText>
            A new destination to collect, share and discover the most valuable information on the web based on personal
            interests.
          </StyledText>
          <StyledButton
            largePaddings
            onClick={() => {
              posthogCapture(POSTHOG_EVENTS.LandingGSButton);
              handleGetStarted();
            }}
          >
            Get started
          </StyledButton>
        </StyledTextWrap>
      </StyledGreenBlock>
    </StyledWrapper>
  );
};

export default TopBlock;
