import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTE_ONBOARDING_PROFILE, ROUTE_ONBOARDING_PROFILE_INTERESTS } from 'routes';

import ProfileSetup from 'components/OnboardingV2/ProfileSetup';
import { ROUTE_ONBOARDING_PROFILE_USERNAME, ROUTE_ONBOARDING_PROFILE_INTRO } from '../../../routes';
import Topics from '../Topics';
import Bio from '../Bio';
import Username from '../Username';

const ProfileSetupRoutes = () => {
  return (
    <Switch>
      <Route exact path={ROUTE_ONBOARDING_PROFILE}>
        <ProfileSetup />
      </Route>
      <Route path={ROUTE_ONBOARDING_PROFILE_USERNAME}>
        <Username />
      </Route>
      <Route path={ROUTE_ONBOARDING_PROFILE_INTRO}>
        <Bio />
      </Route>
      <Route path={ROUTE_ONBOARDING_PROFILE_INTERESTS}>
        <Topics />
      </Route>
    </Switch>
  );
};

export default ProfileSetupRoutes;
