import React, { Dispatch, FC, SetStateAction, KeyboardEvent } from 'react';

import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';

import { StyledContainer, StyledLabel, StyledInput } from './styled';

interface IInputProps {
  setText: Dispatch<SetStateAction<string>>;
  text: string;
  isDark?: boolean;
  isDisabled?: boolean;
  placeholder: string;
  hasCrossIcon?: boolean;
  Icon?: FC;
  hasAutofocus?: boolean;
  handleKeyPress?: () => void;
}

const Input: FC<IInputProps> = ({
  setText,
  text,
  isDark = true,
  placeholder,
  Icon,
  isDisabled,
  hasCrossIcon,
  hasAutofocus,
  handleKeyPress,
}) => {
  const onEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleKeyPress?.();
    }
  };

  return (
    <StyledContainer className="input">
      {Icon && (
        <StyledLabel isDark={isDark} htmlFor="input">
          <Icon />
        </StyledLabel>
      )}
      <StyledInput
        hasPaddingLeft={!!Icon}
        hasCrossIcon={hasCrossIcon}
        isDark={isDark}
        id="input"
        placeholder={placeholder}
        value={text}
        onChange={({ currentTarget: { value } }) => setText(value)}
        disabled={isDisabled}
        autoComplete="off"
        autoFocus={!!hasAutofocus}
        onKeyDown={onEnter}
      />
      {!!text.length && hasCrossIcon && (
        <StyledLabel isLast isPointer isDark={isDark} onClick={() => setText('')}>
          <CrossIcon width={8} height={8} viewBox="0 0 12 12" />
        </StyledLabel>
      )}
    </StyledContainer>
  );
};

export default Input;
