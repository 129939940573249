import React, { FC, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import {
  ROUTE_SPARK_RESP_ADD_PICK,
  ROUTE_SPARK_RESP_ADD_STYLE,
  ROUTE_SPARK_RESP_ADD_THOUGHT,
  ROUTE_SPARK_RESP_STYLE_DESCRIPTION,
} from 'routes';
import { ALL_PICKS } from 'constants/common';
import { CardSchema, CardStyleSchema } from 'constants/graphqlTypes';
import { useGoBack } from 'helpers/routingHelper';
import { useExtractData } from 'components/AddContent/helpers/hooks';
import { COLORS } from 'styles/constants';
import AddPick from './AddPick';
import AddStyle from './AddStyle';
import AddThought from './AddThought';
import ReportStyles from './ReportStyles';

const AddResponseToSpark: FC = () => {
  const [url, setUrl] = useState('');

  const [isPicked, setIsPicked] = useState(false);

  const [style, setStyle] = useState<CardStyleSchema | undefined>();
  const [styleItemId, setStyleItemId] = useState(0);
  const [color, setColor] = useState(COLORS.transparent);

  const { pickId, cardId, title, image, setExtractData } = useExtractData();
  const goBack = useGoBack();

  const { state } = useLocation<{ variable: { spark?: CardSchema } }>();

  const spark = state?.variable?.spark;

  useEffect(() => {
    if (state.variable && !spark?.id) {
      goBack();
    }
  }, [spark, state]);

  return (
    <Switch>
      <Route exact path={ROUTE_SPARK_RESP_ADD_PICK}>
        <AddPick
          setExtractData={setExtractData}
          spark={spark}
          handleClose={goBack}
          setIsPicked={setIsPicked}
          url={url}
          setUrl={setUrl}
        />
      </Route>
      <Route exact path={ROUTE_SPARK_RESP_ADD_STYLE}>
        <AddStyle
          setColor={setColor}
          setItemId={setStyleItemId}
          setStyle={setStyle}
          handleClose={goBack}
          listTitle={ALL_PICKS}
          cardId={cardId}
          preExtractImage={image}
          preExtractTitle={title}
          itemId={styleItemId}
          color={color}
          pickId={pickId}
        />
      </Route>
      <Route exact path={ROUTE_SPARK_RESP_ADD_THOUGHT}>
        {spark && (
          <AddThought
            spark={spark}
            pickId={pickId}
            cardId={cardId}
            cardStyle={style}
            handleClose={goBack}
            listTitle={ALL_PICKS}
            isPicked={isPicked}
          />
        )}
      </Route>
      <Route path={ROUTE_SPARK_RESP_STYLE_DESCRIPTION}>
        <ReportStyles cardId={cardId} />
      </Route>
    </Switch>
  );
};

export default AddResponseToSpark;
