import React, { FC } from 'react';
import Truncate from 'react-truncate-markup';

import useIsModalOpen from 'helpers/useIsModalOpen';
import useGuideTour from 'helpers/useGuideTour';
import { IToast } from 'providers/helpers/models';
import { EUiButtonType } from 'components/UI/Button/constants';
import { TEST_ID_TOAST_VIEW } from 'constants/aqa/common';

import {
  StyledWrap,
  StyledImage,
  StyledAddedTo,
  StyledItemName,
  StyledItemEnding,
  StyledEmoji,
  StyledItemAltEnding,
  StyledItemTruncated,
  StyledButton,
} from './styled';

interface IToastProps extends IToast {
  handleHide: () => void;
}
const Toast: FC<IToastProps> = ({
  isToastOpen,
  toastAction,
  toastError,
  toastId,
  toastImg,
  toastEmoji,
  toastItemName,
  toastItemStart,
  toastItemEnding,
  toastItemAltEnding,
  toastType,
  toastLinkText,
  customFontWeight,
  handleToastButton,
  handleHide,
  linesCount,
  toastHandler,
  customBottomCoord,
}) => {
  const { isGuideTour } = useGuideTour();

  const isModalOpen = useIsModalOpen();
  const lines = linesCount || (toastError ? 2 : 1);
  const isTextCentered = !toastId && !toastType && !toastImg && !toastEmoji && !toastLinkText;
  const isFontNormal = !!toastId && !!toastType && !!toastImg;

  const buttonHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    handleToastButton?.();
    handleHide();
  };

  return isToastOpen && !isGuideTour ? (
    <StyledWrap
      data-testid={TEST_ID_TOAST_VIEW}
      onClick={toastHandler ?? handleHide}
      isAboveTabBar={isModalOpen}
      toastError={toastError}
      isClickable={!!toastHandler}
      customBottomCoord={customBottomCoord}
    >
      {toastImg && <StyledImage src={toastImg} />}
      {toastAction && <StyledAddedTo>{toastAction}</StyledAddedTo>}
      {toastEmoji && <StyledEmoji>{toastEmoji}</StyledEmoji>}
      <StyledItemName
        isFontNormal={isFontNormal}
        isTextCenter={isTextCentered}
        isNotTruncated={!!linesCount}
        hasAltEnding={!!toastItemAltEnding}
        customFontWeight={customFontWeight}
      >
        {toastItemStart && <StyledItemEnding> {toastItemStart}</StyledItemEnding>}
        {toastItemName && (
          <Truncate lines={lines} ellipsis={toastItemAltEnding ? "...'s" : '...'}>
            <StyledItemTruncated hasAltEnding={!!toastItemAltEnding}>{toastItemName}</StyledItemTruncated>
          </Truncate>
        )}
        {toastItemEnding && <StyledItemEnding> {toastItemEnding}</StyledItemEnding>}
        {toastItemAltEnding && <StyledItemAltEnding>{toastItemAltEnding}</StyledItemAltEnding>}
      </StyledItemName>
      {toastLinkText && handleToastButton && (
        <StyledButton onClick={buttonHandler} buttonType={EUiButtonType.Emphasized} isDark={false}>
          {toastLinkText}
        </StyledButton>
      )}
    </StyledWrap>
  ) : null;
};

export default Toast;
