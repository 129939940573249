import { styled } from 'linaria/react';

import { StyledItem as CustomStyledItem } from 'components/AddContent/Menu/styled';

export const StyledItem = styled(CustomStyledItem)<{ isSelected: boolean }>`
  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 12.4444L10.3333 16L17 8' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    content: ${({ isSelected }) => (isSelected ? "''" : 'none')};
    height: 44px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
  }
`;
