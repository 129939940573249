import React, { FC } from 'react';

import { CardSchema } from 'constants/graphqlTypes';
import Grain from 'components/UI/Grain';
import Avatar from 'components/UI/Avatar';
import { EMPTY_ITEMS } from 'constants/common';
import Avatars from 'components/UI/Avatars';
import UILink from 'components/UI/Link';
import { getGradientImage } from 'components/Sparks/constants';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_SPARK_DETAIL } from 'routes';
import { getNumberOfResponsesText } from 'helpers/sparks';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';

import {
  StyledAvatarWrap,
  StyledCardContent,
  StyledSparkTitle,
  StyledUserText,
  StyledInfo,
  StyledFooter,
  StyledSparkTitleWrap,
  StyledUsername,
  StyledText,
} from './styled';

interface ICardProps {
  card: CardSchema;
  isDark: boolean;
  hideFooter?: boolean;
}

const Card: FC<ICardProps> = ({ card, isDark, hideFooter }) => {
  const { user, cardStyle, title, sparkResponses, usersWhoAnswered } = card;
  const { items } = usersWhoAnswered ?? EMPTY_ITEMS;

  const hasResponses = !!sparkResponses?.totalCount;
  const footerText = getNumberOfResponsesText(sparkResponses?.totalCount);

  const bg = getGradientImage(cardStyle?.bgColor?.predefinedGradientName ?? '');

  return user ? (
    <div>
      <UILink route={transformRoute(ROUTE_SPARK_DETAIL, { sparkId: card.id })}>
        <StyledCardContent bg={bg}>
          <Grain />
          <StyledInfo>
            <StyledAvatarWrap>
              <Avatar user={user} dimension={80} />
            </StyledAvatarWrap>
            <StyledUserText>
              <StyledUsername>{user?.userInfo?.firstName ?? user?.username}</StyledUsername>
              {user?.isVerified && <VerificationBadge />}
              <StyledText>&nbsp;wonders</StyledText>
            </StyledUserText>
            <StyledSparkTitleWrap>
              <StyledSparkTitle>{title}</StyledSparkTitle>
            </StyledSparkTitleWrap>
          </StyledInfo>
        </StyledCardContent>
      </UILink>
      {!hideFooter && (
        <StyledFooter isDark={isDark}>
          {hasResponses ? (
            <>
              <span>{footerText}</span>
              <Avatars users={items} limit={2} />
            </>
          ) : (
            <span>Be the first to respond?</span>
          )}
        </StyledFooter>
      )}
    </div>
  ) : null;
};

export default Card;
