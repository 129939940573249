import React, { FC } from 'react';

import StyledWarning from './styled';

const Warning: FC<{ isDark: boolean; dataTestId?: string }> = ({ isDark, dataTestId, children }) => (
  <StyledWarning data-testid={dataTestId} isDark={isDark}>
    {children}
  </StyledWarning>
);

export default Warning;
