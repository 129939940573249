import { styled } from 'linaria/react';

import { COLORS, FONTS } from '../../styles/constants';

export const StyledWrap = styled.section<{ isFirst: boolean }>`
  padding-top: ${({ isFirst }) => (isFirst ? 32 : 0)}px;
`;

export const StyledList = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
`;

export const StyledListItem = styled.li`
  padding-bottom: 16px;
  position: relative;
  width: 100%;
`;

export const StyledListTitle = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: block;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
  padding-bottom: 16px;
`;
