import { styled } from 'linaria/react';

import { Link } from 'react-router-dom';
import { COLORS } from 'styles/constants';
import { StyledBody, StyledFooter as ImportFooter } from '../Common/styled';

export const StyledWrap = styled(StyledBody)`
  border-radius: 0;
  gap: 16px;
  min-height: 540px;
  padding: 40px 12px 24px;
`;

export const StyledImage = styled.img`
  border-radius: 8px;
  height: auto;
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  color: ${COLORS.white[100]};
  text-decoration: none;
`;

export const StyledContent = styled.div<{ isReverse?: boolean }>`
  display: flex;
  gap: ${({ isReverse }) => (isReverse ? 16 : 0)}px;
  flex-direction: ${({ isReverse }) => `column${isReverse ? '-reverse' : ''}`};
`;

export const StyledFooter = styled(ImportFooter)<{ hasImage: boolean }>`
  align-items: flex-start;
  cursor: pointer;
  flex: 1;
  margin-top: ${({ hasImage }) => (hasImage ? 0 : -16)}px;
  padding: 0 12px 24px;
`;
