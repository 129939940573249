import { styled } from 'linaria/lib/react';

import { StyledButtonWrap as ImportedWrap } from 'components/TopicsModal/styled';

export const StyledTagsWrap = styled.div`
  padding-bottom: 120px;

  button {
    min-height: 44px;
    padding: 8px 0;
  }

  p {
    text-transform: capitalize;
  }
`;

export const StyledButtonWrap = styled(ImportedWrap)`
  align-items: center;
  flex-direction: column;

  button {
    max-width: 342px;
    width: 100%;
  }
`;
