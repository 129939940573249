import React, { FC } from 'react';

import Badge from 'components/UI/Badge';
import { IThoughtItem } from 'components/Thoughts/helpers/helpers';

import { StyledImage, StyledTitle } from 'components/TabModal/styled';
import { StyledHeader, StyledText, StyledWrap } from './styled';

const ThoughtContent: FC<{ image?: string; title: string; item: IThoughtItem }> = ({ image, title, item }) => {
  const { user, text } = item;
  return (
    <StyledWrap>
      <StyledHeader withImage>
        {image && <StyledImage src={image} />}
        {title && <StyledTitle>{title}</StyledTitle>}
      </StyledHeader>
      <Badge user={user} />
      <StyledText>{text}</StyledText>
    </StyledWrap>
  );
};

export default ThoughtContent;
