import React, { FC } from 'react';

import { useUserSettings } from 'graphQL/contextualIntro/hooks';
import SinglePageLayout from 'components/SinglePageLayout';
import { StyledItem } from 'components/AddContent/Menu/styled';
import { StyledList } from 'pages/Settings/styled';
import useTheme from 'helpers/useTheme';
import {
  TEST_CONNECTING_WITH_OTHERS_ALLOW_COLLABORATE,
  TEST_CONNECTING_WITH_OTHERS_ALLOW_MESSAGE,
  TEST_ID_CONNECTING_WITH_OTHERS_START,
} from 'constants/aqa/settings/accountManagement';
import { EVisibilityRoles } from './constants';
import Permission from './Permission';

import { StyledListWrap } from './styled';

const ConnectingWithOthers: FC = () => {
  const isDark = useTheme();
  const { messagesAndThoughtsVisibility, collaborativeListsVisibility } = useUserSettings();

  return (
    <SinglePageLayout
      pageTestId={TEST_ID_CONNECTING_WITH_OTHERS_START}
      title="Connecting with others"
      subTitle="We give you the ability to control who can interact with you on guide:human."
    >
      <StyledListWrap>
        <StyledList isDark={isDark}>
          <StyledItem>
            <Permission
              dataTestId={TEST_CONNECTING_WITH_OTHERS_ALLOW_MESSAGE}
              type={EVisibilityRoles.MessagesAndThoughtsVisibility}
              role={messagesAndThoughtsVisibility}
              text="Allow people to message me and react to my thoughts"
            />
          </StyledItem>
          <StyledItem>
            <Permission
              dataTestId={TEST_CONNECTING_WITH_OTHERS_ALLOW_COLLABORATE}
              type={EVisibilityRoles.CollaborativeListsVisibility}
              role={collaborativeListsVisibility}
              text="Allow people to add me to collaborate on lists"
            />
          </StyledItem>
        </StyledList>
      </StyledListWrap>
    </SinglePageLayout>
  );
};

export default ConnectingWithOthers;
