import { CardSchema, CardTypeOptions } from './graphqlTypes';

export const FILE_MAX_SIZE = 100;

export enum EQueryFetchPolicy {
  CacheFirst = 'cache-first',
  CacheOnly = 'cache-only',
  CacheAndNetwork = 'cache-and-network',
  NetworkOnly = 'network-only',
  NoCache = 'no-cache',
  StandBy = 'standby',
}

export const BIO_LENGTH = 50;
export const GROUP_CHAT_NAME_LENGTH = 50;
export const LIST_NAME_LENGTH = 50;
export const LIST_DESCRIPTION_LENGTH = 280;
export const ALL_PICKS = 'All picks';
export const EXPLORE_WRAP_PADDING_BOTTOM = 92;

export const SWIPER_MOUSE_WHEEL_OPTIONS = {
  forceToAxis: true,
  releaseOnEdges: true,
  thresholdDelta: 10,
};

export const EMPTY_QUESTION_CARD: CardSchema = {
  id: 'emptyPickId',
  cardId: 'emptyCardId',
  type: CardTypeOptions.Question,
  title: 'No question just yet, check back tomorrow to explore more.',
};

export const EMPTY_ITEMS = { items: [], totalCount: 0 };

export const EMPTY_DISCOVERY_ITEMS = { sectionItems: [], totalCount: 0 };

export const SHOW_FETCH_MORE_LOADER = { notifyOnNetworkStatusChange: true };

export enum ESearchParams {
  FollowingTab = 'isFollowingTab',
  ChannelsTab = 'isChannelsTab',
  ThoughtsTab = 'thoughtsTab',
  ThoughtId = 'thoughtId',
  Reaction = 'reaction',
  CreatedAt = 'createdAt',
  Tab = 'tab',
  Search = 'search',
  Id = 'id',
  Type = 'type',
  UserId = 'userId',
  UserName = 'username',
}

export const MS_IN_DAY = 1000 * 60 * 60 * 24;

export const WEB_PREFIX = 'web-';
export const HOME_VIEW = 'home-view';
export const TAB_BAR = 'tab-bar';

export const REACTIONS_NUMBER_STEP = 25;

export const OFF_PLATFORM_SHARING_REDIRECT_STORAGE_KEY = 'redirectShareUrl';

export const INVITE_FRIENDS_REDIRECT_STORAGE_KEY = 'inviteFriendPrevUrl';

export const MAX_DISPLAYED_PICKS = 500;

export const FEEDS_SORTING = 'feeds-sorting';
