import { styled } from 'linaria/lib/react';
import { StyledSubtitle } from 'components/UI/ContentSubtitle/styled';
import StyledTitle from 'components/UI/ContentTitle/styled';
import { COLORS } from 'styles/constants';

export const StyledContent = styled.section<{ isDark: boolean }>`
  border-top: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.dark[10])};
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  align-items: center;
`;

export const StyledTextContainer = styled.div`
  text-align: center;
  padding: 16px 32px;
`;

export const StyledMainInfo = styled(StyledTitle)`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  padding: 0 8px 8px;
`;

export const StyledInfo = styled(StyledSubtitle)`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  padding: 0 8px 8px;
`;

export const StyledButtonContainer = styled.div`
  margin: auto auto 0;
  max-width: 390px;
  width: 100%;

  & > button {
    width: 100%;
  }
`;
