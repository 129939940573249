import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: fit-content;
`;

export const StyledUserInvite = styled.div<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.brown.dark[100] : COLORS.white[100])};
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
  padding: 10px 0 8px;
`;
