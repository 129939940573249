import { FetchResult } from '@apollo/client';

import { useExtractionMutation, ExtractionErrorSchema, ExtractionMutation } from 'constants/graphqlTypes';

export interface IExtraction {
  id?: string;
  cardId?: string;
  title?: string | null;
  image?: string | null;
  loading: boolean;
  extract: () => Promise<FetchResult<ExtractionMutation>>;
  error?: ExtractionErrorSchema;
  mediaTag?: string | null;
  source?: string | null;
}
export const useExtraction = (url: string): IExtraction => {
  const [extract, { data, loading }] = useExtractionMutation({ variables: { url } });
  const { startExtractCard } = data || {};

  const title = startExtractCard?.__typename === 'PreExtractCardSchema' ? startExtractCard?.title : '';
  const image = startExtractCard?.__typename === 'PreExtractCardSchema' ? startExtractCard?.image : '';
  const id =
    startExtractCard?.__typename === 'PreExtractCardSchema' || startExtractCard?.__typename === 'CardSchema'
      ? startExtractCard.id
      : '';
  const cardId =
    startExtractCard?.__typename === 'PreExtractCardSchema' || startExtractCard?.__typename === 'CardSchema'
      ? startExtractCard.cardId
      : '';
  const mediaTag = startExtractCard?.__typename === 'CardSchema' ? startExtractCard?.mediaTags?.items[0]?.name : '';
  const source = startExtractCard?.__typename === 'CardSchema' ? startExtractCard?.sourceName : '';

  if (startExtractCard && startExtractCard?.__typename === 'ExtractionErrorSchema') {
    return { error: startExtractCard, extract, loading };
  }
  return { id, cardId, title, image, extract, loading, mediaTag, source };
};
