import { styled } from 'linaria/react';

import { COLORS, FONTS, GUEST_LIST_GRADIENT } from 'styles/constants';

export const StyledWrap = styled.div<{ bg: string; hasFewItems: boolean }>`
  aspect-ratio: ${({ hasFewItems }) => (hasFewItems ? 0.69 : 1)};
  background: ${GUEST_LIST_GRADIENT};
  border-radius: 10px;
  color: ${COLORS.white[100]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 30px;
  position: relative;
  text-decoration: none;

  @media screen and (min-width: 390px) {
    padding: 40px;
  }

  &::before {
    background-image: ${({ bg }) => `url(${bg})`};
    background-position: center;
    background-size: 100% 100%;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const StyledTitle = styled.span<{ hasFewItems: boolean }>`
  font: 400 ${({ hasFewItems }) => (hasFewItems ? '30px' : '40px')} / 100% ${FONTS.primary};
  margin-top: -13px;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  word-spacing: 100vw;

  @media screen and (min-width: 390px) {
    font: 400 ${({ hasFewItems }) => (hasFewItems ? '46px' : '54px')} / 100% ${FONTS.primary};
    margin-top: -15px;
  }
`;

export const StyledPretitle = styled.div<{ hasFewItems: boolean }>`
  display: block;
  font: 400 ${({ hasFewItems }) => (hasFewItems ? '14px' : '18px')} / 140% ${FONTS.primary};
  position: relative;
`;

export const StyledImagesList = styled.ul`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
  list-style: none;
  margin: 16px 0 0;
  overflow: hidden;
`;

export const StyledImageItem = styled.li<{ src?: string | null }>`
  aspect-ratio: 1;
  background-image: ${({ src }) => `url(${src ?? ''})`};
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

export const StyledRelWrap = styled.div<{ isRight: boolean }>`
  margin-top: 15px;
  margin-left: ${({ isRight }) => (isRight ? 'auto' : '0')};
  position: relative;
  width: 250px;
`;
