import { css } from 'linaria';
import { styled } from 'linaria/lib/react';
import { COLORS, FONTS, BREAKPOINTS } from 'styles/constants';

export const StyledWrap = styled.div<{ bgColor: { start: string; end: string } }>`
  background: ${({ bgColor }) => bgColor.start ?? COLORS.white[100]};
  height: 100dvh;
  overflow: hidden;
  transition: 1s all ease-in-out;
  position: relative;

  &::before {
    background: ${({ bgColor }) =>
      `linear-gradient(${COLORS.transparent} 5%, ${bgColor.end ?? COLORS.black[60]} 100%)`};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: ${({ bgColor }) => (bgColor.start ? '1' : '0')};
  }
`;

export const StyledInner = styled.div`
  padding: 93px 12px 0;

  * {
    position: relative;
  }
`;

export const StyledTitle = css`
  color: ${COLORS.white[100]};
  font: 400 22px/130% ${FONTS.primary};
  text-align: center;
`;

export const StyledTitleWrap = styled.div`
  margin: 0 48px 51px;
  min-height: 58px;

  p {
    margin: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    min-height: 29px;
  }
`;

export const StyledButtonWrap = styled.div`
  animation: fade ease 400ms forwards;
  animation-delay: 2s;
  bottom: -100px;
  opacity: 0;
  display: flex;
  justify-content: center;
  left: 0;
  margin-top: 3vh;
  padding: 0 24px;
  position: absolute;
  width: 100%;
  z-index: 200;
  flex-direction: column;
  align-items: center;

  > button {
    max-width: 342px;
    width: 100%;
  }

  p {
    color: ${COLORS.white[100]};
    font: 400 14px/130% ${FONTS.secondary};
    margin-bottom: 24px;
  }

  @keyframes fade {
    from {
      bottom: -100px;
      opacity: 0;
    }

    to {
      bottom: 5vh;
      opacity: 1;
    }
  }
`;
