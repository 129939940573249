import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useTheme from 'helpers/useTheme';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import Avatar from 'components/UI/Avatar';
import { useProfile } from 'graphQL/profile/hooks';
import { getGradient } from 'components/List/Header/helper';
import { generateName } from 'components/Profile/ProfileTop/helpers';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';
import { ROUTE_ONBOARDING_CONTENT } from 'routes';
import Grain from 'components/UI/Grain';

import { StyledContentWrap } from 'components/OnboardingV2/ProfileSetup/styled';
import {
  StyledCard,
  StyledContainer,
  StyledFullname,
  StyledHeading,
  StyledText,
  StyledUsername,
  StyledVerificationWrap,
} from './styled';

const Completion = () => {
  const posthogCapture = usePostHogCapture();
  const { userData } = useProfile();
  const isDark = useTheme();
  const { push } = useHistory();

  const { username, userInfo, isVerified, userStyle } = userData ?? {};
  const { firstName, lastName, bio } = userInfo ?? {};

  const imageColor = userStyle?.thumbnailImageSet?.colors?.sourceGradient?.items?.[0]?.colorValue;
  const hasNoCustomBg = userStyle?.thumbnailImageSetId === null;

  const { firstColor, secondColor } = getGradient(imageColor ?? '');
  const fullname = generateName(undefined, firstName, lastName);

  useEffect(() => {
    setTimeout(() => {
      push(ROUTE_ONBOARDING_CONTENT);
    }, 4000);
  }, []);

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.OnboardingProfileCompletion);
  }, []);

  return (
    <StyledContentWrap gradientStart={secondColor} gradientEnd={firstColor}>
      {!hasNoCustomBg && <Grain />}
      <StyledContainer>
        <StyledHeading isDark={isDark} hasBg={!hasNoCustomBg}>
          Simply irresistible.
        </StyledHeading>
        {userData && (
          <StyledCard isDark={isDark} hasBg={!hasNoCustomBg}>
            <Avatar dimension={92} user={userData} />
            {fullname && <StyledFullname>{fullname}</StyledFullname>}
            {isVerified && (
              <StyledVerificationWrap>
                <VerificationBadge hasCaption />
              </StyledVerificationWrap>
            )}
            <StyledUsername>:{username}</StyledUsername>
            <StyledText>{bio}</StyledText>
          </StyledCard>
        )}
      </StyledContainer>
    </StyledContentWrap>
  );
};

export default Completion;
