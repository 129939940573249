import { styled } from 'linaria/react';

export const StyledInteractiveListReactionMenuWrap = styled.div<{ isPointer: boolean; isCursorGrab: boolean }>`
  cursor: ${({ isPointer, isCursorGrab }) => {
    if (isCursorGrab) {
      return 'grab';
    }
    return isPointer ? 'pointer' : 'auto';
  }};
`;
