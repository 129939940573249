import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';
import { StyledWrap as ImportWrap } from '../styled';

export const StyledWrap = styled(ImportWrap)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
  padding-top: 0;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    justify-content: space-around;
  }

  .animated-bg {
    padding-top: 96px;
    margin: 0 -20px;

    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      margin: 0;
    }
  }

  > p {
    margin: 0;
  }
`;

export const StyledText = styled.p`
  font: 22px/130% ${FONTS.primary};
  margin: 0;
  padding-top: 8px;
`;

export const StyledContentWrap = styled.div`
  margin-top: -10px;
  padding: 0 24px;
`;

export const StyledSharingListWrap = styled.div`
  padding-top: 60px;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding-top: 87px;
  }
`;

export const StyledShareButtons = styled.ul`
  align-items: center;
  display: flex;
  gap: 34px;
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 20px 24px 0;

  .icon {
    height: 48px;
    width: 48px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledListItem = styled.li`
  cursor: pointer;

  a {
    text-decoration: none;
  }
`;

export const StyledShareWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    display: block;
    color: ${COLORS.white[100]};
    font: 600 14px/130% ${FONTS.secondary};
    padding-top: 8px;
    white-space: nowrap;
  }
`;
