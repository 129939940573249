import { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { CardSchema, ECardPermissionsOptions } from 'constants/graphqlTypes';
import {
  ROUTE_ADD_THOUGHT,
  ROUTE_ADD_TO_LIST,
  ROUTE_LIST_EDIT,
  ROUTE_LIST_CHANGE_LAYOUT,
  ROUTE_USER_ALL_PICKS_LAYOUT,
} from 'routes';
import { transformRoute } from 'helpers/routingHelper';
import { ReactComponent as ThoughtIcon } from 'assets/icons/Thought.svg';
import { ReactComponent as MovePickIcon } from 'assets/icons/MovePick.svg';
import { ReactComponent as ChangeViewIcon } from 'assets/icons/ListImagesView.svg';

import usePageType from 'helpers/usePageType';
import { useCurrentList, useGetListItemsId } from 'graphQL/list/hooks';
import useOpenModal from './useOpenModal';

interface IOption {
  text: string;
  Icon: FC;
  condition?: boolean;
  toggle?: () => void;
}

export const useThought = (id: string, cardId: string, listId?: string, hasThought?: boolean): IOption => {
  const toggle = useOpenModal(ROUTE_ADD_THOUGHT, true, { pickId: id, cardId, listId, hasThought });

  return {
    Icon: ThoughtIcon,
    text: `${hasThought ? 'Edit' : 'Add'} thought`,
    toggle,
  };
};

export const useChangeListView = (item?: CardSchema): IOption => {
  const location = useLocation();

  const { id: listId } = item ?? {};
  const { replace } = useHistory();
  const { isAllPicks } = usePageType();
  const { profileName } = useParams<{ profileName: string }>();

  const toggle = () => {
    replace({
      pathname: isAllPicks
        ? transformRoute(ROUTE_USER_ALL_PICKS_LAYOUT, { profileName })
        : transformRoute(ROUTE_LIST_CHANGE_LAYOUT, { listId }),
      state: {
        ...location.state,
        ...(isAllPicks && { backgroundModal: location }),
      },
    });
  };

  return {
    Icon: ChangeViewIcon,
    text: 'Change view',
    toggle,
  };
};

export const useEditList = (list?: CardSchema): IOption => {
  const { id: listId, cardId: listCardId, title, description, isPrivateList, permissions } = list ?? {};
  const { replace } = useHistory();
  const location = useLocation();

  const { total } = useGetListItemsId(listId);

  const toggle = () => {
    replace({
      pathname: transformRoute(ROUTE_LIST_EDIT, { listId }),
      state: {
        ...location.state,
        backgroundModal: location,
        variable: {
          listCardId,
          title: title ?? '',
          description: description ?? '',
          itemsTotal: total,
          isPrivateList: !!isPrivateList,
          list,
        },
      },
    });
  };

  return {
    condition: permissions?.includes(ECardPermissionsOptions.EditCard),
    Icon: ThoughtIcon,
    text: 'Edit list',
    toggle,
  };
};

interface IUseMoveToListArgs {
  pickId: string;
  cardId: string;
  currentListId?: string;
  userPickSchemaRef?: string;
  disableListRedirect?: boolean;
}

export const useGotoMoveToList = ({
  cardId,
  pickId,
  currentListId,
  userPickSchemaRef,
  disableListRedirect,
}: IUseMoveToListArgs): IOption => {
  const toggle = useOpenModal(ROUTE_ADD_TO_LIST, false, {
    pickId,
    cardId,
    currentListId,
    userPickSchemaRef,
    isDetail: true,
    isMovePick: true,
    disableListRedirect,
  });

  return {
    Icon: MovePickIcon,
    text: 'Move pick',
    toggle,
  };
};

export const useMoveToCurrentList = (
  pickId: string,
  cardId: string,
  moveListCardId?: string,
  userPickSchemaRef?: string
): IOption => {
  const { title, id: currentListId, cardId: currentListCardId } = useCurrentList() ?? {};

  const toggle = useOpenModal(ROUTE_ADD_THOUGHT, true, {
    pickId,
    cardId,
    moveListCardId: currentListCardId,
    moveListTitle: title,
    isDetail: true,
    currentListId,
    userPickSchemaRef,
  });

  return {
    Icon: MovePickIcon,
    text: `Move pick to this list`,
    toggle,
  };
};
