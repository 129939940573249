import React, { useState, FC, Fragment, Dispatch, SetStateAction, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { ReactComponent as PeopleIcon } from 'assets/icons/People.svg';
import { ReactComponent as Logo } from 'assets/icons/Logo.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';

import Modal from 'components/Modal';
import ContentTitle from 'components/UI/ContentTitle';
import ListItem from 'components/UI/ListItem';
import Button from 'components/UI/Button';
import { ROUTE_CREATE_FEED } from 'routes';
import { useLink } from 'helpers/routingHelper';
import { useGetFollowings } from 'graphQL/follows/hooks';
import { useGetSuggestionSearches } from 'graphQL/search/hooks';
import { useUpdateFeed, useGetFeedAllUsers } from 'graphQL/feeds/hooks';
import getAuthUser from 'helpers/getAuthUser';
import Input from 'components/UI/Input';
import { EToggleType } from 'components/UI/Toggle/models';
import { StyledButtonWrap } from 'components/TopicsModal/styled';
import { EUiButtonType } from 'components/UI/Button/constants';
import { IEditFeedUsersLocation } from 'components/CustomFeeds/CreateFeed/models';
import {
  useCreateFeedUsersSelection,
  useCreateFeedEvents,
  useCreateFeedUsers,
} from 'components/CustomFeeds/CreateFeed/hooks';
import { checkIsUserEnabled } from 'components/CustomFeeds/CreateFeed/helpers';

import UserItem from 'components/CustomFeeds/UserItem';
import {
  StyledAllTopicsWrapper,
  StyledContentContainer,
  StyledTopicsLabel,
  StyledSearchContainer,
  StyledUsersContainer,
} from 'components/CustomFeeds/CreateFeed/Views/SelectFeedCreateTopics/styled';

import { ESelectionMode } from 'components/CustomFeeds/constants';

const { All, Followings, Deselect, Select } = ESelectionMode;

interface ISelectFeedEditUsersProps {
  selectionMode: ESelectionMode;
  setSelectionMode: Dispatch<SetStateAction<ESelectionMode>>;
  selectedUsers: string[];
  setSelectedUsers: Dispatch<SetStateAction<string[]>>;
  deselectedUsers: string[];
  setDeselectedUsers: Dispatch<SetStateAction<string[]>>;
  totalSelectedUsers: number;
  setTotalSelectedUsers: Dispatch<SetStateAction<number>>;
  isAllFollowings: boolean;
  setAllFollowings: Dispatch<SetStateAction<boolean>>;
  isAllUsers: boolean;
  setAllUsers: Dispatch<SetStateAction<boolean>>;
}

const SelectFeedEditUsers: FC<ISelectFeedEditUsersProps> = ({
  selectionMode,
  setSelectionMode,
  selectedUsers,
  setSelectedUsers,
  deselectedUsers,
  setDeselectedUsers,
  totalSelectedUsers,
  setTotalSelectedUsers,
  isAllFollowings,
  setAllFollowings,
  isAllUsers,
  setAllUsers,
}) => {
  const [searchString, setSearchString] = useState('');
  /* this state variable (isEditStarted) is created to figure out
  if we started editing users or not -
  it becomes true if we click on any user or any other button
  during editing custom feed in SelectFeedEditUsers  */
  const [isEditStarted, setIsEditStarted] = useState(false);
  const [isSelectFollowings, setSelectFollowings] = useState(false);

  const { state } = useLocation<IEditFeedUsersLocation>();
  const { feedId, editedParams, editedTotalCount, title } = state?.variable ?? {};
  const { goBack, replace } = useHistory();
  const feedLink = useLink(ROUTE_CREATE_FEED);
  const handleExit = () => goBack();
  const { userId } = getAuthUser();

  const { handleSelection, handleSelectItem } = useCreateFeedEvents(
    selectionMode,
    setSelectionMode,
    setSelectedUsers,
    setDeselectedUsers,
    setSelectFollowings,
    setIsEditStarted,
    setTotalSelectedUsers,
    isAllFollowings,
    totalSelectedUsers,
    selectedUsers,
    deselectedUsers,
    editedParams?.is_all_followings
  );

  const { suggestionSearches: users } = useGetSuggestionSearches(`user:${searchString}`, undefined, feedId);

  const { items, loadMore, loading, totalCount: totalFollowingsCount } = useGetFollowings(userId, undefined, feedId);
  const summaryTotalUsers = useGetFeedAllUsers();

  const { updateFeed } = useUpdateFeed({
    feedId,
    title,
    isAllFollowings: isAllFollowings !== editedParams?.is_all_followings ? isAllFollowings : undefined,
    isAllUsers: isAllUsers !== editedParams?.is_all_users ? isAllUsers : undefined,
    includedUsers: selectedUsers.length ? selectedUsers : undefined,
    excludedUsers: deselectedUsers.length ? deselectedUsers : undefined,
  });

  const handleSubmitEditing = async () => {
    if (isEditStarted) {
      await updateFeed();
    }
    handleExit();
  };

  useCreateFeedUsersSelection({
    selectionMode,
    setTotalSelectedUsers,
    summaryTotal: summaryTotalUsers,
    selectedUsers,
    deselectedUsers,
    setSelectionMode,
    totalSelectedUsers,
    totalFollowingsCount,
    isSearch: !!searchString,
    isAllUsers,
    isAllFollowings,
    editedTotalCount,
    isEditStarted,
    isAllFollowingsEdit: editedParams?.is_all_followings,
    isAllUsersEdit: editedParams?.is_all_users,
  });

  useCreateFeedUsers({
    selectionMode,
    setAllFollowings,
    setAllUsers,
    summaryTotalUsers,
    totalSelectedUsers,
    deselectedUsers,
    selectedUsers,
    isAllUsersEdit: editedParams?.is_all_users,
    isAllFollowingsEdit: editedParams?.is_all_followings,
    isAllFollowings,
    isEditStarted,
    setTotalSelectedUsers,
  });

  useEffect(() => {
    if (editedTotalCount) {
      setTotalSelectedUsers(editedTotalCount);
    }
  }, [editedTotalCount]);

  useEffect(() => {
    // Display correct data when feed was created with isAllFollowings=true
    if (!loading && !isAllUsers) {
      if (isAllFollowings && totalFollowingsCount <= totalSelectedUsers) {
        setSelectionMode(Followings);
      }
      if (isAllFollowings && totalFollowingsCount > totalSelectedUsers) {
        setSelectionMode(Deselect);
      }
    }
  }, [loading, totalFollowingsCount, totalSelectedUsers]);

  useEffect(() => {
    // Reset state after close modal
    return () => {
      setSelectedUsers([]);
      setDeselectedUsers([]);
      setAllFollowings(false);
      setAllUsers(false);
      setSelectionMode(Select);
    };
  }, []);

  return (
    <Modal handleClose={handleExit} handlePrev={() => (feedId ? goBack() : replace(feedLink))} enableInnerScroll>
      <StyledContentContainer hasPaddingBottom={false}>
        <ContentTitle isDark>Select users you want to hear from</ContentTitle>
        <StyledSearchContainer>
          <Input
            Icon={SearchIcon}
            placeholder="Search"
            isDark
            setText={setSearchString}
            text={searchString}
            hasCrossIcon
          />
          {searchString && (
            <Button text="Cancel" handler={() => setSearchString('')} isDark type={EUiButtonType.Transparent} />
          )}
        </StyledSearchContainer>
        {!searchString && (
          <StyledAllTopicsWrapper>
            <ListItem
              title="All users"
              isDark
              handleClick={() => handleSelection(All)}
              hasToggle
              toggleType={EToggleType.CheckPlus}
              Icon={() => <Logo viewBox="0 0 622 383" />}
              isEnable={selectionMode === All}
            />
            {totalFollowingsCount > 0 && (
              <ListItem
                title={`All ${totalFollowingsCount} user${totalFollowingsCount > 1 ? `s` : ``} you follow`}
                isDark
                handleClick={() => handleSelection(Followings)}
                hasToggle
                toggleType={EToggleType.CheckPlus}
                Icon={PeopleIcon}
                isEnable={selectionMode === Followings || selectionMode === All || isSelectFollowings}
              />
            )}
          </StyledAllTopicsWrapper>
        )}
        <StyledUsersContainer>
          {!searchString
            ? totalFollowingsCount > 0 && (
                <>
                  <StyledTopicsLabel>Following</StyledTopicsLabel>
                  {items.map((item, index) => (
                    <Fragment key={item.id}>
                      <UserItem
                        user={item}
                        isEnable={checkIsUserEnabled({
                          id: item.id,
                          selectionMode,
                          selectedUsers,
                          deselectedUsers,
                          isFollowedByMe: item?.isFollowedByMe,
                          isAllUsers,
                          isSelectedInFeed: !!item?.isSelectedInFeed,
                          feedId,
                          isEditStarted,
                          totalSelectedUsers,
                          isAllFollowingsEdit: editedParams?.is_all_followings,
                        })}
                        handleClick={() =>
                          handleSelectItem(
                            item.id,
                            item?.isFollowedByMe,
                            !!searchString,
                            feedId,
                            !!item.isSelectedInFeed
                          )
                        }
                        isDark
                      />
                      {!loading && index === items.length - 3 && <Waypoint onEnter={loadMore} />}
                    </Fragment>
                  ))}
                </>
              )
            : users.map(
                (item) =>
                  item.__typename === 'UserObjectType' && (
                    <Fragment key={item.id}>
                      <UserItem
                        user={item}
                        isEnable={checkIsUserEnabled({
                          id: item.id,
                          selectionMode,
                          selectedUsers,
                          deselectedUsers,
                          isFollowedByMe: item?.isFollowedByMe,
                          isSearch: !!searchString,
                          isAllUsers,
                          isAllFollowings,
                          isSelectedInFeed: !!item?.isSelectedInFeed,
                          feedId,
                          isEditStarted,
                          totalSelectedUsers,
                          isAllFollowingsEdit: editedParams?.is_all_followings,
                        })}
                        handleClick={() =>
                          handleSelectItem(
                            item.id,
                            item?.isFollowedByMe,
                            !!searchString,
                            feedId,
                            !!item?.isSelectedInFeed
                          )
                        }
                        isDark
                      />
                    </Fragment>
                  )
              )}
        </StyledUsersContainer>
        {!!totalSelectedUsers && (
          <StyledButtonWrap>
            <Button
              handler={handleSubmitEditing}
              text={`Continue with ${selectionMode === All ? 'all' : totalSelectedUsers} user${
                totalSelectedUsers > 1 ? `s` : ``
              }`}
            />
          </StyledButtonWrap>
        )}
      </StyledContentContainer>
    </Modal>
  );
};

export default SelectFeedEditUsers;
