import { styled } from 'linaria/lib/react';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledSeeMore = styled.div<{ isDark: boolean }>`
  width: 100%;
  height: 170px;
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.dark[10])};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
`;

export const StyledTitleWrap = styled.div<{ isDark: boolean }>`
  padding: 32px 0 16px 24px;
  font: 400 22px/130% ${FONTS.primary};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding-left: 15px;
  }
`;

export const StyledCreatorsWrap = styled.div`
  padding-left: 9px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding-left: 0px;
  }
`;

export const StyledCreatorsContainer = styled.div``;

export const StyledTitleTextWrap = styled.span`
  display: block;
  max-width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
