import { css } from 'linaria';
import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';

export const StyledListItem = styled.li<{ isRight: boolean; timestamp: string }>`
  display: flex;
  justify-content: ${({ isRight }) => (isRight ? 'flex-end' : 'flex-start')};
  position: relative;
  transition: 0.3s transform ease-in-out;
  width: 100%;
  user-select: none;

  &::after {
    color: ${COLORS.brown.light[100]};
    content: ${({ timestamp }) => `"${timestamp}"`};
    font: 12px/140% ${FONTS.secondary};
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: ${({ isRight }) => (isRight ? -100 : 18)}px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s opacity ease-in-out;
  }
`;

export const StyledMessageWrap = styled.div<{ isMenuOpen: boolean; isFullWidth: boolean }>`
  position: relative;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'fit-content')};
  z-index: ${({ isMenuOpen }) => (isMenuOpen ? 50 : 'unset')};
`;

export const StyledFadeIn = css`
  animation: fadein 200ms ease-in-out forwards;
  position: relative;

  @keyframes fadein {
    0% {
      top: 300px;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
`;

export const StyledMessageInner = styled.div`
  position: relative;
`;

interface StyledMessageProps {
  isDark: boolean;
  isMine?: boolean;
  isDeleted?: boolean;
  isTransparent?: boolean;
  hasReaction?: boolean;
  hasLink?: boolean;
}

export const StyledTextMessage = styled.div<StyledMessageProps>`
  background-color: ${({ isMine, isDark }) => {
    if (isDark) {
      return isMine ? COLORS.white[30] : COLORS.black[100];
    }

    return isMine ? COLORS.white.off[100] : COLORS.white[100];
  }};
  border: ${({ isMine, isDark }) => {
    if (!isMine) {
      return `1px solid ${isDark ? COLORS.white[30] : COLORS.white.off[100]}`;
    }
    return 'none';
  }};
  border-radius: 22px;
  cursor: ${({ hasLink }) => (hasLink ? 'default' : 'pointer')};
  font: 14px/140% ${FONTS.secondary};
  max-width: 228px;
  width: fit-content;
  padding: 8px 16px;
  position: relative;
  user-select: none;
  margin-bottom: ${({ hasReaction, isDeleted }) => (hasReaction && !isDeleted ? '16px' : 0)};
`;

export const StyledUnavailableContent = styled(StyledTextMessage)<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[60] : COLORS.brown.light[100])};
`;

export const StyledLink = styled.a<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.green.light : COLORS.green.dark)};
  text-decoration: none;
`;

export const StyledContentMessage = styled.div`
  position: relative;
  user-select: none;
`;

export const StyledReaction = styled(StyledTextMessage)<{ isLiked: boolean }>`
  align-items: center;
  background-color: ${({ isLiked, isDark }) => {
    if (isLiked) {
      return COLORS.red.semiLight;
    }

    return isDark ? COLORS.black[100] : COLORS.white[100];
  }};
  color: ${({ isLiked, isDark }) => (isLiked || !isDark ? COLORS.brown.dark[100] : COLORS.white[100])};
  display: flex;
  gap: 4px;
  height: 26px;
  margin-left: ${({ isMine }) => (isMine ? 'unset' : 'auto')};
  margin-top: -8px;
  padding: 0 6px;
  width: fit-content;
`;

export const StyledName = styled.span`
  display: block;
  font-weight: 600;
  overflow: hidden;
  padding-bottom: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledContentWrap = styled.div<{ disableLink: boolean; isMine: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isMine }) => (isMine ? 'end' : 'start')};

  a {
    pointer-events: ${({ disableLink }) => (disableLink ? 'none' : 'auto')};
    user-select: ${({ disableLink }) => (disableLink ? 'none' : 'auto')};
    -webkit-touch-callout: none;
  }
`;

export const StyledTokenWrap = styled.div<{ isRight?: boolean }>`
  position: absolute;
  top: calc(100% - 8px);
  right: ${({ isRight }) => (isRight ? 'auto' : '0')};
  left: ${({ isRight }) => (isRight ? '0' : 'auto')};
  display: flex;
  justify-content: flex-end;
  z-index: 1;
`;

export const StyledMentionedUser = styled.span`
  cursor: pointer;
  color: ${COLORS.green.dark};
  font-weight: 600;
`;

export const StyledDeletedText = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[60] : COLORS.brown.light[100])};
`;
