import React, { FC, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ReactComponent as PrivacyIcon } from 'assets/icons/Privacy.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/TrashCan.svg';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { ReactComponent as ReorderListIcon } from 'assets/icons/MovePick.svg';
import { transformRoute } from 'helpers/routingHelper';
import { useDeleteItem } from 'graphQL/cardOptions/hooks';
import { useListSettingsLinks } from 'components/ListSettings/hooks';
import Modal from 'components/Modal';
import Button from 'components/UI/MenuButton';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { ROUTE_LIST_DETAIL, ROUTE_PROFILE } from 'routes';

import { StyledItem } from 'components/AddContent/Menu/styled';
import { StyledInfo, StyledLabel, StyledWrapper } from 'components/ProfileSettings/EditProfile/styled';
import { StyledError } from 'components/UI/TextInput/styled';
import { useChangeListView } from 'helpers/useContextMenu';
import { useGetDetailList } from 'graphQL/list/hooks';
import { StyledActions, StyledOption } from './styled';
import { IUseListEditLocation } from './models';

const EditList: FC = () => {
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [deleteListError, setDeleteListError] = useState('');
  const { push, replace } = useHistory();
  const { listId } = useParams<{ listId: string }>();
  const { changeName, changeDescription, changePrivacy, changeCollaborators, changeOrder } = useListSettingsLinks(
    listId
  );
  const { state } = useLocation<IUseListEditLocation>();
  const { prevPath, variable } = state ?? {};
  const { listCardId, description, title, itemsTotal, isPrivateList, list } = variable;
  const { card } = useGetDetailList({ id: listId });
  const { Icon: ChangeViewIcon, text: changeViewText, toggle: changeView } = useChangeListView(list);

  const handleChangeView = () => {
    changeView?.();
  };

  const deleteListSubTitle = `This will permanently delete your list${
    isPrivateList
      ? ` and all ${itemsTotal} pick${itemsTotal && itemsTotal > 1 ? 's' : ''}.`
      : ', all picks remain in ‘All picks’.'
  }`;

  const handleClose = () => {
    replace(transformRoute(ROUTE_LIST_DETAIL, { listId }));
  };

  const { deleteItem, loading } = useDeleteItem(listCardId, card);
  const handleDelete = () => {
    handleClose();
    deleteItem()
      .then(() => {
        handleClose();
        if (prevPath) {
          push(prevPath);
        } else {
          push(ROUTE_PROFILE);
        }
      })
      .catch((e) => {
        setDeleteListError(e.message);
        setIsDeleteConfirm(false);
      });
  };

  return (
    <Modal fadeIn={false} handleClose={handleClose}>
      <StyledWrapper>
        <StyledOption onClick={() => replace(changeName)}>
          <StyledLabel isPointer>List name</StyledLabel>
          <StyledInfo>{title}</StyledInfo>
        </StyledOption>
        <StyledOption onClick={() => replace(changeDescription)}>
          <StyledLabel isPointer>Description</StyledLabel>
          <StyledInfo isEmpty={!description}>{description || 'Tap to add'}</StyledInfo>
        </StyledOption>
        <StyledActions>
          <StyledLabel>Actions</StyledLabel>
          <StyledItem>
            <Button text="Roles and permissions" Icon={UserIcon} handler={() => replace(changeCollaborators)} />
          </StyledItem>
          <StyledItem>
            <Button text="Edit privacy settings" Icon={PrivacyIcon} handler={() => replace(changePrivacy)} />
          </StyledItem>
          {itemsTotal > 1 && (
            <StyledItem>
              <Button hasStroke text="Reorder list" Icon={ReorderListIcon} handler={() => replace(changeOrder)} />
            </StyledItem>
          )}
          {!!itemsTotal && (
            <StyledItem>
              <Button hasStroke text={changeViewText} Icon={ChangeViewIcon} handler={handleChangeView} />
            </StyledItem>
          )}
          <StyledItem>
            <Button isRed text="Delete this list" Icon={TrashIcon} handler={() => setIsDeleteConfirm(true)} />
            {deleteListError && <StyledError>{deleteListError}</StyledError>}
          </StyledItem>
        </StyledActions>
      </StyledWrapper>
      {isDeleteConfirm && (
        <ConfirmationModal
          handleCancel={() => setIsDeleteConfirm(false)}
          handleAccept={handleDelete}
          subTitle={deleteListSubTitle}
          titleText={`delete list${isPrivateList ? ' and picks' : ''}`}
          buttonText="delete"
          disableSubmit={loading}
        />
      )}
    </Modal>
  );
};

export default EditList;
