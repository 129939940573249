import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_PRIVACY } from 'routes';

import useDocumentTitle from 'helpers/useDocumentTitle';
import useTheme from 'helpers/useTheme';
import Arrow from 'components/UI/Arrow';
import MenuButton from 'components/UI/MenuButton';
import SinglePageLayout from 'components/SinglePageLayout';
import PrivacyData from 'components/PrivacyData';

import { StyledSliderWrap, StyledBottom } from './styled';

const PrivacyApproach: FC = () => {
  const isDark = useTheme();
  const { push } = useHistory();
  useDocumentTitle('Our privacy approach');

  return (
    <SinglePageLayout title="Our privacy approach">
      <StyledSliderWrap>
        <PrivacyData isDark={isDark} />
      </StyledSliderWrap>
      <StyledBottom isDark={isDark}>
        <MenuButton
          text="Our full privacy policy"
          handler={() => push(ROUTE_PRIVACY)}
          isDark={isDark}
          Icon={() => <Arrow degree={135} isDark={isDark} />}
          isReversed
        />
      </StyledBottom>
    </SinglePageLayout>
  );
};

export default PrivacyApproach;
