import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useTheme from 'helpers/useTheme';
import Input from 'components/UI/Input';
import SinglePageLayout from 'components/SinglePageLayout';
import Button from 'components/UI/Button';

import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { useSearchChat } from 'graphQL/messages/hooks';
import { List } from 'components/Messages';
import { useNavBehavior } from 'components/Navigation/helpers/hooks';
import { EUiButtonType } from 'components/UI/Button/constants';
import { StyledFixedWrap, StyledListWrap, StyledSearchWrap } from './styled';

const MessagesSearch: FC = () => {
  const [searchString, setSearchString] = useState('');
  const [refHeight, setRefHeight] = useState<number>();
  const { fontColor, bgColor } = useNavBehavior();
  const isDark = useTheme();
  const ref = useRef<HTMLDivElement>(null);

  const { goBack } = useHistory();
  const { items, loading, loadMore } = useSearchChat(searchString);

  useEffect(() => {
    setRefHeight(ref.current?.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current?.clientHeight]);

  return (
    <>
      <StyledFixedWrap ref={ref} isHide={false} color={fontColor} bgColor={bgColor}>
        <StyledSearchWrap>
          <Input
            Icon={SearchIcon}
            placeholder="Search"
            isDark={isDark}
            text={searchString}
            setText={setSearchString}
            hasCrossIcon
            hasAutofocus
          />
          <Button text="Cancel" handler={() => goBack()} isDark={isDark} type={EUiButtonType.Transparent} />
        </StyledSearchWrap>
      </StyledFixedWrap>
      <SinglePageLayout paddingTop={16}>
        <StyledListWrap paddingTop={refHeight ?? 0}>
          <List items={items} isDark={isDark} loadMore={loadMore} loading={loading} isSearchActive={!!searchString} />
        </StyledListWrap>
      </SinglePageLayout>
    </>
  );
};

export default MessagesSearch;
