import { styled } from 'linaria/react';

import { COLORS } from 'styles/constants';

const StyledSlider = styled.section<{ gradientStart: string; gradientEnd: string }>`
  background-color: ${({ gradientStart }) => gradientStart};
  height: 100dvh;
  overflow: hidden;
  transition: 0.6s background-color ease-in-out;
  width: 100%;

  .swiper {
    height: 100%;
  }

  .detail {
    background: ${COLORS.transparent};
  }

  &::before {
    background: ${({ gradientEnd }) => `linear-gradient(${COLORS.transparent} 5%, ${gradientEnd} 100%)`};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export default StyledSlider;
