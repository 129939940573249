import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';
import { DEFAULT_GRADIENT } from 'components/Collection/helpers';

export const StyledCoverWrap = styled.div<{
  image?: string | null;
  bgColor?: string;
  counter?: number | null;
  isDark: boolean;
}>`
  position: relative;
  background: ${({ image, bgColor }) => (image ? `url(${image})` : bgColor ?? DEFAULT_GRADIENT)};
  width: 100%;
  aspect-ratio: 1;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  margin-top: 5px;

  &::after {
    align-items: center;
    background: ${COLORS.green.light};
    border-radius: 50%;
    border: ${({ isDark }) => `2px solid ${isDark ? COLORS.black[100] : COLORS.white[100]}`};
    color: ${COLORS.brown.dark[100]};
    content: ${({ counter }) => (counter ? `'${counter}'` : '')};
    display: ${({ counter }) => (counter ? 'flex' : 'none')};
    font: 400 12px/140% ${FONTS.secondary};
    height: 22px;
    justify-content: center;
    min-width: 22px;
    padding: 0 5px;
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
  }
`;

export const StyledImageUser = styled.img<{ hasUnreadPicks?: boolean }>`
  top: 8px;
  left: 8px;
  border-radius: 25px;
  height: 34px;
  object-fit: cover;
  position: absolute;
  width: 34px;
  border: 2px solid ${({ hasUnreadPicks }) => (hasUnreadPicks ? COLORS.green.light : COLORS.white[100])};
`;

export const StyledCardTitle = styled.span`
  width: 100%;
  position: absolute;
  color: ${COLORS.white[100]};
  bottom: 8px;
  padding: 0 8px;
  font: 400 14px/140% ${FONTS.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledGradient = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
`;
