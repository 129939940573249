import { styled } from 'linaria/react';
import { StyledTitle } from 'components/List/Content/styled';
import { StyledList } from 'pages/Settings/styled';

export const StyledItemWrap = styled.div`
  padding: 32px 0;
`;

export const StyledListTitle = styled(StyledTitle)`
  padding: 0 0 16px 0;
`;

export const StyledScrollableList = styled(StyledList)`
  overflow-y: scroll;
  justify-content: flex-start;

  &::-webkit-scrollbar {
    display: none;
  }
`;
