import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledSuggestionContainer = styled.div<{ isDark: boolean }>`
  backdrop-filter: blur(2px);
  background: ${COLORS.black[40]};
  font: 400 14px/140% ${FONTS.secondary};
  height: 100%;
  max-width: 528px;
  padding-bottom: 120px;
  position: absolute;
  width: 100%;
  z-index: 10;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    background: ${({ isDark }) => (isDark ? COLORS.black[40] : COLORS.white[40])};
  }
`;

export const StyledList = styled.ul<{ isDark: boolean }>`
  background: ${({ isDark }) => (isDark ? COLORS.black[100] : COLORS.white[100])};
  height: 100%;
  margin: 0;
  overflow: auto;
  padding: 16px 24px 32px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledItem = styled.li`
  cursor: pointer;
  display: block;
  margin-bottom: 32px;
  text-transform: capitalize;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledLabel = styled.p`
  margin: 0 0 16px;
`;
