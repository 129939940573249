import { useState, useRef, useEffect, RefObject } from 'react';
import { getEdgeArrowPosition } from './helpers';

export const useReactionMenu = () => {
  const [reactionCoordinates, setReactionCoordinates] = useState<{ x: number; y: number } | null>(null);
  const [isQuickReactionOpen, setIsQuickReactionOpen] = useState(false);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);

  const infoRef = useRef<HTMLDivElement>(null);
  const delta =
    (infoRef.current?.getBoundingClientRect() &&
      reactionCoordinates &&
      infoRef.current?.getBoundingClientRect().height -
        (infoRef.current?.getBoundingClientRect().bottom - reactionCoordinates.y)) ||
    0;
  const minusTop = 76 - delta;

  return {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  };
};

export const useTooltipArrowCorrection = (isOpen: boolean): RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const arrow: HTMLDivElement | null | undefined = ref.current?.querySelector('.react-tooltip-arrow');

      if (arrow) {
        const { left } = window.getComputedStyle(arrow);

        arrow.style.left = getEdgeArrowPosition(left);
      }
    }, 50);
  }, [isOpen]);

  return ref;
};
