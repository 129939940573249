import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTE_CHANGE_PHOTO, ROUTE_CHANGE_NAME, ROUTE_CHANGE_BIO, ROUTE_PROFILE_EDIT } from 'routes';

import EditProfileData from './EditProfileData';
import ChangePhoto from './ChangePhoto';
import ChangeName from './ChangeName';
import ChangeBio from './ChangeBio';

interface IEditProfileProps {
  handleClose: () => void;
  handlePrev?: () => void;
}

const EditProfile: FC<IEditProfileProps> = ({ handleClose, handlePrev }) => (
  <Switch>
    <Route exact path={ROUTE_PROFILE_EDIT}>
      <EditProfileData handleClose={handleClose} />
    </Route>
    <Route exact path={ROUTE_CHANGE_PHOTO}>
      <ChangePhoto handleClose={handleClose} />
    </Route>
    <Route exact path={ROUTE_CHANGE_NAME}>
      <ChangeName handleClose={handleClose} handlePrev={handlePrev} />
    </Route>
    <Route exact path={ROUTE_CHANGE_BIO}>
      <ChangeBio handleClose={handleClose} handlePrev={handlePrev} />
    </Route>
  </Switch>
);

export default EditProfile;
