export enum EExtractionError {
  GeneralError = 'GeneralError',
  BlockedByAdminError = 'BlockedByAdminError',
  PrivateSource = 'PrivateSource',
}

export const GENERAL_ERRORS = [
  'REQUEST_ERROR',
  'BLOCKED_BY_CONTENT_TYPE',
  'RESPONSE_ERROR',
  'NOT_FOUND',
  'EXTRACT_ERROR',
  'UNEXPECTED_ERROR',
];
