import React, { FC } from 'react';

import useTheme from 'helpers/useTheme';
import QuestionSlider from 'components/Sliders/QuestionSlider';

import { DiscoverSectionSchema } from 'constants/graphqlTypes';
import { StyledTitle } from './styled';

const QuestionSection: FC<{ section: DiscoverSectionSchema }> = ({ section }) => {
  const cards = section.cardItems ?? [];
  const isDark = useTheme();

  return (
    <>
      <StyledTitle isDark={isDark}>{section.title}</StyledTitle>
      <QuestionSlider items={cards.map(({ card }) => card)} isDark={isDark} showAll />
    </>
  );
};

export default QuestionSection;
