import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Badge from 'components/UI/Badge';
import useTheme from 'helpers/useTheme';

import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/ArrowRight.svg';
import { ROUTE_SEARCH_RESULT } from 'routes';
import { useRemoveFromRecentSearches, useAddToRecentSearches } from 'graphQL/search/hooks';
import { ESearchTypeOptions, UserObjectType } from 'constants/graphqlTypes';
import { generateTopicTitle } from 'constants/aqa/signup';
import { StyledContent as StyledText } from 'components/UI/Badge/styled';
import { ESearchParams } from 'constants/common';
import { StyledCloseIconWrapper, StyledSearchIconWrapper, StyledListItem, StyledContent } from './styled';

interface IRecentSearchItem {
  id: string;
  keyword?: string | null;
  user?: UserObjectType | null;
  hasNameOnly: boolean;
  dataTestId: string;
  isBold?: boolean;
}

const RecentSearchItem: FC<IRecentSearchItem> = ({ keyword, id, user, hasNameOnly, dataTestId, isBold = false }) => {
  const { push } = useHistory();
  const isDark = useTheme();
  const { removeFromRecentSearches } = useRemoveFromRecentSearches(id);
  const { addToRecentSearches } = useAddToRecentSearches();
  const EdgeIcon = !user ? <ArrowRight width={15} height={15} viewBox="0 0 20 20" /> : undefined;

  return (
    <StyledListItem>
      <StyledContent
        data-testid={`${dataTestId}-${generateTopicTitle(user ? user.username : keyword ?? '')}`}
        onClick={() =>
          user
            ? addToRecentSearches(user.id, ESearchTypeOptions.User)
            : push({
                pathname: ROUTE_SEARCH_RESULT,
                search: `?${ESearchParams.Search}=${encodeURIComponent(keyword ?? '')}`,
              })
        }
      >
        {user ? (
          <Badge user={user} isDark={isDark} hasUsername={!hasNameOnly} hasNameOnly={hasNameOnly} isBold={isBold} />
        ) : (
          <>
            <StyledSearchIconWrapper isDark={isDark}>
              <SearchIcon />
            </StyledSearchIconWrapper>
            <StyledText isDark={isDark}>{keyword}</StyledText>
          </>
        )}
      </StyledContent>
      {(EdgeIcon || hasNameOnly) && (
        <StyledCloseIconWrapper
          data-testid={`${dataTestId}_cross-button`}
          shouldRotate={!hasNameOnly}
          isDark={isDark}
          onClick={() => removeFromRecentSearches()}
        >
          {hasNameOnly ? <CrossIcon /> : EdgeIcon}
        </StyledCloseIconWrapper>
      )}
    </StyledListItem>
  );
};

export default RecentSearchItem;
