import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { v4 } from 'uuid';
import { ROUTE_PRIVACY } from 'routes';

import Arrow from 'components/UI/Arrow';
import Modal from 'components/Modal';
import MenuButton from 'components/UI/MenuButton';

import { StyledWrap, StyledTitle, StyledSubtitle, StyledText, StyledTextWrap, StyledBottom } from './styled';

interface IPrivacyModalProps {
  bgColor?: string;
  title?: string;
  Icon: FC;
  fullInfo?: { title: string; text: string }[];
  handleClose: () => void;
}

const PrivacyModal: FC<IPrivacyModalProps> = ({ handleClose, bgColor, title, Icon, fullInfo }) => {
  const { push } = useHistory();

  return (
    <Modal handleClose={handleClose} bgColor={bgColor}>
      <StyledWrap>
        <div>
          <Icon />
          <StyledTitle isDark>{title}</StyledTitle>
          {fullInfo?.length &&
            fullInfo.map((item) => (
              <StyledTextWrap key={v4()}>
                <StyledSubtitle>{item.title}</StyledSubtitle>
                <StyledText>{item.text}</StyledText>
              </StyledTextWrap>
            ))}
        </div>
        <StyledBottom>
          <MenuButton
            text="Read our full privacy policy"
            handler={() => {
              push(ROUTE_PRIVACY);
            }}
            isDark
            Icon={() => <Arrow degree={135} isDark />}
            isReversed
          />
        </StyledBottom>
      </StyledWrap>
    </Modal>
  );
};

export default PrivacyModal;
