import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useGetSeeMoreQuestions } from 'graphQL/questions/hooks';
import {
  DiscoverSectionSchema,
  EDiscoverItemLayoutOptions,
  EDiscoverSlotHeadTypeOptions,
} from 'constants/graphqlTypes';

import QuestionSection from 'components/Explore/QuestionSection';

const SeeMoreQuestions: FC = () => {
  const { questionId } = useParams<{ questionId?: string }>();
  const { questionCards } = useGetSeeMoreQuestions(questionId);

  const section: DiscoverSectionSchema = {
    title: 'See more',
    cardItems: questionCards.map((questionCard) => ({
      layout: EDiscoverItemLayoutOptions.FirstUp,
      card: questionCard,
    })),
    headType: EDiscoverSlotHeadTypeOptions.Default,
  };

  return <QuestionSection section={section} />;
};

export default SeeMoreQuestions;
