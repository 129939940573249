import React, { FC, useRef } from 'react';

import { ESearchTag } from 'components/Search/helpers/constants';
import { ESearchTypeOptions, UserObjectType } from 'constants/graphqlTypes';
import { FIND_USER_REGEX } from 'components/ComplexSearchInput/helpers/constants';
import { TEST_SEARCH_USER_OPTION_ITEM } from 'constants/aqa/search';
import { useSearchUsers, useAddToRecentSearches, useGetSuggestionSearches } from 'graphQL/search/hooks';
import Badge from 'components/UI/Badge';
import useClickOutside from 'helpers/useClickOutside';
import useModal from 'helpers/useModal';
import useTheme from 'helpers/useTheme';
import LoaderContent from 'components/UI/LoaderContent';
import { ISuggestionsProps } from './models';

import { StyledItem, StyledLabel, StyledList, StyledSuggestionContainer } from './styled';

const SuggestionsUsers: FC<ISuggestionsProps> = ({ type, userText, setText }) => {
  const isDark = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const { addToRecentSearches } = useAddToRecentSearches();
  const { suggestionSearches } = useGetSuggestionSearches(userText, !!userText);
  const { items, loading } = useSearchUsers(userText, 3, 1);

  const handleUserClick = (user: UserObjectType) => {
    addToRecentSearches(user.id, ESearchTypeOptions.User);
    setText((prev) =>
      prev.endsWith(ESearchTag.User)
        ? `${prev.concat(user.username)} `
        : `${prev.replace(FIND_USER_REGEX, ESearchTag.User.concat(user.username))} `
    );
  };

  const suggestionUsers = userText.length > 1 ? items : suggestionSearches;

  useClickOutside(ref, () => setText((prev) => prev.concat(' ')));
  useModal();

  return (
    <StyledSuggestionContainer ref={ref} isDark={isDark}>
      <StyledList isDark={isDark}>
        {suggestionUsers.length || type === ESearchTag.User ? (
          <>
            {type === ESearchTag.User && <StyledLabel>Suggested</StyledLabel>}
            {suggestionUsers.map(
              (user, index) =>
                user.__typename === 'UserObjectType' && (
                  <StyledItem
                    data-testid={`${TEST_SEARCH_USER_OPTION_ITEM}-${index}-${user.username}`}
                    key={user.id}
                    onClick={() => handleUserClick(user)}
                  >
                    <Badge isDark={isDark} hasUsername user={user} isDisabled />
                  </StyledItem>
                )
            )}
          </>
        ) : (
          <>{loading ? <LoaderContent isDark={isDark} /> : 'No results found'}</>
        )}
      </StyledList>
    </StyledSuggestionContainer>
  );
};

export default SuggestionsUsers;
