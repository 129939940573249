import { styled } from 'linaria/react';
import { COLORS } from '../../../styles/constants';

export const StyledBottom = styled.div<{ isDark: boolean }>`
  border-top: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.dark[10])};
  margin-top: 32px;
`;

export const StyledSliderWrap = styled.div`
  margin: 35px -24px 16px -12px;

  .swiper-slide {
    height: auto;
  }

  > div {
    .slider-prev,
    .slider-next {
      margin-top: 90px;
    }
  }
`;
