const getKeywordsFromTitle = (title: string): string => {
  return (
    title
      // eslint-disable-next-line no-useless-escape
      .replace(/[.,\/#!?$%\^&\*;:{}=\-_`~()]/g, '')
      .replace(/\s{2,}/g, ' ')
      .trim()
      .toLowerCase()
  );
};

export default getKeywordsFromTitle;
