import React, { Dispatch, FC, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';

import Grain from 'components/UI/Grain';
import useModal from 'helpers/useModal';
import useModalFade from 'helpers/useModalFade';
import InteractionBlock from 'components/InteractionBlock';
import InviteBlock from 'components/InviteBlock';
import UILink from 'components/UI/Link';
import usePageType from 'helpers/usePageType';
import withHapticFeedback from 'helpers/withHapticFeedback';
import { ROUTE_CARD_DETAIL } from 'routes';

import { StyledWrap, StyledCloseButton } from 'components/Modal/styled';
import {
  StyledHeader,
  StyledModal,
  StyledTab,
  StyledTabsContainer,
  StyledImage,
  StyledTitle,
  StyledLinkWrap,
} from './styled';

export interface ITabModal {
  tabs: string[];
  currentTab: number;
  bgColor?: string;
  image?: string;
  title?: string;
  handleClose: () => void;
  fadeIn?: boolean;
  setCurrentTab: Dispatch<SetStateAction<number>>;
  hideFade?: boolean;
  handleOpenConnections?: () => void;
  pickId?: string;
  tabDataTestIds?: string[];
}

const TabModal: FC<ITabModal> = ({
  children,
  tabs,
  currentTab,
  bgColor,
  handleClose,
  setCurrentTab,
  image,
  title,
  fadeIn,
  hideFade,
  pickId,
  handleOpenConnections,
  tabDataTestIds,
}) => {
  useModal();
  const { state } = useLocation<{ variable?: { openFromCardDetailPage?: boolean } }>();
  const isOpenedFromDetailPage = state.variable?.openFromCardDetailPage;
  const { isMyProfile } = usePageType();
  const { isOpen, hasFade, onClose } = useModalFade({ handleClose, fadeIn });

  return (
    <StyledWrap isOpen={isOpen} hasTransition={hasFade && !hideFade} onMouseDown={onClose}>
      <StyledModal
        isOpen={isOpen}
        hasTransition={hasFade && !hideFade}
        onMouseDown={(e) => e.stopPropagation()}
        bgColor={bgColor}
      >
        {(title || image) && <Grain />}
        <InteractionBlock swipeSize={50} swipeDownCallback={onClose}>
          <StyledHeader withImage={!!(image || !tabs.length)}>
            <StyledCloseButton isDark isPrev={false} onClick={onClose} />
            <StyledLinkWrap onClick={isOpenedFromDetailPage ? onClose : undefined}>
              <UILink route={ROUTE_CARD_DETAIL} params={{ pickId }} isDisabled={!!isOpenedFromDetailPage}>
                {image && <StyledImage src={image} />}
                {title && <StyledTitle className="tabmodal__title">{title}</StyledTitle>}
              </UILink>
            </StyledLinkWrap>
          </StyledHeader>
        </InteractionBlock>
        {handleOpenConnections && isMyProfile && <InviteBlock isDark />}
        <StyledTabsContainer>
          {tabs.length > 1 &&
            tabs.map((tab, index) => (
              <StyledTab
                customColor={!!bgColor}
                key={tab}
                isActive={index === currentTab}
                onClick={
                  handleOpenConnections ||
                  (index === currentTab ? () => setCurrentTab(index) : withHapticFeedback(() => setCurrentTab(index)))
                }
                data-testid={tabDataTestIds?.[index]}
              >
                {tab}
              </StyledTab>
            ))}
        </StyledTabsContainer>
        {children}
      </StyledModal>
    </StyledWrap>
  );
};
export default TabModal;
