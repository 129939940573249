import { styled } from 'linaria/lib/react';

import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledFormSection = styled.section`
  display: grid;
  height: 636px;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    height: 556px;
  }

  > video {
    position: absolute;
    width: 128%;
    right: 0;
    height: 100%;
    object-fit: cover;
    z-index: 0;

    &::after {
      position: absolute;
    }
  }
`;

export const StyledContent = styled.div`
  z-index: 20;
  padding: 72px 23px 52px;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    align-items: flex-end;
    padding: 63px 50px 55px;
  }

  &::before {
    position: absolute;
    inset: 0;
    z-index: -1;
    content: '';
    background: ${COLORS.black[20]};
  }
`;

export const StyledHeadingContainer = styled.div`
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-width: 521px;
  }
`;

export const StyledSignupHeading = styled.h4`
  color: ${COLORS.white.off[100]};
  display: block;
  font: 400 32px/120% ${FONTS.primary};
  margin: 0;
  padding: 0 47px;
  text-align: center;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    text-align: left;
    padding: 0;
  }
`;

export const StyledSignupSubheading = styled.p`
  color: ${COLORS.white.off[100]};
  font: 600 20px/130% ${FONTS.secondary};
  margin: 0;
  padding: 17px 82px 0;
  text-align: center;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 16px 0 0;
    text-align: left;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-width: 521px;
  }
`;

export const StyledFormField = styled.div`
  border-bottom: 1px solid ${COLORS.white.off[100]};
  display: flex;
  gap: 3px;
  width: 100%;

  &:last-of-type {
    padding-top: 18px;
  }
`;

export const StyledFormLabel = styled.label`
  font: 600 20px/130% ${FONTS.secondary};
  color: ${COLORS.white.off[100]};
  white-space: nowrap;
  flex: 0 0;
`;

export const StyledFormInput = styled.input`
  background: ${COLORS.transparent};
  border: none;
  outline: none;
  font: 600 20px/130% ${FONTS.secondary};
  color: ${COLORS.white.off[100]};
  width: 100%;
  flex: 1 1;
  caret-color: ${COLORS.white.off[100]};
  padding: 0;

  /* remove standart autocomplete styling */

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    border: none;
    background: ${COLORS.transparent};
    outline: none;
    -webkit-text-fill-color: ${COLORS.white.off[100]} !important;
    -webkit-box-shadow: 0 0 0px ${COLORS.transparent};
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const StyledSubmit = styled.button`
  align-items: center;
  background: ${COLORS.white[100]};
  border-radius: 22px;
  border: none;
  color: ${COLORS.brown.dark[100]};
  cursor: pointer;
  display: flex;
  font: 600 14px/140% ${FONTS.secondary};
  justify-content: center;
  margin-top: 18px;
  outline: none;
  padding: 12px 40px;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    margin-top: 40px;
    width: auto;
    align-self: flex-end;
  }
`;
