import React, { FC, useState } from 'react';

import useTheme from 'helpers/useTheme';
import useDocumentTitle from 'helpers/useDocumentTitle';
import LeftMenu from 'components/Navigation/LeftMenu';
import SavedPicksContent from 'components/SavedPicks/SavedPicksContent';
import ArchiveButton from 'components/SavedPicks/ArchiveButton';
import RightMenu from 'components/SavedPicks/Navigation';

import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { useGetSavedPickCount, useGetSavedPicks, useSavedPicksAdding } from 'graphQL/savedPicks/hooks';
import { useNavBehavior } from 'components/Navigation/helpers/hooks';
import { StyledMainHeading, StyledText } from 'pages/CheckEmailPage/styled';
import { StyledNavigation } from 'components/Navigation/styled';
import { StyledBottomMenu, StyledButton, StyledPageInfoContainer, StyledWrap } from './styled';

const pageTitle = 'Saved for later';

const SavedPicks: FC = () => {
  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const { items, loading, loadMore } = useGetSavedPicks({ isArchived: false });
  const { isHide, bgColor, fontColor } = useNavBehavior();

  const isDark = useTheme();
  const { totalCount } = useGetSavedPickCount(true);
  const text = items.length ? "Share a pick from links you've saved" : "You haven't saved anything yet";

  useDocumentTitle(pageTitle);

  const { handlePickerClick, cardsBaseSelection } = useSavedPicksAdding(items);
  const { selectedCards: selectedCardIds } = cardsBaseSelection;

  return (
    <>
      <StyledNavigation color={fontColor} isHide={isHide} bgColor={bgColor}>
        <LeftMenu />
        {!!items.length && (
          <RightMenu
            setDeleteMode={setIsDeleteMode}
            isDeleteMode={isDeleteMode}
            cardsBaseSelection={cardsBaseSelection}
          />
        )}
      </StyledNavigation>
      {!!selectedCardIds.length && (
        <StyledBottomMenu>
          <StyledButton aria-label="Click to add saved picks" onClick={handlePickerClick}>
            <PlusIcon viewBox="0 0 20 20" height="50px" width="50px" strokeWidth="0.75" />
          </StyledButton>
        </StyledBottomMenu>
      )}
      <StyledWrap maxWidth={528}>
        <StyledPageInfoContainer customHeaderGap={8} isPaddingBottom={!!items?.length}>
          <StyledMainHeading isDark={isDark}>{pageTitle}</StyledMainHeading>
          <StyledText isDark={isDark}>{text}</StyledText>
        </StyledPageInfoContainer>
        <SavedPicksContent
          isDark={isDark}
          cards={items}
          loading={loading}
          loadMore={loadMore}
          isSelectable={isDeleteMode}
          cardsSelection={cardsBaseSelection}
        />
        {!!totalCount && <ArchiveButton isDark={isDark} isSmall={!items.length} />}
      </StyledWrap>
    </>
  );
};

export default SavedPicks;
