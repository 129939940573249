import { styled } from 'linaria/react';
import { StyledInfo } from 'components/ProfileSettings/EditProfile/styled';
import { FONTS, COLORS, BREAKPOINTS } from '../../../styles/constants';

export const StyledSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  > section {
    padding-top: 16px;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-height: 60vh;
  }
`;

export const StyledSectionName = styled.span`
  display: block;
  color: ${COLORS.white[100]};
  font: 14px/140% ${FONTS.secondary};
`;

export const StyledMemberWrap = styled.div`
  padding: 16px 0;
  gap: 12px;

  span {
    font-family: ${FONTS.primary};
    font-weight: 400;
  }

  &:not(:last-child) {
    padding-bottom: 0;
  }
`;

export const StyledGroupName = styled(StyledInfo)<{ hasTitle: boolean }>`
  color: ${({ hasTitle }) => `${hasTitle ? COLORS.white[100] : COLORS.white[60]}`};
  padding-bottom: 16px;
`;

export const StyledItemWrap = styled.div`
  border-bottom: 1px solid ${COLORS.white[30]};

  &:last-child {
    border: none;
  }
`;

export const StyledListWrap = styled.div`
  padding-bottom: 10px;
  overflow: auto;
  flex: 1;

  .userlist-item:not(:first-child) {
    padding: 0 0 16px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-height: 410px;
  }
`;
