import React, { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { CardTypeOptions } from 'constants/graphqlTypes';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { useGetUserCardsId } from 'graphQL/cards/userCards/hooks';
import useIsModalOpen from 'helpers/useIsModalOpen';
import { transformRoute, useContentNotFound } from 'helpers/routingHelper';
import { COLORS } from 'styles/constants';

import DetailSlider from 'components/DetailSlider';
import { useNavigationRef } from 'components/Navigation/helpers/hooks';

import { ROUTE_USER_ALL_PICKS_ITEM } from 'routes';

const AllPicksItem: FC = () => {
  const { pickId, profileName } = useParams<{ pickId: string; profileName: string }>();
  const { card, loading } = useGetDetailCard(pickId);
  const { state } = useLocation();
  const { replace } = useHistory();
  const isModalOpen = useIsModalOpen();
  const { ids, loadMore } = useGetUserCardsId();
  const handlerContentNotFound = useContentNotFound();

  if (card && card.type !== CardTypeOptions.Card && !loading) {
    handlerContentNotFound();
  }

  const handleRoute = (id: string) => {
    if (!loading && !isModalOpen) {
      replace({ pathname: transformRoute(ROUTE_USER_ALL_PICKS_ITEM, { profileName, pickId: id }), state });
    }
  };

  useNavigationRef(COLORS.white[100]);

  return ids.length && card ? (
    <DetailSlider handleLoadItems={loadMore} handleRoute={handleRoute} card={card} items={ids.map((id) => ({ id }))} />
  ) : null;
};

export default AllPicksItem;
