import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ApolloClient, from, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { PostHogProvider } from 'posthog-js/react';

import { createBrowserHistory } from 'history';
import getAuthUser from 'helpers/getAuthUser';
import { PusherProvider } from 'helpers/useContextPusher';
import { getSpecificApiLink } from 'helpers/apolloHelpers';
import config from 'constants/config';
import { getErrorHandler } from 'helpers/errorHandler';

import App from './App';

// Pusher initialization
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js');
}

const beamsClient =
  'PushManager' in window
    ? new PusherPushNotifications.Client({
        instanceId: 'ea15644b-d4e0-43ff-8e39-e95b99b3692e',
      })
    : null;

const history = createBrowserHistory();

const contextLink = setContext((_, { headers }) => {
  const { token } = getAuthUser();

  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : null),
    },
  };
});

const errorLink = getErrorHandler(history);

const client = new ApolloClient({
  link: from([contextLink, errorLink, getSpecificApiLink()]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allUsers: {
            keyArgs: ['filters', '@connection', ['key']],
          },
        },
      },
    },
  }),
});

if ('virtualKeyboard' in navigator) {
  const root = document.documentElement;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  navigator.virtualKeyboard.overlaysContent = true;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  navigator.virtualKeyboard.addEventListener('geometrychange', (event) => {
    const { height } = event.target.boundingRect;
    root.style.setProperty('--keyboard-offset', `${height}px`);
  });
}

ReactDOM.render(
  <PostHogProvider apiKey={config.postHogKey} options={{ api_host: config.postHogHost, autocapture: false }}>
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <ApolloProvider client={client}>
        <Router history={history}>
          <PusherProvider value={beamsClient}>
            <App />
          </PusherProvider>
        </Router>
      </ApolloProvider>
    </GoogleOAuthProvider>
  </PostHogProvider>,
  document.getElementById('root')
);
