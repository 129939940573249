import React, { FC } from 'react';

import Button from 'components/UI/Button';

import { ReactComponent as DoubleChevron } from 'assets/icons/DoubleChevron.svg';
import { EUiButtonType } from 'components/UI/Button/constants';
import { usePausebleTimer } from 'helpers/usePausebleTimer';
import useIsModalOpen from 'helpers/useIsModalOpen';

import { StyledImmersiveExploreWrap } from './styled';

const ImmersiveExploreButton: FC<{
  handleSwipe: () => void;
  isReactionsModalOpen?: boolean;
  isReactionButtonMenuShown?: boolean;
}> = ({ handleSwipe, isReactionButtonMenuShown, isReactionsModalOpen }) => {
  const isModalOpen = useIsModalOpen();

  const shouldPause = isReactionButtonMenuShown || isReactionsModalOpen || isModalOpen;

  usePausebleTimer({
    onCompleted: handleSwipe,
    duration: 11000,
    shouldPause,
  });

  return (
    <StyledImmersiveExploreWrap shouldPauseAnimation={shouldPause}>
      <svg width="115" height="51" className="stroke">
        <rect
          className="animate"
          x="0.5"
          y="0.5"
          width="115"
          height="51"
          rx="26"
          stroke="white"
          strokeWidth="1"
          fill="none"
        />
      </svg>
      <Button text="Explore" Icon={<DoubleChevron />} type={EUiButtonType.Primary} handler={handleSwipe} />
    </StyledImmersiveExploreWrap>
  );
};

export default ImmersiveExploreButton;
