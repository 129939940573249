import React, { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { useContentReactions, IContentIds } from 'helpers/useContentReactions';
import { parseToEmoji } from '../helpers/helpers';
import ReactionsEditModal from '../ReactionsEditModal';

import { StyledWrap, StyledAnimatedBackground } from './styled';
import TokenAnimation from '../Animations/ReactionToken/Animation';
import { useAnimationContext } from '../Animations/hooks';

interface IReactionsToken {
  contentIds: IContentIds;
  isDark?: boolean;
  setHasReactions?: (hasReactions: boolean) => void;
  setIsModalOpen?: (isOpen: boolean) => void;
  hideSecondEmoji?: boolean;
  isVertical?: boolean;
  className?: string;
  isDetail?: boolean;
}

const ReactionsToken: FC<IReactionsToken> = ({
  contentIds,
  isVertical,
  isDark,
  setHasReactions,
  hideSecondEmoji,
  className,
  isDetail,
  setIsModalOpen,
}) => {
  const { listReactions, commentReactions, cardDetailReactions } = useContentReactions(contentIds, isDetail);

  const getTargetReactions = () => {
    if (isDetail && !commentReactions.reactions) {
      return cardDetailReactions;
    }
    return commentReactions.reactions ? commentReactions : listReactions;
  };

  const { reactions, loadMoreReactions } = getTargetReactions();

  if (setHasReactions) {
    setHasReactions(!!reactions?.totalCount);
  }

  const emojis =
    reactions?.items.map((emoji) => (emoji?.symbol?.symbol ? parseToEmoji(emoji?.symbol?.symbol) : undefined)) ?? [];
  const portalContainer = document.getElementById('modal-root');

  const { isPlaying } = useAnimationContext();
  const [isModalOpen, setModalOpen] = useState(false);

  const showPlusReactions = reactions?.totalCount && reactions?.totalCount > (hideSecondEmoji ? 1 : 2);

  useEffect(() => {
    if (!reactions?.items.length) {
      setModalOpen(false);
    }
  }, [reactions]);

  useEffect(() => {
    setIsModalOpen?.(isModalOpen);
  }, [isModalOpen]);

  return reactions?.totalCount ? (
    <>
      <StyledWrap className={className} isVertical={isVertical} onClick={() => setModalOpen(true)} isDark={!!isDark}>
        <>
          {isPlaying && <StyledAnimatedBackground isDark={!!isDark} />}
          {emojis[0] && (
            <span>
              {isPlaying && <TokenAnimation emoji={emojis[0]} />}
              {emojis[0]}
            </span>
          )}
          {emojis[1] && !hideSecondEmoji && <span>{emojis[1]}</span>}
          {showPlusReactions && <span>+{reactions.totalCount - (hideSecondEmoji ? 1 : 2)}</span>}
        </>
      </StyledWrap>
      {isModalOpen &&
        portalContainer &&
        createPortal(
          <ReactionsEditModal
            contentIds={contentIds}
            setModalOpen={setModalOpen}
            reactions={reactions}
            loadMoreReactions={loadMoreReactions}
          />,
          portalContainer
        )}
    </>
  ) : null;
};

export default ReactionsToken;
