import React, { FC } from 'react';

import { useSearchPicks } from 'graphQL/search/hooks';
import { ECollectionLayoutOptions } from 'constants/graphqlTypes';
import { TEST_SEARCH_RESULTS_PICKS_PAGE, TEST_SEARCH_RESULTS_PICKS_PAGE_EMPTY_LABEL } from 'constants/aqa/search';

import Masonry from 'components/Masonry';
import ListGrid from 'components/List/Grid';
import useTheme from 'helpers/useTheme';
import { convertSearchString } from '../helpers';
import EmptySearchMessage from './EmptySearchMessage';

const Picks: FC<{ searchText: string }> = ({ searchText }) => {
  const filteredWord = convertSearchString(searchText);

  const { items, loadMore, loading } = useSearchPicks(filteredWord);

  const isDark = useTheme();

  return (
    <>
      {!loading && !items.length && (
        <EmptySearchMessage dataTestId={TEST_SEARCH_RESULTS_PICKS_PAGE_EMPTY_LABEL} isDark={isDark} />
      )}
      {items.length > 5 ? (
        <Masonry
          pageTestId={TEST_SEARCH_RESULTS_PICKS_PAGE}
          items={items}
          loadMore={loadMore}
          loading={loading}
          isDark={isDark}
          showActivity
        />
      ) : (
        <ListGrid
          pageTestId={TEST_SEARCH_RESULTS_PICKS_PAGE}
          layout={ECollectionLayoutOptions.Detail}
          items={items}
          loadMore={loadMore}
          loading={loading}
        />
      )}
    </>
  );
};

export default Picks;
