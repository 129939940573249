import React, { FC } from 'react';

import ListGrid from 'components/List/Grid';

import { ECollectionLayoutOptions } from 'constants/graphqlTypes';
import { EQueryFetchPolicy } from 'constants/common';
import { useGetOtherAnswers } from 'graphQL/questions/hooks';

import { StyledSubtitle } from 'pages/QuestionDetail/styled';
import { StyledWrap } from './styled';

interface IOtherPicksProps {
  questionId?: string;
}

const OtherPicks: FC<IOtherPicksProps> = ({ questionId }) => {
  const { otherAnswers, loading, loadMore, totalCount } = useGetOtherAnswers(
    questionId,
    12,
    EQueryFetchPolicy.CacheAndNetwork
  );

  return otherAnswers?.length ? (
    <>
      <StyledSubtitle>Picks from others</StyledSubtitle>
      <StyledWrap>
        <ListGrid
          hideActionsInfo={false}
          layout={ECollectionLayoutOptions.Default}
          items={otherAnswers}
          loadMore={loadMore}
          loading={loading}
          hasLoadBtn
          totalCount={totalCount}
          showActivity
        />
      </StyledWrap>
    </>
  ) : null;
};

export default OtherPicks;
