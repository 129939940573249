import { styled } from 'linaria/react';
import { StyledPreview as CustomStyledPreview } from 'components/AddContent/AddStyle/styled';
import { BREAKPOINTS } from '../../../styles/constants';

export const StyledPreview = styled(CustomStyledPreview)`
  padding: 32px 0 100px;
  max-width: 177px;
  cursor: auto;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    max-width: 258px;
  }

  .collaborators {
    display: none;
  }
`;
