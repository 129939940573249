import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import useTheme from 'helpers/useTheme';

import {
  TEST_BURGER_MENU_ABOUT_US_BTN,
  TEST_BURGER_MENU_ACTIVITY_BTN,
  TEST_BURGER_MENU_HELP_BTN,
  TEST_BURGER_MENU_SETTINGS_BTN,
} from 'constants/aqa/burgerMenu';
import { ROUTE_ACTIVITY, ROUTE_HELP, ROUTE_SETTINGS, ROUTE_UI, ROUTE_ABOUT } from 'routes';
import { StyledList, StyledListButton } from './styled';

interface IListProps {
  hasActivity: boolean;
  hasAbout: boolean;
  handleClose: () => void;
}

const List: FC<IListProps> = ({ handleClose, hasAbout, hasActivity }) => {
  const isDark = useTheme();
  const { push } = useHistory();

  const handleRoute = (route: string) => {
    handleClose();
    push(route);
  };

  return (
    <StyledList>
      <li>
        <StyledListButton
          data-testid={TEST_BURGER_MENU_ACTIVITY_BTN}
          hasIndicator={hasActivity}
          isDark={isDark}
          onClick={() => handleRoute(ROUTE_ACTIVITY)}
        >
          Activity
        </StyledListButton>
      </li>
      <li>
        <StyledListButton
          data-testid={TEST_BURGER_MENU_ABOUT_US_BTN}
          hasIndicator={hasAbout}
          isDark={isDark}
          onClick={() => handleRoute(ROUTE_ABOUT)}
        >
          About us
        </StyledListButton>
      </li>
      <li>
        <StyledListButton
          data-testid={TEST_BURGER_MENU_SETTINGS_BTN}
          isDark={isDark}
          onClick={() => handleRoute(ROUTE_SETTINGS)}
        >
          Settings
        </StyledListButton>
      </li>
      <li>
        <StyledListButton
          data-testid={TEST_BURGER_MENU_HELP_BTN}
          isDark={isDark}
          onClick={() => handleRoute(ROUTE_HELP)}
        >
          Help
        </StyledListButton>
      </li>
      {process.env.NODE_ENV === 'development' && (
        <li>
          <StyledListButton isDark={isDark} onClick={() => handleRoute(ROUTE_UI)}>
            UI TESTING (dev only)
          </StyledListButton>
        </li>
      )}
    </StyledList>
  );
};

export default List;
