import { styled } from 'linaria/react';
import { BREAKPOINTS, FONTS } from 'styles/constants';

export const StyledSparkPreview = styled.div<{ bg?: string; hasOneItem?: boolean }>`
  align-items: center;
  background-image: ${({ bg }) => `url(${bg})` ?? ''};
  background-size: cover;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 ${({ hasOneItem }) => (hasOneItem ? 24 : 16)}px;
  position: absolute;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0 ${({ hasOneItem }) => (hasOneItem ? 85 : 16)}px;
  }
`;
export const StyledTextWrap = styled.span<{ hasOneItem?: boolean }>`
  display: -webkit-box;
  font: 400 ${({ hasOneItem }) => (hasOneItem ? 32 : 10)}px / 120% ${FONTS.primary};
  overflow: hidden;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    font-size: ${({ hasOneItem }) => (hasOneItem ? 32 : 12)}px;
  }
`;
