import React, { FC, useState } from 'react';

import ListGrid from 'components/List/Grid';
import useTheme from 'helpers/useTheme';
import { useGetSuggestedTopicFeed } from 'graphQL/cards/topicCards/hooks';
import { ECollectionLayoutOptions, TagSchema } from 'constants/graphqlTypes';
import Avatars from 'components/UI/Avatars';
import Wrap from 'components/UI/Wrap/styled';
import TopicsList from 'components/TopicsList';
import TopicUsersModal from 'components/TopicUsersModal';
import {
  StyledTopicTitle,
  StyledContentWrap,
  StyledTitleWrap,
  StyledText,
  StyledHeadWrap,
  StyledAvatarsWrap,
} from './styled';

const TopicDetail: FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    id,
    loading,
    cardItems,
    name,
    cardTotalCount,
    userItems,
    userTotalCount,
    suggestedTags,
    totalCardsWithTag,
    parentTagName,
    loadMore,
  } = useGetSuggestedTopicFeed();

  const isTotalCardsWithTagANumber = typeof totalCardsWithTag === 'number';
  const isDark = useTheme();
  const isShowTopicList =
    suggestedTags && suggestedTags.length > 0 && name && cardItems.length === cardTotalCount && !loading;

  return (
    <Wrap>
      {name && (
        <StyledTitleWrap isDark={isDark} total={cardTotalCount || 0}>
          <StyledTopicTitle isDark={isDark}>{name}</StyledTopicTitle>
        </StyledTitleWrap>
      )}
      <StyledHeadWrap>
        {parentTagName && isTotalCardsWithTagANumber && (
          <StyledText>
            People to follow and their picks to explore, based on your {totalCardsWithTag}{' '}
            {parentTagName?.charAt(0).toUpperCase() + parentTagName?.slice(1)} picks
          </StyledText>
        )}
        <StyledAvatarsWrap onClick={() => setModalOpen(true)}>
          <Avatars users={userItems ?? []} limit={6} hideNumber={false} isBig total={userTotalCount ?? 0} />
        </StyledAvatarsWrap>
      </StyledHeadWrap>
      <StyledContentWrap>
        <ListGrid layout={ECollectionLayoutOptions.Detail} items={cardItems} loadMore={loadMore} loading={loading} />
      </StyledContentWrap>
      {isShowTopicList && name && (
        <TopicsList
          topicListId={id}
          tags={suggestedTags as TagSchema[]}
          title={`Explore topics related to ${name?.charAt(0).toUpperCase() + name?.slice(1)}`}
        />
      )}
      {isModalOpen && <TopicUsersModal handleClose={() => setModalOpen(false)} />}
    </Wrap>
  );
};

export default TopicDetail;
