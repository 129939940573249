import React, { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Button from 'components/UI/Button';

import { EInviteStatusOptions, UserObjectType } from 'constants/graphqlTypes';
import { ROUTE_LIST_EDIT_COLLABORATORS_INVITATION } from 'routes';
import { IAddContentLocation } from 'components/AddContent/helpers/models';
import { EUiButtonType } from 'components/UI/Button/constants';
import { transformRoute } from '../../helpers/routingHelper';

const InviteButton: FC<{ user: UserObjectType }> = ({ user }) => {
  const { invitationStatus } = user;

  const { listId } = useParams<{ listId: string }>();
  const { replace } = useHistory();
  const { state } = useLocation<IAddContentLocation | undefined>();
  const { variable } = state ?? {};

  const handleOpen = () =>
    replace({
      pathname: transformRoute(ROUTE_LIST_EDIT_COLLABORATORS_INVITATION, { listId }),
      state: {
        ...state,
        variable: { ...variable, userId: user.id },
      },
    });

  const isInvited = invitationStatus === EInviteStatusOptions.Invited;

  return (
    <Button
      type={isInvited ? EUiButtonType.Primary : EUiButtonType.Emphasized}
      isDisabled={isInvited}
      text={isInvited ? 'Invited' : 'Invite'}
      handler={handleOpen}
    />
  );
};

export default InviteButton;
