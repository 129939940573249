import { COLORS } from 'styles/constants';

export const PULSING_ANIMATION = 'pulsing 4s ease-in-out infinite both';

export const PULSING_KEYFRAMES = `
  @keyframes pulsing {
    0% {
      background-color: ${COLORS.white[20]};
    }

    50% {
      background-color: ${COLORS.white[8]};
    }
  }
`;

export const INVALID_URL_ERROR_MESSAGE = 'Invalid URL';

export enum ENavigationFlowType {
  AddPick = 'AddPick',
  AddQOTDAnswer = 'AddQOTDAnswer',
  AddSparkResponse = 'AddSparkResponse',
}
