import React, { FC } from 'react';

import getImages from 'helpers/getImages';
import { CardSchema } from 'constants/graphqlTypes';

import { StyledGuestListStylesItem, StyledGuestListStylesItemImage } from './styled';

const GuestListStylesItem: FC<{ guestList: CardSchema }> = ({ guestList }) => {
  const { title } = guestList;
  const { cardStyle } = guestList ?? {};
  const { imageUrl: bgImage } = getImages(cardStyle, true);

  return (
    <StyledGuestListStylesItem>
      <p>The guest:list</p>
      <p>{title}</p>
      <StyledGuestListStylesItemImage src={bgImage} />
    </StyledGuestListStylesItem>
  );
};

export default GuestListStylesItem;
