import { useParams } from 'react-router-dom';

import {
  CardSchema,
  Maybe,
  useGetDetailListQuery,
  useGetDetailListReactionsQuery,
  useGetListItemsIdQuery,
  useGetListItemsQuery,
  useGetListsWithItemQuery,
} from 'constants/graphqlTypes';
import { EQueryFetchPolicy, SHOW_FETCH_MORE_LOADER, REACTIONS_NUMBER_STEP } from 'constants/common';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import {
  IUseGetDetailList,
  IUseGetDetailListReactions,
  IUseGetListItems,
  IUseGetListItemsParams,
  IUseListsWithCard,
  IUseGetDetailListParams,
} from './models';
import { updateListItems } from './helpers';

export const useGetDetailList = ({
  id,
  fetchPolicy,
  coverLimit,
  isDetail,
}: IUseGetDetailListParams): IUseGetDetailList => {
  const { data, loading } = useGetDetailListQuery({
    variables: { id, coverLimit, isDetail },
    fetchPolicy,
    skip: !id,
  });

  return { card: data?.allCards?.items?.[0], loading };
};

export const useGetDetailListReactions = (id?: string, skip?: boolean): IUseGetDetailListReactions => {
  const { data, loading, fetchMore, refetch } = useGetDetailListReactionsQuery({
    variables: { id, reactionsOffset: 0, reactionsLimit: REACTIONS_NUMBER_STEP },
    skip: skip ?? !id,
  });

  const reactions = data?.allCards?.items?.[0]?.reactions;
  const fetchedReactions = reactions?.items || [];
  const totalCount = reactions?.totalCount ?? 0;

  const loadMoreReactions = () =>
    fetchedReactions.length < totalCount &&
    fetchMore({
      variables: { offset: fetchedReactions.length, reactionsLimit: fetchedReactions.length + REACTIONS_NUMBER_STEP },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...prev,
          ...fetchMoreResult,
        };
      },
    });

  const refetchReactions = () => refetch();

  return { reactions, loading, loadMoreReactions, refetchReactions };
};

export const useGetListItems = ({
  id,
  limit,
  skipQuery,
  refPlaceholder,
  onLoadMore,
  fetchPolicy,
}: IUseGetListItemsParams): IUseGetListItems => {
  const { data, loading, fetchMore } = useGetListItemsQuery({
    variables: {
      id,
      offset: 0,
      limit: limit || 10,
      refListId: refPlaceholder ?? id,
    },
    fetchPolicy: fetchPolicy ?? EQueryFetchPolicy.CacheAndNetwork,
    skip: !id || skipQuery,
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.allCards?.items[0]?.cardsFromCollection ?? {};

  const loadMore = () =>
    items?.length &&
    totalCount &&
    items.length < totalCount &&
    !loading &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult.allCards?.items[0].cardsFromCollection?.items) {
          onLoadMore?.(fetchMoreResult.allCards?.items[0].cardsFromCollection?.items);
        }
        return updateListItems(prev, fetchMoreResult);
      },
    });

  return { items: items ?? [], loadMore, totalCount, loading };
};

export const useGetListItemsId = (
  id?: string,
  pickId?: string
): { ids: string[]; total: number; loading: boolean; loadMore: () => void } => {
  const { data, loading, fetchMore } = useGetListItemsIdQuery({
    variables: { id, ...(pickId && { filters: { id: pickId } }) },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
    skip: !id,
  });

  const ids = data?.allCards?.items[0]?.cardsFromCollection?.items.map(({ id: itemId }) => itemId) ?? [];
  const total = data?.allCards?.items[0]?.cardsFromCollection?.totalCount ?? 0;

  const loadMore = () =>
    ids.length < total &&
    !loading &&
    fetchMore({
      variables: { offset: ids.length },
      updateQuery: (prev, { fetchMoreResult }) => updateListItems(prev, fetchMoreResult),
    });

  return { ids, total, loading, loadMore };
};

export const useListsWithCard = (cardId: string): IUseListsWithCard => {
  const { data, loading } = useGetListsWithItemQuery({
    variables: { itemId: cardId },
    skip: !cardId,
  });

  const parentCards = data?.allCards?.items[0].parentCards;
  const listCardIdsWithCard =
    parentCards?.items.reduce(
      (prev: string[], { cardId: parentCardId }) => (parentCardId ? prev.concat(parentCardId) : prev),
      []
    ) ?? [];

  return {
    loading,
    totalCount: parentCards?.totalCount ?? 0,
    lastListTitle: parentCards?.items?.[0]?.title ?? '',
    listCardIdsWithCard,
  };
};

export const useCurrentList = (): Maybe<CardSchema> | undefined => {
  const { listId } = useParams<{ listId: string }>();
  const { card: currentList } = useGetDetailCard(listId);

  return currentList;
};
