import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  ROUTE_NOTIFICATION_SETTING,
  ROUTE_NOTIFICATION_EMAIL_SETTING,
  ROUTE_NOTIFICATION_PUSH_SETTING,
  ROUTE_NOTIFICATION_PUSH_SETTING_SPECIFIC_USERS,
  ROUTE_NOTIFICATION_COLLABORATE_ACTIVITY,
} from 'routes';

import NotificationsMenu from './NotificationsMenu';
import EmailNotifications from './EmailNotifications';
import PushNotifications from './PushNotifications';
import ActivityNotifications from './ActivityNotifications';
import ActivityCollaborateNotifications from './ActivityCollaborateNotification';

const Notifications: FC = () => (
  <Switch>
    <Route exact path={ROUTE_NOTIFICATION_SETTING}>
      <NotificationsMenu />
    </Route>
    <Route exact path={ROUTE_NOTIFICATION_EMAIL_SETTING}>
      <EmailNotifications />
    </Route>
    <Route exact path={ROUTE_NOTIFICATION_PUSH_SETTING}>
      <PushNotifications />
    </Route>
    <Route exact path={ROUTE_NOTIFICATION_PUSH_SETTING_SPECIFIC_USERS}>
      <ActivityNotifications />
    </Route>
    <Route exact path={ROUTE_NOTIFICATION_COLLABORATE_ACTIVITY}>
      <ActivityCollaborateNotifications />
    </Route>
  </Switch>
);

export default Notifications;
