import React, { FC } from 'react';

import { UserObjectType, UserShortSchema } from 'constants/graphqlTypes';

import Avatar from '../Avatar';
import StyledUsers from './styled';

interface IAvatarDoubleProps {
  users: [UserObjectType | UserShortSchema, UserObjectType | UserShortSchema];
  dimension?: number;
  dataTestId?: string;
}

const AvatarDouble: FC<IAvatarDoubleProps> = ({ users, dimension = 32, dataTestId }) => (
  <StyledUsers data-testid={dataTestId} dimension={dimension}>
    <Avatar dimension={dimension} user={users[1]} />
    <Avatar dimension={dimension} user={users[0]} />
  </StyledUsers>
);

export default AvatarDouble;
