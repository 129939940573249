import { ApolloCache } from '@apollo/client';
import { SECTION_ITEMS_LIMIT } from 'components/CustomFeeds/constants';
import {
  ChannelSectionItemsSortTypeOptions,
  GetChannelSectionItemsDocument,
  GetChannelSectionItemsQuery,
} from 'constants/graphqlTypes';

export const deleteSectionFeedFromCache = (
  feedId: string,
  sectionId: string,
  cache: ApolloCache<GetChannelSectionItemsQuery>,
  sorting?: ChannelSectionItemsSortTypeOptions
): void => {
  const cachedFeeds: GetChannelSectionItemsQuery | null = cache.readQuery({
    query: GetChannelSectionItemsDocument,
    variables: { sectionId, limit: SECTION_ITEMS_LIMIT, ...(sorting && { sorting }) },
  });

  if (cachedFeeds) {
    cache.writeQuery({
      query: GetChannelSectionItemsDocument,
      variables: { sectionId, limit: SECTION_ITEMS_LIMIT, ...(sorting && { sorting }) },
      data: {
        channelSectionItemsV2: {
          ...cachedFeeds.channelSectionItemsV2,
          totalCount: cachedFeeds?.channelSectionItemsV2?.totalCount
            ? cachedFeeds?.channelSectionItemsV2?.totalCount - 1
            : 0,
          items: cachedFeeds?.channelSectionItemsV2?.items?.filter(({ id }) => id !== feedId),
        },
      },
    });
  }
};
