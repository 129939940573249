import { useContext } from 'react';

import { DiscoveryTabContext } from 'providers/helpers/contexts';
import { IDiscoveryTab } from 'providers/helpers/models';
import { EExploreTabs } from 'components/Explore/ExploreTabs/helpers/constants';

const useDiscoveryTab = (): IDiscoveryTab => {
  const value = useContext<IDiscoveryTab>(DiscoveryTabContext);

  return {
    ...value,
    setDiscoveryTab: (tab: EExploreTabs | string) => value.setDiscoveryTab?.(tab),
  };
};

export default useDiscoveryTab;
