import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

interface IStyledFooterProps {
  isDark: boolean;
  isKeyboardOpen: boolean;
  isFixed: boolean;
}

export const StyledFooter = styled.footer<IStyledFooterProps>`
  background-color: ${({ isDark }) => (isDark ? COLORS.black[100] : COLORS.white[100])};
  bottom: 0;
  padding: 16px 24px 0;
  padding-bottom: ${({ isKeyboardOpen, isFixed }) => {
    if (isKeyboardOpen) {
      return isFixed ? 'calc(var(--keyboard-offset) + 16px)' : '16px';
    }

    return '24px';
  }};
  position: ${({ isFixed, isKeyboardOpen }) => (isFixed && isKeyboardOpen ? 'fixed' : 'static')};
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding-bottom: 58px;
  }
`;

export const StyledFooterContent = styled.div<IStyledFooterProps>`
  align-items: flex-end;
  display: flex;
  position: relative;
  width: 100%;
`;

export const StyledSubmit = styled.div<{ isShown: boolean }>`
  overflow: hidden;
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  transition: 0.2s all ease-in-out;
  flex: 0 0 ${({ isShown }) => (isShown ? 84 : 0)}px;
  padding-left: ${({ isShown }) => (isShown ? 8 : 0)}px;
`;

export const StyledInputWrap = styled.div<{ isDark: boolean }>`
  background-color: ${({ isDark }) => (isDark ? COLORS.white[10] : COLORS.white.off[100])};
  border-radius: 22px;
  flex-grow: 1;
  padding: 10px 20px 12px;
  position: relative;

  .editable {
    color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    font-family: ${FONTS.secondary};
    font-size: 16px;
    line-height: 140%;
    max-height: 22px;
    min-height: 22px;
    outline: none;
    overflow-wrap: break-word;
    overflow-y: scroll;
    transition: all 0.4s ease;
    vertical-align: baseline;
    width: 100%;
    word-break: break-word;

    span {
      color: ${COLORS.green.dark};
      font-weight: 600;
    }

    &.expanded {
      height: auto;
      max-height: inherit;
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;

export const StyledPlaceholder = styled.div<{ isDark: boolean }>`
  position: absolute;
  left: 20px;
  top: 12px;
  color: ${({ isDark }) => (isDark ? COLORS.white[60] : COLORS.brown.light[100])};
  font-family: ${FONTS.secondary};
  pointer-events: none;
`;
