import {
  ROUTE_ONBOARDING_USERNAME,
  ROUTE_ONBOARDING_FULLNAME,
  ROUTE_ONBOARDING_BIO,
  ROUTE_ONBOARDING_IMAGE,
  ROUTE_ONBOARDING_TOPICS,
  ROUTE_ONBOARDING_NOTIFICATIONS,
  ROUTE_ONBOARDING_SUCCESS,
  ROUTE_ONBOARDING_WELCOME,
} from 'routes';

export const ONBOARDING_ROUTES = [
  ROUTE_ONBOARDING_WELCOME,
  ROUTE_ONBOARDING_FULLNAME,
  ROUTE_ONBOARDING_USERNAME,
  ROUTE_ONBOARDING_BIO,
  ROUTE_ONBOARDING_IMAGE,
  ROUTE_ONBOARDING_TOPICS,
  ROUTE_ONBOARDING_NOTIFICATIONS,
  ROUTE_ONBOARDING_SUCCESS,
];
