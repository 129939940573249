import React, { FC } from 'react';

import StyledTitle from './styled';

interface IContentTitleProps {
  isDark: boolean;
  color?: string;
  dataTestId?: string;
}

const ContentTitle: FC<IContentTitleProps> = ({ isDark, color, children, dataTestId }) => (
  <StyledTitle data-testid={dataTestId} isDark={isDark} color={color}>
    {children}
  </StyledTitle>
);

export default ContentTitle;
