import React, { FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ROUTE_MESSAGES } from 'routes';

import Button from 'components/UI/MenuButton';
import Badge from 'components/UI/Badge';
import BlockButton from 'components/Blocking/UserButton';

import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { ReactComponent as BlockIcon } from 'assets/icons/Block.svg';

import { SingleChatSchema, useGetUserNameQuery } from 'constants/graphqlTypes';
import { useChatNotifications } from 'graphQL/messages/hooks';
import { ENotificationSettingField } from 'graphQL/messages/constants';
import { useProfile } from 'graphQL/profile/hooks';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import { EReportTypes } from 'components/Report/constants';

import { EMenuButtonType } from 'components/UI/MenuButton/constants';
import ChatSection from './ChatSection';
import { StyledMemberWrap, StyledItemWrap } from './styled';

import { getChatMembers } from './helpers';

const SingleChatSettings: FC<{ chat: SingleChatSchema }> = ({ chat }) => {
  const { replace } = useHistory();
  const { pathname, state } = useLocation<{ variable: { chatId: string } }>();
  const { chatId } = state.variable;

  const { userData } = useProfile();

  const users = chat.member ? getChatMembers([chat.member]) : [];
  const { id } = users?.[0] ?? {};
  const { data } = useGetUserNameQuery({ variables: { id } });
  const cardId = data?.allUsers?.items?.[0]?.cardId;

  const { notificationSettings, updateNotificationSettings } = useChatNotifications(chatId);

  const toReport = useNavigateToReport({
    pathname,
    type: EReportTypes.User,
    chatId,
    id,
    userCardId: cardId,
  });

  const handleClose = () => replace(ROUTE_MESSAGES);

  return (
    <>
      <ChatSection name="Members">
        {[userData, ...users].map(
          (chatMember) =>
            chatMember && (
              <StyledMemberWrap key={chatMember.id}>
                <Badge user={chatMember} hasNameOnly />
              </StyledMemberWrap>
            )
        )}
      </ChatSection>
      <ChatSection name="Notifications">
        {notificationSettings.map(
          (setting) =>
            setting &&
            setting.notificationSettingType && (
              <StyledItemWrap key={setting.id}>
                <Button
                  text={ENotificationSettingField[setting.notificationSettingType]}
                  handler={() => updateNotificationSettings(setting.id)}
                  type={EMenuButtonType.Toggle}
                  checked={!!setting.isEnable}
                />
              </StyledItemWrap>
            )
        )}
      </ChatSection>
      <ChatSection name="Actions">
        {users?.[0] && (
          <BlockButton userId={users?.[0]?.id} isBlockedByMe={false} handler={handleClose}>
            <StyledItemWrap>
              <Button text="Block user" Icon={BlockIcon} />
            </StyledItemWrap>
          </BlockButton>
        )}
        <StyledItemWrap>
          <Button text="Report user" Icon={ReportIcon} handler={() => toReport()} />
        </StyledItemWrap>
      </ChatSection>
    </>
  );
};

export default SingleChatSettings;
