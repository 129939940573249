import React, { FC, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { useHistory } from 'react-router-dom';

import { COLORS } from 'styles/constants';
import { ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST } from 'routes';
import { useLink } from 'helpers/routingHelper';

import Modal from 'components/Modal';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import SliderInteractive from 'components/SliderInteractive';
import { UserObjectType } from 'constants/graphqlTypes';
import { useOnModalMouseDown } from './utils/hooks';
import { EActionType, ECurrentMode } from './utils/constants';
import { IUseBehaviour } from './utils/models';
import CarouselCard from './CarouselCard';
import Footer from './Footer';

import { StyledModalWrap, StyledCardCounter, StyledSliderContainer, StyledContentContainer } from './styled';

const { CardFocus, Mode } = EActionType;
const { Default, Comment } = ECurrentMode;

interface IAddSavedContentProps {
  behaviourState: IUseBehaviour;
  userData?: UserObjectType;
}

const AddSavedContent: FC<IAddSavedContentProps> = ({ behaviourState, userData }) => {
  const { replace } = useHistory();
  const AddListLink = useLink(ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST);
  const [showExitWarning, setShowExitWarning] = useState(false);
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  const {
    state,
    buttonData: { text, isBtnTransparent },
    dispatch,
    currentThought,
    setCurrentThought,
  } = behaviourState;

  const { mode, dataToSend, currentCardIndex, cards, newStyleItems } = state;

  const onModalMouseDown = useOnModalMouseDown({
    state,
    dispatch,
    setCurrentThought,
    currentThought,
  });

  const handleClose = () => {
    if (mode === Default) {
      replace(AddListLink);
    }
    setCurrentThought(dataToSend[currentCardIndex]?.commentText ?? '');
    dispatch({ type: Mode, variable: { mode: Default } });
  };

  return (
    <StyledModalWrap onMouseDownCapture={onModalMouseDown}>
      <Modal
        fadeIn={false}
        handleClose={handleClose}
        handlePrev={handleClose}
        buttonText={text}
        isBtnTransparent={isBtnTransparent}
        handleDone={() => {}}
        isExitConfirmed={mode !== Default ? false : isExitConfirmed}
        setShowConfirm={mode === Default ? setShowExitWarning : undefined}
        preventExit={mode !== Default}
      >
        <StyledContentContainer>
          <StyledSliderContainer>
            <SliderInteractive
              isInfinite={false}
              customSlidesPerView="auto"
              fontColor={COLORS.white[100]}
              spaceBetween={11}
              onSlideChange={(index) => setTimeout(() => dispatch({ type: CardFocus, variable: { index } }))}
              touchStartPreventDefault={false}
              slideToClickedSlide
              centeredSlides
            >
              {cards.map((card, index) => (
                <SwiperSlide key={card.id} className={mode === Comment ? 'no-swiping' : undefined}>
                  <CarouselCard
                    card={{
                      ...cards[index],
                      cardStyle: newStyleItems.find(({ cardId }) => cardId === card.id)?.cardStyle,
                    }}
                    mode={mode}
                    isActive={currentCardIndex === index}
                    dispatch={dispatch}
                    newStyleItem={newStyleItems.find(({ cardId }) => cardId === card.id)}
                  />
                </SwiperSlide>
              ))}
            </SliderInteractive>
          </StyledSliderContainer>
        </StyledContentContainer>
        <StyledCardCounter>
          {currentCardIndex + 1} of {cards.length}
        </StyledCardCounter>
        <Footer
          userData={userData}
          dispatch={dispatch}
          currentThought={currentThought}
          setCurrentThought={setCurrentThought}
          state={state}
        />
      </Modal>
      {showExitWarning && (
        <ConfirmationModal
          handleCancel={() => setShowExitWarning(false)}
          handleClickOutside={() => setShowExitWarning(false)}
          handleAccept={() => setIsExitConfirmed(true)}
          isWarning
          buttonText="Discard"
          titleText="Discard pick?"
          subTitle="Your edits and thoughts will be discarded if you leave before posting."
        />
      )}
    </StyledModalWrap>
  );
};

export default AddSavedContent;
