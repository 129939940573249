import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';

import Grain from 'components/UI/Grain/Grain';
import Avatar from 'components/UI/Avatar/Avatar';
import UiLink from 'components/UI/Link';
import { MessageContentStatus, MessageItemType, MessageSchema } from 'constants/graphqlTypes';
import { getGradientImage } from 'components/Sparks/constants';
import { StyledSparkTitle, StyledText, StyledUsername } from 'components/Sparks/Views/Card/styled';
import { useGetMessageContent } from 'graphQL/messages/hooks';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_SPARK_DETAIL } from 'routes';
import ReactionsToken from 'components/Messages/Message/ReactionsToken';
import { useMessagesChatData } from 'components/Messages/helpers/hooks';
import UnavailableContent from '../UnavailableContent';
import { StyledTokenWrap } from '../../styled';
import { EMessageContentType } from '../../models';

import {
  StyledAuthorText,
  StyledInfo,
  StyledResponseCounter,
  StyledSparkContainer,
  StyledSparkTitleWrap,
  StyledWrap,
  StyledAvatarWrap,
  StyledRelWrap,
} from './styled';

interface ISparkProps {
  id: string;
  contentReactionId?: string;
  message: MessageSchema;
  isDark: boolean;
  isMine: boolean;
  messageRef: React.RefObject<HTMLDivElement>;
  setIsContentDeleted: Dispatch<SetStateAction<boolean>>;
}

const { Deleted, Unavailable } = MessageContentStatus;

const Spark: FC<ISparkProps> = ({
  id,
  contentReactionId,
  message,
  isDark,
  isMine,
  messageRef,
  setIsContentDeleted,
}) => {
  const { spark, messageContentStatus } = useGetMessageContent({
    itemType: MessageItemType.Spark,
    entityId: id,
    contentReactionId,
  });

  const { chatId } = useMessagesChatData();
  const { user, title, sparkResponses } = spark ?? {};
  const bgImage = getGradientImage(spark?.cardStyle?.bgColor?.predefinedGradientName ?? '');

  const total = sparkResponses?.totalCount;
  const responsesText = `${total} response${total !== 1 ? 's' : ''}`;

  const showUnavailable = messageContentStatus === Deleted || messageContentStatus === Unavailable;

  useEffect(() => {
    setIsContentDeleted(messageContentStatus === Deleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageContentStatus]);

  const contentIds = {
    chatId,
    messageId: message?.id || '',
  };

  return user && spark && !showUnavailable ? (
    <StyledWrap isRight={isMine}>
      <StyledRelWrap ref={messageRef}>
        <UiLink route={transformRoute(ROUTE_SPARK_DETAIL, { sparkId: spark.id })}>
          <StyledSparkContainer bgImage={bgImage}>
            <StyledInfo>
              <StyledAvatarWrap>
                <Avatar user={user} />
              </StyledAvatarWrap>
              <StyledAuthorText>
                <StyledUsername>{user.userInfo?.firstName ?? user.username}</StyledUsername>
                <StyledText>&nbsp;wonders</StyledText>
              </StyledAuthorText>
              <StyledSparkTitleWrap>
                <StyledSparkTitle>{title}</StyledSparkTitle>
              </StyledSparkTitleWrap>
              {!!total && <StyledResponseCounter>{responsesText}</StyledResponseCounter>}
            </StyledInfo>
            <Grain />
          </StyledSparkContainer>
        </UiLink>
        <StyledTokenWrap className="token-wrap" isRight={isMine}>
          <ReactionsToken contentIds={contentIds} isDark={isDark} />
        </StyledTokenWrap>
      </StyledRelWrap>
    </StyledWrap>
  ) : (
    <>
      {showUnavailable && messageContentStatus && (
        <UnavailableContent
          isDark={isDark}
          isMine={isMine}
          contentType={EMessageContentType.Spark}
          messageContentStatus={messageContentStatus}
        />
      )}
    </>
  );
};

export default Spark;
