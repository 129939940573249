import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';
import { v4 } from 'uuid';

import useTheme from 'helpers/useTheme';
import ActivityItem from 'components/ActivityItem';

import { ActivitySchema } from 'constants/graphqlTypes';
import { TEST_ID_ACTIVITY_VIEW_START } from 'constants/aqa/activity';
import { getActivityListTitle } from 'graphQL/activity/helpers';
import { StyledList, StyledListItem, StyledListTitle } from './styled';

interface IActivityListProps {
  handleLoad: () => void;
  items: ActivitySchema[];
  index: number;
  loading: boolean;
}

const ActivityList: FC<IActivityListProps> = ({ items, index, handleLoad, loading }) => {
  const isDark = useTheme();

  const activityListTestId = `${TEST_ID_ACTIVITY_VIEW_START}_list-${index}`;

  return (
    <>
      <StyledListTitle data-testid={`${activityListTestId}_date-title-label`} isDark={isDark}>
        {getActivityListTitle(index)}
      </StyledListTitle>
      <StyledList data-testid={activityListTestId}>
        {items.map(
          ({ createdAt, activityUnionData, groupingCount }, itemIndex) =>
            activityUnionData && (
              <StyledListItem key={v4()}>
                <ActivityItem
                  activityTestId={`${activityListTestId}_item-${itemIndex}`}
                  count={groupingCount}
                  data={activityUnionData}
                  createdAt={createdAt}
                />
              </StyledListItem>
            )
        )}
        {!loading && <Waypoint onEnter={handleLoad} />}
      </StyledList>
    </>
  );
};

export default ActivityList;
