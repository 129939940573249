import React, { FC, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import {
  ROUTE_ONBOARDING_PREVIEW,
  ROUTE_ONBOARDING,
  ROUTE_ONBOARDING_COMPLETION,
  ROUTE_ONBOARDING_FULLNAME,
  ROUTE_ONBOARDING_NETWORKING,
  ROUTE_ONBOARDING_NOTIFICATIONS,
  ROUTE_ONBOARDING_PROFILE,
  ROUTE_ONBOARDING_WELCOME,
  ROUTE_ONBOARDING_CONTENT,
  ROUTE_ONBOARDING_CONTENT_LIST_DETAIL,
} from 'routes';
import config from 'constants/config';
import usePageType from 'helpers/usePageType';
import { useCheckOnboardingStatus } from 'graphQL/onboarding/hooks';
import { useGetProfileLists } from 'graphQL/cards/userCards/hooks';
import getAuthUser from 'helpers/getAuthUser';
import ContentTutorialRoutes from './ContentTutorial/Routes/ContentTutorialRoutes';

import Welcome from './Welcome';
import InviteCode from './InviteCode';
import Fullname from './Fullname';
import Notifications from './Notifications';
import ProfileSetupRoutes from './ProfileSetup/ProfileSetupRoutes';
import ProfilePreview from './ProfilePreview';
import Completion from './Completion';
import Networking from './Networking';

const OnboardingV2: FC = () => {
  const { areInviteCodesDisabled } = config;
  const { onboardingRedirectStep, shouldRedirectFromVerification, user, loading } = useCheckOnboardingStatus();
  const { push } = useHistory();
  const { state } = useLocation();
  const { isOnboardingVerification } = usePageType();
  const isListDetailOnboardingStep = onboardingRedirectStep === ROUTE_ONBOARDING_CONTENT_LIST_DETAIL;
  const { lists } = useGetProfileLists({
    customUserId: getAuthUser().userId,
    skip: !isListDetailOnboardingStep,
    limit: 1,
    withPrivacy: true,
  });

  const [hasRedirected, setHasRedirected] = useState(false);
  const redirectId = lists?.[0]?.id;

  useEffect(() => {
    const shouldRedirect =
      onboardingRedirectStep && shouldRedirectFromVerification && isOnboardingVerification && !hasRedirected;

    if (shouldRedirect && isListDetailOnboardingStep && redirectId) {
      setHasRedirected(true);
      push({ pathname: onboardingRedirectStep ?? '', state: { ...state, id: redirectId } });
    }
    if (shouldRedirect && !isListDetailOnboardingStep) {
      setHasRedirected(true);
      push(onboardingRedirectStep ?? '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    redirectId,
    isListDetailOnboardingStep,
    onboardingRedirectStep,
    shouldRedirectFromVerification,
    isOnboardingVerification,
    hasRedirected,
  ]);

  return (
    <Switch>
      <Route exact path={ROUTE_ONBOARDING}>
        {areInviteCodesDisabled ? <Welcome /> : <InviteCode />}
      </Route>
      {!user?.activatedAt && !areInviteCodesDisabled && !loading && <Redirect to={ROUTE_ONBOARDING} />}
      {!areInviteCodesDisabled && (
        <Route exact path={ROUTE_ONBOARDING_WELCOME}>
          <Welcome />
        </Route>
      )}
      <Route path={ROUTE_ONBOARDING_PREVIEW}>
        <ProfilePreview />
      </Route>
      <Route path={ROUTE_ONBOARDING_FULLNAME}>
        <Fullname />
      </Route>
      <Route path={ROUTE_ONBOARDING_PROFILE}>
        <ProfileSetupRoutes />
      </Route>
      <Route path={ROUTE_ONBOARDING_COMPLETION}>
        <Completion />
      </Route>
      <Route path={ROUTE_ONBOARDING_CONTENT}>
        <ContentTutorialRoutes />
      </Route>
      <Route path={ROUTE_ONBOARDING_NOTIFICATIONS}>
        <Notifications />
      </Route>
      <Route path={ROUTE_ONBOARDING_NETWORKING}>
        <Networking />
      </Route>
    </Switch>
  );
};

export default OnboardingV2;
