import React, { FC } from 'react';

import getImages from 'helpers/getImages';
import getStyles from 'helpers/getStyles';
import { getActivityTestId } from 'constants/aqa/activity';

import UILink from 'components/UI/Link';

import { CardSchema } from 'constants/graphqlTypes';
import { ROUTE_CARD_DETAIL } from 'routes';
import { StyledThumbnail } from './styled';

const PickPreview: FC<{ pick: CardSchema; activityTestId?: string }> = ({ pick, activityTestId }) => {
  const { cardStyle, id } = pick;
  const { imageUrl } = getImages(cardStyle);
  const { bgColor } = getStyles(cardStyle);
  const dataTestId = activityTestId ? getActivityTestId(activityTestId, 'pick-preview') : '';

  return (
    <UILink route={ROUTE_CARD_DETAIL} params={{ pickId: id }}>
      <StyledThumbnail data-testid={dataTestId} className="thumbnail" src={imageUrl} bg={bgColor} />
    </UILink>
  );
};

export default PickPreview;
