import { styled } from 'linaria/lib/react';
import { COLORS } from 'styles/constants';

export const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding-bottom: 12px;
`;

export const StyledListItem = styled.li`
  padding: 16px 0;

  .badge-title {
    font-weight: 600;
  }

  .badge-subtitle {
    color: ${COLORS.white[100]};
    font-weight: 400;
  }
`;
