import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_HIDDEN_WORDS } from 'routes';
import { EUiButtonType } from 'components/UI/Button/constants';
import { getHiddenAlertTestId } from 'constants/aqa/search';

import OnboardingAlert from 'components/UI/OnboardingAlert';
import Button from 'components/UI/Button';

import { StyledAlertButtons } from './styled';

interface IHiddenWordsAlertProps {
  showHiddenContent: () => void;
  isDark: boolean;
  isSmall?: boolean;
  hasBottomMargin?: boolean;
  itemTestId?: string;
}

const HiddenWordsAlert: FC<IHiddenWordsAlertProps> = ({
  showHiddenContent,
  isDark,
  isSmall = false,
  hasBottomMargin = false,
  itemTestId,
}) => {
  const { push } = useHistory();

  return (
    <OnboardingAlert isDark={isDark} isTransparent hasBottomMargin={hasBottomMargin}>
      <span>Show additional content that may contain hidden words.</span>
      <StyledAlertButtons isSmall={isSmall}>
        <Button
          dataTestId={getHiddenAlertTestId(itemTestId, true)}
          handler={showHiddenContent}
          text="Show"
          type={EUiButtonType.Primary}
          isDark={isDark}
        />
        <Button
          dataTestId={getHiddenAlertTestId(itemTestId)}
          handler={() => push(ROUTE_HIDDEN_WORDS)}
          text="View settings"
          type={EUiButtonType.Primary}
          isDark={isDark}
        />
      </StyledAlertButtons>
    </OnboardingAlert>
  );
};

export default HiddenWordsAlert;
