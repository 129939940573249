import React, { FC, useEffect, useState } from 'react';
import { StyledAnimationContainer } from './styled';

const AnimatedEmoji: FC<{ emoji: string; timeToAppear: number; xCoord: number }> = ({
  emoji,
  timeToAppear,
  xCoord,
}) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const handleShow = setTimeout(() => setIsShown(true), timeToAppear);

    return () => {
      clearTimeout(handleShow);
    };
  }, []);

  return isShown ? (
    <StyledAnimationContainer xCoord={xCoord} isShown={isShown}>
      {emoji}
    </StyledAnimationContainer>
  ) : null;
};

export default AnimatedEmoji;
