import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import getAuthUser from 'helpers/getAuthUser';

import {
  EDateCreatedActivityOptions,
  GetActivityIndicationDocument,
  useGetActivityIndicationQuery,
  useGetActivityQuery,
  useGetReactionsQuery,
  useReadActivityMutation,
} from 'constants/graphqlTypes';
import { useGetSearchParam } from 'helpers/routingHelper';
import { EMPTY_ITEMS, EQueryFetchPolicy, ESearchParams, SHOW_FETCH_MORE_LOADER } from 'constants/common';
import { getCreatedAt, mergeActivity } from './helpers';
import { IUseActivity, IUseReactions } from './models';

export const useActivityIndication = (): boolean => {
  const { username } = getAuthUser();
  const { data } = useGetActivityIndicationQuery({ skip: !username });

  const total = data?.activities?.totalCount ?? 0;

  return !!(total && total > 0);
};

export const useActivity = (): IUseActivity => {
  const { userId } = getAuthUser();
  const variables = { offset: 0, referrerId: userId };

  const { data, loading, fetchMore } = useGetActivityQuery({
    variables,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
  });
  const { data: monthData, loading: monthLoading, fetchMore: monthFetchMore } = useGetActivityQuery({
    variables: { ...variables, created: EDateCreatedActivityOptions.Month },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
    skip: !data,
  });
  const { data: earlierData, loading: earlierLoading, fetchMore: earlierFetchMore } = useGetActivityQuery({
    variables: { ...variables, created: EDateCreatedActivityOptions.Earlier },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
    skip: !monthData,
  });

  const { items, totalCount } = data?.activities ?? EMPTY_ITEMS;
  const { items: monthItems, totalCount: monthTotal } = monthData?.activities ?? EMPTY_ITEMS;
  const { items: earlierItems, totalCount: earlierTotal } = earlierData?.activities ?? EMPTY_ITEMS;

  const allowLoadMore = items.length < totalCount;
  const monthAllowLoadMore = monthItems.length < monthTotal;

  const loadMore = () =>
    allowLoadMore &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => mergeActivity(prev, fetchMoreResult),
    });

  const monthLoadMore = () =>
    monthAllowLoadMore &&
    monthFetchMore({
      variables: { offset: monthItems.length },
      updateQuery: (prev, { fetchMoreResult }) => mergeActivity(prev, fetchMoreResult),
    });

  const earlierLoadMore = () =>
    earlierItems.length < earlierTotal &&
    earlierFetchMore({
      variables: { offset: earlierItems.length },
      updateQuery: (prev, { fetchMoreResult }) => mergeActivity(prev, fetchMoreResult),
    });

  const load = (index: number) => {
    if (index === 2) {
      earlierLoadMore();
      return;
    }

    if (index) {
      monthLoadMore();
      return;
    }

    loadMore();
  };

  return {
    lists: [items, !allowLoadMore ? monthItems : [], !monthAllowLoadMore ? earlierItems : []],
    loading: loading || monthLoading || earlierLoading,
    load,
  };
};

export const useReadActivity = (): void => {
  const { listId } = useParams<{ listId: string }>();

  const [mutation] = useReadActivityMutation({
    variables: { listId },
    refetchQueries: [{ query: GetActivityIndicationDocument }],
  });

  useEffect(() => {
    const update = () => mutation();
    window.addEventListener('beforeunload', update);

    return () => {
      window.removeEventListener('beforeunload', update);
      mutation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useReactions = (): IUseReactions => {
  const { thoughtId } = useParams<{ thoughtId: string }>();

  const createdAt = useGetSearchParam(ESearchParams.CreatedAt);

  const { data, loading, fetchMore } = useGetReactionsQuery({
    variables: { dateCreated: getCreatedAt(createdAt), thoughtId },
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.getCommentReactions ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        const prevItems = prev.getCommentReactions?.items;
        const nextItems = fetchMoreResult?.getCommentReactions?.items;
        const total = prev.getCommentReactions?.totalCount ?? 0;

        return {
          getCommentReactions: {
            ...prev.getCommentReactions,
            totalCount: total,
            items: [...(prevItems ?? []), ...(nextItems ?? [])],
          },
        };
      },
    });

  return { items, loadMore, loading };
};
