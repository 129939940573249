import React, { FC } from 'react';

import OnboardingAlert from 'components/UI/OnboardingAlert';
import { StyledTutorialHeader, StyledTutorialWrapper } from './styled';

const Tutorial: FC<{ handler: () => void }> = ({ handler }) => (
  <StyledTutorialWrapper onMouseDown={handler}>
    <div onMouseDown={(e) => e.stopPropagation()}>
      <OnboardingAlert submitText="OK" handleSubmit={handler}>
        <StyledTutorialHeader>Curate what you’re into</StyledTutorialHeader>
        Here’s a starting list we’ve prepared for you to share what you’re currently listening, watching or reading.
      </OnboardingAlert>
    </div>
  </StyledTutorialWrapper>
);

export default Tutorial;
