import React, { Dispatch, FC } from 'react';
import { isSafariIos } from 'helpers/useUserAgent';

import ReactionsIcons from './helpers';
import { StyledIconWrap, StyledSectionsFilter } from './styled';

interface ISectionsFilterButtons {
  sections: string[];
  selectedSection: string;
  setSelectedSection: Dispatch<React.SetStateAction<string>>;
  setBlock: Dispatch<React.SetStateAction<boolean>>;
}

const SectionsFilterButtons: FC<ISectionsFilterButtons> = ({
  sections,
  selectedSection,
  setSelectedSection,
  setBlock,
}) => {
  const handleClick = (title: string) => {
    const elem = document.getElementById(title);
    elem?.scrollIntoView({ behavior: isSafariIos() ? 'smooth' : 'auto', block: 'start' });

    setSelectedSection?.(title);
    setBlock?.(true);
    setTimeout(() => setBlock?.(false), 600);
  };

  return (
    <StyledSectionsFilter>
      {sections.map((section) => {
        const Icon = ReactionsIcons[section];
        return (
          Icon && (
            <StyledIconWrap key={section} isActive={selectedSection === section} onClick={() => handleClick(section)}>
              <Icon />
            </StyledIconWrap>
          )
        );
      })}
    </StyledSectionsFilter>
  );
};

export default SectionsFilterButtons;
