import { ApolloCache } from '@apollo/client';
import {
  CardSchema,
  GetRecentSearchesDocument,
  GetRecentSearchesQuery,
  SearchSchema,
  UnionSearchDataSchema,
  UserObjectType,
} from 'constants/graphqlTypes';

export const updateSearchResult = (prev: SearchSchema, next?: SearchSchema | null): SearchSchema => ({
  ...prev,
  items: [...prev.items, ...(next?.items ?? [])],
});

export const updateRecentSearchesCache = (cache: ApolloCache<GetRecentSearchesQuery>, searchId: string): void => {
  const data: GetRecentSearchesQuery | null = cache.readQuery({
    query: GetRecentSearchesDocument,
  });
  if (data) {
    cache.writeQuery({
      query: GetRecentSearchesDocument,
      data: {
        recentSearches: {
          ...data.recentSearches,
          items: data?.recentSearches?.items.filter(({ id }) => id !== searchId),
          totalCount: data?.recentSearches?.totalCount ? data?.recentSearches?.totalCount - 1 : 0,
        },
      },
    });
  }
};

export const getSearchCards = (items: UnionSearchDataSchema[]): CardSchema[] =>
  items.reduce((acc: CardSchema[], item) => {
    if (item.__typename === 'CardSchema' && item) {
      return acc.concat(item);
    }
    if (item.__typename === 'CardPickSchema' && item.card) {
      return acc.concat(item.card);
    }
    return acc;
  }, []);

export const getSearchUsers = (items: UnionSearchDataSchema[]): UserObjectType[] =>
  items.reduce((acc: UserObjectType[], item) => (item.__typename === 'UserObjectType' ? acc.concat(item) : acc), []);
