import { styled } from 'linaria/react';

import { COLORS, BREAKPOINTS } from 'styles/constants';

export const StyledWrap = styled.div`
  background: ${COLORS.white[100]};
  padding: 40px 0;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    padding: 50px 0;
  }
`;

export const StyledContainer = styled.div`
  margin-top: 40px;

  .swiper-wrapper {
    transition-timing-function: linear !important;
  }

  .swiper-slide {
    height: auto;
    max-width: 264px;

    > div {
      height: 100%;
    }
  }

  .follow-btn {
    pointer-events: none;
  }
`;
