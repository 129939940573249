import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';

import { NotificationSettingDomain, NotificationSettingType } from 'constants/graphqlTypes';
import {
  COLLABORATING_NOTIFICATIONS_SETTINGS_VIEW,
  USERS_NOTIFICATIONS_SETTINGS_VIEW,
  generateSettingsTestIds,
} from 'constants/aqa/settings/accountManagement';
import { useNotifications } from 'graphQL/profile/notifications/hooks';

import { ROUTE_NOTIFICATION_PUSH_SETTING_SPECIFIC_USERS, ROUTE_NOTIFICATION_COLLABORATE_ACTIVITY } from 'routes';
import useTheme from 'helpers/useTheme';
import MenuButton from 'components/UI/MenuButton';
import { EMenuButtonType } from 'components/UI/MenuButton/constants';
import { StyledList } from 'pages/Settings/styled';
import { StyledItem } from 'components/AddContent/Menu/styled';

import { SETTINGS_LABELS } from './constants';
import { StyledSubTitle } from './styled';

const SettingsSection: FC<{
  domain: NotificationSettingDomain;
  title?: string;
  dataTestId?: string;
}> = ({ domain, title, dataTestId }) => {
  const { push } = useHistory();
  const isDark = useTheme();
  const filter = {
    notificationSettingDomain: domain,
  };
  const { notificationSettings, updateNotificationSetting, loading } = useNotifications(filter);
  const [updatingSetting, setUpdatingSetting] = useState<NotificationSettingType | null>(null);

  const { titleTestId } = generateSettingsTestIds(dataTestId);

  return notificationSettings.length ? (
    <section data-testid={dataTestId}>
      {title && <StyledSubTitle data-testid={titleTestId}>{title}</StyledSubTitle>}
      <StyledList isDark={isDark}>
        {notificationSettings.map(
          ({ notificationSettingType, isEnable }, index) =>
            notificationSettingType && (
              <StyledItem key={notificationSettingType}>
                <MenuButton
                  text={SETTINGS_LABELS[notificationSettingType]}
                  checked={isEnable}
                  isDark={isDark}
                  disabled={loading && updatingSetting === notificationSettingType}
                  type={EMenuButtonType.Toggle}
                  handler={() => {
                    if (!loading) {
                      setUpdatingSetting(notificationSettingType);
                      updateNotificationSetting({
                        isEnable: !isEnable,
                        notificationSettingType,
                        filter,
                      });
                    }
                  }}
                  dataTestId={`${dataTestId}-${index}`}
                />
              </StyledItem>
            )
        )}
        {domain === NotificationSettingDomain.Network && (
          <MenuButton
            text="Activity notifications"
            handler={() => push(ROUTE_NOTIFICATION_PUSH_SETTING_SPECIFIC_USERS)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
            dataTestId={USERS_NOTIFICATIONS_SETTINGS_VIEW}
          />
        )}
        {domain === NotificationSettingDomain.CollaborativeLists && (
          <MenuButton
            text="New activity in collaborative lists"
            handler={() => push(ROUTE_NOTIFICATION_COLLABORATE_ACTIVITY)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
            dataTestId={COLLABORATING_NOTIFICATIONS_SETTINGS_VIEW}
          />
        )}
      </StyledList>
    </section>
  ) : null;
};

export default SettingsSection;
