import React, { FC } from 'react';

import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { UserObjectType } from 'constants/graphqlTypes';

import { StyledBio } from 'components/Collection/styled';
import { StyledUsername } from 'components/Sparks/Views/Card/styled';
import { StyledText, StyledUsernameWrap } from './styled';

interface ListCreatorsTextProps {
  users: UserObjectType[];
  totalCount: number;
  isDark?: boolean;
  showCreators?: boolean;
}

const ListCreatorsText: FC<ListCreatorsTextProps> = ({ users, totalCount, isDark, showCreators = true }) => (
  <StyledUsernameWrap isDark={!!isDark}>
    <StyledUsername>{generateFullName(users[0])}</StyledUsername>
    {users[0]?.isVerified ? <VerificationBadge /> : <>&nbsp;</>}
    {totalCount > 1 && showCreators ? (
      <>
        and&nbsp;
        {totalCount > 2 ? (
          <StyledText>{`${totalCount - 1} others`}</StyledText>
        ) : (
          <>
            <StyledUsername>{generateFullName(users[1])}</StyledUsername>
            {users[1].isVerified && <VerificationBadge />}
          </>
        )}
      </>
    ) : (
      <>{users[0]?.userInfo?.bio && <StyledBio>&nbsp;·&nbsp;{users[0]?.userInfo?.bio}</StyledBio>}</>
    )}
  </StyledUsernameWrap>
);

export default ListCreatorsText;
