import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)<{ disabled?: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  text-decoration: none;
  -webkit-user-drag: none;
`;

export default StyledLink;
