import React, { FC } from 'react';

import Card from 'components/Card';

import { CardSchema, EDiscoverItemLayoutOptions } from 'constants/graphqlTypes';
import { StyledItem, StyledList } from '../styled';
import { isBigItem } from '../helpers';

interface IDefaultViewProps {
  items: CardSchema[];
  isDark: boolean;
  showActivity?: boolean | null;
}

const DefaultView: FC<IDefaultViewProps> = ({ items, isDark, showActivity }) => (
  <StyledList>
    {items.map((item, index) => (
      <StyledItem isBig={isBigItem(index)} key={item.id}>
        <Card
          showActivity={showActivity}
          layout={isBigItem(index) ? EDiscoverItemLayoutOptions.FullBleed : undefined}
          card={item}
          isDark={isDark}
        />
      </StyledItem>
    ))}
  </StyledList>
);

export default DefaultView;
