import { useEffect, useState } from 'react';

import { CardSchema, ECardExtractionOptions, useGetExtractionCardQuery } from 'constants/graphqlTypes';
import { EQueryFetchPolicy } from 'constants/common';

const useGetExtractionCard = (
  id: string,
  skip?: boolean
): { card?: CardSchema; loading: boolean; isQueryCalled: boolean } => {
  const [isQueryCalled, setIsCalled] = useState(false);
  const { startPolling, stopPolling, data, loading } = useGetExtractionCardQuery({
    variables: { id },
    nextFetchPolicy: EQueryFetchPolicy.CacheOnly,
    skip: !id || skip,
    onCompleted: () => setIsCalled(true),
  });
  const card = data?.allCards?.items[0];
  const { extractionStatus } = card ?? {};

  useEffect(() => {
    if (extractionStatus !== ECardExtractionOptions.Complete) {
      startPolling(500);
    } else {
      stopPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractionStatus]);

  return { card, loading, isQueryCalled };
};

export default useGetExtractionCard;
