import React, { FC, Fragment, useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import { ReactComponent as FilterIcon } from 'assets/icons/Filter.svg';

import TabModal from 'components/TabModal';
import {
  CardCommentFilterOptionsEnum,
  CardCommentHiddenWordsFilterOptionsEnum,
  ECardLayoutOptions,
} from 'constants/graphqlTypes';
import { useGetThoughtsCard } from 'graphQL/card/helpers/hooks';
import { IThoughtModal } from 'providers/helpers/models';
import useDisableContextMenu from 'helpers/useDisableContextMenu';
import getImages from 'helpers/getImages';
import getStyles from 'helpers/getStyles';
import useTabsScroll from 'helpers/useTabsScroll';

import HiddenWordsAlert from 'components/HiddenWordsAlert';
import { useThoughts } from './helpers/hooks';
import ThoughtsFilterMenu from './ThoughtsFilterMenu';
import Thought from './Thought';
import ActionEntity from './ActionEntity/ActionEntity';
import ReactionInfo from './ReactionInfo';

import { StyledListItem, StyledFilterItem, StyledWrap, StyledAlertWrap, StyledHiddenTitle } from './styled';

const Thoughts: FC = () => {
  const [hideReaction, setHideReaction] = useState(false);
  const [thoughtCriterion, setThoughtCriterion] = useState(CardCommentFilterOptionsEnum.Default);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const showHiddenContent = () => setIsHidden(false);
  const { replace, goBack } = useHistory();

  const { pathname } = useLastLocation() ?? {};
  const { pickId } = useParams<{ pickId: string }>();

  const card = useGetThoughtsCard(pickId);

  const { id, cardId = '', cardStyle, title, comments } = card ?? {};
  const { totalCount } = comments ?? {};
  const { bgColor, layout } = getStyles(cardStyle);
  const { imageUrl } = getImages(cardStyle, true);
  const { state } = useLocation<{ variable: IThoughtModal; backgroundModal: { pathname: string } }>();
  const { hideTabs, currentTab: initialTab } = state.variable;
  const {
    items,
    loadMore,
    isLoading,
    tabs,
    currentTab,
    setCurrentTab,
    isThoughtTab,
    isPickedByMe,
    totalCount: totalCountNotHidden,
  } = useThoughts(pickId, totalCount ?? 0, thoughtCriterion, CardCommentHiddenWordsFilterOptionsEnum.Nonhidden);
  const { items: hiddenItems, loadMore: hiddenLoadMore, isLoading: isHiddenLoading } = useThoughts(
    pickId,
    totalCount ?? 0,
    thoughtCriterion,
    CardCommentHiddenWordsFilterOptionsEnum.Hidden
  );

  const hasThoughts = !!totalCount;

  const handleScroll = () => {
    setHideReaction(true);
    setTimeout(() => setHideReaction(false), 10);
  };

  const handleClose = () => {
    if (state?.backgroundModal.pathname) {
      replace({ pathname: state?.backgroundModal.pathname, state: { ...state, backgroundModal: undefined } });
      return;
    }
    goBack();
  };

  useDisableContextMenu();

  useEffect(() => {
    if (initialTab) {
      setCurrentTab(initialTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTab]);

  useEffect(() => {
    if (hiddenItems.length) {
      setIsHidden(true);
    }
  }, [hiddenItems.length]);

  const isShowHiddenThought =
    !!hiddenItems.length && !isHiddenLoading && !isLoading && isThoughtTab && totalCountNotHidden === items.length;

  return (
    <TabModal
      bgColor={bgColor}
      currentTab={currentTab}
      tabs={hideTabs ? [] : tabs}
      image={layout !== ECardLayoutOptions.Plain ? imageUrl : undefined}
      title={title ?? ''}
      pickId={card?.id}
      handleClose={handleClose}
      fadeIn={!pathname?.includes('topics') && !pathname?.includes('report')}
      setCurrentTab={setCurrentTab}
    >
      <StyledWrap
        ref={useTabsScroll(currentTab)}
        onScroll={handleScroll}
        noActions={!hasThoughts && isThoughtTab}
        noHorizontalPadding
      >
        {isThoughtTab && hasThoughts && (
          <StyledFilterItem isDark>
            <span>
              {thoughtCriterion === CardCommentFilterOptionsEnum.Default
                ? 'Everyone'
                : thoughtCriterion[0].concat(thoughtCriterion.slice(1).toLowerCase())}
            </span>
            <FilterIcon onClick={() => setIsFiltersOpen(true)} />
          </StyledFilterItem>
        )}
        {(hasThoughts || !isThoughtTab) &&
          items.map((item, index) => (
            <Fragment key={item.id}>
              {!isLoading && index === items.length - 1 && <Waypoint onEnter={loadMore} />}
              <li>
                <Thought cardPickId={id ?? ''} cardId={cardId ?? ''} hideReaction={hideReaction} item={item} />
              </li>
            </Fragment>
          ))}
        {isShowHiddenThought && (
          <>
            {isHidden ? (
              <StyledAlertWrap>
                <HiddenWordsAlert showHiddenContent={showHiddenContent} isDark />
              </StyledAlertWrap>
            ) : (
              <>
                <StyledHiddenTitle>Hidden thought{hiddenItems.length !== 1 ? 's' : ''}</StyledHiddenTitle>
                {(hasThoughts || !isThoughtTab) &&
                  hiddenItems.map((item, index) => (
                    <Fragment key={item.id}>
                      {!isHiddenLoading && index === items.length - 1 && <Waypoint onEnter={hiddenLoadMore} />}
                      <li>
                        <Thought cardPickId={id ?? ''} cardId={cardId ?? ''} hideReaction={hideReaction} item={item} />
                      </li>
                    </Fragment>
                  ))}
              </>
            )}
          </>
        )}
        {!hasThoughts && isThoughtTab && !isLoading && (
          <StyledListItem>
            <ActionEntity pickId={pickId} cardId={cardId} isPickedByMe={!!isPickedByMe} />
          </StyledListItem>
        )}
        {isFiltersOpen && (
          <ThoughtsFilterMenu
            pickId={pickId}
            isOpen={isFiltersOpen}
            handleModal={() => setIsFiltersOpen(false)}
            setCriterion={setThoughtCriterion}
            currentCriterion={thoughtCriterion}
          />
        )}
      </StyledWrap>
      <ReactionInfo />
    </TabModal>
  );
};

export default Thoughts;
