import { styled } from 'linaria/lib/react';
import { StyledFilterBtn as FilterButton } from 'pages/FeedsSection/styled';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledDescription = styled.span`
  font: 400 14px/140% ${FONTS.secondary};
  padding: 8px 0;
`;

export const StyledTitleWrap = styled.div`
  display: flex;
  padding: 32px 0 16px;
`;

export const StyledWrap = styled.div<{ hasCover: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${BREAKPOINTS.s}px;
  padding-top: ${({ hasCover }) => (hasCover ? '0' : '60px')};
  padding-bottom: 16px;
  position: relative;
  width: calc(100% - 48px);
`;

export const StyledFilterBtn = styled(FilterButton)<{ isDark: boolean }>`
  border-radius: 50%;
  border: 1px solid ${({ isDark }) => (isDark ? `${COLORS.white[30]}` : `${COLORS.white.off[100]}`)};
  height: 44px;
  width: 44px;
  min-width: 44px;

  path {
    fill: ${({ isDark }) => (isDark ? `${COLORS.white[100]}` : `${COLORS.brown.light[100]}`)};
  }
`;

export const StyledTitle = styled.span<{ isDark: boolean; total?: number }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: block;
  font-size: 32px;
  line-height: 120%;
  position: relative;
  word-wrap: break-word;

  &::after {
    content: ${({ total }) => (Number.isInteger(total) ? `"${total}"` : 'none')};
    font-size: 14px;
    line-height: 140%;
    padding-left: 5px;
    position: relative;
    top: -15px;
  }
`;

export const StyledAvatarsWrap = styled.div`
  padding-left: 20px;
`;
