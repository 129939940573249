import { styled } from 'linaria/react';

import { COLORS } from 'styles/constants';

export const StyledList = styled.ul`
  background-color: ${COLORS.white[100]};
  border-radius: 50px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25), 0px 1px 3px 0px rgba(0, 0, 0, 0.25),
    0px 5px 5px 0px rgba(0, 0, 0, 0.21), 0px 11px 6px 0px rgba(0, 0, 0, 0.13), 0px 19px 8px 0px rgba(0, 0, 0, 0.04),
    0px 30px 8px 0px rgba(0, 0, 0, 0);
  display: flex;
  gap: 8px;
  list-style: none;
  padding: 8px;
  position: relative;
  margin: 0;
`;

export const StyledToolTipWrap = styled.div`
  .reaction-tooltip {
    background: ${COLORS.transparent};
    padding: 0;
    transition: opacity 0.15s ease-in-out !important;
    z-index: 200;
    position: relative;

    .react-tooltip-arrow {
      background: white;
      height: 21px;
      width: 21px;
      z-index: 100;
    }
  }
`;

export const StyledAbsWrap = styled.div<{ minusTop: number }>`
  position: absolute;
  top: ${({ minusTop }) => -minusTop || -76}px;
  bottom: 0;
  pointer-events: none;
`;

export const StyledRelWrap = styled.div`
  position: relative;
  height: 100%;
`;
