import { v4 } from 'uuid';

import { CardSchema, CardTypeOptions, ColorSchema } from 'constants/graphqlTypes';
import { useProfile } from 'graphQL/profile/hooks';

interface IUseMockSparkCardArgs {
  title: string;
  gradient: ColorSchema;
}
export const useMockSparkCard = ({ title, gradient }: IUseMockSparkCardArgs): CardSchema => {
  const { userData: user } = useProfile();

  const mock = {
    id: v4(),
    type: CardTypeOptions.Spark,
    cardId: v4(),
    user,
    title,
    cardStyle: {
      id: v4(),
      bgColor: { ...gradient },
    },
  };

  return mock;
};
