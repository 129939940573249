import { styled } from 'linaria/react';

import { COLORS } from 'styles/constants';
import { StyledButton as StyledCustomButton } from 'components/Card/UserCard/styled';

export const StyledDeclinedWrap = styled.div`
  border-radius: 20px;
  border: 1px solid ${COLORS.white.off[100]};
  padding: 16px;
  width: 100%;
`;

export const StyledWrap = styled(StyledDeclinedWrap)`
  backdrop-filter: blur(10px);
`;

export const StyledInviteText = styled.span`
  display: block;
  font-size: 14px;
  line-height: 140%;
  padding-top: 16px;
`;

export const StyledBtnWrap = styled.div`
  display: flex;
  gap: 8px;
  margin: 0 auto;
  padding-top: 16px;
`;

export const StyledButton = styled(StyledCustomButton)`
  margin: 0;
  width: 100%;
`;

export const StyledButtonDecline = styled(StyledButton)<{ isDark?: boolean }>`
  background: ${({ isDark }) => (isDark ? COLORS.white[10] : COLORS.white.off[100])};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
`;

export const StyledButtonAccept = styled(StyledButton)<{ isDark?: boolean }>`
  background: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  color: ${({ isDark }) => (isDark ? COLORS.brown.dark[100] : COLORS.white[100])};
`;
