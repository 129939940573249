import React, { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { SingleChatSchema } from 'constants/graphqlTypes';
import { useBlockUser } from 'graphQL/blocking/hooks';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { EReportTypes } from 'components/Report/constants';
import { StyledConfirmationWrap } from './styled';

const BlockedMemberWarning: FC<{ chat: SingleChatSchema }> = ({ chat }) => {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const { pathname } = useLocation();

  const { id: chatId, member, isBlockedByMe, isBlockedMe } = chat;
  const { user } = member ?? {};

  const { unblockUser } = useBlockUser(user?.id ?? '', chatId);
  const toReport = useNavigateToReport({ chatId: id, pathname, type: EReportTypes.Chat });

  return isBlockedByMe || isBlockedMe ? (
    <StyledConfirmationWrap>
      <ConfirmationModal
        titleText="Blocked"
        buttonText={isBlockedByMe ? 'Report' : ''}
        handleAccept={() => toReport()}
        secondButtonText={isBlockedByMe ? 'Unblock' : 'Okay'}
        handleAcceptSecondOption={() => (isBlockedByMe ? unblockUser() : goBack())}
        cancelButtonText={isBlockedByMe ? 'Back' : undefined}
        handleCancel={isBlockedByMe ? () => goBack() : undefined}
        handleClickOutside={() => goBack()}
        isColumnDirection
        subTitle={
          isBlockedByMe
            ? 'You have blocked this user, and are unable to send or receive messages with them.'
            : 'This user has blocked you'
        }
      />
    </StyledConfirmationWrap>
  ) : null;
};

export default BlockedMemberWarning;
