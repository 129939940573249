import React, { FC } from 'react';
import { format } from 'date-fns';
import TruncateMarkup from 'react-truncate-markup';

import Grain from 'components/UI/Grain';
import Avatars from 'components/UI/Avatars';
import UILink from 'components/UI/Link';
import getImages from 'helpers/getImages';
import { CardSchema, ECardLayoutOptions } from 'constants/graphqlTypes';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { ReactComponent as PickFromLinkIcon } from 'assets/icons/PickFromLink.svg';

import { StyledTag } from 'components/Card/Common/styled';
import { ROUTE_ADD_ANSWER, ROUTE_QUESTION_DETAIL } from 'routes';
import useOpenModal from 'helpers/useOpenModal';
import { useTruncatedLines } from './hooks';

import {
  StyledContainer,
  StyledTextPick,
  StyledContent,
  StyledDate,
  StyledFooter,
  StyledNames,
  StyledTitle,
  StyledIcon,
  StyledTextPickTitle,
} from './styled';

const QuestionCard: FC<{ card: CardSchema; isEmpty?: boolean }> = ({ card, isEmpty }) => {
  const { id, title: questionTitle, createdAt, usersWhoAnswered, myAnswers } = card;
  const date = !!createdAt && format(new Date(createdAt), 'MMM d, y');

  const totalAnswers = usersWhoAnswered?.totalCount;
  const otherUsersWhoAnswered = usersWhoAnswered?.items?.slice(0, 3) ?? [];

  const myAnswer = myAnswers?.items[0];
  const { title: answerTitle, mediaTags } = myAnswer ?? {};
  const { cardStyle } = card ?? {};
  const { imageUrl } = getImages(cardStyle);
  const background = cardStyle?.bgColor?.colorValue;
  const tag = mediaTags?.items[0]?.name;

  const openAddContent = useOpenModal(ROUTE_ADD_ANSWER, false, {
    questionTitle,
    questionId: id,
  });

  const onAddContent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    openAddContent();
  };

  const { containerRef, truncatedLines } = useTruncatedLines();

  return (
    <UILink isDisabled={isEmpty} route={ROUTE_QUESTION_DETAIL} params={{ questionId: id }}>
      <StyledContainer hasAnswer={!!myAnswer} background={background} className="question-card">
        {!!myAnswer && <Grain />}
        {date && <StyledDate>{date}</StyledDate>}
        <StyledTitle>{questionTitle}</StyledTitle>
        {!!myAnswer && (
          <StyledContent ref={containerRef}>
            {cardStyle?.layout !== ECardLayoutOptions.Plain ? (
              <img src={imageUrl} alt={answerTitle ?? ''} />
            ) : (
              <StyledTextPick>
                {tag && <StyledTag>{tag}</StyledTag>}
                {!!truncatedLines && (
                  <TruncateMarkup lines={truncatedLines} ellipsis=" [...]">
                    <StyledTextPickTitle>{answerTitle}</StyledTextPickTitle>
                  </TruncateMarkup>
                )}
              </StyledTextPick>
            )}
          </StyledContent>
        )}
        {!isEmpty && (
          <StyledFooter isEmpty={!myAnswer}>
            <Avatars users={otherUsersWhoAnswered.reverse()} limit={3} hideNumber />
            <StyledNames>
              <span className="name">{`${generateFullName(usersWhoAnswered?.items?.[0])}`}</span>
              <span>
                {!!totalAnswers && totalAnswers > 1 && ` and ${totalAnswers - 1} other${totalAnswers > 2 ? 's' : ''}`}
              </span>
            </StyledNames>
            <StyledIcon isDark={!!answerTitle} onClick={onAddContent}>
              <PickFromLinkIcon />
            </StyledIcon>
          </StyledFooter>
        )}
      </StyledContainer>
    </UILink>
  );
};

export default QuestionCard;
