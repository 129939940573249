import { styled } from 'linaria/react';

export const StyledList = styled.ul`
  overflow: auto;
  margin-top: 8px;

  &::-webkit-scrollbar {
    width: 0;
  }
`;
