import { ReactComponent as PeopleIcon } from 'assets/icons/PeopleGroup.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/Person.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg';

export const listDescription = [
  {
    title: 'Collaborate',
    description: 'Team up your tastes and curate lists with others',
    icon: PeopleIcon,
  },
  {
    title: 'Share reels',
    description: 'Export a sizzle reel of your curations to share on your favourite social apps',
    icon: ShareIcon,
  },
  {
    title: 'You’re in control',
    description: 'Lists can be public, private or only visible in your personal network',
    icon: PersonIcon,
  },
];
