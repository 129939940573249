import { styled } from 'linaria/react';

export const StyledWrap = styled.div<{ isPageScrollable?: boolean }>`
  height: ${({ isPageScrollable }) => (isPageScrollable ? 'calc(100dvh - var(--keyboard-offset))' : 'auto')};
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledContainer = styled.div<{
  isPageScrollable?: boolean;
  hasUserCardsSlider?: boolean;
  maxWidth?: number;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: ${({ isPageScrollable }) => (isPageScrollable ? 'auto' : 'calc(100dvh - var(--keyboard-offset))')};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '390px')};
  margin: 0 auto;
  min-height: ${({ hasUserCardsSlider }) => (hasUserCardsSlider ? '100vh' : '0')};
  padding: 75px 24px 45px;
  position: relative;
  overflow-y: ${({ isPageScrollable }) => (isPageScrollable ? 'visible' : 'auto')};
  overflow-x: ${({ isPageScrollable }) => (isPageScrollable ? 'visible' : 'hidden')};

  &::-webkit-scrollbar {
    display: none;
  }

  .swiper {
    margin: ${({ hasUserCardsSlider }) => (hasUserCardsSlider ? '0 -25px 83px' : '0')};
    padding-top: ${({ hasUserCardsSlider }) => (hasUserCardsSlider ? '32px' : '0')};
    max-width: 100vw;
  }
`;

export const StyledInfoContainer = styled.div<{ isWideContent?: boolean; customHeaderGap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ customHeaderGap }) => customHeaderGap ?? 16}px;
  max-width: ${({ isWideContent }) => (isWideContent ? '100%' : '278px')};
  padding-bottom: 16px;

  h2,
  span {
    margin: 0;
  }
`;

export const StyledButtonContainer = styled.div<{ isDark: boolean; maxWidth?: number }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: auto;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '390px')};
  position: relative;
  width: 100%;
  z-index: 10;
`;

export const StyledFileInput = styled.input<{ isImgUploaded?: boolean }>`
  bottom: ${({ isImgUploaded }) => (isImgUploaded ? '0' : 'unset')};
  cursor: pointer;
  height: 44px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: ${({ isImgUploaded }) => (isImgUploaded ? 'unset' : 0)};
  z-index: 10;
`;
