import {
  CardSchema,
  CardTypeOptions,
  ECardBackgroundStyleOptions,
  ECardLayoutOptions,
  EColorTypeOptions,
} from 'constants/graphqlTypes';

export const CAROUSEL_SPEED = 4000;

export const MOCK_CARDS: CardSchema[] = [
  {
    id: '0daa881d-c376-4d7a-9ef3-6c85e2da4317',
    cardId: 'affd97ae-6baf-4e5e-a6cb-77b28c5c83e4',
    type: CardTypeOptions.Card,
    title: 'How Coral Reefs Work',
    externalSources: {
      totalCount: 1,
      items: [
        {
          id: '5cccbabb-9d13-4234-a8f1-9a16a99d43df',
          sourceUrl: 'https://open.spotify.com/episode/5FOhBJBhTRt115oMZ3NofJ',
          sourceDomainName: 'open.spotify.com',
          __typename: 'CardSourceSchema',
        },
      ],
      __typename: 'CardSourceListObjectType',
    },
    mediaTags: {
      totalCount: 1,
      items: [
        {
          id: '204',
          name: 'podcast',
          __typename: 'MediaTagSchema',
        },
      ],
      __typename: 'MediaTagListObjectType',
    },
    cardStyle: {
      bgStyle: ECardBackgroundStyleOptions.Color,
      layout: ECardLayoutOptions.Framed,
      id: '39aeb330-0018-4651-b474-6faa84b78716',
      bgColor: {
        id: 'Q29sb3JTY2hlbWE6YWFiY2JmNWQtZWE1MC00OGE4LTljNzEtMzI3Mzc4MTkxYTI1',
        colorType: EColorTypeOptions.Gradient,
        colorValue: 'linear-gradient(180deg, #d13c35 0%, #822521 100%)',
        fontColor: '#FFFFFF',
        gradientStart: '#d13c35',
        gradientEnd: '#822521',
        predefinedGradientName: null,
        __typename: 'ColorSchema',
      },
      __typename: 'CardStyleSchema',
      thumbnailImageSet: {
        imageId: 'a7a77550-bbba-5c17-b7cf-6971f9a10247',
        images: [
          {
            url: 'https://d2br135murif7r.cloudfront.net/a7a77550-bbba-5c17-b7cf-6971f9a10247-preview',
            fontColor: '#FFFFFF',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJ2E3YTc3NTUwLWJiYmEtNWMxNy1iN2NmLTY5NzFmOWExMDI0NycpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
            __typename: 'ImageSchema',
          },
        ],
        id: 'SW1hZ2VTZXRTY2hlbWE6YTdhNzc1NTAtYmJiYS01YzE3LWI3Y2YtNjk3MWY5YTEwMjQ3',
        __typename: 'ImageSetSchema',
      },
    },
    sourceName: 'spotifypodcast',
    __typename: 'CardSchema',
    pickedByUsers: {
      totalCount: 1,
      items: [
        {
          id: 'f2f815f7-bf88-4c05-80e3-dd47ea2d6186',
          username: 'ariston',
          cardId: '637dec5f-b80e-4f95-8fdf-6e65cebaccec',
          isVerified: true,
          userInfo: {
            firstName: 'Ariston',
            lastName: '',
            bio: 'Culture writer.',
            __typename: 'UserInfoSchema',
          },
          __typename: 'UserObjectType',
          userStyle: {
            id: 'ae2bad0f-a496-45da-bb25-5fa298746b47',
            thumbnailImageSet: {
              id: 'SW1hZ2VTZXRTY2hlbWE6OTMyZWYxYmQtNzBhMy01NjdmLWJmMGUtZWNiM2YyOTk4YmU1',
              imageId: '932ef1bd-70a3-567f-bf0e-ecb3f2998be5',
              images: [
                {
                  url: 'https://d3tmvo86aoqtq8.cloudfront.net/932ef1bd-70a3-567f-bf0e-ecb3f2998be5-preview',
                  id:
                    'SW1hZ2VTY2hlbWE6KFVVSUQoJzkzMmVmMWJkLTcwYTMtNTY3Zi1iZjBlLWVjYjNmMjk5OGJlNScpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
                  __typename: 'ImageSchema',
                },
              ],
              __typename: 'ImageSetSchema',
            },
            __typename: 'CardStyleSchema',
          },
        },
      ],
      __typename: 'UserListObjectType',
    },
  },
  {
    id: '613a0a4a-f623-4a3d-93dd-213c18100395',
    cardId: '4530490a-b033-4967-a7e8-0f89e5e25af9',
    type: CardTypeOptions.Card,
    title: 'How Hip-Hop Conquered the World',
    externalSources: {
      totalCount: 1,
      items: [
        {
          id: 'c860a429-b67d-4d41-a0a4-51259ffe2cfd',
          sourceUrl: 'https://www.nytimes.com/2023/08/10/magazine/hip-hop-50-anniversary.html',
          sourceDomainName: 'nytimes.com',
          __typename: 'CardSourceSchema',
        },
      ],
      __typename: 'CardSourceListObjectType',
    },
    mediaTags: {
      totalCount: 1,
      items: [
        {
          id: '202',
          name: 'article',
          __typename: 'MediaTagSchema',
        },
      ],
      __typename: 'MediaTagListObjectType',
    },
    cardStyle: {
      bgStyle: ECardBackgroundStyleOptions.Color,
      layout: ECardLayoutOptions.Framed,
      id: '7305d01e-a14b-462e-9ec4-c1b729304c88',
      bgColor: {
        id: 'Q29sb3JTY2hlbWE6NWMxZTUwNTAtOWM2My00YTNhLWI5ZGMtOGM3YzRhM2QwOTE1',
        colorType: EColorTypeOptions.Gradient,
        colorValue: 'linear-gradient(180deg, #8b8b8b 0%, #565656 100%)',
        fontColor: '#FFFFFF',
        gradientStart: '#8b8b8b',
        gradientEnd: '#565656',
        predefinedGradientName: null,
        __typename: 'ColorSchema',
      },
      __typename: 'CardStyleSchema',
      thumbnailImageSet: {
        imageId: '55899fc7-bb08-546d-8dd7-f8a9770cf935',
        images: [
          {
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/55899fc7-bb08-546d-8dd7-f8a9770cf935-detail',
            fontColor: '#FFFFFF',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJzU1ODk5ZmM3LWJiMDgtNTQ2ZC04ZGQ3LWY4YTk3NzBjZjkzNScpLCA8SW1hZ2VUeXBlT3B0aW9ucy5ERVRBSUw6ICdkZXRhaWwnPik=',
            __typename: 'ImageSchema',
          },
        ],
        id: 'SW1hZ2VTZXRTY2hlbWE6NTU4OTlmYzctYmIwOC01NDZkLThkZDctZjhhOTc3MGNmOTM1',
        __typename: 'ImageSetSchema',
      },
    },
    sourceName: 'nytimes',
    __typename: 'CardSchema',
    pickedByUsers: {
      totalCount: 1,
      items: [
        {
          id: '12a5ba68-8176-4702-a789-39f8b36e795c',
          username: 'tablehopper',
          cardId: '09c641ee-e375-46f9-a0d2-3d39b168040f',
          isVerified: true,
          userInfo: {
            firstName: 'Marcia',
            lastName: 'Gagliardi',
            bio: 'SF-based restaurant columnist and bohemian.',
            __typename: 'UserInfoSchema',
          },
          __typename: 'UserObjectType',
          userStyle: {
            id: 'ea5bd7f9-d689-429a-ab99-5e1d1e68c32a',
            thumbnailImageSet: {
              id: 'SW1hZ2VTZXRTY2hlbWE6ZWNiM2VjYWYtN2NiMi01MGE4LTg1OTktMzg1YmZmMjNlMzM4',
              imageId: 'ecb3ecaf-7cb2-50a8-8599-385bff23e338',
              images: [
                {
                  url: 'https://d3tmvo86aoqtq8.cloudfront.net/ecb3ecaf-7cb2-50a8-8599-385bff23e338-preview',
                  id:
                    'SW1hZ2VTY2hlbWE6KFVVSUQoJ2VjYjNlY2FmLTdjYjItNTBhOC04NTk5LTM4NWJmZjIzZTMzOCcpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
                  __typename: 'ImageSchema',
                },
              ],
              __typename: 'ImageSetSchema',
            },
            __typename: 'CardStyleSchema',
          },
        },
      ],
      __typename: 'UserListObjectType',
    },
  },
  {
    id: 'efaadb77-f098-4569-a2bc-bee152d38883',
    cardId: '56768eb5-b0dc-459d-9029-92603718dbe0',
    type: CardTypeOptions.Card,
    title: 'Amoako Boafo',
    externalSources: {
      totalCount: 1,
      items: [
        {
          id: '415c262b-2054-404b-9068-64050fb4ac73',
          sourceUrl: 'https://www.seattleartmuseum.org/exhibitions/boafo',
          sourceDomainName: 'seattleartmuseum.org',
          __typename: 'CardSourceSchema',
        },
      ],
      __typename: 'CardSourceListObjectType',
    },
    mediaTags: {
      totalCount: 1,
      items: [
        {
          id: '212',
          name: 'video',
          __typename: 'MediaTagSchema',
        },
      ],
      __typename: 'MediaTagListObjectType',
    },
    cardStyle: {
      bgStyle: ECardBackgroundStyleOptions.Color,
      layout: ECardLayoutOptions.Borderless,
      id: '4b917e45-15d3-444f-b295-bd2a13cc07e9',
      bgColor: {
        id: 'Q29sb3JTY2hlbWE6NzQxZTUxZDItOWZlMS00OWQ2LWE1OGUtMGQ3MjNjZTE5YmJk',
        colorType: EColorTypeOptions.Gradient,
        colorValue: 'linear-gradient(180deg, #A58E66 0%, #867352 100%)',
        fontColor: '#FFFFFF',
        gradientStart: '#967d5d',
        gradientEnd: '#5d4e3a',
        predefinedGradientName: null,
        __typename: 'ColorSchema',
      },
      __typename: 'CardStyleSchema',
      thumbnailImageSet: {
        imageId: 'c8b52bf0-49d3-5d46-babd-2fa113ad3a37',
        images: [
          {
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/c8b52bf0-49d3-5d46-babd-2fa113ad3a37-preview',
            fontColor: '#362E0B',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJ2M4YjUyYmYwLTQ5ZDMtNWQ0Ni1iYWJkLTJmYTExM2FkM2EzNycpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
            __typename: 'ImageSchema',
          },
        ],
        id: 'SW1hZ2VTZXRTY2hlbWE6YzhiNTJiZjAtNDlkMy01ZDQ2LWJhYmQtMmZhMTEzYWQzYTM3',
        __typename: 'ImageSetSchema',
      },
    },
    sourceName: 'seattleartmuseum',
    __typename: 'CardSchema',
    pickedByUsers: {
      totalCount: 1,
      items: [
        {
          id: 'f3a99f04-e844-4a28-894b-a526f4003a93',
          username: 'pete_forester',
          cardId: '34188823-3f2c-481f-b91c-6ddaa6204aa8',
          isVerified: true,
          userInfo: {
            firstName: 'Pete',
            lastName: 'Forester',
            bio: 'Brooklyn based writer.',
            __typename: 'UserInfoSchema',
          },
          __typename: 'UserObjectType',
          userStyle: {
            id: '9363d4eb-e0c4-4cb4-8870-1923c366b286',
            thumbnailImageSet: {
              id: 'SW1hZ2VTZXRTY2hlbWE6YmM1NDkwYjMtZjVlNC01NzJjLWFkYjctMWY4MGE2YjdkYWE3',
              imageId: 'bc5490b3-f5e4-572c-adb7-1f80a6b7daa7',
              images: [
                {
                  url: 'https://d3tmvo86aoqtq8.cloudfront.net/bc5490b3-f5e4-572c-adb7-1f80a6b7daa7-preview',
                  id:
                    'SW1hZ2VTY2hlbWE6KFVVSUQoJ2JjNTQ5MGIzLWY1ZTQtNTcyYy1hZGI3LTFmODBhNmI3ZGFhNycpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
                  __typename: 'ImageSchema',
                },
              ],
              __typename: 'ImageSetSchema',
            },
            __typename: 'CardStyleSchema',
          },
        },
      ],
      __typename: 'UserListObjectType',
    },
  },
  {
    id: '82685a8a-26bf-43d8-8a5f-a94c4cc528dc',
    cardId: '20b0193c-baa8-4127-ada5-f63fa349b42e',
    type: CardTypeOptions.Card,
    title: 'The ‘Vice City’ Guide to Lionel Messi in Miami',
    externalSources: {
      totalCount: 1,
      items: [
        {
          id: '82ec7dbe-950b-4617-8375-fbb43433489f',
          sourceUrl:
            'https://www.theringer.com/soccer/2023/6/8/23753216/lionel-messi-inter-miami-cf-major-league-soccer-gta-vice-city',
          sourceDomainName: 'theringer.com',
          __typename: 'CardSourceSchema',
        },
      ],
      __typename: 'CardSourceListObjectType',
    },
    mediaTags: {
      totalCount: 1,
      items: [
        {
          id: '197',
          name: 'video',
          __typename: 'MediaTagSchema',
        },
      ],
      __typename: 'MediaTagListObjectType',
    },
    cardStyle: {
      bgStyle: ECardBackgroundStyleOptions.Color,
      layout: ECardLayoutOptions.Borderless,
      id: 'aac8f68a-df2f-45eb-b450-9bc1d36ea711',
      bgColor: {
        id: 'Q29sb3JTY2hlbWE6MjhhNWZmNDktZGM0Yy00M2JiLThmZjMtMmM2MzhkYTk5MzQ1',
        colorType: EColorTypeOptions.Gradient,
        colorValue: 'linear-gradient(180deg, #dc4284 0%, #892952 100%)',
        fontColor: '#FFFFFF',
        gradientStart: '#dc4284',
        gradientEnd: '#892952',
        predefinedGradientName: null,
        __typename: 'ColorSchema',
      },
      __typename: 'CardStyleSchema',
      thumbnailImageSet: {
        imageId: 'b335b17a-8cbb-5532-9a7e-2bd1c9c3bc33',
        images: [
          {
            url: 'https://d2br135murif7r.cloudfront.net/b335b17a-8cbb-5532-9a7e-2bd1c9c3bc33-preview',
            fontColor: '#FFFFFF',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJ2IzMzViMTdhLThjYmItNTUzMi05YTdlLTJiZDFjOWMzYmMzMycpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
            __typename: 'ImageSchema',
          },
        ],
        id: 'SW1hZ2VTZXRTY2hlbWE6YjMzNWIxN2EtOGNiYi01NTMyLTlhN2UtMmJkMWM5YzNiYzMz',
        __typename: 'ImageSetSchema',
      },
    },
    sourceName: 'theringer',
    __typename: 'CardSchema',
    pickedByUsers: {
      totalCount: 1,
      items: [
        {
          id: 'cfed5823-7a33-4a92-8966-1035bdffe1f7',
          username: 'itselliott',
          cardId: '8857d0fb-25e8-4bc3-acd7-b41fe7a9d32d',
          isVerified: true,
          userInfo: {
            firstName: 'Elliott',
            lastName: 'Shaw',
            bio: 'Writer',
            __typename: 'UserInfoSchema',
          },
          __typename: 'UserObjectType',
          userStyle: {
            id: '9dffcc4a-8632-4cdc-8da0-eaacdfdb0acb',
            thumbnailImageSet: {
              id: 'SW1hZ2VTZXRTY2hlbWE6OTRhZjZkZTUtNDkxMC01YjZmLThmOTctOWQ2ZDg0OTY2Njhi',
              imageId: '94af6de5-4910-5b6f-8f97-9d6d8496668b',
              images: [
                {
                  url: 'https://d3tmvo86aoqtq8.cloudfront.net/94af6de5-4910-5b6f-8f97-9d6d8496668b-preview',
                  id:
                    'SW1hZ2VTY2hlbWE6KFVVSUQoJzk0YWY2ZGU1LTQ5MTAtNWI2Zi04Zjk3LTlkNmQ4NDk2NjY4YicpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
                  __typename: 'ImageSchema',
                },
              ],
              __typename: 'ImageSetSchema',
            },
            __typename: 'CardStyleSchema',
          },
        },
      ],
      __typename: 'UserListObjectType',
    },
  },
  {
    id: 'c461b523-9761-434e-8cd0-3dcb084f7fb1',
    cardId: '1b1cdd31-6db9-4ecc-b66f-be0fc5713da7',
    type: CardTypeOptions.Card,
    title: 'For Dua Lipa, Just Being a Pop Star Isn’t Enough',
    externalSources: {
      totalCount: 1,
      items: [
        {
          id: '095d60ad-d79b-4edc-9340-994476ca6a67',
          sourceUrl: 'https://www.nytimes.com/2023/08/07/t-magazine/dua-lipa-service95.html',
          sourceDomainName: 'nytimes.com',
          __typename: 'CardSourceSchema',
        },
      ],
      __typename: 'CardSourceListObjectType',
    },
    mediaTags: {
      totalCount: 1,
      items: [
        {
          id: '202',
          name: 'article',
          __typename: 'MediaTagSchema',
        },
      ],
      __typename: 'MediaTagListObjectType',
    },
    cardStyle: {
      bgStyle: ECardBackgroundStyleOptions.Color,
      layout: ECardLayoutOptions.Framed,
      id: '2081e809-a513-4d33-94bd-a5908e07bd4c',
      bgColor: {
        id: 'Q29sb3JTY2hlbWE6MTg1ZmI3MzEtNGU0NC00ZWE2LTlkMzItZWM0NmMxNTVkZGIz',
        colorType: EColorTypeOptions.Gradient,
        colorValue: 'linear-gradient(180deg, #666666 0%, #363636 100%)',
        fontColor: '#FFFFFF',
        gradientStart: '#3a3a38',
        gradientEnd: '#242423',
        predefinedGradientName: null,
        __typename: 'ColorSchema',
      },
      __typename: 'CardStyleSchema',
      thumbnailImageSet: {
        imageId: 'f46b611a-27c2-51c3-a1f0-6cd6d95c2ce9',
        images: [
          {
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/f46b611a-27c2-51c3-a1f0-6cd6d95c2ce9-preview',
            fontColor: '#FFFFFF',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJ2Y0NmI2MTFhLTI3YzItNTFjMy1hMWYwLTZjZDZkOTVjMmNlOScpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
            __typename: 'ImageSchema',
          },
        ],
        id: 'SW1hZ2VTZXRTY2hlbWE6ZjQ2YjYxMWEtMjdjMi01MWMzLWExZjAtNmNkNmQ5NWMyY2U5',
        __typename: 'ImageSetSchema',
      },
    },
    sourceName: 'nytimes',
    __typename: 'CardSchema',
    pickedByUsers: {
      totalCount: 1,
      items: [
        {
          id: 'eee1b688-6b4f-4fc1-9a85-b9811b46ac5e',
          username: 'nia.groce',
          cardId: '2a0e9e79-441b-4312-852a-6afc6194fb42',
          isVerified: true,
          userInfo: {
            firstName: 'Nia',
            lastName: 'G.',
            bio: 'writer | affinity for travel, style, art +',
            __typename: 'UserInfoSchema',
          },
          __typename: 'UserObjectType',
          userStyle: {
            id: '6614eeca-1ef1-4dc1-a71a-1e3003a89137',
            thumbnailImageSet: {
              id: 'SW1hZ2VTZXRTY2hlbWE6NGM4ZGQ2NjAtZjI0Ni01MTJmLWEyMjItYTk5N2EwMTlkZDM1',
              imageId: '4c8dd660-f246-512f-a222-a997a019dd35',
              images: [
                {
                  url: 'https://d3tmvo86aoqtq8.cloudfront.net/4c8dd660-f246-512f-a222-a997a019dd35-preview',
                  id:
                    'SW1hZ2VTY2hlbWE6KFVVSUQoJzRjOGRkNjYwLWYyNDYtNTEyZi1hMjIyLWE5OTdhMDE5ZGQzNScpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
                  __typename: 'ImageSchema',
                },
              ],
              __typename: 'ImageSetSchema',
            },
            __typename: 'CardStyleSchema',
          },
        },
      ],
      __typename: 'UserListObjectType',
    },
  },
  {
    id: '5b96fc22-a0ee-477e-bbee-e0fa3fa4160f',
    cardId: 'a5082e6a-12d0-4670-a263-b54864b42772',
    type: CardTypeOptions.Card,
    title: 'How ‘The Bear’ Star Jeremy Allen White Became America’s Top Chef',
    externalCreators: {
      totalCount: 1,
      items: [],
      __typename: 'CardCreatorListObjectType',
    },
    externalSources: {
      totalCount: 1,
      items: [
        {
          id: '3bb32013-c60f-404a-bf1b-ecc5ba52d9c9',
          sourceUrl: 'https://www.thewrap.com/jeremy-allen-white-the-bear-interview-emmys/',
          sourceDomainName: 'thewrap.com',
          __typename: 'CardSourceSchema',
        },
      ],
      __typename: 'CardSourceListObjectType',
    },
    mediaTags: {
      totalCount: 1,
      items: [
        {
          id: '202',
          name: 'article',
          __typename: 'MediaTagSchema',
        },
      ],
      __typename: 'MediaTagListObjectType',
    },
    cardStyle: {
      bgStyle: ECardBackgroundStyleOptions.Color,
      layout: ECardLayoutOptions.Framed,
      id: '642933f9-62b3-48c9-af0e-f82e08c25978',
      bgColor: {
        id: 'Q29sb3JTY2hlbWE6YTkzMTYwY2EtZmE4NS00MDdjLWFiZTctNDE0NWVkMzZkNWJk',
        colorType: EColorTypeOptions.Gradient,
        colorValue: 'linear-gradient(180deg, #154882 0%, #0E284C 100%)',
        fontColor: '#FFFFFF',
        gradientStart: '#173360',
        gradientEnd: '#0e1f3c',
        predefinedGradientName: null,
        __typename: 'ColorSchema',
      },
      __typename: 'CardStyleSchema',
      thumbnailImageSet: {
        imageId: '2c2f3bf0-ea91-5b6b-a784-1c76b4772069',
        images: [
          {
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/2c2f3bf0-ea91-5b6b-a784-1c76b4772069-detail',
            fontColor: '#362E0B',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJzJjMmYzYmYwLWVhOTEtNWI2Yi1hNzg0LTFjNzZiNDc3MjA2OScpLCA8SW1hZ2VUeXBlT3B0aW9ucy5ERVRBSUw6ICdkZXRhaWwnPik=',
            __typename: 'ImageSchema',
          },
        ],
        id: 'SW1hZ2VTZXRTY2hlbWE6MmMyZjNiZjAtZWE5MS01YjZiLWE3ODQtMWM3NmI0NzcyMDY5',
        __typename: 'ImageSetSchema',
      },
    },
    sourceName: 'thewrap',
    __typename: 'CardSchema',
    pickedByUsers: {
      totalCount: 1,
      items: [
        {
          id: 'ddbed73e-1b45-49fe-851e-6b1f32968dd0',
          username: 'kidblast',
          cardId: '5db03123-5ee3-4fc6-9e19-d6433309fc08',
          isVerified: true,
          userInfo: {
            firstName: 'mj',
            lastName: 'deery',
            bio: 'Storyteller for social change',
            __typename: 'UserInfoSchema',
          },
          __typename: 'UserObjectType',
          userStyle: {
            id: 'e08375a6-0f32-4753-8d8b-df1ce486f8d5',
            thumbnailImageSet: {
              id: 'SW1hZ2VTZXRTY2hlbWE6MjgxYWY1NGYtOGE3My01ZjBhLTg1Y2EtYzlmZjQxMGZkYjhj',
              imageId: '281af54f-8a73-5f0a-85ca-c9ff410fdb8c',
              images: [
                {
                  url: 'https://d3tmvo86aoqtq8.cloudfront.net/281af54f-8a73-5f0a-85ca-c9ff410fdb8c-preview',
                  id:
                    'SW1hZ2VTY2hlbWE6KFVVSUQoJzI4MWFmNTRmLThhNzMtNWYwYS04NWNhLWM5ZmY0MTBmZGI4YycpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
                  __typename: 'ImageSchema',
                },
              ],
              __typename: 'ImageSetSchema',
            },
            __typename: 'CardStyleSchema',
          },
        },
      ],
      __typename: 'UserListObjectType',
    },
  },
  {
    id: '0dcb4343-1966-4d27-865e-5cbf23f0b951',
    cardId: 'b643792f-3f74-47d4-abec-a5d7b7bca0ab',
    type: CardTypeOptions.Card,
    title: 'Watch Severance',
    externalSources: {
      totalCount: 1,
      items: [
        {
          id: '80acee1c-8d3c-41ca-961e-d77405616ecd',
          sourceUrl: 'https://tv.apple.com/us/show/severance/umc.cmc.1srk2goyh2q2zdxcx605w8vtx',
          sourceDomainName: 'tv.apple.com',
          __typename: 'CardSourceSchema',
        },
      ],
      __typename: 'CardSourceListObjectType',
    },
    mediaTags: {
      totalCount: 1,
      items: [
        {
          id: '197',
          name: 'video',
          __typename: 'MediaTagSchema',
        },
      ],
      __typename: 'MediaTagListObjectType',
    },
    cardStyle: {
      bgStyle: ECardBackgroundStyleOptions.Color,
      layout: ECardLayoutOptions.Borderless,
      id: '07f223c1-e2e7-4729-b424-fab892baf15e',
      bgColor: {
        id: 'Q29sb3JTY2hlbWE6MTIzODk5MjEtMWVhYi00Mjk0LTllNDItOTFmOTRhYWQ2ZTll',
        colorType: EColorTypeOptions.Gradient,
        colorValue: 'linear-gradient(180deg, #1E3E2F 0%, #0A2619 100%)',
        fontColor: '#FFFFFF',
        gradientStart: '#253733',
        gradientEnd: '#17221f',
        predefinedGradientName: null,
        __typename: 'ColorSchema',
      },
      __typename: 'CardStyleSchema',
      thumbnailImageSet: {
        imageId: '7a442292-5dd2-5522-b8c0-a7f31ec78a70',
        images: [
          {
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/7a442292-5dd2-5522-b8c0-a7f31ec78a70-preview',
            fontColor: '#FFFFFF',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJzdhNDQyMjkyLTVkZDItNTUyMi1iOGMwLWE3ZjMxZWM3OGE3MCcpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
            __typename: 'ImageSchema',
          },
        ],
        id: 'SW1hZ2VTZXRTY2hlbWE6N2E0NDIyOTItNWRkMi01NTIyLWI4YzAtYTdmMzFlYzc4YTcw',
        __typename: 'ImageSetSchema',
      },
    },
    sourceName: 'appletv',
    __typename: 'CardSchema',
    pickedByUsers: {
      totalCount: 1,
      items: [
        {
          id: '229d1e6c-64b4-41ff-b438-1cee7b4931be',
          username: 'tom.jackson',
          cardId: '5189a095-5b79-4e1a-81d6-426973521dc9',
          isVerified: true,
          userInfo: {
            firstName: 'Tom',
            lastName: 'Jackson',
            bio: 'Maker of things',
            __typename: 'UserInfoSchema',
          },
          __typename: 'UserObjectType',
          userStyle: {
            id: 'bac05c74-4a79-47c8-acae-c72692c742b6',
            thumbnailImageSet: {
              id: 'SW1hZ2VTZXRTY2hlbWE6Nzc0YzY0M2YtODRjYy01OWY0LWJmNzItMWUzMTEyM2MwNTI0',
              imageId: '774c643f-84cc-59f4-bf72-1e31123c0524',
              images: [
                {
                  url: 'https://d3tmvo86aoqtq8.cloudfront.net/774c643f-84cc-59f4-bf72-1e31123c0524-preview',
                  id:
                    'SW1hZ2VTY2hlbWE6KFVVSUQoJzc3NGM2NDNmLTg0Y2MtNTlmNC1iZjcyLTFlMzExMjNjMDUyNCcpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
                  __typename: 'ImageSchema',
                },
              ],
              __typename: 'ImageSetSchema',
            },
            __typename: 'CardStyleSchema',
          },
        },
      ],
      __typename: 'UserListObjectType',
    },
  },
  {
    id: 'ad35902e-8304-47ee-8eb7-4c4e779f0a06',
    cardId: 'b27e8c79-9ffe-4e52-96c5-d46c6aec8de5',
    type: CardTypeOptions.Card,
    title: 'Jonathan the Tortoise: World’s oldest living land animal celebrates 191st birthday',
    externalSources: {
      totalCount: 1,
      items: [
        {
          id: '4a399ab6-fb59-4c1e-861f-60b06214db5d',
          sourceUrl:
            'https://www.euronews.com/green/2023/12/04/jonathan-the-tortoise-worlds-oldest-living-land-mammal-celebrates-190th-birthday?',
          sourceDomainName: 'euronews.com',
          __typename: 'CardSourceSchema',
        },
      ],
      __typename: 'CardSourceListObjectType',
    },
    mediaTags: {
      totalCount: 1,
      items: [
        {
          id: '202',
          name: 'article',
          __typename: 'MediaTagSchema',
        },
      ],
      __typename: 'MediaTagListObjectType',
    },
    cardStyle: {
      bgStyle: ECardBackgroundStyleOptions.Color,
      layout: ECardLayoutOptions.Framed,
      id: 'd245f744-6a5a-49cb-8c68-822a275da13c',
      bgColor: {
        id: 'Q29sb3JTY2hlbWE6NjkwZGFlYjktNzY3MC00YjlmLWIwY2QtNjM2OTVlYTcwOTRj',
        colorType: EColorTypeOptions.Gradient,
        colorValue: 'linear-gradient(180deg, #859F43 0%, #4C530C 100%)',
        fontColor: '#FFFFFF',
        gradientStart: '#3b321b',
        gradientEnd: '#241f10',
        predefinedGradientName: null,
        __typename: 'ColorSchema',
      },
      __typename: 'CardStyleSchema',
      thumbnailImageSet: {
        imageId: 'c7e9d986-94ca-57d3-870a-c998a8e57a57',
        images: [
          {
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/c7e9d986-94ca-57d3-870a-c998a8e57a57-preview',
            fontColor: '#FFFFFF',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJ2M3ZTlkOTg2LTk0Y2EtNTdkMy04NzBhLWM5OThhOGU1N2E1NycpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
            __typename: 'ImageSchema',
          },
        ],
        id: 'SW1hZ2VTZXRTY2hlbWE6YzdlOWQ5ODYtOTRjYS01N2QzLTg3MGEtYzk5OGE4ZTU3YTU3',
        __typename: 'ImageSetSchema',
      },
    },
    sourceName: 'euronews',
    __typename: 'CardSchema',
    pickedByUsers: {
      totalCount: 1,
      items: [
        {
          id: 'f2f815f7-bf88-4c05-80e3-dd47ea2d6186',
          username: 'ariston',
          cardId: '637dec5f-b80e-4f95-8fdf-6e65cebaccec',
          isVerified: true,
          userInfo: {
            firstName: 'Ariston',
            lastName: '',
            bio: 'Culture writer.',
            __typename: 'UserInfoSchema',
          },
          __typename: 'UserObjectType',
          userStyle: {
            id: 'ae2bad0f-a496-45da-bb25-5fa298746b47',
            thumbnailImageSet: {
              id: 'SW1hZ2VTZXRTY2hlbWE6OTMyZWYxYmQtNzBhMy01NjdmLWJmMGUtZWNiM2YyOTk4YmU1',
              imageId: '932ef1bd-70a3-567f-bf0e-ecb3f2998be5',
              images: [
                {
                  url: 'https://d3tmvo86aoqtq8.cloudfront.net/932ef1bd-70a3-567f-bf0e-ecb3f2998be5-preview',
                  id:
                    'SW1hZ2VTY2hlbWE6KFVVSUQoJzkzMmVmMWJkLTcwYTMtNTY3Zi1iZjBlLWVjYjNmMjk5OGJlNScpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
                  __typename: 'ImageSchema',
                },
              ],
              __typename: 'ImageSetSchema',
            },
            __typename: 'CardStyleSchema',
          },
        },
      ],
      __typename: 'UserListObjectType',
    },
  },
  {
    id: 'a921fa74-7bcc-4dbd-adab-0c4875e0e661',
    cardId: '05ba6cde-34f9-4d59-924b-1d31f686e0da',
    type: CardTypeOptions.Card,
    title: 'André’s One Man Show',
    externalSources: {
      totalCount: 1,
      items: [
        {
          id: '54c62111-6f1a-4935-a2f6-9cc6ddd84699',
          sourceUrl: 'https://citizen-magazine.co/andres-one-man-show/',
          sourceDomainName: 'citizen-magazine.co',
          __typename: 'CardSourceSchema',
        },
      ],
      __typename: 'CardSourceListObjectType',
    },
    mediaTags: {
      totalCount: 1,
      items: [
        {
          id: '202',
          name: 'article',
          __typename: 'MediaTagSchema',
        },
      ],
      __typename: 'MediaTagListObjectType',
    },
    cardStyle: {
      bgStyle: ECardBackgroundStyleOptions.Color,
      layout: ECardLayoutOptions.Framed,
      id: '633d9cb8-3234-4937-b359-ee9a4eb69964',
      bgColor: {
        id: 'Q29sb3JTY2hlbWE6M2ZiYWYyMmMtODNmYS00NDFkLTlhMjgtZWQ3OGVhMWI0YjQw',
        colorType: EColorTypeOptions.Gradient,
        colorValue: 'linear-gradient(180deg, #BBBEB3 0%, #727F76 100%)',
        fontColor: '#FFFFFF',
        gradientStart: '#908f88',
        gradientEnd: '#5a5955',
        predefinedGradientName: null,
        __typename: 'ColorSchema',
      },
      __typename: 'CardStyleSchema',
      thumbnailImageSet: {
        imageId: 'eaaf999c-adf3-5458-ba7a-98b5f815425b',
        images: [
          {
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/eaaf999c-adf3-5458-ba7a-98b5f815425b-detail',
            fontColor: '#362E0B',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJ2VhYWY5OTljLWFkZjMtNTQ1OC1iYTdhLTk4YjVmODE1NDI1YicpLCA8SW1hZ2VUeXBlT3B0aW9ucy5ERVRBSUw6ICdkZXRhaWwnPik=',
            __typename: 'ImageSchema',
          },
        ],
        id: 'SW1hZ2VTZXRTY2hlbWE6ZWFhZjk5OWMtYWRmMy01NDU4LWJhN2EtOThiNWY4MTU0MjVi',
        __typename: 'ImageSetSchema',
      },
    },
    sourceName: 'citizen-magazine',
    __typename: 'CardSchema',
    pickedByUsers: {
      totalCount: 1,
      items: [
        {
          id: 'eee1b688-6b4f-4fc1-9a85-b9811b46ac5e',
          username: 'nia.groce',
          cardId: '2a0e9e79-441b-4312-852a-6afc6194fb42',
          isVerified: true,
          userInfo: {
            firstName: 'Nia',
            lastName: 'G.',
            bio: 'writer | affinity for travel, style, art +',
            __typename: 'UserInfoSchema',
          },
          __typename: 'UserObjectType',
          userStyle: {
            id: '6614eeca-1ef1-4dc1-a71a-1e3003a89137',
            thumbnailImageSet: {
              id: 'SW1hZ2VTZXRTY2hlbWE6NGM4ZGQ2NjAtZjI0Ni01MTJmLWEyMjItYTk5N2EwMTlkZDM1',
              imageId: '4c8dd660-f246-512f-a222-a997a019dd35',
              images: [
                {
                  url: 'https://d3tmvo86aoqtq8.cloudfront.net/4c8dd660-f246-512f-a222-a997a019dd35-preview',
                  id:
                    'SW1hZ2VTY2hlbWE6KFVVSUQoJzRjOGRkNjYwLWYyNDYtNTEyZi1hMjIyLWE5OTdhMDE5ZGQzNScpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
                  __typename: 'ImageSchema',
                },
              ],
              __typename: 'ImageSetSchema',
            },
            __typename: 'CardStyleSchema',
          },
        },
      ],
      __typename: 'UserListObjectType',
    },
  },
];
