import React, { FC, useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import { usePostHogCapture } from 'helpers/posthogHooks';
import AnimatedBackground from 'components/AnimatedBackground';
import Loader from 'components/UI/Loader';
import useToast from 'helpers/useToast';

import { ROUTE_SIGNIN } from 'routes';
import {
  TEST_CHECK_MAIL_OPEN_EMAIL_BTN,
  TEST_CHECK_MAIL_SUBTITLE_FIRST,
  TEST_CHECK_MAIL_SUBTITLE_SECOND,
  TEST_CHECK_MAIL_TITLE,
} from 'constants/aqa/signup';
import { useAuthTokenQuery, OauthTokenTypes } from 'constants/graphqlTypes';
import { EQueryFetchPolicy } from 'constants/common';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { StyledContent, StyledMainHeading, StyledLoader, StyledResend, StyledText, StyledWrapper } from './styled';

const CheckEmailPage: FC = () => {
  const { replace } = useHistory();
  const { state } = useLocation<{ variable?: { email: string } }>();
  const { email } = state?.variable ?? {};
  const { setToast } = useToast();
  const posthogCapture = usePostHogCapture();

  const [isRefetched, setIsRefetched] = useState(false);

  const handleError = (err: ApolloError) => {
    setToast({
      isToastOpen: true,
      toastError: true,
      toastItemName: err?.message,
      linesCount: 2,
    });
  };

  const { data, loading, refetch } = useAuthTokenQuery({
    skip: !email,
    variables: { email, tokenType: OauthTokenTypes.MagicLink },
    fetchPolicy: EQueryFetchPolicy.NoCache,
    onError: handleError,
  });
  const { isNewUser } = data?.authToken ?? {};

  useEffect(() => {
    if (!email) {
      replace(ROUTE_SIGNIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (isNewUser) {
      posthogCapture(POSTHOG_EVENTS.SignUpMagicLink);
      posthogCapture(POSTHOG_EVENTS.SignUp);
    }
  }, [isNewUser]);

  return (
    <AnimatedBackground>
      <StyledWrapper>
        <StyledContent>
          {loading ? (
            <StyledLoader>
              <Loader size={18} />
            </StyledLoader>
          ) : (
            <>
              {isNewUser ? (
                <>
                  <StyledMainHeading data-testid={TEST_CHECK_MAIL_TITLE} isDark>
                    Check your email to <br />
                    create your account
                  </StyledMainHeading>
                  <StyledText data-testid={TEST_CHECK_MAIL_SUBTITLE_FIRST} isDark>
                    guide:human is password-free to improve security and simplify how you access your account.
                  </StyledText>
                  <StyledText data-testid={TEST_CHECK_MAIL_SUBTITLE_SECOND} isDark>
                    We’ve sent an email with a link to {email}, tap the link to finish creating your account.
                  </StyledText>
                </>
              ) : (
                <>
                  <StyledMainHeading data-testid={TEST_CHECK_MAIL_TITLE} isDark>
                    Welcome back! <br />
                    Check your email
                  </StyledMainHeading>
                  <StyledText data-testid={TEST_CHECK_MAIL_SUBTITLE_FIRST} isDark>
                    On the same device, please tap the link we just sent to {email} to log in.
                  </StyledText>
                </>
              )}
              {isRefetched ? (
                <StyledText isDark>The email has been resent.</StyledText>
              ) : (
                <StyledResend
                  data-testid={TEST_CHECK_MAIL_OPEN_EMAIL_BTN}
                  isDark
                  onClick={() =>
                    refetch()
                      .catch((err: ApolloError) => {
                        handleError(err);
                      })
                      .then((res) => res && res.data.authToken && setIsRefetched(true))
                  }
                >
                  Resend email
                </StyledResend>
              )}
            </>
          )}
        </StyledContent>
      </StyledWrapper>
    </AnimatedBackground>
  );
};

export default CheckEmailPage;
