import React, { FC, SyntheticEvent, useState } from 'react';

import { StyledImageItem } from 'components/Card/UserCard/styled';
import { USERCARD_PICK_HEIGHT, USERCARD_PICK_MAXWIDTH } from 'pages/OnboardingV2/Networking/UserSuggestion/constants';

const UserCardPick: FC<{ url: string; index: number }> = ({ url, index }) => {
  const [dimensions, setDimensions] = useState({ width: USERCARD_PICK_MAXWIDTH, height: USERCARD_PICK_HEIGHT });

  const handleImageLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;
    const aspectRatio = naturalWidth / naturalHeight;

    const height =
      USERCARD_PICK_HEIGHT < USERCARD_PICK_MAXWIDTH / aspectRatio
        ? USERCARD_PICK_HEIGHT
        : USERCARD_PICK_MAXWIDTH / aspectRatio;

    const width =
      USERCARD_PICK_HEIGHT < USERCARD_PICK_MAXWIDTH / aspectRatio
        ? USERCARD_PICK_HEIGHT * aspectRatio
        : USERCARD_PICK_MAXWIDTH;

    setDimensions({ width, height });
  };

  return (
    <StyledImageItem
      key={`key-${index}`}
      index={index}
      width={dimensions.width}
      height={dimensions.height}
      onLoad={handleImageLoad}
      src={url}
      alt=""
    />
  );
};

export default UserCardPick;
