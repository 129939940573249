import { styled } from 'linaria/react';

import { COLORS } from 'styles/constants';

export const StyledReaction = styled.button`
  align-items: center;
  background-color: ${COLORS.white[100]};
  border: 1px solid ${COLORS.white.off[100]};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 44px;
  width: 44px;
  user-select: none;
  position: relative;
  z-index: 200;
`;
