import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledList = styled.li<{ isInFlow?: boolean; isHidden: boolean }>`
  align-items: center;
  background-color: ${COLORS.transparent};
  border-radius: 100px;
  bottom: 41px;
  box-shadow: none;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  left: 50%;
  list-style: none;
  margin: 0;
  max-width: 420px;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  padding: 0 24px 0;
  pointer-events: ${({ isHidden }) => (isHidden ? 'none' : 'all')};
  position: ${({ isInFlow }) => (isInFlow ? 'static' : 'fixed')};
  transform: ${({ isInFlow }) => (isInFlow ? 'none' : 'translateX(-50%)')};
  width: 100%;
`;

export const StyledLink = styled.a`
  align-items: center;
  backdrop-filter: blur(2px);
  background-color: ${COLORS.white[10]};
  border-radius: 22px;
  color: ${COLORS.white[100]};
  display: flex;
  font: 600 14px/140% ${FONTS.secondary};
  gap: 8px;
  height: 44px;
  justify-content: center;
  min-width: 95px;
  text-decoration: none;

  span {
    font-size: 12px;
    margin-left: 4px;
  }
`;
