import { styled } from 'linaria/react';

export const StyledBackground = styled.div<{ gradient?: { start: string; end: string } }>`
  align-items: center;
  display: flex;
  height: 100dvh;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100vw;

  .rotating-bg {
    animation: rotate linear 4000ms infinite;
    background: ${({ gradient }) =>
      `linear-gradient(180deg,
        ${gradient?.start ?? '#2a657e'} 0%,
        ${gradient?.end ?? '#b977ad'} 90%,
        ${gradient?.end ?? '#b977ad'} 100%)`};
    content: '';
    flex-shrink: 0;
    position: fixed;
    transform-origin: center center;
    inset: calc(-1 * max(100vh, 100vw));

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-360deg);
      }
    }
  }
`;

export const StyledContent = styled.div<{ enableInnerScroll?: boolean }>`
  height: 100%;
  overflow-y: ${({ enableInnerScroll }) => (enableInnerScroll ? 'auto' : 'visible')};
  position: relative;
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;
