import React, { FC, Dispatch, SetStateAction } from 'react';

import Button from 'components/UI/MenuButton';
import { ReactComponent as TrashIcon } from 'assets/icons/TrashCan.svg';
import { StyledItem } from 'components/AddContent/Menu/styled';
import { useCurrentList, useListsWithCard } from 'graphQL/list/hooks';
import { CardSchema } from 'constants/graphqlTypes';
import getAuthUser from 'helpers/getAuthUser';
import { ETypeOfCard } from '../../RemovePick/models';
import { checkIsAdmin } from './CardButton/helpers';

interface IRemoveCardButtonProps {
  card: CardSchema;
  setTypeOfCard: Dispatch<SetStateAction<string>>;
}

const RemoveCardButton: FC<IRemoveCardButtonProps> = ({ card, setTypeOfCard }) => {
  const { username } = getAuthUser();
  const { isCollaborative } = useCurrentList() ?? {};
  const { id: pickId, pickedByUsers, isCardPickedByCollaborators, permissions, isPrivate } = card;
  const { listCardIdsWithCard, loading } = useListsWithCard(pickId);
  const hasMyPick = !!pickedByUsers?.items.find((user) => user.username === username);
  const isAdmin = checkIsAdmin(permissions);

  const handleUnpick = () => {
    if (isCollaborative && hasMyPick && isCardPickedByCollaborators && isAdmin) {
      return setTypeOfCard(ETypeOfCard.IsCollabListWithOptions);
    }
    if (isCollaborative) {
      return setTypeOfCard(ETypeOfCard.IsCollabList);
    }
    if (isPrivate) {
      return setTypeOfCard(ETypeOfCard.IsPrivateList);
    }
    if (listCardIdsWithCard.length) {
      return setTypeOfCard(ETypeOfCard.IsAllOptions);
    }
    return setTypeOfCard(ETypeOfCard.IsAllPicks);
  };

  return (
    <StyledItem>
      <Button isRed text="Remove pick" Icon={TrashIcon} handler={handleUnpick} disabled={loading} />
    </StyledItem>
  );
};

export default RemoveCardButton;
