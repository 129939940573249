import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/TrashCan.svg';
import { useDeleteSpark, useGetSparkDetail, useGetUserSparks } from 'graphQL/sparks/hooks';
import { MessageType } from 'constants/graphqlTypes';

import { ROUTE_USER_PROFILE } from 'routes';
import Button from 'components/UI/MenuButton';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import ShareButtons from 'components/Navigation/RightMenu/ShareButtons';
import { StyledItem } from 'components/AddContent/Menu/styled';
import getAuthUser from 'helpers/getAuthUser';

import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import { EReportTypes } from 'components/Report/constants';
import { transformRoute } from 'helpers/routingHelper';
import MenuWrap from './MenuWrap';
import { useMenuModal } from '../helpers/hooks';

const SparkButton = () => {
  const { replace, goBack } = useHistory();
  const deleteSpark = useDeleteSpark();
  const { isModalOpen, handleModal } = useMenuModal();
  const { spark } = useGetSparkDetail() ?? {};
  const { userId, username } = getAuthUser();
  const { totalCount } = useGetUserSparks({ userId });

  const { id, cardId, user } = spark ?? {};
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCloseConfirm = () => {
    setShowDeleteModal(false);
  };

  const redirectAfterDelete = () =>
    totalCount > 1 ? goBack() : replace(transformRoute(ROUTE_USER_PROFILE, { profileName: username }));

  const handleDeleteSpark = () => {
    if (cardId) {
      deleteSpark(cardId, () => {
        handleCloseConfirm();
        handleModal(false);
        redirectAfterDelete();
      });
    }
  };

  const showDeleteBtn = user?.id === getAuthUser().userId;

  const { pathname } = useLocation();
  const goToReport = useNavigateToReport({ cardId, pathname, type: EReportTypes.Spark });

  const handleReport = () => {
    handleModal(false);
    goToReport();
  };

  return (
    <>
      <MenuWrap isOpen={isModalOpen} handleModal={handleModal}>
        {cardId && (
          <ShareButtons
            closePreviousModal={() => handleModal(false)}
            sharedId={cardId}
            sparkPickId={id}
            messageType={MessageType.Spark}
          />
        )}
        {showDeleteBtn ? (
          <StyledItem>
            <Button text="Delete your spark" Icon={TrashIcon} handler={() => setShowDeleteModal(true)} isRed />
          </StyledItem>
        ) : (
          <StyledItem>
            <Button text="Report spark" Icon={ReportIcon} handler={handleReport} />
          </StyledItem>
        )}
      </MenuWrap>
      {showDeleteModal && cardId && (
        <ConfirmationModal
          handleAccept={handleDeleteSpark}
          buttonText="Delete"
          handleClickOutside={handleCloseConfirm}
          subTitle="This will permanently delete your spark, all responses remain on guide:human but lose the connection to this spark. "
          titleText="Delete spark"
          isWarning
          handleCancel={handleCloseConfirm}
        />
      )}
    </>
  );
};

export default SparkButton;
