import React, { Dispatch, FC, SetStateAction } from 'react';

import { StyledRoleItem, StyledText } from 'components/ListSettings/EditListCollaborators/RoleSelect/styled';
import { EVisibilityStatusOptions, Maybe, useUpdateUserSettingsMutation } from 'constants/graphqlTypes';
import { EVisibilityRoles } from '../constants';
import { getCurrentRolesDescription } from '../helpers';

import { StyledRoleList } from '../styled';

const { CollaborativeListsVisibility, MessagesAndThoughtsVisibility } = EVisibilityRoles;
const { Everyone, Following, NoOne } = EVisibilityStatusOptions;

const RolesList: FC<{
  role?: Maybe<EVisibilityStatusOptions>;
  type: EVisibilityRoles;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ role, type, setIsOpen }) => {
  const [updateUserSettings] = useUpdateUserSettingsMutation();

  const handleChangeVisibility = (value: EVisibilityStatusOptions) => {
    if (role !== value) {
      updateUserSettings({
        variables: {
          ...(type === CollaborativeListsVisibility && { collaborativeListsVisibility: value }),
          ...(type === MessagesAndThoughtsVisibility && { messagesAndThoughtsVisibility: value }),
        },
      });
    }
    setIsOpen(false);
  };

  const { everyone, following, noOne } = getCurrentRolesDescription(type);

  return (
    <StyledRoleList>
      <StyledRoleItem isActive={role === Everyone} onClick={() => handleChangeVisibility(Everyone)}>
        <StyledText>
          Everyone
          <br />
          <i>{everyone}</i>
        </StyledText>
      </StyledRoleItem>
      <StyledRoleItem isActive={role === Following} onClick={() => handleChangeVisibility(Following)}>
        <StyledText>
          Following
          <br />
          <i>{following}</i>
        </StyledText>
      </StyledRoleItem>
      <StyledRoleItem isActive={role === NoOne} onClick={() => handleChangeVisibility(NoOne)}>
        <StyledText>
          No one
          <br />
          <i>{noOne}</i>
        </StyledText>
      </StyledRoleItem>
    </StyledRoleList>
  );
};

export default RolesList;
