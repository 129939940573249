import { useEffect, useState } from 'react';

interface IUseModalFade {
  onClose: () => void;
  isOpen: boolean;
  hasFade: boolean;
}
interface IUseModalFadeArgs {
  handleClose: () => void;
  fadeIn?: boolean;
  fadeOut?: boolean;
  preventExit?: boolean;
}

const useModalFade = ({ handleClose, fadeIn, fadeOut, preventExit }: IUseModalFadeArgs): IUseModalFade => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasFade, setHasFade] = useState(fadeIn ?? true);
  const [wasClosed, setWasClosed] = useState(false);

  const onClose = () => !preventExit && !wasClosed && setWasClosed(true);

  useEffect(() => {
    if (wasClosed) {
      setIsOpen(false);

      if (fadeOut) {
        setTimeout(handleClose, 200);
      } else {
        handleClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasClosed]);

  useEffect(() => {
    setIsOpen(true);
    setTimeout(() => setHasFade(fadeOut ?? true), 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { onClose, isOpen, hasFade };
};

export default useModalFade;
