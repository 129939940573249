import React, { Dispatch, FC, SetStateAction } from 'react';

import { ReactComponent as SparkIcon } from 'assets/icons/AddSpark.svg';
import { ReactComponent as PickFromLinkIcon } from 'assets/icons/PickFromLink.svg';
import { CardSchema } from 'constants/graphqlTypes';
import { ISetExtractData } from 'components/AddContent/helpers/models';
import { getGradientImage } from 'components/Sparks/constants';
import Modal from 'components/Modal';
import TextInput from 'components/UI/TextInput';
import Button from 'components/UI/MenuButton';
import useDisableScroll from 'helpers/useDisableScroll';
import ExtractionError from 'components/ExtractionError';
import useExtractionProcess from 'helpers/useExtractionProcess';
import Grain from 'components/UI/Grain';
import { useCheckPickUrl } from 'components/AddContent/AddPick/hooks';

import { StyledFooter } from 'components/AddContent/AddPick/styled';
import { StyledInputWrapper } from 'components/AddContent/CreateList/styled';
import { useSparkResponseLinks } from '../hooks';
import {
  StyledModalInfoContainer,
  StyledModalTitle,
  StyledSparkPreview,
  StyledSparkPreviewContainer,
  StyledSparkTitle,
} from './styled';

interface IAddPickProps {
  url: string;
  spark?: CardSchema | null;
  setUrl: Dispatch<SetStateAction<string>>;
  setIsPicked: Dispatch<SetStateAction<boolean>>;
  setExtractData: ({ extractPickId, extractCardId, extractTitle, extractImage }: ISetExtractData) => void;
  handleClose: () => void;
}
const AddPick: FC<IAddPickProps> = ({ spark, url, setUrl, handleClose, setIsPicked, setExtractData }) => {
  const { addResponseStyle, addResponseThought } = useSparkResponseLinks();

  const validationError = useCheckPickUrl(url);

  useDisableScroll();

  const { reason, error, extractionError, loading, checkPickExist } = useExtractionProcess({
    setExtractData,
    setIsPicked,
    replaceRouteAfterExtract: addResponseStyle,
    replaceRouteAfterPickExist: addResponseThought,
    url,
  });

  const sparkPreviewImage = getGradientImage(spark?.cardStyle?.bgColor?.predefinedGradientName ?? '');

  return (
    <Modal
      fadeIn
      handleClose={handleClose}
      handleDone={() => checkPickExist(url)}
      buttonText="Next"
      disabled={!!validationError || !!error || !url}
      loading={loading}
    >
      <StyledSparkPreviewContainer>
        <StyledSparkPreview bgUrl={sparkPreviewImage}>
          <Grain />
          <SparkIcon />
        </StyledSparkPreview>
        <StyledModalInfoContainer>
          <StyledModalTitle>Response to </StyledModalTitle>
          <StyledSparkTitle>{spark?.title}</StyledSparkTitle>
        </StyledModalInfoContainer>
      </StyledSparkPreviewContainer>
      <StyledInputWrapper>
        <TextInput
          inputHeight={143}
          placeholder="https://www.eg.com"
          setText={setUrl}
          text={url}
          error={validationError || error}
        />
      </StyledInputWrapper>
      {!url && (
        <StyledFooter>
          <Button
            text="Paste link from clipboard"
            Icon={PickFromLinkIcon}
            handler={() => navigator.clipboard?.readText().then(setUrl)}
          />
        </StyledFooter>
      )}
      {extractionError && (
        <ExtractionError setUrl={setUrl} extractionError={extractionError} reason={reason} fromSpark />
      )}
    </Modal>
  );
};

export default AddPick;
