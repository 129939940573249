import React, { FC } from 'react';
import Truncate from 'react-truncate-markup';

import getImages from 'helpers/getImages';
import getStyles from 'helpers/getStyles';
import { CardSchema, ECardLayoutOptions } from 'constants/graphqlTypes';

import { StyledTag, StyledTitle, StyledDescription, StyledWrap, StyledImageWrap, StyledImage } from './styled';

const PickContent: FC<{ card: CardSchema }> = ({ card }) => {
  const { title, cardStyle, mediaTags, description } = card ?? {};
  const { layout } = getStyles(cardStyle);
  const { imageUrl } = getImages(cardStyle, true);

  const mediaTag = mediaTags?.items[0]?.name;
  const isTweet = mediaTag === 'tweets';

  const planeCardLines = description?.length ? 9 : 11;

  const linesCount = layout === ECardLayoutOptions.Plain ? planeCardLines : 3;

  return (
    <StyledWrap>
      {mediaTag && <StyledTag>{mediaTag}</StyledTag>}
      <StyledTitle>
        <Truncate lines={linesCount} ellipsis={isTweet ? '[...]' : '...'}>
          <span>{title}</span>
        </Truncate>
      </StyledTitle>
      {imageUrl && layout !== ECardLayoutOptions.Plain && (
        <StyledImageWrap isImageSmall={false}>
          <StyledImage src={imageUrl} />
        </StyledImageWrap>
      )}
      {(layout === ECardLayoutOptions.Plain || !imageUrl) && description && (
        <StyledDescription>
          <Truncate lines={2}>
            <span>{description}</span>
          </Truncate>
        </StyledDescription>
      )}
    </StyledWrap>
  );
};

export default PickContent;
