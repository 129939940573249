import React, { FC, useState } from 'react';
import Truncate from 'react-truncate-markup';

import Collection from 'components/Collection';

import { CardSchema } from 'constants/graphqlTypes';
import { StyledInviteText, StyledPreview, StyledTitleList, StyledToggle } from './styled';

const Content: FC<{ isDark: boolean; text: string; list: CardSchema }> = ({ isDark, text, list }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { title, isPrivateList, cardStyle, permissions } = list;
  const isEmptyList = !cardStyle?.collectionCover?.totalCount;

  return (
    <>
      {text && (
        <StyledInviteText isDark={isDark}>
          {!isOpen ? (
            <Truncate
              lines={3}
              ellipsis={
                <>
                  ...&nbsp;
                  <StyledToggle onClick={() => setIsOpen(true)} isDark={isDark}>
                    more
                  </StyledToggle>
                </>
              }
            >
              <span>&ldquo;{text}&rdquo;&nbsp;</span>
            </Truncate>
          ) : (
            <>
              &ldquo;{text}&rdquo;&nbsp;
              <StyledToggle onClick={() => setIsOpen(false)} isDark={isDark}>
                close
              </StyledToggle>
            </>
          )}
        </StyledInviteText>
      )}
      {!isOpen && (
        <>
          <StyledPreview isPrivateList={!!isPrivateList} isEmptyList={isEmptyList}>
            <Collection
              item={list}
              hideTitle
              hidePrivateLabel
              isReplace
              disableLink={!!isPrivateList && !permissions?.length}
            />
          </StyledPreview>
          <StyledTitleList isDark={isDark}>{title}</StyledTitleList>
        </>
      )}
    </>
  );
};

export default Content;
