import {
  EMAIL_REGEX,
  USERNAME_LENGTH,
  USERNAME_ACCEPTABLE_CHARS,
  USERNAME_AT_LEAST_1_LETTER,
  USERNAME_CHARS,
} from './constants';

export const validateLength = ([min, max]: number[], length: number, fieldName?: string): string =>
  length < min || length > max ? `${fieldName ?? 'Field'} must be between ${min} and ${max} characters long.` : '';

export const validateEmailRegex = (email: string): string =>
  !EMAIL_REGEX.test(email) ? 'Please enter a valid email' : '';

export const validateUsername = (userName: string): string => {
  const lengthError = !userName.length ? 'Required' : validateLength(USERNAME_LENGTH, userName.length, 'Username');

  if (lengthError) {
    return lengthError;
  }

  if (!USERNAME_ACCEPTABLE_CHARS.test(userName)) {
    return `Username can include letters, digits and symbols ${USERNAME_CHARS.split('').join(' ')}`;
  }

  if (!USERNAME_AT_LEAST_1_LETTER.test(userName)) {
    return 'Username must include at least 1 letter.';
  }

  return '';
};
