import React, { FC } from 'react';

import { CardSchema, ECardLayoutOptions, UserObjectType } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';
import { TEST_CARD_LIST } from 'constants/aqa/card';

import Grain from 'components/UI/Grain';
import getStyles from 'helpers/getStyles';
import getImages from 'helpers/getImages';
import Avatars from 'components/UI/Avatars/Avatars';
import Source from '../Common/Source';
import Content from '../Common/Content';

import { StyledWrap, StyledLink } from '../Common/styled';
import { StyledBody, StyledImage, StyledFooter } from './styled';
import { useItemUrl } from '../helpers/hooks';

const CardList: FC<{ card: CardSchema; isDark?: boolean; hideActivity?: boolean; dataTestId?: string }> = ({
  card,
  isDark,
  hideActivity,
  dataTestId,
}) => {
  const { id, cardStyle, extraData } = card;
  const { items } = extraData ?? {};
  const { bgColor, layout } = getStyles(cardStyle);
  const { imageUrl } = getImages(cardStyle);

  const color = isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  const url = useItemUrl(id);
  const users =
    items?.reduce((acc: UserObjectType[], creator) => (creator?.user ? acc.concat(creator.user) : acc), []) ?? [];

  return (
    <StyledWrap>
      <StyledLink className="card" to={url}>
        <StyledBody bg={bgColor} isReverse={false}>
          <Grain />
          <Content dataTestId={dataTestId ?? TEST_CARD_LIST} card={card} />
          {imageUrl && layout !== ECardLayoutOptions.Plain && <StyledImage src={imageUrl} />}
        </StyledBody>
      </StyledLink>
      <StyledFooter color={color}>
        <Source card={card} />
        {!hideActivity && (
          <StyledLink to={url}>
            <Avatars limit={3} users={users} />
          </StyledLink>
        )}
      </StyledFooter>
    </StyledWrap>
  );
};

export default CardList;
