import { styled } from 'linaria/lib/react';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledPageHeader = styled.div<{ bgImage?: string }>`
  background: ${({ bgImage }) => `url(${bgImage})`};
  background-size: cover;
  height: 547px;
  padding: 80px 24px 24px;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const StyledInfoContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledSparkTitle = styled.div`
  font: 400 32px/120% ${FONTS.primary};
  color: ${COLORS.white[100]};
  width: 100%;
  max-width: 528px;
  margin: 0 auto;
  word-break: break-word;

  > span {
    width: 270px;
    display: block;
    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      padding-left: 24px;
    }
  }
`;

export const StyledUserContainer = styled.div`
  margin: 0 -24px;
  padding: 20px 24px 0;
  border-top: 1px solid ${COLORS.white[30]};

  > div {
    width: 100%;
    max-width: 528px;
    margin: 0 auto;

    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      padding-left: 24px;
    }
  }
`;

export const StyledResponseCounterContainer = styled.div`
  width: 100%;
  max-width: 528px;
  margin: 0 auto;
  padding: 32px 24px 0;
`;

export const StyledResponseCounter = styled.div<{ isDark: boolean }>`
  font: 400 22px/130% ${FONTS.primary};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
`;

export const StyledNoResponsePlaceholder = styled.div<{ isDark: boolean }>`
  border-top: 1px solid ${COLORS.white.off[100]};
  padding: 28px 0 16px;
  font: 400 14px/140% ${FONTS.secondary};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
`;

export const StyledResponseButton = styled.button`
  align-items: center;
  background-color: ${COLORS.brown.dark[100]};
  border-radius: 100px;
  bottom: 34px;
  box-shadow: 0px 11px 6px ${COLORS.black[10]}, 0px 5px 5px ${COLORS.black[20]}, 0px 1px 3px ${COLORS.black[20]},
    0px 0px 0px ${COLORS.black[20]};
  border: none;
  color: ${COLORS.white[100]};
  cursor: pointer;
  display: flex;
  font: 600 14px/140% ${FONTS.secondary};
  height: 58px;
  justify-content: center;
  left: 50%;
  margin: 0;
  outline: none;
  padding: 0;
  position: fixed;
  transform: translateX(-50%);
  width: 246px;
`;

export const StyledListWrap = styled.div`
  button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 342px;
    width: 100%;
  }
`;
