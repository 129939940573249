import { StyledTextsList } from 'components/Collection/styled';
import { styled } from 'linaria/lib/react';
import { FONTS } from 'styles/constants';

export const StyledAllPicksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledTitle = styled.p`
  font: 400 22px/130% ${FONTS.primary};
`;

export const StyledSubTitle = styled.p`
  padding-top: 8px;
  width: 100%;
  display: flex;
  align-items: center;

  > .creator-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: 400 12px/140% ${FONTS.secondary};
  }
`;

export const StyledCoverContainer = styled.div`
  aspect-ratio: 1/1;
  margin-top: 16px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  .spark-preview {
    border-radius: 8px;
    overflow: hidden;

    > .grain {
      z-index: 10;
    }
  }

  .spark-preview__text {
    z-index: 20;
    line-break: anywhere;
  }

  > ul {
    width: 100%;
    height: 100%;
  }
`;

export const StyledTextCard = styled(StyledTextsList)<{ background?: string }>`
  background: ${({ background }) => background ?? 'transparent'};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 15.6%;
  position: relative;
`;
