import React, { FC } from 'react';

import Card from 'components/Card';

import { CardSchema, EDiscoverItemLayoutOptions } from 'constants/graphqlTypes';
import { getSearchCardTestId } from 'constants/aqa/search';

import { StyledList } from '../styled';
import { StyledItem } from './styled';

const DetailView: FC<{ items: CardSchema[]; isDark: boolean; pageTestId?: string }> = ({
  items,
  isDark,
  pageTestId,
}) => (
  <StyledList>
    {items.map((item, index) => (
      <StyledItem key={item.id}>
        <Card
          dataTestId={getSearchCardTestId(index, pageTestId)}
          layout={EDiscoverItemLayoutOptions.FirstUp}
          card={item}
          isDark={isDark}
        />
      </StyledItem>
    ))}
  </StyledList>
);

export default DetailView;
