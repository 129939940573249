import { styled } from 'linaria/react';
import { StyledTitle } from 'components/GuestList/Card/styled';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  .list-owner {
    padding: 8px 0 16px;
  }

  ul {
    width: 100%;
    height: 100%;
  }
`;

export const StyledText = styled(StyledTitle)`
  word-spacing: normal;
  -webkit-line-clamp: 6;
`;

export const StyledEmptyListBody = styled.div`
  aspect-ratio: 1/1;
  border: 1px solid ${COLORS.brown.dark[10]};
  border-radius: 8px;
  width: 100%;
`;

export const StyledCreatorText = styled.p`
  padding: 8px 0 16px;
  display: flex;
  align-items: center;

  > .creator-text {
    text-transform: none;
    font-family: ${FONTS.secondary};
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
