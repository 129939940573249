import { Operation, DocumentNode, split, ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql/language';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { createUploadLink } from 'apollo-upload-client';
import config from '../constants/config';
import getAuthUser from './getAuthUser';

const DIRECTIVE_FOR_CHAT = 'chat';

const localizationHeaders = { '-x-gh-device-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone ?? '' };

const getDirectiveNamesFromOperation = (operationQuery: DocumentNode): string[] => {
  if (operationQuery && operationQuery.definitions && operationQuery.definitions.length > 0) {
    const operationDefinition = operationQuery.definitions.find(
      (definition) => definition.kind === 'OperationDefinition'
    ) as OperationDefinitionNode;

    if (operationDefinition && operationDefinition.directives) {
      return operationDefinition.directives.map((directive) => directive.name.value);
    }
  }

  return [];
};

const determineWhichHttpLinkToUse = (operation: Operation): boolean => {
  const directives = getDirectiveNamesFromOperation(operation.query);
  return directives.some((directive) => directive === DIRECTIVE_FOR_CHAT);
};

const determineWhichProtocolToUse = (operation: Operation): boolean => {
  const definition = getMainDefinition(operation.query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
};

export const getSpecificApiLink = (): ApolloLink => {
  const { token } = getAuthUser();
  const apiLink = createUploadLink({
    uri: config.apiUrl,
    headers: localizationHeaders,
  });
  const messageApiLink = createUploadLink({
    uri: config.messagesApiURL,
    headers: localizationHeaders,
  });
  const wsLink = new WebSocketLink(
    new SubscriptionClient(config.subscriptionApiUrl, {
      connectionParams: {
        Authorization: `Bearer ${token}`,
      },
    })
  );

  // we need '(... as unknown) as ApolloLink' because createUploadLink ApolloLink !== ApolloLink and 'as' doesn't work
  const httpLinks = split(
    determineWhichHttpLinkToUse,
    (messageApiLink as unknown) as ApolloLink,
    (apiLink as unknown) as ApolloLink
  );

  return split(determineWhichProtocolToUse, wsLink, httpLinks);
};
