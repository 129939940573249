import { ApolloError } from '@apollo/client';

export interface IValidationError {
  graphQLErrors: { validationError: { message: string }[] }[];
}

const getValidationError = (e?: ApolloError & IValidationError): string =>
  e?.graphQLErrors?.[0]?.validationError?.[0]?.message ?? '';

export default getValidationError;
