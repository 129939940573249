import React, { FC } from 'react';

import { ActivityCollectionPrivacyObjectType, Scalars } from 'constants/graphqlTypes';
import { getActivityTestId } from 'constants/aqa/activity';
import { ROUTE_LIST_DETAIL } from 'routes';

import ListPreview from './Elements/ListPreview';
import UserPreview from './Elements/UserPreview';
import Content from './Elements/Content';

interface IPrivacyProps {
  date: Scalars['DateTime'] | null;
  item: ActivityCollectionPrivacyObjectType;
  activityTestId: string;
}

const Privacy: FC<IPrivacyProps> = ({ date, item, activityTestId }) => {
  const { user, collection, isPrivate } = item;
  const { title, id } = collection ?? {};

  return (
    <>
      {user && <UserPreview dataTestId={getActivityTestId(activityTestId, 'avatar')} user={user} />}
      <Content activityTestId={activityTestId} url={ROUTE_LIST_DETAIL} params={{ listId: id }} date={date} user={user}>
        made your collaborative list <b>{title}</b> {isPrivate ? 'private' : 'public'}
      </Content>
      {collection && <ListPreview activityTestId={activityTestId} list={collection} />}
    </>
  );
};

export default Privacy;
