import React, { useState, FC, Fragment, Dispatch, SetStateAction, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { ReactComponent as PeopleIcon } from 'assets/icons/People.svg';
import { ReactComponent as Logo } from 'assets/icons/Logo.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';

import Modal from 'components/Modal';
import ContentTitle from 'components/UI/ContentTitle';
import ListItem from 'components/UI/ListItem';
import Button from 'components/UI/Button';
import { ROUTE_CREATE_FEED_NAME, ROUTE_CREATE_FEED } from 'routes';
import { useLink } from 'helpers/routingHelper';
import { useGetFollowings } from 'graphQL/follows/hooks';
import { useGetSuggestionSearches } from 'graphQL/search/hooks';
import { useGetFeedAllUsers } from 'graphQL/feeds/hooks';
import getAuthUser from 'helpers/getAuthUser';
import Input from 'components/UI/Input';
import { EToggleType } from 'components/UI/Toggle/models';
import { StyledButtonWrap } from 'components/TopicsModal/styled';
import { EUiButtonType } from 'components/UI/Button/constants';
import UserItem from 'components/CustomFeeds/UserItem';

import { UserObjectType } from 'constants/graphqlTypes';
import { checkArrayElements, checkIsUserEnabled, getIdsFromObjects } from './helpers';

import {
  StyledAllTopicsWrapper,
  StyledContentContainer,
  StyledTopicsLabel,
  StyledSearchContainer,
  StyledUsersContainer,
} from '../SelectFeedCreateTopics/styled';

import { ESelectionMode } from '../../../constants';

import { useCreateFeedSelections, useTotalCount } from './hooks';

const { All, Followings, Select, Deselect } = ESelectionMode;

interface ISelectFeedCreateUsersProps {
  selectionMode: ESelectionMode;
  setSelectionMode: Dispatch<SetStateAction<ESelectionMode>>;
  selectedUsers: UserObjectType[];
  setSelectedUsers: Dispatch<SetStateAction<UserObjectType[]>>;
  deselectedUsers: UserObjectType[];
  setDeselectedUsers: Dispatch<SetStateAction<UserObjectType[]>>;
  totalSelectedUsers: number;
  setTotalSelectedUsers: Dispatch<SetStateAction<number>>;
  isAllFollowings: boolean;
  setAllFollowings: Dispatch<SetStateAction<boolean>>;
  isAllUsers: boolean;
  setAllUsers: Dispatch<SetStateAction<boolean>>;
}

const SelectFeedCreateUsers: FC<ISelectFeedCreateUsersProps> = ({
  selectionMode,
  setSelectionMode,
  selectedUsers,
  setSelectedUsers,
  deselectedUsers,
  setDeselectedUsers,
  totalSelectedUsers,
  setTotalSelectedUsers,
  isAllFollowings,
  setAllFollowings,
  isAllUsers,
  setAllUsers,
}) => {
  const selectedUsersIds = getIdsFromObjects(selectedUsers);
  const deselectedUsersIds = getIdsFromObjects(deselectedUsers);
  const hasDeselectedFollowers = deselectedUsers.find((user) => user.isFollowedByMe);

  const [searchString, setSearchString] = useState('');
  // This state covers special (CRAZY) state when 'AllUsers' button clicked and then 'All users you follow' clicked.
  // This means that All users except Followings should be selected.
  // Requested by QA, BA.
  const [haveAllUsersNoFollowings, setHaveAllUsersNoFollowings] = useState(false);

  const { goBack, replace } = useHistory();
  const nameLink = useLink(ROUTE_CREATE_FEED_NAME);
  const feedLink = useLink(ROUTE_CREATE_FEED);
  const handleExit = () => goBack();
  const { userId } = getAuthUser();

  const followingsData = useGetFollowings(userId);
  const { items, loadMore, loading, totalCount: totalFollowingsCount } = followingsData ?? {};
  const { handleSelection, handleSelectItem } = useCreateFeedSelections({
    selectionMode,
    setSelectionMode,
    setSelectedUsers,
    setDeselectedUsers,
    setSelectFollowings: setAllFollowings,
    setHaveAllUsersNoFollowings,
    setAllUsers,
    selectedUsers,
    deselectedUsers,
    setTotalSelectedUsers,
  });

  const { suggestionSearches: users } = useGetSuggestionSearches(`user:${searchString}`);

  const summaryTotalUsers = useGetFeedAllUsers();

  useTotalCount({
    selectionMode,
    setTotalSelectedUsers,
    summaryTotal: summaryTotalUsers,
    selectedUsers,
    deselectedUsers,
    totalSelectedUsers,
    totalFollowingsCount,
    isAllUsers,
    isAllFollowings,
    haveAllUsersNoFollowings,
  });

  useEffect(() => {
    const areAllFollowedUsersSelected =
      checkArrayElements(selectedUsers, items) && !loading && items.length === totalFollowingsCount;

    // That case should work when All Followings selected by click on each element
    // Initial state is empty, no user was selected/deselected, 'selectionMode=Select'
    // expected result => 'all users you follow' button should be selected, all followings users should have checkmark
    if (areAllFollowedUsersSelected && selectionMode === Select && !haveAllUsersNoFollowings) {
      setAllFollowings(true);
      setSelectionMode(Followings);
    }

    // (initial state=> select all followings by hand => deselect 1 ===> allFollowings should be set to false)
    // expected result => selection mode - select & 'all users you follow' button should be deselected,
    // 'all users' button should be deselected.
    if (!areAllFollowedUsersSelected && selectionMode === Select && !haveAllUsersNoFollowings) {
      setAllFollowings(false);
    }

    // we entered crazy mode than selected all the followers mannually.
    // expected result => allusers should be selected, 'allusers' & 'all users you follow' buttons should be selected.
    if (
      areAllFollowedUsersSelected &&
      selectionMode === Deselect &&
      haveAllUsersNoFollowings &&
      !deselectedUsers.length
    ) {
      setAllUsers(true);
      setSelectionMode(All);
      setAllFollowings(false);
      setHaveAllUsersNoFollowings(false);
      setDeselectedUsers([]);
      setSelectedUsers([]);
    }
    // [initial state -> allusers] => deselect 1 user => select him again ===> [should return to allusers mode]
    // expected result => allusers should be selected, 'allusers' & 'all users you follow' buttons should be selected.
    if (
      selectionMode === Deselect &&
      !hasDeselectedFollowers &&
      !deselectedUsers.length &&
      !selectedUsers.length &&
      !haveAllUsersNoFollowings &&
      isAllUsers
    ) {
      setAllUsers(true);
      setSelectionMode(All);
      setAllFollowings(false);
      setHaveAllUsersNoFollowings(false);
      setDeselectedUsers([]);
      setSelectedUsers([]);
    }

    // [initial state => all user] => deselect 1 user from search(not the one you follow) ===> [should set allFollowings to true]
    // expected result :
    // 'all users' button should be deselected, every user except the one from before should have a checkmark
    // 'all users you follow' button should be selected, all followings users should have checkmark
    if (
      selectionMode === Deselect &&
      deselectedUsers.length &&
      !hasDeselectedFollowers &&
      !haveAllUsersNoFollowings &&
      isAllUsers
    ) {
      setAllFollowings(true);
    }
  }, [
    deselectedUsers.length,
    selectedUsers.length,
    items.length,
    totalFollowingsCount,
    loading,
    selectionMode,
    haveAllUsersNoFollowings,
  ]);

  return (
    <Modal handleClose={handleExit} handlePrev={() => replace(feedLink)} enableInnerScroll>
      <StyledContentContainer hasPaddingBottom={false}>
        <ContentTitle isDark>Select users you want to hear from</ContentTitle>
        <StyledSearchContainer>
          <Input
            Icon={SearchIcon}
            placeholder="Search"
            isDark
            setText={setSearchString}
            text={searchString}
            hasCrossIcon
          />
          {searchString && (
            <Button text="Cancel" handler={() => setSearchString('')} isDark type={EUiButtonType.Transparent} />
          )}
        </StyledSearchContainer>
        {!searchString && (
          <StyledAllTopicsWrapper>
            <ListItem
              title="All users"
              isDark
              handleClick={() => handleSelection(All)}
              hasToggle
              toggleType={EToggleType.CheckPlus}
              Icon={() => <Logo viewBox="0 0 622 383" />}
              isEnable={selectionMode === All}
            />
            {totalFollowingsCount > 0 && (
              <ListItem
                title={`All ${totalFollowingsCount} user${totalFollowingsCount > 1 ? `s` : ``} you follow`}
                isDark
                handleClick={() => handleSelection(Followings)}
                hasToggle
                toggleType={EToggleType.CheckPlus}
                Icon={PeopleIcon}
                isEnable={hasDeselectedFollowers ? false : selectionMode === All || isAllFollowings}
              />
            )}
          </StyledAllTopicsWrapper>
        )}
        <StyledUsersContainer>
          {!searchString
            ? totalFollowingsCount > 0 && (
                <>
                  <StyledTopicsLabel>Following</StyledTopicsLabel>
                  {items.map((item) => {
                    const isEnable = checkIsUserEnabled({
                      id: item.id,
                      selectionMode,
                      selectedUsers: selectedUsersIds,
                      deselectedUsers: deselectedUsersIds,
                      isFollowedByMe: item.__typename === 'UserObjectType' && item?.isFollowedByMe,
                      isSearch: !!searchString,
                      isAllUsers,
                      isAllFollowings,
                      haveAllUsersNoFollowings,
                    });

                    return (
                      <Fragment key={item.id}>
                        <UserItem
                          user={item}
                          isEnable={checkIsUserEnabled({
                            id: item.id,
                            selectionMode,
                            selectedUsers: selectedUsersIds,
                            deselectedUsers: deselectedUsersIds,
                            isAllUsers,
                            isAllFollowings,
                            isSearch: !!searchString,
                            haveAllUsersNoFollowings,
                          })}
                          handleClick={() => handleSelectItem(item, undefined, isEnable)}
                          isDark
                        />
                      </Fragment>
                    );
                  })}
                </>
              )
            : users.map((item) => {
                const isEnable = checkIsUserEnabled({
                  id: item.id,
                  selectionMode,
                  selectedUsers: selectedUsersIds,
                  deselectedUsers: deselectedUsersIds,
                  isFollowedByMe: item.__typename === 'UserObjectType' && item?.isFollowedByMe,
                  isSearch: !!searchString,
                  isAllUsers,
                  isAllFollowings,
                  haveAllUsersNoFollowings,
                });

                return (
                  item.__typename === 'UserObjectType' && (
                    <Fragment key={item.id}>
                      <UserItem
                        user={item}
                        isEnable={isEnable}
                        handleClick={() => handleSelectItem(item, !!searchString, isEnable)}
                        isDark
                      />
                    </Fragment>
                  )
                );
              })}
          {!loading && <Waypoint onEnter={loadMore} />}
        </StyledUsersContainer>
        {!!totalSelectedUsers && (
          <StyledButtonWrap>
            <Button
              handler={() => replace(nameLink)}
              text={`Create with ${selectionMode === All ? 'all' : totalSelectedUsers} user${
                totalSelectedUsers > 1 ? `s` : ``
              }`}
            />
          </StyledButtonWrap>
        )}
      </StyledContentContainer>
    </Modal>
  );
};

export default SelectFeedCreateUsers;
