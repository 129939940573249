import React from 'react';
import { styled } from 'linaria/react';

import { COLORS, FONTS } from '../../styles/constants';

const StyledCenteredWrap = styled.div`
  width: 100%;
  padding-top: 150px;
  font-family: ${FONTS.secondary};
  color: ${COLORS.brown[100]};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.3px;
  text-align: center;
`;

const Unauthorized: React.FC = () => (
  <StyledCenteredWrap>
    <b>Authorization Required</b>
    <div>Please log in to see the content of this page.</div>
  </StyledCenteredWrap>
);

export default Unauthorized;
