import React, { FC } from 'react';

import { useGetSuggestionSearches } from 'graphQL/search/hooks';
import { StyledList } from 'components/ActivityList/styled';
import { TEST_SEARCH_RECENT_ITEM } from 'constants/aqa/search';

import RecentSearchItem from '../RecentSearch';
import AllResultsFor from '../AllResultsFor';
import { StyledRecentSearches } from '../RecentSearch/styled';

const SuggestionSearches: FC<{ searchKey: string }> = ({ searchKey }) => {
  const { suggestionSearches } = useGetSuggestionSearches(searchKey);

  return (
    <StyledRecentSearches>
      <StyledList>
        {suggestionSearches.map((suggestion, index) => (
          <RecentSearchItem
            dataTestId={`${TEST_SEARCH_RECENT_ITEM}-${index}`}
            key={suggestion.id}
            id={suggestion.id}
            user={suggestion?.__typename === 'UserObjectType' ? suggestion : undefined}
            keyword={suggestion?.__typename === 'MediaTagSchema' ? suggestion.name : ''}
            hasNameOnly={false}
            isBold
          />
        ))}
        <AllResultsFor text={searchKey} />
      </StyledList>
    </StyledRecentSearches>
  );
};

export default SuggestionSearches;
