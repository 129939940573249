import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal';
import useModalNavigation from 'helpers/useModalNavigation';
import Button from 'components/UI/Button';

import { TEST_ADD_FROM_LINK_TITLE_LABEL } from 'constants/aqa/addPick';
import { ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST, ROUTE_ADD_TO_LIST } from 'routes';
import { useGetSavedPicks } from 'graphQL/savedPicks/hooks';
import { useUserSettings } from 'graphQL/contextualIntro/hooks';
import { useCardsBaseSelection } from 'pages/OnboardingV2/ContentTutorial/ListFill/hooks';

import { StyledTitle, StyledOptionalTitle, StyleCountNote, StyledButtonWrap } from './styled';

import SavedPicksSection from './SavedPicksSection';
import ContentMenuButtons from './ContentMenuButtons';

const Menu: FC<{ handleClose: () => void; fadeIn: boolean }> = ({ handleClose, fadeIn }) => {
  const cardsBaseSelection = useCardsBaseSelection(false, false);

  const { replace } = useHistory();
  const { isThreePicksAdded } = useUserSettings();
  const { items } = useGetSavedPicks({ isArchived: false });
  const { selectedCards } = cardsBaseSelection || {};

  const selectedPicks = items.filter((card) => selectedCards.includes(card.cardId));

  const { id, cardId } = selectedPicks?.[0] ?? {};

  const multiAddLink = useModalNavigation(ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST, false, { cards: selectedPicks });
  const singleAddLink = useModalNavigation(ROUTE_ADD_TO_LIST, false, { pickId: id, cardId, isSavedPick: true });
  const selectedCount = selectedCards.length ?? 0;

  return (
    <Modal enableInnerScroll handleClose={handleClose} fadeIn={fadeIn}>
      <StyledTitle data-testid={TEST_ADD_FROM_LINK_TITLE_LABEL}>
        {!isThreePicksAdded && <StyledOptionalTitle>Article? Book? Sneaker?</StyledOptionalTitle>}
        Start sharing what you&rsquo;re into today
      </StyledTitle>
      {!!items.length && <SavedPicksSection cards={items} cardsBaseSelection={cardsBaseSelection} />}
      {selectedCount ? (
        <>
          {selectedCount === 5 && <StyleCountNote>You can share a max of 5 picks at a time</StyleCountNote>}
          <StyledButtonWrap>
            <Button
              text={`Continue with ${selectedCount} pick${selectedCount > 1 ? 's' : ''}`}
              handler={() => replace(selectedCount > 1 ? multiAddLink : singleAddLink)}
            />
          </StyledButtonWrap>
        </>
      ) : (
        <ContentMenuButtons />
      )}
    </Modal>
  );
};

export default Menu;
