import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { useGetMessageContent } from 'graphQL/messages/hooks';
import { MessageContentStatus, MessageItemType, MessageSchema, MessageType } from 'constants/graphqlTypes';
import { useFullScreenAnimation } from 'components/Reactions/Animations/Fullscreen/hooks';
import ReactionsToken from 'components/Messages/Message/ReactionsToken';
import { useMessagesChatData } from 'components/Messages/helpers/hooks';
import SmallCard from 'components/List/SmallCard';
import UnavailableContent from '../UnavailableContent';
import { EMessageContentType } from '../../models';

import { StyledListWrap } from './styled';
import { StyledMessageInfo, StyledRelWrap } from '../styled';

import ReactionButton from '../ReactionButton';
import { getReactionContentText } from '../../helpers/helpers';
import { StyledTokenWrap } from '../../styled';

const List: FC<{
  id: string;
  message: MessageSchema;
  isMine: boolean;
  messageRef: React.RefObject<HTMLDivElement>;
  isDark: boolean;
  setIsContentDeleted: Dispatch<SetStateAction<boolean>>;
  contentReactionId?: string;
  nextMessageType?: MessageType | null;
}> = ({ id, isMine, messageRef, isDark, setIsContentDeleted, contentReactionId, message, nextMessageType }) => {
  const { list, messageContentStatus } = useGetMessageContent({
    itemType: MessageItemType.Collection,
    entityId: id,
    contentReactionId,
  });

  const { chatId } = useMessagesChatData();

  useEffect(() => {
    setIsContentDeleted(messageContentStatus === MessageContentStatus.Deleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageContentStatus]);

  const { creators } = list ?? {};

  const contentReactionEmoji = list?.reactions?.items?.[0]?.symbol?.symbol;
  useFullScreenAnimation(message.id, contentReactionEmoji);

  if (
    messageContentStatus === MessageContentStatus.Unavailable ||
    messageContentStatus === MessageContentStatus.Deleted
  ) {
    return (
      <UnavailableContent
        isDark={isDark}
        isMine={isMine}
        contentType={EMessageContentType.List}
        messageContentStatus={messageContentStatus}
      />
    );
  }

  const contentIds = {
    chatId,
    messageId: message?.id || '',
  };

  return list && creators ? (
    <StyledListWrap isRight={isMine} ref={messageRef} hasBottomMargin={!!nextMessageType}>
      {contentReactionEmoji && (
        <StyledMessageInfo>{getReactionContentText(isMine, EMessageContentType.List)}</StyledMessageInfo>
      )}
      <StyledRelWrap>
        {contentReactionEmoji && <ReactionButton emojiCode={contentReactionEmoji} />}
        <SmallCard item={list} showActivity isLarge showCreators />
        <StyledTokenWrap className="token-wrap" isRight={isMine}>
          <ReactionsToken contentIds={contentIds} isDark={isDark} />
        </StyledTokenWrap>
      </StyledRelWrap>
    </StyledListWrap>
  ) : null;
};

export default List;
