import { IUpdateListNotification } from './models';

export const updateListNotification = ({ cache, listId, isInChannel }: IUpdateListNotification): void => {
  if (listId) {
    cache.modify({
      id: cache.identify({ id: listId, __typename: 'CardSchema' }),
      fields: {
        isInChannel: () => isInChannel,
      },
    });
  }
};
