import React, { FC, useState } from 'react';

import { MixedGroupFollowSchema } from 'constants/graphqlTypes';
import { TEST_FOLLOWING_TAB_MIXED_GROUP_SECTION } from 'constants/aqa/explore';
import PickSlider from 'components/Sliders/PickSlider';
import useTheme from 'helpers/useTheme';
import Creators from 'components/Collection/Creators';
import { ROUTE_USER_ALL_PICKS, ROUTE_USER_PROFILE } from 'routes';
import UiLink from 'components/UI/Link';
import { StyledCreatorsWrap } from '../ListGroup/styled';
import { useMixedGroupCards } from './hooks';
import { StyledTitleTextWrap, StyledTitleWrap } from './styled';

interface IMixedGroupProps {
  item: MixedGroupFollowSchema;
}
const MixedGroup: FC<IMixedGroupProps> = ({ item }) => {
  const { totalCount, user } = item;
  const { username: profileName, userInfo } = user ?? {};
  const cardItemIds = item.cardItemIds ?? [];

  const [paginatedIds, setPaginatedIds] = useState<string[]>(cardItemIds?.slice(0, 4));

  const { cards } = useMixedGroupCards(paginatedIds, user);

  const isDark = useTheme();

  return cards.length && user ? (
    <div data-testid={TEST_FOLLOWING_TAB_MIXED_GROUP_SECTION}>
      <StyledTitleWrap isDark={isDark}>
        <UiLink route={ROUTE_USER_ALL_PICKS} params={{ profileName }}>
          <StyledTitleTextWrap>New from {userInfo?.firstName ?? profileName}</StyledTitleTextWrap>
        </UiLink>
      </StyledTitleWrap>
      <UiLink route={ROUTE_USER_PROFILE} params={{ profileName }}>
        <StyledCreatorsWrap>
          <Creators creators={[user]} creatorsText={`Added ${totalCount} picks`} isDark={isDark} />
        </StyledCreatorsWrap>
      </UiLink>
      <PickSlider
        items={cards}
        isDark={isDark}
        isInfinite={false}
        onWaypointEnter={() => cardItemIds.length && setPaginatedIds((prev) => cardItemIds.slice(0, prev.length + 4))}
      />
    </div>
  ) : null;
};

export default MixedGroup;
