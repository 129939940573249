import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLottie } from 'lottie-react';
import { TypeAnimation } from 'react-type-animation';

import AnimatedBackground from 'components/AnimatedBackground';
import Button from 'components/UI/Button';
import { ROUTE_ONBOARDING_NETWORKING_USER_SUGGESTION } from 'routes';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { ENewOnboardingStepOptions } from 'constants/graphqlTypes';
import { useUpdateOnboardingStepV2 } from 'graphQL/onboarding/hooks';

import { StyledButtonWrap, StyledContentWrap, StyledText, StyledTitle, StyledWrap } from '../styled';

const Join: FC = () => {
  const posthogCapture = usePostHogCapture();

  const [animationData, setAnimationData] = useState();
  const [isTitleShowed, setIsTitleShowed] = useState(false);
  const [isLottieAnimationShowed, setIsLottieAnimationShowed] = useState(false);
  const { push } = useHistory();

  useUpdateOnboardingStepV2(ENewOnboardingStepOptions.Networking);

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.OnboardingNetworkingPreview);
    fetch('/mockedData/animationBackground.json')
      .then((data) => data.json())
      .then((data) => setAnimationData(data));
  }, []);

  const { View, setSpeed } = useLottie({
    animationData,
    loop: true,
    className: 'animated-bg',
  });
  setSpeed(0.6);

  const goToUserSuggestionPage = () => push(ROUTE_ONBOARDING_NETWORKING_USER_SUGGESTION);

  useEffect(() => {
    if (isTitleShowed) {
      setTimeout(() => {
        setIsLottieAnimationShowed(true);
      }, 200);
    }
  }, [isTitleShowed]);

  return animationData ? (
    <AnimatedBackground enableInnerScroll>
      <StyledWrap isLottieAnimationShowed={isLottieAnimationShowed}>
        <StyledContentWrap>
          <StyledText>
            <TypeAnimation
              preRenderFirstString
              wrapper="p"
              cursor={false}
              sequence={['', 500, 'Join the quest for knowledge', () => setIsTitleShowed(true)]}
              speed={45}
            />
          </StyledText>
          {isTitleShowed && (
            <StyledTitle>
              On guide:human, you follow people to get their perspective on the world, and they follow you to get yours.
            </StyledTitle>
          )}
        </StyledContentWrap>
        <div>
          {View}
          <StyledButtonWrap>
            <Button text="Continue" handler={goToUserSuggestionPage} />
          </StyledButtonWrap>
        </div>
      </StyledWrap>
    </AnimatedBackground>
  ) : null;
};

export default Join;
