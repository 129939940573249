import { ICON_DARK, ICON_LIGHT } from './constants';

export const getIconColor = (isDark: boolean): string => (isDark ? ICON_DARK : ICON_LIGHT);

export const getCrossIcon = (isDark: boolean): string =>
  `"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='none'%3E%3Cpath stroke='%23${getIconColor(
    isDark
  )}' stroke-linecap='round' stroke-linejoin='round' d='m1 9 8-8M9 9 1 1'/%3E%3C/svg%3E"`;

export const getHomeIcon = (isDark: boolean): string =>
  `"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none'%3E%3Cpath d='M1.86 14.5a1.4 1.4 0 0 1-.96-.39 1.32 1.32 0 0 1-.4-.93V6.43a1.29 1.29 0 0 1 .44-.98L6.08.85a1.36 1.36 0 0 1 1.84 0l5.14 4.6c.14.13.25.28.32.45l.46-.2-.46.2c.08.16.12.34.12.53v6.75c0 .35-.14.68-.4.93-.25.25-.6.39-.96.39H1.86Z' stroke='%23${getIconColor(
    isDark
  )}'/%3E%3C/svg%3E"`;
