import React, { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import DetailSlider from 'components/DetailSlider';

import { COLORS } from 'styles/constants';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { useFollowingItemsList } from 'graphQL/cards/exploreCards/hooks';
import { useNavigationRef } from 'components/Navigation/helpers/hooks';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_FOLLOWING_CARD_DETAIL } from 'routes';

const FollowingItem: FC = () => {
  const { pickId } = useParams<{ pickId: string }>();
  const { items, loadMore } = useFollowingItemsList(pickId);

  const { card } = useGetDetailCard(pickId);
  const { state } = useLocation();
  const { replace } = useHistory();

  const handleRoute = (id: string) =>
    replace({ pathname: transformRoute(ROUTE_FOLLOWING_CARD_DETAIL, { pickId: id }), state });

  useNavigationRef(COLORS.white[100]);

  return items && card ? (
    <DetailSlider handleLoadItems={loadMore} handleRoute={handleRoute} card={card} items={items} />
  ) : null;
};

export default FollowingItem;
