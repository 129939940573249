import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTE_OFF_PLATFORM_SHARING } from 'routes';
import { ESearchParams } from 'constants/common';
import Button from 'components/UI/MenuButton';
import { ReactComponent as Share } from 'assets/icons/Share.svg';

import { StyledItem } from 'components/AddContent/Menu/styled';
import MenuWrap from './MenuWrap';
import { useMenuModal } from '../helpers/hooks';

import { SHARE_TYPE_ALL_SPARKS } from './constants';

const AnotherAllSparksButton = () => {
  const { isModalOpen, handleModal } = useMenuModal();

  const { push } = useHistory();
  const { profileName } = useParams<{ profileName: string }>();

  const handleShareContent = () =>
    push({
      pathname: ROUTE_OFF_PLATFORM_SHARING,
      search: `?${ESearchParams.Type}=${SHARE_TYPE_ALL_SPARKS}&${ESearchParams.UserName}=${profileName}`,
    });

  return (
    <MenuWrap isOpen={isModalOpen} handleModal={handleModal}>
      <StyledItem>
        <Button text="Share" Icon={Share} handler={() => handleShareContent()} />
      </StyledItem>
    </MenuWrap>
  );
};

export default AnotherAllSparksButton;
