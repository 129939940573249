import React, { FC, useRef, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Grain from 'components/UI/Grain';
import Content from 'components/Card/Common/Content';
import Source from 'components/Card/Common/Source';
import Actions from 'components/Card/Actions';
import getStyles from 'helpers/getStyles';
import QuestionAttribute from 'components/Card/QuestionAttribute';
import InteractionBlock from 'components/InteractionBlock';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import ReactionsModal from 'components/Reactions/ReactionsModal/ReactionsModal';
import { ReactionsProvider, useReactionContextState } from 'components/Reactions/hooks';
import HiddenWordsAlert from 'components/HiddenWordsAlert';

import { CardSchema } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';
import { StyledLink } from 'components/Card/Common/styled';
import { useIsUserAllowedToReact, useListItemUrl } from 'components/Card/helpers/hooks';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import useReferrerId from 'helpers/useReferrerId';

import getImages from 'helpers/getImages';
import getAuthUser from 'helpers/getAuthUser';
import { StyledWrap as StyledInner, StyledTitle } from 'components/Collection/styled';
import SparkAttribute from 'components/Card/SparkAttribute';
import { StyledWrap, StyledTitleView, StyledQuestion, StyledDetail } from './styled';

const Item: FC<{ item: CardSchema; isDark: boolean; dataTestId: string }> = ({ item, isDark, dataTestId }) => {
  const [isHidden, setIsHidden] = useState(false);
  const { id, cardStyle, responseToQuestion, responseToSpark } = item;
  const { id: questionId, title: questionTitle, questionGuestList } = responseToQuestion ?? {};
  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  const {
    thoughtId,
    setThoughtId,
    pickerId,
    setPickerId,
    cardId,
    setCardId,
    user,
    setUser,
  } = useReactionContextState();

  const { imageUrl } = getImages(questionGuestList?.cardStyle);
  const { bgColor } = getStyles(cardStyle);
  const { push } = useHistory();

  const url = useListItemUrl(id);
  const bodyRef = useRef<HTMLDivElement>(null);
  const { userId } = getAuthUser();
  const reffererId = useReferrerId();
  const isCurrentUserPick = useMemo(() => pickerId === userId, [pickerId]);
  const isCardReaction =
    item?.extraData?.items?.[0]?.__typename !== 'CardCommentSchema' ||
    (item?.extraData?.items?.[0]?.__typename === 'CardCommentSchema' && item?.extraData?.items?.[0]?.isSuspicious);

  const isCardAllowedToReact = useIsUserAllowedToReact(item?.extraData?.items?.[0]?.user);
  const isFallbackAllowedToReact = useIsUserAllowedToReact(user);
  const isCardBlocked = isCardReaction && !isCardAllowedToReact;
  const isFallbackBlocked = !isCardReaction && !isFallbackAllowedToReact;

  const disableLongTap = isCurrentUserPick || isFallbackBlocked || isCardBlocked;

  useEffect(() => {
    if (!item) return;
    setIsHidden(!!item.isHidden);
  }, [item]);

  useEffect(() => {
    setPickerId(item?.extraData?.items?.[0]?.user?.id ?? reffererId ?? null);
    if (!isCardReaction) {
      setThoughtId(item?.extraData?.items?.[0].id ?? null);
      setUser(item?.extraData?.items?.[0]?.user ?? null);
    }
    setCardId(item?.id);
  }, []);

  return !isHidden ? (
    <ReactionsProvider
      value={{
        isQuickReactionOpen,
        setThoughtId,
        thoughtId,
        setPickerId,
        pickerId,
        cardId,
        setCardId,
        user,
        setUser,
      }}
    >
      <StyledInner ref={infoRef} disableLinks={isQuickReactionOpen}>
        <InteractionBlock
          tapCallback={() => setIsQuickReactionOpen(false)}
          longTapCallback={() => !disableLongTap && setIsQuickReactionOpen(true)}
          getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
        >
          <div>
            {item?.isHidden && <StyledTitle isDark={isDark}>Hidden pick</StyledTitle>}
            <StyledTitleView
              ref={bodyRef}
              className="card"
              bg={bgColor}
              isReverse={false}
              onClick={({ target }) => target === bodyRef.current && push(url)}
            >
              <Grain />
              <StyledWrap>
                <StyledLink to={url}>
                  <Content dataTestId={dataTestId} bigTitle card={item} isTitleView />
                </StyledLink>
              </StyledWrap>
            </StyledTitleView>
            <StyledDetail isDark={isDark}>
              <Source card={item} />
              <Actions isSpotlight item={item} fontColor={isDark ? COLORS.white[100] : COLORS.brown.dark[100]} />
            </StyledDetail>
            {questionId && questionTitle && (
              <StyledQuestion>
                <QuestionAttribute
                  isDark={isDark}
                  questionId={questionId}
                  title={questionTitle}
                  text={`Response to ${questionGuestList?.title ?? 'Question of the Day'}`}
                  imageUrl={imageUrl}
                />
              </StyledQuestion>
            )}
            {responseToSpark && <SparkAttribute spark={responseToSpark} isDark={isDark} />}
          </div>
        </InteractionBlock>
        {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && !isCurrentUserPick && (
          <ReactionMenu
            isShown={isQuickReactionOpen}
            reactionCoordinates={reactionCoordinates}
            handleClickOutside={() => setIsQuickReactionOpen(false)}
            minusTop={minusTop}
            setIsReactionsModalOpen={setIsReactionsModalOpen}
            setIsQuickReactionOpen={setIsQuickReactionOpen}
          />
        )}
        {isReactionsModalOpen && (
          <ReactionsModal
            id={isCardReaction || !thoughtId ? item?.id : thoughtId}
            handleClose={() => setIsReactionsModalOpen(false)}
          />
        )}
      </StyledInner>
    </ReactionsProvider>
  ) : (
    <HiddenWordsAlert showHiddenContent={() => setIsHidden(false)} isDark={isDark} />
  );
};

export default Item;
