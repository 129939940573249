import React, { FC } from 'react';

import { COLORS } from 'styles/constants';
import { UserObjectType, UserShortSchema } from 'constants/graphqlTypes';
import { REF_PLACEHOLDER } from 'components/Explore/Item/helpers';
import { getUserInfo } from 'components/Profile/ProfileTop/helpers';
import GraphemeSplitter from 'grapheme-splitter';

import StyledAvatar from './styled';
import AvatarPlaceholder from './AvatarPlaceholder';

export interface IAvatarProps {
  user: UserObjectType | UserShortSchema;
  dimension?: number;
  color?: string;
  dataTestId?: string;
}

const Avatar: FC<IAvatarProps> = ({ dimension = 44, color = COLORS.white[100], user, dataTestId }) => {
  const { username, firstName, lastName, avatar } = getUserInfo(user);
  const splitter = new GraphemeSplitter();

  const getFirstSymbol = (name?: string | null) => {
    if (!name) return '';
    return splitter.splitGraphemes(name)[0];
  };

  const initialsLetters = `${getFirstSymbol(firstName)}${getFirstSymbol(lastName)}`;
  const isDeletedUser = user?.id === REF_PLACEHOLDER;

  return isDeletedUser ? (
    <AvatarPlaceholder dataTestId={dataTestId} dimension={dimension} />
  ) : (
    <StyledAvatar data-testid={dataTestId || ''} color={color} dimension={dimension} src={avatar} className="avatar">
      {!avatar && (initialsLetters || username[0])}
    </StyledAvatar>
  );
};

export default Avatar;
