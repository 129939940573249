import React, { FC } from 'react';
import Truncate from 'react-truncate-markup';

import getImages from 'helpers/getImages';
import { CardSchema } from 'constants/graphqlTypes';

import { StyledBody, StyledImageItem, StyledImagesList } from 'components/Collection/styled';
import { StyledDescription, StyledTag } from '../PickContent/styled';
import { StyledText, StyledTitle, StyledWrap } from './styled';

const GuestList: FC<{ guestList: CardSchema; guestListSeriesItems?: CardSchema[] | null }> = ({
  guestList,
  guestListSeriesItems,
}) => {
  const { title, description } = guestList;
  const { cardStyle } = guestList ?? {};
  const { imageUrl: bgImage, bgUrl } = getImages(cardStyle, true);
  const isMultiple = !!(guestListSeriesItems?.length && guestListSeriesItems?.length >= 4);

  const guestListSeriesBg =
    guestListSeriesItems && guestListSeriesItems.length >= 1
      ? guestListSeriesItems[0]?.cardStyle?.thumbnailImageSet?.images?.[0]?.url
      : bgUrl;

  const imageSrc = guestListSeriesItems ? guestListSeriesBg : bgImage;

  return (
    <StyledWrap>
      <StyledTag>{guestListSeriesItems ? 'The' : 'The guest:list'}</StyledTag>
      {guestListSeriesItems ? (
        <StyledTitle isSeries={!!guestListSeriesItems}>
          guest <br />
          :list
        </StyledTitle>
      ) : (
        <StyledTitle isSeries={false}>{title}</StyledTitle>
      )}
      <StyledBody className="list-body" bgColor="none">
        <StyledImagesList isMultiple={isMultiple}>
          {guestListSeriesItems?.length && isMultiple ? (
            guestListSeriesItems.slice(0, 4).map(({ cardStyle: style, title: seriesTitle }) => (
              <StyledImageItem
                key={style?.thumbnailImageSet?.images?.[0]?.id}
                src={style?.thumbnailImageSet?.images?.[0]?.url}
              >
                <span>{seriesTitle}</span>
              </StyledImageItem>
            ))
          ) : (
            <StyledImageItem src={imageSrc} />
          )}
        </StyledImagesList>
      </StyledBody>
      <StyledDescription>
        <Truncate lines={2}>
          <StyledText>{description}</StyledText>
        </Truncate>
      </StyledDescription>
    </StyledWrap>
  );
};

export default GuestList;
