import React, { FC, Dispatch, SetStateAction, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import Button from 'components/UI/Button/Button';

import { ReactionListObjectType, MessageReactionListObjectType } from 'constants/graphqlTypes';
import { useRemoveReaction } from 'graphQL/reactions/hooks';
import { useRemoveMessageReaction } from 'graphQL/messages/hooks';
import { IContentIds } from 'helpers/useContentReactions';
import getAuthUser from 'helpers/getAuthUser';

import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { EUiButtonType } from 'components/UI/Button/constants';
import Modal from 'components/Modal';
import Avatar from 'components/UI/Avatar/Avatar';
import { StyledTitle } from 'components/AddContent/Menu/styled';
import ReactionViewButton from '../ReactionViewButton';

import { parseToEmoji } from '../helpers/helpers';
import { StyledItem, StyledEmoji, StyledList } from './styled';

interface IReactionsEditModalProps {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  reactions?: ReactionListObjectType | MessageReactionListObjectType | null;
  handleClose?: () => void;
  loadMoreReactions?: () => void;
  contentIds: IContentIds;
}

const ReactionsEditModal: FC<IReactionsEditModalProps> = ({
  setModalOpen,
  reactions,
  handleClose,
  loadMoreReactions,
  contentIds,
}) => {
  const { userId } = getAuthUser();
  const { removeReactionHandler } = useRemoveReaction();
  const { removeMessageReactionHandler } = useRemoveMessageReaction(contentIds.chatId, contentIds.messageId);

  const handleRemoveReaction = (reactionId: string, commentId?: string | null, cardId?: string | null) => {
    removeReactionHandler({ reactionId, commentId, cardId });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    handleClose?.();
  };

  const [loadingItems, setLoadingItems] = useState<string[]>([]);

  return (
    <Modal handleClose={handleCloseModal}>
      <StyledTitle>Reactions</StyledTitle>
      <StyledList>
        {reactions?.__typename === 'ReactionListObjectType' &&
          reactions?.items.map(({ id, userSrc, symbol, srcUserId, commentId, cardId }) => (
            <StyledItem key={id}>
              {userSrc && <Avatar user={userSrc} dimension={44} />}
              <StyledEmoji>{symbol?.symbol && parseToEmoji(symbol.symbol)}</StyledEmoji>
              <span>{userId === srcUserId ? 'You' : generateFullName(userSrc)}</span>
              {userId === srcUserId ? (
                <Button
                  loading={loadingItems.includes(id)}
                  handler={() => {
                    setLoadingItems([...loadingItems, id]);
                    handleRemoveReaction(id, commentId, cardId);
                  }}
                  text="Remove"
                  type={EUiButtonType.Primary}
                />
              ) : (
                <ReactionViewButton userId={srcUserId} />
              )}
            </StyledItem>
          ))}
        {reactions?.__typename === 'MessageReactionListObjectType' &&
          reactions?.items.map(({ id, user, reactionSymbol, messageId }) => (
            <StyledItem key={id}>
              {user && <Avatar user={user} dimension={44} />}
              <StyledEmoji>{reactionSymbol?.symbol && parseToEmoji(reactionSymbol.symbol)}</StyledEmoji>
              <span>{userId === user?.id ? 'You' : generateFullName(user)}</span>
              {userId === user?.id && (
                <Button
                  loading={loadingItems.includes(id)}
                  handler={() => {
                    setLoadingItems([...loadingItems, id]);
                    removeMessageReactionHandler({ reactionId: id, messageId });
                  }}
                  text="Remove"
                  type={EUiButtonType.Primary}
                />
              )}
            </StyledItem>
          ))}
        <Waypoint onEnter={loadMoreReactions} />
      </StyledList>
    </Modal>
  );
};

export default ReactionsEditModal;
