import React, { FC, useState } from 'react';

import { useListNotifications } from 'graphQL/profile/notifications/hooks';
import useTheme from 'helpers/useTheme';
import Toggle from 'components/UI/Toggle';
import ListPreview from 'components/ActivityItem/Elements/ListPreview';
import { StyledText } from 'components/UI/ListItem/styled';

import { CardSchema } from 'constants/graphqlTypes';
import { StyledUserLabel } from './styled';

interface ISpecificListsProps {
  list: CardSchema;
  isNotificationsEnable: boolean;
}
const SpecificList: FC<ISpecificListsProps> = ({ list, isNotificationsEnable }) => {
  const isDark = useTheme();

  const [checked, setChecked] = useState(isNotificationsEnable);

  const { updateListNotifications, loading } = useListNotifications();

  const onChecked = () => {
    setChecked(!checked);
    updateListNotifications(list?.id, !isNotificationsEnable);
  };

  return (
    <StyledUserLabel>
      <ListPreview list={list} />
      <StyledText isDark={isDark}>{list?.title}</StyledText>
      <Toggle isDark={isDark} checked={checked} handler={onChecked} isDisabled={loading} />
    </StyledUserLabel>
  );
};

export default SpecificList;
