import React, { Dispatch, FC, SetStateAction } from 'react';

import Button from 'components/UI/Button';
import BlockButton from 'components/Blocking/UserButton';
import MuteUserButton from 'components/Muting/MuteUserButton';
import BadgeWithChildren from 'components/UI/BadgeWithChildren';
import InviteButton from 'components/InviteButton/InviteButton';
import FollowButton from 'components/FollowButton';

import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import IconCircle from 'components/UI/IconCircle/IconCircle';
import { UserObjectType } from 'constants/graphqlTypes';
import { useIsUserAllowedToReact } from 'components/Card/helpers/hooks';
import { StyledListItem } from './styled';
import { EButtonType } from './constants';

interface IUserListItemProps {
  user: UserObjectType;
  buttonType: EButtonType;
  handleUser?: () => void;
  isSelected: boolean;
  isDark: boolean;
  hasUsername?: boolean;
  hasNameOnly?: boolean;
  setError?: Dispatch<SetStateAction<boolean>>;
  dataTestId?: string;
  buttonDataTestId?: string;
  paddingVertical?: number;
}

const UserListItem: FC<IUserListItemProps> = ({
  user,
  buttonType,
  handleUser,
  isSelected,
  isDark,
  hasUsername,
  hasNameOnly,
  setError,
  dataTestId,
  buttonDataTestId,
  paddingVertical,
}) => {
  const { id, isBlockedByMe, isMutedByMe } = user;

  const isAllowedToReact = useIsUserAllowedToReact(user);

  const isSelect = buttonType === EButtonType.Select;
  const isNone = buttonType === EButtonType.None;
  const isBadgeDisabled = (isNone && !!handleUser) || isSelect;

  const handleClick = () => {
    if (isNone || isSelect) {
      if (isAllowedToReact || !setError) {
        handleUser?.();
      } else {
        setError?.(true);
      }
    }
  };

  return (
    <StyledListItem
      data-testid={dataTestId}
      isSelect={isBadgeDisabled}
      onClick={handleClick}
      className="userlist-item"
      paddingVertical={paddingVertical}
    >
      <BadgeWithChildren
        isDisabled={isBadgeDisabled}
        user={user}
        isDark={isDark}
        hasUsername={hasUsername}
        hasNameOnly={hasNameOnly}
      >
        {buttonType === EButtonType.Follow && (
          <FollowButton dataTestId={buttonDataTestId} isDark={isDark} user={user} />
        )}
        {buttonType === EButtonType.Block && (
          <BlockButton userId={id} isBlockedByMe={!!isBlockedByMe}>
            <Button isDark={isDark} text={isBlockedByMe ? 'Unblock' : 'Block'} />
          </BlockButton>
        )}
        {buttonType === EButtonType.Mute && (
          <MuteUserButton fullname={generateFullName(user)} userId={id} isMutedByMe={!!isMutedByMe}>
            <Button isDark={isDark} text={isMutedByMe ? 'Unmute' : 'Mute'} />
          </MuteUserButton>
        )}
        {buttonType === EButtonType.Invite && <InviteButton user={user} />}
        {isSelect && (isAllowedToReact || !setError) && (
          <IconCircle
            Icon={isSelected ? CheckIcon : PlusIcon}
            hasStroke
            isDark={isDark}
            hasBorder={!isSelected}
            hasBackground={isSelected}
          />
        )}
      </BadgeWithChildren>
    </StyledListItem>
  );
};

export default UserListItem;
