import { styled } from 'linaria/react';

import ImportLink from '../../UI/Link/styled';

import { COLORS, FONTS } from '../../../styles/constants';

export const StyledLink = styled(ImportLink)`
  flex-grow: 1;
  overflow: hidden;
`;
export const StyledText = styled.p<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
  padding: 2px 8px;
  text-decoration: none;
  word-break: break-word;
  margin: 0;
  overflow: hidden;

  b {
    font-weight: 550;
  }
`;

export const StyledDate = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.light[100])};
`;

export const StyledThumbnail = styled.div<{ src?: string | null; bg?: string }>`
  background: ${({ src, bg }) => (src ? `url('${src}')` : bg || COLORS.white[30])};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  height: 44px;
  min-width: 44px;
  width: 44px;

  &::after {
    content: '';
    padding-bottom: 100%;
    display: block;
  }
`;

export const StyledButtonWrapper = styled.div`
  flex-shrink: 0;
`;

export const StyledUsername = styled.span`
  display: inline-flex;
  max-width: 100%;
  b {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledBadgeContainer = styled.div`
  width: 20.2px;
  height: 16px;
`;
