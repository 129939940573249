import React, { FC } from 'react';

import { Scalars, UnionActivityObjectType } from 'constants/graphqlTypes';

import StyledContainer from 'components/UI/BadgeWithChildren/styled';
import Follow from './Follow';
import Thought from './Thought';
import Privacy from './Privacy';
import Add from './Add';
import Reaction from './Reaction';

interface IActivityItemProps {
  data: UnionActivityObjectType;
  count?: number | null;
  createdAt: Scalars['DateTime'] | null;
  activityTestId: string;
}

const ActivityItem: FC<IActivityItemProps> = ({ data, createdAt, count, activityTestId }) => (
  <StyledContainer>
    {data.__typename === 'ActivityFollowObjectType' && (
      <Follow activityTestId={activityTestId} item={data} date={createdAt} />
    )}
    {data.__typename === 'ActivityThoughtObjectType' && (
      <Thought activityTestId={activityTestId} item={data} date={createdAt} />
    )}
    {data.__typename === 'ActivityCollectionPrivacyObjectType' && (
      <Privacy activityTestId={activityTestId} item={data} date={createdAt} />
    )}
    {data.__typename === 'ActivityCollectionAddObjectType' && (
      <Add activityTestId={activityTestId} item={data} date={createdAt} count={count} />
    )}
    {data.__typename === 'ActivityCollectionRemovalObjectType' && (
      <Add activityTestId={activityTestId} item={data} date={createdAt} count={count} isRemoval />
    )}
    {data.__typename === 'ActivityReactionObjectType' && (
      <Reaction activityTestId={activityTestId} item={data} date={createdAt} count={count} />
    )}
  </StyledContainer>
);

export default ActivityItem;
