import React, { FC } from 'react';

import { CardSchema } from 'constants/graphqlTypes';
import { getListStyles } from 'components/Collection/helpers';
import { StyledWrap, StyledList } from 'components/List/Grid/styled';

import ListContent from 'components/List/Content';
import ListHeader from 'pages/OnboardingV2/ContentTutorial/Preview/ListDetailPreview/ListHeader';
import ListDetailPreviewCard from './ListDetailPreviewCard';

interface IListDetailPreviewProps {
  list: CardSchema;
  items: CardSchema[];
  itemsTotalCount: number;
}

const ListDetailPreview: FC<IListDetailPreviewProps> = ({ list, items, itemsTotalCount }) => {
  const { creators, isCollaborative, title, description, isPrivateList, invite, reactions } = list ?? {};
  const { user } = creators?.items[0] ?? {};
  const { images, titles, bgColor } = getListStyles(list, true);

  return (
    <div>
      <ListHeader titles={titles} images={images} bgColor={bgColor} />
      <ListContent
        listCardId={list.cardId}
        title={title}
        totalCount={itemsTotalCount}
        user={user}
        description={description}
        isPrivate={isPrivateList}
        isCollaborative={isCollaborative}
        creators={creators}
        invite={invite}
        reactions={reactions}
        isDarkFromProps={false}
      />
      <StyledWrap className="listGrid">
        <StyledList>
          {items.map((item, index) => (
            <ListDetailPreviewCard key={item.id} item={item} index={index} isDefaultView={items.length > 3} />
          ))}
        </StyledList>
      </StyledWrap>
    </div>
  );
};

export default ListDetailPreview;
