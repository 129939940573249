import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_MESSAGES_SETTINGS } from 'routes';

import Modal from 'components/Modal';
import UserList from 'components/UserList';
import ContentSubtitle from 'components/UI/ContentSubtitle';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { EButtonType } from 'components/UserList/constants';

import { generateName } from 'components/Profile/ProfileTop/helpers';
import { UserObjectType } from 'constants/graphqlTypes';
import { useSearchChatMembers } from 'graphQL/search/hooks';
import { useAddGroupMembers } from 'graphQL/messages/hooks';

import { StyledSection } from '../UserInput/styled';
import UserInput from '../UserInput';
import { StyledListWrap } from './styled';

const AddMembers: FC = () => {
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [text, setText] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<UserObjectType[]>([]);
  const { state } = useLocation<{ variable: { chatId: string } }>();
  const { chatId } = state.variable;

  const { items, loading, loadMore } = useSearchChatMembers(text ?? '', { notInChat: chatId });

  const { replace } = useHistory();

  const removeUser = (user: UserObjectType) => setSelectedUsers((prev) => prev.filter(({ id }) => id !== user.id));
  const handleUser = (user: UserObjectType) => {
    if (selectedUsers.some(({ id }) => id === user.id)) {
      removeUser(user);
    } else {
      setSelectedUsers((prev) => [...prev, user]);
    }
    setText('');
  };

  const handleClose = () => replace(ROUTE_MESSAGES_SETTINGS, state);
  const handleDone = () => selectedUsers.length && setIsConfirmation(true);

  const onAddMemberError = () => {
    setIsConfirmation(false);
    setIsErrorModalOpen(true);
  };

  const { addMembers, loading: addMembersLoading } = useAddGroupMembers(
    chatId,
    selectedUsers.map((user) => user.id),
    handleClose,
    onAddMemberError
  );

  const selectedUsersNames = selectedUsers?.reduce(
    (acc: string, { username, userInfo }: UserObjectType, index: number) => {
      const name = generateName(username, userInfo?.firstName);
      return index ? acc.concat(' and ').concat(name) : name;
    },
    ''
  );

  const usersTitle =
    selectedUsers.length > 2
      ? generateName(selectedUsers[0].username, selectedUsers[0].userInfo?.firstName).concat(
          ` and ${selectedUsers?.length - 1} more`
        )
      : selectedUsersNames;

  return (
    <Modal fadeIn={false} handleClose={handleClose} handlePrev={handleClose} buttonText="Next" handleDone={handleDone}>
      <StyledSection>
        <ContentSubtitle isDark text="Add members" />
        <UserInput users={selectedUsers} handleRemove={removeUser} isDark text={text} setText={setText} autoFocus />
      </StyledSection>
      <StyledListWrap>
        <ContentSubtitle isDark text="Suggested" />
        <UserList
          hasUsername
          users={items}
          loading={loading}
          loadMore={loadMore}
          buttonType={EButtonType.Select}
          selected={selectedUsers}
          handleUser={handleUser}
          setError={setIsErrorModalOpen}
        />
      </StyledListWrap>
      {isConfirmation && (
        <ConfirmationModal
          handleCancel={() => setIsConfirmation(false)}
          handleAccept={() => addMembers()}
          buttonText="Add"
          handleClickOutside={() => setIsConfirmation(false)}
          subTitle={`${usersTitle} will see previous messages from this thread`}
          titleText="Add new members?"
          isWarning={false}
          disableSubmit={addMembersLoading}
        />
      )}
      {isErrorModalOpen && (
        <ConfirmationModal
          handleAccept={() => setIsErrorModalOpen(false)}
          buttonText="OK"
          handleClickOutside={() => setIsErrorModalOpen(false)}
          subTitle="You can't message this person because they've chosen not to receive messages."
          titleText="Unable to send message"
          isWarning={false}
        />
      )}
    </Modal>
  );
};

export default AddMembers;
