import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTE_STYLE_DESCRIPTION, ROUTE_REPORT_STYLE_ISSUE, ROUTE_REPORT_STYLE_OTHER } from 'routes';
import ReportIssue from './ReportIssue';
import ReportOther from './ReportOther';

import StyleDescription from './StyleDescription';

const ReportStyles: FC<{ cardId: string }> = ({ cardId }) => (
  <Switch>
    <Route exact path={ROUTE_STYLE_DESCRIPTION}>
      <StyleDescription />
    </Route>
    <Route exact path={ROUTE_REPORT_STYLE_ISSUE}>
      <ReportIssue cardId={cardId} />
    </Route>
    <Route exact path={ROUTE_REPORT_STYLE_OTHER}>
      <ReportOther cardId={cardId} />
    </Route>
  </Switch>
);

export default ReportStyles;
