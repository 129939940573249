import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import TruncateMarkup from 'react-truncate-markup';

import { useGetMessageContent } from 'graphQL/messages/hooks';
import { ECardLayoutOptions, MessageItemType, MessageContentStatus, MessageSchema } from 'constants/graphqlTypes';
import getImages from 'helpers/getImages';
import Badge from 'components/UI/Badge/Badge';
import UILink from 'components/UI/Link';
import { ROUTE_CARD_DETAIL } from 'routes';
import getStyles from 'helpers/getStyles';
import Grain from 'components/UI/Grain';
import { useFullScreenAnimation } from 'components/Reactions/Animations/Fullscreen/hooks';
import { EUiLinkClassNames } from 'components/UI/Link/constants';
import ReactionsToken from 'components/Messages/Message/ReactionsToken';
import { useMessagesChatData } from 'components/Messages/helpers/hooks';
import ThoughtLastUpdate from 'components/Thoughts/Thought/ThoughtLastUpdate';
import { getWeeklyDateFormat } from 'helpers/getDateFormat';
import UnavailableContent from '../UnavailableContent';
import { EMessageContentType } from '../../models';
import ReactionButton from '../ReactionButton';
import { getReactionContentText } from '../../helpers/helpers';

import { StyledMessageInfo, StyledRelWrap } from '../styled';
import {
  StyledContainer,
  StyledInfo,
  StyledSourceContainer,
  StyledSourceImage,
  StyledSourceTitle,
  StyledThought,
  StyledThoughtContainer,
  StyledWrap,
} from './styled';
import { StyledTokenWrap } from '../../styled';

const Thought: FC<{
  message: MessageSchema;
  commentId: string;
  pickId: string;
  isMine: boolean;
  messageRef: React.RefObject<HTMLDivElement>;
  isDark: boolean;
  setIsContentDeleted: Dispatch<SetStateAction<boolean>>;
  contentReactionId?: string;
}> = ({ commentId, pickId, isMine, messageRef, isDark, setIsContentDeleted, contentReactionId, message }) => {
  const { comment, commentList, messageContentStatus } = useGetMessageContent({
    itemType: MessageItemType.Comment,
    entityId: commentId,
    pickId,
    contentReactionId,
  });

  const contentReactionEmoji = comment?.reactions?.items?.[0]?.symbol?.symbol;

  useFullScreenAnimation(message.id, contentReactionEmoji);

  useEffect(() => {
    setIsContentDeleted(messageContentStatus === MessageContentStatus.Deleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageContentStatus]);

  const { card, user, text, updatedAt } = comment ?? {};
  const { cardStyle, title } = card ?? {};
  const { bgColor, layout } = getStyles(cardStyle);

  const { chatId } = useMessagesChatData();
  const { imageUrl } = getImages(cardStyle, true);
  const isHasImage = layout !== ECardLayoutOptions.Plain && imageUrl;

  const contentIds = {
    chatId,
    messageId: message?.id || '',
  };

  return messageContentStatus === MessageContentStatus.Unavailable ||
    messageContentStatus === MessageContentStatus.Deleted ? (
    <UnavailableContent
      isDark={isDark}
      isMine={isMine}
      contentType={EMessageContentType.Thought}
      messageContentStatus={messageContentStatus}
    />
  ) : (
    <>
      {user && (
        <StyledWrap isMine={isMine} ref={messageRef}>
          {contentReactionEmoji && (
            <StyledMessageInfo>{getReactionContentText(isMine, EMessageContentType.Thought)}</StyledMessageInfo>
          )}
          <StyledRelWrap>
            <UILink
              route={`${ROUTE_CARD_DETAIL}?thoughtsTab=0`}
              params={{ pickId }}
              classname={EUiLinkClassNames.ThoughtPreview}
            >
              <StyledContainer bg={bgColor}>
                <Grain />
                <StyledSourceContainer>
                  {isHasImage && <StyledSourceImage src={imageUrl} />}
                  <TruncateMarkup lines={2}>
                    <StyledSourceTitle>{title}</StyledSourceTitle>
                  </TruncateMarkup>
                </StyledSourceContainer>
                <StyledThoughtContainer>
                  <Badge user={user} />
                  <StyledThought>“{text}”</StyledThought>
                  <StyledInfo>
                    <ThoughtLastUpdate
                      lastUpdate={getWeeklyDateFormat(updatedAt)}
                      username={user?.username}
                      parentCard={commentList}
                    />
                  </StyledInfo>
                </StyledThoughtContainer>
              </StyledContainer>
            </UILink>
            {contentReactionEmoji && <ReactionButton emojiCode={contentReactionEmoji} />}
            <StyledTokenWrap className="token-wrap" isRight={isMine}>
              <ReactionsToken contentIds={contentIds} isDark={isDark} />
            </StyledTokenWrap>
          </StyledRelWrap>
        </StyledWrap>
      )}
    </>
  );
};

export default Thought;
