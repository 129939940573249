import React, { FC } from 'react';
import { ROUTE_QUESTION_DETAIL } from 'routes';
import Attribute from 'components/UI/Attribute';

import { StyledQuestionLabel, StyledThumbnail } from './styled';

interface IQuestionAttribute {
  questionId: string;
  title: string;
  text?: string | null;
  isFullBleed?: boolean;
  isDark?: boolean;
  hasBorder?: boolean;
  imageUrl?: string;
}

const QuestionAttribute: FC<IQuestionAttribute> = ({
  title,
  isDark,
  isFullBleed,
  questionId,
  text,
  imageUrl,
  hasBorder = true,
}) => (
  <Attribute
    title={text || 'Response to Question of the Day'}
    text={title}
    isFullBleed={isFullBleed}
    route={ROUTE_QUESTION_DETAIL}
    params={{ questionId }}
    hasBorder={hasBorder}
    isDark={isDark}
  >
    {imageUrl ? <StyledThumbnail src={imageUrl} /> : <StyledQuestionLabel>Q:</StyledQuestionLabel>}
  </Attribute>
);

export default QuestionAttribute;
