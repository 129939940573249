import React, { FC, useState } from 'react';

import OnboardingAlert from 'components/UI/OnboardingAlert';

import { ETutorialStatus } from 'constants/graphqlTypes';
import { useUpdateUserSettings, useUserSettings } from 'graphQL/contextualIntro/hooks';
import useGuideTour from 'helpers/useGuideTour';
import { StyledWrap } from 'components/Modal/styled';
import { StyledAlertWrap } from 'components/CardDetails/ReactionInfo/styled';

const ReactionInfo: FC = () => {
  const [wasSent, setWasSent] = useState(false);

  const { isGuideTour } = useGuideTour();
  const { loading, isThoughtReactionIntroCompleted, tutorialStatus } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings({
    isThoughtReactionIntroCompleted: true,
  });

  const handleClose = () => {
    if (!wasSent) {
      updateUserSettings();
      setWasSent(true);
    }
  };

  return !isThoughtReactionIntroCompleted && !isGuideTour && !loading && tutorialStatus === ETutorialStatus.Finished ? (
    <>
      <StyledWrap isOpen={!wasSent} hasTransition onMouseDown={handleClose}>
        <StyledAlertWrap onMouseDown={(e) => e.stopPropagation()}>
          <OnboardingAlert
            title="Tap to send a reaction &#128293;&#10084;&#65039;&#128514;"
            submitText="Got it"
            handleSubmit={() => updateUserSettings()}
            hasCross
            handleCancel={handleClose}
          >
            Tap the button on thoughts to send a reaction. Find reactions you’ve sent and received in your messages.
          </OnboardingAlert>
        </StyledAlertWrap>
      </StyledWrap>
    </>
  ) : null;
};

export default ReactionInfo;
