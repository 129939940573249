import React, { FC, useState } from 'react';
import { createPortal } from 'react-dom';

import Button from 'components/UI/Button';
import TourModal from 'components/TourModal';
import { EUiButtonType } from 'components/UI/Button/constants';

import imageArea from 'assets/images/imageArea.png';
import { StyledContent, StyledTextContainer, StyledMainInfo, StyledInfo, StyledButtonContainer } from './styled';

const EmptyPageContent: FC<{ isDark: boolean }> = ({ isDark }) => {
  const [isOpen, setIsOpen] = useState(false);
  const portalContainer = document.getElementById('modal-root');

  return (
    <>
      <StyledContent isDark={isDark}>
        <img width="53%" src={imageArea} alt="saved picks" />
        <StyledTextContainer>
          <StyledMainInfo isDark={isDark}>Save everything you find with one tap.</StyledMainInfo>
          <StyledInfo isDark={isDark}>
            Privately collect websites, music, videos, and more, before you share them to the world.
          </StyledInfo>
        </StyledTextContainer>
      </StyledContent>
      <StyledButtonContainer>
        <Button text="Show me how" type={EUiButtonType.Primary} isDark={isDark} handler={() => setIsOpen(true)} />
      </StyledButtonContainer>
      {isOpen && portalContainer && createPortal(<TourModal handleClose={() => setIsOpen(false)} />, portalContainer)}
    </>
  );
};

export default EmptyPageContent;
