import React, { FC, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';

import Modal from 'components/Modal';
import TextInput from 'components/UI/TextInput';
import Button from 'components/UI/MenuButton';
import ContentTitle from 'components/UI/ContentTitle';
import ExtractionError from 'components/ExtractionError';
import useDisableScroll from 'helpers/useDisableScroll';
import useExtractionProcess from 'helpers/useExtractionProcess';
import { ReactComponent as PickFromLinkIcon } from 'assets/icons/PickFromLink.svg';
import { ISetExtractData } from 'components/AddContent/helpers/models';
import { StyledFooter } from 'components/AddContent/AddPick/styled';
import { useCheckPickUrl } from 'components/AddContent/AddPick/hooks';
import { useAnswerLinks } from '../hooks';

import { StyledInputWrapper } from './styled';

interface IAddLinkProps {
  url: string;
  handleClose: () => void;
  setExtractData: ({ extractPickId, extractCardId, extractTitle, extractImage }: ISetExtractData) => void;
  setUrl: Dispatch<SetStateAction<string>>;
  setIsPicked: Dispatch<SetStateAction<boolean>>;
  fadeIn: boolean;
}

const AddLink: FC<IAddLinkProps> = ({ url, handleClose, setExtractData, setUrl, setIsPicked, fadeIn }) => {
  const { addAnswerStyle, addAnswerThought } = useAnswerLinks();

  const {
    state: { variable },
  } = useLocation<{ variable: { questionTitle: string } }>();
  const { questionTitle } = variable ?? {};

  const { reason, error, extractionError, loading, checkPickExist } = useExtractionProcess({
    setExtractData,
    setIsPicked,
    replaceRouteAfterExtract: addAnswerStyle,
    replaceRouteAfterPickExist: addAnswerThought,
    url,
  });

  const validationError = useCheckPickUrl(url);

  useDisableScroll();

  return (
    <Modal
      fadeIn={fadeIn}
      handleClose={handleClose}
      handleDone={() => checkPickExist(url)}
      buttonText="Next"
      disabled={!!validationError || !!error || !url}
      loading={loading}
    >
      <StyledInputWrapper>
        <ContentTitle isDark>{questionTitle}</ContentTitle>
        <TextInput
          inputHeight={143}
          placeholder="https://www.eg.com"
          setText={setUrl}
          text={url}
          error={validationError || error}
          longDash={false}
        />
      </StyledInputWrapper>
      {!url && (
        <StyledFooter>
          <Button
            text="Paste link from clipboard"
            Icon={PickFromLinkIcon}
            handler={() => navigator.clipboard?.readText().then(setUrl)}
          />
        </StyledFooter>
      )}
      {extractionError && (
        <ExtractionError setUrl={setUrl} extractionError={extractionError} reason={reason} fromQotd />
      )}
    </Modal>
  );
};

export default AddLink;
