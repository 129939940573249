import { HOME_VIEW, TAB_BAR, WEB_PREFIX } from 'constants/common';

export const TEST_ID_HOME_START = `${WEB_PREFIX}${HOME_VIEW}`;
export const TEST_HOME_BURGER_MENU_BTN = `${TEST_ID_HOME_START}_burger-menu-button`;
export const TEST_HOME_MESSAGES_BTN = `${TEST_ID_HOME_START}_messages-button`;
export const TEST_HOME_SEARCH_BTN = `${TEST_ID_HOME_START}_search-button`;

export const TEST_HOME_FEATURED_TAB_BTN = `${TEST_ID_HOME_START}_page-button-featured`;
export const TEST_HOME_FOLLOWING_TAB_BTN = `${TEST_ID_HOME_START}_page-button-following`;
export const TEST_HOME_EVERYONE_TAB_BTN = `${TEST_ID_HOME_START}_page-button-everyone`;
export const TEST_HOME_CHANNELS_TAB_BTN = `${TEST_ID_HOME_START}_page-button-channels`;

export const TEST_ID_TAB_BAR_START = `${WEB_PREFIX}${TAB_BAR}`;
export const TEST_TAB_BAR_HOME_BTN = `${TEST_ID_TAB_BAR_START}_home-button`;
export const TEST_TAB_BAR_PLUS_BTN = `${TEST_ID_TAB_BAR_START}_plus-button`;
export const TEST_TAB_BAR_PROFILE_BTN = `${TEST_ID_TAB_BAR_START}_profile-button`;
