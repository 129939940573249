import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { ROUTE_GUEST_LIST_DETAIL } from 'routes';

import { StyledLink } from 'components/GuestList/Card/styled';

import { transformRoute } from 'helpers/routingHelper';
import getImages from 'helpers/getImages';
import { MessageContentStatus, MessageItemType, MessageSchema, MessageType } from 'constants/graphqlTypes';
import { useFullScreenAnimation } from 'components/Reactions/Animations/Fullscreen/hooks';
import { useGetMessageContent } from 'graphQL/messages/hooks';

import ReactionsToken from 'components/Messages/Message/ReactionsToken';
import { useMessagesChatData } from 'components/Messages/helpers/hooks';
import UnavailableContent from '../UnavailableContent';
import { EMessageContentType } from '../../models';
import { StyledTokenWrap } from '../../styled';
import { StyledText, StyledTitle, StyledWrap } from './styled';

const GuestList: FC<{
  id: string;
  isMine: boolean;
  isDark: boolean;
  message: MessageSchema;
  messageRef: React.RefObject<HTMLDivElement>;
  contentReactionId?: string;
  setIsContentDeleted: Dispatch<SetStateAction<boolean>>;
  nextMessageType?: MessageType | null;
}> = ({ id, isMine, isDark, message, messageRef, contentReactionId, setIsContentDeleted, nextMessageType }) => {
  const { guestList, messageContentStatus } = useGetMessageContent({
    itemType: MessageItemType.GuestList,
    entityId: id,
    contentReactionId,
  });

  const { chatId } = useMessagesChatData();

  const { cardStyle, reactions: guestListReactions, title } = guestList ?? {};

  const contentReactionEmoji = guestListReactions?.items?.[0]?.symbol?.symbol;

  const { imageUrl } = getImages(cardStyle);
  useFullScreenAnimation(message.id, contentReactionEmoji);

  useEffect(() => {
    setIsContentDeleted(messageContentStatus === MessageContentStatus.Deleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageContentStatus]);

  if (
    messageContentStatus === MessageContentStatus.Unavailable ||
    messageContentStatus === MessageContentStatus.Deleted
  ) {
    return (
      <UnavailableContent
        isDark={isDark}
        isMine={isMine}
        contentType={EMessageContentType.Pick}
        messageContentStatus={messageContentStatus}
      />
    );
  }

  const contentIds = {
    chatId,
    messageId: message?.id || '',
  };

  return (
    <StyledWrap isRight={isMine} ref={messageRef} hasBottomMargin={!!nextMessageType}>
      <StyledLink to={transformRoute(ROUTE_GUEST_LIST_DETAIL, { id })} bg={imageUrl ?? ''}>
        <StyledText>The guest:list</StyledText>
        <StyledTitle>{title}</StyledTitle>
      </StyledLink>
      <StyledTokenWrap isRight={isMine}>
        <ReactionsToken contentIds={contentIds} isDark={isDark} />
      </StyledTokenWrap>
    </StyledWrap>
  );
};

export default GuestList;
