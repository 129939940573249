import { css } from 'linaria';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = css`
  align-items: center;
  color: ${COLORS.white[100]};
  display: flex;
  font: 22px/130% ${FONTS.primary};
  height: 100%;
  justify-content: center;
  padding: 0 61px;
  text-align: center;
  white-space: pre-line;
  width: 100%;
`;
