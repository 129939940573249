import { styled } from 'linaria/react';
import { FONTS, BREAKPOINTS } from 'styles/constants';

export const StyledDescription = styled.p`
  margin: 0;
  padding: 0 0 20px
  font: 400 14px/140% ${FONTS.secondary};

  &:nth-of-type(1) {
    padding-top: 16px;
  }
  &:last-child {
    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      padding-bottom: 298px;
    }
  }
`;

export const StyledLink = styled.a`
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
`;

export const StyledWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;
