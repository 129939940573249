import { styled } from 'linaria/react';
import { BREAKPOINTS } from 'styles/constants';

export const StyledWrap = styled.div`
  .highlighter-child-wrap {
    max-width: 100%;

    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      padding: 0 2vw;
    }
  }
`;

export const StyledContentWrap = styled.div`
  max-width: 396px;
  margin-left: auto;
`;
