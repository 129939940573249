import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';

import { UserObjectType } from 'constants/graphqlTypes';
import { SwiperSlide } from 'swiper/react';
import { Swiper as SwiperRef } from 'swiper';
import ScrollContainer from 'components/ScrollContainer/ScrollContainer';
import { StyledInput, StyledList } from './styled';

import User from './User';

interface IUserInputProps {
  users: UserObjectType[];
  isDark: boolean;
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  handleRemove: (user: UserObjectType) => void;
  autoFocus?: boolean;
}

const UserInput: FC<IUserInputProps> = ({ users, isDark, text, autoFocus, setText, handleRemove }) => {
  const ref = useRef<SwiperRef | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const userCountRef = useRef<number>(0);

  useEffect(() => {
    if (users.length > userCountRef.current) {
      ref.current?.slideTo(users.length);
    }
    userCountRef.current = users.length;
  }, [users]);

  const onSwiper = (swiper: SwiperRef) => {
    ref.current = swiper;
  };

  return (
    <StyledList onClick={() => inputRef.current?.focus()}>
      <ScrollContainer onSwiper={onSwiper} spaceBetween={5}>
        {users.map((user) => (
          <SwiperSlide key={user.id} onClick={(event) => event.stopPropagation()}>
            <User handleRemove={() => handleRemove(user)} user={user} isDark={isDark} />
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <StyledInput
            value={text}
            onChange={({ target }) => setText(target.value)}
            isDark={isDark}
            autoFocus={autoFocus}
            ref={inputRef}
            textLength={text.length}
          />
        </SwiperSlide>
      </ScrollContainer>
    </StyledList>
  );
};

export default UserInput;
