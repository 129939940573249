import { ROUTE_USER_PROFILE, ROUTE_DISCOVERY, ROUTE_MESSAGES } from 'routes';
import { transformRoute } from 'helpers/routingHelper';

export const getRedirectUrl = (prevPath?: string, username?: string): string => {
  // reached list from profile page
  if (prevPath?.includes('/@')) {
    return transformRoute(ROUTE_USER_PROFILE, { profileName: username });
  }

  // reached list from message invite
  if (prevPath?.includes(ROUTE_MESSAGES)) {
    return ROUTE_MESSAGES;
  }

  // setStorageTabItem(EExploreTabs.Featured);
  return ROUTE_DISCOVERY;
};
