import React, { FC } from 'react';

import Item from 'components/Explore/Item';
import { CardTypeOptions, UnionDiscoverFollowingObjectType } from 'constants/graphqlTypes';
import { FOLLOWING_TAB_VIEW, getExploreItemDataTestId } from 'constants/aqa/explore';
import Card from 'components/Sparks/Views/Card';
import useTheme from 'helpers/useTheme';
import { useFeaturedFlagEnabled } from 'helpers/posthogHooks';
import { EFeaturedFlags } from 'constants/posthogEvents';
import BigCard from 'components/List/BigCard';
import { getCardLayout } from '../helpers';
import ListGroup from '../ListGroup';
import MixedGroup from '../MixedGroup';

import { StyledSparkContainer } from './styled';

const TabItem: FC<{ currentItem: UnionDiscoverFollowingObjectType; items: UnionDiscoverFollowingObjectType[] }> = ({
  currentItem,
  items,
}) => {
  const isViewSparks = useFeaturedFlagEnabled(EFeaturedFlags.ViewSparks);
  const isDark = useTheme();

  return (
    <>
      {currentItem.__typename === 'CardSchema' &&
        currentItem.type !== CardTypeOptions.Collection &&
        currentItem.type !== CardTypeOptions.Spark && (
          <Item
            dataTestId={getExploreItemDataTestId(getCardLayout(currentItem, items), FOLLOWING_TAB_VIEW)}
            item={{ card: currentItem, layout: getCardLayout(currentItem, items) }}
          />
        )}
      {currentItem.__typename === 'CardSchema' && currentItem.type === CardTypeOptions.Collection && (
        <BigCard item={currentItem} />
      )}
      {currentItem.__typename === 'CardSchema' && currentItem.type === CardTypeOptions.Spark && isViewSparks && (
        <StyledSparkContainer>
          <Card isDark={isDark} card={currentItem} />
        </StyledSparkContainer>
      )}
      {currentItem.__typename === 'ListGroupFollowSchema' && currentItem?.cardItems?.length && (
        <ListGroup item={currentItem} />
      )}
      {currentItem.__typename === 'MixedGroupFollowSchema' && <MixedGroup item={currentItem} />}
    </>
  );
};

export default TabItem;
