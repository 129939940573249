import React, { FC, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { ROUTE_CREATE_FEED_NAME, ROUTE_CREATE_FEED, ROUTE_CREATE_FEED_USERS } from 'routes';
import { ChannelSectionItemsSortTypeOptions, UserObjectType } from 'constants/graphqlTypes';
import { ESelectionMode } from '../../constants';
import AddFeedName from '../Views/AddFeedName';
import SelectFeedCreateTopics from '../Views/SelectFeedCreateTopics';
import SelectFeedCreateUsers from '../Views/SelectFeedCreateUsers';
import { getIdsFromObjects } from '../Views/SelectFeedCreateUsers/helpers';

const CreateFeed: FC = () => {
  const { Select } = ESelectionMode;

  const { state } = useLocation<{ variable?: { sectionId: string; sorting: ChannelSectionItemsSortTypeOptions } }>();
  const { sectionId, sorting } = state?.variable ?? {};

  const [selectionTopicMode, setSelectionTopicMode] = useState<ESelectionMode>(Select);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [deselectedTopics, setDeselectedTopics] = useState<string[]>([]);
  const [totalSelectedTopics, setTotalSelectedTopics] = useState(0);

  const [selectionUserMode, setSelectionUserMode] = useState<ESelectionMode>(Select);

  const [selectedUsers, setSelectedUsers] = useState<UserObjectType[]>([]);
  const [deselectedUsers, setDeselectedUsers] = useState<UserObjectType[]>([]);

  const [totalSelectedUsers, setTotalSelectedUsers] = useState(0);

  const [isAllFollowings, setAllFollowings] = useState(false);
  const [isAllUsers, setAllUsers] = useState(false);

  return (
    <Switch>
      <Route exact path={ROUTE_CREATE_FEED}>
        <SelectFeedCreateTopics
          selectionMode={selectionTopicMode}
          setSelectionMode={setSelectionTopicMode}
          selectedTopics={selectedTopics}
          setSelectedTopics={setSelectedTopics}
          deselectedTopics={deselectedTopics}
          setDeselectedTopics={setDeselectedTopics}
          totalSelectedTopics={totalSelectedTopics}
          setTotalSelectedTopics={setTotalSelectedTopics}
        />
      </Route>
      <Route path={ROUTE_CREATE_FEED_USERS}>
        <SelectFeedCreateUsers
          selectionMode={selectionUserMode}
          setSelectionMode={setSelectionUserMode}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          deselectedUsers={deselectedUsers}
          setDeselectedUsers={setDeselectedUsers}
          totalSelectedUsers={totalSelectedUsers}
          setTotalSelectedUsers={setTotalSelectedUsers}
          isAllFollowings={isAllFollowings}
          setAllFollowings={setAllFollowings}
          isAllUsers={isAllUsers}
          setAllUsers={setAllUsers}
        />
      </Route>
      <Route exact path={ROUTE_CREATE_FEED_NAME}>
        <AddFeedName
          selectionTopicMode={selectionTopicMode}
          excludedTopics={deselectedTopics}
          includedTopics={selectedTopics}
          excludedUsers={getIdsFromObjects(deselectedUsers)}
          includedUsers={getIdsFromObjects(selectedUsers)}
          isAllFollowings={isAllFollowings}
          isAllUsers={isAllUsers}
          sectionId={sectionId ?? ''}
          sorting={sorting}
        />
      </Route>
    </Switch>
  );
};

export default CreateFeed;
