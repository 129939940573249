import React, { FC } from 'react';
import { StyledWrap, StyledSubtitle } from './styled';

export interface IContentSubtitleProps {
  isDark: boolean;
  text: string;
  color?: string;
  dataTestId?: string;
}

const ContentSubtitle: FC<IContentSubtitleProps> = ({ isDark, text, color, dataTestId }) => (
  <StyledWrap>
    <StyledSubtitle data-testid={dataTestId} color={color} isDark={isDark}>
      {text}
    </StyledSubtitle>
  </StyledWrap>
);

export default ContentSubtitle;
