import React, { FC } from 'react';

import { StyledEmptySearchMessage } from './styled';

const EmptySearchMessage: FC<{ isDark: boolean; dataTestId?: string }> = ({ isDark, dataTestId }) => (
  <StyledEmptySearchMessage data-testid={dataTestId} isDark={isDark}>
    No results found.
    <br />
    Try different keywords or check for typos.
  </StyledEmptySearchMessage>
);

export default EmptySearchMessage;
