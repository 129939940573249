import { useEffect } from 'react';
import disableScroll from 'disable-scroll';

const useDisableScroll = (ignore?: boolean): void => {
  useEffect(() => {
    if (!ignore) {
      disableScroll.on(undefined, {
        authorizedInInputs: [37, 38, 39, 40],
        disableKeys: true,
        disableScroll: true,
        disableWheel: true,
        keyboardKeys: [33, 34, 35, 36, 37, 38, 39, 40],
      });
    }

    return () => disableScroll.off();
  }, [ignore]);
};

export default useDisableScroll;
