import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledList = styled.ul`
  font-family: ${FONTS.secondary};
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  list-style: none;
`;

export const StyledListItem = styled.li`
  &:not(:last-of-type) {
    padding-bottom: 16px;
  }
`;

export const StyledMoveWarning = styled.li`
  border-radius: 20px;
  border: 1px solid ${COLORS.white[30]};
  font: 400 14px/140% ${FONTS.secondary};
  margin-top: 16px;
  padding: 16px;
`;
