import React, { FC } from 'react';

import Avatars from 'components/UI/Avatars/Avatars';
import UILink from 'components/UI/Link';

import { ROUTE_CARD_DETAIL } from 'routes';
import { COLORS } from 'styles/constants';
import { CardSchema, MessageSchema } from 'constants/graphqlTypes';
import { TEST_CARD_COMMON, getCardSourceLabelId } from 'constants/aqa/card';
import getAuthUser from 'helpers/getAuthUser';
import { getUserIdFromMessage } from 'graphQL/messages/helpers';
import ReactionButton from 'components/Messages/Message/Shared/ReactionButton';
import { StyledTokenWrap } from 'components/Messages/Message/styled';
import ReactionsToken from 'components/Messages/Message/ReactionsToken';
import { useMessagesChatData } from 'components/Messages/helpers/hooks';
import usePageType from 'helpers/usePageType';
import { REF_PLACEHOLDER } from 'components/Explore/Item/helpers';
import { StyledWrap, StyledFooter, StyledRelWrap } from './styled';

import Body from './Body';
import Source from './Source';

interface ICommonProps {
  card: CardSchema;
  isDark?: boolean;
  showActivity?: boolean | null;
  contentEmoji?: string;
  message?: MessageSchema;
  disableReactionCallback?: (value: boolean) => void;
  dataTestId?: string;
}

const Common: FC<ICommonProps> = ({
  card,
  isDark = true,
  showActivity,
  contentEmoji,
  message,
  disableReactionCallback,
  dataTestId,
}) => {
  const { chatId } = useMessagesChatData();
  const { id, pickedByUsers } = card;
  const { userId: currentUserId } = getAuthUser();
  const userId = getUserIdFromMessage(message);
  const isMine = userId === currentUserId;
  const { isListDetail } = usePageType();
  const isPickedByDeletedUser =
    isListDetail && pickedByUsers?.totalCount === 1 && pickedByUsers?.items?.[0]?.id === REF_PLACEHOLDER;
  const isShowActivity = showActivity || isPickedByDeletedUser;

  const color = isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  const contentIds = {
    chatId,
    messageId: message?.id || '',
  };

  return (
    <StyledWrap>
      <StyledRelWrap>
        <Body dataTestId={dataTestId} card={card} />
        {contentEmoji && <ReactionButton emojiCode={contentEmoji} />}
        <StyledTokenWrap className="token-wrap" isRight={isMine}>
          <ReactionsToken contentIds={contentIds} isDark={isDark} />
        </StyledTokenWrap>
      </StyledRelWrap>
      <StyledFooter
        color={color}
        className="card-footer"
        onTouchStart={() => disableReactionCallback?.(true)}
        onMouseDownCapture={() => disableReactionCallback?.(true)}
        onMouseUpCapture={() => disableReactionCallback?.(false)}
        onTouchEnd={() => disableReactionCallback?.(false)}
        onMouseLeave={() => disableReactionCallback?.(false)}
      >
        <Source dataTestId={getCardSourceLabelId(dataTestId ?? TEST_CARD_COMMON)} card={card} />
        {isShowActivity && (
          <UILink route={ROUTE_CARD_DETAIL} params={{ pickId: id }}>
            <Avatars limit={3} total={pickedByUsers?.totalCount ?? 0} users={pickedByUsers?.items ?? []} hideNumber />
          </UILink>
        )}
      </StyledFooter>
    </StyledWrap>
  );
};

export default Common;
