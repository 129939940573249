import React, { FC } from 'react';

import Avatar from 'components/UI/Avatar';
import UILink from 'components/UI/Link';

import { ROUTE_USER_PROFILE } from 'routes';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { DiscoverSectionSchema, EDiscoverSlotHeadTypeOptions } from 'constants/graphqlTypes';
import { StyledAnnotation, StyledTitle, StyledWrap } from './styled';

const Heading: FC<{ section: DiscoverSectionSchema }> = ({ section }) => {
  const { curator, title, headType, annotation, curatorAnnotation } = section;
  const { username: profileName, userInfo } = curator ?? {};
  const { bio } = userInfo ?? {};

  const name = generateFullName(curator);
  const isFull = headType === EDiscoverSlotHeadTypeOptions.CuratorFull;
  const showHeader = headType !== EDiscoverSlotHeadTypeOptions.Default && (!!title || !!annotation || !!curator);

  return showHeader ? (
    <StyledWrap>
      <StyledTitle>
        {isFull ? (
          <>
            Guest curator:
            <br />
            {name}
          </>
        ) : (
          <span>{title}</span>
        )}
      </StyledTitle>
      {isFull && curator && (
        <UILink route={ROUTE_USER_PROFILE} params={{ profileName }}>
          <Avatar user={curator} />
        </UILink>
      )}
      {(annotation || curatorAnnotation) && (
        <StyledAnnotation>
          {isFull && (
            <span>
              <b>{name}</b> {bio && `· ${bio}`}
              <br />
            </span>
          )}
          {isFull ? `“${curatorAnnotation}”` : <span className="with-trancate">{annotation}</span>}
        </StyledAnnotation>
      )}
    </StyledWrap>
  ) : null;
};

export default Heading;
