import { styled } from 'linaria/lib/react';

import ImportedStyledTitle from 'components/UI/ContentTitle/styled';
import { COLORS, FONTS } from 'styles/constants';
import { StyledSubtitle as ImportedStyledSubtitle } from 'components/UI/ContentSubtitle/styled';
import { BUTTON_STYLES } from 'components/UI/Button/constants';

export const StyledContentWrap = styled.div<{ gradientStart?: string; gradientEnd?: string }>`
  background: ${({ gradientStart }) => gradientStart ?? COLORS.transparent};
  display: flex;
  justify-content: center;
  position: relative;
  transition: 0.5s all ease-in-out;
  min-height: 100dvh;

  &::before {
    background: ${({ gradientEnd }) =>
      `linear-gradient(${COLORS.transparent} 5%, ${gradientEnd ?? COLORS.black[60]} 100%)`};
    content: '';
    height: 100%;
    left: 0;
    opacity: ${({ gradientStart }) => (gradientStart ? '1' : '0')};
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const StyledContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 390px;
  padding: 75px 24px 45px;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTitle = styled(ImportedStyledTitle)`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  flex-shrink: 0;
`;

export const StyledSubtitle = styled(ImportedStyledSubtitle)`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  padding-top: 8px;
  flex-shrink: 0;
`;

export const StyledInfoBlockWrap = styled.div<{ isDark: boolean; isLast?: boolean }>`
  padding: 32px 0 ${({ isLast }) => (isLast ? 64 : 16)}px;
  flex-shrink: 0;

  > p {
    font: 400 14px/140% ${FONTS.primary};
    color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    padding-bottom: 16px;
    margin: 0;
  }
`;

export const StyledInfoBlock = styled.div<{
  isDark: boolean;
  hasNoCustomBg: boolean;
  isCompleted: boolean;
  isLast?: boolean;
}>`
  align-items: center;
  background: ${({ isCompleted }) => (isCompleted ? COLORS.white[10] : COLORS.transparent)};
  border: ${({ hasNoCustomBg, isCompleted, isDark }) => {
    if (hasNoCustomBg && isCompleted && !isDark) {
      return `1px solid ${isDark ? COLORS.white[100] : COLORS.brown.dark[100]}`;
    }
    return 'none';
  }};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  padding: 24px;
  position: relative;

  > div {
    flex: 1;
    overflow: hidden;
  }

  p {
    color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    font: 400 14px/140% ${FONTS.secondary};
    margin: 0;
    padding-bottom: 4px;
    position: relative;
    word-break: break-word;
  }

  a {
    color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    font: 600 14px/140% ${FONTS.secondary};
    position: relative;
    text-decoration: underline;
  }

  .dashed-line {
    animation: rotate infinite linear 120s;
    border-radius: 10px;
    display: ${({ isCompleted }) => (isCompleted ? 'none' : 'block')};
    height: 100%;
    inset: 0px;
    position: absolute;
    width: 100%;

    rect {
      height: calc(100% - 2px);
      rx: 10;
      stroke: ${({ isDark }) => (isDark ? COLORS.white[60] : COLORS.brown.light[100])};
      width: calc(100% - 2px);
    }

    @keyframes rotate {
      100% {
        stroke-dashoffset: 1000;
      }
    }
  }

  .check-icon {
    path {
      stroke: ${({ hasNoCustomBg, isDark }) => (hasNoCustomBg && !isDark ? COLORS.brown.dark[100] : COLORS.white[100])};
    }
  }
`;

export const StyledButtonContainer = styled.div<{ isDark: boolean; hasUploadedImage: boolean }>`
  bottom: 46px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 50%;
  max-width: 390px;
  padding: 0 24px;
  position: fixed;
  transform: translateX(-50%);
  width: 100%;

  button {
    &:disabled {
      background: ${({ isDark, hasUploadedImage }) => {
        if (!hasUploadedImage) {
          return COLORS.white.off[100];
        }
        return BUTTON_STYLES.background.emphasized.disabled[isDark ? 'dark' : 'light'];
      }};
    }
    width: 100%;
  }
`;
