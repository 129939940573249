import React, { FC } from 'react';

import InteractionBlock from 'components/InteractionBlock';
import { useReactionMenu } from '../helpers/hooks';
import ReactionMenu from '../ReactionMenu';
import ReactionsModal from '../ReactionsModal';
import { StyledInteractiveListReactionMenuWrap } from './styled';

const InteractiveReactionMenu: FC<{
  entityId: string;
  children: JSX.Element;
  isReactionDisabled?: boolean;
  isCursorGrab?: boolean;
}> = ({ entityId, children, isReactionDisabled, isCursorGrab }) => {
  const {
    isReactionsModalOpen,
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  return (
    <StyledInteractiveListReactionMenuWrap ref={infoRef} isPointer={!isReactionDisabled} isCursorGrab={!!isCursorGrab}>
      <InteractionBlock
        tapCallback={() => setIsQuickReactionOpen(false)}
        longTapCallback={() => setIsQuickReactionOpen(!isReactionDisabled)}
        getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
      >
        {children}
      </InteractionBlock>
      {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && (
        <ReactionMenu
          isShown={isQuickReactionOpen}
          reactionCoordinates={reactionCoordinates}
          handleClickOutside={() => setIsQuickReactionOpen(false)}
          minusTop={minusTop}
          setIsReactionsModalOpen={setIsReactionsModalOpen}
          setIsQuickReactionOpen={setIsQuickReactionOpen}
        />
      )}
      {isReactionsModalOpen && <ReactionsModal id={entityId} handleClose={() => setIsReactionsModalOpen(false)} />}
    </StyledInteractiveListReactionMenuWrap>
  );
};

export default InteractiveReactionMenu;
