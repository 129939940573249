import { useEffect } from 'react';

const useDocumentTitle = (title?: string | null, skip?: boolean): void => {
  useEffect(() => {
    if (!skip) {
      document.title = `${title ? `${title} | ` : ''}guide:human`;
    }
  }, [title, skip]);
};

export default useDocumentTitle;
