import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from 'components/Onboarding/Layout';
import Grain from 'components/UI/Grain';
import useTheme from 'helpers/useTheme';

import { ROUTE_ONBOARDING_NOTIFICATIONS } from 'routes';
import {
  EOnboardingStepOptions,
  useGetPreferredTopicsQuery,
  useUpdateUserPreferredTagsMutation,
  GetProfileTopicsDocument,
} from 'constants/graphqlTypes';
import { useGetProfileTopics } from 'graphQL/profile/topics/hooks';
import { EQueryFetchPolicy, WEB_PREFIX } from 'constants/common';
import { useUpdateOnboardingStep, useUpdateCachedOnboardingStep } from 'graphQL/onboarding/hooks';
import { TEST_ID_USER_INFO_VIEW_START, generateTopicTitle } from 'constants/aqa/signup';
import { updateTopics } from './helpers/helpers';
import { StyledTopic, StyledWrap } from './styled';
import { COLOR_SWATCHES } from './helpers/constants';

const Topics: FC = () => {
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const { push } = useHistory();
  const [updatePreferredTags] = useUpdateUserPreferredTagsMutation({
    variables: { tagIds: selectedTopics },
    refetchQueries: [GetProfileTopicsDocument],
  });

  const isDark = useTheme();
  const { topics: profileTopics } = useGetProfileTopics(EQueryFetchPolicy.NetworkOnly);
  const { data: topicsData } = useGetPreferredTopicsQuery();
  const { items } = topicsData?.preferredTags ?? {};

  const colors = useMemo(
    () => Array.from(items ?? [], (_val, index) => COLOR_SWATCHES[index % COLOR_SWATCHES.length]),
    [items]
  );

  const updateCache = useUpdateCachedOnboardingStep(EOnboardingStepOptions.Notifications);
  const handleButton = () =>
    updatePreferredTags().then(() => {
      updateCache();
      push(ROUTE_ONBOARDING_NOTIFICATIONS);
    });
  const isButtonDisabled = selectedTopics.length < 3;

  useEffect(() => {
    if (profileTopics.length) {
      setSelectedTopics(profileTopics.map((profileTopic) => profileTopic.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileTopics]);

  useUpdateOnboardingStep(EOnboardingStepOptions.Topics);

  return (
    <Layout
      heading="Next, tell us what you're interested in"
      primaryBtnText={isButtonDisabled ? `${selectedTopics.length}/3` : 'Continue'}
      isPrimaryBtnDisabled={isButtonDisabled}
      handlePrimaryBtn={handleButton}
      blockScroll={false}
      pageTestId={TEST_ID_USER_INFO_VIEW_START}
    >
      <StyledWrap>
        {(items ?? []).map(({ id, name }, index) => (
          <li key={id}>
            <StyledTopic
              data-testid={`${WEB_PREFIX}${generateTopicTitle(name ?? '')}`}
              isDark={isDark}
              key={id}
              bgColor={colors[index]}
              isSelected={selectedTopics.includes(id)}
              onClick={() => setSelectedTopics((prev) => updateTopics(prev, id))}
            >
              {selectedTopics.includes(id) && <Grain />}
              <span>{name}</span>
            </StyledTopic>
          </li>
        ))}
      </StyledWrap>
    </Layout>
  );
};

export default Topics;
