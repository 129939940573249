import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { ECardPermissionsOptions } from 'constants/graphqlTypes';
import useNavigationUserData from 'graphQL/profile/navigation/hook';
import useOpenModal from 'helpers/useOpenModal';
import Avatar from 'components/UI/Avatar';
import usePageType from 'helpers/usePageType';
import useIsModalOpen from 'helpers/useIsModalOpen';
import handleVibrate from 'helpers/handleVibrate';
import useGuideTour from 'helpers/useGuideTour';

import { TEST_TAB_BAR_HOME_BTN, TEST_TAB_BAR_PLUS_BTN, TEST_TAB_BAR_PROFILE_BTN } from 'constants/aqa/main';
import { COLORS } from 'styles/constants';
import { transformRoute } from 'helpers/routingHelper';
import { HOME_BUTTON, PICKER_BUTTON, PROFILE_BUTTON } from 'components/GuideTour/constants';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { ROUTE_ADD_CONTENT, ROUTE_ADD_PICK, ROUTE_DISCOVERY, ROUTE_USER_PROFILE } from 'routes';

import withHapticFeedback from 'helpers/withHapticFeedback';
import { StyledList, StyledButton, StyledAvatarWrap } from './styled';
import { HOME_ICON_PATH } from '../../helpers/constants';

const MainMenu: FC = () => {
  const user = useNavigationUserData();
  const posthogCapture = usePostHogCapture();

  const { listId } = useParams<{ listId: string }>();
  const { push } = useHistory();
  const { card } = useGetDetailCard(listId);
  const { isPickedByMe, title, cardId: listCardId, id: listPickId, isPrivateList } = card ?? {};
  const { isMyProfile, isDiscovery, isExactlyProfile, isListDetail } = usePageType();
  const { username } = user ?? {};
  const { isGuideTour } = useGuideTour();

  const isModalOpen = useIsModalOpen();
  const isHidden = isModalOpen && !isGuideTour;
  const hasEditPermission = card?.permissions?.includes(ECardPermissionsOptions.EditCollectionItems);

  const openAddContent = useOpenModal(
    isPickedByMe && hasEditPermission ? ROUTE_ADD_PICK : ROUTE_ADD_CONTENT,
    false,
    isPickedByMe && hasEditPermission
      ? {
          listCardId,
          listPickId,
          listTitle: title,
          isPrivateList,
        }
      : undefined
  );
  const handleAddContent = () => {
    if (isListDetail) {
      posthogCapture(POSTHOG_EVENTS.CreatePickFromList);
    }

    handleVibrate();
    openAddContent();
  };

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

  return username ? (
    <StyledList isHidden={isHidden}>
      <li>
        <StyledButton
          data-testid={TEST_TAB_BAR_HOME_BTN}
          data-tour={HOME_BUTTON}
          onClick={withHapticFeedback(() => (isDiscovery ? scrollToTop() : push(ROUTE_DISCOVERY)))}
        >
          <svg width="24" height="24">
            <path
              d={HOME_ICON_PATH}
              fill={isDiscovery ? COLORS.white[100] : 'none'}
              stroke={COLORS.white[100]}
              strokeWidth="1.5"
            />
          </svg>
        </StyledButton>
      </li>
      <li>
        <StyledButton data-testid={TEST_TAB_BAR_PLUS_BTN} data-tour={PICKER_BUTTON} onClick={handleAddContent}>
          <PlusIcon viewBox="0 0 20 20" height="44px" width="44px" strokeWidth="0.75" />
        </StyledButton>
      </li>
      <li>
        <StyledButton
          data-testid={TEST_TAB_BAR_PROFILE_BTN}
          data-tour={PROFILE_BUTTON}
          onClick={withHapticFeedback(() =>
            isExactlyProfile && isMyProfile
              ? scrollToTop()
              : push(transformRoute(ROUTE_USER_PROFILE, { profileName: username }))
          )}
        >
          <StyledAvatarWrap hasBorder={isMyProfile}>{user && <Avatar user={user} dimension={24} />}</StyledAvatarWrap>
        </StyledButton>
      </li>
    </StyledList>
  ) : null;
};

export default MainMenu;
