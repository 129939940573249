import { EReactionTypeOptions } from 'constants/graphqlTypes';

interface IGetTitleLinesArgs {
  height: number;
  hasImage: boolean;
  hasText: boolean;
  fontSize: number;
}
export const getTitleLines = ({ height, hasImage, hasText, fontSize }: IGetTitleLinesArgs): number => {
  // Maximum content size (without title) with, and without image
  const breakpoint = hasImage ? 670 : 700;

  const lineHeight = fontSize * 1.2;

  // Calculate the number of lines needed to fit the content
  // If there is text or an image, use the calculated lines; otherwise, add 4 extra lines
  const lines = Math.ceil((height - breakpoint) / lineHeight) + (hasText || hasImage ? 0 : 4);

  return lines < 1 ? 1 : lines;
};

export const getDescLines = (height: number): number => {
  if (height < 500) {
    return 2;
  }

  return height < 600 ? 4 : 6;
};

export const getReactionType = (reaction?: string | null): EReactionTypeOptions | null => {
  switch (reaction) {
    case EReactionTypeOptions.Heart:
      return EReactionTypeOptions.Heart;
    case EReactionTypeOptions.Like:
      return EReactionTypeOptions.Like;
    default:
      return null;
  }
};
