import { styled } from 'linaria/lib/react';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledQuestionSlide = styled.div`
  .question-card {
    aspect-ratio: auto;
    height: 342px;
    min-height: 342px;

    @media screen and (min-width: ${BREAKPOINTS.xs}px) {
      height: unset;
      aspect-ratio: 1;
    }
  }
`;

export const StyledBrowseAllQuestions = styled.div<{ isDark: boolean }>`
  align-items: center;
  aspect-ratio: 1/1;
  border-radius: 10px;
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[10])};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  cursor: pointer;
  display: flex;
  font: 400 22px/130% ${FONTS.primary};
  justify-content: center;
`;
