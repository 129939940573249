import { KeyboardEvent } from 'react';
import { COLORS, FONTS } from '../../../../styles/constants';

export interface IInputStyle {
  color: string;
  fontFamily: string;
  fontSize: string;
  fontWeight: string;
  lineHeight: string;
  margin: string;
  padding: string;
  placeholderColor: string;
}

export const getInputStyle = (type?: string, isDark?: boolean): IInputStyle => {
  if (type === 'thought') {
    return {
      color: COLORS.white[100],
      fontFamily: FONTS.secondary,
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '130%',
      margin: '12px 0',
      padding: '0 17px 0 12px',
      placeholderColor: COLORS.brown.light[100],
    };
  }

  return {
    color: isDark ? COLORS.brown.light[100] : COLORS.brown.dark[100],
    fontFamily: FONTS.primary,
    fontSize: '22px',
    fontWeight: '400',
    lineHeight: '130%',
    margin: '8px 0',
    padding: '0',
    placeholderColor: isDark ? COLORS.brown.light[30] : COLORS.white.off[100],
  };
};

export const preventSecondSpace = (e: KeyboardEvent<HTMLTextAreaElement>) => {
  if (
    e.key === ' ' &&
    e.currentTarget.selectionStart === e.currentTarget.selectionEnd &&
    e.currentTarget.value[e.currentTarget.selectionStart - 1] === ' '
  ) {
    e.preventDefault();
  }
};

export const preventSpaceAtStart = (e: KeyboardEvent<HTMLTextAreaElement>) => {
  if (
    e.key === ' ' &&
    e.currentTarget.selectionStart === e.currentTarget.selectionEnd &&
    e.currentTarget.selectionStart === 0
  ) {
    e.preventDefault();
  }
};
