export const countedSizes = (
  cardHeight: number,
  ratio: {
    s: number;
    m: number;
    l: number;
  }
) =>
  Object.entries(ratio).reduce((acc: { [key: string]: number }, [key, sizeRatio]) => {
    acc[key] = Math.floor(cardHeight * sizeRatio);
    return acc;
  }, {});
