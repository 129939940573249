import React, { FC, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import swiper, { Mousewheel, Pagination } from 'swiper';
import 'swiper/swiper.min.css';

import { SWIPER_MOUSE_WHEEL_OPTIONS } from 'constants/common';

import UserCard from 'components/Card/UserCard';
import CardSlide from './CardSlide';
import ListSlide from './ListSlide';

import { StyledSlide, StyledUser } from './styled';
import { USERS } from './helpers/constants';

interface ISliderProps {
  handleInit: (ev: swiper) => void;
  handleSwipe: (index: number) => void;
  handleSwipeEnd: (index: number) => void;
}

const Slider: FC<ISliderProps> = ({ handleInit, handleSwipe, handleSwipeEnd }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isSlideHidden, setIsSlideHidden] = useState(false);

  const changeSlide = () => {
    setIsSlideHidden(true);
    setTimeout(() => {
      setSlideIndex((prev) => (prev < 2 ? prev + 1 : 0));
      setIsSlideHidden(false);
    }, 300);
  };

  useEffect(() => {
    const interval = setInterval(changeSlide, 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Swiper
      autoHeight
      onInit={handleInit}
      speed={600}
      onTransitionEnd={({ realIndex }) => handleSwipeEnd(realIndex)}
      onSlideChange={({ realIndex }) => handleSwipe(realIndex)}
      modules={[Pagination, Mousewheel]}
      pagination
      spaceBetween={24}
      mousewheel={SWIPER_MOUSE_WHEEL_OPTIONS}
    >
      <SwiperSlide>
        <StyledSlide isHidden={isSlideHidden}>
          <CardSlide index={slideIndex} />
        </StyledSlide>
      </SwiperSlide>
      <SwiperSlide>
        <StyledSlide isHidden={isSlideHidden}>
          <StyledUser>
            <UserCard item={USERS[slideIndex]} enableOldBehaviour />
          </StyledUser>
        </StyledSlide>
      </SwiperSlide>
      <SwiperSlide>
        <StyledSlide isHidden={isSlideHidden}>
          <ListSlide index={slideIndex} />
        </StyledSlide>
      </SwiperSlide>
    </Swiper>
  );
};
export default Slider;
