import { ApolloCache } from '@apollo/client';
import { GetProfileTopicsDocument, GetProfileTopicsQuery } from 'constants/graphqlTypes';

export const updateIsTopicEnabled = (
  cache: ApolloCache<undefined>,
  userId: string,
  topicId: string,
  newStatus: boolean
): void => {
  const cachedTopicsData: GetProfileTopicsQuery | null = cache.readQuery({
    query: GetProfileTopicsDocument,
    variables: { id: userId },
  });

  const cachedTopicsArray = cachedTopicsData?.allUsers?.items[0].userPreferredTags?.items;
  const updatedTopicsArray = cachedTopicsArray?.map((topic) => {
    if (topic.id !== topicId) {
      return topic;
    }
    return {
      ...topic,
      isPreferredEnable: newStatus,
    };
  });

  if (!cachedTopicsData) {
    return;
  }

  cache.writeQuery({
    query: GetProfileTopicsDocument,
    variables: { id: userId },
    data: {
      allUsers: {
        ...cachedTopicsData.allUsers,
        items: [
          {
            ...cachedTopicsData.allUsers?.items[0],
            userPreferredTags: {
              ...cachedTopicsData.allUsers?.items[0].userPreferredTags,
              items: updatedTopicsArray,
            },
          },
        ],
      },
    },
  });
};
