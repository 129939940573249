import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';

export const StyledContainer = styled.div<{ isDark: boolean }>`
  display: flex;
  padding-bottom: 16px;
  gap: 4px;
  font: 400 12px/140% ${FONTS.secondary};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  align-items: center;
`;

export const StyledLabelWrap = styled.span`
  position: relative;
  height: calc(1.3 * 12px);
`;

export const StyledAnimatedLabel = styled.label`
  max-height: calc(1.3 * 12px);
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  flex: 1;
  white-space: nowrap;

  > div {
    height: calc(1.3 * 12px);
  }
`;
