import { getListStyles } from 'components/Collection/helpers';
import { getGradient } from 'components/List/Header/helper';
import { getUserInfo } from 'components/Profile/ProfileTop/helpers';
import { getGradientImage } from 'components/Sparks/constants';

import { CardSchema, MessageType, UserObjectType, ImageSetSchema } from 'constants/graphqlTypes';
import config from 'constants/config';

import getAuthUser from 'helpers/getAuthUser';
import getImages from 'helpers/getImages';
import getStyles from 'helpers/getStyles';
import { ALLPICKS_ALLSPARKS_SHARING_BRANDED_GRADIENT, BRANDED_GRADIENTS, COLORS } from 'styles/constants';

import { IUseAllPicksCover } from 'graphQL/cards/userCards/models';
import { SHARE_TYPE_ALL_PICKS, SHARE_TYPE_ALL_SPARKS } from 'components/Navigation/RightMenu/constants';
import {
  IAllpicksShareData,
  IGetSparkImagesWithColorIds,
  ISharingStylesContent,
  ISparkImageWithColorId,
  ShareUrlData,
} from './models';

export const getShareContent = (
  contentType: string,
  user?: UserObjectType,
  thoughtCard?: CardSchema,
  pick?: CardSchema,
  list?: CardSchema,
  spark?: CardSchema | null,
  // NA - get rid of that if you don't use it for styling GL
  guestList?: CardSchema | null,
  allPicksCoverData?: IUseAllPicksCover,
  allPicksGradients?: IAllpicksShareData[],
  allSparksImages?: string[]
): ISharingStylesContent => {
  const { cardStyle, title } = thoughtCard || pick || {};
  const { urls, bgColor: listBgColor } = getListStyles(list);
  const { imageUrl: bgImage } = getImages(cardStyle, true);
  const { bgColor } = getStyles(cardStyle);

  const { avatar } = getUserInfo(user);

  const hasTextAllPicks = !!allPicksCoverData?.titles.length;

  const allpicksImgBg = allPicksCoverData?.images?.[0]?.images?.[0]?.url;
  const availableImagesForAllpicks = allPicksCoverData?.images
    ?.reduce((prev: string[], curr) => {
      const img = curr?.images?.[0]?.url;
      return img ? prev.concat(`url('${img}')`) : prev;
    }, [])
    .splice(0, 4);

  const allPicksBackgroundGradients = allPicksGradients?.map((gradient) => gradient.gradientStart);

  const sparkBg = getGradientImage(spark?.cardStyle?.bgColor?.predefinedGradientName ?? '');

  const getSourceStyles = (): string[] => {
    switch (contentType) {
      case MessageType.Pick:
      case MessageType.Thought: {
        const sourceColors = cardStyle?.colors?.sourceColor?.items.map(
          (item) => `linear-gradient(180deg, ${item.gradientStart} 0%, ${item.gradientEnd} 100%)`
        );
        return [...(bgImage ? [`url('${bgImage}')`] : []), ...(sourceColors || [])];
      }
      case MessageType.Spark:
        return [
          ...(sparkBg ? [`url('${sparkBg}')`] : []),
          ...(spark?.user && getUserInfo(spark?.user).avatar
            ? [`url('${getUserInfo(spark?.user).avatar}')`]
            : BRANDED_GRADIENTS),
        ];
      case MessageType.User: {
        const sourceColors = user?.spotlights?.map(
          (item) =>
            `linear-gradient(180deg, ${item.cardStyle?.bgColor?.gradientStart} 0%, ${item.cardStyle?.bgColor?.gradientEnd} 100%)`
        );

        return [...(avatar ? [`url('${avatar}')`] : []), ...(sourceColors || []), ...BRANDED_GRADIENTS];
      }
      case MessageType.List: {
        const listCoverImages = urls.slice(-4).map((url) => `url(${url})`);
        return [...listCoverImages, listBgColor];
      }
      case MessageType.GuestList: {
        const { imageUrl: guestListImage } = getImages(guestList?.cardStyle);
        return [...(guestListImage ? [`url('${guestListImage}')`] : [])];
      }
      case SHARE_TYPE_ALL_PICKS: {
        return [...(availableImagesForAllpicks ?? []), ALLPICKS_ALLSPARKS_SHARING_BRANDED_GRADIENT];
      }
      case SHARE_TYPE_ALL_SPARKS: {
        return [
          ...[...new Set((allSparksImages ?? []).map((sparkImage) => `url('${sparkImage}')`))],
          ALLPICKS_ALLSPARKS_SHARING_BRANDED_GRADIENT,
        ];
      }
      default: {
        return [];
      }
    }
  };

  const getBackgroundImage = (): string => {
    switch (contentType) {
      case MessageType.Pick:
      case MessageType.Thought:
        return bgImage ?? '';
      case MessageType.User:
        return avatar ?? '';
      case MessageType.Spark:
        return sparkBg;
      case MessageType.List:
        return urls.slice(-4)?.[0];
      case SHARE_TYPE_ALL_PICKS:
        return allpicksImgBg ?? '';
      case SHARE_TYPE_ALL_SPARKS:
        return allSparksImages?.[0] ?? '';
      default:
        return '';
    }
  };

  const getBgColor = (): string => {
    if (list) {
      return listBgColor;
    }
    if (hasTextAllPicks && allPicksBackgroundGradients?.length) {
      const lastAllPicksGradient = allPicksBackgroundGradients[allPicksBackgroundGradients.length - 1];
      const firstAllPicksGradient = allPicksBackgroundGradients[0];

      return `linear-gradient(180deg, ${firstAllPicksGradient} 0%, ${lastAllPicksGradient} 100%)`;
    }
    return bgColor;
  };

  return {
    bgColor: getBgColor(),
    bgImage: getBackgroundImage(),
    title: title ?? '',
    sourceStyles: getSourceStyles(),
  };
};

export const getSharingStyle = (contentType: string, background: string): 'image' | 'color' => {
  if (contentType === MessageType.Spark && background.includes('webp')) {
    return 'color';
  }

  if (background.includes('url')) {
    return 'image';
  }

  return 'color';
};

export const getSharingImageId = (background: string, images: ImageSetSchema[]): string | undefined => {
  return background.includes('url')
    ? images.find((image) => background.includes(image.images?.[0]?.url ?? ''))?.imageId
    : undefined;
};

export const getSharingUrl = (sharingData: ShareUrlData): string => {
  const { appBaseUrl } = config;
  const { type, itemId, userName, style, color, imageId, guestListId } = sharingData;
  const { firstColor: gradientStart, secondColor: gradientEnd } = getGradient(color ?? '');

  const colorParams =
    style === 'color'
      ? `&gradientStart=${encodeURIComponent(gradientStart || COLORS.green.light)}&gradientEnd=${encodeURIComponent(
          gradientEnd || COLORS.white.off[100]
        )}`
      : '';

  switch (type) {
    case MessageType.Pick:
      return `${appBaseUrl}/share/card/${itemId}?style=${style}${colorParams}`;
    case MessageType.List: {
      if (style === 'color') {
        return `${appBaseUrl}/share/list/${itemId}?style=${style}${colorParams}`;
      }
      return `${appBaseUrl}/share/list/${itemId}?style=${style}&imageId=${imageId}`;
    }
    case MessageType.Thought:
      return `${appBaseUrl}/share/thought/${itemId}?style=${style}${colorParams}&clb=${appBaseUrl}/picks/${itemId}/thoughts`;
    case MessageType.User:
      return `${appBaseUrl}/share/profile/${userName}?style=${style}${colorParams}`;
    case MessageType.GuestList:
      return `${appBaseUrl}/share/guest-lists/${itemId}?style=${style}`;
    case MessageType.GuestListSeries:
      return `${appBaseUrl}/share/guest-lists-series/${itemId}?clb=${appBaseUrl}/guest-lists-series/${guestListId}`;
    case MessageType.Spark:
      return `${appBaseUrl}/share/spark/${itemId}?style=${style}${color ? colorParams : ''}`;
    case SHARE_TYPE_ALL_PICKS:
      return `${appBaseUrl}/share/all-picks/@${userName || getAuthUser().username}?style=${style}${
        style === 'color' ? `${colorParams}` : `&imageId=${imageId}`
      }`;
    case SHARE_TYPE_ALL_SPARKS:
      return `${appBaseUrl}/share/all-sparks/@${userName || getAuthUser().username}${
        style === 'color' ? `?style=${style}${colorParams}` : `?gradientId=${imageId}`
      }`;
    default:
      return '';
  }
};

export const getSharingTitle = (type: string): string => {
  switch (type) {
    case MessageType.Pick:
      return 'Share pick';
    case MessageType.List:
      return 'Share this list';
    case SHARE_TYPE_ALL_PICKS:
      return 'Share this list';
    case MessageType.Thought:
      return 'Share this thought';
    case MessageType.User:
      return 'Share this profile';
    case MessageType.GuestList:
      return 'Share list';
    case MessageType.GuestListSeries:
      return 'Share guest list';
    case MessageType.Spark:
      return 'Share this spark';
    case SHARE_TYPE_ALL_SPARKS:
      return 'Share this spark';
    default:
      return 'Share';
  }
};

export const getSparkImagesWithColorIds = (sparks: CardSchema[]): IGetSparkImagesWithColorIds => {
  const sparksImagesAndColorIds = sparks?.reduce((prev: ISparkImageWithColorId[], curr) => {
    const currImg = getGradientImage(curr.cardStyle?.bgColor?.predefinedGradientName ?? '');
    const currColorId = curr.cardStyle?.bgColor?.colorId;
    return currImg && curr.id && currColorId ? prev.concat({ img: currImg, id: currColorId }) : prev;
  }, []);

  const images: string[] | undefined = sparksImagesAndColorIds?.map(({ img }) => img);

  return {
    images: images?.length < 4 ? [images[0]] : images.reverse(),
    sparksImagesAndColorIds,
  };
};
