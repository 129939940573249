import React, { FC, useEffect } from 'react';

import useTheme from 'helpers/useTheme';
import UILink from 'components/UI/Link';
import { TagSchema } from 'constants/graphqlTypes';
import { ROUTE_TOPIC_DETAIL } from 'routes';
import { ReactComponent as ArrowIcon } from 'assets/icons/ArrowRight.svg';

import { StyledContainer, StyledItem, StyledItemText, StyledText } from './styled';

const TopicsList: FC<{ topicListId?: string | null; tags: TagSchema[]; title?: string | null }> = ({
  topicListId,
  tags,
  title,
}) => {
  const isDark = useTheme();

  useEffect(() => {
    if (topicListId) {
      localStorage.setItem('topicListId', topicListId);
    }
  }, [topicListId]);

  return (
    <StyledContainer>
      <StyledText>{title || 'Explore recommended topics for you'}</StyledText>
      {tags.map(({ name, id }) => (
        <UILink route={ROUTE_TOPIC_DETAIL} params={{ topicId: `${id}?name=${name}` }} key={id}>
          <StyledItem isDark={isDark}>
            <StyledItemText>{name}</StyledItemText>
            <ArrowIcon />
          </StyledItem>
        </UILink>
      ))}
    </StyledContainer>
  );
};

export default TopicsList;
