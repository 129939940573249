import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

export const StyledCardWrap = styled.div<{ isRight: boolean; isFullWidth: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: ${({ isRight }) => (isRight ? 'row-reverse' : 'row')};
  gap: 12px;
  padding-top: 4px;
  margin-left: ${({ isRight, isFullWidth }) => (isRight && !isFullWidth ? 'auto' : 0)}px;
  max-width: ${({ isFullWidth }) => (isFullWidth ? 308 : 250)}px;
  position: relative;
  width: 100%;

  .card-footer {
    padding: 15px 0 0;
  }
`;

export const StyledButton = styled.button<{ isDark: boolean }>`
  align-items: center;
  background-color: ${({ isDark }) => (isDark ? COLORS.brown.dark[100] : COLORS.white.off[100])};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 44px;
  min-width: 44px;
  width: 44px;
  user-select: none;
  position: relative;

  path {
    stroke: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;
