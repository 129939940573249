import { styled } from 'linaria/react';
import { COLORS, FONTS } from '../../../styles/constants';

export const StyledWrap = styled.section`
  .swiper {
    padding: 16px 24px;
  }
`;

export const RadioButtonWrap = styled.div<{ isDark: boolean }>`
  > input {
    display: none;
  }

  > label {
    background: ${({ isDark }) => (isDark ? `${COLORS.white[10]}` : `${COLORS.white.off[100]}`)};
    color: ${({ isDark }) => (isDark ? `${COLORS.white[100]}` : `${COLORS.brown.dark[100]}`)};
  }

  > input[type='radio']:disabled + label {
    color: ${COLORS.brown.light[100]};
  }

  > input[type='radio']:checked + label {
    background-color: ${({ isDark }) => (isDark ? `${COLORS.white[100]}` : `${COLORS.brown.dark[100]}`)};
    color: ${({ isDark }) => (isDark ? `${COLORS.brown.dark[100]}` : `${COLORS.white[100]}`)};
  }
`;

export const StyledLabel = styled.label<{ isDisabled: boolean }>`
  border-radius: 22px;
  border: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  display: block;
  font: 600 14px/140% ${FONTS.secondary};
  padding: 12px 20px;
  white-space: nowrap;

  &::first-letter {
    text-transform: uppercase;
  }
`;
