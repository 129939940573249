import { useState, SetStateAction, Dispatch } from 'react';
import validUrl from 'valid-url';

import { useUploadImageMutation } from 'constants/graphqlTypes';

export const getImageMeta = (url: string, callback: (a: number, b: number) => void) => {
  const img = new Image();
  img.src = url;
  img.onload = () => {
    callback(img.width, img.height);
  };
};

export const useUploadImageFormLink = (setUrl: Dispatch<SetStateAction<string | File>>, handleClose: () => void) => {
  const [error, setError] = useState('');
  const [link, setLink] = useState('');

  const [uploadImage, { loading }] = useUploadImageMutation();

  const change = (value: string) => {
    setLink(value);
    setError('');
  };

  const upload = () => {
    if (validUrl.isUri(link)) {
      uploadImage({
        variables: {
          url: link,
        },
      })
        .then((data) => {
          setUrl(data?.data?.uploadImage?.images?.[3]?.url || '');
          handleClose();
        })
        .catch((err) => {
          setLink('');
          setError(err.message);
        });
    } else {
      setLink('');
      setError('Invalid link');
    }
  };

  return { link, error, loading, change, upload };
};
