import { styled } from 'linaria/react';

export const StyledSwiper = styled.div`
  max-width: 100%;

  .swiper {
    min-width: 100%;

    .swiper-slide {
      max-width: fit-content;
      width: fit-content;
    }

    .swiper-scrollbar.swiper-scrollbar-horizontal {
      position: relative;
    }
  }
`;
