import React, { FC, Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { XBlock, XMasonry } from 'react-xmasonry';
import { Waypoint } from 'react-waypoint';

import { useGetDetailList, useGetListItems } from 'graphQL/list/hooks';
import useTheme from 'helpers/useTheme';
import LoaderContent from 'components/UI/LoaderContent';
import ListContent from 'components/List/Content';
import Card from 'components/Card';
import LeftMenu from 'components/Navigation/LeftMenu';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { useNavBehavior } from 'components/Navigation/helpers/hooks';
import { StyledNavigation } from 'components/Navigation/styled';
import { EQueryFetchPolicy } from 'constants/common';
import { BREAKPOINTS } from 'styles/constants';
import { ENewOnboardingStepOptions } from 'constants/graphqlTypes';
import { useUpdateOnboardingStepV2 } from 'graphQL/onboarding/hooks';
import { IOnboardingListLocation } from '../constants';
import { useCardWidth, useCardsBaseSelection } from '../ListFill/hooks';
import ListFill from '../ListFill';
import Tutorial from './Tutorial';
import ListButtons from './ListButtons';
import ListBottomMenu from './ListBottomMenu';

import { StyledCardsWrapper, StyledWrapper } from './styled';
import { StyledCard } from '../ListFill/styled';

interface IListDetailProps {
  listId?: string;
  listLoading?: boolean;
}

const ListDetail: FC<IListDetailProps> = ({ listId: listIdFromState, listLoading }) => {
  const posthogCapture = usePostHogCapture();
  useUpdateOnboardingStepV2(ENewOnboardingStepOptions.ManageList);
  const { state } = useLocation<IOnboardingListLocation | undefined>();
  const { id } = state ?? {};
  const listId = listIdFromState ?? id;

  const isDark = useTheme();
  const { selectedCards, handleCardClick, resetSelectedCards } = useCardsBaseSelection(true);
  const cardWidth = useCardWidth();
  const { isHide, bgColor, fontColor } = useNavBehavior();

  const { card, loading } = useGetDetailList({
    id: listId,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    isDetail: true,
  });

  const { title, description, user } = card ?? {};
  const { totalCount, items, loadMore, loading: cardsLoading } = useGetListItems({ id: listId });
  const hint = items.length ? 'Add more, or continue when you’re ready' : 'Add a pick to continue';
  const isSingleCard = items.length === 1;
  const wasTutorialShowed = !!localStorage.getItem('wasTutorialShowed');

  const [showTutorial, setShowTutorial] = useState(!wasTutorialShowed);
  const [showHint, setShowHint] = useState(false);
  const [showPickModal, setShowPickModal] = useState(false);

  const showHintHandler = () => {
    setTimeout(() => setShowHint(true), 500);
  };

  useEffect(() => {
    setTimeout(() => !wasTutorialShowed && setShowTutorial(true), 500);
    posthogCapture(POSTHOG_EVENTS.OnboardingListCreation);
  }, []);

  const closeTutorial = () => {
    setShowTutorial(false);
    localStorage.setItem('wasTutorialShowed', 'true');
    showHintHandler();
  };

  const openFillListModal = () => {
    setShowPickModal(true);
    setShowHint(false);
  };

  const closeFillListModal = () => {
    setShowPickModal(false);
    showHintHandler();
  };

  const collectionBgColors = card?.cardStyle?.collectionBgColors;

  return (
    <StyledWrapper>
      <StyledNavigation color={fontColor} isHide={isHide} bgColor={bgColor}>
        <LeftMenu />
        {!!items.length && (
          <ListButtons
            isDark={isDark}
            selectedCards={selectedCards}
            handleCancel={resetSelectedCards}
            listId={id}
            collectionBgColors={collectionBgColors}
          />
        )}
      </StyledNavigation>
      {loading || listLoading ? (
        <LoaderContent isFullScreen isDark={isDark} />
      ) : (
        <Fragment key={items.length}>
          <ListContent title={title} totalCount={totalCount} user={user} description={description} isOnboarding />
          <StyledCardsWrapper>
            <XMasonry
              targetBlockWidth={isSingleCard ? BREAKPOINTS.s : cardWidth}
              responsive
              maxColumns={isSingleCard ? 1 : 2}
              center={false}
            >
              {items.map((item, index) => (
                <XBlock key={item.id}>
                  {!cardsLoading && index === items.length - 1 && <Waypoint onEnter={loadMore} />}
                  <StyledCard
                    onClick={() => handleCardClick(item.id)}
                    isShowCheckbox
                    showCheck={selectedCards.includes(item.id)}
                  >
                    <Card shouldDisableLongTap card={item} isDark={isDark} />
                  </StyledCard>
                </XBlock>
              ))}
            </XMasonry>
          </StyledCardsWrapper>
          {showTutorial && <Tutorial handler={closeTutorial} />}
          {!selectedCards.length && <ListBottomMenu hint={hint} showHint={showHint} handler={openFillListModal} />}
          {showPickModal && listId && <ListFill id={listId} handleClose={closeFillListModal} />}
        </Fragment>
      )}
    </StyledWrapper>
  );
};

export default ListDetail;
