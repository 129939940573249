import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { ROUTE_QUESTION_OF_THE_DAY } from 'routes';

import { StyledQuestionSlide, StyledBrowseAllQuestions } from './styled';

const BrowseAll: FC<{ isDark: boolean }> = ({ isDark }) => {
  const posthogCapture = usePostHogCapture();
  const { push } = useHistory();

  const handlerAll = () => {
    posthogCapture(POSTHOG_EVENTS.FeaturedOpenAllQuestionPage);
    posthogCapture(POSTHOG_EVENTS.SparkAction);
    push(ROUTE_QUESTION_OF_THE_DAY);
  };

  return (
    <StyledQuestionSlide>
      <StyledBrowseAllQuestions className="question-card" isDark={isDark} onClick={handlerAll}>
        Browse all questions
      </StyledBrowseAllQuestions>
    </StyledQuestionSlide>
  );
};

export default BrowseAll;
