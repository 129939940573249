import { styled } from 'linaria/react';
import { StyledNavigation } from 'components/Navigation/styled';
import { BREAKPOINTS, COLORS } from '../../styles/constants';

export const StyledFixedWrap = styled(StyledNavigation)`
  padding: 16px 24px 0;
  width: 100%;
`;

export const StyledListWrap = styled.div<{ paddingTop: number }>`
  padding-top: ${({ paddingTop }) => paddingTop}px;
`;

export const StyledSearchWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${BREAKPOINTS.s}px;
  margin: 0 auto;

  #input {
    cursor: pointer;
  }

  > div {
    flex: 1;
    padding: 16px 0;
  }
`;

export const StyledContentWrap = styled.div`
  max-height: calc(100dvh - 52px);
  display: flex;
  flex-direction: column;
`;

export const StyledUsersWrap = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 50px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledMentionSuggestionsContainer = styled.div<{ isDark: boolean }>`
  background: ${({ isDark }) => (isDark ? COLORS.black[100] : COLORS.white[100])};
  width: 100%;
  position: absolute;
  top: unset;
  bottom: calc(100% + 16px);
  left: 0;
  max-height: 218px;
  padding: 0 24px;
  margin: 0;
  border-top: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.white.off[100])};
  overflow: auto;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-height: 346px;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const Styledlist = styled.ul`
  width: 100%;
  margin: 0;
`;

export const StyledItem = styled.li`
  cursor: pointer;
  display: block;
  padding-bottom: 16px;

  &:first-child {
    padding-top: 16px;
  }
`;
