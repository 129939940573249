import { styled } from 'linaria/react';

import { BREAKPOINTS, FONTS, COLORS } from '../../../styles/constants';

export const StyledButton = styled.button<{
  isRed?: boolean;
  isDark: boolean;
  isReversed?: boolean;
  hasToggle?: boolean;
  hasDescription?: boolean;
}>`
  align-items: center;
  background-color: ${COLORS.transparent};
  border: none;
  color: ${({ isRed, isDark }) => {
    if (isRed) {
      return COLORS.red[100];
    }
    return isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  }};
  cursor: pointer;
  display: flex;
  flex-direction: ${({ isReversed }) => (isReversed ? 'row-reverse' : 'row')};
  flex-wrap: ${({ hasDescription }) => (hasDescription ? 'wrap' : 'nowrap')};
  justify-content: ${({ isReversed, hasToggle }) => (isReversed || hasToggle ? 'space-between' : 'start')};
  min-height: 77px;
  font-family: ${FONTS.primary};
  font-size: 14px;
  padding: 16px 0;
  text-align: left;
  width: 100%;

  > div:nth-child(2) {
    margin-left: auto;
  }

  svg {
    transform: rotate(${({ isReversed }) => (isReversed ? 180 : 0)}deg);
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(14px * ${BREAKPOINTS.retinaScale});
    padding: calc(16px * ${BREAKPOINTS.retinaScale}) 0;
  }
`;

export const StyledText = styled.p<{ hasPadding: boolean; hasDescription?: boolean; hasTruncate?: boolean }>`
  margin: 0;
  text-align: left;
  padding-left: ${({ hasPadding }) => (hasPadding ? 12 : 0)}px;
  padding-right: ${({ hasPadding }) => (hasPadding ? 5 : 0)}px;
  white-space: ${({ hasTruncate }) => (hasTruncate ? 'nowrap' : 'normal')};
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ hasDescription }) => (hasDescription ? 'auto' : '100%')};
`;

export const StyledDescription = styled.span`
  font: 400 12px/140% ${FONTS.secondary};
  padding: 26px 0 16px;
  width: 100%;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;
