import { styled } from 'linaria/lib/react';
import { FONTS } from 'styles/constants';
import { StyledInputContainer } from '../AddFeedName/styled';

export const StyledTopicsLabel = styled.span`
  display: block;
  padding: 24px 0 8px;
  font: 400 14px/140% ${FONTS.secondary};
`;

export const StyledTopicsList = styled.ul<{ hasPaddingBottom?: boolean }>`
  margin: 0;
  padding-bottom: ${({ hasPaddingBottom }) => (hasPaddingBottom ? '72px' : 0)};
`;

export const StyledAllTopicsWrapper = styled.div`
  padding-top: 24px;
`;

export const StyledSearchContainer = styled.div`
  padding-top: 32px;
  display: flex;

  > div {
    flex-grow: 1;
  }
`;

export const StyledContentContainer = styled(StyledInputContainer)`
  min-height: 514px;
  box-sizing: content-box;
`;

export const StyledUsersContainer = styled.div`
  padding-bottom: 96px;
  transition: padding-bottom 100ms ease-in-out;
  box-sizing: content-box;
`;
