import React, { FC } from 'react';

import useTheme from 'helpers/useTheme';
import { useHistory } from 'react-router-dom';
import { useAddToRecentSearches } from 'graphQL/search/hooks';

import { ROUTE_SEARCH_RESULT } from 'routes';
import { ESearchTypeOptions } from 'constants/graphqlTypes';
import { ESearchParams } from 'constants/common';
import { TEST_SEARCH_ALL_RESULTS_FOR_BTN } from 'constants/aqa/search';

import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { StyledContent as StyledText } from 'components/UI/Badge/styled';
import { StyledContent, StyledListItem } from '../RecentSearch/styled';
import { StyledSearchIconWrapper } from './styled';

const AllResultsFor: FC<{ text: string }> = ({ text }) => {
  const isDark = useTheme();
  const { push } = useHistory();

  const { addToRecentSearches } = useAddToRecentSearches();

  const handleClick = () => {
    addToRecentSearches(text, ESearchTypeOptions.Keyword);
    push({ pathname: ROUTE_SEARCH_RESULT, search: `?${ESearchParams.Search}=${encodeURIComponent(text)} ` });
  };

  return (
    <StyledListItem data-testid={TEST_SEARCH_ALL_RESULTS_FOR_BTN}>
      <StyledContent onClick={handleClick}>
        <StyledSearchIconWrapper isDark={isDark}>
          <SearchIcon />
        </StyledSearchIconWrapper>
        <StyledText isDark={isDark}>All results for &lsquo;{text}&rsquo;</StyledText>
      </StyledContent>
    </StyledListItem>
  );
};

export default AllResultsFor;
