import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from '../../styles/constants';

export const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: -webkit-fill-available;
`;

export const StyledContent = styled.div`
  box-sizing: border-box;
  color: ${COLORS.brown.dark[100]};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 75px 28px 40px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-width: 467px;
    align-self: center;
    height: fit-content;
  }
`;

export const StyledMainHeading = styled.h2<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 22px/130% ${FONTS.primary};
  width: 100%;
  margin: 0 0 16px;
`;

export const StyledText = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: inline-block;
  font: 400 14px/140% ${FONTS.secondary};
  margin-bottom: 25px;
`;

export const StyledBottomText = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: inline-block;
  font: 400 12px/140% ${FONTS.secondary};
  margin-bottom: 16px;
`;

export const StyledLoader = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0;
  justify-content: center;
  height: 100%;
`;

export const StyledResend = styled(StyledText)`
  text-decoration: underline;
  cursor: pointer;
`;
