import { WEB_PREFIX } from 'constants/common';

export const ONBOARDING_TOUR = 'onboarding-tour';

export const TEST_ID_ONBOARDING_TOUR_START = `${WEB_PREFIX}${ONBOARDING_TOUR}`;

export const TEST_ONBOARDING_TOUR_TITLE = `${TEST_ID_ONBOARDING_TOUR_START}_onboarding-title`;
export const TEST_ONBOARDING_TOUR_MESSAGE = `${TEST_ID_ONBOARDING_TOUR_START}_onboarding-message`;
export const TEST_ONBOARDING_TOUR_SUBMIT_BTN = `${TEST_ID_ONBOARDING_TOUR_START}_submit-button`;
export const TEST_ONBOARDING_TOUR_CANCEL_BTN = `${TEST_ID_ONBOARDING_TOUR_START}_cancel-button`;
export const TEST_ONBOARDING_TOUR_CHECKBOX_BTN = `${TEST_ID_ONBOARDING_TOUR_START}_check-box-button`;
export const TEST_ONBOARDING_TOUR_CLOSE_BTN = `${TEST_ID_ONBOARDING_TOUR_START}_close-button`;

export const TEST_ONBOARDING_TOUR_GOT_IT_BTN = `${TEST_ID_ONBOARDING_TOUR_START}_got-it-button`;
export const TEST_ONBOARDING_TOUR_ADD_PICK_TITLE = `${TEST_ID_ONBOARDING_TOUR_START}_add-pick-title`;
