import React, { FC } from 'react';

import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';
import { Scalars, UserObjectType } from 'constants/graphqlTypes';
import { getActivityTestId } from 'constants/aqa/activity';
import useTheme from 'helpers/useTheme';
import { IReplaceParams, transformRoute } from 'helpers/routingHelper';
import { getWeeklyDateFormat } from 'helpers/getDateFormat';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { StyledText, StyledLink, StyledUsername, StyledDate, StyledBadgeContainer } from './styled';

interface IContentProps {
  url: string;
  params?: IReplaceParams;
  date: Scalars['DateTime'] | null;
  username?: string;
  user?: UserObjectType | null;
  isDisabled?: boolean;
  activityTestId: string;
}

const Content: FC<IContentProps> = ({ date, children, url, params, user, isDisabled, activityTestId }) => {
  const isDark = useTheme();
  const name = generateFullName(user);

  const textTestId = getActivityTestId(activityTestId, 'text-label');
  const dateTestId = getActivityTestId(activityTestId, 'date-label');
  const usernameTestId = getActivityTestId(activityTestId, 'username-label');

  return (
    <StyledLink to={params ? transformRoute(url, params) : url} disabled={isDisabled}>
      <StyledText data-testid={textTestId} isDark={isDark}>
        <div>
          <StyledUsername data-testid={usernameTestId}>
            {!!name.length && <b>{name}</b>}
            {user?.isVerified ? (
              <StyledBadgeContainer>
                <VerificationBadge />
              </StyledBadgeContainer>
            ) : (
              <>&nbsp;</>
            )}
          </StyledUsername>
          {children}
        </div>
        <StyledDate data-testid={dateTestId} isDark={isDark}>
          {getWeeklyDateFormat(date)}
        </StyledDate>
      </StyledText>
    </StyledLink>
  );
};

export default Content;
