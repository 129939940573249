import { styled } from 'linaria/react';

import { COLORS, FONTS } from '../../../styles/constants';

export const StyledWrap = styled.div`
  margin: 16px 23px 0;
`;

export const StyledTitle = styled.p<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 14px/140% ${FONTS.primary};
  margin: 0 0 12px;
`;

export const StyledDescription = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: block;
  font: 400 14px/140% ${FONTS.secondary};
  white-space: break-spaces;
`;

export const StyledAvatarsWrap = styled.ul`
  border-bottom: 1px solid ${COLORS.white.off[100]};
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  list-style: none;
  margin: 0;
  padding: 35px 0;
`;
