import { styled } from 'linaria/react';

import { StyledMainHeading } from 'pages/CheckEmailPage/styled';
import { COLORS, FONTS } from 'styles/constants';

export const StyledBg = styled.div<{ bg: string; hasSlider: boolean }>`
  background-color: ${({ bg }) => bg};
  position: relative;
  transition: 0.6s background-color ease-in-out;

  &::before {
    bottom: 0;
    background: linear-gradient(${COLORS.transparent}, #bdadaa);
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .swiper {
    width: 100%;
    padding-top: 54px;
    opacity: ${({ hasSlider }) => (hasSlider ? 1 : 0)};
    transition: 0.2s opacity ease-in-out;

    @media (max-height: 790px) {
      padding-top: 24px;
    }

    @media (max-height: 770px) {
      padding-top: 0;
    }

    &-slide {
      min-height: 430px;
    }

    &-pagination {
      display: flex;
      gap: 10px;
      justify-content: center;
      padding-top: 84px;

      @media (max-height: 850px) {
        padding-top: 64px;
      }

      @media (max-height: 830px) {
        padding-top: 44px;
      }

      @media (max-height: 810px) {
        padding-top: 24px;
      }

      @media (max-height: 690px) {
        display: none;
      }

      &-bullet {
        background-color: ${COLORS.white[60]};
        border-radius: 50%;
        display: inline-block;
        height: 6px;
        transition: 0.2s background-color ease-in-out;
        width: 6px;

        &-active {
          background-color: ${COLORS.white[100]};
        }
      }
    }
  }
`;

export const StyledHeading = styled(StyledMainHeading)<{ hasPadding: boolean; isHidden: boolean }>`
  font-weight: normal;
  padding-top: ${({ hasPadding }) => (hasPadding ? 128 : 0)}px;
  text-align: center;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  transition: 0.2s opacity ease-in-out;

  @media (max-height: 740px) {
    margin-top: -40px;
  }
`;

export const StyledCard = styled.div`
  max-width: 242px;

  .card {
    border: 1px solid ${COLORS.white[60]};
  }
`;

export const StyledComment = styled.div`
  align-items: center;
  color: ${COLORS.white[100]};
  display: flex;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  gap: 8px;
  line-height: 140%;
  padding-top: 16px;

  div {
    min-width: 44px;
  }

  @media (max-height: 660px) {
    display: none;
  }
`;

export const StyledUser = styled.div`
  max-width: 326px;
  width: 100%;

  .card {
    background-color: ${COLORS.transparent};
    border: 1px solid ${COLORS.white[60]};
    pointer-events: none;

    span {
      color: ${COLORS.white[100]};
    }

    button {
      display: none;
    }
  }
`;

export const StyledList = styled.div`
  max-width: 270px;
  position: relative;
  width: 100%;

  #cover {
    border: 1px solid ${COLORS.white[60]};
  }
`;

export const StyledListTitle = styled.span`
  color: ${COLORS.white[100]};
  display: block;
  font-size: 22px;
  line-height: 130%;
  padding-top: 16px;
  text-align: center;
`;

export const StyledSlide = styled.div<{ isHidden: boolean }>`
  display: flex;
  justify-content: center;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  transition: 0.3s opacity ease-in-out;
  width: 100%;
`;
