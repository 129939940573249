import Image1 from 'assets/images/welcomePage/image1.webp';
import Image2 from 'assets/images/welcomePage/image2.webp';
import Image3 from 'assets/images/welcomePage/image3.webp';
import Image4 from 'assets/images/welcomePage/image4.webp';
import Image5 from 'assets/images/welcomePage/image5.webp';
import Image6 from 'assets/images/welcomePage/image6.webp';
import Image7 from 'assets/images/welcomePage/image7.webp';
import Image8 from 'assets/images/welcomePage/image8.webp';
import Image9 from 'assets/images/welcomePage/image9.webp';
import Image10 from 'assets/images/welcomePage/image10.webp';
import Image11 from 'assets/images/welcomePage/image11.webp';
import Image12 from 'assets/images/welcomePage/image12.webp';

export interface ICardMock {
  mediaTag?: string;
  customTitle: string;
  imgUrl: string;
  isVideo?: boolean;
}

export const mockedImages: string[] = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
];

// Extending the HTMLVideoElement interface to include browser-specific fullscreen methods
export interface HTMLVideoElementWithFullscreen extends HTMLVideoElement {
  mozRequestFullScreen?: () => Promise<void>;
  webkitRequestFullscreen?: () => Promise<void>;
  webkitEnterFullscreen?: () => void; // iOS Safari
}
