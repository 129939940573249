import { EMPTY_ITEMS, SHOW_FETCH_MORE_LOADER } from 'constants/common';
import {
  GetUserFollowersDocument,
  GetUserFollowersQuery,
  GetUserFollowingsDocument,
  GetUserFollowingsQuery,
  GetUserTopicsQuery,
  Maybe,
  PreviewActionUserFragmentDoc,
  UserFilter,
  UserObjectType,
} from 'constants/graphqlTypes';
import { ApolloCache } from '@apollo/client';
import { EPeopleField } from './constants';
import { IPeopleItems, IPeopleQueryParams, PeopleQueryType } from './models';

export const updatePeopleQuery = (
  field: EPeopleField,
  query: PeopleQueryType,
  newQuery?: PeopleQueryType
): PeopleQueryType => ({
  ...query,
  ...(query.allUsers && {
    allUsers: {
      ...query.allUsers,
      items: [
        {
          ...(query.allUsers.items[0] && {
            ...query.allUsers.items[0],
            [field]: {
              ...query.allUsers.items[0][field],
              items: [
                ...(query.allUsers.items[0][field]?.items ?? []),
                ...(newQuery?.allUsers?.items[0][field]?.items ?? []),
              ],
            },
          }),
        },
      ],
    },
  }),
});

export const getPeopleQueryParams = (id: string, filters?: UserFilter, feedId?: string): IPeopleQueryParams => ({
  variables: { id, filters, feedId },
  ...SHOW_FETCH_MORE_LOADER,
});

export const getPeopleItems = (field: EPeopleField, query?: PeopleQueryType): IPeopleItems =>
  query?.allUsers?.items[0]?.[field] ?? EMPTY_ITEMS;

export const getCachedUser = (cache: ApolloCache<any>, userId: string, username?: string): UserObjectType | null =>
  cache.readFragment({
    fragment: PreviewActionUserFragmentDoc,
    fragmentName: 'PreviewActionUser',
    // must add 2 fields in cache identify - otherwise bad result in readFragment
    id: cache.identify({ __typename: 'UserObjectType', id: userId, username }),
  });

export const getCachedFollowingsOrFollowers = <T extends GetUserFollowingsQuery | GetUserFollowersQuery>(
  cache: ApolloCache<GetUserFollowingsQuery | GetUserFollowersQuery>,
  userId: string,
  isFollowings: boolean
): T | null =>
  cache.readQuery({
    query: isFollowings ? GetUserFollowingsDocument : GetUserFollowersDocument,
    variables: { id: userId },
  });

export const updateCachedFollowingsWithUser = ({
  cache,
  cachedTargetQuery,
  userToAddRemove,
  followingsOwnerId,
}: {
  cache: ApolloCache<any>;
  cachedTargetQuery: GetUserFollowingsQuery | null;
  userToAddRemove: Maybe<UserObjectType>;
  followingsOwnerId?: string;
}): void => {
  const cachedFollowings = cachedTargetQuery?.allUsers?.items[0].followings;

  if (!cachedTargetQuery || !userToAddRemove || !cachedFollowings?.items || !cachedTargetQuery?.allUsers?.items?.[0]) {
    return;
  }

  const shouldAddUser = !cachedFollowings?.items.find((user) => user.id === userToAddRemove?.id);

  const newItems = shouldAddUser
    ? [...cachedFollowings?.items, userToAddRemove]
    : cachedFollowings.items.filter((el) => el.id !== userToAddRemove?.id);

  cache.writeQuery<GetUserFollowingsQuery>({
    query: GetUserFollowingsDocument,
    variables: { id: followingsOwnerId },
    data: {
      allUsers: {
        ...cachedTargetQuery.allUsers,
        items: [
          {
            ...cachedTargetQuery?.allUsers?.items?.[0],
            followings: {
              ...cachedFollowings,
              totalCount: cachedFollowings.totalCount + (shouldAddUser ? 1 : -1),
              items: newItems,
            },
          },
        ],
      },
    },
  });
};

export const updateCachedFollowersWithUser = ({
  cache,
  cachedTargetQuery,
  userToAddRemove,
  followersOwnerId,
}: {
  cache: ApolloCache<any>;
  cachedTargetQuery: GetUserFollowersQuery | null;
  userToAddRemove: Maybe<UserObjectType>;
  followersOwnerId?: string;
}): void => {
  const cachedFollowers = cachedTargetQuery?.allUsers?.items[0].followers;

  if (!cachedTargetQuery || !userToAddRemove || !cachedFollowers?.items || !cachedTargetQuery?.allUsers?.items?.[0]) {
    return;
  }

  const shouldAddUser = !cachedFollowers?.items.find((user) => user.id === userToAddRemove?.id);

  const newItems = shouldAddUser
    ? [...cachedFollowers?.items, userToAddRemove]
    : cachedFollowers.items.filter((el) => el.id !== userToAddRemove?.id);

  cache.writeQuery<GetUserFollowersQuery>({
    query: GetUserFollowersDocument,
    variables: { id: followersOwnerId },
    data: {
      allUsers: {
        ...cachedTargetQuery.allUsers,
        items: [
          {
            ...cachedTargetQuery?.allUsers?.items?.[0],
            followers: {
              ...cachedFollowers,
              totalCount: cachedFollowers.totalCount + (shouldAddUser ? 1 : -1),
              items: newItems,
            },
          },
        ],
      },
    },
  });
};

export const updateUserTopicsQuery = (
  prev: GetUserTopicsQuery,
  fetchMoreResult: GetUserTopicsQuery | undefined
): GetUserTopicsQuery => {
  const prevTags = prev.allUsers?.items[0]?.followingTags;
  const newItems = fetchMoreResult?.allUsers?.items[0].followingTags?.items ?? [];
  return prev.allUsers?.totalCount && prevTags?.items && newItems
    ? {
        ...prev,
        allUsers: {
          ...prev.allUsers,
          items: [
            {
              ...prev.allUsers.items[0],
              followingTags: {
                ...prevTags,
                items: [...prevTags.items, ...newItems],
              },
            },
          ],
        },
      }
    : prev;
};
