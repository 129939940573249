import React, { FC } from 'react';
import { StyledIcon } from './styled';

const IconCircle: FC<{
  customIconText?: string;
  hasBackground?: boolean;
  hasBorder?: boolean;
  hasStroke?: boolean;
  Icon?: FC;
  isDark?: boolean;
  isRed?: boolean;
  handleClick?: () => void;
}> = ({ customIconText, hasBackground, hasBorder, hasStroke, Icon, isDark, isRed, handleClick }) => (
  <StyledIcon
    className="icon"
    isRed={isRed}
    hasStroke={hasStroke}
    isDark={isDark}
    hasBackground={!!hasBackground}
    hasBorder={hasBorder}
    onClick={handleClick}
  >
    {Icon ? <Icon /> : customIconText}
  </StyledIcon>
);

export default IconCircle;
