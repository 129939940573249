import { useLocation, useHistory } from 'react-router-dom';

import {
  ROUTE_ONBOARDING,
  ROUTE_ONBOARDING_CONTENT_CONFIRMATION_FINISH,
  ROUTE_ONBOARDING_NETWORKING,
  ROUTE_ONBOARDING_NETWORKING_CONFIRMATION,
  ROUTE_ONBOARDING_NOTIFICATIONS,
  ROUTE_ONBOARDING_PROFILE,
  ROUTE_ONBOARDING_PROFILE_INTERESTS,
  ROUTE_ONBOARDING_PROFILE_INTRO,
  ROUTE_ONBOARDING_PROFILE_USERNAME,
  ROUTE_WELCOME,
} from 'routes';
import config from 'constants/config';
import { ONBOARDING_ROUTES_V2 } from 'components/OnboardingV2/ProfileSetup/helpers';

import { ONBOARDING_ROUTES } from './constants';

const PROFILE_SUBROUTES = [
  ROUTE_ONBOARDING_PROFILE_INTRO,
  ROUTE_ONBOARDING_PROFILE_USERNAME,
  ROUTE_ONBOARDING_PROFILE_INTERESTS,
];

export const useNavigateBackOnboarding = (): (() => void) => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const prevPath = ONBOARDING_ROUTES[ONBOARDING_ROUTES.indexOf(pathname) - 1] ?? ROUTE_ONBOARDING;
  const prevPathV2 = ONBOARDING_ROUTES_V2[ONBOARDING_ROUTES_V2.indexOf(pathname) - 1] ?? ROUTE_WELCOME;

  const getPrevPathForOnboardingV2 = () => {
    if (PROFILE_SUBROUTES.includes(pathname)) {
      return ROUTE_ONBOARDING_PROFILE;
    }

    if (pathname.includes(ROUTE_ONBOARDING_NETWORKING_CONFIRMATION)) {
      return ROUTE_ONBOARDING_NETWORKING;
    }
    if (pathname.includes(ROUTE_ONBOARDING_NOTIFICATIONS)) {
      return ROUTE_ONBOARDING_CONTENT_CONFIRMATION_FINISH;
    }

    return prevPathV2;
  };

  return () => (config.isNewOnboardingEnabled ? push(getPrevPathForOnboardingV2()) : push(prevPath));
};
