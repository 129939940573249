import React, { FC } from 'react';

import Toggle from 'components/UI/Toggle';

import { StyledThumbnail } from 'components/ActivityItem/Elements/styled';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';

import { StyledWrap, StyledButton, StyledText, StyledIconWrap } from './styled';

import IconCircle from '../IconCircle/IconCircle';
import { EToggleType } from '../Toggle/models';
import Label from '../Label';
import AvatarGH from '../AvatarGH';
import { IListItemProps } from './models';

const ListItem: FC<IListItemProps> = ({
  title,
  image,
  isEnable,
  isDark,
  bgColor,
  hasToggle,
  toggleType,
  handleClick,
  hasPrivateLabel,
  dataTestId,
  ghLogoParams,
  Icon,
}) => (
  <StyledWrap data-testid={dataTestId}>
    <StyledButton onClick={handleClick} isSelected={!hasToggle && isEnable} disabled={!hasToggle && isEnable}>
      {ghLogoParams ? (
        <AvatarGH
          dimension={ghLogoParams?.dimension}
          isDark={ghLogoParams?.isDark}
          isRounded={ghLogoParams?.isRounded}
        />
      ) : (
        <>{!Icon && <StyledThumbnail src={image} bg={bgColor} />}</>
      )}
      {Icon && (
        <StyledIconWrap className="icon">
          <Icon />
        </StyledIconWrap>
      )}
      <StyledText className="item-title" isDark={isDark}>
        {title}
      </StyledText>
      {hasToggle && (
        <>
          {toggleType === EToggleType.Default ? (
            <Toggle isDark={isDark} checked={!!isEnable} handler={handleClick} />
          ) : (
            <IconCircle
              Icon={isEnable ? CheckIcon : PlusIcon}
              isDark
              hasBorder={!isEnable}
              hasBackground={isEnable}
              hasStroke
            />
          )}
        </>
      )}
      {hasPrivateLabel && <Label title="Private" isDark={isDark} />}
    </StyledButton>
  </StyledWrap>
);

export default ListItem;
