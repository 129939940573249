import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { StyledTab, StyledTabsContainer } from 'components/TabModal/styled';
import { useGetSearchParam } from 'helpers/routingHelper';
import useSearchTotal from 'helpers/useSearchTotal';
import useTheme from 'helpers/useTheme';

import { ROUTE_SEARCH_RESULT } from 'routes';
import { ESearchCardType } from 'constants/graphqlTypes';
import { ESearchParams } from 'constants/common';
import Picks from './Picks';
import Lists from './Lists';
import People from './People';
import { searchResultTabs } from './constants';

const Result: FC = () => {
  const searchText = useGetSearchParam(ESearchParams.Search) ?? '';
  const { replace } = useHistory();
  const isDark = useTheme();
  const currentTab = useGetSearchParam(ESearchParams.Tab) ?? ESearchCardType.Card;
  useSearchTotal(searchText);

  const handleClick = (tab: ESearchCardType) => {
    if (currentTab !== tab) {
      replace({
        pathname: ROUTE_SEARCH_RESULT,
        search: `?${ESearchParams.Search}=${encodeURIComponent(searchText)}&${ESearchParams.Tab}=${tab}`,
      });
    }
  };

  return (
    <>
      <StyledTabsContainer>
        {searchResultTabs.map(({ text, dataTestId, type }) => (
          <StyledTab
            data-testid={dataTestId}
            isActive={currentTab === type}
            onClick={() => handleClick(type)}
            isDark={isDark}
          >
            {text}
          </StyledTab>
        ))}
      </StyledTabsContainer>
      {currentTab === ESearchCardType.Card && <Picks searchText={searchText} />}
      {currentTab === ESearchCardType.Collection && <Lists searchText={searchText} />}
      {currentTab === ESearchCardType.User && <People searchText={searchText} />}
    </>
  );
};

export default Result;
