import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

export const StyledWrap = styled.div<{ isMine: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: ${({ isMine }) => (isMine ? 'row' : 'row-reverse')};
  gap: 12px;
  max-width: 308px;
  position: relative;
  padding-top: 14px;
  width: 100%;
`;

export const StyledLinkContainer = styled.div<{ isDark: boolean }>`
  .card {
    border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.white.off[100])};
  }

  .footer {
    padding-bottom: 0;
  }
`;

export const StyledTitle = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown[100])};
  font-weight: 400;
  display: block;
  font-size: 14px;
  line-height: 1.2;
  padding-top: 2px;
  word-break: break-word;
`;
