import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ROUTE_BLOCKED_ACCOUNTS,
  ROUTE_DATA_PREFERENCES,
  ROUTE_DELETE_MY_ACCOUNT,
  ROUTE_MUTED_ACCOUNTS,
  ROUTE_REQUEST_YOUR_DATA,
  ROUTE_HIDDEN_WORDS,
  ROUTE_CONNECTING_WITH_OTHERS,
} from 'routes';

import useTheme from 'helpers/useTheme';

import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import { StyledList, StyledSliderWrap } from 'pages/Settings/styled';

import { StyledItem } from 'components/AddContent/Menu/styled';
import SinglePageLayout from 'components/SinglePageLayout';
import MenuButton from 'components/UI/MenuButton';
import PrivacyData from 'components/PrivacyData/PrivacyData';
import {
  TEST_ID_PRIVACY_AND_DATA_VIEW_START,
  TEST_PRIVACY_ACTION_BLOCKED_ACCOUNTS,
  TEST_PRIVACY_ACTION_CONNECTING_WITH_OTHERS,
  TEST_PRIVACY_ACTION_DATA_PREFERENCES,
  TEST_PRIVACY_ACTION_DELETE_MY_ACCOUNT,
  TEST_PRIVACY_ACTION_HIDDEN_WORDS,
  TEST_PRIVACY_ACTION_MUTED_ACCOUNTS,
  TEST_PRIVACY_ACTION_REQUEST_YOUR_DATA,
} from 'constants/aqa/settings/accountManagement';

const PrivacyAndDataMenu: FC = () => {
  const isDark = useTheme();
  const { push } = useHistory();

  return (
    <SinglePageLayout pageTestId={TEST_ID_PRIVACY_AND_DATA_VIEW_START} title="Privacy and data">
      <StyledSliderWrap>
        <PrivacyData isDark={isDark} />
      </StyledSliderWrap>
      <StyledList isDark={isDark}>
        <StyledItem>
          <MenuButton
            text="Data preferences"
            handler={() => push(ROUTE_DATA_PREFERENCES)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
            dataTestId={TEST_PRIVACY_ACTION_DATA_PREFERENCES}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Connecting with others"
            handler={() => push(ROUTE_CONNECTING_WITH_OTHERS)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
            dataTestId={TEST_PRIVACY_ACTION_CONNECTING_WITH_OTHERS}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Blocked accounts"
            handler={() => push(ROUTE_BLOCKED_ACCOUNTS)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
            dataTestId={TEST_PRIVACY_ACTION_BLOCKED_ACCOUNTS}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Muted accounts"
            handler={() => push(ROUTE_MUTED_ACCOUNTS)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
            dataTestId={TEST_PRIVACY_ACTION_MUTED_ACCOUNTS}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Hidden words"
            handler={() => push(ROUTE_HIDDEN_WORDS)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
            dataTestId={TEST_PRIVACY_ACTION_HIDDEN_WORDS}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Request your data"
            handler={() => push(ROUTE_REQUEST_YOUR_DATA)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
            dataTestId={TEST_PRIVACY_ACTION_REQUEST_YOUR_DATA}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Delete my account"
            handler={() => push(ROUTE_DELETE_MY_ACCOUNT)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
            dataTestId={TEST_PRIVACY_ACTION_DELETE_MY_ACCOUNT}
          />
        </StyledItem>
      </StyledList>
    </SinglePageLayout>
  );
};

export default PrivacyAndDataMenu;
