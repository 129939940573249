import React, { FC } from 'react';

import usePageType from 'helpers/usePageType';
import { CardSchema, ECardExtractionOptions } from 'constants/graphqlTypes';
import { getCardMediaTypeLabelId, getCardTitleId } from 'constants/aqa/card';
import useCardContext from 'helpers/useCardProvider';
import { isVideoCard } from 'helpers/isVideoCard';
import { StyledBio } from 'components/UI/Highlight/styled';

import { getBrandedIcon, getCardTagCaption } from '../helpers/helpers';
import { StyledContent, StyledTag, StyledTitleContainer, StyledTweet, StyledSaved } from './styled';

interface IContentProps {
  card: CardSchema;
  bigTitle?: boolean;
  hugeTitle?: boolean;
  hasPaddings?: boolean;
  dataTestId: string;
  isImageVertical?: boolean;
  isTitleView?: boolean;
}

const Content: FC<IContentProps> = ({
  card,
  bigTitle,
  hugeTitle,
  hasPaddings,
  dataTestId,
  isImageVertical,
  isTitleView,
}) => {
  const { mediaTags, title, extractionStatus, externalCreators, sourceName, isSavedByMe } = card;
  const tagName = mediaTags?.items[0]?.name;
  const { creatorUsername, creatorName } = externalCreators?.items?.[0] ?? {};

  const isVideo = isVideoCard(card);
  const isTweet = tagName === 'tweets';
  const Icon = getBrandedIcon(sourceName);
  const isVerticalBrandedVideoCard = isVideo && !!Icon && isImageVertical;
  const hasEllipsis = !!Icon && title && title?.length > 180 && !isTitleView;

  const tag = !tagName && extractionStatus !== ECardExtractionOptions.Complete ? 'Loading' : tagName;
  const { cardHeader } = useCardContext() ?? {};
  const { isAddThought } = usePageType();

  const currentTitle = (hasEllipsis
    ? title?.slice(0, title?.lastIndexOf(' ', 180))
    : title || 'Generating title'
  )?.concat(hasEllipsis ? ' [...]' : '');

  const editedName = sourceName === 'mastodon' ? creatorName?.split('&')[0] : creatorName;

  return (
    <StyledContent hasPaddings={hasPaddings}>
      {(cardHeader || tag || !!Icon) && (
        <StyledTag data-testid={getCardMediaTypeLabelId(dataTestId)} disableCapitalize={!!cardHeader}>
          {getCardTagCaption({ isVideo, isTweet, cardHeader, tag })}
        </StyledTag>
      )}
      {!!Icon && (
        <StyledTweet>
          <Icon viewBox="0 0 24 24" height="13" width="13" />
          {editedName}
          {creatorUsername && <StyledBio>@{creatorUsername.replace('@', '')}</StyledBio>}
        </StyledTweet>
      )}
      <StyledTitleContainer
        isBig={bigTitle && !isVerticalBrandedVideoCard}
        isHuge={hugeTitle && !isVerticalBrandedVideoCard}
      >
        <span data-testid={getCardTitleId(dataTestId)}>{currentTitle}</span>
      </StyledTitleContainer>
      {isSavedByMe && !isAddThought && <StyledSaved>Saved pick</StyledSaved>}
    </StyledContent>
  );
};

export default Content;
