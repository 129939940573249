import { useState, Dispatch, SetStateAction } from 'react';

import { EOnboardingStepOptions, useUpdateUserMutation, useUploadImageMutation } from 'constants/graphqlTypes';
import usePageType from 'helpers/usePageType';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import config from 'constants/config';

export interface IUseUploadImage {
  url: string | File;
  isAddFromLink: boolean;
  loading: boolean;
  handleDone: () => void;
  setUrl: Dispatch<SetStateAction<string | File>>;
  setIsAddFromLink: Dispatch<SetStateAction<boolean>>;
  setCropper: Dispatch<SetStateAction<Cropper | undefined>>;
}

export const useUploadImage = (handleNext?: () => void): IUseUploadImage => {
  const posthogCapture = usePostHogCapture();
  const [uploadImage, { loading }] = useUploadImageMutation();
  const [updateUser, { loading: loadingUserUpdate }] = useUpdateUserMutation();
  const { isOnboarding } = usePageType();
  const { isNewOnboardingEnabled } = config;

  const [url, setUrl] = useState<string | File>('');
  const [isAddFromLink, setIsAddFromLink] = useState(false);
  const [cropper, setCropper] = useState<Cropper>();

  const handleDone = () => {
    cropper?.getCroppedCanvas().toBlob((blob: BlobPart | null) => {
      if (blob) {
        const image = new File([blob], 'cropImg');
        uploadImage({
          variables: {
            image,
          },
        }).then(({ data }) => {
          updateUser({
            variables: {
              userStyle: {
                thumbnailImageSetId: data?.uploadImage?.imageId,
              },
              // TODO DVA remove onboarding step
              onboardingStep: isOnboarding && !isNewOnboardingEnabled ? EOnboardingStepOptions.UploadImage : undefined,
            },
          }).then(() => {
            handleNext?.();
            if (isOnboarding) {
              posthogCapture(POSTHOG_EVENTS.OnboardingProfileParametersSave, {
                screen: 'avatar',
              });
            } else {
              posthogCapture(POSTHOG_EVENTS.EditProfile);
              posthogCapture(POSTHOG_EVENTS.ChangePhoto);
            }
          });
        });
      }
    }, 'image/jpeg');
  };

  return {
    url,
    isAddFromLink,
    loading: loading || loadingUserUpdate,
    setUrl,
    handleDone,
    setIsAddFromLink,
    setCropper,
  };
};
