import React, { FC, Fragment, useMemo, useState } from 'react';
import GraphemeSplitter from 'grapheme-splitter';
import { v4 } from 'uuid';
import Truncate from 'react-truncate-markup';

import {
  CHAR_CLASSNAME,
  FILLED_LETTER_OPACITY,
  FILLED_OPACITY_DECREASE_FACTOR,
  LETTER_ANIMATION_TIME,
  LETTER_VISIBLE_ANIMATION_TIME,
  LINE_HEIGHT,
  TRUNCATE_INNER_ID,
  VISIBILITY_OPACITY_DECREASE_FACTOR,
  VISIBLE_LETTER_OPACITY,
  DATA_WORD_INDEX_ATTR,
  getTruncateWrapId,
} from './constants';
import { getFilledLineDelay, getTotalLineDelays, getVisibleLineDelay, getWordCharacterData } from './helpers';

import Ellipsis from './Ellipsis';

import { VISIBLE_LETTER, StyledAnimationChar } from '../styled';
import { StyledThoughtAnimation } from './styled';

interface IThoughtAnimationProps {
  text: string;
  hasMoreButton?: boolean;
  isVirtualAction?: boolean;
  lines?: number;
}

const ThoughtAnimation: FC<IThoughtAnimationProps> = ({ text, isVirtualAction, hasMoreButton = true, lines = 3 }) => {
  const words = text.split(' ');
  const splitter = new GraphemeSplitter();

  // Generate a unique ID for the Truncate wrap element
  // to ensure animations are exclusive to this component,
  // avoiding conflicts with virtual actions (refer to Actions.tsx).
  const [elementId] = useState(v4());

  // Post-render function for Truncate component to animate truncated text.
  const afterTruncate = () => {
    if (!isVirtualAction) {
      const wordElements = getWordCharacterData(elementId);
      const totalLineDelays = getTotalLineDelays(wordElements);
      const totalVisibleLineDelays = getTotalLineDelays(wordElements, true);

      const charCounterForLine = { line: -1, counter: 0 };
      wordElements.forEach(({ wordLine, chars }) => {
        const charsVisibleCopy = [...chars];
        const charsFilledCopy = [...chars];

        chars.forEach((char: Element, letterIndex, elements) => {
          if (char instanceof HTMLSpanElement && wordLine !== undefined) {
            if (charCounterForLine.line !== wordLine) {
              charCounterForLine.line = wordLine ?? 0;
              charCounterForLine.counter = 0;
            } else {
              charCounterForLine.counter += 1;
            }

            if (wordLine === 0) {
              // initial VISIBLE_LETTER_OPACITY is set for the first line's letters.
              char.classList.add(VISIBLE_LETTER);
            }

            const visibleLineDelay =
              getVisibleLineDelay(totalVisibleLineDelays, wordLine) +
              LETTER_VISIBLE_ANIMATION_TIME * charCounterForLine.counter;

            const filledLineDelay =
              getFilledLineDelay(totalLineDelays, wordLine) + LETTER_ANIMATION_TIME * charCounterForLine.counter;

            if (letterIndex === 0) {
              // Animates the elevation of the word.
              chars.forEach((charInWord) => {
                charInWord.animate(
                  { top: '-2px' },
                  {
                    easing: 'ease-in-out',
                    duration: elements.length * LETTER_ANIMATION_TIME,
                    fill: 'forwards',
                    delay:
                      getFilledLineDelay(totalLineDelays, wordLine) +
                      LETTER_ANIMATION_TIME * charCounterForLine.counter,
                  }
                );
              });
            }

            // Animates letters in the word so that they become visible(VISIBLE_LETTER_OPACITY).
            // The first letter is with VISIBLE_LETTER_OPACITY,
            // next letters in the current word have an exponentially decreasing opacity by 0.5 factor.
            charsVisibleCopy.forEach((visibleChar, index) => {
              if (wordLine !== 0) {
                visibleChar.animate(
                  { opacity: VISIBLE_LETTER_OPACITY * VISIBILITY_OPACITY_DECREASE_FACTOR ** index },
                  { duration: LETTER_VISIBLE_ANIMATION_TIME, fill: 'forwards', delay: visibleLineDelay }
                );
              }
            });
            // removing the first letter from the copy array so that it won't be animated again.
            // (it's already visible with full VISIBLE_LETTER_OPACITY)
            charsVisibleCopy.shift();

            // Animates letters in the word so that they become fully filled(FILLED_LETTER_OPACITY).
            // The first letter is with FILLED_LETTER_OPACITY,
            // next letters in the current word have an exponentially decreasing opacity by 0.8 factor.
            charsFilledCopy.forEach((charFilled, index) => {
              // The following check is used to prevent letters of having opacity less than VISIBLE_LETTER_OPACITY.
              if (FILLED_OPACITY_DECREASE_FACTOR ** index >= VISIBLE_LETTER_OPACITY) {
                charFilled.animate(
                  { opacity: FILLED_LETTER_OPACITY * FILLED_OPACITY_DECREASE_FACTOR ** index },
                  { duration: LETTER_ANIMATION_TIME, fill: 'forwards', delay: filledLineDelay }
                );
              }
            });
            // removing the first letter from the copy array so that it won't be animated again.
            // (it's already filled with full FILLED_LETTER_OPACITY)
            charsFilledCopy.shift();
          }
        });
      });
    }
  };

  // We need component memoization  to minimize Truncate re-renders and ensure smooth animation.
  const MemoizedThoughtAnimation = useMemo(() => {
    return (
      <StyledThoughtAnimation key={text} id={getTruncateWrapId(elementId)}>
        <Truncate
          lineHeight={LINE_HEIGHT}
          onTruncate={afterTruncate}
          lines={lines}
          ellipsis={<Ellipsis hasMoreButton={hasMoreButton} />}
        >
          <div id={TRUNCATE_INNER_ID}>
            {words.map((word, wordIndex) => (
              <Fragment key={`${word}-${v4()}`}>
                {/* Reserve space for natural word rendering. */}{' '}
                {splitter.splitGraphemes(word).map((char) => (
                  <StyledAnimationChar
                    {...{ [DATA_WORD_INDEX_ATTR]: wordIndex }}
                    className={`${CHAR_CLASSNAME}`}
                    key={`${char}-${v4()}`}
                  >
                    {char}
                  </StyledAnimationChar>
                ))}
              </Fragment>
            ))}
          </div>
        </Truncate>
      </StyledThoughtAnimation>
    );
  }, [elementId, text]);

  return MemoizedThoughtAnimation;
};

export default ThoughtAnimation;
