import React from 'react';
import { TypeAnimation } from 'react-type-animation';

import { StyledAnimationContainer, StyledTitleAnimation } from './styled';

const TitleAnimation = () => (
  <StyledAnimationContainer>
    <TypeAnimation
      wrapper="h1"
      cursor={false}
      sequence={['', 1000, 'Let humans be your guide.', 2000]}
      className={StyledTitleAnimation}
      speed={45}
    />
  </StyledAnimationContainer>
);

export default TitleAnimation;
