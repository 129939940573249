export const COLOR_SWATCHES = [
  '#CD6234',
  '#5397D7',
  '#42A635',
  '#7C80C5',
  '#9B60BF',
  '#CE7979',
  '#817E63',
  '#767676',
  '#A28933',
  '#71922B',
  '#577897',
  '#C45DC6',
  '#9D7058',
  '#586F9D',
  '#589D90',
  '#755965',
  '#838511',
  '#63835E',
  '#9E928C',
  '#8A2A2A',
];
