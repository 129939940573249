import { styled } from 'linaria/lib/react';
import { COLORS } from 'styles/constants';
import { StyledWrapper as ImportedStyledWrapper } from '../../../../ProfileSettings/EditProfile/styled';

export const StyledDeleteWrap = styled.div`
  border-top: 1px solid ${COLORS.white[30]};
`;

export const StyledWrapper = styled(ImportedStyledWrapper)`
  gap: 16px;
`;

export const StyledPicksOptionWrap = styled.div`
  padding: 16px 0;
`;
