import React, { FC } from 'react';

import useModal from 'helpers/useModal';
import {
  StyledModalWrapper,
  StyledModal,
  StyledContent,
  StyledButtons,
  StyledButton,
  StyledHead,
  StyledSubmit,
  StyledSecondButton,
  StyledTitle,
} from './styled';

interface IConfirmModalProps {
  handleCancel?: () => void;
  handleAccept?: () => void;
  handleAcceptSecondOption?: () => void;
  buttonText?: string;
  secondButtonText?: string;
  titleText?: string | null;
  subTitle?: string | null;
  handleClickOutside?: () => void;
  cancelButtonText?: string;
  isWarning?: boolean;
  isFirstLetterBig?: boolean;
  disableSubmit?: boolean;
  isColumnDirection?: boolean;
  ignoreUseModal?: boolean;
  dataTestIdConfirmBtn?: string;
  dataTestIdCancelBtn?: string;
}

const ConfirmationModal: FC<IConfirmModalProps> = ({
  titleText,
  buttonText,
  secondButtonText,
  cancelButtonText = 'cancel',
  subTitle,
  handleCancel,
  handleAccept,
  handleAcceptSecondOption,
  handleClickOutside = handleCancel,
  isWarning = true,
  isFirstLetterBig = true,
  disableSubmit,
  isColumnDirection,
  ignoreUseModal,
  dataTestIdConfirmBtn,
  dataTestIdCancelBtn,
}) => {
  const modalText = titleText ?? 'leave';

  useModal(ignoreUseModal);

  return (
    <StyledModalWrapper onMouseDown={handleClickOutside}>
      <StyledModal onMouseDown={(e) => e.stopPropagation()} onSubmit={(e) => e.preventDefault()}>
        <StyledContent>
          <StyledHead isFirstLetterBig={isFirstLetterBig}>{modalText}</StyledHead>
          <StyledTitle>{subTitle ?? `Are you sure you want to ${modalText}?`}</StyledTitle>
        </StyledContent>
        <StyledButtons isColumnDirection={isColumnDirection}>
          {handleCancel && (
            <StyledButton data-testid={dataTestIdCancelBtn} className="cancel-button" onClick={handleCancel}>
              {cancelButtonText}
            </StyledButton>
          )}
          {secondButtonText && (
            <StyledSecondButton className="second-button" isRed={isWarning} onClick={handleAcceptSecondOption}>
              {secondButtonText}
            </StyledSecondButton>
          )}
          {buttonText && (
            <StyledSubmit
              data-testid={dataTestIdConfirmBtn}
              disabled={disableSubmit}
              className="first-button"
              isRed={isWarning}
              onClick={handleAccept}
            >
              {buttonText}
            </StyledSubmit>
          )}
        </StyledButtons>
      </StyledModal>
    </StyledModalWrapper>
  );
};

export default ConfirmationModal;
