import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';

import { useAllChats } from 'graphQL/messages/hooks';
import { List } from 'components/Messages';
import { ROUTE_MESSAGES_NEW, ROUTE_MESSAGES_SEARCH } from 'routes';

import useTheme from 'helpers/useTheme';
import MessagesTabs from 'components/Messages/MessagesTabs';
import SinglePageLayout from 'components/SinglePageLayout';
import NavWrap from 'components/Messages/Navigation';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';

import { EQueryFetchPolicy } from 'constants/common';
import { useGetAllChatsCountQuery } from 'constants/graphqlTypes';
import { getMessageTab } from 'components/Messages/helpers/helpers';
import { StyledSearchWrap } from './styled';

const MessagesList: FC = () => {
  const isDark = useTheme();

  const { push } = useHistory();
  const { data: allChats, loading: allChatsCountLoading } = useGetAllChatsCountQuery({
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });
  const { items, totalCount, loading, loadMore } = useAllChats();

  return (
    <>
      <NavWrap>
        <Button text="New" handler={() => push(ROUTE_MESSAGES_NEW)} isDark={isDark} />
      </NavWrap>
      <SinglePageLayout title="Messages">
        {!!allChats?.allChats?.totalCount && !allChatsCountLoading && <MessagesTabs />}
        {totalCount > 10 && !getMessageTab() && (
          <StyledSearchWrap onClick={() => push(ROUTE_MESSAGES_SEARCH)}>
            <Input Icon={SearchIcon} placeholder="Search" isDark={isDark} text="" setText={() => {}} />
          </StyledSearchWrap>
        )}
        <List loading={loading} loadMore={loadMore} items={items} isDark={isDark} />
      </SinglePageLayout>
    </>
  );
};

export default MessagesList;
