import { Dispatch, SetStateAction } from 'react';

import { CardSchema } from 'constants/graphqlTypes';

export enum ETypeOfCard {
  IsAllOptions = 'IsAllOptions',
  IsAllPicks = 'IsAllPicks',
  IsCollabList = 'IsCollabList',
  IsCollabListWithOptions = 'IsCollabListWithOptions',
  IsPrivateList = 'IsPrivateList',
}

export interface IRemovePickProps {
  card: CardSchema;
  handleCancel: () => void;
  handleClose: () => void;
  listCardId: string;
  listCardsIds: string[];
  typeOfCard: string;
  setTypeOfCard: Dispatch<SetStateAction<string>>;
}

export interface IHandleTypeOfCardParams {
  handleRemoveFromAllPicks: () => void;
  handleRemoveFromList: (isRemoveFromAllPicks: boolean, isRemovePickFully: boolean, hasOtherPicks: boolean) => void;
  isAdmin: boolean;
  pickersCount: number;
  typeOfCard: string;
  isCardPickedByCollaborators: boolean;
  setTypeOfCard: Dispatch<SetStateAction<string>>;
  isDeletedPicker: boolean;
  hasMyPick: boolean;
}

export interface IHandleTypeOfCard {
  subTitle: string;
  title?: string;
  handleRemove: () => void;
  handleRemoveSecondOption?: () => void;
  buttonText: string;
  secondButtonText?: string;
  cancelButtonText?: string;
}
