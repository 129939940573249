import { styled } from 'linaria/react';
import { css } from 'linaria';
import { Link } from 'react-router-dom';

import { FONTS, BREAKPOINTS } from '../../../styles/constants';
import { FONT_SIZE, LINE_HEIGHT, VISIBLE_LETTER_OPACITY } from './ThoughtAnimation/constants';

export const StyledActionHeightContainer = styled.div`
  display: flex;
  gap: 0;
  max-height: 206px;
  overflow: hidden;
  position: relative;
`;

export const StyledVirtualActionContainer = styled.div`
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  width: 1px;

  > div {
    width: 396px;
  }
`;

export const StyledRealActionContainer = styled.div<{ enableConstantHeight?: boolean }>`
  left: 0;
  position: ${({ enableConstantHeight }) => (enableConstantHeight ? 'absolute' : 'static')};
  top: 0;
  width: 100%;
`;

export const StyledComment = styled.div<{
  isDetail?: boolean;
  fontColor: string;
  isSpotlight?: boolean;
  hasAnimation?: boolean;
  isQuickReactionOpen: boolean;
  withoutPadding?: boolean;
  isCardInFocus?: boolean;
  isReactionButtonMenuShown?: boolean;
  isReactionTokenModalOpen?: boolean;
}>`
  color: ${({ fontColor }) => fontColor};
  font-size: ${({ isSpotlight }) => `${isSpotlight ? 12 : 14}px`};
  height: auto;
  /* 165 px - is minimal height for avatar + reaction btn + reaction indicator */
  min-height: ${({ isDetail }) => (isDetail ? '165px' : 'auto')};
  padding: ${({ isDetail, isSpotlight, withoutPadding }) =>
    `${isDetail ? 20 : 0}px ${isSpotlight || withoutPadding ? 0 : 12}px 0`};
  user-select: none;
  width: 100%;

  & > div {
    align-items: ${({ isSpotlight }) => (isSpotlight ? 'center' : 'flex-start')};
    display: flex;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    font-size: 14px;
  }

  .circle .animate {
    animation-play-state: ${({
      isQuickReactionOpen,
      isReactionButtonMenuShown,
      isReactionTokenModalOpen,
      isCardInFocus,
    }) =>
      isQuickReactionOpen || isReactionButtonMenuShown || isReactionTokenModalOpen || !isCardInFocus
        ? 'paused'
        : 'running'};
    animation-duration: ${({ isDetail }) => (isDetail ? '24s' : '12s')};
  }
`;

export const StyledCommentInfo = styled.div<{ isSmall?: boolean }>`
  display: flex;
  flex: 1;
  font-family: ${FONTS.secondary};
  flex-direction: column;
  justify-content: center;
  line-height: 20px;
  position: relative;
  width: calc(80% - 30px);
  word-break: break-word;
  margin-right: 12px;
  min-height: ${({ isSmall }) => (isSmall ? 22 : 44)}px;

  & > div > span {
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    -webkit-touch-callout: none;
  }

  a {
    color: inherit;
  }
`;

export const StyledToggle = styled.span`
  border-bottom: 1px solid grey;
  display: inline-block;
`;

export const StyledAvatarWithReactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-right: 8px;
`;

export const APPEAR_ANIMATION = css`
  animation: 1s scaleIn ease-in-out forwards;
  animation-play-state: paused;
  visibility: hidden;
  pointer-events: none;
  scale: 0;
  opacity: 0;

  @keyframes scaleIn {
    0% {
      visibility: visible;
    }
    50% {
      opacity: 1;
    }
    100% {
      scale: 1;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      cursor: pointer;
    }
  }
`;

export const StyledReactionContainer = styled.div<{ hasLoadedReactions: boolean; isVirtualAction?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  min-height: ${({ isVirtualAction }) => (isVirtualAction ? '100px' : 'auto')};

  .${APPEAR_ANIMATION} {
    animation-play-state: ${({ hasLoadedReactions }) => (hasLoadedReactions ? 'running' : 'paused')};
  }
`;

export const StyledLink = styled(Link)<{ size: number; disabled?: boolean }>`
  align-items: center;
  align-self: flex-start;
  cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
  display: inline-flex;
  height: ${({ size }) => size}px;
  justify-content: center;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  text-decoration: none;
  position: relative;
  width: ${({ size }) => size}px;

  .circle {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-90deg);
    width: 100%;
  }

  circle {
    stroke-width: 4;
    fill-opacity: 0;
  }

  .animate {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: stroke 12s ease-out forwards;

    @keyframes stroke {
      to {
        stroke-dashoffset: 0;
      }
    }
  }
`;

export const StyledAction = styled.span`
  b {
    font-weight: 550;
  }
`;

export const StyledText = styled.p`
  margin: 0;
  white-space: pre-wrap;
  cursor: pointer;
`;

export const StyledAnimationChar = styled.span<{ reserveSpaces?: boolean }>`
  font-size: ${FONT_SIZE}px;
  font-weight: 600;
  line-height: ${LINE_HEIGHT}px;
  opacity: 0;
  white-space: ${({ reserveSpaces }) => (reserveSpaces ? 'pre' : 'normal')};
  will-change: top, opacity;
  position: relative;
  top: 0;
`;

export const VISIBLE_LETTER = css`
  opacity: ${VISIBLE_LETTER_OPACITY};
`;

export const StyledAnimationWord = styled.span<{ isUnderlined?: boolean }>`
  position: relative;
  transform: translateY(2px);
  animation-timing-function: ease-in-out;
  animation-name: elevate;
  animation-fill-mode: forwards;
  animation-play-state: paused;
  text-decoration: ${({ isUnderlined }) => (isUnderlined ? 'underline' : 'none')};
  will-change: transform;
  max-width: fit-content;
`;

export const StyledFollowingStatus = styled.span`
  cursor: initial;
  flex-shrink: 0;
  font-weight: 400;
`;
export const StyledUserBio = styled.span<{ isSmall?: boolean }>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ isSmall }) => (isSmall ? 1 : 2)};
  display: block;
  display: -webkit-box;
  font-weight: 400;
  cursor: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ isSmall }) => (isSmall ? 'nowrap' : 'normal')};
`;

export const StyledActionContent = styled.div<{ isDetail?: boolean }>`
  margin: 0;
  font: ${({ isDetail }) => (isDetail ? `400 ${FONT_SIZE}px / ${LINE_HEIGHT}px ${FONTS.secondary}` : 'unset')};
`;

export const StyledHidePaddingTop = css`
  padding-top: 0;
`;

export const StyledTruncateWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

export const StyledTruncateClickArea = styled.div`
  position: absolute;
  inset: 0;
  cursor: pointer;
  z-index: 10;
`;

export const StyledAlertWrap = styled.div`
  margin: 32px 0;
`;

export const StyledName = styled.div`
  display: flex;
  align-items: center;
`;
