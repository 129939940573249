import { styled } from 'linaria/lib/react';

export const StyledItem = styled.div`
  text-align: center;
`;

export const StyledCardsContainer = styled.div`
  animation: fade ease 400ms forwards;
  opacity: 0;
  overflow: hidden;
  padding: 0 6px;
  transform: translateY(100%);

  -webkit-mask-image: linear-gradient(to bottom, #000 0%, #000 47%, rgba(0, 0, 0, 0));
  -webkit-mask-size: 100% 94%;
  -webkit-mask-repeat: no-repeat;

  & > div {
    height: 544px;
  }

  @keyframes fade {
    from {
      opacity: 0;
      transform: translateY(100%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
