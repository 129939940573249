import { useLocation, useParams } from 'react-router-dom';

import { useGetUserId } from 'graphQL/profile/hooks';

import { ROUTE_SEARCH } from 'routes';
import usePageType from './usePageType';

export interface ILocationStateType extends Location {
  userRefId?: string;
  background?: { pathname: string };
}

const useReferrerId = (): string | undefined => {
  const { isDiscovery, isProfile } = usePageType();
  const { profileName } = useParams<{ profileName: string }>();
  const { state, pathname } = useLocation<ILocationStateType>();
  const { userId } = useGetUserId(profileName);

  const userRefId = state?.userRefId;
  const localRef = localStorage.getItem('refId') || undefined;
  const isUserRefAvailable = !isDiscovery || !ROUTE_SEARCH.includes(pathname);

  if (isProfile) {
    return userId;
  }

  return isUserRefAvailable ? userRefId || localRef : undefined;
};

export default useReferrerId;
