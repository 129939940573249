import { useEffect, useState } from 'react';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { useStorageEvent } from 'helpers/useStorage';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { GroupChatSchema, SingleChatSchema, UserShortSchema } from 'constants/graphqlTypes';
import { ROUTE_MESSAGES, ROUTE_MESSAGES_CHAT } from 'routes';
import { IUseGroupChatTitle, IUseMessagesTabs, IUseMessagesChatData } from './models';
import { MESSAGES_TAB_KEY } from './constants';
import { getMessageTab, getMoreNumber } from './helpers';

export const useSingleChatTitle = (chat: SingleChatSchema): { title: string; user?: UserShortSchema | null } => {
  const { member } = chat;

  const user = member?.user;

  const title = generateFullName(user);

  return { title, user };
};

export const useGroupChatTitle = (chat: GroupChatSchema): IUseGroupChatTitle => {
  const { title, members } = chat;

  const moreNumber = getMoreNumber(members);
  const users =
    members?.items
      ?.slice(0, 2)
      .reduce((acc: UserShortSchema[], member) => (member?.user ? acc.concat(member.user) : acc), []) ?? [];

  const membersName =
    users.reduce((acc: string, user: UserShortSchema) => {
      const name = generateFullName(user);
      return acc.length > 0 ? `${acc}, ${name}` : name;
    }, '') ?? '';

  return { title: title ?? membersName, membersName, users, moreNumber };
};

export const useMessagesTabs = (isSetter?: boolean): IUseMessagesTabs => {
  const { state } = useLocation<{ prevPath: string }>();
  const defaultTab = state?.prevPath && !state.prevPath.includes(ROUTE_MESSAGES) ? null : getMessageTab();
  const [tab, setTab] = useState(defaultTab);

  useEffect(() => {
    if (isSetter) {
      localStorage.setItem(MESSAGES_TAB_KEY, JSON.stringify(tab));
      window.dispatchEvent(new Event('storage'));
    }
  }, [tab, isSetter]);

  useStorageEvent(() => !isSetter && setTab(getMessageTab()));

  return { tab, setTab };
};

export const useMessagesChatData = (): IUseMessagesChatData => {
  const routeMatch = useRouteMatch(ROUTE_MESSAGES_CHAT);
  const isMessageChat = !!routeMatch;

  const { id } = useParams<{ id: string }>();

  return {
    isMessageChat,
    chatId: isMessageChat ? id : '',
  };
};
