import React, { FC } from 'react';

import { EButtonType } from 'components/UserList/constants';
import { TEST_SEARCH_RESULTS_PEOPLE_PAGE, TEST_SEARCH_RESULTS_PEOPLE_PAGE_EMPTY_LABEL } from 'constants/aqa/search';
import { useSearchUsers } from 'graphQL/search/hooks';

import useTheme from 'helpers/useTheme';
import UserList from 'components/UserList';

import EmptySearchMessage from './EmptySearchMessage';
import { StyledPeopleWrap } from './styled';

const People: FC<{ searchText: string }> = ({ searchText }) => {
  const isDark = useTheme();
  const { items, loading, loadMore } = useSearchUsers(searchText);

  return (
    <>
      {!loading && !items.length && (
        <EmptySearchMessage dataTestId={TEST_SEARCH_RESULTS_PEOPLE_PAGE_EMPTY_LABEL} isDark={isDark} />
      )}
      <StyledPeopleWrap>
        <UserList
          pageTestId={TEST_SEARCH_RESULTS_PEOPLE_PAGE}
          buttonType={EButtonType.None}
          isDark={isDark}
          users={items}
          loadMore={loadMore}
          loading={loading}
          hasUsername
          paddingVertical={8}
        />
      </StyledPeopleWrap>
    </>
  );
};

export default People;
