import { css } from 'linaria';

export const StyledFadeOut = css`
  animation: fadeout 200ms ease-in-out forwards;

  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
