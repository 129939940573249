import { styled } from 'linaria/react';
import { FONTS } from 'styles/constants';

export const StyledSubmit = styled.div`
  margin: 16px 0 12px;

  button {
    width: 100%;
  }
`;

export const StyledCaption = styled.p`
  margin: 0;
  font: 400 12px/140% ${FONTS.secondary};
  width: 100%;
`;
