import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useGetComments } from 'graphQL/card/comments/hooks';
import { useGetPicks } from 'graphQL/card/picks/hook';
import {
  CardCommentFilterOptionsEnum,
  CardCommentHiddenWordsFilterOptionsEnum,
  CardCommentListObjectType,
  useGetThoughtsTotalCountQuery,
} from 'constants/graphqlTypes';
import { EQueryFetchPolicy } from 'constants/common';
import { convertTypes, IThoughtItem } from './helpers';

import { IThoughthTotalCounts, IUsersActions } from './models';

interface IThoughts {
  currentTab: number;
  tabs: string[];
  items: IThoughtItem[];
  comments?: CardCommentListObjectType | null;
  isLoading: boolean;
  usersActions: IUsersActions;
  isThoughtTab: boolean;
  loadMore?: () => void;
  setCurrentTab: Dispatch<SetStateAction<number>>;
  isPickedByMe?: boolean;
  totalCount?: number;
}

export const useThoughts = (
  id: string,
  commentsCount: number,
  criterion?: CardCommentFilterOptionsEnum,
  selectByHidden?: CardCommentHiddenWordsFilterOptionsEnum
): IThoughts => {
  const [currentTab, setCurrentTab] = useState(0);

  const { comments, loading: isLoadingThoughts, loadMore: loadMoreThoughts, totalCount } = useGetComments(
    id,
    undefined,
    criterion,
    selectByHidden
  );
  const {
    items: picks,
    loading: isLoadingPicks,
    loadMore: loadMorePicks,
    totalCount: picksCount,
    isPickedByMe,
  } = useGetPicks({
    id,
  });

  const tabs: string[] = [
    `Thought${commentsCount > 1 ? 's' : ''} · ${commentsCount}`,
    `Add${picksCount > 1 ? 's' : ''} · ${picksCount}`,
  ];

  const { adds, thoughts } = convertTypes(picks, comments);

  const isThoughts = currentTab === 0;

  useEffect(() => {
    setCurrentTab(currentTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsCount, picksCount]);

  return {
    currentTab,
    tabs,
    items: isThoughts ? thoughts : adds,
    comments,
    isLoading: isLoadingThoughts || isLoadingPicks,
    loadMore: isThoughts ? loadMoreThoughts : loadMorePicks,
    setCurrentTab,
    usersActions: { commentsCount, picksCount },
    isThoughtTab: isThoughts,
    isPickedByMe,
    totalCount,
  };
};

export const useThoughtsTotalCounts = (
  pickId: string
): { thoughtsTotalCounts: IThoughthTotalCounts; loading: boolean } => {
  const { data, loading } = useGetThoughtsTotalCountQuery({
    variables: {
      id: pickId,
      followingCriterion: CardCommentFilterOptionsEnum.Following,
      highlightedCriterion: CardCommentFilterOptionsEnum.Highlighted,
    },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  return {
    thoughtsTotalCounts: {
      everyoneTotal: data?.everyoneComments?.items[0]?.comments?.totalCount ?? 0,
      followingTotal: data?.followingComments?.items[0]?.comments?.totalCount ?? 0,
      highlightsTotal: data?.highlightedComments?.items[0]?.comments?.totalCount ?? 0,
    },
    loading,
  };
};
