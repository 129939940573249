import React, { Dispatch, FC, SetStateAction } from 'react';

import { MessageSchema, MessageType } from 'constants/graphqlTypes';
import Card from './Card';
import LinkPreview from './LinkPreview';
import List from './List';
import Thought from './Thought';
import UserCard from './UserCard';
import GuestListSeries from './GuestListSeries';
import GuestList from './GuestList';
import Invite from '../Invite';
import { useMessageIds } from '../hooks';
import Spark from './Spark';

interface ISharedContent {
  isDark: boolean;
  isMine: boolean;
  messageRef: React.RefObject<HTMLDivElement>;
  message: MessageSchema;
  setIsContentDeleted: Dispatch<SetStateAction<boolean>>;
  contentReactionId?: string;
  nextMessageType?: MessageType | null;
}

const SharedContent: FC<ISharedContent> = ({
  isDark,
  isMine,
  messageRef,
  message,
  setIsContentDeleted,
  contentReactionId,
  nextMessageType,
}) => {
  const {
    pickId,
    listId,
    userId: profileUserId,
    commentId,
    commentCardId,
    linkId,
    guestListSeriesId,
    guestListId,
    invitationId,
    sparkId,
  } = useMessageIds(message);

  return (
    <>
      {pickId && !listId && !guestListId && !invitationId && !sparkId && (
        <Card
          setIsContentDeleted={setIsContentDeleted}
          id={pickId}
          isDark={isDark}
          isMine={isMine}
          messageRef={messageRef}
          contentReactionId={contentReactionId}
          message={message}
        />
      )}
      {listId && (
        <List
          message={message}
          setIsContentDeleted={setIsContentDeleted}
          id={listId}
          isDark={isDark}
          isMine={isMine}
          messageRef={messageRef}
          contentReactionId={contentReactionId}
          nextMessageType={nextMessageType}
        />
      )}
      {profileUserId && (
        <UserCard
          setIsContentDeleted={setIsContentDeleted}
          userId={profileUserId}
          isDark={isDark}
          isMine={isMine}
          messageRef={messageRef}
          message={message}
        />
      )}
      {commentCardId && commentId && (
        <Thought
          message={message}
          setIsContentDeleted={setIsContentDeleted}
          commentId={commentId}
          pickId={commentCardId}
          isDark={isDark}
          isMine={isMine}
          messageRef={messageRef}
          contentReactionId={contentReactionId}
        />
      )}
      {linkId && message.message.__typename === 'LinkPreviewMessageSchema' && (
        <LinkPreview
          isDark={isDark}
          isMine={isMine}
          messageRef={messageRef}
          message={message}
          messageLink={message.message}
        />
      )}
      {guestListSeriesId && (
        <GuestListSeries
          id={guestListSeriesId}
          setIsContentDeleted={setIsContentDeleted}
          isDark={isDark}
          isMine={isMine}
          messageRef={messageRef}
          message={message}
        />
      )}
      {guestListId && (
        <GuestList
          id={guestListId}
          isDark={isDark}
          isMine={isMine}
          message={message}
          messageRef={messageRef}
          setIsContentDeleted={setIsContentDeleted}
          nextMessageType={nextMessageType}
        />
      )}
      {invitationId && (
        <Invite
          id={invitationId}
          message={message}
          setIsContentDeleted={setIsContentDeleted}
          pickId={pickId}
          contentReactionId={contentReactionId}
          messageRef={messageRef}
          isDark={isDark}
          isMine={isMine}
          nextMessageType={nextMessageType}
        />
      )}
      {sparkId && (
        <Spark
          id={sparkId}
          message={message}
          setIsContentDeleted={setIsContentDeleted}
          contentReactionId={contentReactionId}
          messageRef={messageRef}
          isDark={isDark}
          isMine={isMine}
        />
      )}
    </>
  );
};

export default SharedContent;
