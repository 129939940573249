import React, { FC } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import DetailSlider from 'components/DetailSlider';
import { COLORS } from 'styles/constants';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { useTopicItemsList } from 'graphQL/cards/topicCards/hooks';
import { useNavigationRef } from 'components/Navigation/helpers/hooks';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_TOPIC_ITEM_DETAIL } from 'routes';
import { REF_PLACEHOLDER } from 'components/Explore/Item/helpers';

const TopicItem: FC = () => {
  const { pickId, topicId } = useParams<{ pickId: string; topicId: string }>();

  const { items, loadMore } = useTopicItemsList(topicId);

  const { card } = useGetDetailCard(pickId);
  const { state } = useLocation();
  const { replace } = useHistory();

  const handleRoute = (id: string) =>
    replace({
      pathname: transformRoute(ROUTE_TOPIC_ITEM_DETAIL, { topicId, pickId: id }),
      state: {
        ...state,
        fallbackRefId: REF_PLACEHOLDER,
      },
    });

  useNavigationRef(COLORS.white[100]);

  return items.length && card ? (
    <DetailSlider handleLoadItems={loadMore} handleRoute={handleRoute} card={card} items={items} />
  ) : null;
};

export default TopicItem;
