import React, { FC } from 'react';

import Avatar from 'components/UI/Avatar';
import AvatarPlaceholder from 'components/UI/Avatar/AvatarPlaceholder';
import { CardSchema } from 'constants/graphqlTypes';

import { StyledUsername, StyledText, StyledSparkTitle } from 'components/Sparks/Views/Card/styled';
import { StyledUserInfo, StyledSpark, StyledInfoContainer } from './styled';

const SparkContent: FC<{ spark?: CardSchema | null }> = ({ spark }) => {
  const { user, title } = spark ?? {};

  return (
    <StyledInfoContainer removePaddingLeftRight hasWhiteAvatar={false} hasPaddingBottom={false}>
      {user ? <Avatar user={user} dimension={80} /> : <AvatarPlaceholder dimension={80} />}
      <StyledUserInfo>
        <StyledUsername>{user?.userInfo?.firstName ?? user?.username}</StyledUsername>
        <StyledText>&nbsp;wonders</StyledText>
      </StyledUserInfo>
      <StyledSpark>
        <StyledSparkTitle>{title}</StyledSparkTitle>
      </StyledSpark>
    </StyledInfoContainer>
  );
};

export default SparkContent;
