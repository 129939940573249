import { ROUTE_USER_TOPICS } from 'routes';
import { UserObjectType } from 'constants/graphqlTypes';
import { transformRoute } from 'helpers/routingHelper';

import { useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import usePageType from './usePageType';

export const useOpenTopics = (): ((user: UserObjectType, isAfterFollow?: boolean) => void) => {
  const { push } = useHistory();
  const { isOnboardingNetworkingUserSuggestion } = usePageType();

  const location = useLocation<{ backgroundModal: Location<undefined> }>();
  const backgroundModal = location?.state?.backgroundModal ?? location;

  return (user: UserObjectType, isAfterFollow?: boolean) => {
    push({
      pathname: transformRoute(ROUTE_USER_TOPICS, { profileName: user.username }),
      state: {
        ...location.state,
        backgroundModal,
        isSingleModal: true,
        variable: {
          user,
          isAfterFollow,
          isOnboardingNetworkingUserSuggestion,
        },
      },
    });
  };
};
