import { useParams } from 'react-router-dom';
import {
  useGetSuggestedTopicFeedQuery,
  useGetSuggestedTopicUsersQuery,
  useGetTopicItemDetailQuery,
} from 'constants/graphqlTypes';

import { EMPTY_ITEMS, SHOW_FETCH_MORE_LOADER } from 'constants/common';
import { REF_PLACEHOLDER } from 'components/Explore/Item/helpers';

export const useGetSuggestedTopicFeed = () => {
  const { topicId } = useParams<{ topicId: string }>();

  const { data, loading, fetchMore } = useGetSuggestedTopicFeedQuery({
    skip: !topicId,
    variables: { suggestedTagId: +topicId, fallbackReferrerId: REF_PLACEHOLDER },
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items: cardItems, totalCount: cardTotalCount } = data?.suggestedTopicFeed?.cards ?? EMPTY_ITEMS;
  const { items: userItems, totalCount: userTotalCount } = data?.suggestedTopicFeed?.users ?? EMPTY_ITEMS;
  const name = data?.suggestedTopicFeed?.relatedTag?.name;

  const loadMore = () =>
    cardItems.length < cardTotalCount &&
    fetchMore({
      variables: {
        offset: cardItems.length,
      },
      updateQuery(prev, { fetchMoreResult }) {
        const { items: prevItems } = prev.suggestedTopicFeed?.cards ?? EMPTY_ITEMS;
        const { items: newItems } = fetchMoreResult?.suggestedTopicFeed?.cards ?? EMPTY_ITEMS;

        if (!newItems.length || !prev.suggestedTopicFeed?.cards || !prevItems.length) {
          return prev;
        }
        return {
          ...prev,
          suggestedTopicFeed: {
            ...prev.suggestedTopicFeed,
            cards: {
              ...prev.suggestedTopicFeed?.cards,
              items: [...prevItems, ...newItems],
            },
          },
        };
      },
    });

  return {
    id: data?.suggestedTopicFeed?.id,
    name,
    cardItems,
    cardTotalCount,
    userItems,
    userTotalCount,
    suggestedTags: data?.suggestedTopicFeed?.suggestedTags,
    totalCardsWithTag: data?.suggestedTopicFeed?.parentTag?.totalCardsWithTag,
    parentTagName: data?.suggestedTopicFeed?.parentTag?.tag?.name,
    loading,
    loadMore,
  };
};

export const useGetSuggestedTopicUsers = () => {
  const { topicId } = useParams<{ topicId: string }>();

  const { data, loading, fetchMore } = useGetSuggestedTopicUsersQuery({
    skip: !topicId,
    variables: { suggestedTagId: +topicId },
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.suggestedTopicFeed?.users ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: {
        offset: items.length,
      },
      updateQuery(prev, { fetchMoreResult }) {
        const { items: prevItems } = prev.suggestedTopicFeed?.users ?? EMPTY_ITEMS;
        const { items: newItems } = fetchMoreResult?.suggestedTopicFeed?.users ?? EMPTY_ITEMS;

        if (!newItems.length || !prev.suggestedTopicFeed?.users || !prevItems.length) {
          return prev;
        }
        return {
          ...prev,
          suggestedTopicFeed: {
            ...prev.suggestedTopicFeed,
            users: {
              ...prev.suggestedTopicFeed?.users,
              items: [...prevItems, ...newItems],
            },
          },
        };
      },
    });

  return {
    loading,
    items,
    totalCount,
    loadMore,
  };
};

export const useTopicItemsList = (topicId: string) => {
  const { data, fetchMore } = useGetTopicItemDetailQuery({
    skip: !topicId,
    variables: { suggestedTagId: +topicId },
    ...SHOW_FETCH_MORE_LOADER,
  });

  const items = data?.suggestedTopicFeed?.cards?.items ?? [];
  const totalCount = data?.suggestedTopicFeed?.cards?.totalCount ?? 0;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: {
        offset: items.length,
      },
      updateQuery(prev, { fetchMoreResult }) {
        const { items: prevItems } = prev.suggestedTopicFeed?.cards ?? EMPTY_ITEMS;
        const { items: newItems } = fetchMoreResult?.suggestedTopicFeed?.cards ?? EMPTY_ITEMS;

        if (!newItems.length || !prev.suggestedTopicFeed?.cards || !prevItems.length) {
          return prev;
        }
        return {
          ...prev,
          suggestedTopicFeed: {
            ...prev.suggestedTopicFeed,
            cards: {
              ...prev.suggestedTopicFeed?.cards,
              items: [...prevItems, ...newItems],
            },
          },
        };
      },
    });

  return { items: items.map(({ id }) => ({ id })), loadMore };
};
