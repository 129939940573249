import React, { Dispatch, FC, Fragment, SetStateAction } from 'react';
import { SwiperSlide } from 'swiper/react';

import Grain from 'components/UI/Grain';
import ScrollContainer from 'components/ScrollContainer';
import RadioButton from 'components/Explore/ExploreTabs/RadioButton';
import { ContentTutorialCategorySchema } from 'constants/graphqlTypes';

import { ReactComponent as SparkIcon } from 'assets/icons/AddSpark.svg';
import gradientImage from 'assets/images/sparks/predefined-gradient-22.webp';

import { StyledSparkPreview } from 'components/Sparks/Routes/AddResponseToSpark/AddPick/styled';
import { StyledModalHeader, StyledTags } from './styled';

interface IModalHeaderProps {
  categoryItems: ContentTutorialCategorySchema[];
  activeCategory: string | null;
  setActiveCategory: Dispatch<SetStateAction<string | null>>;
}

const ModalHeader: FC<IModalHeaderProps> = ({ categoryItems, activeCategory, setActiveCategory }) => (
  <>
    <StyledModalHeader>
      <StyledSparkPreview bgUrl={gradientImage}>
        <Grain />
        <SparkIcon />
      </StyledSparkPreview>
      <span>What’s been of interest to you this month?</span>
    </StyledModalHeader>
    <StyledTags className="tags-container">
      <ScrollContainer spaceBetween={8} customSlidesOffsetBefore={36} customSlidesOffsetAfter={36}>
        {categoryItems?.map(({ id, title }) => (
          <Fragment key={id}>
            {id && title && (
              <SwiperSlide key={id}>
                <RadioButton
                  isDark
                  text={title}
                  isChecked={activeCategory === id}
                  id={id}
                  onClick={() => setActiveCategory(id)}
                />
              </SwiperSlide>
            )}
          </Fragment>
        ))}
      </ScrollContainer>
    </StyledTags>
  </>
);

export default ModalHeader;
