import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/UI/Button/Button';
import { EUiButtonType } from 'components/UI/Button/constants';
import { ROUTE_MESSAGES } from 'routes';
import { useChatId } from 'graphQL/messages/hooks';

const ReactionViewButton: FC<{ userId?: string | null }> = ({ userId }) => {
  const { push } = useHistory();
  const { chatId, loading } = useChatId(userId);

  return chatId && !loading ? (
    <Button handler={() => push(`${ROUTE_MESSAGES}/${chatId}`)} text="View" type={EUiButtonType.Primary} />
  ) : null;
};

export default ReactionViewButton;
