import {
  ROUTE_ONBOARDING,
  ROUTE_ONBOARDING_CONTENT_LIST_CREATION,
  ROUTE_ONBOARDING_CONTENT_LIST_DETAIL,
  ROUTE_ONBOARDING_FULLNAME,
  ROUTE_ONBOARDING_NETWORKING,
  ROUTE_ONBOARDING_NOTIFICATIONS,
  ROUTE_ONBOARDING_PREVIEW,
  ROUTE_ONBOARDING_PROFILE,
  ROUTE_ONBOARDING_PROFILE_INTERESTS,
  ROUTE_ONBOARDING_PROFILE_INTRO,
  ROUTE_ONBOARDING_WELCOME,
} from 'routes';
import { ENewOnboardingStepOptions, TagSchema } from 'constants/graphqlTypes';
import config from 'constants/config';

import reduceItems from 'helpers/reduceItems';
import { IParams } from './models';

export const getInfoBlocks = (bio?: string, interests?: string): IParams[] => [
  {
    title: 'Intro',
    subtitle: bio || 'Briefly introduce your expertise or how you want to identify.',
    linkText: bio ? 'Change' : 'Add intro',
    route: ROUTE_ONBOARDING_PROFILE_INTRO,
    isCompleted: !!bio,
  },
  {
    title: 'What are you into?',
    subtitle: interests || 'Find people sharing picks on topics you’re interested in. Your interests remain private.',
    linkText: interests ? 'Change' : 'Add interests',
    route: ROUTE_ONBOARDING_PROFILE_INTERESTS,
    isCompleted: !!interests,
  },
];

export const getInterests = (tags: TagSchema[]): string | undefined => {
  if (!tags?.length) {
    return undefined;
  }

  const reducedNames = reduceItems(tags).slice(0, 5);

  const tagsNames = reducedNames.reduce((prev: string, curr, index) => {
    const name = curr?.name ?? '';
    const prefix = index === reducedNames.length - 1 ? ' & ' : '';
    const postfix = index < reducedNames.length - 2 ? ', ' : '';

    if (tags.length <= 5) {
      return name ? prev.concat(`${prefix}${name}${postfix}`) : prev;
    }

    return name ? prev.concat(`${name}${index === reducedNames.length - 1 ? ' & more' : ', '} `) : prev;
  }, '');

  return tagsNames;
};

const {
  WelcomeScreen,
  FullName,
  Profile,
  Notifications,
  Networking,
  CreateList,
  ManageList,
  QuickSaving,
} = ENewOnboardingStepOptions;

const { areInviteCodesDisabled } = config;
const OnboardingRoutesWithSteps = {
  [WelcomeScreen]: areInviteCodesDisabled ? ROUTE_ONBOARDING : ROUTE_ONBOARDING_WELCOME,
  [FullName]: ROUTE_ONBOARDING_FULLNAME,
  [Profile]: ROUTE_ONBOARDING_PROFILE,
  [CreateList]: ROUTE_ONBOARDING_CONTENT_LIST_CREATION,
  [ManageList]: ROUTE_ONBOARDING_CONTENT_LIST_DETAIL,
  [Notifications]: ROUTE_ONBOARDING_NOTIFICATIONS,
  [Networking]: ROUTE_ONBOARDING_NETWORKING,
  // not used step - should redirect to the next step in chronology.
  [QuickSaving]: ROUTE_ONBOARDING_NOTIFICATIONS,
};

export const getNewOnboardingRedirectPath = (step: ENewOnboardingStepOptions): string =>
  OnboardingRoutesWithSteps[step];

export const ONBOARDING_ROUTES_V2 = [
  ROUTE_ONBOARDING_PREVIEW,
  ROUTE_ONBOARDING_FULLNAME,
  ROUTE_ONBOARDING_PROFILE,
  ROUTE_ONBOARDING_CONTENT_LIST_CREATION,
  ROUTE_ONBOARDING_CONTENT_LIST_DETAIL,
  ROUTE_ONBOARDING_NOTIFICATIONS,
  ROUTE_ONBOARDING_NETWORKING,
];
