import React, { FC } from 'react';

import { ROUTE_CARD_DETAIL, ROUTE_LIST_DETAIL } from 'routes';
import { ActivityCollectionAddObjectType, ActivityCollectionRemovalObjectType, Scalars } from 'constants/graphqlTypes';
import { getActivityTestId } from 'constants/aqa/activity';

import ListPreview from './Elements/ListPreview';
import UserPreview from './Elements/UserPreview';
import PickPreview from './Elements/PickPreview';
import Content from './Elements/Content';

interface IAddProps {
  item: ActivityCollectionAddObjectType | ActivityCollectionRemovalObjectType;
  date: Scalars['DateTime'] | null;
  count?: number | null;
  isRemoval?: boolean;
  activityTestId: string;
}

const Add: FC<IAddProps> = ({ item, date, count, isRemoval, activityTestId }) => {
  const { collection, user, card } = item;
  const { id: pickId, title } = card ?? {};
  const { id: listId, title: listTitle } = collection ?? {};

  const isMultiple = count && count > 1;

  const avatarTestId = getActivityTestId(activityTestId, 'avatar');

  return (
    <>
      {user && <UserPreview dataTestId={avatarTestId} user={user} />}
      <Content
        activityTestId={activityTestId}
        url={isMultiple ? ROUTE_LIST_DETAIL : ROUTE_CARD_DETAIL}
        params={{ pickId, listId }}
        date={date}
        user={user}
      >
        {isRemoval ? (
          <>removed your pick from&nbsp;</>
        ) : (
          <>
            added <b>{isMultiple ? `${count} picks` : title}</b> to&nbsp;
          </>
        )}
        <b>{listTitle}</b>
      </Content>
      {isMultiple && collection && <ListPreview activityTestId={activityTestId} list={collection} />}
      {!isMultiple && card && <PickPreview activityTestId={activityTestId} pick={card} />}
    </>
  );
};

export default Add;
