import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { CardSchema, MessageType, UserObjectType } from 'constants/graphqlTypes';
import { ReactComponent as ShareIcon } from 'assets/icons/InviteToGH.svg';
import Avatars from 'components/UI/Avatars';
import { ROUTE_OFF_PLATFORM_SHARING } from 'routes';
import { ESearchParams } from 'constants/common';

import { StyledAvatars, StyledDot, StyledFooter, StyledFooterInfo, StyledLabel, StyledShare } from './styled';

interface IFooterProps {
  item: CardSchema;
}

const Footer: FC<IFooterProps> = ({ item }) => {
  const { isPrivateList, creators, isCollaborative, id, hasNewCollaboratorsCards } = item;
  const filteredCreators =
    creators?.items.reduce((acc: UserObjectType[], creator) => (creator?.user ? acc.concat(creator.user) : acc), []) ??
    [];

  const { push } = useHistory();
  const handleShareContent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    push({
      pathname: ROUTE_OFF_PLATFORM_SHARING,
      search: `?${ESearchParams.Type}=${MessageType.List}&${ESearchParams.Id}=${id}`,
    });
  };

  return (
    <StyledFooter>
      <StyledFooterInfo>
        {!!isPrivateList && <StyledLabel>Private</StyledLabel>}
        {!!isCollaborative && (
          <>
            <StyledAvatars>
              <Avatars users={filteredCreators} limit={3} hideNumber />
            </StyledAvatars>
            {!!hasNewCollaboratorsCards && <StyledDot />}
            {creators?.totalCount} collaborators
          </>
        )}
      </StyledFooterInfo>
      {!isPrivateList && (
        <StyledShare aria-label="Share list" onClick={handleShareContent}>
          <ShareIcon width={24} height={24} viewBox="0 0 20 20" />
        </StyledShare>
      )}
    </StyledFooter>
  );
};

export default Footer;
