import React, { FC } from 'react';

import StyledLink from './styled';

interface IOuterLinkProps {
  text: string;
  url: string;
  isDark?: boolean;
  fontSize?: number;
}

const OuterLink: FC<IOuterLinkProps> = ({ text, url, isDark = true, fontSize = 14 }) => (
  <StyledLink target="_blank" rel="noreferrer" href={url} fontSize={fontSize} isDark={isDark}>
    {text}
  </StyledLink>
);

export default OuterLink;
