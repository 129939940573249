import { styled } from 'linaria/react';

import { StyledWrap as Wrap } from 'components/AddContent/AddToList/styled';

import { BREAKPOINTS } from 'styles/constants';
import ImportedStyledTitle from '../UI/ContentTitle/styled';

export const StyledTitleWrap = styled.div`
  padding: 32px 0 16px;
  flex-shrink: 0;
`;

export const StyledTitle = styled(ImportedStyledTitle)`
  text-align: left;
`;

export const StyledSubtitleWrap = styled.div`
  flex-shrink: 0;
  padding-bottom: 16px;
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 0 70px;
  margin: 0;
`;

export const StyledButtonWrap = styled.div<{ hasKeyboardOffset?: boolean }>`
  bottom: 45px;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
  padding: 0 24px ${({ hasKeyboardOffset }) => (hasKeyboardOffset ? 'var(--keyboard-offset)' : 0)};

  button {
    width: 342px;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0;
  }
`;

export const StyledWrap = styled(Wrap)`
  margin: 0;
  font-size: 14px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    min-height: 470px;
  }
`;
