import { styled } from 'linaria/react';

import { BREAKPOINTS } from 'styles/constants';
import { StyledItem as ImportItem } from '../styled';

export const StyledItem = styled(ImportItem)`
  padding: 0 12px 32px;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0 0 32px;
  }
`;
