import { WEB_PREFIX } from 'constants/common';

export const CARD_VIEW = 'card-view';

export const TEST_ID_CARD_VIEW_START = `${WEB_PREFIX}${CARD_VIEW}`;
export const TEST_CARD_COMMON = `${TEST_ID_CARD_VIEW_START}_common`;
export const TEST_CARD_FIRST_UP = `${TEST_ID_CARD_VIEW_START}_first-up`;
export const TEST_CARD_FULL_BLEED = `${TEST_ID_CARD_VIEW_START}_full-bleed`;
export const TEST_CARD_IMMERSIVE = `${TEST_ID_CARD_VIEW_START}_immersive`;
export const TEST_CARD_LIST = `${TEST_ID_CARD_VIEW_START}_list`;

export const getCardSourceLabelId = (dataTestId: string): string => `${dataTestId}_source-label`;
export const getCardImageId = (dataTestId: string): string => `${dataTestId}_image`;
export const getCardMediaTypeLabelId = (dataTestId: string): string => `${dataTestId}_media-type-label`;
export const getCardTitleId = (dataTestId: string): string => `${dataTestId}_title-label`;

export const TEST_CARD_COMMON_IMAGE = `${TEST_CARD_COMMON}_image`;

export const TEST_CARD_FIRST_UP_IMAGE = `${TEST_CARD_FIRST_UP}_image`;
