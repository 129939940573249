import { StyledRightArrowBtn as ImportedStyledRightArrowBtn } from 'components/SliderInteractive/styled';
import { styled } from 'linaria/lib/react';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

const MEDIUM_TABLET_BREAKPOINT = 992;

export const StyledCenterSectionMobile = styled.section`
  align-items: center;
  background: ${COLORS.white.off[100]};
  display: flex;
  flex-direction: column;
  padding: 57px 0 0;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    display: none;
  }
`;

export const StyledSectionSubheader = styled.p`
  color: ${COLORS.brown[100]};
  font: 600 14px/140% ${FONTS.secondary};
  margin: 0;
  text-align: center;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    color: ${COLORS.brown.dark[100]};
    display: block;
    font: 600 20px/130% ${FONTS.secondary};
    text-align: left;
  }
`;

export const StyledSectionHeader = styled.h2`
  color: ${COLORS.brown[100]};
  font: 400 32px/120% ${FONTS.primary};
  margin: 0;
  padding-top: 13px;
  text-align: center;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    display: none;
  }
`;

export const StyledCarouselContainer = styled.div`
  display: flex;
  gap: 15px;
  max-width: 100vw;
  padding: 40px 18px 0px;
  position: relative;
  width: 100%;

  .swiper {
    flex: 1;
  }
`;

export const StyledLeftArrowBtn = styled(ImportedStyledRightArrowBtn)`
  height: 24px;
  margin-top: 17.3%;
  width: 24px;

  &:hover {
    background: ${COLORS.transparent};
  }
`;

export const StyledRightArrowBtn = styled(StyledLeftArrowBtn)`
  svg {
    transform: rotate(180deg);
  }
`;

export const StyledCarouselSlide = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  img {
    width: 100%;
  }
`;

export const StyledSlideTitle = styled.h3`
  color: ${COLORS.brown[100]};
  font: 400 22px/130% ${FONTS.primary};
  margin: 0;
  padding-top: 40px;
  text-align: center;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    font: 400 32px/120% ${FONTS.primary};
    padding: 0;
    text-align: left;
  }
`;

export const StyledSlideSubTitle = styled.p`
  color: ${COLORS.brown[100]};
  font: 400 14px/140% ${FONTS.secondary};
  margin: 0;
  padding: 10px 0 49px;
  text-align: center;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    text-align: left;
  }

  @media screen and (min-width: ${MEDIUM_TABLET_BREAKPOINT}px) {
    padding: 10px 0 0;
    text-align: left;
  }
`;

export const StyledCenterSectionBig = styled.section`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    background: ${COLORS.white.off[100]};
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
    padding: 50px 56px 50px 50px;
    width: 100%;
  }
`;
export const StyledImageContainer = styled.div`
  flex: 2.6 2.6;
  max-width: 527px;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    flex: 2 2;
  }

  @media screen and (min-width: ${MEDIUM_TABLET_BREAKPOINT}px) {
    flex: 2.6 2.6;
  }

  @media screen and (min-width: ${BREAKPOINTS.l}px) {
    max-width: 936px;
  }

  > img {
    width: 100%;

    @media screen and (min-width: ${BREAKPOINTS.m}px) {
      border-radius: 20px;
      height: 644px;
      object-fit: cover;
    }

    @media screen and (min-width: ${MEDIUM_TABLET_BREAKPOINT}px) {
      border-radius: 0;
      height: auto;
      object-fit: contain;
    }
  }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1;
  gap: 10px;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const StyledInfoBlock = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.2)};
  padding-top: 0;
  transition: opacity 50ms ease-in-out;

  @media screen and (min-width: ${MEDIUM_TABLET_BREAKPOINT}px) {
    margin-top: 52px;

    &:first-of-type {
      margin-top: 15px;
    }
  }
`;
