export const USERNAME_LENGTH = [4, 40];
export const EMAIL_LENGTH = [6, 72];

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,8}$/;

export const USERNAME_CHARS = ".-_:'";
export const USERNAME_CHARS_REGEX_STRING = ".\\-_:'";
export const USERNAME_ACCEPTABLE_CHARS = new RegExp(`^[a-zA-Z0-9${USERNAME_CHARS_REGEX_STRING}]*$`);
export const USERNAME_AT_LEAST_1_LETTER = /[a-zA-Z]/;
