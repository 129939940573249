import { StyledList, StyledMeatballWrap } from 'components/AddContent/AddStyle/styled';
import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-top: 40px;
`;

export const StyledCardContainer = styled.div`
  overflow: auto;
  flex-shrink: 1;
  height: 100%;
  margin: 0 auto;
  max-width: 352px;
  padding-bottom: 68px;
  width: 100%;

  a {
    pointer-events: none !important;
    display: block;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  > div {
    height: 100%;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: end;
  margin-top: auto;
  padding: 24px 0;
`;

export const StyledGradientsText = styled.span`
  font: 400 14px/140% ${FONTS.secondary};
  padding-left: 4px;
`;

export const StyledGradientsList = styled(StyledList)`
  padding: 12px 12px 0 0;
`;

export const StyledShuffleButtonWrap = styled(StyledMeatballWrap)<{ isCurrent: boolean }>`
  border-color: ${({ isCurrent }) => (isCurrent ? COLORS.white[100] : COLORS.transparent)};
  border-radius: 14px;
`;
