import { styled } from 'linaria/react';
import { COLORS, BREAKPOINTS, FONTS } from 'styles/constants';

export const StyledFooter = styled.footer`
  background: ${COLORS.brown.dark[100]};
  margin: 0;
  padding: 40px 25px 38px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 52px 33px 41px 50px;
  }
`;

export const StyledLogo = styled.img`
  height: 42px;
  width: fit-content;
`;

export const StyledMainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const StyledListsContainer = styled.div`
  font: 600 14px ${FONTS.secondary};
  display: flex;
  margin: 190px 0 78px;
  gap: 8px;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    margin: 6px 0 57px;
  }

  > div {
    flex-grow: 1;

    @media screen and (min-width: ${BREAKPOINTS.m}px) {
      width: 164px;
    }

    @media screen and (min-width: ${BREAKPOINTS.l}px) {
      width: 273px;
    }
  }

  span {
    display: block;
  }

  a {
    color: ${COLORS.white.off[100]};
    cursor: pointer;
    display: block;
    line-height: 140%;
    padding-bottom: 8px;
    text-decoration: none;
  }
`;

export const StyledListTitle = styled.span`
  color: ${COLORS.brown.light[100]};
  padding-bottom: 30px;
`;

export const StyledCopyrightText = styled.span`
  color: ${COLORS.brown.light[100]};
  font-family: ${FONTS.secondary};
  font-size: 14px;
`;
