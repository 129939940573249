import { styled } from 'linaria/react';

import { StyledContent } from 'components/Profile/ProfileContent/styled';
import { FONTS, COLORS } from 'styles/constants';

export const StyledEmptySearchMessage = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: block;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
  padding: 32px 24px 0;
`;

export const StyledListsWrap = styled(StyledContent)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 40px;

  > div:not(:last-child) {
    width: calc(50% - 6px);
    margin-bottom: 48px;
  }
`;

export const StyledPeopleWrap = styled.div`
  padding: 16px 24px;
`;
