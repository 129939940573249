import React, { FC } from 'react';

import useTheme from 'helpers/useTheme';

import { CardSchema } from 'constants/graphqlTypes';
import SliderHeader from 'components/Sliders/SliderHeader';
import GuestSlider from 'components/Sliders/GuestSlider';

interface IGuestSection {
  cards: CardSchema[];
  title: string;
  description: string;
}

const GuestSection: FC<IGuestSection> = ({ cards, title, description }) => {
  const isDark = useTheme();
  const isOneItem = cards.length === 1;

  return (
    <div>
      <SliderHeader title={title} description={description} isOneItem={isOneItem} isDark={isDark} />
      <div className="slider-container">
        <GuestSlider items={cards} isDark={isDark} />
      </div>
    </div>
  );
};

export default GuestSection;
