import { useLocation } from 'react-router-dom';
import { Location } from 'history';

import { Variable } from './useOpenModal';

const useModalNavigation = (
  pathname: string,
  isSingleModal?: boolean,
  variable?: { [key: string]: Variable },
  shouldClearSearchParams?: boolean
): {
  pathname: string;
  state: {
    variable?: { [key: string]: Variable } | undefined;
    isSingleModal?: true | undefined;
    backgroundModal: Location<undefined>;
  };
} => {
  const location = useLocation<{ backgroundModal: Location<undefined> }>();
  const backgroundModal = location?.state?.backgroundModal ?? {
    ...location,
    ...(shouldClearSearchParams && { search: null }),
  };

  return {
    pathname,
    state: {
      ...location.state,
      backgroundModal,
      ...(isSingleModal && { isSingleModal }),
      ...(variable && { variable }),
    },
  };
};

export default useModalNavigation;
