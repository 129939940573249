import React, { FC } from 'react';

import PickSlider from 'components/Sliders/PickSlider';
import UserSlider from 'components/Sliders/UserSlider';
import useTheme from 'helpers/useTheme';
import Card from 'components/Card';

import { useGetListItems } from 'graphQL/list/hooks';
import { CardSchema, CardTypeOptions, EDiscoverItemLayoutOptions } from 'constants/graphqlTypes';
import { StyledItem, StyledList } from './styled';

import ListTitle from './ListTitle';
import ListTitleWrapper from './ListTitleWrapper';

const { Collection, SuggestedUsers } = CardTypeOptions;

const IList: FC<{ list: CardSchema; layout: EDiscoverItemLayoutOptions }> = ({ list, layout }) => {
  const { id, type } = list;
  const isDark = useTheme();

  const isCarousel = layout === EDiscoverItemLayoutOptions.Carousel;
  const isSuggestedUsersCarousel = type === SuggestedUsers;

  const getLimit = () => {
    if (isSuggestedUsersCarousel) return 10;
    return isCarousel ? 20 : 3;
  };
  const limit = getLimit();
  const { items, totalCount } = useGetListItems({
    id,
    limit,
    refPlaceholder: id,
  });

  return (
    <>
      <ListTitleWrapper id={id} isDisabled={isSuggestedUsersCarousel}>
        <ListTitle list={list} type={type} totalCount={totalCount} />
      </ListTitleWrapper>
      {!isCarousel && !isSuggestedUsersCarousel ? (
        <StyledList>
          {items.map((item) => (
            <StyledItem key={item.id}>
              <Card card={item} isDark={isDark} layout={EDiscoverItemLayoutOptions.List} showActivity />
            </StyledItem>
          ))}
        </StyledList>
      ) : (
        <>
          {type === Collection && <PickSlider items={items} isDark={isDark} />}
          {isSuggestedUsersCarousel && !!items.length && <UserSlider hasAnimatedImages items={items} isDark={isDark} />}
        </>
      )}
    </>
  );
};

export default IList;
