import React, { FC } from 'react';
import { Mousewheel, Scrollbar, Swiper as SwiperRef } from 'swiper';
import { Swiper } from 'swiper/react';
import { SWIPER_MOUSE_WHEEL_OPTIONS } from 'constants/common';

import 'swiper/swiper.min.css';
import { StyledSwiper } from './styled';

interface IScrollContainerProps {
  spaceBetween?: number;
  onSwiper?: (swiper: SwiperRef) => void;
  scrollbar?: boolean;
  customSlidesOffsetBefore?: number;
  customSlidesOffsetAfter?: number;
  slidesPerView?: number;
}

const ScrollContainer: FC<IScrollContainerProps> = ({
  children,
  spaceBetween = 5,
  onSwiper = () => {},
  scrollbar = false,
  customSlidesOffsetBefore,
  customSlidesOffsetAfter,
  slidesPerView,
}) => (
  <StyledSwiper>
    <Swiper
      onSwiper={onSwiper}
      slidesPerView={slidesPerView ?? 'auto'}
      spaceBetween={spaceBetween}
      modules={[Scrollbar, Mousewheel]}
      mousewheel={SWIPER_MOUSE_WHEEL_OPTIONS}
      scrollbar={scrollbar}
      slidesOffsetBefore={customSlidesOffsetBefore}
      slidesOffsetAfter={customSlidesOffsetAfter}
    >
      {children}
    </Swiper>
  </StyledSwiper>
);

export default ScrollContainer;
