import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from '../../../styles/constants';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  padding-top: 16px;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    padding-bottom: 40px;
  }
`;

export const StyledLabel = styled.label<{ isPointer?: boolean }>`
  cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'auto')};
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
`;

export const StyledInfo = styled.p<{ isEmpty?: boolean }>`
  cursor: pointer;
  font-family: ${FONTS.primary};
  font-size: 22px;
  font-weight: 400;
  line-height: 28.6px;
  margin: 0;
  padding-top: 8px;
  word-break: break-word;
  color: ${({ isEmpty }) => (isEmpty ? COLORS.white[30] : COLORS.white[100])};
`;

export const StyledAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const StyledButton = styled.button`
  background: ${COLORS.white[10]};
  border: none;
  border-radius: 22px;
  color: ${COLORS.white[100]};
  cursor: pointer;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 19.6px;
  margin-left: 16px;
  padding: 12px 20px;
`;

export const StyledInputWrapper = styled.div`
  padding: 32px 0 8px;
`;
