import React, { FC } from 'react';

import Grain from 'components/UI/Grain';
import Slider from 'components/List/Header/Slider';
import { ImageSetSchema } from 'constants/graphqlTypes';
import { getGradient } from 'components/List/Header/helper';
import { useNavigationRef } from 'components/Navigation/helpers/hooks';

import { COLORS } from 'styles/constants';
import { StyledImage } from 'components/List/Header/styled';
import { StyledWrap } from './styled';

interface IListHeaderProps {
  bgColor: string;
  images: ImageSetSchema[];
  titles: string[];
}

const ListHeader: FC<IListHeaderProps> = ({ bgColor, titles, images }) => {
  const isDark = false;
  const { firstColor, secondColor } = getGradient(bgColor);

  const ref = useNavigationRef(COLORS.white[100]);
  const imagesUrl = images.map((image) => image?.images?.[0]?.url || '');

  const firstImage = images[0]?.images?.[0]?.url;
  const imagesCount = images?.length ?? 0;
  const isOneImage = imagesCount > 0 && imagesCount < 3;
  const isSliderImages = imagesCount >= 3;
  const isText = imagesCount === 0;
  const uniqueTitles = [...new Set(titles)];

  return (
    <StyledWrap
      ref={ref}
      bgColor={bgColor}
      firstColor={firstColor}
      isDark={isDark}
      isOneImage={isOneImage}
      isSliderImages={isSliderImages}
      secondColor={secondColor}
      isAllPicks={false}
    >
      <Grain />
      {isText && titles && (
        <Slider isOnboarding items={uniqueTitles} isImages={false} isAllPicks={false} pauseAnimation />
      )}
      {isOneImage && firstImage && <StyledImage src={firstImage} />}
      {isSliderImages && imagesUrl && <Slider pauseAnimation items={imagesUrl} isImages />}
    </StyledWrap>
  );
};

export default ListHeader;
