import { styled } from 'linaria/react';
import { BREAKPOINTS, FONTS } from 'styles/constants';
import { StyledWrap as Wrap } from 'components/Card/Common/styled';
import { StyledTitle } from 'components/SinglePageLayout/styled';
import { StyledTitle as StyledListTitle } from 'components/List/Content/styled';

export const StyledTopicTitle = styled(StyledTitle)`
  display: block;
  max-width: calc(100% - 24px);
  overflow: hidden;
  padding: 0 0 8px 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledContentWrap = styled(Wrap)`
  .masonryWrap,
  .listGrid {
    width: 100%;
    padding: 0 12px;
  }

  @media screen and (max-width: ${BREAKPOINTS.s}px) {
    .masonryWrap,
    .listGrid {
      padding: 0;
    }
  }
`;

export const StyledTitleWrap = styled(StyledListTitle)<{ isSmallCard?: boolean }>`
  display: flex;
  font-size: 22px;
  line-height: 130%;
  padding: 0;
  max-width: 100%;
  white-space: nowrap;
  word-wrap: unset;

  &::after {
    top: 0;
  }
`;

export const StyledText = styled.p`
  font: 14px/140% ${FONTS.secondary};
  margin: 0 0 24px;
`;

export const StyledHeadWrap = styled.div`
  padding: 0 24px 24px;

  ul {
    margin-left: 19px;
  }
`;

export const StyledAvatarsWrap = styled.div`
  cursor: pointer;
`;
