import { WEB_PREFIX } from 'constants/common';

export const LOGIN_SHEET = 'login-auth-method-sheet';
export const TEST_ID_LOGIN_START = `${WEB_PREFIX}${LOGIN_SHEET}`;
export const TEST_LOGIN_SHEET_EMAIL_BTN = `${TEST_ID_LOGIN_START}_email-button`;
export const TEST_LOGIN_SHEET_APPLE_BTN = `${TEST_ID_LOGIN_START}_apple-button`;
export const TEST_LOGIN_SHEET_GOOGLE_BTN = `${TEST_ID_LOGIN_START}_google-button`;

export const MAGIC_LINK_SHEET = 'authorization-email';
export const TEST_ID_MAGIC_LINK_START = `${WEB_PREFIX}${MAGIC_LINK_SHEET}`;
export const TEST_MAGIC_LINK_INPUT = `${TEST_ID_MAGIC_LINK_START}_email-text-field`;
export const TEST_MAGIC_LINK_CONTINUE_BTN = `${TEST_ID_MAGIC_LINK_START}_continue-button`;

export const WELCOME_VIEW = 'welcome-view';
export const TEST_ID_WELCOME_START = `${WEB_PREFIX}${WELCOME_VIEW}`;
export const TEST_WELCOME_VIEW_TITLE = `${TEST_ID_WELCOME_START}_title-label`;
export const TEST_WELCOME_VIEW_SUBTITLE = `${TEST_ID_WELCOME_START}_subtitle-label`;
export const TEST_WELCOME_VIEW_ABOUT_US = `${TEST_ID_WELCOME_START}_about-us-label`;
export const TEST_WELCOME_VIEW_GET_STARTED_BTN = `${TEST_ID_WELCOME_START}_get-started-button`;
