import { styled } from 'linaria/lib/react';

export const StyledAnimationWrap = styled.div`
  div:nth-child(even) {
    font-size: 12px;
  }
`;

export const StyledAnimationContainer = styled.div<{ isShown: boolean; xCoord: number }>`
  animation: reactionToken 700ms ease-out forwards;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1000;
  pointer-events: none;
  user-select: none;

  @keyframes reactionToken {
    14% {
      transform: translate(${({ xCoord }) => xCoord}%, -150%);
    }
    28% {
      transform: translate(${({ xCoord }) => xCoord}%, -300%);
      opacity: 0.75;
    }
    42% {
      transform: translate(${({ xCoord }) => xCoord}%, -450%);
    }
    56% {
      transform: translate(${({ xCoord }) => xCoord}%, -600%);
      opacity: 0.5;
    }
    70% {
      transform: translate(${({ xCoord }) => xCoord}%, -750%);
    }
    84% {
      transform: translate(${({ xCoord }) => xCoord}%, -900%);
      opacity: 0.25;
    }
    100% {
      transform: translate(${({ xCoord }) => xCoord}%, -1050%);
      opacity: 0;
    }
  }
`;
