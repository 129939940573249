import { createContext } from 'react';

export const ThemeContext = createContext(false);
export const NavigationContext = createContext({});
export const IsModalOpenContext = createContext({});
export const GuideTourContext = createContext({});
export const FollowingTabKeyContext = createContext({});
export const DiscoveryTabContext = createContext({});
export const OpenHiddenCardsContext = createContext({});

export const { Provider: IsModalOpenProvider } = IsModalOpenContext;
export const { Provider: ThemeProvider } = ThemeContext;
export const { Provider: NavigationProvider } = NavigationContext;
export const { Provider: GuideTourProvider } = GuideTourContext;
export const { Provider: FollowingTabKeyProvider } = FollowingTabKeyContext;
export const { Provider: DiscoveryTabProvider } = DiscoveryTabContext;
export const { Provider: OpenHiddenCardsProvider } = OpenHiddenCardsContext;
