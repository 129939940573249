import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import MenuButton from 'components/UI/MenuButton';
import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import { ROUTE_ARCHIVE } from 'routes';
import { StyledNotice, StyledWrapper } from './styled';

const ArchiveButton: FC<{ isDark: boolean; isSmall: boolean }> = ({ isDark, isSmall }) => {
  const { push } = useHistory();

  return (
    <StyledWrapper isSmall={isSmall}>
      <StyledNotice isDark={isDark}>Saves older than 30 days are automatically archived</StyledNotice>
      <MenuButton text="Archive" handler={() => push(ROUTE_ARCHIVE)} isDark={isDark} Icon={ChevronLeft} isReversed />
    </StyledWrapper>
  );
};

export default ArchiveButton;
