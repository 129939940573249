import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal';
import Button from 'components/UI/MenuButton';
import LoaderContent from 'components/UI/LoaderContent';

import { ROUTE_GUEST_LIST_SERIES } from 'routes';
import { StyledItem } from 'components/AddContent/Menu/styled';
import { StyledList } from 'components/ProfileSettings/Menu/styled';
import { ReactComponent as Chevron } from 'assets/icons/ChevronLeft.svg';
import { GuestListSeriesSchema } from 'constants/graphqlTypes';
import { useAllGuestListSeries } from 'graphQL/guestList/hooks';
import { StyleSelectTitle, StyledButton } from './styled';

interface SeriesSelectProps {
  current: GuestListSeriesSchema;
  isDark: boolean;
}

const SeriesSelect: FC<SeriesSelectProps> = ({ current, isDark }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { push } = useHistory();
  const { allSeries, loading } = useAllGuestListSeries();
  const { title } = current ?? {};

  const isMultiple = allSeries.length > 1;

  const handleItem = ({ guestListSeriesCard }: GuestListSeriesSchema) => {
    push(ROUTE_GUEST_LIST_SERIES.replace(':id', guestListSeriesCard?.id ?? ''));
    setIsOpen(false);
  };

  return (
    <>
      {loading ? (
        <LoaderContent isDark={isDark} />
      ) : (
        <StyledButton onClick={() => isMultiple && setIsOpen(true)} isDark={isDark}>
          <StyleSelectTitle>{title}</StyleSelectTitle>
          {isMultiple && <Chevron viewBox="0 0 24 24" height={16} width={16} />}
        </StyledButton>
      )}
      {isOpen && (
        <Modal fadeIn handleClose={() => setIsOpen(false)}>
          <StyledList>
            {allSeries.map((item) => (
              <StyledItem key={item.id}>
                <Button text={item.title ?? ''} handler={() => handleItem(item)} />
              </StyledItem>
            ))}
          </StyledList>
        </Modal>
      )}
    </>
  );
};

export default SeriesSelect;
