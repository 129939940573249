import React, { FC } from 'react';

import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { EButtonType } from 'components/UserList/constants';

import useSuggestedUsersSearch from 'helpers/useSuggestedUsersSearch';
import Input from 'components/UI/Input';
import useTheme from 'helpers/useTheme';
import UserList from 'components/UserList';
import Button from 'components/UI/Button';
import { EUiButtonType } from 'components/UI/Button/constants';
import { StyledInputWrapper, StyledEmptySearchMessage } from './styled';

const Search: FC<{ isMutedSearch?: boolean }> = ({ isMutedSearch }) => {
  const isDark = useTheme();

  const { text, setText, items, loadMore, loading } = useSuggestedUsersSearch({});

  return (
    <>
      <StyledInputWrapper>
        <Input
          Icon={SearchIcon}
          placeholder="Search"
          isDark={isDark}
          setText={setText}
          text={text}
          hasCrossIcon
          hasAutofocus
        />
        {text && <Button text="Cancel" handler={() => setText('')} isDark={isDark} type={EUiButtonType.Transparent} />}
      </StyledInputWrapper>
      {!loading && !items.length && (
        <StyledEmptySearchMessage isDark={isDark}>No results found</StyledEmptySearchMessage>
      )}
      <UserList
        buttonType={isMutedSearch ? EButtonType.Mute : EButtonType.Block}
        users={items}
        loadMore={loadMore}
        loading={loading}
        isDark={isDark}
      />
    </>
  );
};

export default Search;
