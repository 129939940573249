import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import EditTextFieldModal from 'components/EditTextFieldModal';
import { transformRoute, useLink } from 'helpers/routingHelper';
import { useUpdateFeedName } from 'graphQL/feeds/hooks';
import { ROUTE_EDIT_FEED } from 'routes';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';

const EditFeedName: FC = () => {
  const [titleText, setTitleText] = useState('');
  const [error, setError] = useState('');
  const { replace } = useHistory();
  const { state } = useLocation<{ variable: { usersTotalCount: number; feedId: string; title: string } }>();
  const { feedId, title } = state?.variable ?? {};

  const editFeed = useLink(transformRoute(ROUTE_EDIT_FEED, { ...state.variable, title: titleText }));

  const onCompleted = () => {
    replace({
      pathname: transformRoute(ROUTE_EDIT_FEED, { ...state.variable, title: titleText }),
      state: {
        ...state,
        variable: { ...state.variable, title: titleText },
      },
    });
  };

  const { updateFeedName, loading, error: updateNameError } = useUpdateFeedName(feedId, titleText, onCompleted);

  useEffect(() => {
    if (updateNameError) {
      setError(updateNameError);
    }
  }, [updateNameError]);

  useEffect(() => {
    if (title) {
      setTitleText(title);
    }
  }, [title]);

  return (
    <EditTextFieldModal
      text={titleText}
      setText={setTitleText}
      label="Feed name"
      maxLength={20}
      isSubmitDisabled={!titleText}
      handleClose={() => replace(editFeed)}
      handleDone={updateFeedName}
      setError={setError}
      error={error}
      loading={loading}
      autoCapitalize={EAutoCapitalize.On}
    />
  );
};

export default EditFeedName;
