import React, { FC, useMemo, useEffect } from 'react';
import { v4 } from 'uuid';
import Truncate from 'react-truncate-markup';

import useTheme from 'helpers/useTheme';
import Grain from 'components/UI/Grain';

import { CardSchema, UserObjectType } from 'constants/graphqlTypes';
import { useIsUserAllowedToReact, useItemUrl } from 'components/Card/helpers/hooks';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import usePageType from 'helpers/usePageType';
import useReferrerId from 'helpers/useReferrerId';
import getAuthUser from 'helpers/getAuthUser';
import Label from 'components/UI/Label';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import InteractionBlock from 'components/InteractionBlock';
import ReactionsModal from 'components/Reactions/ReactionsModal';
import { ReactionsProvider, useReactionContextState } from 'components/Reactions/hooks';

import {
  StyledTitle,
  StyledLink,
  StyledBody,
  StyledImagesList,
  StyledImageItem,
  StyledTextsList,
  StyledTextItem,
  StyledMetadata,
  StyledWrap,
  StyledTitleTextWrap,
  StyledCreatorsWrap,
} from './styled';

import { getListStyles } from './helpers';
import { COLORS } from '../../styles/constants';

import Collaborators from './Collaborators';
import Creators from './Creators';

interface ICollection {
  item: CardSchema;
  hideTitle?: boolean;
  isReplace?: boolean;
  hideCollaborators?: boolean;
  hidePrivateLabel?: boolean;
  disableLink?: boolean;
  showCreators?: boolean;
  showListDescription?: boolean;
  dataTestId?: string;
  fontSize?: number;
  removePaddingTopFromTitle?: boolean;
  hasSmallTitle?: boolean;
  isFeaturedTab?: boolean;
}

const Collection: FC<ICollection> = ({
  disableLink,
  item,
  hideTitle,
  isReplace,
  hideCollaborators,
  hidePrivateLabel,
  showCreators,
  showListDescription,
  dataTestId,
  fontSize = 32,
  removePaddingTopFromTitle,
  hasSmallTitle,
  isFeaturedTab,
}) => {
  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  const { thoughtId, setThoughtId, pickerId, setPickerId, cardId, setCardId } = useReactionContextState();

  const isDark = useTheme();
  const { id, title, description, isCollaborative, cardsFromCollection, isPrivateList, creators } = item;
  const isUserAllowedToReact = useIsUserAllowedToReact(creators?.items[0]?.user);
  const { images, titles, bgColor } = getListStyles(item);
  const { isInvite, isMessages } = usePageType();

  const hideMetadata = hideCollaborators && hidePrivateLabel;
  const url = useItemUrl(id, true);
  const { userId } = getAuthUser();
  const reffererId = useReferrerId();
  const isCurrentUserList = !!creators?.items?.find((data) => data?.user?.id === userId)?.user?.id;
  const isDisableReaction =
    isCurrentUserList || isCollaborative || isInvite || isMessages || !isUserAllowedToReact || disableLink;

  const titlesWithKey = useMemo(
    () =>
      titles.map((cover) => ({
        cover,
        key: v4(),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [titles.length, titles.join(','), v4]
  );

  useEffect(() => {
    setPickerId(creators?.items?.[0]?.user?.id ?? reffererId ?? null);
    setCardId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredCreators =
    creators?.items.reduce((acc: UserObjectType[], creator) => (creator?.user ? acc.concat(creator.user) : acc), []) ??
    [];

  return (
    <ReactionsProvider
      value={{
        isQuickReactionOpen,
        setThoughtId,
        thoughtId,
        setPickerId,
        pickerId,
        cardId,
        setCardId,
      }}
    >
      <StyledWrap data-testid={dataTestId} ref={infoRef} disableLinks={isQuickReactionOpen || disableLink}>
        <InteractionBlock
          tapCallback={() => setIsQuickReactionOpen(false)}
          longTapCallback={() => !isDisableReaction && setIsQuickReactionOpen(true)}
          getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
        >
          <div>
            <StyledLink
              onClick={(e) => {
                if (disableLink) {
                  e.preventDefault();
                }
              }}
              className="card collection-preview-anchor"
              replace={isReplace}
              to={url}
            >
              <div>
                {title && !hideTitle && (
                  <StyledTitle
                    removePaddingTop={removePaddingTopFromTitle}
                    isSmall={hasSmallTitle}
                    isDark={isDark}
                    total={cardsFromCollection?.totalCount || 0}
                  >
                    <Truncate lines={2} ellipsis="...">
                      <StyledTitleTextWrap>{title}</StyledTitleTextWrap>
                    </Truncate>
                  </StyledTitle>
                )}
                {creators?.items && (
                  <StyledCreatorsWrap>
                    <Creators
                      creators={filteredCreators}
                      creatorsTotalCount={creators.totalCount}
                      isDark={isDark}
                      isSmall={hasSmallTitle}
                      showCreators={!!isCollaborative || showCreators}
                      creatorsText={showListDescription && description ? `"${description}"` : ''}
                      isFeaturedTab={isFeaturedTab}
                    />
                  </StyledCreatorsWrap>
                )}
                <StyledBody className="list-body" bgColor={bgColor}>
                  <Grain />
                  {!!images.length && (
                    <StyledImagesList isMultiple={images.length >= 4}>
                      {images.length >= 4 &&
                        images
                          .slice(0, 4)
                          .map((image) => <StyledImageItem key={image.id} src={image?.images?.[0]?.url} />)}
                      {images.length < 4 && <StyledImageItem key={images[0]?.id} src={images[0]?.images?.[0]?.url} />}
                    </StyledImagesList>
                  )}
                  {!!titlesWithKey.length && (
                    <StyledTextsList>
                      {titlesWithKey.map(({ cover, key }, i) => (
                        <StyledTextItem
                          className="cover-text-item"
                          key={key}
                          opacity={1 - (1 / titlesWithKey.length) * i}
                          color={COLORS.white[100]}
                          fontSize={fontSize}
                        >
                          {cover}
                        </StyledTextItem>
                      ))}
                    </StyledTextsList>
                  )}
                </StyledBody>
                {!hideMetadata && (isPrivateList || isCollaborative) && (
                  <StyledMetadata>
                    {!hidePrivateLabel && isPrivateList && <Label title="Private" isDark={isDark} />}
                    {!hideCollaborators && isCollaborative && <Collaborators item={item} />}
                  </StyledMetadata>
                )}
              </div>
            </StyledLink>
          </div>
        </InteractionBlock>
        {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && !isDisableReaction && (
          <ReactionMenu
            isShown={isQuickReactionOpen}
            reactionCoordinates={reactionCoordinates}
            handleClickOutside={() => setIsQuickReactionOpen(false)}
            minusTop={minusTop}
            setIsReactionsModalOpen={setIsReactionsModalOpen}
            setIsQuickReactionOpen={setIsQuickReactionOpen}
          />
        )}
        {isReactionsModalOpen && <ReactionsModal id={id} handleClose={() => setIsReactionsModalOpen(false)} />}
      </StyledWrap>
    </ReactionsProvider>
  );
};

export default Collection;
