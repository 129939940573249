import React, { ChangeEvent, FC, useRef } from 'react';

import useDisableScroll from 'helpers/useDisableScroll';
import usePageType from 'helpers/usePageType';
import useTheme from 'helpers/useTheme';
import useFixedViewport from 'helpers/useFixedViewport';

import AnimatedPlaceholder from 'components/UI/AnimatedPlaceholderV2/AnimatedPlaceholder';
import Button from 'components/UI/Button';
import { EUiButtonType } from 'components/UI/Button/constants';
import { generateLayoutTestIds } from 'constants/aqa/components/layout';
import { generateTopicTitle } from 'constants/aqa/signup';
import { IAnimatedPlaceholderProps } from 'components/UI/AnimatedPlaceholderV2/models';

import { StyledMainHeading, StyledText, StyledBottomText } from 'pages/CheckEmailPage/styled';
import { StyledButtonContainer, StyledContainer, StyledInfoContainer, StyledFileInput, StyledWrap } from './styled';

interface ILayoutProps {
  heading?: string;
  isPrimaryBtnDisabled?: boolean;
  isPrimaryBtnRed?: boolean;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  text?: string;
  isImgUploaded?: boolean;
  hideButtons?: boolean;
  isSecondaryBtnTransparent?: boolean;
  handlePrimaryBtn?: () => void;
  handleSecondaryBtn?: () => void;
  handleUpload?: (e: ChangeEvent<HTMLInputElement>) => void;
  blockScroll?: boolean;
  isWideContent?: boolean;
  isPageScrollable?: boolean;
  pageTestId?: string;
  bottomText?: string;
  animatedPlaceholderParams?: IAnimatedPlaceholderProps;
  customHeaderGap?: number;
  hasUserCardsSlider?: boolean;
  secondaryBtnIsDark?: boolean;
  maxWidth?: number;
}

const Layout: FC<ILayoutProps> = ({
  children,
  primaryBtnText,
  isPrimaryBtnDisabled,
  isPrimaryBtnRed,
  secondaryBtnText,
  text,
  heading,
  bottomText,
  isImgUploaded,
  hideButtons,
  isSecondaryBtnTransparent,
  handlePrimaryBtn,
  handleSecondaryBtn,
  handleUpload,
  blockScroll = true,
  isWideContent,
  isPageScrollable,
  pageTestId,
  animatedPlaceholderParams,
  customHeaderGap,
  hasUserCardsSlider,
  secondaryBtnIsDark,
  maxWidth,
}) => {
  const { isOnboardingImage } = usePageType();
  const isDark = useTheme();

  useDisableScroll(!blockScroll);
  useFixedViewport();
  const inputRef = useRef<HTMLInputElement>(null);

  const { titleTestId, subtitleTestId } = generateLayoutTestIds(pageTestId);

  const onClearFile = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <StyledWrap isPageScrollable={isPageScrollable}>
      <StyledContainer hasUserCardsSlider={hasUserCardsSlider} isPageScrollable={isPageScrollable} maxWidth={maxWidth}>
        {(heading || text) && (
          <StyledInfoContainer isWideContent={isWideContent} customHeaderGap={customHeaderGap}>
            {heading && (
              <StyledMainHeading data-testid={titleTestId} isDark={isDark}>
                {heading}
              </StyledMainHeading>
            )}
            {text && (
              <StyledText data-testid={subtitleTestId} isDark={isDark}>
                {text}
              </StyledText>
            )}
          </StyledInfoContainer>
        )}
        {children}
        {!hideButtons && (
          <StyledButtonContainer isDark={isDark} maxWidth={maxWidth}>
            {animatedPlaceholderParams && <AnimatedPlaceholder items={animatedPlaceholderParams.items} />}
            {isOnboardingImage && (
              <StyledFileInput
                type="file"
                id="file"
                onChange={handleUpload}
                accept=".png, .jpg, .jpeg"
                ref={inputRef}
                onClick={onClearFile}
                isImgUploaded={isImgUploaded}
              />
            )}
            {bottomText && (
              <StyledBottomText data-testid={subtitleTestId} isDark={isDark}>
                {bottomText}
              </StyledBottomText>
            )}
            {primaryBtnText && (
              <Button
                dataTestId={`${pageTestId}_${generateTopicTitle(primaryBtnText)}-button`}
                handler={handlePrimaryBtn}
                isDisabled={isPrimaryBtnDisabled}
                isDark={isDark}
                text={primaryBtnText}
                type={isPrimaryBtnRed ? EUiButtonType.Danger : EUiButtonType.Emphasized}
              />
            )}
            {secondaryBtnText && (
              <Button
                dataTestId={`${pageTestId}_${generateTopicTitle(secondaryBtnText)}-button`}
                text={secondaryBtnText}
                handler={handleSecondaryBtn}
                isDark={secondaryBtnIsDark !== undefined ? secondaryBtnIsDark : !primaryBtnText || isDark}
                type={isSecondaryBtnTransparent ? EUiButtonType.Transparent : EUiButtonType.Emphasized}
              />
            )}
          </StyledButtonContainer>
        )}
      </StyledContainer>
    </StyledWrap>
  );
};

export default Layout;
