import { getGradient } from 'components/List/Header/helper';

// getTransformedGradient used to create a 10px transparent space for the top part of Borderless cards.
// without that there will be visual bug (see GH-14669) caused by border-radius antialiasing in modern browsers.
export const getTransformedGradient = (bgColor: string, borderRadiusDistance?: number) => {
  const isBgGradient = bgColor.includes('linear-gradient');

  const { firstColor: gradientStart, secondColor: gradientEnd } = getGradient(bgColor);

  return `linear-gradient(180deg, transparent 0%, transparent ${borderRadiusDistance ?? 10}px, 
    ${isBgGradient ? gradientStart : bgColor} ${borderRadiusDistance ?? 10}px, 
    ${isBgGradient ? gradientEnd : bgColor} 100%)`;
};
