import { CardStyleSchema, ImageSchema, ImageSetSchema, Maybe, ImageTypeOptions } from 'constants/graphqlTypes';

interface IGetImages {
  imageUrl?: string;
  bgUrl?: string;
  imageSet?: Maybe<ImageSetSchema>;
}

interface IGetImage {
  images?: Maybe<Maybe<ImageSchema>[]>;
  type: ImageTypeOptions;
}

const getImage = ({ images, type }: IGetImage): Maybe<ImageSchema> | undefined =>
  images?.find((image) => image?.url?.toUpperCase()?.includes(type)) ?? images?.[0];

export const getImageUrl = (params: IGetImage): string | undefined => getImage(params)?.url ?? undefined;

const getImages = (style?: Maybe<CardStyleSchema>, isDetail?: boolean): IGetImages => ({
  imageUrl: getImageUrl({
    images: style?.thumbnailImageSet?.images,
    type: isDetail ? ImageTypeOptions.Detail : ImageTypeOptions.Preview,
  }),
  bgUrl: getImageUrl({
    images: style?.bgImageSet?.images,
    type: ImageTypeOptions.Detail,
  }),
  imageSet: style?.thumbnailImageSet,
});

export default getImages;
