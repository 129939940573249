import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledItemContainer = styled.li<{ bg: string; isFromTwitter: boolean; image?: string }>`
  align-items: center;
  background: ${({ bg }) => bg};
  border-radius: 10px;
  display: flex;
  margin-bottom: 8px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  user-select: none;
  width: 100%;

  a {
    pointer-events: none;
    user-select: none;
  }

  > :not(.grain) {
    position: relative;
    z-index: 100;
  }

  .thumbnail {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    &::after {
      content: ${({ image, isFromTwitter }) => (!image && !isFromTwitter ? '"Aa"' : '')};
      padding: 0;
      font: 400 14px/140% ${FONTS.secondary};
      color: ${COLORS.white[100]};
    }

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.30334 5.92804L13.4031 0H12.1946L7.7665 5.14724L4.22977 0H0.150584L5.4988 7.78354L0.150584 14H1.35913L6.03533 8.56434L9.77037 14H13.8496L8.30304 5.92804H8.30334ZM6.64806 7.85211L6.10618 7.07705L1.79459 0.909776H3.65084L7.13035 5.88696L7.67223 6.66202L12.1952 13.1316H10.3389L6.64806 7.85241V7.85211Z' fill='white'/%3E%3C/svg%3E%0A");
      content: '';
      display: ${({ isFromTwitter }) => (isFromTwitter ? 'block' : 'none')};
      height: 14px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      z-index: 10;
    }
  }
`;

export const StyledTitleContainer = styled.span`
  flex: 1 1;
  font: 400 14px/140% ${FONTS.primary};
  overflow: hidden;
  padding-left: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledIconContainer = styled.div<{ isDragging: boolean }>`
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')} !important;
  flex: 0 0;
  padding-right: 8px;
  padding-left: 12px;
`;
