import React, { FC, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { EVisibilityStatusOptions, UserObjectType } from 'constants/graphqlTypes';
import Modal from 'components/Modal';
import getAuthUser from 'helpers/getAuthUser';
import UserList from 'components/UserList';
import Input from 'components/UI/Input';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import useDebouncedValue from 'helpers/useDebounced';

import { StyledWrap } from 'components/TabModal/styled';
import { EButtonType } from 'components/UserList/constants';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_LIST_EDIT_COLLABORATORS } from 'routes';
import { useGetFollowings } from 'graphQL/follows/hooks';
import { StyledSearchWrap, StyledTitle } from '../styled';

const { Following, NoOne } = EVisibilityStatusOptions;

const InviteCollaborators: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { replace } = useHistory();
  const { listId } = useParams<{ listId: string }>();
  const { state } = useLocation();
  const { userId } = getAuthUser();
  const [searchText, setSearch] = useState('');

  const debouncedSearchText = useDebouncedValue(searchText);
  const searchSequence = searchText.length > 1 ? debouncedSearchText : undefined;

  const { items, loadMore, loading, totalCount } = useGetFollowings(userId, {
    notInCollection: listId,
    or: [{ usernameIlike: searchSequence }, { nameIlike: searchSequence }],
  });

  const handleUser = ({ userSettings, isFollowingMe }: UserObjectType) => {
    if (
      userSettings?.collaborativeListsVisibility === NoOne ||
      (userSettings?.collaborativeListsVisibility === Following && !isFollowingMe)
    ) {
      setIsOpen(true);
    }
  };

  const showSearch = totalCount > 20 || !!searchText;

  return (
    <>
      <Modal
        fadeIn={false}
        fadeOut={false}
        handleClose={() => replace({ pathname: transformRoute(ROUTE_LIST_EDIT_COLLABORATORS, { listId }), state })}
      >
        <StyledTitle>Invite people</StyledTitle>
        {showSearch && (
          <StyledSearchWrap>
            <Input Icon={SearchIcon} placeholder="Search by name" setText={setSearch} text={searchText} />
          </StyledSearchWrap>
        )}
        <StyledWrap noHorizontalPadding>
          <UserList
            users={items}
            loadMore={loadMore}
            loading={loading}
            buttonType={EButtonType.Invite}
            handleUser={handleUser}
            fromInviteCollaborators
          />
        </StyledWrap>
      </Modal>
      {isOpen && (
        <ConfirmationModal
          handleClickOutside={() => setIsOpen(false)}
          buttonText="OK"
          handleAccept={() => setIsOpen(false)}
          isWarning={false}
          titleText="Unable to invite"
          subTitle="You can't invite this person because they've chosen not to receive invites."
        />
      )}
    </>
  );
};

export default InviteCollaborators;
