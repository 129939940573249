import React, { FC } from 'react';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { COLORS } from 'styles/constants';
import StyledAvatar from './styled';

const AvatarPlaceholder: FC<{ dimension: number; dataTestId?: string }> = ({ dimension, dataTestId }) => {
  return (
    <StyledAvatar data-testid={dataTestId || ''} dimension={dimension} color={COLORS.white[100]}>
      <UserIcon viewBox="0 0 12 16" />
    </StyledAvatar>
  );
};

export default AvatarPlaceholder;
