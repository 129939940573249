import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS, FONTS } from '../../../styles/constants';

export const StyledWrap = styled.div`
  padding-top: 16px;
`;

export const StyledSubtitle = styled.p<{ isDark: boolean; color?: string }>`
  color: ${({ isDark, color }) => {
    if (color) {
      return color;
    }

    return isDark ? COLORS.white[100] : COLORS.brown.light[100];
  }};
  overflow: hidden;
  text-overflow: ellipsis;
  font: 400 14px/140% ${FONTS.secondary};
  margin: 0;

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(14px * ${BREAKPOINTS.retinaScale});
  }
`;
