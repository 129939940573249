import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';

export const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 24px;
`;

export const StyledListButton = styled.button<{ isDark: boolean; hasIndicator?: boolean }>`
  background-color: ${COLORS.transparent};
  border: none;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  cursor: pointer;
  font-family: ${FONTS.primary};
  font-size: 22px;
  line-height: 130%;
  padding: 8px 0;
  position: relative;

  &::after {
    background-color: ${COLORS.green.light};
    border-radius: 50%;
    content: ${({ hasIndicator }) => (hasIndicator ? '""' : 'none')};
    display: block;
    height: 10px;
    position: absolute;
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
  }
`;
