import React, { FC } from 'react';

import getImages from 'helpers/getImages';
import { Maybe, CardSchema } from 'constants/graphqlTypes';

import Modal from 'components/Modal';
import QuestionAttribute from 'components/Card/QuestionAttribute';
import SparkAttribute from 'components/Card/SparkAttribute';

import { StyledWrap, StyledTitle, StyledText } from './styled';

interface IConnectedModalProps {
  handleClose: () => void;
  responseToQuestion?: Maybe<CardSchema>;
  guestListQuestion?: Maybe<CardSchema>;
  responseToSpark?: Maybe<CardSchema>;
}

const ConnectedModal: FC<IConnectedModalProps> = ({
  handleClose,
  responseToQuestion,
  guestListQuestion,
  responseToSpark,
}) => {
  const { imageUrl } = getImages(guestListQuestion?.questionGuestList?.cardStyle);

  return (
    <Modal handleClose={handleClose}>
      <StyledWrap>
        <StyledTitle>Connected to this thought</StyledTitle>
        <StyledText>Prompts</StyledText>
        {responseToSpark && <SparkAttribute spark={responseToSpark} isDark isFullBleed hasBorder={false} />}
        {responseToQuestion?.id && responseToQuestion?.title && (
          <QuestionAttribute
            questionId={responseToQuestion?.id}
            title={responseToQuestion?.title}
            text="Question of the day"
            isDark
            isFullBleed
            hasBorder={false}
          />
        )}
        {guestListQuestion?.id && guestListQuestion?.title && guestListQuestion?.questionGuestList?.title && (
          <QuestionAttribute
            questionId={guestListQuestion?.id}
            text={guestListQuestion?.questionGuestList?.title}
            title={guestListQuestion?.title}
            isDark
            isFullBleed
            imageUrl={imageUrl}
            hasBorder={false}
          />
        )}
      </StyledWrap>
    </Modal>
  );
};

export default ConnectedModal;
