import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

const StyledAvatarGH = styled.div<{ dimension: number; isDark?: boolean; isRounded?: boolean }>`
  align-items: center;
  background-color: ${({ isDark }) => (isDark ? COLORS.brown.dark[100] : COLORS.green.light)};
  border-radius: ${({ isRounded }) => (isRounded ? '50%' : '9px')};
  display: flex;
  justify-content: center;
  height: ${({ dimension }) => dimension}px;
  width: ${({ dimension }) => dimension}px;

  svg {
    width: 60%;
  }

  path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown[100])};
  }
`;

export default StyledAvatarGH;
