import React, { FC, useEffect } from 'react';

import VerificationInput from 'react-verification-input';

import AnimatedBackground from 'components/AnimatedBackground';
import { useInviteCode } from 'pages/Onboarding/InviteCode/hooks';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { TEST_ID_USER_INFO_VIEW_INVITE_CODE_TEXT_FIELD_CONTAINER } from 'constants/aqa/signup';
import { StyledContent, StyledWrapper } from 'pages/CheckEmailPage/styled';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { CODE_LENGTH } from './constants';

import { StyledCodesContainer, StyledHeading, StyledText } from './styled';

const InviteCode: FC = () => {
  const posthogCapture = usePostHogCapture();
  const { value, setValue, handleSend, inputRef, isErrorShown, isRepeatedErrorShown } = useInviteCode();

  useEffect(() => {
    if (inputRef.current && isRepeatedErrorShown) {
      inputRef.current.disabled = true;
    }
  }, [isRepeatedErrorShown]);

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.OnboardingInviteCode);
  }, []);

  return (
    <AnimatedBackground>
      <StyledWrapper>
        <StyledContent>
          <StyledHeading>Enter your invite code</StyledHeading>
          <StyledText>
            If you don’t have a code, you can&nbsp;
            <a href="mailto:hello@guidehuman.co">request one here.</a>
          </StyledText>
          <StyledCodesContainer
            isInputDisabled={isRepeatedErrorShown}
            data-testid={TEST_ID_USER_INFO_VIEW_INVITE_CODE_TEXT_FIELD_CONTAINER}
          >
            <VerificationInput
              classNames={{
                container: 'codesContainer',
                character: 'character',
                characterSelected: 'character--selected',
              }}
              autoFocus
              length={CODE_LENGTH}
              placeholder=""
              value={value}
              onChange={(codeValue) => setValue(codeValue)}
              onComplete={handleSend}
              ref={inputRef}
            />
          </StyledCodesContainer>

          {isErrorShown && !isRepeatedErrorShown && (
            <StyledText>
              The code entered is not valid. Please try again. If you’re having trouble with the code, please
              <br />
              <a href="mailto:beta@guidehuman.co">contact us.</a>
            </StyledText>
          )}
          {isRepeatedErrorShown && (
            <StyledText>
              You have reached the daily limit for attempts. Please
              <br />
              <a href="mailto:beta@guidehuman.co">contact us.</a>
            </StyledText>
          )}
        </StyledContent>
      </StyledWrapper>
    </AnimatedBackground>
  );
};

export default InviteCode;
