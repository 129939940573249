import React, { FC } from 'react';

import Avatar from 'components/UI/Avatar';

import { StyledButton, StyledText } from 'components/UI/ListItem/styled';
import { EQueryFetchPolicy } from 'constants/common';
import { TEST_ADD_TO_LIST_ORGANIZE_LATER } from 'constants/aqa/addPick';
import { useProfile } from 'graphQL/profile/hooks';
import { StyledListItem, StyledList } from './styled';

const OrganizeLater: FC<{ isDisabled: boolean; handler: () => void }> = ({ isDisabled, handler }) => {
  const { userData } = useProfile(undefined, EQueryFetchPolicy.CacheOnly);

  return (
    <StyledList isOrganizeLater>
      <StyledListItem>
        <StyledButton data-testid={TEST_ADD_TO_LIST_ORGANIZE_LATER} disabled={isDisabled} onClick={handler}>
          {userData && <Avatar dimension={48} user={userData} />}
          <StyledText isDark>Organize later</StyledText>
        </StyledButton>
      </StyledListItem>
    </StyledList>
  );
};

export default OrganizeLater;
