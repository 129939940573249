import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Invite } from 'assets/icons/InviteToGH.svg';
import OnboardingListReel from 'assets/videos/onboarding-list-reel-example.mp4';
import AnimatedBackground from 'components/AnimatedBackground';
import Button from 'components/UI/Button';

import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { ROUTE_ONBOARDING_NOTIFICATIONS } from 'routes';
import { ENewOnboardingStepOptions } from 'constants/graphqlTypes';
import { useUpdateCachedOnboardingStepV2 } from 'graphQL/onboarding/hooks';

import { StyledButtonWrap, StyledTitleWrap } from 'pages/OnboardingV2/ProfilePreview/styled';
import { StyledVideoWrap, StyledTitle, StyledInner } from './styled';

const ConfirmationFinish = () => {
  const posthogCapture = usePostHogCapture();
  const { push } = useHistory();
  const updateCache = useUpdateCachedOnboardingStepV2(ENewOnboardingStepOptions.Notifications);

  const handleNext = () => {
    updateCache();
    push(ROUTE_ONBOARDING_NOTIFICATIONS);
  };

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.OnboardingSharePreviews);
  }, []);

  return (
    <AnimatedBackground>
      <StyledInner>
        <StyledTitleWrap>
          <StyledTitle>
            Great curations are also worth{' '}
            <span>
              <Invite />
            </span>{' '}
            sharing.
          </StyledTitle>
        </StyledTitleWrap>
        <StyledVideoWrap>
          <video width="100%" height="100%" autoPlay muted playsInline loop preload="auto">
            <source src={`${OnboardingListReel}#t=0.001`} type="video/mp4" />
          </video>
        </StyledVideoWrap>
      </StyledInner>
      <StyledButtonWrap>
        <p>Generate video reels to share on social</p>
        <Button text="Continue" handler={handleNext} />
      </StyledButtonWrap>
    </AnimatedBackground>
  );
};

export default ConfirmationFinish;
