import { styled } from 'linaria/react';
import ContentTitle from '../ContentTitle/styled';
import { COLORS, FONTS } from '../../../styles/constants';

export const StyledItem = styled.div<{ bgColor?: string }>`
  background: ${({ bgColor }) => bgColor ?? COLORS.brown.dark[100]};
  border-radius: 10px;
  color: ${COLORS.white[100]};
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  padding: 24px 16px 40px;
  position: relative;

  a {
    pointer-events: none;
  }
`;

export const StyledTitle = styled(ContentTitle)<{ isDark: boolean }>`
  margin: 16px 0 8px;
  position: relative;
  width: 80%;
`;

export const StyledText = styled.p`
  font: 400 14px/140% ${FONTS.secondary};
  margin: 0;
  position: relative;
`;
