import React, { FC, Dispatch, Fragment, SetStateAction, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import Modal from 'components/Modal';
import ContentTitle from 'components/UI/ContentTitle';
import ListItem from 'components/UI/ListItem';
import LoaderContent from 'components/UI/LoaderContent';
import Button from 'components/UI/Button';
import { useLink } from 'helpers/routingHelper';
import { useGetProfileTopics } from 'graphQL/profile/topics/hooks';
import { useGetHeadTopics } from 'graphQL/feeds/hooks';
import Input from 'components/UI/Input';
import { EToggleType } from 'components/UI/Toggle/models';
import { ROUTE_CREATE_FEED_USERS } from 'routes';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { EUiButtonType } from 'components/UI/Button/constants';
import { SortOrderEnum } from 'constants/graphqlTypes';
import { DEFAULT_TOPIC_GRADIENT } from 'styles/constants';

import { StyledButtonWrap } from 'components/TopicsModal/styled';
import { checkIsTopicEnabled } from '../../helpers';
import { useCreateFeedEvents, useCreateFeedTopicsSelection } from '../../hooks';

import {
  StyledAllTopicsWrapper,
  StyledContentContainer,
  StyledSearchContainer,
  StyledTopicsList,
  StyledTopicsLabel,
} from './styled';

import { DisplaySections, ESelectionMode } from '../../../constants';

const { All } = ESelectionMode;
interface ISelectFeedCreateTopicsProps {
  selectionMode: ESelectionMode;
  setSelectionMode: Dispatch<SetStateAction<ESelectionMode>>;
  selectedTopics: string[];
  setSelectedTopics: Dispatch<SetStateAction<string[]>>;
  deselectedTopics: string[];
  setDeselectedTopics: Dispatch<SetStateAction<string[]>>;
  totalSelectedTopics: number;
  setTotalSelectedTopics: Dispatch<SetStateAction<number>>;
}

const SelectFeedCreateTopics: FC<ISelectFeedCreateTopicsProps> = ({
  selectionMode,
  setSelectionMode,
  selectedTopics,
  setSelectedTopics,
  deselectedTopics,
  setDeselectedTopics,
  totalSelectedTopics,
  setTotalSelectedTopics,
}) => {
  const { goBack, replace } = useHistory();
  const userLink = useLink(ROUTE_CREATE_FEED_USERS);
  const handleExit = () => goBack();

  const [searchString, setSearchString] = useState('');

  const profileTopicsData = useGetProfileTopics(undefined, SortOrderEnum.Asc);
  const headTopicsData = useGetHeadTopics();
  const { handleSelection, handleSelectItem } = useCreateFeedEvents(
    selectionMode,
    setSelectionMode,
    setSelectedTopics,
    setDeselectedTopics
  );

  const { totalCount, isLoading, isQueryCalled } = headTopicsData ?? {};
  const searchHeadTopicsData = useGetHeadTopics(searchString);

  const showGeneralLoader =
    isLoading && profileTopicsData.isLoading && !isQueryCalled && !profileTopicsData.isQueryCalled;

  const displayingSections = [
    !searchString && { ...profileTopicsData, title: 'Your interests' },
    !!searchString && { ...searchHeadTopicsData },
    !searchString && { ...headTopicsData, title: 'All topics' },
  ].reduce((prev: DisplaySections, curr) => (curr ? prev.concat(curr) : prev), []);

  useCreateFeedTopicsSelection({
    selectionMode,
    setTotalSelectedTopics,
    summaryTotal: totalCount,
    selectedTopics,
    deselectedTopics,
    setSelectionMode,
    totalSelectedTopics,
  });

  useEffect(() => {
    if (selectedTopics.length === totalCount && !profileTopicsData.isLoading && !isLoading) {
      setSelectionMode(All);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopics.length, profileTopicsData.isLoading, isLoading, totalCount]);

  return (
    <Modal handleClose={handleExit} handlePrev={handleExit} enableInnerScroll>
      <StyledContentContainer hasPaddingBottom={!!searchString}>
        <ContentTitle isDark>Select the topics you’re interested in</ContentTitle>
        <StyledSearchContainer>
          <Input
            Icon={SearchIcon}
            placeholder="Search"
            isDark
            setText={setSearchString}
            text={searchString}
            hasCrossIcon
          />
          {searchString && (
            <Button text="Cancel" handler={() => setSearchString('')} isDark type={EUiButtonType.Transparent} />
          )}
        </StyledSearchContainer>
        {showGeneralLoader ? (
          <LoaderContent isFullScreen />
        ) : (
          <>
            {!searchString && (
              <StyledAllTopicsWrapper>
                <ListItem
                  title="All topics"
                  isDark
                  handleClick={() => handleSelection(All)}
                  hasToggle
                  toggleType={EToggleType.CheckPlus}
                  ghLogoParams={{ dimension: 44 }}
                  isEnable={selectionMode === All}
                  bgColor={DEFAULT_TOPIC_GRADIENT}
                />
              </StyledAllTopicsWrapper>
            )}
            {displayingSections.map(({ title, isLoading: sectionLoading, loadMoreTopics, topics }, sectionIndex) => (
              <>
                <StyledTopicsLabel>{title}</StyledTopicsLabel>
                <StyledTopicsList hasPaddingBottom={sectionIndex === displayingSections.length - 1 && !sectionLoading}>
                  {topics?.map(({ name, id, imageSet }, index) => (
                    <Fragment key={id}>
                      {name && id && (
                        <ListItem
                          title={name}
                          isDark
                          handleClick={() => handleSelectItem(id)}
                          image={imageSet?.images?.[0].url}
                          hasToggle
                          toggleType={EToggleType.CheckPlus}
                          bgColor={DEFAULT_TOPIC_GRADIENT}
                          isEnable={checkIsTopicEnabled({
                            id,
                            selectionMode,
                            selectedTopics,
                            deselectedTopics,
                          })}
                        />
                      )}
                      {/* we need index === topics.length - 3 because otherwise Continue button overlaps waypoint and blocks loadmore execution  */}
                      {!sectionLoading && index === topics.length - 3 && <Waypoint onEnter={loadMoreTopics} />}
                    </Fragment>
                  ))}
                  {sectionLoading && <LoaderContent isFullScreen={!topics.length} />}
                </StyledTopicsList>
              </>
            ))}
          </>
        )}
        {!!totalSelectedTopics && (
          <StyledButtonWrap>
            <Button
              handler={() => replace(userLink)}
              text={`Continue with ${totalSelectedTopics} topic${totalSelectedTopics === 1 ? '' : 's'}`}
            />
          </StyledButtonWrap>
        )}
      </StyledContentContainer>
    </Modal>
  );
};

export default SelectFeedCreateTopics;
