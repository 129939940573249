import { useContext } from 'react';

import { OpenHiddenCardsContext } from 'providers/helpers/contexts';
import { IOpenHiddenCards } from 'providers/helpers/models';

const useOpenHiddenCards = (): { openCard?: (id: string) => void; openIds?: string[] } => {
  const { ids, openCard } = useContext<IOpenHiddenCards>(OpenHiddenCardsContext);

  return { openCard, openIds: ids };
};

export default useOpenHiddenCards;
