import { styled } from 'linaria/react';

import { FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  padding-bottom: 32px;

  a {
    color: inherit;
  }
`;

export const StyledTitle = styled.span`
  display: block;
  font: 400 22px/130% ${FONTS.primary};
  padding: 32px 0;
`;

export const StyledText = styled.span`
  display: block;
  font: 400 14px/140% ${FONTS.secondary};
`;
