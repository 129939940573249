import { styled } from 'linaria/react';
import { StyledNoContent as StyledNoListContent } from '../List/Content/styled';
import { BREAKPOINTS } from '../../styles/constants';

export const StyledNoContent = styled(StyledNoListContent)`
  max-width: 90%;

  a {
    color: inherit;
  }

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    max-width: 390px;
    padding: 28px 22px 0;
  }
`;
