import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ShuffleIcon } from 'assets/icons/shuffle-icon.svg';
import Modal from 'components/Modal';
import { useAddContentLinks } from 'components/AddContent/helpers/hooks';
import { ColorSchema } from 'constants/graphqlTypes';
import { useCreateSpark } from 'graphQL/sparks/hooks';
import { useGoBack } from 'helpers/routingHelper';
import StyleButton from 'components/AddContent/AddStyle/StyleButton';
import { StyledMeatball as StyledShuffleButton } from 'components/AddContent/AddStyle/styled';
import { getGradientImage } from 'components/Sparks/constants';
import { IFollowingKey } from 'providers/helpers/models';
import { FollowingTabKeyContext } from 'providers/helpers/contexts';
import Card from '../Card';
import { useMockSparkCard } from './hooks';
import { NUMBER_OF_PREDEFINED_GRADIENTS } from './constants';

import {
  StyledCardContainer,
  StyledContentContainer,
  StyledFooter,
  StyledGradientsList,
  StyledGradientsText,
  StyledShuffleButtonWrap,
} from './styled';

interface IAddSparkStyleProps {
  sparkTitle: string;
  allGradients: ColorSchema[];
}

const AddSparkStyle: FC<IAddSparkStyleProps> = ({ sparkTitle, allGradients }) => {
  const { addSpark } = useAddContentLinks();
  const { replace } = useHistory();
  const handleClose = () => replace(addSpark);

  const predefinedGradients = [...allGradients].splice(0, NUMBER_OF_PREDEFINED_GRADIENTS);

  const [currentGradient, setCurrentGradient] = useState<ColorSchema>(predefinedGradients[0]);
  const mockCard = useMockSparkCard({
    title: sparkTitle,
    gradient: currentGradient,
  });
  const { resetFollowingTabKey } = useContext<IFollowingKey>(FollowingTabKeyContext);

  useEffect(() => {
    if (!sparkTitle || !allGradients.length) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shuffleGradients = [...allGradients].slice(NUMBER_OF_PREDEFINED_GRADIENTS);
  const [randomGradientIndex, setRandomGradientIndex] = useState(0);

  const isCurrentGradientRandom = !!shuffleGradients.find((gradient) => gradient.id === currentGradient.id);

  const navigateBack = useGoBack();
  const onCompleted = () => {
    setTimeout(() => {
      resetFollowingTabKey?.();
    }, 1000);
    navigateBack();
  };
  const { handleDone, loading } = useCreateSpark({
    title: sparkTitle.trim(),
    bgColorId: currentGradient?.colorId ?? '',
    onCompleted,
  });

  return (
    <Modal
      handleDone={handleDone}
      buttonText="Create"
      fadeIn={false}
      handleClose={handleClose}
      handlePrev={handleClose}
      customContentPadding="0px"
      disabled={loading}
    >
      <StyledContentContainer>
        <StyledCardContainer>
          <Card hideFooter card={mockCard} isDark />
        </StyledCardContainer>
        <StyledFooter>
          <StyledGradientsText>Select your background</StyledGradientsText>
          <StyledGradientsList>
            <StyledShuffleButtonWrap isCurrent={isCurrentGradientRandom}>
              <StyledShuffleButton
                onClick={() => {
                  setCurrentGradient(shuffleGradients[randomGradientIndex]);
                  setRandomGradientIndex((prev) => (prev + 1) % shuffleGradients.length);
                }}
              >
                <ShuffleIcon />
              </StyledShuffleButton>
            </StyledShuffleButtonWrap>
            {predefinedGradients.map((gradient) => (
              <li key={gradient.id}>
                <StyleButton
                  handleSelect={() => setCurrentGradient(gradient)}
                  isCurrent={gradient.id === currentGradient.id}
                  item={{ colors: [gradient], id: 1 }}
                  customImageUrl={getGradientImage(gradient.predefinedGradientName ?? '')}
                  withGrain
                />
              </li>
            ))}
          </StyledGradientsList>
        </StyledFooter>
      </StyledContentContainer>
    </Modal>
  );
};

export default AddSparkStyle;
