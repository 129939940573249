import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import useTheme from 'helpers/useTheme';
import AvatarGH from 'components/UI/AvatarGH';
import Layout from 'components/Onboarding/Layout';

import { ROUTE_ONBOARDING_SUCCESS } from 'routes';
import { EOnboardingStepOptions } from 'constants/graphqlTypes';
import { TEST_ID_USER_INFO_VIEW_START } from 'constants/aqa/signup';
import { useNotifications } from 'graphQL/profile/notifications/hooks';
import { useUpdateOnboardingStep } from 'graphQL/onboarding/hooks';
import { StyledText, StyledTextContainer, StyledContent, StyledWrap } from './styled';

const Notifications: FC = () => {
  const { push } = useHistory();

  const { updateNotificationSetting } = useNotifications();
  const isDark = useTheme();

  const handleNext = (isEnableAll?: boolean) => {
    if (isEnableAll) {
      updateNotificationSetting({ isEnableAll });
    }

    push(ROUTE_ONBOARDING_SUCCESS);
  };

  useUpdateOnboardingStep(EOnboardingStepOptions.Notifications);

  return (
    <Layout
      heading="Are you happy for us to send you notifications?"
      text="We’ll let you know what others are thinking about the things you’re into."
      primaryBtnText="Enable"
      handlePrimaryBtn={() => handleNext(true)}
      secondaryBtnText="Skip"
      handleSecondaryBtn={() => handleNext()}
      isSecondaryBtnTransparent
      pageTestId={TEST_ID_USER_INFO_VIEW_START}
    >
      <StyledWrap>
        <StyledContent isDark={isDark}>
          <AvatarGH dimension={44} isDark />
          <StyledTextContainer isDark={isDark}>
            <StyledText>New thoughts added to your pick</StyledText>
            <br />
            <StyledText isSecondary>See what Jim West and Priya Swift think</StyledText>
          </StyledTextContainer>
        </StyledContent>
      </StyledWrap>
    </Layout>
  );
};

export default Notifications;
