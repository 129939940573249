import React, { FC } from 'react';
import { v4 } from 'uuid';

import Grain from 'components/UI/Grain';
import { CardSchema } from 'constants/graphqlTypes';
import getImages from 'helpers/getImages';
import getStyles from 'helpers/getStyles';
import { StyledImage, StyledMiniatureContainer, StyledMiniatureWrap } from './styled';

interface ICardMiniatureProps {
  cards: CardSchema[];
}

const CardsMiniaturePile: FC<ICardMiniatureProps> = ({ cards }) => {
  const cardsDataToShow = cards.slice(0, 3).map(({ cardStyle }, index) => {
    const { imageUrl } = getImages(cardStyle);
    const { bgColor } = getStyles(cardStyle);
    return { imageUrl: index === 0 ? imageUrl : undefined, bgColor };
  });
  return (
    <StyledMiniatureContainer>
      {cardsDataToShow.map(({ bgColor, imageUrl }, index) => (
        <StyledMiniatureWrap bgColor={bgColor} index={index} key={v4()}>
          <div>
            <Grain />
            {imageUrl && <StyledImage src={imageUrl} />}
          </div>
        </StyledMiniatureWrap>
      ))}
    </StyledMiniatureContainer>
  );
};
export default CardsMiniaturePile;
