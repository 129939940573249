import { UserObjectType } from 'constants/graphqlTypes';
import { useState } from 'react';
import { IUseBlockUserWarning } from './models';

export const useBlockUserWarning = (handleNext: () => void, user?: UserObjectType): IUseBlockUserWarning => {
  const { isBlockedByMe, isBlockedMe } = user ?? {};

  const [showModal, setShowModal] = useState(false);

  return {
    showBlockWarning: !!(isBlockedByMe || isBlockedMe) && showModal,
    checkUserBlock: () => (isBlockedByMe || isBlockedMe ? setShowModal(true) : handleNext()),
    closeModal: () => setShowModal(false),
  };
};
