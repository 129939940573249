import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTE_ACCOUNT_MANAGEMENT, ROUTE_ACCOUNT_MANAGEMENT_DETAILS, ROUTE_ACCOUNT_MANAGEMENT_TOPICS } from 'routes';

import AccountDetails from './AccountDetails';
import AccountManagementMenu from './AccountManagementMenu';
import AdjustTopic from './AdjustTopic';

const AccountManagement: FC = () => (
  <Switch>
    <Route exact path={ROUTE_ACCOUNT_MANAGEMENT}>
      <AccountManagementMenu />
    </Route>
    <Route exact path={ROUTE_ACCOUNT_MANAGEMENT_DETAILS}>
      <AccountDetails />
    </Route>
    <Route exact path={ROUTE_ACCOUNT_MANAGEMENT_TOPICS}>
      <AdjustTopic />
    </Route>
  </Switch>
);
export default AccountManagement;
