import { Dispatch, SetStateAction } from 'react';

import {
  ECollectionLayoutOptions,
  GetDetailListDocument,
  GetListItemsDocument,
  useChangeListOrderMutation,
  useChangeListViewMutation,
  useToggleListPrivacyMutation,
  useUpdateDescriptionMutation,
  useUpdateTitleMutation,
} from 'constants/graphqlTypes';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';

export const useUpdateListDescription = (
  listId: string,
  listCardId: string,
  description?: string,
  onCompleted?: () => void
) => {
  const [updateCard, { loading, error }] = useUpdateDescriptionMutation({
    variables: {
      id: listCardId,
      description: description?.trim(),
    },
    onCompleted,
  });

  return { updateDescription: updateCard, loading, descriptionError: error?.message };
};

export const useUpdateListTitle = (listId: string, listCardId: string, title?: string, onCompleted?: () => void) => {
  const [updateCard, { loading, error }] = useUpdateTitleMutation({
    variables: {
      id: listCardId,
      title: title?.trim(),
    },
    onCompleted,
  });

  return { updateTitle: updateCard, loading, titleError: error?.message };
};

export const useToggleListPrivacy = (
  listCardId: string,
  listPickId: string,
  isPrivate: boolean,
  setIsPrivate: Dispatch<SetStateAction<boolean>>,
  onComplete?: () => void
) => {
  const posthogCapture = usePostHogCapture();

  const [toggle] = useToggleListPrivacyMutation({
    variables: {
      cardId: listCardId,
      pickId: listPickId,
      isPrivate,
    },
    update: (cache, { data }) => {
      setIsPrivate(isPrivate);
      const listId = data?.pickCard?.id;
      cache.modify({
        id: cache.identify({ __typename: 'CardSchema', id: listId }),
        fields: {
          isPrivate: () => isPrivate,
        },
      });
    },
    onCompleted: () => {
      onComplete?.();
      posthogCapture(POSTHOG_EVENTS.EditList);
      posthogCapture(POSTHOG_EVENTS.EditListPrivacy);
    },
  });

  return toggle;
};

export const useChangeCollectionLayout = (
  listCardId: string,
  listId: string,
  collectionLayout?: ECollectionLayoutOptions
) => {
  const [changeListViewMutation] = useChangeListViewMutation({
    variables: {
      id: listCardId,
      collectionLayout,
    },
    refetchQueries: listId
      ? [
          { query: GetDetailListDocument, variables: { id: listId } },
          { query: GetListItemsDocument, variables: { id: listId, refListId: listId, offset: 0 } },
        ]
      : undefined,
  });

  return changeListViewMutation;
};

export const useReorderList = (listCardId: string): ((cardIds: string[]) => void) => {
  const [mutation] = useChangeListOrderMutation({
    variables: {
      listId: listCardId,
    },
    refetchQueries: [GetDetailListDocument, GetListItemsDocument],
  });

  return (cardIds: string[]) => mutation({ variables: { listId: listCardId, cardIds } });
};
