import { ECardRoleTypeOptions } from 'constants/graphqlTypes';

export const ROLES_LIST_OPTIONS = [
  {
    title: 'Admin',
    subtitle: 'User can add/remove collaborators, and edit/add/remove picks and thoughts.',
    role: ECardRoleTypeOptions.Owner,
  },
  {
    title: 'Collaborator',
    subtitle: 'Users can add/remove picks and thoughts.',
    role: ECardRoleTypeOptions.Collaborator,
  },
  {
    title: 'Viewer',
    subtitle: 'Can see the list and thoughts and picks in it.',
    role: ECardRoleTypeOptions.Viewer,
  },
];
