import React, { FC } from 'react';

import { ReactComponent as Chevron } from 'assets/icons/ChevronLeft.svg';

import { ChannelCustomFeedSchema, ChannelSectionListV2 } from 'constants/graphqlTypes';

import UILink from 'components/UI/Link';
import FeedCarousel from 'components/CustomFeeds/FeedCarousel';
import AddCustomFeedCard from 'components/CustomFeeds/FeedCard/AddCustomFeedCard';

import { useGetChannelSectionItems } from 'graphQL/channel/hooks';
import useTheme from 'helpers/useTheme';
import { ROUTE_CUSTOM_FEEDS } from 'routes';

import { StyledFeedCardWrap } from 'components/CustomFeeds/FeedCard/styled';
import { StyledSectionType, StyledSectionWrap, StyledTextContainer, StyledTitle } from '../styled';

interface ICustomFeedSectionProps {
  section: ChannelSectionListV2;
}

const CustomFeedSection: FC<ICustomFeedSectionProps> = ({ section: { id, title } }) => {
  const isDark = useTheme();
  const { items, totalCount, loadMore } = useGetChannelSectionItems(id);

  const customFeeds: ChannelCustomFeedSchema[] =
    items?.reduce((prev: ChannelCustomFeedSchema[], curr) => {
      if (curr && curr.__typename === 'ChannelCustomFeedSchema') {
        prev.push(curr);
      }
      return prev;
    }, []) ?? [];
  const isNotCustomFeeds = !customFeeds?.length;

  return (
    <StyledSectionWrap>
      <StyledTextContainer>
        {isNotCustomFeeds && <StyledSectionType>Assemble it the way you want</StyledSectionType>}
        <StyledTitle isDark={isDark}>
          <UILink route={ROUTE_CUSTOM_FEEDS} isDisabled={isNotCustomFeeds}>
            <span>{title ?? ''}</span>
            {!isNotCustomFeeds && <Chevron viewBox="0 0 24 24" height={24} width={24} />}
          </UILink>
        </StyledTitle>
      </StyledTextContainer>
      {!isNotCustomFeeds ? (
        <FeedCarousel totalCount={totalCount ?? 0} loadMore={loadMore} customFeeds={customFeeds} />
      ) : (
        <StyledFeedCardWrap>
          <AddCustomFeedCard sectionId={id} />
        </StyledFeedCardWrap>
      )}
    </StyledSectionWrap>
  );
};

export default CustomFeedSection;
