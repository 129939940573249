import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS } from 'styles/constants';

import { StyledWrap, StyledButtons } from 'components/UI/OnboardingAlert/styled';

export const StyledTutorialWrapper = styled.div`
  align-items: center;
  background-color: ${COLORS.black[40]};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  max-height: 100dvh;
  opacity: 1;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;

  @media screen and (max-width: ${BREAKPOINTS.s}px) {
    align-items: flex-end;
  }

  ${StyledWrap} {
    max-width: 528px;
    padding: 32px 24px 21px;

    @media screen and (max-width: ${BREAKPOINTS.s}px) {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  ${StyledButtons} {
    padding-top: 32px;
  }

  button {
    width: 100%;

    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      width: auto;
    }
  }
`;
