import React, { Dispatch, FC, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';

import {
  TEST_EDIT_CARD_STYLE_CAROUSEL,
  TEST_EDIT_CARD_STYLE_CAROUSEL_IMAGE_SECTION,
  TEST_EDIT_CARD_STYLE_CAROUSEL_REPORT_BTN,
  TEST_EDIT_CARD_STYLE_CAROUSEL_TEXT_SECTION,
} from 'constants/aqa/addPick';
import { ReactComponent as DotMenuIcon } from 'assets/icons/DotMenu.svg';
import { useSparkResponseLinks } from 'components/Sparks/Routes/AddResponseToSpark/hooks';
import { StyledList, StyledMeatball, StyledMeatballWrap } from './styled';
import { IStyleItem } from '../helpers/models';

import StyleButton from './StyleButton';
import { useOfferReportLinks } from '../helpers/hooks';

interface IItemsProps {
  items: IStyleItem[];
  itemId: number;
  setItemId: Dispatch<SetStateAction<number>>;
  isSpark?: boolean;
  handler?: (id: number) => void;
}

const Items: FC<IItemsProps> = ({ items, itemId, isSpark, setItemId, handler }) => {
  const { replace } = useHistory();
  const { styleDescription } = useOfferReportLinks();
  const { sparkResponseStyleDescription } = useSparkResponseLinks();

  const handleMeatballClick = () => replace(isSpark ? sparkResponseStyleDescription : styleDescription);

  const withImages = items.reduce((prev: number[], curr: IStyleItem) => (curr.image ? prev.concat(curr.id) : prev), []);
  const withoutImages = items.reduce(
    (prev: number[], curr: IStyleItem) => (!curr.image ? prev.concat(curr.id) : prev),
    []
  );

  const handleSelect = handler ?? setItemId;

  return (
    <StyledList data-testid={TEST_EDIT_CARD_STYLE_CAROUSEL}>
      {items.map((styleItem) => (
        <li
          data-testid={`${
            styleItem.image ? TEST_EDIT_CARD_STYLE_CAROUSEL_IMAGE_SECTION : TEST_EDIT_CARD_STYLE_CAROUSEL_TEXT_SECTION
          }_style-item-${
            withImages.indexOf(styleItem.id) !== -1
              ? withImages.indexOf(styleItem.id)
              : withoutImages.indexOf(styleItem.id)
          }`}
          key={styleItem.id ?? v4()}
        >
          <StyleButton
            handleSelect={() => handleSelect(styleItem.id)}
            isCurrent={styleItem.id === itemId}
            item={styleItem}
          />
        </li>
      ))}
      <StyledMeatballWrap data-testid={TEST_EDIT_CARD_STYLE_CAROUSEL_REPORT_BTN}>
        <StyledMeatball onClick={handleMeatballClick}>
          <DotMenuIcon />
        </StyledMeatball>
      </StyledMeatballWrap>
    </StyledList>
  );
};

export default Items;
