import React, { Dispatch, FC } from 'react';

import { CardSchema } from 'constants/graphqlTypes';
import { EUiButtonType } from 'components/UI/Button/constants';
import Card from 'components/Card';
import Button from 'components/UI/Button';
import { findNextColor } from 'components/AddContent/helpers/helpers';
import { EActionType, ECurrentMode } from './utils/constants';
import { IAction, INewStyleItem } from './utils/models';

import { StyledCardContainer, StyledCardFooter, StyledSrc } from './styled';
import { getCardColors } from './utils/helpers';

interface ICarouselCardProps {
  card: CardSchema;
  isActive: boolean;
  mode: ECurrentMode;
  dispatch: Dispatch<IAction>;
  newStyleItem?: INewStyleItem;
}

const CarouselCard: FC<ICarouselCardProps> = ({ card, isActive, mode, dispatch, newStyleItem }) => {
  const { externalSources } = card;
  const { sourceDomainName, sourceUrl } = externalSources?.items?.[0] ?? {};

  const colors = getCardColors(card, newStyleItem?.activeStyleOptionId ?? 0);
  const currentColor = newStyleItem?.cardStyle?.bgColor;

  const changeColor = () =>
    isActive &&
    currentColor &&
    colors &&
    dispatch({
      type: EActionType.ChangeColor,
      variable: {
        color: findNextColor(colors, currentColor),
        cardId: card.id,
      },
    });

  return (
    <StyledCardContainer
      isActive={isActive}
      shouldHideCard={mode === ECurrentMode.Comment}
      shouldHideButton={mode === ECurrentMode.Style}
      onClick={changeColor}
    >
      <Card isDark card={card} disableLinks shouldDisableLongTap />
      <StyledCardFooter>
        <StyledSrc>{sourceDomainName ?? sourceUrl}</StyledSrc>
        <Button
          text="Style"
          type={EUiButtonType.Primary}
          isDark
          handler={() => dispatch({ type: EActionType.Mode, variable: { mode: ECurrentMode.Style } })}
        />
      </StyledCardFooter>
    </StyledCardContainer>
  );
};

export default CarouselCard;
