import { css } from 'linaria';
import { styled } from 'linaria/lib/react';
import { BREAKPOINTS, FONTS } from 'styles/constants';

export const StyledTitleAnimation = css`
  font: 400 32px/120% ${FONTS.primary};
  margin: 0;
  padding: 4px 10px 0;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    font-size: 45px;
    line-height: 110%;
    padding: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.l}px) {
    font-size: 60px;
  }
`;

export const StyledAnimationContainer = styled.div`
  height: 80px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    height: 100px;
  }

  @media screen and (min-width: ${BREAKPOINTS.l}px) {
    height: 135px;
  }
`;
