import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';

import getAuthUser from 'helpers/getAuthUser';
import useTheme from 'helpers/useTheme';
import { UserObjectType } from 'constants/graphqlTypes';
import { useGetFollowings } from 'graphQL/follows/hooks';

import LoaderContent from 'components/UI/LoaderContent';
import SinglePageLayout from 'components/SinglePageLayout';
import SpecificUser from 'components/SettingsSection/SpecificUser';
import ContentSubtitle from 'components/UI/ContentSubtitle';

import { COLORS } from 'styles/constants';
import { StyledList } from '../styled';

const ActivityNotifications: FC = () => {
  const isDark = useTheme();
  const { userId } = getAuthUser();
  const { items: users, loading, loadMore } = useGetFollowings(userId);

  return (
    <SinglePageLayout title="Activity notifications" subTitle="Be notified each time someone shares a pick.">
      {!users.length && !loading ? (
        <ContentSubtitle
          isDark={isDark}
          text="You’re not currently following anyone."
          color={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
        />
      ) : (
        <StyledList isDark={isDark}>
          {users.map((user: UserObjectType) => (
            <li key={user.id}>
              <SpecificUser user={user} isNotificationsEnable={!!user.isNotificationsEnable} />
            </li>
          ))}
          {loading ? <LoaderContent isFullScreen={!users.length} isDark /> : <Waypoint onEnter={loadMore} />}
        </StyledList>
      )}
    </SinglePageLayout>
  );
};

export default ActivityNotifications;
