import React, { FC, useRef, useState } from 'react';

import useClickOutside from 'helpers/useClickOutside';

import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { UserObjectType } from 'constants/graphqlTypes';
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';
import { StyledButton, StyledListItem } from './styled';

interface IUserProps {
  user: UserObjectType;
  isDark: boolean;
  handleRemove: () => void;
  alwaysSelected?: boolean;
}

const User: FC<IUserProps> = ({ user, isDark, handleRemove, alwaysSelected }) => {
  const [isSelected, setIsSelected] = useState(false);

  const ref = useRef<HTMLLIElement | null>(null);
  useClickOutside(ref, () => setIsSelected(false));

  return (
    <StyledListItem
      className="member"
      ref={ref}
      onClick={() => (isSelected || alwaysSelected ? handleRemove() : setIsSelected(true))}
      isRed={isSelected}
      isDark={isDark}
    >
      <p>{generateFullName(user)}</p>
      {(isSelected || alwaysSelected) && (
        <StyledButton onClick={handleRemove}>
          <CrossIcon />
        </StyledButton>
      )}
    </StyledListItem>
  );
};

export default User;
