import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation } from 'swiper';
import { v4 } from 'uuid';

import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import { COLORS } from 'styles/constants';
import { SWIPER_MOUSE_WHEEL_OPTIONS } from 'constants/common';
import { ICenterSectionSlide } from './models';
import { CENTER_SECTION_SLIDES } from './constants';
import { getCenterSectionImgAlt } from './helpers';

import {
  StyledCarouselContainer,
  StyledCarouselSlide,
  StyledCenterSectionMobile,
  StyledSectionHeader,
  StyledSectionSubheader,
  StyledRightArrowBtn,
  StyledLeftArrowBtn,
  StyledSlideTitle,
  StyledSlideSubTitle,
  StyledCenterSectionBig,
  StyledInfoContainer,
  StyledInfoBlock,
  StyledImageContainer,
} from './styled';

const MiddleBlock = () => {
  const prevBtnClassName = `prev-${v4()}`;
  const nextBtnClassName = `next-${v4()}`;

  const [selectedBlock, setSelectedBlock] = useState<ICenterSectionSlide>(CENTER_SECTION_SLIDES[0]);

  return (
    <>
      <StyledCenterSectionMobile>
        <StyledSectionSubheader>Get to Know</StyledSectionSubheader>
        <StyledSectionHeader>
          Explore.
          <br />
          Save.
          <br />
          Share.
        </StyledSectionHeader>
        <StyledCarouselContainer>
          <StyledLeftArrowBtn
            isDisabled={false}
            className={`${prevBtnClassName} slider-prev`}
            color={COLORS.brown[100]}
          >
            <ChevronLeft viewBox="0 0 24 24" height="22" width="22" />
          </StyledLeftArrowBtn>
          <Swiper
            navigation={{
              prevEl: `.${prevBtnClassName}`,
              nextEl: `.${nextBtnClassName}`,
            }}
            grabCursor
            slidesPerView={1}
            loop
            speed={600}
            modules={[Mousewheel, Navigation]}
            mousewheel={SWIPER_MOUSE_WHEEL_OPTIONS}
            noSwipingClass="no-swiping"
            spaceBetween={50}
          >
            {CENTER_SECTION_SLIDES.map(({ img, subtitle, title }) => (
              <SwiperSlide key={subtitle}>
                <StyledCarouselSlide>
                  <img src={img} alt={getCenterSectionImgAlt(selectedBlock)} />
                  <StyledSlideTitle>{title}</StyledSlideTitle>
                  <StyledSlideSubTitle>{subtitle}</StyledSlideSubTitle>
                </StyledCarouselSlide>
              </SwiperSlide>
            ))}
          </Swiper>
          <StyledRightArrowBtn
            isDisabled={false}
            className={`${nextBtnClassName} slider-next`}
            color={COLORS.brown[100]}
          >
            <ChevronLeft viewBox="0 0 24 24" height="22" width="22" />
          </StyledRightArrowBtn>
        </StyledCarouselContainer>
      </StyledCenterSectionMobile>
      <StyledCenterSectionBig>
        <StyledImageContainer>
          <img src={selectedBlock.img} alt={getCenterSectionImgAlt(selectedBlock)} />
        </StyledImageContainer>
        <StyledInfoContainer>
          <StyledSectionSubheader>Get to Know</StyledSectionSubheader>
          {CENTER_SECTION_SLIDES.map((slide) => (
            <StyledInfoBlock
              onClick={() => setSelectedBlock(slide)}
              isSelected={selectedBlock.title === slide.title}
              key={slide.subtitle}
            >
              <StyledSlideTitle>{slide.title}</StyledSlideTitle>
              <StyledSlideSubTitle>{slide.subtitle}</StyledSlideSubTitle>
            </StyledInfoBlock>
          ))}
        </StyledInfoContainer>
      </StyledCenterSectionBig>
    </>
  );
};

export default MiddleBlock;
