import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';
import { NAVIGATION_HEIGHT } from 'components/Navigation/helpers/constants';

export const StyledChatWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${BREAKPOINTS.s}px;
  height: 100dvh;
  position: relative;
`;

export const StyledSeparator = styled.span`
  color: ${COLORS.brown.light[100]};
  font-family: ${FONTS.secondary};
  font-size: 12px;
  text-align: center;

  a {
    font-weight: 600;
    color: inherit;
  }
`;

export const StyledThread = styled.section<{ isIosVirtualKeyboardOpen: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  height: 100dvh;
  gap: 32px;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
  padding: 68px 24px
    ${({ isIosVirtualKeyboardOpen }) => (isIosVirtualKeyboardOpen ? 'calc(var(--keyboard-offset) + 98px)' : '15px')};
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledEmpty = styled.div<{ isDark: boolean; isIosVirtualKeyboardOpen: boolean }>`
  position: absolute;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  top: ${NAVIGATION_HEIGHT}px;
  left: 0;
  right: 0;
  bottom: ${({ isIosVirtualKeyboardOpen }) =>
    isIosVirtualKeyboardOpen ? 'calc(84px + var(--keyboard-offset))' : '84px'};

  b {
    font-weight: 600;
    padding-bottom: 6px;
  }
`;

export const StyledIosPlaceholder = styled.div`
  min-height: 24px;
`;
