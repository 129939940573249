import { styled } from 'linaria/react';
import { BREAKPOINTS } from 'styles/constants';

export const StyledSection = styled.section`
  padding: 32px 0 64px;

  .hide-padding {
    margin-top: 12px;
  }
`;

export const StyledContent = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 96px;
  list-style: none;
  margin: 0;
  width: 100%;
`;

export const StyledGuestWrap = styled.div<{ hasOneItem: boolean }>`
  padding: 0 ${({ hasOneItem }) => (hasOneItem ? 12 : 0)}px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0;

    .slider-info {
      padding: 0 24px 16px;
    }
  }
`;

export const StyledBlockWrap = styled.div`
  padding: 96px 0;
`;

export const StyledFeedbackWrap = styled.div`
  padding: 16px 24px;

  button {
    margin-top: 16px;
    width: 100%;
  }
`;

export const StyledListWrap = styled.div<{ hasPadding: boolean }>`
  padding: 0 ${({ hasPadding }) => (hasPadding ? 12 : 0)}px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0;
  }
`;

export const StyledCardWrap = styled.div`
  margin: 0 5%;
  padding: 16px 12px 0;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    margin: 0 6%;
  }
`;
