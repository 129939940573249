import { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import useTheme from 'helpers/useTheme';
import usePageType from 'helpers/usePageType';

import { useGetListItems } from 'graphQL/list/hooks';
import { INavigation, INavigationWithSetter } from 'providers/helpers/models';
import { NavigationContext } from 'providers/helpers/contexts';
import { COLORS } from 'styles/constants';
import { IUseMenuModal } from './models';
import { NAVIGATION_HEIGHT } from './constants';

export const useNavColor = (): INavigationWithSetter => {
  const value = useContext<INavigation>(NavigationContext);

  return {
    ...value,
    setNavColor: (data: INavigation) => value.setNavColor?.(data),
    setIsBottomMenuHidden: (data: boolean) => value.setIsBottomMenuHidden?.(data),
  };
};

export const useNavBehavior = (): { isHide: boolean; bgColor?: string; fontColor: string } => {
  const [isHide, setIsHde] = useState(false);
  const [hasBgColor, setHasBgColor] = useState(false);
  const { navColor, sectionHeight } = useNavColor();

  const { listId } = useParams<{ listId?: string }>();
  const { totalCount } = useGetListItems({ id: listId });

  const {
    isLegalPage,
    isOnboardingWelcome,
    isOnboardingVerification,
    isOnboardingPreview,
    isCheckEmail,
    isShareSheet,
    isOnboardingProfileOrNested,
    isOnboardingNetworkingJoin,
    isOnboardingNetworkingConfirmation,
    isOnboardingContent,
    isOnboardingContentListPreview,
    isOnboardingContentConfirmation,
    isListDetail,
  } = usePageType();

  const isDark = useTheme();
  const color = isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  const bgColor = isDark ? COLORS.black[100] : COLORS.white[100];
  const currentColor = !hasBgColor && navColor ? navColor : color;

  useEffect(() => {
    let lastScrollValue = 0;

    const handleScroll = () => {
      const scrollToTop = window.scrollY;

      setIsHde(scrollToTop > lastScrollValue && scrollToTop > NAVIGATION_HEIGHT);
      setHasBgColor(!!sectionHeight && scrollToTop > sectionHeight);

      lastScrollValue = scrollToTop;
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [sectionHeight]);

  const shouldNavigationBeTranspared =
    isLegalPage ||
    isOnboardingWelcome ||
    isOnboardingPreview ||
    isOnboardingVerification ||
    isCheckEmail ||
    isShareSheet;

  if (isOnboardingProfileOrNested) {
    return {
      isHide: false,
      bgColor: COLORS.transparent,
      fontColor: currentColor,
    };
  }

  if (
    isOnboardingNetworkingJoin ||
    isOnboardingNetworkingConfirmation ||
    isOnboardingContent ||
    isOnboardingContentListPreview ||
    isOnboardingContentConfirmation
  ) {
    return {
      isHide: true,
      fontColor: COLORS.white[100],
    };
  }

  if (isListDetail && !totalCount) {
    return {
      isHide: false,
      fontColor: COLORS.brown.dark[100],
    };
  }

  if (shouldNavigationBeTranspared) {
    return {
      isHide: false,
      bgColor: COLORS.transparent,
      fontColor: COLORS.white[100],
    };
  }

  return { isHide, bgColor: hasBgColor || !navColor ? bgColor : undefined, fontColor: currentColor };
};

export const useNavigationRef = (navColor: string): ((node: HTMLElement | null) => void) => {
  const [nodeRef, setNodeRef] = useState<HTMLElement>();
  const setRef = useCallback((node: HTMLElement | null) => {
    if (node) {
      setNodeRef(node);
    }
  }, []);
  const { setNavColor } = useNavColor();

  useEffect(() => {
    setNavColor({ navColor, sectionHeight: nodeRef?.clientHeight });

    return () => setNavColor({ navColor: undefined, sectionHeight: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navColor, nodeRef?.clientHeight]);

  return setRef;
};

export const useMenuModal = (): IUseMenuModal => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return { isModalOpen, handleModal: (isOpen: boolean) => setIsModalOpen(isOpen) };
};

export const useShowBottomMenu = (): boolean => {
  const root = document.documentElement;
  const {
    isActivity,
    isOnboarding,
    isSettings,
    isHelp,
    isAboutUs,
    isShareSheet,
    isSearch,
    isMessages,
    isSparkDetail,
    isSharingPage,
    isLegalPage,
    isSavedPicks,
  } = usePageType();
  const { isBottomMenuHidden } = useNavColor();
  const [isHideMenuOnSearchPage, setIsHideMenuOnSearchPage] = useState(false);

  const isShowMenu =
    !isShareSheet &&
    !isActivity &&
    !isOnboarding &&
    !isSettings &&
    !isHelp &&
    !isAboutUs &&
    !isMessages &&
    !isHideMenuOnSearchPage &&
    !isSparkDetail &&
    !isSharingPage &&
    !isLegalPage &&
    !isSavedPicks;

  const setCSSProp = () => {
    setTimeout(() => root.style.setProperty('--show-bottom-menu', isShowMenu ? '112px' : '34px'), 10);
  };

  useEffect(() => {
    setIsHideMenuOnSearchPage(isSearch && !!isBottomMenuHidden);
  }, [isSearch, isBottomMenuHidden]);

  useEffect(() => {
    setCSSProp();
  }, [isShowMenu]);

  return isShowMenu;
};
