import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';

import { Maybe, ReactionSymbolSchema } from 'constants/graphqlTypes';
import ContentSubtitle from 'components/UI/ContentSubtitle';
import ReactionButton from '../ReactionButton';
import { parseToEmoji } from '../helpers/helpers';

import { StyledEmojiWrap, StyledGroupSection } from './styled';
import { BLOCKED_REACTIONS } from '../constants';

interface IEmojiGroupProps {
  targetId: string;
  title: string;
  symbols: Maybe<ReactionSymbolSchema[]> | undefined;
  handleClick: () => void;
  handleInViewPort?: () => void;
}

const EmojiGroup: FC<IEmojiGroupProps> = ({ title, symbols, handleClick, handleInViewPort }) => (
  <Waypoint onEnter={() => handleInViewPort?.()} topOffset={18} bottomOffset="99%">
    <StyledGroupSection id={title}>
      <ContentSubtitle isDark text={title} />
      <StyledEmojiWrap>
        {symbols
          ?.filter(({ name }) => name && !BLOCKED_REACTIONS.includes(name))
          .map((emoji) => (
            <ReactionButton key={emoji.id} emoji={parseToEmoji(emoji.symbol)} isEmojiOnly handleClick={handleClick} />
          ))}
      </StyledEmojiWrap>
    </StyledGroupSection>
  </Waypoint>
);

export default EmojiGroup;
