import React, { FC } from 'react';

import { COLORS } from 'styles/constants';
import { StyledAllPicks } from 'components/Profile/ProfileContent/styled';
import { StyledImageItem, StyledImagesList } from 'components/Collection/styled';
import { StyledList, StyledListTitle } from './styled';
import { LISTS } from './helpers/constants';

const ListSlide: FC<{ index: number }> = ({ index }) => {
  const { cover, title } = LISTS[index];

  return (
    <StyledList>
      <StyledAllPicks id="cover" isDark={false} bgColor={COLORS.transparent}>
        <StyledImagesList isMultiple>
          {cover.map((image) => (
            <StyledImageItem key={image} src={image} />
          ))}
        </StyledImagesList>
      </StyledAllPicks>
      <StyledListTitle>{title}</StyledListTitle>
    </StyledList>
  );
};

export default ListSlide;
