// minimal offsets before centering
export const MINIMAL_MESSAGE_OFFSET_TOP = 150;
export const MINIMAL_MESSAGE_OFFSET_BOTTOM = 200;

export const REACTION_MENU_OFFSET_HORIZONTAL = 16;

export const MENTIONED_USER_EXPRESSION = /\[user_id:.*\]/g;

export const DOUBLE_HTML_TAG_EXPRESSION = /<[^>]+>.*?<\/[^>]+>/g;

export const SINGLE_HTML_TAG_EXPRESSION = /<[^>]+>/g;

export const HTML_TAG_CONTENT_EXPRESSION = />(.*?)</g;

export const MAX_HEIGHT_TO_SCALE = 330;

export const MINIMAL_SCROLL_DISTANCE_TO_PREVENT_REACTION_MENU = 5;
