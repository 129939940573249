import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_CONFIRM_DELETION } from 'routes';

import useTheme from 'helpers/useTheme';
import Layout from 'components/Onboarding/Layout';
import MenuButton from 'components/UI/MenuButton';
import { EMenuButtonType } from 'components/UI/MenuButton/constants';

const DeleteAllPicks: FC = () => {
  const isDark = useTheme();
  const [shouldDeletePicks, setShouldDeletePicks] = useState(false);
  const { push, go } = useHistory();
  const location = useLocation<{ pagesToMenu: number }>();
  const { pagesToMenu } = location.state;

  return (
    <Layout
      heading="Delete your picks from lists you collaborated on?"
      text="You can choose to leave your picks in lists that you collaborated on for others to still see, but we will remove your name, profile picture and thought."
      primaryBtnText="Continue"
      secondaryBtnText="Cancel"
      isSecondaryBtnTransparent
      isWideContent
      handlePrimaryBtn={() =>
        push({
          pathname: ROUTE_CONFIRM_DELETION,
          state: { ...location.state, shouldDeletePicks, pagesToMenu: pagesToMenu - 1 },
        })
      }
      handleSecondaryBtn={() => go(pagesToMenu)}
    >
      <MenuButton
        isDark={isDark}
        text="Delete my picks entirely"
        checked={shouldDeletePicks}
        type={EMenuButtonType.Toggle}
        handler={() => setShouldDeletePicks(!shouldDeletePicks)}
      />
    </Layout>
  );
};

export default DeleteAllPicks;
