import React, { FC } from 'react';

import Truncate from 'react-truncate-markup';
import { DiscoverSectionSchema } from 'constants/graphqlTypes';
import { StyledTitle } from 'components/List/Content/styled';
import { StyledDescription } from 'components/List/styled';
import List from 'components/Card/List';
import { FEATURED_TAB_VIEW } from 'constants/aqa/explore';
import { StyledSection } from './styled';

const DynamicListSection: FC<{ section: DiscoverSectionSchema | null; isDark: boolean }> = ({ section, isDark }) => {
  const { title, annotation, cardItems } = section || {};

  return (
    <>
      {title && (
        <Truncate lines={2}>
          <StyledTitle isExplore isDark={isDark} total={section?.cardItems?.length ?? 0}>
            {title}
          </StyledTitle>
        </Truncate>
      )}
      {annotation && <StyledDescription isDark={isDark}>{annotation}</StyledDescription>}
      {cardItems && (
        <StyledSection>
          {cardItems.map(({ card }) => (
            <List
              key={card.id}
              dataTestId={`${FEATURED_TAB_VIEW}-dynamic-card-${card.id}`}
              card={card}
              isDark={isDark}
            />
          ))}
        </StyledSection>
      )}
    </>
  );
};

export default DynamicListSection;
