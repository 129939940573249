import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';
import { StyledWrap as ImportWrap } from '../AboutUs/styled';

export const StyledWrap = styled(ImportWrap)`
  padding-top: 48px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .sharing-list {
    height: 25%;
  }

  p {
    margin: 0;
  }
`;

export const StyledHeader = styled.h1<{ isStylingMode: boolean }>`
  font: ${({ isStylingMode }) => (isStylingMode ? `14px/29px ${FONTS.secondary}` : `22px/29px ${FONTS.primary}`)};
  margin: 0;
`;

export const StyledContentWrap = styled.section`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledContent = styled.div`
  padding: 0 15%;
  width: 100%;
`;

export const StyledSharingWrap = styled.div`
  ul {
    padding-left: 0;
  }
`;

export const StyledTitle = styled.p`
  color: ${COLORS.white[100]};
  font: 600 14px/140% ${FONTS.secondary};
  padding-bottom: 8px;
`;

export const StyledLinkTitle = styled.p`
  font: 600 14px/140% ${FONTS.secondary};
`;

export const StyledSubtitle = styled.p`
  font: 14px/140% ${FONTS.secondary};
`;

export const StyledText = styled.div`
  flex-grow: 1;
`;
