import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledContainer = styled.div<{ isDark: boolean; isDefaultImg: boolean }>`
  align-items: center;
  background: ${({ isDefaultImg }) => (isDefaultImg ? COLORS.transparent : COLORS.white[10])};
  border-radius: 10px;
  border: ${({ isDark, isDefaultImg }) =>
    isDefaultImg ? `1px solid ${isDark ? COLORS.white[30] : COLORS.brown.dark[10]}` : 'none'};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 12px;
  margin-top: 28px;
  padding: 32px;
  width: 100%;
`;

export const StyledImgContainer = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  user-select: none;

  > svg {
    animation: rotate infinite linear 120s;
    border-radius: 50%;
    content: '';
    inset: -8px;
    position: absolute;
    transform: rotate(180deg);
    z-index: -1;

    @keyframes rotate {
      100% {
        stroke-dashoffset: 1000;
      }
    }
  }

  .avatar {
    opacity: 0;

    animation: fadein 300ms ease-in forwards;
    animation-delay: 100ms;

    @keyframes fadein {
      100% {
        opacity: 1;
      }
    }
  }
`;

export const StyledFileInput = styled.input`
  display: none;
`;

export const StyledImageLabel = styled.div`
  align-items: center;
  background: ${COLORS.white.off[100]};
  border-radius: 22px;
  bottom: -22px;
  color: ${COLORS.brown.dark[100]};
  display: flex;
  font: 600 14px/140% ${FONTS.secondary};
  gap: 4px;
  height: 44px;
  left: 50%;
  padding: 0 20px 0 12px;
  position: absolute;
  transform: translateX(-50%);

  circle {
    stroke: ${COLORS.brown.dark[100]};
  }

  circle[fill='#fff'] {
    fill: ${COLORS.brown.dark[100]};
    stroke: transparent;
  }

  path {
    stroke: ${COLORS.brown.dark[100]};
  }
`;

export const StyledImg = styled.img`
  height: 180px;
  object-fit: cover;
  position: relative;
  width: 180px;
`;

export const StyledFullname = styled.p<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 22px/130% ${FONTS.primary};
  margin: 0;
  padding-top: 38px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const StyledUsername = styled.p<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 14px/140% ${FONTS.secondary};
  margin: 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
`;

export const StyledChangeUsername = styled.div<{ isDark: boolean }>`
  padding-top: 16px;

  > a {
    color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    font: 600 14px/140% ${FONTS.secondary};
    text-decoration: underline;
  }
`;
