import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal';
import Button from 'components/UI/MenuButton';
import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { useAddContentLinks, useOfferReportLinks } from 'components/AddContent/helpers/hooks';
import { useSparkResponseLinks } from 'components/Sparks/Routes/AddResponseToSpark/hooks';

import { StyledDescriptionWrap, StyledTitle } from '../styled';

const StyleDescription: FC<{ isSpark?: boolean }> = ({ isSpark }) => {
  const { replace } = useHistory();
  const { addStyle } = useAddContentLinks();
  const { reportStyleIssue } = useOfferReportLinks();
  const { sparkResponseReportStyleIssue, addResponseStyle } = useSparkResponseLinks();

  const handleClose = () => replace(isSpark ? addResponseStyle : addStyle);

  const handleReport = () => replace(isSpark ? sparkResponseReportStyleIssue : reportStyleIssue);

  return (
    <Modal handleClose={handleClose} handlePrev={handleClose}>
      <StyledTitle>Styles come from the link source</StyledTitle>
      <StyledDescriptionWrap>
        Each pick is generated from a pasted URL, extracting the title, images, categorization, colors, and URL.
        <br />
        <br />
        The appearance of each pick is dependent on how the source site is coded. We preserve all that we can to ensure
        the integrity of what you share.
        <br />
        <br />
        Our team is constantly working to improve how picks are able to be added to guide:human.
      </StyledDescriptionWrap>
      <Button text="Report an issue" Icon={ReportIcon} handler={handleReport} />
    </Modal>
  );
};

export default StyleDescription;
