import { styled } from 'linaria/react';
import { COLORS } from '../../../styles/constants';

export const StyledFlatButton = styled.button<{ width: number; isRed?: boolean }>`
  align-items: center;
  background: ${({ isRed }) => {
    return isRed ? COLORS.red.danger : COLORS.brown.dark[100];
  }};
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: ${({ width }) => width}px;
  transition: all 0.3s ease-in-out;
  padding: 0;
  width: ${({ width }) => width}px;

  path {
    fill: ${COLORS.white[100]};
  }
`;
