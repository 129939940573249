import React, { FC } from 'react';

import useOpenModal from 'helpers/useOpenModal';
import { ROUTE_USER_FOLLOWERS } from 'routes';
import { transformRoute } from 'helpers/routingHelper';
import { TEST_ID_WEB_PROFILE_VIEW_CONNECTIONS_LABEL } from 'constants/aqa/profileConnections';

import { StyledLink } from './styled';

const ConnectionsButton: FC<{ username: string; connectionsCount: number }> = ({ username, connectionsCount }) => {
  const connectionsLink = transformRoute(ROUTE_USER_FOLLOWERS, { profileName: username });
  const handleOpenConnections = useOpenModal(connectionsLink);

  return (
    <StyledLink data-testid={TEST_ID_WEB_PROFILE_VIEW_CONNECTIONS_LABEL} onClick={handleOpenConnections}>
      {`${connectionsCount} connection${connectionsCount > 1 ? 's' : ''}`}
    </StyledLink>
  );
};

export default ConnectionsButton;
