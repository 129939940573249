import React, { FC, useEffect } from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';

import {
  ROUTE_ONBOARDING,
  ROUTE_ONBOARDING_USERNAME,
  ROUTE_ONBOARDING_FULLNAME,
  ROUTE_ONBOARDING_BIO,
  ROUTE_ONBOARDING_IMAGE,
  ROUTE_ONBOARDING_TOPICS,
  ROUTE_ONBOARDING_NOTIFICATIONS,
  ROUTE_ONBOARDING_SUCCESS,
  ROUTE_WELCOME,
  ROUTE_ONBOARDING_WELCOME,
} from 'routes';
import { useCheckOnboardingStatus } from 'graphQL/onboarding/hooks';
import usePageType from 'helpers/usePageType';
import config from 'constants/config';
import Welcome from 'pages/OnboardingV2/Welcome';
import AddImage from './AddImage';
import Bio from './Bio';
import Fullname from './Fullname';
import Notifications from './Notifications';
import Success from './Success';
import Topics from './Topics';
import Username from './Username';
import InviteCode from './InviteCode';

const Onboarding: FC = () => {
  const { onboardingRedirectStep, shouldRedirectFromVerification, user } = useCheckOnboardingStatus();
  const { push, replace, listen } = useHistory();
  const { isOnboardingVerification, isOnboardingWelcome } = usePageType();
  const { areInviteCodesDisabled } = config;

  useEffect(() => {
    if (onboardingRedirectStep && shouldRedirectFromVerification) {
      push(onboardingRedirectStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingRedirectStep, shouldRedirectFromVerification]);

  useEffect(() => {
    return listen((_, action) => {
      if (action === 'POP' && (isOnboardingVerification || isOnboardingWelcome)) {
        localStorage.clear();
        replace(ROUTE_WELCOME);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listen]);

  return (
    <Switch>
      <Route exact path={ROUTE_ONBOARDING}>
        {areInviteCodesDisabled ? <Welcome /> : <InviteCode />}
      </Route>
      {!user?.activatedAt && !areInviteCodesDisabled && <Redirect to={ROUTE_ONBOARDING} />}
      {!areInviteCodesDisabled && (
        <Route exact path={ROUTE_ONBOARDING_WELCOME}>
          <Welcome />
        </Route>
      )}
      <Route path={ROUTE_ONBOARDING_FULLNAME}>
        <Fullname />
      </Route>
      <Route path={ROUTE_ONBOARDING_USERNAME}>
        <Username />
      </Route>
      <Route path={ROUTE_ONBOARDING_BIO}>
        <Bio />
      </Route>
      <Route path={ROUTE_ONBOARDING_IMAGE}>
        <AddImage />
      </Route>
      <Route path={ROUTE_ONBOARDING_TOPICS}>
        <Topics />
      </Route>
      <Route path={ROUTE_ONBOARDING_NOTIFICATIONS}>
        <Notifications />
      </Route>
      <Route path={ROUTE_ONBOARDING_SUCCESS}>
        <Success />
      </Route>
    </Switch>
  );
};

export default Onboarding;
