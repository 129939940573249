import React, { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import usePageType from 'helpers/usePageType';
import { LocationDescriptor } from 'history';
import { TEST_NAVIGATION_CHEVRON_LEFT } from 'constants/aqa/common';
import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import { useGoBack, transformRoute } from 'helpers/routingHelper';
import getAuthUser from 'helpers/getAuthUser';
import { INVITE_FRIENDS_REDIRECT_STORAGE_KEY } from 'constants/common';
import { ROUTE_DISCOVERY, ROUTE_MESSAGES, ROUTE_SAVED_PICKS, ROUTE_USER_PROFILE } from 'routes';
import { StyledButton } from '../styled';

const BackButton: FC<{ steps?: number }> = ({ steps }) => {
  const { profileName: currentProfileUsername } = useParams<{ profileName: string }>();
  const { push, go, replace } = useHistory();
  const { username: authUsername } = getAuthUser();
  const goBack = useGoBack();
  const { state } = useLocation<{
    backUrl: string;
    prevPath: string;
    isGoBack: boolean;
    variable?: { prevReportModalState: LocationDescriptor };
  }>();
  const {
    isShareSheet,
    isMessagesList,
    isMessagesChat,
    isAllPicks,
    isListItem,
    isListDetail,
    isTopics,
    isSavedPicks,
    isArchive,
    isChannelsLists,
  } = usePageType();
  const isPrevPathSearch = state?.prevPath?.includes('search');
  const isPrevPathSparkDetail = state?.prevPath?.includes('/sparks/');
  const isPrevPathFeedDetail = state?.prevPath?.includes('/feed/');

  const ignoreStatePrevPath = isPrevPathSearch || isPrevPathSparkDetail || isSavedPicks || isPrevPathFeedDetail;

  const goBackFromInvite = () => {
    const prevUrl = sessionStorage.getItem(INVITE_FRIENDS_REDIRECT_STORAGE_KEY);

    if (prevUrl) {
      sessionStorage.removeItem(INVITE_FRIENDS_REDIRECT_STORAGE_KEY);
      replace(prevUrl);
    } else {
      goBack();
    }
  };

  const handleBack = () => {
    if (state?.variable?.prevReportModalState) {
      return push(state?.variable?.prevReportModalState);
    }

    if (state?.backUrl) {
      return push(state.backUrl);
    }

    if (state?.prevPath && !ignoreStatePrevPath) {
      return push(state.prevPath);
    }

    // Default previous routes
    if (isMessagesList || isTopics || isSavedPicks || isChannelsLists) {
      return push(ROUTE_DISCOVERY);
    }
    if (isArchive) {
      return push(ROUTE_SAVED_PICKS);
    }
    if (isMessagesChat && !state?.isGoBack) {
      return push(ROUTE_MESSAGES);
    }
    if (isAllPicks) {
      return push(transformRoute(ROUTE_USER_PROFILE, { profileName: currentProfileUsername }));
    }
    if (isListDetail && !isListItem && !isPrevPathSearch) {
      return push(transformRoute(ROUTE_USER_PROFILE, { profileName: authUsername }));
    }

    return !isShareSheet ? goBack() : goBackFromInvite();
  };

  return (
    <StyledButton data-testid={TEST_NAVIGATION_CHEVRON_LEFT} onClick={() => (steps ? go(-steps) : handleBack())}>
      <ChevronLeft />
    </StyledButton>
  );
};

export default BackButton;
