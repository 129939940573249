import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ChatUnionSchema } from 'constants/graphqlTypes';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import useTheme from 'helpers/useTheme';

import { EUiButtonType } from 'components/UI/Button/constants';
import { EReportTypes } from 'components/Report/constants';
import { StyledText } from 'components/UI/OnboardingAlert/styled';
import Button from 'components/UI/Button';
import BlockedMemberWarning from './BlockedMemberWarning';

import { StyledBlockedAlert, StyledButtonWrap } from './styled';
import { useBlockChat } from '../../../graphQL/messages/hooks';
import BlockChatButton from '../ChatSettings/Buttons/BlockChatButton';

const BlockedAlert: FC<{ chat: ChatUnionSchema; setScroll: Dispatch<SetStateAction<boolean>> }> = ({
  chat,
  setScroll,
}) => {
  const { pathname } = useLocation();
  const { id, isBlockedByMe } = chat;
  const isDark = useTheme();
  const toReport = useNavigateToReport({ chatId: id, pathname, type: EReportTypes.Chat });
  const handleBlock = useBlockChat(id, !!isBlockedByMe);

  const isBlockedGroupChat = chat?.__typename === 'GroupChatSchema' && chat.isBlockedByMe;

  useEffect(() => {
    setScroll(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlockedGroupChat]);

  return (
    <>
      {isBlockedGroupChat && (
        <StyledBlockedAlert>
          <StyledText isDark={isDark} isFirst isTransparent>
            You’ve blocked this thread and are unable to receive or send message to this group.
          </StyledText>
          <StyledButtonWrap>
            <Button text="Report" type={EUiButtonType.Primary} isDark={isDark} handler={toReport} />
            <BlockChatButton chat={chat}>
              <Button text="Unblock" type={EUiButtonType.Primary} isDark={isDark} handler={handleBlock} />
            </BlockChatButton>
          </StyledButtonWrap>
        </StyledBlockedAlert>
      )}
      {chat?.__typename === 'SingleChatSchema' && <BlockedMemberWarning chat={chat} />}
    </>
  );
};

export default BlockedAlert;
