import { css } from 'linaria';
import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledContentWrap = styled.div<{ gradientStart?: string; gradientEnd?: string }>`
  background: ${({ gradientStart }) => gradientStart ?? COLORS.transparent};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: 0.5s all ease-in-out;
  min-height: 100dvh;
  overflow: hidden;

  &::before {
    background: ${({ gradientEnd }) =>
      `linear-gradient(${COLORS.transparent} 5%, ${gradientEnd ?? COLORS.black[60]} 100%)`};
    content: '';
    height: 100%;
    left: 0;
    opacity: ${({ gradientStart }) => (gradientStart ? '1' : '0')};
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const StyledContent = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isLoading }) => (isLoading ? 'center' : 'start')};
  align-items: center;
  position: relative;
  height: 100dvh;
`;

export const StyledTextWrap = styled.div`
  box-sizing: content-box;
  padding: 68px 0 43px;
  min-height: 57px;
  width: 268px;
`;
export const StyledText = css`
  min-height: 57px;
  color: ${COLORS.white[100]};
  font: 400 22px/130% ${FONTS.primary};
  text-align: center;
  width: 268px;
  margin: 0;
`;

export const StyledPreviewContainer = styled.div`
  animation: fadein 300ms ease-out forwards;
  border-radius: 8px;
  height: 543px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transform: translateY(100px);
  user-select: none;
  width: 252px;
  -webkit-mask-image: linear-gradient(to bottom, #000 0%, #000 75%, rgba(0, 0, 0, 0));
  -webkit-mask-size: 100% 94%;
  -webkit-mask-repeat: no-repeat;

  @keyframes fadein {
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  > div {
    background: linear-gradient(${COLORS.transparent} 0%, ${COLORS.white[100]} 10%, ${COLORS.white[100]} 100%);
    height: 800px;
    pointer-events: none;
    scale: 0.647;
    transform-origin: top left;
    width: 390px;
  }
`;
