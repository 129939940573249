import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_LIST_DETAIL, ROUTE_USER_ALL_PICKS, ROUTE_MESSAGES } from 'routes';
import UserBlockedWarning from 'components/UserBlockedWarning';
import MenuWrap from 'components/Navigation/RightMenu/MenuWrap';
import Button from 'components/UI/MenuButton';
import BlockButton from 'components/Blocking/UserButton';
import ShareButtons from 'components/Navigation/RightMenu/ShareButtons';
import RemovePick from 'components/RemovePick';

import { useMenuModal } from 'components/Navigation/helpers/hooks';
import { useBlockUserWarning } from 'components/UserBlockedWarning/hooks';
import { EMenuButtonType } from 'components/UI/MenuButton/constants';
import { EReportTypes } from 'components/Report/constants';
import { MessageType, UserObjectType } from 'constants/graphqlTypes';
import { useFollowUserOpenTopics } from 'graphQL/follows/hooks';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import { IThoughtModal } from 'providers/helpers/models';
import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { ReactComponent as MuteIcon } from 'assets/icons/Mute.svg';
import { ReactComponent as BlockIcon } from 'assets/icons/Block.svg';
import { ReactComponent as GridIcon } from 'assets/icons/Grid.svg';
import { ReactComponent as SmileyIcon } from 'assets/icons/Smiley.svg';

import MuteUserButton from 'components/Muting/MuteUserButton';
import MoveCardButtons from 'components/Navigation/RightMenu/MoveCardButtons';
import RemoveCardButton from 'components/Navigation/RightMenu/RemoveCardButton';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import getAuthUser from 'helpers/getAuthUser';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { useThought } from 'helpers/useContextMenu';
import { useGetComments } from 'graphQL/card/comments/hooks';
import { useListsWithCard } from 'graphQL/list/hooks';
import { checkIsAdmin } from 'components/Navigation/RightMenu/CardButton/helpers';

import { StyledSettingsLabel } from 'components/Navigation/styled';
import { StyledListItem, StyledList } from 'components/Navigation/RightMenu/CardButton/styled';
import { StyledWrap, StyledItem } from './styled';

interface IThoughtMenuProps {
  commentId: string;
  cardId: string;
  cardPickId: string;
  user: UserObjectType;
  allowReport?: boolean;
  setIsOptionsMenuOpened?: Dispatch<SetStateAction<boolean>>;
  hasReactions?: boolean;
  chatId?: string;
}

const ThoughtMenu: FC<IThoughtMenuProps> = ({
  commentId,
  cardPickId,
  cardId,
  user,
  allowReport = true,
  setIsOptionsMenuOpened,
  hasReactions,
  chatId,
}) => {
  const { push } = useHistory();

  const { pathname, state } = useLocation<{ variable: IThoughtModal; prevPath?: string }>();
  const { prevPath } = state ?? {};
  const [isModalOpen, setModalOpen] = useState(false);
  const [typeOfCard, setTypeOfCard] = useState('');
  const { username, userId } = getAuthUser();
  const toReport = useNavigateToReport({ commentId, pathname, type: EReportTypes.Thought, cardId });
  const { id, isFollowedByMe, isBlockedByMe, isMutedByMe, userInfo } = user;
  const { card } = useGetDetailCard(cardPickId);

  const { listCardIdsWithCard } = useListsWithCard(cardId);
  const { isPickedByMe, parentCards, permissions, pickId } = card ?? {};

  const { totalCount: myThought } = useGetComments(pickId ?? '', { referrerUserId: userId });
  const { Icon: ThoughtIcon, text: thoughtText, toggle: openThought } = useThought(
    cardPickId,
    cardId,
    undefined,
    !!myThought
  );

  const { id: parentListId, cardId: listCardId, title: parentListTitle } = parentCards?.items[0] ?? {};
  const isMyListPrev = prevPath?.includes('/lists') && parentListId && prevPath?.includes(parentListId);
  const showViewInYourList = isPickedByMe && !!parentListId && !isMyListPrev;
  const isAdmin = checkIsAdmin(permissions);
  const { handleModal } = useMenuModal();

  const onMuteUser = () => {
    handleModal(false);
    setModalOpen(false);
  };

  const { handleFollowWithChecks } = useFollowUserOpenTopics(user);

  const { showBlockWarning, closeModal, checkUserBlock } = useBlockUserWarning(handleFollowWithChecks, user);

  const handleThought = () => {
    handleModal(false);
    openThought?.();
  };

  const handleViewMyList = () => {
    push(
      transformRoute(parentListId ? ROUTE_LIST_DETAIL : ROUTE_USER_ALL_PICKS, {
        listId: parentListId,
        profileName: username,
      })
    );
  };

  const handleOpenInMessages = () => {
    if (!chatId) {
      return null;
    }
    return push(`${ROUTE_MESSAGES}/${chatId}`);
  };

  useEffect(() => {
    setIsOptionsMenuOpened?.(isModalOpen);
  }, [isModalOpen]);

  return (
    <StyledWrap>
      <MenuWrap isOpen={isModalOpen} handleModal={setModalOpen} isPortal>
        <ShareButtons
          messageType={MessageType.Thought}
          sharedId={commentId}
          commentCardId={cardPickId}
          closePreviousModal={() => setModalOpen(false)}
          userId={id}
        />
        {hasReactions && (
          <StyledItem>
            <Button text="Open in messages" handler={handleOpenInMessages} Icon={SmileyIcon} hasStroke />
          </StyledItem>
        )}
        {allowReport && (
          <>
            <StyledItem>
              <Button
                text={`Following ${userInfo?.firstName} ${userInfo?.lastName}`}
                handler={checkUserBlock}
                type={EMenuButtonType.Toggle}
                checked={!!isFollowedByMe}
                Icon={UserIcon}
                hasTruncate
              />
            </StyledItem>
            <BlockButton userId={id} isBlockedByMe={!!isBlockedByMe} handler={() => handleModal(false)}>
              <StyledItem>
                <Button
                  checked={!!isBlockedByMe}
                  Icon={BlockIcon}
                  text={`${isBlockedByMe ? 'Unblock' : 'Block'} user`}
                />
              </StyledItem>
            </BlockButton>
            <MuteUserButton
              userId={id}
              isMutedByMe={!!isMutedByMe}
              fullname={generateFullName(user)}
              handler={onMuteUser}
            >
              <StyledItem>
                <Button text={`${isMutedByMe ? 'Unmute' : 'Mute'} user`} Icon={MuteIcon} />
              </StyledItem>
            </MuteUserButton>
            <StyledItem>
              <Button text="Report thought" Icon={ReportIcon} handler={toReport} />
            </StyledItem>
          </>
        )}
        {showBlockWarning && <UserBlockedWarning isBlockedByMe={false} handleClose={closeModal} />}
        {(user.id === userId || isAdmin) && (
          <StyledListItem>
            <StyledList>
              <StyledSettingsLabel>More options</StyledSettingsLabel>
              <StyledItem>
                <Button text={thoughtText} Icon={ThoughtIcon} handler={handleThought} />
              </StyledItem>
              {isPickedByMe && card && (
                <MoveCardButtons
                  closePreviousModal={() => handleModal(false)}
                  isCardInAnotherList={false}
                  card={card}
                  parentListCardId={listCardId}
                  parentListTitle={parentListTitle}
                  handleViewInMyList={handleViewMyList}
                />
              )}
              {showViewInYourList && (
                <StyledItem>
                  <Button text="View in your list" Icon={GridIcon} handler={handleViewMyList} />
                </StyledItem>
              )}
              {card && <RemoveCardButton card={card} setTypeOfCard={setTypeOfCard} />}
            </StyledList>
          </StyledListItem>
        )}
        {typeOfCard && card && (
          <RemovePick
            card={card}
            handleCancel={() => setTypeOfCard('')}
            handleClose={() => handleModal(false)}
            listCardId={(listCardId || card?.cardId) ?? ''}
            listCardsIds={listCardIdsWithCard}
            typeOfCard={typeOfCard}
            setTypeOfCard={setTypeOfCard}
          />
        )}
      </MenuWrap>
    </StyledWrap>
  );
};

export default ThoughtMenu;
