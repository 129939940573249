import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { generateName } from 'components/Profile/ProfileTop/helpers';
import { IUserTopicsLocation, IUseTopicsModalText } from './models';

export const useTopicsModalText = ({
  topicsLength,
  loading,
}: IUseTopicsModalText): { title: string; subtitle: string } => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');

  const { state } = useLocation<IUserTopicsLocation>();
  const {
    user: { username, userInfo },
    isAfterFollow,
  } = state?.variable ?? {};
  const { firstName, lastName } = userInfo ?? {};

  const isTopicsSelection = !isAfterFollow && topicsLength;

  useEffect(() => {
    const name = generateName(username, firstName, isTopicsSelection ? undefined : lastName);

    if (isAfterFollow) {
      setTitle(`You’re now following ${name}`);
      setSubtitle('Choose the topics you want to see from them. You will automatically see new topics they share.');
    }
    if (isTopicsSelection) {
      setTitle(`Choose the topics you see from ${name}`);
      setSubtitle('You will automatically see new topics they share.');
    }
    if (!isAfterFollow && !topicsLength && !loading) {
      setTitle(`${name} hasn’t added any picks yet`);
      setSubtitle(
        'When they do, you will automatically see the topics they share. You can come back here to choose the topics you see.'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, topicsLength, isAfterFollow]);

  return { title, subtitle };
};
