import React, { FC, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import {
  ROUTE_ADD_ANSWER,
  ROUTE_ANSWER_ADD_TO_LIST,
  ROUTE_ANSWER_ADD_STYLE,
  ROUTE_ANSWER_ADD_THOUGHT,
  ROUTE_ANSWER_ADD_LIST,
} from 'routes';
import { CardStyleSchema } from 'constants/graphqlTypes';
import { useGoBack } from 'helpers/routingHelper';
import { COLORS } from 'styles/constants';

import { useExtractData, useNavigateFromCreatedList } from 'components/AddContent/helpers/hooks';

import AddStyle from 'components/AddAnswer/AddStyle';

import AddLink from 'components/AddAnswer/AddLink';
import { ALL_PICKS } from 'constants/common';
import CreateList from 'components/AddContent/CreateList';
import AddToList from './AddToList';
import AddThought from './AddThought';
import { useAnswerLinks } from './hooks';

const AddAnswer: FC = () => {
  const [url, setUrl] = useState('');

  const [isPicked, setIsPicked] = useState(false);
  const [listToAdd, setListToAdd] = useState<string>('');
  const [listTitle, setListTitle] = useState(ALL_PICKS);

  const [style, setStyle] = useState<CardStyleSchema | undefined>();
  const [styleItemId, setStyleItemId] = useState(0);
  const [color, setColor] = useState(COLORS.transparent);

  const { addAnswerToList } = useAnswerLinks();

  const { pickId, cardId, title, image, setExtractData } = useExtractData();

  const { replace } = useHistory();

  const goBack = useGoBack();

  const handleCreateListCompleted = useNavigateFromCreatedList(setListTitle, setListToAdd);

  return (
    <Switch>
      <Route exact path={ROUTE_ADD_ANSWER}>
        <AddLink
          setIsPicked={setIsPicked}
          fadeIn
          url={url}
          setUrl={setUrl}
          setExtractData={setExtractData}
          handleClose={goBack}
        />
      </Route>
      <Route path={ROUTE_ANSWER_ADD_STYLE}>
        <AddStyle
          itemId={styleItemId}
          setItemId={setStyleItemId}
          preExtractTitle={title}
          preExtractImage={image}
          setStyle={setStyle}
          cardId={cardId}
          pickId={pickId}
          handleClose={goBack}
          listTitle={listTitle}
          color={color}
          setColor={setColor}
        />
      </Route>
      <Route path={ROUTE_ANSWER_ADD_LIST}>
        <CreateList handleClose={handleCreateListCompleted} handlePrev={() => replace(addAnswerToList)} />
      </Route>
      <Route path={ROUTE_ANSWER_ADD_TO_LIST}>
        <AddToList
          pickId={pickId}
          setExtractData={setExtractData}
          setListToAdd={setListToAdd}
          setListTitle={setListTitle}
          handleClose={goBack}
        />
      </Route>
      <Route path={ROUTE_ANSWER_ADD_THOUGHT}>
        <AddThought
          pickId={pickId}
          cardId={cardId}
          listCardId={listToAdd}
          cardStyle={style}
          handleClose={goBack}
          listTitle={listTitle}
          isPicked={isPicked}
        />
      </Route>
    </Switch>
  );
};

export default AddAnswer;
