import { ApolloCache } from '@apollo/client';
import {
  ChannelAdminFeedSchema,
  ChannelCustomFeedSchema,
  ChannelSectionItemsSortTypeOptions,
  GetChannelSectionItemsDocument,
  GetChannelSectionItemsQuery,
} from 'constants/graphqlTypes';
import { SECTION_ITEMS_LIMIT } from '../constants';

export const getSortingTitle = (type?: ChannelSectionItemsSortTypeOptions): string => {
  switch (type) {
    case ChannelSectionItemsSortTypeOptions.RecentlyUpdated:
      return 'Updated';
    case ChannelSectionItemsSortTypeOptions.Created:
      return 'Date';
    case ChannelSectionItemsSortTypeOptions.AlphaAsc:
      return 'Alphabetical (A-Z)';
    case ChannelSectionItemsSortTypeOptions.Custom:
      return 'Custom';
    default:
      return '';
  }
};

export const updateFeedsOrder = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
  sectionId: string,
  orderedFeeds: (ChannelCustomFeedSchema | ChannelAdminFeedSchema)[],
  sorting?: ChannelSectionItemsSortTypeOptions
): void => {
  cache.updateQuery(
    {
      query: GetChannelSectionItemsDocument,
      variables: { sectionId, limit: SECTION_ITEMS_LIMIT, sorting },
    },
    (data: GetChannelSectionItemsQuery | null) => {
      return {
        ...data,
        channelSectionItemsV2: {
          ...data?.channelSectionItemsV2,
          totalCount: data?.channelSectionItemsV2?.totalCount ? data?.channelSectionItemsV2?.totalCount : 0,
          items: orderedFeeds,
        },
      };
    }
  );
};
