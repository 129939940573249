import React, { FC } from 'react';
import { format } from 'date-fns';

import Avatars from 'components/UI/Avatars';
import Attribute from 'components/UI/Attribute';
import UILink from 'components/UI/Link';
import getImages from 'helpers/getImages';

import { CardSchema, Maybe, UserListObjectType } from 'constants/graphqlTypes';
import { ROUTE_GUEST_LIST_DETAIL, ROUTE_QUESTION_OF_THE_DAY } from 'routes';
import { StyledThumbnail } from 'components/Card/QuestionAttribute/styled';
import {
  StyledSubtitle,
  StyledTitle,
  StyledWrap,
  StyledAvatarWrap,
  StyledAttributeWrap,
  StyledThumbnailWrap,
} from './styled';

interface IQuestionHeaderProps {
  title?: string;
  createdAt: string | number | Date;
  usersWhoAnswered?: Maybe<UserListObjectType>;
  questionGuestList?: CardSchema | null;
  isDark: boolean;
}

const QuestionHeader: FC<IQuestionHeaderProps> = ({
  title,
  createdAt,
  usersWhoAnswered,
  questionGuestList,
  isDark,
}) => {
  const date = format(new Date(createdAt), 'MMM d, y');

  const { imageUrl } = getImages(questionGuestList?.cardStyle);
  const { id, title: guestListTitle } = questionGuestList ?? {};

  return (
    <StyledWrap>
      {!questionGuestList && (
        <UILink route={ROUTE_QUESTION_OF_THE_DAY}>
          <StyledSubtitle isDark={isDark}>
            <b>Question of the day</b> – {date}
          </StyledSubtitle>
        </UILink>
      )}
      <StyledTitle>{title}</StyledTitle>
      {questionGuestList && (
        <StyledAttributeWrap>
          <Attribute
            title="The guest:list"
            text={`with ${guestListTitle}`}
            route={ROUTE_GUEST_LIST_DETAIL}
            params={{ id }}
            isDark={isDark}
            isFullBleed
          >
            <StyledThumbnailWrap>
              <StyledThumbnail src={imageUrl} />
            </StyledThumbnailWrap>
          </Attribute>
        </StyledAttributeWrap>
      )}
      <StyledAvatarWrap>
        <Avatars
          users={usersWhoAnswered?.items ?? []}
          limit={6}
          hideNumber={false}
          isBig
          total={usersWhoAnswered?.totalCount ?? 0}
        />
      </StyledAvatarWrap>
    </StyledWrap>
  );
};

export default QuestionHeader;
