import React, { FC } from 'react';

import useTheme from 'helpers/useTheme';
import ListsCards from 'components/Lists';

import { TEST_SEARCH_RESULTS_LISTS_PAGE, TEST_SEARCH_RESULTS_LISTS_PAGE_EMPTY_LABEL } from 'constants/aqa/search';
import { useSearchLists } from 'graphQL/search/hooks';
import { StyledListsWrap } from './styled';

import EmptySearchMessage from './EmptySearchMessage';

const Lists: FC<{ searchText: string }> = ({ searchText }) => {
  const { items, loadMore, loading } = useSearchLists(searchText);

  const isDark = useTheme();

  return (
    <>
      {!loading && !items.length && (
        <EmptySearchMessage dataTestId={TEST_SEARCH_RESULTS_LISTS_PAGE_EMPTY_LABEL} isDark={isDark} />
      )}
      <StyledListsWrap>
        <ListsCards
          pageTestId={TEST_SEARCH_RESULTS_LISTS_PAGE}
          isDark={isDark}
          items={items}
          loading={loading}
          loadMore={loadMore}
        />
      </StyledListsWrap>
    </>
  );
};

export default Lists;
