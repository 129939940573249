import React, { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import Modal from 'components/Modal';
import Button from 'components/UI/MenuButton';
import getAuthUser from 'helpers/getAuthUser';
import LoaderContent from 'components/UI/LoaderContent';

import { useGetCreators } from 'graphQL/card/creators/hook';
import { useEditList } from 'helpers/useContextMenu';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { IAddContentLocation } from 'components/AddContent/helpers/models';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { ROUTE_LIST_DETAIL, ROUTE_LIST_INVITE_COLLABORATORS, ROUTE_USER_PROFILE } from 'routes';
import { transformRoute } from 'helpers/routingHelper';
import { StyledTitle } from '../styled';
import { StyledList } from './styled';

import Collaborator from './Collaborator';

const EditListCollaborators: FC<{ navigateToEditList: () => void }> = ({ navigateToEditList }) => {
  const { state } = useLocation<IAddContentLocation | undefined>();
  const { fromAvatars } = state?.variable || {};
  const { listId } = useParams<{ listId: string }>();
  const { replace, goBack } = useHistory();

  const { card } = useGetDetailCard(listId);
  const { userId, username } = getAuthUser();

  const listUserId = card?.picks?.items.find((item) => item.id === listId)?.userId;

  const { condition: isUserAnAdmin } = useEditList(card);

  const handleClose = (hasRedirectToProfile?: boolean) =>
    replace(
      transformRoute(hasRedirectToProfile ? ROUTE_USER_PROFILE : ROUTE_LIST_DETAIL, { listId, profileName: username })
    );
  const { creators, loadMore, loading } = useGetCreators();

  const handleCloseModal = () =>
    creators.find((creator) => creator.userId === userId) ? navigateToEditList() : handleClose();

  const onClose = () => (fromAvatars ? goBack() : handleCloseModal());

  return (
    <Modal fadeIn={false} handleClose={onClose} handlePrev={isUserAnAdmin ? onClose : undefined}>
      {!loading && (
        <StyledTitle>
          {creators.length === 1 ? 'Invite people to collaborate on this list' : 'Roles and permissions'}
        </StyledTitle>
      )}
      <StyledList>
        {!loading &&
          creators.length !== 1 &&
          creators.map(
            ({ user, roleName }) =>
              user &&
              roleName && (
                <li key={user.id}>
                  <Collaborator
                    authUserId={userId}
                    creators={creators}
                    isUserAViewier={!card?.permissions?.length}
                    isUserAnAdmin={isUserAnAdmin}
                    user={user}
                    listUserId={listUserId}
                    role={roleName}
                    handleClose={() => handleClose(true)}
                  />
                </li>
              )
          )}
        {loading && !creators.length && <LoaderContent />}
        <Waypoint onEnter={loadMore} topOffset="200px" />
      </StyledList>
      {!!isUserAnAdmin && (
        <Button
          text="Invite people"
          Icon={PlusIcon}
          hasStroke
          handler={() => replace({ pathname: transformRoute(ROUTE_LIST_INVITE_COLLABORATORS, { listId }), state })}
        />
      )}
    </Modal>
  );
};

export default EditListCollaborators;
