import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

import { StyledWrap as StyledFullBleedWrap } from '../FullBleed/styled';

export const StyledWrap = styled(StyledFullBleedWrap)`
  background-position: center;
  background-size: cover;
  gap: 12px;
  min-height: 640px;
  padding: 40px 24px 24px;
  cursor: pointer;
`;

export const StyledComment = styled.div`
  margin-top: auto;
  padding-top: 32px;
`;

export const StyledImage = styled.img`
  height: 100%;
  object-fit: cover;
  transition: transform 1s ease-in-out;
  width: 100%;
`;

export const StyledImageContainer = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::after {
    bottom: 0;
    background-color: ${COLORS.black[60]};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const StyledContainer = styled.div`
  overflow: hidden;
  position: relative;

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
`;
