import { CardSchema, CardStyleInput, ECardLayoutOptions } from 'constants/graphqlTypes';

import getStyles from 'helpers/getStyles';
import getImages from 'helpers/getImages';

import { getPreExtractImage } from 'components/AddContent/helpers/helpers';

export const setStyleInput = (item?: CardSchema): CardStyleInput => {
  const { cardStyle } = item ?? {};
  const { layout, bgColor, isDefaultStyle } = getStyles(cardStyle);
  const { imageSet } = getImages(cardStyle);

  const isImageExist = imageSet?.imageId !== getPreExtractImage().imageId;
  const thumbnailImageSetId = isImageExist ? imageSet?.imageId : null;
  const styledLayout = isImageExist ? layout : ECardLayoutOptions.Plain;

  return { layout: styledLayout, bgColor, thumbnailImageSetId, isDefaultStyle };
};
