import React, { Dispatch, FC, SetStateAction } from 'react';

import { CardSchema } from 'constants/graphqlTypes';
import { StyledShareButtons } from 'pages/OffPlatformSharing/ShareSheet/styled';
import { GUEST_LIST_GRADIENT } from 'styles/constants';

import GuestListStylesItem from '../GuestList/GuestListStylesItem';
import { StyledListItem, StyledListWrap } from './styled';

const SharingStylesList: FC<{
  currentStyle: string;
  styles: string[];
  guestList?: CardSchema | null;
  setStyle: Dispatch<SetStateAction<string>>;
}> = ({ currentStyle, styles, guestList, setStyle }) => (
  <StyledListWrap>
    <StyledShareButtons>
      {guestList && (
        <StyledListItem
          isActive={!currentStyle.includes('url')}
          background={GUEST_LIST_GRADIENT}
          onClick={() => setStyle(' ')}
        >
          <GuestListStylesItem guestList={guestList} />
        </StyledListItem>
      )}
      {styles.map((style) => (
        <StyledListItem
          isActive={currentStyle === style}
          key={style}
          background={style}
          onClick={() => setStyle(style)}
        />
      ))}
    </StyledShareButtons>
  </StyledListWrap>
);

export default SharingStylesList;
