import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.button<{ isDark: boolean; isSingle: boolean; isDisabled: boolean }>`
  align-items: center;
  background: ${({ isDark }) => (isDark ? `${COLORS.black[100]}` : `${COLORS.white[100]}`)};
  border-radius: 14px;
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.white.off[100])};
  cursor: pointer;
  display: flex;
  font: 12px/140% ${FONTS.secondary};
  gap: 4px;
  height: 26px;
  justify-content: center;
  min-width: 26px;
  padding: ${({ isSingle }) => (isSingle ? '3px' : '7px')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  > .token-item {
    position: relative;
    white-space: nowrap;
    color: ${({ isDark }) => (isDark ? `${COLORS.white[100]}` : `${COLORS.brown.dark[100]}`)};
  }
`;
