import { styled } from 'linaria/react';

import { StyledUserInfo } from 'components/UI/Highlight/styled';
import { StyledCommentInfo } from 'components/Card/Actions/styled';
import { COLORS } from '../../../styles/constants';

export const StyledWrap = styled.div<{ isFullBleed?: boolean }>`
  padding-top: ${({ isFullBleed }) => (isFullBleed ? 8 : 24)}px;
`;

export const StyledQuestionWrap = styled.div<{ isDark: boolean; isFullBleed?: boolean; hasBorder?: boolean }>`
  border-top: ${({ hasBorder }) => (hasBorder ? '1px' : 0)} solid
    ${({ isFullBleed, isDark }) => (isDark || isFullBleed ? COLORS.white[10] : COLORS.white.off[100])};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  margin: 0 ${({ hasBorder }) => (hasBorder ? 'auto' : 0)};
  width: ${({ isFullBleed }) => (isFullBleed ? 'calc(100% - 24px)' : '100%')};
  padding: ${({ hasBorder }) => (hasBorder ? '22px' : '8px')} ${({ isFullBleed }) => (isFullBleed ? 0 : '12px')} 0;
`;

export const StyledQuestionInfo = styled(StyledCommentInfo)`
  font-size: 14px;
  min-height: auto;
`;

export const StyledQuestionHeader = styled(StyledUserInfo)``;
