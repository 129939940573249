import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IBlockedByAdminReason, IExtractionError } from 'components/AddContent/AddPick/AddPick';
import { ILink, ISetExtractData } from 'components/AddContent/helpers/models';
import { useExtraction } from 'graphQL/addContent/extraction/hook';
import { useLazyGetCardId } from 'graphQL/card/helpers/hooks';
import getValidationError from './getValidationError';

interface IUseExtractionProcessArgs {
  url: string;
  replaceRouteAfterExtract: ILink;
  replaceRouteAfterPickExist: ILink;
  setIsPicked: Dispatch<SetStateAction<boolean>>;
  setExtractData: ({ extractPickId, extractCardId, extractTitle, extractImage }: ISetExtractData) => void;
}
interface IUseExtractionProcess {
  error: string;
  extractionError: IExtractionError | null;
  loading: boolean;
  reason: IBlockedByAdminReason;
  checkPickExist: (externalLink: string) => void;
}

const useExtractionProcess = ({
  setExtractData,
  setIsPicked,
  replaceRouteAfterExtract,
  replaceRouteAfterPickExist,
  url,
}: IUseExtractionProcessArgs): IUseExtractionProcess => {
  const { replace } = useHistory();

  const { extract, id, cardId, title, image, loading } = useExtraction(url);

  const [reason, setReason] = useState<IBlockedByAdminReason>({});
  const [error, setError] = useState('');
  const [extractionError, setExtractionError] = useState<IExtractionError | null>(null);

  useEffect(() => {
    setExtractData({ extractPickId: id, extractCardId: cardId, extractImage: image, extractTitle: title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, cardId, title, image]);

  const extractCallback = () =>
    extract()
      .then(({ data }) => {
        if (data && data.startExtractCard?.__typename === 'ExtractionErrorSchema') {
          const { errorType, reasonTitle, reasonDescription } = data?.startExtractCard;
          setReason({ reasonTitle, reasonDescription });
          return setExtractionError({ errorType });
        }
        if (
          data?.startExtractCard?.__typename === 'PreExtractCardSchema' ||
          data?.startExtractCard?.__typename === 'CardSchema'
        ) {
          const { isPrivateSource } = data?.startExtractCard;
          if (isPrivateSource) {
            return setExtractionError({ isPrivateSource });
          }
        }
        return replace(replaceRouteAfterExtract);
      })
      .catch((e) => setError(getValidationError(e)));

  const { checkPickExist, pickId, cardId: existedCardId, isAvailable, isPickedByMe } = useLazyGetCardId(
    extractCallback
  );

  useEffect(() => {
    if (!isAvailable && existedCardId) {
      setIsPicked(isPickedByMe);
      setExtractData({ extractPickId: pickId, extractCardId: existedCardId });
      replace(replaceRouteAfterPickExist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickId, isAvailable, existedCardId]);

  useEffect(() => {
    setError('');
  }, [url]);

  return {
    reason,
    error,
    extractionError,
    checkPickExist,
    loading,
  };
};

export default useExtractionProcess;
