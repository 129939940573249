import useTheme from 'helpers/useTheme';
import React, { FC, useEffect } from 'react';

import 'assets/fonts/primary/font-face.css';
import 'assets/fonts/secondary/font-face.css';

import StyledGlobalStyle from './styled';

const GlobalStyle: FC = () => {
  const isDark = useTheme();

  useEffect(() => {
    document.body.classList.add(isDark ? 'dark' : 'light');
    document.body.classList.remove(isDark ? 'light' : 'dark');
  }, [isDark]);

  return <StyledGlobalStyle />;
};

export default GlobalStyle;
