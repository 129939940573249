import React, { FC } from 'react';

import { ROUTE_INVITE_USER_SHARING } from 'routes';
import Avatar from 'components/UI/Avatar/Avatar';
import UILink from 'components/UI/Link';

import { useProfile } from 'graphQL/profile/hooks';
import { ReactComponent as Invite } from 'assets/icons/InviteToGH.svg';
import { StyledLinkTitle, StyledSubtitle, StyledText } from 'pages/OffPlatformSharing/styled';

import { StyledLinkContent, StyledLinkWrap, StyledShareWrap } from './styled';

const InviteBlock: FC<{ isDark?: boolean }> = ({ isDark }) => {
  const { userData } = useProfile();

  return (
    <StyledLinkWrap>
      <UILink route={ROUTE_INVITE_USER_SHARING}>
        <StyledLinkContent isDark={isDark}>
          {userData && <Avatar user={userData} />}
          <StyledText>
            <StyledLinkTitle>Invite friends to guide:human</StyledLinkTitle>
            <StyledSubtitle>guidehuman.co/join</StyledSubtitle>
          </StyledText>
          <StyledShareWrap>
            <Invite />
          </StyledShareWrap>
        </StyledLinkContent>
      </UILink>
    </StyledLinkWrap>
  );
};

export default InviteBlock;
