import { WEB_PREFIX } from 'constants/common';

export const SETTINGS_VIEW = 'settings-view';

export const TEST_ID_SETTINGS_START = `${WEB_PREFIX}${SETTINGS_VIEW}`;
export const TEST_SETTINGS_TITLE = `${TEST_ID_SETTINGS_START}_title-label`;

export const TEST_SETTINGS_ACTION_START = `${TEST_ID_SETTINGS_START}_action`;
export const TEST_SETTINGS_ACCOUNT_MANAGEMENT = `${TEST_SETTINGS_ACTION_START}_account-management`;
export const TEST_SETTINGS_ACCESSIBILITY = `${TEST_SETTINGS_ACTION_START}_accessibility`;
export const TEST_SETTINGS_NOTIFICATIONS = `${TEST_SETTINGS_ACTION_START}_notifications`;
export const TEST_SETTINGS_PRIVACY_AND_DATA = `${TEST_SETTINGS_ACTION_START}_privacy-and-data`;
