import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export const AutoScrollContext = createContext({});

export const { Provider: AutoScrollProvider } = AutoScrollContext;

interface IUseAutoScrollContext {
  isAutoScrollStarted?: boolean;
  setAutoScrollStarted?: Dispatch<SetStateAction<boolean>>;
}

export const useAutoScrollContext = (): IUseAutoScrollContext => {
  const context = useContext<IUseAutoScrollContext>(AutoScrollContext);

  return context;
};
