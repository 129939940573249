import { styled } from 'linaria/react';

import ImportTitle from 'components/UI/ContentTitle/styled';
import ImportWrap from 'components/UI/Wrap/styled';

import { BREAKPOINTS, COLORS } from 'styles/constants';

export const StyledHeader = styled.header<{ bg?: string }>`
  background-image: ${({ bg }) => `url(${bg ?? ''})`};
  background-size: cover;
  background-position: center;
  height: ${({ bg }) => (bg ? 390 : 60)}px;
  width: 100%;
`;

export const StyledAnnotation = styled(ImportTitle)`
  display: flex;
  gap: 8px;
  font-size: 14px;
  white-space: nowrap;
`;

export const StyledSeries = styled(StyledAnnotation)`
  color: ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.light[100])};
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledTitle = styled(ImportTitle)`
  cursor: default;
  font-size: 32px;
`;

export const StyledWrap = styled(ImportWrap)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px 24px 48px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 32px 12px 48px;
  }
`;
