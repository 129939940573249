import React, { Dispatch, FC, SetStateAction } from 'react';
import { ReactComponent as AddReaction } from 'assets/icons/AddReaction.svg';
import { StyledRelWrap, StyledWrap } from './styled';
import ReactionMenu from '../ReactionMenu/ReactionMenu';

interface ReactionQuickButtonProps {
  entityId: string;
  setIsReactionsModalOpen: Dispatch<SetStateAction<boolean>>;
  isDark?: boolean;
  isReactionButtonMenuShown: boolean;
  setIsReactionButtonMenuShown: Dispatch<SetStateAction<boolean>>;
  isReactionMenuOpened?: boolean;
  offset?: number;
  className?: string;
}

const ReactionQuickButton: FC<ReactionQuickButtonProps> = ({
  entityId,
  setIsReactionsModalOpen,
  isDark,
  isReactionButtonMenuShown,
  setIsReactionButtonMenuShown,
  isReactionMenuOpened,
  offset,
  className,
}) => {
  return (
    <StyledRelWrap>
      <StyledWrap
        className={className}
        isDark={!!isDark}
        isToolTipShown={isReactionButtonMenuShown}
        data-tooltip-id={entityId}
        data-tooltip-place="top"
        onClick={() => !isReactionMenuOpened && setIsReactionButtonMenuShown(true)}
      >
        <div>
          <AddReaction viewBox="0 0 24 24" height={24} width={24} />
        </div>
      </StyledWrap>
      {isReactionButtonMenuShown && (
        <ReactionMenu
          isShown={isReactionButtonMenuShown}
          handleClickOutside={() => setIsReactionButtonMenuShown(false)}
          domElementId={entityId}
          offset={offset ?? 4}
          setIsReactionsModalOpen={setIsReactionsModalOpen}
          setIsQuickReactionOpen={setIsReactionButtonMenuShown}
        />
      )}
    </StyledRelWrap>
  );
};

export default ReactionQuickButton;
