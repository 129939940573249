interface IGetAuthUser {
  userId: string;
  username: string;
  token: string;
  email: string;
  isOnboardingCompleted: boolean;
  cardId: string;
}

const getAuthUser = (): IGetAuthUser => {
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const { authToken } = userData;

  return {
    userId: authToken?.userAuth?.id,
    username: authToken?.userAuth?.username,
    token: authToken?.token,
    email: authToken?.userAuth?.email,
    isOnboardingCompleted: authToken?.isOnboardingCompleted,
    cardId: authToken?.cardId,
  };
};

export default getAuthUser;
