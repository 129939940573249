import { styled } from 'linaria/react';

import { BREAKPOINTS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  padding: 0 24px 48px;

  a {
    display: block;
    padding: 12px 0;
    width: fit-content;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0 12px 48px;
  }
`;

export const StyledTitle = styled.span`
  display: block;
  font-size: 22px;
  word-break: break-word;

  span {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const StyledAnnotation = styled.span`
  font-family: ${FONTS.secondary};
  font-size: 14px;

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.with-trancate {
      display: -webkit-box;
      white-space: normal;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  b {
    font-weight: 550;
  }
`;
