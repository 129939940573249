import { RefObject, useEffect, useRef, useState } from 'react';

import { getLines } from './helpers';

export const useTruncatedLines = (): { truncatedLines: number; containerRef: RefObject<HTMLDivElement> } => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [truncatedLines, setTruncatedLines] = useState(3);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const height = entries?.[0].borderBoxSize[0].blockSize;
      setTruncatedLines(getLines(height));
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  return { containerRef, truncatedLines };
};
