import React, { FC } from 'react';

import Card from 'components/Card';
import useOpenHiddenCards from 'helpers/useOpenHiddenCards';

import { CardSchema } from 'constants/graphqlTypes';
import { StyledCardWrap } from './styled';

interface ISavedPicksItemProps {
  item: CardSchema;
  isSelectable: boolean;
  isChecked: boolean;
  isDark: boolean;
  handler?: (cardId: string) => void;
}

const SavedPicksItem: FC<ISavedPicksItemProps> = ({ isSelectable, isChecked, handler, item, isDark }) => {
  const { cardId, isHidden } = item;
  const { openIds } = useOpenHiddenCards();

  const allowSelect = !isHidden || !!openIds?.includes(cardId);

  return (
    <StyledCardWrap
      showCheck={isSelectable && isChecked}
      onClick={() => isSelectable && allowSelect && handler?.(cardId)}
      isSelectable={allowSelect && isSelectable}
    >
      <Card disableLinks={!!isSelectable} card={item} shouldDisableLongTap showActivity={false} isDark={isDark} />
    </StyledCardWrap>
  );
};

export default SavedPicksItem;
