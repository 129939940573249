import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from '../../styles/constants';
import { BUTTON_STYLES, EUiButtonType } from '../UI/Button/constants';

export const StyledTitle = styled.span`
  padding: 48px 0 40px;
  font: 400 22px/130% ${FONTS.primary};
`;

export const StyledGoogleLink = styled.a`
  text-decoration: none;
`;

export const StyledInputScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 48px 0 32px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    gap: 16px;
  }
`;

export const StyledFooter = styled.p`
  font-family: ${FONTS.secondary};
  font-size: 12px;
  line-height: 16.8px;
  margin: 0 auto;
  max-width: 325px;
  padding: 24px 0 33px;
  text-align: center;

  a {
    color: ${COLORS.white[100]};
  }
`;

export const StyledButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: auto;
  padding: 0 0 var(--keyboard-offset);

  > button {
    :disabled {
      background: ${() => BUTTON_STYLES.background[EUiButtonType.Emphasized].default.dark};
      color: ${() => BUTTON_STYLES.color[EUiButtonType.Emphasized].default.dark};
    }
  }
`;
