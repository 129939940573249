import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledLink = styled.div<{ disabled?: boolean }>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  gap: 8px;
  max-width: ${BREAKPOINTS.s}px;
  overflow: hidden;
  padding: 0;
  flex-grow: 1;
  text-decoration: none;
`;

export const StyledUsername = styled.span<{ isDark?: boolean; isTyping?: boolean }>`
  align-items: center;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  font-weight: 550;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledMore = styled(StyledUsername)`
  min-width: 84px;
`;

export const StyledTyping = styled(StyledUsername)`
  color: ${COLORS.brown.light[100]};
  font-weight: 400;
  min-width: 84px;
`;

export const StyledTypingName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
`;

export const StyledTypingTitle = styled.span<{ isDark?: boolean }>`
  font-weight: 400;
  color: ${({ isDark }) => (isDark ? COLORS.white[60] : COLORS.brown.light[100])};
`;
