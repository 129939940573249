import React, { FC } from 'react';
import { ReactComponent as VerificationIcon } from 'assets/icons/Verification.svg';
import { StyledIcon, StyledSubtitle, StyledWrap } from './styled';

const VerificationBadge: FC<{ hasCaption?: boolean }> = ({ hasCaption }) => (
  <StyledWrap>
    <StyledIcon>
      <VerificationIcon />
    </StyledIcon>
    {hasCaption && <StyledSubtitle>Verified</StyledSubtitle>}
  </StyledWrap>
);

export default VerificationBadge;
