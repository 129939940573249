import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';

import useClickOutside from 'helpers/useClickOutside';

import { EReactionTypeOptions } from 'constants/graphqlTypes';
import { Tooltip } from 'react-tooltip';
import { useGetEmojis } from 'graphQL/reactions/hooks';
import { v4 } from 'uuid';
import usePageType from 'helpers/usePageType';
import { ACTION_WRAPPER_TRUNCATE_CLASS } from 'components/Card/Actions/ActionContent';
import ReactionButton from '../ReactionButton';
import { MOCK_EMOJIS } from '../constants';
import { useTooltipArrowCorrection } from '../helpers/hooks';

import { StyledList, StyledToolTipWrap, StyledAbsWrap, StyledRelWrap } from './styled';

interface ReactionMenuProps {
  handleClickOutside: (type?: EReactionTypeOptions) => void;
  domElementId?: string;
  isShown: boolean;
  reactionCoordinates?: { x: number; y: number };
  offset?: number;
  minusTop?: number;
  setIsReactionsModalOpen?: Dispatch<SetStateAction<boolean>>;
  setIsQuickReactionOpen: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
}

export const REACTION_MENU_CLASSNAME = 'reaction-menu';

const ReactionMenu: FC<ReactionMenuProps> = ({
  handleClickOutside,
  isShown,
  reactionCoordinates,
  domElementId,
  offset,
  minusTop,
  setIsReactionsModalOpen,
  setIsQuickReactionOpen,
  onClose,
}) => {
  const ref = useRef<HTMLUListElement | null>(null);

  useGetEmojis();

  const customEventHandler = (e: MouseEvent | TouchEvent) => {
    if (e.target instanceof HTMLElement && e.target.closest(`.${ACTION_WRAPPER_TRUNCATE_CLASS}`)) {
      e.preventDefault();
      e.stopImmediatePropagation();
      handleClickOutside();
    }
  };

  useClickOutside(ref, handleClickOutside, false, customEventHandler);

  useEffect(() => {
    if (!isShown) {
      onClose?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown]);

  const tooltipRef = useTooltipArrowCorrection(isShown);

  const { isMessages, isItemDetail } = usePageType();
  const handleScroll = () => !isMessages && !isItemDetail && setIsQuickReactionOpen(false);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);

    return document.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isShown ? (
    <StyledAbsWrap minusTop={minusTop ?? 76}>
      <StyledRelWrap>
        <StyledToolTipWrap ref={tooltipRef} key={v4()}>
          <Tooltip
            className="reaction-tooltip"
            position={reactionCoordinates}
            id={domElementId}
            place="top"
            isOpen={isShown}
            clickable
            opacity={1}
            offset={offset}
          >
            <StyledList className={REACTION_MENU_CLASSNAME} ref={ref}>
              {Object.values(MOCK_EMOJIS).map((emojiValue) => (
                <li key={emojiValue}>
                  <ReactionButton emoji={emojiValue} setIsQuickReactionOpen={setIsQuickReactionOpen} />
                </li>
              ))}
              <li>
                <ReactionButton
                  isPlus
                  handleClick={() => {
                    setIsReactionsModalOpen?.(true);
                    setIsQuickReactionOpen(false);
                  }}
                />
              </li>
            </StyledList>
          </Tooltip>
        </StyledToolTipWrap>
      </StyledRelWrap>
    </StyledAbsWrap>
  ) : null;
};

export default ReactionMenu;
