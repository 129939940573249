import Profile1 from 'assets/images/onboarding/profile/profile1.webp';
import Profile2 from 'assets/images/onboarding/profile/profile2.webp';
import Profile3 from 'assets/images/onboarding/profile/profile3.webp';
import Profile4 from 'assets/images/onboarding/profile/profile4.webp';

export const MOCK_PROFILES = [
  {
    bgColor: {
      start: '#8B7B6C',
      end: '#2C251D',
    },
    imgUrl: Profile1,
  },
  {
    bgColor: {
      start: '#2152BB',
      end: '#31353B',
    },
    imgUrl: Profile2,
  },
  {
    bgColor: {
      start: '#D48102',
      end: '#794A01',
    },
    imgUrl: Profile3,
  },
  {
    bgColor: {
      start: '#7B9CAE',
      end: '#404A50',
    },
    imgUrl: Profile4,
  },
];
