import React, { FC, useRef } from 'react';

import useClickOutside from 'helpers/useClickOutside';

import { StyledFlatButton } from './styled';

interface IFlatButtonProps {
  Icon: FC;
  onClick?: () => void;
  handleHide?: () => void;
  width: number;
  isRed?: boolean;
}

const FlatButton: FC<IFlatButtonProps> = ({ Icon, onClick, handleHide, width, isRed }) => {
  const ref = useRef<HTMLButtonElement | null>(null);

  useClickOutside(ref, handleHide || (() => {}));

  const onClickButton = () => {
    handleHide?.();
    onClick?.();
  };

  return (
    <StyledFlatButton ref={ref} onClick={onClickButton} width={width} isRed={isRed}>
      <Icon />
    </StyledFlatButton>
  );
};

export default FlatButton;
