import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';
import { StyledList as ImportList } from 'components/AddContent/Menu/styled';
import ContentTitle from 'components/UI/ContentTitle/styled';

export const StyledWrap = styled.div`
  margin-top: 5px;
`;

export const StyledTitle = styled(ContentTitle)<{ isDark: boolean }>`
  padding: 27px 0 19px;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
`;

export const StyledList = styled(ImportList)<{ isDark: boolean }>`
  padding: 0;

  path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }

  li {
    border-color: ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.dark[10])};
  }
`;

export const StyledListItem = styled.div`
  padding: 30px 0;
  font-size: 14px;
`;

export const StyledText = styled.p<{ isDark: boolean }>`
  font: 400 14px/140% ${FONTS.secondary};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};

  a {
    text-decoration: underline;
    color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;

export const StyledInfo = styled.div`
  margin: 15px 0 5px;
`;
