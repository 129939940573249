import React from 'react';
import { SwiperSlide } from 'swiper/react';

import Card from 'components/Card';
import SliderInteractive from 'components/SliderInteractive';
import { COLORS } from 'styles/constants';
import { MOCK_CARDS, CAROUSEL_SPEED } from './constants';

import {
  StyledCarouselContainer,
  StyledCarouselHeader,
  StyledCarouselSubHeader,
  StyledWeeklyPicksSection,
} from './styled';

const WeeklyPicks = () => (
  <StyledWeeklyPicksSection>
    <StyledCarouselSubHeader>Cultivate your interests</StyledCarouselSubHeader>
    <StyledCarouselHeader>Featured Picks</StyledCarouselHeader>
    <StyledCarouselContainer>
      <SliderInteractive
        customSlidesPerView="auto"
        spaceBetween={12}
        fontColor={COLORS.brown.dark[100]}
        showBtnsSm={false}
        constantOffset={138}
        autoplayOptions={{ delay: 0, disableOnInteraction: false }}
        customSpeed={CAROUSEL_SPEED}
      >
        {MOCK_CARDS.map((item) => (
          <SwiperSlide key={item.id}>
            <Card disableLinks shouldDisableLongTap card={item} isDark={false} showActivity />
          </SwiperSlide>
        ))}
      </SliderInteractive>
    </StyledCarouselContainer>
  </StyledWeeklyPicksSection>
);

export default WeeklyPicks;
