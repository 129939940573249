import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import usePageType from 'helpers/usePageType';
import Navigation from 'components/Navigation';
import useTheme from 'helpers/useTheme';
import StyledWrap, { StyledVirtualBg } from './styled';

const PageLayout: FC<{ children: JSX.Element }> = ({ children }) => {
  const {
    isOnboardingWelcome,
    isOnboardingSuccess,
    isOnboardingProfileCompletion,
    isOnboardingListDetail,
    isArchive,
    isSavedPicks,
  } = usePageType();
  const { pathname } = useLocation();

  const isDark = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const hideNavigation =
    isOnboardingWelcome ||
    isOnboardingSuccess ||
    isOnboardingProfileCompletion ||
    isOnboardingListDetail ||
    isArchive ||
    isSavedPicks;

  return (
    <StyledWrap isDark={isDark}>
      {!hideNavigation && <Navigation />}
      <StyledVirtualBg isDark={isDark} />
      {children}
    </StyledWrap>
  );
};

export default PageLayout;
