import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DESCRIPTION_INPUT_HEIGHT } from 'components/AddContent/CreateList/CreateList';
import Modal from 'components/Modal';
import ContentTitle from 'components/UI/ContentTitle';
import TextInput from 'components/UI/TextInput';
import Button from 'components/UI/Button';
import useDisableScroll from 'helpers/useDisableScroll';
import { useCreateFeedTopic } from 'graphQL/feeds/hooks';
import { useLink } from 'helpers/routingHelper';
import { ROUTE_CREATE_FEED_USERS } from 'routes';
import { ChannelSectionItemsSortTypeOptions } from 'constants/graphqlTypes';

import { StyledButtonWrap } from 'components/TopicsModal/styled';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import { StyledInputContainer } from './styled';
import { ESelectionMode } from '../../../constants';

interface IAddFeedNameProps {
  selectionTopicMode: ESelectionMode;
  excludedTopics: string[];
  includedTopics: string[];
  excludedUsers: string[];
  includedUsers: string[];
  isAllFollowings: boolean;
  isAllUsers: boolean;
  sectionId: string;
  sorting?: ChannelSectionItemsSortTypeOptions;
}

const AddFeedName: FC<IAddFeedNameProps> = ({
  selectionTopicMode,
  excludedTopics,
  includedTopics,
  excludedUsers,
  includedUsers,
  isAllFollowings,
  isAllUsers,
  sectionId,
  sorting,
}) => {
  const { goBack, replace } = useHistory();
  const userLink = useLink(ROUTE_CREATE_FEED_USERS);
  const handleExit = () => goBack();
  useDisableScroll();

  const [feedName, setFeedName] = useState('');

  const { createFeedTopics } = useCreateFeedTopic(
    feedName,
    selectionTopicMode === ESelectionMode.All || includedTopics.length === 0,
    isAllFollowings,
    isAllUsers,
    excludedTopics,
    includedTopics,
    excludedUsers,
    includedUsers,
    sectionId,
    sorting
  );

  const createHandler = () => {
    createFeedTopics();
    handleExit();
  };

  return (
    <Modal handleClose={handleExit} handlePrev={() => replace(userLink)}>
      <StyledInputContainer hasPaddingBottom={!!feedName}>
        <ContentTitle isDark>What do you want to name your feed? </ContentTitle>
        <TextInput
          inputHeight={DESCRIPTION_INPUT_HEIGHT}
          counterNumber={20}
          maxLength={20}
          placeholder="Feed name"
          setText={setFeedName}
          text={feedName}
          autoCapitalize={EAutoCapitalize.On}
        />
        {feedName && (
          <StyledButtonWrap hasKeyboardOffset>
            <Button handler={createHandler} text="Create feed" />
          </StyledButtonWrap>
        )}
      </StyledInputContainer>
    </Modal>
  );
};

export default AddFeedName;
