import { DEFAULT_GRADIENT } from 'components/Collection/helpers';
import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledSparkPreviewContainer = styled.div`
  border-bottom: 1px solid ${COLORS.white[10]};
  display: flex;
  padding: 20px 12px 24px;
  width: 100%;
  margin-bottom: 11px;
`;

export const StyledSparkPreview = styled.div<{ bgUrl: string }>`
  align-items: center;
  background: ${({ bgUrl }) => `url(${bgUrl})` || DEFAULT_GRADIENT};
  background-size: cover;
  border-radius: 8px;
  display: flex;
  flex-shrink: 0;
  height: 44px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 44px;
`;

export const StyledModalInfoContainer = styled.div`
  padding-left: 8px;
  width: 100%;
  overflow: hidden;
`;

export const StyledModalTitle = styled.div`
  font: 400 22px/130% ${FONTS.primary};
`;

export const StyledSparkTitle = styled.div`
  font: 400 14px/140% ${FONTS.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
