import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import Modal from 'components/Modal';
import MenuButton from 'components/UI/MenuButton';
import { IReportData, IReportOption } from 'graphQL/cardOptions/models';
import { useReports } from 'graphQL/cardOptions/hooks';
import { StyledItem, StyledTitle } from 'components/AddContent/Menu/styled';
import { StyledList } from 'components/ProfileSettings/Menu/styled';
import SubmitReport from 'components/SubmitReport/SubmitReport';
import ConfirmationBlock from './ConfirmationBlock';

const Report: FC = () => {
  const [isConfirmationBlockOpen, setIsConfirmationBlockOpen] = useState(false);
  const [isSubmitReportOpen, setIsSubmitReportOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IReportOption | null>(null);

  const { replace } = useHistory();

  const { state } = useLocation<{ variable?: IReportData; backgroundModal: any }>();
  const variable = state?.variable ?? {};
  const backgroundModal = state?.backgroundModal ?? {};
  const { inviteId, pathname, isBlockedByMe, id: userId, chatId, type: reportTarget } = variable;

  const isGroupChat = !!chatId && reportTarget === 'thread';

  const { pathname: lastPathname } = useLastLocation() ?? {};
  const { options, report } = useReports(variable);

  const handleClose = () => {
    setIsConfirmationBlockOpen(false);
    if (inviteId) {
      replace({
        pathname: lastPathname,
        state: {
          ...state,
          variable,
          backgroundModal,
        },
      });
    }
    if (pathname) {
      replace({
        pathname,
        state: {
          ...state,
          variable,
          backgroundModal,
        },
      });
    }
  };

  const handlePrev = () => {
    if (!chatId) {
      return;
    }
    replace({
      pathname,
      state: {
        ...state,
        variable,
        backgroundModal,
      },
    });
  };

  const onOptionClick = (questionId: string) => {
    if ((isGroupChat || userId) && !inviteId) {
      return setIsSubmitReportOpen(true);
    }

    report(questionId);
    if (userId && !isBlockedByMe) {
      return setIsConfirmationBlockOpen(true);
    }
    return handleClose();
  };

  const onSubmitReport = () => {
    const questionId = selectedOption?.id || '';
    report(questionId);
    setIsSubmitReportOpen(false);

    if (userId && !isBlockedByMe) {
      setIsConfirmationBlockOpen(true);
    } else {
      handleClose();
    }
  };

  return (
    <Modal
      fadeIn={false}
      fadeOut={!lastPathname?.includes('thoughts')}
      handleClose={handleClose}
      handlePrev={handlePrev}
    >
      <StyledTitle>Why are you reporting this {chatId ? 'thread' : reportTarget}?</StyledTitle>
      <StyledList>
        {options.map((option) => (
          <StyledItem onClick={() => setSelectedOption(option)} key={option.id}>
            <MenuButton text={option.title} handler={() => onOptionClick(option.id)} />
          </StyledItem>
        ))}
      </StyledList>
      {isSubmitReportOpen && (
        <SubmitReport
          title={selectedOption?.title}
          handleSubmit={onSubmitReport}
          handleClose={handleClose}
          handlePrev={() => setIsSubmitReportOpen(false)}
        />
      )}
      {isConfirmationBlockOpen && userId && <ConfirmationBlock userId={userId} handleCancel={handleClose} />}
    </Modal>
  );
};

export default Report;
