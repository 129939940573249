import React, { FC, useEffect, useState } from 'react';

import { CardSchema, ImageSetSchema } from 'constants/graphqlTypes';
import ContentCard from './ContentCard';
import { cacheImages, createImagesList, generateCardStyles } from './helpers/helpers';

import { StyledAnimatedImage } from './styled';

interface IContentCardProps {
  item: CardSchema;
  images: ImageSetSchema[];
}

const AnimatedContentCard: FC<IContentCardProps> = ({ item, images }) => {
  const [imagesAreLoaded, setImagesAreLoaded] = useState(false);
  const [card, setCard] = useState(generateCardStyles(item, images));

  const cacheImagesHandler = async (srcArray: string[]) => {
    await cacheImages(srcArray);
    setImagesAreLoaded(true);
  };

  useEffect(() => {
    const imagesForLoading = createImagesList(images);
    cacheImagesHandler(imagesForLoading);
  }, []);

  useEffect(() => {
    let index = 0;
    const interval = imagesAreLoaded
      ? setInterval(() => {
          const newIndex = index + 1 < images.length ? index + 1 : 0;
          index = newIndex;
          setCard(generateCardStyles(item, images, newIndex));
        }, 1300)
      : undefined;

    return () => (interval ? clearInterval(interval) : undefined);
  }, [imagesAreLoaded]);

  return (
    <StyledAnimatedImage>
      <ContentCard item={item} card={card} images={images} />
    </StyledAnimatedImage>
  );
};

export default AnimatedContentCard;
