import React from 'react';
import { SwiperSlide } from 'swiper/react';

import SliderInteractive from 'components/SliderInteractive';
import UserCard from 'components/Card/UserCard';
import { COLORS } from 'styles/constants';
import { MOCK_USERS } from './constants';
import { CAROUSEL_SPEED } from '../WeeklyPicks/constants';

import { StyledCarouselHeader, StyledCarouselSubHeader } from '../WeeklyPicks/styled';
import { StyledWrap, StyledContainer } from './styled';

const Connect = () => (
  <StyledWrap>
    <StyledCarouselSubHeader>Join the quest for knowledge</StyledCarouselSubHeader>
    <StyledCarouselHeader>Connect</StyledCarouselHeader>
    <StyledContainer>
      <SliderInteractive
        customSlidesPerView="auto"
        spaceBetween={12}
        fontColor={COLORS.brown.dark[100]}
        showBtnsSm={false}
        constantOffset={138}
        autoplayOptions={{ delay: 0, disableOnInteraction: false }}
        customSpeed={CAROUSEL_SPEED}
      >
        {MOCK_USERS.map((item) => (
          <SwiperSlide key={item?.id}>
            <UserCard item={item} isLandingView disableLinks enableOldBehaviour />
          </SwiperSlide>
        ))}
      </SliderInteractive>
    </StyledContainer>
  </StyledWrap>
);

export default Connect;
