import { styled } from 'linaria/react';
import { css } from 'linaria';

import { StyledFooter as ImportFooter } from 'components/Card/Common/styled';
import { BREAKPOINTS, COLORS, FONTS } from '../../styles/constants';

export const StyledWrap = styled.div<{ bgColor: string }>`
  background: ${({ bgColor }) => bgColor};
  font-family: ${FONTS.primary};
  height: 100dvh;
  position: relative;
  width: 100%;

  .hide-padding {
    margin-top: 12px;
  }
`;

export const StyledContent = styled.div<{ disableUserSelect?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${COLORS.white[100]};
  padding: 50px 12px 85px;
  max-width: 420px;
  margin: 0 auto;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding-top: 60px;
  }

  * {
    user-select: ${({ disableUserSelect }) => (disableUserSelect ? 'none' : 'auto')};
  }
`;

export const StyledImage = styled.img<{ hasFullWidth?: boolean }>`
  width: ${({ hasFullWidth }) => (hasFullWidth ? '100%' : 'auto')};
  flex: 0 1;
  height: 100%;
  border-radius: 8px;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  object-fit: cover;
`;

export const StyledDetailsWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const StyledVideoImage = styled.div<{ src: string; isTiktokVideo: boolean }>`
  background-image: ${({ src }) => `url('${src}')`};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  cursor: pointer;
  max-height: 206px;
  width: 100%;
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 56.25%;
  }

  &::after {
    background-image: ${({ isTiktokVideo }) =>
      isTiktokVideo
        ? 'none'
        : `url(
            "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='14' fill='none'%3E%3Cpath d='M12 7 0 14V0l12 7Z' fill='%23fff'/%3E%3C/svg%3E"
          )`};
    bottom: 12px;
    content: '';
    height: 14px;
    left: 12px;
    position: absolute;
    width: 12px;
  }
`;

export const StyledDetailsContent = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PULSE_ANIMATION = css`
  animation: pulse 5s infinite ease-in-out;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }
    33%,
    42% {
      transform: scale(1);
    }
    87%,
    100% {
      transform: scale(0.95);
    }
  }
`;

export const StyledSourceWrap = styled.div<{ showPointer: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font: inherit;
  color: inherit;
  overflow: hidden;
  text-decoration: none;
  cursor: ${({ showPointer }) => (showPointer ? 'pointer' : 'auto')};
`;

export const StyledVideoSourceWrapLink = styled.a`
  text-decoration: none;
  -webkit-touch-callout: none;
  display: block;
  font: inherit;
  color: inherit;
  width: 100%;
`;

export const StyledInfo = styled.div<{ disableLinks: boolean; isCurrentCardInFocus: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 0 20px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    height: calc(100dvh - 372px);
  }

  a {
    pointer-events: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
  }

  .${PULSE_ANIMATION} {
    animation-play-state: ${({ isCurrentCardInFocus }) => (isCurrentCardInFocus ? 'running' : 'paused')};
  }
`;

export const StyledType = styled.span<{ isVideo?: boolean }>`
  display: block;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  padding: ${({ isVideo }) => (isVideo ? '16px' : 0)} 12px 8px;
  text-transform: capitalize;
`;

export const StyledTitle = styled.span<{ isVideo: boolean; titleFontSize: number }>`
  display: block;
  font-size: ${({ titleFontSize }) => titleFontSize}px;
  line-height: 120%;
  padding: 0 12px ${({ isVideo }) => (isVideo ? 0 : 20)}px;
  white-space: pre-wrap;
`;

export const StyledDescription = styled.p`
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  padding: 0 12px 32px;
  overflow: hidden;
  white-space: pre-wrap;
  pointer-events: none;
`;

export const StyledImageWrap = styled.div<{ isImageSmall: boolean }>`
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledFooter = styled(ImportFooter)<{ isVideo: boolean }>`
  padding: ${({ isVideo }) => (isVideo ? '16px' : '12px')} 12px 0;
`;

export const StyledActionsWrap = styled.div<{ disableLinks?: boolean }>`
  position: relative;

  a {
    pointer-events: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
  }
`;

export const StyledTweetWrap = styled.div`
  padding: 0 12px;
`;

export const StyledVideoImageWrap = styled.div<{ isImageSmall: boolean }>`
  @media (max-height: ${BREAKPOINTS.m}px) {
    max-height: ${({ isImageSmall }) => (isImageSmall ? '100px' : '100%')};

    div::before {
      padding-top: ${({ isImageSmall }) => (isImageSmall ? '100px' : '56.25%')};
    }
  }
`;

export const StyledSavedWrap = styled.div`
  border-radius: 20px;
  border: 1px solid ${COLORS.white[30]};
  font: 400 14px/140% ${FONTS.secondary};
  padding: 16px;
  margin: 16px 0;
`;
