import React, { Dispatch, FC, SetStateAction } from 'react';

import Button from 'components/UI/MenuButton';
import MenuWrap from 'components/Navigation/RightMenu/MenuWrap';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';
import { CardCommentFilterOptionsEnum } from 'constants/graphqlTypes';

import { StyledItem } from './Thought/ThoughtMenu/styled';
import { getThoughtsCount } from './helpers/helpers';
import { StyledItemWrap } from './styled';
import { useThoughtsTotalCounts } from './helpers/hooks';

const ThoughtsFilterMenu: FC<{
  pickId: string;
  isOpen: boolean;
  currentCriterion: CardCommentFilterOptionsEnum;
  setCriterion: Dispatch<SetStateAction<CardCommentFilterOptionsEnum>>;
  handleModal?: (isOpen: boolean) => void;
}> = ({ pickId, isOpen, handleModal, currentCriterion, setCriterion }) => {
  const { thoughtsTotalCounts, loading } = useThoughtsTotalCounts(pickId);

  return (
    <MenuWrap isOpen={isOpen} handleModal={handleModal} isPortal>
      {!loading &&
        Object.entries(CardCommentFilterOptionsEnum).map(
          ([filterKey, filterCriterion]) =>
            !!getThoughtsCount(filterCriterion, thoughtsTotalCounts) && (
              <StyledItem>
                <StyledItemWrap
                  isDark
                  onClick={() => {
                    setCriterion(filterCriterion);
                    handleModal?.(false);
                  }}
                >
                  <Button text={filterKey === 'Default' ? 'Everyone' : filterKey} />
                  {currentCriterion === filterCriterion && <CheckIcon width={44} height={44} viewBox="0 0 20 24" />}
                </StyledItemWrap>
              </StyledItem>
            )
        )}
    </MenuWrap>
  );
};

export default ThoughtsFilterMenu;
