import { ApolloCache } from '@apollo/client';

import { GetBlockedUsersDocument, GetBlockedUsersQuery } from 'constants/graphqlTypes';
import { IRefItem, IRefItems } from 'graphQL/models';

export const deleteBlockedUserFromCache = (unBlockedUserId: string, cache: ApolloCache<unknown>): void => {
  const blockedUsersList: GetBlockedUsersQuery | null = cache?.readQuery({
    query: GetBlockedUsersDocument,
    variables: { offset: 0 },
  });

  if (!blockedUsersList) {
    return;
  }

  cache?.writeQuery({
    query: GetBlockedUsersDocument,
    variables: { offset: 0 },
    data: {
      allUsers: {
        ...blockedUsersList.allUsers,
        items: blockedUsersList.allUsers?.items.filter(({ id }) => id !== unBlockedUserId),
        totalCount: blockedUsersList.allUsers?.totalCount ? blockedUsersList.allUsers?.totalCount - 1 : 0,
      },
    },
  });
};

export const deleteUserFromConnections = (blockedId: string, userId: string, cache: ApolloCache<unknown>): void => {
  cache.modify({
    id: cache.identify({ id: userId, __typename: 'UserObjectType' }),
    fields: {
      connections: (prev: IRefItems) => ({
        totalCount: prev.totalCount - 1,
        items: prev.items?.filter((item: IRefItem) => !item.__ref.includes(blockedId)),
      }),
    },
  });
};
