import React, { FC, useState } from 'react';

import OnboardingAlert from 'components/UI/OnboardingAlert';

import { useUpdateUserSettings, useUserSettings } from 'graphQL/contextualIntro/hooks';
import { ETutorialStatus } from 'constants/graphqlTypes';
import useIsModalOpen from 'helpers/useIsModalOpen';
import useGuideTour from 'helpers/useGuideTour';
import Highlighter from 'components/Highlighter';
import { NOTIFICATION_BUTTON } from 'components/GuideTour/constants';
import { StyledContentWrap, StyledWrap } from './styled';

const NotificationInfo: FC<{ userName: string }> = ({ userName }) => {
  const [wasSent, setWasSent] = useState(false);
  const isModalOpen = useIsModalOpen();

  const { isGuideTour } = useGuideTour();
  const { loading, isUserActivityNotificationIntroCompleted, tutorialStatus } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings({
    isUserActivityNotificationIntroCompleted: true,
  });

  const handleClose = () => {
    if (!wasSent) {
      updateUserSettings();
      setWasSent(true);
    }
  };

  return !isGuideTour &&
    tutorialStatus === ETutorialStatus.Finished &&
    !isUserActivityNotificationIntroCompleted &&
    !loading &&
    !isModalOpen ? (
    <StyledWrap>
      <Highlighter
        id={NOTIFICATION_BUTTON}
        highlighterOffset={4}
        borderRadius="50%"
        highlight
        heightOffset
        handleClose={handleClose}
        elementOffset={10}
      >
        <StyledContentWrap onMouseDown={(e) => e.stopPropagation()}>
          <OnboardingAlert
            title="Turn on activity notifications"
            submitText="Got it"
            handleSubmit={updateUserSettings}
            hasCross
            handleCancel={handleClose}
          >
            {`You can now get notifications when ${userName} posts on topics you follow.`}
          </OnboardingAlert>
        </StyledContentWrap>
      </Highlighter>
    </StyledWrap>
  ) : null;
};

export default NotificationInfo;
