import { MessageSchema, MessageType } from 'constants/graphqlTypes';
import { RefObject, useEffect, useRef } from 'react';
import { useReadMessage } from 'graphQL/messages/hooks';
import { IUseMessageIds } from './models';

export const useMessageIds = ({ message, messageType }: MessageSchema): IUseMessageIds => {
  const pickId = message.__typename === 'CardMessageSchema' && message?.pickId;

  const listId = messageType === MessageType.List && pickId;

  const guestListId = messageType === MessageType.GuestList && pickId;

  const userId = message.__typename === 'UserMessageSchema' && message?.userId;

  const commentId = message.__typename === 'CardCommentMessageSchema' && message?.commentId;

  // || "" needed to bypass ts2590 error Expression produces a union type that is too complex to represent.
  const sparkId =
    (message.__typename === 'CardMessageSchema' && messageType === MessageType.Spark && message?.pickId) || '';

  const linkId =
    message.__typename === 'LinkPreviewMessageSchema' && messageType === MessageType.LinkPreview && message?.id;
  const commentCardId =
    messageType === MessageType.Thought && message.__typename === 'CardCommentMessageSchema' && message.pickId;
  const guestListSeriesId =
    message.__typename === 'GuestListSeriesMessageSchema' &&
    messageType === MessageType.GuestListSeries &&
    message?.guestListSeriesId;
  const invitationId =
    messageType === MessageType.Invite &&
    message.__typename === 'InvitationMessageSchema' &&
    message?.id &&
    message?.invitationId;
  const invitePickId =
    messageType === MessageType.Invite && message.__typename === 'InvitationMessageSchema' && message?.pickId;

  return {
    ...(pickId && { pickId }),
    ...(listId && { listId }),
    ...(userId && { userId }),
    ...(commentId && { commentId }),
    ...(commentCardId && { commentCardId }),
    ...(linkId && { linkId }),
    ...(guestListSeriesId && { guestListSeriesId }),
    ...(guestListId && { guestListId }),
    ...(messageType === MessageType.Invite &&
      message.__typename === 'InvitationMessageSchema' && {
        invitationId: invitationId || undefined,
        pickId: invitePickId || undefined,
      }),
    ...(!!sparkId && { sparkId }),
  };
};

export const useMessageObserver = (message: MessageSchema): RefObject<HTMLDivElement> => {
  const readMessage = useReadMessage(message.id);
  const messageWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !message.isViewed) {
            readMessage();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (messageWrapperRef.current) {
      observer.observe(messageWrapperRef.current);
    }

    return () => {
      if (messageWrapperRef.current) {
        observer.unobserve(messageWrapperRef.current);
      }
    };
  }, [message]);

  return messageWrapperRef;
};
