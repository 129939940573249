import { styled } from 'linaria/react';
import { COLORS, FONTS } from '../../../styles/constants';

export const StyledContainer = styled.div<{ hasAnswer: boolean; background?: string }>`
  aspect-ratio: 1/1;
  background: ${({ background }) => background ?? COLORS.white.off[100]};
  border-radius: 10px;
  box-sizing: border-box;
  color: ${({ hasAnswer }) => (hasAnswer ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px 12px 16px;
  position: relative;
`;

export const StyledDate = styled.span`
  font: 400 12px/140% ${FONTS.secondary};
`;

export const StyledTitle = styled.span`
  font: 400 22px/130% ${FONTS.primary};
  padding-top: 4px;

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const StyledContent = styled.div`
  padding: 16px 0;
  overflow: hidden;

  img {
    max-width: 100%;
    height: 100%;
    border-radius: 10px;
    position: relative;
  }
`;

export const StyledTextPick = styled.div`
  padding: 20px 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.white[30]};
  border-radius: 10px;
`;

export const StyledTextPickTitle = styled.span`
  font: 400 14px/140% ${FONTS.primary};
`;

export const StyledFooter = styled.div<{ isEmpty: boolean }>`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-top: auto;
  padding-left: 8px;
  width: 100%;
`;

export const StyledNames = styled.div`
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  gap: 3px;

  span {
    font: 600 14px/140% ${FONTS.secondary};
  }

  .name {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
`;

export const StyledIcon = styled.div<{ isDark: boolean }>`
  align-items: center;
  background: transparent;
  border-radius: 50%;
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.dark[10])};
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  width: 44px;

  path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;
