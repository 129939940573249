import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';
import { StyledModal as StyledModalContainer, StyledHeader as StyledModalHeader } from 'components/Modal/styled';

export const StyledModal = styled(StyledModalContainer)<{ bgColor?: string }>`
  background: ${({ bgColor }) => bgColor ?? COLORS.brown.dark[100]};
  padding: 24px 0 12px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    height: 85vh;
  }
`;

export const StyledHeader = styled(StyledModalHeader)`
  position: relative;
  border-bottom: ${({ withImage }) => (withImage ? `1px solid ${COLORS.white[30]}` : 'none')};
  padding: ${({ withImage }) => (withImage ? '0 12px 16px 12px' : '0 12px')};
`;

export const StyledLinkWrap = styled.div`
  flex: 1 1;
  overflow: hidden;
  cursor: pointer;

  .ui-link {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .tabmodal__title {
    line-break: anywhere;
  }
`;

export const StyledTabsContainer = styled.div`
  position: relative;
  display: flex;
`;

export const StyledTab = styled.div<{ isActive: boolean; customColor?: boolean; isDark?: boolean }>`
  border-bottom: 1px solid
    ${({ isActive, isDark = true }) => {
      const color = isDark ? COLORS.white[100] : COLORS.brown.dark[100];
      const inactiveColor = isDark ? COLORS.white[30] : COLORS.white.off[100];
      return isActive ? color : inactiveColor;
    }};
  color: ${({ isActive, customColor, isDark = true }) => {
    const color = isDark ? COLORS.white[100] : COLORS.brown.dark[100];
    const inactiveColor = customColor ? COLORS.white[30] : COLORS.brown.light[100];
    return isActive ? color : inactiveColor;
  }};
  cursor: pointer;
  font: 600 14px/140% ${FONTS.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  padding-top: 32px;
  flex: 1 0;
  text-transform: capitalize;
`;

export const StyledWrap = styled.div<{ noActions?: boolean; noHorizontalPadding?: boolean }>`
  display: ${({ noActions }) => (noActions ? 'flex' : 'block')};
  color: ${COLORS.white[100]};
  height: ${({ noActions }) => (noActions ? '100%' : 'auto')};
  margin: 0;
  min-height: 100px;
  padding: 16px ${({ noHorizontalPadding }) => (noHorizontalPadding ? 0 : 24)}px;
  scrollbar-width: none;
  position: relative;
  overflow: auto;
  list-style: none;
  -ms-overflow-style: none;

  & > li {
    display: block;
    margin-bottom: 32px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledImage = styled.img`
  border-radius: 8px;
  height: 50px;
  width: 50px;
  object-fit: cover;
  overflow: hidden;
  margin: 0 12px 0 7px;
  position: relative;
`;

export const StyledTitle = styled.span`
  color: ${COLORS.white[100]};
  flex: 1;
  font: 400 14px/140% ${FONTS.primary};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
