import React, { FC } from 'react';

import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';

import StyledCheckbox from './styled';

interface CheckboxProps {
  isDark?: boolean;
  isChecked: boolean;
  handler: () => void;
  isTransparent?: boolean;
  dataTestId?: string;
}

const Checkbox: FC<CheckboxProps> = ({ isDark = true, isChecked, handler, isTransparent, dataTestId }) => (
  <StyledCheckbox data-testid={dataTestId} isTransparent={isTransparent} isDark={isDark} isChecked={isChecked}>
    <input type="checkbox" checked={isChecked} onChange={handler} />
    <CheckIcon />
  </StyledCheckbox>
);

export default Checkbox;
