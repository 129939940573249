import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { BIO_LENGTH, EMPTY_ITEMS } from 'constants/common';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import { ROUTE_ONBOARDING_PROFILE } from 'routes';
import { useGetBioPlaceholdersQuery } from 'constants/graphqlTypes';
import { TEST_USER_INFO_BIO_TEXT_FIELD } from 'constants/aqa/signup';
import Layout from 'components/Onboarding/Layout';
import TextInput from 'components/UI/TextInput';
import ContentTitle from 'components/UI/ContentTitle';
import useChangeProfileBio from 'components/ProfileSettings/EditProfile/ChangeBio/hooks';
import useTheme from 'helpers/useTheme';

import { StyledTextWrapper } from 'components/UI/AnimatedPlaceholder/styled';

const Bio: FC = () => {
  const posthogCapture = usePostHogCapture();

  const { push } = useHistory();
  const handleNext = () => push(ROUTE_ONBOARDING_PROFILE);

  const { handleDone, handleChange, shortBio } = useChangeProfileBio(handleNext);
  const isDark = useTheme();

  const { data } = useGetBioPlaceholdersQuery() ?? {};
  const { items: placeholders } = data?.allListBioPlaceholder ?? EMPTY_ITEMS;

  useEffect(() => {
    return () => {
      if (!shortBio.length) {
        posthogCapture(POSTHOG_EVENTS.OnboardingProfileParameters, {
          screen: 'intro',
        });
      }

      localStorage.setItem('bio', ' ');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      animatedPlaceholderParams={{ items: placeholders }}
      primaryBtnText="Save"
      handlePrimaryBtn={handleDone}
      maxWidth={528}
    >
      <ContentTitle isDark={isDark}>How would you introduce yourself?</ContentTitle>
      <StyledTextWrapper>
        <TextInput
          dataTestId={TEST_USER_INFO_BIO_TEXT_FIELD}
          autoCapitalize={EAutoCapitalize.On}
          counterNumber={BIO_LENGTH}
          isDark={isDark}
          placeholder=""
          maxLength={BIO_LENGTH}
          setText={handleChange}
          text={shortBio}
        />
      </StyledTextWrapper>
    </Layout>
  );
};

export default Bio;
