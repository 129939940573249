import React, { FC } from 'react';

import { COLORS } from 'styles/constants';

import { StyledBody, StyledTag, StyledTitle, StyledContent, StyledImageWrap } from 'components/Card/Common/styled';
import { ICardMock } from '../../../Welcome/constants';

const Card: FC<{ card: ICardMock }> = ({ card: { mediaTag, imgUrl, customTitle } }) => (
  <StyledBody bg={COLORS.transparent} isReverse={false} className="card">
    <StyledContent>
      <StyledTag>{mediaTag}</StyledTag>
      <StyledTitle>{customTitle || 'Generating title'}</StyledTitle>
    </StyledContent>
    <StyledImageWrap isBorderless={false}>
      <img src={imgUrl} alt={customTitle ?? ''} />
    </StyledImageWrap>
  </StyledBody>
);

export default Card;
