import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import ReportIssue from 'components/AddContent/AddStyle/ReportStyles/ReportIssue';
import ReportOther from 'components/AddContent/AddStyle/ReportStyles/ReportOther';
import StyleDescription from 'components/AddContent/AddStyle/ReportStyles/StyleDescription';
import {
  ROUTE_SPARK_RESP_STYLE_DESCRIPTION,
  ROUTE_SPARK_RESP_REPORT_STYLE_ISSUE,
  ROUTE_SPARK_RESP_REPORT_STYLE_OTHER,
} from 'routes';

const ReportStyles: FC<{ cardId: string }> = ({ cardId }) => (
  <Switch>
    <Route exact path={ROUTE_SPARK_RESP_STYLE_DESCRIPTION}>
      <StyleDescription isSpark />
    </Route>
    <Route exact path={ROUTE_SPARK_RESP_REPORT_STYLE_ISSUE}>
      <ReportIssue cardId={cardId} isSpark />
    </Route>
    <Route exact path={ROUTE_SPARK_RESP_REPORT_STYLE_OTHER}>
      <ReportOther cardId={cardId} isSpark />
    </Route>
  </Switch>
);

export default ReportStyles;
