import { ESelectionMode } from '../constants';

export const checkIsUserEnabled = ({
  id,
  selectionMode,
  selectedUsers,
  deselectedUsers,
  isAllUsers,
  isFollowedByMe,
  isSearch,
  isAllFollowings,
  isSelectedInFeed,
  feedId,
  isEditStarted,
  totalSelectedUsers,
  isAllFollowingsEdit,
}: {
  id: string;
  selectionMode: ESelectionMode;
  selectedUsers: string[];
  deselectedUsers: string[];
  isFollowedByMe?: boolean | null;
  isSearch?: boolean;
  isAllUsers?: boolean;
  isAllFollowings?: boolean;
  isSelectedInFeed?: boolean;
  feedId?: string;
  isEditStarted?: boolean;
  totalSelectedUsers?: number;
  isAllFollowingsEdit?: boolean;
}) => {
  if (isSelectedInFeed !== undefined && !isEditStarted) {
    return isSelectedInFeed;
  }

  if (totalSelectedUsers === 0) {
    return false;
  }

  if (selectionMode === ESelectionMode.All) {
    return true;
  }
  if (isSearch && !isFollowedByMe && isAllFollowings && !isAllUsers && !isSelectedInFeed) {
    return selectedUsers.includes(id);
  }
  if (isAllUsers && !isAllFollowingsEdit && !isAllFollowings && isFollowedByMe) {
    return selectedUsers.includes(id);
  }

  if (
    (isSearch && isFollowedByMe && isAllFollowings) ||
    (isSelectedInFeed && feedId && !isAllFollowingsEdit && !isAllFollowings)
  ) {
    return !deselectedUsers.includes(id);
  }
  if (!isAllFollowingsEdit && isAllFollowings && isEditStarted) {
    return selectedUsers.includes(id);
  }
  if (
    (selectionMode === ESelectionMode.Select && !isAllUsers) ||
    (isEditStarted && !isSelectedInFeed && selectionMode === ESelectionMode.Deselect && !isFollowedByMe)
  ) {
    return selectedUsers.includes(id);
  }
  return !deselectedUsers.includes(id);
};

export const checkIsTopicEnabled = ({
  id,
  selectionMode,
  selectedTopics,
  deselectedTopics,
}: {
  id: string;
  selectionMode: ESelectionMode;
  selectedTopics: string[];
  deselectedTopics: string[];
}) => {
  if (selectionMode === ESelectionMode.All) {
    return true;
  }
  if (selectionMode === ESelectionMode.Select) {
    return selectedTopics.includes(id);
  }

  return !deselectedTopics.includes(id);
};
