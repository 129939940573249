import React, { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { TypeAnimation } from 'react-type-animation';

import { DiscoverSectionSchema } from 'constants/graphqlTypes';
import useTheme from 'helpers/useTheme';

import { StyledWrap, StyledDescription } from './styled';

const DateSection: FC<{ section: DiscoverSectionSchema }> = ({ section }) => {
  const isDark = useTheme();
  const [isAnimationStarted, setIsAnimationStarted] = useState(false);

  return (
    <StyledWrap isDark={isDark}>
      <Waypoint onEnter={() => setIsAnimationStarted(true)} />
      {isAnimationStarted ? (
        <TypeAnimation
          preRenderFirstString
          wrapper="p"
          cursor={false}
          sequence={['', 500, section.title ?? '', 2000]}
          speed={45}
        />
      ) : (
        <p>&nbsp;</p>
      )}
      <StyledDescription isDark={isDark}>{section?.annotation}</StyledDescription>
    </StyledWrap>
  );
};

export default DateSection;
