import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';

import { CardSchema, GetDetailListDocument, GetDetailListQuery } from 'constants/graphqlTypes';
import { getListStyles } from 'components/Collection/helpers';
import { reducePlainCards, updateCachedDetailList } from './helpers';
import { IUpdateCoverArgs, IUseGetListCoverIndexes } from './models';

export const useUpdateCachedListThumbnails = (): ((args: IUpdateCoverArgs) => void) => {
  const { cache } = useApolloClient();

  const updateCover = ({
    listId,
    coverImgIndexes,
    coverTextIndexes,
    coverBgIndexes,
    orderItems,
    isTextListCover,
  }: IUpdateCoverArgs) => {
    const orderItemsWithoutPlainCards = reducePlainCards(orderItems);

    const cachedBgItems = coverBgIndexes.reduce(
      (acc: CardSchema[], curr) => acc.concat([...(!isTextListCover ? orderItemsWithoutPlainCards : orderItems)][curr]),
      []
    );

    const reducedCoverItems = isTextListCover
      ? [...orderItems].splice(coverTextIndexes[0], coverTextIndexes.length)
      : orderItemsWithoutPlainCards.splice(coverImgIndexes[0], coverImgIndexes.length);

    if (coverImgIndexes.length || coverTextIndexes.length) {
      cache.updateQuery(
        {
          query: GetDetailListDocument,
          variables: {
            id: listId,
            coverLimit: 4,
          },
        },
        (data: GetDetailListQuery | null) =>
          updateCachedDetailList(data, orderItems, reducedCoverItems, cachedBgItems, isTextListCover)
      );

      if (isTextListCover) {
        cache.updateQuery(
          {
            query: GetDetailListDocument,
            variables: {
              id: listId,
              coverLimit: undefined,
            },
          },
          (data: GetDetailListQuery | null) =>
            updateCachedDetailList(data, orderItems, reducedCoverItems, cachedBgItems, isTextListCover)
        );
      }
    }
  };

  return updateCover;
};

export const useGetListCoverIndexes = (
  items: CardSchema[],
  isTextListCover: boolean,
  list?: CardSchema
): IUseGetListCoverIndexes => {
  const [coverImgIndexes, setCoverImgIndexes] = useState<number[]>([]);
  const [coverTextIndexes, setCoverTextIndexes] = useState<number[]>([]);
  const [coverBgIndexes, setCoverBgIndexes] = useState<number[]>([]);

  const { images, titles } = getListStyles(list);

  useEffect(() => {
    const ids = images.map(({ imageId }) => imageId);
    const indexes: number[] = [];
    reducePlainCards(items).forEach((item, index) => {
      if (ids.includes(item.cardStyle?.thumbnailImageSet?.imageId ?? '')) {
        indexes.push(index);
      }
    });
    setCoverImgIndexes(indexes);
  }, [images.length, items.length]);

  const bgColors = (list?.cardStyle?.collectionBgColors ?? []).map(({ colorValue }) => colorValue);

  useEffect(() => {
    const newIndexes: number[] = [];
    (!isTextListCover ? reducePlainCards(items) : items).forEach((item, index) => {
      if (bgColors.includes(item.cardStyle?.bgColor?.colorValue ?? '')) {
        newIndexes.push(index);
      }
    });
    setCoverBgIndexes(newIndexes);
  }, [list?.cardStyle?.collectionBgColors?.length, items.length]);

  useEffect(() => {
    const newIndexes: number[] = [];
    items.forEach((item, index) => titles.includes(item.title ?? '') && newIndexes.push(index));
    setCoverTextIndexes(newIndexes);
  }, [list?.cardStyle?.collectionCover?.items?.length, items.length]);

  return {
    coverImgIndexes,
    coverTextIndexes,
    coverBgIndexes,
  };
};
