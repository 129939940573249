import { styled } from 'linaria/react';
import { StyledSectionType } from 'components/Explore/ChannelsTab/styled';
import { COLORS } from 'styles/constants';

export const StyledStatus = styled(StyledSectionType)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  padding: 12px 0;

  path {
    stroke: ${COLORS.brown.light[100]};
  }
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 16px 0;
`;
