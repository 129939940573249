import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as ImmersiveIcon } from 'assets/icons/Immersive.svg';
import { ReactComponent as OverviewIcon } from 'assets/icons/Overview.svg';

import { useEditList } from 'helpers/useContextMenu';
import { CardSchema, MessageType, ECardPermissionsOptions, EViewTypeOptions } from 'constants/graphqlTypes';
import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { StyledItem } from 'components/AddContent/Menu/styled';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import { useMenuModal } from 'components/Navigation/helpers/hooks';
import { useUpdateItemView } from 'graphQL/feeds/hooks';
import { updateListItemView } from 'graphQL/list/helpers';
import { EReportTypes } from 'components/Report/constants';

import useToast from 'helpers/useToast';

import Button from 'components/UI/MenuButton';
import { StyledActions } from 'components/ListSettings/EditList/styled';
import { StyledLabel } from 'components/ProfileSettings/EditProfile/styled';
import { StyledItem as StyledItemWithCheck } from 'components/List/ChangeLayout/styled';
import ShareButtons from './ShareButtons';
import MenuWrap from './MenuWrap';

const ListButton: FC<{ list: CardSchema; hasShare?: boolean }> = ({ list, hasShare = true }) => {
  const { setToast } = useToast();
  const { isModalOpen, handleModal } = useMenuModal();
  const { pathname } = useLocation();
  const { id: pickId, isPickedByMe, cardId, isPrivate, isPrivateList, isCollaborative, permissions, cardStyle } = list;
  const { viewType } = cardStyle ?? {};
  const [currentLayout, setCurrentLayout] = useState<EViewTypeOptions>(EViewTypeOptions.Immersive);

  const { Icon: EditListIcon, text: editListText, toggle: toggleEditList, condition: isAdmin } = useEditList(list);
  const hasViewerRole = !permissions?.includes(ECardPermissionsOptions.EditCollectionItems) && isPickedByMe;

  const toReport = useNavigateToReport({ cardId, pathname, type: EReportTypes.List });
  const updateItemViewMutation = useUpdateItemView(pickId, currentLayout);

  const handleReport = () => {
    handleModal(false);
    toReport();
  };

  const handleEditList = () => {
    handleModal(false);
    toggleEditList?.();
  };

  const handleUpdateView = (layout: EViewTypeOptions) => {
    if (!pickId || layout === currentLayout) {
      return;
    }

    updateItemViewMutation({
      variables: { itemId: pickId, viewType: layout },
      onCompleted: () => {
        setCurrentLayout(layout);
      },
      update: (cache, { data }) => updateListItemView(cache, data),
    }).catch(({ message }) => setToast({ isToastOpen: true, toastItemName: message, toastError: true }));
  };

  useEffect(() => {
    if (viewType) {
      setCurrentLayout(viewType);
    }
  }, [viewType]);

  return (
    <MenuWrap isOpen={isModalOpen} handleModal={handleModal}>
      <StyledActions>
        <StyledLabel>Change view</StyledLabel>
        <StyledItemWithCheck isSelected={currentLayout === EViewTypeOptions.Immersive}>
          <Button text="Immersive" Icon={ImmersiveIcon} handler={() => handleUpdateView(EViewTypeOptions.Immersive)} />
        </StyledItemWithCheck>
        <StyledItemWithCheck isSelected={currentLayout === EViewTypeOptions.Overview}>
          <Button text="Overview" Icon={OverviewIcon} handler={() => handleUpdateView(EViewTypeOptions.Overview)} />
        </StyledItemWithCheck>
      </StyledActions>

      <StyledActions>
        <StyledLabel>Actions</StyledLabel>
        {!(isPrivate && !isCollaborative) && hasShare && (
          <ShareButtons
            showShare={!(isPrivateList && isCollaborative)}
            closePreviousModal={() => handleModal(false)}
            messageType={MessageType.List}
            sharedId={pickId}
            hasFilter={!!(isPrivate && isCollaborative)}
          />
        )}
        {isPickedByMe && isAdmin && (
          <>
            <StyledItem>
              <Button text={editListText} Icon={EditListIcon} handler={handleEditList} />
            </StyledItem>
          </>
        )}
        {(hasViewerRole || !isPickedByMe) && (
          <StyledItem>
            <Button text="Report list" Icon={ReportIcon} handler={handleReport} />
          </StyledItem>
        )}
      </StyledActions>
    </MenuWrap>
  );
};

export default ListButton;
