import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { BIO_LENGTH, EMPTY_ITEMS } from 'constants/common';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import { getInputStyle } from 'components/UI/TextInput/helpers/helpers';
import { ROUTE_ONBOARDING_IMAGE } from 'routes';
import { EOnboardingStepOptions, useGetBioPlaceholdersQuery } from 'constants/graphqlTypes';
import { TEST_USER_INFO_BIO_TEXT_FIELD, TEST_USER_INFO_TITLE } from 'constants/aqa/signup';
import AnimatedPlaceholder from 'components/UI/AnimatedPlaceholder';
import ContentTitle from 'components/UI/ContentTitle';
import Layout from 'components/Onboarding/Layout';
import TextInput from 'components/UI/TextInput';
import useChangeProfileBio from 'components/ProfileSettings/EditProfile/ChangeBio/hooks';
import useTheme from 'helpers/useTheme';

import { StyledTextWrapper } from 'components/UI/AnimatedPlaceholder/styled';
import { useUpdateOnboardingStep, useUpdateCachedOnboardingStep } from 'graphQL/onboarding/hooks';

const Bio: FC = () => {
  const { push } = useHistory();
  const updateCache = useUpdateCachedOnboardingStep(EOnboardingStepOptions.UploadImage);
  const handleNext = () => {
    updateCache();
    push(ROUTE_ONBOARDING_IMAGE);
  };
  const { handleDone, handleChange, shortBio } = useChangeProfileBio(handleNext);
  const isDark = useTheme();
  const inputStyle = getInputStyle('', isDark);

  const { data } = useGetBioPlaceholdersQuery() ?? {};
  const { items: placeholders } = data?.allListBioPlaceholder ?? EMPTY_ITEMS;

  useUpdateOnboardingStep(EOnboardingStepOptions.Description);

  return (
    <Layout primaryBtnText="Continue" handlePrimaryBtn={handleDone}>
      <ContentTitle dataTestId={TEST_USER_INFO_TITLE} isDark={isDark}>
        How would you describe yourself?
      </ContentTitle>
      <StyledTextWrapper>
        {!shortBio && <AnimatedPlaceholder items={placeholders} inputStyle={inputStyle} />}
        <TextInput
          dataTestId={TEST_USER_INFO_BIO_TEXT_FIELD}
          autoCapitalize={EAutoCapitalize.On}
          counterNumber={BIO_LENGTH}
          isDark={isDark}
          placeholder=""
          maxLength={BIO_LENGTH}
          setText={handleChange}
          text={shortBio}
        />
      </StyledTextWrapper>
    </Layout>
  );
};

export default Bio;
