import React, { FC, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useMarkViewedInChannelsMutation } from 'constants/graphqlTypes';

import { useNavigationRef } from 'components/Navigation/helpers/hooks';
import DetailSlider from 'components/DetailSlider';

import { useGetListItemsId } from 'graphQL/list/hooks';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';

import { transformRoute } from 'helpers/routingHelper';

import { ROUTE_LIST_DETAIL, ROUTE_LIST_ITEM } from 'routes';
import { COLORS } from 'styles/constants';

const ListItem: FC = () => {
  const { listId, pickId } = useParams<{ listId?: string; pickId?: string }>();
  const { replace } = useHistory();
  const { state } = useLocation();

  const { ids, loading, loadMore } = useGetListItemsId(listId);
  const { ids: filteredIds, loading: isLoadingItem } = useGetListItemsId(listId, pickId);
  const { card } = useGetDetailCard(pickId, listId);
  const { isNewInChannels } = card ?? {};

  const [markedViewedInChannels] = useMarkViewedInChannelsMutation();

  const handleRoute = (id: string) =>
    replace({
      pathname: transformRoute(ROUTE_LIST_ITEM, { listId, pickId: id }),
      state: {
        ...state,
        listId,
      },
    });

  useNavigationRef(COLORS.white[100]);

  useEffect(() => {
    if (!isLoadingItem && !filteredIds.includes(pickId ?? '') && !loading) {
      replace(transformRoute(ROUTE_LIST_DETAIL, { listId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredIds]);

  useEffect(() => {
    if (listId && isNewInChannels) {
      markedViewedInChannels({ variables: { referrerItemId: listId, pickId } });
    }
  }, [isNewInChannels]);

  return ids.length && card ? (
    <DetailSlider handleLoadItems={loadMore} handleRoute={handleRoute} card={card} items={ids.map((id) => ({ id }))} />
  ) : null;
};

export default ListItem;
