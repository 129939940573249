import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { StyledList } from 'components/ProfileSettings/Menu/styled';

import { TEST_NAVIGATION_DOT_MENU_BTN } from 'constants/aqa/common';
import Modal from 'components/Modal';
import DotMenuButton from './DotMenuButton';

interface IMenuWrapProps {
  handleModal?: (isOpen: boolean) => void;
  isOpen: boolean;
  isPortal?: boolean;
  dotMenuBtnDataTestId?: string;
}

const MenuWrap: FC<IMenuWrapProps> = ({ children, handleModal, isOpen, isPortal = false, dotMenuBtnDataTestId }) => {
  const portalContainer = document.getElementById('modal-root');

  if (!portalContainer) {
    return null;
  }

  return (
    <>
      <DotMenuButton
        dataTestId={dotMenuBtnDataTestId ?? TEST_NAVIGATION_DOT_MENU_BTN}
        handler={() => handleModal?.(true)}
      />
      {isOpen &&
        (isPortal ? (
          createPortal(
            <Modal handleClose={() => handleModal?.(false)}>
              <StyledList>{children}</StyledList>
            </Modal>,
            portalContainer
          )
        ) : (
          <Modal handleClose={() => handleModal?.(false)}>
            <StyledList>{children}</StyledList>
          </Modal>
        ))}
    </>
  );
};

export default MenuWrap;
