import { styled } from 'linaria/react';

import { BREAKPOINTS } from 'styles/constants';

export const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-height: 60vh;
  }
`;
