import { styled } from 'linaria/react';

import { BREAKPOINTS, FONTS, COLORS } from '../../../styles/constants';

export const StyledModalWrapper = styled.div`
  align-items: center;
  background-color: ${COLORS.black[25]};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2200;
`;

export const StyledModal = styled.div`
  background: ${COLORS.white['100']};
  border-radius: 14px;
  min-width: 250px;
  max-width: 270px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-width: 370px;
  }
`;

export const StyledContent = styled.div`
  padding: 16px;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 24px;
  }
`;

export const StyledHead = styled.h3<{ isFirstLetterBig: boolean }>`
  color: ${COLORS.brown.dark['100']};
  font-family: ${FONTS.appleDevises}, ${FONTS.secondary};
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  line-height: 22px;
  padding: 0;
  word-break: break-word;

  &:first-letter {
    text-transform: ${({ isFirstLetterBig }) => (isFirstLetterBig ? 'uppercase' : 'initial')};
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    font-size: 22px;
    line-height: 29px;
  }
`;

export const StyledTitle = styled.p`
  color: ${COLORS.brown.dark['100']};
  font-family: ${FONTS.appleDevises}, ${FONTS.secondary};
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  padding-top: 2px;
  word-break: break-word;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    font-size: 14px;
    line-height: 19.6px;
    padding-top: 4px;
  }
`;

export const StyledButtons = styled.div<{ isColumnDirection?: boolean }>`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  width: 100%;
  border-top: ${({ isColumnDirection }) => (!isColumnDirection ? `1px solid ${COLORS.gray.light}` : 'none')};

  > button {
    border-top: ${({ isColumnDirection }) => (isColumnDirection ? `1px solid ${COLORS.gray.light}` : 'none')};
    min-width: ${({ isColumnDirection }) => (isColumnDirection ? '100%' : '48%')};
  }
`;

export const StyledButton = styled.button`
  background: ${COLORS.transparent};
  border: none;
  color: ${COLORS.blue};
  cursor: pointer;
  font-family: ${FONTS.appleDevises}, ${FONTS.secondary};
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  min-width: 48%;
  max-width: 80%;
  padding: 11px 0;

  &:first-letter {
    text-transform: uppercase;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    font-size: 20px;
    line-height: 29px;
  }
`;

export const StyledSubmit = styled(StyledButton)<{ isRed: boolean }>`
  color: ${({ isRed }) => (isRed ? COLORS.red[100] : COLORS.blue)};
  font-weight: 600;
`;

export const StyledSecondButton = styled(StyledButton)<{ isRed: boolean }>`
  color: ${({ isRed }) => (isRed ? COLORS.red[100] : COLORS.blue)};
  width: 100%;
`;
