import React, { FC } from 'react';

import useTheme from 'helpers/useTheme';

import { COLORS } from 'styles/constants';
import { generateLayoutTestIds } from 'constants/aqa/components/layout';
import ContentSubtitle from 'components/UI/ContentSubtitle';
import { StyledHeader, StyledTitle, StyledWrap } from './styled';

const SinglePageLayout: FC<{
  title?: string;
  subTitle?: string;
  paddingTop?: number;
  paddingBottom?: number;
  pageTestId?: string;
}> = ({ title, subTitle, children, paddingTop, paddingBottom, pageTestId }) => {
  const isDark = useTheme();

  const { titleTestId, subtitleTestId } = generateLayoutTestIds(pageTestId);

  return (
    <StyledWrap id="single-page-layout" paddingTop={paddingTop} paddingBottom={paddingBottom}>
      {(!!title || !!subTitle) && (
        <StyledHeader>
          {title && (
            <StyledTitle data-testid={titleTestId} isDark={isDark}>
              {title}
            </StyledTitle>
          )}
          {subTitle && (
            <ContentSubtitle
              dataTestId={subtitleTestId}
              color={!isDark ? COLORS.brown.dark[100] : ''}
              isDark={isDark}
              text={subTitle}
            />
          )}
        </StyledHeader>
      )}
      {children}
    </StyledWrap>
  );
};

export default SinglePageLayout;
