import React, { FC, useState } from 'react';
import { cx } from 'linaria';

import Avatar from 'components/UI/Avatar';
import getAuthUser from 'helpers/getAuthUser';
import InteractionBlock from 'components/InteractionBlock';
import UILink from 'components/UI/Link';

import { ROUTE_USER_PROFILE } from 'routes';
import { MessageSchema, MessageType } from 'constants/graphqlTypes';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { DELETED_USER, REF_PLACEHOLDER } from 'components/Explore/Item/helpers';
import { useMessageChatContext } from 'helpers/useChatProvider';
import SystemMessage from '../Message/SystemMessage';
import { StyledMessageAvatar, StyledMessageList, StyledWrap } from './styled';

import Message from '../Message';
import { StyledFadeIn } from '../Message/styled';

interface IMessageGroupProps {
  messages: MessageSchema[];
  isDark: boolean;
  isKeyboardOpen: boolean;
  isGroupChat: boolean;
  totalCount: number;
  isBlockedGroupChat?: boolean | null;
}

const MessageGroup: FC<IMessageGroupProps> = ({
  messages,
  isDark,
  isKeyboardOpen,
  isGroupChat,
  totalCount,
  isBlockedGroupChat,
}) => {
  const { userId: myId } = getAuthUser();

  const isSystemMessage = messages?.[0].messageType === MessageType.System;
  const user = messages[0].sender?.__typename === 'UserShortSchema' ? messages[0].sender : null;
  const isMine = user?.id === myId;
  const isDeletedUser = user?.id === REF_PLACEHOLDER;
  const [isFirstMessageUnavailable, setIsFirstMessageUnavailable] = useState(true);
  const isLastDeleted =
    !!messages[0].deletedAt || messages[0].message.__typename === 'DeletedMessageSchema' || isFirstMessageUnavailable;
  const isLastLinkPreview = messages[0].messageType === MessageType.LinkPreview;
  const isLastPickPreview = messages[0].messageType === MessageType.Pick;

  const { username: profileName } = user ?? {};

  const name = generateFullName(user);

  const toggleTimestamps = (show: boolean) =>
    document
      .querySelectorAll('.message')
      .forEach((el) => (show ? el.classList.add('message-swiped') : el.classList.remove('message-swiped')));

  const [allowSwipeLeft, setAllowSwipeLeft] = useState(true);

  const { shouldPlay1stMessageAnimation } = useMessageChatContext();

  if (isSystemMessage) {
    return (
      <>
        {messages.map((message) => (
          <SystemMessage message={message} key={message?.id} />
        ))}
      </>
    );
  }

  return user ? (
    <InteractionBlock
      swipeLeftCallback={() => isMine && allowSwipeLeft && toggleTimestamps(true)}
      touchEndCallback={() => isMine && toggleTimestamps(false)}
    >
      <StyledWrap isRight={isMine}>
        <StyledMessageAvatar
          isLinkPreview={(isLastLinkPreview || isLastPickPreview) && !isLastDeleted}
          className={cx(
            isMine ? 'message message-right' : '',
            shouldPlay1stMessageAnimation && totalCount === 1 ? StyledFadeIn : undefined
          )}
        >
          <UILink
            route={ROUTE_USER_PROFILE}
            params={{ profileName: isDeletedUser || !profileName ? DELETED_USER : profileName }}
          >
            <Avatar dimension={28} user={user} />
          </UILink>
        </StyledMessageAvatar>
        <StyledMessageList isRight={isMine}>
          {messages.map((message, index) => {
            const nextMessageType = index > 0 ? messages[index - 1].messageType : undefined;
            return (
              <Message
                name={isGroupChat && index === messages.length - 1 && !isMine && name !== 'anyone' ? name : ''}
                key={message.id}
                message={message}
                nextMessageType={nextMessageType}
                isDark={isDark}
                isKeyboardOpen={isKeyboardOpen}
                setAllowSwipeLeft={setAllowSwipeLeft}
                totalCount={totalCount}
                isBlockedGroupChat={isBlockedGroupChat}
                setIsMessageUnavailable={index === 0 ? setIsFirstMessageUnavailable : undefined}
              />
            );
          })}
        </StyledMessageList>
      </StyledWrap>
    </InteractionBlock>
  ) : null;
};

export default MessageGroup;
