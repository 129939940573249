import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as ThoughtIcon } from 'assets/icons/Thought.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/TrashCan.svg';
import Modal from 'components/Modal';
import useDisableScroll from 'helpers/useDisableScroll';
import useOpenModal from 'helpers/useOpenModal';
import MenuButton from 'components/UI/MenuButton';

import { ROUTE_EDIT_FEED_NAME, ROUTE_EDIT_FEED_TOPICS, ROUTE_EDIT_FEED_USERS } from 'routes';
import { useGetFeedTopicsData, useGetFeedUsersData, useGetHeadTopics, useDeleteFeed } from 'graphQL/feeds/hooks';
import { transformRoute, useLink } from 'helpers/routingHelper';
import { generateEditFeedTopicsText } from 'components/CustomFeeds/helpers';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { ESelectionMode } from 'components/CustomFeeds/constants';

import { StyledInfo, StyledLabel } from 'components/ProfileSettings/EditProfile/styled';
import { StyledOption } from 'components/ListSettings/EditList/styled';
import { StyledDeleteWrap, StyledPicksOptionWrap, StyledWrapper } from './styled';
import EditFeedButton from '../../EditFeedButton';
import { sortFeedTopics } from '../../helpers';

interface IEditFeedModalProps {
  setTotalSelectedTopics: Dispatch<SetStateAction<number>>;
  setSelectionTopicMode: Dispatch<SetStateAction<ESelectionMode>>;
}

const EditFeedModal: FC<IEditFeedModalProps> = ({ setTotalSelectedTopics, setSelectionTopicMode }) => {
  const [isDeleteFeedModalOpen, setIsDeleteFeedModalOpen] = useState(false);
  const { goBack, replace } = useHistory();
  const handleExit = () => goBack();

  const { state } = useLocation<{ variable: { usersTotalCount: number; feedId: string; title: string } }>();
  const { feedId, title } = state?.variable ?? {};

  useDisableScroll();

  const { items, feedSearchParameters, totalCount: usersTotalCount } = useGetFeedUsersData(feedId, { limit: 2 });

  const { totalCount, isLoading: headTopicsLoading } = useGetHeadTopics();
  const { totalCount: feedTopicsTotalCount, topics: feedTopics, loading: feedTopicsLoading } = useGetFeedTopicsData(
    feedId
  );
  const sortedFeedTopics = sortFeedTopics(feedTopics);

  const editedParams = JSON.parse(feedSearchParameters || '{}');
  const { deleteFeed } = useDeleteFeed(feedId);

  const editName = useLink(transformRoute(ROUTE_EDIT_FEED_NAME, { feedId, title }));
  const firstFeedTopic = sortedFeedTopics[0];
  const showAllPicks = feedTopicsTotalCount === totalCount;

  const topicsTitle = showAllPicks
    ? 'All topics'
    : `${feedTopicsTotalCount} topic${feedTopicsTotalCount !== 1 ? 's' : ''}`;
  const usersTitle = `${usersTotalCount} user${usersTotalCount !== 1 ? 's' : ''}`;

  const gotoFeedCreate = useOpenModal(ROUTE_EDIT_FEED_TOPICS, false, {
    title,
    allExistingTopicsCount: totalCount,
    feedId,
  });
  const gotoFeedUsers = useOpenModal(ROUTE_EDIT_FEED_USERS, false, {
    feedId,
    editedParams,
    editedTotalCount: usersTotalCount,
    title,
  });

  const onDeleteButtonClick = () => setIsDeleteFeedModalOpen(true);
  const handleDeleteFeed = () => {
    deleteFeed();
    setIsDeleteFeedModalOpen(false);
  };

  useEffect(() => {
    // initial selected topics, number we show in continue button before any topic is selected or deselected.
    setTotalSelectedTopics(feedTopicsTotalCount);
    if (feedTopicsTotalCount === totalCount && !feedTopicsLoading && !headTopicsLoading) {
      setSelectionTopicMode(ESelectionMode.All);
    } else if (feedTopicsTotalCount !== totalCount && !feedTopicsLoading && !headTopicsLoading) {
      setSelectionTopicMode(ESelectionMode.Select);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedTopicsTotalCount, totalCount, feedTopicsLoading, headTopicsLoading]);

  return (
    <Modal handleClose={handleExit} enableInnerScroll>
      <StyledWrapper>
        <StyledOption onClick={() => replace(editName)}>
          <StyledLabel isPointer>Feed name</StyledLabel>
          <StyledInfo>{title}</StyledInfo>
        </StyledOption>
        {!feedTopicsLoading && !headTopicsLoading && (
          <StyledPicksOptionWrap>
            <StyledOption>
              <StyledLabel isPointer>Show all picks from topic</StyledLabel>
              <EditFeedButton
                showAllPicks={showAllPicks}
                title={topicsTitle}
                subtitle={generateEditFeedTopicsText(feedTopicsTotalCount, sortedFeedTopics)}
                Icon={ThoughtIcon}
                handleClick={gotoFeedCreate}
                srcImage={firstFeedTopic?.imageSet?.images?.[0]?.url ?? ''}
              />
            </StyledOption>
          </StyledPicksOptionWrap>
        )}
        {items && !!usersTotalCount && (
          <StyledOption>
            <StyledLabel isPointer>Added by</StyledLabel>
            <EditFeedButton
              title={usersTitle}
              Icon={ThoughtIcon}
              handleClick={gotoFeedUsers}
              users={items.slice(0, 2)}
              usersTotalCount={usersTotalCount}
            />
          </StyledOption>
        )}
      </StyledWrapper>
      <StyledDeleteWrap>
        <MenuButton isRed text="Delete this feed" Icon={TrashIcon} handler={onDeleteButtonClick} />
      </StyledDeleteWrap>
      {isDeleteFeedModalOpen && (
        <ConfirmationModal
          titleText="Delete feed"
          subTitle="This will permanently delete this feed."
          buttonText="Delete"
          handleAccept={handleDeleteFeed}
          handleCancel={() => setIsDeleteFeedModalOpen(false)}
        />
      )}
    </Modal>
  );
};

export default EditFeedModal;
