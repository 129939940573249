import { HOME_VIEW, WEB_PREFIX } from 'constants/common';
import { EDiscoverSlotTypeOptions, EDiscoverItemLayoutOptions } from 'constants/graphqlTypes';

export const FEATURED_TAB_VIEW = 'featured-tab-view';
export const FOLLOWING_TAB_VIEW = 'following-tab-view';
export const EVERYONE_TAB_VIEW = 'everyone-tab-view';

export const TEST_ID_FEATURED_START = `${WEB_PREFIX}${HOME_VIEW}_${FEATURED_TAB_VIEW}`;
export const TEST_ID_FOLLOWING_START = `${WEB_PREFIX}${HOME_VIEW}_${FOLLOWING_TAB_VIEW}`;

export const TEST_FOLLOWING_TAB_MIXED_GROUP_SECTION = `${TEST_ID_FOLLOWING_START}_mixed-group-section`;
export const TEST_FOLLOWING_TAB_LIST_GROUP_SECTION = `${TEST_ID_FOLLOWING_START}_list-group-section`;

export const generateLowerCaseSectionName = (upperCaseName: string): string =>
  upperCaseName.split('_').join('-').toLowerCase();

export const getExploreSectionDataTestId = (
  type: EDiscoverSlotTypeOptions | EDiscoverItemLayoutOptions,
  ending: string
): string => `${TEST_ID_FEATURED_START}_${generateLowerCaseSectionName(type)}-${ending}`;

export const getExploreItemDataTestId = (
  type: EDiscoverSlotTypeOptions | EDiscoverItemLayoutOptions,
  tab: string
): string => `${WEB_PREFIX}${HOME_VIEW}_${tab}_${generateLowerCaseSectionName(type)}-pick-item`;
