import { useEffect } from 'react';
import { Location } from 'history';
import { useHistory, useLocation } from 'react-router-dom';

import { ILink } from 'components/AddContent/helpers/models';
import { OFF_PLATFORM_SHARING_REDIRECT_STORAGE_KEY } from 'constants/common';
import { ROUTE_DISCOVERY } from '../routes';
import getAuthUser from './getAuthUser';
import useToast from './useToast';

export interface IReplaceParams {
  [key: string]: string | number | undefined;
}

export const getDefaultRouteForBg = (route: string): Location => ({
  pathname: route,
  state: '',
  search: '',
  hash: '',
});

export const transformRoute = (route: string, params: IReplaceParams): string =>
  Object.keys(params).reduce<string>((accum, value) => accum.replace(`:${value}`, params[value] as string), route);

export const useGoBack = (route?: string): (() => void) => {
  const { goBack, length: historyLength, replace, location } = useHistory();

  return () => {
    if (historyLength >= 3 && location.key) {
      goBack();
    } else {
      replace(route ?? ROUTE_DISCOVERY);
    }
  };
};

export const useGetSearchParam = (paramName: string): string | null => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  return urlSearchParams.get(paramName);
};

export const useRemoveSearchParam = (paramName: string): (() => void) => {
  const { search } = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(search);

  return () => {
    if (urlSearchParams.has(paramName)) {
      urlSearchParams.delete(paramName);
    }
    history.replace({ search: urlSearchParams.toString() });
  };
};

export const useLink = (path?: string): ILink => {
  const { state } = useLocation();

  return { pathname: path ?? '#', state };
};

export const useOffPlatformSharingRedirect = (): void => {
  const redirectUrl = useGetSearchParam('clb');

  useEffect(() => {
    if (redirectUrl && !getAuthUser().userId) {
      localStorage.setItem(OFF_PLATFORM_SHARING_REDIRECT_STORAGE_KEY, redirectUrl);
    }

    if (redirectUrl && getAuthUser().userId) {
      window.location.assign(redirectUrl);
    }
  }, [redirectUrl]);
};

export const useContentNotFound = (): (() => void) => {
  const { push } = useHistory();
  const { setToast } = useToast();

  return () => {
    push(ROUTE_DISCOVERY);
    setToast({ isToastOpen: true, toastItemName: 'Content not found' });
  };
};
