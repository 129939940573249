import React, { FC, useState } from 'react';

import { TagSchema } from 'constants/graphqlTypes';
import ListItem from 'components/UI/ListItem';
import { EToggleType } from 'components/UI/Toggle/models';
import { getConnectionFollowTopicDataTestId } from 'constants/aqa/profileConnections';

interface ITopicProps {
  topic: TagSchema;
  updateSelectedIdsList: (id: string, isSelected: boolean) => void;
}

const Topic: FC<ITopicProps> = ({ topic, updateSelectedIdsList }) => {
  const { name: title, id, isFollowEnable: isChecked, imageSet } = topic ?? {};
  const src = imageSet?.images?.[0].url;
  const [isSelected, setIsSelected] = useState(!!isChecked);

  const handleClick = () => {
    updateSelectedIdsList(id, !isSelected);
    setIsSelected((prevState) => !prevState);
  };

  return (
    <ListItem
      image={src}
      title={title ?? ''}
      isDark
      handleClick={handleClick}
      toggleType={EToggleType.CheckPlus}
      hasToggle
      isEnable={isSelected}
      dataTestId={
        title ? getConnectionFollowTopicDataTestId(title?.split(' ').join('-').toLocaleLowerCase()) : undefined
      }
    />
  );
};

export default Topic;
