import React, { cloneElement, FC, useState } from 'react';
import { createPortal } from 'react-dom';

import ConfirmationModal from 'components/UI/ConfirmationModal';

import { useBlockUser } from 'graphQL/blocking/hooks';

interface IBlockUserButtonProps {
  handler?: () => void;
  userId: string;
  isBlockedByMe: boolean;
  children: JSX.Element;
}

const BlockUserButton: FC<IBlockUserButtonProps> = ({ userId, isBlockedByMe, handler, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const portalContainer = document.getElementById('modal-root');

  const { blockUser, unblockUser } = useBlockUser(userId, undefined, handler);

  const blockWord = `${isBlockedByMe ? 'un' : ''}block`;

  const handleAccept = () => {
    if (isBlockedByMe) {
      unblockUser();
    } else {
      blockUser();
    }
    setIsOpen(false);
  };

  return children ? (
    <>
      {cloneElement(children, { onClick: () => setIsOpen(true), handler: () => setIsOpen(true) })}
      {isOpen &&
        portalContainer &&
        createPortal(
          <ConfirmationModal
            buttonText={blockWord}
            handleAccept={handleAccept}
            handleCancel={() => setIsOpen(false)}
            isWarning={!isBlockedByMe}
            subTitle={
              isBlockedByMe
                ? 'They will be able to message and follow you again.'
                : 'They will no longer be able to message or follow you. If they attempt to follow or message you they will be notified they are blocked. You can still see each other’s profiles and picks.'
            }
            titleText={`${blockWord} user?`}
            ignoreUseModal
          />,
          portalContainer
        )}
    </>
  ) : null;
};

export default BlockUserButton;
