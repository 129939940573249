import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { useGetUserId, useProfile } from 'graphQL/profile/hooks';

import usePageType from 'helpers/usePageType';
import NotificationInfo from 'components/Profile/NotificationInfo';
import { generateName } from 'components/Profile/ProfileTop/helpers';
import { ECardPermissionsOptions } from 'constants/graphqlTypes';
import SearchButton from './SearchButton';
import AnotherProfileButton from './AnotherProfileButton/AnotherProfileButton';
import MyProfileButton from './MyProfileButton';
import MyAllPicksButton from './MyAllPicksButton';
import ListButton from './ListButton';
import CardButton from './CardButton';
import BlockedAccountsButton from './BlockedAccountsButton';
import MenuWrap from './MenuWrap';
import MessagesButton from './MessagesButton';
import NotificationsButton from './NotificationsButton';
import GuestListSeriesButton from './GuestListSeriesButton';
import GuestListButton from './GuestListButton';

import { StyledRightMenu } from '../styled';
import SparkButton from './SparkButton';
import MyAllSparksButton from './MyAllSparksButton';
import AnotherAllPicksButton from './AnotherAllPicksButton';
import AnotherAllSparksButton from './AnotherAllSparksButton';
import SavedPicksButtons from './SavedPicksButtons';
import AddCustomFeedButton from './AddCustomFeedButton';
import FeedDetailButton from './FeedDetailButton';

const RightMenu: FC = () => {
  const {
    isMyProfile,
    isAnotherProfile,
    isListItem,
    isAllPicksItem,
    isAllPicks,
    isAllSparks,
    isDiscovery,
    isExactlyProfile,
    isListDetail,
    isBlockedAccounts,
    isItemDetail,
    isGuestListSeries,
    isGuestList,
    isSparkDetail,
    isMutedAccounts,
    isCustomFeeds,
    isFeedDetail,
  } = usePageType();

  const { pickId, listId, feedId, id } = useParams<{ pickId: string; listId: string; feedId: string; id: string }>();
  const { card } = useGetDetailCard(isItemDetail ? pickId : listId, isItemDetail ? feedId ?? listId : undefined);
  const { profileName } = useParams<{ profileName: string }>();
  const { userId } = useGetUserId(profileName);
  const { userData } = useProfile(userId);
  const { isFollowedByMe, isNotificationsEnable, username, userInfo } = userData ?? {};
  const { firstName, lastName } = userInfo ?? {};
  const {
    isCollaborative,
    isNotificationsEnable: isListNotificationsEnable,
    title,
    permissions,
    isSavedByMe,
    isInChannel,
  } = card ?? {};

  const hasEditPermission = permissions?.includes(ECardPermissionsOptions.EditCollectionItems);

  const isChannelNotificationsEnable =
    (!!isListNotificationsEnable && hasEditPermission && !!isInChannel) || (!!isInChannel && !hasEditPermission);

  return (
    <StyledRightMenu className="right-menu">
      {isDiscovery && (
        <li>
          <MessagesButton />
        </li>
      )}
      {isAnotherProfile && isExactlyProfile && userId && !!isFollowedByMe && (
        <li>
          <NotificationsButton
            isNotificationsEnable={!!isNotificationsEnable}
            userId={userId}
            name={`${firstName} ${lastName}`}
          />
          <NotificationInfo userName={generateName(username, firstName)} />
        </li>
      )}
      {isListDetail && (
        <li>
          <NotificationsButton
            isNotificationsEnable={isChannelNotificationsEnable}
            isCollaborative={isCollaborative}
            listId={listId}
            name={title ?? ''}
            hasEditPermission={hasEditPermission}
          />
        </li>
      )}
      <li>
        {isDiscovery && <SearchButton />}
        {isAnotherProfile && isExactlyProfile && !!userData && <AnotherProfileButton />}
        {isMyProfile && isExactlyProfile && <MyProfileButton />}
        {isMyProfile && isAllSparks && <MyAllSparksButton />}
        {!isMyProfile && isAllSparks && <AnotherAllSparksButton />}
        {isMyProfile && isAllPicks && !isAllPicksItem && <MyAllPicksButton />}
        {!isMyProfile && isAllPicks && !isAllPicksItem && <AnotherAllPicksButton />}
        {isListDetail && !isListItem && card && <ListButton list={card} />}
        {isItemDetail && !isSavedByMe && (card ? <CardButton card={card} /> : <MenuWrap isOpen={false} />)}
        {isItemDetail && isSavedByMe && card && <SavedPicksButtons card={card} />}
        {isBlockedAccounts && <BlockedAccountsButton />}
        {isMutedAccounts && <BlockedAccountsButton isMutedSearch />}
        {isGuestListSeries && <GuestListSeriesButton guestListSeriesId={id} />}
        {isGuestList && id && <GuestListButton guestListId={id} />}
        {isSparkDetail && <SparkButton />}
        {isCustomFeeds && <AddCustomFeedButton />}
        {isFeedDetail && !isItemDetail && <FeedDetailButton />}
      </li>
    </StyledRightMenu>
  );
};

export default RightMenu;
