import { useLocation, useHistory } from 'react-router-dom';

import Modal from 'components/Modal/Modal';
import { useSearchChat } from 'graphQL/messages/hooks';
import React, { FC, useState } from 'react';

import { StyledSearchWrap } from 'pages/Messages/styled';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';

import Input from 'components/UI/Input';
import ChatList from './ChatList';

const SelectChatModal: FC = () => {
  const { goBack } = useHistory();

  const [searchString, setSearchString] = useState('');
  const { state } = useLocation<{ variable?: { [key: string]: string | undefined } }>();
  const collectionId = state?.variable?.collectionId;

  const { items, loading, loadMore } = useSearchChat(searchString, collectionId);

  return (
    <Modal fadeIn={false} handleClose={goBack} enableInnerScroll>
      <StyledSearchWrap>
        <Input
          Icon={SearchIcon}
          placeholder="Search"
          text={searchString}
          setText={setSearchString}
          hasCrossIcon
          isDark
        />
      </StyledSearchWrap>
      <ChatList items={items} loading={loading} loadMore={loadMore} />
    </Modal>
  );
};

export default SelectChatModal;
