import { COLORS } from 'styles/constants';

export enum EUiButtonType {
  Primary = 'primary',
  Emphasized = 'emphasized',
  Transparent = 'transparent',
  Danger = 'danger',
}

const { Primary, Emphasized, Transparent, Danger } = EUiButtonType;

export const BUTTON_STYLES = {
  background: {
    [Primary]: {
      default: {
        light: COLORS.white.off[100],
        dark: COLORS.white[10],
      },
      pressed: {
        light: `linear-gradient(0deg, ${COLORS.brown.dark[10]}, ${COLORS.brown.dark[10]}), ${COLORS.white.off[100]}`,
        dark: COLORS.white[30],
      },
      disabled: {
        light: COLORS.white.off[100],
        dark: COLORS.white[10],
      },
    },
    [Emphasized]: {
      default: {
        light: COLORS.brown.dark[100],
        dark: COLORS.white[100],
      },
      pressed: {
        light: `linear-gradient(0deg, ${COLORS.white[10]}, ${COLORS.white[10]}), ${COLORS.brown.dark[100]}`,
        dark: COLORS.white.off[100],
      },
      disabled: {
        light: COLORS.white.off[100],
        dark: COLORS.white[10],
      },
    },
    [Transparent]: {
      default: {
        light: COLORS.transparent,
        dark: COLORS.transparent,
      },
      pressed: {
        light: COLORS.transparent,
        dark: COLORS.transparent,
      },
      disabled: {
        light: COLORS.transparent,
        dark: COLORS.transparent,
      },
    },
    [Danger]: {
      default: {
        light: COLORS.red.danger,
        dark: COLORS.red.danger,
      },
      pressed: {
        light: COLORS.red.dark,
        dark: COLORS.red.dark,
      },
      disabled: {
        light: COLORS.red.light,
        dark: COLORS.red.light,
      },
    },
  },
  color: {
    [Primary]: {
      default: {
        light: COLORS.brown.dark[100],
        dark: COLORS.white[100],
      },
      pressed: {
        light: COLORS.brown.dark[100],
        dark: COLORS.white[100],
      },
      disabled: {
        light: COLORS.brown.light[100],
        dark: COLORS.brown.light[100],
      },
    },
    [Emphasized]: {
      default: {
        light: COLORS.white[100],
        dark: COLORS.brown.dark[100],
      },
      pressed: {
        light: COLORS.white[100],
        dark: COLORS.brown.dark[100],
      },
      disabled: {
        light: COLORS.brown.light[100],
        dark: COLORS.brown.light[100],
      },
    },
    [Transparent]: {
      default: {
        light: COLORS.brown.dark[100],
        dark: COLORS.white[100],
      },
      pressed: {
        light: COLORS.brown.dark[100],
        dark: COLORS.white[100],
      },
      disabled: {
        light: COLORS.brown.dark[40],
        dark: COLORS.brown.light[100],
      },
    },
    [Danger]: {
      default: {
        light: COLORS.white[100],
        dark: COLORS.white[100],
      },
      pressed: {
        light: COLORS.white[100],
        dark: COLORS.white[100],
      },
      disabled: {
        light: COLORS.brown.dark[40],
        dark: COLORS.brown.dark[40],
      },
    },
  },
};
