import React, { FC } from 'react';

import { CardSchema } from 'constants/graphqlTypes';
import { generateLowerCaseSectionName } from 'constants/aqa/explore';
import { StyledList } from '../styled';
import { StyledItem } from '../DetailView/styled';

import Item from './Item';

const TitleView: FC<{ items: CardSchema[]; isDark: boolean; pageTestId?: string }> = ({
  items,
  isDark,
  pageTestId,
}) => (
  <StyledList>
    {items.map((item) => (
      <StyledItem key={item.id}>
        <Item
          dataTestId={`${pageTestId}_${generateLowerCaseSectionName(item.type)}-pick-item`}
          item={item}
          isDark={isDark}
        />
      </StyledItem>
    ))}
  </StyledList>
);

export default TitleView;
