import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { ROUTE_USER_PROFILE } from 'routes';

import { transformRoute } from 'helpers/routingHelper';
import { useGetMessageContent, useGetProfileMessage } from 'graphQL/messages/hooks';

import Avatar from 'components/UI/Avatar';
import Grain from 'components/UI/Grain';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';
import { ISendContentData } from 'graphQL/messages/models';
import { MessageContentStatus, MessageItemType, MessageSchema } from 'constants/graphqlTypes';
import { MAX_DISPLAYED_PICKS } from 'constants/common';
import ReactionsToken from 'components/Messages/Message/ReactionsToken';
import { useMessagesChatData } from 'components/Messages/helpers/hooks';
import { StyledFollowsInfo, StyledLink, StyledName, StyledUsername } from 'components/Card/UserCard/styled';
import { EMessageContentType } from '../../models';
import UnavailableContent from '../UnavailableContent';

import { StyledVerificationWrap, StyledWrapper } from './styled';
import { StyledTokenWrap } from '../../styled';

const UserCard: FC<{
  userId: string;
  isMine?: boolean;
  isDark: boolean;
  messageRef: React.RefObject<HTMLDivElement>;
  setIsContentDeleted: Dispatch<SetStateAction<boolean>>;
  message: MessageSchema;
}> = ({ userId, isMine, messageRef, setIsContentDeleted, isDark, message }) => {
  const customData: unknown = message.message.__typename === 'UserMessageSchema' && message.message.data;

  const dataObject: ISendContentData | null = typeof customData === 'string' ? JSON.parse(customData) : null;
  const { gradientStart: messageGradientStart, gradientEnd: messageGradientEnd } = dataObject?.bgColor ?? {};

  const { gradientEnd, gradientStart, fontColor, picksCount, connectionsCount } = useGetProfileMessage(userId);
  const { messageContentStatus, user } = useGetMessageContent({ itemType: MessageItemType.User, entityId: userId });
  const { chatId } = useMessagesChatData();

  useEffect(() => {
    setIsContentDeleted(messageContentStatus === MessageContentStatus.Deleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageContentStatus]);

  const { userInfo, username, isVerified } = user ?? {};
  const { firstName, lastName } = userInfo ?? {};
  const picks =
    picksCount > MAX_DISPLAYED_PICKS
      ? `${MAX_DISPLAYED_PICKS}+ picks`
      : `${picksCount} pick${picksCount && picksCount === 1 ? '' : 's'}`;
  const connections = `${connectionsCount} connection${connectionsCount && connectionsCount === 1 ? '' : 's'}`;

  const contentIds = {
    chatId,
    messageId: message?.id || '',
  };

  if (messageContentStatus === MessageContentStatus.Deleted) {
    return (
      <UnavailableContent
        isDark={isDark}
        isMine={isMine}
        contentType={EMessageContentType.Profile}
        messageContentStatus={messageContentStatus}
      />
    );
  }

  return user ? (
    <StyledWrapper
      fontColor={fontColor}
      isRight={!!isMine}
      gradientStart={messageGradientStart || gradientStart}
      gradientEnd={messageGradientEnd || gradientEnd}
      ref={messageRef}
    >
      {gradientEnd && <Grain />}
      <StyledLink to={transformRoute(ROUTE_USER_PROFILE, { profileName: username })}>
        <Avatar user={user} dimension={92} />
        <StyledName>{`${firstName} ${lastName}`}</StyledName>
        {isVerified && (
          <StyledVerificationWrap>
            <VerificationBadge hasCaption />
          </StyledVerificationWrap>
        )}
        <StyledUsername>:{username}</StyledUsername>
      </StyledLink>
      <StyledFollowsInfo>
        {picks} · {connections}
      </StyledFollowsInfo>
      <StyledTokenWrap className="token-wrap" isRight={!!isMine}>
        <ReactionsToken contentIds={contentIds} isDark={isDark} />
      </StyledTokenWrap>
    </StyledWrapper>
  ) : null;
};

export default UserCard;
