import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

export const StyledWrap = styled.div`
  .dot-btn {
    svg {
      margin-top: 6px;

      path {
        fill: ${COLORS.white[100]};
      }
    }
  }
`;

export const StyledItem = styled.li`
  position: relative;
  border-bottom: 1px solid ${COLORS.white[30]};

  &:last-child {
    border: none;
  }
`;
