import React, { FC, Dispatch, SetStateAction } from 'react';

import Modal from 'components/Modal';

import TextInput from 'components/UI/TextInput';
import ContentTitle from 'components/UI/ContentTitle';

import {
  TEST_ID_UPLOAD_FROM_LINK_VIEW_ADD_BUTTON,
  TEST_ID_UPLOAD_FROM_LINK_VIEW_URL_NAME_LABEL,
} from 'constants/aqa/profile';
import { useUploadImageFormLink } from '../hooks';
import { StyledInputWrapper } from '../styled';

const AddFromLink: FC<{
  setUrl: Dispatch<SetStateAction<string | File>>;
  handleClose: () => void;
  setIsAddFromLink: Dispatch<SetStateAction<boolean>>;
}> = ({ setUrl, handleClose, setIsAddFromLink }) => {
  const { link, error, loading, change, upload } = useUploadImageFormLink(setUrl, () => setIsAddFromLink(false));

  const handleSubmit = () => {
    upload();
  };

  return (
    <Modal
      loading={loading}
      buttonText="Add"
      fadeIn={false}
      handleClose={handleClose}
      handlePrev={() => setIsAddFromLink(false)}
      handleDone={handleSubmit}
      dataTestIdDoneButton={TEST_ID_UPLOAD_FROM_LINK_VIEW_ADD_BUTTON}
    >
      <StyledInputWrapper>
        <ContentTitle isDark>Add from a link</ContentTitle>
        <TextInput
          placeholder="Copy & paste the image URL"
          setText={(value: string) => change(value)}
          text={link}
          error={error}
          dataTestId={TEST_ID_UPLOAD_FROM_LINK_VIEW_URL_NAME_LABEL}
          longDash={false}
        />
      </StyledInputWrapper>
    </Modal>
  );
};

export default AddFromLink;
