import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import LoaderContent from 'components/UI/LoaderContent';

import { MessagesThreadProvider } from 'helpers/useMessagesThreadProvider';
import { isSafariIos } from 'helpers/useUserAgent';
import FullScreenReactionAnimation from 'components/Reactions/Animations/Fullscreen/Animations';
import {
  FullScreenAnimationProvider,
  useFullScreenAnimationState,
} from 'components/Reactions/Animations/Fullscreen/hooks';
import { IUseMessages } from 'graphQL/messages/models';
import { StyledIosPlaceholder, StyledThread } from './styled';
import MessageGroup from '../MessageGroup';
import Separator from './Separator';
import ScrollToLatestButton from '../ScrollToLatestButton';
import NoMessagesPlaceholder from './NoMessagesPlaceholder';

interface IMessageThreadProps {
  messagesData: IUseMessages;
  isDark: boolean;
  isKeyboardOpen: boolean;
  isGroupChat: boolean;
  lastUnreadMessageId?: string;
  isBlockedGroupChat?: boolean | null;
}

const MessageThread: FC<IMessageThreadProps> = ({
  messagesData,
  isDark,
  isKeyboardOpen,
  isGroupChat,
  lastUnreadMessageId,
  isBlockedGroupChat,
}) => {
  const ref = useRef<HTMLElement>(null);
  const [wasScrolled, setWasScrolled] = useState(false);
  const [doesBlurExist, setDoesBlurExist] = useState(false);
  const { items: messagesGroups, totalCount, loadMore, loading } = messagesData;
  const scrollThread = () => {
    if (isSafariIos()) {
      ref.current?.scrollTo(0, ref.current?.scrollTop - 1);
      ref.current?.scrollTo(0, ref.current?.scrollTop + 1);
    }
  };

  useEffect(() => {
    if (!wasScrolled) {
      scrollThread();
      setWasScrolled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesGroups]);

  const { makeAnimation, animations, shouldPlayAnimation, setShouldPlayAnimation } = useFullScreenAnimationState();

  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    if (ref.current) {
      setShowScrollButton(Math.abs(ref.current.scrollTop) > ref.current.clientHeight);
    }
  };

  const scrollToLatest = () => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
      setTimeout(() => {
        ref.current?.scrollTo(0, 0);
      }, 100);
    }
  };

  useEffect(() => {
    if (lastUnreadMessageId) {
      setShouldPlayAnimation(lastUnreadMessageId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUnreadMessageId]);

  const isIosVirtualKeyboardOpen = isSafariIos() && isKeyboardOpen;

  return (
    <FullScreenAnimationProvider value={{ makeAnimation, animations, shouldPlayAnimation, setShouldPlayAnimation }}>
      <MessagesThreadProvider value={{ scrollThreadContent: scrollThread, ref, doesBlurExist, setDoesBlurExist }}>
        <FullScreenReactionAnimation />
        <StyledThread isIosVirtualKeyboardOpen={isIosVirtualKeyboardOpen} ref={ref} id="thread" onScroll={handleScroll}>
          {messagesGroups.map((group, index) => (
            <Fragment key={group[group.length - 1].id}>
              <MessageGroup
                isGroupChat={isGroupChat}
                isKeyboardOpen={isKeyboardOpen}
                isDark={isDark}
                messages={group}
                totalCount={totalCount}
                isBlockedGroupChat={isBlockedGroupChat}
              />
              <Separator group={group} prev={messagesGroups[index + 1]} />
            </Fragment>
          ))}
          {showScrollButton && (
            <ScrollToLatestButton
              text={messagesGroups.flat().some((message) => !message.isViewed) ? 'New messages' : ''}
              handler={scrollToLatest}
            />
          )}
          {loading ? <LoaderContent isDark={isDark} isCentered /> : <Waypoint onEnter={loadMore} />}
          <NoMessagesPlaceholder
            isDark={isDark}
            isIosVirtualKeyboardOpen={isIosVirtualKeyboardOpen}
            shouldShow={!messagesGroups.length && !loading}
          />
          {isSafariIos() && <StyledIosPlaceholder />}
        </StyledThread>
      </MessagesThreadProvider>
    </FullScreenAnimationProvider>
  );
};

export default MessageThread;
