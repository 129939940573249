import React, { FC } from 'react';

import useOpenModal from 'helpers/useOpenModal';
import { ROUTE_CREATE_FEED } from 'routes';

import { ReactComponent as PlusSmallIcon } from 'assets/icons/PlusSmall.svg';

import { StyledAddCustomFeed } from './styled';

const AddCustomFeedCard: FC<{ sectionId: string }> = ({ sectionId }) => {
  const gotoFeedCreate = useOpenModal(ROUTE_CREATE_FEED, undefined, { sectionId });

  return (
    <StyledAddCustomFeed onClick={gotoFeedCreate}>
      <PlusSmallIcon />
      <span>Add custom feed</span>
    </StyledAddCustomFeed>
  );
};

export default AddCustomFeedCard;
