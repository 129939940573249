import React, { FC, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';

import Modal from 'components/Modal';
import { useGetChannelPageSection, useGetChannelSectionItems, useReorderSectionItems } from 'graphQL/channel/hooks';
import {
  ChannelAdminFeedSchema,
  ChannelCustomFeedSchema,
  ChannelSectionItemsSortTypeOptions,
} from 'constants/graphqlTypes';

import { FEEDS_SORTING } from 'constants/common';
import { StyledTitle } from 'components/AddContent/AddStyle/styled';
import { ReactComponent as ReorderListIcon } from 'assets/icons/MovePick.svg';

import DndFeedsList from './DndFeedsList';
import { getSortingTitle, updateFeedsOrder } from '../helpers';

import { StyledStatus } from './styled';

const CustomSortModal: FC<{
  sortingType?: ChannelSectionItemsSortTypeOptions;
  handleClose: () => void;
  handlePrev: () => void;
  setSorting?: () => void;
}> = ({ sortingType, handleClose, handlePrev, setSorting }) => {
  const { cache } = useApolloClient();
  const [isDragged, setIsDragged] = useState(false);
  const [orderItems, setOrderItems] = useState<(ChannelCustomFeedSchema | ChannelAdminFeedSchema)[]>([]);
  const { channelSection } = useGetChannelPageSection();
  const { id: sectionId } = channelSection ?? {};

  const data = useGetChannelSectionItems(sectionId, sortingType);
  const { items: dataItems, loading, loadMore } = data ?? {};

  const reorderItems = useReorderSectionItems();

  const items = dataItems?.reduce(
    (prev: (ChannelCustomFeedSchema | ChannelAdminFeedSchema)[], curr) =>
      curr?.__typename === 'ChannelCustomFeedSchema' || curr?.__typename === 'ChannelAdminFeedSchema'
        ? prev.concat(curr)
        : prev,
    []
  );

  useEffect(() => {
    const handleClearSorting = () => {
      setSorting?.();
      localStorage.setItem(FEEDS_SORTING, '');
    };

    window.addEventListener('beforeunload', handleClearSorting);

    return () => {
      window.removeEventListener('beforeunload', handleClearSorting);
      handleClearSorting();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (items?.length) {
      setOrderItems(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(items)]);

  const onDragEnd = (feeds: (ChannelCustomFeedSchema | ChannelAdminFeedSchema)[]) => {
    if (sectionId) {
      updateFeedsOrder(cache, sectionId, feeds, sortingType);
      reorderItems(
        feeds.map(({ id }) => id),
        sectionId
      );
    }
    setIsDragged(true);
  };

  return (
    <Modal handleClose={handleClose} handlePrev={handlePrev} enableInnerScroll>
      <StyledTitle>Edit feeds order</StyledTitle>
      <StyledStatus>
        <ReorderListIcon />
        <span>
          {isDragged || !sortingType
            ? getSortingTitle(ChannelSectionItemsSortTypeOptions.Custom)
            : getSortingTitle(sortingType)}
        </span>
      </StyledStatus>
      {items && (
        <DndFeedsList
          items={orderItems}
          loading={loading}
          onDragEnd={onDragEnd}
          setItems={setOrderItems}
          loadMore={loadMore}
        />
      )}
    </Modal>
  );
};

export default CustomSortModal;
