import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

import Grain from 'components/UI/Grain';
import Button from 'components/UI/Button';
import { ROUTE_ONBOARDING_FULLNAME } from 'routes';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { useUpdateCachedOnboardingStepV2 } from 'graphQL/onboarding/hooks';
import { ENewOnboardingStepOptions } from 'constants/graphqlTypes';

import ProfileSlider from './ProfileSlider';
import { MOCK_PROFILES } from './constants';

import { StyledWrap, StyledInner, StyledTitle, StyledButtonWrap, StyledTitleWrap } from './styled';

const ProfilePreview: FC = () => {
  const posthogCapture = usePostHogCapture();
  const { push } = useHistory();
  const [index, setIndex] = useState(0);

  const updateCacheV2 = useUpdateCachedOnboardingStepV2(ENewOnboardingStepOptions.FullName);

  const handleNext = () => {
    updateCacheV2();
    push(ROUTE_ONBOARDING_FULLNAME);
  };

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.OnboardingCardPreview);
  }, []);

  return (
    <StyledWrap bgColor={MOCK_PROFILES[index].bgColor}>
      <Grain />
      <StyledInner>
        <StyledTitleWrap>
          <TypeAnimation
            wrapper="p"
            cursor={false}
            sequence={['', 1000, 'Now, let’s create a beautiful profile', 2000]}
            className={StyledTitle}
            speed={80}
          />
        </StyledTitleWrap>
        <ProfileSlider items={MOCK_PROFILES} setIndex={setIndex} />
      </StyledInner>
      <StyledButtonWrap>
        <Button text="Continue" handler={handleNext} />
      </StyledButtonWrap>
    </StyledWrap>
  );
};

export default ProfilePreview;
