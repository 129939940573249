import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledHeading = styled.h1`
  color: ${COLORS.white[100]};
  display: inline-block;
  font: 400 22px/130% ${FONTS.primary};
  margin: 0;
  width: 100%;
`;

export const StyledText = styled.span`
  color: ${COLORS.white[100]};
  display: inline-block;
  font: 400 14px/140% ${FONTS.secondary};
  max-width: 343px;
  padding-top: 16px;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const StyledCodesContainer = styled.div<{ isInputDisabled: boolean }>`
  max-width: 343px;
  padding-top: 29px;
  width: 100%;

  .codesContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  input {
    z-index: 500 !important;
    font-size: 16px;
  }

  input:not([value='']) {
    width: 0px;
    height: 0px;
    border: none;
    padding: 0;
  }

  .character {
    align-items: center;
    background: ${({ isInputDisabled }) => (isInputDisabled ? COLORS.white[5] : COLORS.white[20])};
    border-radius: 4px;
    border: none;
    color: ${COLORS.white[100]};
    display: flex;
    font: 400 22px/130% ${FONTS.primary};
    height: 56px !important;
    justify-content: center;
    max-width: 48px;
    outline: none;
    text-align: center;
    text-transform: capitalize;
  }

  .character--selected {
    position: relative;
  }

  .character--selected:empty::after {
    animation: cursor 1s infinite step-end;
    background-color: ${COLORS.white[100]};
    content: '';
    display: block;
    height: 29px;
    left: 16%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
  }

  .character--selected:not(:empty)::after {
    animation: cursor 1s infinite step-end;
    background-color: ${COLORS.white[100]};
    content: '';
    display: block;
    height: 29px;
    pointer-events: none;
    position: absolute;
    right: 16%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
  }

  @keyframes cursor {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
`;
