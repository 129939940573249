import { styled } from 'linaria/react';

import { COLORS, BREAKPOINTS, FONTS } from '../../styles/constants';

const ANIMATION = 'multicolor 15s ease-in-out infinite forwards';

export const StyledWrapper = styled.div`
  background: ${COLORS.brown.dark[100]};
  color: ${COLORS.brown.dark[100]};
  font-family: ${FONTS.primary};
  overflow: hidden;
`;

export const StyledHeader = styled.header`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin: 0;
  padding: 15px 15px;
  animation: ${ANIMATION};

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    justify-content: center;
    gap: 20px;
    padding: 19px 0;
  }

  @keyframes multicolor {
    0%,
    100% {
      background: ${COLORS.brown[100]};
    }

    16.66% {
      background: ${COLORS.brown.light[100]};
    }

    33.32% {
      background: ${COLORS.green.light};
    }

    49.48% {
      background: ${COLORS.white.off[100]};
    }

    66.64% {
      background: ${COLORS.white[100]};
    }

    83.3% {
      background: ${COLORS.white[20]};
    }
  }
`;

export const StyledHeaderButton = styled.button`
  animation: ${ANIMATION};
  border-radius: 22px;
  border: none;
  cursor: pointer;
  font: 600 14px/140% ${FONTS.secondary};
  padding: 6px 30px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 12px 40px;
  }

  @keyframes multicolor {
    0%,
    100% {
      background: ${COLORS.green.light};
      color: ${COLORS.brown[100]};
    }

    16.66% {
      background: ${COLORS.brown.dark[100]};
      color: ${COLORS.white[100]};
    }

    33.32% {
      background: ${COLORS.white.off[100]};
      color: ${COLORS.brown[100]};
    }

    49.48% {
      background: ${COLORS.gray[100]};
      color: ${COLORS.brown[100]};
    }

    66.64% {
      background: ${COLORS.brown.light[100]};
      color: ${COLORS.white[100]};
    }

    83.3% {
      background: ${COLORS.white[100]};
      color: ${COLORS.brown.dark[100]};
    }
  }
`;

export const StyledHeaderText = styled.p`
  animation: ${ANIMATION};
  font-size: 14px;
  line-height: 130%;
  margin: 0;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    font-size: 22px;
  }
  @keyframes multicolor {
    0%,
    100% {
      color: ${COLORS.white[100]};
    }

    16.66% {
      color: ${COLORS.white[100]};
    }

    33.32% {
      color: ${COLORS.brown[100]};
    }

    49.48% {
      color: ${COLORS.brown.dark[100]};
    }

    66.64% {
      color: ${COLORS.brown[100]};
    }

    83.3% {
      color: ${COLORS.white[100]};
    }
  }
`;
