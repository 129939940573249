import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EQueryFetchPolicy } from 'constants/common';
import { CardTypeOptions, ETutorialStatus } from 'constants/graphqlTypes';
import { TEST_PROFILE_NO_PICKS_TITLE_LABEL } from 'constants/aqa/profile';

import useTheme from 'helpers/useTheme';
import usePageType from 'helpers/usePageType';
import useDiscoveryTab from 'helpers/useDiscoveryTab';
import useGuideTour from 'helpers/useGuideTour';
import { usePostHogCapture } from 'helpers/posthogHooks';

import { useGetTotalCountCards, useGetUserId, useProfile } from 'graphQL/profile/hooks';
import { useUserSettings } from 'graphQL/contextualIntro/hooks';

import AppTour from 'components/Profile/AppTour';
import ProfileTop from 'components/Profile/ProfileTop';
import ProfileContent from 'components/Profile/ProfileContent';
import { StyledNoContent } from 'components/Profile/styled';
import { EExploreTabs } from 'components/Explore/ExploreTabs/helpers/constants';
import { ROUTE_DISCOVERY } from 'routes';
import { POSTHOG_EVENTS } from '../../constants/posthogEvents';

const ProfilePage: FC = () => {
  const { profileName } = useParams<{ profileName: string }>();
  const [hideSpotlights, setHideSpotlights] = useState(true);

  const posthogCapture = usePostHogCapture();
  const { isMyProfile } = usePageType();
  const isDark = useTheme();
  const { userId, loading } = useGetUserId(profileName);
  const { isGuideTour } = useGuideTour();
  const { tutorialStatus, loading: settingsLoading } = useUserSettings();
  const { setDiscoveryTab } = useDiscoveryTab();
  const { totalCount } = useGetTotalCountCards(
    userId ?? '',
    {
      typeIn: [CardTypeOptions.Card, CardTypeOptions.Collection],
      withPrivacy: true,
    },
    EQueryFetchPolicy.CacheAndNetwork
  );

  const { userData, loading: profileLoading } = useProfile(
    userId,
    EQueryFetchPolicy.CacheFirst,
    EQueryFetchPolicy.CacheFirst
  );

  const showNoContentText = userData && !totalCount;

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.OpenProfilePage, {
      isOwnProfilePage: isMyProfile,
    });
  }, [profileName]);

  return (
    <>
      {tutorialStatus === ETutorialStatus.NotStarted && !settingsLoading && !isGuideTour && <AppTour />}
      <ProfileTop
        userData={userId ? userData : undefined}
        loading={profileLoading || loading}
        setHideSpotlights={setHideSpotlights}
        hideSpotlights={hideSpotlights}
      />
      {!profileLoading && !hideSpotlights && !!userId && (
        <>
          <ProfileContent />
          {showNoContentText && (
            <StyledNoContent data-testid={TEST_PROFILE_NO_PICKS_TITLE_LABEL} isDark={isDark}>
              {isMyProfile ? (
                <>
                  You haven’t added any picks yet. Get started by exploring what others are adding on the{' '}
                  <a onClick={() => setDiscoveryTab?.(EExploreTabs.Featured)} href={ROUTE_DISCOVERY}>
                    Featured feed
                  </a>
                </>
              ) : (
                'This user hasn’t added any picks yet.'
              )}
            </StyledNoContent>
          )}
        </>
      )}
    </>
  );
};
export default ProfilePage;
