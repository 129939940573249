import { styled } from 'linaria/react';
import { COLORS, GUEST_LIST_GRADIENT } from 'styles/constants';

export const StyledGuestListBg = styled.div`
  background: ${GUEST_LIST_GRADIENT};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;

  &::after {
    content: ' ';
    border-radius: 50%;
    background: #fff;
    opacity: 0.4;
    height: 224px;
    position: absolute;
    bottom: -50px;
    left: -120px;
    width: 224px;
  }

  &::before {
    content: ' ';
    border-radius: 50%;
    background: #fff;
    opacity: 0.5;
    height: 224px;
    position: absolute;
    top: 20%;
    right: -100px;
    width: 224px;
  }
`;

export const StyledBackground = styled.div<{ background?: string; isGuestList: boolean }>`
  background: ${({ background }) => (background ? `${background}` : `${COLORS.white[100]}`)};
  background-position: center;
  background-size: cover;
  align-items: center;
  display: flex;
  height: 100dvh;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100vw;

  > .grain {
    backdrop-filter: ${({ isGuestList }) => (!isGuestList ? 'blur(30px)' : 'none')};

    &::before {
      background: linear-gradient(180deg, ${COLORS.transparent} 50%, ${COLORS.black[100]} 110%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 1;
    }
  }
`;

export const StyledContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
