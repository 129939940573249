import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';

import useTheme from 'helpers/useTheme';
import LoaderContent from 'components/UI/LoaderContent/LoaderContent';

import { EDiscoverSlotHeadTypeOptions } from 'constants/graphqlTypes';
import { getExploreItemDataTestId, EVERYONE_TAB_VIEW } from 'constants/aqa/explore';
import { useEveryoneItems } from 'graphQL/cards/exploreCards/hooks';
import { StyledContent, StyledSection } from './styled';

import Item from './Item';
import Footer from './Footer';
import Heading from './Heading';

const EveryoneTab: FC = () => {
  const isDark = useTheme();
  const { items, loading, loadMore, total } = useEveryoneItems();

  return (
    <>
      <StyledSection>
        <Heading
          section={{
            headType: EDiscoverSlotHeadTypeOptions.Simple,
            title: 'Everyone on guide:human',
            annotation: 'A chronological feed from everyone on guide:human',
          }}
        />
        <StyledContent>
          {items.map((item) => (
            <Item
              dataTestId={item.layout ? getExploreItemDataTestId(item.layout, EVERYONE_TAB_VIEW) : ''}
              key={item.card.id}
              item={item}
            />
          ))}
        </StyledContent>
      </StyledSection>
      {loading ? <LoaderContent isFullScreen={!items.length} isDark={isDark} /> : <Waypoint onEnter={loadMore} />}
      {!loading && items.length === total && <Footer />}
    </>
  );
};

export default EveryoneTab;
