import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';
import { StyledListButton as ImportButton } from './List/styled';

export const StyledSignOut = styled(ImportButton)`
  font: 600 14px/140% ${FONTS.secondary};
  margin-top: 40px;
  text-align: left;
  width: fit-content;
`;

export const StyledSection = styled.section<{ isShown: boolean; isDark: boolean }>`
  background-color: ${({ isDark }) => (isDark ? COLORS.brown.dark[100] : COLORS.white[100])};
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  max-width: 400px;
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  padding: 24px;
  position: fixed;
  transform: ${({ isShown }) => (isShown ? 'none' : 'translateX(-100%)')};
  transition: 0.15s all ease-in-out;
  width: 100%;
  z-index: 10;
`;

export const StyledButton = styled.button<{ icon: string }>`
  background-color: ${COLORS.transparent};
  background-image: ${({ icon }) => `url(${icon})`};
  background-size: cover;
  border: none;
  content: '';
  cursor: pointer;
  display: block;
  height: 16px;
  padding: 0;
  transition: 0.15s transform ease-in-out;
  width: 16px;
`;

export const StyledCloseMenuButtonWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  right: 19px;
  bottom: 19px;

  &:hover {
    transform: scale(1.2);
  }
`;
