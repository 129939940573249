import React, { FC } from 'react';

import { NotificationSettingDomain } from 'constants/graphqlTypes';
import {
  TEST_ID_NOTIFICATIONS_SELECTION_VIEW_START,
  TEST_NOTIFICATIONS_SELECTION_TYPE_ACTIVITY,
  TEST_NOTIFICATIONS_SELECTION_TYPE_COLLABORATIVE,
  TEST_NOTIFICATIONS_SELECTION_TYPE_DISCOVER,
  TEST_NOTIFICATIONS_SELECTION_TYPE_NETWORK,
} from 'constants/aqa/settings/accountManagement';
import SinglePageLayout from 'components/SinglePageLayout';

import SettingsSection from 'components/SettingsSection/SettingsSection';
import { StyledSectionsWrap } from 'components/SettingsSection/styled';

const PushNotifications: FC = () => {
  return (
    <SinglePageLayout
      pageTestId={TEST_ID_NOTIFICATIONS_SELECTION_VIEW_START}
      title="App notifications"
      subTitle="Push and in-app notifications to keep you in the know"
    >
      <StyledSectionsWrap>
        <SettingsSection
          dataTestId={TEST_NOTIFICATIONS_SELECTION_TYPE_ACTIVITY}
          domain={NotificationSettingDomain.Activity}
          title="Your picks and thoughts"
        />
        <SettingsSection
          dataTestId={TEST_NOTIFICATIONS_SELECTION_TYPE_NETWORK}
          domain={NotificationSettingDomain.Network}
          title="Social interactions"
        />
        <SettingsSection
          dataTestId={TEST_NOTIFICATIONS_SELECTION_TYPE_COLLABORATIVE}
          domain={NotificationSettingDomain.CollaborativeLists}
          title="Collaborating on lists"
        />
        <SettingsSection
          dataTestId={TEST_NOTIFICATIONS_SELECTION_TYPE_DISCOVER}
          domain={NotificationSettingDomain.DiscoverContent}
          title="From guide:human"
        />
      </StyledSectionsWrap>
    </SinglePageLayout>
  );
};

export default PushNotifications;
