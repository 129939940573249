import React, { FC } from 'react';
import { useLastLocation } from 'react-router-last-location';

import { StyledNavigation } from 'components/Navigation/styled';
import { COLORS } from 'styles/constants';
import { ROUTE_MESSAGES_NEW } from 'routes';

import useTheme from 'helpers/useTheme';
import BackButton from 'components/Navigation/LeftMenu/BackButton';

const NavWrap: FC = ({ children }) => {
  const isDark = useTheme();

  const { pathname } = useLastLocation() ?? {};

  return (
    <StyledNavigation
      className="navigation"
      isMessages
      bgColor={isDark ? COLORS.black[100] : COLORS.white[100]}
      isHide={false}
      color={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
    >
      <BackButton steps={pathname === ROUTE_MESSAGES_NEW ? 2 : 0} />
      {children}
    </StyledNavigation>
  );
};

export default NavWrap;
