import {
  CardSchema,
  CardTypeOptions,
  EDiscoverItemLayoutOptions,
  UnionDiscoverFollowingObjectType,
} from 'constants/graphqlTypes';
import { IDetailSliderListItem } from 'graphQL/cards/exploreCards/models';
import { v4 } from 'uuid';

const { Collection, Spark, SuggestedUsers } = CardTypeOptions;
export const getCardLayout = (item: CardSchema, cards: UnionDiscoverFollowingObjectType[]) => {
  const { cardId } = item;
  const index = cards
    .reduce(
      (acc: CardSchema[], curr) =>
        curr.__typename === 'CardSchema' && curr.type !== Collection ? acc.concat(curr) : acc,
      []
    )
    .findIndex((card) => card.cardId === cardId);

  return index !== 0 && (index + 1) % 4 === 0
    ? EDiscoverItemLayoutOptions.FullBleed
    : EDiscoverItemLayoutOptions.FirstUp;
};

export const getKey = (item: UnionDiscoverFollowingObjectType) => {
  const { __typename: typename } = item;
  switch (item.__typename) {
    case 'CardSchema':
      return `${typename}-${item.id}-${item.type}`;
    case 'ListGroupFollowSchema':
      return `${typename}-${item.collectionId}-${item.cardItems?.map(({ id }) => id).join()}`;
    case 'MixedGroupFollowSchema':
      return `${typename}-${item.cardItemIds?.join(',')}-${item.user?.id}`;
    default:
      return v4();
  }
};

export const getFollowingItemIds = (item: UnionDiscoverFollowingObjectType): string[] => {
  switch (item.__typename) {
    case 'CardSchema': {
      if (item.type === Collection || item.type === Spark) {
        return [];
      }
      return [item.id];
    }

    case 'ListGroupFollowSchema':
      return item.cardItems?.reduce((acc: string[], { id }) => (id ? acc.concat(id) : acc), []) ?? [];
    case 'MixedGroupFollowSchema':
      return item.cardItemIds ?? [];
    default:
      return [];
  }
};

export const getFollowingIds = (followingItems: UnionDiscoverFollowingObjectType[]): string[] =>
  followingItems.map((item) => getFollowingItemIds(item)).flat();

export const getFollowingSliderIds = (ids: UnionDiscoverFollowingObjectType[]): IDetailSliderListItem[] =>
  getFollowingIds(ids).map((id) => ({
    id,
  }));

export const checkForSuggestedUsers = (item?: UnionDiscoverFollowingObjectType) =>
  item?.__typename === 'CardSchema' && item?.type === SuggestedUsers;
