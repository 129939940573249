import React, { FC, useMemo } from 'react';
import { v4 } from 'uuid';

import { generateName } from 'components/Profile/ProfileTop/helpers';
import { CardSchema } from 'constants/graphqlTypes';
import { getListStyles } from 'components/Collection/helpers';
import { COLORS } from 'styles/constants';

import {
  StyledBody,
  StyledImageItem,
  StyledImagesList,
  StyledTextItem,
  StyledTextsList,
} from 'components/Collection/styled';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';
import { StyledCreatorText, StyledEmptyListBody, StyledText, StyledWrap } from './styled';

const ListContent: FC<{ list: CardSchema }> = ({ list }) => {
  const { title, user } = list;
  const { images, titles } = getListStyles(list);
  const { userInfo, isVerified } = user ?? {};
  const { firstName, lastName } = userInfo ?? {};

  const fullName = generateName(undefined, firstName, lastName);
  const titlesWithKey = useMemo(
    () =>
      titles.map((cover) => ({
        cover,
        key: v4(),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [titles.length, titles.join(','), v4]
  );

  return (
    <StyledWrap>
      <StyledText>{title}</StyledText>
      <StyledCreatorText>
        <span className="creator-text">List by {fullName}</span>
        {isVerified && <VerificationBadge />}
      </StyledCreatorText>
      {(!!images.length || !!titlesWithKey.length) && (
        <StyledBody className="list-body" bgColor="none">
          {!!images.length && (
            <StyledImagesList isMultiple={images.length >= 4}>
              {images.length >= 4 &&
                images
                  .slice(images.length - 4, images.length)
                  .map((image) => <StyledImageItem key={image.id} src={image?.images?.[0]?.url} />)}
              {images.length < 4 && <StyledImageItem key={images[0]?.id} src={images[0]?.images?.[0]?.url} />}
            </StyledImagesList>
          )}
          {!!titlesWithKey.length && (
            <StyledTextsList>
              {titlesWithKey.map(({ cover, key }, i) => (
                <StyledTextItem
                  className="cover-text-item"
                  key={key}
                  opacity={1 - (1 / titlesWithKey.length) * i}
                  color={COLORS.white[100]}
                >
                  {cover}
                </StyledTextItem>
              ))}
            </StyledTextsList>
          )}
        </StyledBody>
      )}
      {!images.length && !titlesWithKey.length && <StyledEmptyListBody />}
    </StyledWrap>
  );
};

export default ListContent;
