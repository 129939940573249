import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

const StyledLink = styled.a<{ fontSize: number; isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font-family: ${FONTS.secondary};
  font-size: ${({ fontSize }) => fontSize}px;
`;

export default StyledLink;
