import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { useApolloClient } from '@apollo/client';
import Modal from 'components/Modal';
import { StyledTitle } from 'components/AddContent/AddStyle/styled';
import MenuButton from 'components/UI/MenuButton';
import { EMenuButtonType } from 'components/UI/MenuButton/constants';
import Button from 'components/UI/Button';
import { EUiButtonType } from 'components/UI/Button/constants';
import { useAdjustFeedFilters, useGetCardsFromFeedFilters, useGetMediaTagsFilters } from 'graphQL/feeds/hooks';
import { GetFeedCardsDocument, MediaTagSchema } from 'constants/graphqlTypes';
import { addNewAdminFeedFilters, useAdminFeedFiltersContext } from './hooks';
import { StyledButtonWrap, StyledTagsWrap } from './styled';
import { useFilterFeeds } from '../CreateFeed/hooks';
import { getAdminCustomFeedTags } from '../helpers';

const FilterFeedModal: FC = () => {
  const { goBack } = useHistory();

  const [totalPicks, setTotalPicks] = useState(0);
  const [feedTags, setFeedTags] = useState<MediaTagSchema[]>([]);
  const [includedFilters, setIncludedFilters] = useState<string[]>([]);
  const [excludedFilters, setExcludedFilters] = useState<string[]>([]);
  const [clearAll, setClearAll] = useState(false);

  const { state } = useLocation<{ variable: { isAdminFeed: boolean; feedId: string } }>();
  const { isAdminFeed, feedId } = state?.variable ?? {};
  const tab = feedId ?? '';

  const { setNewAdminFeedFilters } = useAdminFeedFiltersContext();
  const apolloClient = useApolloClient();

  const onCompleted = () => {
    setTimeout(() => {
      apolloClient.refetchQueries({
        include: [GetFeedCardsDocument],
      });
    }, 500);
    goBack();
  };

  const { tags, loadMoreTags, loading: mediaTagsLoading } = useGetMediaTagsFilters(tab, clearAll);
  const { totalCount, loading } = useGetCardsFromFeedFilters(
    tab,
    isAdminFeed ? getAdminCustomFeedTags(feedTags) : includedFilters,
    isAdminFeed ? getAdminCustomFeedTags(feedTags, true) : excludedFilters
  );
  const { adjustFeedFilters } = useAdjustFeedFilters(tab, includedFilters, excludedFilters, clearAll, onCompleted);

  const handleSubmitForAdminFeed = () => {
    addNewAdminFeedFilters({
      feedId: tab,
      newExcluded: getAdminCustomFeedTags(feedTags, true),
      newIncluded: getAdminCustomFeedTags(feedTags),
      setNewAdminFeedFilters,
    });
    onCompleted();
  };

  const { handleSelectItem, handleClearAll, totalPicksCount } = useFilterFeeds(
    tags,
    feedTags,
    setFeedTags,
    includedFilters,
    setIncludedFilters,
    excludedFilters,
    setExcludedFilters,
    totalPicks,
    setTotalPicks,
    totalCount,
    loading,
    tab
  );

  return (
    <Modal handleClose={goBack} enableInnerScroll>
      <StyledTitle>Filter by type</StyledTitle>
      <StyledTagsWrap>
        {feedTags.map(({ id, name, isSelectedInFeedFilters }) => (
          <MenuButton
            text={name || ''}
            type={EMenuButtonType.Radio}
            checked={!!isSelectedInFeedFilters}
            handler={() => {
              handleSelectItem(id);
            }}
          />
        ))}
        {!mediaTagsLoading && <Waypoint onEnter={loadMoreTags} />}
      </StyledTagsWrap>
      <StyledButtonWrap>
        <Button
          handler={() => (isAdminFeed ? handleSubmitForAdminFeed() : adjustFeedFilters())}
          text={`Show ${totalPicksCount} pick${totalPicksCount === 1 ? '' : 's'}`}
        />
        <Button
          handler={() => {
            setClearAll(true);
            handleClearAll();
          }}
          text="Clear all"
          type={EUiButtonType.Transparent}
        />
      </StyledButtonWrap>
    </Modal>
  );
};

export default FilterFeedModal;
