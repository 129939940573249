import React, { FC, useEffect, useState } from 'react';
import LoaderContent from 'components/UI/LoaderContent';
import { Waypoint } from 'react-waypoint';

import SortFeedModal from 'components/CustomFeeds/SortFeedModal';

import useTheme from 'helpers/useTheme';
import useDocumentTitle from 'helpers/useDocumentTitle';
import { useGetChannelPageSection, useGetChannelSectionItems } from 'graphQL/channel/hooks';
import {
  ChannelAdminFeedSchema,
  ChannelCustomFeedSchema,
  ChannelSectionItemsSortTypeOptions,
  ChannelSectionTypeOptions,
} from 'constants/graphqlTypes';
import { FEEDS_SORTING } from 'constants/common';
import { ReactComponent as FilterIcon } from 'assets/icons/Filter.svg';
import FeedCard from 'components/CustomFeeds/FeedCard';
import CustomFeedMenu from 'components/CustomFeeds/CustomFeedMenu/Menu';
import { StyledSectionType } from 'components/Explore/ChannelsTab/styled';

import {
  StyledPageInfoContainer,
  StyledWrap,
  StyledHeader,
  StyledContent,
  StyledFilterBtn,
  StyledHeaderWrap,
} from './styled';

type ChannelFeed = ChannelCustomFeedSchema | ChannelAdminFeedSchema;

const FeedsSection: FC = () => {
  const isDark = useTheme();
  const [selectedFeed, setSelectedFeed] = useState<ChannelFeed | null>(null);
  const [isSortFeedOpen, setIsSortFeedOpen] = useState<boolean>(false);
  const [sorting, setSorting] = useState<ChannelSectionItemsSortTypeOptions | undefined>();

  const { channelSection, loading: sectionsLoading } = useGetChannelPageSection();
  const { id, sectionType } = channelSection ?? {};
  const isCustomFeeds = sectionType === ChannelSectionTypeOptions.CustomFeeds;
  const isFeedsByTopic = sectionType === ChannelSectionTypeOptions.AdminFollowByTopic;

  const pageTitle = isCustomFeeds ? 'Your custom feeds' : 'From people you follow';

  const data = useGetChannelSectionItems(id, sorting);
  const { items: dataItems, loading, loadMore } = data ?? {};

  const items = dataItems?.reduce(
    (prev: ChannelFeed[], curr) =>
      curr?.__typename === 'ChannelCustomFeedSchema' || curr?.__typename === 'ChannelAdminFeedSchema'
        ? prev.concat(curr)
        : prev,
    []
  );

  useDocumentTitle(pageTitle);

  useEffect(() => {
    const sortingParam = localStorage.getItem(FEEDS_SORTING);
    const initialSorting = Object.values(ChannelSectionItemsSortTypeOptions).includes(
      sortingParam as ChannelSectionItemsSortTypeOptions
    )
      ? (sortingParam as ChannelSectionItemsSortTypeOptions)
      : ChannelSectionItemsSortTypeOptions.RecentlyUpdated;

    setSorting(initialSorting);

    return () => {
      localStorage.setItem(FEEDS_SORTING, ChannelSectionItemsSortTypeOptions.RecentlyUpdated);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(FEEDS_SORTING, sorting ?? ChannelSectionItemsSortTypeOptions.RecentlyUpdated);
  }, [sorting]);

  return (
    <>
      <StyledWrap maxWidth={528}>
        <StyledPageInfoContainer>
          {!isCustomFeeds && (
            <StyledSectionType>{isFeedsByTopic ? 'Feeds By Topic' : 'Feeds By Type'}</StyledSectionType>
          )}
          <StyledHeaderWrap>
            <StyledHeader isDark={isDark}>{pageTitle}</StyledHeader>
            <StyledFilterBtn isDark={isDark} onClick={() => setIsSortFeedOpen(true)}>
              <FilterIcon />
            </StyledFilterBtn>
          </StyledHeaderWrap>
        </StyledPageInfoContainer>
        <StyledContent>
          {items?.map((feed) => (
            <FeedCard key={feed.id} feed={feed} handler={isCustomFeeds ? () => setSelectedFeed(feed) : undefined} />
          ))}
          {loading || sectionsLoading ? (
            <LoaderContent isDark={isDark} isFullScreen={!items?.length} />
          ) : (
            <Waypoint onEnter={loadMore} />
          )}
        </StyledContent>
      </StyledWrap>
      {selectedFeed && id && (
        <CustomFeedMenu
          feedId={selectedFeed.id}
          title={selectedFeed?.item?.title ?? ''}
          handleClose={() => setSelectedFeed(null)}
          sectionId={id}
          sorting={sorting}
        />
      )}
      {isSortFeedOpen && (
        <SortFeedModal
          selectedSorting={sorting}
          setSorting={setSorting}
          handleClose={() => setIsSortFeedOpen(false)}
          items={items}
          hideDate={!isCustomFeeds}
        />
      )}
    </>
  );
};

export default FeedsSection;
