import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from '../../../../styles/constants';

export const StyledCardContent = styled.div<{ bg?: string }>`
  align-items: center;
  border-radius: 10px;
  height: 100%;
  max-height: 438px;
  min-height: 438px;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-image: ${({ bg }) => (bg ? `url(${bg})` : 'none')};
  background-size: cover;
`;

export const StyledInfo = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledAvatarWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

export const StyledUserText = styled.div`
  border-bottom: 1px solid ${COLORS.white[30]};
  color: ${COLORS.white[100]};
  display: flex;
  font: 600 14px/140% ${FONTS.secondary};
  justify-content: center;
  padding: 4px 12px 20px;
  text-align: center;
  width: 100%;
  word-break: break-all;
`;

export const StyledUsername = styled.span<{ isDark?: boolean; minWidth?: number }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : 'inherit')};
  flex-shrink: 1;
  line-height: 140%;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'auto')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledText = styled.span`
  flex-shrink: 0;
`;

export const StyledSparkTitleWrap = styled.div`
  color: ${COLORS.white[100]};
  font: 400 32px/120% ${FONTS.primary};
  margin: 0 auto;
  padding: 40px 0 80px;
  text-align: center;
  width: 85%;
  word-break: break-word;
`;

export const StyledSparkTitle = styled.span`
  display: -webkit-box;
  overflow: hidden;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
`;

export const StyledFooter = styled.div<{ isDark: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 0;
  width: 100%;

  > span {
    font: 400 14px/140% ${FONTS.secondary};
    color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;

//
//
// ------------------- UI page -------------------
export const StyledSparkWrapUI = styled.div`
  width: 342px;
`;
// ------------------- UI page -------------------
