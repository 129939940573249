import { styled } from 'linaria/react';

import { StyledBody } from 'components/Collection/styled';
import { EXPLORE_WRAP_PADDING_BOTTOM } from 'constants/common';
import { COLORS, BREAKPOINTS, FONTS } from '../../../styles/constants';

export const StyledWrap = styled.div`
  padding-bottom: ${EXPLORE_WRAP_PADDING_BOTTOM}px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${BREAKPOINTS.s}px;
  padding: 8px 0 52px;
  width: calc(100% - 24px);

  .xblock {
    padding: 0 6px;
  }
`;

export const StyledAllPicks = styled(StyledBody)<{ isDark: boolean; hideBorder?: boolean }>`
  border: ${({ hideBorder }) => (hideBorder ? 0 : '1px')} solid
    ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[10])};
`;

export const StyledNoListsText = styled.div<{ isDark: boolean }>`
  border-top: 1px solid ${COLORS.white.off[100]};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 14px/140% ${FONTS.secondary};
  margin: 8px 12px 0;
  padding-top: 24px;
`;

export const StyledRecentPickContainer = styled.div`
  padding: 24px 0 16px;
`;

export const StyledTitle = styled.p`
  font: 400 22px/130% ${FONTS.primary};
  margin: 0 0 16px;
`;
