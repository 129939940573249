import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import { COLORS, FONTS } from '../../../styles/constants';

export const StyledReactionWrap = styled.div<{ disableLinks?: boolean }>`
  position: relative;

  a,
  .card,
  .card-link {
    pointer-events: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
    user-select: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
  }
`;

export const StyledTitle = styled.p<{ isDark: boolean }>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: -webkit-box;
  font: 400 14px/140% ${FONTS.primary};
  margin: 8px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledDescription = styled.p<{ isDark: boolean }>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: -webkit-box;
  font: 350 12px/100% ${FONTS.secondary};
  margin: 6px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;

export const StyledWrap = styled.div<{
  bgColor: string;
  fullWidth?: boolean;
  isDark: boolean;
  counter?: number | null;
}>`
  background: ${({ bgColor }) => bgColor};
  border-radius: 8px;
  height: ${({ fullWidth }) => (fullWidth ? '255px' : '267px')};
  justify-content: space-between;
  margin-top: 5px; // needed for counter
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '156px')};

  &::before {
    border-radius: 8px;
    border: 1px solid rgba(24, 24, 0, 0.2);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::after {
    content: ${({ counter }) => (counter ? `'${counter}'` : '')};
    align-items: center;
    background: ${COLORS.green.light};
    border: ${({ isDark }) => `2px solid ${isDark ? COLORS.black[100] : COLORS.white[100]}`};
    border-radius: 50%;
    color: ${COLORS.brown.dark[100]};
    display: ${({ counter }) => (counter ? 'flex' : 'none')};
    font: 400 12px/140% ${FONTS.secondary};
    justify-content: center;
    height: 22px;
    position: absolute;
    top: -5px;
    right: -5px;
    width: 22px;
    z-index: 1;
  }
`;

export const StyledInner = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;

export const StyledBottom = styled.div`
  margin-top: auto;
  padding: 8px 16px 16px;
  position: relative;

  li:first-child {
    margin-left: 0;
  }
`;

export const StyledImagesList = styled.ul<{ isMultiple: boolean; fullWidth?: boolean }>`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr ${({ isMultiple }) => (isMultiple ? '1fr' : '')};
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: ${({ fullWidth }) => (fullWidth ? '16px 16px 8px' : '16px')};
  width: 100%;
  min-height: ${({ fullWidth }) => (fullWidth ? '136px' : '156px')};
`;

export const StyledImageItem = styled.li<{ src?: string | null }>`
  background-image: ${({ src }) => `url(${src ?? ''})`};
  background-position: center;
  border-radius: 8px;
  background-size: cover;
  overflow: hidden;
  position: relative;
`;

export const StyledTextWrap = styled.div<{ bgColor: string }>`
  border-radius: 8px;
  border: 1px solid rgba(24, 24, 0, 0.1);
  margin: 16px;
  overflow: hidden;
`;

export const StyledTextsList = styled.ul<{ bgColor: string; isLarge?: boolean }>`
  background: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: column;
  font-size: 9px;
  height: 124px;
  list-style: none;
  margin: 0;
  padding: 6.5%;
  width: 100%;
`;

export const StyledTextItem = styled.li<{ opacity: number; color: string }>`
  color: ${({ color }) => color};
  line-height: 130%;
  opacity: ${({ opacity }) => opacity};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
