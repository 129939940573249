import { IGetTopicsResult } from 'graphQL/profile/topics/models';

export enum ESelectionMode {
  All = 'All',
  Select = 'Select',
  Deselect = 'Deselect',
  Followings = 'Followings',
}

export type DisplaySections = (IGetTopicsResult & { title?: string })[];

export const SECTION_ITEMS_LIMIT = 6;
