import { styled } from 'linaria/react';
import { StyledTextItem } from 'components/List/SmallCard/styled';

export const StyledMessageList = styled.ul<{ isRight?: boolean }>`
  align-items: ${({ isRight }) => (isRight ? 'flex-end' : 'flex-start')};
  display: flex;
  flex-direction: column-reverse;
  gap: 4px;
  list-style: none;
  margin: 0;
  max-width: calc(100% - 36px);

  li:not(.cover-text-item):first-child {
    padding-bottom: 4px;
  }

  li:not(:first-child)[data-has-reaction='true'] {
    padding-bottom: 20px;
  }

  ${StyledTextItem} {
    &:first-child {
      padding-bottom: 0;
    }
  }
`;

export const StyledMessageAvatar = styled.div<{ isLinkPreview: boolean }>`
  padding-bottom: ${({ isLinkPreview }) => (isLinkPreview ? 36 : 4)}px;
  transition: 0.3s transform ease-in-out;
`;

export const StyledWrap = styled.div<{ isRight?: boolean }>`
  align-items: flex-end;
  display: flex;
  flex-direction: ${({ isRight }) => (isRight ? 'row-reverse' : 'row')};
  gap: 8px;
  margin-left: ${({ isRight }) => (isRight ? 'auto' : 0)};
  width: 100%;
  word-break: break-word;
`;
