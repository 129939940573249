import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
`;

export const StyledInput = styled.div<{ isDark: boolean }>`
  background: ${({ isDark }) => (isDark ? COLORS.white[10] : COLORS.white.off[100])};
  border-radius: 22px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px 10px 18px;
`;

export const StyledLabel = styled.label<{ isLast?: boolean; isPointer?: boolean; isDark: boolean }>`
  align-items: center;
  cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'auto')};
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: ${({ isLast }) => (isLast ? 'auto' : 0)};
  width: 24px;

  path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;

export const StyledTextContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledInputRow = styled.input<{ isDark: boolean }>`
  background: transparent;
  border: none;
  caret-color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  color: transparent;
  font-family: ${FONTS.secondary};
  font-size: 16px;
  height: 30px;
  outline: none;
  padding: 0 2px 0 4px;
  position: relative;
  width: -webkit-fill-available;
  width: 100%;

  &::placeholder {
    color: ${COLORS.brown.light[100]};
  }
`;

export const StyledInputResult = styled.span<{ isDark: boolean; maxWidth?: number }>`
  align-items: center;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  font-family: ${FONTS.secondary};
  font-size: 16px;
  height: 30px;
  max-width: ${({ maxWidth }) => `${maxWidth}px` ?? '100%'};
  overflow: hidden;
  padding: 0 2px 0 4px;
  pointer-events: none;
  position: absolute;
  scroll-behavior: auto;
  white-space: nowrap;
  width: 100%;
  width: -webkit-fill-available;

  span {
    -webkit-text-stroke: 0.4px;
  }
`;
