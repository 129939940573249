import React, { FC, useEffect, useState } from 'react';
import useTheme from 'helpers/useTheme';

import MenuButton from 'components/UI/MenuButton';
import Button from 'components/UI/Button';
import SinglePageLayout from 'components/SinglePageLayout';

import { format } from 'date-fns';
import { RequestUserDataMutationVariables } from 'constants/graphqlTypes';
import { useRequestedAt, useUpdateRequestUserData } from 'graphQL/requestUserData/hooks';
import { EMenuButtonType } from 'components/UI/MenuButton/constants';
import {
  TEST_ID_REQUEST_DATA_VIEW_START,
  TEST_REQUEST_DATA_OPTION_MESSAGES,
  TEST_REQUEST_DATA_OPTION_PERSONAL_DETAILS,
  TEST_REQUEST_DATA_OPTION_PICKS_THOUGHTS_LISTS,
  TEST_REQUEST_DATA_OPTION_REACTIONS,
  TEST_REQUEST_DATA_OPTION_REPORTS,
  TEST_REQUEST_DATA_SUBMIT_BTN,
} from 'constants/aqa/settings/accountManagement';
import { StyledList } from 'pages/Settings/styled';
import { StyledItem } from '../DataPreferences/styled';
import { StyledCaption, StyledSubmit } from './styled';

const RequestYourData: FC = () => {
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [userData, setUserData] = useState<RequestUserDataMutationVariables>({
    isPersonalDetailsRequested: true,
    isPicksRequested: true,
    isReactionsRequested: true,
    isMessagesRequested: true,
    isReportsRequested: true,
  });

  const {
    isPersonalDetailsRequested,
    isPicksRequested,
    isReactionsRequested,
    isMessagesRequested,
    isReportsRequested,
  } = userData ?? {};

  const { requestedAt } = useRequestedAt();
  const localDate = new Date(`${requestedAt}Z`);
  const { requestUserDataMutation } = useUpdateRequestUserData(userData);
  const noCheckboxSelected = !Object.values(userData).some((value) => value);

  const isDark = useTheme();

  useEffect(() => {
    setIsRequestSent(false);
  }, [userData]);

  const handleSubmit = () => {
    requestUserDataMutation();
    setIsRequestSent(true);
  };

  return (
    <SinglePageLayout
      pageTestId={TEST_ID_REQUEST_DATA_VIEW_START}
      title="Request your data"
      subTitle="We’ll email you a download link to a copy of the data we have stored. Choose what you would like a copy of below."
    >
      <StyledList isDark={isDark}>
        <StyledItem>
          <MenuButton
            text="Personal details"
            checked={!!isPersonalDetailsRequested}
            isDark={isDark}
            handler={() => setUserData({ ...userData, isPersonalDetailsRequested: !isPersonalDetailsRequested })}
            type={EMenuButtonType.Checkbox}
            description="The basic details you provided about yourself, like name, email and bio."
            dataTestId={TEST_REQUEST_DATA_OPTION_PERSONAL_DETAILS}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Picks, thoughts and lists"
            checked={!!isPicksRequested}
            isDark={isDark}
            handler={() => setUserData({ ...userData, isPicksRequested: !isPicksRequested })}
            type={EMenuButtonType.Checkbox}
            description="All the content you have added to guide:human."
            dataTestId={TEST_REQUEST_DATA_OPTION_PICKS_THOUGHTS_LISTS}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Reactions"
            checked={!!isReactionsRequested}
            isDark={isDark}
            handler={() => setUserData({ ...userData, isReactionsRequested: !isReactionsRequested })}
            type={EMenuButtonType.Checkbox}
            description="All the reactions you have send and received on guide:human."
            dataTestId={TEST_REQUEST_DATA_OPTION_REACTIONS}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Messages"
            checked={!!isMessagesRequested}
            isDark={isDark}
            handler={() => setUserData({ ...userData, isMessagesRequested: !isMessagesRequested })}
            type={EMenuButtonType.Checkbox}
            description="The entire message history between you and other users."
            dataTestId={TEST_REQUEST_DATA_OPTION_MESSAGES}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Reports"
            checked={!!isReportsRequested}
            isDark={isDark}
            handler={() => setUserData({ ...userData, isReportsRequested: !isReportsRequested })}
            type={EMenuButtonType.Checkbox}
            description="Information you've shared with us when reporting bugs and other problems."
            dataTestId={TEST_REQUEST_DATA_OPTION_REPORTS}
          />
        </StyledItem>
      </StyledList>
      <StyledSubmit>
        <Button
          isDisabled={isRequestSent || noCheckboxSelected}
          text={isRequestSent ? 'Request sent' : 'Submit request'}
          isDark={isDark}
          handler={handleSubmit}
          dataTestId={TEST_REQUEST_DATA_SUBMIT_BTN}
        />
      </StyledSubmit>
      <StyledCaption>
        {isRequestSent
          ? 'Keep an eye on your inbox. We’ll send you a link to download your data with the next 24 hours.'
          : !!requestedAt && `Last requested ${format(localDate, 'eee, dd MMM yyyy h:mma')}`}
      </StyledCaption>
    </SinglePageLayout>
  );
};

export default RequestYourData;
