import React, { ChangeEvent, FC, useState } from 'react';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';

import Modal from 'components/Modal';
import useToast from 'helpers/useToast';
import getAuthUser from 'helpers/getAuthUser';
import Avatar from 'components/UI/Avatar';
import CropImage from 'components/ProfileSettings/EditProfile/ChangePhoto/CropImage';
import { generateFullName, getUserInfo } from 'components/Profile/ProfileTop/helpers';
import { ReactComponent as SmileyIcon } from 'assets/icons/Smiley.svg';
import { ReactComponent as DashedLine } from 'assets/icons/onboardingv2/DashedLineRx97.svg';
import { ROUTE_ONBOARDING_PROFILE_USERNAME } from 'routes';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { useUploadImage } from 'components/ProfileSettings/EditProfile/ChangePhoto/hooks';
import { handleFileUploading } from 'components/ProfileSettings/EditProfile/ChangePhoto/helpers';
import { UserObjectType } from 'constants/graphqlTypes';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';

import {
  StyledChangeUsername,
  StyledContainer,
  StyledFileInput,
  StyledFullname,
  StyledImageLabel,
  StyledImgContainer,
  StyledUsername,
} from './styled';

interface IProfileSetup {
  userData: UserObjectType;
  isDark: boolean;
}

const INPUT_ID = 'onboarding-image-upload';

const ProfileSetupCard: FC<IProfileSetup> = ({ isDark, userData }) => {
  const posthogCapture = usePostHogCapture();
  const { username: storageUsername } = getAuthUser();
  const fullname = generateFullName(userData);
  const { avatar } = getUserInfo(userData);
  const { url, loading, setUrl, handleDone, setCropper } = useUploadImage();
  const { setToast } = useToast();
  const [hasUploadedImg, setHasUploadedImg] = useState(false);
  const [isCropperOpened, setIsCropperOpened] = useState(false);
  const [inputKey, setInputKey] = useState('');

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    handleFileUploading(setUrl, setToast, e);
    setIsCropperOpened(true);
  };

  const handleFinish = () => {
    setIsCropperOpened(false);
    handleDone();
    setInputKey(v4());
    setHasUploadedImg(true);
  };

  const imageColor = userData?.userStyle?.thumbnailImageSet?.colors?.sourceGradient?.items?.[0]?.colorValue;
  const isDefaultImage = userData.userStyle?.thumbnailImageSetId === null;

  const handleAvatarClose = () => {
    setIsCropperOpened(false);
    posthogCapture(POSTHOG_EVENTS.OnboardingProfileParameters, {
      screen: 'avatar',
    });
  };

  return (
    <>
      <StyledContainer isDark={isDark} isDefaultImg={isDefaultImage}>
        {userData && (
          <StyledImgContainer htmlFor={INPUT_ID}>
            {!(hasUploadedImg && imageColor) && <DashedLine />}
            <Avatar key={avatar} dimension={180} user={userData} />
            <StyledImageLabel>
              {isDefaultImage ? (
                <>
                  <SmileyIcon /> <span>Add</span>
                </>
              ) : (
                <>
                  <CheckIcon /> <span>Change</span>{' '}
                </>
              )}
            </StyledImageLabel>
          </StyledImgContainer>
        )}
        <StyledFileInput key={inputKey} accept=".png, .jpg, .jpeg" type="file" id={INPUT_ID} onInput={onFileUpload} />
        <StyledFullname isDark={isDark}>{fullname}</StyledFullname>
        <StyledUsername isDark={isDark}>:{storageUsername}</StyledUsername>
        <StyledChangeUsername isDark={isDark}>
          <Link to={ROUTE_ONBOARDING_PROFILE_USERNAME}>Change username</Link>
        </StyledChangeUsername>
      </StyledContainer>
      {isCropperOpened && (
        <Modal
          buttonText="Save"
          loading={loading}
          handleDone={url ? handleFinish : undefined}
          handleClose={handleAvatarClose}
        >
          <CropImage url={url} setCropper={setCropper} />
        </Modal>
      )}
    </>
  );
};

export default ProfileSetupCard;
