import { GetSavedPicksQuery } from 'constants/graphqlTypes';

export const updateSavedPicks = (prev: GetSavedPicksQuery, next?: GetSavedPicksQuery): GetSavedPicksQuery =>
  prev.getSavedPicks?.items && next?.getSavedPicks?.items
    ? {
        getSavedPicks: {
          __typename: 'CardSaveListObjectType',
          totalCount: prev.getSavedPicks.totalCount,
          totalCountNotViewed: prev.getSavedPicks.totalCountNotViewed,
          items: [...prev.getSavedPicks.items, ...next?.getSavedPicks.items],
        },
      }
    : prev;
