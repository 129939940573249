import React, { FC } from 'react';
import { ApolloError } from '@apollo/client';

import useTheme from 'helpers/useTheme';
import useModalNavigation from 'helpers/useModalNavigation';
import Avatar from 'components/UI/Avatar';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';

import { SingleChatSchema } from 'constants/graphqlTypes';
import { ROUTE_MESSAGES, ROUTE_MESSAGES_SETTINGS } from 'routes';
import { useHistory } from 'react-router-dom';
import { StyledUsername as StyledFullname } from 'components/Sparks/Views/Card/styled';
import { StyledLink, StyledUsername, StyledTyping } from './styled';
import { StyledTitleWrap } from '../List/styled';
import { useSingleChatTitle } from '../helpers/hooks';

const SingleChatMember: FC<{ chat: SingleChatSchema; isTyping: boolean; error?: ApolloError }> = ({
  chat,
  isTyping,
  error,
}) => {
  const { title, user } = useSingleChatTitle(chat);
  const { username: profileName, isVerified } = user ?? {};
  const { firstName, lastName } = user || {};
  const typingName = firstName || lastName || profileName;
  const link = useModalNavigation(ROUTE_MESSAGES_SETTINGS, false, { chatId: chat.id });
  const { push } = useHistory();
  const redirectToMessageList = () => push(ROUTE_MESSAGES);

  const isDark = useTheme();

  return user ? (
    <>
      <StyledLink onClick={() => push(link)}>
        <Avatar user={user} dimension={28} />
        <StyledTitleWrap hideGap={!!isVerified}>
          <StyledUsername isDark={isDark}>
            <StyledFullname>{isTyping ? typingName : title}</StyledFullname>
          </StyledUsername>
          {isVerified && <VerificationBadge />}
          {isTyping && <StyledTyping> is typing...</StyledTyping>}
        </StyledTitleWrap>
      </StyledLink>
      {chat && error && (
        <ConfirmationModal
          titleText="Inactive thread"
          subTitle={`${title} has left this thread and it will be deleted.`}
          buttonText="Okay"
          handleAccept={redirectToMessageList}
          handleClickOutside={redirectToMessageList}
          isWarning={false}
        />
      )}
    </>
  ) : null;
};

export default SingleChatMember;
