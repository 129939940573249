import { UserObjectType } from 'constants/graphqlTypes';
import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

export const ReactionsContext = createContext({});

export const { Provider: ReactionsProvider } = ReactionsContext;

interface IUseReactionsContext {
  isQuickReactionOpen?: boolean;
  thoughtId?: string | null;
  pickerId?: string | null;
  cardId?: string;
  user?: UserObjectType | null;
  setCardId?: Dispatch<SetStateAction<string | null>>;
  setThoughtId?: Dispatch<SetStateAction<string | null>>;
  setPickerId?: Dispatch<SetStateAction<string | null>>;
  setUser?: Dispatch<SetStateAction<UserObjectType | null>>;
  messageId?: string | null;
  commentatorId?: string | null;
}

export const useReactionContext = (): IUseReactionsContext => {
  const context = useContext<IUseReactionsContext>(ReactionsContext);

  return context;
};

export const useReactionContextState = () => {
  const [thoughtId, setThoughtId] = useState<string | null>(null);

  const [pickerId, setPickerId] = useState<string | null>(null);

  const [cardId, setCardId] = useState<string | null>(null);

  const [user, setUser] = useState<UserObjectType | null>(null);

  return {
    cardId,
    thoughtId,
    pickerId,
    setCardId,
    setThoughtId,
    setPickerId,
    user,
    setUser,
  };
};
