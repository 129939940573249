import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { ReactComponent as NotificationsIcon } from 'assets/icons/Notification.svg';
import { ReactComponent as PrivacyIcon } from 'assets/icons/Privacy.svg';

import {
  TEST_ID_SETTINGS_START,
  TEST_SETTINGS_ACCOUNT_MANAGEMENT,
  TEST_SETTINGS_NOTIFICATIONS,
  TEST_SETTINGS_PRIVACY_AND_DATA,
} from 'constants/aqa/settings/settings';
import { ROUTE_ACCOUNT_MANAGEMENT, ROUTE_NOTIFICATION_SETTING, ROUTE_PRIVACY_AND_DATA } from 'routes';
import useTheme from 'helpers/useTheme';
import useDocumentTitle from 'helpers/useDocumentTitle';
import SinglePageLayout from 'components/SinglePageLayout';
import MenuButton from 'components/UI/MenuButton';
import { StyledItem } from 'components/AddContent/Menu/styled';

import { StyledList } from './styled';

const Settings: FC = () => {
  const isDark = useTheme();
  const { push } = useHistory();

  useDocumentTitle('Settings');

  return (
    <SinglePageLayout pageTestId={TEST_ID_SETTINGS_START} title="Settings">
      <StyledList isDark={isDark}>
        <StyledItem>
          <MenuButton
            dataTestId={TEST_SETTINGS_ACCOUNT_MANAGEMENT}
            text="Account management"
            Icon={UserIcon}
            handler={() => push(ROUTE_ACCOUNT_MANAGEMENT)}
            isDark={isDark}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            dataTestId={TEST_SETTINGS_NOTIFICATIONS}
            text="Notifications"
            Icon={NotificationsIcon}
            handler={() => push(ROUTE_NOTIFICATION_SETTING)}
            isDark={isDark}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            dataTestId={TEST_SETTINGS_PRIVACY_AND_DATA}
            text="Privacy and data"
            Icon={PrivacyIcon}
            handler={() => push(ROUTE_PRIVACY_AND_DATA)}
            isDark={isDark}
          />
        </StyledItem>
      </StyledList>
    </SinglePageLayout>
  );
};

export default Settings;
