import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div<{ isDark: boolean; isSmall?: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-family: ${FONTS.secondary};
  font-size: 12px;
  justify-content: ${({ isSmall }) => (isSmall ? 'flex-start' : 'space-between')};
`;

export const StyledText = styled.span<{ hasIndicator: boolean }>`
  align-items: center;
  display: flex;
  flex-grow: ${({ hasIndicator }) => (hasIndicator ? 0 : 1)};
  overflow: hidden;
  position: relative;

  &::after {
    background-color: ${COLORS.green.light};
    border-radius: 50%;
    content: ${({ hasIndicator }) => (hasIndicator ? '""' : 'none')};
    display: block;
    flex-shrink: 0;
    height: 6px;
    margin-left: 3px;
    width: 6px;
  }
`;

export const StyledLabel = styled.span<{ hasIndicator: boolean }>`
  position: relative;

  &::after {
    background-color: ${COLORS.green.light};
    border-radius: 50%;
    content: ${({ hasIndicator }) => (hasIndicator ? '""' : 'none')};
    display: block;
    flex-shrink: 0;
    height: 6px;
    margin-left: 8px;
    width: 6px;
  }
`;
