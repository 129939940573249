import { EReactionTypeOptions } from 'constants/graphqlTypes';
import { ROUTE_CARD_DETAIL_THOUGHTS } from 'routes';
import { transformRoute } from './routingHelper';

import useOpenModal from './useOpenModal';

interface IOpenThoughtModal {
  id: string;
  hideTabs?: boolean;
  currentTab?: number;
  thoughtId?: string | null;
  reaction?: EReactionTypeOptions | null;
  shouldClearSearchParams?: boolean;
  openFromCardDetailPage?: boolean;
}

const useThoughtsModal = ({
  id,
  hideTabs,
  currentTab,
  thoughtId,
  reaction,
  shouldClearSearchParams,
  openFromCardDetailPage,
}: IOpenThoughtModal): (() => void) =>
  useOpenModal(
    transformRoute(ROUTE_CARD_DETAIL_THOUGHTS, { pickId: id }),
    false,
    {
      hideTabs,
      currentTab: currentTab ?? 0,
      thoughtId,
      reaction,
      openFromCardDetailPage,
      shouldOpenThoughts: false,
    },
    shouldClearSearchParams
  );

export default useThoughtsModal;
