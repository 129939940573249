import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import EditTextFieldModal from 'components/EditTextFieldModal';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import { useUpdateGroupChat } from 'graphQL/messages/hooks';
import { ROUTE_MESSAGES_SETTINGS } from 'routes';
import { GROUP_CHAT_NAME_LENGTH } from 'constants/common';

const ChangeGroupName: FC = () => {
  const { replace } = useHistory();
  const { state } = useLocation<{ variable: { chatId: string; chatTitle: string } }>();
  const { chatId, chatTitle } = state.variable;

  const [text, setText] = useState(chatTitle);
  const handleClose = () => replace(ROUTE_MESSAGES_SETTINGS, state);
  const updateChatTitle = useUpdateGroupChat(chatId, { title: text }, handleClose);

  return (
    <EditTextFieldModal
      text={text}
      setText={setText}
      label="Group Name"
      placeholder="Add a name"
      handleClose={handleClose}
      handleDone={updateChatTitle}
      error=""
      loading={false}
      setError={() => {}}
      autoCapitalize={EAutoCapitalize.On}
      maxLength={GROUP_CHAT_NAME_LENGTH}
    />
  );
};

export default ChangeGroupName;
