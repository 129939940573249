import { CardSchema, ImageSetSchema } from 'constants/graphqlTypes';
import { ICardStyleModel } from './models';

export const generateCardStyles = (item: CardSchema, images: ImageSetSchema[], index = 0): ICardStyleModel => {
  const { cardStyle } = item;
  const { collectionBgColors, collectionCardsSources } = cardStyle || {};
  const imageUrl = images[index]?.images?.[0]?.url;
  const { gradientStart, gradientEnd } = collectionBgColors?.[index] || {};
  const { sourceDomainName } = collectionCardsSources?.[index] || {};
  const styleId = `${index}-${images[index]?.images?.[0]?.id}`;

  return {
    imageUrl,
    gradientStart,
    gradientEnd,
    sourceDomainName,
    styleId,
  };
};

export const createImagesList = (images: ImageSetSchema[]): string[] => {
  return images.reduce((res: string[], image) => {
    const imageUrl = image.images?.[0]?.url;
    return imageUrl ? [...res, imageUrl] : res;
  }, []);
};

export const cacheImages = async (srcArray: string[]): Promise<void> => {
  const promises = await srcArray.map((src) => {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => res(null);
      img.onerror = () => rej();
    });
  });
  await Promise.all(promises);
};
