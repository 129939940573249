import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Avatar from 'components/UI/Avatar';
import UILink from 'components/UI/Link';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';

import { UnionExtraData, UserObjectType } from 'constants/graphqlTypes';
import { transformRoute } from 'helpers/routingHelper';
import usePageType from 'helpers/usePageType';
import getAuthUser from 'helpers/getAuthUser';
import { IContentIds } from 'helpers/useContentReactions';
import { TEST_ACTION_USERNAME } from 'constants/aqa/pickDetails';
import { ROUTE_USER_PROFILE } from 'routes';

import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { useReactionContext } from 'components/Reactions/hooks';
import { StyledUserInfo } from 'components/UI/Highlight/styled';
import { StyledUsername } from 'components/Sparks/Views/Card/styled';
import ReactionQuickButton from 'components/Reactions/ReactionQuickButton';
import ReactionsToken from 'components/Reactions/ReactionsToken';
import { useHalfTransparency } from './hooks';

import {
  APPEAR_ANIMATION,
  StyledActionContent,
  StyledAvatarWithReactionContainer,
  StyledComment,
  StyledCommentInfo,
  StyledFollowingStatus,
  StyledHidePaddingTop,
  StyledLink,
  StyledName,
  StyledReactionContainer,
  StyledUserBio,
} from './styled';

interface ICommentProps {
  user: UserObjectType;
  fontColor: string;
  isDetail?: boolean;
  isSmall?: boolean;
  isSpotlight?: boolean;
  hasAnimation: boolean;
  withoutPadding?: boolean;
  isVirtualAction?: boolean;
  action?: UnionExtraData;
  id?: string;
  isReactionButtonMenuShown?: boolean;
  isReactionTokenModalOpen?: boolean;
  setIsReactionButtonMenuShown?: Dispatch<SetStateAction<boolean>>;
  setIsReactionsModalOpen?: Dispatch<SetStateAction<boolean>>;
  setIsReactionTokenModalOpen?: Dispatch<SetStateAction<boolean>>;
}

const Action: FC<ICommentProps> = ({
  children,
  user,
  fontColor,
  isDetail,
  isSmall,
  isSpotlight,
  hasAnimation,
  withoutPadding,
  action,
  isReactionButtonMenuShown,
  setIsReactionButtonMenuShown,
  isVirtualAction,
  setIsReactionsModalOpen,
  isReactionTokenModalOpen,
  setIsReactionTokenModalOpen,
  id,
}) => {
  const { pickId } = useParams<{ pickId: string }>();
  const { isItemDetail, isMyProfile } = usePageType();
  const { username: authenticateUsername } = getAuthUser();
  const { userInfo, username, isFollowedByMe, isVerified } = user;
  const { bio } = userInfo ?? {};
  const isAuthenticateUser = username === authenticateUsername;
  const avatarSize = isSmall ? 22 : 44;
  const isCurrentCardInFocus = pickId === id;

  const { isQuickReactionOpen, setPickerId } = useReactionContext();

  const { ref } = useHalfTransparency();
  const { ref: actionRef } = useHalfTransparency();

  useEffect(() => {
    setPickerId?.(user.id);
  }, [user]);

  const { id: actionId } = action ?? {};

  const contentIds: IContentIds =
    action?.__typename === 'CardCommentSchema'
      ? { commentId: action?.id, pickId: id || undefined }
      : { pickId: id || undefined };

  const [shouldRunAnimation, setShouldRunAnimation] = useState(false);

  useEffect(() => {
    let timeout: number;
    if (pickId === id) {
      timeout = +setTimeout(() => {
        setShouldRunAnimation(true);
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [pickId, id]);

  return (
    <StyledComment
      className="action-comment-wrap"
      fontColor={fontColor}
      isDetail={isDetail}
      isSpotlight={isSpotlight}
      isQuickReactionOpen={!!isQuickReactionOpen}
      isReactionButtonMenuShown={isReactionButtonMenuShown}
      isReactionTokenModalOpen={isReactionTokenModalOpen}
      hasAnimation={hasAnimation}
      withoutPadding={withoutPadding}
      isCardInFocus={isCurrentCardInFocus}
    >
      <div>
        <StyledAvatarWithReactionContainer>
          <StyledLink
            size={avatarSize + (hasAnimation ? 8 : 0)}
            to={transformRoute(ROUTE_USER_PROFILE, { profileName: username || 'deleted_user' })}
          >
            {hasAnimation && (
              <svg className="circle" viewBox="0 0 200 200">
                <circle cx={100} cy={100} r={95} stroke={`${fontColor}33`} />
                <circle cx={100} cy={100} r={95} className="animate" stroke={fontColor} />
              </svg>
            )}
            <Avatar user={user} dimension={avatarSize} />
          </StyledLink>
          {isDetail && (
            <StyledReactionContainer isVirtualAction={isVirtualAction} hasLoadedReactions={shouldRunAnimation}>
              {!isAuthenticateUser && setIsReactionButtonMenuShown && setIsReactionsModalOpen && (
                <ReactionQuickButton
                  className={APPEAR_ANIMATION}
                  offset={16}
                  isReactionButtonMenuShown={!!isReactionButtonMenuShown}
                  setIsReactionButtonMenuShown={setIsReactionButtonMenuShown}
                  isDark
                  entityId={actionId ?? ''}
                  setIsReactionsModalOpen={setIsReactionsModalOpen}
                  isReactionMenuOpened={isQuickReactionOpen}
                />
              )}
              <ReactionsToken
                isDetail={isDetail}
                className={APPEAR_ANIMATION}
                isVertical
                hideSecondEmoji
                contentIds={contentIds}
                isDark
                setIsModalOpen={setIsReactionTokenModalOpen}
              />
            </StyledReactionContainer>
          )}
        </StyledAvatarWithReactionContainer>
        <StyledCommentInfo isSmall={isSmall}>
          <div ref={ref}>
            <StyledUserInfo className="action-user-info">
              <UILink route={transformRoute(ROUTE_USER_PROFILE, { profileName: username })}>
                <StyledName>
                  <StyledUsername data-testid={TEST_ACTION_USERNAME}>{generateFullName(user)}</StyledUsername>
                  {isVerified && <VerificationBadge />}
                  {!isMyProfile && isFollowedByMe && (
                    <StyledFollowingStatus>&nbsp;·&nbsp;Following</StyledFollowingStatus>
                  )}
                </StyledName>
              </UILink>
            </StyledUserInfo>
            {bio && (
              <StyledUserBio className="action-user-info" isSmall={!isItemDetail}>
                {bio}
              </StyledUserBio>
            )}
          </div>
          <StyledActionContent
            isDetail={isDetail}
            ref={actionRef}
            className={username ? 'hide-padding' : StyledHidePaddingTop}
          >
            {children}
          </StyledActionContent>
        </StyledCommentInfo>
      </div>
    </StyledComment>
  );
};

export default Action;
