import useToast from 'helpers/useToast';

import {
  GetExploreEveryoneDocument,
  GetExploreItemsDocument,
  useGetMutedUsersQuery,
  useMuteUserMutation,
  useUnmuteUserMutation,
} from 'constants/graphqlTypes';
import { EQueryFetchPolicy, SHOW_FETCH_MORE_LOADER, EMPTY_ITEMS } from 'constants/common';
import { FollowingTabKeyContext } from 'providers/helpers/contexts';
import { IFollowingKey } from 'providers/helpers/models';
import { useContext } from 'react';
import { deleteMutedUserFromCache } from './helpers';
import { IUseMuteUser, IUseMutedUsers } from './models';

export const useMuteUser = (mutedId: string, fullname?: string, onCompleted?: () => void): IUseMuteUser => {
  const { setToast } = useToast();
  const { resetFollowingTabKey } = useContext<IFollowingKey>(FollowingTabKeyContext);
  const [muteUserMutation] = useMuteUserMutation({
    refetchQueries: [
      { query: GetExploreEveryoneDocument, variables: {} },
      { query: GetExploreItemsDocument, variables: {} },
    ],
    onCompleted: () => {
      onCompleted?.();
      setTimeout(() => resetFollowingTabKey?.(), 1000);
    },
  });
  const [unmuteUserMutation] = useUnmuteUserMutation({
    update: (cache) => deleteMutedUserFromCache(mutedId, cache),
    refetchQueries: [
      { query: GetExploreEveryoneDocument, variables: {} },
      { query: GetExploreItemsDocument, variables: {} },
    ],
    onCompleted: () => {
      onCompleted?.();
      setTimeout(() => resetFollowingTabKey?.(), 1000);
    },
  });

  const muteUser = () =>
    muteUserMutation({ variables: { mutedId } })
      .then(() => setToast?.({ isToastOpen: true, toastItemName: `You have muted ${fullname}` }))
      .catch(({ message }) => setToast({ isToastOpen: true, toastItemName: message, toastError: true }));

  const unmuteUser = () =>
    unmuteUserMutation({ variables: { mutedId } })
      .then(() => setToast?.({ isToastOpen: true, toastItemName: `${fullname} has been unmuted` }))
      .catch(({ message }) => setToast({ isToastOpen: true, toastItemName: message, toastError: true }));

  return { muteUser, unmuteUser };
};

export const useMutedUsers = (): IUseMutedUsers => {
  const { data, loading, fetchMore } = useGetMutedUsersQuery({
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.allUsers ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        ...(prev.allUsers && {
          allUsers: {
            ...prev.allUsers,
            items: [...(prev.allUsers?.items ?? []), ...(fetchMoreResult?.allUsers?.items ?? [])],
          },
        }),
      }),
    });

  return { items, loading, loadMore };
};
