import React, { FC, useState } from 'react';

import InfoSlider from 'components/Sliders/InfoSlider';
import { PRIVACY_DATA } from './constants';

import PrivacyModal from './PrivacyModal';

const PrivacyData: FC<{ isDark: boolean }> = ({ isDark }) => {
  const [currentIndex, setCurrentIndex] = useState(-1);

  return (
    <>
      <InfoSlider
        isDark={isDark}
        items={PRIVACY_DATA}
        onSetIndex={(index) => {
          setCurrentIndex(index);
        }}
      />
      {currentIndex >= 0 && (
        <PrivacyModal
          bgColor={PRIVACY_DATA[currentIndex].bgColor}
          handleClose={() => {
            setCurrentIndex(-1);
          }}
          title={PRIVACY_DATA[currentIndex].title}
          Icon={PRIVACY_DATA[currentIndex].Icon}
          fullInfo={PRIVACY_DATA[currentIndex].fullInfo}
        />
      )}
    </>
  );
};

export default PrivacyData;
