import { styled } from 'linaria/lib/react';
import { COLORS } from 'styles/constants';

export const StyledCardWrap = styled.div<{ showCheck: boolean; isSelectable: boolean }>`
  padding: 6px;

  &::after {
    background: ${({ showCheck }) => (showCheck ? COLORS.white[100] : COLORS.white[30])};
    border: 1px solid ${COLORS.white[100]};
    border-radius: 4px;
    bottom: 68px;
    content: ${({ showCheck }) =>
      showCheck
        ? `url('data:image/svg+xml,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_8_2)"><path d="M1 5.5L4.28694 8.62259C4.50022 8.82521 4.84147 8.80081 5.02375 8.56991L11 1" stroke="%23181800" stroke-linecap="round"/></g><defs><clipPath id="clip0_8_2"><rect width="12" height="10" fill="white"/></clipPath></defs></svg>')`
        : '""'};
    display: ${({ isSelectable }) => (isSelectable ? 'flex' : 'none')};
    height: 24px;
    justify-content: center;
    position: absolute;
    right: 13px;
    vertical-align: middle;
    width: 24px;
  }
`;

export const StyledSavedPicksWrap = styled.div`
  padding-bottom: 4px;
`;
