import React, { FC } from 'react';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';

import { transformRoute } from 'helpers/routingHelper';
import {
  ROUTE_LIST_EDIT,
  ROUTE_LIST_EDIT_NAME,
  ROUTE_LIST_EDIT_DESCRIPTION,
  ROUTE_LIST_EDIT_PRIVACY,
  ROUTE_LIST_EDIT_COLLABORATORS,
  ROUTE_LIST_INVITE_COLLABORATORS,
  ROUTE_LIST_EDIT_COLLABORATORS_INVITATION,
  ROUTE_LIST_EDIT_ORDER,
  ROUTE_LIST_CHANGE_LAYOUT,
} from 'routes';
import ChangeLayout from 'components/List/ChangeLayout';
import EditList from './EditList';
import EditListTitle from './EditListName';
import EditListDescription from './EditListDescription';
import EditPrivacy from './EditPrivacy';
import EditListCollaborators from './EditListCollaborators';
import InviteCollaborators from './InviteCollaborators';
import CollaboratorInvitation from './CollaboratorInvitation';
import EditOrder from './EditOrder';

const ListSettings: FC = () => {
  const { replace } = useHistory();
  const { listId } = useParams<{ listId: string }>();
  const { state } = useLocation<{ variable: { title: string } } | undefined>();

  const navigateToEditList = () => {
    replace({
      pathname: transformRoute(ROUTE_LIST_EDIT, { listId }),
      state: {
        ...state,
      },
    });
  };

  return (
    <Switch>
      <Route exact path={ROUTE_LIST_EDIT}>
        <EditList />
      </Route>
      <Route path={ROUTE_LIST_EDIT_NAME}>
        <EditListTitle navigateToEditList={navigateToEditList} />
      </Route>
      <Route path={ROUTE_LIST_EDIT_DESCRIPTION}>
        <EditListDescription navigateToEditList={navigateToEditList} />
      </Route>
      <Route exact path={ROUTE_LIST_EDIT_COLLABORATORS}>
        <EditListCollaborators navigateToEditList={navigateToEditList} />
      </Route>
      <Route path={ROUTE_LIST_INVITE_COLLABORATORS}>
        <InviteCollaborators />
      </Route>
      <Route path={ROUTE_LIST_EDIT_COLLABORATORS_INVITATION}>
        <CollaboratorInvitation />
      </Route>
      <Route path={ROUTE_LIST_EDIT_PRIVACY}>
        <EditPrivacy />
      </Route>
      <Route path={ROUTE_LIST_EDIT_ORDER}>
        <EditOrder />
      </Route>
      <Route path={ROUTE_LIST_CHANGE_LAYOUT}>
        <ChangeLayout />
      </Route>
    </Switch>
  );
};

export default ListSettings;
