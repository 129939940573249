import React, { FC, useEffect, useMemo } from 'react';
import { v4 } from 'uuid';

import { CardSchema } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';
import { useIsUserAllowedToReact, useItemUrl } from 'components/Card/helpers/hooks';
import getAuthUser from 'helpers/getAuthUser';
import { getListStyles } from 'components/Collection/helpers';
import usePageType from 'helpers/usePageType';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import Grain from 'components/UI/Grain';
import InteractionBlock from 'components/InteractionBlock';
import ReactionsModal from 'components/Reactions/ReactionsModal';
import Collaborators from 'components/Collection/Collaborators';
import { ReactionsProvider, useReactionContextState } from 'components/Reactions/hooks';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import useTheme from 'helpers/useTheme';
import { countedSizes } from './helpers';
import { SMALL_CARD_HEIGHT, LARGE_CARD_HEIGHT, SIZE_RATIO } from './constants';
import { formatDateRange, getCreatorsString } from '../helpers';

import {
  StyledTitle,
  StyledDate,
  StyledReactionWrap,
  StyledHead,
  StyledWrap,
  StyledInner,
  StyledLink,
  StyledImageItem,
  StyledImagesList,
  StyledFooter,
  StyledTextsList,
  StyledTextItem,
  StyledTextWrap,
  StyledListAuthors,
  StyledLabel,
} from './styled';

interface ISmallCardProps {
  item: CardSchema;
  dataTestId?: string;
  disableLink?: boolean;
  isReplace?: boolean;
  showActivity?: boolean;
  isLarge?: boolean;
  showCreators?: boolean;
  hideFooter?: boolean;
  enlargeTitle?: boolean;
}

const SmallCard: FC<ISmallCardProps> = ({
  item,
  dataTestId,
  disableLink,
  isReplace,
  showActivity,
  isLarge,
  showCreators,
  hideFooter,
  enlargeTitle,
}) => {
  const { id, title, isCollaborative, creators, createdAt, updatedAt, isPrivateList } = item;
  const isDark = useTheme();

  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  const isUserAllowedToReact = useIsUserAllowedToReact(creators?.items[0]?.user);
  const { thoughtId, setThoughtId, pickerId, setPickerId, cardId, setCardId } = useReactionContextState();
  const { isInvite, isMessages } = usePageType();
  const url = useItemUrl(id, true);
  const { userId } = getAuthUser();
  const { images, titles, bgColor } = getListStyles(item);

  const isCurrentUserList = !!creators?.items?.find((data) => data?.user?.id === userId)?.user?.id;
  const isDisableReaction =
    isCurrentUserList || isCollaborative || isInvite || isMessages || !isUserAllowedToReact || disableLink;

  const titlesWithKey = useMemo(
    () =>
      titles.map((cover) => ({
        cover,
        key: v4(),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [titles.length, titles.join(','), v4]
  );

  const isActivityVisible = isCollaborative || !!showActivity;
  const cardHeight = isLarge ? LARGE_CARD_HEIGHT : SMALL_CARD_HEIGHT;

  const sizes = countedSizes(cardHeight, SIZE_RATIO);

  useEffect(() => {
    setCardId(id);
    setPickerId(creators?.items?.[0]?.user?.id ?? '');
  }, []);

  return (
    <ReactionsProvider
      value={{
        isQuickReactionOpen,
        setThoughtId,
        thoughtId,
        setPickerId,
        pickerId,
        cardId,
        setCardId,
      }}
    >
      <StyledReactionWrap data-testid={dataTestId} ref={infoRef} disableLinks={isQuickReactionOpen || disableLink}>
        <InteractionBlock
          tapCallback={() => setIsQuickReactionOpen(false)}
          longTapCallback={() => !isDisableReaction && setIsQuickReactionOpen(true)}
          getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
        >
          <div>
            <StyledLink
              onClick={(e) => {
                if (disableLink) {
                  e.preventDefault();
                }
              }}
              className="card collection-preview-anchor"
              replace={isReplace}
              to={url}
            >
              <StyledWrap bgColor={bgColor} height={cardHeight}>
                <StyledInner>
                  <Grain />
                  <StyledHead>
                    {createdAt && updatedAt && (
                      <StyledDate isDark={!!images.length || !!titlesWithKey.length}>
                        {formatDateRange(item?.createdAt, item?.updatedAt)}
                      </StyledDate>
                    )}
                    <StyledTitle enlargeTitle={enlargeTitle} isDark={!!images.length || !!titlesWithKey.length}>
                      {title}
                    </StyledTitle>
                  </StyledHead>
                  {!!images.length && (
                    <StyledImagesList
                      isMultiple={images.length >= 4}
                      className={`images-${images.length >= 4 ? 4 : images.length}`}
                    >
                      {images.length === 1 && (
                        <StyledImageItem src={images[0]?.images?.[0]?.url ?? ''} size={sizes.m} />
                      )}
                      {images.length === 2 && (
                        <>
                          <StyledImageItem src={images[1]?.images?.[0]?.url ?? ''} size={sizes.m} />
                          <StyledImageItem src={images[0]?.images?.[0]?.url ?? ''} size={sizes.m} />
                        </>
                      )}
                      {images.length === 3 && (
                        <>
                          <StyledImageItem src={images[2]?.images?.[0]?.url ?? ''} size={sizes.s} />
                          <StyledImageItem src={images[1]?.images?.[0]?.url ?? ''} size={sizes.s} />
                          <StyledImageItem src={images[0]?.images?.[0]?.url ?? ''} size={sizes.s} />
                        </>
                      )}
                      {images.length >= 4 && (
                        <>
                          <StyledImageItem src={images[3]?.images?.[0]?.url ?? ''} size={sizes.s} />
                          <StyledImageItem src={images[2]?.images?.[0]?.url ?? ''} size={sizes.s} />
                          <StyledImageItem src={images[1]?.images?.[0]?.url ?? ''} size={sizes.s} />
                          <StyledImageItem src={images[0]?.images?.[0]?.url ?? ''} size={sizes.s} />
                        </>
                      )}
                    </StyledImagesList>
                  )}
                  {!!titlesWithKey.length && (
                    <StyledTextWrap bgColor={bgColor}>
                      <StyledTextsList bgColor={bgColor} isLarge={isLarge} size={sizes.l}>
                        {titlesWithKey.map(({ cover, key }, i) => (
                          <StyledTextItem
                            className="cover-text-item"
                            key={key}
                            opacity={1 - (1 / titlesWithKey.length) * i}
                            color={COLORS.white[100]}
                          >
                            {cover}
                          </StyledTextItem>
                        ))}
                      </StyledTextsList>
                    </StyledTextWrap>
                  )}
                  {!!isPrivateList && <StyledLabel>Private</StyledLabel>}
                </StyledInner>
              </StyledWrap>
              {!hideFooter && (
                <StyledFooter>
                  {isActivityVisible && <Collaborators item={item} isSmall />}
                  {!!showCreators && (
                    <StyledListAuthors isDark={isDark}>{getCreatorsString(creators)}</StyledListAuthors>
                  )}
                </StyledFooter>
              )}
            </StyledLink>
          </div>
        </InteractionBlock>
        {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && !isDisableReaction && (
          <ReactionMenu
            isShown={isQuickReactionOpen}
            reactionCoordinates={reactionCoordinates}
            handleClickOutside={() => setIsQuickReactionOpen(false)}
            minusTop={minusTop}
            setIsReactionsModalOpen={setIsReactionsModalOpen}
            setIsQuickReactionOpen={setIsQuickReactionOpen}
          />
        )}
        {isReactionsModalOpen && <ReactionsModal id={id} handleClose={() => setIsReactionsModalOpen(false)} />}
      </StyledReactionWrap>
    </ReactionsProvider>
  );
};

export default SmallCard;
