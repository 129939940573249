import React, { FC, Fragment } from 'react';
import { v4 } from 'uuid';

import { CHAR_CLASSNAME, DATA_WORD_INDEX_ATTR, ELLIPSIS_CLASSNAME } from './constants';

import { StyledAnimationChar } from '../styled';

const Ellipsis: FC<{ hasMoreButton: boolean }> = ({ hasMoreButton }) => (
  <>
    {['... ', ...(hasMoreButton ? ['more'] : [])].map((word, wordIndex) => (
      <Fragment key={v4()}>
        {word.split('').map((char) => (
          <StyledAnimationChar
            reserveSpaces
            key={`word-${v4()}`}
            className={`${CHAR_CLASSNAME} ${ELLIPSIS_CLASSNAME}`}
            {...{ [DATA_WORD_INDEX_ATTR]: wordIndex }}
          >
            {char}
          </StyledAnimationChar>
        ))}
      </Fragment>
    ))}
  </>
);

export default Ellipsis;
