import { styled } from 'linaria/react';

import { StyledButton as ImportButton } from 'components/Card/UserCard/styled';
import { COLORS } from 'styles/constants';

export const StyledBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledBtnWrap = styled.div<{ isColumn?: boolean }>`
  display: flex;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  gap: 8px;
  margin: 0 auto;
  width: 100%;
`;

export const StyledButton = styled(ImportButton)<{ isDark: boolean }>`
  background-color: ${({ isDark }) => (isDark ? COLORS.brown.dark[100] : COLORS.white[10])};
  color: ${COLORS.white[100]};
  width: 100%;
`;

export const StyledCloseButton = styled(ImportButton)<{ isDark: boolean }>`
  background-color: ${COLORS.transparent};
  color: ${({ isDark }) => (isDark ? COLORS.brown.dark[100] : COLORS.white[100])};
  padding: 0;
  width: 167px;
`;

export const StyledAcceptButton = styled(ImportButton)`
  background-color: ${COLORS.white[100]};
  color: ${COLORS.brown.dark[100]};
  width: 100%;
`;

export const StyledDeclineButton = styled(StyledButton)<{ isDark: boolean }>`
  background-color: ${COLORS.transparent};
  color: ${({ isDark }) => (isDark ? COLORS.brown.dark[100] : COLORS.white[100])};
`;
