import { useEffect } from 'react';

const useWindowResize = (callback: () => void): void => {
  useEffect(() => {
    callback();
    window.addEventListener('resize', callback);

    return () => window.removeEventListener('resize', callback);
  }, []);
};

export default useWindowResize;
