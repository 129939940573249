import React, { FC, useState } from 'react';

import SliderInteractive from 'components/SliderInteractive';
import QuestionCard from 'components/Card/QuestionCard';

import { SwiperSlide } from 'swiper/react';
import { COLORS } from 'styles/constants';
import { CardSchema } from 'constants/graphqlTypes';
import BrowseAll from './BrowseAll';

import { StyledQuestionSlide } from './styled';
import { StyledCardWrap } from '../styled';

interface IQuestionSliderProps {
  items: CardSchema[];
  isDark: boolean;
  showAll?: boolean;
}

const QuestionSlider: FC<IQuestionSliderProps> = ({ items, isDark, showAll }) => {
  const [slideIndex, setSlideInd] = useState(0);

  const hasOneItem = showAll ? items.length === 0 : items.length === 1;
  const lastCardIndex = showAll ? items.length : items.length - 1;

  return hasOneItem ? (
    <>
      {showAll && <BrowseAll isDark={isDark} />}
      {!showAll && items?.[0] && (
        <StyledCardWrap>
          <QuestionCard card={items[0]} />
        </StyledCardWrap>
      )}
    </>
  ) : (
    <SliderInteractive
      onSlideChange={(ind) => setSlideInd(ind)}
      showBtnsSm={!!items.length}
      spaceBetween={13}
      fontColor={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
      isInfinite={false}
      customSlidesPerView={1.2}
      offset={12}
      isPrevDisabled={slideIndex === 0}
      isNextDisabled={slideIndex === lastCardIndex}
    >
      {items.map((item) => (
        <SwiperSlide key={item.id}>
          <StyledQuestionSlide>
            <QuestionCard card={item} />
          </StyledQuestionSlide>
        </SwiperSlide>
      ))}
      {showAll && (
        <SwiperSlide>
          <BrowseAll isDark={isDark} />
        </SwiperSlide>
      )}
    </SliderInteractive>
  );
};

export default QuestionSlider;
