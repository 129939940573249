import { styled } from 'linaria/react';

const StyledLoader = styled.div<{ isFullScreen?: boolean; isCentered?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${({ isFullScreen }) => (isFullScreen ? 170 : 20)}px 0;
  width: 100%;
  margin: ${({ isCentered }) => (isCentered ? 'auto' : 0)};
`;

export default StyledLoader;
