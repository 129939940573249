import React, { FC, useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import useTheme from 'helpers/useTheme';
import { COLORS } from 'styles/constants';
import SliderInteractive from 'components/SliderInteractive';
import { ChannelAdminFeedSchema, ChannelCustomFeedSchema } from 'constants/graphqlTypes';
import { groupItems } from './helpers';

import FeedCard from '../FeedCard';

import { StyledSlide } from './styled';
import { StyledFeedCardWrap } from '../FeedCard/styled';

interface IFeedCarouselProps {
  customFeeds: (ChannelCustomFeedSchema | ChannelAdminFeedSchema)[];
  loadMore: () => void;
  totalCount: number;
}

const FeedCarousel: FC<IFeedCarouselProps> = ({ customFeeds, totalCount, loadMore }) => {
  const slides = groupItems(customFeeds);

  const isDark = useTheme();

  const [slideIndex, setSlideIndex] = useState(0);

  const handleLoadMore = () => {
    if (slideIndex === slides.length - 2 && customFeeds.length < totalCount) {
      loadMore();
    }
  };

  useEffect(() => {
    if (slideIndex === slides.length - 1 && customFeeds.length !== totalCount) {
      loadMore();
    }
  }, [slideIndex, slides.length, customFeeds.length, totalCount]);

  return customFeeds.length === 1 ? (
    <StyledFeedCardWrap>
      <StyledSlide>
        <FeedCard feed={customFeeds[0]} />
      </StyledSlide>
    </StyledFeedCardWrap>
  ) : (
    <SliderInteractive
      touchStartPreventDefault
      customSlidesPerView={1.2}
      spaceBetween={13}
      showBtnsSm
      fontColor={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
      offset={12}
      isInfinite={false}
      onTransitionEnd={handleLoadMore}
      onAnimationEnd={handleLoadMore}
      onSlideChange={(index) => setSlideIndex(index)}
      isPrevDisabled={slideIndex === 0}
      isNextDisabled={slideIndex === slides.length - 1}
    >
      {slides.map((slideCards) => (
        <SwiperSlide key={slideCards[0].id}>
          <StyledSlide>
            {slideCards.map((feed) => (
              <FeedCard feed={feed} key={feed.id} />
            ))}
          </StyledSlide>
        </SwiperSlide>
      ))}
    </SliderInteractive>
  );
};

export default FeedCarousel;
