import React, { FC, RefObject } from 'react';

import { StyledScaler } from './styled';

interface IScalerProps {
  messageRef: RefObject<HTMLDivElement>;
  scaleFactor?: number;
  newMaxHeight?: number;
  isMine: boolean;
  handleAfterScale: (rect: DOMRect) => void;
  handleScroll?: () => void;
}
const Scaler: FC<IScalerProps> = ({
  children,
  isMine,
  messageRef,
  scaleFactor,
  newMaxHeight,
  handleAfterScale,
  handleScroll,
}) => {
  const handleTransitionEnd = () => {
    handleScroll?.();
    if (scaleFactor && newMaxHeight) {
      const coordRect = messageRef.current?.getBoundingClientRect();
      if (coordRect) {
        handleAfterScale(coordRect);
      }
    }
  };

  return (
    <StyledScaler
      onTransitionEnd={handleTransitionEnd}
      isMine={isMine}
      scaleFactor={scaleFactor}
      maxHeight={newMaxHeight}
    >
      {children}
    </StyledScaler>
  );
};

export default Scaler;
