import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledImmersiveSlider = styled.div`
  .swiper {
    height: 100dvh;
  }
`;

export const StyledImmersivePage = styled.div<{ isDark: boolean; isEmpty: boolean }>`
  background: ${({ isEmpty, isDark }) => {
    if (isEmpty) {
      return `linear-gradient(${COLORS.green.light}, ${COLORS.green.dark})`;
    }

    return isDark ? COLORS.black[100] : COLORS.white[100];
  }};
  height: 100dvh;
  overflow: hidden;
  position: relative;
  width: 100vw;
  z-index: 300;

  .grain {
    background-color: ${COLORS.black[25]};
  }
`;

export const StyledImmersiveWrap = styled.section`
  align-items: center;
  color: ${COLORS.white[100]};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: 476px;
  overflow: hidden;
  padding: 32px;
  position: relative;
  user-select: none;
  width: 100%;
`;

export const StyledImmersiveBgImage = styled.img<{ isVisible: boolean }>`
  filter: blur(20px);
  height: 120dvh;
  object-fit: cover;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: none;
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  transform: translate(-10%, -10%);
  width: 120vw;
`;

export const StyledImmersiveTitle = styled.h1`
  animation: title 1.2s ease-in-out forwards;
  font-size: 22px;
  font-weight: 400;
  line-height: 28.6px;
  margin: 0;
  text-align: center;
  width: fit-content;

  @keyframes title {
    0% {
      opacity: 0;
      clip-path: inset(0 100% 0 0);
    }
    60% {
      clip-path: inset(0 0 0 0);
    }
    100% {
      opacity: 1;
    }
  }
`;

export const StyledImmersiveTotal = styled.span`
  display: inline-block;
  animation: total 0.3s linear 0.8s forwards;
  font-size: 14px;
  height: 16px;
  opacity: 0;
  padding-left: 4px;
  text-align: left;
  transform: translateY(4);
  width: 14px;

  @keyframes total {
    0% {
      opacity: 0;
      transform: translateY(4);
    }
    100% {
      opacity: 1;
      transform: translateY(-8px);
    }
  }
`;

export const StyledImmersiveDescription = styled.div`
  font-family: ${FONTS.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
  padding: 6px 0 0;
  text-align: center;
`;

export const StyledImmersiveCreators = styled.div`
  animation: creators 14.29s linear forwards;
  display: flex;
  justify-content: center;
  padding-bottom: 24px;

  @keyframes creators {
    0% {
      opacity: 0;
      transform: scale(0.83);
    }
    8.68% {
      opacity: 1;
      transform: scale(1.36);
    }
    21.83% {
      transform: scale(1.25);
    }
    63.12% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.25);
    }
  }

  li {
    margin-left: -10px;
  }
`;

export const StyledImmersiveReaction = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  animation: reaction 1s ease-in-out 5s forwards;
  padding-top: 18px;
  transform: scale(0);

  @keyframes reaction {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const StyledImmersiveBottomMenu = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 0 24px 38px;
  position: fixed;
  width: 100%;

  div {
    cursor: pointer;
  }

  .icon-navigation {
    border: 1px solid ${COLORS.white[30]};
    border-radius: 50%;
  }
`;

export const StyledImmersiveExploreWrap = styled.div<{ shouldPauseAnimation: boolean }>`
  .stroke {
    bottom: 35px;
    left: 50%;
    overflow: visible;
    position: fixed;
    transform: translateX(-50%);
  }

  .animate {
    stroke-dasharray: 287; /* The length of the line calculated from the perimeter of the rectangle */
    stroke-dashoffset: 257;
    animation: stroke 10s linear forwards;
    animation-play-state: ${({ shouldPauseAnimation }) => (shouldPauseAnimation ? 'paused' : 'running')};
  }

  @keyframes stroke {
    from {
      stroke-dasharray: 0 287; /* Start with an invisible line */
    }
    to {
      stroke-dasharray: 287 0; /* The final state is the full length of the perimeter */
    }
  }

  button {
    padding-right: 12px;

    svg {
      animation: arrows 3s linear infinite;

      @keyframes arrows {
        0% {
          transform: translateY(0);
        }
        25% {
          transform: translateY(8%);
        }
        75% {
          transform: translateY(-8%);
        }
        100% {
          transform: translateY(0);
        }
      }
    }
  }
`;

export const StyledTapArea = styled.button`
  background: transparent;
  border: none;
  cursor: grab;
  height: calc(100% - 180px);
  left: 50%;
  position: absolute;
  top: 48%;
  transform: translate(-50%, -50%);
  width: 100%;
`;
