import List1 from 'assets/images/onboarding/lists/list1.webp';
import List2 from 'assets/images/onboarding/lists/list2.webp';
import List3 from 'assets/images/onboarding/lists/list3.webp';
import List4 from 'assets/images/onboarding/lists/list4.webp';

export const MOCK_LISTS = [
  {
    bgColor: {
      start: '#357C93',
      end: '#EFE167',
    },
    imgUrl: List1,
  },
  {
    bgColor: {
      start: '#BB2323',
      end: '#FFAB47',
    },
    imgUrl: List2,
  },
  {
    bgColor: {
      start: '#3C3F35',
      end: '#8AA1CD',
    },
    imgUrl: List3,
  },
  {
    bgColor: {
      start: '#1F5DBA',
      end: '#FFB7CF',
    },
    imgUrl: List4,
  },
];

export interface IOnboardingListLocation {
  id?: string;
}
