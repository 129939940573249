import {
  useQuestionDetailQuery,
  useOtherAnswersQuery,
  useGetQuestionCardsLazyQuery,
  useGetEmptyQuestionCardsQuery,
} from 'constants/graphqlTypes';

import useReferrerId from 'helpers/useReferrerId';
import { EQueryFetchPolicy, EMPTY_ITEMS } from 'constants/common';

import { IUseGetDetailQuestion, IUseGetQuestions, IUseOtherAnswers, IUseQuestionsWithPagination } from './models';
import { mergeCards, updateOtherAnswers } from './helper';

export const useGetDetailQuestion = (id?: string, fetchPolicy?: EQueryFetchPolicy): IUseGetDetailQuestion => {
  const { data, loading } = useQuestionDetailQuery({
    variables: { id },
    fetchPolicy,
    skip: !id,
  });

  return { question: data?.allCards?.items?.[0], loading };
};

export const useGetOtherAnswers = (id?: string, limit?: number, fetchPolicy?: EQueryFetchPolicy): IUseOtherAnswers => {
  const { data, loading, fetchMore } = useOtherAnswersQuery({
    variables: { id, offset: 0, limit },
    fetchPolicy,
    skip: !id,
  });

  const { items, totalCount } = data?.allCards?.items?.[0].otherAnswers ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateOtherAnswers(prev, fetchMoreResult),
    });

  return { otherAnswers: items, loadMore, totalCount, loading };
};

export const useQuestionOfDay = (): IUseQuestionsWithPagination => {
  const referrerId = useReferrerId();
  const [loadQuestions, { data, loading, fetchMore }] = useGetQuestionCardsLazyQuery();
  const { items: questionCards, totalCount } = data?.allCards ?? EMPTY_ITEMS;

  const allowLoadMore = questionCards.length < totalCount;

  const getQuestions = (filter: string) => {
    loadQuestions({ variables: { createdAtByMonth: filter, referrerId } });
  };

  const loadMore = () =>
    allowLoadMore &&
    !loading &&
    fetchMore?.({
      variables: { offset: questionCards.length },
      updateQuery: (prev, { fetchMoreResult }) => mergeCards(prev, fetchMoreResult),
    });

  return { getQuestions, questionCards: questionCards.length ? questionCards : [], loadMore, loading };
};

export const useGetSeeMoreQuestions = (questionId?: string): IUseGetQuestions => {
  const referrerId = useReferrerId();
  const { data, loading } = useGetEmptyQuestionCardsQuery({
    variables: { referrerId, questionId },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  return { questionCards: data?.allCards?.items || [], loading };
};
