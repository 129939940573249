import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTE_BLOCKED_ACCOUNTS, ROUTE_BLOCKED_ACCOUNTS_SEARCH } from 'routes';

import SinglePageLayout from 'components/SinglePageLayout';
import usePageType from 'helpers/usePageType';
import Users from 'components/Blocking/Users';
import Search from 'components/Blocking/Search';
import { TEST_ID_BLOCKED_ACCOUNTS_VIEW_START } from 'constants/aqa/settings/accountManagement';

const BlockedAccounts: FC = () => {
  const { isBlockedAccounts } = usePageType();

  return (
    <SinglePageLayout
      pageTestId={TEST_ID_BLOCKED_ACCOUNTS_VIEW_START}
      title={isBlockedAccounts ? 'Blocked accounts' : 'Block an account'}
    >
      <Switch>
        <Route exact path={ROUTE_BLOCKED_ACCOUNTS}>
          <Users />
        </Route>
        <Route exact path={ROUTE_BLOCKED_ACCOUNTS_SEARCH}>
          <Search />
        </Route>
      </Switch>
    </SinglePageLayout>
  );
};

export default BlockedAccounts;
