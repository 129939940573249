import React, { FC } from 'react';

import { MessageType, CardTypeOptions } from 'constants/graphqlTypes';
import { useMenuModal } from 'components/Navigation/helpers/hooks';
import { useGuestListHeader } from 'graphQL/guestList/hooks';

import MenuWrap from './MenuWrap';
import ShareButtons from './ShareButtons';

const GuestListSeriesButton: FC<{ guestListSeriesId: string }> = ({ guestListSeriesId }) => {
  const { card } = useGuestListHeader(guestListSeriesId, CardTypeOptions.GuestListSeries);
  const shareId = card?.guestListSeries?.id;
  const { isModalOpen, handleModal } = useMenuModal();
  return (
    <MenuWrap isOpen={isModalOpen} handleModal={handleModal}>
      {shareId && (
        <ShareButtons
          closePreviousModal={() => handleModal(false)}
          messageType={MessageType.GuestListSeries}
          sharedId={shareId}
        />
      )}
    </MenuWrap>
  );
};

export default GuestListSeriesButton;
