import { CardSchema, ColorSchema } from 'constants/graphqlTypes';

interface IGetGradient {
  firstColor: string;
  secondColor: string;
}

export const getGradient = (bgColor: string): IGetGradient => {
  const bgColorArray = bgColor?.split(' ');

  return bgColorArray.length > 1
    ? {
        firstColor: bgColorArray[1],
        secondColor: bgColorArray[3] ?? bgColorArray[1],
      }
    : {
        firstColor: bgColorArray[0],
        secondColor: bgColorArray[0],
      };
};

export const getCoverGradient = (cards: CardSchema[], isImages: boolean): string | null => {
  const cardColors = cards
    .slice(0, 8)
    .reduce((acc: ColorSchema[], { cardStyle }) => (cardStyle?.bgColor ? acc.concat(cardStyle?.bgColor) : acc), []);

  let topColor = null;
  let bottomColor = null;

  if ((cardColors.length === 1 && isImages) || (cardColors.length < 4 && !isImages)) {
    const { firstColor: gradientStart, secondColor: gradientEnd } = getGradient(cardColors?.[0]?.colorValue ?? '');
    topColor = gradientStart;
    bottomColor = gradientEnd;
  }

  if ((cardColors.length >= 2 && isImages) || (cardColors.length >= 4 && !isImages)) {
    const { firstColor: gradientStart } = getGradient(cardColors?.[1]?.colorValue ?? '');
    const { secondColor: gradientEnd } = getGradient(cardColors?.[0]?.colorValue ?? '');
    topColor = gradientStart;
    bottomColor = gradientEnd;
  }

  if (!topColor && !bottomColor) {
    return null;
  }

  return `linear-gradient(180deg, ${topColor} 0%, ${bottomColor} 100%)`;
};
