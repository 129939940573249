import { PostHogCaptureType } from 'helpers/posthogHooks';

type PostHogEventsType = {
  [key: string]: PostHogCaptureType;
};

export const POSTHOG_EVENTS: PostHogEventsType = {
  AppStart: ['app_start', { description: 'App start' }],
  LandingOpen: ['page_open', { description: 'Navigate to the web page' }],
  LandingOpenFrom: ['page_open_from', { description: 'Navigate to the page from' }],
  LandingJoinButton: ['join_button_tap', { description: 'Tap Join button on page header' }],
  LandingGSHeaderButton: ['get_started_button_tap_header', { description: 'Tap Get started button on page header' }],
  LandingGSButton: ['get_started_button_tap_page', { description: 'Tap Get started button on page' }],
  LandingWaitListForm: ['fill_waitlist_form', { description: 'Type/insert any symbols in waitlist form' }],
  LandingJonWaitList: ['join_waitlist_tap', { description: 'Tap Join waitlist button' }],
  LandingJonWaitListSuccess: ['join_waitlist_success', { description: 'Successfully joined waitlist' }],
  LandingAboutUs: ['about_us_open', { description: 'Tap About us button (any)' }],
  LandingDownloadApp: ['download_app_open', { description: 'Tap Download app button' }],
  LandingTS: ['terms_of_service_open', { description: 'Tap Terms of service app button' }],
  LandingContactUs: ['contact_us_open', { description: 'Tap Contact us app button' }],
  LandingPP: ['privacy_policy_open', { description: 'Tap Download app button' }],
  SignUp: ['signup_allactions', { description: 'Sign up' }],
  SignUpMagicLink: ['signup_magiclink', { description: 'Sign up via magic link' }],
  SignUpGoogle: ['signup_google', { description: 'Sign up with Google account' }],
  SignUpAppleID: ['signup_apple', { description: 'Sign up with Apple ID' }],
  Login: ['login_allactions', { description: 'Login' }],
  LoginMagicLink: ['login_magiclink', { description: 'Log in via magic link' }],
  LoginGoogle: ['login_google', { description: 'Log in with Google account' }],
  LoginAppleID: ['login_apple', { description: 'Log in with Apple ID' }],
  DeleteAccount: ['accountmanagement_deleteaccount_byuser', { description: 'Delete account by user' }],
  isOnboardingTourDone: ['signup_apponboarding_complete', { description: 'Onboarding on registration is completed' }],
  Onboarding: ['onboarding_allactions', { description: 'App Initial Onboarding' }],
  StartTourFeaturedTab: [
    'onboarding_start_fromfeaturedtab',
    { description: 'start onboarding tour tour from featured tab' },
  ],
  StartTourHelpTab: ['onboarding_start_fromhelpmenu', { description: 'start onboarding tour tour from help tab' }],
  CompleteGuideTour: ['onboarding_complete', { description: 'Guide tour completed' }],
  EditProfile: ['profile_edit_allactions', { description: 'edit user profile' }],
  ChangePhoto: ['profile_edit_image', { description: "change user's avatar" }],
  ChangeName: ['profile_edit_name', { description: "change user's name" }],
  ChangeShortBio: ['profile_edit_bio', { description: "change user's short bio" }],
  AddCard: ['add_pick_allactions', { description: 'Add card' }],
  AddLink: ['add_pick_fromlink', { description: 'Add via link' }],
  AddPage: [
    'add_pick_frompage',
    { description: 'Add from page (Explore/Following/Everyone tab, Other user’s profile)' },
  ],
  AddPickWithTags: ['add_pick_with_tag', { description: 'Add pick with tags' }],
  AddOffPlatform: ['add_pick_fromoffplatform', { description: 'add a pick - off-platform' }],
  CreatePickFromList: ['add_pick_publiclist', { description: 'Add pick to the list' }],
  AddPickOrgLt: ['add_pick_organizelater', { description: 'add a pick - organize later' }],
  AddPickPublic: ['add_pick_publiclist', { description: 'add a pick in public list' }],
  AddPickPrivate: ['add_pick_privatelist', { description: 'add a pick in private list' }],
  UserAction: ['user_allactions', { description: 'User' }],
  OpenProfilePage: ['user_profile_open', { description: 'Open profile page' }],
  UserFollow: ['user_follow', { description: 'Following User' }],
  UserUnfollow: ['user_unfollow', { description: 'Unfollowing User' }],
  UserFollowingTags: ['user_follow_by_tags', { description: 'Following tags' }],
  UserUnfollowingTags: ['user_unfollow_by_tags', { description: 'Unfollowing tags' }],
  SpotlightsUpdate: ['profile_spotlights_edit', { description: 'Setting/changing spotlighted cards' }],
  ListAction: ['list_allactions', { description: 'List' }],
  CreateList: ['list_create', { description: 'Create a list' }],
  DelList: ['list_delete', { description: 'Delete a list' }],
  RemUserFromList: ['list_collaborative_role_remove_collaborator', { description: 'remove users from a list' }],
  OpenList: ['list_open_detail_page', { description: 'Open a list' }],
  ListNameKeyword: ['list_name_with_keyword', { description: 'Create or update list with words in name' }],
  EditList: ['list_edit_allactions', { description: 'Edit list' }],
  EditLIstName: ['list_edit_name', { description: 'Edit list name' }],
  EditListDescr: ['list_edit_description', { description: 'Edit list description' }],
  EditListPrivacy: ['list_edit_privacy', { description: 'change list settings (public/private)' }],
  CollabBecomesAdmin: [
    'list_collaborative_role_edit_collaborator_toadmin',
    { description: 'Changing roles collaborator to Admin' },
  ],
  AdminBecomesCollab: ['list_collaborative_role_edit_admin_tocollaborator ', { description: 'RAdminBecomesCollab' }],
  InvitationReport: ['report_invite', { description: 'Report invitation' }],
  CardAction: ['pick_allactions', { description: 'Card update' }],
  OpenCardDetail: ['pick_detail_open', { description: 'Open card details' }],
  OpenCardDetailWithTag: ['pick_detail_with_tag_open', { description: 'Open card details with tag' }],
  AddThougth: ['pick_thought_add', { description: 'Adding thought' }],
  EditThought: ['pick_thought_edit', { description: 'Editing thought' }],
  MoveToList: ['pick_moveto_anotherlist', { description: 'Move to another list' }],
  OpenThoughtSheet: ['pick_open_thought_sheet', { description: 'Pick - open thought sheet' }],
  CardUnpick: ['pick_remove', { description: 'Unpick the card' }],
  CollaborativeList: ['collaborative_list_allactions', { description: 'Collaborative list update' }],
  ListInvite: ['list_collaborative_invite', { description: 'Inviting colaborator to a list' }],
  DetailPageAccept: ['list_collaborative_invite_accept_fromdetailpage', { description: 'Accept from detailed page' }],
  InviteAccept: ['list_collaborative_invite_accept', { description: 'Accept invite' }],
  InviteDecline: ['list_collaborative_invite_decline', { description: 'Decline intitation for a list' }],
  OneAdminLeaveList: [
    'list_collaborative_role_admin_leave',
    { description: 'Admin leaves a list (there are other Admin in the list)' },
  ],
  LastAdminLeaveList: [
    'list_collaborative_role_admin_last_leave',
    { description: 'Admin leaves a list (there are no other Admin in the list)' },
  ],
  CollabLeaveList: ['list_collaborative_role_collaborator_leave', { description: 'collaborator leaves a list' }],
  ReactionsAction: ['reaction_allactions', { description: 'Reactions' }],
  ThoughtReaction: ['reaction_thought', { description: 'Reactions to thought' }],
  CardReaction: ['reaction_pick', { description: 'Reations to card' }],
  ListReaction: ['reaction_list', { description: 'Reactions to list' }],
  SearchAction: ['search_allactions', { description: 'Search' }],
  TapTextSearch: ['search_by_text', { description: 'Searching by tapping text in input field' }],
  SuggestSearch: ['search_suggested_user', { description: 'Search suggestions' }],
  UserSearch: ['search_by_user', { description: 'Searching by  user (text and button)' }],
  TimeSearch: ['search_by_time', { description: 'Searching by  time (text and button)' }],
  TypeSearch: ['search_by_type', { description: 'Searching by  type (text and button)' }],
  SparkAction: ['spark_allactions', { description: 'All Spark' }],
  OpenAllQuestionPage: ['spark_allsparks_open', { description: 'All questions page opening ' }],
  FeaturedOpenAllQuestionPage: [
    'spark_allsparks_open_fromfeatured',
    { description: 'from featured tab QotD carousel' },
  ],
  QotDOpen: ['spark_open_detail', { description: 'opening  QotD detailed page' }],
  CreateSpark: ['spark_create', { description: 'Create spark' }],
  AllSparks: ['spark_allsparks_open_fromprofile', { description: 'opening  All Sparks from Profile' }],
  AnswerQuestion: ['spark_add_pick', { description: 'Answer question' }],
  GuestListAction: ['guestlist_allactions', { description: 'Guest list' }],
  GuestListOpen: ['guestlist_detail_open', { description: 'Open guest list' }],
  GuestListSeriesOpen: ['guestlist_series_detail_open', { description: 'Open guest list series' }],
  MessAction: ['messages_allactions', { description: 'Messages' }],
  MessOpen: ['messages_open', { description: 'open Messaging page' }],
  SocialMediaSendLinkAll: ['offplatform_invite_allactions', { description: 'send invite' }],
  SocialMediaSendLinkNum: ['offplatform_invite_sendlink', { description: 'user sent invite link to social media' }],
  OffplatformShare: ['offplatform_share_allactions', { description: 'User share' }],
  OffplatformSharePick: ['offplatform_share_card', { description: 'User shared a card' }],
  OffplatformShareList: ['offplatform_share_list', { description: 'User shared a list' }],
  OffplatformShareThought: ['offplatform_share_thought', { description: 'User shared a thought' }],
  OffplatformShareUser: ['offplatform_share_user', { description: 'User shared a user' }],
  OffplatformShareSpark: ['offplatform_share_spark', { description: 'User shared a spark' }],
  OffplatformShareGuestList: ['offplatform_share_guestlist', { description: 'User shared a guest list' }],
  OnboardingInviteCode: ['onboarding_invite_code', { description: 'Invite code screen is shown' }],
  OnboardingInviteCodeSuccess: ['onboarding_invite_code_success', { description: `"you're in!" screen is shown` }],
  OnboardingCardPreview: ['onboarding_cards_preview', { description: 'Profile cards previews screen is shown' }],
  OnboardingFullName: ['onboarding_full_name', { description: 'Full name screen is shown' }],
  OnboardingProfileSetup: ['onboarding_profile_setup', { description: 'Profile setup screen is shown' }],
  OnboardingProfileParameters: [
    'onboarding_profile_parameters',
    { description: 'Username/Intro/Topics/Avatar screen is shown' },
  ],
  OnboardingProfileParametersSave: [
    'onboarding_profile_parameters_save',
    { description: 'Username/Intro/Topics/Avatar screens are opened and data is saved on them' },
  ],
  OnboardingProfileCompletion: [
    'onboarding_profile_completion',
    { description: 'Profile setup completion screen is opened' },
  ],
  OnboardingContentPreviews: ['onboarding_content_previews', { description: 'List previews screen is opened' }],
  OnboardingListInstructions: ['onboarding_list_instructions', { description: 'List instructions screen is opened' }],
  OnboardingListCreation: ['onboarding_list_creation', { description: 'List creation screen is opened' }],
  OnboardingPicksAdded: ['onboarding_picks_added', { description: 'pick/picks are added to the list' }],
  OnboardingSharePreviews: ['onboarding_share_previews', { description: 'Share previews screen is displayed' }],
  OnboardingNotificationsScreen: ['onboarding_notifications_screen', { description: 'Notifications screen is opened' }],
  OnboardingNetworkingPreview: [
    'onboarding_networking_preview',
    { description: 'Networking preview screen is opened' },
  ],
  OnboardingNetworkingSuggestions: [
    'onboarding_networking_suggestions',
    { description: 'Networking suggestions screen is opened' },
  ],
  OnboardingCompletion: ['onboarding_completion', { description: 'Onboarding completed' }],
  AddFromSavedCards: ['add_from_saved_for_later', { description: 'Add pick from saved for later' }],
  DeleteFromSavedCards: ['delete_from_saved_for_later', { description: 'Delete pick from saved for later' }],
};

export enum EInviteType {
  Message = 'text_message',
  Whatsapp = 'whatsapp',
  Mail = 'mail',
  X = 'x_post',
  Facebook = 'facebook',
  Linkedin = 'linkedin',
}

export enum EFeaturedFlags {
  CustomFeed = 'customFeed',
  CreateSpark = 'createSparks',
  InteractSpark = 'interactSparks',
  ViewSparks = 'viewSparks',
  ShowChannelsTab = 'showChannelsTab',
  ShowEveryoneTab = 'showEveryoneTab',
}

export enum EPageTypes {
  Featured = 'featured',
  Following = 'following',
  Everyone = 'everyone',
  Spotlights = 'spotlights',
  List = 'list',
  Messages = 'chat',
  CustomFeed = 'customFeed',
  Search = 'search',
}
