interface IMenuButtonTestIds {
  titleTestId: string;
  subtitleTestId: string;
  toggleTestId: string;
  checkboxTestId: string;
}

export const generateMenuButtonTestIds = (baseTestId?: string): IMenuButtonTestIds => {
  const titleTestId = `${baseTestId}_title-label`;
  const subtitleTestId = `${baseTestId}_subtitle-label`;
  const toggleTestId = `${baseTestId}_switch-control`;
  const checkboxTestId = `${baseTestId}_check-box`;

  return { titleTestId, subtitleTestId, toggleTestId, checkboxTestId };
};
