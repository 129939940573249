import { styled } from 'linaria/lib/react';

import { COLORS, FONTS } from 'styles/constants';

import StyledButton from 'components/UI/Button/styled';

export const StyledModalWrap = styled.div`
  .modal {
    padding: 24px 0px;
  }

  .modal--header {
    padding: 0 24px 0 12px;
  }
  .modal--content {
    padding: 0;
  }
`;

export const StyledContentContainer = styled.div`
  width: 100%;
  padding-top: 36px;
  flex: 1 1;
  overflow-y: scroll;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const StyledSliderContainer = styled.div`
  .swiper-slide {
    max-width: 240px;
  }
`;

export const StyledCardContainer = styled.div<{
  isActive: boolean;
  shouldHideCard: boolean;
  shouldHideButton: boolean;
}>`
  opacity: ${({ shouldHideCard, isActive }) => (shouldHideCard && !isActive ? 0 : 1)};
  transition: 0.2s opacity ease-in-out;

  .card-body {
    border: ${({ isActive }) => `1px solid ${isActive ? COLORS.white[100] : COLORS.transparent}`};
  }

  .card-footer {
    display: none;
  }

  ${StyledButton} {
    opacity: ${({ isActive, shouldHideCard, shouldHideButton }) =>
      !isActive || shouldHideCard || shouldHideButton ? 0 : 1};
    pointer-events: ${({ isActive, shouldHideCard, shouldHideButton }) =>
      !isActive || shouldHideCard || shouldHideButton ? 'none' : 'auto'};
    transition: 0.2s opacity ease-in-out;
  }
`;

export const StyledCardFooter = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  width: 100%;
`;

export const StyledCardCounter = styled.div`
  color: ${COLORS.brown.light[100]};
  font: 400 14px/140% ${FONTS.secondary};
  padding: 5px 0 21px;
  text-align: center;
  width: 100%;
`;

export const StyledSrc = styled.span`
  color: ${COLORS.white[100]};
  flex: 1 1;
  font: 400 12px/140% ${FONTS.secondary};
  overflow: hidden;
  padding-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledFooter = styled.footer`
  align-items: center;
  background-color: ${COLORS.brown.dark[100]};
  border-radius: 20px 20px 0 0;
  border-top: 1px solid ${COLORS.white[30]};
  display: flex;
  flex-wrap: wrap;
  flex: 0 0;
  padding: 0 24px calc(var(--keyboard-offset));
  transition: 0.2s padding ease-in-out;
  width: 100%;
`;

export const StyledComplete = styled.div<{ hideButton?: boolean }>`
  overflow: hidden;
  padding: ${({ hideButton }) => (hideButton ? 0 : '27px 0 24px')};
  position: relative;
  transition: 0.3s all ease-in-out;
  width: 100%;

  > button {
    bottom: ${({ hideButton }) => (hideButton ? '-88px' : '0')};
    pointer-events: ${({ hideButton }) => (hideButton ? 'none' : 'auto')};
    position: ${({ hideButton }) => (hideButton ? 'absolute' : 'static')};
    transition: 0.3s all ease-in-out;
    width: 100%;
  }
`;

export const StyledComment = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledDrag = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 100%;

  &::after {
    background-color: ${COLORS.white[30]};
    border-radius: 20px;
    content: '';
    height: 4px;
    width: 56px;
  }
`;

export const StyledStyleSelectionContainer = styled.div`
  padding: 0 24px;

  > span {
    font: 400 14px/140% ${FONTS.secondary};
    color: ${COLORS.white[100]};
    padding-left: 24px;
  }
`;

export const StyledStyleSelection = styled.div`
  padding-top: 16px;
`;
