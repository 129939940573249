import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';

import LoaderContent from 'components/UI/LoaderContent';
import useTheme from 'helpers/useTheme';
import SinglePageLayout from 'components/SinglePageLayout';
import ReactionsList from 'components/Reactions/ReactionsList';

import { useReactions } from 'graphQL/activity/hooks';

const Reactions: FC = () => {
  const { items, loading, loadMore } = useReactions();

  const isDark = useTheme();

  return (
    <SinglePageLayout title="Reactions">
      <ReactionsList isDark={isDark} items={items} />
      {loading ? <LoaderContent isFullScreen={!items.length} isDark={isDark} /> : <Waypoint onEnter={loadMore} />}
    </SinglePageLayout>
  );
};

export default Reactions;
