import { styled } from 'linaria/lib/react';
import { StyledMainHeading } from 'pages/CheckEmailPage/styled';
import { COLORS } from 'styles/constants';

export const StyledWrap = styled.div<{ maxWidth?: number }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100dvh - var(--keyboard-offset));
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '390px')};
  margin: 0 auto;
  overflow: visible;
  padding: 75px 6px 45px;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .swiper {
    margin: 0;
    padding-top: 0;
  }
`;

export const StyledPageInfoContainer = styled.div`
  padding: 10px 18px 24px 18px;
`;

export const StyledContent = styled.section`
  display: flex;
  flex-direction: column;
  padding: 6px;
  gap: 12px;
  padding-bottom: 90px;
`;

export const StyledHeader = styled(StyledMainHeading)`
  margin: 0;
`;

export const StyledFilterBtn = styled.div<{ isDark: boolean }>`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;

  path {
    fill: ${({ isDark }) => (isDark ? `${COLORS.white[100]}` : `${COLORS.brown.light[100]}`)};
  }
`;

export const StyledHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
`;
