import { RefObject, useEffect, useRef } from 'react';
import { addListenerMulti, removeListenerMulti } from './helpers';

export const useHalfTransparency = (isMyThought?: boolean): { ref: RefObject<HTMLDivElement> } => {
  const ref = useRef<HTMLDivElement>(null);

  const startEvents = ['mousedown', 'touchstart'];
  const endEvents = ['mouseup', 'mouseleave', 'touchend'];

  const handleTouchStart = () => !isMyThought && ref.current?.classList.add('half-transparent');
  const handleTouchEnd = () => ref.current?.classList.remove('half-transparent');

  useEffect(() => {
    const refCurrent = ref.current;

    addListenerMulti(refCurrent, startEvents, handleTouchStart);
    addListenerMulti(refCurrent, endEvents, handleTouchEnd);

    return () => {
      removeListenerMulti(refCurrent, startEvents, handleTouchStart);
      removeListenerMulti(refCurrent, endEvents, handleTouchEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return { ref };
};
