import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import UILink from 'components/UI/Link';
import { ROUTE_USER_PROFILE, ROUTE_MESSAGES_SETTINGS } from 'routes';
import { MessageSchema, ActionType } from 'constants/graphqlTypes';
import { useGetUserById } from 'graphQL/messages/hooks';
import { StyledSeparator } from 'components/Messages/MessageThread/styled';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import useOpenModal from 'helpers/useOpenModal';
import { EMPTY_USER } from 'components/Messages/helpers/constants';
import { useMessageObserver } from '../hooks';
import { StyledOtherUsersText } from './styled';

const SystemMessage: FC<{ message: MessageSchema }> = ({ message }) => {
  const { id } = useParams<{ id: string }>();
  const openChatSettings = useOpenModal(ROUTE_MESSAGES_SETTINGS, false, { chatId: id });
  const initiator =
    message?.message?.__typename === 'SystemMessageSchema' &&
    message?.message?.initiator?.__typename === 'UserShortSchema'
      ? message?.message?.initiator
      : null;
  const attributes = message?.message?.__typename === 'SystemMessageSchema' && message?.message.attributes;
  const addedUsers: string[] = Object.values(JSON.parse(attributes));
  const firstUser = useGetUserById(addedUsers[0]);
  const secondUser = useGetUserById(addedUsers[1]);

  const messageWrapperRef = useMessageObserver(message);
  const emptyUser = <> {EMPTY_USER}</>;

  return (
    <StyledSeparator ref={messageWrapperRef}>
      {initiator?.username ? (
        <UILink route={ROUTE_USER_PROFILE} params={{ profileName: initiator?.username }}>
          {generateFullName(initiator)}
        </UILink>
      ) : (
        emptyUser
      )}
      {message?.message?.__typename === 'SystemMessageSchema' && message?.message?.actionType === ActionType.AddUser && (
        <>
          {' '}
          added
          {firstUser ? (
            <UILink route={ROUTE_USER_PROFILE} params={{ profileName: firstUser?.username }} key={firstUser?.id}>
              {' '}
              {generateFullName(firstUser)}
            </UILink>
          ) : (
            emptyUser
          )}
          {addedUsers.length <= 2 ? (
            secondUser && (
              <>
                {' and'}
                <UILink route={ROUTE_USER_PROFILE} params={{ profileName: secondUser?.username }} key={secondUser?.id}>
                  {' '}
                  {generateFullName(secondUser)}{' '}
                </UILink>
              </>
            )
          ) : (
            <>
              &nbsp;and&nbsp;
              <StyledOtherUsersText onClick={openChatSettings}>{addedUsers.length - 1} others</StyledOtherUsersText>
            </>
          )}
        </>
      )}
      {message?.message?.__typename === 'SystemMessageSchema' &&
        message?.message?.actionType === ActionType.LeaveChat && <> has left the group</>}
    </StyledSeparator>
  );
};

export default SystemMessage;
