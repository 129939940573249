import React, { FC } from 'react';
import { SwiperSlide } from 'swiper/react';

import Card from 'components/Sparks/Views/Card';
import SliderInteractive from 'components/SliderInteractive';

import { COLORS } from 'styles/constants';
import { CardSchema } from 'constants/graphqlTypes';
import { StyledCardWrap, StyledContainer } from './styled';

const SparkSlider: FC<{
  items: CardSchema[];
  isDark: boolean;
}> = ({ items, isDark }) =>
  items.length === 1 && items?.[0] ? (
    <StyledCardWrap>
      <Card card={items[0]} isDark={isDark} />
    </StyledCardWrap>
  ) : (
    <StyledContainer>
      <SliderInteractive
        customSlidesPerView={1.1}
        spaceBetween={13}
        fontColor={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
        showBtnsSm={!!items.length}
        offset={12}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <Card card={item} isDark={isDark} />
          </SwiperSlide>
        ))}
      </SliderInteractive>
    </StyledContainer>
  );

export default SparkSlider;
