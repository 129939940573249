import React, { FC, Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';

import { ROUTE_CUSTOM_WORDS, ROUTE_TERMS } from 'routes';
import MenuButton from 'components/UI/MenuButton';
import UiLink from 'components/UI/Link';
import useTheme from 'helpers/useTheme';
import SinglePageLayout from 'components/SinglePageLayout';
import { EMenuButtonType } from 'components/UI/MenuButton/constants';
import {
  TEST_HIDDEN_WORDS_HIDE_CUSTOM,
  TEST_HIDDEN_WORDS_HIDE_MANAGE,
  TEST_HIDDEN_WORDS_HIDE_OFFENSIVE,
  TEST_ID_HIDDEN_WORDS_VIEW_START,
} from 'constants/aqa/settings/accountManagement';
import { useUpdateUserSettings, useUserSettings } from 'graphQL/contextualIntro/hooks';
import { useGetUserHiddenWords } from 'graphQL/hiddenWords/hooks';

import { StyledList } from 'pages/Settings/styled';
import { StyledItem } from '../DataPreferences/styled';

const HiddenWords: FC = () => {
  const [disabledSystemContent, setDisabledSystemContent] = useState(false);
  const [disabledUserContent, setDisabledUserContent] = useState(false);

  const { push } = useHistory();
  const { isOffensiveContentSystemBlockEnabled, isOffensiveContentUserBlockEnabled } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings({});
  const { totalCount, loading } = useGetUserHiddenWords();

  const isDark = useTheme();
  const customWordsCount = !!totalCount && !loading ? `(${totalCount})` : '';

  const systemContentHandler = () => {
    if (disabledSystemContent) return;

    updateUserSettings({
      variables: { isOffensiveContentSystemBlockEnabled: !isOffensiveContentSystemBlockEnabled },
      onCompleted: () => setDisabledSystemContent(false),
    });
    setDisabledSystemContent(true);
  };

  const userContentHandler = () => {
    if (disabledUserContent) return;

    updateUserSettings({
      variables: { isOffensiveContentUserBlockEnabled: !isOffensiveContentUserBlockEnabled },
      onCompleted: () => setDisabledUserContent(false),
    });
    setDisabledUserContent(true);
  };

  return (
    <SinglePageLayout
      pageTestId={TEST_ID_HIDDEN_WORDS_VIEW_START}
      title="Hidden words"
      subTitle="You can choose to hide picks and thoughts with certain words or phrases or emojis you don’t want to see."
    >
      <StyledList isDark={isDark}>
        <StyledItem>
          <MenuButton
            text="Hide offensive words and phrases"
            checked={!!isOffensiveContentSystemBlockEnabled}
            isDark={isDark}
            type={EMenuButtonType.Toggle}
            disabled={disabledSystemContent}
            handler={systemContentHandler}
            dataTestId={TEST_HIDDEN_WORDS_HIDE_OFFENSIVE}
          >
            <>
              This will hide common offensive words, phrases, and emojis automatically. Read more in our{' '}
              <UiLink route={ROUTE_TERMS}>terms of service.</UiLink>
            </>
          </MenuButton>
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Hide custom words and phrases"
            checked={!!isOffensiveContentUserBlockEnabled}
            isDark={isDark}
            type={EMenuButtonType.Toggle}
            disabled={disabledUserContent}
            handler={userContentHandler}
            description="You can create a custom set of words, phrases and emojis that you can hide."
            dataTestId={TEST_HIDDEN_WORDS_HIDE_CUSTOM}
          />
        </StyledItem>
        {isOffensiveContentUserBlockEnabled && (
          <StyledItem>
            <MenuButton
              text={`Manage custom words and phrases ${customWordsCount}`}
              handler={() => push(ROUTE_CUSTOM_WORDS)}
              isDark={isDark}
              Icon={ChevronLeft}
              isReversed
              dataTestId={TEST_HIDDEN_WORDS_HIDE_MANAGE}
            />
          </StyledItem>
        )}
      </StyledList>
    </SinglePageLayout>
  );
};

export default HiddenWords;
