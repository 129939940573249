import React, { FC } from 'react';

import UserList from 'components/UserList';
import { EButtonType } from 'components/UserList/constants';

import useTheme from 'helpers/useTheme';
import { useMutedUsers } from 'graphQL/muting/hooks';
import { TEST_MUTED_ACCOUNTS_EMPTY_LABEL } from 'constants/aqa/settings/accountManagement';

const MutedUsers: FC = () => {
  const { items, loading, loadMore } = useMutedUsers();
  const isDark = useTheme();

  return (
    <UserList
      buttonType={EButtonType.Mute}
      loadMore={loadMore}
      emptyMessage="You have not restricted any accounts"
      isDark={isDark}
      users={items}
      loading={loading}
      emptyLabelTestId={TEST_MUTED_ACCOUNTS_EMPTY_LABEL}
    />
  );
};

export default MutedUsers;
