import { styled } from 'linaria/react';
import { StyledText } from 'pages/CheckEmailPage/styled';
import StyledTitle from 'components/UI/ContentTitle/styled';

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
  overflow: hidden;
  padding-top: 32px;

  & > video {
    border-radius: 10px;
    height: 100%;
    max-height: calc(1 * min(54vh, 458px));
  }
`;

export const StyledSecondaryText = styled(StyledText)`
  margin-top: 30px;
`;

export const StyledContentTitle = styled(StyledTitle)`
  padding: 0 24px;
`;
