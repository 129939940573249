import React, { FC } from 'react';

import { ROUTE_CREATE_FEED } from 'routes';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import useTheme from 'helpers/useTheme';
import withHapticFeedback from 'helpers/withHapticFeedback';

import { useGetChannelsSections } from 'graphQL/channel/hooks';
import { ChannelSectionTypeOptions } from 'constants/graphqlTypes';
import useOpenModal from 'helpers/useOpenModal';
import { FEEDS_SORTING } from 'constants/common';

import { StyledStrokeButton } from '../styled';

const AddCustomFeedButton: FC = () => {
  const sorting = localStorage.getItem(FEEDS_SORTING);
  const { sections } = useGetChannelsSections();
  const sectionId = sections?.find((section) => section.sectionType === ChannelSectionTypeOptions.CustomFeeds)?.id;
  const gotoFeedCreate = useOpenModal(ROUTE_CREATE_FEED, undefined, { isFeedsPage: true, sectionId, sorting });
  const isDark = useTheme();

  return (
    <StyledStrokeButton
      isDark={isDark}
      onClick={withHapticFeedback(() => {
        gotoFeedCreate();
      })}
    >
      <PlusIcon strokeWidth="0.6" width="44" height="44" viewBox="1 1 18 18" />
    </StyledStrokeButton>
  );
};

export default AddCustomFeedButton;
