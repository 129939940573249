import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import { StyledUserInfo } from 'components/UI/Highlight/styled';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  width: 100%;
`;
export const StyledRelWrap = styled.div`
  position: relative;
`;

export const StyledLink = styled(Link)`
  color: ${COLORS.white[100]};
  display: block;
  position: relative;
  text-decoration: none;
`;

export const StyledBody = styled.div<{ bg: string; isReverse: boolean }>`
  background: ${({ bg }) => bg};
  border-radius: 10px;
  display: flex;
  flex-direction: ${({ isReverse }) => `column${isReverse ? '-reverse' : ''}`};
  gap: ${({ isReverse }) => (isReverse ? 12 : 20)}px;
  min-height: 102px;
  overflow: hidden;
  padding: 20px 12px 12px;
  position: relative;
  width: 100%;
`;

export const StyledContent = styled.div<{ hasPaddings?: boolean }>`
  padding: ${({ hasPaddings }) => (hasPaddings ? '0 12px 16px' : 0)};
  position: relative;
  width: 100%;
`;

export const StyledTag = styled.span<{ disableCapitalize?: boolean }>`
  color: ${COLORS.white[100]};
  font: 12px/140% ${FONTS.secondary};
  text-transform: ${({ disableCapitalize }) => (disableCapitalize ? 'none' : 'capitalize')};
`;

export const StyledTitle = styled.span<{ isBig?: boolean; isHuge?: boolean }>`
  color: ${COLORS.white[100]};
  display: block;
  font-size: ${({ isBig, isHuge }) => {
    if (isHuge) {
      return 32;
    }
    return isBig ? 22 : 14;
  }}px;
  line-height: ${({ isBig, isHuge }) => {
    if (isHuge) {
      return 120;
    }
    return isBig ? 130 : 120;
  }}%;
  padding-top: ${({ isBig, isHuge }) => {
    if (isHuge) {
      return 12;
    }
    return isBig ? 4 : 2;
  }}px;
  word-break: break-word;
`;

export const StyledTitleContainer = styled.div<{ isBig?: boolean; isHuge?: boolean }>`
  padding-top: ${({ isBig, isHuge }) => {
    if (isHuge) {
      return 12;
    }
    return isBig ? 4 : 2;
  }}px;

  > span {
    color: ${COLORS.white[100]};
    display: block;
    font-size: ${({ isBig, isHuge }) => {
      if (isHuge) {
        return 32;
      }
      return isBig ? 22 : 14;
    }}px;
    line-height: ${({ isBig, isHuge }) => {
      if (isHuge) {
        return 120;
      }
      return isBig ? 130 : 120;
    }}%;

    word-break: break-word;
  }
`;

export const StyledImageWrap = styled.div<{ isBorderless: boolean; isTiktokVideo?: boolean }>`
  border-radius: ${({ isBorderless }) => (isBorderless ? '10px 10px 0 0' : '8px')};
  box-shadow: 0 0 8px ${COLORS.brown.dark[10]};
  display: flex;
  margin: ${({ isBorderless }) => (isBorderless ? '-21px -12px ' : '')}0;
  overflow: hidden;
  position: relative;
  width: ${({ isBorderless }) => (isBorderless ? 'calc(100% + 24px)' : '100%')};

  img {
    height: auto;
    width: 100%;
  }

  &::after {
    background-image: ${({ isTiktokVideo }) =>
      isTiktokVideo
        ? 'none'
        : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='14' fill='none'%3E%3Cpath d='M12 7 0 14V0l12 7Z' fill='%23fff'/%3E%3C/svg%3E")`};
    bottom: 12px;
    content: ${({ isBorderless }) => (isBorderless ? '""' : 'none')};
    height: 14px;
    left: 12px;
    position: absolute;
    width: 12px;
  }
`;

export const StyledFooter = styled.footer<{ color: string; hasPaddings?: boolean; isFirstUp?: boolean }>`
  align-items: center;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  padding: 15px ${({ hasPaddings }) => (hasPaddings ? 12 : 0)}px ${({ isFirstUp }) => (isFirstUp ? 12 : 24)}px;

  .source-link {
    color: ${({ color }) => color};
    font-family: ${FONTS.secondary};
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }

  .ui-link {
    margin-left: auto;
  }
`;

export const StyledLoader = styled.div`
  display: none;
  margin: 0 auto;
`;

export const StyledHighlightWrap = styled.div`
  padding-top: 20px;
`;

export const StyledTweet = styled(StyledUserInfo)`
  font-family: ${FONTS.secondary};
  font-size: 12px;
  padding: 2px 0 8px;

  svg {
    margin-right: 8px;
    position: relative;
    top: 2px;
  }
`;

export const StyledSpace = styled.div<{ isFirstUp?: boolean }>`
  margin-bottom: ${({ isFirstUp }) => (isFirstUp ? 0 : -8)}px;
  height: ${({ isFirstUp }) => (isFirstUp ? 16 : 0)}px;
`;

export const StyledSaved = styled.div`
  backdrop-filter: blur(2px);
  background: ${COLORS.white[10]};
  border-radius: 4px;
  font: 400 14px/140% ${FONTS.secondary};
  margin-top: 8px;
  padding: 4px 8px;
  width: fit-content;
`;
