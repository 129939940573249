import React, { FC } from 'react';

import { useShowBottomMenu, useNavBehavior } from './helpers/hooks';
import { StyledNavigation } from './styled';

import LeftMenu from './LeftMenu';
import BottomMenu from './BottomMenu';
import RightMenu from './RightMenu';

const Navigation: FC = () => {
  const { isHide, bgColor, fontColor } = useNavBehavior();
  const showBottomMenu = useShowBottomMenu();

  return (
    <StyledNavigation id="navigation-container" color={fontColor} isHide={isHide} bgColor={bgColor}>
      <LeftMenu />
      {showBottomMenu && <BottomMenu />}
      <RightMenu />
    </StyledNavigation>
  );
};

export default Navigation;
