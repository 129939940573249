import { styled } from 'linaria/lib/react';

import { BREAKPOINTS } from '../../styles/constants';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px 16px;
  width: 100%;
  max-width: 390px;
  box-sizing: border-box;

  & > span {
    font-size: 22px;
    line-height: 130%;
  }

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    max-width: 342px;
    padding: 32px 0 16px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: ${BREAKPOINTS.s}px) {
    max-width: 100%;
    padding: 32px 24px 16px;
  }
`;

export const StyledSliderContainer = styled.div`
  padding: 0 0 90px;
  max-width: 100vw;
  width: 100%;

  .swiper-slide {
    max-width: 'none';
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0 0 90px;
    max-width: 342px;
  }
`;

export const StyledSlide = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;

  > div {
    flex: 1 1;
    width: 100%;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-width: 342px;
  }
`;

export const StyledAlertWrap = styled.div`
  margin-bottom: 24px;
  max-width: 366px;
  width: 100%;
`;
