import React, { FC } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';

import Badge from 'components/UI/Badge';
import IconCircle from 'components/UI/IconCircle';

import { UserObjectType } from 'constants/graphqlTypes';
import { StyledWrap } from './styled';

interface IUserItemProps {
  user: UserObjectType;
  isEnable: boolean;
  handleClick: () => void;
  isDark: boolean;
}

const UserItem: FC<IUserItemProps> = ({ user, isEnable, isDark, handleClick }) => (
  <StyledWrap onClick={handleClick}>
    <Badge user={user} hasUsername isDark={isDark} isDisabled />
    <IconCircle
      Icon={isEnable ? CheckIcon : PlusIcon}
      isDark={isDark}
      hasBorder={!isEnable}
      hasBackground={isEnable}
      hasStroke
    />
  </StyledWrap>
);

export default UserItem;
