import React, { FC } from 'react';

import { StyledNavigation } from 'components/Navigation/styled';
import { COLORS } from 'styles/constants';

import { StyledWrap } from './styled';

const SharingNavigation: FC<{ color?: string }> = ({ color, children }) => (
  <StyledWrap>
    <StyledNavigation className="navigation" isHide={false} color={color ?? COLORS.brown.dark[100]}>
      {children}
    </StyledNavigation>
  </StyledWrap>
);

export default SharingNavigation;
