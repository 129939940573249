import React, { FC } from 'react';

import AnimatedBackground from 'components/AnimatedBackground';
import Loader from 'components/UI/Loader';

import { useLogin } from 'graphQL/auth/hooks';
import { StyledLoader } from 'pages/CheckEmailPage/styled';

const AuthRedirect: FC = () => {
  useLogin();

  return (
    <AnimatedBackground>
      <StyledLoader>
        <Loader size={18} />
      </StyledLoader>
    </AnimatedBackground>
  );
};

export default AuthRedirect;
