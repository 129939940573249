import React, { FC } from 'react';

import { ImageSetSchema } from 'constants/graphqlTypes';

import Grain from 'components/UI/Grain';

import { useImmersiveBgIndex, useTransparentNavigation } from './helpers/hooks';
import { StyledImmersiveBgImage, StyledTapArea } from './styled';

const IImmersiveBackground: FC<{ items: ImageSetSchema[]; handleTap: () => void }> = ({ items, handleTap }) => {
  const { index, secondIndex, isSecondShown } = useImmersiveBgIndex(items.length);
  const imagesUrl = items.map((image) => image?.images?.[0]?.url || '');

  const image = imagesUrl[index];
  const secondImage = imagesUrl[secondIndex];

  useTransparentNavigation();

  return (
    <>
      {image && <StyledImmersiveBgImage isVisible={!isSecondShown} src={image} />}
      {secondImage && <StyledImmersiveBgImage isVisible={isSecondShown} src={secondImage} />}
      <Grain />
      <StyledTapArea onClick={handleTap} />
    </>
  );
};

export default IImmersiveBackground;
