import React, { FC, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  ROUTE_ONBOARDING_CONTENT,
  ROUTE_ONBOARDING_CONTENT_LIST_CREATION,
  ROUTE_ONBOARDING_CONTENT_LIST_DETAIL,
  ROUTE_ONBOARDING_CONTENT_LIST_PREVIEW,
  ROUTE_ONBOARDING_CONTENT_CONFIRMATION_FINISH,
} from 'routes';

import ContentTutorial from 'pages/OnboardingV2/ContentTutorial';

import ListCreation from 'pages/OnboardingV2/ContentTutorial/ListCreation';
import ListDetail from 'pages/OnboardingV2/ContentTutorial/ListDetail';
import Preview from 'pages/OnboardingV2/ContentTutorial/Preview';
import ConfirmationFinish from 'pages/OnboardingV2/ContentTutorial/ConfirmationFinish';
import { useGetProfileLists } from 'graphQL/cards/userCards/hooks';
import getAuthUser from 'helpers/getAuthUser';

const ContentTutorialRoutes: FC = () => {
  const { userId } = getAuthUser();
  const [listId, setListId] = useState<string | undefined>();

  const { lists, loading: listLoading } = useGetProfileLists({
    customUserId: userId,
    limit: 1,
    skip: !userId || !!listId,
    withPrivacy: true,
  });

  useEffect(() => {
    if (!listId && lists[0]?.id) {
      setListId(lists[0]?.id);
    }
  }, [lists[0]?.id]);

  return (
    <Switch>
      <Route exact path={ROUTE_ONBOARDING_CONTENT}>
        <ContentTutorial />
      </Route>
      <Route path={ROUTE_ONBOARDING_CONTENT_LIST_CREATION}>
        <ListCreation setListId={setListId} listId={listId} />
      </Route>
      <Route path={ROUTE_ONBOARDING_CONTENT_LIST_DETAIL}>
        <ListDetail listId={listId} listLoading={listLoading} />
      </Route>
      <Route path={ROUTE_ONBOARDING_CONTENT_LIST_PREVIEW}>
        <Preview />
      </Route>
      <Route path={ROUTE_ONBOARDING_CONTENT_CONFIRMATION_FINISH}>
        <ConfirmationFinish />
      </Route>
    </Switch>
  );
};

export default ContentTutorialRoutes;
