import { useEffect, useState } from 'react';

import { GetDetailListDocument, GetListItemsDocument, usePickOnboardingCardsMutation } from 'constants/graphqlTypes';
import { BREAKPOINTS } from 'styles/constants';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { usePostHogCapture } from 'helpers/posthogHooks';
import useToast from 'helpers/useToast';
import { IRefItems } from 'graphQL/models';
import { IUseCardsBaseSelection, IUseCardsSelection, IUseCardsSelectionArgs } from './models';

export const useCardWidth = (): number | undefined => {
  const chooseCardWidth = () => (window.innerWidth < BREAKPOINTS.s ? 200 : undefined);

  const [cardWidth, setCardWidth] = useState<number | undefined>(chooseCardWidth());

  const handleWindowResize = () => setCardWidth(chooseCardWidth());

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return cardWidth;
};

export const useCardsBaseSelection = (isDelete?: boolean, showToast = true): IUseCardsBaseSelection => {
  const [selectedCards, setSelectedCards] = useState<string[]>([]);

  const { setToast } = useToast();

  const handleCardClick = (id: string): void => {
    if (selectedCards.includes(id) || selectedCards.length < 5) {
      setSelectedCards((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : prev.concat(id)));

      return;
    }

    if (showToast) {
      setToast({
        isToastOpen: true,
        toastItemName: `You can ${isDelete ? 'delete' : 'share'} a max of 5 picks at a time`,
        customBottomCoord: 90,
      });
    }
  };

  const resetSelectedCards = () => setSelectedCards([]);

  return {
    selectedCards,
    handleCardClick,
    resetSelectedCards,
  };
};

export const useCardsSelection = ({
  handleClose,
  listId,
  categoryItems,
}: IUseCardsSelectionArgs): IUseCardsSelection => {
  const posthogCapture = usePostHogCapture();
  const { selectedCards, handleCardClick } = useCardsBaseSelection();
  const [pickCards] = usePickOnboardingCardsMutation();

  const buttonText = selectedCards.length
    ? `Continue with ${selectedCards.length} pick${selectedCards.length > 1 ? 's' : ''}`
    : undefined;

  const categoriesIds = categoryItems.map((item) => item.id);

  const handleComplete = (): void => {
    pickCards({
      variables: {
        listId,
        pickIds: selectedCards,
      },
      refetchQueries: [
        {
          query: GetListItemsDocument,
          variables: {
            id: listId,
            refListId: listId,
            offset: 0,
          },
        },
        {
          query: GetDetailListDocument,
          variables: {
            id: listId,
            isDetail: true,
            coverLimit: undefined,
          },
        },
      ],
      update: (cache) => {
        categoriesIds.forEach((categoryId) => {
          cache.modify({
            id: cache.identify({ __typename: 'ContentTutorialCategorySchema', id: categoryId }),
            fields: {
              categoryPicks: (prev: IRefItems) => {
                const newItems = prev.items.filter(
                  (item) => !selectedCards.includes(item.__ref.split('CardSchema:')[1])
                );
                const deltaLength = prev.items.length - newItems.length;
                const newTotalCount = prev.totalCount - deltaLength;

                return {
                  ...prev,
                  items: newItems,
                  totalCount: newTotalCount,
                };
              },
            },
          });
        });
      },
      onCompleted: () => {
        handleClose();
        posthogCapture(POSTHOG_EVENTS.OnboardingPicksAdded);
      },
    });
  };

  return {
    selectedCards,
    buttonText,
    handleCardClick,
    handleComplete,
  };
};
