import { addToCards, removeFromItems } from 'graphQL/cardOptions/helpers';
import { IRefItems } from 'graphQL/models';
import { IAddRemoveItemToList } from './models';

export const addItemToList = ({ cache, listId, pickId }: IAddRemoveItemToList): void => {
  cache.modify({
    id: cache.identify({ id: listId, __typename: 'CardSchema' }),
    fields: {
      cardsFromCollection: (prev: IRefItems) => ({
        ...prev,
        totalCount: prev.totalCount + 1,
        items: addToCards(prev.items, pickId),
      }),
    },
  });
};

export const removeItemFromList = ({ cache, listId, pickId }: IAddRemoveItemToList): void => {
  cache.modify({
    id: cache.identify({ id: listId, __typename: 'CardSchema' }),
    fields: {
      cardsFromCollection: (prev: IRefItems) => ({
        ...prev,
        totalCount: prev.totalCount - 1,
        items: removeFromItems(prev.items, pickId),
      }),
    },
  });
};
