import { Dispatch, SetStateAction, useState } from 'react';

import { UserObjectType } from 'constants/graphqlTypes';
import { useGetConnections } from 'graphQL/follows/hooks';
import { useGetSearchSuggestedUsers } from 'graphQL/search/hooks';

import getAuthUser from './getAuthUser';
import useDebouncedValue from './useDebounced';

interface IUseSuggestedUsersSearch {
  countSymbolsForStartSearch?: number;
  excludedUsers?: string[];
  withoutBlockedAndBlockers?: boolean;
}

interface IUseSuggestedUsersSearchResult {
  items: UserObjectType[];
  loading: boolean;
  loadMore: () => void;
  text: string;
  setText: Dispatch<SetStateAction<string>>;
}

const useSuggestedUsersSearch = ({
  countSymbolsForStartSearch = 2,
  withoutBlockedAndBlockers,
}: IUseSuggestedUsersSearch): IUseSuggestedUsersSearchResult => {
  const [text, setText] = useState('');

  const { userId } = getAuthUser();

  const searchText = useDebouncedValue(text);
  const shouldSearch = text.length > countSymbolsForStartSearch;

  const { items: searchItems, loading: searchLoading, loadMore: searchLoadMore } = useGetSearchSuggestedUsers({
    searchString: searchText,
    limit: 10,
    skipLength: countSymbolsForStartSearch,
    withoutBlockedAndBlockers,
  });

  const { items, loading, loadMore } = useGetConnections(userId);

  return {
    items: shouldSearch ? searchItems : items,
    loading: shouldSearch ? searchLoading : loading,
    loadMore: shouldSearch ? searchLoadMore : loadMore,
    text,
    setText,
  };
};

export default useSuggestedUsersSearch;
