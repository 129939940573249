import config from 'constants/config';

const { safariAppleTeamId, safariExtBundleId, chromeExtId } = config;

const safariExtensionId = safariExtBundleId && safariAppleTeamId ? `${safariExtBundleId} (${safariAppleTeamId})` : '';

export enum EMessagePrefixes {
  LOGIN = 'login',
  LOGOUT = 'logout',
}

export const sendMessageToExtension = (messageType: EMessagePrefixes, message: string) => {
  if (typeof chrome === 'object' && chromeExtId) {
    chrome?.runtime?.sendMessage?.(chromeExtId, `${messageType}:${message}`);
  }

  if (typeof browser === 'object' && safariExtensionId) {
    browser?.runtime?.sendMessage?.(safariExtensionId, `${messageType}:${message}`);
  }
};
