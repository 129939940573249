import { ICenterSectionSlide } from './models';

import groupLists from '../../../assets/images/landing/groupLists.webp';
import groupPicks from '../../../assets/images/landing/groupPicks.webp';
import groupProfile from '../../../assets/images/landing/groupProfile.webp';

export const CENTER_SECTION_SLIDES: ICenterSectionSlide[] = [
  {
    img: groupPicks,
    title: ':Picks',
    subtitle: 'Share what you love, and add your thoughts. Discover the best stuff you didn’t know about.',
  },
  {
    img: groupLists,
    title: ':Lists',
    subtitle: 'Lists are your special collections, used to organize your favorite finds, and guide other users.',
  },
  {
    img: groupProfile,
    title: ':Profile',
    subtitle: 'Beautiful profiles as unique as you are. Follow experts and friends to see what they’re excited about.',
  },
];
