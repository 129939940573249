import { useHistory, useLocation } from 'react-router-dom';

type ILocationStateVariable = {
  [key: string]: string | undefined;
};

const useNavigateWithStateVars = () => {
  const { replace } = useHistory();
  const { state } = useLocation<{ variable?: ILocationStateVariable }>();

  return (pathname: string, variable?: ILocationStateVariable) =>
    replace({
      pathname,
      state: { ...state, variable: { ...state.variable, ...(variable ? { ...variable } : {}) } },
    });
};

export default useNavigateWithStateVars;
