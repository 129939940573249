import { RefObject, useEffect } from 'react';

export default (
  ref: RefObject<HTMLElement>,
  callback: () => void,
  isPreventEvent = false,
  customEventHandler?: (e: TouchEvent | MouseEvent) => void
): void => {
  useEffect(() => {
    const handleClickOutside = (e: TouchEvent | MouseEvent): void => {
      customEventHandler?.(e);

      if (ref.current && !ref.current.contains(e.target as Node)) {
        if (isPreventEvent) {
          e.preventDefault();
        }
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside, { passive: false });

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref, callback]);
};
