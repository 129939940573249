import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';

const BORDER_RADIUS = '20px';

export const StyledWrap = styled.button<{ isDark: boolean; isVertical?: boolean }>`
  align-items: center;
  background: ${({ isDark }) => (isDark ? COLORS.white[10] : COLORS.white[100])};
  border-radius: ${BORDER_RADIUS};
  border: ${({ isDark }) => (isDark ? 'none' : `1px ${COLORS.white.off[100]} solid`)};
  border: none;
  color: ${({ isDark }) => (isDark ? `${COLORS.white[100]}` : `${COLORS.brown.dark[100]}`)};
  cursor: pointer;
  display: flex;
  flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};
  font: 12px/140% ${FONTS.secondary};
  gap: ${({ isVertical }) => (isVertical ? '2px' : '8px')};
  height: ${({ isVertical }) => (isVertical ? 'auto' : '36px')};
  justify-content: center;
  min-height: ${({ isVertical }) => (isVertical ? '36px' : 'none')};
  min-width: ${({ isVertical }) => (isVertical ? '36px' : 'none')};
  padding: ${({ isVertical }) => (isVertical ? '8px 6px' : '6px 8px')};
  position: relative;
  width: ${({ isVertical }) => (isVertical ? '36px' : 'auto')};

  > span {
    position: relative;
  }
`;

export const StyledAnimatedBackground = styled.div<{ isDark: boolean }>`
  border-radius: ${BORDER_RADIUS};
  position: absolute;
  inset: 0;

  animation: reactionTokenBackground 1.5s linear;
  @keyframes reactionTokenBackground {
    0% {
      background: ${({ isDark }) => (isDark ? COLORS.white[10] : COLORS.white[100])};
    }
    25% {
      background: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.white.off[100])};
    }
    75% {
      background: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.white.off[100])};
    }
    100% {
      background: ${({ isDark }) => (isDark ? COLORS.white[10] : COLORS.white[100])};
    }
  }
`;
