import React, { FC, FormEvent } from 'react';

import landingVideo from 'assets/videos/landing_vidio_14.mp4';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';

import {
  StyledContent,
  StyledForm,
  StyledFormField,
  StyledFormInput,
  StyledFormLabel,
  StyledFormSection,
  StyledHeadingContainer,
  StyledSignupHeading,
  StyledSignupSubheading,
  StyledSubmit,
} from './styled';
import { useSubscribeForm } from './hooks';

const SubscribeForm: FC = () => {
  const posthogCapture = usePostHogCapture();

  const { name, setName, email, setEmail, handleSubmit } = useSubscribeForm();

  const handleJoinWaitList = (e: FormEvent<HTMLFormElement>) => {
    posthogCapture(POSTHOG_EVENTS.LandingJonWaitList);
    handleSubmit(e);
  };

  return (
    <StyledFormSection id="landing-form">
      <StyledContent>
        <StyledHeadingContainer>
          <StyledSignupHeading>Let humans be your guide.</StyledSignupHeading>
          <StyledSignupSubheading>Sign up for a better way to do social today.</StyledSignupSubheading>
        </StyledHeadingContainer>
        <StyledForm onSubmit={handleJoinWaitList}>
          <StyledFormField>
            <StyledFormLabel htmlFor="mce-EMAIL">Email Address:</StyledFormLabel>
            <StyledFormInput
              value={email}
              id="mce-EMAIL"
              name="EMAIL"
              type="email"
              required
              onChange={(e) => {
                posthogCapture(POSTHOG_EVENTS.LandingWaitListForm);
                setEmail(e.currentTarget.value);
              }}
            />
          </StyledFormField>
          <StyledFormField>
            <StyledFormLabel htmlFor="mce-FNAME">Name:</StyledFormLabel>
            <StyledFormInput
              id="mce-FNAME"
              type="text"
              name="FNAME"
              value={name}
              onChange={(e) => {
                posthogCapture(POSTHOG_EVENTS.LandingWaitListForm);
                setName(e.currentTarget.value);
              }}
            />
          </StyledFormField>
          <StyledSubmit type="submit">Join the Waitlist</StyledSubmit>
        </StyledForm>
      </StyledContent>
      <video width="128%" height="100%" autoPlay muted playsInline loop preload="auto">
        <source src={`${landingVideo}#t=0.001`} type="video/mp4" />
      </video>
    </StyledFormSection>
  );
};

export default SubscribeForm;
