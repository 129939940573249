import React, { FC } from 'react';

import Toggle from 'components/UI/Toggle';
import IconCircle from 'components/UI/IconCircle';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { generateMenuButtonTestIds } from 'constants/aqa/components/menuButton';
import { EMenuButtonType } from './constants';
import Checkbox from '../Checkbox/Checkbox';

import { StyledButton, StyledDescription, StyledText } from './styled';

interface IMenuButtonProps {
  Icon?: FC;
  customIconText?: string;
  handler?: () => void;
  text: string;
  isRed?: boolean;
  hasStroke?: boolean;
  hasTruncate?: boolean;
  disabled?: boolean;
  checked?: boolean;
  isReversed?: boolean;
  isDark?: boolean;
  description?: string;
  children?: JSX.Element;
  type?: EMenuButtonType;
  dataTestId?: string;
}

const MenuButton: FC<IMenuButtonProps> = ({
  Icon,
  customIconText,
  text,
  handler,
  isRed,
  hasStroke,
  hasTruncate,
  disabled,
  checked = false,
  isReversed,
  isDark = true,
  description,
  children,
  type,
  dataTestId,
}) => {
  const { titleTestId, subtitleTestId, toggleTestId, checkboxTestId } = generateMenuButtonTestIds(dataTestId);

  return (
    <StyledButton
      data-testid={dataTestId}
      onClick={handler}
      isRed={isRed}
      disabled={disabled}
      isDark={isDark}
      isReversed={isReversed}
      hasToggle={type === EMenuButtonType.Toggle || type === EMenuButtonType.Radio}
      hasDescription={!!description || !!children}
    >
      {(Icon || customIconText) && (
        <IconCircle
          isRed={isRed}
          isDark={isDark}
          Icon={Icon}
          customIconText={customIconText}
          hasStroke={hasStroke}
          hasBackground={false}
          hasBorder={!isReversed}
        />
      )}
      <StyledText
        data-testid={titleTestId}
        hasDescription={!!description || !!children}
        hasPadding={!isReversed && !!(Icon || customIconText)}
        hasTruncate={hasTruncate}
      >
        {text}
      </StyledText>
      {type === EMenuButtonType.Toggle && (
        <Toggle dataTestId={toggleTestId} isDisabled={disabled} isDark={isDark} checked={checked} />
      )}
      {type === EMenuButtonType.Checkbox && (
        <Checkbox
          dataTestId={checkboxTestId}
          isTransparent
          isDark={isDark}
          isChecked={checked}
          handler={() => handler?.()}
        />
      )}
      {type === EMenuButtonType.Radio && (
        <IconCircle
          isDark={isDark}
          Icon={checked ? CheckIcon : PlusIcon}
          hasStroke
          hasBackground={checked}
          hasBorder={!checked}
        />
      )}
      {description && (
        <StyledDescription onClick={(e) => e.stopPropagation()} data-testid={subtitleTestId}>
          {description}
        </StyledDescription>
      )}
      {children && (
        <StyledDescription onClick={(e) => e.stopPropagation()} data-testid={subtitleTestId}>
          {children}
        </StyledDescription>
      )}
    </StyledButton>
  );
};
export default MenuButton;
