import { styled } from 'linaria/react';

import { COLORS } from 'styles/constants';
import { StyledList as ImportList } from 'components/AddContent/Menu/styled';

export const StyledList = styled(ImportList)<{ isDark: boolean }>`
  padding: 0;

  li {
    border-color: ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.dark[10])};
  }
`;

export const StyledSliderWrap = styled.div`
  margin: 0 -24px 16px -12px;

  .swiper-slide {
    height: auto;
  }

  > div {
    .slider-prev,
    .slider-next {
      margin-top: 90px;
    }
  }
`;
