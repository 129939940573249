import React, { FC, useState } from 'react';

import { useMoveToCurrentList, useGotoMoveToList } from 'helpers/useContextMenu';
import Button from 'components/UI/MenuButton';
import getAuthUser from 'helpers/getAuthUser';
import { ALL_PICKS } from 'constants/common';

import ConfirmationModal from 'components/UI/ConfirmationModal';
import { ReactComponent as ExistingListIcon } from 'assets/icons/ListDefaultView.svg';
import { CardSchema, Maybe } from 'constants/graphqlTypes';
import { StyledItem } from 'components/AddContent/Menu/styled';
import { StyledMoveWarning } from './CardButton/styled';

interface IMoveCardButtonsProps {
  card: CardSchema;
  isCardInAnotherList: boolean;
  handleViewInMyList: () => void;
  closePreviousModal: () => void;
  parentListCardId?: string;
  parentListTitle?: Maybe<string>;
  isUserInList?: boolean;
}

const MoveCardButtons: FC<IMoveCardButtonsProps> = ({
  isCardInAnotherList,
  card,
  handleViewInMyList,
  closePreviousModal,
  parentListCardId,
  parentListTitle,
  isUserInList,
}) => {
  const { userId } = getAuthUser();

  const { id: pickId, cardId, isCardPickedByCollaborators, picks } = card;
  const myPickId = picks?.items.find((item) => item.userId === userId)?.id || '';
  const userPickSchemaRef = card?.extraData?.items?.find((data) => data?.user?.id === userId)?.id;

  const { Icon: ToListIcon, text: toListText, toggle: openMoveToList } = useGotoMoveToList({
    pickId: myPickId,
    cardId,
    currentListId: parentListCardId,
    userPickSchemaRef,
  });
  const { text: moveToCurrentListText, toggle: openMoveToCurrentList } = useMoveToCurrentList(
    pickId,
    cardId,
    parentListCardId,
    userPickSchemaRef
  );

  const [showMoveAlertModal, setShowMoveAlert] = useState(false);

  const handleMoveToListBtn = () => {
    closePreviousModal();

    if (isCardPickedByCollaborators) {
      setShowMoveAlert(true);
    } else {
      openMoveToList?.();
    }
  };

  const handleMoveAlertAccept = () => {
    setShowMoveAlert(false);
    openMoveToList?.();
  };

  return isCardInAnotherList ? (
    <>
      {isUserInList && (
        <>
          <StyledMoveWarning>
            You’ve already added this pick to another list, to contribute a thought to this list you need to move the
            pick to this list.
          </StyledMoveWarning>
          <StyledItem>
            <Button text={moveToCurrentListText} Icon={ToListIcon} handler={openMoveToCurrentList} hasStroke />
          </StyledItem>
        </>
      )}
      <StyledItem>
        <Button
          text={`View in '${parentListTitle ?? ALL_PICKS}'`}
          Icon={ExistingListIcon}
          handler={handleViewInMyList}
        />
      </StyledItem>
    </>
  ) : (
    <>
      <StyledItem>
        <Button text={toListText} Icon={ToListIcon} handler={handleMoveToListBtn} hasStroke />
      </StyledItem>
      {showMoveAlertModal && (
        <ConfirmationModal
          buttonText="Move"
          handleAccept={handleMoveAlertAccept}
          handleCancel={() => setShowMoveAlert(false)}
          titleText="Move pick"
          subTitle="Other users have picked this, only your add and thought will be moved."
          isWarning={false}
        />
      )}
    </>
  );
};

export default MoveCardButtons;
