import React, { FC, Fragment, useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import AvatarDouble from 'components/UI/AvatarDouble';
import useTheme from 'helpers/useTheme';
import Avatar from 'components/UI/Avatar';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';

import { useGetGroupChatMembers, useTypingTitle } from 'graphQL/messages/hooks';
import { GroupChatSchema, UserShortSchema } from 'constants/graphqlTypes';
import useModalNavigation from 'helpers/useModalNavigation';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { ROUTE_MESSAGES, ROUTE_MESSAGES_SETTINGS } from 'routes';
import { StyledUsername as StyledFullname } from 'components/Sparks/Views/Card/styled';
import { useGroupChatTitle } from '../helpers/hooks';
import { StyledLink, StyledMore, StyledTypingName, StyledTypingTitle, StyledUsername } from './styled';
import { StyledTitleWrap } from '../List/styled';
import { getChatMembers } from '../ChatSettings/helpers';

const GroupChatMembers: FC<{ chat: GroupChatSchema; typingIds: string[]; error?: ApolloError }> = ({
  chat,
  typingIds,
  error,
}) => {
  const [showVerification, setShowVerification] = useState(false);
  const { id, title: chatTitle } = chat;
  const { title, users, moreNumber } = useGroupChatTitle(chat);
  const { members } = useGetGroupChatMembers(id);
  useEffect(() => {
    if (typingIds.length === 1) {
      const chatMembers =
        getChatMembers(members)?.reduce((acc: UserShortSchema[], member) => (member ? acc.concat(member) : acc), []) ??
        [];
      const isUserVerified = chatMembers.find((user) => user.id === typingIds[0])?.isVerified;

      setShowVerification(!!isUserVerified);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typingIds]);

  const { typingTitle, typingName } = useTypingTitle(typingIds, getChatMembers(members));
  const { push } = useHistory();
  const redirectToMessageList = () => push(ROUTE_MESSAGES);

  const isDark = useTheme();
  const link = useModalNavigation(ROUTE_MESSAGES_SETTINGS, false, { chatId: id });

  return users ? (
    <>
      <StyledLink onClick={() => push(link)}>
        {users.length > 1 ? (
          <AvatarDouble dimension={20} users={[users[1], users[0]]} />
        ) : (
          <Avatar dimension={28} user={users[0]} />
        )}
        <StyledTitleWrap hideGap>
          <StyledUsername isDark={isDark} isTyping={!!typingTitle || showVerification}>
            {typingTitle ? (
              <>
                <StyledTypingName>{typingName}</StyledTypingName>
                {showVerification && <VerificationBadge />}
                <StyledTypingTitle>&nbsp;{typingTitle}</StyledTypingTitle>
              </>
            ) : (
              <>
                {chatTitle ? (
                  <StyledFullname>{title}</StyledFullname>
                ) : (
                  users.map((user, index) => (
                    <Fragment key={user.username}>
                      <StyledFullname>
                        {!!index && ', '}
                        {generateFullName(user).trim()}
                      </StyledFullname>
                      {user.isVerified && <VerificationBadge />}
                    </Fragment>
                  ))
                )}
              </>
            )}
          </StyledUsername>
          {moreNumber > 0 && !typingTitle && !chatTitle && (
            <StyledMore isDark={isDark}>&nbsp;{`and ${moreNumber} more`}</StyledMore>
          )}
        </StyledTitleWrap>
      </StyledLink>
      {chat && error && (
        <ConfirmationModal
          titleText="Inactive group"
          subTitle="All other members have left this thread and it will be deleted."
          buttonText="Okay"
          handleAccept={redirectToMessageList}
          handleClickOutside={redirectToMessageList}
          isWarning={false}
        />
      )}
    </>
  ) : null;
};

export default GroupChatMembers;
