import { styled } from 'linaria/react';
import { StyledUserText } from 'components/Sparks/Views/Card/styled';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div<{ isRight: boolean }>`
  margin-left: ${({ isRight }) => (isRight ? 'auto' : '0px')};
  width: 250px;
`;

export const StyledRelWrap = styled.div`
  position: relative;
`;

export const StyledSparkContainer = styled.div<{ bgImage: string }>`
  background: ${({ bgImage }) => `url(${bgImage})`};
  border-radius: 10px;
  color: ${COLORS.white[100]};
  overflow: hidden;
  padding: 32px 16px;
  position: relative;
`;

export const StyledInfo = styled.div`
  position: relative;
`;

export const StyledAvatarWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAuthorText = styled(StyledUserText)`
  border: none;
  padding: 16px 0 0;
`;

export const StyledSparkTitleWrap = styled.div`
  align-items: center;
  display: flex;
  font: 400 22px/130% ${FONTS.primary};
  justify-content: center;
  padding-top: 8px;
  text-align: center;
  width: 100%;
`;

export const StyledResponseCounter = styled.div`
  align-items: center;
  display: flex;
  font: 400 14px/140% ${FONTS.secondary};
  justify-content: center;
  padding-top: 16px;
`;
