import React, { FC } from 'react';

import { SEARCH_FILTER_BTNS } from '../helpers/constants';
import { StyledFilterBtns } from '../FilterBtn/styled';
import FilterBtn from '../FilterBtn';

interface FilterBtnsProps {
  onSetValue: (value: string) => void;
}

const FilterBtns: FC<FilterBtnsProps> = ({ onSetValue }) => (
  <StyledFilterBtns>
    {SEARCH_FILTER_BTNS.map(({ icon, title, placeholder }) => (
      <FilterBtn key={title} Icon={icon} title={title} onSetValue={() => onSetValue(`${placeholder}`)} />
    ))}
  </StyledFilterBtns>
);

export default FilterBtns;
