import React, { FC } from 'react';

import useTheme from 'helpers/useTheme';
import Avatars from 'components/UI/Avatars';

import { CardSchema, UserObjectType } from 'constants/graphqlTypes';
import { StyledLabel, StyledText, StyledWrap } from './styled';

interface ICollaboratorsProps {
  item: CardSchema;
  isSmall?: boolean;
}

const Collaborators: FC<ICollaboratorsProps> = ({ item, isSmall }) => {
  const { hasNewCollaboratorsCards, creators } = item;
  const { totalCount, items } = creators ?? {};

  const isDark = useTheme();
  const total = totalCount ?? 0;
  const users =
    items?.reduce((acc: UserObjectType[], creator) => (creator?.user ? acc.concat(creator.user) : acc), []) ?? [];

  return (
    <StyledWrap isDark={isDark} isSmall={isSmall} className="collaborators">
      {!isSmall && <StyledText hasIndicator={!!hasNewCollaboratorsCards}>{total} collaborators</StyledText>}
      <Avatars limit={3} users={users} hideNumber total={total} />
      {isSmall && <StyledLabel hasIndicator={!!hasNewCollaboratorsCards} />}
    </StyledWrap>
  );
};

export default Collaborators;
