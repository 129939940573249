import { CardSchema, ColorSchema } from 'constants/graphqlTypes';
import { createStyleItems } from 'components/AddContent/helpers/helpers';
import { ECurrentMode } from './constants';
import { IGetModalDoneButton, IState } from './models';

const { Default } = ECurrentMode;

export const getModalDoneButton = ({ mode, listTitle }: IState): IGetModalDoneButton => {
  if (mode === Default) {
    return {
      text: listTitle || 'All picks',
      isBtnTransparent: true,
    };
  }

  return {
    text: 'Done',
    isBtnTransparent: false,
  };
};

export const getCardColors = (card: CardSchema, activeStyleOptionId: number): ColorSchema[] | undefined => {
  const { linkImageSets } = card ?? {};
  const showImages = linkImageSets?.items?.some(({ images }) => images?.[0]?.url);
  const items = createStyleItems([
    ...(showImages && linkImageSets?.items ? linkImageSets.items : []),
    ...(linkImageSets?.items[0]?.colors?.sourceGradient?.items ?? []),
  ]);

  const currentItem = items.find(({ id }) => id === activeStyleOptionId);
  return currentItem?.colors;
};
