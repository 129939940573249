import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';

import { ReactComponent as AppleIcon } from 'assets/icons/Apple.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/Google.svg';

import config from 'constants/config';
import Modal from 'components/Modal';
import { ROUTE_PRIVACY, ROUTE_SIGNIN, ROUTE_TERMS } from 'routes';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { OauthTokenTypes, useAuthTokenLazyQuery } from 'constants/graphqlTypes';
import { useProceedLogin } from 'graphQL/auth/hooks';
import { usePostHogCapture } from 'helpers/posthogHooks';

import Button from 'components/UI/Button';
import { EUiButtonType } from 'components/UI/Button/constants';
import {
  TEST_LOGIN_SHEET_APPLE_BTN,
  TEST_LOGIN_SHEET_EMAIL_BTN,
  TEST_LOGIN_SHEET_GOOGLE_BTN,
} from 'constants/aqa/login';
import { StyledButtonWrap, StyledFooter, StyledGoogleLink, StyledTitle } from '../styled';
import { StyledSocialButton } from './styled';

const AuthStart: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const { push } = useHistory();
  const onCompleted = useProceedLogin();
  const posthogCapture = usePostHogCapture();

  const [socialAuth] = useAuthTokenLazyQuery({
    onCompleted: ({ authToken }) => {
      onCompleted({ authToken });
      posthogCapture(authToken?.isNewUser ? POSTHOG_EVENTS.SignUpGoogle : POSTHOG_EVENTS.LoginGoogle);
      posthogCapture(authToken?.isNewUser ? POSTHOG_EVENTS.SignUp : POSTHOG_EVENTS.Login);
    },
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      socialAuth({ variables: { token: tokenResponse.access_token, tokenType: OauthTokenTypes.Google } });
    },
  });

  const handleMagicLink = () => {
    handleClose();
    push(ROUTE_SIGNIN);
  };

  return (
    <Modal handleClose={handleClose}>
      <StyledTitle>Sign up or log in</StyledTitle>
      <StyledButtonWrap>
        <Button dataTestId={TEST_LOGIN_SHEET_EMAIL_BTN} handler={handleMagicLink} isDark text="Continue with email" />
        <AppleLogin
          clientId={config.appleClientId}
          redirectURI={config.appleRedirectUrl}
          responseType="code id_token"
          scope="email name"
          responseMode="form_post"
          render={(renderProps) => (
            <StyledSocialButton
              data-testid={TEST_LOGIN_SHEET_APPLE_BTN}
              buttonType={EUiButtonType.Emphasized}
              isDark
              onClick={renderProps.onClick}
            >
              <AppleIcon />
              Continue with Apple
            </StyledSocialButton>
          )}
        />
        <StyledGoogleLink>
          <StyledSocialButton
            data-testid={TEST_LOGIN_SHEET_GOOGLE_BTN}
            buttonType={EUiButtonType.Emphasized}
            isDark
            onClick={() => googleLogin()}
          >
            <GoogleIcon />
            Continue with Google
          </StyledSocialButton>
        </StyledGoogleLink>
      </StyledButtonWrap>
      <StyledFooter>
        By registering you agree with our <a href={ROUTE_TERMS}>Terms of Service</a> and{' '}
        <a href={ROUTE_PRIVACY}>Privacy policy</a>
      </StyledFooter>
    </Modal>
  );
};

export default AuthStart;
