import {
  CardSchema,
  CardTypeOptions,
  useGetAllGuestListSeriesQuery,
  useGetGuestListHeaderQuery,
  useGetGuestListQuery,
  useGetGuestListSectionsQuery,
  useGetGuestListSeriesCardQuery,
  useGetGuestListSeriesQuery,
} from 'constants/graphqlTypes';
import { SHOW_FETCH_MORE_LOADER } from 'constants/common';
import { IUseAllGuestListSeries, IUseGuestList, IUseGuestListSections, IUseGuestListSeries } from './models';

export const useGuestListHeader = (
  id: string,
  type: CardTypeOptions,
  shouldSkip?: boolean
): { card: CardSchema | undefined; loading: boolean } => {
  const { data, loading } = useGetGuestListHeaderQuery({ variables: { id, type }, skip: shouldSkip });

  return { card: data?.allCards?.items[0], loading };
};

export const useGuestListSections = (id: string): IUseGuestListSections => {
  const { data, loading, fetchMore } = useGetGuestListSectionsQuery({
    variables: { id, referrerPickId: id },
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.guestListSections ?? {};

  const loadMore = () => {
    if (items && totalCount && items.length < totalCount) {
      fetchMore({
        variables: {
          offset: items.length,
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          if (!previousQueryResult.guestListSections?.totalCount) {
            return previousQueryResult;
          }

          return {
            ...previousQueryResult,
            guestListSections: {
              ...previousQueryResult.guestListSections,
              items: [
                ...(previousQueryResult.guestListSections.items ?? []),
                ...(fetchMoreResult.guestListSections?.items ?? []),
              ],
            },
          };
        },
      });
    }
  };

  return { sections: data?.guestListSections?.items ?? [], loading, loadMore };
};

export const useAllGuestListSeries = (): IUseAllGuestListSeries => {
  const { data, loading } = useGetAllGuestListSeriesQuery();

  return { allSeries: data?.allGuestListSeries?.seriesItems ?? [], loading };
};

export const useGuestListSeries = (id: string): IUseGuestListSeries => {
  const { data, loading } = useGetGuestListSeriesQuery({ variables: { id } });

  return { cards: data?.allGuestListSeries?.seriesItems?.[0].guestListItems ?? [], loading };
};

export const useGuestList = (id: string, type: CardTypeOptions, shouldSkip?: boolean): IUseGuestList => {
  const { data, loading } = useGetGuestListQuery({ variables: { id, type }, skip: shouldSkip });

  return { card: data?.allCards?.items[0], loading };
};

export const useGuestListSeriesCard = (
  id?: string
): { guestListSeriesItems?: CardSchema[] | null; card?: CardSchema | null; loading: boolean } => {
  const { data, loading } = useGetGuestListSeriesCardQuery({ variables: { id }, skip: !id });

  return {
    guestListSeriesItems: data?.allGuestListSeries?.seriesItems[0].guestListItems,
    card: data?.allGuestListSeries?.seriesItems?.[0].guestListSeriesCard,
    loading,
  };
};
