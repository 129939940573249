import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import Grain from 'components/UI/Grain';
import Spotlights from 'components/Spotlights';
import LoaderContent from 'components/UI/LoaderContent';
import useTheme from 'helpers/useTheme';
import getStyles from 'helpers/getStyles';

import { UserObjectType } from 'constants/graphqlTypes';
import { useGetSpotlights } from 'graphQL/spotlights/hook';
import { COLORS } from 'styles/constants';
import { useNavigationRef } from 'components/Navigation/helpers/hooks';
import { useProfileContext } from 'helpers/useProfileProvider';
import { StyledSection } from './styled';

import Info from './Info';

interface IProfileTopProps {
  userData?: UserObjectType;
  loading: boolean;
  setHideSpotlights: Dispatch<SetStateAction<boolean>>;
  hideSpotlights: boolean;
}

const ProfileTop: FC<IProfileTopProps> = ({ userData, loading, setHideSpotlights, hideSpotlights }) => {
  const [index, setIndex] = useState(0);
  const [hiddenCards, setHiddenCards] = useState<string[]>([]);
  const [hideGradient, setHideGradient] = useState(true);

  const spotlightsData = useGetSpotlights();
  const isDark = useTheme();

  const { cards, loading: spotlightsLoading } = spotlightsData;
  const { gradientStart, gradientEnd } = getStyles(cards[index]?.cardStyle);
  const { setGradientStart, setGradientEnd } = useProfileContext();

  const isCurrentSpotlightHidden = hiddenCards.includes(cards[index]?.id);

  useEffect(() => {
    if (gradientEnd && gradientStart) {
      setGradientEnd?.(gradientEnd);
      setGradientStart?.(gradientStart);
    } else {
      setGradientEnd?.(null);
      setGradientStart?.(null);
    }
  }, [gradientStart, gradientEnd]);

  const fontColor = !hideGradient || isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  const spotlightsChangeKey = JSON.stringify(cards.map(({ id }) => id));
  const ref = useNavigationRef(fontColor);

  useEffect(() => {
    setIndex(0);
  }, [spotlightsChangeKey]);

  useEffect(() => {
    setHiddenCards(cards.filter(({ isHidden }) => !!isHidden).map(({ id }) => id));
  }, [cards, cards.length]);

  useEffect(() => {
    setHideSpotlights(spotlightsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotlightsLoading]);

  useEffect(() => {
    setHideGradient(!cards.length || isCurrentSpotlightHidden || spotlightsLoading || loading);
  }, [isCurrentSpotlightHidden, cards.length, spotlightsLoading, loading]);

  return (
    <StyledSection
      ref={cards.length ? ref : null}
      fontColor={fontColor}
      fixedHeight={!!cards.length}
      gradientStart={!hideGradient ? gradientStart : undefined}
      gradientEnd={!hideGradient ? gradientEnd : undefined}
      isDark={isDark}
    >
      {userData && !hideGradient && <Grain />}
      {loading ? (
        <LoaderContent isCentered isDark={isDark} isFullScreen />
      ) : (
        <Info userData={userData} fontColor={fontColor} noContent={!cards.length} hideGradient={hideGradient} />
      )}
      {!hideSpotlights && (
        <Spotlights
          hiddenCards={hiddenCards}
          setHiddenCards={setHiddenCards}
          spotlightsData={spotlightsData}
          fontColor={fontColor}
          setIndex={setIndex}
          key={spotlightsChangeKey}
        />
      )}
    </StyledSection>
  );
};

export default ProfileTop;
