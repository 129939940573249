import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';

import config from 'constants/config';
import { EPageTypes, POSTHOG_EVENTS } from 'constants/posthogEvents';
import getAuthUser from 'helpers/getAuthUser';
import { getCachedUser } from 'graphQL/follows/helpers';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';

export type PostHogCaptureType = [string, { description: string }];

export type PostHogCaptureParams = {
  following_id?: string;
  key?: string;
  keyword?: string;
  media_tag?: string | null;
  navigate_from?: EPageTypes;
  referrer?: string;
  source?: string | null;
  tag?: string;
  username?: string;
  screen?: string;
  isOwnProfilePage?: boolean;
};

export const usePostHogCapture = () => {
  const postHog = usePostHog();
  const client = useApolloClient();
  const { userId, username } = getAuthUser();

  const user = getCachedUser(client.cache, userId);

  return (key: PostHogCaptureType, params?: PostHogCaptureParams) => {
    const [name, description] = key;

    if (config.postHogKey && userId !== postHog?.get_distinct_id()) {
      postHog?.alias(postHog?.get_distinct_id(), userId);
    }

    postHog?.capture(name, {
      ...description,
      ...params,
      // $set wad added for identify user, with new version of posthog-js it doesn't work
      ...(userId &&
        user && {
          $set: {
            id: userId,
            name: generateFullName(user),
            bio: user?.userInfo?.bio,
            username,
          },
        }),
    });
  };
};

export const usePostHogIdentify = (): void => {
  const postHogCapture = usePostHogCapture();
  const postHog = usePostHog();
  const { userId, username } = getAuthUser();

  const [appStarted, setAppStarted] = useState(false);

  useEffect(() => {
    if (config.postHogKey && userId) {
      postHog?.identify(userId, {
        id: userId,
        username,
      });

      if (!appStarted) {
        postHogCapture(POSTHOG_EVENTS.AppStart);
        setAppStarted(true);
      }
    }
    return () => {
      if (config.postHogKey) {
        postHog?.reset();
      }
    };
  }, [postHog, userId]);
};

export const useFeaturedFlagEnabled = (flag: string): boolean => {
  const { username } = getAuthUser();
  const postHog = usePostHog();

  usePostHogIdentify();
  postHog?.setPersonPropertiesForFlags({ username }, false);
  const flagEnabled = useFeatureFlagEnabled(flag);

  return !config.postHogKey || !!flagEnabled;
};
