import React, { FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Modal from 'components/Modal';
import { useSingleChat } from 'graphQL/messages/hooks';
import { Location } from 'history';

import GroupChatSettings from './GroupChatSettings';
import SingleChatSettings from './SingleChatSettings';
import { StyledSectionWrap } from './styled';

const ChatSettings: FC = () => {
  const { replace, goBack } = useHistory();
  const { state } = useLocation<{ backgroundModal: Location<undefined>; variable: { chatId: string } }>();
  const { chatId } = state.variable;

  const handleClose = () => {
    if (!state?.backgroundModal || !state?.backgroundModal.pathname) {
      return goBack();
    }
    return replace(state?.backgroundModal?.pathname);
  };

  const chat = useSingleChat(chatId);

  return chat ? (
    <Modal handleClose={handleClose}>
      <StyledSectionWrap>
        {chat?.__typename === 'GroupChatSchema' && <GroupChatSettings chat={chat} />}
        {chat.__typename === 'SingleChatSchema' && <SingleChatSettings chat={chat} />}
      </StyledSectionWrap>
    </Modal>
  ) : null;
};

export default ChatSettings;
