import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_DELETE_PICKS } from 'routes';

import useTheme from 'helpers/useTheme';
import { useAdminCollaborativeLists } from 'graphQL/profile/hooks';
import { ICollaborativeListItem } from 'graphQL/profile/models';
import { CollectionUserType, UserObjectType } from 'constants/graphqlTypes';

import { StyledItem } from 'components/AddContent/Menu/styled';
import Button from 'components/UI/Button';
import { EUiButtonType } from 'components/UI/Button/constants';
import Layout from 'components/Onboarding/Layout';
import BadgeWithChildren from 'components/UI/BadgeWithChildren/BadgeWithChidlren';

import { StyledListTitle, StyledItemWrap, StyledScrollableList } from './styled';
import CollaboratorsListModal from './CollaboratorsListModal';

const CollaborativeAdminLists: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedList, setSelectedList] = useState({ listId: '', listTitle: '' });
  const [collaborativeLists, setCollaborativeLists] = useState<ICollaborativeListItem[]>();
  const [newAdmins, setNewAdmins] = useState<CollectionUserType[]>([]);
  const isDark = useTheme();
  const { state } = useLocation<{ pagesToMenu: number }>();
  const { pagesToMenu } = state;
  const { push, go } = useHistory();

  const { lists, loading } = useAdminCollaborativeLists();

  useEffect(() => {
    setCollaborativeLists(lists);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists.length]);

  const setNewAdminHelper = (collaboratorId: string, selectedListId: string) => {
    setNewAdmins((admins) =>
      admins.find(({ userId, collectionId }) => userId === collaboratorId && collectionId === selectedListId)
        ? admins
        : [...admins, { userId: collaboratorId ?? '', collectionId: selectedListId }]
    );
  };

  const handleUpdateAdmin = (collaborator: UserObjectType) => {
    setCollaborativeLists((adminLists) =>
      adminLists?.map((list) => {
        if (list.id === selectedList.listId && list.newAdmin && list.newAdmin.id !== collaborator.id) {
          setNewAdminHelper(collaborator.id, selectedList.listId);
          return { ...list, newAdmin: collaborator };
        }

        return list;
      })
    );
  };

  return (
    <>
      <Layout
        heading="Assign new admins"
        text="The following list(s) need at least one other admin. We've automatically assigned someone, but you're welcome to edit this."
        primaryBtnText="Continue"
        secondaryBtnText="Cancel"
        isPrimaryBtnDisabled={loading}
        isSecondaryBtnTransparent
        isWideContent
        handlePrimaryBtn={() =>
          push({ pathname: ROUTE_DELETE_PICKS, state: { newAdmins, pagesToMenu: pagesToMenu - 1 } })
        }
        handleSecondaryBtn={() => go(pagesToMenu)}
        blockScroll={false}
      >
        <StyledScrollableList isDark={isDark}>
          {collaborativeLists?.map(({ id, cardsTotalCount, title, newAdmin }) => (
            <StyledItem key={id}>
              <StyledItemWrap>
                <StyledListTitle isDark={isDark} total={cardsTotalCount} isExplore>
                  {title}
                </StyledListTitle>
                {newAdmin && (
                  <BadgeWithChildren user={newAdmin ?? {}} isDark={isDark}>
                    <Button
                      isDark={isDark}
                      type={EUiButtonType.Primary}
                      text="Edit"
                      handler={() => {
                        setSelectedList({ listId: id, listTitle: title ?? '' });
                        setIsOpen(true);
                      }}
                    />
                  </BadgeWithChildren>
                )}
              </StyledItemWrap>
            </StyledItem>
          ))}
        </StyledScrollableList>
      </Layout>
      {isOpen && selectedList && (
        <CollaboratorsListModal
          list={selectedList}
          handleClose={() => setIsOpen(false)}
          handleSelect={handleUpdateAdmin}
        />
      )}
    </>
  );
};

export default CollaborativeAdminLists;
