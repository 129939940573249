// LETTER_ANIMATION_TIME used for the animation of the letters to become full filled + animation of word elevation.
export const LETTER_ANIMATION_TIME = 60; // ms

// LETTER_VISIBLE_ANIMATION_TIME used for the animation of the letters to become visible.
export const LETTER_VISIBLE_ANIMATION_TIME = 60; // ms

export const FONT_SIZE = 20; // px
export const LINE_HEIGHT = FONT_SIZE * 1.4; // px
export const VISIBLE_LETTER_OPACITY = 0.15;
export const FILLED_LETTER_OPACITY = 1;

/**
 * Generates a unique ID for the truncate wrap element.
 * @param id - The original uuid.
 * @returns The generated ID.
 */
export const getTruncateWrapId = (id: string) => `id-${id}`;

export const TRUNCATE_INNER_ID = 'truncate-inner';

export const CHAR_CLASSNAME = 'char';

export const ELLIPSIS_CLASSNAME = 'ellipsis';

export const DATA_WORD_INDEX_ATTR = 'data-word-index';

export const VISIBILITY_OPACITY_DECREASE_FACTOR = 0.5;
export const FILLED_OPACITY_DECREASE_FACTOR = 0.5;
