import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ReactComponent as DashedLine } from 'assets/icons/onboardingv2/DashedLineRx10.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';

import { StyledInfoBlock, StyledInfoBlockWrap } from './styled';
import { IInfoBlockProps } from './models';

const InfoBlock: FC<IInfoBlockProps> = ({
  params: { title, subtitle, linkText, route, isCompleted },
  isDark,
  hasNoCustomBg,
  isLast,
}) => {
  const { push } = useHistory();
  return (
    <StyledInfoBlockWrap isDark={isDark} isLast={isLast}>
      <p>{title}</p>
      <StyledInfoBlock
        onClick={() => push(route)}
        hasNoCustomBg={hasNoCustomBg}
        isCompleted={isCompleted}
        isDark={isDark}
        isLast={isLast}
      >
        <DashedLine className="dashed-line" viewBox="0 0 342 112" />
        <div>
          <p>{subtitle}</p>
          <Link to={route}>{linkText}</Link>
        </div>
        {isCompleted && <CheckIcon className="check-icon" />}
      </StyledInfoBlock>
    </StyledInfoBlockWrap>
  );
};

export default InfoBlock;
