import { styled } from 'linaria/react';

export const StyledList = styled.ul<{ bg: string }>`
  align-content: center;
  align-items: center;
  background: ${({ bg }) => bg};
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  height: 44px;
  gap: 2px;
  justify-content: center;
  list-style: none;
  width: 44px;
`;

export const StyledListItem = styled.li<{ image: string; isSingle?: boolean }>`
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  border-radius: ${({ isSingle }) => (isSingle ? 2 : 1)}px;
  height: ${({ isSingle }) => (isSingle ? 30 : 14)}px;
  width: ${({ isSingle }) => (isSingle ? 30 : 14)}px;
`;
