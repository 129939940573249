import React, { FC, Fragment } from 'react';
import { Waypoint } from 'react-waypoint';

import { useGetProfileTopics, useUpdateProfileTopic } from 'graphQL/profile/topics/hooks';
import SinglePageLayout from 'components/SinglePageLayout';
import ListItem from 'components/UI/ListItem';
import { EToggleType } from 'components/UI/Toggle/models';
import { EQueryFetchPolicy } from 'constants/common';
import { TEST_ADJUST_TOPICS_ITEM, TEST_ID_ADJUST_TOPICS_START } from 'constants/aqa/settings/accountManagement';
import { generateTopicTitle } from 'constants/aqa/signup';

import { COLORS } from 'styles/constants';

import useTheme from 'helpers/useTheme';

import { StyledList } from './styled';

const AdjustTopic: FC = () => {
  const { topics, loadMoreTopics, isLoading } = useGetProfileTopics(EQueryFetchPolicy.CacheAndNetwork);
  const { updateStatus } = useUpdateProfileTopic();
  const isDark = useTheme();

  return (
    <SinglePageLayout
      pageTestId={TEST_ID_ADJUST_TOPICS_START}
      title="Adjust the topics that help inform the content you see in the Featured tab"
      subTitle="These are taken from the picks you share and the topics you chose when you joined guide:human."
    >
      <StyledList>
        {topics.map(({ id, name, imageSet, isPreferredEnable }) => (
          <Fragment key={id}>
            <ListItem
              title={name ?? ''}
              image={imageSet?.images?.[0]?.url ?? ''}
              isEnable={!!isPreferredEnable}
              isDark={isDark}
              bgColor={COLORS.gray[100]}
              hasToggle
              handleClick={() => updateStatus(!isPreferredEnable, id, name)}
              toggleType={EToggleType.Default}
              dataTestId={`${TEST_ADJUST_TOPICS_ITEM}-${generateTopicTitle(name || '')}`}
            />
          </Fragment>
        ))}
      </StyledList>
      {!isLoading && <Waypoint onEnter={loadMoreTopics} />}
    </SinglePageLayout>
  );
};

export default AdjustTopic;
