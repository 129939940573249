import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import { COLORS, FONTS } from '../../styles/constants';

export const StyledText = styled.div`
  margin: 32px 0;
`;

export const StyledTextWrapper = styled.div`
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 1.1;
  color: ${COLORS.white[100]};
`;

export const StyledHeading = styled.p`
  font-size: 22px;
  font-weight: 500;
  margin: 32px 0 16px;
`;

export const StylesSubHeating = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

export const StyledContent = styled.p`
  font-family: ${FONTS.secondary};
  font-size: 14px;
`;

export const StyledLink = styled.a`
  color: ${COLORS.white[100]};
  margin-left: 5px;
`;

export const StyledList = styled.ul`
  list-style-type: disc;
  margin-left: 15px;

  li {
    margin-bottom: 5px;
  }

  ul {
    list-style-type: circle;
    margin: 5px 0 5px 20px;
  }
`;

export const LegalLink = styled(Link)`
  color: ${COLORS.white[100]};
`;
