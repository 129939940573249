import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { EMPTY_ITEMS, EQueryFetchPolicy, SHOW_FETCH_MORE_LOADER } from 'constants/common';
import {
  GetFeedTopicsDataDocument,
  useGetFeedTopicsDataQuery,
  useGetFeedUsersDataQuery,
  useGetHeadTagsQuery,
  useUpdateFeedNameMutation,
  useUpdateFeedMutation,
  useCreateFeedMutation,
  useDeleteItemMutation,
  useGetMediaTagsFiltersQuery,
  MediaTagSchema,
  useGetCardsFromFeedFiltersQuery,
  useAdjustFeedFiltersMutation,
  GetMediaTagsFiltersDocument,
  GetFeedUsersDataDocument,
  useCheckExistingUserQuery,
  UserFilter,
  CardTypeOptions,
  GetChannelSectionItemsDocument,
  GetChannelSectionItemsQuery,
  useGetFeedCardsQuery,
  GetFeedCardsDocument,
  useGetFeedItemsIdQuery,
  useGetChannelSectionItemQuery,
  EViewTypeOptions,
  useUpdateFeedViewMutation,
  ChannelSectionItemsSortTypeOptions,
} from 'constants/graphqlTypes';
import { SECTION_ITEMS_LIMIT } from 'components/CustomFeeds/constants';
import { IGetTopicsResult } from 'graphQL/profile/topics/models';
import { useAdminFeedFiltersContext } from 'components/CustomFeeds/FilterFeedModal/hooks';
import useToast from 'helpers/useToast';
import { ROUTE_DISCOVERY } from 'routes';
import { EExploreTabs } from 'components/Explore/ExploreTabs/helpers/constants';
import useDiscoveryTab from 'helpers/useDiscoveryTab';
import { deleteSectionFeedFromCache } from './helpers';
import { IFeedModel } from './models';

export const useGetFeedCards = (feedId: string) => {
  const { newAdminFeedFilters } = useAdminFeedFiltersContext();
  const { included, excluded } = newAdminFeedFilters?.find((filter) => filter.feedId === feedId) ?? {};
  const { data, fetchMore, loading } = useGetFeedCardsQuery({
    variables: {
      feedId,
      includedFilters: included ?? [],
      excludedFilters: excluded ?? [],
      offset: 0,
    },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });
  const { items: cards, totalCount } = data?.allCards?.items?.[0].cardsFromFeed ?? {};
  const { totalCount: filtersTotalCount } = data?.allCards?.items?.[0].feedFilters ?? EMPTY_ITEMS;
  const loadMore = () =>
    fetchMore({
      variables: { offset: cards?.length },
      updateQuery: (previousQueryResult, { fetchMoreResult }) =>
        fetchMoreResult && previousQueryResult.allCards?.items?.[0]?.cardsFromFeed?.items
          ? {
              ...previousQueryResult,
              allCards: {
                ...previousQueryResult.allCards,
                items: [
                  {
                    ...previousQueryResult.allCards?.items[0],
                    cardsFromFeed: {
                      ...previousQueryResult.allCards?.items?.[0]?.cardsFromFeed,
                      items: [
                        ...previousQueryResult.allCards?.items?.[0]?.cardsFromFeed?.items,
                        ...(fetchMoreResult.allCards?.items?.[0]?.cardsFromFeed?.items ?? []),
                      ],
                    },
                  },
                ],
              },
            }
          : previousQueryResult,
    });
  return { cards: cards ?? [], totalCount, loadMore, loading, filtersTotalCount };
};

export const useGetHeadTopics = (search?: string, feedId?: string): IGetTopicsResult => {
  const [isQueryCalled, setIsQueryCalled] = useState(false);
  const { data, fetchMore, loading } = useGetHeadTagsQuery({
    variables: { search, feedId },
    onCompleted: () => setIsQueryCalled(false),
    fetchPolicy: EQueryFetchPolicy.NetworkOnly,
    skip: search?.length === 0,
  });

  const { items, totalCount } = data?.allTags ?? EMPTY_ITEMS;
  const loadMoreTopics = () => {
    if (totalCount && items && items.length < totalCount) {
      fetchMore({
        variables: {
          offset: items.length,
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) =>
          fetchMoreResult && previousQueryResult.allTags?.totalCount
            ? {
                ...previousQueryResult,
                allTags: {
                  ...previousQueryResult.allTags,
                  items: [...(previousQueryResult.allTags?.items ?? []), ...(fetchMoreResult.allTags?.items ?? [])],
                },
              }
            : previousQueryResult,
      });
    }
  };

  return {
    topics: items ?? [],
    loadMoreTopics,
    isLoading: loading,
    totalCount,
    isQueryCalled,
  };
};

export const useGetFeedUsersData = (
  feedId: string,
  params?: { limit?: number; filters?: UserFilter; skip?: boolean }
) => {
  const { data, loading } = useGetFeedUsersDataQuery({
    variables: { feedId, limit: params?.limit, filters: params?.filters },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    skip: params?.skip,
  });

  const { items, totalCount } = data?.allCards?.items?.[0]?.usersFromFeed ?? EMPTY_ITEMS;
  const isUserFeed = data?.allCards?.items?.[0]?.type !== CardTypeOptions.AdminFeed;

  return {
    items: items ?? [],
    totalCount,
    title: data?.allCards?.items?.[0]?.title ?? '',
    feedSearchParameters: data?.allCards?.items?.[0]?.feedSearchParameters ?? '',
    isUserFeed,
    loading,
  };
};

export const useGetFeedAllUsers = () => {
  const { data } = useCheckExistingUserQuery({
    variables: {
      filter: { isActive: true, isBanned: false, idNe: '00000000-0000-0000-0000-000000000000' },
    },
  });
  return data?.allUsers?.totalCount ?? 0;
};

export const useGetFeedTopicsData = (feedId: string, skip?: boolean) => {
  const { data, loading } = useGetFeedTopicsDataQuery({
    variables: { feedId, limit: 3 },
    skip: !!skip,
    fetchPolicy: EQueryFetchPolicy.NetworkOnly,
  });

  const { items, totalCount } = data?.allCards?.items?.[0]?.topicsFromFeed ?? EMPTY_ITEMS;

  return {
    topics: items ?? [],
    totalCount,
    loading,
  };
};

export const useUpdateFeed = ({
  feedId,
  title,
  isAllTopics,
  excludedTopics,
  includedTopics,
  isAllFollowings,
  isAllUsers,
  excludedUsers,
  includedUsers,
}: IFeedModel) => {
  const [updateFeed] = useUpdateFeedMutation({
    variables: {
      feedId,
      title,
      isAllTopics,
      excludedTopics,
      includedTopics,
      isAllFollowings,
      isAllUsers,
      excludedUsers,
      includedUsers,
    },
    refetchQueries: [
      { query: GetFeedTopicsDataDocument, variables: { feedId, limit: 3 } },
      { query: GetFeedUsersDataDocument, variables: { feedId } },
    ],
  });

  return { updateFeed };
};

export const useCreateFeedTopic = (
  title: string,
  isAllTopics: boolean,
  isAllFollowings: boolean,
  isAllUsers: boolean,
  excludedTopics?: string[],
  includedTopics?: string[],
  excludedUsers?: string[],
  includedUsers?: string[],
  sectionId?: string,
  sorting?: ChannelSectionItemsSortTypeOptions
) => {
  const [createFeedTopics] = useCreateFeedMutation({
    variables: {
      title: title.trim(),
      isAllTopics,
      isAllFollowings,
      isAllUsers,
      excludedTopics,
      includedTopics,
      excludedUsers,
      includedUsers,
    },
    update: (cache, { data }) => {
      const cachedFeeds: GetChannelSectionItemsQuery | null = cache.readQuery({
        query: GetChannelSectionItemsDocument,
        variables: { sectionId, limit: SECTION_ITEMS_LIMIT, ...(sorting && { sorting }) },
      });

      if (cachedFeeds) {
        cache.writeQuery({
          query: GetChannelSectionItemsDocument,
          variables: { sectionId, limit: SECTION_ITEMS_LIMIT, ...(sorting && { sorting }) },
          data: {
            channelSectionItemsV2: {
              ...cachedFeeds.channelSectionItemsV2,
              totalCount: cachedFeeds?.channelSectionItemsV2?.totalCount
                ? cachedFeeds?.channelSectionItemsV2?.totalCount + 1
                : 0,
              items: [data?.createFeedV2, ...(cachedFeeds?.channelSectionItemsV2?.items ?? [])],
            },
          },
        });
      }
    },
  });

  return { createFeedTopics };
};

export const useUpdateFeedName = (feedId: string, title: string, onCompleted: () => void) => {
  const [updateFeedName, { loading, error }] = useUpdateFeedNameMutation({
    variables: { feedId, title: title.trim() },
    onCompleted,
    refetchQueries: [{ query: GetFeedTopicsDataDocument, variables: { feedId } }],
  });

  return { updateFeedName, loading, error: error?.message };
};

export const useDeleteFeed = (feedId: string, sectionId?: string, sorting?: ChannelSectionItemsSortTypeOptions) => {
  const { setToast } = useToast();
  const { push } = useHistory();
  const { setDiscoveryTab } = useDiscoveryTab();

  const [deleteItem, { loading }] = useDeleteItemMutation({
    variables: { cardId: feedId },
    update: (cache) => sectionId && deleteSectionFeedFromCache(feedId, sectionId, cache, sorting),
    onCompleted: () => {
      if (!sectionId) {
        setDiscoveryTab?.(EExploreTabs.Featured);
        push(ROUTE_DISCOVERY);
      }
    },
    onError: (error) =>
      setToast({
        isToastOpen: true,
        toastItemName: error.message,
        toastError: true,
        linesCount: 3,
      }),
  });

  return { deleteFeed: deleteItem, loading };
};

export const useGetMediaTagsFilters = (
  feedId?: string,
  clearAll?: boolean
): {
  tags: MediaTagSchema[];
  totalCount: number;
  loadMoreTags: () => void;
  loading: boolean;
} => {
  const { data, fetchMore, loading } = useGetMediaTagsFiltersQuery({
    variables: { feedId },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const { items, totalCount } = data?.allMediaTags ?? EMPTY_ITEMS;
  const allowLoadMore = items.length < totalCount;

  const loadMoreTags = () => {
    if (allowLoadMore) {
      fetchMore({
        variables: { offset: items.length ?? 0 },
        updateQuery: (prev, { fetchMoreResult }) => {
          const prevTags = prev.allMediaTags?.items;
          const incomingTags = fetchMoreResult?.allMediaTags?.items;
          const prevTagsAfterClear = prevTags?.map((tag) => {
            return { ...tag, isSelectedInFeedFilters: false };
          });
          const incomingTagsAfterClear = incomingTags?.map((tag) => {
            return { ...tag, isSelectedInFeedFilters: false };
          });

          if (clearAll && prevTagsAfterClear && incomingTagsAfterClear && prev.allMediaTags?.items) {
            return {
              ...prev,
              allMediaTags: {
                ...prev.allMediaTags,
                items: [...prevTagsAfterClear, ...incomingTagsAfterClear],
              },
            };
          }
          if (!clearAll && prevTags && incomingTags && prev.allMediaTags?.items) {
            return {
              ...prev,
              allMediaTags: {
                ...prev.allMediaTags,
                items: [...prevTags, ...incomingTags],
              },
            };
          }
          return prev;
        },
      });
    }
  };

  return {
    tags: items ?? [],
    totalCount,
    loadMoreTags,
    loading,
  };
};

export const useGetCardsFromFeedFilters = (feedId: string, includedFilters: string[], excludedFilters: string[]) => {
  const { data, loading } = useGetCardsFromFeedFiltersQuery({
    variables: { feedId, includedFilters, excludedFilters },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const { totalCount } = data?.allCards?.items?.[0].cardsFromFeed ?? EMPTY_ITEMS;

  return {
    totalCount,
    loading,
  };
};

export const useGetChannelSectionItem = (feedId: string) => {
  const { data, loading } = useGetChannelSectionItemQuery({
    variables: { itemId: feedId },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  if (
    data?.channelSectionItem?.__typename === 'ChannelAdminFeedSchema' ||
    data?.channelSectionItem?.__typename === 'ChannelCustomFeedSchema'
  ) {
    const { bgColor, userCovers, item, detailCovers } = data.channelSectionItem;

    return { bgColor, userCovers, item, loading, detailCovers };
  }

  return null;
};

export const useAdjustFeedFilters = (
  feedId: string,
  addedMediaTagIds: string[],
  removedMediaTagIds: string[],
  clearAll: boolean,
  onCompleted: () => void
) => {
  const [adjustFeedFilters, { loading, error }] = useAdjustFeedFiltersMutation({
    variables: { feedId, clearAll, addedMediaTagIds, removedMediaTagIds },
    onCompleted,
    refetchQueries: [
      { query: GetMediaTagsFiltersDocument, variables: { feedId } },
      {
        query: GetFeedCardsDocument,
        variables: { feedId, includedFilters: addedMediaTagIds, excludedFilters: removedMediaTagIds, offset: 0 },
      },
    ],
  });

  return { adjustFeedFilters, loading, error: error?.message };
};

export const useGetFeedItemsId = (feedId: string) => {
  const { newAdminFeedFilters } = useAdminFeedFiltersContext();
  const { included, excluded } = newAdminFeedFilters?.find((filter) => filter.feedId === feedId) ?? {};
  const { data, fetchMore, loading } = useGetFeedItemsIdQuery({
    variables: {
      feedId,
      includedFilters: included ?? [],
      excludedFilters: excluded ?? [],
      offset: 0,
    },
    ...SHOW_FETCH_MORE_LOADER,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    skip: !feedId,
  });
  const { items, totalCount } = data?.allCards?.items?.[0].cardsFromFeed ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    !loading &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (previousQueryResult, { fetchMoreResult }) =>
        fetchMoreResult && previousQueryResult.allCards?.items?.[0]?.cardsFromFeed?.items
          ? {
              ...previousQueryResult,
              allCards: {
                ...previousQueryResult.allCards,
                items: [
                  {
                    ...previousQueryResult.allCards?.items[0],
                    cardsFromFeed: {
                      ...previousQueryResult.allCards?.items?.[0]?.cardsFromFeed,
                      items: [
                        ...previousQueryResult.allCards?.items?.[0]?.cardsFromFeed?.items,
                        ...(fetchMoreResult.allCards?.items?.[0]?.cardsFromFeed?.items ?? []),
                      ],
                    },
                  },
                ],
              },
            }
          : previousQueryResult,
    });

  const idItems = items.filter(({ id }) => id !== undefined).map(({ id }) => ({ id })) ?? [];
  return { items: idItems, totalCount, loadMore, loading };
};

export const useUpdateItemView = (feedId: string, viewType: EViewTypeOptions) => {
  const [updateItemViewMutation] = useUpdateFeedViewMutation({
    variables: { itemId: feedId, viewType },
  });

  return updateItemViewMutation;
};
