import React, { FC } from 'react';

import { DiscoverSectionSchema } from 'constants/graphqlTypes';
import { ROUTE_USER_PROFILE } from 'routes';
import UILink from 'components/UI/Link';
import Avatar from 'components/UI/Avatar';
import useTheme from 'helpers/useTheme';
import { StyledWrap, StyledTitle, StyledDescription, StyledAvatarsWrap } from './styled';

const EditorialSection: FC<{ section: DiscoverSectionSchema }> = ({ section }) => {
  const isDark = useTheme();

  return (
    <StyledWrap>
      <StyledTitle isDark={isDark}>{section?.title || 'A note from our editors'}</StyledTitle>
      <StyledDescription isDark={isDark}>{section?.annotation}</StyledDescription>
      <StyledAvatarsWrap>
        {section.editors?.map((user) => (
          <li key={user?.id}>
            <UILink route={ROUTE_USER_PROFILE} params={{ profileName: user?.username }}>
              <Avatar user={user} />
            </UILink>
          </li>
        ))}
      </StyledAvatarsWrap>
    </StyledWrap>
  );
};

export default EditorialSection;
