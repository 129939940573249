import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { useContentNotFound } from 'helpers/routingHelper';
import { useGetDetailQuestion } from 'graphQL/questions/hooks';
import useDocumentTitle from 'helpers/useDocumentTitle';
import useTheme from 'helpers/useTheme';

import LoaderContent from 'components/UI/LoaderContent';
import QuestionHeader from 'components/QuestionDetails/QuestionHeader';
import TopPicks from 'components/QuestionDetails/TopPicks/';
import OtherPicks from 'components/QuestionDetails/OtherPicks/OtherPicks';
import YourPicks from 'components/QuestionDetails/YourPicks/YourPicks';
import SeeMore from 'components/QuestionDetails/SeeMore';
import GuestSection from 'components/Explore/GuestSection/GuestSection';

import { EQueryFetchPolicy } from 'constants/common';
import { StyledSectionWrap, StyledWrap } from './styled';

const QuestionDetail: FC = () => {
  const posthogCapture = usePostHogCapture();

  const { questionId } = useParams<{ questionId?: string }>();
  const { goBack } = useHistory();
  const handlerContentNotFound = useContentNotFound();

  const { question, loading } = useGetDetailQuestion(questionId, EQueryFetchPolicy.CacheAndNetwork);

  const { id, title, createdAt, usersWhoAnswered, myAnswers, topAnswers, questionGuestList } = question ?? {};
  useDocumentTitle(title);

  if (!questionId) {
    goBack();
  }

  if (!question && !loading) {
    handlerContentNotFound();
  }

  const isDark = useTheme();

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.QotDOpen);
    posthogCapture(POSTHOG_EVENTS.SparkAction);
  }, []);

  return (
    <StyledWrap paddingTop={84}>
      {loading && <LoaderContent isFullScreen isDark={isDark} />}
      {question && !loading && (
        <>
          <QuestionHeader
            isDark={isDark}
            title={title ?? ''}
            usersWhoAnswered={usersWhoAnswered}
            createdAt={createdAt}
            questionGuestList={questionGuestList}
          />
          <YourPicks questionTitle={title} questionId={id} isDark={isDark} myAnswers={myAnswers} />
          <TopPicks isDark={isDark} topAnswers={topAnswers} />
          <OtherPicks questionId={questionId} />
          {questionGuestList ? (
            <StyledSectionWrap>
              <GuestSection
                title="The guest:list"
                description="Inspiring guests share their universe of passions"
                cards={[questionGuestList]}
              />
            </StyledSectionWrap>
          ) : (
            <SeeMore />
          )}
        </>
      )}
    </StyledWrap>
  );
};

export default QuestionDetail;
