import React, { FC } from 'react';

import { useGetSuggestedTopicUsers } from 'graphQL/cards/topicCards/hooks';
import Modal from 'components/Modal';
import UserList from 'components/UserList';
import { EButtonType } from 'components/UserList/constants';
import { getConnectionsUserFollowBtnId } from 'constants/aqa/profileConnections';
import { StyledWrap } from 'components/TabModal/styled';
import { StyledTitle } from './styled';

const TopicUsersModal: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const { items, loading, loadMore } = useGetSuggestedTopicUsers();

  return (
    <Modal handleClose={handleClose}>
      <StyledTitle>In this list</StyledTitle>
      <StyledWrap noHorizontalPadding>
        <UserList
          itemBtndataTestIdTemplate={getConnectionsUserFollowBtnId}
          buttonType={EButtonType.Follow}
          users={items}
          loading={loading}
          loadMore={loadMore}
        />
      </StyledWrap>
    </Modal>
  );
};

export default TopicUsersModal;
