import { styled } from 'linaria/react';

import { COLORS, FONTS } from '../../../styles/constants';

interface IStyledAvatarProps {
  dimension: number;
  src?: string | null;
  color: string;
  isGreenBorder?: boolean | null;
}

const StyledAvatar = styled.div<IStyledAvatarProps>`
  align-items: center;
  background-color: ${COLORS.brown.light[100]};
  background-image: ${({ src }) => (src ? `url('${src}')` : 'none')};
  background-position: center;
  background-size: cover;
  border: ${({ isGreenBorder }) => (isGreenBorder ? `2px solid ${COLORS.green.light}` : 'none')};
  border-radius: 50%;
  color: ${({ color }) => color};
  display: flex;
  font-family: ${FONTS.primary};
  font-size: ${({ dimension }) => dimension / 3}px;
  justify-content: center;
  height: ${({ dimension }) => dimension}px;
  line-height: 120%;
  min-width: ${({ dimension }) => dimension}px;
  position: relative;
  text-transform: uppercase;
  width: ${({ dimension }) => dimension}px;

  svg {
    height: 38%;
    width: auto;
  }
`;

export default StyledAvatar;
