import { WEB_PREFIX } from 'constants/common';

export const TEST_ID_WEB_PROFILE_VIEW = `${WEB_PREFIX}profile-view`;

export const TEST_ID_WEB_PROFILE_VIEW_CONNECTIONS_LABEL = `${TEST_ID_WEB_PROFILE_VIEW}_connections-label`;

export const TEST_ID_WEB_CONNECTIONS_VIEW = `${WEB_PREFIX}connections-view`;

export const getConnectionsModalTabButtonId = (peopleNumber: number, isFollowers?: boolean) =>
  `${TEST_ID_WEB_CONNECTIONS_VIEW}_pager-view_${peopleNumber}-follow${isFollowers ? 'ers' : 'ing'}-page-label`;

export const getConnectionsUserFollowBtnId = (username: string) =>
  `${TEST_ID_WEB_CONNECTIONS_VIEW}_item-${username}_action-button`;

const TEST_ID_WEB_FOLLOW_BY_TOPIC_VIEW = `${WEB_PREFIX}follow-by-topic-view`;

export const getConnectionFollowTopicDataTestId = (topicName: string) =>
  `${TEST_ID_WEB_FOLLOW_BY_TOPIC_VIEW}_item-${topicName}_name-label`;

export const TEST_ID_CONNECTION_MODAL_UNFOLLOW_CONFIRM_BTN = `${TEST_ID_WEB_FOLLOW_BY_TOPIC_VIEW}_unfollow_confirm_action-button`;
export const TEST_ID_CONNECTION_MODAL_UNFOLLOW_CANCEL_BTN = `${TEST_ID_WEB_FOLLOW_BY_TOPIC_VIEW}_unfollow_cancel_action-button`;
