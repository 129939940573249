import React, { FC } from 'react';

import { ReactComponent as Chevron } from 'assets/icons/ChevronLeft.svg';

import { ChannelListSchema, ChannelSectionListV2, ChannelSectionTypeOptions } from 'constants/graphqlTypes';

import UILink from 'components/UI/Link';

import useTheme from 'helpers/useTheme';
import { transformRoute } from 'helpers/routingHelper';
import { useGetChannelSectionItems } from 'graphQL/channel/hooks';
import { ROUTE_CHANNELS_LISTS } from 'routes';
import ListsSlider from '../ListsSlider';
import { StyledSectionWrap, StyledText, StyledTextContainer, StyledTitle } from '../styled';
import { EChannelsListsUrlType } from './constants';

const ListSection: FC<{ section: ChannelSectionListV2 }> = ({ section }) => {
  const isDark = useTheme();
  const { id, title, sectionType } = section;
  const { items, loading, loadMore } = useGetChannelSectionItems(id);

  const lists =
    items?.reduce(
      (prev: ChannelListSchema[], curr) => (curr?.__typename === 'ChannelListSchema' ? [...prev, curr] : prev),
      []
    ) ?? [];

  const urlType =
    sectionType === ChannelSectionTypeOptions.FollowList
      ? EChannelsListsUrlType.FollowList
      : EChannelsListsUrlType.TrendingLists;

  return (
    <StyledSectionWrap>
      <StyledTextContainer>
        <StyledTitle isDark={isDark}>
          <UILink route={transformRoute(ROUTE_CHANNELS_LISTS, { type: urlType, id })} isDisabled={!items?.length}>
            <span>{title}</span>
            {!!items?.length && <Chevron viewBox="0 0 24 24" height={24} width={24} />}
          </UILink>
        </StyledTitle>
        {!items?.length && !loading && <StyledText>You haven&apos;t subscribed to any lists yet</StyledText>}
      </StyledTextContainer>
      {items && !!items.length && <ListsSlider items={lists} isDark={isDark} onWaypointEnter={loadMore} />}
    </StyledSectionWrap>
  );
};

export default ListSection;
