import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledInfoWrapper = styled.div`
  padding-left: 52px;
`;

export const StyledInfo = styled.div<{ isHighlighted?: boolean }>`
  display: block;
  font-family: ${FONTS.secondary};
  font-size: 12px;
  line-height: 140%;
  margin: 0;
  word-break: break-word;
  white-space: pre-wrap;
  color: ${COLORS.white[60]};
  padding-top: 12px;
  position: relative;

  > span {
    color: ${COLORS.white[100]};
    font-size: 14px;
    display: ${({ isHighlighted }) => (isHighlighted ? 'block' : 'inline')};
    font-weight: ${({ isHighlighted }) => (isHighlighted ? 600 : 400)};
    padding-top: ${({ isHighlighted }) => (isHighlighted ? '8px' : 0)};
  }

  a {
    font-weight: 600;
    color: inherit;
    position: relative;
    z-index: 100;
  }
`;

export const StyledText = styled.span<{ isDragAllowed: boolean }>`
  cursor: ${({ isDragAllowed }) => (isDragAllowed ? 'grab' : 'default')};
`;

export const StyledWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const StyledContent = styled.div`
  min-width: 100%;
  padding: 0 24px;
  position: relative;
  transition: all 0.3s ease-in-out;

  .badge {
    z-index: 10;
  }
  .badge-content:active {
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
  }
`;

export const StyledTextBtn = styled.b`
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  z-index: 100;
`;

export const StyledQuickReactionsButtonWrap = styled.div<{ hasNoReactionButton?: boolean }>`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  padding: 12px 0 0 ${({ hasNoReactionButton }) => (hasNoReactionButton ? 0 : 52)}px;
  position: relative;
`;

export const StyledHighlightedWrap = styled.div`
  font-family: ${FONTS.primary};
  color: ${COLORS.white[100]};

  .avatarGH {
    background-color: ${COLORS.white[100]};
  }
`;

export const StyledRelWrap = styled.div`
  position: relative;
`;

export const StyledButtonWrap = styled.div`
  padding: 0 24px;
`;

export const StyledClickArea = styled.div`
  inset: 0 -24px 0;
  position: absolute;
  z-index: 0;
`;
