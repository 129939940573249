import React, { FC } from 'react';

import { CardSchema } from 'constants/graphqlTypes';
import Collection from 'components/Collection';
import BigCard from 'components/List/BigCard';
import { StyledListWrap } from '../styled';

const ListSection: FC<{ item: CardSchema }> = ({ item }) => {
  const { isTileLayout } = item;

  return (
    <StyledListWrap hasPadding={!!isTileLayout}>
      {isTileLayout ? (
        <Collection
          showCreators
          showListDescription
          hidePrivateLabel
          hideCollaborators
          removePaddingTopFromTitle
          item={item}
          isFeaturedTab
        />
      ) : (
        <BigCard item={item} />
      )}
    </StyledListWrap>
  );
};

export default ListSection;
