import React, { FC, useState } from 'react';

import { useListNotifications, useUpdateSpecificUserStatus } from 'graphQL/profile/notifications/hooks';
import { useChannelAddItem, useChannelDeleteItem } from 'graphQL/channel/hooks';

import { ReactComponent as BellPlus } from 'assets/icons/BellPlus.svg';
import { ReactComponent as BellCheckmark } from 'assets/icons/BellCheck.svg';
import { SectionTypeOptions } from 'constants/graphqlTypes';
import { StyledButton } from '../styled';

const NotificationsButton: FC<{
  isNotificationsEnable: boolean;
  hasEditPermission?: boolean;
  isCollaborative?: boolean | null;
  userId?: string;
  listId?: string;
  name?: string | null;
}> = ({ isNotificationsEnable, hasEditPermission, isCollaborative, userId, name, listId }) => {
  const [isListNotificationsEnable, setListNotificationsEnable] = useState(isNotificationsEnable);

  const notificationUserMessage = `Notifications ${
    isNotificationsEnable ? 'enabled' : 'are deactivated'
  } for any posts on topics you’re following from ${name}.`;

  const notificationListMessage = `Notifications ${
    isListNotificationsEnable ? 'enabled' : 'deactivated'
  } for any posts from ${name}.`;

  const { updateSpecificUserNotificationsStatus } = useUpdateSpecificUserStatus(notificationUserMessage);
  const { updateListNotifications } = useListNotifications(notificationListMessage);
  const { channelsAddItem } = useChannelAddItem(listId);
  const { channelsDeleteItem } = useChannelDeleteItem(listId);

  const handleClick = () => {
    if (userId && !listId) {
      updateSpecificUserNotificationsStatus({ userId, isEnable: !isNotificationsEnable });
    }
    if (!isNotificationsEnable) {
      channelsAddItem({
        variables: {
          id: listId,
          type: SectionTypeOptions.List,
        },
      });
    }
    if (isNotificationsEnable) {
      channelsDeleteItem({
        variables: {
          itemId: listId,
        },
      });
    }
    if (listId && !userId && isCollaborative && hasEditPermission) {
      setListNotificationsEnable(!isListNotificationsEnable);
      updateListNotifications(listId, !isNotificationsEnable);
    }
  };

  return (
    <StyledButton className="icon-navigation" onClick={handleClick}>
      {isNotificationsEnable ? <BellCheckmark /> : <BellPlus />}
    </StyledButton>
  );
};

export default NotificationsButton;
