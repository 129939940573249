import React, { FC } from 'react';

import OnboardingAlert from 'components/UI/OnboardingAlert';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';

import { StyledBottomMenu, StyledButton } from './styled';

interface IListButtomMenuProps {
  hint: string;
  showHint?: boolean;
  handler: () => void;
}

const ListBottomMenu: FC<IListButtomMenuProps> = ({ hint, showHint, handler }) => (
  <>
    {showHint && (
      <StyledBottomMenu bottom={111}>
        <OnboardingAlert title={hint} />
      </StyledBottomMenu>
    )}
    <StyledBottomMenu>
      <StyledButton aria-label="Click to add a pick" onClick={handler}>
        <PlusIcon viewBox="0 0 20 20" height="50px" width="50px" strokeWidth="0.75" />
      </StyledButton>
    </StyledBottomMenu>
  </>
);

export default ListBottomMenu;
