import { styled } from 'linaria/react';

import { COLORS } from '../../../styles/constants';

const StyledLoader = styled.div<{ size: number; isDark: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  animation: loading 3s ease-in-out infinite;
  animation-direction: reverse;

  &::before,
  &::after {
    content: '';
    height: ${({ size }) => Math.ceil(size / 3)}px;
    width: ${({ size }) => Math.ceil(size / 3)}px;
    background-color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    border-radius: 50%;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }

    85% {
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: rotate(-359deg);
    }
  }
`;

export default StyledLoader;
