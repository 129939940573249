import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';

import { MASONRY_ITEM_WIDTH } from 'components/List/Grid/helpers';
import { StyledContent } from 'components/Profile/ProfileContent/styled';
import { CardSchema } from 'constants/graphqlTypes';
import { XBlock, XMasonry } from 'react-xmasonry';

import LoaderContent from 'components/UI/LoaderContent';
import Card from 'components/Card';
import { getSearchCardTestId } from 'constants/aqa/search';

interface MasonryProps {
  items: CardSchema[];
  loading: boolean;
  loadMore: () => void;
  isDark: boolean;
  showActivity?: boolean;
  pageTestId?: string;
}

const Masonry: FC<MasonryProps> = ({ items, loadMore, loading, isDark, showActivity, pageTestId }) => (
  <>
    <StyledContent className="masonryWrap">
      <XMasonry
        targetBlockWidth={MASONRY_ITEM_WIDTH}
        responsive={false}
        maxColumns={2}
        smartUpdate
        smartUpdateCeil={50}
      >
        {items.map((item, index) => (
          <XBlock width={1} key={item.id}>
            <Card
              dataTestId={getSearchCardTestId(index, pageTestId)}
              card={item}
              isDark={isDark}
              showActivity={showActivity}
            />
          </XBlock>
        ))}
      </XMasonry>
    </StyledContent>
    {loading ? <LoaderContent isFullScreen={!items.length} isDark={isDark} /> : <Waypoint onEnter={loadMore} />}
  </>
);

export default Masonry;
