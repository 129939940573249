import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS } from '../../../styles/constants';

export const StyledWrap = styled.div<{ checked: boolean; isDark?: boolean; isDisabled?: boolean }>`
  align-items: center;
  background-color: ${({ checked, isDark }) => {
    if (checked) {
      return isDark ? COLORS.white[100] : COLORS.brown.dark[100];
    }
    return isDark ? COLORS.white[30] : COLORS.white.off[100];
  }};
  border-radius: 40px;
  display: flex;
  flex-direction: ${({ checked }) => (checked ? 'row-reverse' : 'row')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.6' : '1')};
  padding: 0 2px;
  height: 24px;
  min-width: 48px;
  width: 48px;

  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }

  &::before {
    background-color: ${({ isDark }) => (isDark ? COLORS.brown.dark[100] : COLORS.white[100])};
    border-radius: 50%;
    content: '';
    height: 20px;
    width: 20px;
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    border-radius: calc(40px * ${BREAKPOINTS.retinaScale});
    height: calc(24px * ${BREAKPOINTS.retinaScale});
    padding: 0 calc(2px * ${BREAKPOINTS.retinaScale});
    width: calc(48px * ${BREAKPOINTS.retinaScale});

    &::before {
      height: calc(20px * ${BREAKPOINTS.retinaScale});
      width: calc(20px * ${BREAKPOINTS.retinaScale});
    }
  }
`;
