import React, { FC } from 'react';

import usePageType from 'helpers/usePageType';
import SettingsButton from './SettingsButton';
import BackButton from './BackButton';
import OnboardingButton from './OnboardingButton';

const LeftMenu: FC = () => {
  const { isDiscovery, isOnboarding, isExactlyProfile, isMyProfile } = usePageType();

  const isExactlyMyProfile = isMyProfile && isExactlyProfile;
  const showSettings = isExactlyMyProfile || isDiscovery;

  return (
    <>
      {isOnboarding && <OnboardingButton />}
      {showSettings && <SettingsButton />}
      {!showSettings && !isOnboarding && <BackButton />}
    </>
  );
};

export default LeftMenu;
