import { UserObjectType } from 'constants/graphqlTypes';

export const MOCK_USERS: UserObjectType[] = [
  {
    __typename: 'UserObjectType',
    id: 'f3a99f04-e844-4a28-894b-a526f4003a93',
    userInfo: {
      firstName: 'Pete',
      lastName: 'Forester',
      bio: 'Brooklyn based writer.',
    },
    userCounters: {
      connectionsCount: 13,
    },
    username: 'pete_forester',
    pickedCards: {
      totalCount: 242,
      items: [],
    },
    isVerified: true,
    cardId: '34188823-3f2c-481f-b91c-6ddaa6204aa8',
    userStyle: {
      __typename: 'CardStyleSchema',
      id: '9363d4eb-e0c4-4cb4-8870-1923c366b286',
      thumbnailImageSet: {
        __typename: 'ImageSetSchema',
        id: 'SW1hZ2VTZXRTY2hlbWE6YmM1NDkwYjMtZjVlNC01NzJjLWFkYjctMWY4MGE2YjdkYWE3',
        imageId: 'bc5490b3-f5e4-572c-adb7-1f80a6b7daa7',
        images: [
          {
            __typename: 'ImageSchema',
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/bc5490b3-f5e4-572c-adb7-1f80a6b7daa7-preview',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJ2JjNTQ5MGIzLWY1ZTQtNTcyYy1hZGI3LTFmODBhNmI3ZGFhNycpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
          },
        ],
      },
    },
  },
  {
    __typename: 'UserObjectType',
    id: 'eee1b688-6b4f-4fc1-9a85-b9811b46ac5e',
    userInfo: {
      firstName: 'Nia',
      lastName: 'G.',
      bio: 'writer | affinity for travel, style, art +',
    },
    userCounters: {
      connectionsCount: 12,
    },
    username: 'nia.groce',
    pickedCards: {
      totalCount: 411,
      items: [],
    },
    isVerified: true,
    cardId: '2a0e9e79-441b-4312-852a-6afc6194fb42',
    userStyle: {
      __typename: 'CardStyleSchema',
      id: '6614eeca-1ef1-4dc1-a71a-1e3003a89137',
      thumbnailImageSet: {
        __typename: 'ImageSetSchema',
        id: 'SW1hZ2VTZXRTY2hlbWE6NGM4ZGQ2NjAtZjI0Ni01MTJmLWEyMjItYTk5N2EwMTlkZDM1',
        imageId: '4c8dd660-f246-512f-a222-a997a019dd35',
        images: [
          {
            __typename: 'ImageSchema',
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/4c8dd660-f246-512f-a222-a997a019dd35-preview',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJzRjOGRkNjYwLWYyNDYtNTEyZi1hMjIyLWE5OTdhMDE5ZGQzNScpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
          },
        ],
      },
    },
  },
  {
    __typename: 'UserObjectType',
    id: '229d1e6c-64b4-41ff-b438-1cee7b4931be',
    userInfo: {
      firstName: 'Tom',
      lastName: 'Jackson',
      bio: 'Maker of things',
    },
    userCounters: {
      connectionsCount: 43,
    },
    username: 'tom.jackson',
    pickedCards: {
      totalCount: 315,
      items: [],
    },
    isVerified: true,
    cardId: '5189a095-5b79-4e1a-81d6-426973521dc9',
    userStyle: {
      __typename: 'CardStyleSchema',
      id: 'bac05c74-4a79-47c8-acae-c72692c742b6',
      thumbnailImageSet: {
        __typename: 'ImageSetSchema',
        id: 'SW1hZ2VTZXRTY2hlbWE6Nzc0YzY0M2YtODRjYy01OWY0LWJmNzItMWUzMTEyM2MwNTI0',
        imageId: '774c643f-84cc-59f4-bf72-1e31123c0524',
        images: [
          {
            __typename: 'ImageSchema',
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/774c643f-84cc-59f4-bf72-1e31123c0524-preview',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJzc3NGM2NDNmLTg0Y2MtNTlmNC1iZjcyLTFlMzExMjNjMDUyNCcpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
          },
        ],
      },
    },
  },
  {
    __typename: 'UserObjectType',
    id: 'f2f815f7-bf88-4c05-80e3-dd47ea2d6186',
    userInfo: {
      firstName: 'Ariston',
      lastName: '',
      bio: 'Culture writer.',
    },
    userCounters: {
      connectionsCount: 66,
    },
    username: 'ariston',
    pickedCards: {
      totalCount: 433,
      items: [],
    },
    isVerified: true,
    cardId: '637dec5f-b80e-4f95-8fdf-6e65cebaccec',
    userStyle: {
      __typename: 'CardStyleSchema',
      id: 'ae2bad0f-a496-45da-bb25-5fa298746b47',
      thumbnailImageSet: {
        __typename: 'ImageSetSchema',
        id: 'SW1hZ2VTZXRTY2hlbWE6OTMyZWYxYmQtNzBhMy01NjdmLWJmMGUtZWNiM2YyOTk4YmU1',
        imageId: '932ef1bd-70a3-567f-bf0e-ecb3f2998be5',
        images: [
          {
            __typename: 'ImageSchema',
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/932ef1bd-70a3-567f-bf0e-ecb3f2998be5-preview',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJzkzMmVmMWJkLTcwYTMtNTY3Zi1iZjBlLWVjYjNmMjk5OGJlNScpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
          },
        ],
      },
    },
  },
  {
    __typename: 'UserObjectType',
    id: '64d4a5fc-7682-4167-8568-5c098349bf46',
    userInfo: {
      firstName: 'Jon',
      lastName: 'Korn',
      bio: 'Expert Dilettante',
    },
    userCounters: {
      connectionsCount: 14,
    },
    username: 'jon.korn',
    pickedCards: {
      totalCount: 223,
      items: [],
    },
    isVerified: true,
    cardId: 'f8d4bd4c-c2c9-49de-a3e1-06ab65b90fd1',
    userStyle: {
      __typename: 'CardStyleSchema',
      id: 'ae2bad0f-a496-45da-bb25-5fa298746b47',
      thumbnailImageSet: {
        __typename: 'ImageSetSchema',
        id: 'fe19e9e6-eefe-47b7-bf27-c012fbd02dd0',
        imageId: 'SW1hZ2VTZXRTY2hlbWE6NGRjNTgxNGEtZDk2Yy01ZjdmLWJhNzEtY2NlMzg3N2QzNzYx',
        images: [
          {
            __typename: 'ImageSchema',
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/4dc5814a-d96c-5f7f-ba71-cce3877d3761-preview',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJzRkYzU4MTRhLWQ5NmMtNWY3Zi1iYTcxLWNjZTM4NzdkMzc2MScpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
          },
        ],
      },
    },
  },
  {
    __typename: 'UserObjectType',
    id: '12a5ba68-8176-4702-a789-39f8b36e795c',
    userInfo: {
      firstName: 'Marcia',
      lastName: 'Gagliardi',
      bio: 'SF-based restaurant columnist and bohemian.',
    },
    userCounters: {
      connectionsCount: 9,
    },
    username: 'tablehopper',
    pickedCards: {
      totalCount: 216,
      items: [],
    },
    isVerified: true,
    cardId: '09c641ee-e375-46f9-a0d2-3d39b168040f',
    userStyle: {
      __typename: 'CardStyleSchema',
      id: 'ea5bd7f9-d689-429a-ab99-5e1d1e68c32a',
      thumbnailImageSet: {
        __typename: 'ImageSetSchema',
        id: 'SW1hZ2VTZXRTY2hlbWE6ZWNiM2VjYWYtN2NiMi01MGE4LTg1OTktMzg1YmZmMjNlMzM4',
        imageId: 'ecb3ecaf-7cb2-50a8-8599-385bff23e338',
        images: [
          {
            __typename: 'ImageSchema',
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/ecb3ecaf-7cb2-50a8-8599-385bff23e338-preview',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJ2VjYjNlY2FmLTdjYjItNTBhOC04NTk5LTM4NWJmZjIzZTMzOCcpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
          },
        ],
      },
    },
  },
  {
    __typename: 'UserObjectType',
    id: 'cfed5823-7a33-4a92-8966-1035bdffe1f7',
    userInfo: {
      firstName: 'Elliott',
      lastName: 'Shaw',
      bio: 'Writer',
    },
    userCounters: {
      connectionsCount: 4,
    },
    username: 'itselliott',
    pickedCards: {
      totalCount: 206,
      items: [],
    },
    isVerified: true,
    cardId: '8857d0fb-25e8-4bc3-acd7-b41fe7a9d32d',
    userStyle: {
      __typename: 'CardStyleSchema',
      id: '9dffcc4a-8632-4cdc-8da0-eaacdfdb0acb',
      thumbnailImageSet: {
        __typename: 'ImageSetSchema',
        id: 'SW1hZ2VTZXRTY2hlbWE6OTRhZjZkZTUtNDkxMC01YjZmLThmOTctOWQ2ZDg0OTY2Njhi',
        imageId: '94af6de5-4910-5b6f-8f97-9d6d8496668b',
        images: [
          {
            __typename: 'ImageSchema',
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/94af6de5-4910-5b6f-8f97-9d6d8496668b-preview',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJzk0YWY2ZGU1LTQ5MTAtNWI2Zi04Zjk3LTlkNmQ4NDk2NjY4YicpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
          },
        ],
      },
    },
  },
  {
    __typename: 'UserObjectType',
    id: 'ddbed73e-1b45-49fe-851e-6b1f32968dd0',
    userInfo: {
      firstName: 'mj',
      lastName: 'deery',
      bio: 'Storyteller for social change',
    },
    userCounters: {
      connectionsCount: 5,
    },
    username: 'kidblast',
    pickedCards: {
      totalCount: 183,
      items: [],
    },
    isVerified: true,
    cardId: '5db03123-5ee3-4fc6-9e19-d6433309fc08',
    userStyle: {
      __typename: 'CardStyleSchema',
      id: 'e08375a6-0f32-4753-8d8b-df1ce486f8d5',
      thumbnailImageSet: {
        __typename: 'ImageSetSchema',
        id: 'SW1hZ2VTZXRTY2hlbWE6MjgxYWY1NGYtOGE3My01ZjBhLTg1Y2EtYzlmZjQxMGZkYjhj',
        imageId: '281af54f-8a73-5f0a-85ca-c9ff410fdb8c',
        images: [
          {
            __typename: 'ImageSchema',
            url: 'https://d3tmvo86aoqtq8.cloudfront.net/281af54f-8a73-5f0a-85ca-c9ff410fdb8c-preview',
            id:
              'SW1hZ2VTY2hlbWE6KFVVSUQoJzI4MWFmNTRmLThhNzMtNWYwYS04NWNhLWM5ZmY0MTBmZGI4YycpLCA8SW1hZ2VUeXBlT3B0aW9ucy5QUkVWSUVXOiAncHJldmlldyc+KQ==',
          },
        ],
      },
    },
  },
];
