import {
  CardCommentSchema,
  CardPickSchema,
  CardSchema,
  ECardPermissionsOptions,
  ECardRoleTypeOptions,
  GetCommentsQuery,
  GetCreatorsQuery,
  GetPicksQuery,
  Maybe,
  UserCardRoleListObjectType,
  UserCardRoleSchema,
  UserObjectType,
} from 'constants/graphqlTypes';
import { IUpdateCollabProps } from './models';

export enum ENavElements {
  Comments = 'comments',
  Picks = 'picks',
  Creators = 'creators',
}

export type IGetActionUsers = { user: UserObjectType; id: string }[];

export type IActionData = {
  totalCount: number;
  items: IGetActionUsers;
  loadMore?: () => void;
  loading: boolean;
  isPickedByMe?: boolean;
};

export enum EActionType {
  Comments = 'CardCommentListObjectType',
  Picks = 'CardPickListObjectType',
  Likes = 'CardLikeListObjectType',
  Creators = 'UserCardRoleListObjectType',
}

export const getDataFromLikes = (items?: Maybe<CardPickSchema[]>): IGetActionUsers => {
  return (
    (items ?? []).reduce(
      (acc: { user: UserObjectType; id: string }[], { user, id }) =>
        user && !acc.some((item) => item.id === id) ? acc.concat({ user, id }) : acc,
      []
    ) ?? []
  );
};

export const addActions = ({
  prev,
  field,
  type,
  totalCount,
  card,
  items,
  newItems,
}: {
  prev: GetPicksQuery | GetCommentsQuery | GetCreatorsQuery;
  field: ENavElements;
  type: EActionType;
  totalCount: number;
  card?: CardSchema;
  items?: CardPickSchema[] | CardCommentSchema[] | UserCardRoleSchema[];
  newItems?: CardPickSchema[] | CardCommentSchema[] | UserCardRoleSchema[];
}): GetPicksQuery | GetCommentsQuery | GetCreatorsQuery => {
  if (!card || !items || !newItems || !totalCount) {
    return prev;
  }

  return {
    ...prev,
    ...(prev?.allCards && {
      allCards: {
        ...prev?.allCards,
        ...(prev?.allCards.items && {
          items: [{ ...card, [field]: { __typename: type, totalCount, items: [...items, ...newItems] } }],
        }),
      },
    }),
  };
};

const changePermissions = (prev: ECardPermissionsOptions[], isCurrentUser: boolean, role?: ECardRoleTypeOptions) => {
  const permission =
    role === ECardRoleTypeOptions.Owner
      ? ECardPermissionsOptions.EditCard
      : ECardPermissionsOptions.EditCollectionItems;

  return isCurrentUser ? [...(role ? [permission] : [])] : prev;
};

const changeCreators = (prev: UserCardRoleListObjectType, id: string, role?: ECardRoleTypeOptions) => ({
  ...prev,
  totalCount: prev.totalCount - (!role ? 1 : 0),
  items: !role
    ? prev.items.filter(({ userId }) => userId !== id)
    : prev.items.map((creator) => (creator.userId === id ? { ...creator, roleName: role } : creator)),
});

export const updateCollab = ({
  cache,
  id,
  listId,
  isCurrentUser,
  role,
  isCollaborative,
}: IUpdateCollabProps): boolean =>
  cache.modify({
    id: cache.identify({ __typename: 'CardSchema', id: listId }),
    fields: {
      isCollaborative: (prev: boolean) => isCollaborative ?? prev,
      creators: (prev: UserCardRoleListObjectType) => changeCreators(prev, id, role),
      permissions: (prev: ECardPermissionsOptions[]) => changePermissions(prev, isCurrentUser, role),
    },
  });
