import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledInviteText = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font-size: 22px;
  line-height: 130%;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

export const StyledToggle = styled.span<{ isDark: boolean }>`
  border-bottom: 1px solid ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  cursor: pointer;
  display: inline-block;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
`;

export const StyledPreview = styled.div<{ isPrivateList?: boolean; isEmptyList?: boolean }>`
  box-sizing: content-box;
  margin: 0 auto;
  padding: 30px 0 12px;
  width: 80%;
  pointer-events: ${({ isPrivateList }) => (isPrivateList ? 'none' : 'auto')};

  li {
    font-size: 17px;
  }

  .collaborators,
  .grain {
    display: none;
  }

  .list-body {
    background: ${COLORS.transparent};
    border: 1px solid ${({ isEmptyList }) => (isEmptyList ? COLORS.brown.dark[10] : COLORS.white[30])};
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    width: 60%;

    li {
      font-size: 20px;
    }
  }
`;

export const StyledTitleList = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: block;
  font-size: 14px;
  line-height: 140%;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
