import { styled } from 'linaria/react';

import { StyledNoContent as ImportNoContent } from '../Profile/styled';

export const StyledNoContent = styled(ImportNoContent)`
  border: none;
  max-width: unset;
  padding: 16px 0 0 0;
`;

export const StyledList = styled.ul`
  margin: 0;
  list-style: none;
`;

export const StyledListItem = styled.li<{ isSelect: boolean; paddingVertical?: number }>`
  align-items: center;
  cursor: ${({ isSelect }) => (isSelect ? 'pointer' : 'default')};
  display: flex;
  padding: ${({ paddingVertical }) => paddingVertical ?? 16}px 0;

  .badge {
    flex-grow: 1;
  }
`;
