import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';

import { ReactComponent as RefreshIcon } from 'assets/icons/Refresh.svg';
import logoLottie from 'assets/lottie/logo.json';

import { TEST_WELCOME_VIEW_GET_STARTED_BTN, TEST_WELCOME_VIEW_TITLE } from 'constants/aqa/login';

import AuthStart from 'components/Authentication/AuthStart';
import AnimatedBackground from 'components/AnimatedBackground';
import Button from 'components/UI/Button';
import Slider from 'components/List/Header/Slider';

import Intro from './Intro';
import { mockedImages } from './constants';

import { StyledButtonWrap, StyledContent, StyledIconWrapper, StyledSliderWrapper } from './styled';

const Welcome: FC = () => {
  const { state } = useLocation<{ variable?: { openAuthModal?: boolean } }>();
  const { variable } = state ?? {};

  const [isIntroOpened, setIsIntroOpened] = useState(true);
  const [isAuthStartOpened, setIsAuthStartOpened] = useState(false);

  const handlePlayVideo = () => {
    setIsIntroOpened(true);
  };

  useEffect(() => {
    setIsAuthStartOpened(!!variable?.openAuthModal);
  }, [variable]);

  return (
    <AnimatedBackground>
      {isIntroOpened ? (
        <Intro openIntro={setIsIntroOpened} />
      ) : (
        <StyledContent>
          <StyledIconWrapper>
            <RefreshIcon onClick={handlePlayVideo} />
          </StyledIconWrapper>
          <Lottie data-testid={TEST_WELCOME_VIEW_TITLE} animationData={logoLottie} loop={0} className="lottie" />
          <StyledButtonWrap>
            <Button
              dataTestId={TEST_WELCOME_VIEW_GET_STARTED_BTN}
              text="Get started"
              handler={() => setIsAuthStartOpened(true)}
            />
          </StyledButtonWrap>
          <StyledSliderWrapper>
            <Slider items={mockedImages} isImages />
          </StyledSliderWrapper>
        </StyledContent>
      )}
      {isAuthStartOpened && <AuthStart handleClose={() => setIsAuthStartOpened(false)} />}
    </AnimatedBackground>
  );
};

export default Welcome;
