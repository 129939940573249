import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_MESSAGES_CHAT, ROUTE_USER_PROFILE } from 'routes';
import { EMessengerVisibilityStatusOptions, UserShortSchema } from 'constants/graphqlTypes';
import Badge from 'components/UI/Badge';
import { transformRoute } from 'helpers/routingHelper';
import ImportedStyledContainer from 'components/UI/BadgeWithChildren/styled';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import SendButton from '../SendButton';
import { StyledBadgeWrap, StyledButtonWrap } from './styled';

interface ISingleUserItemProps {
  user: UserShortSchema;
  chatId?: string;
}

const SingleUserItem: FC<ISingleUserItemProps> = ({ user, chatId }) => {
  const [error, setError] = useState(false);
  const { push } = useHistory();
  const { id, username, isFollowingMe, messagesAndThoughtsVisibility } = user;

  const isAllowedToMessage =
    messagesAndThoughtsVisibility === EMessengerVisibilityStatusOptions.Everyone ||
    (messagesAndThoughtsVisibility === EMessengerVisibilityStatusOptions.Following && isFollowingMe);

  const redirectRoute = chatId ? ROUTE_MESSAGES_CHAT : ROUTE_USER_PROFILE;

  return username ? (
    <>
      <ImportedStyledContainer onClick={() => !isAllowedToMessage && setError(true)}>
        <StyledBadgeWrap
          onClick={(e) => {
            e.stopPropagation();
            push(transformRoute(redirectRoute, { profileName: username, id: chatId ?? id }));
          }}
        >
          <Badge isDisabled isDark hasUsername user={user} />
        </StyledBadgeWrap>
        {isAllowedToMessage && (
          <StyledButtonWrap>
            <SendButton chatId={chatId} userId={id} />
          </StyledButtonWrap>
        )}
      </ImportedStyledContainer>
      {error && (
        <ConfirmationModal
          buttonText="OK"
          handleAccept={() => setError(false)}
          handleClickOutside={() => setError(false)}
          isWarning={false}
          titleText="Unable to send message"
          subTitle="You can't message this person because they've chosen not to receive messages."
          ignoreUseModal
        />
      )}
    </>
  ) : null;
};

export default SingleUserItem;
