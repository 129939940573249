import { useEffect } from 'react';

export const useStorageEvent = (callback: () => void): void => {
  const { addEventListener, removeEventListener } = window;

  useEffect(() => {
    addEventListener('storage', callback);

    return () => removeEventListener('storage', callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
