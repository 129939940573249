import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  ROUTE_ONBOARDING_NETWORKING,
  ROUTE_ONBOARDING_NETWORKING_CONFIRMATION,
  ROUTE_ONBOARDING_NETWORKING_USER_SUGGESTION,
} from 'routes';
import Join from './Join';
import Confirmation from './Confirmation';
import UserSuggestion from './UserSuggestion';

const Networking: FC = () => {
  return (
    <Switch>
      <Route exact path={ROUTE_ONBOARDING_NETWORKING}>
        <Join />
      </Route>
      <Route path={ROUTE_ONBOARDING_NETWORKING_USER_SUGGESTION}>
        <UserSuggestion />
      </Route>
      <Route path={ROUTE_ONBOARDING_NETWORKING_CONFIRMATION}>
        <Confirmation />
      </Route>
    </Switch>
  );
};

export default Networking;
