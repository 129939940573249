import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import useDocumentTitle from 'helpers/useDocumentTitle';
import useGuideTour from 'helpers/useGuideTour';
import { ETutorialStatus } from 'constants/graphqlTypes';
import { useUserSettings } from 'graphQL/contextualIntro/hooks';
import useTheme from 'helpers/useTheme';
import { ROUTE_PROFILE, ROOT_NOTION_ROUTE, ROUTE_BLOCKED_ACCOUNTS, ROUTE_PRIVACY_APPROACH, ROUTE_TERMS } from 'routes';

import SinglePageLayout from 'components/SinglePageLayout';
import Arrow from 'components/UI/Arrow';
import { StyledItem } from 'components/AddContent/Menu/styled';
import MenuButton from 'components/UI/MenuButton';
import OnboardingAlert from 'components/UI/OnboardingAlert';
import useDiscoveryTab from 'helpers/useDiscoveryTab';
import { EExploreTabs } from 'components/Explore/ExploreTabs/helpers/constants';
import {
  TEST_ID_HELP_VIEW_BLOCKED_ACCOUNTS,
  TEST_ID_HELP_VIEW_HOW_TO_HEADER_TITLE_LABEL,
  TEST_ID_HELP_VIEW_ITEM_ONBOARDING_TOUR_TAKE_THE_TOUR_BUTTON,
  TEST_ID_HELP_VIEW_OPTION_ADD_PICK,
  TEST_ID_HELP_VIEW_OPTION_ADD_THOUGHT,
  TEST_ID_HELP_VIEW_OPTION_CREATE_LIST,
  TEST_ID_HELP_VIEW_OPTION_MAKE_CONNECTION,
  TEST_ID_HELP_VIEW_OPTION_PRIVACY,
  TEST_ID_HELP_VIEW_OPTION_VERSION_TITLE_LABEL,
  TEST_ID_HELP_VIEW_TERMS_AND_CONDITIONS,
  TEST_ID_HELP_VIEW_TITLE_LABEL,
} from 'constants/aqa/help';

import { StyledWrap, StyledTitle, StyledList, StyledText, StyledInfo, StyledListItem } from './styled';

const Help: FC = () => {
  const posthogCapture = usePostHogCapture();
  const isDark = useTheme();
  const { push } = useHistory();
  const { setIsGuideTour, isGuideTour } = useGuideTour();
  const { tutorialStatus, loading: settingsLoading } = useUserSettings();
  const { setDiscoveryTab } = useDiscoveryTab();

  useDocumentTitle('Help');

  return (
    <SinglePageLayout title="Help" pageTestId={TEST_ID_HELP_VIEW_TITLE_LABEL}>
      <StyledWrap>
        <StyledTitle data-testid={TEST_ID_HELP_VIEW_HOW_TO_HEADER_TITLE_LABEL} isDark={isDark}>
          guide:human how to’s
        </StyledTitle>
        <StyledList isDark={isDark}>
          <StyledItem>
            <MenuButton
              dataTestId={TEST_ID_HELP_VIEW_OPTION_ADD_PICK}
              text="Add a pick"
              handler={() =>
                window.open(`${ROOT_NOTION_ROUTE}Add-a-pick-e33db61eef1b484eac3458c5a3ba31a8?pvs=4`, '_blank')
              }
              isDark={isDark}
              Icon={() => <Arrow degree={135} isDark={isDark} />}
              isReversed
            />
          </StyledItem>
          <StyledItem>
            <MenuButton
              dataTestId={TEST_ID_HELP_VIEW_OPTION_ADD_THOUGHT}
              text="Add a thought"
              handler={() =>
                window.open(`${ROOT_NOTION_ROUTE}Add-a-thought-0ffe54beec924ac8956afb5c1dcec8dd?pvs=4`, '_blank')
              }
              isDark={isDark}
              Icon={() => <Arrow degree={135} isDark={isDark} />}
              isReversed
            />
          </StyledItem>
          <StyledItem>
            <MenuButton
              dataTestId={TEST_ID_HELP_VIEW_OPTION_CREATE_LIST}
              text="Create a list"
              handler={() =>
                window.open(`${ROOT_NOTION_ROUTE}Create-a-list-a4551deb45014fa6ad8f70dd9b167227?pvs=4`, '_blank')
              }
              isDark={isDark}
              Icon={() => <Arrow degree={135} isDark={isDark} />}
              isReversed
            />
          </StyledItem>
          <StyledItem>
            <MenuButton
              dataTestId={TEST_ID_HELP_VIEW_OPTION_MAKE_CONNECTION}
              text="Make connections"
              handler={() =>
                window.open(`${ROOT_NOTION_ROUTE}Make-connections-458b1ad2cc7a482ca2e43baee401115a?pvs=4`, '_blank')
              }
              isDark={isDark}
              Icon={() => <Arrow degree={135} isDark={isDark} />}
              isReversed
            />
          </StyledItem>
        </StyledList>
        {tutorialStatus !== ETutorialStatus.Finished && !settingsLoading && !isGuideTour && (
          <StyledInfo>
            <OnboardingAlert
              dataTestId={TEST_ID_HELP_VIEW_ITEM_ONBOARDING_TOUR_TAKE_THE_TOUR_BUTTON}
              title="The basics"
              submitText="Take the tour"
              handleSubmit={() => {
                push(ROUTE_PROFILE);
                setDiscoveryTab?.(EExploreTabs.Featured);
                setIsGuideTour?.(true);
                posthogCapture(POSTHOG_EVENTS.Onboarding);
                posthogCapture(POSTHOG_EVENTS.StartTourHelpTab);
              }}
              isDark={isDark}
              isTransparent
            >
              Let us give you a quick tour of how to browse, follow others and start adding your own picks. It just
              takes 2 minutes.
            </OnboardingAlert>
          </StyledInfo>
        )}
        <StyledTitle isDark={isDark}>Privacy and safety</StyledTitle>
        <StyledList isDark={isDark}>
          <StyledItem>
            <MenuButton
              dataTestId={TEST_ID_HELP_VIEW_BLOCKED_ACCOUNTS}
              text="Blocked accounts"
              handler={() => push(ROUTE_BLOCKED_ACCOUNTS)}
              isDark={isDark}
              Icon={ChevronLeft}
              isReversed
            />
          </StyledItem>
          <StyledItem>
            <MenuButton
              dataTestId={TEST_ID_HELP_VIEW_OPTION_PRIVACY}
              text="Our privacy approach"
              handler={() => push(ROUTE_PRIVACY_APPROACH)}
              isDark={isDark}
              Icon={ChevronLeft}
              isReversed
            />
          </StyledItem>
        </StyledList>
        <StyledTitle isDark={isDark}>About the app</StyledTitle>
        <StyledList isDark={isDark}>
          <StyledItem>
            <StyledListItem data-testid={TEST_ID_HELP_VIEW_OPTION_VERSION_TITLE_LABEL}>Version 1.01</StyledListItem>
          </StyledItem>
          <StyledItem>
            <MenuButton
              dataTestId={TEST_ID_HELP_VIEW_TERMS_AND_CONDITIONS}
              text="Terms and conditions"
              handler={() => push(ROUTE_TERMS)}
              isDark={isDark}
              Icon={ChevronLeft}
              isReversed
            />
          </StyledItem>
        </StyledList>
        <StyledTitle isDark={isDark}>Questions</StyledTitle>
        <StyledText isDark={isDark}>
          We&apos;re working on developing a full help service. For now, you can email any questions you may have to{' '}
          <a href="mailto:help@guidehuman.co">help@guidehuman.co</a>
        </StyledText>
      </StyledWrap>
    </SinglePageLayout>
  );
};

export default Help;
