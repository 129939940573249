import React, { FC } from 'react';

import { StyledItem } from 'components/AddContent/Menu/styled';
import { useChangeListView } from 'helpers/useContextMenu';

import Button from 'components/UI/MenuButton';

const ChangeView: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const { Icon: ChangeViewIcon, text: changeViewText, toggle: changeView } = useChangeListView();

  return (
    <StyledItem>
      <Button
        hasStroke
        text={changeViewText}
        Icon={ChangeViewIcon}
        handler={() => {
          handleClose();
          changeView?.();
        }}
      />
    </StyledItem>
  );
};

export default ChangeView;
