import { ApolloCache } from '@apollo/client';
import { GetMutedUsersDocument, GetMutedUsersQuery } from 'constants/graphqlTypes';

export const deleteMutedUserFromCache = (unmutedUserId: string, cache: ApolloCache<unknown>): void => {
  const mutedUsersList: GetMutedUsersQuery | null = cache?.readQuery({
    query: GetMutedUsersDocument,
    variables: { offset: 0 },
  });

  if (!mutedUsersList) {
    return;
  }

  cache?.writeQuery({
    query: GetMutedUsersDocument,
    variables: { offset: 0 },
    data: {
      ...mutedUsersList,
      allUsers: {
        ...mutedUsersList.allUsers,
        items: mutedUsersList.allUsers?.items.filter(({ id }) => id !== unmutedUserId),
        totalCount: mutedUsersList.allUsers?.totalCount ? mutedUsersList.allUsers?.totalCount - 1 : 0,
      },
    },
  });
};
