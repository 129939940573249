import React, { FC, useEffect, useState } from 'react';

import getAuthUser from 'helpers/getAuthUser';
import { CardCommentHighlightOptions } from 'constants/graphqlTypes';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import { useHalfTransparency } from 'components/Card/Actions/hooks';
import { useIsUserAllowedToReact } from 'components/Card/helpers/hooks';
import InteractionBlock from 'components/InteractionBlock';
import BadgeWithChildren from 'components/UI/BadgeWithChildren';
import AvatarGH from 'components/UI/AvatarGH';
import { StyledHeader } from 'components/UI/Highlight/styled';

import ReactionsModal from 'components/Reactions/ReactionsModal/ReactionsModal';
import FollowButton from 'components/FollowButton';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import ReactionQuickButton from 'components/Reactions/ReactionQuickButton/ReactionQuickButton';
import ReactionsToken from 'components/Reactions/ReactionsToken';
import { AnimationProvider, useAnimationToggle } from 'components/Reactions/Animations/hooks';

import { ReactionsProvider, useReactionContext } from 'components/Reactions/hooks';
import { useChatId } from 'graphQL/messages/hooks';
import { IThoughtItem } from '../helpers/helpers';
import {
  StyledInfo,
  StyledWrap,
  StyledContent,
  StyledQuickReactionsButtonWrap,
  StyledInfoWrapper,
  StyledHighlightedWrap,
  StyledText,
  StyledRelWrap,
  StyledButtonWrap,
  StyledClickArea,
} from './styled';
import ThoughtMenu from './ThoughtMenu';
import ThoughtLastUpdate from './ThoughtLastUpdate';

const { Highlighted, Expert, Interesting, Legal } = CardCommentHighlightOptions;

interface IThoughtProps {
  item: IThoughtItem;
  cardPickId: string;
  cardId: string;
  hideReaction: boolean;
}

const Thought: FC<IThoughtProps> = ({ item, cardPickId, cardId, hideReaction }) => {
  const { id, user, text, lastUpdate, responseToQuestion, guestListQuestion, responseToSpark, highlighted } = item;
  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();
  const [isReactionButtonMenuShown, setIsReactionButtonMenuShown] = useState(false);
  const [hasReactions, setHasReactions] = useState(false);

  const { setThoughtId } = useReactionContext();

  const { userId: myId } = getAuthUser();
  const { id: userId, pickedCards, username } = user;

  const isMyThought = myId === userId;
  const allowReport = !isMyThought && !!text;
  const isAllowedToReact = useIsUserAllowedToReact(user);
  const { chatId } = useChatId(userId);

  const { ref } = useHalfTransparency(isMyThought);

  useEffect(() => {
    if (hideReaction) {
      setIsQuickReactionOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideReaction]);

  const { isPlaying, emoji, toggleAnimation } = useAnimationToggle();

  useEffect(() => {
    setThoughtId?.(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isOptionsMenuOpened, setIsOptionsMenuOpened] = useState(false);

  const shouldOpenReactionMenu = allowReport && isAllowedToReact && !isMyThought && !isOptionsMenuOpened;

  const contentIds = {
    pickId: cardPickId,
    commentId: id,
  };

  return (
    <ReactionsProvider value={{ thoughtId: id, cardId: cardPickId, commentatorId: chatId ? null : userId }}>
      <AnimationProvider value={{ isPlaying, emoji, toggleAnimation }}>
        <StyledRelWrap ref={infoRef}>
          {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && (
            <ReactionMenu
              isShown={isQuickReactionOpen}
              reactionCoordinates={reactionCoordinates}
              handleClickOutside={() => setIsQuickReactionOpen(false)}
              minusTop={minusTop}
              setIsReactionsModalOpen={setIsReactionsModalOpen}
              setIsQuickReactionOpen={setIsQuickReactionOpen}
            />
          )}
          <InteractionBlock
            tapCallback={() => {
              setIsReactionButtonMenuShown(false);
              setIsQuickReactionOpen(false);
            }}
            longTapCallback={() => shouldOpenReactionMenu && setIsQuickReactionOpen(true)}
            getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
          >
            <StyledWrap>
              <StyledContent>
                <BadgeWithChildren user={user}>
                  {text ? (
                    <ThoughtMenu
                      cardPickId={cardPickId}
                      cardId={cardId}
                      commentId={id}
                      user={user}
                      allowReport={allowReport}
                      setIsOptionsMenuOpened={setIsOptionsMenuOpened}
                      hasReactions={hasReactions}
                      chatId={chatId}
                    />
                  ) : (
                    <FollowButton isDark user={user} />
                  )}
                </BadgeWithChildren>
                <StyledInfoWrapper>
                  {text && (
                    <StyledInfo isHighlighted={!!highlighted}>
                      <StyledHighlightedWrap>
                        {highlighted && (
                          <StyledHeader>
                            <AvatarGH />
                            {highlighted === Highlighted && 'Highlighted thought'}
                            {highlighted === Expert && 'Featured: expert perspective'}
                            {highlighted === Interesting && 'Featured: interesting thought'}
                            {highlighted === Legal && 'Featured: legal perspective'}
                          </StyledHeader>
                        )}
                      </StyledHighlightedWrap>
                      <StyledText ref={ref} isDragAllowed={allowReport} onClick={(e) => e.stopPropagation()}>
                        {text}
                      </StyledText>
                    </StyledInfo>
                  )}
                  {lastUpdate && (
                    <ThoughtLastUpdate
                      lastUpdate={lastUpdate}
                      username={username}
                      parentCard={pickedCards?.items[0]}
                      responseToQuestion={responseToQuestion}
                      guestListQuestion={guestListQuestion}
                      responseToSpark={responseToSpark}
                    />
                  )}
                </StyledInfoWrapper>
              </StyledContent>
              {isReactionsModalOpen && <ReactionsModal id={id} handleClose={() => setIsReactionsModalOpen(false)} />}
            </StyledWrap>
          </InteractionBlock>
          {text && isAllowedToReact && (
            <StyledButtonWrap>
              <StyledQuickReactionsButtonWrap>
                {!isMyThought && (
                  <ReactionQuickButton
                    isReactionButtonMenuShown={isReactionButtonMenuShown}
                    setIsReactionButtonMenuShown={setIsReactionButtonMenuShown}
                    isDark
                    entityId={id}
                    setIsReactionsModalOpen={setIsReactionsModalOpen}
                    isReactionMenuOpened={isQuickReactionOpen}
                  />
                )}
                <InteractionBlock
                  tapCallback={() => {
                    setIsReactionButtonMenuShown(false);
                    setIsQuickReactionOpen(false);
                  }}
                  longTapCallback={() => shouldOpenReactionMenu && setIsQuickReactionOpen(true)}
                  getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
                >
                  <StyledClickArea />
                </InteractionBlock>
                <ReactionsToken contentIds={contentIds} isDark setHasReactions={setHasReactions} />
              </StyledQuickReactionsButtonWrap>
            </StyledButtonWrap>
          )}
        </StyledRelWrap>
      </AnimationProvider>
    </ReactionsProvider>
  );
};

export default Thought;
