import { useEffect, useState } from 'react';

import { CardSchema } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';
import { getColor } from './helpers';
import { IUseDetailSliderBg } from './models';

export const useDetailSliderBg = (card: CardSchema, prev?: CardSchema, next?: CardSchema): IUseDetailSliderBg => {
  const [gradientStart, setGradientStart] = useState(card.cardStyle?.bgColor?.gradientStart ?? COLORS.black[100]);
  const [gradientEnd, setGradientEnd] = useState(card.cardStyle?.bgColor?.gradientEnd ?? COLORS.black[100]);

  const handleChange = (index: number) => {
    switch (index) {
      case 0: {
        setGradientStart(getColor('gradientStart', prev));
        setGradientEnd(getColor('gradientEnd', prev));
        break;
      }
      case 2: {
        setGradientStart(getColor('gradientStart', next));
        setGradientEnd(getColor('gradientEnd', next));
        break;
      }
      default: {
        setGradientStart(getColor('gradientStart', card));
        setGradientEnd(getColor('gradientEnd', card));
        break;
      }
    }
  };

  useEffect(() => {
    setGradientStart(getColor('gradientStart', card));
    setGradientEnd(getColor('gradientEnd', card));
  }, [card]);

  return { gradientStart, gradientEnd, handleChange };
};
