import { IState } from './models';

export enum EActionType {
  Init = 'Init',
  ChangeList = 'ChangeList',
  Mode = 'Mode',
  CardFocus = 'CardFocus',
  AddComment = 'AddComment',
  AddStyle = 'AddStyle',
  SaveStyle = 'SaveStyle',
  ChangeColor = 'ChangeColor',
}

export enum ECurrentMode {
  Default = 'Default',
  Comment = 'Comment',
  Style = 'Style',
}

export const DEFAULT_STATE: IState = {
  cards: [],
  listTitle: '',
  listId: '',
  listCardId: '',
  mode: ECurrentMode.Default,
  currentCardIndex: 0,
  dataToSend: [],
  savedStyleItems: [],
  newStyleItems: [],
  cardsColors: {},
};
