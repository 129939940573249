import { styled } from 'linaria/react';

import { FONTS, COLORS } from '../../styles/constants';

const StyledWrap = styled.div<{ isDark: boolean }>`
  z-index: 0;
  position: relative;
  min-height: 100dvh;
  margin: 0 auto;
  font-family: ${FONTS.primary};
  font-size: 16px;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: ${({ isDark }) => (isDark ? COLORS.black[100] : COLORS.white[100])};
`;

export const StyledVirtualBg = styled.div<{ isDark?: boolean }>`
  height: 200dvh;
  width: 200dvw;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-color: ${({ isDark }) => (isDark ? COLORS.black[100] : COLORS.white[100])};
  position: fixed;
  z-index: -200;
  user-select: none;
  pointer-events: none;
`;

export default StyledWrap;
