import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_OFF_PLATFORM_SHARING } from 'routes';
import { useMenuModal } from 'components/Navigation/helpers/hooks';
import Button from 'components/UI/MenuButton';
import { ReactComponent as Share } from 'assets/icons/Share.svg';
import { ESearchParams } from 'constants/common';

import { StyledItem } from 'components/AddContent/Menu/styled';
import ChangeView from './ChangeView';
import MenuWrap from './MenuWrap';

import { SHARE_TYPE_ALL_PICKS } from './constants';

const MyAllPicksButton: FC = () => {
  const { isModalOpen, handleModal } = useMenuModal();
  const { push } = useHistory();

  const handleShareContent = () => {
    push({
      pathname: ROUTE_OFF_PLATFORM_SHARING,
      search: `?${ESearchParams.Type}=${SHARE_TYPE_ALL_PICKS}`,
    });
  };

  return (
    <MenuWrap isOpen={isModalOpen} handleModal={handleModal}>
      <StyledItem>
        <Button text="Share" Icon={Share} handler={() => handleShareContent()} />
      </StyledItem>
      <ChangeView handleClose={() => handleModal(false)} />
    </MenuWrap>
  );
};

export default MyAllPicksButton;
