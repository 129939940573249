import { styled } from 'linaria/react';

export const StyledAlertButtons = styled.div<{ isSmall: boolean }>`
  align-items: ${({ isSmall }) => (isSmall ? 'stretch' : 'center')};
  display: flex;
  flex-direction: ${({ isSmall }) => (isSmall ? 'column' : 'row')};
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-start;
  margin-top: 16px;

  button {
    min-width: ${({ isSmall }) => (isSmall ? 'auto' : '150px')};
    width: ${({ isSmall }) => (isSmall ? '100%' : 'auto')};
    flex: 1;
  }
`;
