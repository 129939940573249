import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { StyledItem } from 'components/AddContent/Menu/styled';
import Button from 'components/UI/MenuButton';

import { ReactComponent as PaperPlaneIcon } from 'assets/icons/PaperPlane.svg';
import { ReactComponent as Share } from 'assets/icons/Share.svg';

import useOpenModal from 'helpers/useOpenModal';
import { ROUTE_OFF_PLATFORM_SHARING, ROUTE_SELECT_CHAT_MODAL } from 'routes';
import { MessageType } from 'constants/graphqlTypes';
import { ESearchParams } from 'constants/common';
import { useProfileContext } from 'helpers/useProfileProvider';

export interface IShareButtonsDataTestIds {
  shareBtnIds: { dataTestIdButton?: string; dataTestIdButtonLabel?: string };
  sendBtnIds: { dataTestIdButton?: string; dataTestIdButtonLabel?: string };
}

interface IShareButtons {
  closePreviousModal: () => void;
  sharedId: string;
  messageType: MessageType;
  showShare?: boolean;
  commentCardId?: string;
  hasFilter?: boolean;
  userId?: string;
  guestListCardId?: string;
  sparkPickId?: string;
  dataTestIds?: IShareButtonsDataTestIds;
}

const ShareButtons: FC<IShareButtons> = ({
  closePreviousModal,
  sharedId,
  commentCardId,
  messageType,
  showShare = true,
  hasFilter,
  userId,
  sparkPickId,
  dataTestIds,
}) => {
  const { shareBtnIds, sendBtnIds } = dataTestIds ?? {};

  const { push } = useHistory();
  const { gradientStart: profileGradientStart, gradientEnd: profileGradientEnd } = useProfileContext();
  const goToChatSelect = useOpenModal(ROUTE_SELECT_CHAT_MODAL, false, {
    entityId: sharedId,
    commentCardId,
    messageType,
    collectionId: hasFilter ? sharedId : null,
    profileGradientStart,
    profileGradientEnd,
  });

  const handleNavigate = () => {
    closePreviousModal();
    goToChatSelect();
  };

  const getShareId = () => {
    if (sparkPickId) {
      return sparkPickId;
    }

    return messageType === MessageType.Thought ? commentCardId : sharedId;
  };

  const handleShareContent = () =>
    push({
      pathname: ROUTE_OFF_PLATFORM_SHARING,
      search: `?${ESearchParams.Type}=${messageType}&${ESearchParams.Id}=${getShareId()}${
        userId ? `&${ESearchParams.UserId}=${userId}` : ''
      }`,
    });

  return (
    <>
      {showShare && (
        <StyledItem>
          <Button
            text="Share"
            Icon={Share}
            handler={() => handleShareContent()}
            dataTestId={shareBtnIds?.dataTestIdButton}
          />
        </StyledItem>
      )}
      <StyledItem>
        <Button
          text="Send on guide:human"
          Icon={PaperPlaneIcon}
          handler={handleNavigate}
          dataTestId={sendBtnIds?.dataTestIdButton}
        />
      </StyledItem>
    </>
  );
};

export default ShareButtons;
