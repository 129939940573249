import {
  CardSchema,
  ColorSchema,
  ECardLayoutOptions,
  ImageSetSchema,
  GetDetailListQuery,
  CardTitleObjectType,
} from 'constants/graphqlTypes';

export const getNewListBgs = (
  oldBgColors: ColorSchema[],
  cachedBgItems: CardSchema[],
  orderItems: CardSchema[],
  isTextListCover: boolean
): ColorSchema[] => {
  const newItems = cachedBgItems.reduce((prev: ColorSchema[], curr, currentIndex) => {
    if (isTextListCover) {
      return curr?.cardStyle?.bgColor && (currentIndex === 0 || currentIndex === cachedBgItems.length - 1)
        ? prev.concat(curr.cardStyle.bgColor)
        : prev;
    }
    return curr?.cardStyle?.bgColor &&
      curr?.cardStyle.layout !== ECardLayoutOptions.Plain &&
      (currentIndex === 0 || currentIndex === cachedBgItems.length - 1)
      ? prev.concat(curr.cardStyle.bgColor)
      : prev;
  }, []);

  return newItems.length ? newItems : oldBgColors;
};

export const getNewListImages = (oldImages: ImageSetSchema[], reducedCoverItems: CardSchema[]): ImageSetSchema[] => {
  const newImages = reducedCoverItems.reduce(
    (prev: ImageSetSchema[], curr) =>
      curr.cardStyle?.thumbnailImageSet ? prev.concat(curr.cardStyle?.thumbnailImageSet) : prev,
    []
  );
  return newImages.length ? newImages : oldImages;
};

export const getNewCoverTitles = (
  oldTitles: CardTitleObjectType[],
  reducedCoverItems: CardSchema[]
): CardTitleObjectType[] => {
  const newTitles = reducedCoverItems.map(
    ({ title }) => ({
      __typename: oldTitles[0].__typename,
      title,
    }),
    []
  );

  return newTitles.length ? newTitles : oldTitles;
};

export const reducePlainCards = (cards: CardSchema[]) =>
  cards.reduce(
    (prev: CardSchema[], curr) => (curr.cardStyle?.layout !== ECardLayoutOptions.Plain ? prev.concat(curr) : prev),
    []
  );

export const updateCachedDetailList = (
  data: GetDetailListQuery | null,
  orderItems: CardSchema[],
  reducedCoverItems: CardSchema[],
  cachedBgItems: CardSchema[],
  isTextListCover: boolean
): GetDetailListQuery | null => {
  if (!data?.allCards?.items[0].cardStyle?.collectionCover?.items) {
    return data;
  }

  return {
    ...data,
    allCards: {
      ...data.allCards,
      items: [
        {
          ...data.allCards.items[0],
          cardStyle: {
            ...data.allCards.items[0].cardStyle,
            collectionCover: {
              ...data.allCards.items[0].cardStyle?.collectionCover,
              ...(!isTextListCover &&
                data.allCards.items[0].cardStyle?.collectionCover?.items?.[0]?.__typename === 'ImageSetSchema' &&
                data.allCards.items[0].cardStyle.collectionCover.items.length === reducedCoverItems.length && {
                  items: getNewListImages(
                    data.allCards.items[0].cardStyle.collectionCover.items.reduce(
                      (prev: ImageSetSchema[], curr) =>
                        curr.__typename === 'ImageSetSchema' ? prev.concat(curr) : prev,
                      []
                    ),
                    reducedCoverItems
                  ),
                }),
              ...(isTextListCover &&
                data.allCards.items[0].cardStyle?.collectionCover?.items?.[0]?.__typename === 'CardTitleObjectType' &&
                data.allCards.items[0].cardStyle.collectionCover.items.length === reducedCoverItems.length && {
                  items: getNewCoverTitles(
                    data.allCards.items[0].cardStyle.collectionCover.items.reduce(
                      (prev: CardTitleObjectType[], curr) =>
                        curr.__typename === 'CardTitleObjectType' ? prev.concat(curr) : prev,
                      []
                    ),
                    reducedCoverItems
                  ),
                }),
            },
            ...(data.allCards.items[0].cardStyle?.collectionBgColors?.length === cachedBgItems.length && {
              collectionBgColors: getNewListBgs(
                data.allCards.items[0].cardStyle.collectionBgColors,
                cachedBgItems,
                orderItems,
                isTextListCover
              ),
            }),
          },
        },
      ],
    },
  };
};
