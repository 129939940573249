import React, { FC } from 'react';

import Avatars from 'components/UI/Avatars';
import { UserObjectType } from 'constants/graphqlTypes';
import ListCreatorsText from 'components/List/Content/ListCollabDescription/ListCreatorsText';
import {
  StyledCreators,
  StyledCreatorsAvatars,
  StyledCreatorsInfo,
  StyledCreatorsText,
} from 'components/Collection/styled';
import { getAvatarsLimit, getCirclesCount } from './helpers';

interface ICreatorsProps {
  creators: UserObjectType[];
  isDark: boolean;
  creatorsText?: string;
  creatorsTotalCount?: number;
  showCreators?: boolean;
  isSmall?: boolean;
  isFeaturedTab?: boolean;
}

const Creators: FC<ICreatorsProps> = ({
  creators,
  isDark,
  creatorsText,
  creatorsTotalCount,
  showCreators,
  isSmall,
  isFeaturedTab,
}) => {
  const totalCount = creatorsTotalCount ?? creators.length;

  return (
    <StyledCreators className="creators-container">
      <StyledCreatorsAvatars isSmall={isSmall}>
        <Avatars
          users={creators}
          total={totalCount}
          limit={getAvatarsLimit(!!isFeaturedTab, !!showCreators)}
          isBig={!isSmall}
          hideNumber={!isFeaturedTab}
        />
      </StyledCreatorsAvatars>
      <StyledCreatorsInfo circlesCount={getCirclesCount(!!isFeaturedTab, totalCount)}>
        <StyledCreatorsText isBold isDark={isDark}>
          <ListCreatorsText users={creators} totalCount={totalCount} isDark={isDark} showCreators={showCreators} />
        </StyledCreatorsText>
        <StyledCreatorsText isDark={isDark}>{creatorsText ?? 'Published a new list'}</StyledCreatorsText>
      </StyledCreatorsInfo>
    </StyledCreators>
  );
};

export default Creators;
