import { format, formatDistanceToNowStrict, intervalToDuration, isToday, isYesterday } from 'date-fns';

// Time stamp date & time format:
// Today -  XX min ago
// This week -  XX days ago
// Earlier -  X MMM YYYY (4 Jul 2022)

// TODO:  update using this helper
export const getWebDate = (date: string): Date => new Date(date);

export const getWeeklyDateFormat = (date: string): string => {
  const duration = intervalToDuration({ start: new Date(date), end: new Date() });

  if (duration.days !== undefined && duration.days < 7 && duration.months === 0) {
    return formatDistanceToNowStrict(new Date(date), {
      addSuffix: true,
      roundingMethod: 'floor',
    }).replace('minute', 'min');
  }

  return format(new Date(date), 'd MMM yyyy');
};

export const getDailyDateFormat = (date: string): string => {
  const currentDate = new Date(getWebDate(date)).setHours(0, 0, 0, 0);

  if (isToday(currentDate)) {
    return 'Today';
  }
  if (isYesterday(currentDate)) {
    return 'Yesterday';
  }
  return format(getWebDate(date), 'eee dd MMM');
};
