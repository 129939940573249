import React, { Dispatch, FC } from 'react';

import { ECardRoleTypeOptions } from 'constants/graphqlTypes';
import { StyledRoleItem, StyledRoleList, StyledText } from './styled';

interface ISelectOptionsListProps {
  selectedOption: { name: string; value: ECardRoleTypeOptions; description: string };
  selectOptions: { name: string; value: ECardRoleTypeOptions; description: string }[];
  selectOptionHandler: Dispatch<
    React.SetStateAction<{ name: string; value: ECardRoleTypeOptions; description: string }>
  >;
}

const SelectOptionsList: FC<ISelectOptionsListProps> = ({ selectedOption, selectOptions, selectOptionHandler }) => (
  <StyledRoleList>
    {selectOptions.map((selectOption) => (
      <StyledRoleItem
        key={selectOption.value}
        isActive={selectOption.value === selectedOption.value}
        onClick={() => selectOptionHandler(selectOption)}
      >
        <StyledText>
          {selectOption.name}
          <br />
          <i>{selectOption.description}</i>
        </StyledText>
      </StyledRoleItem>
    ))}
  </StyledRoleList>
);

export default SelectOptionsList;
