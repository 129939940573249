import { styled } from 'linaria/react';

import { COLORS, FONTS } from '../../../styles/constants';

export const StyledWrap = styled.div<{ isDark: boolean }>`
  margin-bottom: -48px;

  > p {
    box-sizing: content-box;
    min-height: calc(22px * 1.3);
    color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    font-size: 22px;
    line-height: 130%;
    margin: 0;
    padding: 0 24px 8px;
  }
`;

export const StyledDescription = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: block;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 19.6px;
  padding: 0 24px 0;
  white-space: break-spaces;
`;
