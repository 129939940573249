import React, { FC } from 'react';
import { v4 } from 'uuid';

import useTheme from 'helpers/useTheme';
import { COLORS } from 'styles/constants';

import { StyledSlider, StyledSliderImage, StyledSliderTrack, StyledSliderText } from './styled';

interface ISlider {
  isImages: boolean;
  items: string[];
  isAllPicks?: boolean;
  pauseAnimation?: boolean;
  isOnboarding?: boolean;
}

const Slider: FC<ISlider> = ({ items, isImages, pauseAnimation, isAllPicks, isOnboarding }) => {
  const isDark = useTheme();

  const color = isAllPicks && !isDark && isImages ? COLORS.brown.dark[100] : COLORS.white[100];

  return (
    <StyledSlider isImages={isImages}>
      <StyledSliderTrack
        pauseAnimation={pauseAnimation}
        itemsLength={items.length}
        isImages={isImages}
        onlyOneItem={items.length === 1 && isImages}
      >
        {isImages ? (
          <>
            {items.map((img) => (
              <StyledSliderImage key={v4()} src={img} alt="" />
            ))}
            {items.map((img) => (
              <StyledSliderImage key={v4()} src={img} alt="" />
            ))}
            {items.map((img) => (
              <StyledSliderImage key={v4()} src={img} alt="" />
            ))}
          </>
        ) : (
          <>
            {items.map((title) => (
              <StyledSliderText isOnboarding={isOnboarding} key={v4()} color={color}>
                {title}
              </StyledSliderText>
            ))}
            {items.map((title) => (
              <StyledSliderText isOnboarding={isOnboarding} key={v4()} color={color}>
                {title}
              </StyledSliderText>
            ))}
            {items.map((title) => (
              <StyledSliderText isOnboarding={isOnboarding} key={v4()} color={color}>
                {title}
              </StyledSliderText>
            ))}
            {items.map((title) => (
              <StyledSliderText isOnboarding={isOnboarding} key={v4()} color={color}>
                {title}
              </StyledSliderText>
            ))}
            {items.map((title) => (
              <StyledSliderText isOnboarding={isOnboarding} key={v4()} color={color}>
                {title}
              </StyledSliderText>
            ))}
            {items.map((title) => (
              <StyledSliderText isOnboarding={isOnboarding} key={v4()} color={color}>
                {title}
              </StyledSliderText>
            ))}
            {items.map((title) => (
              <StyledSliderText isOnboarding={isOnboarding} key={v4()} color={color}>
                {title}
              </StyledSliderText>
            ))}
            {items.map((title) => (
              <StyledSliderText isOnboarding={isOnboarding} key={v4()} color={color}>
                {title}
              </StyledSliderText>
            ))}
          </>
        )}
      </StyledSliderTrack>
    </StyledSlider>
  );
};

export default Slider;
