import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import { GUEST_LIST_GRADIENT, COLORS, FONTS } from 'styles/constants';

export const StyledLink = styled(Link)<{ bg?: string }>`
  aspect-ratio: 1;
  background-image: ${({ bg }) => (bg ? `url(${bg})` : GUEST_LIST_GRADIENT)};
  background-size: cover;
  border-radius: 10px;
  color: ${COLORS.white[100]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 20px 12px 0;
  position: relative;
  text-decoration: none;
`;

export const StyledTitle = styled.span`
  display: -webkit-box;
  font: 400 22px/130% ${FONTS.primary};
  word-spacing: 100vw;
  word-break: break-all;
  overflow: hidden;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
`;

export const StyledDescription = styled.span`
  display: block;
  font: 400 14px/140% ${FONTS.secondary};
  padding: 12px;
  margin: 0 -12px;

  span {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
