export interface IUseMessageIds {
  pickId?: string;
  listId?: string;
  userId?: string;
  commentId?: string;
  commentCardId?: string;
  linkId?: string;
  guestListSeriesId?: string;
  guestListId?: string;
  invitationId?: string;
  sparkId?: string;
}

export enum EMessageContentType {
  Pick = 'Pick',
  List = 'List',
  Thought = 'Thought',
  Profile = 'Profile',
  Spark = 'Spark',
}
