import React, { Dispatch, FC, Fragment, SetStateAction, useRef, KeyboardEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';

import Button from 'components/UI/Button';
import { EUiButtonType } from 'components/UI/Button/constants';
import useTheme from 'helpers/useTheme';
import { useRemoveSearchParam } from 'helpers/routingHelper';
import { useAddToRecentSearches } from 'graphQL/search/hooks';
import { ROUTE_SEARCH_RESULT } from 'routes';

import { ESearchTypeOptions } from 'constants/graphqlTypes';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';

import { ESearchParams } from 'constants/common';
import { TEST_SEARCH_CANCEL_BTN, TEST_SEARCH_INPUT } from 'constants/aqa/search';
import { useOnFocus } from './helpers/hooks';

import { INPUT_REGEX } from './helpers/constants';

import { StyledWrap, StyledInput, StyledLabel, StyledInputResult, StyledInputRow, StyledTextContainer } from './styled';
import { getSearchValue } from './helpers/helpers';

const ComplexSearchInput: FC<{
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  onFocusHandler: () => void;
  onBlurHandler: () => void;
  isItemSelected: boolean;
}> = ({ inputValue, setInputValue, onFocusHandler, onBlurHandler, isItemSelected }) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const isDark = useTheme();
  const { push, goBack } = useHistory();

  const { addToRecentSearches } = useAddToRecentSearches();
  const removeSearchParam = useRemoveSearchParam(ESearchParams.Search);
  useOnFocus(inputRef, inputValue);

  const elements = inputValue.split(' ').map((textSlice, index, arr) => {
    if (inputValue.includes('type:')) {
      return <span key={v4()}>{textSlice}&nbsp;</span>;
    }
    if (INPUT_REGEX.test(textSlice) && index < arr.length - 1) {
      return <span key={v4()}>{textSlice}&nbsp;</span>;
    }
    return <Fragment key={v4()}>{textSlice}&nbsp;</Fragment>;
  });

  const handleEnter = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    const searchValue = getSearchValue(inputValue, isItemSelected);
    if (key === 'Enter' && searchValue) {
      addToRecentSearches(searchValue, ESearchTypeOptions.Keyword);
      push({ pathname: ROUTE_SEARCH_RESULT, search: `?${ESearchParams.Search}=${encodeURIComponent(searchValue)}` });
    }
  };

  const handleRemove = () => {
    setInputValue('');
    removeSearchParam();
  };

  const spanElement = spanRef.current;

  const scrollHandler = () => {
    spanElement?.scrollTo({ left: inputRef.current?.scrollLeft });
  };

  useEffect(() => {
    inputRef.current?.addEventListener('selectionchange', scrollHandler);

    return () => inputRef.current?.removeEventListener('selectionchange', scrollHandler);
  }, []);

  return (
    <StyledWrap>
      <StyledInput isDark={isDark}>
        <StyledLabel isDark={isDark}>
          <SearchIcon />
        </StyledLabel>
        <StyledTextContainer>
          <StyledInputResult ref={spanRef} isDark={isDark} maxWidth={inputRef.current?.clientWidth}>
            {elements}
          </StyledInputResult>
          <StyledInputRow
            data-testid={TEST_SEARCH_INPUT}
            autoFocus
            onChange={(e) => {
              setInputValue(e.target.value);
              scrollHandler();
            }}
            onScroll={scrollHandler}
            onSelect={scrollHandler}
            onKeyPress={handleEnter}
            placeholder="Search"
            ref={inputRef}
            type="text"
            value={inputValue}
            isDark={isDark}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
          />
        </StyledTextContainer>
        {!!inputValue.length && (
          <StyledLabel isLast isPointer isDark={isDark} onClick={handleRemove}>
            <CrossIcon />
          </StyledLabel>
        )}
      </StyledInput>
      <Button
        dataTestId={TEST_SEARCH_CANCEL_BTN}
        text="Cancel"
        handler={() => (inputValue.length ? goBack() : null)}
        isDark={isDark}
        type={EUiButtonType.Transparent}
      />
    </StyledWrap>
  );
};

export default ComplexSearchInput;
