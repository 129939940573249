import React, { FC, useState, useRef, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as TrashIcon } from 'assets/icons/TrashCan.svg';
import { ReactComponent as LeaveIcon } from 'assets/icons/Leave.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import InteractionBlock from 'components/InteractionBlock';
import FlatButton from 'components/UI/FlatButton';
import UILink from 'components/UI/Link/Link';
import LeaveGroupChatButton from 'components/Messages/ChatSettings/Buttons/LeaveGroupChatButton';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';

import { useSingleChat } from 'graphQL/messages/hooks';

import { getWeeklyDateFormat } from 'helpers/getDateFormat';
import useClickOutside from 'helpers/useClickOutside';
import usePageType from 'helpers/usePageType';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import { ROUTE_MESSAGES_CHAT } from 'routes';
import { EReportTypes } from 'components/Report/constants';

import { UserShortSchema } from 'constants/graphqlTypes';
import { StyledUsername as StyledFullname, StyledUsername } from 'components/Sparks/Views/Card/styled';
import {
  StyledContentWrap,
  StyledInfo,
  StyledInteractionArea,
  StyledMessage,
  StyledText,
  StyledTitle,
  StyledTitleWrap,
  StyledWrap,
  StyledContentLink,
  StyledTruncateContainer,
  StyledMore,
} from '../styled';

interface IContentItem {
  chatId: string;
  isDark: boolean;
  hasUnreaded: boolean;
  isUserVerified?: boolean;
  hasCustomTitle?: boolean;
  title: string;
  lastActionAt: string;
  text?: string | null;
  username?: string | null;
  moreNumber?: number;
  firstUsers?: UserShortSchema[];
  userId?: string;
  isBlockedByMe?: boolean | null;
}
const { Chat, User } = EReportTypes;

const ContentItem: FC<IContentItem> = ({
  chatId,
  isDark,
  hasUnreaded,
  title,
  username,
  text,
  lastActionAt,
  children,
  moreNumber,
  isUserVerified,
  hasCustomTitle,
  firstUsers,
  userId,
  isBlockedByMe,
}) => {
  const [isShown, setIsShown] = useState(false);
  const { pathname } = useLocation();

  const chat = useSingleChat(chatId);
  const isGroupChat = chat?.__typename === 'GroupChatSchema';

  const toReport = useNavigateToReport({
    chatId,
    id: userId,
    pathname,
    isBlockedByMe,
    type: isGroupChat ? Chat : User,
  });

  const ref = useRef<HTMLDivElement>(null);
  const { isMessagesSearch } = usePageType();

  useClickOutside(ref, () => setIsShown(false));

  return (
    <StyledContentWrap ref={ref}>
      <LeaveGroupChatButton
        chatId={chatId}
        isGroupChat={isGroupChat}
        handleAccept={() => setIsShown(false)}
        handleCancel={() => setIsShown(false)}
      >
        <FlatButton Icon={isGroupChat ? LeaveIcon : TrashIcon} width={isShown ? 70 : 0} isRed />
      </LeaveGroupChatButton>
      <FlatButton Icon={ReportIcon} onClick={() => toReport()} width={isShown ? 70 : 0} />
      <UILink route={ROUTE_MESSAGES_CHAT} params={{ id: chatId }} isDisabled={isShown}>
        <StyledContentLink>
          <StyledWrap hasIndicator={hasUnreaded} hasRightPadding={isShown}>
            {children}
            <StyledInfo>
              <StyledTitleWrap hideGap>
                {isGroupChat ? (
                  <>
                    {hasCustomTitle ? (
                      <StyledTitle isDark={isDark} isBold>
                        <StyledUsername>{title}</StyledUsername>
                      </StyledTitle>
                    ) : (
                      <StyledTruncateContainer isBold isDark={isDark} id={chatId}>
                        {firstUsers?.map((user, index) => (
                          <Fragment key={user.username}>
                            <StyledFullname>
                              {!!index && ', '}
                              {generateFullName(user).trim()}
                            </StyledFullname>
                            {user.isVerified && <VerificationBadge />}
                          </Fragment>
                        ))}
                        {!!moreNumber && moreNumber > 0 && (
                          <StyledMore isDark={isDark}>&nbsp;{`and ${moreNumber} more`}</StyledMore>
                        )}
                      </StyledTruncateContainer>
                    )}
                  </>
                ) : (
                  <>
                    <StyledTitle isDark={isDark} isBold>
                      <StyledUsername>{title}</StyledUsername>
                      {isUserVerified && <VerificationBadge />}
                    </StyledTitle>
                  </>
                )}
              </StyledTitleWrap>
              <StyledMessage>
                {text && !username ? (
                  <StyledText isDark={isDark} isBold={hasUnreaded}>
                    {`${text} · `}
                  </StyledText>
                ) : (
                  <StyledText isDark={isDark} isBold={false}>
                    {username}
                    {!!moreNumber && username && <>&nbsp;{`and ${moreNumber} more`}</>}
                  </StyledText>
                )}
                {!username && (
                  <StyledText isDark={isDark} isBold={hasUnreaded} fixedWidth>
                    {getWeeklyDateFormat(lastActionAt)}
                  </StyledText>
                )}
              </StyledMessage>
            </StyledInfo>
            <InteractionBlock
              swipeLeftCallback={() => !isMessagesSearch && setIsShown(true)}
              swipeRightCallback={() => setIsShown(false)}
            >
              <StyledInteractionArea />
            </InteractionBlock>
          </StyledWrap>
        </StyledContentLink>
      </UILink>
    </StyledContentWrap>
  );
};

export default ContentItem;
