import React, { FC } from 'react';

import { IReplaceParams } from 'helpers/routingHelper';
import UILink from 'components/UI/Link';

import { StyledText } from 'components/Sparks/Views/Card/styled';
import { StyledWrap, StyledQuestionHeader, StyledQuestionWrap, StyledQuestionInfo } from './styled';
import { StyledUsername, StyledUsernameWrap } from '../BadgeWithFollowing/styled';

interface IAttributeProps {
  title?: string;
  text: string;
  isFullBleed?: boolean;
  isDark?: boolean;
  hasBorder?: boolean;
  children?: JSX.Element;
  route: string;
  params?: IReplaceParams;
  isSpark?: boolean;
}

const Attribute: FC<IAttributeProps> = ({
  title,
  text,
  isFullBleed,
  route,
  params,
  isDark,
  hasBorder,
  children,
  isSpark,
}) => {
  return (
    <StyledWrap isFullBleed={isFullBleed}>
      <UILink route={route} params={params}>
        <StyledQuestionWrap isDark={!!isDark} isFullBleed={isFullBleed} hasBorder={hasBorder}>
          {children}
          <StyledQuestionInfo>
            {isSpark ? (
              <StyledUsernameWrap>
                <StyledUsername>{title}’s</StyledUsername>
                <StyledText>&nbsp;spark</StyledText>
              </StyledUsernameWrap>
            ) : (
              <StyledQuestionHeader>{title}</StyledQuestionHeader>
            )}
            <span>{text}</span>
          </StyledQuestionInfo>
        </StyledQuestionWrap>
      </UILink>
    </StyledWrap>
  );
};

export default Attribute;
