import React, { FC, useState, useEffect, Dispatch, SetStateAction, useMemo } from 'react';

import { useGetChatMessageReactions, useGetMessageContent } from 'graphQL/messages/hooks';
import { getListStyles } from 'components/Collection/helpers';
import { MessageItemType, MessageSchema, MessageType, MessageContentStatus } from 'constants/graphqlTypes';
import { EQueryFetchPolicy } from 'constants/common';

import { ROUTE_LIST_DETAIL } from 'routes';
import { COLORS } from 'styles/constants';
import { SMALL_CARD_HEIGHT, SIZE_RATIO } from 'components/List/SmallCard/constants';
import { countedSizes } from 'components/List/SmallCard/helpers';

import UILink from 'components/UI/Link';
import Grain from 'components/UI/Grain';
import { v4 } from 'uuid';
import ReactionsToken from 'components/Messages/Message/ReactionsToken';
import { useMessagesChatData } from 'components/Messages/helpers/hooks';
import InviteActions from './InviteActions';
import { StyledTokenWrap, StyledTextMessage } from '../styled';
import { EMessageContentType } from '../models';
import UnavailableContent from '../Shared/UnavailableContent';
import {
  StyledRelWrap,
  StyledWrap,
  StyledTop,
  StyledBottom,
  StyledInner,
  StyledImagesList,
  StyledImageItem,
  StyledInviteText,
  StyledTextWrap,
  StyledTextItem,
  StyledTextsList,
} from './styled';

interface IInviteProps {
  id: string;
  contentReactionId?: string;
  message: MessageSchema;
  pickId?: string;
  isDark: boolean;
  isMine: boolean;
  messageRef: React.RefObject<HTMLDivElement>;
  setIsContentDeleted: Dispatch<SetStateAction<boolean>>;
  nextMessageType?: MessageType | null;
}

const Invite: FC<IInviteProps> = ({
  id,
  contentReactionId,
  pickId,
  isDark,
  isMine,
  setIsContentDeleted,
  messageRef,
  message,
  nextMessageType,
}) => {
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  const { chatId } = useMessagesChatData();
  const { reactions } = useGetChatMessageReactions(chatId, message?.id || '');

  const { invitation, collection, messageContentStatus } = useGetMessageContent({
    itemType: MessageItemType.Invite,
    entityId: id,
    pickId,
    contentReactionId,
    fetchPolicy: EQueryFetchPolicy.NetworkOnly,
  });
  const { id: invitationId = '', status, roleName, inviter } = invitation ?? {};
  const { bgColor, titles, images } = getListStyles(collection);
  const isEmptyList = images.length === 0 && titles.length === 0;

  const titlesWithKey = useMemo(
    () =>
      titles.map((cover) => ({
        cover,
        key: v4(),
      })),
    [titles.length, titles.join(','), v4]
  );

  useEffect(() => {
    setIsContentDeleted(messageContentStatus === MessageContentStatus.Deleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageContentStatus]);

  if (
    messageContentStatus === MessageContentStatus.Unavailable ||
    messageContentStatus === MessageContentStatus.Deleted
  ) {
    return (
      <UnavailableContent
        isDark={isDark}
        isMine={isMine}
        contentType={EMessageContentType.List}
        messageContentStatus={messageContentStatus}
      />
    );
  }

  const contentIds = {
    chatId,
    messageId: message?.id || '',
  };

  const cardHeight = SMALL_CARD_HEIGHT;
  const sizes = countedSizes(cardHeight, SIZE_RATIO);

  return invitation ? (
    <>
      <StyledRelWrap isRight={isMine}>
        <UILink
          route={ROUTE_LIST_DETAIL}
          params={{ listId: collection?.id }}
          isDisabled={!!(collection?.isPrivateList && !collection?.isPickedByMe) || isConfirmationOpen}
        >
          <StyledWrap bgColor={bgColor} ref={messageRef} hasItems={!isEmptyList}>
            <StyledInner>
              <Grain />
              {(!!titlesWithKey.length || !!images.length) && (
                <StyledTop>
                  {!!images.length && (
                    <StyledImagesList
                      isMultiple={images.length >= 4}
                      className={`images-${images.length >= 4 ? 4 : images.length}`}
                    >
                      {images.length === 1 && (
                        <StyledImageItem src={images[0]?.images?.[0]?.url ?? ''} size={sizes.m} />
                      )}
                      {images.length === 2 && (
                        <>
                          <StyledImageItem src={images[1]?.images?.[0]?.url ?? ''} size={sizes.m} />
                          <StyledImageItem src={images[0]?.images?.[0]?.url ?? ''} size={sizes.m} />
                        </>
                      )}
                      {images.length === 3 && (
                        <>
                          <StyledImageItem src={images[2]?.images?.[0]?.url ?? ''} size={sizes.s} />
                          <StyledImageItem src={images[1]?.images?.[0]?.url ?? ''} size={sizes.s} />
                          <StyledImageItem src={images[0]?.images?.[0]?.url ?? ''} size={sizes.s} />
                        </>
                      )}
                      {images.length >= 4 && (
                        <>
                          <StyledImageItem src={images[3]?.images?.[0]?.url ?? ''} size={sizes.s} />
                          <StyledImageItem src={images[2]?.images?.[0]?.url ?? ''} size={sizes.s} />
                          <StyledImageItem src={images[1]?.images?.[0]?.url ?? ''} size={sizes.s} />
                          <StyledImageItem src={images[0]?.images?.[0]?.url ?? ''} size={sizes.s} />
                        </>
                      )}
                    </StyledImagesList>
                  )}
                  {!!titlesWithKey.length && (
                    <StyledTextWrap bgColor={bgColor}>
                      <StyledTextsList bgColor={bgColor} isLarge={false} size={sizes.l}>
                        {titlesWithKey.map(({ cover, key }, i) => (
                          <StyledTextItem
                            className="cover-text-item"
                            key={key}
                            opacity={1 - (1 / titlesWithKey.length) * i}
                            color={COLORS.white[100]}
                          >
                            {cover}
                          </StyledTextItem>
                        ))}
                      </StyledTextsList>
                    </StyledTextWrap>
                  )}
                </StyledTop>
              )}
              <StyledBottom hasItems={!isEmptyList}>
                <InviteActions
                  isDark={isDark}
                  hasItems={!isEmptyList}
                  title={collection?.title}
                  inviteId={invitationId}
                  pickId={collection?.id}
                  cardId={collection?.cardId}
                  user={inviter}
                  status={status}
                  roleName={roleName}
                  isConfirmationOpen={isConfirmationOpen}
                  setConfirmationOpen={setConfirmationOpen}
                />
              </StyledBottom>
            </StyledInner>
          </StyledWrap>
        </UILink>
        <StyledTokenWrap className="token-wrap" isRight={isMine}>
          <ReactionsToken contentIds={contentIds} isDark={isDark} />
        </StyledTokenWrap>
      </StyledRelWrap>
      {invitation?.message && (
        <StyledInviteText
          isRight={isMine}
          hasReactions={!!reactions?.totalCount}
          hasBottomMargin={nextMessageType === MessageType.Invite}
        >
          <StyledTextMessage isMine={isMine} isDark={isDark}>
            {invitation.message}
          </StyledTextMessage>
        </StyledInviteText>
      )}
    </>
  ) : null;
};

export default Invite;
