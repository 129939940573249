export enum EChatField {
  AllChats = 'allChats',
  SearchChats = 'searchChats',
}

export enum ENotificationSettingField {
  NEW_MESSAGE = 'New messages',
  NEW_MENTION = 'New @ mentions',
  NEW_ACTIVITY = 'New activity',
}

export const CHAT_MESSAGES_LIMIT = 30;
