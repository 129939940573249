import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

interface IStyledCheckboxProps {
  isDark: boolean;
  isChecked: boolean;
  isTransparent?: boolean;
}

const StyledCheckbox = styled.div<IStyledCheckboxProps>`
  background-color: ${({ isDark, isChecked, isTransparent }) => {
    if (isTransparent && !isChecked) return 'transparent';
    return isDark ? COLORS.white[isChecked ? 100 : 30] : COLORS.brown.dark[isChecked ? 100 : 30];
  }};
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  border-radius: 4px;
  display: flex;
  height: 24px;
  position: relative;
  width: 24px;

  input {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  svg {
    bottom: 1px;
    position: relative;
    right: 1px;
  }

  path {
    stroke: ${({ isDark, isChecked }) => {
      if (!isChecked) {
        return COLORS.transparent;
      }

      return isDark ? COLORS.brown.dark[100] : COLORS.white[100];
    }};
  }
`;

export default StyledCheckbox;
