import React, { FC } from 'react';

import getImages from 'helpers/getImages';
import UILink from 'components/UI/Link';

import { CardSchema } from 'constants/graphqlTypes';
import { useNavigationRef } from 'components/Navigation/helpers/hooks';
import { COLORS } from 'styles/constants';
import { ROUTE_GUEST_LIST_SERIES } from 'routes';
import { StyledSubtitle } from 'components/UI/ContentSubtitle/styled';
import { StyledHeader, StyledTitle, StyledAnnotation, StyledSeries, StyledWrap } from './styled';

const Header: FC<{ card: CardSchema; isSeries?: boolean; isDark: boolean }> = ({ card, isDark, isSeries }) => {
  const { title, description, cardStyle, guestListSeries } = card;
  const seriesId = guestListSeries?.guestListSeriesCard?.id;
  const { bgUrl } = getImages(cardStyle);
  const { title: seriesTitle } = guestListSeries ?? {};

  const color = isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  const ref = useNavigationRef(bgUrl ? COLORS.white[100] : color);

  return card ? (
    <>
      <StyledHeader ref={ref} bg={bgUrl} />
      <StyledWrap>
        {!isSeries && seriesId && (
          <UILink route={ROUTE_GUEST_LIST_SERIES} params={{ id: seriesId }}>
            <StyledAnnotation color={color} isDark={isDark}>
              The guest:list
              <StyledSeries isDark={isDark}>{`| ${seriesTitle}`}</StyledSeries>
            </StyledAnnotation>
          </UILink>
        )}
        {title && !isSeries && (
          <StyledTitle color={color} isDark={isDark}>
            {title}
          </StyledTitle>
        )}
        <StyledSubtitle color={color} isDark={isDark}>
          {description}
        </StyledSubtitle>
      </StyledWrap>
    </>
  ) : null;
};

export default Header;
