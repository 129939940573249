import React, { FC } from 'react';

import UserBlockedWarning from 'components/UserBlockedWarning';
import Button from 'components/UI/Button';

import { useBlockUserWarning } from 'components/UserBlockedWarning/hooks';
import { UserObjectType } from 'constants/graphqlTypes';
import { useFollowUserOpenTopics } from 'graphQL/follows/hooks';
import BlockButton from 'components/Blocking/UserButton';
import getAuthUser from 'helpers/getAuthUser';
import { EUiButtonType } from 'components/UI/Button/constants';

interface IFollowButtonProps {
  isDark: boolean;
  user: UserObjectType;
  buttonType?: EUiButtonType;
  dataTestId?: string;
  isDisabled?: boolean;
}

const FollowButton: FC<IFollowButtonProps> = ({ isDark, user, buttonType, dataTestId, isDisabled }) => {
  const { id, isFollowedByMe, isBlockedByMe } = user;
  const { userId } = getAuthUser();

  const { handleFollowWithChecks, loading } = useFollowUserOpenTopics(user);

  const { showBlockWarning, closeModal, checkUserBlock } = useBlockUserWarning(handleFollowWithChecks, user);

  return isBlockedByMe ? (
    <BlockButton userId={id} isBlockedByMe={isBlockedByMe}>
      <Button dataTestId={dataTestId} isDark={isDark} text="Unblock" />
    </BlockButton>
  ) : (
    <>
      {userId !== id && (
        <>
          <Button
            dataTestId={dataTestId}
            type={buttonType || (isFollowedByMe ? EUiButtonType.Primary : EUiButtonType.Emphasized)}
            text={isFollowedByMe ? 'Following' : 'Follow'}
            isDark={isDark}
            handler={() => (isDisabled ? null : checkUserBlock())}
            loading={loading}
            isDisabled={loading}
          />
          {showBlockWarning && <UserBlockedWarning isBlockedByMe={false} handleClose={closeModal} />}
        </>
      )}
    </>
  );
};

export default FollowButton;
