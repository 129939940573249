import { WEB_PREFIX } from 'constants/common';

export const ADD_FROM_LINK_VIEW = 'add-from-link-view';
export const PROFILE_VIEW = 'profile-view';

export const TEST_ID_ADD_FROM_LINK_START = `${WEB_PREFIX}${ADD_FROM_LINK_VIEW}`;
export const TEST_ADD_FROM_LINK_TITLE_LABEL = `${TEST_ID_ADD_FROM_LINK_START}_title-label`;
export const TEST_ADD_FROM_LINK_ADD_PICK_BTN = `${TEST_ID_ADD_FROM_LINK_START}_add-pick-button`;
export const TEST_ADD_FROM_LINK_CREATE_LIST_BTN = `${TEST_ID_ADD_FROM_LINK_START}_create-list-button`;
export const TEST_ADD_FROM_LINK_START_SPARK_BTN = `${TEST_ID_ADD_FROM_LINK_START}_start-spark-button`;

export const CREATE_COLLECTION = 'create-collection';
export const TEST_ID_CREATE_COLLECTION_START = `${WEB_PREFIX}${CREATE_COLLECTION}`;
export const TEST_CREATE_COLLECTION_TITLE = `${TEST_ID_CREATE_COLLECTION_START}_title-label`;
export const TEST_CREATE_COLLECTION_INPUT = `${TEST_ID_CREATE_COLLECTION_START}_list-name-text-field`;
export const TEST_CREATE_COLLECTION_PRIVATE_BTN = `${TEST_ID_CREATE_COLLECTION_START}_private-toggle`;

export const ADD_DESCRIPTION_VIEW = 'add-description-view';
export const TEST_ID_ADD_DESCRIPTION_START = `${WEB_PREFIX}${ADD_DESCRIPTION_VIEW}`;
export const TEST_ADD_DESCRIPTION_INPUT = `${TEST_ID_ADD_DESCRIPTION_START}_description-text-field`;
export const TEST_ADD_DESCRIPTION_CREATE_BTN = `${TEST_ID_ADD_DESCRIPTION_START}_create-button`;

export const EXTRACT_FROM_LINK_VIEW = 'extract-from-link-view';
export const TEST_ID_EXTRACT_FROM_LINK_START = `${WEB_PREFIX}${EXTRACT_FROM_LINK_VIEW}`;
export const TEST_EXTRACT_FROM_LINK_TITLE = `${TEST_ID_EXTRACT_FROM_LINK_START}_title-label`;
export const TEST_EXTRACT_FROM_LINK_INPUT = `${TEST_ID_EXTRACT_FROM_LINK_START}_link-text-field`;
export const TEST_EXTRACT_FROM_LINK_PASTE_LINK_BTN = `${TEST_ID_EXTRACT_FROM_LINK_START}_paste-link-button`;

export const ADD_TO_LIST_VIEW = 'add-to-list-view';
export const TEST_ID_ADD_TO_LIST_START = `${WEB_PREFIX}${ADD_TO_LIST_VIEW}`;
export const TEST_ADD_TO_LIST_TITLE = `${TEST_ID_ADD_TO_LIST_START}_title-label`;
export const TEST_ADD_TO_LIST_ORGANIZE_LATER = `${TEST_ID_ADD_TO_LIST_START}_organize-later-item`;
export const TEST_ADD_TO_LIST_CREATE_LIST_BTN = `${TEST_ID_ADD_TO_LIST_START}_create-list-button`;

export const EDIT_CARD_STYLE_VIEW = 'edit-card-style-view';
export const TEST_ID_EDIT_CARD_STYLE_START = `${WEB_PREFIX}${EDIT_CARD_STYLE_VIEW}`;
export const TEST_EDIT_CARD_STYLE_CAROUSEL = `${TEST_ID_EDIT_CARD_STYLE_START}_styles-carousel`;
export const TEST_EDIT_CARD_STYLE_CAROUSEL_IMAGE_SECTION = `${TEST_EDIT_CARD_STYLE_CAROUSEL}_styles-section-0`;
export const TEST_EDIT_CARD_STYLE_CAROUSEL_TEXT_SECTION = `${TEST_EDIT_CARD_STYLE_CAROUSEL}_styles-section-1`;
export const TEST_EDIT_CARD_STYLE_CAROUSEL_REPORT_BTN = `${TEST_EDIT_CARD_STYLE_CAROUSEL}_report-style-button`;

export const LEAVE_THOUGHT_VIEW = 'leave-thought-view';
export const TEST_ID_LEAVE_THOUGHT_START = `${WEB_PREFIX}${LEAVE_THOUGHT_VIEW}`;
export const TEST_LEAVE_THOUGHT_INPUT = `${TEST_ID_LEAVE_THOUGHT_START}_text-field`;

export const SPOTLIGHT_VIEW = 'spotlight-view';
export const TEST_ID_SPOTLIGHT_START = `${WEB_PREFIX}${PROFILE_VIEW}_${SPOTLIGHT_VIEW}`;
export const TEST_SPOTLIGHT_ITEM = `${TEST_ID_SPOTLIGHT_START}_spotlight-item`;
