import { EVisibilityRoles, ROLES_DESCRIPTION } from './constants';

export const getCurrentRolesDescription = (
  type: EVisibilityRoles
): {
  everyone: string;
  following: string;
  noOne: string;
} => {
  switch (type) {
    case EVisibilityRoles.MessagesAndThoughtsVisibility:
      return ROLES_DESCRIPTION.messagesAndThoughts;
    case EVisibilityRoles.CollaborativeListsVisibility:
      return ROLES_DESCRIPTION.collaborativeLists;
    default:
      return { everyone: '', following: '', noOne: '' };
  }
};
