import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0 12px 32px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0 0 32px;
  }
`;

export const StyledWrap = styled.div<{ isDark: boolean }>`
  align-items: center;
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.white.off[100])};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 12px;
`;

export const StyledText = styled.span<{ isDark: boolean }>`
  font-size: 14px;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};

  span {
    display: block;
    font-size: 12px;
    font-family: ${FONTS.secondary};
    padding-top: 4px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const StyledImage = styled.img`
  border-radius: 8px;
  height: 80px;
  min-width: 80px;
  object-fit: cover;
  width: 80px;
`;
