import { HEADINGS } from './constants';

export const getButtonText = (index: number, isFirstScreen: boolean): string => {
  if (isFirstScreen) {
    return 'Show me';
  }

  return index > 1 ? 'Start using guide:human' : 'Next';
};

export const getHeadingText = (index: number, isFirstScreen: boolean): string[] => [
  isFirstScreen ? 'You are all set up!' : HEADINGS[index][0],
  isFirstScreen ? 'Here’s what you can now do with guide:human' : HEADINGS[index][1],
  HEADINGS[index][2],
];
