import React, { FC } from 'react';

import { useMenuModal } from 'components/Navigation/helpers/hooks';
import { MessageType } from 'constants/graphqlTypes';

import MenuWrap from './MenuWrap';
import ShareButtons from './ShareButtons';

const GuestListButton: FC<{ guestListId: string }> = ({ guestListId }) => {
  const { isModalOpen, handleModal } = useMenuModal();

  return (
    <MenuWrap isOpen={isModalOpen} handleModal={handleModal}>
      <ShareButtons
        closePreviousModal={() => handleModal(false)}
        messageType={MessageType.GuestList}
        sharedId={guestListId}
      />
    </MenuWrap>
  );
};

export default GuestListButton;
