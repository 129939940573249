import React, { FC } from 'react';

import { ActivityReactionObjectType, Scalars } from 'constants/graphqlTypes';
import { ROUTE_ACTIVITY_REACTIONS, ROUTE_CARD_DETAIL } from 'routes';
import { ESearchParams } from 'constants/common';
import { getActivityTestId } from 'constants/aqa/activity';

import Emoji from 'components/UI/Emoji';
import AvatarDouble from 'components/UI/AvatarDouble';
import UserPreview from './Elements/UserPreview';
import Content from './Elements/Content';
import PickPreview from './Elements/PickPreview';

interface IReactionProps {
  date: Scalars['DateTime'] | null;
  item: ActivityReactionObjectType;
  count?: number | null;
  activityTestId: string;
}

const Reaction: FC<IReactionProps> = ({ date, item, count, activityTestId }) => {
  const { users, card, reactionTypes, commentId, dateCreated } = item;
  const { id: cardId } = card ?? {};

  const user = users[0];
  const reaction = reactionTypes[0];
  const route = count
    ? `${ROUTE_ACTIVITY_REACTIONS}?${ESearchParams.CreatedAt}=${dateCreated}`
    : `${ROUTE_CARD_DETAIL}?thoughtsTab=0&${ESearchParams.Reaction}=${reaction}&${ESearchParams.ThoughtId}=${commentId}`;

  const avatarTestId = getActivityTestId(activityTestId, 'avatar');

  return (
    <>
      {count && users.length > 1 ? (
        <AvatarDouble dataTestId={avatarTestId} users={[users[1], users[0]]} />
      ) : (
        <UserPreview dataTestId={avatarTestId} user={user} />
      )}
      {commentId && (
        <Content
          activityTestId={activityTestId}
          url={route}
          params={{ cardId, thoughtId: commentId }}
          date={date}
          user={user}
        >
          {count && (
            <>
              and <b>{count - 1} others </b>
            </>
          )}
          reacted to your thought
        </Content>
      )}
      {card && <PickPreview activityTestId={activityTestId} pick={card} />}
      <Emoji reactions={reactionTypes} />
    </>
  );
};

export default Reaction;
