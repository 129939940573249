import React, { FC } from 'react';

import useFixedViewport from 'helpers/useFixedViewport';
import Grain from 'components/UI/Grain';

import { StyledBackground, StyledContent, StyledGuestListBg } from './styled';

const ImageBackground: FC<{ background?: string; isGuestList: boolean }> = ({ background, isGuestList, children }) => {
  const isGuestListImageBg = isGuestList && !background?.includes('url');

  useFixedViewport();

  return (
    <StyledBackground background={background} isGuestList={isGuestListImageBg}>
      {isGuestListImageBg && <StyledGuestListBg />}
      <Grain />
      <StyledContent>{children}</StyledContent>
    </StyledBackground>
  );
};

export default ImageBackground;
