import React, { FC } from 'react';

import useTheme from 'helpers/useTheme';
import LoaderContent from 'components/UI/LoaderContent';

import { StyledListTitle, StyledList } from 'components/ActivityList/styled';
import { useGetRecentSearches } from 'graphQL/search/hooks';
import { TEST_SEARCH_RECENT_ITEM } from 'constants/aqa/search';
import { StyledRecentSearches } from '../RecentSearch/styled';

import RecentSearchItem from '../RecentSearch';

const RecentSearches: FC = () => {
  const isDark = useTheme();

  const { items, loading } = useGetRecentSearches();

  return (
    <StyledRecentSearches>
      {!!items.length && <StyledListTitle isDark={isDark}>Recent searches</StyledListTitle>}
      <StyledList>
        {items.map(({ id, searchDataUnion }, index) => (
          <RecentSearchItem
            dataTestId={`${TEST_SEARCH_RECENT_ITEM}-${index}`}
            key={id}
            id={id}
            hasNameOnly
            user={searchDataUnion?.__typename === 'RecentSearchUserObjectType' ? searchDataUnion.user : undefined}
            keyword={searchDataUnion?.__typename === 'RecentSearchKeywordObjectType' ? searchDataUnion?.keyword : ''}
          />
        ))}
      </StyledList>
      {loading && <LoaderContent isFullScreen isDark={isDark} />}
    </StyledRecentSearches>
  );
};

export default RecentSearches;
