import { useHistory } from 'react-router-dom';
import { ApolloError } from '@apollo/client';

import { EMPTY_ITEMS, EQueryFetchPolicy } from 'constants/common';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';

import {
  CardSaveFilter,
  CardSchema,
  CardTypeOptions,
  GetSavedPicksDocument,
  useDeleteSavedPicksMutation,
  useGetSavedPicksCountQuery,
  useGetSavedPicksQuery,
  useMarkSavedPicksAsViewedMutation,
  useMoveSavesToPicksMutation,
} from 'constants/graphqlTypes';
import {
  ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST,
  ROUTE_ADD_TO_LIST,
  ROUTE_LIST_DETAIL,
  ROUTE_USER_ALL_PICKS,
} from 'routes';

import useToast from 'helpers/useToast';
import useOpenModal from 'helpers/useOpenModal';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { useCardsBaseSelection } from 'pages/OnboardingV2/ContentTutorial/ListFill/hooks';

import { getNotifications } from 'graphQL/cardOptions/helpers';
import { transformRoute } from 'helpers/routingHelper';
import getAuthUser from 'helpers/getAuthUser';
import { CardSchemaWithIsViewed, IuseMoveSavedToPicks } from './models';
import { updateSavedPicks } from './helpers';

export const useGetSavedPicks = (
  filters: CardSaveFilter
): { items: CardSchemaWithIsViewed[]; loading: boolean; loadMore: () => void } => {
  const [markSavedPicksAsViewedMutation] = useMarkSavedPicksAsViewedMutation({});

  const { data: savedCards, loading, fetchMore } = useGetSavedPicksQuery({
    variables: { filters },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    onCompleted: () => {
      markSavedPicksAsViewedMutation({
        fetchPolicy: EQueryFetchPolicy.NoCache,
      });
    },
  });

  const { items, totalCount } = savedCards?.getSavedPicks ?? EMPTY_ITEMS;

  const loadMore = () =>
    items &&
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length, filters },
      updateQuery: (prev, { fetchMoreResult }) => updateSavedPicks(prev, fetchMoreResult),
    });

  return {
    items: (items ?? []).reduce((acc: CardSchemaWithIsViewed[], pick) => {
      if (pick.card) {
        acc.push({
          ...pick.card,
          isViewed: pick.isViewed,
        });
      }
      return acc;
    }, []),
    loading,
    loadMore,
  };
};

export const useDeleteSavedPicks = (
  cardsIds: string[],
  onCompleted: () => void,
  isArchived?: boolean,
  deleteArchive?: boolean
): { deleteSavedPicks: () => void; loading: boolean } => {
  const { setToast } = useToast();
  const posthogCapture = usePostHogCapture();

  const [deletePicks, { loading }] = useDeleteSavedPicksMutation({
    variables: { cardsIds, deleteArchive },
    ...(!deleteArchive && {
      refetchQueries: [{ query: GetSavedPicksDocument, variables: { filters: { isArchived: !!isArchived } } }],
    }),
    onCompleted: () => {
      posthogCapture(POSTHOG_EVENTS.DeleteFromSavedCards);
      onCompleted();
    },
    onError: (error: ApolloError) => {
      setToast({
        isToastOpen: true,
        toastItemName: error.message,
        toastError: true,
      });
    },
  });
  return { deleteSavedPicks: () => deletePicks(), loading };
};

export const useGetSavedPickCount = (isArchived: boolean): { totalCount: number; totalCountNotViewed: number } => {
  const { totalCount, totalCountNotViewed } =
    useGetSavedPicksCountQuery({
      variables: { isArchived },
      fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    }).data?.getSavedPicks ?? {};

  return { totalCount: totalCount ?? 0, totalCountNotViewed: totalCountNotViewed ?? 0 };
};

export const useDeleteArchivePicks = (
  onCompleted: () => void
): { deleteArchivePicks: () => void; loading: boolean } => {
  const { deleteSavedPicks, loading } = useDeleteSavedPicks([], onCompleted, true, true);

  return { deleteArchivePicks: () => deleteSavedPicks(), loading };
};

export const useMoveSavedToPicks = ({
  listCardId,
  listId,
  listTitle,
  cardImageUrl,
  data,
  onCompleted,
}: IuseMoveSavedToPicks) => {
  const posthogCapture = usePostHogCapture();
  const { replace } = useHistory();

  const [moveSavedToPicks, { loading }] = useMoveSavesToPicksMutation({
    variables: { collectionId: listCardId || undefined, data },
    ...getNotifications({
      action: 'Shared to',
      message: listTitle || 'All Picks',
      image: cardImageUrl ?? undefined,
      type: CardTypeOptions.Collection,
      onCompleted: () => {
        posthogCapture(POSTHOG_EVENTS.AddFromSavedCards);
        posthogCapture(POSTHOG_EVENTS.AddCard);

        onCompleted?.();

        replace(
          listCardId
            ? transformRoute(ROUTE_LIST_DETAIL, { listId })
            : transformRoute(ROUTE_USER_ALL_PICKS, { profileName: getAuthUser().username ?? '' })
        );
      },
    }),
  });

  return { moveSavedToPicks, loading };
};

export const useSavedPicksAdding = (items: CardSchemaWithIsViewed[]) => {
  const cardsBaseSelection = useCardsBaseSelection();
  const { selectedCards: selectedCardIds } = cardsBaseSelection;
  const selectedCards: CardSchema[] = items.filter(({ cardId }) => selectedCardIds.includes(cardId)) ?? [];

  const { id, cardId } = selectedCards[0] ?? {};

  const goToMultiAdd = useOpenModal(ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST, false, { cards: selectedCards });
  const goToSingleAdd = useOpenModal(ROUTE_ADD_TO_LIST, false, { pickId: id, cardId, isSavedPick: true });

  const handlePickerClick = selectedCards.length > 1 ? goToMultiAdd : goToSingleAdd;

  return { handlePickerClick, selectedCards, cardsBaseSelection };
};
