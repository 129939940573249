import React, { FC } from 'react';

import Card from 'pages/Onboarding/Success/Card';
import StyledAvatar from 'components/UI/Avatar/styled';

import { COLORS } from 'styles/constants';
import { StyledCard, StyledComment } from './styled';
import { CARDS } from './helpers/constants';

const CardSlide: FC<{ index: number }> = ({ index }) => {
  const { card, thought } = CARDS[index];
  const { text, avatar } = thought;

  return (
    <StyledCard>
      <Card card={card} />
      <StyledComment>
        <StyledAvatar color={COLORS.white[100]} dimension={44} src={avatar} />
        {text}
      </StyledComment>
    </StyledCard>
  );
};

export default CardSlide;
