import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';
import { StyledTitleWrap as ImportedStyledTitleWrap } from '../ListGroup/styled';

export const StyledTitleTextWrap = styled.span`
  display: -webkit-box;
  max-width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

export const StyledTitleWrap = styled(ImportedStyledTitleWrap)`
  a {
    color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    font: 400 22px/130% ${FONTS.primary};
  }
`;
