import { EQueryFetchPolicy } from 'constants/common';
import { useGetNavigationUserDataQuery, UserObjectType } from 'constants/graphqlTypes';
import getAuthUser from 'helpers/getAuthUser';

const useNavigationUserData = (fetchPolicy?: EQueryFetchPolicy): UserObjectType | undefined => {
  const { userId } = getAuthUser();
  const { data } = useGetNavigationUserDataQuery({
    variables: { id: userId },
    skip: !userId,
    fetchPolicy,
  });

  return data?.allUsers?.items[0];
};

export default useNavigationUserData;
