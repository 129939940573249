import React, { FC } from 'react';

import { Maybe, CardListObjectType, EDiscoverItemLayoutOptions } from 'constants/graphqlTypes';

import Card from 'components/Card';
import { StyledSubtitle, StyledSectionWrap } from 'pages/QuestionDetail/styled';

import { StyledItem } from './styled';

interface ITopPicksProps {
  topAnswers?: Maybe<CardListObjectType>;
  isDark: boolean;
}

const TopPicks: FC<ITopPicksProps> = ({ topAnswers, isDark }) => {
  return topAnswers?.items.length ? (
    <>
      <StyledSubtitle>Top picks</StyledSubtitle>
      <StyledSectionWrap>
        {topAnswers?.items.map((item) => (
          <StyledItem key={item.id}>
            <Card card={item} isDark={isDark} layout={EDiscoverItemLayoutOptions.FirstUp} showActivity />
          </StyledItem>
        ))}
      </StyledSectionWrap>
    </>
  ) : null;
};

export default TopPicks;
