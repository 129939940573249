import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  UnionDiscoverFollowingObjectType,
  useGetEveryoneItemsListQuery,
  useGetExploreEveryoneQuery,
  useGetExploreFollowingsListQuery,
  useGetExploreFollowingsQuery,
  useGetExploreItemsQuery,
} from 'constants/graphqlTypes';
import {
  EMPTY_DISCOVERY_ITEMS,
  EMPTY_ITEMS,
  EQueryFetchPolicy,
  MS_IN_DAY,
  SHOW_FETCH_MORE_LOADER,
} from 'constants/common';
import { REF_PLACEHOLDER } from 'components/Explore/Item/helpers';
import {
  checkForSuggestedUsers,
  getFollowingIds,
  getFollowingSliderIds,
} from 'components/Explore/FollowingTab/helpers';
import { IUseExploreItems, IUseEveryoneItems, IUseDetailSliderItems, IUseFollowingItems } from './models';
import { updateEveryone, updateFollowings } from './helpers';

export const useExploreItems = (): IUseExploreItems => {
  const { data, fetchMore, loading } = useGetExploreItemsQuery({
    ...SHOW_FETCH_MORE_LOADER,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    variables: { limit: 1, fallbackRefId: REF_PLACEHOLDER },
  });

  const { sectionItems, totalCount } = data?.discoverExploreItems ?? EMPTY_DISCOVERY_ITEMS;

  const loadMore = () =>
    !loading &&
    fetchMore({
      variables: { offset: sectionItems.length, limit: 4 },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...prev,
          ...(prev.discoverExploreItems && {
            discoverExploreItems: {
              ...prev.discoverExploreItems,
              sectionItems: [
                ...(prev.discoverExploreItems?.sectionItems ?? []),
                ...(fetchMoreResult?.discoverExploreItems?.sectionItems ?? []),
              ],
            },
          }),
        };
      },
    });

  return {
    sections: sectionItems ?? [],
    loading,
    total: totalCount,
    onWaypointEnter: loadMore,
  };
};

export const useFollowingItems = (): IUseFollowingItems => {
  const [date, setDate] = useState(Date.now());

  const [fromTime, setFromTime] = useState<number | null>(date);

  const [shouldLoadPrevDay, setShouldLoadPrevDay] = useState(false);
  const [isQueryCalled, setIsQueryCalled] = useState(false);
  const [cachedItems, setCashedItems] = useState<UnionDiscoverFollowingObjectType[]>([]);

  const updateCashedItems = (newItems: UnionDiscoverFollowingObjectType[]) =>
    setCashedItems((prev) => [...prev, ...newItems]);

  const { data, fetchMore, loading } = useGetExploreFollowingsQuery({
    variables: {
      fromTime: `${Math.floor(date / 1000)}`,
      offset: 0,
    },
    fetchPolicy: EQueryFetchPolicy.NetworkOnly,
    ...SHOW_FETCH_MORE_LOADER,
    onCompleted: ({ discoverFollowingsItems }) => {
      const { items, totalCount } = discoverFollowingsItems ?? EMPTY_ITEMS;

      if (!checkForSuggestedUsers(items?.[0]) && totalCount && discoverFollowingsItems?.fromTime) {
        setShouldLoadPrevDay(true);
        setFromTime(discoverFollowingsItems.fromTime ? discoverFollowingsItems.fromTime * 1000 : null);
      }
      if (!isQueryCalled && items?.length) {
        updateCashedItems(items);
        setIsQueryCalled(true);
      }
    },
  });

  const { items, totalCount } = data?.discoverFollowingsItems ?? EMPTY_ITEMS;

  const loadMore = () => {
    if (items && items.length < totalCount) {
      fetchMore({
        variables: { offset: items.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult.discoverFollowingsItems?.items?.length) {
            updateCashedItems(fetchMoreResult.discoverFollowingsItems?.items);
          }

          return updateFollowings(prev, fetchMoreResult);
        },
      });
    }
  };

  const loadPrevDay = () => {
    if (!loading && fromTime) {
      setDate(fromTime - MS_IN_DAY);
      setIsQueryCalled(false);
      setShouldLoadPrevDay(false);
    }
  };

  return {
    items: cachedItems,
    loading,
    shouldLoadPrevDay,
    loadMore,
    loadPrevDay,
    total: totalCount,
  };
};

export const useFollowingItemsList = (pickId: string): IUseDetailSliderItems => {
  const [stateItems, setStateItems] = useState<UnionDiscoverFollowingObjectType[]>([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [isQueryCalled, setIsQueryCalled] = useState(false);

  const { pickId: urlId } = useParams<{ pickId: string }>();

  const loadPrevDay = (timestamp: number) => {
    setFromDate(timestamp * 1000 - MS_IN_DAY);
    setIsQueryCalled(false);
  };

  const { data, fetchMore, loading } = useGetExploreFollowingsListQuery({
    variables: { fromTime: `${Math.floor(fromDate / 1000)}` },
    fetchPolicy: EQueryFetchPolicy.NetworkOnly,
    onCompleted: ({ discoverFollowingsItems }) => {
      const { items, totalCount, fromTime } = discoverFollowingsItems ?? { ...EMPTY_ITEMS, fromTime: undefined };

      if (items && items.length < totalCount) {
        fetchMore({
          variables: {
            offset: items.length,
          },
        });
      }

      const itemsIds = getFollowingIds(items ?? []);

      const pickIndex = itemsIds.findIndex((id) => id === pickId);

      const shouldLoadPrevDay =
        items?.length === totalCount &&
        !!totalCount &&
        ((!getFollowingIds(stateItems).includes(pickId) && !checkForSuggestedUsers(items?.[0])) ||
          pickIndex === itemsIds.length - 1);

      if (!isQueryCalled && items?.length) {
        setStateItems((prev) => [...prev, ...items]);
        setIsQueryCalled(true);
      }

      if (shouldLoadPrevDay && fromTime && !!discoverFollowingsItems?.totalCount) {
        loadPrevDay(fromTime);
      }
    },
  });

  useEffect(() => {
    const currentIndex = getFollowingIds(stateItems).findIndex((id) => id === urlId);

    const newFromTime = data?.discoverFollowingsItems?.fromTime;
    if (currentIndex >= stateItems.length - 1 && newFromTime && data?.discoverFollowingsItems?.totalCount) {
      loadPrevDay(newFromTime);
    }
  }, [urlId, stateItems.length, data?.discoverFollowingsItems?.fromTime, data?.discoverFollowingsItems?.totalCount]);

  const items = data?.discoverFollowingsItems?.items ?? [];
  const total = data?.discoverFollowingsItems?.totalCount ?? 0;

  const loadMore = () =>
    items.length < total &&
    !loading &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateFollowings(prev, fetchMoreResult),
    });

  return { items: getFollowingSliderIds(stateItems), loadMore };
};

export const useEveryoneItems = (): IUseEveryoneItems => {
  const { data, fetchMore, loading } = useGetExploreEveryoneQuery({
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items: everyoneItems, totalCount } = data?.discoverEveryoneItems ?? EMPTY_ITEMS;

  const loadMore = () =>
    everyoneItems.length < totalCount &&
    fetchMore({
      variables: { offset: everyoneItems.length },
      updateQuery: (prev, { fetchMoreResult }) => updateEveryone(prev, fetchMoreResult),
    });

  return {
    items: everyoneItems,
    loading,
    loadMore,
    total: totalCount,
  };
};

export const useEveryoneItemsList = (fromTime?: string): IUseDetailSliderItems => {
  const { data, fetchMore, loading } = useGetEveryoneItemsListQuery({ variables: { fromTime }, skip: !fromTime });

  const items = data?.discoverEveryoneItems?.items ?? [];
  const total = data?.discoverEveryoneItems?.totalCount ?? 0;

  const loadMore = () =>
    items.length < total &&
    !loading &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateEveryone(prev, fetchMoreResult),
    });

  return { items: items.map(({ card: { id }, referrerId }) => ({ id, referrerId })), loadMore };
};
