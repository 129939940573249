import { styled } from 'linaria/react';
import { COLORS, FONTS } from '../../../styles/constants';

export const StyledWrap = styled.div<{ isDark: boolean; hasWrap: boolean }>`
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[10] : COLORS.white.off[100])};
  border-radius: 12px;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  flex-wrap: ${({ hasWrap }) => (hasWrap ? 'wrap' : 'nowrap')};
  gap: 8px;
  padding: 20px 12px;
  position: relative;
  word-break: break-word;
`;

export const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  font-style: italic;
  font-size: 12px;
  gap: 8px;
  line-height: 140%;
  width: 100%;
`;

export const StyledText = styled.span<{ isBig: boolean }>`
  display: block;
  font-family: ${FONTS.secondary};
  font-size: ${({ isBig }) => (isBig ? 14 : 12)}px;
  line-height: 140%;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const StyledUserInfo = styled.span`
  display: block;
  font-weight: 550;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledBio = styled.span<{ isVerified?: boolean }>`
  font-weight: 400;
  cursor: initial;

  &:before {
    content: ${({ isVerified }) => (isVerified ? 'none' : '" · "')};
  }
`;

export const StyledCuratedAvatar = styled.div`
  .avatarGH {
    margin: -15px 0 0 29px;
  }
`;
