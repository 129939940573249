import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledTopicsItemWrap = styled.div`
  padding: 8px 0;
`;

export const StyledBadgeWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`;

export const StyledBadge = styled.div`
  display: flex;
  overflow: hidden;

  .avatarGH {
    min-width: 44px;
  }
`;

export const StyledUserTextWrap = styled.div`
  align-items: flex-start;
  color: ${COLORS.white[100]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  overflow: hidden;
  padding: 0 12px;
  width: 100%;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`;

export const StyledUserTitle = styled.span`
  font: 400 14px/140% ${FONTS.primary};
`;

export const StyledUserSubtitle = styled.span`
  display: flex;
  font: 400 12px/140% ${FONTS.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledUserSubtitleWrap = styled.span`
  align-items: center;
  display: flex;
  font: 400 12px/140% ${FONTS.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledMore = styled.span`
  font: 400 12px/140% ${FONTS.secondary};
  min-width: 84px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
