import React, { FC } from 'react';

import { COLORS } from 'styles/constants';
import { StyledList, StyledListItem } from './styled';
import StyledAvatar from '../Avatar/styled';

interface IAvatarsProps {
  urls: string[];
  limit: number;
  total?: number;
  isBig?: boolean;
  hideNumber?: boolean;
}

const UrlAvatars: FC<IAvatarsProps> = ({ urls, total, isBig, hideNumber, limit }) => (
  <StyledList>
    {urls.slice(0, limit).map((url) => (
      <StyledListItem isBig={isBig} key={url}>
        <StyledAvatar dimension={isBig ? 44 : 20} src={url} className="avatar" color={COLORS.white[100]} />
      </StyledListItem>
    ))}
    {!!total && total > limit && !hideNumber && (
      <StyledListItem className="total" isEmpty isBig={isBig}>
        +{total - limit}
      </StyledListItem>
    )}
  </StyledList>
);

export default UrlAvatars;
