import React, { FC, useEffect, useRef } from 'react';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';

import TopBlock from 'components/Landing/TopBlock';
import WeeklyPicks from 'components/Landing/WeeklyPicks';
import MiddleBlock from 'components/Landing/MiddleBlock';
import SubscribeForm from 'components/Landing/SubscribeForm';
import Connect from 'components/Landing/Connect';
import Footer from 'components/Landing/Footer';

import { StyledWrapper, StyledHeader, StyledHeaderButton, StyledHeaderText } from './styled';

const LandingPage: FC = () => {
  const formRef = useRef<HTMLDivElement>(null);
  const posthogCapture = usePostHogCapture();

  const scrollToForm = () => {
    if (formRef?.current) {
      formRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  };

  const handleJoinButton = () => {
    posthogCapture(POSTHOG_EVENTS.LandingJoinButton);
    scrollToForm();
  };

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.LandingOpen);
    posthogCapture(POSTHOG_EVENTS.LandingOpenFrom, {
      referrer: document.referrer,
    });
  }, []);

  return (
    <StyledWrapper>
      <StyledHeader>
        <StyledHeaderText>We’re in Beta. Join our waitlist now.</StyledHeaderText>
        <StyledHeaderButton onClick={handleJoinButton}>Join</StyledHeaderButton>
      </StyledHeader>
      <TopBlock handleGetStarted={scrollToForm} />
      <WeeklyPicks />
      <MiddleBlock />
      <Connect />
      <div ref={formRef}>
        <SubscribeForm />
      </div>
      <Footer />
    </StyledWrapper>
  );
};

export default LandingPage;
