import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';
import { StyledTitle } from '../Section/styled';

export const StyledButton = styled.button<{ isDark: boolean }>`
  background-color: ${COLORS.transparent};
  border: none;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  cursor: pointer;
  display: flex;
  font-family: ${FONTS.primary};
  padding: 0;
  width: 100%;

  svg {
    margin-top: 6px;
    transform: rotate(-90deg);

    path {
      fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    }
  }
`;

export const StyleSelectTitle = styled(StyledTitle)`
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
