import { styled } from 'linaria/react';
import { COLORS, FONTS } from '../../../styles/constants';

export const StyledInputWrapper = styled.div`
  padding: 16px 0;
  display: flex;

  > div {
    flex-grow: 1;
  }
`;

export const StyledEmptySearchMessage = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: block;
  font: 14px/140% ${FONTS.secondary};
  padding-top: 16px;
`;
