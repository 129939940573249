import React, { FC } from 'react';
import { isSameDay } from 'date-fns';

import { MessageSchema } from 'constants/graphqlTypes';
import { getDailyDateFormat, getWebDate } from 'helpers/getDateFormat';
import { StyledSeparator } from './styled';

const Separator: FC<{ group: MessageSchema[]; prev?: MessageSchema[] }> = ({ group, prev }) =>
  !isSameDay(getWebDate(group[0].createdAt), getWebDate(prev?.[0].createdAt)) ? (
    <StyledSeparator>{getDailyDateFormat(group[0].createdAt)}</StyledSeparator>
  ) : null;

export default Separator;
