import { styled } from 'linaria/react';

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1;
  gap: 16px;
  justify-content: center;
  margin: 0 -24px;

  > div {
    flex-shrink: 0;
  }
`;

export const StyledAnimationStart = styled.div<{ isUploaded?: boolean; index?: number }>`
  animation: start 600ms ease-in-out 200ms forwards;
  opacity: 0;
  transform: ${({ index }) => {
    if (!index) {
      return 'none';
    }
    return `translateX(${index > 1 ? '' : '-'}200%)`;
  }};

  @keyframes start {
    to {
      opacity: 1;
      transform: none;
    }
  }
`;

export const StyledAnimationEnd = styled.div<{ index?: number }>`
  animation: end 600ms 200ms ease-in-out forwards;

  @keyframes end {
    to {
      opacity: ${({ index }) => (index ? 0 : 1)};
      transform: ${({ index }) => {
        if (!index) {
          return 'scale(1.33)';
        }
        return `translateX(${index > 1 ? '' : '-'}200%)`;
      }};
    }
  }
`;
