import React, { FC, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { ROUTE_MESSAGES, ROUTE_MESSAGES_CHAT, ROUTE_MESSAGES_NEW, ROUTE_MESSAGES_SEARCH } from 'routes';
import { useMessagesSubscription } from 'graphQL/messages/hooksSubscriptions';

import StyledWrap, { StyledVirtualBg } from 'pages/Layout/styled';
import useTheme from 'helpers/useTheme';
import { ChatContextProvider, useMessagesChatContextState } from 'helpers/useChatProvider';
import MessagesList from './MessagesList';
import MessagesChat from './MessagesChat';
import MessagesNew from './MessagesNew';
import MessagesSearch from './MessagesSearch';

const Messages: FC = () => {
  const isDark = useTheme();
  const posthogCapture = usePostHogCapture();

  const { setShouldPlay1stMessageAnimations, shouldPlay1stMessageAnimation } = useMessagesChatContextState();
  const onNewMessage = () => {
    setShouldPlay1stMessageAnimations(true);
  };

  const { typingIds } = useMessagesSubscription(onNewMessage);

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.MessAction);
    posthogCapture(POSTHOG_EVENTS.MessOpen);
  }, []);

  return (
    <ChatContextProvider value={{ setShouldPlay1stMessageAnimations, shouldPlay1stMessageAnimation }}>
      <StyledWrap isDark={isDark}>
        <StyledVirtualBg isDark={isDark} />
        <Switch>
          <Route exact path={ROUTE_MESSAGES} component={MessagesList} />
          <Route exact path={ROUTE_MESSAGES_NEW} component={MessagesNew} />
          <Route exact path={ROUTE_MESSAGES_SEARCH} component={MessagesSearch} />
          <Route exact path={ROUTE_MESSAGES_CHAT}>
            <MessagesChat typingIds={typingIds} />
          </Route>
        </Switch>
      </StyledWrap>
    </ChatContextProvider>
  );
};

export default Messages;
