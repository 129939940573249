import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import { FONTS } from 'styles/constants';
import { StyledTitle as StyledListTitle } from '../Content/styled';

export const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
`;

export const StyledWrap = styled.div<{ disableLinks?: boolean }>`
  position: relative;

  a,
  .card,
  .card-link {
    pointer-events: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
    user-select: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
  }
`;

export const StyledTitle = styled(StyledListTitle)<{ isSmall?: boolean }>`
  font: ${({ isSmall }) => (isSmall ? '22' : '32')}px / 130% ${FONTS.primary};
  padding: 0;
  max-width: 100%;

  &::after {
    top: ${({ isSmall }) => (isSmall ? '-10' : '-16')}px;
  }
`;
