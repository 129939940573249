import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'components/UI/Button/Button';
import { useSendContent } from 'graphQL/messages/hooks';
import { ROUTE_MESSAGES_CHAT } from 'routes';
import { transformRoute } from 'helpers/routingHelper';
import { ISendButtonLocation } from './models';

interface ISendButtonProps {
  chatId?: string;
  userId?: string;
}

const SendButton: FC<ISendButtonProps> = ({ chatId, userId }) => {
  const [wasSent, setWasSent] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [justCreatedChat, setJustCreatedChat] = useState<string>();
  const [text, setText] = useState<'Send' | 'Sent' | 'View chat'>('Send');

  useEffect(() => {
    if (wasSent) {
      setIsDisabled(true);
      setText('Sent');

      setTimeout(() => {
        setIsDisabled(false);
        setText('View chat');
      }, 1000);
    }
  }, [wasSent]);

  const {
    state: {
      variable: { entityId, messageType, commentCardId, profileGradientEnd, profileGradientStart },
    },
  } = useLocation<ISendButtonLocation>();
  const { replace } = useHistory();

  const handleSend = useSendContent({
    chatId,
    userId,
    handleComplete: (id: string) => !chatId && setJustCreatedChat(id),
    profileGradientStart,
    profileGradientEnd,
  });

  const handleClick = () => {
    if (!wasSent) {
      handleSend(entityId, messageType, commentCardId);
      setWasSent(true);
    } else {
      replace({
        pathname: transformRoute(ROUTE_MESSAGES_CHAT, { id: chatId ?? justCreatedChat }),
        state: {
          isGoBack: true,
        },
      });
    }
  };

  return <Button handler={handleClick} isDisabled={isDisabled} text={text} isDark />;
};

export default SendButton;
