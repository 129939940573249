import React, { FC } from 'react';

import { ESearchTag } from '../helpers/constants';
import { ISuggestionsProps } from './models';
import SuggestionsText from './SuggestionsText';
import SuggestionsUsers from './SuggestionsUsers';

const { Time, Type } = ESearchTag;

const Suggestions: FC<ISuggestionsProps> = ({ type, userText, setText }) => {
  if (!type && !userText) {
    return null;
  }

  return type === Time || type === Type ? (
    <SuggestionsText type={type} userText={userText} setText={setText} />
  ) : (
    <SuggestionsUsers type={type} userText={userText} setText={setText} />
  );
};

export default Suggestions;
