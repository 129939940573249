import React, { FC } from 'react';

import { UserObjectType } from 'constants/graphqlTypes';

import StyledContainer, { StyledChildren } from './styled';

import Badge from '../Badge';

interface IBadgeWithChildrenProps {
  user: UserObjectType;
  isDark?: boolean;
  hasUsername?: boolean;
  hasNameOnly?: boolean;
  isDisabled?: boolean;
}

const BadgeWithChildren: FC<IBadgeWithChildrenProps> = ({
  user,
  children,
  isDark,
  hasUsername,
  hasNameOnly,
  isDisabled,
}) => (
  <StyledContainer>
    <Badge isDisabled={isDisabled} hasUsername={hasUsername} user={user} isDark={isDark} hasNameOnly={hasNameOnly} />
    <StyledChildren>{children}</StyledChildren>
  </StyledContainer>
);

export default BadgeWithChildren;
