import { styled } from 'linaria/react';

import { COLORS } from '../../../styles/constants';

export const StyledItem = styled.li<{ hideBorder?: boolean }>`
  position: relative;
  border-bottom: ${({ hideBorder }) => (hideBorder ? 'none' : `1px solid ${COLORS.white[30]}`)};
  display: flex;
  align-items: center;

  &:last-child {
    border: none;
  }
`;
