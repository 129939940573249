import React, { FC, useState } from 'react';

import ProfileGrid from 'components/Profile/ProfileGrid';
import useTheme from 'helpers/useTheme';
import usePageType from 'helpers/usePageType';
import LoaderContent from 'components/UI/LoaderContent';

import { CardTypeOptions, PrivacyOptions } from 'constants/graphqlTypes';
import { useGetProfileLists, useGetUserCards } from 'graphQL/cards/userCards/hooks';
import ProfilePrivacyButtons from '../ProfilePrivacyButtons/ProfilePrivacyButtons';
import ProfileRecentPick from './ProfileRecentPick';

import { StyledContent, StyledNoListsText, StyledWrap } from './styled';

const { Public, Private } = PrivacyOptions;
const { Card } = CardTypeOptions;

const ProfileContent: FC = () => {
  const { totalCount, loading: picksLoading } = useGetUserCards({ type: Card, limit: 0 });
  const [showPublicLists, setShowPublicLists] = useState(true);
  const { totalCount: totalLists } = useGetProfileLists({ limit: 0, withPrivacy: true });
  const { lists, loadMore, loading, isQueryCalled } = useGetProfileLists({
    limit: 10,
    privacy: showPublicLists ? Public : Private,
  });
  const { isMyProfile } = usePageType();

  const isDark = useTheme();
  const hasPicks = isMyProfile ? totalCount > 3 : !!totalCount;
  const firstListsLoading = loading && !isQueryCalled;
  const showContent = (hasPicks || !!totalLists) && !firstListsLoading;

  return (
    <>
      {showContent && (
        <StyledWrap>
          <StyledContent>
            <ProfileRecentPick />
            <ProfilePrivacyButtons
              picksTotalCount={totalCount}
              showPublicLists={showPublicLists}
              setShowPublicLists={setShowPublicLists}
            />
            {!totalLists && (
              <StyledNoListsText isDark={isDark}>
                {isMyProfile ? 'You don’t have any lists yet.' : 'There are no lists yet. '}
              </StyledNoListsText>
            )}
            {!!lists.length && <ProfileGrid isDark={isDark} items={lists} loadMore={loadMore} loading={loading} />}
          </StyledContent>
        </StyledWrap>
      )}
      {(loading || picksLoading) && !hasPicks && !lists.length && <LoaderContent isDark={isDark} />}
    </>
  );
};

export default ProfileContent;
