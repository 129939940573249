import { styled } from 'linaria/react';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 44px;
  width: 100%;
`;

export const StyledChildren = styled.div`
  flex-shrink: 0;
`;

export default StyledContainer;
