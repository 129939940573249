import { ESearchTag, SUGGESTED_TIMES_TEST_IDS } from 'components/Search/helpers/constants';
import { WEB_PREFIX } from 'constants/common';

export const SEARCH_VIEW = 'search-view';
export const SEARCH_RESULTS_VIEW = 'search-results-view';
export const SHOW_CONTENT_VIEW = 'show-content-view';

export const TEST_ID_SEARCH_START = `${WEB_PREFIX}${SEARCH_VIEW}`;
export const TEST_SEARCH_CONTAINER = `${TEST_ID_SEARCH_START}_search-container`;
export const TEST_SEARCH_INPUT = `${TEST_SEARCH_CONTAINER}_search-bar_text-field`;
export const TEST_SEARCH_CANCEL_BTN = `${TEST_SEARCH_CONTAINER}_cancel-button`;

export const getFilterButtonTestId = (title: string): string =>
  `${TEST_ID_SEARCH_START}_${title.toLowerCase()}-filter-button`;

export const TEST_SEARCH_RECENT_ITEM = `${TEST_ID_SEARCH_START}_recent-search-item`;
export const TEST_SEARCH_ALL_RESULTS_FOR_BTN = `${TEST_SEARCH_RECENT_ITEM}_all-results-for-button`;

export const TEST_SEARCH_USER_OPTIONS_VIEW = `${TEST_ID_SEARCH_START}_user-options-view`;
export const TEST_SEARCH_TIME_OPTIONS_VIEW = `${TEST_ID_SEARCH_START}_time-options-view`;
export const TEST_SEARCH_TYPE_OPTIONS_VIEW = `${TEST_ID_SEARCH_START}_type-options-view`;

export const getSuggestionTypeItemTestId = (
  type: ESearchTag | null,
  index: number,
  suggestedTypes: string[]
): string => {
  if (!type) return '';

  const startTestId = type === ESearchTag.Type ? TEST_SEARCH_TYPE_OPTIONS_VIEW : TEST_SEARCH_TIME_OPTIONS_VIEW;
  const getItemTestIds = () => {
    if (suggestedTypes.length && type === ESearchTag.Type) {
      return suggestedTypes;
    }
    return SUGGESTED_TIMES_TEST_IDS;
  };

  return `${startTestId}_${getItemTestIds()[index].toLocaleLowerCase()}-label`;
};

export const TEST_SEARCH_USER_OPTION_ITEM = `${TEST_SEARCH_USER_OPTIONS_VIEW}_user-item`;

export const TEST_ID_SEARCH_RESULTS_START = `${WEB_PREFIX}${SEARCH_RESULTS_VIEW}`;
export const TEST_SEARCH_RESULTS_PICKS_PAGE = `${TEST_ID_SEARCH_RESULTS_START}_picks-page`;
export const TEST_SEARCH_RESULTS_LISTS_PAGE = `${TEST_ID_SEARCH_RESULTS_START}_lists-page`;
export const TEST_SEARCH_RESULTS_PEOPLE_PAGE = `${TEST_ID_SEARCH_RESULTS_START}_people-page`;

export const TEST_SEARCH_RESULTS_PICKS_PAGE_LABEL = `${TEST_SEARCH_RESULTS_PICKS_PAGE}-label`;
export const TEST_SEARCH_RESULTS_LISTS_PAGE_LABEL = `${TEST_SEARCH_RESULTS_LISTS_PAGE}-label`;
export const TEST_SEARCH_RESULTS_PEOPLE_PAGE_LABEL = `${TEST_SEARCH_RESULTS_PEOPLE_PAGE}-label`;

export const TEST_SEARCH_RESULTS_PICKS_PAGE_EMPTY_LABEL = `${TEST_SEARCH_RESULTS_PICKS_PAGE}_empty-result-item_label`;
export const TEST_SEARCH_RESULTS_LISTS_PAGE_EMPTY_LABEL = `${TEST_SEARCH_RESULTS_LISTS_PAGE}_empty-result-item_label`;
export const TEST_SEARCH_RESULTS_PEOPLE_PAGE_EMPTY_LABEL = `${TEST_SEARCH_RESULTS_PEOPLE_PAGE}_empty-result-item_label`;
export const getSearchCardTestId = (index: number, pageTestId?: string): string => {
  if (!pageTestId) return '';

  return `${pageTestId}_item-${index}`;
};

export const getHiddenAlertTestId = (itemTestId?: string, isShowButton?: boolean): string => {
  if (!itemTestId) return '';

  return `${itemTestId}_${SHOW_CONTENT_VIEW}_${isShowButton ? 'show' : 'view-settings'}-button`;
};
