import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.ul`
  overflow-y: scroll;
  padding-bottom: 105px;
  margin-top: 40px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTopic = styled.button<{ isSelected: boolean; bgColor: string; isDark: boolean }>`
  background: ${({ isSelected, bgColor }) => (isSelected ? bgColor : `inherit`)};
  border-radius: 60px;
  border: 1px solid ${({ isDark, isSelected }) => (isDark && !isSelected ? COLORS.white[100] : COLORS.brown.dark[10])};
  color: ${({ isSelected, isDark }) => (isSelected || isDark ? `${COLORS.white[100]}` : `${COLORS.brown.light[100]}`)};
  cursor: pointer;
  font: 400 22px/130% ${FONTS.primary};
  margin-top: 8px;
  overflow: hidden;
  padding: 16px 24px;
  position: relative;

  > span {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  &::before {
    background: linear-gradient(${COLORS.transparent} 0%, ${COLORS.black[10]} 100%);
    border-radius: 60px;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
