import React, { FC } from 'react';

import useDisableScroll from 'helpers/useDisableScroll';

import OnboardingAlert from 'components/UI/OnboardingAlert';
import { StyledTutorialWrapper } from 'components/Profile/AppTour/styled';

import { StyledLink } from './styled';
import { useExitGuideTour } from './hooks';

interface ILastStep {
  title: string;
  setIsExitConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
}

const LastStep: FC<ILastStep> = ({ title, setIsExitConfirmation }) => {
  const { handelTourExit, handelHelpPage } = useExitGuideTour(setIsExitConfirmation);

  useDisableScroll();

  return (
    <StyledTutorialWrapper>
      <OnboardingAlert
        title={title}
        submitText="Done"
        titleSize={22}
        handleSubmit={() => handelTourExit(false)}
        handleCancel={() => setIsExitConfirmation(true)}
      >
        We hope you have fun, and if you need help, visit our&nbsp;
        <StyledLink onClick={handelHelpPage} to="#">
          help center
        </StyledLink>
        &nbsp;in the menu. We look forward to seeing your picks and thoughts!
      </OnboardingAlert>
    </StyledTutorialWrapper>
  );
};

export default LastStep;
