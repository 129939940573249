import { useLocation, useHistory } from 'react-router-dom';
import { useEffect, useReducer, useState, MouseEvent } from 'react';

import { ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST } from 'routes';
import { MODAL_CLOSE_BUTTON_ID, MODAL_DONE_BUTTON_ID, MODAL_OVERLAY_ID } from 'components/Modal/constants';
import { useLink } from 'helpers/routingHelper';
import { ListRoutingParams } from 'components/AddContent/helpers/models';
import { DEFAULT_STATE, EActionType, ECurrentMode } from './constants';
import { ILocationState, IOnModalMouseDownArgs, IUseBehaviour } from './models';
import { Reducer } from './reducer';
import { getModalDoneButton } from './helpers';

const { Init, ChangeList } = EActionType;
const { Default, Comment, Style } = ECurrentMode;
const { Mode } = EActionType;

export const useBehaviour = (
  listCardId: string,
  listTitle: string,
  listRoutingParams: ListRoutingParams
): IUseBehaviour => {
  const [state, dispatch] = useReducer(Reducer, DEFAULT_STATE);
  const { currentCardIndex, dataToSend } = state;
  const [currentThought, setCurrentThought] = useState('');

  const {
    state: { variable },
  } = useLocation<ILocationState>();

  const { cards } = variable ?? {};

  useEffect(() => {
    if (cards) {
      dispatch({ type: Init, variable: { cards } });
    }
  }, [cards.length, cards]);

  useEffect(() => {
    dispatch({ type: ChangeList, variable: { listCardId, listId: listRoutingParams.id, listTitle } });
  }, [listCardId, listTitle, listRoutingParams.id]);

  useEffect(() => {
    setCurrentThought(dataToSend[currentCardIndex]?.commentText ?? '');
  }, [currentCardIndex, dataToSend[currentCardIndex]?.commentText]);

  const buttonData = getModalDoneButton(state);

  return {
    state,
    dispatch,
    buttonData,
    currentThought,
    setCurrentThought,
  };
};

export const useOnModalMouseDown = ({ state, dispatch, setCurrentThought, currentThought }: IOnModalMouseDownArgs) => {
  const { mode, dataToSend, currentCardIndex } = state;
  const { replace } = useHistory();
  const AddListLink = useLink(ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST);

  return (e: MouseEvent) => {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    if (e.target.id === MODAL_DONE_BUTTON_ID && mode === Comment) {
      dispatch({ type: EActionType.AddComment, variable: { commentText: currentThought } });
    } else if (e.target.id === MODAL_DONE_BUTTON_ID && mode === Style) {
      dispatch({ type: EActionType.SaveStyle });
    } else if (e.target.id === MODAL_DONE_BUTTON_ID && mode === Default) {
      replace(AddListLink);
    } else if (mode === Default && e.target instanceof HTMLTextAreaElement) {
      dispatch({ type: Mode, variable: { mode: Comment } });
    } else if (mode === Style && e.target.id === MODAL_OVERLAY_ID) {
      dispatch({ type: Mode, variable: { mode: Default } });
    } else if (
      mode === Comment &&
      !(e.target instanceof HTMLTextAreaElement) &&
      e.target.id !== MODAL_CLOSE_BUTTON_ID &&
      e.target.id !== MODAL_DONE_BUTTON_ID
    ) {
      setCurrentThought(dataToSend[currentCardIndex]?.commentText ?? '');
      dispatch({ type: Mode, variable: { mode: Default } });
    }
  };
};
