import React, { FC } from 'react';

import { StyledList, StyledListItem } from './styled';

interface IListMiniatureProps {
  gradient: string;
  images: string[];
  dataTestId?: string;
}

const ListMiniature: FC<IListMiniatureProps> = ({ gradient, images, dataTestId }) => {
  const hasFourItems = images?.length && images.length > 3;

  return (
    <StyledList data-testid={dataTestId} bg={gradient}>
      {images?.slice(0, hasFourItems ? 4 : 1)?.map((image) => (
        <StyledListItem isSingle={!hasFourItems} key={image} image={image} />
      ))}
    </StyledList>
  );
};

export default ListMiniature;
