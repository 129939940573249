import React, { FC } from 'react';

import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { useGetUserById, useGetUserByUsername } from 'graphQL/messages/hooks';
import { CardSchema } from 'constants/graphqlTypes';
import { getGradientImage } from 'components/Sparks/constants';
import getAuthUser from 'helpers/getAuthUser';
import Grain from 'components/UI/Grain';

import { StyledSparkPreview, StyledTextWrap } from 'components/Sharing/AllSparks/styled';
import { StyledImageItem, StyledImagesList } from 'components/Collection/styled';
import { StyledAllPicksContainer, StyledCoverContainer, StyledSubTitle, StyledTitle } from '../AllPicks/styled';

interface IAllSparksProps {
  allSparksCards: CardSchema[];
  username?: string;
}
const AllSparks: FC<IAllSparksProps> = ({ allSparksCards, username }) => {
  const loggedUser = useGetUserById(getAuthUser().userId, !!username);
  const customUser = useGetUserByUsername(username ?? '', !username);
  const fullname = generateFullName(username ? customUser : loggedUser ?? null);
  const hasFourItems = allSparksCards?.length > 3;

  return (
    <StyledAllPicksContainer>
      <StyledTitle>All sparks</StyledTitle>
      <StyledSubTitle>List by {fullname}</StyledSubTitle>
      <StyledCoverContainer>
        {hasFourItems ? (
          <StyledImagesList isMultiple>
            {allSparksCards
              .map(({ cardStyle, title, id }) => (
                <StyledImageItem key={id}>
                  <StyledSparkPreview bg={getGradientImage(cardStyle?.bgColor?.predefinedGradientName ?? '')}>
                    <Grain />
                    <StyledTextWrap className="spark-preview__text">{title}</StyledTextWrap>
                  </StyledSparkPreview>
                </StyledImageItem>
              ))
              .reverse()}
          </StyledImagesList>
        ) : (
          <>
            <StyledSparkPreview
              className="spark-preview"
              hasOneItem
              bg={getGradientImage(allSparksCards?.[0]?.cardStyle?.bgColor?.predefinedGradientName ?? '')}
            >
              <Grain />
              <StyledTextWrap className="spark-preview__text" hasOneItem>
                {allSparksCards?.[0]?.title}
              </StyledTextWrap>
            </StyledSparkPreview>
          </>
        )}
      </StyledCoverContainer>
    </StyledAllPicksContainer>
  );
};

export default AllSparks;
