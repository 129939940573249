import { MoveSaveToPickInput } from 'constants/graphqlTypes';

import { convertCardStyleToCardStyleInput } from 'components/AddContent/helpers/helpers';
import { IAction, IState } from './models';
import { EActionType, ECurrentMode } from './constants';

const { Default } = ECurrentMode;

export const Reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case EActionType.Init: {
      const dataToSend: MoveSaveToPickInput[] = action.variable.cards.map((card) => ({
        cardId: card.cardId,
        cardStyle: convertCardStyleToCardStyleInput(card?.cardStyle),
        commentText: undefined,
      }));

      return {
        ...state,
        cards: action.variable.cards,
        dataToSend,
        savedStyleItems: action.variable.cards.map((card) => ({
          cardId: card.id,
          activeStyleOptionId: 0,
          cardStyle: card.cardStyle,
        })),
        newStyleItems: action.variable.cards.map((card) => ({
          cardId: card.id,
          activeStyleOptionId: 0,
          cardStyle: card.cardStyle,
        })),
      };
    }

    case EActionType.ChangeList: {
      return {
        ...state,
        listId: action.variable.listId,
        listTitle: action.variable.listTitle,
        listCardId: action.variable.listCardId,
      };
    }

    case EActionType.CardFocus: {
      return { ...state, currentCardIndex: action.variable.index };
    }

    case EActionType.Mode: {
      return { ...state, mode: action.variable.mode, newStyleItems: state.savedStyleItems };
    }

    case EActionType.AddComment: {
      const updatedDataToSend: MoveSaveToPickInput[] = state.dataToSend.map((item, index) =>
        index === state.currentCardIndex ? { ...item, commentText: action.variable.commentText || undefined } : item
      );

      return { ...state, dataToSend: updatedDataToSend, mode: Default };
    }

    case EActionType.AddStyle: {
      const { cards } = state;

      const newStyleItems = state.newStyleItems.map((item) =>
        item.cardId === action.variable.cardId
          ? {
              ...item,
              cardStyle: action.variable.cardStyle,
              activeStyleOptionId: action.variable.activeStyleOptionId,
            }
          : item
      );
      return { ...state, cards, newStyleItems };
    }

    case EActionType.SaveStyle: {
      return {
        ...state,
        mode: Default,
        savedStyleItems: state.newStyleItems,
        dataToSend: state.dataToSend.map((item, index) => ({
          ...item,
          cardStyle: convertCardStyleToCardStyleInput(state.newStyleItems[index].cardStyle),
        })),
      };
    }

    case EActionType.ChangeColor: {
      const newStyleItems = state.newStyleItems.map((item) =>
        item.cardId === action.variable.cardId
          ? {
              ...item,
              cardStyle: item.cardStyle
                ? {
                    ...item.cardStyle,
                    bgColor: { ...action.variable.color },
                  }
                : undefined,
            }
          : item
      );
      return { ...state, newStyleItems };
    }

    default:
      return { ...state };
  }
};
