import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal';
import Avatar from 'components/UI/Avatar';

import { COLORS } from 'styles/constants';
import { useUpdateUserData } from 'graphQL/profile/update/hooks';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { StyledOption } from 'components/ListSettings/EditList/styled';
import {
  TEST_ID_EDIT_PROFILE_VIEW_BIO_LABEL,
  TEST_ID_EDIT_PROFILE_VIEW_CHANGE_PHOTO_BUTTON,
  TEST_ID_EDIT_PROFILE_VIEW_NAME_LABEL,
} from 'constants/aqa/profile';
import { useProfileSettingsLinks } from '../hooks';
import { StyledWrapper, StyledLabel, StyledInfo, StyledAvatarWrapper, StyledButton } from './styled';

const EditProfileData: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const { replace } = useHistory();
  const { home, changePhoto, changeName, changeBio } = useProfileSettingsLinks();

  const userData = useUpdateUserData();
  const { userInfo } = userData ?? {};
  const { bio } = userInfo ?? {};

  return (
    <Modal fadeIn={false} handleClose={handleClose} handlePrev={() => replace(home)}>
      <StyledWrapper>
        <div>
          <StyledLabel>Profile image</StyledLabel>
          <StyledAvatarWrapper>
            {userData && <Avatar dimension={96} user={userData} color={COLORS.white[100]} />}
            <StyledButton
              data-testid={TEST_ID_EDIT_PROFILE_VIEW_CHANGE_PHOTO_BUTTON}
              onClick={() => replace(changePhoto)}
            >
              Change photo
            </StyledButton>
          </StyledAvatarWrapper>
        </div>
        <StyledOption onClick={() => replace(changeName)}>
          <StyledLabel isPointer>Name</StyledLabel>
          <StyledInfo data-testid={TEST_ID_EDIT_PROFILE_VIEW_NAME_LABEL}>{generateFullName(userData)}</StyledInfo>
        </StyledOption>
        <StyledOption onClick={() => replace(changeBio)}>
          <StyledLabel isPointer>Short bio</StyledLabel>
          <StyledInfo data-testid={TEST_ID_EDIT_PROFILE_VIEW_BIO_LABEL} isEmpty={!bio}>
            {bio || 'Tap to add'}
          </StyledInfo>
        </StyledOption>
      </StyledWrapper>
    </Modal>
  );
};

export default EditProfileData;
