import React, { FC } from 'react';

import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';
import {
  TEST_ONBOARDING_TOUR_CANCEL_BTN,
  TEST_ONBOARDING_TOUR_CLOSE_BTN,
  TEST_ONBOARDING_TOUR_MESSAGE,
  TEST_ONBOARDING_TOUR_SUBMIT_BTN,
  TEST_ONBOARDING_TOUR_TITLE,
} from 'constants/aqa/onboarding';
import { EUiButtonType } from '../Button/constants';
import Button from '../Button';

import { StyledButtons, StyledText, StyledTitle, StyledWrap, StyledCross } from './styled';

interface IOnboardingAlertProps {
  title?: string;
  submitText?: string;
  cancelText?: string;
  handleCancel?: () => void;
  handleSubmit?: () => void;
  hasCross?: boolean;
  isTransparent?: boolean;
  isDark?: boolean;
  titleSize?: number;
  hasBottomMargin?: boolean;
  dataTestId?: string;
}

const OnboardingAlert: FC<IOnboardingAlertProps> = ({
  title,
  submitText,
  cancelText,
  handleCancel,
  handleSubmit,
  hasCross,
  isTransparent = false,
  isDark = false,
  titleSize = 14,
  hasBottomMargin = false,
  dataTestId,
  children,
}) => (
  <StyledWrap isTransparent={isTransparent} hasBottomMargin={hasBottomMargin} isDark={isDark}>
    {title && (
      <StyledTitle
        data-testid={TEST_ONBOARDING_TOUR_TITLE}
        titleSize={titleSize}
        isTransparent={isTransparent}
        isDark={isDark}
      >
        {title}
      </StyledTitle>
    )}
    {children && (
      <StyledText
        data-testid={TEST_ONBOARDING_TOUR_MESSAGE}
        isFirst={!title}
        isTransparent={isTransparent}
        isDark={isDark}
      >
        {children}
      </StyledText>
    )}
    {(handleCancel || handleSubmit) && (
      <StyledButtons isFirst={!children && !title}>
        {submitText && handleSubmit && (
          <Button
            dataTestId={dataTestId ?? TEST_ONBOARDING_TOUR_SUBMIT_BTN}
            type={isTransparent ? EUiButtonType.Primary : EUiButtonType.Emphasized}
            handler={handleSubmit}
            isDark={!isTransparent}
            text={submitText}
          />
        )}
        {cancelText && handleCancel && (
          <Button
            dataTestId={TEST_ONBOARDING_TOUR_CANCEL_BTN}
            type={EUiButtonType.Transparent}
            handler={handleCancel}
            isDark={false}
            text={cancelText}
          />
        )}
      </StyledButtons>
    )}
    {hasCross && handleCancel && (
      <StyledCross data-testid={TEST_ONBOARDING_TOUR_CLOSE_BTN} onClick={handleCancel}>
        <CrossIcon height="8px" width="8px" viewBox="0 0 11 11" />
      </StyledCross>
    )}
  </StyledWrap>
);

export default OnboardingAlert;
