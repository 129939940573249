export const isIos = (): boolean => /iPad|iPhone|iPod/.test(navigator.userAgent);
export const isMacOs = (): boolean => navigator.userAgent.includes('Mac');
export const isAndroid = (): boolean => navigator.userAgent.includes('Android');

export const isWindows = (): boolean => navigator.userAgent.includes('Windows');

export const isSafari = (): boolean =>
  navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');

export const isSafariIos = (): boolean => isIos() && isSafari();

export const isIosOrMacOs = (): boolean => isIos() || isMacOs();
