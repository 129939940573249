import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';

export const StyledList = styled.div`
  margin-top: 32px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledItem = styled.div`
  align-items: center;
  color: ${COLORS.white[100]};
  display: flex;
  font: 600 14px/140% ${FONTS.secondary};
  gap: 8px;
  justify-content: flex-start;
  margin-bottom: 32px;

  button {
    margin-left: auto;
    overflow: initial;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledEmoji = styled.div`
  background-color: ${COLORS.white[100]};
  border-radius: 44px;
  color: ${COLORS.black[100]};
  font-size: 22px;
  height: 44px;
  line-height: 28px;
  margin-left: -16px;
  min-width: 44px;
  padding: 8px;
  scrollbar-width: none;
  text-align: center;
`;
