import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Modal from 'components/Modal';
import Avatar from 'components/UI/Avatar';
import TextInput from 'components/UI/TextInput';
import SmallCard from 'components/List/SmallCard';
import useDisableScroll from 'helpers/useDisableScroll';

import { isIos } from 'helpers/useUserAgent';
import { ROUTE_LIST_EDIT_COLLABORATORS, ROUTE_LIST_INVITE_COLLABORATORS } from 'routes';
import { IAddContentLocation } from 'components/AddContent/helpers/models';
import { useGetInviteList, useInviteCollaborator } from 'graphQL/list/invite/hooks';
import { transformRoute } from 'helpers/routingHelper';
import { StyledDrag, StyledFooter, StyledComment } from 'components/AddContent/AddThought/styled';
import { COLORS } from 'styles/constants';
import { useProfile } from 'graphQL/profile/hooks';
import { EQueryFetchPolicy } from 'constants/common';
import { StyledPreview } from './styled';
import { ROLE_OPTIONS } from './constants';

const CollaboratorInvitation: FC = () => {
  const { userData } = useProfile(undefined, EQueryFetchPolicy.CacheOnly);
  const { replace } = useHistory();
  const { state } = useLocation<IAddContentLocation>();
  const { variable: stateData } = state ?? {};
  const { userId } = stateData ?? {};
  const { listId } = useParams<{ listId: string }>();
  const handleClose = () => {
    replace({ pathname: transformRoute(ROUTE_LIST_INVITE_COLLABORATORS, { listId }), state });
  };
  const { card: list } = useGetInviteList(listId);

  useEffect(() => {
    if (!userId) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useDisableScroll(!isIos());

  const [inviteText, setInviteText] = useState('');
  const [inviteRole, setInviteRole] = useState(ROLE_OPTIONS[2]);
  const { inviteMutation, loading } = useInviteCollaborator(
    userId ?? '',
    list?.cardId ?? '',
    inviteText,
    inviteRole.value
  );

  return (
    <Modal
      fadeIn={false}
      fadeOut={false}
      handleClose={handleClose}
      handlePrev={handleClose}
      selectButtonOptions={ROLE_OPTIONS}
      selectedButtonOption={inviteRole}
      selectButtonOptionHandler={setInviteRole}
      buttonText="Invite"
      handleDone={() => {
        inviteMutation().then(() => {
          replace({ pathname: transformRoute(ROUTE_LIST_EDIT_COLLABORATORS, { listId }), state });
        });
      }}
      loading={loading}
    >
      <StyledPreview bgColor={COLORS.transparent}>{list && <SmallCard item={list} disableLink />}</StyledPreview>
      <StyledFooter>
        <StyledDrag />
        <StyledComment>
          {userData && <Avatar user={userData} />}
          <TextInput
            inputHeight={80}
            placeholder="Add a note for the invitees"
            setText={(value: string) => setInviteText(value)}
            text={inviteText}
            type="thought"
            isMultiLine
          />
        </StyledComment>
      </StyledFooter>
    </Modal>
  );
};

export default CollaboratorInvitation;
