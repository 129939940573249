import { styled } from 'linaria/react';
import ContentTitle from 'components/UI/ContentTitle/styled';
import { FONTS, COLORS } from '../../../styles/constants';

export const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-top: 16px;
  position: relative;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTitle = styled(ContentTitle)<{ isDark: boolean }>`
  margin: 16px 0 32px;
`;

export const StyledTextWrap = styled.div`
  margin-top: 24px;
`;

export const StyledSubtitle = styled.p`
  font: 600 14px/140% ${FONTS.secondary};
  margin: 0;
`;

export const StyledText = styled.p`
  font: 400 14px/140% ${FONTS.secondary};
  margin: 0;
`;

export const StyledBottom = styled.div`
  border-top: 1px solid ${COLORS.white[30]};
  margin-top: 32px;
`;
