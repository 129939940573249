import React, { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { COLORS } from 'styles/constants';

import Card from 'components/Card';
import Modal from 'components/Modal';
import FollowButton from 'components/FollowButton';
import LoaderContent from 'components/UI/LoaderContent';
import BadgeWithChildren from 'components/UI/BadgeWithChildren';
import UniversalSlider from 'components/Sliders/UniversalSlider';

import { useGetUserId, useProfile } from 'graphQL/profile/hooks';
import { useGetSuggestedUserPicks } from 'graphQL/onboarding/hooks';
import { IProfilePreviewModalLocationState } from './ models';

import { StyledBadgeContainer, StyledHeader, StyledModalWrap, StyledSliderContainer } from './styled';

const ProfilePreviewModal: FC = () => {
  const { goBack } = useHistory();
  const { profileName } = useParams<{ profileName: string }>();
  const { state } = useLocation<IProfilePreviewModalLocationState>();
  const { userPicks: locationUserPicks, isSpotlight: locationIsSpotlight, disableBadge } = state?.variable || {};

  const { userId } = useGetUserId(profileName);
  const { userData, loading: profileLoading } = useProfile(userId ?? '', undefined, undefined, !userId);

  const { userPicks: picks, loading: picksLoading, isSpotlight } = useGetSuggestedUserPicks({
    userId,
    customSkip: !!locationUserPicks || !!locationIsSpotlight,
  });

  const userPicks = locationUserPicks || picks;

  return (
    <StyledModalWrap>
      <Modal enableInnerScroll fadeIn fadeOut hideGrain handleClose={goBack} bgColor={COLORS.white[100]} isDark={false}>
        {userData && !picksLoading && !profileLoading ? (
          <>
            <StyledHeader>{locationIsSpotlight ?? isSpotlight ? 'Spotlights' : 'Recent picks'}</StyledHeader>
            <StyledBadgeContainer>
              <BadgeWithChildren isDisabled={disableBadge} isDark={false} user={userData}>
                <FollowButton isDark={false} user={userData} />
              </BadgeWithChildren>
            </StyledBadgeContainer>
            <StyledSliderContainer hasOneCard={userPicks.length === 1}>
              <UniversalSlider
                touchStartPreventDefault={false}
                slideToClickedSlide
                removeButtons
                constantOffset={12}
                slidesPerView={2.25}
                items={userPicks}
                itemRender={(card) => (
                  <Card disableLinks isDark={false} card={card} shouldDisableLongTap showActivity />
                )}
              />
            </StyledSliderContainer>
          </>
        ) : (
          <LoaderContent isFullScreen isDark={false} />
        )}
      </Modal>
    </StyledModalWrap>
  );
};

export default ProfilePreviewModal;
