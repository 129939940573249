import React, { FC } from 'react';
import { SwiperSlide } from 'swiper/react';
import { ROUTE_SAVED_PICKS } from 'routes';

import SavedPicksItem from 'components/SavedPicks/SavedPicksContent/SavedPicksItem';
import UILink from 'components/UI/Link';
import ScrollContainer from 'components/ScrollContainer';

import { IUseCardsBaseSelection } from 'pages/OnboardingV2/ContentTutorial/ListFill/models';
import { CardSchema } from 'constants/graphqlTypes';
import { StyledListTitle } from '../AddToList/styled';
import { StyledLinkWrap, StyledSectionWrap, StyledTextWrap } from './styled';

interface ISavedPicksSectionProps {
  cards: CardSchema[];
  cardsBaseSelection: IUseCardsBaseSelection;
}

const SavedPicksSection: FC<ISavedPicksSectionProps> = ({ cards, cardsBaseSelection }) => {
  const { selectedCards, handleCardClick } = cardsBaseSelection || {};

  return (
    <>
      <StyledTextWrap>
        <StyledListTitle>
          Pick from your Saved for Later.{' '}
          <StyledLinkWrap>
            <UILink route={ROUTE_SAVED_PICKS}>View all</UILink>
          </StyledLinkWrap>
        </StyledListTitle>
      </StyledTextWrap>
      <StyledSectionWrap>
        <ScrollContainer slidesPerView={2} scrollbar spaceBetween={12}>
          {cards?.map((item) => (
            <SwiperSlide key={item.cardId}>
              <SavedPicksItem
                item={item}
                isSelectable
                isChecked={!!selectedCards?.includes(item.cardId)}
                isDark
                handler={handleCardClick}
              />
            </SwiperSlide>
          ))}
        </ScrollContainer>
      </StyledSectionWrap>
    </>
  );
};

export default SavedPicksSection;
