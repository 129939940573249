import React, { FC, useState, useEffect, SetStateAction, Dispatch } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { ReactComponent as EditIcon } from 'assets/icons/EditInfo.svg';
import IconCircle from 'components/UI/IconCircle/IconCircle';

import { getImageMeta } from '../hooks';
import { StyledWrap, StyledTitleWrapper } from './styled';

const MIN_CROP = 100;
const WIDTH_CROP = 390;

interface ICropImage {
  url: string | File;
  setCropper: Dispatch<SetStateAction<Cropper | undefined>>;
}

const CropImage: FC<ICropImage> = ({ url, setCropper }) => {
  const [cropImage, setCropImage] = useState<string | undefined>(undefined);
  const [ratio, setRatio] = useState<number | null>(null);

  useEffect(() => {
    if (typeof url === 'object') {
      const reader = new FileReader();
      reader.onload = () => {
        setCropImage(reader.result as string);
      };
      reader.readAsDataURL(url);
    } else {
      setCropImage(url);
    }
  }, [url]);

  useEffect(() => {
    if (cropImage) {
      getImageMeta(cropImage, (w, h) => {
        setRatio(w / h);
      });
    }
  }, [cropImage]);

  return (
    <div>
      <StyledTitleWrapper>
        <IconCircle Icon={EditIcon} isDark hasBorder />
        <span>Crop</span>
      </StyledTitleWrapper>
      <StyledWrap>
        {cropImage && ratio && (
          <Cropper
            style={{ width: WIDTH_CROP, height: WIDTH_CROP / ratio, maxHeight: '70vh' }}
            src={cropImage}
            zoomOnWheel={false}
            movable
            dragMode="move"
            viewMode={3}
            center={false}
            minCropBoxWidth={MIN_CROP}
            minCropBoxHeight={MIN_CROP}
            preview=".img-preview"
            autoCropArea={0.9}
            aspectRatio={1}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        )}
      </StyledWrap>
    </div>
  );
};

export default CropImage;
