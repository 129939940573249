import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CreateListMutation, ENewOnboardingStepOptions } from 'constants/graphqlTypes';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import SinglePageLayout from 'components/SinglePageLayout';
import Button from 'components/UI/Button';
import useTheme from 'helpers/useTheme';
import useCreateList from 'graphQL/addContent/createList/hook';
import { useUpdateCachedOnboardingStepV2, useUpdateOnboardingStepV2 } from 'graphQL/onboarding/hooks';
import { ROUTE_ONBOARDING_CONTENT_LIST_DETAIL } from 'routes';

import { listDescription } from './constants';
import ListDescriptionSection from './ListDescriptionSection';

import { StyledButtonWrapper, StyledWrap } from './styled';

interface IListCreationProps {
  setListId: Dispatch<SetStateAction<string | undefined>>;
  listId?: string;
}
const ListCreation: FC<IListCreationProps> = ({ setListId, listId }) => {
  const posthogCapture = usePostHogCapture();
  const isDark = useTheme();
  const location = useLocation();
  const { push } = useHistory();

  useUpdateOnboardingStepV2(ENewOnboardingStepOptions.CreateList);

  const updateCache = useUpdateCachedOnboardingStepV2(ENewOnboardingStepOptions.ManageList);
  const goToNextPage = (data?: CreateListMutation | null) => {
    updateCache();
    setListId(data?.createCollection?.id);
    push({
      pathname: ROUTE_ONBOARDING_CONTENT_LIST_DETAIL,
      state: {
        ...location.state,
        id: data?.createCollection?.id,
      },
    });
  };

  const { createList, loading } = useCreateList(
    'In my ears, eyes and mind',
    false,
    'My Top Picks in Books, Beats, and Binge-Watching',
    goToNextPage,
    false
  );

  const handleCreate = (): void => {
    if (listId) {
      push({
        pathname: ROUTE_ONBOARDING_CONTENT_LIST_DETAIL,
        state: {
          ...location.state,
          id: listId,
        },
      });
    } else {
      createList();
    }
  };

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.OnboardingListInstructions);
  }, []);

  return (
    <StyledWrap>
      <SinglePageLayout
        title="A list is a collection of picks from the web that you curate and share with the world."
        paddingTop={72}
        paddingBottom={24}
      >
        {listDescription.map((item) => (
          <ListDescriptionSection item={item} isDark={isDark} key={item.title} />
        ))}
      </SinglePageLayout>
      <StyledButtonWrapper>
        <Button text="Create your first list" handler={handleCreate} isDark={isDark} loading={loading} />
      </StyledButtonWrapper>
    </StyledWrap>
  );
};

export default ListCreation;
