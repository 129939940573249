import React, { FC } from 'react';
import { SwiperSlide } from 'swiper/react';
import { v4 } from 'uuid';

import OuterLink from 'components/UI/OuterLink/OuterLink';
import InfoCard from 'components/UI/InfoCard';
import SliderInteractive from 'components/SliderInteractive';

import { COLORS } from 'styles/constants';
import { StyledContainer, StyledSlideWrap } from './styled';

interface IInfoSlider {
  isDark: boolean;
  onSetIndex: (index: number) => void;
  items: {
    bgColor?: string;
    title: string;
    text: string;
    Icon: FC;
    fullInfo?: { title: string; text: string }[];
  }[];
}

const InfoSlider: FC<IInfoSlider> = ({ isDark, items, onSetIndex }) => (
  <StyledContainer>
    <SliderInteractive
      customSlidesPerView={1.075}
      spaceBetween={12}
      fontColor={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
      showBtnsSm={!!items.length}
    >
      {items.map((item, index) => (
        <SwiperSlide key={v4()}>
          <StyledSlideWrap onClick={() => onSetIndex(index)}>
            <InfoCard bgColor={item.bgColor} Icon={item.Icon} title={item.title}>
              {item.text} <OuterLink url="#" text="Read more" />
            </InfoCard>
          </StyledSlideWrap>
        </SwiperSlide>
      ))}
    </SliderInteractive>
  </StyledContainer>
);

export default InfoSlider;
