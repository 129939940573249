import { useEffect } from 'react';

import { useUpdateOpenAppCounterMutation } from 'constants/graphqlTypes';
import getAuthUser from './getAuthUser';

const useUpdateOpenAppCounter = (): void => {
  const [mutation] = useUpdateOpenAppCounterMutation();
  const { username } = getAuthUser();
  useEffect(() => {
    if (username) {
      mutation();
    }
  }, [mutation]);
};

export default useUpdateOpenAppCounter;
