import React, { FC } from 'react';
import Truncate from 'react-truncate-markup';

import Avatar from 'components/UI/Avatar';

import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { transformRoute } from 'helpers/routingHelper';
import { UserObjectType } from 'constants/graphqlTypes';
import { ROUTE_USER_PROFILE } from 'routes';
import { StyledLink, StyledUserInvite } from './styled';

const User: FC<{ user: UserObjectType; isDark: boolean; hasPrivateText: boolean }> = ({
  user,
  isDark,
  hasPrivateText,
}) => {
  const { username } = user;

  const privateInviteText = hasPrivateText ? ' on a private list' : '';

  return (
    <>
      <StyledLink to={transformRoute(ROUTE_USER_PROFILE, { profileName: username })}>
        <Avatar user={user} />
      </StyledLink>
      <StyledUserInvite isDark={isDark}>
        <Truncate lines={1}>
          <span>{generateFullName(user)}</span>
        </Truncate>
        {` invited you to collaborate${privateInviteText}`}
      </StyledUserInvite>
    </>
  );
};

export default User;
