import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Modal from 'components/Modal';
import Button from 'components/UI/MenuButton';
import useToast from 'helpers/useToast';
import usePageType from 'helpers/usePageType';
import getAuthUser from 'helpers/getAuthUser';

import { useChangeCollectionLayout } from 'graphQL/list/update/hooks';
import { ECollectionLayoutOptions } from 'constants/graphqlTypes';
import { ROUTE_LIST_DETAIL, ROUTE_USER_ALL_PICKS } from 'routes';
import { transformRoute } from 'helpers/routingHelper';
import { useGetDetailList } from 'graphQL/list/hooks';
import { useProfile } from 'graphQL/profile/hooks';
import { StyledList } from 'components/ProfileSettings/Menu/styled';
import { StyledItem } from './styled';
import { viewOptions } from './helpers/constants';

const ChangeLayout: FC = () => {
  const { replace } = useHistory();
  const { listId } = useParams<{ listId: string }>();
  const { isAllPicks } = usePageType();
  const { card } = useGetDetailList({ id: listId });
  const { cardStyle } = card ?? {};
  const { username } = getAuthUser();
  const { userData } = useProfile() ?? {};
  const { setToast } = useToast();

  const existingLayout =
    (isAllPicks ? userData?.userStyle?.layoutCollection : cardStyle?.layoutCollection) ??
    ECollectionLayoutOptions.Default;

  const handleClose = () => {
    replace(transformRoute(isAllPicks ? ROUTE_USER_ALL_PICKS : ROUTE_LIST_DETAIL, { listId, profileName: username }));
  };

  const id = isAllPicks ? userData?.cardId : card?.cardId;
  const changeListViewMutation = useChangeCollectionLayout(id ?? '', listId, existingLayout);

  const handleUpdateView = (layout: ECollectionLayoutOptions) => {
    if (!id || layout === existingLayout) {
      return;
    }

    changeListViewMutation({ variables: { id, collectionLayout: layout } })
      .then(handleClose)
      .catch(({ message }) => setToast({ isToastOpen: true, toastItemName: message, toastError: true }));
  };

  return (
    <Modal fadeIn={false} handleClose={handleClose}>
      <StyledList>
        {viewOptions.map(({ layout, key, customIconText, Icon }) => (
          <StyledItem isSelected={existingLayout === layout} key={key}>
            <Button
              text={key}
              Icon={Icon}
              customIconText={customIconText}
              handler={() => handleUpdateView(layout)}
              hasStroke={layout === ECollectionLayoutOptions.ImageGrid}
            />
          </StyledItem>
        ))}
      </StyledList>
    </Modal>
  );
};

export default ChangeLayout;
