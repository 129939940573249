import { styled } from 'linaria/lib/react';

import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';
import { DEFAULT_GRADIENT } from 'components/Collection/helpers';

export const StyledContainer = styled.div`
  align-items: center;
  background: ${COLORS.white.off[100]};
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  min-height: 116px;
  padding: 8px;
  width: 100%;
`;

export const StyledFeedCardWrap = styled.div`
  padding: 0 12px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0;
  }
`;

export const StyledAddCustomFeed = styled(StyledContainer)`
  cursor: pointer;
  justify-content: center;
  gap: 4px;

  > span {
    color: ${COLORS.brown.dark[100]};
    font: 600 14px/140% ${FONTS.secondary};
  }
`;

export const StyledImageWrap = styled.div<{ counter?: number }>`
  position: relative;

  &::after {
    align-items: center;
    background: ${COLORS.green.light};
    border-radius: 50%;
    border: 2px solid ${COLORS.white.off[100]};
    color: ${COLORS.brown.dark[100]};
    content: ${({ counter }) => (counter ? `'${counter}'` : '')};
    display: ${({ counter }) => (counter ? 'block' : 'none')};
    display: flex;
    font: 400 12px/140% ${FONTS.secondary};
    height: 22px;
    justify-content: center;
    position: absolute;
    right: -5px;
    top: -5px;
    min-width: 22px;
    padding: 0 5px;
  }
`;

export const StyledCover = styled.div<{ src?: string; bgColor?: string }>`
  background: ${({ src, bgColor }) => (src ? `url(${src})` : bgColor ?? DEFAULT_GRADIENT)};
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  flex-shrink: 0;
  height: 100px;
  position: relative;
  width: 100px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const StyledTitle = styled.p`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${COLORS.brown.dark[100]};
  display: -webkit-box;
  font: 400 14px/140% ${FONTS.primary};
  margin: 0;
  overflow: hidden;
`;

export const StyledTags = styled.p`
  color: ${COLORS.brown.light[100]};
  font: 400 12px/140% ${FONTS.secondary};
  margin: 0;
  overflow: hidden;
  padding-top: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledAvatarsWrap = styled.div`
  align-items: center;
  display: flex;
  padding-left: 8px;
  padding-top: 8px;
`;

export const StyledAvatarsWrapText = styled.span`
  color: ${COLORS.brown.dark[100]};
  font: 400 12px/140% ${FONTS.secondary};
  padding-left: 6px;
`;

export const StyledDotMenuWrap = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: 10px;

  > svg {
    cursor: pointer;
  }
`;

export const StyledImageUser = styled.img<{ hasUnreadPicks?: boolean }>`
  border-radius: 25px;
  border: 2px solid ${({ hasUnreadPicks }) => (hasUnreadPicks ? COLORS.green.light : COLORS.white[100])};
  height: 34px;
  left: 8px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 34px;
`;
