import { styled } from 'linaria/lib/react';
import { StyledSubtitle } from 'components/UI/ContentSubtitle/styled';
import StyledTitle from 'components/UI/ContentTitle/styled';
import { COLORS } from 'styles/constants';
import { StyledInfoContainer } from 'components/Onboarding/Layout/styled';

export const StyledWrap = styled.div<{ maxWidth?: number }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100dvh - var(--keyboard-offset));
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '390px')};
  margin: 0 auto;
  overflow-y: visible;
  overflow-x: visible;
  padding: 75px 6px 45px;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .swiper {
    margin: 0;
    padding-top: 0;
  }
`;

export const StyledPageInfoContainer = styled(StyledInfoContainer)<{ isPaddingBottom?: boolean }>`
  padding: 10px 18px ${({ isPaddingBottom }) => (isPaddingBottom ? '26' : '32')}px;
`;

export const StyledContent = styled.section<{ isDark: boolean }>`
  border-top: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.dark[10])};
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  align-items: center;
`;

export const StyledTextContainer = styled.div`
  text-align: center;
  padding: 16px 32px;
`;

export const StyledMainInfo = styled(StyledTitle)`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  padding: 0 8px 8px;
`;

export const StyledInfo = styled(StyledSubtitle)`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  padding: 0 8px 8px;
`;

export const StyledButtonContainer = styled.div`
  margin: auto auto 0;
  max-width: 390px;
  width: 100%;

  & > button {
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  align-items: center;
  background-color: ${COLORS.brown.dark[100]};
  border: none;
  border-radius: 100px;
  box-shadow: 0px 11px 6px ${COLORS.black[10]}, 0px 5px 5px ${COLORS.black[20]}, 0px 1px 3px ${COLORS.black[20]},
    0px 0px 0px ${COLORS.black[20]};
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 58px;
  padding: 0;
  width: 58px;

  path {
    stroke: ${COLORS.white[100]};
  }
`;

export const StyledBottomMenu = styled.div<{ bottom?: number }>`
  bottom: ${({ bottom }) => bottom || 42}px;
  display: flex;
  left: 50%;
  max-width: calc(100% - 24px);
  position: fixed;
  transform: translateX(-50%);
  width: max-content;
  z-index: 100;
`;
