import React, { FC, useEffect } from 'react';

import { CardSchema } from 'constants/graphqlTypes';
import { useIsUserAllowedToReact, useItemUrl } from 'components/Card/helpers/hooks';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import usePageType from 'helpers/usePageType';
import useReferrerId from 'helpers/useReferrerId';
import getAuthUser from 'helpers/getAuthUser';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import InteractionBlock from 'components/InteractionBlock';
import ReactionsModal from 'components/Reactions/ReactionsModal';
import { ReactionsProvider, useReactionContextState } from 'components/Reactions/hooks';

import { StyledLink, StyledWrap } from './styled';
import Card from './Card';

interface IListCardProps {
  item: CardSchema;
  isReplace?: boolean;
  disableLink?: boolean;
  dataTestId?: string;
}

const BigCard: FC<IListCardProps> = ({ disableLink, item, isReplace, dataTestId }) => {
  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  const { thoughtId, setThoughtId, pickerId, setPickerId, cardId, setCardId } = useReactionContextState();

  const { id, isCollaborative, creators } = item;
  const isUserAllowedToReact = useIsUserAllowedToReact(creators?.items[0]?.user);
  const { isInvite, isMessages } = usePageType();

  const url = useItemUrl(id, true);
  const { userId } = getAuthUser();
  const reffererId = useReferrerId();
  const isCurrentUserList = !!creators?.items?.find((data) => data?.user?.id === userId)?.user?.id;
  const isDisableReaction =
    isCurrentUserList || isCollaborative || isInvite || isMessages || !isUserAllowedToReact || disableLink;

  useEffect(() => {
    setPickerId(creators?.items?.[0]?.user?.id ?? reffererId ?? null);
    setCardId(id);
  }, []);

  return (
    <ReactionsProvider
      value={{
        isQuickReactionOpen,
        setThoughtId,
        thoughtId,
        setPickerId,
        pickerId,
        cardId,
        setCardId,
      }}
    >
      <StyledWrap
        className="bigcard"
        data-testid={dataTestId}
        ref={infoRef}
        disableLinks={isQuickReactionOpen || disableLink}
      >
        <InteractionBlock
          tapCallback={() => setIsQuickReactionOpen(false)}
          longTapCallback={() => !isDisableReaction && setIsQuickReactionOpen(true)}
          getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
        >
          <div>
            <StyledLink
              onClick={(e) => {
                if (disableLink) {
                  e.preventDefault();
                }
              }}
              className="card collection-preview-anchor"
              replace={isReplace}
              to={url}
            >
              <Card item={item} />
            </StyledLink>
          </div>
        </InteractionBlock>
        {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && !isDisableReaction && (
          <ReactionMenu
            isShown={isQuickReactionOpen}
            reactionCoordinates={reactionCoordinates}
            handleClickOutside={() => setIsQuickReactionOpen(false)}
            minusTop={minusTop}
            setIsReactionsModalOpen={setIsReactionsModalOpen}
            setIsQuickReactionOpen={setIsQuickReactionOpen}
          />
        )}
        {isReactionsModalOpen && <ReactionsModal id={id} handleClose={() => setIsReactionsModalOpen(false)} />}
      </StyledWrap>
    </ReactionsProvider>
  );
};

export default BigCard;
