import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/Clock.svg';
import { ReactComponent as GridIcon } from 'assets/icons/GridSearch.svg';

export enum ESearchTag {
  User = 'user:',
  Time = 'time:',
  Type = 'type:',
}

export const SEARCH_FILTER_BTNS = [
  { title: 'User', icon: UserIcon, placeholder: ESearchTag.User },
  { title: 'Time', icon: ClockIcon, placeholder: ESearchTag.Time },
  { title: 'Type', icon: GridIcon, placeholder: ESearchTag.Type },
];

export const SUGGESTED_TIMES = ['Last 24 hours', 'Last week', 'Last month', 'Last 6 months'];
export const SUGGESTED_TYPES = ['Article', 'Podcast', 'Video', 'Tweets', 'Music'];

export const SUGGESTED_TIMES_TEST_IDS = ['day', 'week', 'month', 'half-year'];
