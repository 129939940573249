import React, { Dispatch, FC, SetStateAction } from 'react';

import { ColorSchema } from 'constants/graphqlTypes';
import { StyledColorButton, StyledColorsList } from './styled';

interface IColorsProps {
  setColor?: Dispatch<SetStateAction<string>>;
  items: ColorSchema[];
  color?: string;
  dispatch?: (color: ColorSchema) => void;
}

const Colors: FC<IColorsProps> = ({ items, color, setColor, dispatch }) => (
  <StyledColorsList>
    {items.map((item) => (
      <li key={item.id}>
        <StyledColorButton
          onClick={() => (dispatch ? dispatch(item) : setColor?.(item.colorValue))}
          isCurrent={item.colorValue === color}
          color={item.colorValue}
        />
      </li>
    ))}
  </StyledColorsList>
);

export default Colors;
