import React, { FC } from 'react';
import Truncate from 'react-truncate-markup';
import { formatDistanceToNowStrict } from 'date-fns';

import { ROUTE_LIST_DETAIL } from 'routes';
import { CardSchema, UnionExtraData } from 'constants/graphqlTypes';

import { TEST_ACTION_THOUGHT } from 'constants/aqa/pickDetails';
import { getWebDate, getWeeklyDateFormat } from 'helpers/getDateFormat';
import { useReactionContext } from 'components/Reactions/hooks';
import withHapticFeedback from 'helpers/withHapticFeedback';
import useThoughtsModal from 'helpers/useThoughtsModal';
import usePageType from 'helpers/usePageType';

import UILink from 'components/UI/Link';
import ThoughtLastUpdate from 'components/Thoughts/Thought/ThoughtLastUpdate';
import ThoughtAnimation from './ThoughtAnimation';

import { StyledAction, StyledText, StyledToggle, StyledTruncateWrapper } from './styled';

interface IActionContentProps {
  item: CardSchema;
  action: UnionExtraData;
  isDetail?: boolean;
  isSmall?: boolean;
  isVirtualAction?: boolean;
  isReactionButtonMenuShown?: boolean;
}

export const ACTION_WRAPPER_TRUNCATE_CLASS = 'action-truncate-wrapper';

const ActionContent: FC<IActionContentProps> = ({
  action,
  item,
  isDetail,
  isSmall,
  isVirtualAction,
  isReactionButtonMenuShown,
}) => {
  const { id, responseToQuestion, questionGuestList } = item;
  const { isItemDetail } = usePageType();

  const user = action?.user;
  const { username = '', userInfo } = user ?? {};
  const linesDetailsTruncateCount = userInfo?.bio && userInfo.bio.length > 43 ? 3 : 4;

  const openThoughts = useThoughtsModal({ id, hideTabs: !isItemDetail, openFromCardDetailPage: isItemDetail });

  const { isQuickReactionOpen } = useReactionContext();

  const handleTruncateClick = () => {
    if (!isVirtualAction && !isReactionButtonMenuShown && !isQuickReactionOpen) {
      withHapticFeedback(openThoughts)();
    }
  };

  return (
    <>
      {action?.__typename === 'CardCommentSchema' && (!action?.isSuspicious || isDetail) && (
        <>
          <StyledTruncateWrapper className={ACTION_WRAPPER_TRUNCATE_CLASS} onClickCapture={handleTruncateClick}>
            {isDetail ? (
              action?.text && <ThoughtAnimation isVirtualAction={isVirtualAction} text={`“${action.text}”`} />
            ) : (
              <Truncate
                lines={!isItemDetail || isSmall ? 2 : linesDetailsTruncateCount}
                ellipsis={
                  <>
                    ... <StyledToggle>more</StyledToggle>
                  </>
                }
              >
                <StyledText data-testid={TEST_ACTION_THOUGHT}>&ldquo;{action?.text}&rdquo;</StyledText>
              </Truncate>
            )}
          </StyledTruncateWrapper>
          {isItemDetail && (
            <ThoughtLastUpdate
              lastUpdate={getWeeklyDateFormat(action.createdAt)}
              username={username}
              parentCard={action?.collection}
              guestListQuestion={questionGuestList}
              responseToQuestion={responseToQuestion}
            />
          )}
        </>
      )}
      {(action?.__typename === 'CardPickSchema' ||
        (action?.__typename === 'CardCommentSchema' && action?.isSuspicious && !isDetail)) && (
        <StyledAction>
          Added &nbsp;
          <b>
            {formatDistanceToNowStrict(getWebDate(action?.createdAt), {
              addSuffix: true,
              roundingMethod: 'floor',
            })}
          </b>
        </StyledAction>
      )}
      {action?.__typename === 'CollectionCardLinkSchema' && (
        <StyledAction>
          Added to&nbsp;
          <UILink route={ROUTE_LIST_DETAIL} params={{ listId: action?.collection?.id }}>
            <b>{action?.collection?.title}</b>
          </UILink>
        </StyledAction>
      )}
    </>
  );
};

export default ActionContent;
