import { EDateCreatedActivityOptions, GetActivityQuery } from 'constants/graphqlTypes';

export const mergeActivity = (prev: GetActivityQuery, next?: GetActivityQuery): GetActivityQuery => {
  const old = prev.activities;
  const prevItems = old?.items;
  const nextItems = next?.activities?.items;

  return old && prevItems && nextItems
    ? {
        ...prev,
        activities: { ...old, items: [...prevItems, ...nextItems] },
      }
    : prev;
};

export const getActivityListTitle = (index: number): string => {
  if (index === 2) {
    return 'Earlier';
  }

  return index ? 'This month' : 'Today';
};

export const getCreatedAt = (createdAt: string | null): EDateCreatedActivityOptions => {
  switch (createdAt) {
    case EDateCreatedActivityOptions.Earlier:
      return EDateCreatedActivityOptions.Earlier;
    case EDateCreatedActivityOptions.Month:
      return EDateCreatedActivityOptions.Month;
    default:
      return EDateCreatedActivityOptions.Today;
  }
};
