import { styled } from 'linaria/react';

import StyledButton from '../../UI/Button/styled';

export const StyledSocialButton = styled(StyledButton)`
  padding: 0 15px;
  display: grid;
  grid-template-columns: 0 1fr;
  align-items: center;
  width: 100%;
`;
