import React, { FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { ROUTE_MESSAGES, ROUTE_MESSAGES_SETTINGS_ADD_MEMBERS, ROUTE_MESSAGES_SETTINGS_TITLE } from 'routes';

import { ReactComponent as LeaveIcon } from 'assets/icons/Leave.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { ReactComponent as BlockIcon } from 'assets/icons/Block.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';

import Button from 'components/UI/MenuButton';
import Badge from 'components/UI/Badge';
import LoaderContent from 'components/UI/LoaderContent';
import { EMenuButtonType } from 'components/UI/MenuButton/constants';

import { GroupChatSchema } from 'constants/graphqlTypes';
import { useChatNotifications, useGetGroupChatMembers } from 'graphQL/messages/hooks';
import { useProfile } from 'graphQL/profile/hooks';
import { ENotificationSettingField } from 'graphQL/messages/constants';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';

import ChatSection from './ChatSection';
import LeaveGroupChatButton from './Buttons/LeaveGroupChatButton';
import BlockChatButton from './Buttons/BlockChatButton';
import { EReportTypes } from '../../Report/constants';
import { getChatMembers } from './helpers';
import { StyledMemberWrap, StyledGroupName, StyledItemWrap } from './styled';

const GroupChatSettings: FC<{ chat: GroupChatSchema }> = ({ chat }) => {
  const { replace, goBack } = useHistory();
  const { pathname, state } = useLocation<{ variable: { chatId: string } }>();

  const { id, title, isBlockedByMe } = chat;

  const { userData } = useProfile();
  const { members, loading, loadMore } = useGetGroupChatMembers(id, 10);
  const { notificationSettings, updateNotificationSettings } = useChatNotifications(id);

  const handleClose = () => replace(ROUTE_MESSAGES);
  const openChangeTitle = () =>
    replace({
      pathname: ROUTE_MESSAGES_SETTINGS_TITLE,
      state: { ...state, variable: { ...state.variable, chatTitle: title ?? '' } },
    });

  const openAddMember = () =>
    replace({
      pathname: ROUTE_MESSAGES_SETTINGS_ADD_MEMBERS,
      state: { ...state, variable: { ...state.variable } },
    });

  const toReport = useNavigateToReport({
    pathname,
    type: EReportTypes.Chat,
    chatId: id,
  });

  return (
    <>
      {!isBlockedByMe && (
        <ChatSection name="Group name">
          <StyledGroupName onClick={openChangeTitle} hasTitle={!!title}>
            {title || 'Add a name'}
          </StyledGroupName>
        </ChatSection>
      )}
      <ChatSection name="Members">
        {[userData, ...getChatMembers(members)].map(
          (chatMember) =>
            chatMember && (
              <StyledMemberWrap key={chatMember.id}>
                <Badge user={chatMember} hasNameOnly />
              </StyledMemberWrap>
            )
        )}
        {loading ? <LoaderContent /> : <Waypoint onEnter={loadMore} />}
        {!isBlockedByMe && <Button text="Add member" Icon={PlusIcon} hasStroke handler={openAddMember} />}
      </ChatSection>
      {!isBlockedByMe && (
        <ChatSection name="Notifications">
          {notificationSettings.map(
            (setting) =>
              setting &&
              setting.notificationSettingType && (
                <StyledItemWrap key={setting.id}>
                  <Button
                    text={ENotificationSettingField[setting.notificationSettingType]}
                    handler={() => updateNotificationSettings(setting.id)}
                    type={EMenuButtonType.Toggle}
                    checked={!!setting.isEnable}
                  />
                </StyledItemWrap>
              )
          )}
        </ChatSection>
      )}
      <ChatSection name="Actions">
        <LeaveGroupChatButton chatId={id} isGroupChat handleAccept={handleClose}>
          <StyledItemWrap>
            <Button text="Leave group" Icon={LeaveIcon} />
          </StyledItemWrap>
        </LeaveGroupChatButton>

        <BlockChatButton chat={chat} handler={isBlockedByMe ? goBack : handleClose}>
          <StyledItemWrap>
            <Button text={`${isBlockedByMe ? 'Unblock' : 'Block'} group`} Icon={BlockIcon} />
          </StyledItemWrap>
        </BlockChatButton>
        <StyledItemWrap>
          <Button text="Report group" Icon={ReportIcon} handler={() => toReport()} />
        </StyledItemWrap>
      </ChatSection>
    </>
  );
};

export default GroupChatSettings;
