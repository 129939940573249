import React, { FC } from 'react';

import Button from 'components/UI/Button';
import useTheme from 'helpers/useTheme';

import { TabOptions } from 'constants/graphqlTypes';
import { StyledList } from './styled';
import { getTabButtonType } from '../helpers/helpers';
import { useMessagesTabs } from '../helpers/hooks';

const MessagesTabs: FC = () => {
  const { tab, setTab } = useMessagesTabs(true);

  const isDark = useTheme();

  return (
    <StyledList>
      <li>
        <Button type={getTabButtonType(tab, null)} isDark={isDark} handler={() => setTab(null)} text="All" />
      </li>
      <li>
        <Button
          type={getTabButtonType(tab, TabOptions.ActiveThreads)}
          isDark={isDark}
          handler={() => setTab(TabOptions.ActiveThreads)}
          text="Active threads"
        />
      </li>
      <li>
        <Button
          type={getTabButtonType(tab, TabOptions.Reactions)}
          isDark={isDark}
          handler={() => setTab(TabOptions.Reactions)}
          text="Reactions"
        />
      </li>
    </StyledList>
  );
};

export default MessagesTabs;
