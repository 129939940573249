import { useGetCommentReactionsList } from 'graphQL/card/comments/hooks';
import { useGetDetailListReactions } from 'graphQL/list/hooks';
import { useGetChatMessageReactions } from 'graphQL/messages/hooks';
import { useGetCardDetailReactions } from 'graphQL/reactions/hooks';

export interface IContentIds {
  chatId?: string;
  pickId?: string;
  commentId?: string;
  messageId?: string;
  listId?: string;
}

export const useContentReactions = (
  { chatId, pickId, commentId, messageId, listId }: IContentIds,
  isDetail?: boolean
) => {
  const cardDetailReactions = useGetCardDetailReactions(pickId || '', !isDetail);
  const commentReactions = useGetCommentReactionsList(pickId, commentId);
  const listReactions = useGetDetailListReactions(listId);
  const messageReactions = useGetChatMessageReactions(chatId || '', messageId || '', !chatId || !messageId);

  return { commentReactions, cardDetailReactions, listReactions, messageReactions };
};
