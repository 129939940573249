import { styled } from 'linaria/react';
import { css } from 'linaria';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

const CARD_IMAGE_HEIGHT = 392;
const CARD_SOURCE_HEIGHT = 43;

const StyledCardWrapper = styled.div`
  color: ${COLORS.white[100]};
  padding: 40px 12px 12px;
  position: relative;
`;

export const StyledContentCard = styled(StyledCardWrapper)<{
  gradientStart?: string | null;
  gradientEnd?: string | null;
  isDark: boolean;
  bgColor?: string;
}>`
  background: ${({ gradientStart, isDark, bgColor }) =>
    bgColor ?? gradientStart ?? (isDark ? COLORS.black[100] : COLORS.white[100])};
  transition: 0.3s background ease 0.01s;

  &::before {
    background: ${({ gradientEnd }) =>
      `linear-gradient(${COLORS.transparent} 5%, ${gradientEnd ?? COLORS.black[60]} 100%)`};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: ${({ gradientStart, bgColor }) => (gradientStart && !bgColor ? '1' : '0')};
  }
`;

export const StyledTextCard = styled(StyledCardWrapper)<{ bgColor: string }>`
  background: ${({ bgColor }) => bgColor};
`;

export const StyledDescription = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  font: 400 14px/140% ${FONTS.secondary};
  padding: 0 12px;
  position: relative;
`;

export const StyledAvatars = styled.div`
  margin-left: 10px;
`;

export const StyledAvatarsContainer = styled(StyledAvatars)`
  margin-top: 4px;
`;

export const StyledImage = styled.img`
  border-radius: 8px;
  object-fit: cover;
`;

export const StyledHorizontalImage = css`
  width: 100%;
  height: auto;
  max-height: ${CARD_IMAGE_HEIGHT}px;
`;

export const StyledVerticalImage = css`
  height: ${CARD_IMAGE_HEIGHT}px;
  max-width: 100%;
`;

export const StyledAnimatedImage = styled.div`
  .big-list-image {
    animation: card ease 300ms 1 forwards;
    opacity: 0;

    @keyframes card {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`;

export const StyledSourceWrapper = styled.div`
  color: ${COLORS.white[100]};
  font-family: ${FONTS.secondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  height: ${CARD_SOURCE_HEIGHT}px;
  position: relative;
`;

export const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${CARD_IMAGE_HEIGHT + CARD_SOURCE_HEIGHT}px;
  justify-content: center;
  margin: 26px auto 12px;
  overflow: hidden;
  position: relative;
  width: fit-content;
`;

export const StyledSquareCard = styled.div<{ bgColor: string }>`
  align-items: center;
  aspect-ratio: 1;
  background: ${({ bgColor }) => bgColor};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 40px;
  position: relative;
  overflow: hidden;

  div {
    border-radius: inherit;
  }
`;

export const StyledTextsList = styled.ul`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  height: 180px;
  list-style: none;
  margin: 0;
  overflow: hidden;
`;

export const StyledTextItem = styled.li<{ opacity: number }>`
  color: ${COLORS.white[100]};
  font-size: 22px;
  line-height: 130%;
  opacity: ${({ opacity }) => opacity};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledTextWrapper = styled.div`
  padding: 89px 53px;
`;

export const StyledFooter = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

export const StyledShare = styled.button`
  background-color: ${COLORS.transparent};
  border: none;
  cursor: pointer;
  padding: 10px;
  path {
    fill: ${COLORS.white[100]};
  }
`;

export const StyledFooterInfo = styled.div`
  align-items: center;
  display: flex;
  color: ${COLORS.white[100]};
  font-family: ${FONTS.secondary};
  font-size: 12px;
  flex-grow: 1;
  gap: 7px;
  line-height: 140%;
  padding: 0 12px 12px;
`;

export const StyledDot = styled.div`
  background-color: ${COLORS.green.light};
  border-radius: 50%;
  height: 6px;
  width: 6px;
`;

export const StyledLabel = styled.div`
  padding: 4px 8px;
  background-color: ${COLORS.white.off[100]};
  border-radius: 4px;
  color: ${COLORS.brown.dark[100]};
  font: 400 12px/140% ${FONTS.secondary};
  margin-right: 16px;
  width: fit-content;
`;

export const StyledEmptyCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: ${BREAKPOINTS.s}px) {
    padding: 0 12px;
  }
`;

export const StyledDate = styled.div`
  font-size: 12px;
`;

export const StyledMetaData = styled.div`
  display: flex;
  gap: 16px;
  margin: 0 12px;

  .collaborators {
    font-size: 14px;
  }
`;

export const StyledSource = styled.div`
  bottom: 0;
  margin: 13px;
  position: absolute;
`;
