import React, { FC, SetStateAction, Dispatch, ChangeEvent } from 'react';

import { ReactComponent as AddFromLinkIcon } from 'assets/icons/PickFromLink.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg';

import Button from 'components/UI/MenuButton';
import { StyledItem } from 'components/AddContent/Menu/styled';

import useToast from 'helpers/useToast';
import {
  TEST_ID_UPLOAD_PHOTO_VIEW_ADD_FROM_LINK_BUTTON,
  TEST_ID_UPLOAD_PHOTO_VIEW_CHOOSE_FILE_BUTTON,
} from 'constants/aqa/profile';
import { StyledList } from '../../Menu/styled';
import { StyledFileInput } from './styled';
import { handleFileUploading } from './helpers';

interface IUploadProp {
  setUrl: Dispatch<SetStateAction<string | File>>;
  setIsAddFromLink: Dispatch<SetStateAction<boolean>>;
}
const UploadPhoto: FC<IUploadProp> = ({ setUrl, setIsAddFromLink }) => {
  const { setToast } = useToast();

  return (
    <StyledList>
      <StyledItem>
        <StyledFileInput
          type="file"
          id="file"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileUploading(setUrl, setToast, e)}
          accept=".png, .jpg, .jpeg"
          data-testid={TEST_ID_UPLOAD_PHOTO_VIEW_CHOOSE_FILE_BUTTON}
        />
        <Button text="Choose file" Icon={ShareIcon} handler={() => {}} />
      </StyledItem>
      <StyledItem>
        <Button
          dataTestId={TEST_ID_UPLOAD_PHOTO_VIEW_ADD_FROM_LINK_BUTTON}
          text="Add from a link"
          Icon={AddFromLinkIcon}
          handler={() => setIsAddFromLink(true)}
        />
      </StyledItem>
    </StyledList>
  );
};

export default UploadPhoto;
