import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import AnimatedBackground from 'components/AnimatedBackground';
import { StyledWrap } from 'pages/AboutUs/styled';

import PrivacyPolicyText from './PrivacyPolicyText';
import TermsAndConditionsText from './TermsAndConditionsText';
import CommunityGuidelinesText from './CommunityGuidelinesText';

import { StyledHeading, StyledText } from './styled';

const docs = [
  {
    slug: 'privacy',
    title: 'Privacy policy',
    text: <PrivacyPolicyText />,
  },
  {
    slug: 'terms',
    title: 'Terms of service',
    text: <TermsAndConditionsText />,
  },
  {
    slug: 'community',
    title: 'Community guidelines',
    text: <CommunityGuidelinesText />,
  },
];

const LegalPage: FC = () => {
  const history = useHistory();
  const doc = docs.find((i) => history.location.pathname.includes(i.slug));

  if (!doc) {
    return null;
  }

  return (
    <AnimatedBackground enableInnerScroll>
      <StyledWrap disableInnerScroll>
        <StyledHeading>{doc.title}</StyledHeading>
        <StyledText>{doc.text}</StyledText>
      </StyledWrap>
    </AnimatedBackground>
  );
};

export default LegalPage;
