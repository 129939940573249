import React from 'react';
import { ROUTE_TERMS } from 'routes';

import {
  StyledHeading,
  StylesSubHeating,
  StyledContent,
  StyledLink,
  StyledList,
  StyledTextWrapper,
  LegalLink,
} from './styled';
import { StyledSubHeading } from '../Welcome/styled';

const PrivacyPolicyText: React.FC = () => (
  <StyledTextWrapper>
    Effective Date: December 7, 2023
    <StyledHeading>Introduction</StyledHeading>
    <StyledContent>
      Welcome to the website and mobile application of Guide Human (“guide:human” or “GH” or “we/us/our”). guide:human
      respects your privacy and is committed to protecting it. This Privacy Policy (the “Policy”) applies to our
      website, [guidehuman.co] (this “Website”) and our mobile application (“App”) and associated services
      (collectively, the “Service”). <br />
      At guide:human, we want to serve people with their needs of content sharing and discovery, while fixing the ills
      of present-day social media and the wider information landscape. We believe trust is something companies have to
      earn, and protecting your privacy is one of many steps we are taking to do just that.
    </StyledContent>
    <StylesSubHeating>Contact Details</StylesSubHeating>
    <StyledContent>
      guide:human will answer any questions you have about this Policy or our information practices. Please contact:
      <StyledLink href="mailto:help@guidehuman.co">help@guidehuman.co</StyledLink>.
    </StyledContent>
    <StyledHeading>How This Policy Applies</StyledHeading>
    <StyledContent>
      We at guide:human want you to understand what information we collect, and how we use and share it. That’s why we
      encourage you to read our Privacy Policy. This helps you use in the way that’s right for you <br />
      This Policy describes the information we collect from you, how we use that information and our legal basis for
      doing so. It also covers whether and how that information may be shared and your rights and choices regarding the
      information you provide to us. <br />
      Before you use or submit any information through guide:human’s Services, please review this Policy carefully.
      <br />
      In addition, please review the Service’s <LegalLink to={ROUTE_TERMS}>Terms of Service</LegalLink>, which governs
      your use of the Service. By using the Service, you acknowledge and accept our Privacy Policy and Terms of Service
      apply to our collection, use and sharing of your information and data, and other activities, as described below.
      If you do not agree to the terms of this Policy, please do not provide us with any Personal Information (defined
      above) and do not use the Service. To the extent we provide you notice on the Service of different or additional
      privacy policies or practices (e.g., at the point of our collection), those additional terms shall govern such
      data collection and use.
    </StyledContent>
    <StyledHeading>Changes to This Policy</StyledHeading>
    <StyledContent>
      We may update this Policy from time to time. If we do, we’ll let you know about any material changes, either by
      notifying you on the Service or by sending you an email. New versions of this Policy will never apply
      retroactively—we’ll tell you the exact date they go into effect. If you keep using the Service after a change,
      that means you accept this Policy.
    </StyledContent>
    <StyledHeading>What Information Is Collected</StyledHeading>
    <StyledContent>
      We only collect what we need. <br />
      guide:human collects information that is either i) necessary, ii) enhances your experience, or iii) improves
      guide:human for all.
    </StyledContent>
    <StylesSubHeating>Information You Provide To Us</StylesSubHeating>
    <StyledContent>
      When you create an account, upload content, contact us directly, or otherwise use the Platform, you may provide
      some or all of the following information.
    </StyledContent>
    <StyledList>
      <li>
        <strong>Account Data.</strong> When creating an Account you will provide us with account and profile information
        such as name, email, location, and language preferences. You may optionally provide a profile picture when
        creating an Account. “Account” means a unique account created for you to access the Service or parts of the
        Service.
      </li>
      <li>
        <strong>Content Data.</strong> We receive User Content (as defined in the{' '}
        <LegalLink to={ROUTE_TERMS}>Terms of Service</LegalLink>) that you create when you use the services. Other users
        can copy and/or share your content through this and other services. Only share content that you want to be seen,
        and potentially copied, by others.
      </li>
      <li>
        <strong>Device Data.</strong> We also enable you to access, within the Service, your Device’s camera and photos.
        “Device” means any device that can access the Website such as a computer, a cell phone or a digital tablet. You
        can create an account without allowing access to your Device’s camera and photos. If you do not allow access,
        you will not be able to share content
      </li>
      <li>Your choices and communication preferences.</li>
      <li>Information to verify a “Verified Account”. </li>
      <li>
        Information in correspondence you send to us, including when you contact us for support or exercising your
        rights.
      </li>
      <li>
        Information you share through Feedback (as defined in the{' '}
        <LegalLink to={ROUTE_TERMS}>Terms of Service</LegalLink>).
      </li>
    </StyledList>
    <StylesSubHeating>Information From Other Sources</StylesSubHeating>
    <StyledContent>
      If you register by linking your Google® account or Apple® account, we collect your first and last name and your
      email address. If you register by linking Your Apple® account, you will have the option to enable “Hide My Email.”
      Hide My Email will generate a random email address that then forwards messages to your actual email address.
    </StyledContent>
    <StylesSubHeating>Information We Collect Through Automatic Data Collection Technologies</StylesSubHeating>
    <StyledContent>
      In addition to information that you choose to submit to us via the Service, we automatically receive information
      about your device used to access the Service, including internet or other network activity information such as
      your IP address, geolocation-related data, unique device identifiers, and Cookies. Like many websites, the Service
      uses automatic data collection tools, such as cookies, web beacons, embedded scripts, and location identifying
      technology (collectively “Cookies”).{' '}
    </StyledContent>
    <StyledList>
      <li>
        <strong>Device Information.</strong> We collect information about your device used to access the Service, such
        as your IP address, UDID or other unique identifier (“Device Identifier”). A Device Identifier is a number that
        is automatically assigned to your Device used to access the Service, and our computers identify your Device by
        its Device Identifier; your Device functionality (including browser, operating system, hardware, mobile network
        information); the URL that referred you to the Service; the areas within the Service that you visit and your
        activities there, including remembering you and your preferences; your Device location; your Device
        characteristics; and certain other Device data, including the date and time of day of your visit, among other
        information.
      </li>
      <li>
        <strong>Cookies.</strong> We use Cookies to automatically collect information, measure and analyze how you use
        the Service. These tools help make your visit to the Service easier, more efficient, and personalized. We also
        use the information to improve the Service and provide greater service and value, to better understand your
        potential interests, and to provide you with more relevant content. We cannot verify your personal identity from
        this information. You can modify your browser settings to reject some or all cookies, except those that are
        strictly necessary. You should be aware that some features are only available through the use of cookies and if
        you choose to reject cookies, these features may not be available. In general, there are the following
        categories of cookies used on our Site.
      </li>
      <li>
        <strong>Metadata.</strong> When you create User Content, you automatically upload certain metadata that is
        connected to the User Content. Metadata provides information about your User Content such as how, when, where,
        and by whom the User Content was created, collected, or modified and how that content is formatted.
        Additionally, metadata includes data that you choose to provide with your User Content, such as captions.
      </li>
    </StyledList>
    <StyledContent>
      We may use Tracking Technologies, such as session logs. Some tracking is necessary to make guide:human’s Service
      function properly. These technologies also support security, analytics and an improved user experience for a
      variety of purposes.
    </StyledContent>
    <StyledSubHeading>Third-Party Use of Cookies and Other Tracking Technologies</StyledSubHeading>
    <StyledContent>
      Some content or applications on the Service are served by third-parties. These third parties may use cookies,
      alone or together with web beacons or other tracking technologies, to collect information about you when you use
      the Service. The information they collect may be associated with your Personal Information, or they may collect
      information—including Personal Information—about your online activities over time and across different websites
      and other online services. They may use this information to provide you with interest-based (behavioral)
      advertising or other targeted content.
      <br />
      We do not control those Cookies and we are not responsible for them. You acknowledge and accept that you may
      potentially encounter third-party Cookies in connection with use of the Service and accept that our statements
      under this Privacy Policy do not apply to the Cookies or practices of such third parties.
    </StyledContent>
    <StylesSubHeating>Information Third Parties Provide About You</StylesSubHeating>
    <StyledContent>
      We receive information about you from a third party such as Google and Apple when you register for an account by
      linking your Google® account or Apple® account. Additionally, we may, from time to time, supplement the
      information we collect directly from you on the Service with outside records from third parties for various
      purposes, including to enhance our ability to serve you, to tailor our content and ads to you and to offer you
      opportunities that may be of interest to you. To the extent we combine information we receive from those sources
      with your Personal Information we collect on the Service, it will be treated as Personal Information and we will
      apply this Policy to such combined information, unless we have disclosed otherwise. In no other circumstances do
      our statements under this Policy apply to information we receive about you from third parties.
    </StyledContent>
    <StylesSubHeating>Information You Provide About a Third Party</StylesSubHeating>
    <StyledContent>
      You may send someone else a communication from the Site, such as sending an invitation to a friend. If so, the
      information you provide (e.g., names, email addresses, mobile number, etc.) is used to facilitate the
      communication and is not used by us for any other marketing purpose unless we obtain consent from that person or
      we explicitly say otherwise. Please be aware that when you use any send-to-a-friend functionality on our Site,
      your email address, mobile number, name or user name and message may be included in the communication sent to your
      addressee(s).
    </StyledContent>
    <StylesSubHeating>Do Not Track Disclosures (sometimes referred to as “DNT”)</StylesSubHeating>
    <StyledContent>
      Various third parties are developing or have developed signals or other mechanisms for the expression of consumer
      choice regarding the collection of information about an individual consumer’s online activities over time and
      across third-party web sites or online services (e.g., browser do not track signals). Currently, we do not monitor
      or take any action with respect to these signals or other mechanisms.
    </StyledContent>
    <StyledHeading>How We Use Your Information</StyledHeading>
    <StyledContent>
      We use information that we collect about you or that you provide to us, including any Personal Information for the
      following purposes:
    </StyledContent>
    <StyledList>
      <li>
        To provide and maintain our content, services and products to you, such as:
        <StyledList>
          <li>To process Service account registration</li>
          <li>Deliver content you request</li>
          <li>Provide you with customer support and respond to your requests</li>
          <li>Complete your requests</li>
          <li>Communicate with you about our Service</li>
          <li>To answer your questions and response to and process inquiries</li>
        </StyledList>
      </li>
      <li>To measure and analyze audience traffic and to improve the overall experience at the Service.</li>
      <li>
        To enforce our <LegalLink to={ROUTE_TERMS}>Terms of Service</LegalLink> and other conditions and policies.
      </li>
      <li>
        To send administrative emails, administrative emails include communications such as operational changes or
        updates, new feature releases, new user updates, and content sharing notifications.
      </li>
      <li>For use in our marketing.</li>
      <li>
        To create broader findings with aggregate and deidentified data
        <StyledList>
          <li>
            Aggregate or deidentify information so that it can no longer identify you, as defined under applicable laws,
            to develop aggregated data and statistics.
          </li>
          <li>
            Better understand and represent our users using deidentified data, such as to measure ad performance, create
            advertising interest-based segments, or compile survey results.
          </li>
        </StyledList>
      </li>
      <li>
        To ensure legal compliance, such as:
        <StyledList>
          <li>Comply with legal requirements,</li>
          <li> Assist law enforcement,</li>
          <li>Resolve disputes, and</li>
          <li> Enforce our agreements.</li>
        </StyledList>
      </li>
      <li>
        To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or
        transfer of some or all of our assets, in which Personal Information held by us is among the assets transferred.
      </li>
      <li>
        For data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to
        evaluate and improve our Website, products, services, marketing, and your experience.
      </li>
      <li>
        In any other way we may describe when you provide the information. For any other purpose with your consent.
      </li>
    </StyledList>
    <StyledContent>
      When you create an account, we create a basic profile page for you on guide:human containing your username and
      profile picture. When you post and share, you are directing us to disclose that information as broadly as
      possible. guide:human content, including your profile information (e.g., name/pseudonym, username, profile
      pictures), is available for viewing by the general public. Your username appears with the comments you post and
      messages you send on guide:human. Depending on your settings, and based on the guide:human products and services
      you use, we share:
    </StyledContent>
    <StyledList>
      <li>Your interactions with guide:human content of other users, such as likes, and people you follow.</li>
      <li>Content you send to a specific guide:human user, such as through Direct Messages.</li>
    </StyledList>
    <StyledHeading>Who Is Your Information Shared With</StyledHeading>
    <StyledContent>
      guide:human will seek to share the minimum amount necessary where your data is shared with third parties.
      <br />
      We may disclose aggregated information about our users, without restriction. We may disclose Personal Information
      that we collect, or that you provide as described in this Policy:
    </StyledContent>
    <StyledList>
      <li>For the purposes for which you provided it.</li>
      <li>For any other purpose we disclose when you provide it.</li>
      <li>With your consent.</li>
      <li>
        As required by law; for example with our professional advisors, lawyers, insurers, regulators and other
        authorities, or as we think necessary to protect our organization or others from injury (e.g., in response to a
        court order or subpoena, in response to a law enforcement agency request, or when we believe that someone is
        causing, or is about to cause, injury to or interference with another person’s rights or property).
      </li>
      <li>
        We may access, use, preserve, transfer and disclose your information (including Device Identifiers and Personal
        Information) to third parties: (i) to protect and/or defend the Service’s{' '}
        <LegalLink to={ROUTE_TERMS}>Terms of Service</LegalLink> or other policies applicable to the Service, including
        investigation of potential violations thereof; (ii) to protect the safety, rights, property or security of the
        Site or any third party; and/or (iii) to detect, prevent or otherwise address fraud, security or technical
        issues. Further, we may use IP address or other Device Identifiers, to identify users, and may do so in
        cooperation with third parties such as copyright owners, internet service providers, wireless service providers
        and/or law enforcement agencies, including disclosing such information to third parties, all in our discretion,
        subject to applicable law. Such disclosures may be carried out without notice to you.
      </li>
      <li>To contractors, service providers, and other third parties we use to support our business.</li>
      <li>With our business partners in compliance with relevant data protection laws.</li>
      <li>
        To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution,
        or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy,
        liquidation, or similar proceeding, in which Personal Information held by us is among the assets transferred.
      </li>
    </StyledList>
    <StylesSubHeating>Are There Other Ways My Personal Information Can Be Shared?</StylesSubHeating>
    <StyledContent>
      You may choose to share information (including Personal Information) with people other than us, or with the
      public, through your use of the Website. For example, the Service provides links that allow you to publicly share
      information through social media. You, and you alone, control whether and what to share. When you choose to share
      any information online with anyone—including friends, public officials, or the public at large—that information is
      no longer private and may be shared more broadly than you intended. You may be harmed as a result.
      <br />
      Always be careful when choosing to share information in this way through the Website. If you decide to do so, you
      understand and agree that we are not responsible for any harm that may result from your decision to share
      information through and beyond the Website. We do not control Meta, X (formerly Twitter), or any other third
      party&apos; website, and we are not responsible for their privacy policies and practices. When you leave our
      Website, we encourage you to read and understand the privacy policy of every website you visit.
    </StyledContent>
    <StyledHeading>Your Rights</StyledHeading>
    <StyledContent>
      You may “opt-out” of any online directory or promotional communications from us by following the unsubscribe
      instructions in the communication you receive or contacting us.
      <br />
      You may “opt-in” for information being shared to unaffiliated third-parties, who are not necessary to the
      operations and functionality of the Service. You may opt-out at any time.
      <br />
      We may continue to send you communications regarding the Services, such as notices about administrative updates,
      transaction reports, and changes to the Service, this Policy or the Terms of Service.
      <br />
      guide:human users can manage their personal information in their account settings. You can request a downloadable
      copy of your personal data from guide:human from your account settings page. To modify or delete the personal
      information you’ve provided to us, please log in and update your profile. You may also delete your account from
      your account settings page. We may retain certain information as required by law or as necessary for our
      legitimate business purposes.
    </StyledContent>
    <StyledHeading>Retention</StyledHeading>
    <StyledContent>
      We will retain your information as long as your account is active, as necessary to provide you with the Service.
      We will also retain and use this information as necessary for the purposes set out in this Policy and to the
      extent necessary to comply with our legal obligations, resolve disputes, enforce our agreements, and protect
      guide:human’s legal rights.
      <br />
      We also collect and maintain aggregated, anonymized, or pseudonymized information which we may retain indefinitely
      to protect the safety and security of the Service, improve our services or comply with legal obligations.
    </StyledContent>
    <StyledHeading>Data Minimization</StyledHeading>
    <StyledContent>
      We will take reasonable and appropriate steps to limit the Personal Information we collect, use, and disclose to
      the type and amount necessary, adequate, and relevant for the purposes for which we process it.
    </StyledContent>
    <StyledHeading>Social Media</StyledHeading>
    <StyledContent>
      The Service includes social media features, such as the Meta, X (formerly Twitter), and Snapchat buttons that run
      on the Service. These features may collect your IP address or log which page you are visiting on the Service,
      and/or may set a cookie to enable the feature to function properly. Social media features and widgets are either
      hosted by a third party or hosted directly on the Service. Your interactions with these features are governed by
      the privacy policy of the organization providing them.{' '}
    </StyledContent>
    <StyledHeading>Children’s Privacy</StyledHeading>
    <StyledContent>
      The Service is not intended to be used by anyone under the age of 13. We do not knowingly collect Personal
      Information from children under 13. In the case we discover that a child under 13 has provided us with Personal
      Information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that
      your child has provided us with Personal Information, please contact us so that we will be able to do necessary
      actions.
    </StyledContent>
    <StyledHeading>Links To Other Websites</StyledHeading>
    <StyledContent>
      The Service and this Policy may contain links to third party websites. We have no control over these websites and
      they are not subject to this Policy. Please check the privacy policies of these websites to determine how your
      Personal Information will be collected, stored, used, and disclosed.
    </StyledContent>
    <StyledHeading>International Data Transfers</StyledHeading>
    <StyledContent>
      Due to the global nature of the Internet, through the use of our network you hereby agree to comply with all local
      rules relating to online conduct and that which is considered acceptable Content. Uploading, posting and/or
      transferring of software, technology and other technical data may be subject to the export and import laws of the
      United States and possibly other countries. Through the use of our network, you thus agree to comply with all
      applicable export and import laws, statutes and regulations. <br />
      Because guide:human is a US-based company, your information will be collected and processed in the United States.
      The United States has its own laws governing data protection and government access to information. The rules that
      protect your personal information under United States law may be different than in your home country. By providing
      us with your information, you acknowledge any such transfer or use. If you choose to use the Services, you need to
      agree to our Terms of Service, which set out the contract between guide:human and its users.
    </StyledContent>
    <StyledHeading>Security of Your Personal Information</StyledHeading>
    <StyledContent>
      We have implemented measures to help protect your Personal Information from accidental loss and from unauthorized
      access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind
      firewalls.
      <br />
      Unfortunately, no method of transmitting information over the internet or storing it electronically is 100%
      secure. Neither we nor anyone else can—and we do not—guarantee the confidentiality, security, integrity, or
      availability of any Personal Information transmitted to or from us over the Internet and/or stored electronically.
      In all situations, you should carefully consider whether and how to provide Personal Information to someone else
      (including us). All transmission of Personal Information is at your own risk. We are not responsible for
      circumvention of any privacy settings or security measures contained on the Website.
      <br />
      Access to Personal Information at guide:human is limited to workforce members who need it to do their job.
    </StyledContent>
    <StyledHeading>Unauthorized Uses and Disclosures of Personal Information, and Security Incidents</StyledHeading>
    <StyledContent>
      If we discover an unauthorized use or disclosure of Personal Information or a security incident, we will promptly
      assess our responsibilities under applicable law and take all required actions.{' '}
    </StyledContent>
    <StyledHeading>Disputes</StyledHeading>
    <StyledContent>
      This Privacy Policy shall be construed and governed under the laws of the United States and the Massachusetts
      (without regard to rules governing conflicts of laws provisions). You agree that venue for all actions, arising
      out of or relating in any way to your use of our Services, shall be in the federal court in Boston, Massachusetts
      or state court of competent jurisdiction located in Suffolk County, within one (1) year after the claim arises.
      Each party waives any objections based on forum non conveniens and waives any objection to venue of any action
      instituted hereunder to the extent that an action is brought in the courts identified above. Each party consents
      to personal jurisdiction in the courts identified above.
      <br />
      All such disputes are subject to binding arbitration by a United States Arbitration & Mediation (“USA&M”) neutral
      according to the USA&M Rules of Arbitration. The arbitrator’s decision shall be final and binding, and judgment
      may be entered thereon. If a party fails to proceed with arbitration, unsuccessfully challenges the arbitrator’s
      award, or fails to comply with arbitrator’s award, the other party is entitled of costs of suit including a
      reasonable attorney’s fee for having to compel arbitration or defend or enforce the award.
    </StyledContent>
    <StyledHeading>Contact Us</StyledHeading>
    <StyledContent>
      If you would like to receive further information about this Policy or any of our information practices, please
      contact <StyledLink href="mailto:help@guidehuman.co">help@guidehuman.co</StyledLink>
    </StyledContent>
    <StyledContent>
      Copyright © Guide Human Inc. All rights reserved. The Service is the property of Guide Human, Inc. and is
      protected by United States and international copyright, trademark, and other applicable laws. This includes the
      content, appearance, and design of the Service, as well as the trademarks, product names, graphics, logos, service
      names, slogans, colors, and designs.
    </StyledContent>
    <StyledContent>
      <StyledLink href="https://guidehuman.co/2023.12.7 Privacy Policy.pdf" target="_blank" download>
        Download file
      </StyledLink>
    </StyledContent>
  </StyledTextWrapper>
);

export default PrivacyPolicyText;
