import { styled } from 'linaria/react';
import Button from 'components/UI/Button/styled';
import { FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  margin-top: 40px;
`;

export const StyledItem = styled.div`
  padding-bottom: 64px;

  &:last-child {
    padding-bottom: 16px;
  }
`;

export const StyledAddBtn = styled(Button)<{ isDark: boolean }>`
  border-radius: 22px;
  font: 600 14px/140% ${FONTS.secondary};
  margin: 0 24px 40px;
  width: -webkit-fill-available;
`;
