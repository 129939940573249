import { styled } from 'linaria/react';

import { COLORS, FONTS } from '../../../styles/constants';

export const StyledList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
`;

export const StyledListItem = styled.li<{ isBig?: boolean; isEmpty?: boolean }>`
  align-items: center;
  background-color: ${({ isEmpty }) => (isEmpty ? COLORS.white.off[100] : COLORS.transparent)};
  border-radius: 50%;
  color: ${COLORS.brown.dark[100]};
  display: ${({ isEmpty }) => (isEmpty ? 'flex' : 'block')};
  font: 500 12px/140% ${FONTS.secondary};
  justify-content: center;
  height: ${({ isBig }) => (isBig ? 44 : 20)}px;
  margin-left: ${({ isBig }) => (isBig ? -20 : -8)}px;
  width: ${({ isBig }) => (isBig ? 44 : 20)}px;

  &:last-of-type {
    z-index: 1;
  }
`;
