import { GetExploreEveryoneQuery, GetExploreFollowingsQuery } from 'constants/graphqlTypes';

export const updateFollowings = (
  prev: GetExploreFollowingsQuery,
  fetchMoreResult?: GetExploreFollowingsQuery
): GetExploreFollowingsQuery => ({
  ...prev,
  discoverFollowingsItems: {
    __typename: 'DiscoverFollowingListObjectType',
    ...prev.discoverFollowingsItems,
    totalCount: prev.discoverFollowingsItems?.totalCount ?? 0,
    items: [...(prev.discoverFollowingsItems?.items ?? []), ...(fetchMoreResult?.discoverFollowingsItems?.items ?? [])],
  },
});

export const updateEveryone = (
  prev: GetExploreEveryoneQuery,
  fetchMoreResult?: GetExploreEveryoneQuery
): GetExploreEveryoneQuery => ({
  ...prev,
  discoverEveryoneItems: {
    __typename: 'DiscoverItemListObjectType',
    totalCount: prev.discoverEveryoneItems?.totalCount ?? 0,
    items: [...(prev.discoverEveryoneItems?.items ?? []), ...(fetchMoreResult?.discoverEveryoneItems?.items ?? [])],
  },
});
