import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_WELCOME } from 'routes';

import { useReports } from 'graphQL/cardOptions/hooks';
import { IReportOption } from 'graphQL/cardOptions/models';

import ExitFormCommentPage from './ExitFormCommentPage';
import ExitFormContentPage from './ExitFormContentPage';

const ExitForm: FC = () => {
  const [isCommentPage, setIsCommentPage] = useState(false);
  const [inputText, setInputText] = useState('');
  const [reportOptions, setReportOptions] = useState<(IReportOption & { isSelected?: boolean })[]>([]);
  const { replace } = useHistory();

  const { options, report } = useReports({ isLeaveReport: true });

  useEffect(() => {
    setReportOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length]);

  const checkedOptions = reportOptions.reduce(
    (acc: string[], option) => (option.isSelected ? [...acc, option.id] : acc),
    []
  );

  const handleSelect = (optionId: string) =>
    setReportOptions((prev) =>
      prev.map((option) => (option.id === optionId ? { ...option, isSelected: !option.isSelected } : option))
    );
  const addComment = (text: string) => {
    setInputText(text);
    setIsCommentPage(false);
  };

  return isCommentPage ? (
    <ExitFormCommentPage
      existedText={inputText}
      handleCancel={() => setIsCommentPage(false)}
      handleAddComment={addComment}
    />
  ) : (
    <ExitFormContentPage
      options={reportOptions}
      inputText={inputText}
      handleSubmit={() =>
        !inputText && !checkedOptions.length ? replace(ROUTE_WELCOME) : report('', checkedOptions, inputText)
      }
      handleSkip={() => replace(ROUTE_WELCOME)}
      handleSelect={handleSelect}
      openCommentPage={() => setIsCommentPage(true)}
    />
  );
};

export default ExitForm;
