import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { ROUTE_SEARCH } from 'routes';
import { TEST_HOME_SEARCH_BTN } from 'constants/aqa/main';
import withHapticFeedback from 'helpers/withHapticFeedback';
import { StyledButton } from '../styled';

const SearchButton: FC = () => {
  const { push } = useHistory();

  return (
    <StyledButton
      data-testid={TEST_HOME_SEARCH_BTN}
      className="icon-navigation"
      onClick={withHapticFeedback(() => push(ROUTE_SEARCH))}
    >
      <SearchIcon width="32" height="32" viewBox="0 0 24 24" />
    </StyledButton>
  );
};

export default SearchButton;
