import React, { FC, useContext } from 'react';

import { EFeaturedFlags } from 'constants/posthogEvents';
import { useFeaturedFlagEnabled } from 'helpers/posthogHooks';
import { ChannelsTab, EveryoneTab, ExploreTab, FollowingTab } from 'components/Explore';
import { EExploreTabs } from 'components/Explore/ExploreTabs/helpers/constants';
import ExploreTabs from 'components/Explore/ExploreTabs';
import useDiscoveryTab from 'helpers/useDiscoveryTab';
import StyledWrap from 'components/UI/Wrap';
import { LAST_STEP } from 'components/GuideTour/constants';

import useTheme from 'helpers/useTheme';
import useDocumentTitle from 'helpers/useDocumentTitle';

import { IFollowingKey } from 'providers/helpers/models';
import { FollowingTabKeyContext } from 'providers/helpers/contexts';

const ExplorePage: FC = () => {
  const { followingTabKey } = useContext<IFollowingKey>(FollowingTabKeyContext);
  const isDark = useTheme();
  const { discoveryTab } = useDiscoveryTab();

  useDocumentTitle();

  const isShowChannelsTab = useFeaturedFlagEnabled(EFeaturedFlags.ShowChannelsTab);
  const isShowEveryOneTab = useFeaturedFlagEnabled(EFeaturedFlags.ShowEveryoneTab);
  return (
    <StyledWrap data-tour={LAST_STEP} paddingTop={51}>
      <ExploreTabs isDark={isDark} />
      {discoveryTab === EExploreTabs.Everyone && isShowEveryOneTab && <EveryoneTab />}
      {discoveryTab === EExploreTabs.Following && <FollowingTab key={followingTabKey} />}
      {discoveryTab === EExploreTabs.Featured && <ExploreTab />}
      {discoveryTab === EExploreTabs.Channels && isShowChannelsTab && <ChannelsTab />}
    </StyledWrap>
  );
};

export default ExplorePage;
