import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  p {
    font-family: ${FONTS.secondary};
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }
`;

export const StyledButton = styled.button`
  background-color: ${COLORS.transparent};
  cursor: pointer;
  border: none;
  padding: 10px 0 10px 20px;
  width: max-content;
`;

export const StyledUserInfo = styled.p`
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledUsernameWrap = styled.span<{ isDark?: boolean }>`
  align-items: center;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  font: 600 14px/140% ${FONTS.secondary};
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
`;

export const StyledText = styled.span`
  flex-shrink: 0;
  text-decoration: underline;
`;
