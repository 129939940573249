import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';

import Modal from 'components/Modal';
import { StyledTitle } from 'components/AddContent/Menu/styled';
import Button from 'components/UI/Button';
import { ROUTE_TERMS, ROUTE_REPORT } from 'routes';
import { StyledDescription, StyledWrap, StyledLink } from './styled';

interface ISubmitReportProps {
  title?: string;
  handleSubmit: () => void;
  handleClose: () => void;
  handlePrev: () => void;
}

const SubmitReport: FC<ISubmitReportProps> = ({ title, handleSubmit, handleClose, handlePrev }) => {
  const { state } = useLocation<{
    backgroundModal: Location<undefined>;
    variable: { [key: string]: string | undefined };
  }>();

  const { push } = useHistory();

  const goToTerms = () => {
    // adding prevReportModalState variable to local state representing the report modal which should be open after going back from the terms & conditions page
    const prevReportModalState = {
      pathname: ROUTE_REPORT,
      state,
    };
    return push({
      pathname: ROUTE_TERMS,
      state: {
        variable: {
          prevReportModalState,
        },
      },
    });
  };

  return (
    <Modal handlePrev={handlePrev} handleClose={handleClose}>
      <StyledWrap>
        <div>
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription>
            Submitting a report sends recent messages to guide:human that violate the{' '}
            <StyledLink onClick={goToTerms}>terms of service</StyledLink>.
          </StyledDescription>
          <StyledDescription>We won’t notify the members of this thread that you submitted a report.</StyledDescription>
          <StyledDescription>
            If someone is in immeditate danger please contact your local authorities immediately.
          </StyledDescription>
        </div>
        <Button handler={handleSubmit} text="Submit report" />
      </StyledWrap>
    </Modal>
  );
};

export default SubmitReport;
