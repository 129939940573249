import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { XBlock, XMasonry } from 'react-xmasonry';

import { ChannelListSchema, ChannelSectionItemsSortTypeOptions } from 'constants/graphqlTypes';
import { ReactComponent as FilterIcon } from 'assets/icons/Filter.svg';

import useTheme from 'helpers/useTheme';
import LoaderContent from 'components/UI/LoaderContent';
import TileSmall from 'components/List/TileSmall';
import SortFeedModal from 'components/CustomFeeds/SortFeedModal';
import { EChannelsListsUrlType } from 'components/Explore/ChannelsTab/ListSection/constants';
import { FEEDS_SORTING } from 'constants/common';

import { useGetChannelSectionItems } from 'graphQL/channel/hooks';
import { BREAKPOINTS } from 'styles/constants';
import { StyledFilterBtn } from 'pages/FeedsSection/styled';

import { StyledListItem, StyledTitle, StyledWrap, StyledHeaderWrap } from './styled';

const chooseCardWidth = () => (window.innerWidth >= BREAKPOINTS.xxl ? BREAKPOINTS.retinaScale * 300 : 220);

const ListsChannels: FC = () => {
  const { id: listSectionId, type } = useParams<{ id: string; type: string }>();
  const [cardWidth, setCardWidth] = useState<number>(chooseCardWidth());
  const [key, setKey] = useState(0);
  const [isSortFeedOpen, setIsSortFeedOpen] = useState<boolean>(false);
  const [sorting, setSorting] = useState<ChannelSectionItemsSortTypeOptions | undefined>();
  const isDark = useTheme();

  const data = useGetChannelSectionItems(listSectionId ?? '', sorting);
  const { items: dataItems, loading, loadMore } = data ?? {};

  const items = dataItems?.reduce(
    (prev: ChannelListSchema[], curr) => (curr?.__typename === 'ChannelListSchema' ? [...prev, curr] : prev),
    []
  );

  const handleWindowResize = () => setCardWidth(chooseCardWidth());

  const isTrendingLists = type === EChannelsListsUrlType.TrendingLists;

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Increment the key by 1 each time items changes to force XMasonry to re-render
    setKey((prevKey) => prevKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(items)]);

  useEffect(() => {
    const sortingParam = localStorage.getItem(FEEDS_SORTING);
    const initialSorting = Object.values(ChannelSectionItemsSortTypeOptions).includes(
      sortingParam as ChannelSectionItemsSortTypeOptions
    )
      ? (sortingParam as ChannelSectionItemsSortTypeOptions)
      : ChannelSectionItemsSortTypeOptions.RecentlyUpdated;

    setSorting(initialSorting);

    return () => {
      localStorage.setItem(FEEDS_SORTING, '');
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(FEEDS_SORTING, sorting ?? '');
  }, [sorting]);

  return (
    <StyledWrap>
      <StyledHeaderWrap>
        <StyledTitle>{isTrendingLists ? 'Trending lists' : 'Lists you follow'}</StyledTitle>
        {!isTrendingLists && (
          <StyledFilterBtn isDark={isDark} onClick={() => setIsSortFeedOpen(true)}>
            <FilterIcon />
          </StyledFilterBtn>
        )}
      </StyledHeaderWrap>

      {items?.length ? (
        <>
          <XMasonry
            key={key}
            targetBlockWidth={cardWidth}
            maxColumns={2}
            smartUpdate
            smartUpdateCeil={50}
            center={false}
          >
            {items?.map(
              ({ item, counterUnreadPicks, enabledUserCovers }) =>
                item && (
                  <XBlock key={item.id}>
                    <StyledListItem>
                      <TileSmall
                        item={item}
                        fullWidth
                        counterUnreadPicks={counterUnreadPicks}
                        enabledUserCovers={enabledUserCovers}
                      />
                    </StyledListItem>
                  </XBlock>
                )
            )}
          </XMasonry>
          {!loading && <Waypoint onEnter={loadMore} />}
        </>
      ) : (
        <>{loading && <LoaderContent isFullScreen isDark={false} />}</>
      )}
      {isSortFeedOpen && (
        <SortFeedModal
          hideCustom
          selectedSorting={sorting}
          setSorting={setSorting}
          handleClose={() => setIsSortFeedOpen(false)}
        />
      )}
    </StyledWrap>
  );
};

export default ListsChannels;
