import { ApolloCache } from '@apollo/client';
import {
  CardStyleSchema,
  GetListItemsIdQuery,
  GetListItemsQuery,
  UpdateFeedViewMutation,
} from 'constants/graphqlTypes';

export const updateListItems = (
  prev: GetListItemsQuery | GetListItemsIdQuery,
  next?: GetListItemsQuery | GetListItemsIdQuery
): GetListItemsQuery | GetListItemsIdQuery => {
  const prevList = prev.allCards?.items[0];
  const prevItems = prevList?.cardsFromCollection;
  const newItems = next?.allCards?.items[0].cardsFromCollection;

  if (!prevItems || !newItems || !prevList) {
    return prev;
  }

  return {
    ...prev,
    allCards: {
      ...prev.allCards,
      items: [
        {
          ...prevList,
          cardsFromCollection: {
            ...prevItems,
            items: [...prevItems.items, ...newItems.items],
          },
        },
      ],
      totalCount: 1,
    },
  };
};

export const updateListItemView = (cache: ApolloCache<unknown>, data?: UpdateFeedViewMutation | null) => {
  const { id, cardStyle } = data?.updateItemViewOptions ?? {};
  if (id) {
    cache.modify({
      id: cache.identify({ __typename: 'CardSchema', id }),
      fields: { cardStyle: (prev: CardStyleSchema) => ({ ...prev, viewType: cardStyle?.viewType }) },
    });
  }
};
