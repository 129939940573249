import { useContext, useEffect } from 'react';

import { IsModalOpenContext } from 'providers/helpers/contexts';
import { IIsModalOpen } from 'providers/helpers/models';

import useFixedViewport from './useFixedViewport';

const useModal = (ignore?: boolean): void => {
  const { setIsOpen } = useContext<IIsModalOpen>(IsModalOpenContext);

  useFixedViewport(ignore);

  useEffect(() => {
    if (!ignore) {
      setIsOpen?.(true);
    }

    return () => {
      if (!ignore) {
        setIsOpen?.(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useModal;
