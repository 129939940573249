import React, { FC } from 'react';

import { COLORS } from 'styles/constants';
import extentionImg from 'assets/images/savedPicks/extention.png';

import { StyledContent, StyledContentTitle } from './styled';

const SecondSlide: FC = () => (
  <>
    <StyledContentTitle color={COLORS.brown.dark[100]} isDark={false}>
      Download our browser extension
    </StyledContentTitle>
    <StyledContent>
      <img src={extentionImg} alt="extention" />
    </StyledContent>
  </>
);

export default SecondSlide;
