import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_ONBOARDING_TOPICS } from 'routes';
import { handleFileUploading } from 'components/ProfileSettings/EditProfile/ChangePhoto/helpers';
import { useProfile } from 'graphQL/profile/hooks';
import { useUploadImage } from 'components/ProfileSettings/EditProfile/ChangePhoto/hooks';
import { EOnboardingStepOptions } from 'constants/graphqlTypes';
import { TEST_ID_USER_INFO_VIEW_START } from 'constants/aqa/signup';
import { useUpdateOnboardingStep, useUpdateCachedOnboardingStep } from 'graphQL/onboarding/hooks';
import CropImage from 'components/ProfileSettings/EditProfile/ChangePhoto/CropImage';
import getImages from 'helpers/getImages';
import Layout from 'components/Onboarding/Layout';
import Modal from 'components/Modal';
import useToast from 'helpers/useToast';
import Loader from 'components/UI/Loader';
import Avatars from './Avatars/Avatars';

import { StyledContainer } from './Avatars/styled';

const AddImage: FC = () => {
  const { push } = useHistory();
  const [isModalOpened, setIsOpened] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const handleNext = () => {
    setIsOpened(false);
    setIsUploaded(true);
  };

  const updateCache = useUpdateCachedOnboardingStep(EOnboardingStepOptions.Topics);

  const navigateToNextStep = () => {
    updateCache();
    push(ROUTE_ONBOARDING_TOPICS);
  };

  const { url, loading, setUrl, handleDone, setCropper } = useUploadImage(handleNext);
  const { setToast } = useToast();
  const { userData, loading: profileLoading } = useProfile();
  const name = userData?.userInfo?.firstName || userData?.username || '';
  const { imageUrl: uploadedImageUrl } = getImages(userData?.userStyle);

  useEffect(() => {
    if (uploadedImageUrl) {
      setIsUploaded(true);
    }
  }, [uploadedImageUrl]);

  const heading = uploadedImageUrl
    ? `Nice! It’s great to meet you, ${name}.`
    : 'Add a picture so others know who you are';

  useUpdateOnboardingStep(EOnboardingStepOptions.UploadImage);

  return (
    <>
      <Layout
        heading={profileLoading ? undefined : heading}
        isImgUploaded={!!uploadedImageUrl}
        primaryBtnText={uploadedImageUrl ? 'Continue' : 'Upload image'}
        secondaryBtnText={uploadedImageUrl ? 'Edit profile picture' : 'Skip'}
        hideButtons={profileLoading}
        handlePrimaryBtn={navigateToNextStep}
        handleSecondaryBtn={navigateToNextStep}
        handleUpload={(e) => {
          setIsOpened(true);
          handleFileUploading(setUrl, setToast, e);
        }}
        isSecondaryBtnTransparent
        pageTestId={TEST_ID_USER_INFO_VIEW_START}
      >
        {profileLoading ? (
          <StyledContainer>
            <Loader size={18} isDark={false} />
          </StyledContainer>
        ) : (
          userData && <Avatars user={userData} isUploaded={isUploaded} />
        )}
      </Layout>
      {isModalOpened && (
        <Modal
          handleClose={() => setIsOpened(false)}
          buttonText="Save"
          loading={loading}
          handleDone={url ? handleDone : undefined}
        >
          <CropImage url={url} setCropper={setCropper} />
        </Modal>
      )}
    </>
  );
};

export default AddImage;
