import React, { FC } from 'react';

import usePageType from 'helpers/usePageType';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';

import { UserObjectType } from 'constants/graphqlTypes';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';

import { StyledUsername, StyledBio } from './styled';

const UserDescription: FC<{ user: UserObjectType }> = ({ user }) => {
  const { isAllPicks } = usePageType();
  const { userInfo, isVerified } = user;
  const { bio } = userInfo ?? {};

  return (
    <>
      {isAllPicks ? (
        <>
          <StyledUsername>
            <b>{generateFullName(user)}</b>
            {isVerified && <VerificationBadge />}
          </StyledUsername>
          {bio && <>{bio}</>}
        </>
      ) : (
        <>
          <StyledUsername>
            <b>{generateFullName(user)}</b>
            {isVerified && <VerificationBadge />}
            {bio && (
              <StyledBio>
                <>&nbsp;·&nbsp;</>
                {bio}
              </StyledBio>
            )}
          </StyledUsername>
        </>
      )}
    </>
  );
};

export default UserDescription;
