import React, { FC } from 'react';

import Layout from 'pages/Layout';

const withLayout = (Component: FC) => (): JSX.Element => (
  <Layout>
    <Component />
  </Layout>
);

export default withLayout;
