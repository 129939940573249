import React, { FC, useMemo } from 'react';

import { v4 } from 'uuid';

import { CardSchema } from 'constants/graphqlTypes';
import Grain from 'components/UI/Grain';
import Description from './Description';
import Footer from './Footer';
import { StyledSquareCard, StyledTextCard, StyledTextItem, StyledTextWrapper, StyledTextsList } from './styled';

interface IContentCardProps {
  item: CardSchema;
  titles: string[];
  bgColor: string;
}

const TextCard: FC<IContentCardProps> = ({ item, titles, bgColor }) => {
  const titlesWithKey = useMemo(
    () =>
      titles.map((cover) => ({
        cover,
        key: v4(),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [titles.length, titles.join(','), v4]
  );

  return (
    <StyledTextCard bgColor={bgColor}>
      <Description item={item} />
      <StyledTextWrapper>
        <StyledSquareCard bgColor={bgColor}>
          <Grain />
          <StyledTextsList>
            {titlesWithKey.map(({ cover, key }, i) => (
              <StyledTextItem key={key} opacity={1 - (1 / titlesWithKey.length) * i}>
                {cover}
              </StyledTextItem>
            ))}
          </StyledTextsList>
        </StyledSquareCard>
      </StyledTextWrapper>
      <Footer item={item} />
    </StyledTextCard>
  );
};

export default TextCard;
