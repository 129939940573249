import { styled } from 'linaria/react';

import ImportedButton from '../UI/Button/styled';
import { COLORS, FONTS } from '../../styles/constants';

export const StyledWrap = styled.div<{
  isAboveTabBar: boolean;
  toastError?: boolean;
  isClickable?: boolean;
  customBottomCoord?: number;
}>`
  align-items: center;
  animation: scrollUp 1300ms ease-in-out 0ms, scrollDown 3300ms ease-in-out 1300ms;
  backdrop-filter: blur(10px);
  background-color: ${({ toastError }) => (toastError ? COLORS.red[100] : COLORS.white.off[50])};
  border-radius: 20px;
  bottom: ${({ isAboveTabBar, customBottomCoord }) => {
    if (customBottomCoord) {
      return `${customBottomCoord}px`;
    }
    return isAboveTabBar ? '34px' : 'var(--show-bottom-menu)';
  }};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};
  display: flex;
  height: 76px;
  left: 50%;
  width: 342px;
  padding: 16px;
  position: fixed;
  transform: translateX(-50%) translateY(200px);
  z-index: 2300;

  @keyframes scrollUp {
    0%,
    77% {
      transform: translateX(-50%) translateY(200px);
    }

    100% {
      transform: translateX(-50%) translateY(0px);
    }
  }

  @keyframes scrollDown {
    0%,
    91% {
      transform: translateX(-50%) translateY(0px);
    }

    100% {
      transform: translateX(-50%) translateY(200px);
    }
  }
`;

export const StyledImage = styled.img`
  border-radius: 8px;
  height: 44px;
  width: 44px;
  object-fit: cover;
`;

export const StyledAddedTo = styled.span`
  color: ${COLORS.brown.dark[100]};
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
  padding: 0 4px 0 12px;
  white-space: nowrap;
`;

export const StyledItemName = styled.span<{
  isFontNormal: boolean;
  isTextCenter: boolean;
  isNotTruncated?: boolean;
  hasAltEnding?: boolean;
  customFontWeight?: number;
}>`
  color: ${COLORS.brown.dark[100]};
  font-family: ${FONTS.secondary};
  font-size: 14px;
  font-weight: ${({ customFontWeight }) => customFontWeight ?? 600};
  line-height: 140%;
  overflow: ${({ isNotTruncated }) => (isNotTruncated ? 'visible' : 'hidden')};
  padding-right: ${({ isFontNormal }) => (isFontNormal ? 5 : 12)}px;
  text-align: ${({ isTextCenter }) => (isTextCenter ? 'center' : 'inherit')};
  text-overflow: ellipsis;
  white-space: ${({ isNotTruncated }) => (isNotTruncated ? 'normal' : 'nowrap')};
  width: 100%;
  display: ${({ hasAltEnding }) => (hasAltEnding ? 'flex' : 'block')};
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0 4px;
`;

export const StyledItemEnding = styled.span`
  font-weight: 400;
`;

export const StyledItemAltEnding = styled.span`
  font-weight: 600;
  white-space: break-spaces;
`;

export const StyledEmoji = styled.div`
  background-color: ${COLORS.white[100]};
  border-radius: 44px;
  font-size: 22px;
  height: 44px;
  line-height: 28px;
  margin-right: 16px;
  min-width: 44px;
  padding: 8px;
  text-align: center;
  scrollbar-width: none;
`;

export const StyledItemTruncated = styled.span<{ hasAltEnding: boolean }>`
  max-width: ${({ hasAltEnding }) => (hasAltEnding ? '165px' : 'none')};
`;

export const StyledButton = styled(ImportedButton)`
  flex-shrink: 0;
`;
