import React, { cloneElement, FC, useState } from 'react';

import ConfirmationModal from 'components/UI/ConfirmationModal';
import { useBlockChat } from 'graphQL/messages/hooks';
import { ChatUnionSchema } from 'constants/graphqlTypes';

interface IBlockChatButtonProps {
  handler?: () => void;
  chat: ChatUnionSchema;
  children: JSX.Element;
}

const BlockChatButton: FC<IBlockChatButtonProps> = ({ chat, handler, children }) => {
  const { id, isBlockedByMe } = chat;
  const [isOpen, setIsOpen] = useState(false);
  const handleBlock = useBlockChat(id, !!isBlockedByMe);

  const handleBlockGroup = () => {
    handleBlock();
    setIsOpen(false);
    handler?.();
  };

  return children ? (
    <>
      {cloneElement(children, { onClick: () => setIsOpen(true), handler: () => setIsOpen(true) })}
      {isOpen && (
        <ConfirmationModal
          buttonText={isBlockedByMe ? 'Unblock' : 'Block'}
          handleAccept={handleBlockGroup}
          handleCancel={() => setIsOpen(false)}
          subTitle={
            isBlockedByMe
              ? "This thread will be reactivated and you will be able to send and receive messages. The group won't be notified that you unblocked them."
              : "You will no longer receive messages or be able to send messages to this group. They won't be notified that you blocked them."
          }
          titleText={isBlockedByMe ? 'Unblock group?' : 'Block group?'}
        />
      )}
    </>
  ) : null;
};

export default BlockChatButton;
