import React, { FC } from 'react';

import { ROUTE_PROFILE_SETTINGS } from 'routes';

import useOpenModal from 'helpers/useOpenModal';
import { TEST_ID_PROFILE_VIEW_OPTION_BUTTON } from 'constants/aqa/profile';
import DotMenuButton from './DotMenuButton';

const MyProfileButton: FC = () => (
  <DotMenuButton dataTestId={TEST_ID_PROFILE_VIEW_OPTION_BUTTON} handler={useOpenModal(ROUTE_PROFILE_SETTINGS)} />
);

export default MyProfileButton;
