import { StyledText as StyledTextImported } from 'components/Messages/List/styled';
import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

export const StyledInfo = styled.div`
  overflow: hidden;
  padding: 2px 8px;
  cursor: pointer;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  > button {
    flex-shrink: 0;
    margin-left: auto;
  }
`;

export const StyledText = styled(StyledTextImported)`
  color: ${COLORS.white[100]};
  font-weight: ${({ isBold }) => (isBold ? '600' : '400')};
`;

export const StyledButtonWrap = styled.div`
  flex-shrink: 0;
`;

export const StyledBadgeWrap = styled.div`
  cursor: pointer;
  min-width: 0;
`;
