import { UserShortSchema } from 'constants/graphqlTypes';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import {
  DOUBLE_HTML_TAG_EXPRESSION,
  HTML_TAG_CONTENT_EXPRESSION,
  SINGLE_HTML_TAG_EXPRESSION,
} from 'components/Messages/Message/constants';

const MENTION_SYMBOL = '@';

export const hasMentions = (inputText: string): boolean =>
  inputText[0] === MENTION_SYMBOL ||
  inputText.includes(` ${MENTION_SYMBOL}`) ||
  inputText.replaceAll('&nbsp;', ' ').includes(` ${MENTION_SYMBOL}`);

export const getMentionSymbolIndex = (inputText: string): number => inputText.lastIndexOf(MENTION_SYMBOL);

export const getMentionText = (inputText: string): string => inputText.slice(getMentionSymbolIndex(inputText) + 1);

export const generateTextWithMention = (
  mentionedUser: UserShortSchema,
  inputText: string,
  mentionText: string
): string => {
  const mentionSymbolIndex = getMentionSymbolIndex(inputText);
  const transformedText = `${inputText.substring(0, mentionSymbolIndex)}<span>${generateFullName(
    mentionedUser
  )}</span>&nbsp;${inputText.substring(mentionSymbolIndex + mentionText.length, inputText.length - 1)}`;
  return transformedText;
};

export const formatMentionsBeforeSending = (text: string, mentions: UserShortSchema[]): string => {
  if (!mentions.length) {
    return text;
  }

  return mentions.reduce((acc, mention) => {
    return acc.replace(`<span>${generateFullName(mention)}</span>`, `[user_id:${mention.id}]`);
  }, text);
};

export const formatMessageBeforeSending = (text: string, mentions: UserShortSchema[]): string => {
  const message = formatMentionsBeforeSending(text, mentions);

  return message
    .replaceAll(DOUBLE_HTML_TAG_EXPRESSION, (match) => {
      const tagContent = match.match(HTML_TAG_CONTENT_EXPRESSION);
      return tagContent ? tagContent[0].slice(1, -1) : '';
    })
    .replaceAll(SINGLE_HTML_TAG_EXPRESSION, '')
    .replaceAll('&nbsp;', ' ')
    .replaceAll('&amp;', '&');
};
