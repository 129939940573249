import React, { FC, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import SliderInteractive from 'components/SliderInteractive';
import Card from 'components/GuestList/Card';

import { CardSchema } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';

const GuestSlider: FC<{ items: CardSchema[]; isDark: boolean }> = ({ items, isDark }) => {
  const fontColor = isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  const [slideIndex, setSlideInd] = useState(0);

  return items.length === 1 ? (
    <Card card={items[0]} />
  ) : (
    <SliderInteractive
      onSlideChange={(ind) => setSlideInd(ind)}
      showBtnsSm={!!items.length}
      spaceBetween={13}
      fontColor={fontColor}
      isInfinite={false}
      customSlidesPerView={1.2}
      offset={12}
      isPrevDisabled={slideIndex === 0}
      isNextDisabled={slideIndex === items.length - 1}
    >
      {items.map((card) => (
        <SwiperSlide key={card.id}>
          <Card card={card} />
        </SwiperSlide>
      ))}
    </SliderInteractive>
  );
};

export default GuestSlider;
