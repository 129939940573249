import { styled } from 'linaria/react';
import { FONTS } from 'styles/constants';
import Wrap from 'components/UI/Wrap/styled';

export const StyledWrap = styled(Wrap)`
  padding-bottom: 104px;
`;

export const StyledSubtitle = styled.h2`
  font: 400 22px/140% ${FONTS.primary};
  margin: 0;
  padding: 0 24px 16px;
`;

export const StyledSectionWrap = styled.div`
  padding: 0 12px;
`;
