import { styled } from 'linaria/react';

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 32px;
  list-style: none;

  li {
    position: relative;
  }
`;

export default StyledList;
