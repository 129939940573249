import { Dispatch, SetStateAction, useState } from 'react';

import { useStorageEvent } from 'helpers/useStorage';
import { ESparkTabs, SPARKS_TAB_KEY } from './constants';

const useSparksTabs = (): { tab: string; setTab: Dispatch<SetStateAction<string>> } => {
  const sparksTab: string = JSON.parse(localStorage.getItem(SPARKS_TAB_KEY) ?? 'null');
  const [tab, setTab] = useState(sparksTab ?? ESparkTabs.Featured);
  localStorage.setItem(SPARKS_TAB_KEY, JSON.stringify(tab));

  useStorageEvent(() => setTab(localStorage.getItem(SPARKS_TAB_KEY) ?? ESparkTabs.Featured));

  return { tab, setTab };
};

export default useSparksTabs;
