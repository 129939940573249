import React, { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ReactComponent as ThreeBarsIcon } from 'assets/icons/ThreeHorizontalBars.svg';
import { CardSchema } from 'constants/graphqlTypes';
import PickPreview from 'components/ActivityItem/Elements/PickPreview';
import getStyles from 'helpers/getStyles';
import Grain from 'components/UI/Grain';
import getImages from 'helpers/getImages';
import { checkIsTwitterCard } from 'components/Card/helpers/helpers';

import { StyledIconContainer, StyledItemContainer, StyledTitleContainer } from './styled';

const ListItem: FC<{ card: CardSchema; id: string }> = ({ card, id }) => {
  const { bgColor } = getStyles(card.cardStyle);

  const image = getImages(card.cardStyle).imageUrl;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <StyledItemContainer
      isFromTwitter={checkIsTwitterCard(card)}
      image={image}
      bg={bgColor ?? ''}
      ref={setNodeRef}
      style={style}
    >
      <PickPreview pick={card} />
      <StyledTitleContainer>{card.title}</StyledTitleContainer>
      <StyledIconContainer
        // eslint-disable-next-line
        {...attributes}
        // eslint-disable-next-line
        {...listeners}
        isDragging={isDragging}
      >
        <ThreeBarsIcon />
      </StyledIconContainer>
      <Grain />
    </StyledItemContainer>
  );
};

export default ListItem;
