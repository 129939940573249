import { styled } from 'linaria/lib/react';
import { COLORS } from 'styles/constants';

export const StyledWrap = styled.div<{
  bgColor: string;
  firstColor?: string;
  isAllPicks: boolean;
  isDark: boolean;
  isOneImage?: boolean;
  isSliderImages?: boolean;
  secondColor?: string;
}>`
  align-items: ${({ isSliderImages }) => (isSliderImages ? 'flex-end' : 'center')};
  background: ${({ bgColor }) => bgColor};
  border-bottom: ${({ isAllPicks, isDark }) =>
    isAllPicks ? `1px solid ${isDark ? COLORS.white[30] : COLORS.brown.dark[10]}` : 'none'};
  display: flex;
  justify-content: center;
  min-height: 397px;
  padding: ${({ isSliderImages, isOneImage }) => {
    if (isSliderImages) {
      return '108px 0 12px';
    }
    if (isOneImage) {
      return '60px';
    }
    return '0 24px';
  }};
  position: relative;
  width: 100%;
  -webkit-touch-callout: none;
  user-select: none;

  &::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &::before {
    background: ${({ firstColor, secondColor, isSliderImages, isOneImage }) =>
      !isSliderImages && !isOneImage
        ? `linear-gradient(180deg, ${firstColor} 0%, rgba(0, 0, 0, 0) 50%, ${secondColor} 100%)`
        : ''};
    top: 0;
    z-index: 10;
  }
`;

export const StyledDefaultViewCardWrap = styled.li<{ isBig?: boolean }>`
  display: block;
  padding: ${({ isBig }) => (isBig ? '32px 0 64px' : '0 1% 8px')};
  width: ${({ isBig }) => (isBig ? 100 : 50)}%;
`;

export const StyledDetailViewCardWrap = styled(StyledDefaultViewCardWrap)`
  padding: 0 12px 32px;
  width: 100%;
`;
