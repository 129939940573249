import React, { Dispatch, FC, SetStateAction } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Modal from 'components/Modal';
import { useAddContentLinks } from 'components/AddContent/helpers/hooks';
import ContentTitle from 'components/UI/ContentTitle';
import TextInput from 'components/UI/TextInput';
import { DESCRIPTION_INPUT_HEIGHT } from 'components/AddContent/CreateList/CreateList';

import { StyledInputWrapper } from 'components/AddContent/AddPick/styled';
import { IAddContentLocation } from 'components/AddContent/helpers/models';
import { ROUTE_SPARKS } from 'routes';
import useDisableScroll from 'helpers/useDisableScroll';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';

interface IAddSparkProps {
  sparkTitle: string;
  setSparkTitle: Dispatch<SetStateAction<string>>;
}

const AddSpark: FC<IAddSparkProps> = ({ setSparkTitle, sparkTitle }) => {
  const { state } = useLocation<IAddContentLocation | undefined>();
  const { isSingleModal } = state ?? {};

  const { home, addSparkStyle } = useAddContentLinks();
  const { replace } = useHistory();

  const handlePrev = () => (isSingleModal ? replace(ROUTE_SPARKS) : replace(home));
  const handleDone = () => replace(addSparkStyle);

  useDisableScroll();

  return (
    <Modal
      fadeIn={false}
      handlePrev={handlePrev}
      handleClose={handlePrev}
      handleDone={handleDone}
      disabled={!sparkTitle.length}
      buttonText="Next"
    >
      <StyledInputWrapper>
        <ContentTitle isDark>What are you wondering?</ContentTitle>
        <TextInput
          inputHeight={DESCRIPTION_INPUT_HEIGHT}
          counterNumber={50}
          maxLength={50}
          placeholder="I was today years old when I learned..."
          setText={setSparkTitle}
          text={sparkTitle}
          autoCapitalize={EAutoCapitalize.On}
        />
      </StyledInputWrapper>
    </Modal>
  );
};

export default AddSpark;
