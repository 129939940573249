import PredefinedGradient1 from 'assets/images/sparks/predefined-gradient-1.webp';
import PredefinedGradient2 from 'assets/images/sparks/predefined-gradient-2.webp';
import PredefinedGradient3 from 'assets/images/sparks/predefined-gradient-3.webp';
import PredefinedGradient4 from 'assets/images/sparks/predefined-gradient-4.webp';
import PredefinedGradient5 from 'assets/images/sparks/predefined-gradient-5.webp';
import PredefinedGradient6 from 'assets/images/sparks/predefined-gradient-6.webp';
import PredefinedGradient7 from 'assets/images/sparks/predefined-gradient-7.webp';
import PredefinedGradient8 from 'assets/images/sparks/predefined-gradient-8.webp';
import PredefinedGradient9 from 'assets/images/sparks/predefined-gradient-9.webp';
import PredefinedGradient10 from 'assets/images/sparks/predefined-gradient-10.webp';
import PredefinedGradient11 from 'assets/images/sparks/predefined-gradient-11.webp';
import PredefinedGradient12 from 'assets/images/sparks/predefined-gradient-12.webp';
import PredefinedGradient13 from 'assets/images/sparks/predefined-gradient-13.webp';
import PredefinedGradient14 from 'assets/images/sparks/predefined-gradient-14.webp';
import PredefinedGradient15 from 'assets/images/sparks/predefined-gradient-15.webp';
import PredefinedGradient16 from 'assets/images/sparks/predefined-gradient-16.webp';
import PredefinedGradient17 from 'assets/images/sparks/predefined-gradient-17.webp';
import PredefinedGradient18 from 'assets/images/sparks/predefined-gradient-18.webp';
import PredefinedGradient19 from 'assets/images/sparks/predefined-gradient-19.webp';
import PredefinedGradient20 from 'assets/images/sparks/predefined-gradient-20.webp';
import PredefinedGradient21 from 'assets/images/sparks/predefined-gradient-21.webp';
import PredefinedGradient22 from 'assets/images/sparks/predefined-gradient-22.webp';
import PredefinedGradient23 from 'assets/images/sparks/predefined-gradient-23.webp';
import PredefinedGradient24 from 'assets/images/sparks/predefined-gradient-24.webp';
import PredefinedGradient25 from 'assets/images/sparks/predefined-gradient-25.webp';

export const getGradientImage = (gradientName?: string): string => {
  switch (gradientName) {
    case 'PREDEFINED_GRADIENT_1':
      return PredefinedGradient1;
    case 'PREDEFINED_GRADIENT_2':
      return PredefinedGradient2;
    case 'PREDEFINED_GRADIENT_3':
      return PredefinedGradient3;
    case 'PREDEFINED_GRADIENT_4':
      return PredefinedGradient4;
    case 'PREDEFINED_GRADIENT_5':
      return PredefinedGradient5;
    case 'PREDEFINED_GRADIENT_6':
      return PredefinedGradient6;
    case 'PREDEFINED_GRADIENT_7':
      return PredefinedGradient7;
    case 'PREDEFINED_GRADIENT_8':
      return PredefinedGradient8;
    case 'PREDEFINED_GRADIENT_9':
      return PredefinedGradient9;
    case 'PREDEFINED_GRADIENT_10':
      return PredefinedGradient10;
    case 'PREDEFINED_GRADIENT_11':
      return PredefinedGradient11;
    case 'PREDEFINED_GRADIENT_12':
      return PredefinedGradient12;
    case 'PREDEFINED_GRADIENT_13':
      return PredefinedGradient13;
    case 'PREDEFINED_GRADIENT_14':
      return PredefinedGradient14;
    case 'PREDEFINED_GRADIENT_15':
      return PredefinedGradient15;
    case 'PREDEFINED_GRADIENT_16':
      return PredefinedGradient16;
    case 'PREDEFINED_GRADIENT_17':
      return PredefinedGradient17;
    case 'PREDEFINED_GRADIENT_18':
      return PredefinedGradient18;
    case 'PREDEFINED_GRADIENT_19':
      return PredefinedGradient19;
    case 'PREDEFINED_GRADIENT_20':
      return PredefinedGradient20;
    case 'PREDEFINED_GRADIENT_21':
      return PredefinedGradient21;
    case 'PREDEFINED_GRADIENT_22':
      return PredefinedGradient22;
    case 'PREDEFINED_GRADIENT_23':
      return PredefinedGradient23;
    case 'PREDEFINED_GRADIENT_24':
      return PredefinedGradient24;
    case 'PREDEFINED_GRADIENT_25':
      return PredefinedGradient25;
    default:
      return PredefinedGradient1;
  }
};

export enum ESparkTabs {
  Featured = 'Featured',
  All = 'All',
}

export const SPARKS_TAB_KEY = 'sparks-tab';
