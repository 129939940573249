import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

import Grain from 'components/UI/Grain';
import LoaderContent from 'components/UI/LoaderContent';
import useDisableScroll from 'helpers/useDisableScroll';

import { ROUTE_ONBOARDING_CONTENT_CONFIRMATION_FINISH } from 'routes';
import { usePreview } from './hooks';
import { StyledContentWrap, StyledText, StyledContent, StyledPreviewContainer, StyledTextWrap } from './styled';

import ListPreview from './ListDetailPreview';

const Preview: FC = () => {
  const { list, items, itemsTotalCount, gradientEnd, gradientStart, loading } = usePreview();
  const { push } = useHistory();

  const handleNext = () => push(ROUTE_ONBOARDING_CONTENT_CONFIRMATION_FINISH);

  useDisableScroll();

  return (
    <StyledContentWrap gradientStart={gradientStart} gradientEnd={gradientEnd}>
      <Grain />
      <StyledContent isLoading={loading}>
        {loading ? (
          <LoaderContent isDark isFullScreen />
        ) : (
          <>
            <StyledTextWrap>
              <TypeAnimation
                wrapper="p"
                cursor={false}
                sequence={[300, 'Awesome, you picked some good ones.', 2000, handleNext]}
                className={StyledText}
                speed={45}
              />
            </StyledTextWrap>
            {list && !!items.length && (
              <StyledPreviewContainer>
                <ListPreview list={list} items={items} itemsTotalCount={itemsTotalCount} />
              </StyledPreviewContainer>
            )}
          </>
        )}
      </StyledContent>
    </StyledContentWrap>
  );
};

export default Preview;
