import React, { FC } from 'react';
import { ListGroupFollowSchema } from 'constants/graphqlTypes';
import { TEST_FOLLOWING_TAB_LIST_GROUP_SECTION } from 'constants/aqa/explore';
import PickSlider from 'components/Sliders/PickSlider';
import useTheme from 'helpers/useTheme';
import UiLink from 'components/UI/Link';
import { useItemUrl } from 'components/Card/helpers/hooks';
import Creators from 'components/Collection/Creators';
import { StyledCreatorsWrap, StyledSeeMore, StyledTitleTextWrap, StyledTitleWrap } from './styled';

const ListGroup: FC<{ item: ListGroupFollowSchema }> = ({ item }) => {
  const { cardItems, totalCount, collectionId, card, collaborators } = item;

  const isDark = useTheme();
  const collectionLink = useItemUrl(card?.id ?? '', true);

  const numberOfMorePicks =
    card?.cardsFromCollection?.totalCount &&
    cardItems?.length &&
    card?.cardsFromCollection?.totalCount - cardItems.length;

  return cardItems?.length && totalCount && collectionId ? (
    <div data-testid={TEST_FOLLOWING_TAB_LIST_GROUP_SECTION}>
      <UiLink route={collectionLink}>
        <StyledTitleWrap isDark={isDark}>
          <StyledTitleTextWrap>{card?.title}</StyledTitleTextWrap>
        </StyledTitleWrap>
        <StyledCreatorsWrap>
          <Creators creators={collaborators ?? []} creatorsText={`Added ${totalCount} picks`} isDark={isDark} />
        </StyledCreatorsWrap>
      </UiLink>
      <PickSlider isInfinite={false} items={cardItems} isDark={isDark}>
        {numberOfMorePicks ? (
          <UiLink route={collectionLink}>
            <StyledSeeMore isDark={isDark}>
              See {numberOfMorePicks} <br /> more pick{numberOfMorePicks > 1 ? 's' : ''}
            </StyledSeeMore>
          </UiLink>
        ) : undefined}
      </PickSlider>
    </div>
  ) : null;
};

export default ListGroup;
