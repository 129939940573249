import React, { FC, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

import Modal from 'components/Modal';
import Input from 'components/UI/Input';

import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { useGetEmojis, useGetRecentReactions } from 'graphQL/reactions/hooks';

import EmojiGroup from './EmojiGroup';
import SectionsFilterButtons from './SectionsFilterButtons';
import { EReactionGroupTitles } from './helpers';
import { useEmojis } from './hooks';

import { StyledContentWrap, StyledInputWrap } from './styled';

interface IConnectedModalProps {
  id: string;
  handleClose: () => void;
}

const ReactionsModal: FC<IConnectedModalProps> = ({ id, handleClose }) => {
  const [text, setText] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [blockChanging, setBlockChanging] = useState(false);
  const [scrollY] = useState(window.scrollY);
  const portalContainer = document.getElementById('modal-root');
  const recentReactions = useGetRecentReactions();
  const allEmojis = useGetEmojis();
  const { sections, searchedEmojis } = useEmojis(allEmojis, text);

  useEffect(() => {
    return () => {
      setTimeout(() => {
        window.scrollTo({
          top: scrollY,
          behavior: 'auto',
        });
      }, 0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return portalContainer
    ? createPortal(
        <Modal handleClose={handleClose}>
          <StyledInputWrap>
            <Input Icon={SearchIcon} placeholder="Search" isDark text={text} setText={setText} hasCrossIcon />
          </StyledInputWrap>
          <StyledContentWrap>
            <EmojiGroup
              title={text ? 'Results' : EReactionGroupTitles.Recent}
              symbols={text ? searchedEmojis : recentReactions}
              targetId={id}
              handleClick={handleClose}
              handleInViewPort={() => !blockChanging && setSelectedSection(EReactionGroupTitles.Recent)}
            />
            {!text &&
              allEmojis?.map(
                ({ title, id: emojiId, symbols }) =>
                  !!symbols?.totalCount && (
                    <EmojiGroup
                      key={emojiId}
                      title={title}
                      symbols={symbols.items}
                      targetId={id}
                      handleClick={handleClose}
                      handleInViewPort={() => !blockChanging && setSelectedSection(title)}
                    />
                  )
              )}
          </StyledContentWrap>
          {!text && (
            <SectionsFilterButtons
              sections={sections}
              selectedSection={selectedSection}
              setBlock={setBlockChanging}
              setSelectedSection={setSelectedSection}
            />
          )}
        </Modal>,
        portalContainer
      )
    : null;
};

export default ReactionsModal;
