import React, { FC } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/ArrowRight.svg';
import { StyledArrow } from './styled';

interface IArrow {
  degree?: number;
  isDark?: boolean;
}

const Arrow: FC<IArrow> = ({ degree = 0, isDark = true }) => (
  <StyledArrow degree={degree} isDark={isDark}>
    <ArrowRight width={12} height={12} viewBox="0 0 20 20" />
  </StyledArrow>
);

export default Arrow;
