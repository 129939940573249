import { useEffect, RefObject } from 'react';

export const useOnFocus = (input: RefObject<HTMLInputElement> | null, text: string): void => {
  useEffect(() => {
    const focusedElement = document.activeElement;
    const currentInput = input?.current;

    if (currentInput && currentInput !== focusedElement) {
      currentInput.focus();
      currentInput.setSelectionRange(text.length, text.length);
    }
  }, [input, text]);
};
