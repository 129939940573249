import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ColorSchema } from 'constants/graphqlTypes';
import { getGradient } from 'components/List/Header/helper';
import Button from 'components/UI/Button';
import { EUiButtonType } from 'components/UI/Button/constants';
import { ROUTE_ONBOARDING_CONTENT_LIST_PREVIEW } from 'routes';
import { useUnpickCards } from './hooks';
import { IListPreviewLocationState } from '../Preview/models';

import { StyledButtonWrapper } from './styled';

interface IListButtonsProps {
  listId?: string;
  selectedCards: string[];
  isDark: boolean;
  handleCancel: () => void;
  collectionBgColors?: ColorSchema[] | null;
}

const ListButtons: FC<IListButtonsProps> = ({ listId, selectedCards, isDark, handleCancel, collectionBgColors }) => {
  const { push } = useHistory<IListPreviewLocationState>();
  const { loading, handleUnpick } = useUnpickCards(selectedCards, handleCancel, listId);
  const { firstColor: gradientStart, secondColor: gradientEnd } = getGradient(
    collectionBgColors?.[0]?.colorValue ?? ''
  );

  const goToNextStep = () =>
    push({
      pathname: ROUTE_ONBOARDING_CONTENT_LIST_PREVIEW,
      state: {
        gradientStart,
        gradientEnd,
        id: listId,
      },
    });

  return (
    <>
      {selectedCards.length ? (
        <StyledButtonWrapper>
          <Button
            text="Delete"
            handler={handleUnpick}
            isDark={isDark}
            type={EUiButtonType.Primary}
            isDisabled={loading}
          />
          <Button
            text="Cancel"
            handler={handleCancel}
            isDark={isDark}
            type={EUiButtonType.Primary}
            isDisabled={loading}
          />
        </StyledButtonWrapper>
      ) : (
        <Button text="Continue" handler={goToNextStep} isDark={isDark} />
      )}
    </>
  );
};

export default ListButtons;
