import { WEB_PREFIX } from 'constants/common';
import { PROFILE_VIEW } from './addPick';

export const TEST_ID_PROFILE_VIEW_START = `${WEB_PREFIX}${PROFILE_VIEW}`;
export const TEST_PROFILE_NO_PICKS_TITLE_LABEL = `${TEST_ID_PROFILE_VIEW_START}_no-picks-title-label`;
export const TEST_PROFILE_ACTION_BTN = `${TEST_ID_PROFILE_VIEW_START}_action-button`;

export const TEST_ID_PROFILE_VIEW_USERNAME_LABEL = `${TEST_ID_PROFILE_VIEW_START}_username-label`;
export const TEST_ID_PROFILE_VIEW_NAME_LABEL = `${TEST_ID_PROFILE_VIEW_START}_name-label`;

export const TEST_ID_PROFILE_VIEW_OPTION_BUTTON = `${TEST_ID_PROFILE_VIEW_START}_options-button`;

// OPTIONS
export const TEST_ID_PROFILE_OPTIONS_VIEW = `${WEB_PREFIX}profile-options-view`;

export const TEST_ID_PROFILE_OPTIONS_VIEW_CROSS_BUTTON = `${TEST_ID_PROFILE_OPTIONS_VIEW}_cross-button`;
export const TEST_ID_PROFILE_OPTIONS_VIEW_EDIT_PROFILE_BUTTON = `${TEST_ID_PROFILE_OPTIONS_VIEW}_edit-profile-button`;
export const TEST_ID_PROFILE_OPTIONS_VIEW_EDIT_PROFILE_BUTTON_TITLE_LABEL = `${TEST_ID_PROFILE_OPTIONS_VIEW_EDIT_PROFILE_BUTTON}_title-label`; // V

export const TEST_ID_PROFILE_OPTIONS_VIEW_SHARE_BUTTON = `${TEST_ID_PROFILE_OPTIONS_VIEW}_share-button`;
export const TEST_ID_PROFILE_OPTIONS_VIEW_SHARE_BUTTON_TITLE_LABEL = `${TEST_ID_PROFILE_OPTIONS_VIEW_SHARE_BUTTON}_title-label`;

export const TEST_ID_PROFILE_OPTIONS_VIEW_SEND_ON_GH_BUTTON = `${TEST_ID_PROFILE_OPTIONS_VIEW}_send-on-gh-button`;
export const TEST_ID_PROFILE_OPTIONS_VIEW_SEND_ON_GH_BUTTON_TITLE_LABEL = `${TEST_ID_PROFILE_OPTIONS_VIEW_SEND_ON_GH_BUTTON}_title-label`;

// EDIT PROFILE
export const TEST_ID_EDIT_PROFILE_VIEW = `${WEB_PREFIX}edit-profile-view`;
export const TEST_ID_EDIT_PROFILE_VIEW_CHANGE_PHOTO_BUTTON = `${TEST_ID_EDIT_PROFILE_VIEW}_change-photo-button`;
export const TEST_ID_EDIT_PROFILE_VIEW_NAME_LABEL = `${TEST_ID_EDIT_PROFILE_VIEW}_name-label`;
export const TEST_ID_EDIT_PROFILE_VIEW_BIO_LABEL = `${TEST_ID_EDIT_PROFILE_VIEW}_bio-label`;

// NAME EDIT VIEW

export const TEST_ID_NAME_EDIT_VIEW = `${WEB_PREFIX}name-edit-view`;

export const TEST_ID_NAME_EDIT_VIEW_NAME_LABEL = `${TEST_ID_NAME_EDIT_VIEW}_name-label`;
export const TEST_ID_NAME_EDIT_VIEW_CANCEL_BUTTON = `${TEST_ID_NAME_EDIT_VIEW}_cancel-button`;
export const TEST_ID_NAME_EDIT_VIEW_DONE_BUTTON = `${TEST_ID_NAME_EDIT_VIEW}_done-button`;

// BIO EDIT VIEW

export const TEST_ID_BIO_EDIT_VIEW = `${WEB_PREFIX}bio-edit-view`;
export const TEST_ID_BIO_EDIT_VIEW_BIO_LABEL = `${TEST_ID_BIO_EDIT_VIEW}_bio-label`;
export const TEST_ID_BIO_EDIT_VIEW_CANCEL_BUTTON = `${TEST_ID_BIO_EDIT_VIEW}_cancel-button`;
export const TEST_ID_BIO_EDIT_VIEW_DONE_BUTTON = `${TEST_ID_BIO_EDIT_VIEW}_done-button`;

// UPLOAD PHOTO VIEW

export const TEST_ID_UPLOAD_PHOTO_VIEW = `${WEB_PREFIX}upload-photo-view`;

export const TEST_ID_UPLOAD_PHOTO_VIEW_CHOOSE_FILE_BUTTON = `${TEST_ID_UPLOAD_PHOTO_VIEW}_choose-file-button`;
export const TEST_ID_UPLOAD_PHOTO_VIEW_ADD_FROM_LINK_BUTTON = `${TEST_ID_UPLOAD_PHOTO_VIEW}_add-from-link-button`;
export const TEST_ID_UPLOAD_FROM_LINK_VIEW_URL_NAME_LABEL = `${TEST_ID_UPLOAD_PHOTO_VIEW}_url-name-label`;
export const TEST_ID_UPLOAD_FROM_LINK_VIEW_ADD_BUTTON = `${TEST_ID_UPLOAD_PHOTO_VIEW}_add-button`;

// TOPICS MODAL

export const TEST_PROFILE_TOPICS_MODAL_VIEW = `${TEST_ID_PROFILE_VIEW_START}_topics-modal-view`;
export const TEST_PROFILE_TOPICS_MODAL_DONE_BTN = `${TEST_PROFILE_TOPICS_MODAL_VIEW}_done-button`;

// LISTS

export const TEST_ALL_PICKS_PAGE = `${WEB_PREFIX}all-picks`;
