import { styled } from 'linaria/react';
import { EXPLORE_WRAP_PADDING_BOTTOM } from '../../../constants/common';
import { BREAKPOINTS } from '../../../styles/constants';

const StyledWrap = styled.div<{ paddingTop?: number; paddingBottom?: number }>`
  margin: 0 auto;
  max-width: ${BREAKPOINTS.s}px;
  padding: ${({ paddingTop }) => paddingTop ?? 64}px 0
    ${({ paddingBottom }) => paddingBottom ?? EXPLORE_WRAP_PADDING_BOTTOM}px;
  width: 100%;
`;

export default StyledWrap;
