import { MutationFunction } from '@apollo/client';

import {
  ChannelsAddItemMutation,
  ChannelsAddItemMutationVariables,
  useChannelsAddItemMutation,
  useGetChannelSectionsQuery,
  useChannelsDeleteItemMutation,
  ChannelsDeleteItemMutation,
  ChannelsDeleteItemMutationVariables,
  GetChannelSectionsQuery,
  GetChannelSectionsDocument,
  GetChannelSectionsQueryVariables,
  ChannelSectionTypeOptions,
  GetChannelSectionItemsQuery,
  GetChannelSectionItemsQueryVariables,
  GetChannelSectionItemsDocument,
  ChannelSectionListV2,
  ChannelSectionItemsSortTypeOptions,
  useReorderItemsInSectionMutation,
} from 'constants/graphqlTypes';
import { EQueryFetchPolicy, SHOW_FETCH_MORE_LOADER } from 'constants/common';
import { SECTION_ITEMS_LIMIT } from 'components/CustomFeeds/constants';
import usePageType from 'helpers/usePageType';
import { updateListNotification } from './helpers';
import { useGetChannelSectionItemsQuery } from '../../constants/graphqlTypes';

export const useChannelAddItem = (
  listId?: string
): {
  channelsAddItem: MutationFunction<ChannelsAddItemMutation, ChannelsAddItemMutationVariables>;
  loading: boolean;
} => {
  const [channelsAddItem, { loading }] = useChannelsAddItemMutation({
    update: (cache) => {
      updateListNotification({ cache, listId, isInChannel: true });
    },
  });

  return {
    channelsAddItem,
    loading,
  };
};

export const useChannelDeleteItem = (
  listId?: string
): {
  channelsDeleteItem: MutationFunction<ChannelsDeleteItemMutation, ChannelsDeleteItemMutationVariables>;
  loading: boolean;
} => {
  const [channelsDeleteItem, { loading }] = useChannelsDeleteItemMutation({
    update: (cache, { data }) => {
      updateListNotification({ cache, listId, isInChannel: false });
      const getChannelSections = cache.readQuery<GetChannelSectionsQuery, GetChannelSectionsQueryVariables>({
        query: GetChannelSectionsDocument,
        variables: { limit: 10 },
      });

      const sectionId = getChannelSections?.channelSections?.sections.find(
        (section) => section.sectionType === ChannelSectionTypeOptions.FollowList
      )?.id;

      const pickId = data?.channelsDeleteItem?.id;

      cache.updateQuery<GetChannelSectionItemsQuery, GetChannelSectionItemsQueryVariables>(
        {
          variables: { sectionId },
          query: GetChannelSectionItemsDocument,
        },
        (prev) => {
          return {
            ...prev,
            channelSectionItemsV2: {
              ...prev?.channelSectionItemsV2,
              totalCount: prev?.channelSectionItemsV2?.totalCount ? prev?.channelSectionItemsV2?.totalCount - 1 : 0,
              items: prev?.channelSectionItemsV2?.items?.filter((item) => item.id !== pickId),
            },
          };
        }
      );
    },
  });

  return {
    channelsDeleteItem,
    loading,
  };
};

export const useGetChannelsSections = () => {
  const { data, loading, fetchMore } = useGetChannelSectionsQuery({
    variables: {
      limit: 10,
    },
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { sections, totalCount } = data?.channelSections ?? {};

  const loadMore = () =>
    !loading &&
    sections?.length &&
    totalCount &&
    sections?.length < totalCount &&
    fetchMore({
      variables: { offset: sections?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        const prevSections = prev.channelSections?.sections ?? [];
        const fetchMoreSections = fetchMoreResult.channelSections?.sections ?? [];

        return {
          ...prev,
          channelSections: {
            totalCount: prev.channelSections?.totalCount ?? 0,
            sections: [...prevSections, ...fetchMoreSections],
          },
        };
      },
    });

  return { sections, totalCount, loading, loadMore };
};

export const useGetChannelSectionItems = (
  sectionId?: string,
  sorting?: ChannelSectionItemsSortTypeOptions,
  noLimit?: boolean
) => {
  const { data, loading, fetchMore } = useGetChannelSectionItemsQuery({
    skip: !sectionId,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    variables: {
      sectionId,
      sorting,
      limit: noLimit ? undefined : SECTION_ITEMS_LIMIT,
    },
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.channelSectionItemsV2 ?? {};

  const loadMore = () =>
    !loading &&
    items?.length &&
    totalCount &&
    items.length < totalCount &&
    fetchMore({
      variables: {
        offset: items?.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        const prevItems = prev.channelSectionItemsV2?.items ?? [];
        const fetchMoreItems = fetchMoreResult.channelSectionItemsV2?.items ?? [];

        return {
          ...prev,
          channelSectionItemsV2: {
            totalCount: prev.channelSectionItemsV2?.totalCount ?? 0,
            items: [...prevItems, ...fetchMoreItems],
          },
        };
      },
    });

  return { items, totalCount, loading, loadMore };
};

export const useGetChannelPageSection = (): { channelSection: ChannelSectionListV2 | null; loading: boolean } => {
  const { sections, loading } = useGetChannelsSections();
  const { isFeedsByTopic, isFeedsByType, isCustomFeeds } = usePageType();

  const channelSection =
    sections?.find((section) => {
      if (isCustomFeeds) {
        return section.sectionType === ChannelSectionTypeOptions.CustomFeeds;
      }

      if (isFeedsByTopic) {
        return section.sectionType === ChannelSectionTypeOptions.AdminFollowByTopic;
      }

      if (isFeedsByType) {
        return section.sectionType === ChannelSectionTypeOptions.AdminFollowByType;
      }

      return false;
    }) ?? null;

  return { channelSection, loading };
};

export const useReorderSectionItems = (): ((itemsIds: string[], sectionId: string) => void) => {
  const [reorderItems] = useReorderItemsInSectionMutation();

  return (itemsIds: string[], sectionId: string) => reorderItems({ variables: { itemsIds, sectionId } });
};
