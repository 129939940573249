import config from 'constants/config';
import { EOnboardingStepOptions } from 'constants/graphqlTypes';
import {
  ROUTE_ONBOARDING,
  ROUTE_ONBOARDING_USERNAME,
  ROUTE_ONBOARDING_BIO,
  ROUTE_ONBOARDING_IMAGE,
  ROUTE_ONBOARDING_TOPICS,
  ROUTE_ONBOARDING_NOTIFICATIONS,
  ROUTE_ONBOARDING_FULLNAME,
  ROUTE_ONBOARDING_WELCOME,
} from 'routes';

const { WelcomeScreen, FullName, Username, Description, UploadImage, Topics, Notifications } = EOnboardingStepOptions;
const { areInviteCodesDisabled } = config;
const OnboardingRoutesWithSteps = {
  [WelcomeScreen]: areInviteCodesDisabled ? ROUTE_ONBOARDING : ROUTE_ONBOARDING_WELCOME,
  [FullName]: ROUTE_ONBOARDING_FULLNAME,
  [Username]: ROUTE_ONBOARDING_USERNAME,
  [Description]: ROUTE_ONBOARDING_BIO,
  [UploadImage]: ROUTE_ONBOARDING_IMAGE,
  [Topics]: ROUTE_ONBOARDING_TOPICS,
  [Notifications]: ROUTE_ONBOARDING_NOTIFICATIONS,
};

export const getOnboardingRedirectPath = (step: EOnboardingStepOptions): string => OnboardingRoutesWithSteps[step];
