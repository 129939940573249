import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSearchTotalCount } from 'graphQL/search/hooks';
import { ROUTE_SEARCH_RESULT } from 'routes';
import { ESearchCardType } from 'constants/graphqlTypes';
import { ESearchParams } from 'constants/common';

const useSearchTotal = (searchText: string): void => {
  const { replace } = useHistory();
  const { totalCount: totalCountLists, loadingCount: loadingCountLists } = useSearchTotalCount(
    searchText,
    ESearchCardType.Collection
  );
  const { totalCount: totalCountPicks, loadingCount: loadingCountPicks } = useSearchTotalCount(
    searchText,
    ESearchCardType.Card
  );
  const { totalCount: totalCountUsers, loadingCount: loadingCountUsers } = useSearchTotalCount(
    searchText,
    ESearchCardType.User
  );

  useEffect(() => {
    if (
      !loadingCountLists &&
      !loadingCountPicks &&
      !loadingCountUsers &&
      !totalCountPicks &&
      (totalCountLists || totalCountUsers)
    ) {
      replace({
        pathname: ROUTE_SEARCH_RESULT,
        search: `?${ESearchParams.Search}=${encodeURIComponent(searchText)}&${ESearchParams.Tab}=${
          ESearchCardType[totalCountLists ? 'Collection' : 'User']
        }`,
      });
    }
  }, [
    totalCountLists,
    totalCountPicks,
    totalCountUsers,
    replace,
    searchText,
    loadingCountLists,
    loadingCountPicks,
    loadingCountUsers,
  ]);
};

export default useSearchTotal;
