import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';

import usePageType from 'helpers/usePageType';
import { StyledNoContent } from 'components/List/Content/styled';
import { SearchChatUnionSchema } from 'constants/graphqlTypes';

import LoaderContent from 'components/UI/LoaderContent';

import SingleTheadItem from './ListItem/SingleTheadItem';
import GroupTheadItem from './ListItem/GroupTheadItem';
import UserItem from './ListItem/UserItem';
import { StyledList, StyledListItem } from './styled';
import { NO_SEARCH_RESULTS_PLACEHOLDER, EMPTY_MESSAGES_LIST_PLACEHOLDER } from './constants';

interface IListProps {
  isDark: boolean;
  items: SearchChatUnionSchema[];
  loadMore: () => void;
  loading: boolean;
  isSearchActive?: boolean;
}

const List: FC<IListProps> = ({ isDark, items, loadMore, loading, isSearchActive }) => {
  const { isMessagesSearch } = usePageType();
  return (
    <StyledList>
      {items.map((item) => (
        <StyledListItem key={item.id}>
          {item.__typename === 'SingleChatSchema' && (
            <SingleTheadItem isDark={isDark} item={item} isSearchActive={isSearchActive} />
          )}
          {item.__typename === 'GroupChatSchema' && (
            <GroupTheadItem isDark={isDark} item={item} isSearchActive={isSearchActive} />
          )}
          {item.__typename === 'UserShortSchema' && <UserItem isDark={isDark} user={item} />}
        </StyledListItem>
      ))}
      {!items.length && !loading && (
        <StyledListItem>
          <StyledNoContent isDark={isDark}>
            {isMessagesSearch ? NO_SEARCH_RESULTS_PLACEHOLDER : EMPTY_MESSAGES_LIST_PLACEHOLDER}
          </StyledNoContent>
        </StyledListItem>
      )}
      {loading ? <LoaderContent isFullScreen={!items.length} /> : <Waypoint onEnter={loadMore} />}
    </StyledList>
  );
};

export default List;
