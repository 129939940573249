import { styled } from 'linaria/react';

import { FONTS } from '../../../styles/constants';
import { BUTTON_STYLES, EUiButtonType } from './constants';

interface IUiButtonProps {
  isDark: boolean;
  buttonType: EUiButtonType;
  maxWidth?: string;
  hasIcon?: boolean;
}

const StyledButton = styled.button<IUiButtonProps>`
  align-items: center;
  background: ${({ isDark, buttonType }) => BUTTON_STYLES.background[buttonType].default[isDark ? 'dark' : 'light']};
  border-radius: 22px;
  border: none;
  color: ${({ isDark, buttonType }) => BUTTON_STYLES.color[buttonType].default[isDark ? 'dark' : 'light']};
  cursor: pointer;
  display: ${({ hasIcon }) => (hasIcon ? 'flex' : 'block')};
  font: 600 14px/140% ${FONTS.secondary};
  gap: 4px;
  height: 44px;
  padding: 12px 20px;
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth ?? 'unset'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :active {
    background: ${({ isDark, buttonType }) => BUTTON_STYLES.background[buttonType].pressed[isDark ? 'dark' : 'light']};
    color: ${({ isDark, buttonType }) => BUTTON_STYLES.color[buttonType].pressed[isDark ? 'dark' : 'light']};
  }

  &:disabled {
    background: ${({ isDark, buttonType }) => BUTTON_STYLES.background[buttonType].disabled[isDark ? 'dark' : 'light']};
    color: ${({ isDark, buttonType }) => BUTTON_STYLES.color[buttonType].disabled[isDark ? 'dark' : 'light']};
    cursor: default;
  }
`;

export default StyledButton;
