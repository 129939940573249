import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';
import { XBlock, XMasonry } from 'react-xmasonry';

import Card from 'components/Card';
import Modal from 'components/Modal';
import Button from 'components/UI/Button';
import { useGetCategoryCards, useGetOnboardingPickCategories } from 'graphQL/onboardingV2/hooks';
import useOpenHiddenCards from 'helpers/useOpenHiddenCards';
import { CardSchema } from 'constants/graphqlTypes';
import ModalHeader from './ModalHeader';

import { StyledButtonWrap, StyledCard, StyledContainer, StyledWrap } from './styled';
import { useCardWidth, useCardsSelection } from './hooks';

interface IListFillProps {
  handleClose: () => void;
  id: string;
}
const ListFill: FC<IListFillProps> = ({ id, handleClose }) => {
  const cardWidth = useCardWidth();
  const { openIds } = useOpenHiddenCards();
  const { categoryItems, activeCategory, setActiveCategory } = useGetOnboardingPickCategories();
  const { cards, loading, loadMore } = useGetCategoryCards(activeCategory);
  const { selectedCards, buttonText, handleCardClick, handleComplete } = useCardsSelection({
    listId: id,
    handleClose,
    categoryItems,
  });

  const handleClick = (item: CardSchema) => {
    if (!item?.isHidden || !!openIds?.includes(item?.cardId)) {
      handleCardClick(item.id);
    }
  };

  return (
    <StyledWrap>
      <Modal enableInnerScroll fadeIn handleClose={handleClose}>
        <ModalHeader
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          categoryItems={categoryItems}
        />
        <StyledContainer>
          <XMasonry targetBlockWidth={cardWidth} responsive maxColumns={2} center={false}>
            {cards.map((item, index) => (
              <XBlock key={item.id}>
                {!loading && index === cards.length - 1 && <Waypoint onEnter={loadMore} />}
                <StyledCard
                  onClick={() => handleClick(item)}
                  isShowCheckbox
                  showCheck={selectedCards.includes(item.id)}
                >
                  <Card shouldDisableLongTap card={item} />
                </StyledCard>
              </XBlock>
            ))}
          </XMasonry>
          {buttonText && (
            <StyledButtonWrap>
              <Button text={buttonText} handler={handleComplete} />
            </StyledButtonWrap>
          )}
        </StyledContainer>
      </Modal>
    </StyledWrap>
  );
};

export default ListFill;
