import {
  MessageType,
  MessageSchema,
  UserShortSchema,
  TabOptions,
  MessageReactionSchema,
  GroupChatSchema,
  SenderUnionSchema,
  ActionType,
  Action_Type,
  UserObjectType,
} from 'constants/graphqlTypes';
import { generateFullName, generateName } from 'components/Profile/ProfileTop/helpers';
import { EUiButtonType } from 'components/UI/Button/constants';
import { parseToEmoji } from 'components/Reactions/helpers/helpers';
import getAuthUser from 'helpers/getAuthUser';
import { MENTIONED_USER_EXPRESSION } from '../Message/constants';
import { EMPTY_USER, MESSAGES_TAB_KEY } from './constants';

interface IThreadLastMassage {
  lastMessage?: MessageSchema | null;
  receiver?: UserShortSchema | null;
  username?: string | null;
  firstName?: string | null;
  lastReaction?: MessageReactionSchema | null;
  initiator?: SenderUnionSchema | null;
  actionType?: ActionType | Action_Type | null;
  addedUsers?: string[];
  firstUser?: UserObjectType;
  secondUser?: UserObjectType;
}

const getUserName = (user?: UserObjectType) => (user ? generateFullName(user) : EMPTY_USER);

const getMessageForAddedUsers = (addedUsers: string[], firstUser?: UserObjectType, secondUser?: UserObjectType) => {
  const additinalUsersMessage = ` and ${secondUser ? getUserName(secondUser) : `${addedUsers.length - 1} others}`}`;

  return ` added ${firstUser ? getUserName(firstUser) : EMPTY_USER}${
    addedUsers.length < 2 ? '' : additinalUsersMessage
  }`;
};

const getSystemMessage = (
  actionType: ActionType | Action_Type | null,
  addedUsers: string[] = [],
  initiator?: SenderUnionSchema | null,
  firstUser?: UserObjectType,
  secondUser?: UserObjectType
) => {
  const initiatorName = initiator?.__typename === 'UserShortSchema' ? generateFullName(initiator) : EMPTY_USER;
  const actionText =
    actionType === ActionType.LeaveChat
      ? 'has left the group'
      : getMessageForAddedUsers(addedUsers, firstUser, secondUser);
  return `${initiatorName} ${actionText}`;
};

export const getThreadLastMessageText = ({
  lastMessage,
  receiver,
  username,
  firstName,
  lastReaction,
  initiator,
  actionType,
  addedUsers,
  firstUser,
  secondUser,
}: IThreadLastMassage): string | null => {
  const { message, messageType, mentionedUsers, sender } = lastMessage ?? {};
  const { userId } = getAuthUser();
  const senderId = sender?.__typename === 'UserShortSchema' && sender?.id;
  const isMine = userId === senderId;

  const text = username && firstName ? `${generateName(username, firstName)} s` : 'S';
  const simpleMessageText = username && firstName ? `${generateName(username, firstName)}: ` : ``;
  const reactionText = isMine ? 'You reacted to their' : 'Reacted to your';

  const reactionToMessageName = generateName(lastReaction?.user?.username, lastReaction?.user?.firstName);
  const reactionToMessageText = username && firstName ? `${reactionToMessageName} r` : 'R';
  const inviteUserName =
    !isMine && sender?.__typename === 'UserShortSchema'
      ? generateName(sender.username, sender.firstName)
      : generateName(receiver?.username, receiver?.firstName);

  if (lastReaction && lastMessage && lastReaction.createdAt > lastMessage.createdAt && lastReaction.reactionSymbol) {
    return `${reactionToMessageText}eacted to your message: ${parseToEmoji(lastReaction.reactionSymbol.symbol)}`;
  }

  if (message?.__typename === 'SimpleMessageSchema') {
    return `${simpleMessageText} ${
      mentionedUsers?.length
        ? mentionedUsers.reduce(
            (acc, mentionedUser) => acc.replaceAll(`[user_id:${mentionedUser.id}]`, generateFullName(mentionedUser)),
            message.text
          )
        : message.text.replaceAll(MENTIONED_USER_EXPRESSION, 'User not found')
    }`;
  }
  if (message?.__typename === 'LinkPreviewMessageSchema') {
    return `${text}ent a link`;
  }
  if (message?.__typename === 'UserMessageSchema') {
    return `${text}ent a profile`;
  }
  if (message?.__typename === 'CardCommentMessageSchema' && !message?.contentReactionSymbol) {
    return `${text}ent a thought`;
  }
  if (message?.__typename === 'CardCommentMessageSchema' && message?.contentReactionSymbol) {
    return `${reactionText} thought: ${parseToEmoji(message?.contentReactionSymbol)}`;
  }
  if (
    messageType === MessageType.Pick &&
    message?.__typename === 'CardMessageSchema' &&
    !message?.contentReactionSymbol
  ) {
    return `${text}ent a pick`;
  }
  if (
    messageType === MessageType.Pick &&
    message?.__typename === 'CardMessageSchema' &&
    message?.contentReactionSymbol
  ) {
    return `${reactionText} pick: ${parseToEmoji(message?.contentReactionSymbol)}`;
  }
  if (
    messageType === MessageType.List &&
    message?.__typename === 'CardMessageSchema' &&
    !message?.contentReactionSymbol
  ) {
    return `${text}ent a list`;
  }
  if (
    messageType === MessageType.List &&
    message?.__typename === 'CardMessageSchema' &&
    message?.contentReactionSymbol
  ) {
    return `${reactionText} list: ${parseToEmoji(message?.contentReactionSymbol)}`;
  }
  if (message?.__typename === 'InvitationMessageSchema') {
    return `${isMine ? `You invited ${inviteUserName}` : `${inviteUserName} invited you`} to collaborate on a list`;
  }
  if (messageType === MessageType.Spark && message?.__typename === 'CardMessageSchema') {
    return `${isMine ? 'Sent a spark' : 'Sent you a spark'}`;
  }
  if (messageType === MessageType.GuestList && message?.__typename === 'CardMessageSchema') {
    return `${text}ent a guest:list episode`;
  }
  if (messageType === MessageType.GuestListSeries && message?.__typename === 'GuestListSeriesMessageSchema') {
    return `${text}ent a guest:list series`;
  }
  if (
    message?.__typename === 'SystemMessageSchema' &&
    (actionType === ActionType.LeaveChat || actionType === ActionType.AddUser)
  ) {
    return getSystemMessage(actionType, addedUsers, initiator, firstUser, secondUser);
  }
  return null;
};

export const getTabButtonType = (value: TabOptions | null, compared: TabOptions | null): EUiButtonType =>
  value === compared ? EUiButtonType.Emphasized : EUiButtonType.Primary;

export const getMessageTab = (): TabOptions | null => {
  const messagesTabKey = JSON.parse(localStorage.getItem(MESSAGES_TAB_KEY) ?? 'null');

  switch (messagesTabKey) {
    case TabOptions.ActiveThreads:
      return TabOptions.ActiveThreads;
    case TabOptions.Reactions:
      return TabOptions.Reactions;
    default:
      return null;
  }
};

export const getUsername = (moreNumber: number, users?: UserShortSchema[], isSearchActive?: boolean): string => {
  if (!isSearchActive || !users) {
    return '';
  }
  const firstUsername = `:${users[0]?.username}`;
  const secondUsername = users[1] ? `, :${users[1]?.username}` : '';
  return `${firstUsername}${secondUsername}${moreNumber ? ',' : ''}`;
};

export const getMoreNumber = (members: GroupChatSchema['members']): number => {
  if (!members || !members.totalCount) return 0;
  const moreNumber = members.totalCount - 2;

  return moreNumber > 0 ? moreNumber : 0;
};
