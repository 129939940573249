import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Grain from 'components/UI/Grain/Grain';
import Button from 'components/UI/Button/Button';

import { ROUTE_ABOUT } from 'routes';
import { EUiButtonType } from 'components/UI/Button/constants';
import { StyledBody, StyledTitle, StyledContent } from 'components/Card/Common/styled';
import { StyledButtonWrap, StyledWrap, StyledTag } from './styled';

const AboutUs: FC = () => {
  const { push } = useHistory();

  return (
    <StyledWrap>
      <StyledBody bg="linear-gradient(180deg, #2A657E 0%, #B977AD 100%)" isReverse={false}>
        <Grain />
        <StyledContent>
          <StyledTag>About us</StyledTag>
          <StyledTitle isBig>
            “guide:human is all about humans sharing the things they’re into and their thoughts about them.
            <br />
            <br />
            You follow people to get their perspective on the world, and they follow you to get yours.”
          </StyledTitle>
          <StyledButtonWrap>
            <Button handler={() => push(ROUTE_ABOUT)} text="Learn more" type={EUiButtonType.Primary} />
          </StyledButtonWrap>
        </StyledContent>
      </StyledBody>
    </StyledWrap>
  );
};

export default AboutUs;
