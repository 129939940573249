import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from '../../../styles/constants';

export const StyledWrap = styled.div`
  flex: 1;
  margin-top: 24px;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-height: 436px;
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    max-height: calc(436px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledWrapListTitle = styled.div`
  padding-bottom: 8px;
`;

export const StyledListTitle = styled.span`
  font-family: ${FONTS.secondary};
  font-size: 14px;

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(14px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledList = styled.ul<{ isOrganizeLater?: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0 0 ${({ isOrganizeLater }) => (isOrganizeLater ? 0 : 40)}px;

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    padding: 0 0 calc(${({ isOrganizeLater }) => (isOrganizeLater ? 0 : 40)}px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledListItem = styled.li`
  display: block;
`;

export const StyledHr = styled.hr`
  background-color: ${COLORS.white[30]};
  border: none;
  height: 1px;
  margin: 8px 0 0;
`;
