import { ReactComponent as PersonIcon } from 'assets/icons/Person.svg';
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg';
import { ReactComponent as HandIcon } from 'assets/icons/Hand.svg';
import { ReactComponent as StrikeIcon } from 'assets/icons/Strike.svg';

export const PRIVACY_DATA = [
  {
    bgColor: 'linear-gradient(180deg, #B6572E 0%, #8E4424 100%)',
    Icon: PersonIcon,
    title: 'We give you control',
    text:
      'At guide:human, we put humans first—and believe that your data belongs to you. Nothing is used without your permission.',
    fullInfo: [
      {
        title: 'Choose how your data is used',
        text:
          "At guide:human, we put humans first. We believe that your data belongs to you, that's why we give you control over how it is used to enhance your experience. At any time, access your settings to adjust your data preferences.",
      },
      {
        title: 'Requesting your data',
        text: 'You are able to request all the data you share with guide:human in a downloadable format at any time.',
      },
      {
        title: 'Ask permission',
        text:
          'Anytime we need to access your data, we will always explain its use and ask for your permission. For example, if we use your photos for your profile picture, we will not use them for anything else.',
      },
    ],
  },
  {
    bgColor: 'linear-gradient(180deg, #767676 0%, #5C5C5C 100%)',
    Icon: HandIcon,
    title: 'We only collect what we need',
    text: 'That means data essential to the guide:human experience, as well as data that helps us improve the service.',
    fullInfo: [
      {
        title: 'Data that’s necessary',
        text:
          'We collect basic data to make the app work for you. We need your email address for your account to exist and to send you administrative emails. We’ll also remember your preferences. You cannot turn this off.',
      },
      {
        title: 'Data to enhance your experience',
        text:
          'The content you share on guide:human (picks, thoughts, lists) is stored to give you a personalised, useful experience. We will also use your email address for marketing purpose, which you can choose to opt out of.',
      },
      {
        title: 'Data that improves guide:human for all',
        text:
          'We are constantly working on the features and experience of guide:human. Collecting aggregate data to be able to track usage trends helps us do this. However, we give you the option to opt out of this.',
      },
    ],
  },
  {
    bgColor: 'linear-gradient(180deg, #3478B8 0%, #285D8F 100%)',
    Icon: LockIcon,
    title: 'We ensure your data is safe and secure',
    text: 'See how we’re using best-in-class encryption methods to keep your information safe and lead the industry.',
    fullInfo: [
      {
        title: 'Our security measures',
        text:
          'The safety and security of your personal data is our top priority, and we actually mean it. We use best-in-class encryption methods that keep your information under virtual lock and key, giving you peace of mind.',
      },
      {
        title: 'Leading the industry',
        text:
          'We strive to not only lead the industry in implementing advanced data protection and trustworthy practices, but also to inspire others to raise their standards and do better.',
      },
    ],
  },
  {
    bgColor: 'linear-gradient(180deg, #586F9D 0%, #46587C 100%)',
    Icon: StrikeIcon,
    title: 'We will never sell your data',
    text:
      'We value your data—and your trust. We’ll never track you outside of the app, sell your data, or scrape your contacts.',
    fullInfo: [
      {
        title: 'Our promise',
        text: "We’ll never sell your personal information, and you're in control of how and when we use your data.",
      },
      {
        title: 'Sharing data with trusted partners',
        text:
          "To give you a personalised experience, we may occasionally ask to share your data with trusted partners. For example, if you want to use the social media sharing feature, we'll securely send content to your chosen platform while protecting your privacy.  We will never post on your behalf, or scrape your contacts.",
      },
      {
        title: 'No tracking',
        text:
          'We won’t track you outside of the app, access your personal information or any other device activities. Period.',
      },
    ],
  },
];
