import React, { useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import {
  ROUTE_ADD_SAVED_CONTENT,
  ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST,
  ROUTE_ADD_SAVED_CONTENT_CREATE_LIST,
} from 'routes';
import { CardSchema } from 'constants/graphqlTypes';
import { useProfile } from 'graphQL/profile/hooks';
import { useBehaviour } from 'components/AddSavedContentRoutes/AddSavedContent/utils/hooks';

import AddSavedToList from 'components/AddContent/AddSavedToList';
import { ListRoutingParams } from 'components/AddContent/helpers/models';
import CreateList from 'components/AddContent/CreateList';
import { useLink } from 'helpers/routingHelper';
import AddSavedContent from './AddSavedContent';

const AddSavedContentRoutes = () => {
  const [listToAdd, setListToAdd] = useState('');
  const [listTitle, setListTitle] = useState('');
  const [listRoutingParams, setListRoutingParams] = useState<ListRoutingParams>({
    id: '',
    isPrivateList: false,
  });

  const { goBack, replace } = useHistory();
  const { userData } = useProfile();

  const { state } = useLocation<{ variable: { cards: CardSchema[] } }>();
  const { cards } = state.variable ?? {};

  const behaviourState = useBehaviour(listToAdd, listTitle, listRoutingParams);
  const addSavedContentLink = useLink(ROUTE_ADD_SAVED_CONTENT);
  const addSavedContentChooseLink = useLink(ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST);

  return (
    <Switch>
      <Route exact path={ROUTE_ADD_SAVED_CONTENT}>
        <AddSavedContent behaviourState={behaviourState} userData={userData} />
      </Route>
      <Route exact path={ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST}>
        <AddSavedToList
          cardsPileData={cards}
          pickId={cards?.[0].id}
          setListToAdd={setListToAdd}
          setListTitle={setListTitle}
          setListRoutingParams={setListRoutingParams}
          handleClose={goBack}
          handlePrev={goBack}
          fadeIn
        />
      </Route>
      <Route exact path={ROUTE_ADD_SAVED_CONTENT_CREATE_LIST}>
        <CreateList
          handleClose={(collectionCardId, title, collectionId) => {
            setListToAdd(collectionCardId ?? '');
            setListTitle(title ?? '');
            setListRoutingParams({ id: collectionId ?? '', isPrivateList: false });
            replace(addSavedContentLink);
          }}
          handlePrev={() => replace(addSavedContentChooseLink)}
        />
      </Route>
    </Switch>
  );
};

export default AddSavedContentRoutes;
