import React, { FC } from 'react';

import MenuButton from 'components/UI/MenuButton';
import useTheme from 'helpers/useTheme';
import SinglePageLayout from 'components/SinglePageLayout';
import { EMenuButtonType } from 'components/UI/MenuButton/constants';
import {
  TEST_DATA_PREFERENCES_PERFOMANCE,
  TEST_DATA_PREFERENCES_STRICTLY_NECESSARY,
  TEST_DATA_PREFERENCES_USAGE_HISTORY,
  TEST_ID_DATA_PREFERENCES_VIEW_START,
} from 'constants/aqa/settings/accountManagement';
import { useUpdateUserSettings, useUserSettings } from 'graphQL/contextualIntro/hooks';
import { StyledList } from 'pages/Settings/styled';
import { StyledItem } from './styled';

const DataPreferences: FC = () => {
  const { collectPerformanceAnalytics, collectPersonalPreferences } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings({});

  const isDark = useTheme();

  return (
    <SinglePageLayout
      pageTestId={TEST_ID_DATA_PREFERENCES_VIEW_START}
      title="Data preferences"
      subTitle="We collect data that is essential to the guide:human experience, as well as data that helps us improve the service. You can choose the data we collect."
    >
      <StyledList isDark={isDark}>
        <StyledItem>
          <MenuButton
            disabled
            text="Strictly necessary"
            checked
            isDark={isDark}
            type={EMenuButtonType.Toggle}
            description="We need your email address for this experience to work. We’ll also remember your app settings. You cannot
            turn this off."
            dataTestId={TEST_DATA_PREFERENCES_STRICTLY_NECESSARY}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Performance and analytics"
            checked={!!collectPerformanceAnalytics}
            isDark={isDark}
            type={EMenuButtonType.Toggle}
            handler={() =>
              updateUserSettings({ variables: { collectPerformanceAnalytics: !collectPerformanceAnalytics } })
            }
            description="Data collected on how you use the app, not linked to your identity."
            dataTestId={TEST_DATA_PREFERENCES_PERFOMANCE}
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            text="Usage history"
            checked={!!collectPersonalPreferences}
            isDark={isDark}
            type={EMenuButtonType.Toggle}
            handler={() =>
              updateUserSettings({ variables: { collectPersonalPreferences: !collectPersonalPreferences } })
            }
            description="Saving your usage history helps us personalize your feeds."
            dataTestId={TEST_DATA_PREFERENCES_USAGE_HISTORY}
          />
        </StyledItem>
      </StyledList>
    </SinglePageLayout>
  );
};

export default DataPreferences;
