import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ROUTE_MESSAGES } from 'routes';
import withHapticFeedback from 'helpers/withHapticFeedback';
import { TEST_HOME_MESSAGES_BTN } from 'constants/aqa/main';
import { useUnreadedMessages } from 'graphQL/messages/hooks';
import { useCountersSubscription } from 'graphQL/messages/hooksSubscriptions';
import { ReactComponent as PaperPlane } from 'assets/icons/PaperPlane.svg';
import { StyledButton, StyledLabel } from '../styled';

const MessagesButton: FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const count = useUnreadedMessages();
  const onClick = () => push(ROUTE_MESSAGES, { prevPath: pathname });

  useCountersSubscription();

  return (
    <StyledButton
      data-testid={TEST_HOME_MESSAGES_BTN}
      className="icon-navigation"
      onClick={withHapticFeedback(onClick)}
    >
      <PaperPlane />
      {!!count && <StyledLabel>{count > 99 ? '99+' : count}</StyledLabel>}
    </StyledButton>
  );
};

export default MessagesButton;
