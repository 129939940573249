import { styled } from 'linaria/react';

import { BREAKPOINTS } from 'styles/constants';

export const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
  padding-top: 20px;
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    gap: 78px;
  }
`;

export const StyledTop = styled.div`
  display: flex;
  flex-direction: column;
`;
