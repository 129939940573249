import { styled } from 'linaria/react';
import ImportWrap from 'components/UI/Wrap/styled';

import { COLORS, FONTS } from '../../styles/constants';

export const StyledWrap = styled(ImportWrap)`
  width: calc(100% - 24px);
`;

export const StyledTitle = styled.h1<{ isDark?: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 32px/120% ${FONTS.primary};
  padding: 16px 12px;
  word-wrap: break-word;
  margin: 0;
`;

export const StyledSliderWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

export const StyledSlide = styled.div`
  font: 600 14px/140% ${FONTS.secondary};
  margin: 0 auto;
  text-align: center;
`;

export const StyledSliderButton = styled.button<{ shouldRotate?: boolean; color: string; isDisabled?: boolean }>`
  background-color: ${COLORS.transparent};
  border: none;
  cursor: pointer;
  height: 44px;
  padding: 0;
  visibility: ${({ isDisabled }) => (!isDisabled ? 'visible' : 'hidden')};
  width: 44px;

  svg {
    transform: ${({ shouldRotate }) => (shouldRotate ? 'rotate(-180deg)' : 'rotate(0)')};
  }

  path {
    fill: ${({ color }) => color};
  }
`;

export const StyledCardsWrapper = styled.div`
  padding: 16px 0;

  > a {
    display: block;
    margin-bottom: 12px;
  }
`;
