import { styled } from 'linaria/react';
import { BREAKPOINTS } from '../../../../styles/constants';

const CARD_WIDTH = 117;
const CARD_WIDTH_WITH_MARGIN = 129;
const CARD_HEIGHT = 29;
const CARD_HEIGHT_DESKTOP = 44;

export const StyledSlider = styled.div<{ isImages: boolean }>`
  align-items: center;
  display: flex;
  height: ${({ isImages }) => (isImages ? 'auto' : `${CARD_HEIGHT * 9}px`)};
  max-height: calc(${BREAKPOINTS.s}px - 100px);
  max-width: ${({ isImages }) => (isImages ? BREAKPOINTS.s : BREAKPOINTS.s)}px;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    height: ${({ isImages }) => (isImages ? 'auto' : `${CARD_HEIGHT_DESKTOP * 9}px`)};
  }
`;

export const StyledSliderTrack = styled.div<{
  itemsLength: number;
  isImages: boolean;
  onlyOneItem: boolean;
  pauseAnimation?: boolean;
}>`
  align-items: ${({ isImages }) => (isImages ? 'flex-end' : 'flex-start')};
  animation: scroll
    ${({ onlyOneItem, isImages }) => {
      const animationTime = isImages ? 40 : 10;
      return onlyOneItem ? 0 : animationTime;
    }}s
    linear infinite;
  animation-play-state: ${({ pauseAnimation }) => (pauseAnimation ? 'paused' : 'running')};
  display: flex;
  flex-direction: ${({ isImages }) => (isImages ? 'row' : 'column')};
  width: ${({ itemsLength, isImages }) => (isImages ? `${CARD_WIDTH * itemsLength * 2}px` : 'auto')};

  @keyframes scroll {
    0% {
      transform: ${({ isImages }) => (isImages ? 'translateX(0)' : 'translateY(0)')};
    }
    100% {
      transform: ${({ isImages, itemsLength }) =>
        isImages
          ? `translateX(${itemsLength * -CARD_WIDTH_WITH_MARGIN}px)`
          : `translateY(${itemsLength * -CARD_HEIGHT}px)`};
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    @keyframes scroll {
      0% {
        transform: ${({ isImages }) => (isImages ? 'translateX(0)' : 'translateY(0)')};
      }
      100% {
        transform: ${({ isImages, itemsLength }) =>
          isImages
            ? `translateX(${itemsLength * -CARD_WIDTH_WITH_MARGIN}px)`
            : `translateY(${itemsLength * -CARD_HEIGHT_DESKTOP}px)`};
      }
    }
  }
`;

export const StyledSliderImage = styled.img`
  border-radius: 8px;
  margin: 0 6px;
  min-height: ${CARD_WIDTH}px;
  object-fit: cover;
  width: ${CARD_WIDTH}px;
  min-width: ${CARD_WIDTH}px;
  max-height: 193px;
`;

export const StyledSliderText = styled.span<{ color: string; isOnboarding?: boolean }>`
  color: ${({ color }) => color};
  font-size: 22px;
  height: ${CARD_HEIGHT}px;
  line-height: 130%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100vw - 48px);

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    font-size: ${({ isOnboarding }) => (isOnboarding ? 22 : 35)}px;
    height: ${({ isOnboarding }) => (isOnboarding ? CARD_HEIGHT : CARD_HEIGHT_DESKTOP)}px;
    width: ${({ isOnboarding }) => (isOnboarding ? ` calc(100vw - 48px)` : 528)}px;
  }
`;
