import { IAddContentLocation } from 'components/AddContent/helpers/models';
import { CardSchema } from 'constants/graphqlTypes';

export const getThoughtSubmitText = (routeState: IAddContentLocation['variable'], card?: CardSchema): string => {
  const { isMovePick, moveListTitle, hasThought } = routeState ?? {};
  const { isSavedByMe } = card ?? {};

  if (moveListTitle) {
    return moveListTitle;
  }

  if (isMovePick) {
    return 'Move';
  }

  if (isSavedByMe) {
    return 'Share';
  }

  return hasThought ? 'Save' : 'Add';
};
