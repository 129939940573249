import { styled } from 'linaria/react';
import { StyledTitle as Title } from 'components/GuestList/Card/styled';
import { FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  .list-body {
    margin: 16px 0;
  }

  ul {
    width: 100%;
    height: 100%;
  }

  li {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  li > span {
    line-height: 140%;
    font-size: 9px;
    padding: 0 0 8% 8%;
  }
`;

export const StyledTitle = styled(Title)<{ isSeries: boolean }>`
  display: block;
  line-height: ${({ isSeries }) => (isSeries ? '98%' : '130%')};
  margin-top: ${({ isSeries }) => (isSeries ? '-10px' : 0)};
  font-size: ${({ isSeries }) => (isSeries ? 44 : 22)}px;
  word-break: break-word;
  word-spacing: normal;
  -webkit-line-clamp: 6;
`;

export const StyledText = styled.span`
  font-family: ${FONTS.secondary};
`;

export const StyledGuestListStylesItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;

  p {
    font-size: 3px;
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    width: 20px;
  }

  p:first-child {
    font-size: 2px;
  }
`;

export const StyledGuestListStylesItemImage = styled.img`
  height: 20px;
  margin-top: 3px;
  object-fit: cover;
  width: 20px;
`;
