import {
  CardCommentListObjectType,
  CardSchema,
  UserObjectType,
  ReactionListObjectType,
  CardCommentFilterOptionsEnum,
  CardCommentHighlightOptions,
} from 'constants/graphqlTypes';
import { getWeeklyDateFormat } from 'helpers/getDateFormat';
import { IGetActionUsers } from 'graphQL/card/helpers/helpers';
import { Maybe } from 'graphql/jsutils/Maybe';
import { IThoughthTotalCounts } from './models';

export interface IThoughtItem {
  user: UserObjectType;
  id: string;
  text: string;
  lastUpdate?: string;
  responseToQuestion?: Maybe<CardSchema>;
  responseToSpark?: Maybe<CardSchema>;
  guestListQuestion?: Maybe<CardSchema>;
  reactions?: Maybe<ReactionListObjectType>;
  highlighted?: Maybe<CardCommentHighlightOptions>;
}

export const convertTypes = (
  picks?: IGetActionUsers,
  comments?: Maybe<CardCommentListObjectType>
): { adds: IThoughtItem[]; thoughts: IThoughtItem[] } => ({
  adds: (picks ?? []).reduce(
    (acc: { user: UserObjectType; id: string; text: string }[], { user, id }) =>
      user ? [...acc, { user, id, text: '' }] : acc,
    []
  ),
  thoughts: (comments?.items ?? []).reduce(
    (acc: IThoughtItem[], comment) =>
      comment.user
        ? [
            ...acc,
            {
              user: comment.user,
              id: comment.id,
              text: comment.text ? `"${comment.text}"` : '',
              lastUpdate: getWeeklyDateFormat(comment.createdAt),
              responseToQuestion: comment?.responseToQuestion,
              guestListQuestion: comment?.guestListQuestion,
              responseToSpark: comment?.responseToSpark,
              reactions: comment?.reactions,
              highlighted: comment?.highlighted,
            },
          ]
        : acc,
    []
  ),
});

export const getThoughtsCount = (
  filter: CardCommentFilterOptionsEnum,
  thoughtsTotalCounts: IThoughthTotalCounts
): number | null => {
  switch (filter) {
    case CardCommentFilterOptionsEnum.Default:
      return thoughtsTotalCounts.everyoneTotal;
    case CardCommentFilterOptionsEnum.Following:
      return thoughtsTotalCounts.followingTotal;
    case CardCommentFilterOptionsEnum.Highlighted:
      return thoughtsTotalCounts.highlightsTotal;
    default:
      return null;
  }
};
