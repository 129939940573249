import { StyledInputWrapper } from 'components/AddContent/AddPick/styled';
import Modal from 'components/Modal';
import ContentTitle from 'components/UI/ContentTitle';
import TextInput from 'components/UI/TextInput';
import useDisableScroll from 'helpers/useDisableScroll';
import React, { FC, useState } from 'react';
import { useReportStyles } from '../hooks';

const ReportOther: FC<{ cardId: string; isSpark?: boolean }> = ({ cardId, isSpark }) => {
  const [comment, setComment] = useState('');

  const { handleSubmitReport, closeReportStyleModal, handleReplaceModal } = useReportStyles({
    cardId,
    reportComment: comment,
    isSpark,
  });

  useDisableScroll();

  return (
    <Modal
      buttonText="Submit"
      handlePrev={handleReplaceModal}
      handleDone={() => handleSubmitReport(undefined)}
      handleClose={closeReportStyleModal}
      customCloseText="Cancel"
      disabled={!comment}
    >
      <StyledInputWrapper>
        <ContentTitle isDark>Can you describe what you are experiencing?</ContentTitle>
        <TextInput inputHeight={143} setText={setComment} text={comment} />
      </StyledInputWrapper>
    </Modal>
  );
};

export default ReportOther;
