import { styled } from 'linaria/react';
import { IGetMenuCoords } from './models';

import { COLORS, FONTS } from '../../../styles/constants';

export const StyledMenu = styled.ul<{ isDark: boolean; isMine: boolean; coords: IGetMenuCoords }>`
  backdrop-filter: blur(3px);
  background: ${({ isDark }) => (isDark ? 'rgba(24, 24, 24, 0.7)' : 'rgba(237, 237, 237, 0.8)')};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  left: ${({ coords }) => (coords.left ? `${coords.left}px` : 'unset')};
  list-style: none;
  margin: 0;
  padding: 4px 0;
  position: absolute;
  right: ${({ coords }) => (coords.right ? `${coords.right}px` : 'unset')};
  top: ${({ coords }) => `${coords.bottom}`}px;
  width: 248px;
`;

export const StyledMenuItem = styled.li<{ isRed?: boolean; isSelected?: boolean; isDark?: boolean }>`
  box-sizing: border-box;
  color: ${({ isRed }) => (isRed ? COLORS.red[100] : 'unset')};
  cursor: pointer;
  font: 400 17px/22px ${FONTS.appleDevises}, ${FONTS.secondary};
  padding: 11px 16px 11px 39px;
  position: relative;
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(60, 60, 67, 0.18);
  }

  &::before {
    content: ${({ isSelected }) => (isSelected ? '""' : 'none')};
    background-image: ${({ isDark }) =>
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill='%23${
        isDark ? 'fff' : '000'
      }' d='M6.3 15.5c.6 0 1-.2 1.3-.6L15.3 3c.3-.3.3-.6.3-.9 0-.8-.5-1.3-1.3-1.3-.6 0-1 .2-1.2.7L6.3 12.2 2.8 7.9c-.3-.3-.6-.5-1-.5C.8 7.4.2 8 .2 8.7c0 .4.1.7.4 1L5 15c.4.4.8.6 1.3.6Z'/%3E%3C/svg%3E")`};
    height: 16px;
    left: 14px;
    position: absolute;
    top: 13px;
    width: 16px;
  }
`;

export const StyledAbsWrap = styled.div`
  position: absolute;
  inset: 0;
`;

export const StyledBlurArea = styled.div<{ isDark: boolean }>`
  position: fixed;
  inset: 0;
  backdrop-filter: blur(5px);
  background: ${({ isDark }) => (isDark ? COLORS.black[50] : 'rgba(249, 249, 249, 0.86)')};
`;

export const StyledBlurWrap = styled.div<{ isDark: boolean }>`
  background: ${({ isDark }) => (isDark ? COLORS.black[50] : 'rgba(249, 249, 249, 0.86)')};
  pointer-events: none;
  user-select: none;
  inset: 0;
  position: fixed;
  z-index: -1;
  backdrop-filter: blur(5px);
`;
