import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import Avatars from 'components/UI/Avatars/Avatars';
import getAuthUser from 'helpers/getAuthUser';
import useOpenModal from 'helpers/useOpenModal';
import { transformRoute } from 'helpers/routingHelper';
import { UserCardRoleListObjectType, Maybe, ECardRoleTypeOptions, UserObjectType } from 'constants/graphqlTypes';
import { ROUTE_LIST_EDIT_COLLABORATORS, ROUTE_LIST_EDIT_DESCRIPTION } from 'routes';
import { StyledWrap, StyledButton, StyledUserInfo } from './styled';
import ListCreatorsText from './ListCreatorsText';

import { StyledPlaceholder, StyledThought } from '../ListDescription/styled';

interface IListCollabDescription {
  listCardId: string;
  description?: string | null;
  isDark: boolean;
  creators?: Maybe<UserCardRoleListObjectType>;
}

const ListCollabDescription: FC<IListCollabDescription> = ({ listCardId, creators, isDark, description }) => {
  const { listId } = useParams<{ listId: string }>();
  const { items, totalCount } = creators ?? {};
  const { userId: profileId } = getAuthUser();
  const openCollaborators = useOpenModal(transformRoute(ROUTE_LIST_EDIT_COLLABORATORS, { listId }), true, {
    fromAvatars: true,
    listCardId,
  });

  const toggle = useOpenModal(transformRoute(ROUTE_LIST_EDIT_DESCRIPTION, { listId }), false, {
    description: description ?? '',
    isSingleModal: true,
    listCardId,
  });

  const hasPermission = items?.find(
    ({ user, roleName }) => user?.id === profileId && roleName === ECardRoleTypeOptions.Owner
  );
  const users =
    items
      ?.filter(
        (item) => item.roleName !== ECardRoleTypeOptions.Invited && item.roleName !== ECardRoleTypeOptions.Viewer
      )
      ?.reduce((acc: UserObjectType[], creator) => (creator?.user ? acc.concat(creator.user) : acc), []) ?? [];

  return creators?.items.length ? (
    <StyledWrap>
      <StyledButton onClick={openCollaborators}>
        <Avatars isBig users={users} limit={6} total={totalCount ?? 0} />
      </StyledButton>
      <StyledUserInfo onClick={openCollaborators}>
        <ListCreatorsText users={users} totalCount={users.length} isDark={isDark} />
      </StyledUserInfo>
      {description && <StyledThought isDark={isDark}>&quot;{description}&quot;</StyledThought>}
      {!description && hasPermission && (
        <StyledPlaceholder isDark={isDark} onClick={() => toggle?.()}>
          Tap to add a thought to your list and let others know what you think
        </StyledPlaceholder>
      )}
    </StyledWrap>
  ) : null;
};

export default ListCollabDescription;
