import { ESearchTag } from 'components/Search/helpers/constants';

export const getSearchValue = (inputValue: string, isItemSelected: boolean): string => {
  const userSearchTag = ESearchTag.User;
  const isUserSearch = inputValue.startsWith(userSearchTag);
  if (!isUserSearch || isItemSelected) {
    return inputValue;
  }

  return inputValue.slice(userSearchTag.length);
};
