import React, { FC, useEffect, useState } from 'react';
import InviteUserSharing from './InviteUserSharing';

const ShareSheet: FC = () => {
  const [animationData, setAnimationData] = useState<unknown>();

  useEffect(() => {
    fetch('/mockedData/animationBackground.json')
      .then((data) => data.json())
      .then((data) => setAnimationData(data));
  }, []);

  return animationData ? <InviteUserSharing animationData={animationData} /> : null;
};

export default ShareSheet;
