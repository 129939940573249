import React, { FC, useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import Card from 'components/Card';
import SliderInteractive from 'components/SliderInteractive';

import { COLORS } from 'styles/constants';
import { CardSchema } from 'constants/graphqlTypes';
import { StyledCardWrap, StyledContainer } from './styled';

const PickSlider: FC<{
  items: CardSchema[];
  isDark: boolean;
  isInfinite?: boolean;
  children?: JSX.Element;
  onWaypointEnter?: () => void;
}> = ({ items, isDark, isInfinite = true, children, onWaypointEnter }) => {
  const [slideIndex, setSlideInd] = useState(0);

  useEffect(() => {
    if (items.length && slideIndex === items.length - (children ? 2 : 3) && !isInfinite) {
      onWaypointEnter?.();
    }
  }, [slideIndex]);

  return items.length === 1 && items?.[0] ? (
    <StyledCardWrap>
      <Card card={items[0]} isDark={isDark} showActivity />
    </StyledCardWrap>
  ) : (
    <StyledContainer>
      <SliderInteractive
        onSlideChange={(ind) => setSlideInd(ind)}
        customSlidesPerView={2.25}
        spaceBetween={13}
        fontColor={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
        showBtnsSm={!!items.length}
        offset={12}
        isInfinite={isInfinite}
        isPrevDisabled={!isInfinite && slideIndex === 0}
        isNextDisabled={!isInfinite && slideIndex === items.length - (children ? 1 : 2)}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <Card card={item} isDark={isDark} showActivity />
          </SwiperSlide>
        ))}
        {children && <SwiperSlide>{children}</SwiperSlide>}
      </SliderInteractive>
    </StyledContainer>
  );
};

export default PickSlider;
