import { styled } from 'linaria/react';

import { StyledTag as ImportTag } from 'components/Card/Common/styled';
import { BREAKPOINTS } from 'styles/constants';

export const StyledTag = styled(ImportTag)`
  text-transform: none;
`;

export const StyledWrap = styled.div`
  padding: 0 12px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0;
  }
`;

export const StyledButtonWrap = styled.div`
  padding: 24px 0 12px;

  button {
    backdrop-filter: blur(2px);
  }
`;
