import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS } from '../../../styles/constants';

export const StyledLabel = styled.label`
  align-items: center;
  border-top: 1px solid ${COLORS.white[30]};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  left: 24px;
  margin-top: auto;
  padding: 16px 0 var(--keyboard-offset);

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(14px * ${BREAKPOINTS.retinaScale});
    padding: calc(16px * ${BREAKPOINTS.retinaScale}) 0;
  }
`;

export const StyledInputWrapper = styled.div`
  padding: 32px 0 8px;
`;
