import { styled } from 'linaria/lib/react';
import { FONTS, COLORS } from 'styles/constants';

export const StyledVideoWrap = styled.div`
  height: 447px;
  border-radius: 8px;
  margin: 0 auto 138px;
  width: 252px;
  overflow: hidden;

  video {
    border-radius: 8px;
  }
`;

export const StyledTitle = styled.p`
  color: ${COLORS.white[100]};
  font: 400 22px/130% ${FONTS.primary};
  text-align: center;
`;

export const StyledInner = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 93px 12px 0;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  * {
    position: relative;
  }
`;
