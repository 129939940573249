import { styled } from 'linaria/react';
import { StyledWrap } from 'components/UI/OnboardingAlert/styled';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledWrapper = styled.div`
  padding-top: 60px;
  position: relative;
  min-height: 100vh;

  nav {
    padding-right: 24px;
  }
`;

export const StyledTutorialWrapper = styled.div`
  align-items: center;
  background-color: ${COLORS.black[40]};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  max-height: 100dvh;
  opacity: 1;
  padding-top: 12px;
  position: fixed;
  right: 0;
  top: 0;
  transition: '0.2s opacity ease-in-out';
  z-index: 2000;

  @media screen and (max-width: ${BREAKPOINTS.s}px) {
    align-items: flex-end;
  }

  ${StyledWrap} {
    max-width: 366px;
    padding: 21px 24px;

    @media screen and (max-width: ${BREAKPOINTS.s}px) {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      max-width: 100%;
    }
  }

  button {
    margin: 12px 0;
    width: 100%;
  }
`;

export const StyledTutorialHeader = styled.div`
  font: 22px/130% ${FONTS.primary};
`;

export const StyledButton = styled.button`
  align-items: center;
  background-color: ${COLORS.brown.dark[100]};
  border: none;
  border-radius: 100px;
  box-shadow: 0px 11px 6px ${COLORS.black[10]}, 0px 5px 5px ${COLORS.black[20]}, 0px 1px 3px ${COLORS.black[20]},
    0px 0px 0px ${COLORS.black[20]};
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 58px;
  padding: 0;
  width: 58px;

  path {
    stroke: ${COLORS.white[100]};
  }
`;

export const StyledBottomMenu = styled.div<{ bottom?: number }>`
  bottom: ${({ bottom }) => bottom || 42}px;
  display: flex;
  left: 50%;
  max-width: calc(100% - 24px);
  position: fixed;
  transform: translateX(-50%);
  width: max-content;
  z-index: 100;

  span {
    padding: 0;
  }
`;

export const StyledCardsWrapper = styled.div`
  margin: 16px auto 166px;
  max-width: ${BREAKPOINTS.s}px;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
