import { styled } from 'linaria/react';

import { StyledInfoContainer, StyledFullName } from 'components/Profile/ProfileTop/styled';

export const StyledContainer = styled(StyledInfoContainer)`
  padding-left: 0;
  padding-right: 0;
`;

export const StyledName = styled(StyledFullName)`
  padding-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
