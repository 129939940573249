import { ETypeOfCard, IHandleTypeOfCard, IHandleTypeOfCardParams } from './models';

export const handleTypeOfCard = ({
  handleRemoveFromAllPicks,
  handleRemoveFromList,
  isAdmin,
  pickersCount,
  typeOfCard,
  isCardPickedByCollaborators,
  setTypeOfCard,
  isDeletedPicker,
  hasMyPick,
}: IHandleTypeOfCardParams): IHandleTypeOfCard => {
  if (typeOfCard === ETypeOfCard.IsAllPicks) {
    return {
      subTitle: 'This action removes this pick from all picks and your profile',
      title: 'remove pick?',
      handleRemove: handleRemoveFromAllPicks,
      buttonText: 'remove',
      cancelButtonText: 'keep',
    };
  }
  if (typeOfCard === ETypeOfCard.IsPrivateList) {
    return {
      subTitle: 'This action removes this pick from your profile',
      title: 'remove pick?',
      handleRemove: () => handleRemoveFromList(false, false, false),
      buttonText: 'remove',
      cancelButtonText: 'keep',
    };
  }
  if (typeOfCard === ETypeOfCard.IsAllOptions) {
    return {
      subTitle: `You can remove this pick from the current list${
        isDeletedPicker && !hasMyPick ? '' : ' or from everywhere on your profile'
      }`,
      handleRemove: () => handleRemoveFromList(false, false, false),
      handleRemoveSecondOption: isDeletedPicker ? undefined : () => handleRemoveFromList(true, false, false),
      buttonText: 'remove from list',
      secondButtonText: isDeletedPicker ? undefined : 'remove from all picks and my lists',
    };
  }
  if (typeOfCard === ETypeOfCard.IsCollabListWithOptions) {
    return {
      subTitle: 'You can remove your contribution or all activity from fellow collaborators',
      handleRemove: () => handleRemoveFromList(false, false, isCardPickedByCollaborators),
      handleRemoveSecondOption: () => setTypeOfCard(ETypeOfCard.IsCollabList),
      buttonText: 'remove your pick',
      secondButtonText: 'remove pick for all users',
    };
  }
  if (typeOfCard === ETypeOfCard.IsCollabList && isAdmin) {
    return {
      subTitle: `This action will remove the pick and any thoughts contributed from ${pickersCount} user${
        pickersCount > 1 ? 's' : ''
      } in this list.`,
      title: 'remove pick for all users?',
      handleRemove: () => handleRemoveFromList(false, true, false),
      buttonText: `remove pick for ${pickersCount} user${pickersCount > 1 ? 's' : ''}`,
    };
  }
  return {
    subTitle: `This action will remove your pick (and thought, if you’ve added one) from this list`,
    title: 'remove pick?',
    handleRemove: () => handleRemoveFromList(false, false, isCardPickedByCollaborators),
    buttonText: 'remove pick',
  };
};
