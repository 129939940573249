import handleVibrate from './handleVibrate';

const withHapticFeedback = (handler: () => void): (() => void) => {
  return () => {
    handler();
    handleVibrate();
  };
};

export default withHapticFeedback;
