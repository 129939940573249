import React from 'react';
import ReactDOM from 'react-dom';
import { History } from 'history';
import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';

import ConfirmationModal from 'components/UI/ConfirmationModal';
import { ROUTE_UNAUTHORIZED, ROUTE_UNKNOWN_ERROR } from 'routes';

export type GQLErrorWithStatusCode = GraphQLError & { statusCode?: number };

export const FIXED_GLOBAL_ERROR_CSS_CLASS = 'fixed-by-global-error';

export const getErrorHandler = (history: History): ApolloLink =>
  onError(({ graphQLErrors }) => {
    graphQLErrors?.forEach((error: GQLErrorWithStatusCode) => {
      const errorCode = error?.statusCode;

      if (errorCode === 401) {
        localStorage.clear();
        history.push(ROUTE_UNAUTHORIZED);
        return;
      }

      if (errorCode === 500 || errorCode === 502) {
        history.push(ROUTE_UNKNOWN_ERROR);
      }

      if (errorCode === 423) {
        const root = document.createElement('div');
        document.body.appendChild(root);
        const handleHide = () => {
          root.remove();
          document.body.classList.remove(FIXED_GLOBAL_ERROR_CSS_CLASS);
          document.documentElement.classList.remove(FIXED_GLOBAL_ERROR_CSS_CLASS);
        };

        document.body.classList.add(FIXED_GLOBAL_ERROR_CSS_CLASS);
        document.documentElement.classList.add(FIXED_GLOBAL_ERROR_CSS_CLASS);
        ReactDOM.render(
          <ConfirmationModal
            ignoreUseModal
            isWarning={false}
            buttonText="Okay"
            handleClickOutside={handleHide}
            handleAccept={handleHide}
            titleText="Locked"
            subTitle="Sorry, you can’t do this at the moment. Your pick is currently featured, and therefore edits are locked. If you need help, please contact support at help@guidehuman.co"
          />,
          root
        );
      }
    });
  });
