import { styled } from 'linaria/react';
import StyledButton from '../UI/Button/styled';

import { BREAKPOINTS, COLORS } from '../../styles/constants';

export const StyledWrap = styled.div<{ isOpen: boolean; hasTransition: boolean }>`
  align-items: center;
  background-color: ${COLORS.black[25]};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  max-height: 100dvh;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  padding-top: 12px;
  position: fixed;
  right: 0;
  top: 0;
  transition: ${({ hasTransition }) => (hasTransition ? '0.2s opacity ease-in-out' : 'none')};
  z-index: 2000;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    backdrop-filter: blur(12px);
    flex-flow: column;
    padding: 12px;
  }
`;

export const StyledModal = styled.div<{ isOpen: boolean; bgColor?: string; hasTransition: boolean }>`
  background: ${({ bgColor }) => bgColor || COLORS.brown.dark[100]};
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 518px;
  padding: 24px 12px;
  position: relative;
  transform: ${({ isOpen }) => (isOpen ? 'none' : 'translateY(30%)')};
  transition: ${({ hasTransition }) => (hasTransition ? '0.2s transform ease-in-out' : 'none')};
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    border-radius: 12px;
    height: auto;
    min-height: 250px;
    padding: 24px 28px;
  }

  @media screen and (min-width: ${BREAKPOINTS.l}px) {
    max-width: unset;
    width: 548px;
  }

  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    width: 692px;
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    border-radius: calc(12px * ${BREAKPOINTS.retinaScale});
    padding: calc(24px * ${BREAKPOINTS.retinaScale}) calc(28px * ${BREAKPOINTS.retinaScale});
    width: calc(692px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledHeader = styled.header<{ isReverse?: boolean; withImage?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: ${({ isReverse }) => (isReverse ? 'row-reverse' : 'row')};
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
`;

const getSvgColor = (isDark?: boolean, color?: string) => {
  if (color) {
    return `%23${color.slice(1)}`;
  }
  return isDark ? '%23fff' : '%23181800';
};

export const StyledCloseButton = styled.button<{ isPrev: boolean; isDark?: boolean; color?: string }>`
  align-items: center;
  background-color: ${COLORS.transparent};
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 44px;
  margin-right: auto;
  width: 44px;

  &::after {
    background-image: ${({ isPrev, isDark, color }) =>
      isPrev
        ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath d='m16 4.2-8 8 8 8' stroke='${getSvgColor(
            isDark,
            color
          )}' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")`
        : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17' fill='none'%3E%3Cpath stroke='${getSvgColor(
            isDark,
            color
          )}' stroke-width='1.5' stroke-linecap='round' d='m1.8.8 14.4 14.4M1.8 15.2 16.2.8'/%3E%3C/svg%3E")`};
    content: '';
    height: ${({ isPrev }) => (isPrev ? 24 : 17)}px;
    width: ${({ isPrev }) => (isPrev ? 24 : 17)}px;
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    height: calc(${({ isPrev }) => (isPrev ? 24 : 17)}px * ${BREAKPOINTS.retinaScale});
    width: calc(${({ isPrev }) => (isPrev ? 24 : 17)}px * ${BREAKPOINTS.retinaScale});

    &::after {
      transform: scale(${BREAKPOINTS.retinaScale});
    }
  }
`;

export const StyledCustomCloseButtonWrapper = styled.div`
  margin-right: auto;
  margin: 0 auto 0 -12px;
`;

export const StyledContent = styled.section<{ enableInnerScroll?: boolean; customContentPadding?: string }>`
  color: ${COLORS.white[100]};
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden ${({ enableInnerScroll }) => (enableInnerScroll ? 'auto' : 'hidden')};
  padding: ${({ customContentPadding }) => customContentPadding ?? '0 12px'};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledSelectButton = styled(StyledButton)<{ isOpen?: boolean }>`
  align-items: center;
  display: inline-flex;
  gap: 4px;
  padding: 0 20px;
  text-transform: capitalize;

  svg {
    transform: rotate(${({ isOpen }) => (isOpen ? 90 : -90)}deg);
    transition: 0.15s transform ease-in-out;

    path {
      fill: ${COLORS.white[100]};
    }
  }
`;
