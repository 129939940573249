import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import Button from 'components/UI/Button';
import OnboardingAlert from 'components/UI/OnboardingAlert';

import { ETutorialStatus } from 'constants/graphqlTypes';
import { TEST_SPOTLIGHT_ITEM } from 'constants/aqa/addPick';
import { IUseSpotlights } from 'graphQL/spotlights/hook';
import { useUpdateUserSettings, useUserSettings } from 'graphQL/contextualIntro/hooks';
import { useGetSearchParam, useRemoveSearchParam } from 'helpers/routingHelper';
import SliderInteractive from 'components/SliderInteractive';
import { EUiButtonType } from 'components/UI/Button/constants';
import useWindowResize from 'helpers/useWindowResize';

import { StyledCardWrap } from 'components/Sliders/styled';
import { BREAKPOINTS, COLORS } from '../../styles/constants';
import { StyledSlide, StyledHeader, StyledWrapper, StyledSliderContainer, StyledAlertWrap } from './styled';

import Card from './Card';
import Modal from './Modal/Modal';

interface ISpotlightsProps {
  setIndex: Dispatch<SetStateAction<number>>;
  fontColor: string;
  spotlightsData: IUseSpotlights;
  hiddenCards: string[];
  setHiddenCards: Dispatch<SetStateAction<string[]>>;
}

const Spotlights: FC<ISpotlightsProps> = ({ setIndex, spotlightsData, fontColor, hiddenCards, setHiddenCards }) => {
  const { title, showSelectPicksBtn, titleSelectPicksBtn, hasSpotlights, cards } = spotlightsData;
  const isSpotlightsOpen = useGetSearchParam('isSpotlightsOpen');
  const removeIsSpotlightsOpen = useRemoveSearchParam('isSpotlightsOpen');
  const { isSpotlightIntroCompleted, loading, tutorialStatus } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings({ isSpotlightIntroCompleted: true });
  const [shouldPauseAutoplay, setShouldPauseAutoplay] = useState(false);

  const [isModal, setIsModal] = useState(false);
  const [isAnimationStarted, setAnimationStarted] = useState(false);
  const hideReactionModal = () => {
    setAnimationStarted(() => {
      setTimeout(() => {
        setAnimationStarted(false);
      }, 50);

      return true;
    });
  };

  const isSpotlightsIntro =
    !isSpotlightIntroCompleted &&
    !loading &&
    !hasSpotlights &&
    showSelectPicksBtn &&
    tutorialStatus === ETutorialStatus.Finished;

  const handleCloseSpotlightModal = () => {
    if (isSpotlightsOpen) {
      removeIsSpotlightsOpen();
    }
    setIsModal(false);
  };

  useEffect(() => {
    if (isSpotlightsOpen) {
      setIsModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSlidesPerView = () => (window.innerWidth < BREAKPOINTS.s ? 1.2 : 1);
  const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView());

  useWindowResize(() => setSlidesPerView(getSlidesPerView()));

  const [slideIndex, setSlideIndex] = useState(0);
  const [autoplayOptions, setAutoplayOptions] = useState({
    delay: 3000,
    disableOnInteraction: true,
    stopOnLastSlide: true,
    reverseDirection: false,
  });

  const changeSlidesDirection = (isReverse: boolean) =>
    setAutoplayOptions((options) => ({ ...options, delay: 4000, reverseDirection: isReverse }));

  const changeOptions = (index: number) => {
    if (index === cards.length - 1) {
      changeSlidesDirection(true);
    } else if (index === 0) {
      changeSlidesDirection(false);
    }
    setSlideIndex(index);
  };

  return cards.length ? (
    <StyledWrapper>
      <StyledHeader>
        <span>{title}</span>
        {showSelectPicksBtn && (
          <>
            <Button
              type={EUiButtonType.Primary}
              handler={() => setIsModal(true)}
              text={titleSelectPicksBtn}
              isDark={fontColor === COLORS.white[100]}
            />
            {isModal && (
              <Modal handleClose={handleCloseSpotlightModal} existingSpotlights={hasSpotlights ? cards : []} />
            )}
          </>
        )}
      </StyledHeader>
      {isSpotlightsIntro && (
        <StyledAlertWrap>
          <OnboardingAlert
            title="Choose picks to Spotlight"
            submitText="Got it"
            handleSubmit={() => updateUserSettings()}
          >
            Pin your favorite picks to the top of your profile
          </OnboardingAlert>
        </StyledAlertWrap>
      )}
      <div id="spotlight-container">
        {cards.length === 1 ? (
          <StyledCardWrap hidePaddingTop>
            <StyledSlide>
              <Card
                dataTestId={`${TEST_SPOTLIGHT_ITEM}-0`}
                card={cards[0]}
                fontColor={fontColor}
                isHidden={hiddenCards.includes(cards[0].id)}
                setHiddenCards={setHiddenCards}
              />
            </StyledSlide>
          </StyledCardWrap>
        ) : (
          <StyledSliderContainer>
            <SliderInteractive
              arrowsOffset={150}
              showBtnsSm
              spaceBetween={13}
              customSlidesPerView={slidesPerView}
              onSlideChange={(index) => {
                setIndex(index);
                setAnimationStarted(false);
                changeOptions(index);
              }}
              fontColor={fontColor}
              offset={12}
              onTransitionStart={hideReactionModal}
              onAnimationStart={hideReactionModal}
              autoplayOptions={autoplayOptions}
              isInfinite={false}
              isPrevDisabled={slideIndex === 0}
              isNextDisabled={slideIndex === cards.length - 1}
              shouldPauseAutoplay={shouldPauseAutoplay}
            >
              {cards.map((card, index) => (
                <SwiperSlide key={card.id}>
                  <StyledSlide>
                    <Card
                      dataTestId={`${TEST_SPOTLIGHT_ITEM}-${index}`}
                      card={card}
                      fontColor={fontColor}
                      isAnimationStarted={isAnimationStarted}
                      isHidden={hiddenCards.includes(card.id)}
                      setHiddenCards={setHiddenCards}
                      setShouldPauseAutoplay={setShouldPauseAutoplay}
                    />
                  </StyledSlide>
                </SwiperSlide>
              ))}
            </SliderInteractive>
          </StyledSliderContainer>
        )}
      </div>
    </StyledWrapper>
  ) : null;
};

export default Spotlights;
