import { useContext } from 'react';

import { ThemeContext } from 'providers/helpers/contexts';

const useTheme = (): boolean => {
  const isDark = useContext(ThemeContext);

  return isDark;
};

export default useTheme;
