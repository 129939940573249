import { styled } from 'linaria/react';

import { StyledImageWrap as CustomStyledImageWrap, StyledFooter } from 'components/Card/Common/styled';
import { FONTS, BREAKPOINTS } from '../../../styles/constants';

export const StyledWrapper = styled.div<{ isPlainCard: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 0 25px ${({ isPlainCard }) => (isPlainCard ? '12px' : '0')};
  position: relative;
  width: 100%;
`;

export const StyledContainer = styled.div<{ isPlainCard: boolean; borderColor: string }>`
  color: inherit;
  box-sizing: border-box;
  border: ${({ isPlainCard, borderColor }) => (isPlainCard ? `1px solid ${borderColor}` : 'none')};
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: ${({ isPlainCard }) => (isPlainCard ? '12px' : '16px')};

  > a {
    padding-left: ${({ isPlainCard }) => (isPlainCard ? '0' : '12px')};

    @media screen and (min-width: ${BREAKPOINTS.m}px) {
      padding-left: 0;
    }
  }
`;

export const StyledInner = styled.div`
  position: relative;
`;

export const StyledInfo = styled.div<{ isPlainCard: boolean }>`
  padding: ${({ isPlainCard }) => `${isPlainCard ? '24px' : '16px'} 12px ${isPlainCard ? '6px' : 0}`};
  display: flex;
  flex-direction: column;
  gap: 6px;
  font: 350 12px/140% ${FONTS.secondary};
`;

export const StyledImageWrap = styled(CustomStyledImageWrap)`
  border-radius: 8px;
  margin: 0;
  width: 100%;
`;

export const StyledImage = styled.img`
  border-radius: 8px;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const StyledTag = styled.span`
  font-weight: 400;
  text-transform: capitalize;
`;

export const StyledTitle = styled.span<{ isPlainCard: boolean }>`
  font: 400 ${({ isPlainCard }) => (isPlainCard ? 22 : 14)}px / 130% ${FONTS.primary};
`;

export const StyledSpotLightFooter = styled(StyledFooter)<{ isPlainCard?: boolean }>`
  padding: ${({ isPlainCard }) => (isPlainCard ? 10 : 2)}px 0
    ${({ isPlainCard }) => (isPlainCard ? '94px 12px' : '0 12px')};

  .action-user-info {
    display: none;
  }

  .hide-padding {
    padding: 0;
  }
`;
