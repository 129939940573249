import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import {
  CheckOnboardingStatusQuery,
  EOnboardingStepOptions,
  useCheckOnboardingStatusQuery,
  UserObjectType,
  UserSettingsSchema,
  useUpdateUserMutation,
  CheckOnboardingStatusDocument,
  useGetUsersForOnboardingQuery,
  ENewOnboardingStepOptions,
  useGetSuggestedUserPicksQuery,
} from 'constants/graphqlTypes';
import { ROUTE_ONBOARDING } from 'routes';
import config from 'constants/config';
import { EQueryFetchPolicy, OFF_PLATFORM_SHARING_REDIRECT_STORAGE_KEY } from 'constants/common';

import getAuthUser from 'helpers/getAuthUser';
import { getOnboardingRedirectPath } from 'components/Onboarding/helpers';
import { getNewOnboardingRedirectPath } from 'components/OnboardingV2/ProfileSetup/helpers';
import { IUseGetSuggestedUserPicksArgs } from './models';

interface IUseCheckOnboardingStatus {
  shouldRedirectFromVerification: boolean;
  shouldRedirectToOnboarding: boolean;
  shouldRedirectFromOnboarding: boolean;
  onboardingRedirectStep: string | null;
  user?: UserObjectType;
  isQueryCalled: boolean;
  loading: boolean;
}

export const useCheckOnboardingStatus = (fetchPolicy?: EQueryFetchPolicy): IUseCheckOnboardingStatus => {
  const { areInviteCodesDisabled, isNewOnboardingEnabled } = config;
  const { userId: id } = getAuthUser();
  const { pathname } = useLocation();

  const [isQueryCalled, setIsQueryCalled] = useState(false);

  const { data, loading } = useCheckOnboardingStatusQuery({
    variables: { id },
    skip: !id,
    fetchPolicy,
    onCompleted: () => setIsQueryCalled(true),
  });

  const shareLink = localStorage.getItem(OFF_PLATFORM_SHARING_REDIRECT_STORAGE_KEY);

  const user = data?.allUsers?.items[0];
  const { isOnboardingCompleted, onboardingStep, newOnboardingStep } = user?.userSettings ?? {};
  const shouldRedirectToOnboarding =
    !!id && isOnboardingCompleted === false && !loading && !pathname.includes(ROUTE_ONBOARDING);
  const shouldRedirectFromOnboarding =
    !!id && !!isOnboardingCompleted && pathname.includes(ROUTE_ONBOARDING) && !shareLink;
  const shouldRedirectFromVerification =
    (!!id && !isOnboardingCompleted && !!user?.activatedAt) || areInviteCodesDisabled;

  const getOnboardingRedirectStep = (): string | null => {
    if (isNewOnboardingEnabled) {
      return newOnboardingStep ? getNewOnboardingRedirectPath(newOnboardingStep) : null;
    }
    return onboardingStep ? getOnboardingRedirectPath(onboardingStep) : null;
  };

  return {
    shouldRedirectFromVerification,
    shouldRedirectToOnboarding,
    shouldRedirectFromOnboarding,
    onboardingRedirectStep: getOnboardingRedirectStep(),
    user,
    isQueryCalled,
    loading,
  };
};

export const useUpdateOnboardingStep = (onboardingStep: EOnboardingStepOptions) => {
  const [updateUser] = useUpdateUserMutation();
  const client = useApolloClient();
  const { userId } = getAuthUser();

  const prevData: CheckOnboardingStatusQuery | null = client.readQuery({
    query: CheckOnboardingStatusDocument,
    variables: { id: userId },
  });

  const cachedStep = prevData?.allUsers?.items[0]?.userSettings?.onboardingStep;
  useEffect(() => {
    if (cachedStep === onboardingStep) {
      updateUser({
        variables: {
          onboardingStep,
        },
        fetchPolicy: EQueryFetchPolicy.NoCache,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedStep]);
};

export const useUpdateOnboardingStepV2 = (
  newOnboardingStep: ENewOnboardingStepOptions,
  preventEffect?: boolean
): { update: () => void; updatedUser?: null | UserObjectType } => {
  const [updatedUser, setUpdatedUser] = useState<UserObjectType | null>(null);
  const [updateUser] = useUpdateUserMutation();
  const client = useApolloClient();
  const { userId } = getAuthUser();

  const prevData: CheckOnboardingStatusQuery | null = client.readQuery({
    query: CheckOnboardingStatusDocument,
    variables: { id: userId },
  });

  const cachedStep = prevData?.allUsers?.items[0]?.userSettings?.newOnboardingStep;

  const update = () => {
    if (cachedStep === newOnboardingStep) {
      updateUser({
        variables: {
          newOnboardingStep,
        },
        fetchPolicy: EQueryFetchPolicy.NoCache,
      }).then((res) => setUpdatedUser(res.data?.updateUser ?? null));
    }
  };

  useEffect(() => {
    if (!preventEffect) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedStep]);

  return { update, updatedUser };
};

export const useUpdateCachedOnboardingStep = (onboardingStep: EOnboardingStepOptions): (() => void) => {
  const { cache } = useApolloClient();
  const { userId } = getAuthUser();

  const updateOnboardingStepLocally = (step: EOnboardingStepOptions) => {
    cache.modify({
      id: cache.identify({ __typename: 'UserObjectType', id: userId }),
      fields: {
        userSettings: (prev: UserSettingsSchema) => ({
          ...prev,
          onboardingStep: step,
        }),
      },
    });
  };

  return () => updateOnboardingStepLocally(onboardingStep);
};

export const useGetUsersForOnboarding = () => {
  const { data } = useGetUsersForOnboardingQuery();

  return data?.onboardingNetworking ?? [];
};

export const useUpdateCachedOnboardingStepV2 = (onboardingStep: ENewOnboardingStepOptions) => {
  const { cache } = useApolloClient();
  const { userId } = getAuthUser();

  const updateOnboardingStepLocally = (newOnboardingStep: ENewOnboardingStepOptions) => {
    cache.modify({
      id: cache.identify({ __typename: 'UserObjectType', id: userId }),
      fields: {
        userSettings: (prev: UserSettingsSchema) => ({
          ...prev,
          newOnboardingStep,
        }),
      },
    });
  };

  return () => updateOnboardingStepLocally(onboardingStep);
};

export const useGetSuggestedUserPicks = ({ userId, customSkip }: IUseGetSuggestedUserPicksArgs) => {
  const { token } = getAuthUser();
  const { data, loading } = useGetSuggestedUserPicksQuery({
    variables: { id: userId },
    skip: !userId || !token || customSkip,
  });

  const userPicks = data?.allUsers?.items?.[0]?.previewProfilePicks?.items ?? [];
  const isSpotlight = data?.allUsers?.items?.[0]?.previewProfilePicks?.isSpotlight ?? false;

  return { userPicks, isSpotlight, loading };
};
