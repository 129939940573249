import React, { cloneElement, FC, useState } from 'react';

import ConfirmationModal from 'components/UI/ConfirmationModal';
import { useDeleteChat, useLeaveGroupChat } from 'graphQL/messages/hooks';

interface ILeaveGroupChatButtonProps {
  handleAccept?: () => void;
  handleCancel?: () => void;
  chatId: string;
  isGroupChat: boolean;
  children: JSX.Element;
}

const LeaveGroupChatButton: FC<ILeaveGroupChatButtonProps> = ({
  chatId,
  handleAccept,
  handleCancel,
  children,
  isGroupChat,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const leaveGroupChat = useLeaveGroupChat(chatId);
  const deleteSingleChat = useDeleteChat(chatId);

  const handleLeaveGroup = () => {
    if (isGroupChat) {
      leaveGroupChat();
    } else {
      deleteSingleChat();
    }
    setIsOpen(false);
    handleAccept?.();
  };

  return children ? (
    <>
      {cloneElement(children, { onClick: () => setIsOpen(true), handler: () => setIsOpen(true) })}
      {isOpen && (
        <ConfirmationModal
          buttonText={isGroupChat ? 'Leave' : 'Delete'}
          handleAccept={handleLeaveGroup}
          handleCancel={() => {
            setIsOpen(false);
            handleCancel?.();
          }}
          subTitle={
            isGroupChat
              ? "You won't receive messages from this group until someone adds you back."
              : 'You will no longer have access to messages in this chat.  '
          }
          titleText={isGroupChat ? 'Leave group?' : 'Delete chat'}
        />
      )}
    </>
  ) : null;
};

export default LeaveGroupChatButton;
