import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS } from '../../../styles/constants';

const StyledTitle = styled.p<{ isDark: boolean; color?: string }>`
  color: ${({ isDark, color }) => {
    if (color) {
      return color;
    }

    return isDark ? COLORS.white[100] : COLORS.brown.light[100];
  }};
  font-size: 22px;
  line-height: 130%;
  margin: 0;

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(22px * ${BREAKPOINTS.retinaScale});
  }
`;

export default StyledTitle;
