import React, { FC } from 'react';

import UIHighlight from 'components/UI/Highlight';

import { HighlightSchema } from 'constants/graphqlTypes';
import { StyledHighlightWrap } from './styled';

const Highlight: FC<{ highlight: HighlightSchema; isDark?: boolean }> = ({ highlight, isDark }) => {
  const { curator, introductionText, isShort } = highlight;

  return introductionText ? (
    <StyledHighlightWrap>
      <UIHighlight isStandAlone={isShort} highlight={introductionText} curator={curator} isDark={isDark} />
    </StyledHighlightWrap>
  ) : null;
};

export default Highlight;
