import { useLocation } from 'react-router-dom';

import { EQueryFetchPolicy } from 'constants/common';
import { useGetDetailList, useGetListItems } from 'graphQL/list/hooks';
import { IListPreviewLocationState, IUsePreview } from './models';

export const usePreview = (): IUsePreview => {
  const { state } = useLocation<IListPreviewLocationState>();
  const { gradientEnd, gradientStart, id } = state ?? {};

  const { card: list, loading: listLoading } = useGetDetailList({
    id,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    isDetail: true,
  });

  const { items, loading: loadingItems, totalCount: itemsTotalCount } = useGetListItems({ id: list?.id });
  const loading = loadingItems || listLoading;

  return {
    list,
    items,
    loading,
    gradientEnd,
    gradientStart,
    itemsTotalCount: itemsTotalCount ?? 0,
  };
};
