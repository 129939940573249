import React, { FC, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useMarkViewedInChannelsMutation } from 'constants/graphqlTypes';

import { useNavigationRef } from 'components/Navigation/helpers/hooks';
import DetailSlider from 'components/DetailSlider';

import { useGetFeedItemsId } from 'graphQL/feeds/hooks';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';

import { transformRoute } from 'helpers/routingHelper';

import { ROUTE_FEED_ITEMS } from 'routes';
import { REF_PLACEHOLDER } from 'components/Explore/Item/helpers';
import { COLORS } from 'styles/constants';

const FeedItem: FC = () => {
  const { feedId, pickId } = useParams<{ feedId: string; pickId?: string }>();
  const { replace } = useHistory();
  const { state } = useLocation();

  const { items, loadMore } = useGetFeedItemsId(feedId);
  const { card } = useGetDetailCard(pickId, feedId, undefined, REF_PLACEHOLDER);
  const { isNewInChannels } = card ?? {};

  const [markedViewedInChannels] = useMarkViewedInChannelsMutation();

  const handleRoute = (id: string) =>
    replace({
      pathname: transformRoute(ROUTE_FEED_ITEMS, { feedId, pickId: id }),
      state: {
        ...state,
        feedId,
        fallbackRefId: REF_PLACEHOLDER,
      },
    });

  useEffect(() => {
    if (feedId && isNewInChannels) {
      markedViewedInChannels({
        variables: { referrerItemId: feedId, pickId },
      });
    }
  }, [isNewInChannels]);

  useNavigationRef(COLORS.white[100]);

  return items.length && card ? (
    <DetailSlider handleLoadItems={loadMore} handleRoute={handleRoute} card={card} items={items} />
  ) : null;
};

export default FeedItem;
