export enum EVisibilityRoles {
  CollaborativeListsVisibility = 'CollaborativeListsVisibility',
  MessagesAndThoughtsVisibility = 'MessagesAndThoughtsVisibility',
}

export const ROLES_DESCRIPTION = {
  messagesAndThoughts: {
    everyone: 'Everyone will be able to react to your thoughts',
    following: 'Everyone will be able to react to your thoughts',
    noOne: 'Everyone will be able to react to your thoughts',
  },
  collaborativeLists: {
    everyone: 'Everyone will be able to invite you to collaborate on lists',
    following: 'Only people you’re following can invite you to collaborate on lists',
    noOne: 'No one can invite you to collaborate on lists',
  },
};
