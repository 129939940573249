import getAuthUser from 'helpers/getAuthUser';
import {
  Maybe,
  CollaborationInviteSchema,
  ECollaborativeInviteStatusOptions,
  UserCardRoleListObjectType,
  ECardRoleTypeOptions,
} from 'constants/graphqlTypes';
import {
  EMPTY_LIST_PLACEHOLDER_BASE,
  EMPTY_LIST_PLACEHOLDER_FOR_CREATOR,
  EMPTY_LIST_PLACEHOLDER_FOR_VIEWER,
} from './constants';

export const isUserACreator = (creators?: UserCardRoleListObjectType | null): boolean => {
  const { userId } = getAuthUser();
  if (!creators || !creators?.items || creators?.items.length === 0 || !userId) {
    return false;
  }

  const creatorIds = creators.items
    .filter(
      (item) => item.roleName === ECardRoleTypeOptions.Owner || item.roleName === ECardRoleTypeOptions.Collaborator
    )
    .map((item) => item?.user?.id);

  return creatorIds.includes(userId);
};

export const checkIsBorderHidden = (invite?: Maybe<CollaborationInviteSchema>): boolean => {
  if (!invite || !invite.status) {
    return false;
  }

  const { Accepted, Declined } = ECollaborativeInviteStatusOptions;
  const respondedInviteStatuses = [Accepted, Declined];
  return !respondedInviteStatuses.includes(invite.status);
};

export const getEmptyListDescription = (isCreator?: boolean, isOnboarding?: boolean): string => {
  if (isOnboarding) {
    return EMPTY_LIST_PLACEHOLDER_BASE;
  }

  return isCreator ? EMPTY_LIST_PLACEHOLDER_FOR_CREATOR : EMPTY_LIST_PLACEHOLDER_FOR_VIEWER;
};
