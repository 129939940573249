import React, { Dispatch, FC, SetStateAction } from 'react';

import {
  DndContext,
  closestCenter,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Waypoint } from 'react-waypoint';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';

import { CardSchema } from 'constants/graphqlTypes';

import ListItem from 'components/ListSettings/EditOrder/DndCardList/ListItem';
import LoaderContent from 'components/UI/LoaderContent';
import useTheme from 'helpers/useTheme';

import { StyledContainer } from './styled';

interface IDndListProps {
  items: CardSchema[];
  loading: boolean;
  setItems: Dispatch<SetStateAction<CardSchema[]>>;
  loadMore: () => void;
  onDragEnd: (newItems: CardSchema[]) => void;
}

const DndList: FC<IDndListProps> = ({ items, loading, setItems, loadMore, onDragEnd }) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((prev) => {
        const oldIndex = prev.findIndex(({ id }) => id === active.id);
        const newIndex = prev.findIndex(({ id }) => id === over.id);

        const newItems = arrayMove(prev, oldIndex, newIndex);
        onDragEnd(newItems);

        return newItems;
      });
    }
  };

  const isDark = useTheme();

  return (
    <StyledContainer>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((card) => (
            <ListItem key={card.id} id={card.id} card={card} />
          ))}
          {loading ? (
            <LoaderContent isDark={isDark} isFullScreen={!items.length} />
          ) : (
            <>{items.length && <Waypoint onEnter={loadMore} />}</>
          )}
        </SortableContext>
      </DndContext>
    </StyledContainer>
  );
};

export default DndList;
