import {
  CardSchema,
  CardTitleObjectType,
  ColorSchema,
  ImageSetSchema,
  UnionImageSetAndTitleSchema,
} from 'constants/graphqlTypes';
import { getGradient } from 'components/List/Header/helper';

interface IGetListStyles {
  images: ImageSetSchema[];
  titles: string[];
  bgColor: string;
  urls: string[];
  hasItems: boolean;
}

export const getTitlesForCover = (titles: UnionImageSetAndTitleSchema[]): string[] =>
  Array(6)
    .fill(titles)
    .reduce((acc: CardTitleObjectType[], item) => (item ? acc.concat(item) : acc), [])
    .slice(0, 6)
    .reduce(
      (acc: string[], item: CardTitleObjectType) =>
        item?.__typename === 'CardTitleObjectType' && item ? acc.concat(item.title || '') : acc,
      []
    );

export const DEFAULT_GRADIENT = 'linear-gradient(180deg, #BDD900 20%, #EFEEEA 100%)';

export const getBgColors = (colors?: ColorSchema[] | null, isDetail?: boolean): string => {
  const { firstColor: gradientStart, secondColor: gradientEnd } = getGradient(
    colors?.[colors.length >= 2 ? 1 : 0]?.colorValue ?? ''
  );
  const secondPick = getGradient(colors?.[colors.length >= 2 ? 0 : 1]?.colorValue ?? '');
  const lastPick = isDetail ? secondPick?.secondColor : secondPick?.firstColor;

  if (!gradientStart && !gradientEnd) {
    return DEFAULT_GRADIENT;
  }

  return `linear-gradient(180deg, ${gradientStart} 0%, ${lastPick || gradientEnd} 100%)`;
};

/* eslint no-underscore-dangle: 0 */
export const getListStyles = (card?: CardSchema | null, isDetail?: boolean): IGetListStyles => {
  const images =
    card?.cardStyle?.collectionCover?.items?.reduce(
      (acc: ImageSetSchema[], item) => (item?.__typename === 'ImageSetSchema' && item ? acc.concat(item) : acc),
      []
    ) ?? [];

  const titles = getTitlesForCover(card?.cardStyle?.collectionCover?.items || []);

  const bgColor = getBgColors(card?.cardStyle?.collectionBgColors, isDetail);

  const urls = images.reduce(
    (acc: string[], { images: items }) => (items?.[0].url ? acc.concat(items[0].url) : acc),
    []
  );

  return {
    urls,
    images,
    titles,
    bgColor,
    hasItems: !!images.length || !!titles.length,
  };
};

export const getAvatarsLimit = (isFeaturedTab: boolean, showCreators: boolean): number => {
  if (isFeaturedTab) {
    return 6;
  }
  return showCreators ? 2 : 1;
};

export const getCirclesCount = (isFeaturedTab: boolean, totalCount: number): number => {
  if (isFeaturedTab && totalCount > 6) {
    return 7;
  }
  if (isFeaturedTab && totalCount <= 6) {
    return totalCount;
  }
  return totalCount > 1 ? 2 : 1;
};
