import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTE_MUTED_ACCOUNTS, ROUTE_MUTED_ACCOUNTS_SEARCH } from 'routes';

import SinglePageLayout from 'components/SinglePageLayout';
import MutedUsers from 'components/Muting/MutedUsers';
import Search from 'components/Blocking/Search';
import usePageType from 'helpers/usePageType';
import { TEST_ID_MUTED_ACCOUNTS_VIEW_START } from 'constants/aqa/settings/accountManagement';

const MutedAccounts: FC = () => {
  const { isMutedAccounts } = usePageType();

  return (
    <SinglePageLayout
      pageTestId={TEST_ID_MUTED_ACCOUNTS_VIEW_START}
      title={isMutedAccounts ? 'Muted accounts' : 'Mute an account'}
    >
      <Switch>
        <Route exact path={ROUTE_MUTED_ACCOUNTS}>
          <MutedUsers />
        </Route>
        <Route exact path={ROUTE_MUTED_ACCOUNTS_SEARCH}>
          <Search isMutedSearch />
        </Route>
      </Switch>
    </SinglePageLayout>
  );
};

export default MutedAccounts;
