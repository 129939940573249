import React, { FC, useEffect, useState } from 'react';

import { XBlock, XMasonry } from 'react-xmasonry';
import { Waypoint } from 'react-waypoint';

import Card from 'components/Card';
import Modal from 'components/Modal';
import useOpenHiddenCards from 'helpers/useOpenHiddenCards';
import Button from 'components/UI/Button';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { CardSchema } from 'constants/graphqlTypes';
import { useUpdateSpotlights } from 'graphQL/spotlights/hook';
import { useGetUserCards } from 'graphQL/cards/userCards/hooks';
import { BREAKPOINTS } from 'styles/constants';
import { StyledContainer, StyledCard, StyledTitle, StyledModalInner, StyledButtonWrap } from './styled';

interface ISpotlightsModalProps {
  handleClose: () => void;
  existingSpotlights: CardSchema[];
}

const SpotlightsModal: FC<ISpotlightsModalProps> = ({ handleClose, existingSpotlights }) => {
  const posthogCapture = usePostHogCapture();

  const existingSpotlightsIds = existingSpotlights.map(({ cardId }) => cardId);
  const { recentCards, loadMore, loading } = useGetUserCards({ excludedIds: existingSpotlightsIds, limit: 4 });
  const { openIds } = useOpenHiddenCards();

  const chooseCardWidth = () => {
    if (window.innerWidth >= BREAKPOINTS.xxl) {
      return BREAKPOINTS.retinaScale * 300;
    }
    return window.innerWidth < BREAKPOINTS.xl ? 200 : 360;
  };
  const [cardWidth, setCardWidth] = useState<number>(chooseCardWidth());

  const handleWindowResize = () => setCardWidth(chooseCardWidth());

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedCards, setSelectedCards] = useState<string[]>(existingSpotlightsIds);

  const selectSpotlight = (cardId: string) => {
    if (selectedCards.includes(cardId)) {
      setSelectedCards(selectedCards.filter((item) => item !== cardId));
    } else if (selectedCards.length < 3) {
      setSelectedCards([...selectedCards, cardId]);
    }
  };

  const updateSpotlights = useUpdateSpotlights(selectedCards, () => {
    handleClose();
    posthogCapture(POSTHOG_EVENTS.SpotlightsUpdate);
  });

  const handleSpotlightClick = (item: CardSchema) =>
    (!item.isHidden || openIds?.includes(item.cardId)) && selectSpotlight(item.cardId);

  const hideSaveButton = !existingSpotlights.length && !selectedCards.length;

  const buttonText = selectedCards.length
    ? `Spotlight ${selectedCards.length} pick${selectedCards.length > 1 ? 's' : ''}`
    : 'Save';
  const cards = [...existingSpotlights, ...recentCards];

  return (
    <Modal handleClose={handleClose}>
      <StyledModalInner>
        <StyledTitle>Select up to 3 picks to pin to your profile</StyledTitle>
        <StyledContainer>
          <XMasonry targetBlockWidth={cardWidth} responsive maxColumns={2} center={false}>
            {cards.map((item, index) => (
              <XBlock key={item.id}>
                {!loading && index === cards.length - 1 && <Waypoint onEnter={loadMore} />}
                <StyledCard
                  onClick={() => handleSpotlightClick(item)}
                  content={selectedCards.findIndex((id) => id === item.cardId) + 1}
                  isShowCheckbox={selectedCards.length < 3}
                >
                  <Card card={item} shouldDisableLongTap />
                </StyledCard>
              </XBlock>
            ))}
          </XMasonry>
          {!hideSaveButton && (
            <StyledButtonWrap>
              <Button text={buttonText} handler={updateSpotlights} />
            </StyledButtonWrap>
          )}
        </StyledContainer>
      </StyledModalInner>
    </Modal>
  );
};

export default SpotlightsModal;
