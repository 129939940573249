import React, { FC } from 'react';

import { EReportTypeOptions } from 'constants/graphqlTypes';
import Modal from 'components/Modal';
import MenuButton from 'components/UI/MenuButton';
import { StyledItem } from 'components/AddContent/Menu/styled';
import { StyledReportQuestionsList, StyledTitle } from '../styled';
import { useReportStyles } from '../hooks';

const ReportIssue: FC<{ cardId: string; isSpark?: boolean }> = ({ cardId, isSpark }) => {
  const {
    options,
    handleSubmitReport,
    closeReportStyleModal,
    handleOpenDescription,
    handleReplaceModal,
  } = useReportStyles({
    cardId,
    type: EReportTypeOptions.ExtractReport,
    isSpark,
  });

  return (
    <Modal handlePrev={handleOpenDescription} handleClose={closeReportStyleModal}>
      <StyledTitle>What issue are you experiencing?</StyledTitle>
      <StyledReportQuestionsList isDark>
        {options.map(({ title, id }) => (
          <StyledItem key={id}>
            <MenuButton text={title} handler={title === 'Other' ? handleReplaceModal : () => handleSubmitReport(id)} />
          </StyledItem>
        ))}
      </StyledReportQuestionsList>
    </Modal>
  );
};

export default ReportIssue;
