import React, { FC, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import ComplexSearchInput from 'components/ComplexSearchInput';
import Result from 'components/Search/Result';
import FilterBtns from 'components/Search/FilterBtns';
import StyledWrap from 'components/UI/Wrap';
import { ESearchTag } from 'components/Search/helpers/constants';
import { LAST_SEARCH_TAG_REGEX, USER_SEARCH_TEXT } from 'components/ComplexSearchInput/helpers/constants';
import { useGetSearchParam } from 'helpers/routingHelper';
import useDocumentTitle from 'helpers/useDocumentTitle';
import { ROUTE_SEARCH, ROUTE_SEARCH_RESULT } from 'routes';
import RecentSearches from 'components/Search/RecentSearches';
import Suggestions from 'components/Search/Suggestions/Suggestions';
import SuggestionSearches from 'components/Search/SuggestionSearches';
import { ESearchParams } from 'constants/common';
import { useNavColor } from 'components/Navigation/helpers/hooks';

const { User, Time, Type } = ESearchTag;

const checkIsESearchTag = (item: string | null): ESearchTag | null =>
  item === User || item === Time || item === Type ? item : null;

const Search: FC = () => {
  const posthogCapture = usePostHogCapture();

  const searchText = useGetSearchParam(ESearchParams.Search) ?? '';
  const [text, setText] = useState(searchText);
  const [filterType, setFilterType] = useState<ESearchTag | null>(null);
  const [userSearch, setUserSearch] = useState<string>('');
  const { isBottomMenuHidden: isBottomMenuHiddenContext, setIsBottomMenuHidden } = useNavColor();
  const [isInputActive, setIsInputActive] = useState(true);
  const { pathname } = useLocation();
  const showSuggestionSearches = !!filterType || (!filterType && text);
  const [isItemSelected, setIsItemSelected] = useState(false);

  useDocumentTitle('Search');

  useEffect(() => {
    setFilterType(checkIsESearchTag(text.match(LAST_SEARCH_TAG_REGEX)?.[0] ?? null));
    setUserSearch(text.match(USER_SEARCH_TEXT)?.[1] ?? '');
  }, [text]);

  useEffect(() => {
    setText(searchText);
  }, [searchText]);

  useEffect(() => {
    const isShowSuggestionSearch = text.length > 2 && pathname === ROUTE_SEARCH;
    const isHideBottomMenu = isInputActive || isShowSuggestionSearch;
    if (isBottomMenuHiddenContext !== isHideBottomMenu) {
      setIsBottomMenuHidden(isHideBottomMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, searchText, isInputActive]);

  useEffect(() => {
    if (isInputActive) {
      posthogCapture(POSTHOG_EVENTS.SearchAction);
      posthogCapture(POSTHOG_EVENTS.TapTextSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputActive]);

  const changeValue = (isItemSelectedValue: boolean) => (value: React.SetStateAction<string>) => {
    setIsItemSelected(isItemSelectedValue);
    return setText(value);
  };

  return (
    <StyledWrap>
      <ComplexSearchInput
        inputValue={text}
        setInputValue={changeValue(false)}
        onFocusHandler={() => setIsInputActive(true)}
        onBlurHandler={() => setIsInputActive(false)}
        isItemSelected={isItemSelected}
      />
      <Suggestions userText={userSearch} type={filterType} setText={changeValue(true)} />
      <Switch>
        <Route exact path={ROUTE_SEARCH}>
          <FilterBtns onSetValue={(filterValue: string) => setText((prev) => prev + filterValue)} />
          {showSuggestionSearches ? <SuggestionSearches searchKey={text} /> : <RecentSearches />}
        </Route>
        <Route path={ROUTE_SEARCH_RESULT}>
          <Result />
        </Route>
      </Switch>
    </StyledWrap>
  );
};

export default Search;
