import { styled } from 'linaria/react';

import { BREAKPOINTS } from 'styles/constants';

export const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 0 auto;
  max-width: ${BREAKPOINTS.s}px;
  width: 100%;
`;

export const StyledItem = styled.li<{ isBig?: boolean }>`
  display: block;
  padding: ${({ isBig }) => (isBig ? '32px 0 64px' : '0 1% 8px')};
  width: ${({ isBig }) => (isBig ? 100 : 50)}%;
  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    width: ${({ isBig }) => (isBig ? 100 : 49)}%;
    padding: ${({ isBig }) => (isBig ? '32px 0 64px' : '0 0 8px')};
  }
`;

export const StyledWrap = styled.div<{ hideActionsInfo?: boolean }>`
  padding: 24px 0 96px;

  .action-user-info {
    display: ${({ hideActionsInfo }) => (hideActionsInfo ? 'none' : 'block')};
  }

  .hide-padding {
    padding-top: ${({ hideActionsInfo }) => (hideActionsInfo ? 0 : '12px')};
  }

  .action-comment-wrap {
    min-height: ${({ hideActionsInfo }) => (hideActionsInfo ? 44 : 94)}px;
  }
`;

export const StyledBtnWrap = styled.div`
  button {
    margin: 32px 24px 0 24px;
    width: -webkit-fill-available;

    div {
      margin: 0 auto;
    }
  }
`;
