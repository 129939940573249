import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import TabModal from 'components/TabModal';
import UserList from 'components/UserList';

import { useUserFollows } from 'graphQL/follows/hooks';
import { StyledWrap } from 'components/TabModal/styled';
import { EButtonType } from 'components/UserList/constants';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_USER_FOLLOWERS, ROUTE_USER_FOLLOWINGS, ROUTE_USER_PROFILE } from 'routes';
import useOpenModal from 'helpers/useOpenModal';
import { getConnectionsModalTabButtonId, getConnectionsUserFollowBtnId } from 'constants/aqa/profileConnections';

const Connections: FC<{ isFollowers?: boolean }> = ({ isFollowers }) => {
  const { items, loading, tabs, currentTab, loadMore, setCurrentTab, totalFollowers, totalFollowings } = useUserFollows(
    isFollowers ? 0 : 1
  );
  const { replace } = useHistory();
  const { pathname } = useLastLocation() ?? {};
  const { profileName } = useParams<{ profileName: string }>();

  const hideFade = pathname?.includes('followers') || pathname?.includes('followings');

  const route = transformRoute(currentTab ? ROUTE_USER_FOLLOWERS : ROUTE_USER_FOLLOWINGS, { profileName });
  const handleOpenConnections = useOpenModal(route);

  const handleClose = () => {
    replace(transformRoute(ROUTE_USER_PROFILE, { profileName }));
  };

  return (
    <TabModal
      fadeIn={!pathname?.includes('topics')}
      tabs={tabs}
      tabDataTestIds={[
        getConnectionsModalTabButtonId(totalFollowers || 0, true),
        getConnectionsModalTabButtonId(totalFollowings || 0),
      ]}
      setCurrentTab={setCurrentTab}
      currentTab={currentTab}
      handleClose={handleClose}
      hideFade={hideFade}
      handleOpenConnections={handleOpenConnections}
    >
      <StyledWrap>
        <UserList
          itemBtndataTestIdTemplate={getConnectionsUserFollowBtnId}
          buttonType={EButtonType.Follow}
          users={items}
          loading={loading}
          loadMore={loadMore}
          currentTab={currentTab}
        />
      </StyledWrap>
    </TabModal>
  );
};

export default Connections;
