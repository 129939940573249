import React, { FC } from 'react';

import { Maybe, CardListObjectType, EDiscoverItemLayoutOptions } from 'constants/graphqlTypes';
import useOpenModal from 'helpers/useOpenModal';
import { ROUTE_ADD_ANSWER } from 'routes';

import { StyledSubtitle, StyledSectionWrap } from 'pages/QuestionDetail/styled';
import { EUiButtonType } from 'components/UI/Button/constants';
import Card from 'components/Card';

import { StyledWrap, StyledItem, StyledAddBtn } from './styled';

interface IYourPicksProps {
  questionId?: string;
  questionTitle?: string | null;
  myAnswers?: Maybe<CardListObjectType>;
  isDark: boolean;
}

const YourPicks: FC<IYourPicksProps> = ({ questionId, questionTitle, myAnswers, isDark }) => {
  const openAddContent = useOpenModal(ROUTE_ADD_ANSWER, false, {
    questionTitle,
    questionId,
  });

  const hasMyAnswer = !!myAnswers?.items.length;

  return (
    <StyledWrap>
      {hasMyAnswer && (
        <>
          <StyledSubtitle>Your picks</StyledSubtitle>
          <StyledSectionWrap>
            {myAnswers?.items.map((item) => (
              <StyledItem key={item.id}>
                <Card card={item} isDark={isDark} layout={EDiscoverItemLayoutOptions.FirstUp} showActivity />
              </StyledItem>
            ))}
          </StyledSectionWrap>
        </>
      )}
      <StyledAddBtn
        isDark={isDark}
        onClick={openAddContent}
        buttonType={hasMyAnswer ? EUiButtonType.Primary : EUiButtonType.Emphasized}
      >
        {`Add ${hasMyAnswer ? 'a new' : 'your'} pick`}
      </StyledAddBtn>
    </StyledWrap>
  );
};

export default YourPicks;
