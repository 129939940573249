import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div<{ bgColor: string; hasItems: boolean }>`
  aspect-ratio: ${({ hasItems }) => (hasItems ? '1' : 'auto')};
  background: ${({ bgColor }) => bgColor};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;

  a {
    overflow: hidden;
    border-radius: 10px;
    display: block;
  }
`;

export const StyledTop = styled.div`
  border-bottom: 1px solid var(--transparencies-white-30, rgba(255, 255, 255, 0.3));
  height: 250px;
  padding: 40px 0 0;
  position: relative;
`;

export const StyledBottom = styled.div<{ hasItems: boolean }>`
  padding: ${({ hasItems }) => (hasItems ? '24px 16px' : '52px 16px 34px')};
  position: relative;
`;

export const StyledInner = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

export const StyledPretitle = styled.p<{ hasItems: boolean }>`
  color: ${({ hasItems }) => (hasItems ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 14px/140% ${FONTS.secondary};
  text-align: center;
  margin: 0 0 4px;
`;

export const StyledTitle = styled.p<{ hasItems: boolean }>`
  color: ${({ hasItems }) => (hasItems ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 14px/140% ${FONTS.primary};
  text-align: center;
  margin: 0 0 24px;
`;

export const StyledImagesList = styled.ul<{ isMultiple: boolean }>`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  margin: 0;
  position: relative;
  min-height: 170px;
  height: 100%;

  &.images-2 {
    img {
      &:first-child {
        bottom: 0;
        left: 12px;
        position: absolute;
      }
      &:nth-child(2) {
        bottom: 0;
        position: absolute;
        right: 12px;
      }
    }
  }

  &.images-3 {
    img {
      &:first-child {
        bottom: 0;
        left: 12px;
        position: absolute;
      }
      &:nth-child(2) {
        bottom: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-child(3) {
        bottom: 0;
        position: absolute;
        right: 12px;
      }
    }
  }

  &.images-4 {
    img {
      &:first-child {
        bottom: 0;
        left: 12px;
        position: absolute;
      }
      &:nth-child(2) {
        bottom: 0;
        position: absolute;
        left: 54px;
      }

      &:nth-child(3) {
        bottom: 0;
        position: absolute;
        left: 96px;
      }

      &:nth-child(4) {
        bottom: 0;
        left: 138px;
        position: absolute;
      }
    }
  }
`;

export const StyledImageItem = styled.img<{ size: number }>`
  border-radius: 4px;
  height: auto;
  max-height: ${({ size }) => `${size * 1.64}px`};
  max-width: ${({ size }) => `${size}px`};
  min-height: ${({ size }) => `${size}px`};
  object-fit: cover;
  overflow: hidden;
  position: relative;
  width: ${({ size }) => `${size}px`};

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    max-height: ${({ size }) => `${size * 1.86}px`};
  }
`;

export const StyledTextWrap = styled.div<{ bgColor: string }>`
  border-radius: 8px;
  border: 1px solid rgba(24, 24, 0, 0.1);
  margin: 88px auto 0;
  overflow: hidden;
  width: 120px;
`;

export const StyledTextsList = styled.ul<{ bgColor: string; size: number; isLarge?: boolean }>`
  background: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: column;
  font-size: ${({ isLarge }) => (isLarge ? '12px' : '9px')};
  height: ${({ size }) => `${size}px`};
  list-style: none;
  margin: 0;
  padding: 6.5%;
  width: ${({ size }) => `${size}px`};
`;

export const StyledTextItem = styled.li<{ opacity: number; color: string }>`
  color: ${({ color }) => color};
  line-height: 130%;
  opacity: ${({ opacity }) => opacity};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledRelWrap = styled.div<{ isRight: boolean }>`
  margin-left: ${({ isRight }) => (isRight ? 'auto' : '0')};
  position: relative;
  width: 250px;
`;

export const StyledInviteText = styled.div<{ isRight: boolean; hasReactions: boolean; hasBottomMargin: boolean }>`
  display: flex;
  justify-content: ${({ isRight }) => (isRight ? 'flex-end' : 'flex-start')};
  margin-left: ${({ isRight }) => (isRight ? 'auto' : '0')};
  margin-top: ${({ hasReactions }) => (hasReactions ? '20px' : '16px')};
  margin-bottom: ${({ hasBottomMargin }) => (hasBottomMargin ? '8px' : 0)};
  max-width: calc(100% - 58px);
`;
