import React, { FC } from 'react';

import Avatar from 'components/UI/Avatar';
import AvatarGH from 'components/UI/AvatarGH';
import IconCircle from 'components/UI/IconCircle';
import { UserObjectType } from 'constants/graphqlTypes';
import { DEFAULT_TOPIC_GRADIENT } from 'styles/constants';

import { StyledThumbnail } from 'components/ActivityItem/Elements/styled';
import { StyledUsername } from 'components/Sparks/Views/Card/styled';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';
import {
  StyledBadgeWrap,
  StyledBadge,
  StyledUserTextWrap,
  StyledUserTitle,
  StyledUserSubtitle,
  StyledUserSubtitleWrap,
  StyledMore,
} from './styled';

interface IEditFeedButtonProps {
  showAllPicks?: boolean;
  title: string;
  subtitle?: string;
  srcImage?: string;
  Icon: FC;
  handleClick: () => void;
  users?: UserObjectType[];
  usersTotalCount?: number;
}

const EditFeedButton: FC<IEditFeedButtonProps> = ({
  showAllPicks,
  title,
  subtitle,
  srcImage,
  Icon,
  handleClick,
  users,
  usersTotalCount,
}) => (
  <StyledBadgeWrap>
    <StyledBadge>
      {!!users && !!users?.length && <Avatar user={users[0]} />}
      {!users && (
        <>
          {showAllPicks ? (
            <AvatarGH dimension={44} />
          ) : (
            <StyledThumbnail src={srcImage ?? ''} bg={DEFAULT_TOPIC_GRADIENT} />
          )}
        </>
      )}
      <StyledUserTextWrap>
        <StyledUserTitle>{title}</StyledUserTitle>
        {!showAllPicks && (
          <>
            {subtitle && <StyledUserSubtitle>{subtitle}</StyledUserSubtitle>}
            <StyledUserSubtitleWrap>
              {users && (
                <StyledUserSubtitle key={users[0].username}>
                  <StyledUsername>{generateFullName(users[0]).trim()}</StyledUsername>
                  {users[0].isVerified && <VerificationBadge />}
                </StyledUserSubtitle>
              )}
              {!!users?.length && !!usersTotalCount && usersTotalCount === 2 && (
                <StyledUserSubtitle key={users[1].username}>
                  <StyledUsername>
                    &nbsp;and&nbsp;
                    {generateFullName(users[1]).trim()}
                  </StyledUsername>
                  {users[1].isVerified && <VerificationBadge />}
                </StyledUserSubtitle>
              )}
              {!!users?.length && !!usersTotalCount && usersTotalCount > 2 && (
                <StyledMore>&nbsp;{`and ${usersTotalCount - 1} others`}</StyledMore>
              )}
            </StyledUserSubtitleWrap>
          </>
        )}
      </StyledUserTextWrap>
    </StyledBadge>
    <IconCircle handleClick={handleClick} Icon={Icon} isDark hasBorder />
  </StyledBadgeWrap>
);

export default EditFeedButton;
