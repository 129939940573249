import React, { FC } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { COLORS } from 'styles/constants';
import { LinkPreviewMessageSchema, MessageSchema } from 'constants/graphqlTypes';
import { StyledBody, StyledContent, StyledImageWrap, StyledFooter } from 'components/Card/Common/styled';
import { StyledText } from 'components/Collection/Collaborators/styled';
import { ROUTE_ADD_PICK } from 'routes';
import useOpenModal from 'helpers/useOpenModal';
import { StyledButton } from 'components/Messages/Message/Shared/Card/styled';
import ReactionsToken from 'components/Messages/Message/ReactionsToken';
import { useMessagesChatData } from 'components/Messages/helpers/hooks';
import { StyledLinkContainer, StyledTitle, StyledWrap } from './styled';
import { StyledTokenWrap } from '../../styled';
import { StyledRelWrap } from '../styled';

interface ILinkPreviewProps {
  isDark: boolean;
  isMine?: boolean;
  messageRef: React.RefObject<HTMLDivElement>;
  messageLink: LinkPreviewMessageSchema;
  message: MessageSchema;
}

const LinkPreview: FC<ILinkPreviewProps> = ({ isDark, message, messageLink, isMine, messageRef }) => {
  const color = isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  const { domain, url, text, previewImageUrl } = messageLink ?? {};

  const { chatId } = useMessagesChatData();
  const openAddContent = useOpenModal(ROUTE_ADD_PICK, false, { url });

  const contentIds = {
    chatId,
    messageId: message?.id || '',
  };

  return (
    <StyledWrap isMine={!!isMine} ref={messageRef}>
      <StyledButton isDark={isDark} onClick={openAddContent}>
        <PlusIcon height={28} width={28} viewBox="0 0 20 20" />
      </StyledButton>
      <StyledLinkContainer isDark={isDark}>
        <StyledRelWrap>
          <a target="_blank" rel="noopener noreferrer" href={url ?? '#'} className="link-preview-anchor">
            <StyledBody bg={COLORS.transparent} isReverse={false} className="card">
              <StyledContent>
                <StyledTitle isDark={isDark}>{text}</StyledTitle>
              </StyledContent>
              <StyledImageWrap isBorderless={false}>
                <img src={previewImageUrl ?? ''} alt={text ?? ''} />
              </StyledImageWrap>
            </StyledBody>
          </a>
          <StyledTokenWrap className="token-wrap" isRight={!!isMine}>
            <ReactionsToken contentIds={contentIds} isDark={isDark} />
          </StyledTokenWrap>
        </StyledRelWrap>
        <StyledFooter color={color} className="footer">
          <StyledText hasIndicator={false}>
            <a className="source-link" target="_blank" rel="noopener noreferrer" href={url ?? '#'}>
              {domain}
            </a>
          </StyledText>
        </StyledFooter>
      </StyledLinkContainer>
    </StyledWrap>
  );
};

export default LinkPreview;
