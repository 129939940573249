import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/UI/Button';
import ProfileSetupCard from 'components/OnboardingV2/ProfileSetupCard';
import useTheme from 'helpers/useTheme';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { EUiButtonType } from 'components/UI/Button/constants';
import { useNavigationRef } from 'components/Navigation/helpers/hooks';
import { getGradient } from 'components/List/Header/helper';
import { useProfile } from 'graphQL/profile/hooks';
import { useGetProfileTopics } from 'graphQL/profile/topics/hooks';
import { ROUTE_ONBOARDING_COMPLETION } from 'routes';
import { COLORS } from 'styles/constants';
import { useUpdateOnboardingStepV2 } from 'graphQL/onboarding/hooks';
import { ENewOnboardingStepOptions } from 'constants/graphqlTypes';
import Grain from 'components/UI/Grain';

import { getInfoBlocks, getInterests } from './helpers';
import InfoBlock from './InfoBlock';

import { StyledButtonContainer, StyledContent, StyledContentWrap, StyledSubtitle, StyledTitle } from './styled';

const ProfileSetup = () => {
  const posthogCapture = usePostHogCapture();
  useUpdateOnboardingStepV2(ENewOnboardingStepOptions.Profile);
  const isDarkFromTheme = useTheme();
  const { topics } = useGetProfileTopics();
  const { userData } = useProfile();

  const imageColor = userData?.userStyle?.thumbnailImageSet?.colors?.sourceGradient?.items?.[0]?.colorValue;

  const isDark = !!imageColor || isDarkFromTheme;

  const originalNavColor = isDarkFromTheme ? COLORS.white[100] : COLORS.brown.dark[100];

  const navRef = useNavigationRef(imageColor ? COLORS.white[100] : originalNavColor);
  const { firstColor, secondColor } = getGradient(imageColor ?? '');
  const savedBio = localStorage.getItem('bio');
  const bio = userData?.userInfo?.bio || savedBio;
  const interests = getInterests(topics);
  const displayingBlocks = getInfoBlocks(bio ?? undefined, interests);
  const hasNoCustomBg = userData?.userStyle?.thumbnailImageSetId === null;
  const isFinished = !!bio && !!interests;
  const btnText = isFinished ? 'Continue' : 'Fill in the blanks';
  const { push } = useHistory();

  const handleComplete = () => {
    push(ROUTE_ONBOARDING_COMPLETION);
    if (hasNoCustomBg) {
      posthogCapture(POSTHOG_EVENTS.OnboardingProfileParametersSave, {
        screen: 'avatar',
      });
    }
  };

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.OnboardingProfileSetup);
  }, []);

  return (
    <StyledContentWrap ref={navRef} gradientStart={secondColor} gradientEnd={firstColor}>
      {!hasNoCustomBg && <Grain />}
      <StyledContent>
        <StyledTitle isDark={isDark}>Setup your profile</StyledTitle>
        <StyledSubtitle isDark={isDark}>
          Profiles are visible to others on guide:human and help strengthen your credibility.
        </StyledSubtitle>
        {userData && <ProfileSetupCard userData={userData} isDark={isDark} />}
        {displayingBlocks.map((textParams, index) => (
          <InfoBlock
            key={textParams.title}
            isDark={isDark}
            params={textParams}
            hasNoCustomBg={hasNoCustomBg}
            isLast={index === displayingBlocks.length - 1}
          />
        ))}
        <StyledButtonContainer isDark={isDark} hasUploadedImage={!!imageColor}>
          <Button
            handler={handleComplete}
            isDisabled={!isFinished}
            isDark={isDark}
            text={btnText}
            type={EUiButtonType.Emphasized}
          />
        </StyledButtonContainer>
      </StyledContent>
    </StyledContentWrap>
  );
};

export default ProfileSetup;
