import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as FilterIcon } from 'assets/icons/Filter.svg';

import { EViewTypeOptions } from 'constants/graphqlTypes';
import { useGetChannelSectionItem, useGetFeedCards, useGetFeedUsersData, useUpdateItemView } from 'graphQL/feeds/hooks';

import ListGrid from 'components/List/Grid';
import ImmersiveCover from 'components/ImmersiveCover';
import ListHeader from 'components/List/Header';
import LoaderContent from 'components/UI/LoaderContent';
import UrlAvatars from 'components/UI/UrlAvatars';
import useTheme from 'helpers/useTheme';
import useOpenModal from 'helpers/useOpenModal';
import { getCoverGradient } from 'components/List/Header/helper';
import { DEFAULT_GRADIENT } from 'components/Collection/helpers';

import { ROUTE_FILTER_FEED } from 'routes';
import { getItemsText } from './helpers';
import {
  StyledAvatarsWrap,
  StyledDescription,
  StyledFilterBtn,
  StyledTitle,
  StyledTitleWrap,
  StyledWrap,
} from './styled';

const FeedDetail: FC = () => {
  const isDark = useTheme();
  const { feedId } = useParams<{ feedId: string }>();
  const { title, totalCount: usersTotalCount, isUserFeed } = useGetFeedUsersData(feedId, { limit: 2 });
  const { cards, totalCount: cardsTotalCount, loading: cardsLoading, loadMore } = useGetFeedCards(feedId);
  const { userCovers, detailCovers, loading, item } = useGetChannelSectionItem(feedId) ?? {};
  const bgColor = getCoverGradient(cards, !!cards.length) ?? DEFAULT_GRADIENT;
  const { cardStyle } = item ?? {};
  const { viewType } = cardStyle ?? {};

  const updateFeedViewMutation = useUpdateItemView(feedId, EViewTypeOptions.Immersive);

  const gotoFilterFeed = useOpenModal(ROUTE_FILTER_FEED, false, {
    usersTotalCount,
    feedId,
    title,
    isAdminFeed: !isUserFeed,
  });

  const userUrls: string[] =
    (userCovers?.items ?? [])?.reduce(
      (urls: string[], curr) => (curr?.images?.[0]?.url ? [...urls, curr?.images?.[0]?.url] : urls),
      []
    ) || [];

  useEffect(() => {
    updateFeedViewMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading ? (
    <>
      {viewType === EViewTypeOptions.Immersive ? (
        <ImmersiveCover
          avatars={userUrls}
          loading={!!loading}
          totalAvatars={usersTotalCount}
          id={feedId}
          title={title}
          total={cardsTotalCount}
          firstItemId={cards?.[0]?.id}
          bgItems={detailCovers?.items ?? []}
          isFeed
        />
      ) : (
        <>
          {!!detailCovers?.totalCount && <ListHeader bgColor={bgColor} images={detailCovers?.items} titles={[]} />}
          {!!title && (
            <StyledWrap hasCover={!!detailCovers?.totalCount}>
              <StyledTitleWrap>
                {title && (
                  <StyledTitle isDark={isDark} total={cardsTotalCount && cardsTotalCount > 0 ? cardsTotalCount : 0}>
                    {title}
                  </StyledTitle>
                )}
                <StyledFilterBtn isDark={isDark} onClick={gotoFilterFeed}>
                  <FilterIcon />
                </StyledFilterBtn>
              </StyledTitleWrap>
              {!!userUrls && (
                <StyledAvatarsWrap>
                  <UrlAvatars urls={userUrls} limit={6} isBig total={usersTotalCount} />
                </StyledAvatarsWrap>
              )}
              <StyledDescription>
                {cardsLoading ? 'Loading' : cardsTotalCount} {getItemsText('pick', cardsTotalCount ?? 0)}{' '}
                {!!usersTotalCount && `from ${usersTotalCount} ${getItemsText('user', usersTotalCount)}`}
              </StyledDescription>
            </StyledWrap>
          )}
          <ListGrid items={cards} loadMore={loadMore} loading={cardsLoading} showActivity />
        </>
      )}
    </>
  ) : (
    <LoaderContent />
  );
};

export default FeedDetail;
