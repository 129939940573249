import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';
import { NAVIGATION_HEIGHT } from './helpers/constants';

export const StyledNavigation = styled.nav<{
  color: string;
  isHide: boolean;
  hasBgColor?: boolean;
  bgColor?: string;
  isMessages?: boolean;
}>`
  align-items: center;
  background-color: ${({ bgColor }) => bgColor ?? 'none'};
  display: flex;
  justify-content: space-between;
  padding: ${({ isMessages }) => (isMessages ? '8px 24px 0 12px' : '8px 2vw 8px')};
  position: fixed;
  top: ${({ isHide }) => (isHide ? `-${NAVIGATION_HEIGHT}px` : 0)};
  transition: top 0.3s ease-in-out;
  width: 100%;
  z-index: 200;

  & > button path,
  & > button rect,
  & > div > button path,
  & > div > button rect,
  & .right-menu .icon-navigation path,
  & .right-menu .icon-navigation rect {
    fill: ${({ color }) => color};
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 8px 2vw 8px;
  }
`;

export const StyledButton = styled.button`
  background-color: ${COLORS.transparent};
  border: none;
  cursor: pointer;
  height: 44px;
  padding: 0;
  width: 44px;
  flex-shrink: 0;
`;

export const StyledStrokeButton = styled(StyledButton)<{ isDark: boolean }>`
  path {
    stroke: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;

export const StyledFillButton = styled(StyledButton)<{ isDark: boolean; isTransparent?: boolean }>`
  path {
    stroke: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    fill: ${({ isDark, isTransparent }) => {
      if (isTransparent) {
        return COLORS.transparent;
      }
      return isDark ? COLORS.white[100] : COLORS.brown.dark[100];
    }};
  }
`;

export const StyledRightMenu = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;

  li {
    position: relative;
  }
`;

export const StyledLabel = styled.span`
  align-items: center;
  background-color: ${COLORS.green.light};
  border-radius: 20px;
  color: ${COLORS.brown.dark[100]};
  display: flex;
  font-size: 12px;
  justify-content: center;
  height: 18px;
  left: 22px;
  padding: 0 6px;
  position: absolute;
  top: 4px;
`;

export const StyledSettingsLabel = styled.li`
  font: 400 14px/140% ${FONTS.secondary};
  padding-top: 16px;
  color: white;
`;

export const StyledMenuWrap = styled.div`
  display: flex;
`;
