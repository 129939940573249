import React, { FC } from 'react';
import { MessageContentStatus } from 'constants/graphqlTypes';

import { EMessageContentType } from '../../models';
import { StyledUnavailableContent } from '../../styled';

interface IUnavailableContentProps {
  isDark: boolean;
  contentType: EMessageContentType;
  isMine?: boolean;
  messageContentStatus: Partial<MessageContentStatus>;
}

const UnavailableContent: FC<IUnavailableContentProps> = ({ isDark, isMine, contentType, messageContentStatus }) => (
  <StyledUnavailableContent
    isDark={isDark}
    isDeleted
    isMine={messageContentStatus === MessageContentStatus.Deleted && isMine}
  >
    {contentType} {messageContentStatus?.toLocaleLowerCase()}
  </StyledUnavailableContent>
);

export default UnavailableContent;
