import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import { Maybe } from 'constants/graphqlTypes';
import { COLORS, FONTS } from '../../../styles/constants';

export const StyledWrapper = styled.div<{
  isLandingView?: boolean;
  disableLinks?: boolean;
  isFullHeight?: boolean;
  isOnboardingView?: boolean;
}>`
  background: ${COLORS.white.off[100]};
  border: none;
  border-radius: ${({ isLandingView }) => (isLandingView ? '20px' : '10px')};
  display: flex;
  flex-direction: column;
  height: ${({ isFullHeight }) => (isFullHeight ? '100%' : 'auto')};
  padding: ${({ isLandingView }) => (isLandingView ? '58px 12% 32px' : '52px 12% 32px')};

  a {
    pointer-events: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
    user-select: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
  }

  button {
    pointer-events: ${({ disableLinks, isOnboardingView }) => (disableLinks && !isOnboardingView ? 'none' : 'auto')};
    user-select: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
  }
`;

export const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  text-decoration: none;
`;

export const StyledUserInfoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  text-decoration: none;
`;

export const StyledName = styled.span<{ isLandingView?: boolean }>`
  color: ${COLORS.brown.dark[100]};
  font-size: ${({ isLandingView }) => (isLandingView ? '32px' : '22px')};
  line-height: ${({ isLandingView }) => (isLandingView ? '120%' : '130%')};
  overflow: hidden;
  padding-top: 16px;
  text-align: center;
  text-overflow: ${({ isLandingView }) => (isLandingView ? 'clip' : 'ellipsis')};
  white-space: ${({ isLandingView }) => (isLandingView ? 'normal' : 'nowrap')};
  width: 100%;
`;

export const StyledUsername = styled.span<{ isLandingView?: boolean }>`
  color: ${COLORS.brown.dark[100]};
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
  padding-top: ${({ isLandingView }) => (isLandingView ? '16px' : '8px')};
  text-align: center;
  word-break: break-word;
`;

export const StyledBio = styled.span<{ bio?: Maybe<string>; isLandingView?: boolean }>`
  color: ${COLORS.brown.dark[100]};
  display: ${({ bio }) => (bio ? 'inline-block' : 'none')};
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-overflow: ${({ isLandingView }) => (isLandingView ? 'clip' : 'ellipsis')};
  white-space: ${({ isLandingView }) => (isLandingView ? 'normal' : 'nowrap')};
  width: 100%;
`;

export const StyledButton = styled.button`
  background: ${COLORS.brown.dark[100]};
  border-radius: 22px;
  border: none;
  color: ${COLORS.white[100]};
  cursor: pointer;
  display: block;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  font-weight: 600;
  height: 44px;
  line-height: 140%;
  margin: 0 auto;
  padding: 12px 20px;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    margin: 0;
  }
`;

export const StyledFollowsInfo = styled.span<{
  isOnboardingView?: boolean;
}>`
  color: ${COLORS.brown.dark[100]};
  display: block;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
  padding-top: ${({ isOnboardingView }) => (isOnboardingView ? '17px' : '51px')};
  text-align: center;
  word-break: break-word;
  margin-top: auto;
`;

export const StyledVerificationWrapper = styled.div`
  color: ${COLORS.brown.dark[100]};
  padding-top: 4px;
`;

export const StyledProfileHeader = styled.div`
  align-items: center;
  display: flex;
  height: 100px;
`;

export const StyledAvatarWrap = styled.div<{ showPicks: boolean }>`
  border-radius: 50%;
  border: 4px solid ${COLORS.white.off[100]};
  box-shadow: 0 0 0 5px inset ${COLORS.white.off[100]};
  margin-bottom: -8px;
  overflow: hidden;
  z-index: 10;
`;

export const StyledImagesWrap = styled.div<{ showPicks?: boolean }>`
  align-items: end;
  animation: 250ms ease-in maxWidth forwards;
  animation-delay: 450ms;
  display: flex;
  height: 100%;
  max-width: 0px;

  @keyframes maxWidth {
    to {
      margin-left: ${({ showPicks }) => (showPicks ? '20px' : '0')};
      max-width: 222px;
    }
  }
`;

export const StyledImageItem = styled.img<{ index: number }>`
  animation: growFromBottomLeft 300ms ease-in forwards;
  animation-delay: calc(700ms + ${({ index }) => 80 * index}ms);
  margin-left: -30px;
  max-height: 100px;
  max-width: 74px;
  object-fit: cover;
  overflow: hidden;
  position: relative;
  visibility: hidden;

  @keyframes growFromBottomLeft {
    from {
      transform: scale(0);
      transform-origin: bottom left;
      visibility: hidden;
    }
    to {
      transform: scale(1);
      transform-origin: bottom left;
      visibility: visible;
    }
  }
`;

export const StyledPreview = styled.span`
  align-self: center;
  color: ${COLORS.brown.dark[100]};
  cursor: pointer;
  font: 600 14px/140% ${FONTS.secondary};
  margin-top: 17px;
  text-align: center;
  width: fit-content;
`;
