import { styled } from 'linaria/react';

import { COLORS, FONTS } from '../../../styles/constants';

export const StyledWrap = styled.div`
  overflow: hidden;
  user-select: none;
  -webkit-touch-callout: none;
`;

export const StyledContent = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font-size: 14px;
  font-family: ${FONTS.secondary};
  line-height: 140%;
  padding: 2px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledUsername = styled.div`
  color: ${COLORS.brown.light[100]};
  font-weight: 350;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledName = styled.div<{ isBold: boolean }>`
  display: flex;
  align-items: center;

  .badge-title {
    flex-shrink: 1;
  }

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  }
`;
