import { StyledInfo as StyledInfoImported } from 'components/Thoughts/Thought/styled';
import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div<{ isMine: boolean }>`
  margin-left: ${({ isMine }) => (isMine ? 'auto' : 0)}px;
  max-width: 250px;
  padding-top: 14px;
  position: relative;
  width: 100%;
`;

export const StyledContainer = styled.div<{ bg: string }>`
  background: ${({ bg }) => bg};
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  color: ${COLORS.white[100]};
  overflow: hidden;
`;

export const StyledSourceContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;
  padding: 12px;
  width: 100%;
`;

export const StyledSourceImage = styled.img`
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
  flex: 0 0;
`;

export const StyledSourceTitle = styled.span`
  font: 400 14px/140% ${FONTS.primary};
  flex: 1 1;
`;

export const StyledThoughtContainer = styled.div`
  padding: 12px;
  border-top: 1px solid ${COLORS.white[30]};
`;

export const StyledThought = styled.span`
  font: 400 14px/140% ${FONTS.secondary};
  padding-top: 12px;
  display: block;
`;

export const StyledInfo = styled(StyledInfoImported)`
  padding: 8px 0 0;

  a {
    z-index: unset;
  }
`;
