import { styled } from 'linaria/react';
import { COLORS, FONTS } from '../../../styles/constants';

export const StyledAvatarWrap = styled.div`
  margin-right: 8px;
`;

export const StyledTextWrap = styled.div<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  flex-direction: column;
  font: 400 14px/140% ${FONTS.secondary};
  overflow: hidden;

  a {
    color: inherit;
  }
`;

export const StyledName = styled.div`
  align-items: center;
  display: flex;

  & > .ui-link {
    flex-shrink: 1;
    overflow: hidden;
  }
`;

export const StyledUsernameWrap = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
`;

export const StyledUsername = styled.span`
  flex-shrink: 1;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledBio = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
