import React, { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { CardSchema, ECollectionLayoutOptions } from 'constants/graphqlTypes';
import { RefIdProvider } from 'helpers/useContextRefID';

import LoaderContent from 'components/UI/LoaderContent';
import Button from 'components/UI/Button';
import useTheme from 'helpers/useTheme';
import useReferrerId from 'helpers/useReferrerId';
import { EUiButtonType } from 'components/UI/Button/constants';
import Default from './DefaultView';
import Detail from './DetailView';
import Image from './ImageView';
import Title from './TitleView';

import { StyledWrap, StyledBtnWrap } from './styled';

interface IListGridProps {
  layout?: ECollectionLayoutOptions | null;
  items: CardSchema[];
  loadMore: () => void;
  loading: boolean;
  showActivity?: boolean | null;
  hideActionsInfo?: boolean;
  hasLoadBtn?: boolean;
  totalCount?: number;
  pageTestId?: string;
  isInCollaborativeList?: boolean;
  showDefaultView?: boolean;
  isDarkFromProps?: boolean;
}

const ListGrid: FC<IListGridProps> = ({
  items,
  loading,
  loadMore,
  layout,
  showActivity,
  hideActionsInfo,
  hasLoadBtn = false,
  totalCount,
  pageTestId,
  isInCollaborativeList,
  showDefaultView,
  isDarkFromProps,
}) => {
  const isDarkFromTheme = useTheme();
  const isDark = isDarkFromProps ?? isDarkFromTheme;
  const referrerId = useReferrerId();

  const isDefault = !layout || layout === ECollectionLayoutOptions.Default;
  const canShowDefaultView = showDefaultView || items.length > 3;

  const [loadingBtn, setLoadingBtn] = useState(false);

  const onLoadMore = async () => {
    setLoadingBtn(true);
    await loadMore();
    setLoadingBtn(false);
  };

  return (
    <RefIdProvider value={{ referrerId }}>
      <StyledWrap className="listGrid" hideActionsInfo={hideActionsInfo}>
        {layout === ECollectionLayoutOptions.Title && <Title pageTestId={pageTestId} items={items} isDark={isDark} />}
        {layout === ECollectionLayoutOptions.ImageGrid && (
          <Image items={items} isInCollaborativeList={isInCollaborativeList} />
        )}
        {layout === ECollectionLayoutOptions.Detail && <Detail pageTestId={pageTestId} items={items} isDark={isDark} />}
        {isDefault &&
          (canShowDefaultView ? (
            <Default showActivity={showActivity} items={items} isDark={isDark} />
          ) : (
            <Detail items={items} isDark={isDark} />
          ))}
        {loading && <LoaderContent isDark={isDark} isFullScreen={!items.length} />}
        {!loading && hasLoadBtn && !!totalCount && totalCount > items.length && (
          <StyledBtnWrap>
            <Button
              handler={onLoadMore}
              type={EUiButtonType.Primary}
              isDark={isDark}
              loading={loadingBtn}
              text="Show more"
            />
          </StyledBtnWrap>
        )}
        {!loading && !hasLoadBtn && <Waypoint onEnter={loadMore} />}
      </StyledWrap>
    </RefIdProvider>
  );
};

export default ListGrid;
