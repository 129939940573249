import React, { FC } from 'react';

import { useBlockedUsers } from 'graphQL/blocking/hooks';
import { EButtonType } from 'components/UserList/constants';
import { TEST_BLOCKED_ACCOUNTS_EMPTY_LABEL } from 'constants/aqa/settings/accountManagement';

import useTheme from 'helpers/useTheme';
import UserList from 'components/UserList';

const Users: FC = () => {
  const { items, loading, loadMore } = useBlockedUsers();
  const isDark = useTheme();

  return (
    <UserList
      buttonType={EButtonType.Block}
      loadMore={loadMore}
      emptyMessage="You have not blocked any accounts"
      isDark={isDark}
      users={items}
      loading={loading}
      emptyLabelTestId={TEST_BLOCKED_ACCOUNTS_EMPTY_LABEL}
    />
  );
};

export default Users;
