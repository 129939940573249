import { styled } from 'linaria/react';

import { COLORS } from 'styles/constants';

export const StyledList = styled.ul<{ noPaddings?: boolean; isInFlow?: boolean; isHidden: boolean }>`
  background-color: ${COLORS.brown.dark[100]};
  border-radius: 100px;
  bottom: 34px;
  box-shadow: 0px 11px 6px ${COLORS.black[10]}, 0px 5px 5px ${COLORS.black[20]}, 0px 1px 3px ${COLORS.black[20]},
    0px 0px 0px ${COLORS.black[20]};
  display: flex;
  gap: 24px;
  left: 50%;
  list-style: none;
  margin: 0;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  padding: 4px ${({ noPaddings }) => (noPaddings ? 4 : 24)}px;
  pointer-events: ${({ isHidden }) => (isHidden ? 'none' : 'all')};
  position: ${({ isInFlow }) => (isInFlow ? 'static' : 'fixed')};
  transform: ${({ isInFlow }) => (isInFlow ? 'none' : 'translateX(-50%)')};

  path {
    stroke: ${COLORS.white[100]};
  }
`;

export const StyledButton = styled.button`
  align-items: center;
  background-color: ${COLORS.transparent};
  border: none;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 0;
  width: 50px;
`;

export const StyledAvatarWrap = styled.div<{ hasBorder: boolean }>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 30px;
  position: relative;
  width: 30px;

  &::before {
    border: 1.5px solid ${({ hasBorder }) => (hasBorder ? COLORS.white[100] : COLORS.transparent)};
    border-radius: 50%;
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
  }
`;
