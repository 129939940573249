import { styled } from 'linaria/react';
import { COLORS, FONTS } from '../../../styles/constants';

const StyledWarning = styled.p<{ isDark?: boolean }>`
  border-radius: 20px;
  border: 1px solid ${COLORS.white.off[100]};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 14px/140% ${FONTS.secondary};
  margin: 0 0 16px;
  padding: 16px;
`;

export default StyledWarning;
