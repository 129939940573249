import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import Avatar from 'components/UI/Avatar';
import IconCircle from 'components/UI/IconCircle/IconCircle';

import { useThought } from 'helpers/useContextMenu';
import useOpenModal from 'helpers/useOpenModal';
import { useProfile } from 'graphQL/profile/hooks';
import { ROUTE_ADD_TO_LIST } from 'routes';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';

import { StyledWrap, StyledCaption, StyledEntity, StyledActionName } from './styled';

const ActionEntity: FC<{
  pickId: string;
  cardId: string;
  isPickedByMe: boolean;
}> = ({ pickId, cardId, isPickedByMe }) => {
  const { userData } = useProfile();
  const { listId } = useParams<{ listId?: string }>();
  const toggle = useOpenModal(ROUTE_ADD_TO_LIST, false, { cardId, pickId, isPickFlow: true, listId });
  const { toggle: openThought } = useThought(pickId, cardId, listId);

  const handleAction = () => (isPickedByMe ? openThought?.() : toggle());

  return (
    <StyledWrap onClick={handleAction}>
      <StyledCaption>
        {isPickedByMe ? 'Be the first to add your perspective ' : 'Pick this and add your perspective'}
      </StyledCaption>
      <StyledEntity>
        {userData && <Avatar user={userData} />}
        <StyledActionName>{isPickedByMe ? 'Add a thought' : 'Pick this content'}</StyledActionName>
        <IconCircle Icon={PlusIcon} hasBorder hasStroke isDark />
      </StyledEntity>
    </StyledWrap>
  );
};

export default ActionEntity;
