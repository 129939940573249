import React, { FC, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ROUTE_MESSAGES_CHAT } from 'routes';

import { useMenuModal } from 'components/Navigation/helpers/hooks';
import { StyledItem } from 'components/AddContent/Menu/styled';
import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/Email.svg';
import { ReactComponent as BlockIcon } from 'assets/icons/Block.svg';
import { ReactComponent as MuteIcon } from 'assets/icons/Mute.svg';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import { useGetUserId, useProfile } from 'graphQL/profile/hooks';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { useCreateNewChat } from 'graphQL/messages/hooks';
import { transformRoute } from 'helpers/routingHelper';
import { ChatType, EVisibilityStatusOptions, MessageType } from 'constants/graphqlTypes';

import Button from 'components/UI/MenuButton';
import BlockButton from 'components/Blocking/UserButton';
import MuteUserButton from 'components/Muting/MuteUserButton';
import { EReportTypes } from 'components/Report/constants';
import { useUserSettings } from 'graphQL/contextualIntro/hooks';
import { TEST_ID_PROFILE_VIEW_OPTION_BUTTON } from 'constants/aqa/profile';
import MenuWrap from '../MenuWrap';
import ShareButtons from '../ShareButtons';

const AnotherProfileButton: FC = () => {
  const { profileName } = useParams<{ profileName: string }>();
  const { push } = useHistory();
  const { isModalOpen, handleModal } = useMenuModal();
  const { userId } = useGetUserId(profileName);
  const { userData } = useProfile(userId);
  const { cardId, isBlockedByMe, isBlockedMe, isMutedByMe, isFollowingMe } = userData ?? {};
  const { messagesAndThoughtsVisibility } = useUserSettings(userId);
  const { pathname } = useLocation();

  const fullname = generateFullName(userData);

  const isAllowedToMessage =
    messagesAndThoughtsVisibility === EVisibilityStatusOptions.Everyone ||
    (messagesAndThoughtsVisibility === EVisibilityStatusOptions.Following && isFollowingMe);

  const toReport = useNavigateToReport({
    userCardId: cardId,
    pathname,
    isBlockedByMe,
    id: userId,
    type: EReportTypes.User,
  });

  const { createChat } = useCreateNewChat((chatId: string) =>
    push(transformRoute(ROUTE_MESSAGES_CHAT, { id: chatId }))
  );

  const handleReport = () => {
    handleModal(false);
    toReport();
  };

  const [isCreateThreadDisabled, setIsCreateThreadDisabled] = useState(false);

  const handleCreateThread = () => {
    if (userId) {
      createChat([userId], ChatType.Single);
      setIsCreateThreadDisabled(true);
    }
  };

  return (
    <>
      <MenuWrap
        isOpen={isModalOpen}
        handleModal={handleModal}
        dotMenuBtnDataTestId={TEST_ID_PROFILE_VIEW_OPTION_BUTTON}
      >
        {userData && (
          <>
            {userId && (
              <ShareButtons
                closePreviousModal={() => handleModal(false)}
                messageType={MessageType.User}
                sharedId={userId}
              />
            )}
            {!isBlockedMe && !isBlockedByMe && isAllowedToMessage && (
              <StyledItem>
                <Button
                  disabled={isCreateThreadDisabled}
                  text={`Message ${generateFullName(userData)}`}
                  Icon={EmailIcon}
                  handler={handleCreateThread}
                  hasTruncate
                />
              </StyledItem>
            )}
            {userId && (
              <>
                <BlockButton userId={userId} isBlockedByMe={!!isBlockedByMe} handler={() => handleModal(false)}>
                  <StyledItem>
                    <Button
                      checked={!!isBlockedByMe}
                      Icon={BlockIcon}
                      text={`${isBlockedByMe ? 'Unblock' : 'Block'} user`}
                    />
                  </StyledItem>
                </BlockButton>
                <MuteUserButton
                  userId={userId}
                  isMutedByMe={!!isMutedByMe}
                  fullname={fullname}
                  handler={() => handleModal(false)}
                >
                  <StyledItem>
                    <Button text={`${isMutedByMe ? 'Unmute' : 'Mute'} user`} Icon={MuteIcon} />
                  </StyledItem>
                </MuteUserButton>
              </>
            )}
          </>
        )}
        <StyledItem>
          <Button text="Report user" Icon={ReportIcon} handler={handleReport} />
        </StyledItem>
      </MenuWrap>
    </>
  );
};

export default AnotherProfileButton;
