import React, { ReactNode, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import Truncate from 'react-truncate-markup';

import SliderInteractive from 'components/SliderInteractive';
import useTheme from 'helpers/useTheme';

import { COLORS } from 'styles/constants';
import { StyledCardWrap, StyledContainer } from 'components/Sliders/styled';
import { StyledTitle } from 'components/List/Content/styled';
import { StyledDescription } from 'components/List/styled';

interface IUniversalSliderProps<T> {
  items: T[];
  itemRender: (item: T, isCurrent?: boolean, imagesKey?: string) => ReactNode;
  title?: string | null;
  description?: string | null;
  slidesPerView?: number;
  isInfinite?: boolean;
  removeButtons?: boolean;
  constantOffset?: number;
  touchStartPreventDefault?: boolean;
  slideToClickedSlide?: boolean;
}

const UniversalSlider = <T extends { id: string }>({
  items,
  itemRender,
  title,
  description,
  isInfinite = false,
  slidesPerView = 1.2,
  removeButtons,
  constantOffset,
  touchStartPreventDefault,
  slideToClickedSlide,
}: IUniversalSliderProps<T>): JSX.Element => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [imagesKey, setImagesKey] = useState('');

  const isDark = useTheme();
  const count = items?.length ?? 0;

  const onChange = (index: number) => {
    setSlideIndex(index);
    setImagesKey(`key-${index}`);
  };

  return (
    <>
      {title && (
        <Truncate lines={2}>
          <StyledTitle isExplore isDark={isDark} total={count}>
            {title}
          </StyledTitle>
        </Truncate>
      )}
      {description && <StyledDescription isDark={isDark}>{description}</StyledDescription>}
      {items && count > 1 ? (
        <StyledContainer>
          <SliderInteractive
            slideToClickedSlide={slideToClickedSlide}
            touchStartPreventDefault={touchStartPreventDefault}
            customSlidesPerView={slidesPerView}
            onSlideChange={onChange}
            spaceBetween={13}
            showBtnsSm={!removeButtons}
            constantOffset={constantOffset}
            fontColor={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
            offset={12}
            isInfinite={isInfinite}
            isPrevDisabled={slideIndex === 0 && !isInfinite}
            isNextDisabled={(slideIndex === count - Math.floor(slidesPerView) || slidesPerView > count) && !isInfinite}
          >
            {items.map((item, index) => (
              <SwiperSlide key={item.id}>{itemRender(item, index === slideIndex, imagesKey)}</SwiperSlide>
            ))}
          </SliderInteractive>
        </StyledContainer>
      ) : (
        items?.[0] && <StyledCardWrap>{itemRender(items[0])}</StyledCardWrap>
      )}
    </>
  );
};

export default UniversalSlider;
