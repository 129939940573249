import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

export const StyledWrap = styled.div`
  margin: 60px auto;
  max-width: 528px;
  padding: 0 12px;
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin: 0;
  padding: 16px 0;

  li {
    list-style-type: none;
  }
`;

export const StyledTitle = styled.span<{ isDark: boolean; total?: number }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: block;
  font-size: 32px;
  line-height: 120%;
  padding: 32px 0 16px;
  position: relative;
  word-wrap: break-word;

  &::after {
    content: ${({ total }) => (Number.isInteger(total) ? `"${total}"` : 'none')};
    font-size: 14px;
    line-height: 140%;
    padding-left: 2px;
    position: relative;
    top: -15px;
  }
`;

export const StyledDescription = styled.div`
  padding: 0 12px;
`;

export const StyledListWrap = styled.div`
  padding-top: 40px;
`;
