import React, { FC, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import Modal from 'components/Modal';
import LoaderContent from 'components/UI/LoaderContent';
import Button from 'components/UI/MenuButton';
import ListItem from 'components/UI/ListItem';
import useGuideTour from 'helpers/useGuideTour';

import { getListStyles } from 'components/Collection/helpers';
import { ReactComponent as ListIcon } from 'assets/icons/List.svg';
import { useGetLists } from 'graphQL/addContent/addToList/hooks';
import { ALL_PICKS } from 'constants/common';
import { StyledTitle } from 'components/AddContent/Menu/styled';
import {
  StyledHr,
  StyledList,
  StyledListTitle,
  StyledWrap,
  StyledWrapListTitle,
} from 'components/AddContent/AddToList/styled';
import { IAddContentLocation, ISetExtractData } from 'components/AddContent/helpers/models';

import OrganizeLater from 'components/AddContent/AddToList/OrganizeLater';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { useAnswerLinks } from './hooks';

interface IAddToListProps {
  pickId: string;
  setExtractData: ({ extractPickId, extractCardId, extractTitle, extractImage }: ISetExtractData) => void;
  setListToAdd: Dispatch<SetStateAction<string>>;
  setListTitle: Dispatch<SetStateAction<string>>;
  handleClose: () => void;
}

const AddToList: FC<IAddToListProps> = ({ pickId, setExtractData, handleClose, setListToAdd, setListTitle }) => {
  const { replace } = useHistory();

  const { addAnswerLink, addAnswerStyle, addAnswerThought, addList } = useAnswerLinks();
  const { state } = useLocation<IAddContentLocation | undefined>();
  const { variable: stateData } = state ?? {};
  const { id: stateId, fromAddStyle } = stateData ?? {};

  const itemId = stateId ?? pickId;
  const { items, loading, loadMore } = useGetLists(itemId);
  const { isGuideTour } = useGuideTour();

  const handleGoBack = () => replace(fromAddStyle ? addAnswerStyle : addAnswerThought);
  const lists = isGuideTour ? [] : items;
  const hasItems = !!lists.length;

  useEffect(() => {
    if (!itemId) {
      replace(addAnswerLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(() => {
    if (stateId) {
      setExtractData({ extractCardId: stateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId]);

  const handleItemClick = (title: string, listCardId?: string) => {
    setListTitle(title);
    setListToAdd(listCardId ?? '');
    handleGoBack();
  };

  const [showExitWarning, setShowExitWarning] = useState(false);
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  return (
    <Modal
      handleClose={handleClose}
      loading={loading}
      isExitConfirmed={isExitConfirmed}
      setShowConfirm={setShowExitWarning}
    >
      <StyledTitle>
        {hasItems ? `Which list do you want to add it to?` : 'Create lists to organize your picks'}
      </StyledTitle>
      <StyledWrap>
        {hasItems && (
          <>
            <OrganizeLater isDisabled={!pickId} handler={() => handleItemClick(ALL_PICKS)} />
            <StyledWrapListTitle>
              <StyledListTitle>Your lists</StyledListTitle>
            </StyledWrapListTitle>
          </>
        )}
        <StyledList>
          {lists.map((item) => {
            const { id: listId, cardId: listCardId, title, cardsFromCollection } = item;
            const { urls, bgColor } = getListStyles(item);

            return (
              <ListItem
                key={listId}
                title={title ?? ''}
                isDark
                isEnable={!!cardsFromCollection?.totalCount}
                image={urls[urls.length - 1]}
                bgColor={bgColor}
                handleClick={() => handleItemClick(title ?? '', listCardId)}
              />
            );
          })}
          {loading ? <LoaderContent isFullScreen={!items.length} /> : <Waypoint onEnter={loadMore} />}
        </StyledList>
      </StyledWrap>
      {!hasItems && (
        <>
          <OrganizeLater isDisabled={!pickId} handler={() => handleItemClick(ALL_PICKS)} />
          <StyledHr />
        </>
      )}
      <Button text="Create a list" Icon={ListIcon} handler={() => replace(addList)} />
      {showExitWarning && (
        <ConfirmationModal
          handleCancel={() => setShowExitWarning(false)}
          handleClickOutside={() => setShowExitWarning(false)}
          handleAccept={() => setIsExitConfirmed(true)}
          isWarning
          buttonText="Discard"
          titleText="Discard pick?"
          subTitle="Your edits and thoughts will be discarded if you leave before posting."
        />
      )}
    </Modal>
  );
};

export default AddToList;
