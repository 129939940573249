import { CardSchema, Maybe } from 'constants/graphqlTypes';
import getAuthUser from 'helpers/getAuthUser';

import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_CARD_DETAIL, ROUTE_LIST_DETAIL, ROUTE_USER_ALL_PICKS, ROUTE_USER_PROFILE } from 'routes';

import { ReactComponent as FacebookIcon } from 'assets/icons/brandedContent/Facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/brandedContent/Instagram.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/brandedContent/LinkedIn.svg';
import { ReactComponent as MastodonIcon } from 'assets/icons/brandedContent/Mastodon.svg';
import { ReactComponent as MediumIcon } from 'assets/icons/brandedContent/Medium.svg';
import { ReactComponent as RedditIcon } from 'assets/icons/brandedContent/Reddit.svg';
import { ReactComponent as SpillIcon } from 'assets/icons/brandedContent/Spill.svg';
import { ReactComponent as ThreadsIcon } from 'assets/icons/brandedContent/Threads.svg';
import { ReactComponent as TiktokIcon } from 'assets/icons/brandedContent/Tiktok.svg';
import { ReactComponent as XTwitterIcon } from 'assets/icons/brandedContent/XTwitter.svg';

export const getPathName = (id?: string, isList?: boolean, hasAllPicks?: boolean): string => {
  const { username } = getAuthUser();

  if (!id) {
    return transformRoute(hasAllPicks ? ROUTE_USER_ALL_PICKS : ROUTE_USER_PROFILE, { profileName: username });
  }

  return transformRoute(isList ? ROUTE_LIST_DETAIL : ROUTE_CARD_DETAIL, { listId: id, pickId: id });
};

export const checkIsTwitterCard = (card: CardSchema) => {
  const domain = card.externalSources?.items?.[0]?.sourceDomainName;

  return domain === 'x.com' || domain === 'twitter.com' || card.mediaTags?.items?.[0]?.name === 'tweets';
};

export const getBrandedIcon = (sourceName?: Maybe<string>) => {
  switch (sourceName) {
    case 'facebook':
      return FacebookIcon;
    case 'instagram':
      return InstagramIcon;
    case 'linkedin':
      return LinkedInIcon;
    case 'mastodon':
      return MastodonIcon;
    case 'medium':
      return MediumIcon;
    case 'reddit':
      return RedditIcon;
    case 'spill':
      return SpillIcon;
    case 'threads':
      return ThreadsIcon;
    case 'tiktok':
      return TiktokIcon;
    case 'twitter':
      return XTwitterIcon;
    default:
      return '';
  }
};

export const getCardTagCaption = ({
  isVideo,
  isTweet,
  cardHeader,
  tag,
}: {
  isVideo: boolean;
  isTweet: boolean;
  cardHeader?: string;
  tag?: string | null;
}): string => {
  if (cardHeader) {
    return cardHeader;
  }
  if (isTweet && !isVideo) {
    return 'post';
  }
  return tag || '';
};
