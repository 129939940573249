import React, { FC, Dispatch, Fragment, SetStateAction, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import Modal from 'components/Modal';
import ContentTitle from 'components/UI/ContentTitle';
import ListItem from 'components/UI/ListItem';
import LoaderContent from 'components/UI/LoaderContent';
import Button from 'components/UI/Button';
import { useGetProfileTopics } from 'graphQL/profile/topics/hooks';
import { useGetFeedTopicsData, useGetHeadTopics, useUpdateFeed } from 'graphQL/feeds/hooks';
import Input from 'components/UI/Input';
import { EToggleType } from 'components/UI/Toggle/models';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { EUiButtonType } from 'components/UI/Button/constants';
import { SortOrderEnum, TagSchema } from 'constants/graphqlTypes';

import { StyledButtonWrap } from 'components/TopicsModal/styled';

import {
  StyledAllTopicsWrapper,
  StyledContentContainer,
  StyledSearchContainer,
  StyledTopicsList,
  StyledTopicsLabel,
} from 'components/CustomFeeds/CreateFeed/Views/SelectFeedCreateTopics/styled';
import { DEFAULT_TOPIC_GRADIENT } from 'styles/constants';
import { DisplaySections, ESelectionMode } from '../../../constants';
import { handleClickAllTopics, handleTopicClick, isTopicChecked, removeTopicIdFromArray } from './helpers';

const { All, Select, Deselect } = ESelectionMode;
interface ISelectFeedEditTopicsProps {
  selectionMode: ESelectionMode;
  setSelectionMode: Dispatch<SetStateAction<ESelectionMode>>;
  selectedTopics: string[];
  setSelectedTopics: Dispatch<SetStateAction<string[]>>;
  deselectedTopics: string[];
  setDeselectedTopics: Dispatch<SetStateAction<string[]>>;
  totalSelectedTopics: number;
  setTotalSelectedTopics: Dispatch<SetStateAction<number>>;
}

const SelectFeedEditTopics: FC<ISelectFeedEditTopicsProps> = ({
  selectionMode,
  setSelectionMode,
  selectedTopics,
  setSelectedTopics,
  deselectedTopics,
  setDeselectedTopics,
  totalSelectedTopics,
  setTotalSelectedTopics,
}) => {
  const { goBack } = useHistory();
  const handleExit = () => goBack();

  /** INTERNAL REACT STATE */
  const [isAllTopics, setIsAllTopics] = useState<boolean | null>(null);
  const [searchString, setSearchString] = useState('');
  const [clickedChecked, setClickedChecked] = useState<string[]>([]);
  const [clickedUnchecked, setClickedUnchecked] = useState<string[]>([]);

  const setClickedTopicIds = (topic: TagSchema) => {
    const isTopicWithCheckmark = isTopicChecked({ topic, selectedTopics, deselectedTopics });
    const { id } = topic;

    // add topic to unchecked array if selection mode is All and we clicked on topic with checkmark
    // 1. click on All topics button
    // 2. click on some topic with checkmark
    if (selectionMode === All && isTopicWithCheckmark) {
      setClickedUnchecked((prev) => [...prev, id]);
      return;
    }

    // we add topic to unchecked array if we are deselecting topics(All topics button was active previously and some topic was deselected) and topic has checkmark
    // 1. click on All topics button
    // 2. click on any topic to set selection mode to Deselect
    // 3. click on another topic with checkmark(NOT on topic from step 2)
    if (selectionMode === Deselect && isTopicWithCheckmark) {
      setClickedUnchecked((prev) => [...prev, id]);
      return;
    }
    // we remove topic from unchecked array if we are deselecting topics(All topics button was active previously and some topic was deselected) and topic has NO checkmark
    // 1. click on All topics button
    // 2. click on any topic to set selection mode to Deselect
    // 3. click on the same topic with checkmark(on topic from step 2)
    if (selectionMode === Deselect && !isTopicWithCheckmark) {
      removeTopicIdFromArray(setClickedUnchecked, id);
      return;
    }
    // we remove topic from unchecked array if we are:
    // 1. we have 1 topic from BE
    // 2. click on this topic to remove checkmark
    // 3. click again on this topic to set checkmark back (now the topic is checked)
    if (selectionMode === Select && !isTopicWithCheckmark && clickedUnchecked.includes(id)) {
      removeTopicIdFromArray(setClickedUnchecked, id);
      return;
    }
    // we add topic to checked array if we are:
    // 1. we have topics that are NOT selected originally from BE(2 or more topics are NOT selected)
    // 2. click on topic without checkmark
    if (selectionMode === Select && !isTopicWithCheckmark && !clickedUnchecked.includes(id)) {
      setClickedChecked((prev) => [...prev, id]);
      return;
    }
    // we remove topic from checked array if we are:
    // 1. we have topics that are NOT selected originally from BE(2 or more topics are NOT selected)
    // 2. click on topic without checkmark
    // 3. click on this topic again
    if (selectionMode === Select && isTopicWithCheckmark && !topic.isSelectedInFeed && clickedChecked.includes(id)) {
      removeTopicIdFromArray(setClickedChecked, id);
      return;
    }

    // we remove topic from checked array if we are:
    // 1. we have topics that are NOT selected originally from BE(2 or more topics are NOT selected)
    // 2. click on topic with checkmark
    if (selectionMode === Select && isTopicWithCheckmark && topic.isSelectedInFeed && !clickedUnchecked.includes(id)) {
      removeTopicIdFromArray(setClickedChecked, id);
      setClickedUnchecked((prev) => [...prev, id]);
    }
  };

  /** LOCATION STATE */
  const { state } = useLocation<{ variable: { title: string; allExistingTopicsCount?: number; feedId: string } }>();
  const { title: tabTitle, allExistingTopicsCount, feedId } = state?.variable ?? {}; /** Title of the custom feed */

  const profileTopicsData = useGetProfileTopics(undefined, SortOrderEnum.Asc, feedId); /** FIRST SECTION TOPICS */

  const headTopicsData = useGetHeadTopics(undefined, feedId); /** SECOND SECTION TOPICS */
  const { totalCount, isLoading: headTopicsLoading, isQueryCalled } = headTopicsData ?? {};
  const searchHeadTopicsData = useGetHeadTopics(searchString, feedId); /** SECTION TOPICS WITH ACTIVE SEARCH */
  const { totalCount: feedTopicsTotalCount, loading: feedTopicsLoading } = useGetFeedTopicsData(feedId);

  const showGeneralLoader =
    headTopicsLoading && profileTopicsData.isLoading && !isQueryCalled && !profileTopicsData.isQueryCalled;

  const displayingSections = [
    !searchString && { ...profileTopicsData, title: 'Your interests' },
    !!searchString && { ...searchHeadTopicsData },
    !searchString && { ...headTopicsData, title: 'All topics' },
  ].reduce((prev: DisplaySections, curr) => (curr ? prev.concat(curr) : prev), []);

  const getIsAllTopicsForMutation = (): boolean | undefined => {
    // if getIsAllTopicsForMutation returns undefined -> BE will summarize old tags and new tags(both included and excluded)
    // if getIsAllTopicsForMutation returns false -> BE will replace old tags with new tags
    // if getIsAllTopicsForMutation returns true -> BE will set all topics and then removed excluded topics from all topics list

    // we have selection mode Deselect(All topics button was active previously and some topic was deselected) or we clicked on deselected All topics button
    if (selectionMode === Deselect || isAllTopics) {
      return true;
    }

    // selection mode is Select and we have deselected some topics and we have not clicked
    // 1. we have 2 topics from BE with checkmark(or more than 2 topics)
    // 2. click on topic with checkmark
    // 3. click on All topics button
    // 4. click on All topics button again
    // 5. click on topic from step 2
    if (selectionMode === Select && !!deselectedTopics.length && !clickedUnchecked.length && !selectedTopics.length) {
      return false;
    }
    // 1. we have 3 topics from BE
    // 2. click on 3rd topic(with checkmark)
    // 3. click on 4th topic(without checkmark)
    // 4. click on All topics button
    // 5. click on All topics button again
    // 6. click on 3rd topic
    // 7. click on 2nd topic
    // 8. click on 2nd topic again
    if (
      selectionMode === Select &&
      !!deselectedTopics.length &&
      !!clickedUnchecked.length &&
      !selectedTopics.length &&
      !!clickedChecked.length
    ) {
      return false;
    }
    // we clicked on All topics button with checkmark
    if (selectionMode === Select && isAllTopics === false) {
      return false;
    }
    return undefined;
  };

  const { updateFeed } = useUpdateFeed({
    feedId,
    title: tabTitle,
    isAllTopics: getIsAllTopicsForMutation(),
    excludedTopics: clickedUnchecked,
    includedTopics: clickedChecked,
  });

  const handleSubmitEditing = () => {
    updateFeed();
    handleExit();
  };

  useEffect(() => {
    // if we manually click on allTopics button and previously allTopics were NOT SELECTED
    if (isAllTopics === true) {
      // we create new array with all existing topics and make every topic selected
      // we should not send this array during updateFeed mutation,
      // we need this array only for correct visual representation of selected topics
      const newSelectedTopics = [...(profileTopicsData.topics ?? []), ...(headTopicsData.topics ?? [])].reduce(
        (prev: string[], curr) => (!curr.isSelectedInFeed && !prev.includes(curr.id) ? prev.concat(curr.id) : prev),
        []
      );
      setSelectedTopics(newSelectedTopics);
      setDeselectedTopics([]);
      setTotalSelectedTopics(allExistingTopicsCount ?? 0);
      return;
    }

    // if we manually click on allTopics button and previously allTopics were SELECTED
    if (isAllTopics === false) {
      // we create new array with all existing topics and make every topic deselected
      // we should not send this array during updateFeed mutation,
      // we need this array only for correct visual representation of deselected topics
      const newDeselectedTopics = [...(profileTopicsData.topics ?? []), ...(headTopicsData.topics ?? [])].reduce(
        (prev: string[], curr) => (curr.isSelectedInFeed && !prev.includes(curr.id) ? prev.concat(curr.id) : prev),
        []
      );
      setDeselectedTopics(newDeselectedTopics);
      setSelectedTopics([]);
      setTotalSelectedTopics(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllTopics, profileTopicsData.topics.length, headTopicsData.topics.length]);

  useEffect(() => {
    // we reset internal state on component unmount
    return () => {
      setSelectedTopics([]);
      setDeselectedTopics([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // initial selected topics, number we show in continue button before any topic is selected or deselected.
    setTotalSelectedTopics(feedTopicsTotalCount);
    // we set initial totalCount and initial selectionMode
    if (feedTopicsTotalCount === totalCount && !feedTopicsLoading && !headTopicsLoading) {
      setSelectionMode(All);
    } else if (feedTopicsTotalCount !== totalCount && !feedTopicsLoading && !headTopicsLoading) {
      setSelectionMode(Select);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedTopicsTotalCount, totalCount, feedTopicsLoading, headTopicsLoading]);

  return (
    <Modal handleClose={handleExit} handlePrev={handleExit} enableInnerScroll>
      <StyledContentContainer hasPaddingBottom={!!searchString}>
        <ContentTitle isDark>Select the topics you’re interested in</ContentTitle>
        <StyledSearchContainer>
          <Input
            Icon={SearchIcon}
            placeholder="Search"
            isDark
            setText={setSearchString}
            text={searchString}
            hasCrossIcon
          />
          {searchString && (
            <Button text="Cancel" handler={() => setSearchString('')} isDark type={EUiButtonType.Transparent} />
          )}
        </StyledSearchContainer>
        {showGeneralLoader ? (
          <LoaderContent isFullScreen />
        ) : (
          <>
            {!searchString && (
              <StyledAllTopicsWrapper>
                <ListItem
                  title="All topics"
                  isDark
                  handleClick={() => {
                    handleClickAllTopics(setSelectionMode, setIsAllTopics);
                    setClickedChecked([]);
                    setClickedUnchecked([]);
                  }}
                  hasToggle
                  toggleType={EToggleType.CheckPlus}
                  ghLogoParams={{ dimension: 44 }}
                  isEnable={selectionMode === All}
                  bgColor={DEFAULT_TOPIC_GRADIENT}
                />
              </StyledAllTopicsWrapper>
            )}
            {displayingSections.map(({ title, isLoading: sectionLoading, loadMoreTopics, topics }, sectionIndex) => (
              <>
                <StyledTopicsLabel>{title}</StyledTopicsLabel>
                <StyledTopicsList hasPaddingBottom={sectionIndex === displayingSections.length - 1 && !sectionLoading}>
                  {topics?.map((topic, index) => {
                    const { name, id, imageSet } = topic;

                    return (
                      <Fragment key={id}>
                        {name && id && (
                          <ListItem
                            title={name}
                            isDark
                            handleClick={() => {
                              handleTopicClick({
                                topic,
                                selectedTopics,
                                setSelectedTopics,
                                deselectedTopics,
                                setDeselectedTopics,
                                setTotalSelectedTopics,
                                setIsAllTopics,
                                setSelectionMode,
                                allExistingTopicsCount,
                                setClickedTopicIds,
                              });
                            }}
                            image={imageSet?.images?.[0].url}
                            hasToggle
                            toggleType={EToggleType.CheckPlus}
                            bgColor={DEFAULT_TOPIC_GRADIENT}
                            isEnable={isTopicChecked({ topic, selectedTopics, deselectedTopics })}
                          />
                        )}
                        {/* we need index === topics.length - 3 because otherwise Continue button overlaps waypoint and blocks loadmore execution  */}
                        {!sectionLoading && index === topics.length - 3 && <Waypoint onEnter={loadMoreTopics} />}
                      </Fragment>
                    );
                  })}
                  {sectionLoading && <LoaderContent isFullScreen={!topics.length} />}
                </StyledTopicsList>
              </>
            ))}
          </>
        )}
        {!!totalSelectedTopics && (
          <StyledButtonWrap>
            <Button
              handler={handleSubmitEditing}
              text={`Continue with ${totalSelectedTopics} topic${totalSelectedTopics === 1 ? '' : 's'}`}
            />
          </StyledButtonWrap>
        )}
      </StyledContentContainer>
    </Modal>
  );
};

export default SelectFeedEditTopics;
