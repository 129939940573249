import { useEffect, useState } from 'react';

import { useGetOnboardingPickCategoriesQuery, useGetOnboardingPickCategoryPicksQuery } from 'constants/graphqlTypes';
import { EQueryFetchPolicy } from 'constants/common';

import { IUseGetCategoryCards, IUseGetOnboardingPickCategories } from './models';
import { getUpdatedCategoriesCardsQuery, getUpdatedPickCategoriesQuery, reduceItems } from './helpers';

export const useGetOnboardingPickCategories = (): IUseGetOnboardingPickCategories => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const { data, loading, fetchMore } = useGetOnboardingPickCategoriesQuery({ variables: { limit: 10, offset: 0 } });
  const { categoryItems, totalCount } = data?.allTutorialCategories ?? {};

  useEffect(() => {
    const shouldLoadMore = !loading && totalCount && categoryItems?.length && categoryItems?.length < totalCount;
    if (shouldLoadMore) {
      fetchMore({
        variables: { offset: categoryItems?.length },
        updateQuery: (prev, { fetchMoreResult }) => getUpdatedPickCategoriesQuery(prev, fetchMoreResult),
      });
    }
  }, [categoryItems?.length, totalCount, loading]);

  useEffect(() => {
    // sets first category active by default on first load
    if (activeCategory === null && categoryItems?.length) {
      setActiveCategory(categoryItems?.[0]?.id);
    }
  }, [categoryItems?.length]);

  return {
    categoryItems: reduceItems(categoryItems) ?? [],
    activeCategory,
    setActiveCategory,
  };
};

export const useGetCategoryCards = (categoryId: string | null): IUseGetCategoryCards => {
  const { data, loading, fetchMore } = useGetOnboardingPickCategoryPicksQuery({
    skip: !categoryId,
    variables: { limit: 5, offset: undefined, category: categoryId },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const { items, totalCount } = data?.allTutorialCategories?.categoryItems?.[0].categoryPicks ?? {};

  const reducedCards = reduceItems(items);

  const loadMore = () => {
    if (items?.length && totalCount && items.length < totalCount) {
      fetchMore({
        variables: { offset: items?.length },
        updateQuery: (prev, { fetchMoreResult }) => getUpdatedCategoriesCardsQuery(prev, fetchMoreResult),
      });
    }
  };

  return { cards: reducedCards, loading, loadMore };
};
