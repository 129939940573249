import { ESearchCardType } from 'constants/graphqlTypes';
import {
  TEST_SEARCH_RESULTS_LISTS_PAGE_LABEL,
  TEST_SEARCH_RESULTS_PEOPLE_PAGE_LABEL,
  TEST_SEARCH_RESULTS_PICKS_PAGE_LABEL,
} from 'constants/aqa/search';

export const searchResultTabs = [
  {
    text: 'Picks',
    dataTestId: TEST_SEARCH_RESULTS_PICKS_PAGE_LABEL,
    type: ESearchCardType.Card,
  },
  {
    text: 'Lists',
    dataTestId: TEST_SEARCH_RESULTS_LISTS_PAGE_LABEL,
    type: ESearchCardType.Collection,
  },
  {
    text: 'People',
    dataTestId: TEST_SEARCH_RESULTS_PEOPLE_PAGE_LABEL,
    type: ESearchCardType.User,
  },
];
