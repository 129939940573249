import { useEffect } from 'react';
import { isAndroid } from './useUserAgent';

const useKeyboardOffset = (): void => {
  const root = document.documentElement;

  const setCSSProp = () => {
    setTimeout(
      () =>
        root.style.setProperty(
          '--keyboard-offset',
          `${Math.abs(Math.floor(window.innerHeight - (window.visualViewport?.height ?? window.innerHeight)))}px`
        ),
      10
    );
  };

  useEffect(() => {
    if (!isAndroid()) {
      setCSSProp();

      window.addEventListener('resize', setCSSProp);
      window.addEventListener('scroll', setCSSProp);
      window.visualViewport?.addEventListener('scroll', setCSSProp);
      window.visualViewport?.addEventListener('resize', setCSSProp);
    }

    return () => {
      if (!isAndroid()) {
        window.removeEventListener('resize', setCSSProp);
        window.removeEventListener('scroll', setCSSProp);
        window.visualViewport?.removeEventListener('scroll', setCSSProp);
        window.visualViewport?.removeEventListener('resize', setCSSProp);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useKeyboardOffset;
