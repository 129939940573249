import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Badge from 'components/UI/Badge';
import ConfirmationModal from 'components/UI/ConfirmationModal';

import { ROUTE_MESSAGES_CHAT } from 'routes';
import { ChatType, UserShortSchema } from 'constants/graphqlTypes';
import { useCreateNewChat } from 'graphQL/messages/hooks';
import { transformRoute } from 'helpers/routingHelper';

import { StyledContentWrap } from '../styled';

const UserItem: FC<{ user: UserShortSchema; isDark: boolean }> = ({ user, isDark }) => {
  const [error, setError] = useState(false);
  const { push } = useHistory();

  const { createChat } = useCreateNewChat(
    (chatId: string) => push(transformRoute(ROUTE_MESSAGES_CHAT, { id: chatId })),
    setError
  );

  return user ? (
    <>
      <StyledContentWrap onClick={() => createChat([user.id], ChatType.Single)}>
        <Badge isDark={isDark} hasUsername user={user} isDisabled />
      </StyledContentWrap>
      {error && (
        <ConfirmationModal
          buttonText="OK"
          handleAccept={() => setError(false)}
          handleClickOutside={() => setError(false)}
          isWarning={false}
          titleText="Unable to send message"
          subTitle="You can't message this person because they've chosen not to receive messages."
        />
      )}
    </>
  ) : null;
};

export default UserItem;
