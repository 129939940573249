import { MAX_ARROW_LEFT, MIN_ARROW_LEFT } from './constants';

export const getCodeFromEmoji = (emoji: string): string =>
  [...emoji]
    .map((e) => e.codePointAt(0)?.toString(16))
    .join(' ')
    .toUpperCase();

export const parseToEmoji = (emojiCode: string): string =>
  emojiCode
    .split(' ')
    .map((code) => String.fromCodePoint(parseInt(code, 16)))
    .join('');

export const getEdgeArrowPosition = (left: string): string => {
  const num = Number(left.replace('px', ''));

  if (num < MIN_ARROW_LEFT) {
    return `${MIN_ARROW_LEFT}px`;
  }

  return num > MAX_ARROW_LEFT ? `${MAX_ARROW_LEFT}px` : left;
};
