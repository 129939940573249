import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';

import LoaderContent from 'components/UI/LoaderContent';
import { CardSchema, CardTypeOptions } from 'constants/graphqlTypes';
import { getSearchCardTestId } from 'constants/aqa/search';
import SmallCard from 'components/List/SmallCard';

interface ListsProps {
  items: CardSchema[];
  loadMore: () => void;
  loading: boolean;
  isDark: boolean;
  pageTestId?: string;
}

const Lists: FC<ListsProps> = ({ items, loadMore, loading, isDark, pageTestId }) => (
  <>
    {items.map((item, index) => {
      // temporory as there isn't a new disign of guest-lists, guest-lists are hidden
      const isNotGuestList = item.type !== CardTypeOptions.GuestListSeries && item.type !== CardTypeOptions.GuestList;
      return (
        isNotGuestList && (
          <SmallCard dataTestId={getSearchCardTestId(index, pageTestId)} key={item.id} item={item} showActivity />
        )
      );
    })}
    {loading ? <LoaderContent isFullScreen={!items.length} isDark={isDark} /> : <Waypoint onEnter={loadMore} />}
  </>
);

export default Lists;
