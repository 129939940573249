import { createContext, useContext } from 'react';

const CardContext = createContext({});

export const { Provider: CardProvider } = CardContext;

const useCardContext = () => {
  const context = useContext<{ cardHeader?: string }>(CardContext);

  return context;
};

export default useCardContext;
