import { styled } from 'linaria/react';

import { COLORS, FONTS } from '../../../styles/constants';

export const StyledQuestionLabel = styled.div`
  align-items: center;
  background-color: ${COLORS.white.off[100]};
  border-radius: 8px;
  color: ${COLORS.brown.dark[100]};
  display: flex;
  font: 400 22px/130% ${FONTS.primary};
  height: 44px;
  justify-content: center;
  min-width: 44px;
  margin-right: 12px;
  position: relative;
  width: 44px;
`;

export const StyledThumbnail = styled.div<{ src?: string | null; bg?: string }>`
  background: ${({ src, bg }) => (src ? `url('${src}')` : bg || COLORS.white[30])};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  height: 44px;
  min-width: 44px;
  width: 44px;
  margin-right: 12px;

  &::after {
    content: '';
    padding-bottom: 100%;
    display: block;
  }
`;
