import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Layout from 'components/Onboarding/Layout';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { useDeleteMyProfile } from 'graphQL/profile/hooks';
import FullScreenLoader from 'components/FullScreenLoader';
import { CollectionUserType } from 'constants/graphqlTypes';

const ConfirmAccountDeletion: FC = () => {
  const [isModalShowed, setIsModalShowed] = useState(false);
  const { go } = useHistory();
  const { state } = useLocation<{ newAdmins: CollectionUserType[]; shouldDeletePicks: boolean; pagesToMenu: number }>();
  const { newAdmins, shouldDeletePicks, pagesToMenu } = state;
  const { deleteMyProfile, loading: isDeleteLoading } = useDeleteMyProfile(newAdmins, shouldDeletePicks);

  const handleDelete = () => {
    setIsModalShowed(false);
    deleteMyProfile();
  };

  return (
    <Layout
      heading="Confirm permanent account deletion"
      text="You are about to delete your account. This cannot be undone. Please confirm this is what you want to do."
      primaryBtnText="Confirm and delete my account"
      secondaryBtnText="Cancel"
      isSecondaryBtnTransparent
      isWideContent
      handlePrimaryBtn={() => setIsModalShowed(true)}
      handleSecondaryBtn={() => go(pagesToMenu)}
      isPrimaryBtnRed
    >
      {isModalShowed && (
        <ConfirmationModal
          titleText="Delete my account"
          subTitle="Are you sure you want to delete your account? You cannot undo this."
          buttonText="delete"
          handleAccept={handleDelete}
          handleCancel={() => setIsModalShowed(false)}
        />
      )}
      {isDeleteLoading && <FullScreenLoader />}
    </Layout>
  );
};

export default ConfirmAccountDeletion;
