import { IUpdateSelectedIds } from './models';

export const updateSelectedIds = ({
  topicId,
  isSelected,
  topics,
  setIdsToAdd,
  setIdsToRemove,
}: IUpdateSelectedIds): void => {
  const wasFollowed = !!topics.find(({ id }) => id === topicId)?.isFollowEnable;
  if (wasFollowed && isSelected) {
    setIdsToRemove((prev) => prev.filter((el) => el !== topicId));
  } else if (wasFollowed && !isSelected) {
    setIdsToRemove((prev) => [...prev, topicId]);
  } else if (!wasFollowed && isSelected) {
    setIdsToAdd((prev) => [...prev, topicId]);
  } else {
    setIdsToAdd((prev) => prev.filter((el) => el !== topicId));
  }
};
