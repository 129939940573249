import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledSeeMore = styled.div<{ isDark: boolean }>`
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.dark[10])};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  font: 400 22px/130 ${FONTS.primary};
  height: 438px;
  justify-content: center;
`;
