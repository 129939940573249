import React, { FC } from 'react';
import { XBlock, XMasonry } from 'react-xmasonry';

import { CardSchema, ECardLayoutOptions } from 'constants/graphqlTypes';
import { MASONRY_ITEM_WIDTH } from '../helpers';
import { StyledWrap } from './styled';

import Item from './Item';

const ImageView: FC<{ items: CardSchema[]; isInCollaborativeList?: boolean }> = ({ items, isInCollaborativeList }) => (
  <StyledWrap>
    <XMasonry targetBlockWidth={MASONRY_ITEM_WIDTH} maxColumns={2}>
      {items
        .filter((card) => card.cardStyle?.layout !== ECardLayoutOptions.Plain)
        .map((item) => (
          <XBlock width={1} key={item.id}>
            <Item item={item} isInCollaborativeList={isInCollaborativeList} />
          </XBlock>
        ))}
    </XMasonry>
  </StyledWrap>
);

export default ImageView;
