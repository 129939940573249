import React, { FC, Fragment } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { Waypoint } from 'react-waypoint';
import { useHistory } from 'react-router-dom';
import { ROUTE_FEEDS_BY_TOPIC, ROUTE_FEEDS_BY_TYPE } from 'routes';

import { useGetChannelsSections } from 'graphQL/channel/hooks';
import { ChannelSectionTypeOptions } from 'constants/graphqlTypes';

import useTheme from 'helpers/useTheme';

import LoaderContent from 'components/UI/LoaderContent';
import CustomFeedSection from 'components/Explore/ChannelsTab/CustomFeedSection';
import AdminFeedsSection from './AdminFeedsSection';
import ListSection from './ListSection';

import { StyledHeaderWrap } from './styled';

const { FollowList, CustomFeeds, AdminFollowByTopic, AdminFollowByType, TrendingList } = ChannelSectionTypeOptions;

const ChannelsTab: FC = () => {
  const { sections, loading, loadMore } = useGetChannelsSections();
  const { push } = useHistory();
  const isDark = useTheme();

  return (
    <>
      <StyledHeaderWrap>
        <TypeAnimation
          preRenderFirstString
          wrapper="p"
          cursor={false}
          sequence={['', 200, 'Diverse ways to explore cutting-edge content', 2000]}
          speed={65}
        />
      </StyledHeaderWrap>
      {sections?.map((section) => (
        <Fragment key={section.id}>
          {(section.sectionType === FollowList || section.sectionType === TrendingList) && (
            <ListSection section={section} />
          )}
          {section.sectionType === CustomFeeds && <CustomFeedSection section={section} />}
          {section.sectionType === AdminFollowByTopic && (
            <AdminFeedsSection
              handler={() => push(ROUTE_FEEDS_BY_TOPIC)}
              section={section}
              feedType="Feeds By Topic"
              isDark={isDark}
            />
          )}
          {section.sectionType === AdminFollowByType && (
            <AdminFeedsSection
              handler={() => push(ROUTE_FEEDS_BY_TYPE)}
              section={section}
              feedType="Feeds By Type"
              isDark={isDark}
            />
          )}
        </Fragment>
      ))}
      {loading ? <LoaderContent isFullScreen={!sections?.length} isDark={isDark} /> : <Waypoint onEnter={loadMore} />}
    </>
  );
};

export default ChannelsTab;
