import { styled } from 'linaria/lib/react';
import { BREAKPOINTS, COLORS, FONTS } from '../../../styles/constants';

export const StyledWeeklyPicksSection = styled.section`
  background: ${COLORS.white[100]};
  padding: 40px 0 20px;
  margin: 0;

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    padding: 50px 0 30px;
  }
`;

export const StyledCarouselHeader = styled.h2`
  color: ${COLORS.brown.dark[100]};
  font: 400 32px/120% ${FONTS.primary};
  font-feature-settings: 'clig' off, 'liga' off;
  padding-left: 20px;
  margin: 0;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding-left: 50px;
    font: 400 60px/120% ${FONTS.primary};
  }
`;

export const StyledCarouselSubHeader = styled.p`
  color: ${COLORS.brown.dark[100]};
  margin: 0;
  padding: 0 0 10px 20px;
  font: 600 14px/140% ${FONTS.secondary};

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0 0 15px 50px;
    font: 600 20px/130% ${FONTS.secondary};
  }
`;

export const StyledCarouselContainer = styled.div`
  padding-top: 50px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding-top: 46px;
  }

  .swiper-wrapper {
    transition-timing-function: linear !important;
  }

  .swiper-slide {
    max-width: 170px;

    @media screen and (min-width: ${BREAKPOINTS.xl}px) {
      max-width: 13%;
    }
  }
`;
