import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { v4 } from 'uuid';

import useTheme from 'helpers/useTheme';
import { IAnimatedPlaceholderProps } from './models';

import { StyledAnimatedLabel, StyledContainer, StyledLabelWrap } from './styled';

const AnimatedPlaceholder: FC<IAnimatedPlaceholderProps> = ({ items }) => {
  const isDark = useTheme();
  return (
    <StyledContainer isDark={isDark}>
      <span>Examples:</span>
      <StyledLabelWrap>
        <StyledAnimatedLabel>
          <Swiper
            modules={[Autoplay]}
            loop
            spaceBetween={10}
            speed={800}
            autoplay={{ delay: 2400, disableOnInteraction: false }}
            direction="vertical"
          >
            {items.map((item) => (
              <SwiperSlide key={v4()}>{`“${item}”`}</SwiperSlide>
            ))}
          </Swiper>
        </StyledAnimatedLabel>
      </StyledLabelWrap>
    </StyledContainer>
  );
};

export default AnimatedPlaceholder;
