import React, { FC, useMemo, useEffect } from 'react';

import getImages from 'helpers/getImages';
import getAuthUser from 'helpers/getAuthUser';

import { useIsUserAllowedToReact, useListItemUrl } from 'components/Card/helpers/hooks';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import { useReactionContextState, ReactionsProvider } from 'components/Reactions/hooks';
import useReferrerId from 'helpers/useReferrerId';
import { CardSchema } from 'constants/graphqlTypes';
import InteractionBlock from 'components/InteractionBlock';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import ReactionsModal from 'components/Reactions/ReactionsModal';
import { StyledWrap } from 'components/Collection/styled';
import { isVideoCard } from 'helpers/isVideoCard';
import { StyledImage, StyledImageContainer, StyledLink, StyledPlayIcon } from './styled';

const Item: FC<{ item: CardSchema; isInCollaborativeList?: boolean }> = ({ item, isInCollaborativeList }) => {
  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  const {
    setThoughtId,
    thoughtId,
    setPickerId,
    pickerId,
    cardId,
    setCardId,
    user,
    setUser,
  } = useReactionContextState();

  const { cardStyle, id, sourceName } = item;
  const { imageUrl } = getImages(cardStyle);
  const { userId } = getAuthUser();

  const isVideo = isVideoCard(item);

  const url = useListItemUrl(id);

  const isCurrentUserPick = useMemo(() => pickerId === userId, [pickerId]);

  const isCardAllowedToReact = useIsUserAllowedToReact(item?.extraData?.items?.[0]?.user);
  const isFallbackAllowedToReact = useIsUserAllowedToReact(user);
  const isCardBlocked = !thoughtId && !isCardAllowedToReact;

  const disableLongTap = isCurrentUserPick || !isFallbackAllowedToReact || isCardBlocked || isInCollaborativeList;

  const referrerId = useReferrerId();
  useEffect(() => {
    setCardId(id);

    if (item?.extraData?.items?.length || 0) {
      setPickerId(item?.pickedByUsers?.items?.[0]?.id ?? referrerId ?? null);
    }
  }, []);

  return imageUrl ? (
    <ReactionsProvider
      value={{
        isQuickReactionOpen,
        setThoughtId,
        thoughtId,
        setPickerId,
        pickerId,
        cardId,
        setCardId,
        user,
        setUser,
      }}
    >
      <StyledWrap ref={infoRef} disableLinks={isQuickReactionOpen}>
        <InteractionBlock
          tapCallback={() => setIsQuickReactionOpen(false)}
          longTapCallback={() => !disableLongTap && setIsQuickReactionOpen(true)}
          getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
        >
          <StyledLink to={url}>
            <StyledImageContainer>
              <StyledImage src={imageUrl} />
              {isVideo && sourceName !== 'tiktok' && <StyledPlayIcon />}
            </StyledImageContainer>
          </StyledLink>
        </InteractionBlock>
        {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && !isCurrentUserPick && (
          <ReactionMenu
            isShown={isQuickReactionOpen}
            reactionCoordinates={reactionCoordinates}
            handleClickOutside={() => setIsQuickReactionOpen(false)}
            minusTop={minusTop}
            setIsReactionsModalOpen={setIsReactionsModalOpen}
            setIsQuickReactionOpen={setIsQuickReactionOpen}
          />
        )}
        {isReactionsModalOpen && <ReactionsModal id={id} handleClose={() => setIsReactionsModalOpen(false)} />}
      </StyledWrap>
    </ReactionsProvider>
  ) : null;
};

export default Item;
