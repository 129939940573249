import { useLocation, useParams } from 'react-router-dom';

import { IDetailSliderListItem } from 'graphQL/cards/exploreCards/models';
import { EQueryFetchPolicy } from 'constants/common';
import {
  useGetDetailCardLazyQuery,
  useGetDetailCardQuery,
  useGetCardIdLazyQuery,
  useGetThoughtsCardQuery,
  CardSchema,
} from 'constants/graphqlTypes';
import { useContentNotFound } from 'helpers/routingHelper';

import { IUseGetDetailCard, IUseGetLazyCard, IUseLazyGetCardId } from './models';

export const useGetDetailCard = (
  id?: string,
  listPickId?: string | null,
  skip?: boolean,
  refId?: string
): IUseGetDetailCard => {
  const { state } = useLocation<{ fallbackRefId?: string; guestListId?: string; feedId?: string }>();
  const handlerContentNotFound = useContentNotFound();

  const { fallbackRefId, guestListId, feedId } = state ?? {};

  const { data, error, loading } = useGetDetailCardQuery({
    variables: {
      id,
      ...((refId || fallbackRefId) && { fallbackReferrerId: fallbackRefId ?? refId }),
      ...(listPickId && { refListId: listPickId }),
      ...(guestListId && { refListId: guestListId }),
      ...(feedId && { refListId: feedId }),
      ...(feedId && { refFeedId: feedId }),
    },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    skip: !id || skip,
  });

  if (error) {
    handlerContentNotFound();
  }

  return { card: data?.allCards?.items?.[0], loading };
};

export const useLazyGetDetailCard = (): IUseGetLazyCard => {
  const { listId, feedId } = useParams<{ listId?: string; feedId?: string }>();
  const { state } = useLocation<{ fallbackRefId?: string }>();
  const { fallbackRefId } = state ?? {};
  const [query, { data, loading }] = useGetDetailCardLazyQuery();

  const getLazyCard = ({ id }: IDetailSliderListItem) =>
    query({
      variables: {
        id,
        ...(fallbackRefId && { fallbackReferrerId: fallbackRefId }),
        ...(listId && { refListId: listId }),
        ...(feedId && { refFeedId: feedId }),
        ...(feedId && !listId && { refListId: feedId }),
      },
    });

  return { getLazyCard, lazyCard: data?.allCards?.items?.[0], loading };
};

export const useLazyGetCardId = (callback?: () => void): IUseLazyGetCardId => {
  const [checkUrl, { data, loading }] = useGetCardIdLazyQuery({
    fetchPolicy: EQueryFetchPolicy.NetworkOnly,
    onCompleted: (result) => result.allCards?.totalCount === 0 && callback?.(),
  });

  const isAvailable = data?.allCards?.totalCount === 0;
  const { id: pickId, cardId, isPickedByMe, isSavedByMe } = data?.allCards?.items[0] ?? {};

  const checkPickExist = (externalLink: string) => checkUrl({ variables: { externalLink } });

  return {
    checkPickExist,
    isAvailable,
    isPickedByMe: !!isPickedByMe,
    pickId,
    cardId,
    isSavedByMe: !!isSavedByMe,
    loading,
  };
};

export const useGetThoughtsCard = (id?: string): CardSchema | undefined => {
  const { data } = useGetThoughtsCardQuery({
    variables: { id },
    skip: !id,
  });

  return data?.allCards?.items?.[0];
};
