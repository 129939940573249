import { cloneElement, FC, TouchEvent, MouseEvent, useRef, useState, useEffect } from 'react';

interface InteractionBlockProps {
  longTapCallback?: () => void;
  tapCallback?: () => void;
  swipeSize?: number;
  swipeLeftCallback?: () => void;
  swipeRightCallback?: () => void;
  swipeDownCallback?: () => void;
  getTapCoordinates?: (x: number, y: number) => void;
  longTapWait?: number;
  children: JSX.Element;
  touchEndCallback?: () => void;
}

const InteractionBlock: FC<InteractionBlockProps> = ({
  children,
  getTapCoordinates,
  longTapCallback,
  swipeLeftCallback,
  swipeRightCallback,
  swipeDownCallback,
  touchEndCallback,
  tapCallback,
  swipeSize = 60,
  longTapWait = 300,
}) => {
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchStartY, setTouchStartY] = useState<number | null>(null);

  const timer = useRef<number>();
  const handleClearTimer = () => clearTimeout(timer.current);
  const handleTimer = () => {
    timer.current = +setTimeout(() => longTapCallback?.(), longTapWait);
  };

  const handleTouchStart = (x: number, y: number) => {
    setTouchStartX(x);
    setTouchStartY(y);
    getTapCoordinates?.(x, y);
    handleTimer();
    tapCallback?.();
  };

  const handleTouchEnd = () => {
    setTouchStartX(null);
    setTouchStartY(null);
    handleClearTimer();
    touchEndCallback?.();
  };

  const handleTouchMove = (x: number, y: number) => {
    if (touchStartX && touchStartX - x > swipeSize) {
      handleClearTimer();
      swipeLeftCallback?.();
    }

    if (touchStartX && x - touchStartX > swipeSize) {
      handleClearTimer();
      swipeRightCallback?.();
    }

    if (touchStartY && y - touchStartY > swipeSize) {
      handleClearTimer();
      swipeDownCallback?.();
    }
  };

  useEffect(() => {
    let timeout = 0;

    if (touchStartX && touchStartY) {
      timeout = +setTimeout(() => document.body.classList.add('disable-user-action'), 200);
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }
      document.body.classList.remove('disable-user-action');
    }

    return () => {
      clearTimeout(timeout);
      document.body.classList.remove('disable-user-action');
    };
  }, [touchStartX, touchStartY]);

  const events = {
    onTouchStart: ({ touches }: TouchEvent<HTMLElement>) => handleTouchStart(touches[0].clientX, touches[0].clientY),
    onMouseDownCapture: ({ clientX, clientY }: MouseEvent<HTMLElement>) => handleTouchStart(clientX, clientY),
    onTouchMove: ({ touches }: TouchEvent<HTMLElement>) => handleTouchMove(touches[0].clientX, touches[0].clientY),
    onMouseMoveCapture: ({ clientX, clientY }: MouseEvent<HTMLElement>) => handleTouchMove(clientX, clientY),
    onTouchEnd: handleTouchEnd,
    onMouseUpCapture: handleTouchEnd,
    onMouseLeave: handleTouchEnd,
  };

  return cloneElement(children, events);
};

export default InteractionBlock;
