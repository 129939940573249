import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import Grain from 'components/UI/Grain';
import Slider from 'components/List/Header/Slider';
import InteractiveReactionMenu from 'components/Reactions/InteractiveReactionMenu';
import useTheme from 'helpers/useTheme';
import usePageType from 'helpers/usePageType';

import { ImageSetSchema } from 'constants/graphqlTypes';
import { useNavigationRef } from 'components/Navigation/helpers/hooks';
import { COLORS } from 'styles/constants';
import { getGradient } from './helper';
import { StyledWrap, StyledImage } from './styled';

interface IListHeaderProps {
  bgColor: string;
  images: ImageSetSchema[];
  titles: string[];
  hasReactions?: boolean;
  isCursorGrab?: boolean;
}

const ListHeader: FC<IListHeaderProps> = ({ bgColor, images, titles, hasReactions, isCursorGrab }) => {
  const { listId } = useParams<{ listId?: string }>();
  const isDark = useTheme();
  const { isAllPicks } = usePageType();
  const { firstColor, secondColor } = getGradient(bgColor);

  const fontColor = isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  const ref = useNavigationRef(isAllPicks && !images.length && !titles.length ? fontColor : COLORS.white[100]);
  const imagesUrl = images.map((image) => image?.images?.[0]?.url || '');

  const firstImage = images[0]?.images?.[0]?.url;
  const imagesCount = images?.length ?? 0;
  const isText = imagesCount === 0;
  const isOneImage = imagesCount > 0 && imagesCount < 3;
  const isSliderImages = imagesCount >= 3;
  const uniqueTitles = [...new Set(titles)];

  return (
    <InteractiveReactionMenu
      entityId={listId ?? ''}
      isReactionDisabled={!hasReactions || !listId}
      isCursorGrab={isCursorGrab}
    >
      <StyledWrap
        ref={ref}
        bgColor={bgColor}
        firstColor={firstColor}
        isDark={isDark}
        isOneImage={isOneImage}
        isSliderImages={isSliderImages}
        secondColor={secondColor}
        isAllPicks={isAllPicks}
      >
        {!!(images.length || titles.length) && <Grain />}
        {isText && titles && <Slider items={uniqueTitles} isImages={false} isAllPicks={isAllPicks} />}
        {isOneImage && firstImage && <StyledImage src={firstImage} />}
        {isSliderImages && imagesUrl && <Slider items={imagesUrl} isImages />}
      </StyledWrap>
    </InteractiveReactionMenu>
  );
};

export default ListHeader;
