import React, { useEffect, FC } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';

import { useFeaturedFlagEnabled, usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS, EFeaturedFlags } from 'constants/posthogEvents';
import { getGradientImage } from 'components/Sparks/constants';
import { useNavigationRef } from 'components/Navigation/helpers/hooks';
import { COLORS } from 'styles/constants';
import { useGetSparkDetail, useGetSparkDetailCards } from 'graphQL/sparks/hooks';
import { ROUTE_DISCOVERY, ROUTE_SPARK_RESP_ADD_PICK } from 'routes';
import BadgeWithFollowing from 'components/UI/BadgeWithFollowing';
import ListGrid from 'components/List/Grid/ListGrid';
import Grain from 'components/UI/Grain';
import useOpenModal from 'helpers/useOpenModal';
import useToast from 'helpers/useToast';
import { getNumberOfResponsesText, NO_RESPONSES_PLACEHOLDER } from 'helpers/sparks';

import useTheme from 'helpers/useTheme';

import {
  StyledInfoContainer,
  StyledPageHeader,
  StyledResponseButton,
  StyledResponseCounter,
  StyledResponseCounterContainer,
  StyledSparkTitle,
  StyledUserContainer,
  StyledNoResponsePlaceholder,
  StyledListWrap,
} from './styled';

const SparkDetail: FC = () => {
  const posthogCapture = usePostHogCapture();
  const isInteractSparks = useFeaturedFlagEnabled(EFeaturedFlags.InteractSpark);
  const isViewSparks = useFeaturedFlagEnabled(EFeaturedFlags.ViewSparks);

  const ref = useNavigationRef(COLORS.white[100]);
  const isDark = useTheme();

  const { spark, isQueryCalled, error } = useGetSparkDetail();
  const { user, title } = spark ?? {};

  const { predefinedGradientName } = spark?.cardStyle?.bgColor ?? {};
  const bgImage = getGradientImage(predefinedGradientName ?? '');

  const { items, loading, totalCount, loadMore } = useGetSparkDetailCards(spark);

  const portalContainer = document.querySelector('#navigation-container');

  const handleAddResponse = useOpenModal(ROUTE_SPARK_RESP_ADD_PICK, false, { spark });

  const { replace } = useHistory();
  const { setToast } = useToast();

  useEffect(() => {
    if (isQueryCalled && (!spark || error)) {
      replace(ROUTE_DISCOVERY);
      setToast({ isToastOpen: true, toastItemName: 'Content not found' });
    }
  }, [isQueryCalled, error, spark]);

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.QotDOpen);
    posthogCapture(POSTHOG_EVENTS.SparkAction);
  }, []);

  return user && isViewSparks ? (
    <>
      <StyledPageHeader ref={ref} bgImage={bgImage}>
        <StyledInfoContainer>
          <StyledSparkTitle>
            <span>{title}</span>
          </StyledSparkTitle>
          <StyledUserContainer>
            <div>
              <BadgeWithFollowing user={user} />
            </div>
          </StyledUserContainer>
        </StyledInfoContainer>
        <Grain />
      </StyledPageHeader>

      {!loading && (
        <StyledResponseCounterContainer>
          {!totalCount ? (
            <StyledNoResponsePlaceholder isDark={isDark}>{NO_RESPONSES_PLACEHOLDER}</StyledNoResponsePlaceholder>
          ) : (
            <StyledResponseCounter isDark={isDark}>{getNumberOfResponsesText(totalCount)}</StyledResponseCounter>
          )}
        </StyledResponseCounterContainer>
      )}
      <StyledListWrap>
        <ListGrid
          items={items ?? []}
          loading={loading}
          loadMore={loadMore}
          showActivity
          hasLoadBtn
          totalCount={totalCount}
        />
      </StyledListWrap>
      {portalContainer &&
        isInteractSparks &&
        createPortal(
          <StyledResponseButton onClick={handleAddResponse}>Add your response</StyledResponseButton>,
          portalContainer
        )}
    </>
  ) : null;
};

export default SparkDetail;
