import { styled } from 'linaria/react';

import { COLORS } from 'styles/constants';

export const StyledArrow = styled.div<{ degree: number; isDark?: boolean }>`
  transform: ${({ degree }) => `rotate(${degree}deg)`};

  path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;
