import React, { FC, useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import AnimatedBackground from 'components/AnimatedBackground';

import { StyledWrapper } from './styled';
import { useWelcomeRedirect } from './hooks';

const Welcome: FC = () => {
  const posthogCapture = usePostHogCapture();

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.OnboardingInviteCodeSuccess);
  }, []);

  useWelcomeRedirect();

  return (
    <AnimatedBackground>
      <StyledWrapper>
        <TypeAnimation wrapper="span" cursor={false} sequence={[500, 'You’re in!']} speed={35} />
      </StyledWrapper>
    </AnimatedBackground>
  );
};

export default Welcome;
