import React, { FC, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';

import { ROUTE_ADD_SPARK } from 'routes';

import { ReactComponent as PlusSmallIcon } from 'assets/icons/PlusSmall.svg';

import useTheme from 'helpers/useTheme';
import useOpenModal from 'helpers/useOpenModal';

import Card from 'components/Sparks/Views/Card';
import Button from 'components/UI/Button';
import LoaderContent from 'components/UI/LoaderContent';

import useSparksTabs from 'components/Sparks/hooks';
import { ESparkTabs } from 'components/Sparks/constants';
import { EUiButtonType } from 'components/UI/Button/constants';
import { useGetFeaturedSparkCards, useGetSparkCards } from 'graphQL/sparks/hooks';
import { useFeaturedFlagEnabled, usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS, EFeaturedFlags } from 'constants/posthogEvents';

import { StyledList as StyledTabsList } from 'components/Messages/MessagesTabs/styled';
import { StyledDescription, StyledList, StyledTitle, StyledWrap } from 'pages/AllSparks/styled';
import { StyledStartButton } from './styled';

const Sparks: FC = () => {
  const posthogCapture = usePostHogCapture();
  const isDark = useTheme();

  const isCreateSparkEnabled = useFeaturedFlagEnabled(EFeaturedFlags.CreateSpark);
  const isViewSparks = useFeaturedFlagEnabled(EFeaturedFlags.ViewSparks);

  const { items, loadMore, totalCount, loading } = useGetSparkCards();
  const {
    items: featuredItems,
    loadMore: featuredLoadMore,
    totalCount: featuredTotalCount,
    loading: featuredLoading,
  } = useGetFeaturedSparkCards();
  const { tab, setTab } = useSparksTabs();

  const sparks = tab === ESparkTabs.Featured ? featuredItems : items;
  const currTotalCount = tab === ESparkTabs.Featured ? featuredTotalCount : totalCount;
  const currLoadMore = tab === ESparkTabs.Featured ? featuredLoadMore : loadMore;
  const currLoading = tab === ESparkTabs.Featured ? featuredLoading : loading;

  const handleAddSpark = useOpenModal(ROUTE_ADD_SPARK, true);

  useEffect(() => {
    if (!featuredTotalCount && totalCount) {
      setTab(ESparkTabs.All);
    }
  }, [featuredTotalCount, totalCount]);

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.SparkAction);
    posthogCapture(POSTHOG_EVENTS.OpenAllQuestionPage);
  }, []);

  return (
    <StyledWrap>
      {sparks && isViewSparks && (
        <>
          <StyledDescription>
            <StyledTitle isDark={isDark}>Sparks</StyledTitle>
            <StyledTabsList>
              {!!featuredItems.length && (
                <li>
                  <Button
                    type={tab === ESparkTabs.Featured ? EUiButtonType.Emphasized : EUiButtonType.Primary}
                    isDark={isDark}
                    handler={() => setTab(ESparkTabs.Featured)}
                    text={ESparkTabs.Featured}
                  />
                </li>
              )}
              <li>
                <Button
                  type={tab === ESparkTabs.All ? EUiButtonType.Emphasized : EUiButtonType.Primary}
                  isDark={isDark}
                  handler={() => setTab(ESparkTabs.All)}
                  text={ESparkTabs.All}
                />
              </li>
              {isCreateSparkEnabled && (
                <li>
                  <StyledStartButton onClick={handleAddSpark} isDark={isDark} buttonType={EUiButtonType.Primary}>
                    <PlusSmallIcon />
                    <span>Start a spark</span>
                  </StyledStartButton>
                </li>
              )}
            </StyledTabsList>
          </StyledDescription>
          <StyledList>
            {sparks.map(
              (card) =>
                card.user && (
                  <li key={card.cardId}>
                    <Card card={card} isDark={isDark} />
                  </li>
                )
            )}
            {!currLoading && <Waypoint onEnter={() => sparks.length < currTotalCount && currLoadMore()} />}
          </StyledList>
        </>
      )}
      {currLoading && <LoaderContent isDark={isDark} isFullScreen={!(sparks && sparks.length)} />}
    </StyledWrap>
  );
};

export default Sparks;
