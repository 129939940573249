import React, { FC } from 'react';

import AnimatedEmoji from './AnimatedEmoji';

import { StyledAnimationWrap } from './styled';

const TokenAnimation: FC<{ emoji: string }> = ({ emoji }) => {
  const timers = [200, 300, 500, 600, 700, 800, 900];
  const xCoords = [...Array(7)].map((_el, index) => Math.round(((index % 2) - Math.random()) * 120));

  return (
    <StyledAnimationWrap>
      {timers.map((timer, index) => (
        <AnimatedEmoji xCoord={xCoords[index]} key={timer} timeToAppear={timer} emoji={emoji} />
      ))}
    </StyledAnimationWrap>
  );
};

export default TokenAnimation;
