import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledCheckIcon = styled.div<{ isDark: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;

  path {
    stroke: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;
export const StyledWrapCircle = styled.div`
  padding-bottom: 34px;
`;

export const StyledCircle = styled.div<{ isDark: boolean }>`
  align-items: center;
  border-radius: 72px;
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.dark[10])};
  display: flex;
  height: 64px;
  justify-content: center;
  margin: 0 auto;
  width: 64px;
`;

export const StyledTitle = styled.span`
  font-family: ${FONTS.primary};
`;

export const StyledFooter = styled.footer<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 14px/140% ${FONTS.secondary};
  margin: 0 auto;
  max-width: ${BREAKPOINTS.s}px;
  padding: 48px 0;
  text-align: center;
`;
