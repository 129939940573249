import React, { FC, useEffect, useState } from 'react';

import { parseToEmoji } from 'components/Reactions/helpers/helpers';
import { useFullScreenAnimationContext } from 'components/Reactions/Animations/Fullscreen/hooks';
import { useReactionContext } from 'components/Reactions/hooks';
import useMessagesThreadContext from 'helpers/useMessagesThreadProvider';

import { StyledSharedContentReaction } from './styled';

const ReactionButton: FC<{ emojiCode: string }> = ({ emojiCode }) => {
  const [zIndex, setZIndex] = useState(10);
  const { makeAnimation } = useFullScreenAnimationContext();

  const { isQuickReactionOpen } = useReactionContext();

  const emoji = parseToEmoji(emojiCode);

  const { doesBlurExist } = useMessagesThreadContext();

  useEffect(() => {
    setZIndex(doesBlurExist && !isQuickReactionOpen ? 0 : 10);
  }, [doesBlurExist, isQuickReactionOpen]);

  return (
    <StyledSharedContentReaction zIndex={zIndex} onClick={() => makeAnimation?.(emoji)}>
      {emoji}
    </StyledSharedContentReaction>
  );
};

export default ReactionButton;
