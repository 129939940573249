import React, { FC, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { CardSchema, HighlightSchema } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';
import { TEST_CARD_IMMERSIVE, getCardSourceLabelId } from 'constants/aqa/card';

import handleVibrate from 'helpers/handleVibrate';
import getStyles from 'helpers/getStyles';
import getImages from 'helpers/getImages';
import Grain from 'components/UI/Grain';
import Content from '../Common/Content';
import Source from '../Common/Source';
import Highlight from '../Common/Highlight';
import Actions from '../Actions';

import { StyledFooter } from '../Common/styled';
import { StyledLink } from '../FullBleed/styled';
import { StyledWrap, StyledComment, StyledImage, StyledImageContainer, StyledContainer } from './styled';
import { useItemUrl } from '../helpers/hooks';

const Immersive: FC<{ card: CardSchema; highlight?: HighlightSchema | null; dataTestId?: string }> = ({
  card,
  highlight,
  dataTestId,
}) => {
  const { cardStyle, id } = card;
  const { bgColor } = getStyles(cardStyle);
  const { imageUrl } = getImages(cardStyle);

  const url = useItemUrl(id);
  const { push } = useHistory();
  const backgroundRef = useRef<HTMLDivElement>(null);

  return (
    <StyledContainer>
      {imageUrl && (
        <StyledImageContainer>
          <StyledImage src={imageUrl} alt="image for card" />
        </StyledImageContainer>
      )}
      <StyledWrap
        ref={backgroundRef}
        bg={imageUrl ? COLORS.transparent : bgColor}
        isReverse={false}
        onClick={({ target }) => {
          if (target === backgroundRef.current) {
            push(url);
            handleVibrate();
          }
        }}
      >
        <Grain />
        <StyledLink to={url} onClick={handleVibrate}>
          <Content dataTestId={dataTestId ?? TEST_CARD_IMMERSIVE} card={card} hugeTitle />
        </StyledLink>
        <StyledFooter color={COLORS.white[100]}>
          <Source dataTestId={getCardSourceLabelId(dataTestId ?? TEST_CARD_IMMERSIVE)} card={card} />
        </StyledFooter>
        <StyledComment>
          <Actions withoutPadding item={card} fontColor={COLORS.white[100]} />
        </StyledComment>
        {highlight && <Highlight highlight={highlight} />}
      </StyledWrap>
    </StyledContainer>
  );
};

export default Immersive;
