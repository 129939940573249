import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import Badge from 'components/UI/Badge';
import { UserShortSchema } from 'constants/graphqlTypes';
import { useSearchChatMembers } from 'graphQL/search/hooks';
import { getMentionText } from 'helpers/messagesMentions';

import { StyledMentionSuggestionsContainer, StyledItem } from './styled';

interface IMentionSuggestionsProps {
  text: string;
  isDark: boolean;
  handleMention: (mentionedUser: UserShortSchema, text: string, mention: string) => void;
}

const MentionSuggestions: FC<IMentionSuggestionsProps> = ({ text, isDark, handleMention }) => {
  const { id } = useParams<{ id: string }>();
  const mentionText = getMentionText(text);

  const { items, loadMore } = useSearchChatMembers(mentionText ?? '', { inChat: id });

  return (
    <StyledMentionSuggestionsContainer isDark={isDark}>
      {!!items.length &&
        items.map((user) => (
          <StyledItem key={user.id} onClick={() => user.username && handleMention(user, text, mentionText)}>
            <Badge isDark={isDark} hasUsername user={user} isDisabled />
          </StyledItem>
        ))}
      <Waypoint onEnter={loadMore} />
    </StyledMentionSuggestionsContainer>
  );
};

export default MentionSuggestions;
