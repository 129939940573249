import { styled } from 'linaria/react';
import { StyledLabel as CustomStyledLabel } from 'components/AddContent/CreateList/styled';
import { FONTS } from 'styles/constants';

export const StyledTitle = styled.span`
  padding-top: 32px;
  font: 400 22px/130% ${FONTS.primary};
`;

export const StyledLabel = styled(CustomStyledLabel)`
  border: none;
  padding: 42px 0 26px;
  margin: 0;
`;

export const StyledInfoText = styled.span`
  font: 350 12px/140% ${FONTS.secondary};
  padding-bottom: 16px;
`;

export const StyledSearchWrap = styled.div`
  padding: 32px 0 16px;
`;
