import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';

import getAuthUser from 'helpers/getAuthUser';
import useTheme from 'helpers/useTheme';
import { COLORS } from 'styles/constants';
import { CardSchema } from 'constants/graphqlTypes';
import { useGetCollaborationLists } from 'graphQL/profile/hooks';
import LoaderContent from 'components/UI/LoaderContent';
import SinglePageLayout from 'components/SinglePageLayout';
import SpecificList from 'components/SettingsSection/SpecificLists';
import ContentSubtitle from 'components/UI/ContentSubtitle';
import { StyledList } from '../styled';

const ActivityCollaborateNotifications: FC = () => {
  const isDark = useTheme();
  const { userId } = getAuthUser();
  const { lists, loading, loadMore } = useGetCollaborationLists(userId);

  return (
    <SinglePageLayout
      title="Activity notifications in collaborative lists"
      subTitle="Be notified when picks and thoughts are added."
    >
      {!lists.length && !loading ? (
        <ContentSubtitle
          color={!isDark ? COLORS.brown.dark[100] : ''}
          isDark={isDark}
          text="You’re not currently in any collaborative lists."
        />
      ) : (
        <StyledList isDark={isDark}>
          {lists.map((list: CardSchema) => (
            <li key={list.id}>
              <SpecificList list={list} isNotificationsEnable={!!list.isNotificationsEnable} />
            </li>
          ))}
          {loading ? <LoaderContent isFullScreen={!lists.length} isDark /> : <Waypoint onEnter={loadMore} />}
        </StyledList>
      )}
    </SinglePageLayout>
  );
};

export default ActivityCollaborateNotifications;
