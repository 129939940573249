import { styled } from 'linaria/lib/react';

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  width: 100%;
`;

export const StyledToggles = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const StyledSeeAllPicks = styled.div`
  padding-left: 20px;

  > button {
    padding: 0;
  }
`;
