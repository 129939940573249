import React, { FC } from 'react';

import { AVATAR_DIMENSION } from 'components/Profile/ProfileTop/constants';
import Avatar from 'components/UI/Avatar';
import AvatarPlaceholder from 'components/UI/Avatar/AvatarPlaceholder';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';
import { UserObjectType } from 'constants/graphqlTypes';

import { StyledText, StyledUserInfo, StyledVerificationWrap } from 'components/Profile/ProfileTop/styled';
import { StyledContainer, StyledName } from './styled';

const ProfileContent: FC<{ user?: UserObjectType }> = ({ user }) => {
  const { username, userInfo, isVerified } = user ?? {};
  const { firstName, lastName, bio } = userInfo ?? {};

  return (
    <StyledContainer hasWhiteAvatar={false} hasPaddingBottom={false}>
      {user ? <Avatar user={user} dimension={AVATAR_DIMENSION} /> : <AvatarPlaceholder dimension={AVATAR_DIMENSION} />}
      <StyledName>{user && `${firstName} ${lastName}`}</StyledName>
      {isVerified && (
        <StyledVerificationWrap>
          <VerificationBadge hasCaption />
        </StyledVerificationWrap>
      )}
      <StyledUserInfo>
        {user && <StyledText>:{username}</StyledText>}
        {bio && <StyledText shouldBreak>{bio}</StyledText>}
      </StyledUserInfo>
    </StyledContainer>
  );
};

export default ProfileContent;
