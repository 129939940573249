import { WEB_PREFIX } from 'constants/common';

export const generateSettingsTestIds = (
  baseTestId?: string
): {
  titleTestId: string;
  selectButtonTestId: string;
} => {
  const titleTestId = `${baseTestId}_title-label`;
  const selectButtonTestId = `${baseTestId}_select-button`;

  return { titleTestId, selectButtonTestId };
};

export const ACCOUNT_MANAGEMENT_VIEW = 'account-management-view';
export const ACCOUNT_DETAILS_VIEW = 'account-details-view';
export const ADJUST_TOPICS_VIEW = 'adjuct-topics-view';
export const NOTIFICATIONS_SETTINGS_VIEW = 'notifications-settings-view';
export const NOTIFICATIONS_SELECTION_VIEW = 'notifications-selection-view';
export const COLLABORATING_NOTIFICATIONS_SETTINGS_VIEW = `collaborating-activity-${NOTIFICATIONS_SETTINGS_VIEW}`;
export const USERS_NOTIFICATIONS_SETTINGS_VIEW = `users-activity-${NOTIFICATIONS_SETTINGS_VIEW}`;
export const EMAIL_NOTIFICATIONS_SETTINGS_VIEW = `email-${NOTIFICATIONS_SETTINGS_VIEW}`;
export const PRIVACY_AND_DATA_VIEW = 'privacy-and-data-view';
export const DATA_PREFERENCES_VIEW = 'data-preferences-view';
export const CONNECTING_WITH_OTHERS = 'connecting-with-others-view';
export const BLOCKED_ACCOUNTS_VIEW = 'blocked-accounts-view';
export const MUTED_ACCOUNTS_VIEW = 'muted-accounts-view';
export const HIDDEN_WORDS_VIEW = 'hidden-words-view';
export const REQUEST_DATA_VIEW = 'request-data-view';
export const DELETE_VIEW = 'delete-view';

export const TEST_ID_ACCOUNT_MANAGEMENT_START = `${WEB_PREFIX}${ACCOUNT_MANAGEMENT_VIEW}`;
export const TEST_ACCOUNT_MANAGEMENT_DETAILS = `${TEST_ID_ACCOUNT_MANAGEMENT_START}_action_account-details`;
export const TEST_ACCOUNT_MANAGEMENT_TOPICS = `${TEST_ID_ACCOUNT_MANAGEMENT_START}_action_adjust-topics`;

export const TEST_ID_ACCOUNT_DETAILS_START = `${WEB_PREFIX}${ACCOUNT_DETAILS_VIEW}`;
export const TEST_ACCOUNT_DETAILS_ALERT = `${TEST_ID_ACCOUNT_DETAILS_START}_alert-label`;
export const TEST_ACCOUNT_DETAILS_EMAIL = `${TEST_ID_ACCOUNT_DETAILS_START}_email-label`;
export const TEST_ACCOUNT_DETAILS_USERNAME = `${TEST_ID_ACCOUNT_DETAILS_START}_username-label`;

export const TEST_ID_ADJUST_TOPICS_START = `${WEB_PREFIX}${ADJUST_TOPICS_VIEW}`;
export const TEST_ADJUST_TOPICS_ITEM = `${TEST_ID_ADJUST_TOPICS_START}_topic-item`;

export const TEST_ID_NOTIFICATIONS_SETTINGS_START = `${WEB_PREFIX}${NOTIFICATIONS_SETTINGS_VIEW}`;
export const TEST_NOTIFICATIONS_SETTINGS_APP = `${TEST_ID_NOTIFICATIONS_SETTINGS_START}_action_app-notifications`;
export const TEST_NOTIFICATIONS_SETTINGS_EMAIL = `${TEST_ID_NOTIFICATIONS_SETTINGS_START}_action_email-notifications`;

export const TEST_ID_NOTIFICATIONS_SELECTION_VIEW_START = `${WEB_PREFIX}${NOTIFICATIONS_SELECTION_VIEW}`;
export const TEST_NOTIFICATIONS_SELECTION_TITLE = `${TEST_ID_NOTIFICATIONS_SELECTION_VIEW_START}_title-label`;
export const TEST_NOTIFICATIONS_SELECTION_SUBTITLE = `${TEST_ID_NOTIFICATIONS_SELECTION_VIEW_START}_subtitle-label`;
export const TEST_NOTIFICATIONS_SELECTION_TYPE = `${TEST_ID_NOTIFICATIONS_SELECTION_VIEW_START}_notification-type`;
export const TEST_NOTIFICATIONS_SELECTION_TYPE_ACTIVITY = `${TEST_NOTIFICATIONS_SELECTION_TYPE}-0`;
export const TEST_NOTIFICATIONS_SELECTION_TYPE_NETWORK = `${TEST_NOTIFICATIONS_SELECTION_TYPE}-1`;
export const TEST_NOTIFICATIONS_SELECTION_TYPE_COLLABORATIVE = `${TEST_NOTIFICATIONS_SELECTION_TYPE}-2`;
export const TEST_NOTIFICATIONS_SELECTION_TYPE_DISCOVER = `${TEST_NOTIFICATIONS_SELECTION_TYPE}-3`;

export const TEST_ID_USERS_NOTIFICATIONS_SETTINGS_VIEW_START = `${WEB_PREFIX}${NOTIFICATIONS_SELECTION_VIEW}`;
export const TEST_ID_EMAIL_NOTIFICATIONS_SETTINGS_VIEW_START = `${WEB_PREFIX}${EMAIL_NOTIFICATIONS_SETTINGS_VIEW}`;
export const TEST_EMAIL_NOTIFICATIONS_SETTINGS_ITEM = `${TEST_ID_EMAIL_NOTIFICATIONS_SETTINGS_VIEW_START}_item`;

export const TEST_ID_PRIVACY_AND_DATA_VIEW_START = `${WEB_PREFIX}${PRIVACY_AND_DATA_VIEW}`;
export const TEST_PRIVACY_ACTION = `${TEST_ID_PRIVACY_AND_DATA_VIEW_START}_action`;
export const TEST_PRIVACY_ACTION_DATA_PREFERENCES = `${TEST_PRIVACY_ACTION}_data-preferences`;
export const TEST_PRIVACY_ACTION_CONNECTING_WITH_OTHERS = `${TEST_PRIVACY_ACTION}_connecting-with-others`;
export const TEST_PRIVACY_ACTION_BLOCKED_ACCOUNTS = `${TEST_PRIVACY_ACTION}_blocked-accounts`;
export const TEST_PRIVACY_ACTION_MUTED_ACCOUNTS = `${TEST_PRIVACY_ACTION}_muted-accounts`;
export const TEST_PRIVACY_ACTION_HIDDEN_WORDS = `${TEST_PRIVACY_ACTION}_hidden-words`;
export const TEST_PRIVACY_ACTION_REQUEST_YOUR_DATA = `${TEST_PRIVACY_ACTION}_request-your-data`;
export const TEST_PRIVACY_ACTION_DELETE_MY_ACCOUNT = `${TEST_PRIVACY_ACTION}_delete-my-account`;

export const TEST_ID_DATA_PREFERENCES_VIEW_START = `${WEB_PREFIX}${DATA_PREFERENCES_VIEW}`;
export const TEST_DATA_PREFERENCES_PREFERENCE = `${TEST_ID_DATA_PREFERENCES_VIEW_START}_preference`;
export const TEST_DATA_PREFERENCES_STRICTLY_NECESSARY = `${TEST_DATA_PREFERENCES_PREFERENCE}_strictly-necessary`;
export const TEST_DATA_PREFERENCES_PERFOMANCE = `${TEST_DATA_PREFERENCES_PREFERENCE}_performance-and-analytics`;
export const TEST_DATA_PREFERENCES_USAGE_HISTORY = `${TEST_DATA_PREFERENCES_PREFERENCE}_usage-history`;

export const TEST_ID_CONNECTING_WITH_OTHERS_START = `${WEB_PREFIX}${CONNECTING_WITH_OTHERS}`;
export const TEST_CONNECTING_WITH_OTHERS_ALLOW_MESSAGE = `${TEST_ID_CONNECTING_WITH_OTHERS_START}_allow-message`;
export const TEST_CONNECTING_WITH_OTHERS_ALLOW_COLLABORATE = `${TEST_ID_CONNECTING_WITH_OTHERS_START}_allow-collaborate`;

export const TEST_ID_BLOCKED_ACCOUNTS_VIEW_START = `${WEB_PREFIX}${BLOCKED_ACCOUNTS_VIEW}`;
export const TEST_BLOCKED_ACCOUNTS_EMPTY_LABEL = `${TEST_ID_BLOCKED_ACCOUNTS_VIEW_START}_empty-label`;
export const TEST_BLOCKED_ACCOUNTS_PLUS_BTN = `${TEST_ID_BLOCKED_ACCOUNTS_VIEW_START}_plus-button`;
export const TEST_ID_MUTED_ACCOUNTS_VIEW_START = `${WEB_PREFIX}${MUTED_ACCOUNTS_VIEW}`;
export const TEST_MUTED_ACCOUNTS_EMPTY_LABEL = `${TEST_ID_MUTED_ACCOUNTS_VIEW_START}_empty-label`;
export const TEST_MUTED_ACCOUNTS_PLUS_BTN = `${TEST_ID_MUTED_ACCOUNTS_VIEW_START}_plus-button`;

export const TEST_ID_HIDDEN_WORDS_VIEW_START = `${WEB_PREFIX}${HIDDEN_WORDS_VIEW}`;
export const TEST_HIDDEN_WORDS_HIDE_OFFENSIVE = `${TEST_ID_HIDDEN_WORDS_VIEW_START}_hide-offensive-words`;
export const TEST_HIDDEN_WORDS_HIDE_CUSTOM = `${TEST_ID_HIDDEN_WORDS_VIEW_START}_hide-custom-words`;
export const TEST_HIDDEN_WORDS_HIDE_MANAGE = `${TEST_ID_HIDDEN_WORDS_VIEW_START}_manage-custom-words`;

export const TEST_ID_REQUEST_DATA_VIEW_START = `${WEB_PREFIX}${REQUEST_DATA_VIEW}`;
export const TEST_REQUEST_DATA_SUBMIT_BTN = `${TEST_ID_REQUEST_DATA_VIEW_START}_submit-button`;
export const TEST_REQUEST_DATA_OPTION = `${TEST_ID_REQUEST_DATA_VIEW_START}_data-option`;
export const TEST_REQUEST_DATA_OPTION_PERSONAL_DETAILS = `${TEST_REQUEST_DATA_OPTION}_personal-details`;
export const TEST_REQUEST_DATA_OPTION_PICKS_THOUGHTS_LISTS = `${TEST_REQUEST_DATA_OPTION}_picks-thoughts-and-lists`;
export const TEST_REQUEST_DATA_OPTION_REACTIONS = `${TEST_REQUEST_DATA_OPTION}_reactions`;
export const TEST_REQUEST_DATA_OPTION_MESSAGES = `${TEST_REQUEST_DATA_OPTION}_messages`;
export const TEST_REQUEST_DATA_OPTION_REPORTS = `${TEST_REQUEST_DATA_OPTION}_reports`;

export const TEST_ID_DELETE_VIEW_START = `${WEB_PREFIX}${DELETE_VIEW}`;
export const TEST_DELETE_WARNING_LABEL = `${TEST_ID_DELETE_VIEW_START}_warning-label`;
export const TEST_DELETE_QUESTIONS_LABEL = `${TEST_ID_DELETE_VIEW_START}_question-label`;
export const TEST_DELETE_EMAIL_US_LABEL = `${TEST_ID_DELETE_VIEW_START}_email-label`;
