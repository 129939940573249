import React, { FC } from 'react';
import { v4 } from 'uuid';

import LoaderContent from 'components/UI/LoaderContent';
import useTheme from 'helpers/useTheme';
import useDocumentTitle from 'helpers/useDocumentTitle';
import ActivityList from 'components/ActivityList/ActivityList';
import SinglePageLayout from 'components/SinglePageLayout';

import { useActivity, useReadActivity } from 'graphQL/activity/hooks';
import { TEST_ID_ACTIVITY_VIEW_START } from 'constants/aqa/activity';

const Activity: FC = () => {
  const { lists, load, loading } = useActivity();
  const isDark = useTheme();

  useReadActivity();
  useDocumentTitle('Activity');

  return (
    <SinglePageLayout pageTestId={TEST_ID_ACTIVITY_VIEW_START} title="Activity">
      {lists.map(
        (items, index) =>
          !!items.length && (
            <ActivityList key={v4()} items={items} index={index} loading={loading} handleLoad={() => load(index)} />
          )
      )}
      {loading && <LoaderContent isDark={isDark} />}
    </SinglePageLayout>
  );
};

export default Activity;
