import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from 'components/Onboarding/Layout';
import TextInput from 'components/UI/TextInput';
import useChangeProfileName from 'components/ProfileSettings/EditProfile/ChangeName/hooks';
import useTheme from 'helpers/useTheme';
import { ROUTE_ONBOARDING_USERNAME } from 'routes';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import ContentTitle from 'components/UI/ContentTitle';
import { useUpdateOnboardingStep, useUpdateCachedOnboardingStep } from 'graphQL/onboarding/hooks';
import { EOnboardingStepOptions } from 'constants/graphqlTypes';
import { TEST_USER_INFO_FULLNAME_TEXT_FIELD, TEST_USER_INFO_TITLE } from 'constants/aqa/signup';

const Fullname: FC = () => {
  const { push } = useHistory();
  const isDark = useTheme();

  const updateCache = useUpdateCachedOnboardingStep(EOnboardingStepOptions.Username);
  const handleNext = () => {
    updateCache();
    push(ROUTE_ONBOARDING_USERNAME);
  };
  const { name, error, handleChange, handleError, handleDone } = useChangeProfileName(handleNext);

  const handleText = (value: string) => {
    handleChange(value.replaceAll(/\s{2,}/g, ' '));
    handleError(!value ? 'Required' : '');
  };

  useUpdateOnboardingStep(EOnboardingStepOptions.FullName);
  return (
    <Layout primaryBtnText="Continue" handlePrimaryBtn={handleDone}>
      <ContentTitle dataTestId={TEST_USER_INFO_TITLE} isDark={isDark}>
        What’s your full name?
      </ContentTitle>
      <TextInput
        dataTestId={TEST_USER_INFO_FULLNAME_TEXT_FIELD}
        placeholder="Jo Garcia"
        isDark={isDark}
        text={name}
        setText={handleText}
        error={error}
        autoCapitalize={EAutoCapitalize.Words}
        shouldPrevent2ndSpace
      />
    </Layout>
  );
};

export default Fullname;
