import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

interface IProfileContext {
  gradientStart?: string | null;
  gradientEnd?: string | null;
  setGradientStart?: Dispatch<SetStateAction<string | null>>;
  setGradientEnd?: Dispatch<SetStateAction<string | null>>;
}

const ProfileContext = createContext<IProfileContext>({});
export const { Provider: ProfileProvider } = ProfileContext;

export const useProfileContext = (): IProfileContext => {
  const contextData = useContext(ProfileContext);

  return { ...contextData };
};

export const useProfileContextState = (): IProfileContext => {
  const [gradientStart, setGradientStart] = useState<string | null>(null);
  const [gradientEnd, setGradientEnd] = useState<string | null>(null);

  return {
    gradientStart,
    setGradientStart,
    gradientEnd,
    setGradientEnd,
  };
};
