import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledInformation = styled.div`
  padding: 16px 0;

  span {
    display: block;
  }
`;

export const StyledLabel = styled.span<{ isDark?: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 14px/140% ${FONTS.secondary};
`;

export const StyledText = styled.span`
  color: ${COLORS.brown.light[100]};
  font: 400 22px/130% ${FONTS.primary};
  margin-top: 8px;
  word-wrap: break-word;
`;
