import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';
import { StyledUsername } from '../Navigation/styled';

export const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 4px 0 0;
`;

export const StyledListItem = styled.li`
  padding-bottom: 12px;
`;

export const StyledWrap = styled.div<{ hasIndicator: boolean; hasRightPadding?: boolean }>`
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  min-width: 100%;
  padding-right: ${({ hasRightPadding }) => (hasRightPadding ? '24px' : 0)};
  transition: all 0.3s ease-in-out;
  height: 100%;

  &::after {
    background-color: ${({ hasIndicator }) => (hasIndicator ? COLORS.green.light : COLORS.transparent)};
    border-radius: 50%;
    content: '';
    display: block;
    height: 8px;
    min-width: 8px;
    margin-left: auto;
  }
`;

export const StyledInfo = styled.div`
  overflow: hidden;
`;

export const StyledText = styled.span<{ isBold: boolean; isDark: boolean; fixedWidth?: boolean }>`
  color: ${COLORS.brown.light[100]};
  display: block;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  font-weight: ${({ isBold }) => (isBold ? 500 : 300)};
  line-height: 140%;
  min-width: ${({ fixedWidth }) => (fixedWidth ? '108px' : 'auto')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${({ fixedWidth }) => (fixedWidth ? '108px' : 'auto')};
`;

export const StyledTitleWrap = styled.div<{ hideGap?: boolean }>`
  align-items: baseline;
  display: flex;
  gap: ${({ hideGap }) => (hideGap ? '0' : '4px')};
  overflow: hidden;
`;

export const StyledTitle = styled(StyledText)`
  display: flex;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
`;

export const StyledMessage = styled.div`
  display: flex;
  gap: 4px;
`;

export const StyledInteractionArea = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const StyledContentWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 68px;
  cursor: pointer;

  .ui-link {
    min-width: 100%;
  }

  .badge {
    min-width: 100%;
  }
`;

export const StyledContentLink = styled.div`
  height: 100%;
  pointer-events: auto;
`;

export const StyledTruncateContainer = styled.span<{ isBold: boolean; isDark: boolean }>`
  align-items: baseline;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  font-weight: ${({ isBold }) => (isBold ? 500 : 300)};
  line-height: 140%;
  overflow: hidden;
`;

export const StyledMore = styled(StyledUsername)`
  min-width: 84px;
  font-weight: 500;
`;
