import React, { FC } from 'react';
import ConfirmationModal from '../UI/ConfirmationModal/ConfirmationModal';

const UserBlockedWarning: FC<{ handleClose: () => void; isBlockedByMe: boolean }> = ({
  handleClose,
  isBlockedByMe,
}) => (
  <ConfirmationModal
    titleText={isBlockedByMe ? 'this user is blocked' : 'blocked'}
    subTitle={isBlockedByMe ? 'To continue, you’ll need to unblock them first' : 'This user has blocked you'}
    cancelButtonText="okay"
    handleCancel={handleClose}
  />
);

export default UserBlockedWarning;
