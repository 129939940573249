import React, { FC, useCallback, useEffect, useState } from 'react';

import { ReactComponent as FullScreen } from 'assets/icons/FullScreen.svg';
import introVideo from 'assets/videos/ intro-video.mp4';

import { HTMLVideoElementWithFullscreen } from './constants';
import { FullScreenWrapper, StyledIntroWrapper, StyledSkipIntro } from './styled';

const Intro: FC<{ openIntro: React.Dispatch<React.SetStateAction<boolean>> }> = ({ openIntro }) => {
  const [introRef, setIntroRef] = useState<HTMLVideoElementWithFullscreen>();

  const handleRef = useCallback((node) => {
    setIntroRef(node);
  }, []);

  const handleFullScreen = () => {
    if (introRef) {
      if (introRef.requestFullscreen) {
        introRef.requestFullscreen();
      } else if (introRef.mozRequestFullScreen) {
        // Firefox
        introRef.mozRequestFullScreen();
      } else if (introRef.webkitRequestFullscreen) {
        // Chrome, Safari, and Opera
        introRef.webkitRequestFullscreen();
      } else if (introRef.webkitEnterFullscreen) {
        // iOS Safari
        introRef.webkitEnterFullscreen();
      }
    }
  };

  useEffect(() => {
    if (introRef) {
      introRef.addEventListener('pause', () => introRef?.play());
      introRef.addEventListener('ended', () => {
        openIntro(false);
      });
    }
  }, [introRef]);

  return (
    <StyledIntroWrapper>
      <video width="100%" height="100%" ref={handleRef} autoPlay muted playsInline preload="auto">
        <source src={`${introVideo}#t=0.001`} type="video/mp4" />
      </video>
      <FullScreenWrapper>
        <FullScreen onClick={handleFullScreen} />
      </FullScreenWrapper>
      <StyledSkipIntro onClick={() => openIntro(false)}>Skip intro</StyledSkipIntro>
    </StyledIntroWrapper>
  );
};

export default Intro;
