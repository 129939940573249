import { styled } from 'linaria/lib/react';
import StyledButton from 'components/UI/Button/styled';
import { COLORS } from 'styles/constants';

export const StyledStartButton = styled(StyledButton)`
  align-items: center;
  display: flex;
  gap: 4px;
  padding: 10px 20px 10px 12px;

  path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;
