import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ROUTE_ACTIVITY } from 'routes';
import Modal from 'components/Modal';
import { useGoBack } from 'helpers/routingHelper';

import { getListStyles } from 'components/Collection/helpers';
import { IInviteModalState } from 'graphQL/cardOptions/models';
import { useGetInviteDetails } from 'graphQL/list/invite/hooks';

import { StyledTop, StyledWrap } from './styled';

import Buttons from './Buttons';
import Content from './Content';
import User from './User';

const InviteModal: FC = () => {
  const { inviteId } = useParams<{ inviteId: string }>();
  const goBack = useGoBack(ROUTE_ACTIVITY);

  const { message, inviter, list } = useGetInviteDetails(inviteId);

  const { state } = useLocation<{ variable: IInviteModalState } | undefined>();
  const { withoutAcceptInvite } = state?.variable ?? {};
  const { id, cardId: listCardId, isPrivateList } = list ?? {};

  const { bgColor, hasItems } = getListStyles(list);

  const handleClose = () => {
    goBack();
  };

  return (
    <Modal handleClose={handleClose} bgColor={bgColor} isDark={hasItems}>
      <StyledWrap>
        <StyledTop>
          {inviter && <User user={inviter} isDark={!hasItems} hasPrivateText={!!isPrivateList} />}
          {list && <Content list={list} text={message ?? ''} isDark={hasItems} />}
        </StyledTop>
        {id && listCardId && inviter && inviteId && (
          <Buttons
            isAcceptInvite={!withoutAcceptInvite}
            isDark={!hasItems}
            user={inviter}
            inviteId={inviteId}
            pickId={id}
            cardId={listCardId}
            handleClose={handleClose}
          />
        )}
      </StyledWrap>
    </Modal>
  );
};

export default InviteModal;
