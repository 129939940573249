import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as MenuIcon } from 'assets/icons/Menu.svg';
import { ReactComponent as MenuNotifyIcon } from 'assets/icons/MenuNotifyIcon.svg';
import { ReactComponent as BookmarkIcon } from 'assets/icons/Bookmark.svg';
import { ReactComponent as BookmarkwithCircleIcon } from 'assets/icons/BookmarkwithCircle.svg';

import useTheme from 'helpers/useTheme';
import usePageType from 'helpers/usePageType';

import { SAVED_FOR_LATER } from 'components/GuideTour/constants';
import { TEST_HOME_BURGER_MENU_BTN } from 'constants/aqa/main';
import { useActivityIndication } from 'graphQL/activity/hooks';
import { useUserSettings } from 'graphQL/contextualIntro/hooks';
import { useGetSavedPickCount } from 'graphQL/savedPicks/hooks';

import { ROUTE_SAVED_PICKS } from 'routes';

import withHapticFeedback from 'helpers/withHapticFeedback';
import handleVibrate from 'helpers/handleVibrate';
import Panel from './Panel';
import { StyledButton, StyledFillButton, StyledMenuWrap } from '../../styled';

const SettingsButton: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isDark = useTheme();
  const { isDiscovery } = usePageType();
  const { push } = useHistory();

  const { isViewedAboutPage } = useUserSettings();

  const hasUnreadAbout = !(isViewedAboutPage ?? true);
  const hasUnreadedActivity = useActivityIndication();

  const showIndicator = hasUnreadAbout || hasUnreadedActivity;
  // TO-DO: update after implementing bookmarks logic in GH-16101
  const { totalCount: savedPicksNumber, totalCountNotViewed: savePicksNotViewedNumber } = useGetSavedPickCount(false);

  const handleMenu = () => {
    setIsOpen(true);
    if (isDiscovery) {
      handleVibrate();
    }
  };

  return (
    <>
      <StyledMenuWrap>
        <StyledButton data-testid={TEST_HOME_BURGER_MENU_BTN} onClick={handleMenu}>
          {showIndicator ? <MenuNotifyIcon /> : <MenuIcon />}
        </StyledButton>
        {isDiscovery && (
          <StyledFillButton
            data-tour={SAVED_FOR_LATER}
            isDark={isDark}
            isTransparent={!savedPicksNumber}
            onClick={withHapticFeedback(() => push(ROUTE_SAVED_PICKS))}
          >
            {savePicksNotViewedNumber ? <BookmarkwithCircleIcon /> : <BookmarkIcon />}
          </StyledFillButton>
        )}
      </StyledMenuWrap>

      <Panel
        hasAbout={!isViewedAboutPage}
        hasActivity={hasUnreadedActivity}
        handleClose={() => setIsOpen(false)}
        isShown={isOpen}
      />
    </>
  );
};

export default SettingsButton;
