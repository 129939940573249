import React, { FC, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import SliderInteractive from 'components/SliderInteractive';
import UserCard from 'components/Card/UserCard';

import { COLORS } from 'styles/constants';
import { CardSchema } from 'constants/graphqlTypes';
import { StyledCardWrap } from 'components/Explore/styled';
import { StyledContainer } from './styled';

const UserSlider: FC<{ items: CardSchema[]; isDark: boolean; hasAnimatedImages?: boolean }> = ({
  items,
  isDark,
  hasAnimatedImages,
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [imagesKey, setImagesKey] = useState('');

  const onChange = (index: number) => {
    setCurrentSlideIndex(index);
    setImagesKey(`key-${currentSlideIndex}`);
  };

  return items.length === 1 && items[0].user ? (
    <StyledCardWrap>
      <UserCard item={items[0].user} hasAnimatedImages={hasAnimatedImages} isCurrent enableOldBehaviour />
    </StyledCardWrap>
  ) : (
    <StyledContainer>
      <SliderInteractive
        customSlidesPerView={1.2}
        spaceBetween={13}
        fontColor={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
        showBtnsSm={!!items.length}
        offset={12}
        onSlideChange={(index) => onChange(index)}
      >
        {items.map(
          (item, index) =>
            item.user && (
              <SwiperSlide key={item.id}>
                <UserCard
                  item={item.user}
                  enableOldBehaviour={false}
                  isFullHeight
                  hasAnimatedImages={hasAnimatedImages}
                  imagesKey={imagesKey}
                  isCurrent={currentSlideIndex === index}
                />
              </SwiperSlide>
            )
        )}
      </SliderInteractive>
    </StyledContainer>
  );
};

export default UserSlider;
