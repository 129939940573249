import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';

import { useGetCreators } from 'graphQL/card/creators/hook';
import { UserObjectType } from 'constants/graphqlTypes';

import Button from 'components/UI/Button';
import LoaderContent from 'components/UI/LoaderContent';
import BadgeWithChildren from 'components/UI/BadgeWithChildren/BadgeWithChidlren';
import { EUiButtonType } from 'components/UI/Button/constants';

import Modal from 'components/Modal';
import { StyledTitle } from 'components/ListSettings/styled';
import { StyledList } from 'components/ListSettings/EditListCollaborators/styled';

const CollaboratorsListModal: FC<{
  list: { listId: string; listTitle: string };
  handleClose: () => void;
  handleSelect: (collaborator: UserObjectType) => void;
}> = ({ list, handleClose, handleSelect }) => {
  const { listId, listTitle } = list;
  const { creators, loadMore, loading } = useGetCreators(listId);

  return (
    <Modal fadeIn fadeOut handleClose={handleClose} handleDone={handleClose} loading={false} isDark>
      <StyledTitle>Select a new admin for {listTitle}</StyledTitle>
      <StyledList>
        {creators.map(
          (creator, index) =>
            !!index && (
              <li key={creator.userId}>
                {creator.user && (
                  <BadgeWithChildren user={creator.user} isDark>
                    <Button
                      type={EUiButtonType.Emphasized}
                      text="Select"
                      handler={() => {
                        if (creator.user) {
                          handleSelect(creator.user);
                        }

                        handleClose();
                      }}
                    />
                  </BadgeWithChildren>
                )}
              </li>
            )
        )}
        {loading && !creators.length && <LoaderContent />}
        <Waypoint onEnter={loadMore} topOffset="200px" />
      </StyledList>
    </Modal>
  );
};

export default CollaboratorsListModal;
