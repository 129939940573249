import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';
import { StyledWrap as ImportWrap } from '../../AboutUs/styled';
import { StyledButtonWrap as ImportButtonWrap } from '../../Welcome/styled';

export const StyledWrap = styled(ImportWrap)<{ isLottieAnimationShowed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 100%;
  height: auto;

  .animated-bg {
    animation: fade ease 1000ms forwards;
    display: ${({ isLottieAnimationShowed }) => (isLottieAnimationShowed ? 'block' : 'none')};
    margin: 0 -20px;
    padding-bottom: 114px;

    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      margin: 0;
    }

    @keyframes fade {
      from {
        display: none;
        margin-bottom: -100px;
        opacity: 0;
      }

      to {
        display: block;
        margin-bottom: 0;
        opacity: 1;
      }
    }
  }
`;

export const StyledContentWrap = styled.div`
  padding: 115px 48px 0;
  margin-bottom: 133px;

  p {
    margin: 0;
  }
`;

export const StyledText = styled.p`
  font: 22px/130% ${FONTS.primary};
  margin: 0;
  padding-bottom: 8px;
`;

export const StyledTitle = styled.p`
  animation: fade ease 700ms forwards;
  color: ${COLORS.white[100]};
  font: 400 14px/140% ${FONTS.secondary};

  @keyframes fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const StyledButtonWrap = styled(ImportButtonWrap)`
  animation: fade ease 400ms forwards;
  animation-delay: 2700ms;
  bottom: 0;
  margin-top: 24px;
  opacity: 0;
  position: fixed;
  padding: 0 24px 46px;

  > button {
    max-width: 342px;
    width: 100%;
  }

  @keyframes fade {
    from {
      bottom: -100px;
      opacity: 0;
    }

    to {
      bottom: 0;
      opacity: 1;
    }
  }
`;

export const StyledUserCardWrap = styled.div`
  margin-bottom: 83px;
  padding: 24px 12px 0;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 24px 0 0;
  }
`;
