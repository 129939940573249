import React, { FC } from 'react';

import Grain from 'components/UI/Grain';

import { StyledItem, StyledTitle, StyledText } from './styled';

export interface IInfoCard {
  bgColor?: string;
  Icon?: FC;
  title: string;
  text?: string;
}

const InfoCard: FC<IInfoCard> = ({ bgColor, title, Icon, children }) => {
  return (
    <StyledItem bgColor={bgColor}>
      <Grain />
      {Icon && <Icon />}
      <StyledTitle isDark>{title}</StyledTitle>
      {children && <StyledText>{children}</StyledText>}
    </StyledItem>
  );
};

export default InfoCard;
