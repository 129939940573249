import React, { FC, useState } from 'react';

import useGuideTour from 'helpers/useGuideTour';
import Highlighter from 'components/Highlighter';
import OnboardingAlert from 'components/UI/OnboardingAlert';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { useGetSteps, useExitGuideTour } from './hooks';
import LastStep from './LastStep';

const GuideTour: FC = () => {
  const { isGuideTour, guideTourStep = 0, setGuideTourStep } = useGuideTour();
  const [isExitConfirmation, setIsExitConfirmation] = useState(false);

  const steps = useGetSteps();
  const { handelTourExit, handelHelpPage } = useExitGuideTour(setIsExitConfirmation);

  if (!isGuideTour) {
    return null;
  }

  const { target, highlighterOffset, elementOffset, handleNext, borderRadius, title, text } = steps[guideTourStep];
  const isLastStep = guideTourStep === steps.length - 1;

  const handleStep = () => {
    if (!isLastStep) {
      setGuideTourStep?.(guideTourStep + 1);
      handleNext?.();
    }
  };

  return (
    <>
      {!isExitConfirmation ? (
        <>
          {!isLastStep ? (
            <Highlighter
              id={target}
              highlighterOffset={highlighterOffset}
              elementOffset={elementOffset}
              borderRadius={borderRadius}
              heightOffset
              highlight
              handleClose={() => setIsExitConfirmation(true)}
            >
              <OnboardingAlert
                title={title}
                submitText="Continue"
                handleSubmit={handleStep}
                handleCancel={() => setIsExitConfirmation(true)}
                hasCross
              >
                {text}
              </OnboardingAlert>
            </Highlighter>
          ) : (
            <LastStep title={title} setIsExitConfirmation={setIsExitConfirmation} />
          )}
        </>
      ) : (
        <ConfirmationModal
          titleText="Exit the tour?"
          subTitle="Revisit this tour or learn more about using guide:human in our help center."
          buttonText="Exit tour"
          secondButtonText="Visit help center"
          handleAccept={() => handelTourExit(true)}
          handleAcceptSecondOption={handelHelpPage}
          handleCancel={() => setIsExitConfirmation(false)}
          handleClickOutside={() => {}} // Nothing should happen when clicking on a free area (design)
          isWarning={false}
        />
      )}
    </>
  );
};

export default GuideTour;
