import React, { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ReactComponent as ThreeBarsIcon } from 'assets/icons/ThreeHorizontalBars.svg';
import { ChannelAdminFeedSchema, ChannelCustomFeedSchema } from 'constants/graphqlTypes';
import { StyledIconContainer } from 'components/ListSettings/EditOrder/DndCardList/ListItem/styled';
import { StyledItem } from './styled';

const ListItem: FC<{ feed: ChannelCustomFeedSchema | ChannelAdminFeedSchema; id: string }> = ({ feed, id }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <StyledItem
      ref={setNodeRef}
      style={{
        ...style,
      }}
    >
      <span>{feed.item.title}</span>
      <StyledIconContainer
        // eslint-disable-next-line
        {...attributes}
        // eslint-disable-next-line
        {...listeners}
        isDragging={isDragging}
      >
        <ThreeBarsIcon />
      </StyledIconContainer>
    </StyledItem>
  );
};

export default ListItem;
