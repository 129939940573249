import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS } from '../../../styles/constants';

export const StyledWrap = styled.div<{
  bgColor: string;
  firstColor?: string;
  isAllPicks: boolean;
  isDark: boolean;
  isOneImage?: boolean;
  isSliderImages?: boolean;
  secondColor?: string;
}>`
  align-items: ${({ isSliderImages }) => (isSliderImages ? 'flex-end' : 'center')};
  background: ${({ bgColor }) => bgColor};
  border-bottom: ${({ isAllPicks, isDark }) =>
    isAllPicks ? `1px solid ${isDark ? COLORS.white[30] : COLORS.brown.dark[10]}` : 'none'};
  display: flex;
  justify-content: center;
  min-height: 397px;
  padding: ${({ isSliderImages, isOneImage }) => {
    if (isSliderImages) {
      return '108px 0 12px';
    }
    if (isOneImage) {
      return '60px';
    }
    return '0 24px';
  }};
  position: relative;
  width: 100%;
  -webkit-touch-callout: none;
  user-select: none;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: ${({ isSliderImages, isOneImage }) => {
      if (isSliderImages) {
        return '108px 60px 12px';
      }
      if (isOneImage) {
        return '60px';
      }
      return '0 24px';
    }};
  }

  &::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &::before {
    top: 0;
    background: ${({ firstColor, secondColor, isSliderImages, isOneImage }) =>
      !isSliderImages && !isOneImage
        ? `linear-gradient(180deg, ${firstColor} 0%, rgba(0, 0, 0, 0) 50%, ${secondColor} 100%)`
        : ''};
    z-index: 10;
  }
`;

export const StyledImage = styled.div<{ src: string }>`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  max-width: calc(${BREAKPOINTS.s}px - 120px);
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: flex;
    padding-bottom: 100%;
  }
`;
