import React, { FC } from 'react';

import { StyledTitle } from 'components/List/Content/styled';
import { StyledDescription } from 'components/List/styled';
import { StyledSectionInfo } from './styled';

interface ISliderHeaderProps {
  isOneItem?: boolean;
  isDark: boolean;
  title: string;
  description?: string;
}

const SliderHeader: FC<ISliderHeaderProps> = ({ title, description, isOneItem, isDark }) => (
  <StyledSectionInfo className="slider-info" hasOneItem={!!isOneItem}>
    <StyledTitle isExplore isDark={isDark}>
      {title}
    </StyledTitle>
    <StyledDescription isDark={isDark}>{description}</StyledDescription>
  </StyledSectionInfo>
);

export default SliderHeader;
