import {
  useCreateUserHiddenWordsMutation,
  useGetUserHiddenWordsQuery,
  useRemoveUserHiddenWordsMutation,
  GetUserHiddenWordsDocument,
  useValidateThoughtQuery,
  GetExploreEveryoneDocument,
} from 'constants/graphqlTypes';
import { EQueryFetchPolicy, SHOW_FETCH_MORE_LOADER } from 'constants/common';
import { updateUserHiddenWords } from './helpers';

export const useGetUserHiddenWords = () => {
  const { data, loading, fetchMore } = useGetUserHiddenWordsQuery({
    variables: { offset: 0, limit: 10 },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.getUserHiddenWords ?? {};

  const loadMore = () =>
    items &&
    totalCount &&
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateUserHiddenWords(prev, fetchMoreResult),
    });

  return { items, totalCount, loadMore, loading };
};

export const useCreateHiddenWords = (text: string) => {
  const [createHiddenWords, { loading }] = useCreateUserHiddenWordsMutation({
    variables: { text },
    refetchQueries: [GetUserHiddenWordsDocument, { query: GetExploreEveryoneDocument, variables: {} }],
  });
  return { createHiddenWords, loading };
};

export const useRemoveHiddenWords = () => {
  const [removeHiddenWords] = useRemoveUserHiddenWordsMutation();
  return (hiddenWordId?: string) =>
    removeHiddenWords({
      variables: { hiddenWordId },
      refetchQueries: [GetUserHiddenWordsDocument, { query: GetExploreEveryoneDocument, variables: {} }],
    });
};

export const useValidateThought = () => {
  const { refetch } = useValidateThoughtQuery({
    variables: {
      text: '',
    },
  });
  return (text?: string | null) => refetch({ text });
};
