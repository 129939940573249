interface ILayoutTestIds {
  titleTestId: string;
  subtitleTestId: string;
}

export const generateLayoutTestIds = (baseTestId?: string): ILayoutTestIds => {
  const titleTestId = `${baseTestId}_title-label`;
  const subtitleTestId = `${baseTestId}_subtitle-label`;

  return { titleTestId, subtitleTestId };
};
