import React, { FC } from 'react';

import BlockButton from 'components/Blocking/UserButton';

import { useGetInviteDetails } from 'graphQL/list/invite/hooks';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import { EReportTypes } from 'components/Report/constants';
import Badge from 'components/UI/Badge';
import { StyledBtnWrap, StyledButtonDecline, StyledDeclinedWrap, StyledInviteText } from '../ListCollabInvite/styled';

interface IListCollabInvite {
  isDark: boolean;
  inviteId: string;
}

const ListCollabDecline: FC<IListCollabInvite> = ({ isDark, inviteId }) => {
  const { message, inviter, list } = useGetInviteDetails(inviteId);
  const { id, cardId } = list ?? {};
  const { username, isBlockedByMe } = inviter ?? {};

  const handleReport = useNavigateToReport({
    inviteId,
    cardId,
    isBlockedByMe,
    id,
    type: EReportTypes.Invite,
  });

  return username ? (
    <StyledDeclinedWrap>
      {inviter && <Badge user={inviter} isDark={isDark} />}
      {message && <StyledInviteText>&ldquo;{message}&rdquo;</StyledInviteText>}
      <StyledBtnWrap>
        <StyledButtonDecline onClick={handleReport}>Report invite</StyledButtonDecline>
        {!isBlockedByMe && inviter && (
          <BlockButton userId={inviter.id} isBlockedByMe={!!isBlockedByMe}>
            <StyledButtonDecline>Block user</StyledButtonDecline>
          </BlockButton>
        )}
      </StyledBtnWrap>
    </StyledDeclinedWrap>
  ) : null;
};

export default ListCollabDecline;
