import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ROUTE_PRIVACY_AND_DATA,
  ROUTE_BLOCKED_ACCOUNTS,
  ROUTE_DELETE_MY_ACCOUNT,
  ROUTE_DATA_PREFERENCES,
  ROUTE_REQUEST_YOUR_DATA,
  ROUTE_HIDDEN_WORDS,
  ROUTE_CUSTOM_WORDS,
  ROUTE_MUTED_ACCOUNTS,
  ROUTE_CONNECTING_WITH_OTHERS,
} from 'routes';
import PrivacyAndDataMenu from './PrivacyAndDataMenu';
import BlockedAccounts from './BlockedAccounts';
import DeleteAccount from './DeleteAccount';
import DataPreferences from './DataPreferences';
import RequestYourData from './RequestYourData';
import HiddenWords from './HiddenWords';
import CustomWords from './HiddenWords/CustomWords';
import MutedAccounts from './MutedAccounts';
import ConnectingWithOthers from './ConnectingWithOthers';

const PrivacyAndData: FC = () => (
  <Switch>
    <Route exact path={ROUTE_PRIVACY_AND_DATA}>
      <PrivacyAndDataMenu />
    </Route>
    <Route path={ROUTE_DATA_PREFERENCES}>
      <DataPreferences />
    </Route>
    <Route path={ROUTE_CONNECTING_WITH_OTHERS}>
      <ConnectingWithOthers />
    </Route>
    <Route path={ROUTE_BLOCKED_ACCOUNTS}>
      <BlockedAccounts />
    </Route>
    <Route path={ROUTE_MUTED_ACCOUNTS}>
      <MutedAccounts />
    </Route>
    <Route path={ROUTE_REQUEST_YOUR_DATA}>
      <RequestYourData />
    </Route>
    <Route path={ROUTE_HIDDEN_WORDS}>
      <HiddenWords />
    </Route>
    <Route path={ROUTE_CUSTOM_WORDS}>
      <CustomWords />
    </Route>
    <Route path={ROUTE_DELETE_MY_ACCOUNT}>
      <DeleteAccount />
    </Route>
  </Switch>
);
export default PrivacyAndData;
