import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 150px;
  align-items: center;
  justify-content: center;
`;

export const StyledContent = styled.div<{ isDark: boolean }>`
  border-radius: 12px;
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.light[100])};
  display: flex;
  height: fit-content;
  padding: 24px 16px;
`;

export const StyledTextContainer = styled.div<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.light[100])};
  padding-left: 12px;
`;

export const StyledText = styled.span<{ isSecondary?: boolean }>`
  font: 400 ${({ isSecondary }) => (isSecondary ? 12 : 14)}px / 140% ${FONTS.secondary};
`;
