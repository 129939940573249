import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';

import { BREAKPOINTS } from 'styles/constants';

export const StyledPlayIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='14' fill='none'%3E%3Cpath d='M12 7 0 14V0l12 7Z' fill='%23fff'/%3E%3C/svg%3E");
  bottom: 12px;
  content: '';
  height: 14px;
  left: 12px;
  position: absolute;
  width: 12px;
`;

export const StyledWrap = styled.div`
  margin: 0 auto;
  max-width: ${BREAKPOINTS.s}px;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
`;

export const StyledImageContainer = styled.div`
  position: relative;
`;
export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
