import { useHistory } from 'react-router-dom';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import useToast from 'helpers/useToast';
import getAuthUser from 'helpers/getAuthUser';

import { transformRoute } from 'helpers/routingHelper';
import {
  CardSchema,
  ECardRoleTypeOptions,
  useGetInviteDetailsQuery,
  useGetInviteListQuery,
  useHandleInvitationMutation,
  useInviteCollaboratorMutation,
  UserCardRoleListObjectType,
  GetCollaborationListsDocument,
  GetTotalCountCardsDocument,
  ECollaborativeInviteStatusOptions,
  GetDetailCardDocument,
} from 'constants/graphqlTypes';

import { ROUTE_LIST_DETAIL, ROUTE_MESSAGES } from 'routes';
import { EQueryFetchPolicy } from 'constants/common';
import usePageType from 'helpers/usePageType';

export const useGetInviteList = (id?: string): { card: CardSchema | undefined; loading: boolean } => {
  const { data, loading } = useGetInviteListQuery({
    variables: { id },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    skip: !id,
  });

  return { card: data?.allCards?.items?.[0], loading };
};

export const useGetInviteDetails = (inviteId: string) => {
  const { data, loading } = useGetInviteDetailsQuery({
    variables: { inviteId },
  });

  return {
    loading,
    message: data?.getInvite?.message,
    inviter: data?.getInvite?.inviter,
    list: data?.getInvite?.collection,
  };
};

export const useInviteCollaborator = (
  userId: string,
  listCardId: string,
  message: string,
  roleName: ECardRoleTypeOptions
) => {
  const posthogCapture = usePostHogCapture();
  const [inviteMutation, { loading }] = useInviteCollaboratorMutation({
    variables: {
      userId,
      listCardId,
      message,
      roleName,
    },
    onCompleted: () => {
      posthogCapture(POSTHOG_EVENTS.CollaborativeList);
      posthogCapture(POSTHOG_EVENTS.ListInvite);
    },
  });

  return { inviteMutation, loading };
};

export const useHandleInvite = (
  inviteId: string,
  listId?: string,
  currentPath?: string
): ((isAccept: boolean) => void) => {
  const posthogCapture = usePostHogCapture();

  const { isListDetail } = usePageType();
  const { setToast } = useToast();
  const { replace } = useHistory();
  const { userId } = getAuthUser();

  const [mutation] = useHandleInvitationMutation();

  const handleInvite = (isAccept: boolean) =>
    mutation({
      variables: { invitationId: inviteId, isAccept },
      refetchQueries: [
        GetCollaborationListsDocument,
        {
          query: GetTotalCountCardsDocument,
          variables: { id: userId },
        },
        { query: GetDetailCardDocument, variables: { id: listId } },
      ],
      update: (cache) => {
        cache.modify({
          id: cache.identify({ id: listId, __typename: 'CardSchema' }),
          fields: {
            isCollaborative: (prev: boolean) => (isAccept ? true : prev),
            isPickedByMe: (prev: boolean) => (isAccept ? true : prev),
            creators: (prev: UserCardRoleListObjectType) => ({
              ...prev,
              totalCount: prev.totalCount + (isAccept ? 1 : 0),
              items: isAccept
                ? [
                    { roleName: ECardRoleTypeOptions.Collaborator, user: { __ref: `UserObjectType:${userId}` } },
                    ...prev.items,
                  ]
                : prev.items,
            }),
            invite: () => null,
          },
        });

        cache.modify({
          id: cache.identify({ id: inviteId, __typename: 'CollaborationInviteSchema' }),
          fields: {
            status: () =>
              isAccept ? ECollaborativeInviteStatusOptions.Resend : ECollaborativeInviteStatusOptions.Declined,
          },
        });
      },
    }).then(() => {
      posthogCapture(POSTHOG_EVENTS.CollaborativeList);
      if (isAccept) {
        replace({
          pathname: transformRoute(ROUTE_LIST_DETAIL, { listId }),
          state: { backUrl: currentPath ?? undefined, prevPath: ROUTE_MESSAGES },
        });
        posthogCapture(isListDetail ? POSTHOG_EVENTS.DetailPageAccept : POSTHOG_EVENTS.InviteAccept);
      } else {
        setToast?.({ isToastOpen: true, toastItemName: 'Invite declined' });
        posthogCapture(POSTHOG_EVENTS.InviteDecline);
      }
    });

  return handleInvite;
};
