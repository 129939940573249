import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import { ROUTE_NOTIFICATION_EMAIL_SETTING, ROUTE_NOTIFICATION_PUSH_SETTING } from 'routes';
import {
  TEST_ID_NOTIFICATIONS_SETTINGS_START,
  TEST_NOTIFICATIONS_SETTINGS_APP,
  TEST_NOTIFICATIONS_SETTINGS_EMAIL,
} from 'constants/aqa/settings/accountManagement';

import useTheme from 'helpers/useTheme';
import { StyledList } from 'pages/Settings/styled';
import SinglePageLayout from 'components/SinglePageLayout';
import MenuButton from 'components/UI/MenuButton';
import { StyledItem } from 'components/AddContent/Menu/styled';

const NotificationsMenu: FC = () => {
  const { push } = useHistory();
  const isDark = useTheme();

  return (
    <SinglePageLayout pageTestId={TEST_ID_NOTIFICATIONS_SETTINGS_START} title="Notifications">
      <StyledList isDark={isDark}>
        <StyledItem>
          <MenuButton
            dataTestId={TEST_NOTIFICATIONS_SETTINGS_APP}
            text="App notifications"
            handler={() => push(ROUTE_NOTIFICATION_PUSH_SETTING)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            dataTestId={TEST_NOTIFICATIONS_SETTINGS_EMAIL}
            text="Email notifications"
            handler={() => push(ROUTE_NOTIFICATION_EMAIL_SETTING)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
          />
        </StyledItem>
      </StyledList>
    </SinglePageLayout>
  );
};

export default NotificationsMenu;
