import React, { FC, useEffect } from 'react';
import { useGetSearchParam } from 'helpers/routingHelper';
import { useNavigationRef } from 'components/Navigation/helpers/hooks';
import { COLORS } from 'styles/constants';

import AnimatedBackground from 'components/AnimatedBackground';
import Navigation from 'components/Navigation/Navigation';
import Grain from 'components/UI/Grain';
import { useUpdateUserSettings, useUserSettings } from 'graphQL/contextualIntro/hooks';
import { ABOUT_US_CONTENT_ID, ABOUT_US_TITLE_LABEL } from 'constants/aqa/aboutUs';

import { StyledBgWrap, StyledWrap, StyledTitle, StyledText } from './styled';

const AboutUs: FC = () => {
  useNavigationRef(COLORS.white[100]);
  const hasWelcome = useGetSearchParam('welcome');
  const Layout = hasWelcome ? AnimatedBackground : StyledBgWrap;
  const { isViewedAboutPage } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings({ isViewedAboutPage: true });

  useEffect(() => {
    if (!hasWelcome && !isViewedAboutPage) {
      updateUserSettings();
    }
  }, []);

  return (
    <Layout>
      {!hasWelcome && <Grain />}
      <Navigation />
      <StyledWrap>
        <StyledTitle data-testid={ABOUT_US_TITLE_LABEL}>About us</StyledTitle>
        <StyledText data-testid={`${ABOUT_US_CONTENT_ID}_0`} delay={0} isAnimated={!hasWelcome}>
          There’s so much that’s good about the internet.
        </StyledText>
        <StyledText data-testid={`${ABOUT_US_CONTENT_ID}_1`} delay={600} isAnimated={!hasWelcome}>
          But it’s harder and harder to simply show up as a human with interests and ideas.
        </StyledText>
        <StyledText data-testid={`${ABOUT_US_CONTENT_ID}_2`} delay={1200} isAnimated={!hasWelcome}>
          At guide:human, we’re creating a space where individuals share the things they’re into and the thoughts
          they’re having, free from the trolling and clickbait behavior of traditional social media.
        </StyledText>
        <StyledText data-testid={`${ABOUT_US_CONTENT_ID}_3`} delay={1800} isAnimated={!hasWelcome}>
          We believe that humans are good—we’re building a space where we can act that way.
        </StyledText>
      </StyledWrap>
    </Layout>
  );
};

export default AboutUs;
