import { useLink } from 'helpers/routingHelper';
import {
  ROUTE_SPARK_RESP_ADD_PICK,
  ROUTE_SPARK_RESP_ADD_STYLE,
  ROUTE_SPARK_RESP_ADD_THOUGHT,
  ROUTE_SPARK_RESP_REPORT_STYLE_ISSUE,
  ROUTE_SPARK_RESP_REPORT_STYLE_OTHER,
  ROUTE_SPARK_RESP_STYLE_DESCRIPTION,
} from 'routes';

import { IUseSparkResponseLinks } from './models';

export const useSparkResponseLinks = (): IUseSparkResponseLinks => {
  const addResponsePick = useLink(ROUTE_SPARK_RESP_ADD_PICK);
  const addResponseStyle = useLink(ROUTE_SPARK_RESP_ADD_STYLE);
  const addResponseThought = useLink(ROUTE_SPARK_RESP_ADD_THOUGHT);
  const sparkResponseStyleDescription = useLink(ROUTE_SPARK_RESP_STYLE_DESCRIPTION);
  const sparkResponseReportStyleIssue = useLink(ROUTE_SPARK_RESP_REPORT_STYLE_ISSUE);
  const sparkResponseReportStyleOther = useLink(ROUTE_SPARK_RESP_REPORT_STYLE_OTHER);

  return {
    addResponsePick,
    addResponseStyle,
    addResponseThought,
    sparkResponseStyleDescription,
    sparkResponseReportStyleIssue,
    sparkResponseReportStyleOther,
  };
};
