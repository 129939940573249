import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledSelect = styled.div`
  margin-left: auto;
  position: relative;
`;

export const StyledButton = styled.button<{ isOpen?: boolean; disabled?: boolean }>`
  align-items: center;
  background-color: ${COLORS.white[10]};
  border: none;
  border-radius: 22px;
  color: ${({ disabled }) => (disabled ? COLORS.brown.light[100] : COLORS.white[100])};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: inline-flex;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  font-weight: 600;
  gap: 4px;
  height: 44px;
  padding: 0 20px;
  text-transform: capitalize;

  svg {
    transform: rotate(${({ isOpen }) => (isOpen ? 90 : -90)}deg);
    transition: 0.15s transform ease-in-out;

    path {
      fill: ${COLORS.white[100]};
    }
  }
`;

export const StyledRoleList = styled.ul`
  background-color: ${COLORS.white[90]};
  border-radius: 12px;
  list-style: none;
  padding: 16px 0;
  position: absolute;
  right: 0;
  top: 60px;
  width: 286px;
  z-index: 1;
`;

export const StyledRoleItem = styled.li<{ isActive?: boolean }>`
  align-items: center;
  border-bottom: 1px solid ${COLORS.gray[50]};
  cursor: pointer;
  display: flex;
  gap: 6px;
  padding: 12px 16px;

  &::before {
    background-image: ${({ isActive }) =>
      isActive
        ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.3 15.5c.6 0 1-.2 1.3-.6L15.3 3c.3-.3.3-.6.3-.9 0-.8-.5-1.3-1.3-1.3-.6 0-1 .2-1.2.7L6.3 12.2 2.8 7.9c-.3-.3-.6-.5-1-.5C.8 7.4.2 8 .2 8.7c0 .4.1.7.4 1L5 15c.4.4.8.6 1.3.6Z' fill='%23000'/%3E%3C/svg%3E")`
        : 'none'};
    content: '';
    flex: 0 0 16px;
    height: 16px;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledText = styled.span<{ isRed?: boolean }>`
  color: ${({ isRed }) => (isRed ? COLORS.red.dark : COLORS.black[100])};
  font-family: ${FONTS.secondary};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  i {
    color: ${COLORS.gray.dark};
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }
`;
