import { IGetMenuCoords } from './models';

export const getMenuCoords = (messageCoords: DOMRect, isMine: boolean): IGetMenuCoords => {
  const visualW = window.visualViewport?.width ?? window.innerWidth;

  return {
    ...(isMine ? { right: visualW - messageCoords.right } : { left: messageCoords.left }),
    bottom: messageCoords.bottom + 16,
  };
};
