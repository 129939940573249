import React, { Dispatch, FC, SetStateAction } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';

import { ECardRoleTypeOptions, ECollaborativeInviteStatusOptions, UserObjectType } from 'constants/graphqlTypes';
import getAuthUser from 'helpers/getAuthUser';
import { useHandleInvite } from 'graphQL/list/invite/hooks';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import { EReportTypes } from 'components/Report/constants';
import ConfirmationBlock from 'components/Report/ConfirmationBlock';
import {
  StyledAcceptButton,
  StyledBtnWrap,
  StyledButton,
  StyledDeclineButton,
} from 'components/InviteModal/Buttons/styled';
import { StyledPretitle, StyledTitle } from '../styled';

interface IInviteActionsProps {
  isDark: boolean;
  hasItems: boolean;
  title?: string | null;
  inviteId: string;
  pickId?: string;
  cardId?: string;
  user?: UserObjectType | null;
  status?: ECollaborativeInviteStatusOptions | null;
  roleName?: ECardRoleTypeOptions | null;
  isConfirmationOpen: boolean;
  setConfirmationOpen: Dispatch<SetStateAction<boolean>>;
}

const InviteActions: FC<IInviteActionsProps> = ({
  isDark,
  hasItems,
  title,
  inviteId,
  pickId,
  cardId,
  user,
  status,
  roleName,
  isConfirmationOpen,
  setConfirmationOpen,
}) => {
  const { pathname } = useLocation();
  const portalContainer = document.getElementById('modal-root');
  const { userId } = getAuthUser();
  const handleInvite = useHandleInvite(inviteId, pickId, pathname);
  const { isBlockedByMe, id } = user ?? {};
  const isMine = userId === id;
  const inviteText = roleName === ECardRoleTypeOptions.Viewer ? 'view' : 'collaborate';

  const report = useNavigateToReport({
    inviteId,
    cardId,
    isBlockedByMe,
    id,
    type: EReportTypes.Invite,
  });

  const handleReport = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    report();
  };

  const handleBlock = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setConfirmationOpen(true);
  };

  const handleDecline = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleInvite(false);
  };

  const handleAccept = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleInvite(true);
  };

  return (
    <>
      <StyledPretitle hasItems={hasItems}>
        {status === ECollaborativeInviteStatusOptions.Declined
          ? `Declined to ${inviteText}`
          : `Invite to ${inviteText}`}
      </StyledPretitle>
      {title && <StyledTitle hasItems={hasItems}>{title}</StyledTitle>}
      {isMine || status === ECollaborativeInviteStatusOptions.Accepted ? (
        <StyledAcceptButton onClick={() => {}}>View list</StyledAcceptButton>
      ) : (
        <StyledBtnWrap isColumn>
          {status === ECollaborativeInviteStatusOptions.Invited && (
            <>
              <StyledAcceptButton onClick={handleAccept}>Accept</StyledAcceptButton>
              <StyledDeclineButton isDark={!hasItems} onClick={handleDecline}>
                Decline
              </StyledDeclineButton>
            </>
          )}
          {status === ECollaborativeInviteStatusOptions.Declined && (
            <>
              <StyledButton isDark={isDark} onClick={handleReport}>
                Report invite
              </StyledButton>
              <StyledButton isDark={isDark} onClick={handleBlock}>
                Block user
              </StyledButton>
            </>
          )}
        </StyledBtnWrap>
      )}
      {id &&
        !isBlockedByMe &&
        isConfirmationOpen &&
        portalContainer &&
        createPortal(
          <ConfirmationBlock userId={id} handleCancel={() => setConfirmationOpen(false)} />,
          portalContainer
        )}
    </>
  );
};

export default InviteActions;
