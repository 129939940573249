import { ApolloCache, ApolloError } from '@apollo/client';

import useToast from 'helpers/useToast';

import { REF_PLACEHOLDER } from 'components/Explore/Item/helpers';
import { EReportTypes } from 'components/Report/constants';
import {
  CardSchema,
  CardTypeOptions,
  EReportTypeOptions,
  GetMessageContentDocument,
  GetMessageContentQuery,
  MessageContentStatus,
  MessageItemType,
} from 'constants/graphqlTypes';
import { IRefItem } from 'graphQL/models';
import { GQLErrorWithStatusCode } from 'helpers/errorHandler';
import { IGetNotificationsResult, IReportData } from './models';

interface IGetNotifications {
  isToastOpen?: boolean;
  message: string;
  action?: string;
  ending?: string;
  image?: string;
  linesCount?: number;
  type?: CardTypeOptions;
  onCompleted?: () => void;
  onError?: () => void;
  onLockedError?: () => void;
  toastLinkText?: string;
  handleToastButton?: () => void;
  toastHandler?: () => void;
}

export const checkHasErrorWithCode = (error: ApolloError, code: number) =>
  !!error.graphQLErrors.find((errorWStatusCode: GQLErrorWithStatusCode) => errorWStatusCode.statusCode === code);

export const getNotifications = ({
  isToastOpen = true,
  message,
  action,
  ending,
  image,
  type,
  linesCount,
  onCompleted,
  onError,
  onLockedError,
  toastLinkText,
  handleToastButton,
  toastHandler,
}: IGetNotifications): IGetNotificationsResult => {
  // TODO: ARTEM REWORK LOGIC TO DO NOT USE HOOK INSIDE FUNCTION
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { setToast } = useToast();

  return {
    onError: (error: ApolloError) => {
      if (checkHasErrorWithCode(error, 423)) {
        onLockedError?.();
        return;
      }
      setToast({
        isToastOpen,
        toastItemName: error.message,
        toastError: true,
      });
      onError?.();
    },
    onCompleted: () => {
      setToast({
        isToastOpen,
        toastItemName: message,
        toastAction: action,
        toastItemEnding: ending,
        toastImg: image,
        toastType: type,
        linesCount,
        toastLinkText,
        handleToastButton,
        toastHandler,
      });
      onCompleted?.();
    },
  };
};

export const hasItemId = (item: IRefItem, id?: string): boolean => item?.__ref?.includes(id ?? REF_PLACEHOLDER);

export const addToCards = (items: IRefItem[], cardId: string): IRefItem[] => [
  { __ref: `CardSchema:${cardId}` },
  ...items,
];

export const removeFromItems = (items: IRefItem[], cardId?: string): IRefItem[] =>
  items.filter((card) => !hasItemId(card, cardId));

export const getReportType = ({
  userCardId,
  userId,
  inviteId,
  commentId,
  chatId,
  type,
  isLeaveReport,
  reportComment,
}: IReportData): EReportTypeOptions => {
  if (commentId) {
    return EReportTypeOptions.CommentReport;
  }

  if (inviteId) {
    return EReportTypeOptions.InviteReport;
  }

  if (chatId) {
    return EReportTypeOptions.ChatReport;
  }

  if (userId || userCardId) {
    return EReportTypeOptions.UserReport;
  }

  if (type === EReportTypes.List) {
    return EReportTypeOptions.ListReport;
  }

  if (type === EReportTypes.Spark) {
    return EReportTypeOptions.SparkReport;
  }

  if (reportComment || type === EReportTypeOptions.ExtractReport) {
    return EReportTypeOptions.ExtractReport;
  }

  if (isLeaveReport) {
    return EReportTypeOptions.LeaveReport;
  }

  return EReportTypeOptions.CardReport;
};

export const updateMessageOnListDeletion = (cache: ApolloCache<unknown>, card?: CardSchema): void => {
  cache.updateQuery(
    {
      query: GetMessageContentDocument,
      variables: {
        pickId: card?.id,
        itemType: MessageItemType.Collection,
      },
    },
    (data: GetMessageContentQuery | null) => ({
      ...data,
      getMessageContent: { ...data?.getMessageContent, messageContentStatus: MessageContentStatus.Deleted },
    })
  );
};
