import React, { FC } from 'react';

import { ROUTE_USER_PROFILE } from 'routes';
import { UserObjectType } from 'constants/graphqlTypes';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { StyledBio, StyledCuratedAvatar, StyledHeader, StyledText, StyledUserInfo, StyledWrap } from './styled';

import AvatarGH from '../AvatarGH';
import Avatar from '../Avatar';
import UILink from '../Link';

interface IHighlightProps {
  highlight: string;
  curator?: UserObjectType | null;
  isStandAlone?: boolean | null;
  isDark?: boolean;
}

const Highlight: FC<IHighlightProps> = ({ isDark = true, curator, highlight, isStandAlone }) => {
  const { username: profileName, userInfo } = curator ?? {};
  const { bio } = userInfo ?? {};

  return (
    <StyledWrap hasWrap={!curator} isDark={isDark}>
      {curator ? (
        <UILink route={ROUTE_USER_PROFILE} params={{ profileName }}>
          <StyledCuratedAvatar>
            <Avatar user={curator} />
            {isStandAlone && <AvatarGH dimension={20} isRounded />}
          </StyledCuratedAvatar>
        </UILink>
      ) : (
        <StyledHeader>
          <AvatarGH />
          Highlighted pick
        </StyledHeader>
      )}
      <StyledText isBig={!!curator}>
        {curator && isStandAlone && (
          <StyledUserInfo>
            {generateFullName(curator)}
            {bio && <StyledBio>{bio}</StyledBio>}
          </StyledUserInfo>
        )}
        {!curator || isStandAlone ? highlight : `“${highlight}”`}
      </StyledText>
    </StyledWrap>
  );
};

export default Highlight;
