import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import useTheme from 'helpers/useTheme';

import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { ROUTE_BLOCKED_ACCOUNTS_SEARCH, ROUTE_MUTED_ACCOUNTS_SEARCH } from 'routes';
import { TEST_BLOCKED_ACCOUNTS_PLUS_BTN, TEST_MUTED_ACCOUNTS_PLUS_BTN } from 'constants/aqa/settings/accountManagement';
import { StyledStrokeButton } from '../styled';

const BlockedAccountsButton: FC<{ isMutedSearch?: boolean }> = ({ isMutedSearch }) => {
  const { push } = useHistory();
  const isDark = useTheme();

  return (
    <StyledStrokeButton
      isDark={isDark}
      onClick={() => push(isMutedSearch ? ROUTE_MUTED_ACCOUNTS_SEARCH : ROUTE_BLOCKED_ACCOUNTS_SEARCH)}
      data-testid={isMutedSearch ? TEST_MUTED_ACCOUNTS_PLUS_BTN : TEST_BLOCKED_ACCOUNTS_PLUS_BTN}
    >
      <PlusIcon height={44} width={44} viewBox="0 0 20 20" />
    </StyledStrokeButton>
  );
};

export default BlockedAccountsButton;
