import React, { FC } from 'react';

import { ReactComponent as DotMenuIcon } from 'assets/icons/SmallerDotMenu.svg';
import UrlAvatars from 'components/UI/UrlAvatars';
import UILink from 'components/UI/Link';
import { ROUTE_CUSTOM_FEED_DETAIL } from 'routes';
import { IFeedCardProps } from './models';

import {
  StyledAvatarsWrap,
  StyledAvatarsWrapText,
  StyledContainer,
  StyledContent,
  StyledCover,
  StyledDotMenuWrap,
  StyledImageUser,
  StyledImageWrap,
  StyledTags,
  StyledTitle,
} from './styled';

const FeedCard: FC<IFeedCardProps> = ({ feed, handler }) => {
  const { item, counterUnreadPicks, counterUsers, cover, bgColor, userCovers } = feed;

  const plusMoreUsers = counterUsers ? counterUsers - (userCovers?.items?.length ?? 0) : 0;

  const userUrls: string[] =
    userCovers?.items?.reduce(
      (urls: string[], curr) => (curr?.images?.[0].url ? [...urls, curr.images?.[0].url] : urls),
      []
    ) || [];

  const tags = item?.topicsFromFeed?.items?.map(({ name }) => name)?.join(', ') ?? '';

  return (
    <UILink route={ROUTE_CUSTOM_FEED_DETAIL} params={{ feedId: feed.id }}>
      <StyledContainer>
        <StyledImageWrap counter={counterUnreadPicks ?? 0}>
          <StyledCover src={cover?.images?.[0]?.url ?? ''} bgColor={bgColor?.colorValue} />
          {userCovers?.items[0]?.images?.[0].url && (
            <StyledImageUser src={userCovers?.items[0]?.images?.[0].url} hasUnreadPicks={!!feed.counterUnreadPicks} />
          )}
        </StyledImageWrap>
        <StyledContent>
          <StyledTitle>{item.title}</StyledTitle>
          <StyledTags>{tags}</StyledTags>
          {!!userUrls.length && (
            <StyledAvatarsWrap>
              <UrlAvatars urls={userUrls} limit={3} />
              {!!plusMoreUsers && (
                <StyledAvatarsWrapText>
                  + {plusMoreUsers} more user{plusMoreUsers > 1 ? 's' : ''}
                </StyledAvatarsWrapText>
              )}
            </StyledAvatarsWrap>
          )}
        </StyledContent>
        {handler && (
          <StyledDotMenuWrap
            onClick={(e) => {
              e.preventDefault();
              handler();
            }}
          >
            <DotMenuIcon />
          </StyledDotMenuWrap>
        )}
      </StyledContainer>
    </UILink>
  );
};

export default FeedCard;
