import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

export const StyledWrapper = styled.div<{
  fontColor: string;
  isRight: boolean;
  gradientStart?: string | null;
  gradientEnd?: string | null;
}>`
  background: ${({ gradientStart }) => gradientStart ?? COLORS.white.off[100]};
  border-radius: 10px;
  margin-left: ${({ isRight }) => (isRight ? 'auto' : 0)}px;
  padding: 32px 16px;
  position: relative;
  width: 250px;

  // we cant set overflow: hidden to StyledWrapper to remove grain that extends beyond it
  // because it will cause reactions to usercard being cropped. [GH-14042]
  .grain {
    border-radius: 10px;
  }

  span {
    color: ${({ fontColor }) => fontColor};
    position: relative;
  }

  > span {
    padding-top: 0;
  }

  &::before {
    border-radius: 10px;
    background: ${({ gradientEnd }) =>
      `linear-gradient(${COLORS.transparent} 5%, ${gradientEnd ?? COLORS.black[60]} 100%)`};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: ${({ gradientStart }) => (gradientStart ? '1' : '0')};
  }
`;

export const StyledVerificationWrap = styled.div`
  padding-top: 8px;
`;
