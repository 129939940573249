import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import SliderInteractive from 'components/SliderInteractive';

import Modal from 'components/Modal';
import { ROUTE_HELP } from 'routes';
import { COLORS } from 'styles/constants';

import FirstSlide from './Slides/FirstSlide';
import SecondSlide from './Slides/SecondSlide';

import { StyledContainer, StyledDot, StyledDotsContainer, StyledModalContainer } from './styled';

const TourModal: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const [slideIndex, setSlideInd] = useState(0);
  const { push } = useHistory();

  return (
    <StyledModalContainer>
      <Modal
        isDark={false}
        bgColor={COLORS.green.light}
        buttonText="More help"
        handleDone={() => push(ROUTE_HELP)}
        customContentPadding="24px 0 21px"
        handleClose={handleClose}
        hideGrain
      >
        <StyledContainer>
          <SliderInteractive
            spaceBetween={0}
            fontColor={COLORS.white[100]}
            offset={0}
            isInfinite={false}
            onSlideChange={(ind) => setSlideInd(ind)}
          >
            <SwiperSlide>
              <FirstSlide />
            </SwiperSlide>
            <SwiperSlide>
              <SecondSlide />
            </SwiperSlide>
          </SliderInteractive>
          <StyledDotsContainer>
            <StyledDot isActive={slideIndex === 0} />
            <StyledDot isActive={slideIndex === 1} />
          </StyledDotsContainer>
        </StyledContainer>
      </Modal>
    </StyledModalContainer>
  );
};
export default TourModal;
