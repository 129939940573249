import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from 'components/Onboarding/Layout';
import TextInput from 'components/UI/TextInput';
import useChangeProfileName from 'components/ProfileSettings/EditProfile/ChangeName/hooks';
import useTheme from 'helpers/useTheme';
import { ROUTE_ONBOARDING_PROFILE } from 'routes';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import ContentTitle from 'components/UI/ContentTitle';
import { useUpdateCachedOnboardingStepV2, useUpdateOnboardingStepV2 } from 'graphQL/onboarding/hooks';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { ENewOnboardingStepOptions } from 'constants/graphqlTypes';
import { TEST_USER_INFO_FULLNAME_TEXT_FIELD, TEST_USER_INFO_TITLE } from 'constants/aqa/signup';

const Fullname: FC = () => {
  const { push } = useHistory();
  const posthogCapture = usePostHogCapture();
  const isDark = useTheme();

  const updateCache = useUpdateCachedOnboardingStepV2(ENewOnboardingStepOptions.Profile);
  const handleNext = () => {
    updateCache();
    push(ROUTE_ONBOARDING_PROFILE);
  };
  const { name, error, handleChange, handleError, handleDone } = useChangeProfileName(handleNext);

  const handleText = (value: string) => {
    handleChange(value.replaceAll(/\s{2,}/g, ' '));
    handleError(!value ? 'Required' : '');
  };

  const { updatedUser } = useUpdateOnboardingStepV2(ENewOnboardingStepOptions.FullName);

  useEffect(() => {
    if (!!updatedUser?.userInfo?.firstName || !!updatedUser?.userInfo?.lastName) {
      handleText(`${updatedUser?.userInfo?.firstName} ${updatedUser?.userInfo?.lastName}`);
      return;
    }
    if (updatedUser?.username) {
      handleText(updatedUser?.username);
    }
  }, [updatedUser]);

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.OnboardingFullName);
  }, []);

  return (
    <Layout
      primaryBtnText="Continue"
      handlePrimaryBtn={handleDone}
      bottomText="People use their real names on guide:human"
      maxWidth={528}
    >
      <ContentTitle dataTestId={TEST_USER_INFO_TITLE} isDark={isDark}>
        What’s your full name?
      </ContentTitle>
      <TextInput
        dataTestId={TEST_USER_INFO_FULLNAME_TEXT_FIELD}
        placeholder="Jo Garcia"
        isDark={isDark}
        text={name}
        setText={handleText}
        error={error}
        autoCapitalize={EAutoCapitalize.Words}
        shouldPrevent2ndSpace
      />
    </Layout>
  );
};

export default Fullname;
