import React, { FC } from 'react';

import { ReactComponent as DotMenuIcon } from 'assets/icons/DotMenu.svg';
import { StyledButton } from '../styled';

const DotMenuButton: FC<{ handler: () => void; dataTestId?: string }> = ({ handler, dataTestId }) => (
  <StyledButton data-testid={dataTestId} onClick={handler} className="icon-navigation dot-btn">
    <DotMenuIcon />
  </StyledButton>
);

export default DotMenuButton;
