import { Dispatch, RefObject, SetStateAction, createContext, useContext } from 'react';

interface IMessagesThreadProvider {
  scrollThreadContent?: () => void;
  ref?: RefObject<HTMLElement>;
  setDoesBlurExist?: Dispatch<SetStateAction<boolean>>;
  doesBlurExist?: boolean;
}

const MessagesThreadContext = createContext<IMessagesThreadProvider>({});

export const { Provider: MessagesThreadProvider } = MessagesThreadContext;

const useMessagesThreadContext = (): IMessagesThreadProvider => {
  const context = useContext(MessagesThreadContext);

  return context;
};

export default useMessagesThreadContext;
