import React, { FC } from 'react';

import { COLORS } from 'styles/constants';
import introVideo from 'assets/videos/saved-picks-intro.mp4';

import { StyledContent, StyledContentTitle, StyledSecondaryText } from './styled';

const FirstSlide: FC = () => (
  <>
    <StyledContentTitle color={COLORS.brown.dark[100]} isDark={false}>
      Use your app’s share actions to save picks to guide:human
    </StyledContentTitle>
    <StyledContent>
      <video autoPlay muted playsInline loop preload="auto">
        <source src={`${introVideo}#t=0.001`} type="video/mp4" />
      </video>
      <StyledSecondaryText isDark={false}> From any browser, Netflix, Spotify, and more.</StyledSecondaryText>
    </StyledContent>
  </>
);

export default FirstSlide;
