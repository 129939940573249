import React, { FC } from 'react';

import { IReplaceParams, transformRoute } from 'helpers/routingHelper';
import { LocationDescriptor } from 'history';
import { EUiLinkClassNames } from './constants';

import StyledLink from './styled';

interface ILinkProps {
  route: string | LocationDescriptor;
  isDisabled?: boolean;
  params?: IReplaceParams;
  classname?: EUiLinkClassNames;
  dataTestId?: string;
}

const Link: FC<ILinkProps> = ({ route, params, isDisabled, classname, children, dataTestId }) => (
  <StyledLink
    className={`ui-link ${classname || ''}`}
    to={typeof route === 'string' && params ? transformRoute(route, params) : route}
    disabled={isDisabled}
    onClick={isDisabled ? (e) => e.preventDefault() : undefined}
    data-testid={dataTestId}
  >
    {children}
  </StyledLink>
);

export default Link;
