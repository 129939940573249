import React, { FC } from 'react';

import Avatar from 'components/UI/Avatar';
import Person1 from 'assets/images/onboarding/person1.webp';
import Person2 from 'assets/images/onboarding/person2.webp';
import StyledAvatar from 'components/UI/Avatar/styled';

import { COLORS } from 'styles/constants';
import { UserObjectType } from 'constants/graphqlTypes';
import { StyledContainer, StyledAnimationStart, StyledAnimationEnd } from './styled';

const Avatars: FC<{ user: UserObjectType; isUploaded: boolean }> = ({ user, isUploaded }) => {
  const StyledElement = isUploaded ? StyledAnimationEnd : StyledAnimationStart;

  return (
    <StyledContainer>
      <StyledElement index={1}>
        <StyledAvatar color={COLORS.white[100]} dimension={180} src={Person1} />
      </StyledElement>
      <StyledElement>
        <Avatar dimension={180} user={user} />
      </StyledElement>
      <StyledElement index={2}>
        <StyledAvatar color={COLORS.white[100]} dimension={180} src={Person2} />
      </StyledElement>
    </StyledContainer>
  );
};

export default Avatars;
