import React, { FC } from 'react';

import Loader from '../Loader';
import StyledLoader from './styled';

const LoaderContent: FC<{ isDark?: boolean; isFullScreen?: boolean; isCentered?: boolean }> = ({
  isDark = true,
  isFullScreen,
  isCentered,
}) => (
  <StyledLoader isFullScreen={isFullScreen} isCentered={isCentered}>
    <Loader isDark={isDark} size={18} />
  </StyledLoader>
);

export default LoaderContent;
