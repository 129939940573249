import { styled } from 'linaria/react';
import { css } from 'linaria';
import { COLORS, FONTS } from '../../../../styles/constants';

export const StyledWrap = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledInfo = styled.div<{ isDark: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
  width: calc(100% - 44px);

  > a {
    color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;

export const StyledUserInfo = css`
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const StyledThought = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
  word-break: break-word;
`;

export const StyledPlaceholder = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.light[100])};
  cursor: pointer;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
`;

export const StyledContend = styled.div`
  position: relative;
  display: flex;
`;

export const StyledReactionsWrap = styled.div<{ isMyList: boolean }>`
  padding: ${({ isMyList }) => (isMyList ? 0 : '4px 0 12px')};
`;

export const StyledUsername = styled.span`
  display: flex;

  > b {
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    font-weight: 600;
  }
`;

export const StyledBio = styled.span`
  flex-shrink: 1;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
