import { styled } from 'linaria/react';

import { BREAKPOINTS } from 'styles/constants';

export const StyledListItem = styled.li<{ hasPaddings?: boolean }>`
  padding: ${({ hasPaddings }) => (hasPaddings ? '0 12px' : 0)};

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0;
  }
`;
