import { styled } from 'linaria/lib/react';
import { BREAKPOINTS } from 'styles/constants';

export const StyledGrid = styled.div`
  --COLUMN-GAP: 12px;

  margin: 0 -12px;
  display: grid;
  grid-gap: 32px var(--COLUMN-GAP);
  grid-template-columns: repeat(2, calc(50% - var(--COLUMN-GAP) / 2));

  > div:nth-child(5n + 1) {
    &:not(:first-child) {
      .bigcard {
        padding-top: 32px;
      }
    }
    .bigcard {
      padding-bottom: 32px;
    }

    grid-column: 1 / -1;
  }

  > div:nth-child(5n + 2),
  > div:nth-child(5n + 4) {
    padding-left: 12px;
  }

  > div:nth-child(5n + 3),
  div:nth-child(5n + 5) {
    padding-right: 12px;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    margin: 0;

    > div:nth-child(5n + 2),
    div:nth-child(5n + 4) {
      padding-left: 0px;
    }

    > div:nth-child(5n + 3),
    div:nth-child(5n + 5) {
      padding-right: 0px;
    }
  }
`;

export const StyledGridItem = styled.div`
  max-width: 100%;
`;
