import React, { ChangeEvent, FC } from 'react';

import { IToggleProps } from './models';

import { StyledWrap } from './styled';

const Toggle: FC<IToggleProps> = ({ checked, isDark, isDisabled, handler, dataTestId }) => {
  const handelToggle = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    handler?.();
  };

  return (
    <StyledWrap isDisabled={isDisabled} className="toggle" isDark={isDark} checked={checked}>
      <input data-testid={dataTestId} type="checkbox" onChange={handelToggle} checked={checked} disabled={isDisabled} />
    </StyledWrap>
  );
};

export default Toggle;
