import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from '../../styles/constants';

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StyledTitle = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: block;
  font: 400 14px/140% ${FONTS.primary};
  word-wrap: break-word;
  padding: 0 24px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0 12px;
  }
`;

export const StyledDescription = styled.span<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: -webkit-box;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 19.6px;
  overflow: hidden;
  padding: 0 24px 0;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 16px 6px 0;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledItem = styled.li`
  padding: 0 6px;
`;
