import { styled } from 'linaria/lib/react';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledHeaderWrap = styled.div`
  padding: 32px 24px 16px;

  p {
    font: 400 22px/130% ${FONTS.primary};
    margin: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding-left: 12px;
  }
`;

export const StyledSectionWrap = styled.div`
  padding: 24px 0;
`;

export const StyledSectionElement = styled.div`
  padding: 0 12px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0;
  }
`;

export const StyledTextContainer = styled.div`
  padding: 0 24px;
  cursor: pointer;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0 12px;
  }
`;

export const StyledSectionType = styled.div`
  color: ${COLORS.brown.light[100]};
  font-family: ${FONTS.secondary};
  font-size: 14px;
  padding-bottom: 8px;
`;

export const StyledTitle = styled.p<{ isDark: boolean }>`
  display: block;
  font-size: 22px;
  margin: 0 0 20px;

  a {
    align-items: end;
    color: inherit;
    display: flex;
    justify-content: flex-start;

    path {
      fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.light[100])};
    }
  }

  svg {
    margin-left: 10px;
    transform: rotate(180deg);
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(22px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledText = styled.p`
  font: 400 14px/140% ${FONTS.secondary};
`;

export const StyledSliderWrap = styled.div`
  display: flex;

  > div {
    flex: 1;
  }

  .swiper-slide {
    height: auto;
    width: 156px;
  }
`;
