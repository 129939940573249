import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import Grain from 'components/UI/Grain';
import Button from 'components/UI/Button';
import ProfileSlider from 'pages/OnboardingV2/ProfilePreview/ProfileSlider';

import { ROUTE_ONBOARDING_CONTENT_LIST_CREATION } from 'routes';
import { ENewOnboardingStepOptions } from 'constants/graphqlTypes';
import { MOCK_LISTS } from 'pages/OnboardingV2/ContentTutorial/constants';
import { useUpdateCachedOnboardingStepV2 } from 'graphQL/onboarding/hooks';

import {
  StyledWrap,
  StyledInner,
  StyledTitle,
  StyledButtonWrap,
  StyledTitleWrap,
} from 'pages/OnboardingV2/ProfilePreview/styled';

const ContentTutorial = () => {
  const { push } = useHistory();
  const posthogCapture = usePostHogCapture();
  const [index, setIndex] = useState(0);

  const updateCacheV2 = useUpdateCachedOnboardingStepV2(ENewOnboardingStepOptions.CreateList);

  const handleNext = () => {
    updateCacheV2();
    push(ROUTE_ONBOARDING_CONTENT_LIST_CREATION);
  };

  useEffect(() => {
    posthogCapture(POSTHOG_EVENTS.OnboardingContentPreviews);
  }, []);

  return (
    <StyledWrap bgColor={MOCK_LISTS[index].bgColor}>
      <Grain />
      <StyledInner>
        <StyledTitleWrap>
          <TypeAnimation
            wrapper="p"
            cursor={false}
            sequence={['', 1000, 'Lists and picks bring color to your passions', 2000]}
            className={StyledTitle}
            speed={80}
          />
        </StyledTitleWrap>
        <ProfileSlider items={MOCK_LISTS} setIndex={setIndex} />
      </StyledInner>
      <StyledButtonWrap>
        <Button text="Continue" handler={handleNext} />
      </StyledButtonWrap>
    </StyledWrap>
  );
};

export default ContentTutorial;
