import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import { useApolloClient } from '@apollo/client';

import AnimatedBackground from 'components/AnimatedBackground';
import { ROUTE_USER_PROFILE } from 'routes';
import { transformRoute } from 'helpers/routingHelper';
import getAuthUser from 'helpers/getAuthUser';

import {
  CheckOnboardingStatusDocument,
  CheckOnboardingStatusQuery,
  ENewOnboardingStepOptions,
  useUpdateUserMutation,
} from 'constants/graphqlTypes';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { usePostHogCapture } from 'helpers/posthogHooks';

import { StyledWrap } from './styled';

const Confirmation: FC = () => {
  const { push } = useHistory();
  const posthogCapture = usePostHogCapture();
  const client = useApolloClient();
  const { username, userId } = getAuthUser();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [updateUser] = useUpdateUserMutation({
    onCompleted: () => {
      posthogCapture(POSTHOG_EVENTS.isOnboardingTourDone);
      posthogCapture(POSTHOG_EVENTS.OnboardingCompletion);
      push(transformRoute(ROUTE_USER_PROFILE, { profileName: username }));
    },
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
    },
  });

  const cachedData: CheckOnboardingStatusQuery | null = client.readQuery({
    query: CheckOnboardingStatusDocument,
    variables: { id: userId },
  });

  const step = cachedData?.allUsers?.items[0]?.userSettings?.newOnboardingStep;
  const shouldEndOnboarding = !!step && step === ENewOnboardingStepOptions.Networking;

  useEffect(() => {
    if (shouldEndOnboarding && shouldRedirect) {
      updateUser({
        variables: {
          isOnboardingCompleted: true,
        },
      });
      localStorage.removeItem('bio');
    }
  }, [shouldEndOnboarding, shouldRedirect]);

  return (
    <AnimatedBackground>
      <TypeAnimation
        wrapper="h1"
        cursor={false}
        sequence={['', 0, 'That’s it! \n You’re all setup.', 1000, () => setShouldRedirect(true)]}
        className={StyledWrap}
        speed={45}
      />
    </AnimatedBackground>
  );
};

export default Confirmation;
