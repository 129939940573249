import React, { Dispatch, FC, useState } from 'react';

import {
  ChannelAdminFeedSchema,
  ChannelCustomFeedSchema,
  ChannelSectionItemsSortTypeOptions,
} from 'constants/graphqlTypes';

import CustomSortModal from './CustomSortModal';
import SortingMenuModal from './SortingMenuModal';

const SortFeedModal: FC<{
  selectedSorting?: ChannelSectionItemsSortTypeOptions;
  setSorting: Dispatch<React.SetStateAction<ChannelSectionItemsSortTypeOptions | undefined>>;
  handleClose: () => void;
  hideCustom?: boolean;
  hideDate?: boolean;
  items?: (ChannelCustomFeedSchema | ChannelAdminFeedSchema)[];
}> = ({ hideCustom, selectedSorting, handleClose, setSorting, items, hideDate }) => {
  const [isCustomSorting, setIsCustomSorting] = useState(false);

  return isCustomSorting && items ? (
    <CustomSortModal
      sortingType={selectedSorting}
      handleClose={handleClose}
      handlePrev={() => setIsCustomSorting(false)}
      setSorting={() => setSorting(undefined)}
    />
  ) : (
    <SortingMenuModal
      selectedSorting={selectedSorting}
      setSorting={setSorting}
      handleClose={handleClose}
      hideCustom={hideCustom}
      hideDate={hideDate}
      handleOpenCustom={() => setIsCustomSorting(true)}
    />
  );
};

export default SortFeedModal;
