import { ENavElements, EActionType, getDataFromLikes, IActionData, addActions } from 'graphQL/card/helpers/helpers';
import { EMPTY_ITEMS } from 'constants/common';
import { SelectedUsersInputFilter, useGetPicksQuery } from 'constants/graphqlTypes';

export const useGetPicks = ({
  id,
  skip,
  selectedUsers,
}: {
  id: string;
  skip?: boolean;
  selectedUsers?: SelectedUsersInputFilter;
}): IActionData => {
  const { data, fetchMore, loading } = useGetPicksQuery({
    variables: { id, selectedUsers, offset: 0, limit: selectedUsers ? 8 : 10 },
    skip,
  });

  const { items, totalCount } = data?.allCards?.items?.[0].picks ?? EMPTY_ITEMS;
  const isPickedByMe = data?.allCards?.items?.[0].isPickedByMe;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        const card = prev?.allCards?.items?.[0];
        const newItems = fetchMoreResult?.allCards?.items?.[0]?.picks?.items;

        return addActions({
          prev,
          type: EActionType.Picks,
          field: ENavElements.Picks,
          card,
          items: card?.picks?.items,
          totalCount,
          newItems,
        });
      },
    });

  return {
    totalCount,
    items: getDataFromLikes(items),
    loadMore,
    loading,
    isPickedByMe: !!isPickedByMe,
  };
};
