import {
  GetListsQuery,
  GetProfileListsQuery,
  GetUserCardsIdQuery,
  GetUserCardsQuery,
  GetCollaborationListsQuery,
  GetUserSparksQuery,
} from 'constants/graphqlTypes';

type QueryType = GetUserCardsIdQuery &
  GetProfileListsQuery &
  GetUserCardsQuery &
  GetListsQuery &
  GetCollaborationListsQuery &
  GetUserSparksQuery;

export const updateUserCards = (prev: QueryType, next?: QueryType): QueryType =>
  prev.allUsers?.items[0].pickedCards && next?.allUsers?.items[0].pickedCards
    ? {
        allUsers: {
          __typename: 'UserListObjectType',
          totalCount: 1,
          items: [
            {
              ...prev.allUsers.items[0],
              pickedCards: {
                ...prev.allUsers.items[0].pickedCards,
                items: [...prev.allUsers.items[0].pickedCards.items, ...next?.allUsers?.items[0].pickedCards.items],
              },
            },
          ],
        },
      }
    : prev;
