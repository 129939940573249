import React, { FC } from 'react';

import { StyledIconWrap, StyledSectionHeader, StyledSectionWrap } from './styled';

interface IListDescriptionItem {
  title: string;
  description: string;
  icon: FC;
}

const ListDescriptionSection: FC<{ item: IListDescriptionItem; isDark: boolean }> = ({ item, isDark }) => {
  const { title, description, icon: Icon } = item;

  return (
    <StyledSectionWrap isDark={isDark}>
      <StyledIconWrap>
        <Icon />
      </StyledIconWrap>

      <div>
        <StyledSectionHeader>{title}</StyledSectionHeader>
        {description}
      </div>
    </StyledSectionWrap>
  );
};

export default ListDescriptionSection;
