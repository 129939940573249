import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useTheme from 'helpers/useTheme';
import SavedPicksContent from 'components/SavedPicks/SavedPicksContent';
import Button from 'components/UI/Button';
import useDocumentTitle from 'helpers/useDocumentTitle';
import LeftMenu from 'components/Navigation/LeftMenu';
import ConfirmationModal from 'components/UI/ConfirmationModal/ConfirmationModal';
import useToast from 'helpers/useToast';

import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { ROUTE_SAVED_PICKS } from 'routes';
import {
  useDeleteArchivePicks,
  useDeleteSavedPicks,
  useGetSavedPicks,
  useSavedPicksAdding,
} from 'graphQL/savedPicks/hooks';
import { EUiButtonType } from 'components/UI/Button/constants';
import { useNavBehavior } from 'components/Navigation/helpers/hooks';
import { StyledNavigation } from 'components/Navigation/styled';
import { StyledButtonWrapper } from 'pages/OnboardingV2/ContentTutorial/ListDetail/styled';
import { StyledMainHeading, StyledText } from 'pages/CheckEmailPage/styled';
import { StyledBottomMenu, StyledButton, StyledPageInfoContainer, StyledWrap } from 'pages/SavedPicks/styled';
import { StyledWrapper } from './styled';

const pageTitle = 'Archive';
const modalText =
  'All items in the archive, as well as access to your archive will be removed, up until you have archived items again.';

const Archive: FC = () => {
  const [deleteAllMode, setDeleteAllMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [selectMode, setSelectMode] = useState(false);

  const isDark = useTheme();

  const { push } = useHistory();
  const { items, loading, loadMore } = useGetSavedPicks({ isArchived: true });
  const { setToast } = useToast();
  const { isHide, bgColor, fontColor } = useNavBehavior();

  const { handlePickerClick, selectedCards, cardsBaseSelection } = useSavedPicksAdding(items);
  const { selectedCards: selectedCardIds, resetSelectedCards } = cardsBaseSelection;

  const getPickCount = () => `${selectedCards.length} pick${selectedCards.length > 1 ? 's' : ''}`;
  const goToSavedPicks = () => push(ROUTE_SAVED_PICKS);

  const handleCancelSelect = () => {
    resetSelectedCards();
    setSelectMode(!selectMode);
  };

  const onDeleteComplete = () => {
    setToast({
      isToastOpen: true,
      toastItemName: `${getPickCount()} deleted`,
    });
    resetSelectedCards();
  };

  const onDeleteAllComplete = () => {
    setToast({
      isToastOpen: true,
      toastItemName: 'All archived picks were deleted',
    });
    goToSavedPicks();
  };

  const { deleteSavedPicks, loading: deleteLoading } = useDeleteSavedPicks(selectedCardIds, onDeleteComplete, true);
  const { deleteArchivePicks, loading: deleteArchiveLoading } = useDeleteArchivePicks(onDeleteAllComplete);
  const isLoading = loading || deleteLoading || deleteArchiveLoading;

  const handleDelete = () => {
    setDeleteMode(false);
    deleteSavedPicks();
  };

  const handleDeleteAll = () => {
    setDeleteAllMode(false);
    deleteArchivePicks();
  };

  useDocumentTitle(pageTitle);

  useEffect(() => {
    if (!loading && !items.length) {
      goToSavedPicks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <StyledWrapper>
      <StyledNavigation color={fontColor} isHide={isHide} bgColor={bgColor}>
        <LeftMenu />
        <StyledButtonWrapper>
          <Button
            text={`Delete${selectedCards.length ? '' : ' all'}`}
            handler={() => (selectedCards.length ? setDeleteMode(true) : setDeleteAllMode(true))}
            isDark={isDark}
            type={EUiButtonType.Primary}
            isDisabled={isLoading}
          />
          <Button
            text={selectMode ? 'Cancel' : 'Select'}
            handler={handleCancelSelect}
            isDark={isDark}
            type={EUiButtonType.Primary}
            isDisabled={isLoading}
          />
        </StyledButtonWrapper>
      </StyledNavigation>
      {!!selectedCardIds.length && (
        <StyledBottomMenu>
          <StyledButton aria-label="Click to add saved picks" onClick={handlePickerClick}>
            <PlusIcon viewBox="0 0 20 20" height="50px" width="50px" strokeWidth="0.75" />
          </StyledButton>
        </StyledBottomMenu>
      )}
      <StyledWrap>
        <StyledPageInfoContainer customHeaderGap={8} isPaddingBottom>
          <StyledMainHeading isDark={isDark}>{pageTitle}</StyledMainHeading>
          <StyledText isDark={isDark}>Things you saved, older than 30 days</StyledText>
        </StyledPageInfoContainer>
        <SavedPicksContent
          isDark={isDark}
          cards={items}
          loading={loading}
          loadMore={loadMore}
          isSelectable={selectMode}
          cardsSelection={cardsBaseSelection}
        />
      </StyledWrap>
      {deleteAllMode && (
        <ConfirmationModal
          buttonText="Delete archive"
          handleAccept={handleDeleteAll}
          subTitle={modalText}
          titleText=""
          handleCancel={() => setDeleteAllMode(false)}
        />
      )}
      {deleteMode && (
        <ConfirmationModal
          buttonText={`Delete ${getPickCount()}`}
          handleAccept={handleDelete}
          handleCancel={() => setDeleteMode(false)}
          subTitle="This action deletes selected links from saved picks"
        />
      )}
    </StyledWrapper>
  );
};

export default Archive;
