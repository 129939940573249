import React, { FC } from 'react';

import { UserObjectType } from 'constants/graphqlTypes';
import { ROUTE_USER_PROFILE } from 'routes';
import UILink from 'components/UI/Link';
import Avatar from 'components/UI/Avatar';

const UserAvatar: FC<{ user: UserObjectType; isDisabled?: boolean }> = ({ user, isDisabled }) => {
  const { username } = user;

  return isDisabled ? (
    <Avatar user={user} />
  ) : (
    <UILink route={ROUTE_USER_PROFILE} params={{ profileName: username }}>
      <Avatar user={user} />
    </UILink>
  );
};

export default UserAvatar;
