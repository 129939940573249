import { useUpdateCachedOnboardingStep } from 'graphQL/onboarding/hooks';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import config from 'constants/config';
import { EOnboardingStepOptions } from 'constants/graphqlTypes';
import { ROUTE_ONBOARDING_FULLNAME, ROUTE_ONBOARDING_PREVIEW, ROUTE_ONBOARDING_WELCOME } from 'routes';

export const useWelcomeRedirect = () => {
  const { push } = useHistory();
  const { isNewOnboardingEnabled } = config;

  const updateCache = useUpdateCachedOnboardingStep(EOnboardingStepOptions.FullName);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes(ROUTE_ONBOARDING_WELCOME)) {
      setTimeout(() => {
        if (!isNewOnboardingEnabled) {
          updateCache();
        }
        if (pathname.includes(ROUTE_ONBOARDING_WELCOME)) {
          push(isNewOnboardingEnabled ? ROUTE_ONBOARDING_PREVIEW : ROUTE_ONBOARDING_FULLNAME);
        }
      }, 1500);
    }
  }, [pathname]);
};
