import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { v4 } from 'uuid';

import { IInputStyle } from 'components/UI/TextInput/helpers/helpers';

import { StyledAnimatedLabel } from './styled';

const AnimatedPlaceholder: FC<{ items: string[]; inputStyle: IInputStyle }> = ({ items, inputStyle }) => {
  return (
    <StyledAnimatedLabel inputStyle={inputStyle} itemsLength={items.length}>
      <Swiper
        modules={[Autoplay]}
        loop
        spaceBetween={10}
        speed={800}
        autoplay={{ delay: 1300, disableOnInteraction: false }}
        direction="vertical"
      >
        {items.map((item) => (
          <SwiperSlide key={v4()}>{item}</SwiperSlide>
        ))}
      </Swiper>
    </StyledAnimatedLabel>
  );
};

export default AnimatedPlaceholder;
