import {
  GetUserDataRequestDocument,
  RequestUserDataMutationVariables,
  UserDataRequestObjectType,
  useGetUserDataRequestQuery,
  useRequestUserDataMutation,
} from 'constants/graphqlTypes';
import { IUseUpdateRequestUserData } from './models';

export const useRequestedAt = (): UserDataRequestObjectType => {
  const { data } = useGetUserDataRequestQuery();
  const { requestedAt } = data?.getUserDataRequest ?? {};

  return { requestedAt };
};

export const useUpdateRequestUserData = (userData: RequestUserDataMutationVariables): IUseUpdateRequestUserData => {
  const [requestUserDataMutation] = useRequestUserDataMutation({
    variables: userData,
    refetchQueries: [{ query: GetUserDataRequestDocument, variables: {} }],
  });

  return { requestUserDataMutation };
};
