import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS } from 'styles/constants';

export const StyledContentWrap = styled.div`
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-height: 400px;
  }
`;

export const StyledGroupSection = styled.section`
  padding: 16px 0;

  > div:first-child {
    padding: 0;
  }
`;

export const StyledEmojiWrap = styled.div`
  display: grid;
  flex-wrap: wrap;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(23px, 1fr));
  padding-top: 16px;

  > span {
    cursor: pointer;
  }
`;

export const StyledSectionsFilter = styled.section`
  display: flex;
  padding: 16px 0 12px;
  justify-content: space-between;
`;

export const StyledIconWrap = styled.span<{ isActive?: boolean }>`
  align-items: center;
  border-radius: 50px;
  border: none;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
  cursor: pointer;
  background: ${({ isActive }) => (isActive ? COLORS.white[10] : COLORS.transparent)};

  svg {
    circle {
      stroke: ${({ isActive }) => (isActive ? COLORS.white[100] : COLORS.brown.light[100])};
    }
    path {
      stroke: ${({ isActive }) => (isActive ? COLORS.white[100] : COLORS.brown.light[100])};
    }
  }
`;

export const StyledInputWrap = styled.div`
  padding: 16px 0;
`;
