import { useGetUpdateUserDataQuery } from 'constants/graphqlTypes';

import getAuthUser from 'helpers/getAuthUser';

export const useUpdateUserData = () => {
  const { userId } = getAuthUser();
  const { data } = useGetUpdateUserDataQuery({
    variables: { id: userId },
  });

  return data?.allUsers?.items[0];
};
