// TODO remove later when BE will be available
export const MOCK_EMOJIS = {
  thumbsup: '\u{1f44d}',
  redHeart: '\u{2764}\u{FE0F}',
  applause: '\u{1f44f}',
  fire: '\u{1f525}',
  laugh: '\u{1f602}',
};

export const BLOCKED_REACTIONS = [
  // windows
  'Hut',
  'Olive',
  'Blueberries',
  'Light Blue Heart',
  'Pink Heart',
  'Mending Heart',
  'Heart on Fire',
  'Smiling Face with Tear',
  'Face Exhaling',
  'Smiling Face',
  'Melting Face',
  'Black Cat',
  'Grey Heart',
  'Otter',
  // IOS 16.2
  'Light Blue Heart',
  'Pink Heart',
  'Grey Heart',
  // IOS 16.0
  'Maracas',
  'Flute',
  'Folding Hand Fan',
  'Hair Pick',
  'Donkey',
  'Moose',
  'Goose',
  'Black Bird',
  'Wing',
  'Jellyfish',
  'Hyacinth',
  'Shaking Face',
  'Khanda',
  'Wireless',
  'Pea Pod',
  'Ginger',
  // Android 12
  'Eye in Speech Bubble',
];
