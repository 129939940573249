import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS, FONTS } from '../../../styles/constants';

interface IStyledContentWrapProps {
  isPrivate: boolean;
  hasPicks: boolean;
  hasReactions: boolean;
  isCollaborative: boolean;
  isMyList: boolean;
  isOnboarding: boolean;
}

export const StyledWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${BREAKPOINTS.s}px;
  width: calc(100% - 48px);
`;

export const StyledTitle = styled.span<{ isDark: boolean; total?: number; isExplore?: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: block;
  font-size: ${({ isExplore }) => (isExplore ? 22 : 32)}px;
  line-height: ${({ isExplore }) => (isExplore ? '28.6px' : '120%')};
  padding: ${({ isExplore }) => (isExplore ? '0 24px 0' : '32px 0 16px')};
  position: relative;
  word-wrap: break-word;

  &::after {
    content: ${({ total }) => (Number.isInteger(total) ? `"${total}"` : 'none')};
    font-size: 14px;
    line-height: 140%;
    padding-left: 5px;
    position: relative;
    top: ${({ isExplore }) => (isExplore ? -10 : -15)}px;
  }
`;

export const StyledNoContent = styled.p<{ isDark: boolean; hideBorderTop?: boolean }>`
  border-top: ${({ hideBorderTop, isDark }) =>
    hideBorderTop ? 'none' : `1px solid ${isDark ? COLORS.white[30] : COLORS.brown.dark[10]}`};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font-family: ${FONTS.secondary};
  font-size: 14px;
  line-height: 140%;
  margin: 0 auto;
  padding-top: 24px;
  width: 100%;
`;

export const StyledPrivateWrap = styled.div<{ hasReactions: boolean }>`
  padding-top: ${({ hasReactions }) => (hasReactions ? '16px' : '4px')};
`;

export const StyledContentWrap = styled.div<IStyledContentWrapProps>`
  padding-bottom: ${({ isPrivate, hasPicks, hasReactions, isCollaborative, isMyList, isOnboarding }) => {
    if (isCollaborative && !isPrivate) {
      return 0;
    }
    if (hasPicks) {
      return '16px';
    }
    if (isPrivate || (hasReactions && isMyList) || isOnboarding) {
      return '40px';
    }
    return '28px';
  }};
`;

export const StyledCollabInviteWrap = styled.div`
  padding-top: 24px;
`;
