import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ApolloCache, ApolloError } from '@apollo/client';

import { useFeaturedFlagEnabled, usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS, EFeaturedFlags } from 'constants/posthogEvents';
import { EMPTY_ITEMS, EQueryFetchPolicy, SHOW_FETCH_MORE_LOADER } from 'constants/common';
import {
  CardSchema,
  CardTypeOptions,
  ECardSortingOptions,
  GetMessageContentDocument,
  GetMessageContentQuery,
  GetMessageContentQueryVariables,
  GetSparkDetailCardsDocument,
  GetSparkDetailCardsQuery,
  GetUserCardsDocument,
  GetUserSparksDocument,
  MessageContentStatus,
  MessageItemType,
  useAddCardToSparkMutation,
  useCreateSparkMutation,
  useDeleteSparkMutation,
  useGetFeaturedSparkCardsQuery,
  useGetSparkCardsQuery,
  useGetSparkDetailCardsQuery,
  useGetSparkDetailQuery,
  useGetSparksGradientsQuery,
  useGetUserSparksQuery,
} from 'constants/graphqlTypes';
import { useGetUserId } from 'graphQL/profile/hooks';
import {
  IUseAddResponseToSparkArgs,
  IUseGetSparkDetail,
  IUseGetUserSparks,
  IUseCreateSparkArgs,
  IUseGetSparksGradients,
} from 'graphQL/sparks/models';
import { updateUserCards } from 'graphQL/cards/userCards/helpers';
import useToast from 'helpers/useToast';
import { ROUTE_SPARK_RESP_ADD_PICK } from 'routes';
import getAuthUser from 'helpers/getAuthUser';

import {
  updateAllSparksCardsQueryCache,
  updateSparkCardsQueryCache,
  addCardToExistingCards,
  updateFeaturedSparksCardsQueryCache,
} from './helpers';

export const useGetSparksGradients = (): IUseGetSparksGradients => {
  const { data, loading } = useGetSparksGradientsQuery();

  const { items, totalCount } = data?.allColors ?? EMPTY_ITEMS;

  return {
    items,
    totalCount,
    loading,
  };
};

export const useCreateSpark = ({
  title,
  bgColorId,
  onCompleted,
}: IUseCreateSparkArgs): { handleDone: () => void; loading: boolean } => {
  const { setToast } = useToast();
  const posthogCapture = usePostHogCapture();
  const isInteractSparks = useFeaturedFlagEnabled(EFeaturedFlags.InteractSpark);

  const { push } = useHistory();
  const { state } = useLocation<{ variable?: { [key: string]: string | undefined } }>();

  const [createSpark, { data, loading }] = useCreateSparkMutation({
    variables: { title, bgColorId },
    onError: (error: ApolloError) => {
      setToast({
        isToastOpen: true,
        toastItemName: error.message,
        toastError: true,
      });
    },
    onCompleted: () => {
      setToast({
        customFontWeight: 400,
        isToastOpen: true,
        toastItemName: 'Your spark is live. Why not get it started with a first pick?',
        linesCount: 2,
        toastLinkText: isInteractSparks ? 'Add' : undefined,
        handleToastButton: () =>
          push({
            pathname: ROUTE_SPARK_RESP_ADD_PICK,
            state: { ...state, variable: { spark: data?.createSpark } },
          }),
      });
      posthogCapture(POSTHOG_EVENTS.CreateSpark);
      onCompleted?.();
    },
    refetchQueries: [
      { query: GetUserSparksDocument, variables: { id: getAuthUser().userId, limit: undefined } },
      {
        query: GetUserCardsDocument,
        variables: {
          id: getAuthUser().userId,
          type: CardTypeOptions.Spark,
          limit: 4,
          sorting: ECardSortingOptions.CardCreatedAtAsc,
          offset: 0,
          excludedIds: undefined,
        },
      },
    ],
  });

  return { handleDone: () => createSpark(), loading };
};

export const useGetUserSparks = (params?: { limit?: number; userId: string }): IUseGetUserSparks => {
  const { limit, userId } = params ?? {};
  const { profileName } = useParams<{ profileName: string }>();
  const { userId: id } = useGetUserId(profileName);
  const { data, fetchMore, loading } = useGetUserSparksQuery({
    variables: {
      id: userId ?? id ?? '',
      limit,
    },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
    skip: !userId && !id,
  });

  const { items, totalCount } = data?.allUsers?.items[0]?.pickedCards ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateUserCards(prev, fetchMoreResult),
    });

  return {
    items,
    loadMore,
    totalCount,
    loading,
  };
};

export const useGetSparkDetail = (id?: string): IUseGetSparkDetail => {
  const { sparkId } = useParams<{ sparkId: string }>();
  const [isQueryCalled, setIsQueryCalled] = useState(false);
  const { data, error } = useGetSparkDetailQuery({
    variables: {
      sparkId: id ?? sparkId,
    },
    skip: !sparkId && !id,
    onCompleted: () => setIsQueryCalled(true),
    onError: () => setIsQueryCalled(true),
  });

  return { spark: data?.allCards?.items?.[0], isQueryCalled, error };
};

export const useGetSparkDetailCards = (spark?: CardSchema) => {
  const { data, loading, fetchMore } = useGetSparkDetailCardsQuery({
    variables: { sparkId: spark?.id, sparkCardId: spark?.cardId, offset: 0, limit: 12 },
    skip: !spark,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const { items, totalCount } = data?.allCards?.items?.[0].sparkResponses ?? {};

  const loadMore = () =>
    fetchMore({
      variables: {
        offset: items?.length,
        limit: 12,
      },
      updateQuery: (prev, { fetchMoreResult }) => updateSparkCardsQueryCache(prev, fetchMoreResult),
    });

  return {
    items,
    totalCount: totalCount || 0,
    loadMore,
    loading,
  };
};

export const useDeleteSpark = () => {
  const [deleteMutation] = useDeleteSparkMutation();

  return (sparkCardId: string, onCompleted?: () => void) =>
    deleteMutation({
      variables: { sparkId: sparkCardId },
      onCompleted,
      refetchQueries: [{ query: GetUserSparksDocument, variables: { id: getAuthUser().userId, limit: undefined } }],
      update: (cache: ApolloCache<GetMessageContentQuery>, { data }) => {
        if (!data) {
          return;
        }

        cache.updateQuery<GetMessageContentQuery, GetMessageContentQueryVariables>(
          {
            query: GetMessageContentDocument,
            variables: {
              sparkId: sparkCardId,
              itemType: MessageItemType.Spark,
            },
          },
          (oldMessageContent) => ({
            ...oldMessageContent,
            getMessageContent: {
              ...oldMessageContent?.getMessageContent,
              messageContentStatus: MessageContentStatus.Deleted,
            },
          })
        );
      },
    });
};

export const useAddResponseToSpark = ({ cardId, spark, onCompleted }: IUseAddResponseToSparkArgs) => {
  const posthogCapture = usePostHogCapture();

  const [addAnswerMutation, { loading, data: mutationData }] = useAddCardToSparkMutation({
    update: (cache: ApolloCache<GetSparkDetailCardsQuery>, { data }) => {
      cache.updateQuery(
        {
          query: GetSparkDetailCardsDocument,
          variables: { sparkId: spark.id, sparkCardId: spark.cardId, offset: 0, limit: 12 },
        },
        (query: GetSparkDetailCardsQuery | null): GetSparkDetailCardsQuery => {
          if (
            !(
              query?.allCards?.items?.[0]?.sparkResponses?.items &&
              data?.addCardToSpark?.sparkResponses?.totalCount &&
              data.addCardToSpark.sparkResponses.items?.[0]
            )
          ) {
            return { ...query };
          }

          const { cards: newResponses, totalCount } = addCardToExistingCards(
            query.allCards.items[0].sparkResponses.items,
            data.addCardToSpark.sparkResponses.items?.[0],
            query.allCards.items[0].sparkResponses.totalCount
          );
          return {
            ...query,
            allCards: {
              ...query?.allCards,
              items: [
                {
                  ...query.allCards.items[0],
                  sparkResponses: {
                    ...query.allCards.items[0].sparkResponses,
                    totalCount,
                    items: newResponses,
                  },
                },
              ],
            },
          };
        }
      );
    },
    refetchQueries: [
      {
        query: GetUserSparksDocument,
        variables: {
          id: getAuthUser().userId,
          limit: undefined,
        },
      },
    ],
    onCompleted: () => {
      posthogCapture(POSTHOG_EVENTS.AnswerQuestion);
      posthogCapture(POSTHOG_EVENTS.SparkAction);
    },
  });

  const addResponse = () =>
    addAnswerMutation({
      variables: {
        cardId,
        sparkId: spark.cardId,
      },
      onCompleted,
    });

  return { loading, data: mutationData, addResponse };
};

export const useGetSparkCards = (params?: { limit?: number }): IUseGetUserSparks => {
  const { limit } = params ?? {};

  const { data, fetchMore, loading } = useGetSparkCardsQuery({
    variables: {
      limit,
    },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.allCards ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateAllSparksCardsQueryCache(prev, fetchMoreResult),
    });

  return {
    items,
    loadMore,
    totalCount,
    loading,
  };
};

export const useGetFeaturedSparkCards = (params?: { limit?: number }): IUseGetUserSparks => {
  const { limit } = params ?? {};

  const { data, fetchMore, loading } = useGetFeaturedSparkCardsQuery({
    variables: {
      limit,
    },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.allCards ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateFeaturedSparksCardsQueryCache(prev, fetchMoreResult),
    });

  return {
    items,
    loadMore,
    totalCount,
    loading,
  };
};
