import React, { FC } from 'react';
import { v4 } from 'uuid';

import Grain from 'components/UI/Grain/Grain';
import { useGetUserById, useGetUserByUsername } from 'graphQL/messages/hooks';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { IUseAllPicksCover } from 'graphQL/cards/userCards/models';
import { getImageUrl } from 'helpers/getImages';
import { ImageTypeOptions } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';
import getAuthUser from 'helpers/getAuthUser';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';

import { StyledImageItem, StyledImagesList, StyledTextItem } from 'components/Collection/styled';
import { StyledAllPicksContainer, StyledCoverContainer, StyledSubTitle, StyledTextCard, StyledTitle } from './styled';

interface IAllPicksProps {
  allPicksCoverData: IUseAllPicksCover;
  username?: string;
  allPicksGradient?: string;
}

const AllPicks: FC<IAllPicksProps> = ({ allPicksCoverData, username, allPicksGradient }) => {
  const loggedUser = useGetUserById(getAuthUser().userId, !!username);
  const customUser = useGetUserByUsername(username ?? '', !username);

  const fullname = generateFullName(username ? customUser : loggedUser ?? null);
  const isUserVerified = username ? customUser?.isVerified : loggedUser?.isVerified;
  const { images, titles } = allPicksCoverData ?? {};
  const hasFourItems = images.length >= 4;

  return (
    <StyledAllPicksContainer>
      <StyledTitle>All picks</StyledTitle>
      <StyledSubTitle>
        <span className="creator-text">List by {fullname}</span>
        {isUserVerified && <VerificationBadge />}
      </StyledSubTitle>
      <StyledCoverContainer>
        {!!images.length && (
          <StyledImagesList isMultiple={hasFourItems}>
            {images.slice(0, hasFourItems ? 4 : 1).map((image) => (
              <StyledImageItem
                key={image.id}
                src={getImageUrl({ images: image.images, type: ImageTypeOptions.Preview })}
              />
            ))}
          </StyledImagesList>
        )}
        {!images.length && !!titles.length && (
          <StyledTextCard background={allPicksGradient}>
            <Grain />
            {titles.map((item, i) => (
              <StyledTextItem key={v4()} opacity={1 - (1 / titles.length) * i} color={COLORS.white[100]}>
                {item}
              </StyledTextItem>
            ))}
          </StyledTextCard>
        )}
      </StyledCoverContainer>
    </StyledAllPicksContainer>
  );
};

export default AllPicks;
