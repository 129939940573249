import React, { FC } from 'react';

import { ActivityThoughtObjectType, Scalars } from 'constants/graphqlTypes';
import { getActivityTestId } from 'constants/aqa/activity';
import { ROUTE_CARD_DETAIL } from 'routes';

import PickPreview from './Elements/PickPreview';
import UserPreview from './Elements/UserPreview';
import Content from './Elements/Content';

const Thought: FC<{ date: Scalars['DateTime'] | null; item: ActivityThoughtObjectType; activityTestId: string }> = ({
  date,
  item,
  activityTestId,
}) => {
  const { user, card } = item;
  const { id: pickId, title } = card ?? {};

  const avatarTestId = getActivityTestId(activityTestId, 'avatar');

  return (
    <>
      {user && <UserPreview dataTestId={avatarTestId} user={user} />}
      <Content
        activityTestId={activityTestId}
        url={`${ROUTE_CARD_DETAIL}?thoughtsTab=0`}
        params={{ pickId }}
        date={date}
        user={user}
      >
        added a thought on your pick <b>{title}</b>
      </Content>
      {card && <PickPreview activityTestId={activityTestId} pick={card} />}
    </>
  );
};

export default Thought;
