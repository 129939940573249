import { GetOnboardingPickCategoriesQuery, GetOnboardingPickCategoryPicksQuery } from 'constants/graphqlTypes';

export const reduceItems = <T>(items: (T | undefined)[] | undefined): T[] =>
  items ? items.reduce((prev: T[], curr) => (curr ? prev.concat(curr) : prev), []) : [];

export const getUpdatedPickCategoriesQuery = (
  prevResult: GetOnboardingPickCategoriesQuery,
  newResult: GetOnboardingPickCategoriesQuery
): GetOnboardingPickCategoriesQuery => {
  const reducedPrevItems = reduceItems(prevResult.allTutorialCategories?.categoryItems);
  const reducedNewItems = reduceItems(newResult.allTutorialCategories?.categoryItems);

  if (!reducedPrevItems?.length || !reducedNewItems?.length) {
    return prevResult;
  }
  return {
    ...prevResult,
    allTutorialCategories: {
      ...prevResult.allTutorialCategories,
      categoryItems: [...reducedPrevItems, ...reducedNewItems],
      totalCount: prevResult.allTutorialCategories?.totalCount ?? 0,
    },
  };
};

export const getUpdatedCategoriesCardsQuery = (
  prevResult: GetOnboardingPickCategoryPicksQuery,
  newResult: GetOnboardingPickCategoryPicksQuery
): GetOnboardingPickCategoryPicksQuery => {
  const prevCategoryItem = prevResult.allTutorialCategories?.categoryItems?.[0];

  const newCategoryItemCards = newResult.allTutorialCategories?.categoryItems?.[0].categoryPicks?.items;
  if (
    !prevResult?.allTutorialCategories?.categoryItems.length ||
    !newResult.allTutorialCategories?.categoryItems.length ||
    !prevCategoryItem ||
    !newCategoryItemCards?.length
  ) {
    return prevResult;
  }

  return {
    ...prevResult,
    allTutorialCategories: {
      ...prevResult.allTutorialCategories,
      totalCount: prevResult.allTutorialCategories?.totalCount ?? 0,
      categoryItems: [
        {
          ...prevCategoryItem,
          categoryPicks: {
            ...prevCategoryItem.categoryPicks,
            totalCount: prevCategoryItem.categoryPicks?.totalCount ?? 0,
            items: [...(prevCategoryItem.categoryPicks?.items ?? []), ...newCategoryItemCards],
          },
        },
      ],
    },
  };
};
