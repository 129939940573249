import React, { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ROUTE_MESSAGES_SETTINGS } from 'routes';

import { useMessages, useSingleChat, useUpdateChatViewedAt } from 'graphQL/messages/hooks';
import { MessageThread, MessageInput, SingleChatMember, GroupChatMembers } from 'components/Messages';
import { StyledChatWrap } from 'components/Messages/MessageThread/styled';

import useModal from 'helpers/useModal';
import useTheme from 'helpers/useTheme';
import useOpenModal from 'helpers/useOpenModal';
import useChatKeyboard from 'helpers/useChatKeyboard';
import DotMenuButton from 'components/Navigation/RightMenu/DotMenuButton';
import NavWrap from 'components/Messages/Navigation/NavWrap';
import BlockedAlert from 'components/Messages/BlockedThread/BlockedAlert';

const MessagesChat: FC<{ typingIds: string[] }> = ({ typingIds }) => {
  const { id } = useParams<{ id: string }>();
  const messagesData = useMessages(id);
  const { isKeyboardOpen, setIsKeyboardOpen, setScroll } = useChatKeyboard();

  const openChatSettings = useOpenModal(ROUTE_MESSAGES_SETTINGS, false, { chatId: id });

  const isDark = useTheme();
  const chat = useSingleChat(id);
  const isBlockedGroupChat = chat?.__typename === 'GroupChatSchema' && chat.isBlockedByMe;

  useModal();

  const lastUnreadMessageId = useMemo(
    () => (!chat?.lastMessage?.isViewed ? chat?.lastMessage?.id : undefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chat?.id]
  );

  const updateChatViewedAt = useUpdateChatViewedAt(id);

  useEffect(() => {
    updateChatViewedAt();
  }, []);

  return (
    <>
      <NavWrap>
        {chat && (
          <>
            {chat?.__typename === 'SingleChatSchema' && chat.member && (
              <SingleChatMember chat={chat} isTyping={!!typingIds.length} error={messagesData.error} />
            )}
            {chat?.__typename === 'GroupChatSchema' && !!chat?.members?.items.length && (
              <GroupChatMembers chat={chat} typingIds={typingIds} error={messagesData.error} />
            )}
          </>
        )}
        <DotMenuButton handler={openChatSettings} />
      </NavWrap>
      <StyledChatWrap id="thread-wrap">
        <MessageThread
          messagesData={messagesData}
          isDark={isDark}
          isKeyboardOpen={isKeyboardOpen}
          isGroupChat={chat?.__typename === 'GroupChatSchema'}
          lastUnreadMessageId={lastUnreadMessageId}
          isBlockedGroupChat={isBlockedGroupChat}
        />
        {!isBlockedGroupChat && (
          <MessageInput
            isDark={isDark}
            setIsKeyboardOpen={setIsKeyboardOpen}
            isKeyboardOpen={isKeyboardOpen}
            isGroupChat={chat?.__typename === 'GroupChatSchema'}
            setScroll={setScroll}
          />
        )}
        {chat && <BlockedAlert chat={chat} setScroll={setScroll} />}
      </StyledChatWrap>
    </>
  );
};

export default MessagesChat;
