import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  UserObjectType,
  Maybe,
  UserCardRoleListObjectType,
  CollaborationInviteSchema,
  ReactionListObjectType,
  ECollaborativeInviteStatusOptions,
} from 'constants/graphqlTypes';
import getAuthUser from 'helpers/getAuthUser';
import useTheme from 'helpers/useTheme';
import usePageType from 'helpers/usePageType';

import { useIsUserAllowedToReact } from 'components/Card/helpers/hooks';
import InteractiveReactionMenu from 'components/Reactions/InteractiveReactionMenu';
import Label from 'components/UI/Label';

import ListDescription from './ListDescription';
import ListCollabDescription from './ListCollabDescription';
import ListCollabInvite from './ListCollabInvite';
import ListCollabDecline from './ListCollabDecline';
import { isUserACreator, checkIsBorderHidden, getEmptyListDescription } from './helpers';

import {
  StyledWrap,
  StyledTitle,
  StyledNoContent,
  StyledPrivateWrap,
  StyledContentWrap,
  StyledCollabInviteWrap,
} from './styled';

interface IListContent {
  listCardId?: string;
  description?: string | null;
  isPrivate?: boolean | null;
  title?: string | null;
  totalCount?: number;
  user?: UserObjectType | null;
  isCollaborative?: Maybe<boolean>;
  creators?: Maybe<UserCardRoleListObjectType>;
  invite?: Maybe<CollaborationInviteSchema>;
  reactions?: ReactionListObjectType | null;
  isOnboarding?: boolean;
  isDarkFromProps?: boolean;
}

const ListContent: FC<IListContent> = ({
  listCardId,
  title,
  totalCount,
  user,
  description,
  isPrivate,
  isCollaborative,
  creators,
  invite,
  reactions,
  isOnboarding,
  isDarkFromProps,
}) => {
  const { listId } = useParams<{ listId?: string }>();
  const isDarkFromTheme = useTheme();
  const { isAllPicks } = usePageType();
  const isDark = isDarkFromProps ?? isDarkFromTheme;
  const { userId } = getAuthUser();
  const { id } = user ?? {};
  const isUserAllowedToReact = useIsUserAllowedToReact(user);
  const isMyList = userId === id;
  const isCreator = isUserACreator(creators);
  const [declinedInviteId, setDeclinedInviteId] = useState('');

  const isReactionDisabled = isMyList || !!isCollaborative || !listId || !isUserAllowedToReact;
  const isBorderHidden = checkIsBorderHidden(invite);
  const isCurrentUserList = isCreator || (isMyList && isAllPicks);
  const emptyListDescription = getEmptyListDescription(isCurrentUserList, isOnboarding);

  return (
    <StyledWrap>
      <StyledContentWrap
        hasReactions={!!reactions?.totalCount}
        isPrivate={!!isPrivate}
        hasPicks={!!totalCount && totalCount > 0}
        isCollaborative={!!isCollaborative}
        isMyList={isMyList}
        isOnboarding={!!isOnboarding}
      >
        {title && (
          <InteractiveReactionMenu entityId={listId ?? ''} isReactionDisabled={isReactionDisabled}>
            <StyledTitle isDark={isDark} total={totalCount && totalCount > 0 ? totalCount : 0}>
              {title}
            </StyledTitle>
          </InteractiveReactionMenu>
        )}
        {isCollaborative && listCardId ? (
          <ListCollabDescription
            listCardId={listCardId}
            creators={creators}
            isDark={isDark}
            description={description}
          />
        ) : (
          user && (
            <ListDescription
              listCardId={listCardId}
              user={user}
              isDark={isDark}
              description={description}
              isDisabled={isOnboarding}
            />
          )
        )}
        {isPrivate && (
          <StyledPrivateWrap hasReactions={!!reactions?.totalCount}>
            <Label title="Private" isDark={isDark} />
          </StyledPrivateWrap>
        )}
        {invite && invite.status === ECollaborativeInviteStatusOptions.Invited && (
          <StyledCollabInviteWrap>
            <ListCollabInvite invite={invite} isDark={isDark} setDeclinedInviteId={setDeclinedInviteId} />
          </StyledCollabInviteWrap>
        )}
        {declinedInviteId && (
          <StyledCollabInviteWrap>
            <ListCollabDecline isDark={isDark} inviteId={declinedInviteId} />
          </StyledCollabInviteWrap>
        )}
      </StyledContentWrap>

      {(!totalCount || totalCount < 0) && (
        <StyledNoContent isDark={isDark} hideBorderTop={isBorderHidden}>
          {emptyListDescription}
        </StyledNoContent>
      )}
    </StyledWrap>
  );
};

export default ListContent;
