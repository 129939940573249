import { ECollectionLayoutOptions } from 'constants/graphqlTypes';

import { ReactComponent as DefaultViewIcon } from 'assets/icons/ListDefaultView.svg';
import { ReactComponent as ImagesViewIcon } from 'assets/icons/ListImagesView.svg';
import { ReactComponent as WideCardsViewIcon } from 'assets/icons/Topics.svg';

import { IViewOption } from './models';

export const viewOptions: IViewOption[] = [
  {
    key: 'Default editorial',
    layout: ECollectionLayoutOptions.Default,
    Icon: DefaultViewIcon,
  },
  {
    key: 'Wide cards',
    layout: ECollectionLayoutOptions.Detail,
    Icon: WideCardsViewIcon,
  },
  {
    key: 'Titles only',
    layout: ECollectionLayoutOptions.Title,
    customIconText: 'Aa',
  },
  {
    key: 'Image gallery',
    layout: ECollectionLayoutOptions.ImageGrid,
    Icon: ImagesViewIcon,
  },
];
