import React, { FC } from 'react';
import { RadioButtonWrap, StyledLabel } from './styled';

const RadioButton: FC<{
  text: string;
  isChecked: boolean;
  isDark: boolean;
  onClick: () => void;
  name?: string;
  isDisabled?: boolean;
  dataTestId?: string;
  id?: string;
}> = ({ text, isChecked, isDark, onClick, name, isDisabled, dataTestId, id }) => (
  <RadioButtonWrap data-testid={dataTestId} isDark={isDark}>
    <input id={`${text}${id}`} type="radio" onChange={onClick} checked={isChecked} disabled={isDisabled} />
    <StyledLabel data-tour={name} htmlFor={`${text}${id}`} isDisabled={!!isDisabled}>
      {text}
    </StyledLabel>
  </RadioButtonWrap>
);

export default RadioButton;
