import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { CardSchema, ECardLayoutOptions, HighlightSchema } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';
import { TEST_CARD_FULL_BLEED, getCardImageId, getCardSourceLabelId } from 'constants/aqa/card';

import handleVibrate from 'helpers/handleVibrate';
import getStyles from 'helpers/getStyles';
import getImages from 'helpers/getImages';
import Grain from 'components/UI/Grain';
import Content from '../Common/Content';
import Source from '../Common/Source';
import Highlight from '../Common/Highlight';
import Actions from '../Actions';
import QuestionAttribute from '../QuestionAttribute';

import { StyledImage, StyledLink, StyledWrap, StyledFooter, StyledContent } from './styled';
import SparkAttribute from '../SparkAttribute';
import { useListItemUrl } from '../helpers/hooks';

const FullBleed: FC<{ card: CardSchema; highlight?: HighlightSchema | null; dataTestId?: string }> = ({
  card,
  highlight,
  dataTestId,
}) => {
  const { cardStyle, id, mediaTags, responseToQuestion, responseToSpark } = card;
  const { id: questionId, title: questionTitle, questionGuestList } = responseToQuestion ?? {};
  const { bgColor, layout } = getStyles(cardStyle);
  const { imageUrl } = getImages(cardStyle);
  const { imageUrl: imageGuestUrl } = getImages(questionGuestList?.cardStyle);
  const { push } = useHistory();

  const isVideo = mediaTags?.items[0]?.name === 'video' && layout !== ECardLayoutOptions.Plain;
  const showImage = !!(imageUrl && layout !== ECardLayoutOptions.Plain);
  const url = useListItemUrl(id);
  const isPlainCard = layout === ECardLayoutOptions.Plain;

  const handleFooterClick = (target: HTMLElement) => target.tagName !== 'A' && push(url);

  return (
    <StyledWrap bg={bgColor} isReverse={false}>
      <Grain />
      <StyledLink to={url} onClick={handleVibrate}>
        <StyledContent isReverse={isVideo}>
          <Content
            dataTestId={dataTestId ?? TEST_CARD_FULL_BLEED}
            card={card}
            hugeTitle={isPlainCard}
            bigTitle
            hasPaddings
          />
          {showImage && <StyledImage data-testid={getCardImageId(dataTestId ?? TEST_CARD_FULL_BLEED)} src={imageUrl} />}
        </StyledContent>
      </StyledLink>
      <StyledFooter
        onClick={({ target }) => handleFooterClick(target as HTMLElement)}
        hasImage={showImage}
        color={COLORS.white[100]}
      >
        <Source dataTestId={getCardSourceLabelId(dataTestId ?? TEST_CARD_FULL_BLEED)} card={card} />
      </StyledFooter>
      <Actions item={card} fontColor={COLORS.white[100]} />
      {highlight && <Highlight highlight={highlight} />}
      {questionId && questionTitle && (
        <QuestionAttribute
          isDark
          isFullBleed
          questionId={questionId}
          text={`Response to ${questionGuestList?.title ?? 'Question of the Day'}`}
          title={questionTitle}
          imageUrl={imageGuestUrl}
        />
      )}
      {responseToSpark && <SparkAttribute spark={responseToSpark} isFullBleed isDark />}
    </StyledWrap>
  );
};

export default FullBleed;
