import React, { FC, useEffect } from 'react';
import { SwiperSlide } from 'swiper/react';

import {
  TEST_HOME_CHANNELS_TAB_BTN,
  TEST_HOME_EVERYONE_TAB_BTN,
  TEST_HOME_FEATURED_TAB_BTN,
  TEST_HOME_FOLLOWING_TAB_BTN,
} from 'constants/aqa/main';
import { useFeaturedFlagEnabled } from 'helpers/posthogHooks';
import { EFeaturedFlags } from 'constants/posthogEvents';
import { ESearchParams } from 'constants/common';
import useDiscoveryTab from 'helpers/useDiscoveryTab';
import { useGetSearchParam } from 'helpers/routingHelper';
import withHapticFeedback from 'helpers/withHapticFeedback';
import ScrollContainer from 'components/ScrollContainer';

import RadioButton from './RadioButton';
import { EExploreTabs } from './helpers/constants';

import { StyledWrap } from './styled';

const { Featured, Following, Everyone, Channels } = EExploreTabs;

const ExploreTabs: FC<{ isDark: boolean }> = ({ isDark }) => {
  const { discoveryTab: currentTab, setDiscoveryTab } = useDiscoveryTab();
  const isFollowing = useGetSearchParam(ESearchParams.FollowingTab);
  const isChannelsTab = useGetSearchParam(ESearchParams.ChannelsTab);

  const isShowChannelsTab = useFeaturedFlagEnabled(EFeaturedFlags.ShowChannelsTab);
  const isShowEveryOneTab = useFeaturedFlagEnabled(EFeaturedFlags.ShowEveryoneTab);

  const setTab = (tab: EExploreTabs | string) => withHapticFeedback(() => setDiscoveryTab?.(tab))();

  useEffect(() => {
    if (isFollowing) {
      setDiscoveryTab?.(Following);
    } else if (isChannelsTab) {
      setDiscoveryTab?.(Channels);
    }
  }, []);
  return (
    <StyledWrap>
      <ScrollContainer spaceBetween={8}>
        <SwiperSlide>
          <RadioButton
            isDark={isDark}
            text={Featured}
            isChecked={currentTab === Featured}
            onClick={() => setTab(Featured)}
            dataTestId={TEST_HOME_FEATURED_TAB_BTN}
          />
        </SwiperSlide>
        <SwiperSlide>
          <RadioButton
            isDark={isDark}
            text={Following}
            isChecked={currentTab === Following}
            onClick={() => setTab(Following)}
            dataTestId={TEST_HOME_FOLLOWING_TAB_BTN}
          />
        </SwiperSlide>
        {isShowChannelsTab && (
          <SwiperSlide>
            <RadioButton
              isDark={isDark}
              text={Channels}
              isChecked={currentTab === Channels}
              onClick={() => setDiscoveryTab?.(Channels)}
              dataTestId={TEST_HOME_CHANNELS_TAB_BTN}
            />
          </SwiperSlide>
        )}
        {isShowEveryOneTab && (
          <SwiperSlide>
            <RadioButton
              isDark={isDark}
              text={Everyone}
              isChecked={currentTab === Everyone}
              onClick={() => setTab(Everyone)}
              dataTestId={TEST_HOME_EVERYONE_TAB_BTN}
            />
          </SwiperSlide>
        )}
      </ScrollContainer>
    </StyledWrap>
  );
};

export default ExploreTabs;
