import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from '../../../styles/constants';

export const StyledTitle = styled.span`
  display: block;
  font-size: 22px;
  padding-top: 32px;

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(22px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledOptionalTitle = styled(StyledTitle)`
  display: block;
  padding: 0 0 22px;
`;

export const StyledList = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding-top: 12px;
`;

export const StyledItem = styled.li<{ hideBorder?: boolean }>`
  position: relative;
  border-bottom: ${({ hideBorder }) => (hideBorder ? 'none' : `1px solid ${COLORS.white[30]}`)};

  &:last-child {
    border: none;
  }
`;

export const StyledLinkWrap = styled.span`
  .ui-link {
    color: ${COLORS.white[100]};
    text-decoration: underline;
  }
`;

export const StyledSectionWrap = styled.div`
  padding-top: 16px;
  margin: 0 -12px;

  .section-card {
    padding: 0;
    max-width: 170px;
  }
`;

export const StyledTextWrap = styled.div`
  padding-top: 16px;
  padding-bottom: 8px;
`;

export const StyleCountNote = styled.span`
  font-family: ${FONTS.secondary};
  font-size: 14px;
  opacity: 0.5;
  padding-bottom: 16px;
  text-align: center;
`;

export const StyledButtonWrap = styled.div`
  > button {
    width: 100%;
  }
`;
