import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import validUrl from 'valid-url';
import { ROUTE_USER_PROFILE } from 'routes';
import { v4 } from 'uuid';

import { MessageSchema, MessageType } from 'constants/graphqlTypes';
import { getWebDate } from 'helpers/getDateFormat';
import InteractionBlock from 'components/InteractionBlock';
import getAuthUser from 'helpers/getAuthUser';
import ReactionMenu from 'components/Reactions/ReactionMenu/ReactionMenu';
import ReactionsModal from 'components/Reactions/ReactionsModal';
import UILink from 'components/UI/Link';
import { getUserIdFromMessage } from 'graphQL/messages/helpers';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import { ReactionsProvider } from 'components/Reactions/hooks';
import { AnimationProvider, useAnimationToggle } from 'components/Reactions/Animations/hooks';
import useMessagesThreadContext from 'helpers/useMessagesThreadProvider';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { useMessageChatContext } from 'helpers/useChatProvider';
import ReactionsToken from 'components/Messages/Message/ReactionsToken';
import { useGetChatMessageReactions } from 'graphQL/messages/hooks';
import SharedContent from './Shared';
import ContextMenu from '../ContextMenu';
import { useMessageObserver } from './hooks';
import Scaler from './Scaler';
import {
  MAX_HEIGHT_TO_SCALE,
  MINIMAL_MESSAGE_OFFSET_BOTTOM,
  MENTIONED_USER_EXPRESSION,
  MINIMAL_MESSAGE_OFFSET_TOP,
  REACTION_MENU_OFFSET_HORIZONTAL,
  MINIMAL_SCROLL_DISTANCE_TO_PREVENT_REACTION_MENU,
} from './constants';

import {
  StyledListItem,
  StyledTextMessage,
  StyledMessageWrap,
  StyledName,
  StyledLink,
  StyledContentWrap,
  StyledTokenWrap,
  StyledMessageInner,
  StyledMentionedUser,
  StyledFadeIn,
  StyledDeletedText,
} from './styled';
import { useMessagesChatData } from '../helpers/hooks';

interface IMessage {
  isDark: boolean;
  message: MessageSchema;
  nextMessageType?: MessageType | null;
  isKeyboardOpen: boolean;
  name: string;
  totalCount: number;
  setAllowSwipeLeft: Dispatch<SetStateAction<boolean>>;
  isBlockedGroupChat?: boolean | null;
  setIsMessageUnavailable?: Dispatch<SetStateAction<boolean>>;
}

const Message: FC<IMessage> = ({
  message,
  nextMessageType,
  isDark,
  isKeyboardOpen,
  name,
  totalCount,
  setAllowSwipeLeft,
  isBlockedGroupChat,
  setIsMessageUnavailable,
}) => {
  const [isContentDeleted, setIsContentDeleted] = useState(false);
  const { userId: currentUserId } = getAuthUser();
  const userId = getUserIdFromMessage(message);
  const isMine = userId === currentUserId;

  const { chatId } = useMessagesChatData();
  const { reactions } = useGetChatMessageReactions(chatId, message?.id || '');

  const { message: content, deletedAt, id, createdAt, contentReactionId, mentionedUsers } = message;

  const text = content?.__typename === 'SimpleMessageSchema' ? content.text : null;
  const hasOnlyOneReaction = reactions?.items && reactions?.items.length === 1;
  const isRightToken =
    !!text?.length &&
    ((isMine && text.length > 7) ||
      (!isMine && text.length <= 7 && !hasOnlyOneReaction) ||
      (isMine && text.length <= 7 && !!hasOnlyOneReaction));

  const messageRef = useRef<HTMLDivElement>(null);
  const deleteText = isMine ? 'You deleted this message' : 'This message was deleted';
  const isDeleted = !!deletedAt || content.__typename === 'DeletedMessageSchema';
  const isTextOrDeleteMsg = content.__typename === 'SimpleMessageSchema' || isDeleted;

  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  useEffect(() => {
    setAllowSwipeLeft(!isQuickReactionOpen);
  }, [isQuickReactionOpen]);

  useEffect(() => setIsMessageUnavailable && setIsMessageUnavailable(isContentDeleted), [isContentDeleted]);

  const [messageCoords, setMessageCoords] = useState<DOMRect | null>(null);

  const [scaleFactor, setScaleFactor] = useState<number | undefined>();
  const [newMaxHeight, setNewMaxHeight] = useState<number | undefined>();
  const [oldHeight, setOldHeight] = useState<number | undefined>();
  const [shouldDisableScroll, setShouldDisable] = useState(false);
  const [hasLink, setHasLink] = useState(false);

  const { ref, setDoesBlurExist } = useMessagesThreadContext();

  const handleCloseQuickReactionMenu = (showReactionsModal?: boolean) => {
    if (!showReactionsModal) {
      setIsReactionsModalOpen(false);
    }

    setIsQuickReactionOpen(false);
    setReactionCoordinates(null);
    setMessageCoords(null);
    setScaleFactor(undefined);
    setNewMaxHeight(undefined);
    setShouldDisable(false);

    ref?.current?.scrollBy(0, -1);
    ref?.current?.scrollBy(0, 1);

    setTimeout(() => {
      setOldHeight(undefined);
    }, 300);
  };

  const getMessageRect = () => messageRef.current?.getBoundingClientRect();

  const handleScroll = () => {
    const { top, bottom } = getMessageRect() ?? {};

    const windowH = window.visualViewport?.height ?? window.innerHeight;

    if (!((top && top < MINIMAL_MESSAGE_OFFSET_TOP) || (bottom && windowH - bottom < MINIMAL_MESSAGE_OFFSET_BOTTOM))) {
      return;
    }
    messageRef.current?.scrollIntoView();

    const newRect = getMessageRect();
    if (newRect) {
      ref?.current?.scrollBy({
        left: 0,
        top: newRect.top + newRect.height / 2 - windowH / 2,
      });
    }
  };

  const handleLongTap = useCallback(() => {
    if (isKeyboardOpen) {
      handleCloseQuickReactionMenu();
      return;
    }

    const messageRect = getMessageRect();

    if (messageRect?.height) {
      const height = messageRect?.height;
      const scale = ((window.visualViewport?.height ?? window.innerHeight) - MAX_HEIGHT_TO_SCALE) / height;
      if (scale < 1) {
        setScaleFactor(scale);
        setNewMaxHeight(scale * height);
      } else {
        handleScroll();
        const scrolledRect = getMessageRect();
        setReactionCoordinates(
          scrolledRect
            ? {
                y: scrolledRect.top,
                x: isMine
                  ? scrolledRect.right - REACTION_MENU_OFFSET_HORIZONTAL
                  : scrolledRect.left + REACTION_MENU_OFFSET_HORIZONTAL,
              }
            : null
        );
        setMessageCoords(scrolledRect ?? null);
        setIsQuickReactionOpen(true);
        setShouldDisable(true);
      }
    }
  }, [isKeyboardOpen, isQuickReactionOpen]);

  const handleAfterScale = (rect: DOMRect) => {
    setIsQuickReactionOpen(true);
    setReactionCoordinates(
      rect
        ? {
            y: rect.top,
            x: isMine ? rect.right - REACTION_MENU_OFFSET_HORIZONTAL : rect.left + REACTION_MENU_OFFSET_HORIZONTAL,
          }
        : null
    );
    setMessageCoords(rect ?? null);
    setShouldDisable(true);
  };

  useEffect(() => {
    setOldHeight(getMessageRect()?.height);
  }, []);

  useEffect(() => {
    setDoesBlurExist?.(!!(isQuickReactionOpen && !isKeyboardOpen && messageCoords));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuickReactionOpen && !isKeyboardOpen && messageCoords]);

  useEffect(() => {
    if (text) {
      setHasLink(!!text?.split(' ').some((word) => validUrl.isUri(word)));
    }
  }, [text]);

  const messageWrapperRef = useMessageObserver(message);
  const { isPlaying, toggleAnimation, emoji } = useAnimationToggle();

  const { shouldPlay1stMessageAnimation } = useMessageChatContext();

  const contentIds = {
    chatId,
    messageId: message?.id || '',
  };

  return (
    <StyledListItem
      data-has-reaction={!!message.reactions?.totalCount}
      className={`message ${isMine ? 'message-right' : ''}`}
      isRight={isMine}
      timestamp={format(getWebDate(createdAt), 'HH:mm')}
    >
      <StyledMessageWrap
        className={shouldPlay1stMessageAnimation && totalCount === 1 ? StyledFadeIn : undefined}
        ref={messageWrapperRef}
        isMenuOpen={isQuickReactionOpen}
        isFullWidth={!isTextOrDeleteMsg && !isContentDeleted}
      >
        <AnimationProvider value={{ isPlaying, toggleAnimation, emoji }}>
          <ReactionsProvider value={{ messageId: message.id, isQuickReactionOpen }}>
            <InteractionBlock
              swipeSize={MINIMAL_SCROLL_DISTANCE_TO_PREVENT_REACTION_MENU}
              longTapCallback={() => !isDeleted && !isBlockedGroupChat && handleLongTap()}
            >
              <StyledMessageInner ref={infoRef}>
                {isQuickReactionOpen && !isKeyboardOpen && messageCoords && (
                  <ContextMenu
                    id={id}
                    isMine={isMine}
                    text={text}
                    messageCoords={messageCoords}
                    handleHide={handleCloseQuickReactionMenu}
                    isDark={isDark}
                    shouldDisableScroll={shouldDisableScroll}
                  />
                )}
                <ReactionMenu
                  isShown={isQuickReactionOpen}
                  reactionCoordinates={reactionCoordinates ?? undefined}
                  handleClickOutside={() => {}}
                  minusTop={minusTop}
                  setIsReactionsModalOpen={setIsReactionsModalOpen}
                  setIsQuickReactionOpen={setIsQuickReactionOpen}
                  onClose={() => handleCloseQuickReactionMenu(true)}
                />
                {isReactionsModalOpen && <ReactionsModal id={id} handleClose={handleCloseQuickReactionMenu} />}
                {isTextOrDeleteMsg && (
                  <Scaler
                    messageRef={messageRef}
                    scaleFactor={scaleFactor}
                    newMaxHeight={newMaxHeight ?? oldHeight}
                    isMine={isMine}
                    handleScroll={scaleFactor && scaleFactor < 1 ? handleScroll : undefined}
                    handleAfterScale={handleAfterScale}
                  >
                    <StyledTextMessage
                      hasReaction={!!reactions?.items?.length}
                      ref={messageRef}
                      isDeleted={isDeleted}
                      isDark={isDark}
                      isMine={isMine}
                      hasLink={hasLink}
                    >
                      {name && <StyledName>{name}</StyledName>}
                      {isDeleted ? (
                        <StyledDeletedText isDark={isDark}>{deleteText}</StyledDeletedText>
                      ) : (
                        text?.split(' ').map((word) => {
                          if (validUrl.isUri(word)) {
                            return (
                              <StyledLink key={v4()} target="_blank" href={word} isDark={isDark}>
                                {`${word} `}
                              </StyledLink>
                            );
                          }
                          const userMention = word.match(MENTIONED_USER_EXPRESSION);
                          if (userMention) {
                            const mentionedUser = mentionedUsers?.find(
                              ({ id: mentionedUserId }) => mentionedUserId === userMention[0].slice(9, -1)
                            );
                            const [preMentionText, afterMentionText] = word.split(userMention[0]);
                            return mentionedUser?.id && mentionedUser.username ? (
                              <>
                                {preMentionText}
                                <UILink
                                  key={v4()}
                                  route={ROUTE_USER_PROFILE}
                                  params={{ profileName: mentionedUser.username }}
                                >
                                  <StyledMentionedUser>{`${generateFullName(mentionedUser)} `}</StyledMentionedUser>
                                </UILink>
                                {afterMentionText}
                              </>
                            ) : (
                              <StyledMentionedUser key={v4()}>User not found </StyledMentionedUser>
                            );
                          }
                          return `${word} `;
                        })
                      )}
                      {!isDeleted && (
                        <StyledTokenWrap className="token-wrap" isRight={isRightToken}>
                          <ReactionsToken
                            contentIds={contentIds}
                            isDark={isDark}
                            handleClose={handleCloseQuickReactionMenu}
                            isDisabled={isBlockedGroupChat}
                          />
                        </StyledTokenWrap>
                      )}
                    </StyledTextMessage>
                  </Scaler>
                )}
                {!isDeleted && (
                  <Scaler
                    messageRef={messageRef}
                    scaleFactor={scaleFactor}
                    newMaxHeight={newMaxHeight ?? oldHeight}
                    isMine={isMine}
                    handleAfterScale={handleAfterScale}
                    handleScroll={scaleFactor && scaleFactor < 1 ? handleScroll : undefined}
                  >
                    <StyledContentWrap isMine={isMine} disableLink={isQuickReactionOpen}>
                      <SharedContent
                        setIsContentDeleted={setIsContentDeleted}
                        isDark={isDark}
                        isMine={isMine}
                        messageRef={messageRef}
                        message={message}
                        contentReactionId={contentReactionId || undefined}
                        nextMessageType={nextMessageType}
                      />
                    </StyledContentWrap>
                  </Scaler>
                )}
              </StyledMessageInner>
            </InteractionBlock>
          </ReactionsProvider>
        </AnimationProvider>
      </StyledMessageWrap>
    </StyledListItem>
  );
};

export default Message;
