import { TagSchema, UserObjectType } from 'constants/graphqlTypes';

export const getUniqueItems = (prevArr: UserObjectType[], nextArr: UserObjectType[]): UserObjectType[] => {
  const uniqueObjectsSet = new Set(nextArr.map((obj) => obj.id));
  prevArr.forEach((obj) => {
    uniqueObjectsSet.delete(obj.id);
  });
  const uniqueObjectsArray = Array.from(uniqueObjectsSet, (id) => nextArr.find((obj) => obj.id === id)!);
  return uniqueObjectsArray;
};

export const sortFeedTopics = (feedTopics: TagSchema[]): TagSchema[] =>
  [...feedTopics].sort((a, b) => {
    if (a.name && b.name) {
      return a.name.localeCompare(b.name);
    }
    return 1;
  });
