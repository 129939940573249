import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { QuestionDetailDocument, useAddAnswerToQotdMutation } from 'constants/graphqlTypes';

export const useAddAnswerToQOTD = (questionId: string, cardId: string) => {
  const posthogCapture = usePostHogCapture();

  const [addAnswer, { loading }] = useAddAnswerToQotdMutation({
    variables: {
      questionId,
      cardId,
    },
    refetchQueries: [QuestionDetailDocument],
    onCompleted: () => {
      posthogCapture(POSTHOG_EVENTS.AnswerQuestion);
      posthogCapture(POSTHOG_EVENTS.SparkAction);
    },
    update: (cache, { data }) => {
      const { id, cardStyle, myAnswers } = data?.addCardToQuestion ?? {};
      if (id) {
        cache.modify({
          id: cache.identify({ __typename: 'CardSchema', id }),
          fields: { cardStyle: () => cardStyle, myAnswers: () => myAnswers },
        });
      }
    },
  });

  return {
    addAnswer,
    loading,
  };
};
