import { createContext, useContext, useEffect, useState } from 'react';
import { IUseAnimationToggle } from './models';

export const useAnimationToggle = (duration = 1500): IUseAnimationToggle => {
  const [isPlaying, setIsPlaying] = useState(false);

  const [emoji, setEmoji] = useState<string | null>(null);

  useEffect(() => {
    if (isPlaying) {
      const timer = setTimeout(() => {
        setIsPlaying(false);
        setEmoji(null);
      }, duration);

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [isPlaying]);

  return {
    isPlaying,
    toggleAnimation: (emojiString: string) => {
      setIsPlaying((val) => !val);
      setEmoji(emojiString);
    },
    emoji,
  };
};

const AnimationContext = createContext({});

export const { Provider: AnimationProvider } = AnimationContext;

interface IUseAnimationContext {
  toggleAnimation?: (emoji: string) => void;
  isPlaying?: boolean;
  emoji?: string | null;
}

export const useAnimationContext = (): IUseAnimationContext => {
  const context = useContext<IUseAnimationContext>(AnimationContext);

  return context;
};
