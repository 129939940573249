import React, { FC } from 'react';

import usePageType from 'helpers/usePageType';
import DetailMenu from './DetailMenu';
import MainMenu from './MainMenu';

const BottomMenu: FC = () => {
  const { isItemDetail } = usePageType();

  return isItemDetail ? <DetailMenu /> : <MainMenu />;
};

export default BottomMenu;
