import { styled } from 'linaria/react';

import { COLORS, FONTS } from '../../styles/constants';

export const StyledContainer = styled.div`
  padding: 16px 24px;
`;

export const StyledText = styled.span`
  font: 400 14px/140% ${FONTS.secondary};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledItem = styled.div<{ isDark: boolean }>`
  align-items: center;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: flex;
  font: 400 22px/130% ${FONTS.primary};
  justify-content: space-between;
  margin-top: 32px;

  path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;

export const StyledItemText = styled.span`
  display: inline-block;

  &::first-letter {
    text-transform: uppercase;
  }
`;
