import { styled } from 'linaria/lib/react';
import { BREAKPOINTS, COLORS } from 'styles/constants';

export const StyledList = styled.ul<{ hasPaddingBottom?: boolean }>`
  color: ${COLORS.white[100]};
  flex: 1;
  margin: 0;
  padding: 32px 0 ${({ hasPaddingBottom }) => (hasPaddingBottom ? 280 : 10)}px;
  scrollbar-width: none;
  position: relative;
  overflow: auto;
  list-style: none;
  -ms-overflow-style: none;

  & > li {
    display: block;
    padding-bottom: 32px;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    min-height: 390px;
  }
`;
