import { WEB_PREFIX } from 'constants/common';

export const generateTopicTitle = (topic: string): string => topic.trim().split(' ').join('-').toLowerCase();

export const CHECK_MAIL_VIEW = 'check-mail-view';

export const TEST_ID_CHECK_MAIL_START = `${WEB_PREFIX}${CHECK_MAIL_VIEW}`;
export const TEST_CHECK_MAIL_TITLE = `${TEST_ID_CHECK_MAIL_START}_title-label`;
export const TEST_CHECK_MAIL_SUBTITLE_FIRST = `${TEST_ID_CHECK_MAIL_START}_subtitle-label`;
export const TEST_CHECK_MAIL_SUBTITLE_SECOND = `${TEST_ID_CHECK_MAIL_START}_subtitle-label-second`;
export const TEST_CHECK_MAIL_OPEN_EMAIL_BTN = `${TEST_ID_CHECK_MAIL_START}_open-mail-button`;

export const USER_INFO_VIEW = 'user-info-view';

export const TEST_ID_USER_INFO_VIEW_START = `${WEB_PREFIX}${USER_INFO_VIEW}`;
export const TEST_ID_USER_INFO_VIEW_INVITE_CODE_TEXT_FIELD_CONTAINER = `${TEST_ID_USER_INFO_VIEW_START}_invite-code-view_verification-text-field-container`;
export const TEST_USER_INFO_FULLNAME_TEXT_FIELD = `${TEST_ID_USER_INFO_VIEW_START}_full-name-view_full-name-text-field`;
export const TEST_USER_INFO_USERNAME_TEXT_FIELD = `${TEST_ID_USER_INFO_VIEW_START}_user-name-view_user-name-text-field`;
export const TEST_USER_INFO_BIO_TEXT_FIELD = `${TEST_ID_USER_INFO_VIEW_START}_user-bio-view_user-bio-text-field`;

export const TEST_USER_INFO_TITLE = `${TEST_ID_USER_INFO_VIEW_START}_title`;
export const TEST_USER_INFO_SUBTITLE = `${TEST_ID_USER_INFO_VIEW_START}_subtitle`;
export const TEST_USER_INFO_SUCCESS_TITLE = `${TEST_ID_USER_INFO_VIEW_START}_success-title`;

export const TEST_USER_INFO_START_CONTINUE_BTN = `${TEST_ID_USER_INFO_VIEW_START}_${generateTopicTitle(
  'continue'
)}-button`;
