import { styled } from 'linaria/react';

import { BREAKPOINTS } from 'styles/constants';

export const StyledTitle = styled.span`
  display: block;
  font-size: 22px;
  padding: 0 24px 16px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0 12px 16px;
  }
`;

export const StyledCard = styled.div`
  padding: 0 12px 0;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0;
  }
`;

export const StyledSliderWrap = styled.div<{ hasOneItem: boolean }>`
  .slider-container {
    padding: ${({ hasOneItem }) => (hasOneItem ? '0 12px' : 0)};

    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      padding: 0;
    }
  }

  .slider-info {
    padding: 0 24px 16px;

    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      padding: 0 12px 16px;
    }
  }
`;
