import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledNotice = styled.div<{ isDark: boolean }>`
  border-bottom: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.dark[10])};
  font: 400 14px/140% ${FONTS.secondary};
  padding: 32px 0;
`;

export const StyledWrapper = styled.div<{ isSmall: boolean }>`
  margin: 0 auto 40px;
  max-width: ${({ isSmall }) => (isSmall ? '390px' : '100%')};
  padding: 0 18px;
  width: 100%;
`;
