import { ApolloCache } from '@apollo/client';
import {
  GetCommentsDocument,
  GetPicksDocument,
  GetPicksQuery,
  PickCardMutation,
  GetCommentsQuery,
} from 'constants/graphqlTypes';
import { addToCards, removeFromItems } from 'graphQL/cardOptions/helpers';
import { IRefItem, IRefItems } from 'graphQL/models';
import getAuthUser from 'helpers/getAuthUser';

export const updatePickedCards = (cache: ApolloCache<any>, userId: string, isAdd: boolean, itemId?: string): void => {
  cache.modify({
    id: cache.identify({ id: userId, __typename: 'UserObjectType' }),
    fields: {
      pickedCards: (prev: IRefItems) => ({
        ...prev,
        totalCount: prev.totalCount + (isAdd ? 1 : -1),
        ...(itemId && isAdd && { items: addToCards(prev.items, itemId) }),
      }),
    },
  });
};

export const addToComments = (comments: IRefItem[], comment?: string): IRefItem[] => [
  { __ref: `CardCommentSchema:${comment}` },
  ...comments,
];

export const addToPicks = (picks: IRefItem[], pick?: string): IRefItem[] => [
  { __ref: `CardPickSchema:${pick}` },
  ...picks,
];

export const removeFromPicks = (
  picks: IRefItem[],
  cache: ApolloCache<GetPicksQuery>,
  cardId: string,
  userId: string
): IRefItem[] => {
  const pickCache: GetPicksQuery | null = cache.readQuery({
    query: GetPicksDocument,
    variables: { id: cardId, selectedUsers: undefined, offset: 0, limit: 10, sorting: 'DESC' },
  });

  if (pickCache) {
    const myPick = pickCache?.allCards?.items?.[0].picks?.items.filter((item) => item.user?.id === userId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return removeFromItems(picks, myPick?.[0]?.id);
  }

  return picks;
};

export const removeFromComments = (
  comments: IRefItem[],
  cache: ApolloCache<GetCommentsQuery>,
  cardId: string,
  userId: string
): IRefItem[] => {
  const commentCache: GetCommentsQuery | null = cache.readQuery({
    query: GetCommentsDocument,
    variables: { id: cardId, selectedUsers: undefined, offset: 0, limit: 10, sorting: 'DESC' },
  });

  if (commentCache) {
    const myComment = commentCache?.allCards?.items?.[0].comments?.items.filter((item) => item.user?.id === userId);
    return removeFromItems(comments, myComment?.[0]?.id);
  }

  return comments;
};

interface IUpdatePickedCard {
  cache: ApolloCache<any>;
  pickId: string;
  isPick: boolean;
  data?: PickCardMutation | null;
}

export const updatePickedCard = ({ cache, pickId, isPick, data }: IUpdatePickedCard): void => {
  const { userId } = getAuthUser();
  const hasComment = !!data?.pickCard?.comments?.items.length;

  cache.modify({
    id: cache.identify({ id: pickId, __typename: 'CardSchema' }),
    fields: {
      isPickedByMe: () => isPick,
      comments: (prev: IRefItems) =>
        hasComment
          ? {
              ...prev,
              totalCount: prev.totalCount + (isPick ? 1 : -1),
              items: isPick
                ? addToComments(prev.items, data?.pickCard?.comments?.items?.[0]?.id)
                : removeFromComments(prev.items, cache, pickId, userId),
            }
          : prev,
      picks: (prev: IRefItems) => ({
        ...prev,
        totalCount: prev.totalCount + (isPick ? 1 : -1),
        items: isPick
          ? addToPicks(prev.items, data?.pickCard?.picks?.items?.[0]?.id)
          : removeFromPicks(prev.items, cache, pickId, userId),
      }),
    },
  });
};
