import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import Truncate from 'react-truncate-markup';

import { DiscoverSectionSchema } from 'constants/graphqlTypes';
import { ROUTE_SPARKS } from 'routes';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { COLORS } from 'styles/constants';
import useTheme from 'helpers/useTheme';
import Card from 'components/Sparks/Views/Card';
import SliderInteractive from 'components/SliderInteractive';

import { StyledTitle } from 'components/List/Content/styled';
import { StyledDescription } from 'components/List/styled';
import { StyledContainer } from 'components/Sliders/styled';
import { StyledSeeMore } from './styles';

interface ISparkSectionProps {
  section: DiscoverSectionSchema;
}

const SparkSection: FC<ISparkSectionProps> = ({ section }) => {
  const posthogCapture = usePostHogCapture();
  const { push } = useHistory();

  const isDark = useTheme();

  const { title, annotation } = section;
  const cards = section?.cardItems?.map(({ card }) => card) ?? [];

  const [slideIndex, setSlideInd] = useState(0);
  const lastCardIndex = cards?.length && cards?.length;

  const handlerAll = () => {
    posthogCapture(POSTHOG_EVENTS.FeaturedOpenAllQuestionPage);
    posthogCapture(POSTHOG_EVENTS.SparkAction);
    push(ROUTE_SPARKS);
  };

  return (
    <>
      <Truncate lines={2}>
        <StyledTitle isExplore isDark={isDark}>
          {title}
        </StyledTitle>
      </Truncate>
      <StyledDescription isDark={isDark}>{annotation}</StyledDescription>
      {!!cards.length && (
        <StyledContainer>
          <SliderInteractive
            customSlidesPerView={1.2}
            onSlideChange={(ind) => setSlideInd(ind)}
            spaceBetween={13}
            fontColor={isDark ? COLORS.white[100] : COLORS.brown.dark[100]}
            showBtnsSm
            offset={12}
            isInfinite={false}
            isPrevDisabled={slideIndex === 0}
            isNextDisabled={slideIndex === lastCardIndex}
          >
            {cards?.map((card) => (
              <SwiperSlide key={card.id}>
                <Card isDark={isDark} card={card} />
              </SwiperSlide>
            ))}
            <SwiperSlide>
              <StyledSeeMore isDark={isDark} onClick={handlerAll}>
                See all sparks
              </StyledSeeMore>
            </SwiperSlide>
          </SliderInteractive>
        </StyledContainer>
      )}
    </>
  );
};

export default SparkSection;
