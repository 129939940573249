import { useLink, transformRoute } from 'helpers/routingHelper';
import {
  ROUTE_LIST_EDIT,
  ROUTE_LIST_EDIT_COLLABORATORS,
  ROUTE_LIST_EDIT_DESCRIPTION,
  ROUTE_LIST_EDIT_NAME,
  ROUTE_LIST_EDIT_ORDER,
  ROUTE_LIST_EDIT_PRIVACY,
} from 'routes';

export const useListSettingsLinks = (listId: string) => ({
  editListInfo: useLink(transformRoute(ROUTE_LIST_EDIT, { listId })),
  changeName: useLink(transformRoute(ROUTE_LIST_EDIT_NAME, { listId })),
  changeDescription: useLink(transformRoute(ROUTE_LIST_EDIT_DESCRIPTION, { listId })),
  changePrivacy: useLink(transformRoute(ROUTE_LIST_EDIT_PRIVACY, { listId })),
  changeCollaborators: useLink(transformRoute(ROUTE_LIST_EDIT_COLLABORATORS, { listId })),
  changeOrder: useLink(transformRoute(ROUTE_LIST_EDIT_ORDER, { listId })),
});
