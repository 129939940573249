import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_DELETE_PICKS, ROUTE_REASSIGN_ADMINS } from 'routes';

import useTheme from 'helpers/useTheme';
import { useCollaborativeListsCount } from 'graphQL/profile/hooks';
import Layout from 'components/Onboarding/Layout';

import { StyledInfo } from '../styled';

const BeforeYouGo: FC = () => {
  const isDark = useTheme();
  const { totalCount, loading } = useCollaborativeListsCount(true);
  const { state } = useLocation<{ pagesToMenu: number }>();
  const { pagesToMenu } = state;
  const { push, go } = useHistory();

  return (
    <Layout
      heading="Before you go"
      text="We're sorry to see you go. We need to do a little housekeeping before you delete your account."
      primaryBtnText="Continue"
      secondaryBtnText="Cancel"
      isSecondaryBtnTransparent
      isWideContent
      isPrimaryBtnDisabled={loading}
      handlePrimaryBtn={() =>
        push({
          pathname: totalCount ? ROUTE_REASSIGN_ADMINS : ROUTE_DELETE_PICKS,
          state: { pagesToMenu: pagesToMenu - 1 },
        })
      }
      handleSecondaryBtn={() => go(pagesToMenu)}
    >
      {!loading && (
        <StyledInfo isDark={isDark}>
          <span>Let&apos;s help you:</span>
          {!!totalCount && <p>Assign new admins to lists</p>}
          <p>Keep or delete picks in lists you collaborated on</p>
        </StyledInfo>
      )}
    </Layout>
  );
};

export default BeforeYouGo;
