import React, { FC } from 'react';

import ConfirmationModal from 'components/UI/ConfirmationModal';

import { useBlockUser } from 'graphQL/blocking/hooks';

interface IConfirmBlockProps {
  userId: string;
  handleCancel: () => void;
}

const ConfirmationBlock: FC<IConfirmBlockProps> = ({ userId, handleCancel }) => {
  const { blockUser } = useBlockUser(userId);

  const handleAccept = () => {
    blockUser();
    handleCancel();
  };

  return (
    <ConfirmationModal
      buttonText="block"
      cancelButtonText="don't block"
      handleAccept={handleAccept}
      handleCancel={handleCancel}
      subTitle={`Thank you for sending your report. Would you like to also block this user? They will no longer be able to message or follow you. If they attempt to follow or message you they will be notified they are blocked. You can still see each other's profiles and picks.`}
      titleText="block user?"
    />
  );
};

export default ConfirmationBlock;
