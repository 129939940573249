import { useEffect, useState } from 'react';
import { MutationFunction } from '@apollo/client';
import { usePostHog } from 'posthog-js/react';
import { ROUTE_EXIT_FORM } from 'routes';

import getAuthUser from 'helpers/getAuthUser';
import useContextPusher from 'helpers/useContextPusher';

import {
  useGetUserProfileQuery,
  useGetTotalCountCardsQuery,
  UserObjectType,
  CardFilter,
  useGetCollaborationListsQuery,
  useGetCollaborativeListsCountQuery,
  useGetAdminCollaborativeListsQuery,
  useDeleteUserMutation,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  CollectionUserType,
  useGetUserQuery,
  useGetUserIdQuery,
} from 'constants/graphqlTypes';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { EMPTY_ITEMS, EQueryFetchPolicy } from 'constants/common';
import { updateUserCards } from 'graphQL/cards/userCards/helpers';
import { ICollaborativeListItem, IUseGetCollaborationLists } from 'graphQL/profile/models';
import { EMessagePrefixes, sendMessageToExtension } from 'helpers/browserExtension';
import config from '../../constants/config';

interface IUseProfile {
  userData?: UserObjectType;
  isInfoError: boolean;
  loading: boolean;
  updateInfoError: (isError: boolean) => void;
  isQueryCalled: boolean;
}

export const useGetUserId = (usernameProp: string): { userId: string | undefined; loading: boolean } => {
  const { data, loading } = useGetUserIdQuery({
    variables: { username: usernameProp },
    skip: !usernameProp,
  });

  return { userId: data?.allUsers?.items[0]?.id, loading };
};

export const useProfile = (
  userId?: string,
  fetchPolicy?: EQueryFetchPolicy,
  nextFetchPolicy?: EQueryFetchPolicy,
  skip?: boolean
): IUseProfile => {
  const [isQueryCalled, setIsQueryCalled] = useState(false);
  const [isLocalUserInfoError, setIsLocalUserInfoError] = useState<boolean>(false);
  const { userId: authedUserId } = getAuthUser();
  const { data: userProfile, loading } = useGetUserProfileQuery({
    variables: { id: userId || authedUserId },
    fetchPolicy,
    nextFetchPolicy,
    skip: skip ?? (!userId && !authedUserId),
    onCompleted: () => setIsQueryCalled(true),
  });

  return {
    userData: userProfile?.allUsers?.items[0],
    isInfoError: isLocalUserInfoError,
    loading,
    updateInfoError: (isError: boolean) => setIsLocalUserInfoError(isError),
    isQueryCalled,
  };
};

export const useGetUser = (id?: string): { user?: UserObjectType; loading: boolean } => {
  const { data, loading } = useGetUserQuery({
    variables: { id: id ?? '' },
    skip: !id,
  });

  return { user: data?.allUsers?.items[0], loading };
};

export const useGetTotalCountCards = (
  userId: string,
  filter?: CardFilter,
  fetchPolicy?: EQueryFetchPolicy
): { totalCount?: number; loading: boolean } => {
  const { data, loading } = useGetTotalCountCardsQuery({
    variables: { id: userId, filter },
    fetchPolicy,
    skip: !userId,
  });

  return {
    totalCount: data?.allUsers?.items?.[0]?.pickedCards?.totalCount,
    loading,
  };
};

export const useGetCollaborationLists = (userId: string): IUseGetCollaborationLists => {
  const { data, loading, fetchMore } = useGetCollaborationListsQuery({
    variables: { userId, offset: 0 },
  });

  const { items, totalCount } = data?.allUsers?.items[0]?.pickedCards ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateUserCards(prev, fetchMoreResult),
    });

  return { lists: items, loading, loadMore, totalCount };
};

export const useCollaborativeListsCount = (isAdminLists?: boolean): { totalCount: number; loading: boolean } => {
  const { userId } = getAuthUser();
  const { data, loading } = useGetCollaborativeListsCountQuery({
    variables: { userId, isAdminLists },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  return { totalCount: data?.allUsers?.items[0].pickedCards?.totalCount ?? 0, loading };
};

export const useSignOut = (route: string): (() => void) => {
  const posthog = usePostHog();
  const { stopPusher } = useContextPusher();

  if (config.postHogKey) {
    posthog?.reset();
  }

  return () => {
    stopPusher();
    sendMessageToExtension(EMessagePrefixes.LOGOUT, 'logout');
    localStorage.clear();
    window.location.href = route;
  };
};

export const useAdminCollaborativeLists = (): {
  lists: ICollaborativeListItem[];
  loading: boolean;
} => {
  const { userId } = getAuthUser();
  const { data, loading } = useGetAdminCollaborativeListsQuery({ variables: { userId } });

  const lists: ICollaborativeListItem[] =
    data?.allUsers?.items[0].pickedCards?.items.map(({ id, cardsFromCollection, creators, title }) => ({
      id,
      title: title ?? '',
      cardsTotalCount: cardsFromCollection?.totalCount ?? 0,
      newAdmin: creators?.items[0].user,
    })) ?? [];

  return { lists, loading };
};

export const useDeleteMyProfile = (
  newAdminsList?: CollectionUserType[],
  shouldDeletePicks?: boolean
): {
  deleteMyProfile: MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;
  loading: boolean;
} => {
  const posthogCapture = usePostHogCapture();
  const [isLoading, setIsLoading] = useState(false);
  const signOut = useSignOut(ROUTE_EXIT_FORM);

  const [deleteMyProfile, { loading }] = useDeleteUserMutation({
    variables: {
      shouldDeletePicks,
      ...(newAdminsList?.length && { newAdminsList }),
    },
    onCompleted: () => {
      posthogCapture(POSTHOG_EVENTS.DeleteAccount);
      signOut();
    },
    onError: () => setIsLoading(false),
  });

  useEffect(() => {
    if (loading) {
      setIsLoading(loading);
    }
  }, [loading]);

  return { deleteMyProfile, loading: isLoading };
};
