import useTheme from 'helpers/useTheme';
import React, { FC } from 'react';
import { CardSchema, CardTypeOptions } from 'constants/graphqlTypes';

import { StyledTitle } from 'components/List/Content/styled';
import { StyledDescription, StyledTitle as StyledUsersTitle } from './styled';

const ListTitle: FC<{ type: CardTypeOptions; totalCount?: number; list: CardSchema }> = ({
  type,
  totalCount,
  list,
}) => {
  const isDark = useTheme();
  const { title, description } = list;

  return type === CardTypeOptions.SuggestedUsers ? (
    <StyledUsersTitle isDark={isDark}>{title}</StyledUsersTitle>
  ) : (
    <>
      <StyledTitle isExplore isDark={isDark} total={totalCount ?? 0}>
        {title}
      </StyledTitle>
      <StyledDescription isDark={isDark}>{description}</StyledDescription>
    </>
  );
};

export default ListTitle;
