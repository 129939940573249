import React, { FC, useEffect } from 'react';
import { useLottie } from 'lottie-react';
import { useLastLocation } from 'react-router-last-location';

import config from 'constants/config';
import AnimatedBackground from 'components/AnimatedBackground';
import getAuthUser from 'helpers/getAuthUser';

import { INVITE_FRIENDS_REDIRECT_STORAGE_KEY } from 'constants/common';
import SharingList from './SharingList';
import { StyledTitle } from '../styled';
import { StyledContentWrap, StyledSharingListWrap, StyledText, StyledWrap } from './styled';

const InviteUserSharing: FC<{ animationData: unknown }> = ({ animationData }) => {
  const { View } = useLottie({
    animationData,
    loop: true,
    className: 'animated-bg',
  });
  const { pathname } = useLastLocation() ?? {};
  const { username } = getAuthUser();
  const { joinUsBaseUrl } = config;
  const joinAppUrl = joinUsBaseUrl.concat(username);

  useEffect(() => {
    if (pathname) {
      sessionStorage.setItem(
        INVITE_FRIENDS_REDIRECT_STORAGE_KEY,
        pathname.includes('/@') ? pathname.concat('?withModalBg=profile') : pathname
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatedBackground>
      <StyledWrap>
        {View}
        <StyledContentWrap>
          <StyledTitle>Invite friends</StyledTitle>
          <StyledText>
            guide:human is about meaningful connections. Invite people who you want to share your ideas with.
          </StyledText>
        </StyledContentWrap>
        <StyledSharingListWrap>
          <SharingList url={joinAppUrl} />
        </StyledSharingListWrap>
      </StyledWrap>
    </AnimatedBackground>
  );
};

export default InviteUserSharing;
