import React, { FC } from 'react';
import { StyledSectionName } from './styled';

const ChatSection: FC<{ name: string }> = ({ name, children }) => {
  return (
    <section>
      <StyledSectionName>{name}</StyledSectionName>
      {children}
    </section>
  );
};

export default ChatSection;
