import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from 'components/Onboarding/Layout';
import Grain from 'components/UI/Grain';
import useTheme from 'helpers/useTheme';

import { ROUTE_ONBOARDING_PROFILE } from 'routes';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { usePostHogCapture } from 'helpers/posthogHooks';
import {
  useGetPreferredTopicsQuery,
  useUpdateUserPreferredTagsMutation,
  GetProfileTopicsDocument,
} from 'constants/graphqlTypes';
import { useGetProfileTopics } from 'graphQL/profile/topics/hooks';
import { EQueryFetchPolicy, WEB_PREFIX } from 'constants/common';
import { TEST_ID_USER_INFO_VIEW_START, generateTopicTitle } from 'constants/aqa/signup';

import { updateTopics } from './helpers/helpers';
import { COLOR_SWATCHES } from './helpers/constants';

import { StyledTopic, StyledWrap } from './styled';

const LAYOUT_HEADER_GAP = 8;

const Topics: FC = () => {
  const posthogCapture = usePostHogCapture();

  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const { push } = useHistory();
  const [updatePreferredTags] = useUpdateUserPreferredTagsMutation({
    variables: { tagIds: selectedTopics },
    refetchQueries: [GetProfileTopicsDocument],
    onCompleted: () => posthogCapture(POSTHOG_EVENTS.OnboardingProfileParametersSave, { screen: 'topics' }),
  });

  const isDark = useTheme();
  const { topics: profileTopics } = useGetProfileTopics(EQueryFetchPolicy.NetworkOnly);
  const { data: topicsData } = useGetPreferredTopicsQuery();
  const { items } = topicsData?.preferredTags ?? {};

  const colors = useMemo(
    () => Array.from(items ?? [], (_val, index) => COLOR_SWATCHES[index % COLOR_SWATCHES.length]),
    [items]
  );

  const handleButton = () => updatePreferredTags().then(() => push(ROUTE_ONBOARDING_PROFILE));
  const isButtonDisabled = selectedTopics.length < 3;

  useEffect(() => {
    if (profileTopics.length) {
      setSelectedTopics(profileTopics.map((profileTopic) => profileTopic.id));
    }
  }, [profileTopics]);

  return (
    <Layout
      heading="Add your interests"
      text="Pick at least 3 topics. You can change these later."
      primaryBtnText={isButtonDisabled ? `${selectedTopics.length} / 3` : 'Continue'}
      isPrimaryBtnDisabled={isButtonDisabled}
      handlePrimaryBtn={handleButton}
      blockScroll={false}
      pageTestId={TEST_ID_USER_INFO_VIEW_START}
      customHeaderGap={LAYOUT_HEADER_GAP}
      maxWidth={528}
    >
      <StyledWrap>
        {(items ?? []).map(({ id, name }, index) => (
          <li key={id}>
            <StyledTopic
              data-testid={`${WEB_PREFIX}${generateTopicTitle(name ?? '')}`}
              isDark={isDark}
              key={id}
              bgColor={colors[index]}
              isSelected={selectedTopics.includes(id)}
              onClick={() => setSelectedTopics((prev) => updateTopics(prev, id))}
            >
              {selectedTopics.includes(id) && <Grain />}
              <span>{name}</span>
            </StyledTopic>
          </li>
        ))}
      </StyledWrap>
    </Layout>
  );
};

export default Topics;
