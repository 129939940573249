import { WEB_PREFIX } from 'constants/common';

const HELP_VIEW = `${WEB_PREFIX}help-view`;

export const TEST_ID_HELP_VIEW_TITLE_LABEL = `${HELP_VIEW}_title-label`;
export const TEST_ID_HELP_VIEW_HOW_TO_HEADER_TITLE_LABEL = `${HELP_VIEW}_how-to-header_title-label`;

export const TEST_ID_HELP_VIEW_OPTION_ADD_PICK = `${HELP_VIEW}_option-add-pick`;
export const TEST_ID_HELP_VIEW_OPTION_ADD_THOUGHT = `${HELP_VIEW}_option-add-thought`;
export const TEST_ID_HELP_VIEW_OPTION_CREATE_LIST = `${HELP_VIEW}_option-create-list`;
export const TEST_ID_HELP_VIEW_OPTION_MAKE_CONNECTION = `${HELP_VIEW}_option-make-connection`;
export const TEST_ID_HELP_VIEW_ITEM_ONBOARDING_TOUR_TAKE_THE_TOUR_BUTTON = `${HELP_VIEW}_item-onboarding-tour_take-the-tour-button`;

export const TEST_ID_HELP_VIEW_OPTION_PRIVACY = `${HELP_VIEW}_option-privacy`;
export const TEST_ID_HELP_VIEW_BLOCKED_ACCOUNTS = `${HELP_VIEW}_option-blocked-accounts`;
export const TEST_ID_HELP_VIEW_TERMS_AND_CONDITIONS = `${HELP_VIEW}_option-terms-and-conditions`;

export const TEST_ID_HELP_VIEW_OPTION_VERSION_TITLE_LABEL = `${HELP_VIEW}_option-version_title-label`;
