export interface IToggleProps {
  checked: boolean;
  isDark?: boolean;
  isDisabled?: boolean;
  handler?: () => void;
  dataTestId?: string;
}

export enum EToggleType {
  Default = 'Toggle',
  CheckPlus = 'CheckPlus',
}
