import React, { FC } from 'react';

import { ReactComponent as Logo } from 'assets/icons/Logo.svg';

import StyledAvatarGH from './styled';
import { IAvatarGHProps } from './models';

const AvatarGH: FC<IAvatarGHProps> = ({ dimension = 24, isDark, isRounded }) => (
  <StyledAvatarGH className="avatarGH" dimension={dimension} isRounded={isRounded} isDark={isDark}>
    <Logo viewBox="0 0 622 383" />
  </StyledAvatarGH>
);

export default AvatarGH;
