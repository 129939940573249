import { styled } from 'linaria/react';

import { COLORS, FONTS } from '../../../styles/constants';

export const StyledEmojiWrap = styled.div<{ isLeft?: boolean }>`
  align-items: center;
  background-color: ${COLORS.white.off[100]};
  border-radius: 14px;
  display: flex;
  justify-content: center;
  left: ${({ isLeft }) => (isLeft ? '22px' : 'unset')};
  padding: 6px;
  position: absolute;
  right: ${({ isLeft }) => (isLeft ? 'unset' : '-8px')};
  top: 26px;
`;

export const StyledEmoji = styled.span`
  font: 400 22px/130% ${FONTS.primary};
`;
