import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useCheckUsernameAvailable } from 'graphQL/auth/hooks';

import { TEST_USER_INFO_TITLE, TEST_USER_INFO_USERNAME_TEXT_FIELD } from 'constants/aqa/signup';
import { AuthSchema, useUpdateUserMutation } from 'constants/graphqlTypes';
import { ROUTE_ONBOARDING_PROFILE } from 'routes';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import { usePostHogCapture } from 'helpers/posthogHooks';
import getAuthUser from 'helpers/getAuthUser';
import useTheme from 'helpers/useTheme';

import { USERNAME_LENGTH } from 'components/Authentication/constants';
import Layout from 'components/Onboarding/Layout';
import TextInput from 'components/UI/TextInput';
import { validateUsername } from 'components/Authentication/helpers';
import ContentTitle from 'components/UI/ContentTitle';
import useDebouncedValue from 'helpers/useDebounced';

import { StyledInputWrapper } from './styled';

export const changeLocalStorageUsername = (newUsername: string): void => {
  const localDataJson = localStorage.getItem('userData');
  const { authToken }: { authToken: AuthSchema } = localDataJson ? JSON.parse(localDataJson) : {};
  const newData: AuthSchema = { ...authToken, userAuth: { ...authToken.userAuth, username: newUsername } };
  localStorage.setItem('userData', JSON.stringify({ authToken: newData }));
};

const reservedError = (newUsername: string) => (
  <span>
    This username is reserved. To claim it, please contact us at{' '}
    <a href={`mailto:hello@guidehuman.co?subject=Username reservation: ${newUsername}`}>hello@guidehuman.co</a>
  </span>
);

const Username: FC = () => {
  const { push } = useHistory();
  const posthogCapture = usePostHogCapture();
  const { username: storageUsername } = getAuthUser();
  const isDark = useTheme();

  const [username, setUsernameText] = useState(storageUsername);
  const [usernameError, setUsernameError] = useState<string | ReactNode>('');

  const hasChanges = storageUsername !== username;

  const [availabilityHint, setAvailabilityHint] = useState<[string] | undefined>(undefined);
  const debouncedUsername = useDebouncedValue(username, 500);

  const [updateUserMutation] = useUpdateUserMutation({
    onCompleted: ({ updateUser }) => {
      if (updateUser?.username) {
        changeLocalStorageUsername(updateUser?.username);
      }
      push(ROUTE_ONBOARDING_PROFILE);
    },
    onError: ({ message }) => {
      setUsernameError(message);
      setAvailabilityHint(undefined);
    },
  });

  const skipCheckUsernameAvailable = !hasChanges || storageUsername === debouncedUsername || !debouncedUsername;
  const { loading, errorCode } = useCheckUsernameAvailable(debouncedUsername, skipCheckUsernameAvailable);

  const handleDone = () => {
    if (!usernameError && !loading) {
      updateUserMutation({
        variables: {
          username,
        },
      });
    }
  };

  useEffect(() => {
    if (errorCode) {
      switch (errorCode) {
        case 1004:
          setUsernameError(reservedError(username));
          return;
        case 1005:
          setUsernameError(validateUsername(username));
          return;
        default:
          setUsernameError('Username is unavailable');
      }
    } else {
      setUsernameError(hasChanges ? validateUsername(username) : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, username]);

  useEffect(() => {
    if (usernameError) {
      setAvailabilityHint(undefined);
    } else {
      setAvailabilityHint(loading ? ['Checking availability...'] : ['Username is available']);
    }
  }, [loading, usernameError]);

  useEffect(() => {
    return () => {
      if (storageUsername === username) {
        posthogCapture(POSTHOG_EVENTS.OnboardingProfileParameters, {
          screen: 'username',
        });
      }
    };
  }, []);

  const isSaveBtnDisabled = !hasChanges || loading || usernameError !== '';
  return (
    <Layout isPrimaryBtnDisabled={isSaveBtnDisabled} primaryBtnText="Save" handlePrimaryBtn={handleDone} maxWidth={528}>
      <ContentTitle dataTestId={TEST_USER_INFO_TITLE} isDark={isDark}>
        Change username
      </ContentTitle>
      <StyledInputWrapper isDark={isDark}>
        <TextInput
          dataTestId={TEST_USER_INFO_USERNAME_TEXT_FIELD}
          hints={availabilityHint}
          maxLength={USERNAME_LENGTH[1] + 1}
          isDark={isDark}
          placeholder="username"
          text={username}
          setText={(value: string) => setUsernameText(value.toLocaleLowerCase())}
          error={usernameError}
        />
      </StyledInputWrapper>
    </Layout>
  );
};

export default Username;
