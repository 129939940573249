import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { UserCardRoleListObjectType } from 'constants/graphqlTypes';
import { format, parseISO, isSameYear, isSameMonth } from 'date-fns';

export const formatDateRange = (createdAt: string, updatedAt: string): string => {
  const dateCreated = parseISO(createdAt);
  const dateUpdated = parseISO(updatedAt);
  const formatCreated = format(dateCreated, 'MMM ‘yy');
  const formatUpdated = format(dateUpdated, 'MMM ‘yy');

  if (isSameMonth(dateCreated, dateUpdated)) {
    return formatCreated;
  }

  if (isSameYear(dateCreated, dateUpdated)) {
    return `${formatCreated.split(' ')[0]} — ${formatUpdated}`;
  }
  return `${formatCreated} — ${formatUpdated}`;
};

export const getCreatorsString = (creators?: UserCardRoleListObjectType | null): string => {
  if (!creators) {
    return '';
  }
  const mainUser = `by ${generateFullName(creators.items[0]?.user)}`;
  if (creators.totalCount < 2) {
    return mainUser;
  }
  const additionalCreators =
    creators.totalCount > 2 ? `${creators.totalCount - 1} others` : generateFullName(creators.items[1]?.user);
  return `${mainUser} & ${additionalCreators}`;
};
