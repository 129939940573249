import { ECardLayoutOptions, CardStyleSchema } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';

interface IStyles {
  layout: ECardLayoutOptions;
  bgColor: string;
  gradientStart?: string | null;
  gradientEnd?: string | null;
  isDefaultStyle?: boolean | null;
}

const getStyles = (style?: CardStyleSchema | null): IStyles => ({
  layout: style?.layout ?? ECardLayoutOptions.Plain,
  bgColor:
    style?.bgColor?.colorValue ?? `linear-gradient(180deg, ${COLORS.brown.light[100]} 0%, ${COLORS.brown.mid} 100%)`,
  gradientStart: style?.bgColor?.gradientStart,
  gradientEnd: style?.bgColor?.gradientEnd,
  isDefaultStyle: style?.isDefaultStyle,
});

export default getStyles;
