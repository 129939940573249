import React, { FC } from 'react';

import Grain from 'components/UI/Grain/Grain';
import { IStyleItem } from '../helpers/models';

import { StyledButton } from './styled';

interface IStyleButtonProps {
  item: IStyleItem;
  isCurrent: boolean;
  handleSelect: () => void;
  withGrain?: boolean;
  customImageUrl?: string;
}

const StyleButton: FC<IStyleButtonProps> = ({ item, isCurrent, withGrain, customImageUrl, handleSelect }) => {
  const { image, colors } = item;
  const url = image?.images?.[0]?.url;

  return (
    <StyledButton
      isCurrent={isCurrent}
      bgColor={colors?.[0]?.colorValue}
      image={url}
      disabled={url === '#'}
      onClick={handleSelect}
      customImageUrl={customImageUrl ? `url(${customImageUrl})` : undefined}
    >
      {withGrain && <Grain />}
    </StyledButton>
  );
};

export default StyleButton;
