import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import { MessageType } from 'constants/graphqlTypes';
import {
  TEST_ID_PROFILE_OPTIONS_VIEW_CROSS_BUTTON,
  TEST_ID_PROFILE_OPTIONS_VIEW_EDIT_PROFILE_BUTTON,
  TEST_ID_PROFILE_OPTIONS_VIEW_SHARE_BUTTON,
  TEST_ID_PROFILE_OPTIONS_VIEW_SHARE_BUTTON_TITLE_LABEL,
  TEST_ID_PROFILE_OPTIONS_VIEW_SEND_ON_GH_BUTTON,
  TEST_ID_PROFILE_OPTIONS_VIEW_SEND_ON_GH_BUTTON_TITLE_LABEL,
} from 'constants/aqa/profile';
import getAuthUser from 'helpers/getAuthUser';

import Modal from 'components/Modal';
import Button from 'components/UI/MenuButton';
import ShareButtons, { IShareButtonsDataTestIds } from 'components/Navigation/RightMenu/ShareButtons';

import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { ROUTE_PROFILE_SETTINGS } from 'routes';
import { StyledItem } from 'components/AddContent/Menu/styled';
import { StyledList } from './styled';
import { useProfileSettingsLinks } from '../hooks';

const SHARE_BTNS_DATA_TEST_IDS: IShareButtonsDataTestIds = {
  sendBtnIds: {
    dataTestIdButton: TEST_ID_PROFILE_OPTIONS_VIEW_SEND_ON_GH_BUTTON,
    dataTestIdButtonLabel: TEST_ID_PROFILE_OPTIONS_VIEW_SEND_ON_GH_BUTTON_TITLE_LABEL,
  },
  shareBtnIds: {
    dataTestIdButton: TEST_ID_PROFILE_OPTIONS_VIEW_SHARE_BUTTON,
    dataTestIdButtonLabel: TEST_ID_PROFILE_OPTIONS_VIEW_SHARE_BUTTON_TITLE_LABEL,
  },
};

const Menu: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const { replace } = useHistory();
  const { editProfile } = useProfileSettingsLinks();
  const { pathname } = useLastLocation() ?? {};
  const { userId } = getAuthUser();

  return (
    <Modal
      fadeIn={!pathname?.includes(ROUTE_PROFILE_SETTINGS)}
      handleClose={handleClose}
      dataTestIdCloseButton={TEST_ID_PROFILE_OPTIONS_VIEW_CROSS_BUTTON}
    >
      <StyledList>
        <StyledItem>
          <Button
            text="Edit profile"
            Icon={UserIcon}
            handler={() => replace(editProfile)}
            dataTestId={TEST_ID_PROFILE_OPTIONS_VIEW_EDIT_PROFILE_BUTTON}
          />
        </StyledItem>
        <ShareButtons
          closePreviousModal={() => {}}
          messageType={MessageType.User}
          sharedId={userId}
          dataTestIds={SHARE_BTNS_DATA_TEST_IDS}
        />
      </StyledList>
    </Modal>
  );
};

export default Menu;
