import { WEB_PREFIX } from 'constants/common';

export const PICK_DETAILS_VIEW = 'pick-details-view';

export const TEST_ID_PICK_DETAILS_START = `${WEB_PREFIX}${PICK_DETAILS_VIEW}`;
export const TEST_PICK_DETAILS_TYPE_SUBJECT_LABEL = `${TEST_ID_PICK_DETAILS_START}_type-and-subject-label`;
export const TEST_PICK_DETAILS_TITLE_LABEL = `${TEST_ID_PICK_DETAILS_START}_title-label`;
export const TEST_PICK_DETAILS_SOURCE_LABEL = `${TEST_ID_PICK_DETAILS_START}_source-label`;
export const TEST_PICK_DETAILS_COMMENT_VIEW = `${TEST_ID_PICK_DETAILS_START}_comment-view`;
export const TEST_PICK_DETAILS_THOUGHTS_BTN = `${TEST_ID_PICK_DETAILS_START}_thoughts-button`;
export const TEST_PICK_DETAILS_URL_BTN = `${TEST_ID_PICK_DETAILS_START}_url-button`;

export const TEST_ACTION_THOUGHT = `${TEST_ID_PICK_DETAILS_START}_action-thought`;
export const TEST_ACTION_USERNAME = `${TEST_ID_PICK_DETAILS_START}_action-username`;
