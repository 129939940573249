import React, { FC, useMemo, useEffect, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { createPortal } from 'react-dom';

import { CardSchema, ECardLayoutOptions } from 'constants/graphqlTypes';
import { BREAKPOINTS, COLORS } from 'styles/constants';

import getStyles from 'helpers/getStyles';
import getImages from 'helpers/getImages';
import getAuthUser from 'helpers/getAuthUser';
import { ReactionsProvider, useReactionContextState } from 'components/Reactions/hooks';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import useTheme from 'helpers/useTheme';
import useReferrerId from 'helpers/useReferrerId';

import Source from 'components/Card/Common/Source';
import Actions from 'components/Card/Actions';
import InteractionBlock from 'components/InteractionBlock';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import ReactionsModal from 'components/Reactions/ReactionsModal';
import HiddenWordsAlert from 'components/HiddenWordsAlert';

import { useIsUserAllowedToReact, useItemUrl } from 'components/Card/helpers/hooks';
import { StyledTweet } from 'components/Card/Common/styled';
import withHapticFeedback from 'helpers/withHapticFeedback';

import { StyledWrap } from 'components/Collection/styled';
import { getBrandedIcon } from 'components/Card/helpers/helpers';
import { StyledBio } from 'components/UI/Highlight/styled';
import { isVideoCard } from 'helpers/isVideoCard';
import {
  StyledContainer,
  StyledWrapper,
  StyledImage,
  StyledInfo,
  StyledTitle,
  StyledTag,
  StyledImageWrap,
  StyledSpotLightFooter,
  StyledInner,
} from './styled';

interface ISpotlightCardProps {
  card: CardSchema;
  fontColor: string;
  isAnimationStarted?: boolean;
  dataTestId?: string;
  isHidden: boolean;
  setHiddenCards: Dispatch<SetStateAction<string[]>>;
  setShouldPauseAutoplay?: Dispatch<SetStateAction<boolean>>;
}

const SpotlightCard: FC<ISpotlightCardProps> = ({
  card,
  fontColor,
  isAnimationStarted,
  dataTestId,
  isHidden,
  setHiddenCards,
  setShouldPauseAutoplay,
}) => {
  const { push } = useHistory();
  const isDark = useTheme();
  const { cardStyle, mediaTags, id, sourceName, externalCreators, title } = card;
  const isUserAllowedToReact = useIsUserAllowedToReact(card?.extraData?.items?.[0]?.user);

  const { layout } = getStyles(cardStyle);
  const { imageUrl } = getImages(cardStyle);

  const isPlainCard = layout === ECardLayoutOptions.Plain;

  const borderColor = fontColor === COLORS.white[100] ? COLORS.white[30] : COLORS.brown.dark[30];
  const url = useItemUrl(id);
  const { userId } = getAuthUser();

  const { creatorUsername, creatorName } = externalCreators?.items?.[0] ?? {};
  const editedCreatorName = sourceName === 'mastodon' ? creatorName?.split('&')[0] : creatorName;

  const Icon = getBrandedIcon(sourceName);
  const isVideo = isVideoCard(card);
  const isTweet = mediaTags?.items[0]?.name === 'tweets';
  const isTiktokVideo = isVideo && sourceName === 'tiktok';

  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();
  const {
    setThoughtId,
    thoughtId,
    setPickerId,
    pickerId,
    cardId,
    setCardId,
    user,
    setUser,
  } = useReactionContextState();

  useEffect(() => {
    setShouldPauseAutoplay?.(isQuickReactionOpen);
  }, [isQuickReactionOpen]);

  const referrerId = useReferrerId();
  const isCurrentUserPick = useMemo(() => pickerId === userId, [pickerId]);
  const disableLongTap = isCurrentUserPick || !isUserAllowedToReact;
  const isCardReaction =
    card?.extraData?.items?.[0]?.__typename !== 'CardCommentSchema' ||
    (card?.extraData?.items?.[0]?.__typename === 'CardCommentSchema' && card?.extraData?.items?.[0]?.isSuspicious);
  const portalContainer = document.getElementById('spotlight-container');

  const handleShowHiddenContent = () => {
    setHiddenCards?.((prev) => prev.filter((hiddenId) => hiddenId !== id));
  };

  useEffect(() => {
    if (!isCardReaction) {
      setThoughtId(card?.extraData?.items?.[0].id ?? null);
      setUser(card?.extraData?.items?.[0]?.user ?? null);
    }

    setCardId(card.id);

    if ((card?.extraData?.items?.length || 0) < 2) {
      setPickerId(card?.pickedByUsers?.items?.[0]?.id ?? referrerId ?? null);
    }
  }, []);

  useEffect(() => {
    if (isAnimationStarted && isQuickReactionOpen) {
      setIsQuickReactionOpen(false);
    }
  }, [isAnimationStarted, isQuickReactionOpen]);

  const currentTitle =
    title && title.length > 180 ? title.slice(0, title.lastIndexOf(' ', 180)).concat(' [...]') : title;

  return !isHidden ? (
    <ReactionsProvider
      value={{
        isQuickReactionOpen,
        setThoughtId,
        thoughtId,
        setPickerId,
        pickerId,
        cardId,
        setCardId,
        user,
        setUser,
      }}
    >
      <StyledWrap ref={infoRef} disableLinks={isQuickReactionOpen}>
        <div className={window.innerWidth > BREAKPOINTS.m && !isCurrentUserPick ? 'no-swiping' : ''}>
          {reactionCoordinates &&
            reactionCoordinates.x &&
            reactionCoordinates.y &&
            !isCurrentUserPick &&
            portalContainer &&
            createPortal(
              <ReactionMenu
                isShown={isQuickReactionOpen}
                reactionCoordinates={reactionCoordinates}
                handleClickOutside={() => setIsQuickReactionOpen(false)}
                minusTop={minusTop}
                setIsReactionsModalOpen={setIsReactionsModalOpen}
                setIsQuickReactionOpen={setIsQuickReactionOpen}
              />,
              portalContainer
            )}
          <InteractionBlock
            tapCallback={() => setIsQuickReactionOpen(false)}
            longTapCallback={() => !disableLongTap && setIsQuickReactionOpen(true)}
            getTapCoordinates={(x, y) => setReactionCoordinates({ x, y: y + 90 })}
          >
            <StyledWrapper isPlainCard={isPlainCard}>
              <StyledContainer
                onClick={withHapticFeedback(() => push(url))}
                borderColor={borderColor}
                isPlainCard={isPlainCard}
              >
                <StyledInner>
                  {!isPlainCard && (
                    <StyledImageWrap
                      isBorderless={layout === ECardLayoutOptions.Borderless}
                      isTiktokVideo={isTiktokVideo}
                    >
                      <StyledImage src={imageUrl} />
                    </StyledImageWrap>
                  )}
                  <StyledInfo isPlainCard={isPlainCard}>
                    <div>
                      <StyledTag data-testid={`${dataTestId}_type-label`}>
                        {isTweet && !isVideo && 'post'}
                        {!isTweet && mediaTags?.items[0]?.name}
                      </StyledTag>
                      {!!Icon && (
                        <StyledTweet>
                          <Icon viewBox="0 0 24 24" height="13" width="13" />
                          {editedCreatorName}
                          {creatorUsername && <StyledBio>@{creatorUsername.replace('@', '')}</StyledBio>}
                        </StyledTweet>
                      )}
                    </div>
                    <StyledTitle data-testid={`${dataTestId}_title-label`} isPlainCard={isPlainCard}>
                      {currentTitle}
                    </StyledTitle>
                  </StyledInfo>
                </StyledInner>
                <StyledSpotLightFooter color={fontColor} isPlainCard={isPlainCard}>
                  <Source stopPropagationOnClick dataTestId={`${dataTestId}_source-label`} card={card} />
                </StyledSpotLightFooter>
              </StyledContainer>
              <StyledSpotLightFooter color={fontColor} isPlainCard={isPlainCard}>
                <Actions item={card} fontColor={fontColor} isSmall isSpotlight />
              </StyledSpotLightFooter>
            </StyledWrapper>
          </InteractionBlock>
          {isReactionsModalOpen && <ReactionsModal id={card?.id} handleClose={() => setIsReactionsModalOpen(false)} />}
        </div>
      </StyledWrap>
    </ReactionsProvider>
  ) : (
    <HiddenWordsAlert showHiddenContent={handleShowHiddenContent} isDark={isDark} isSmall={!layout} hasBottomMargin />
  );
};

export default SpotlightCard;
