import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

export const StyledWrap = styled.button<{ isToolTipShown: boolean; isDark: boolean }>`
  align-items: center;
  background: ${({ isToolTipShown, isDark }) => {
    if (isToolTipShown) {
      return isDark ? COLORS.white[100] : COLORS.white.off[100];
    }
    return isDark ? COLORS.white[10] : COLORS.white[100];
  }};
  border-radius: 50%;
  border: none;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
  cursor: pointer;
  border: ${({ isDark }) => (isDark ? 'none' : `1px ${COLORS.white.off[100]} solid`)};

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    circle {
      fill: ${({ isToolTipShown, isDark }) => {
        if (isToolTipShown) {
          return COLORS.brown.dark[100];
        }

        return isDark ? COLORS.white[100] : COLORS.brown.dark[100];
      }};
    }
    path {
      stroke: ${({ isToolTipShown, isDark }) => {
        if (isToolTipShown) {
          return COLORS.brown.dark[100];
        }

        return isDark ? COLORS.white[100] : COLORS.brown.dark[100];
      }};
    }
  }
`;

export const StyledRelWrap = styled.div`
  position: relative;
  z-index: 10;
  border-radius: 50%;
`;
