import React, { FC } from 'react';

import { ROUTE_USER_PROFILE } from 'routes';
import { ActivityFollowObjectType, Scalars } from 'constants/graphqlTypes';
import { getActivityTestId } from 'constants/aqa/activity';

import useTheme from 'helpers/useTheme';
import FollowButton from 'components/FollowButton';
import UserPreview from './Elements/UserPreview';
import Content from './Elements/Content';

import { StyledButtonWrapper } from './Elements/styled';

const Follow: FC<{ date: Scalars['DateTime'] | null; item: ActivityFollowObjectType; activityTestId: string }> = ({
  date,
  item,
  activityTestId,
}) => {
  const { user } = item;
  const { isFollowedByMe, isBlockedByMe, username: profileName } = user ?? {};
  const isDark = useTheme();

  const followBtnTestId = getActivityTestId(activityTestId, 'follow-button');
  const avatarTestId = getActivityTestId(activityTestId, 'avatar');

  return (
    <>
      {user && <UserPreview dataTestId={avatarTestId} user={user} />}
      <Content
        activityTestId={activityTestId}
        url={ROUTE_USER_PROFILE}
        params={{ profileName }}
        date={date}
        user={user}
      >
        started following you
      </Content>
      {user && !isFollowedByMe && !isBlockedByMe && (
        <StyledButtonWrapper>
          <FollowButton dataTestId={followBtnTestId} isDark={isDark} user={user} />
        </StyledButtonWrapper>
      )}
    </>
  );
};

export default Follow;
