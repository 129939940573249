import React, { FC } from 'react';

import AvatarDouble from 'components/UI/AvatarDouble';
import Avatar from 'components/UI/Avatar';

import { GroupChatSchema } from 'constants/graphqlTypes';
import { getThreadLastMessageText, getUsername } from 'components/Messages/helpers/helpers';
import { useGroupChatTitle } from 'components/Messages/helpers/hooks';

import { useGetUserById } from 'graphQL/messages/hooks';
import ContentItem from './ContentItem';

interface IGroupTheadItemProps {
  item: GroupChatSchema;
  isDark: boolean;
  isSearchActive?: boolean;
}

const GroupTheadItem: FC<IGroupTheadItemProps> = ({ item, isDark, isSearchActive }) => {
  const { id, lastMessage, lastActionAt, newChatViewedAt, title: customTitle, lastReaction, isBlockedByMe } = item;
  const { isViewed, sender, message } = lastMessage ?? {};
  const initiator = message && 'initiator' in message ? message.initiator : null;
  const actionType = message && 'actionType' in message ? message.actionType : null;
  const attributes = message && 'attributes' in message ? message.attributes : '{}';
  const addedUsers: string[] = Object.values(JSON.parse(attributes));
  const firstUser = useGetUserById(addedUsers[0]);
  const secondUser = useGetUserById(addedUsers[1]);

  const member = sender?.__typename === 'UserShortSchema' ? sender : null;
  const { username, firstName } = member ?? {};
  const { title, users, moreNumber } = useGroupChatTitle(item);
  const { isViewed: isLastReactionViewed } = lastReaction ?? {};

  const hasUnreaded = (!!lastMessage && !isViewed) || !newChatViewedAt || (!!lastReaction && !isLastReactionViewed);

  return users ? (
    <ContentItem
      chatId={id}
      isDark={isDark}
      hasUnreaded={hasUnreaded}
      title={title}
      text={getThreadLastMessageText({
        lastMessage,
        username,
        firstName,
        lastReaction,
        initiator,
        actionType,
        addedUsers,
        firstUser,
        secondUser,
      })}
      moreNumber={moreNumber}
      lastActionAt={lastReaction && lastActionAt < lastReaction.createdAt ? lastReaction.createdAt : lastActionAt}
      username={getUsername(moreNumber, users, isSearchActive)}
      firstUsers={users}
      hasCustomTitle={!!customTitle}
      isBlockedByMe={isBlockedByMe}
    >
      {users.length > 1 ? <AvatarDouble users={[users[1], users[0]]} /> : <Avatar user={users[0]} />}
    </ContentItem>
  ) : null;
};

export default GroupTheadItem;
