import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledSection = styled.section`
  padding-top: 16px;

  > div {
    padding: 0;
  }
  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding-top: 32px;
  }
`;

export const StyledUsersSection = styled(StyledSection)`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
  overflow: hidden;

  .userlist-item {
    padding: 8px 0;
  }

  .badge-content {
    padding-left: 12px;
  }

  > div {
    padding-bottom: 8px;
  }
`;

export const StyledList = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  scrollbar-width: none;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 16px 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledListItem = styled.li<{ isDark?: boolean; isRed?: boolean }>`
  align-items: center;
  background-color: ${({ isDark, isRed }) => {
    if (isRed) {
      return COLORS.red[100];
    }

    return isDark ? COLORS.white[10] : COLORS.white.off[100];
  }};
  border-radius: 22px;
  color: ${({ isDark, isRed }) => (isDark || isRed ? COLORS.white[100] : COLORS.brown.dark[100])};
  cursor: pointer;
  display: flex;
  gap: 4px;
  height: 44px;
  font-family: ${FONTS.secondary};
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  padding: 0 20px;
  user-select: none;
  max-width: 100%;

  p {
    white-space: nowrap;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledButton = styled.button`
  align-items: center;
  background-color: ${COLORS.transparent};
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 24px;
  margin-right: -12px;
  padding: 0;
  width: 24px;

  path {
    fill: white;
  }
`;

export const StyledInput = styled.input<{ isDark: boolean; textLength: number }>`
  background-color: ${COLORS.transparent};
  border: none;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font-family: ${FONTS.secondary};
  font-weight: 500;
  font-size: 16px;
  height: 44px;
  line-height: 140%;
  outline: none;
  width: ${({ textLength }) => `${textLength + 2}ch`};
`;
