import { ECardRoleTypeOptions } from 'constants/graphqlTypes';

export const ROLE_OPTIONS = [
  {
    name: 'Admin',
    value: ECardRoleTypeOptions.Owner,
    description: 'Can add/remove editors, picks and thoughts.',
  },
  {
    name: 'Collaborator',
    value: ECardRoleTypeOptions.Collaborator,
    description: 'Can add/remove picks and thoughts.',
  },
  {
    name: 'Viewer',
    value: ECardRoleTypeOptions.Viewer,
    description: 'Can see the list and thoughts and picks in it',
  },
];
