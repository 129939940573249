import { useEffect } from 'react';

import useThoughtsModal from 'helpers/useThoughtsModal';

import { ESearchParams } from 'constants/common';
import { useGetSearchParam } from 'helpers/routingHelper';
import { getReactionType } from './helpers';

export const useOpenThoughtSearchParams = (id: string): void => {
  const thoughtsTab = useGetSearchParam(ESearchParams.ThoughtsTab);
  const reaction = useGetSearchParam(ESearchParams.Reaction);
  const thoughtId = useGetSearchParam(ESearchParams.ThoughtId);
  const reactionType = getReactionType(reaction);

  const openThoughts = useThoughtsModal({
    id,
    currentTab: Number(thoughtsTab),
    thoughtId,
    reaction: reactionType,
    shouldClearSearchParams: true,
    openFromCardDetailPage: true,
  });

  useEffect(() => {
    if (thoughtsTab) {
      openThoughts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
