import React, { useEffect, useState } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import DndList from 'components/ListSettings/EditOrder/DndCardList';
import Modal from 'components/Modal';
import SmallCard from 'components/List/SmallCard';
import LoaderContent from 'components/UI/LoaderContent';
import useTheme from 'helpers/useTheme';
import { CardSchema } from 'constants/graphqlTypes';
import { useGetDetailList, useGetListItems } from 'graphQL/list/hooks';
import { EQueryFetchPolicy } from 'constants/common';
import { useReorderList } from 'graphQL/list/update/hooks';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_LIST_EDIT } from 'routes';

import { useGetListCoverIndexes, useUpdateCachedListThumbnails } from './hooks';

import { StyledListPreviewContainer, StyledListPreviewWrap } from './styled';

const EditOrder = () => {
  const { replace } = useHistory();

  const { listId } = useParams<{ listId: string }>();

  const { state } = useLocation<{ variable: { listCardId: string; isPrivateList: boolean } }>();
  const { listCardId } = state?.variable ?? {};

  const handleClose = () =>
    replace({
      pathname: transformRoute(ROUTE_LIST_EDIT, { listId }),
      state,
    });

  const { card: list, loading } = useGetDetailList({
    id: listId,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    coverLimit: 4,
  });

  const isDark = useTheme();

  const [orderItems, setOrderItems] = useState<CardSchema[]>([]);

  const { items, loadMore, loading: loadingItems } = useGetListItems({
    id: listId,
    onLoadMore: (newItems) => setOrderItems((prev) => [...prev, ...newItems]),
    fetchPolicy: EQueryFetchPolicy.NetworkOnly,
  });

  useEffect(() => {
    return () => setOrderItems([]);
  }, []);

  useEffect(() => {
    if (items.length && !orderItems.length) {
      setOrderItems(items);
    }
  }, [items]);

  const isTextListCover = !!list?.cardStyle?.collectionCover?.items?.find(
    (item) => item.__typename === 'CardTitleObjectType'
  );

  const { coverBgIndexes, coverImgIndexes, coverTextIndexes } = useGetListCoverIndexes(items, isTextListCover, list);

  const updateListCover = useUpdateCachedListThumbnails();

  const onDragEnd = (newItems: CardSchema[]) =>
    updateListCover({
      listId,
      coverImgIndexes,
      coverTextIndexes,
      coverBgIndexes,
      orderItems: newItems,
      isTextListCover,
    });

  const handleReorderMutation = useReorderList(listCardId);

  const handleDone = () => {
    handleReorderMutation(orderItems.map(({ cardId }) => cardId));
    handleClose();
  };

  return (
    <Modal
      fadeIn={false}
      handlePrev={handleClose}
      handleClose={handleClose}
      handleDone={handleDone}
      buttonText="Done"
      customCloseText="Cancel"
    >
      {!(loading && loadingItems) ? (
        <>
          <StyledListPreviewWrap>
            <StyledListPreviewContainer>
              {list && !loading && <SmallCard item={list} disableLink isReplace hideFooter />}
            </StyledListPreviewContainer>
          </StyledListPreviewWrap>
          <DndList
            items={orderItems}
            setItems={setOrderItems}
            loading={loadingItems || loading}
            loadMore={loadMore}
            onDragEnd={onDragEnd}
          />
        </>
      ) : (
        <LoaderContent isDark={isDark} isFullScreen />
      )}
    </Modal>
  );
};

export default EditOrder;
