import { Dispatch, SetStateAction } from 'react';

import { ESelectionMode } from 'components/CustomFeeds/constants';

const { All, Select, Deselect } = ESelectionMode;

export const addOrRemoveObjectFromState = <T extends { id: string }>(
  setter: Dispatch<SetStateAction<T[]>>,
  object: T
) => {
  setter((objectsFromState) =>
    objectsFromState.find(({ id }) => id === object.id)
      ? objectsFromState.filter(({ id }) => id !== object.id)
      : [...objectsFromState, object]
  );
};

export const removeObjectFromArray = <T extends { id: string }>(
  setter: Dispatch<SetStateAction<T[]>>,
  object: T
): void => {
  setter((objectsFromState) => objectsFromState.filter(({ id }) => id !== object.id));
};

export const checkObjectInArray = <T extends { id: string }>(items: T[], item: T): T | undefined =>
  items.find(({ id }) => id === item.id);

export const getIdsFromObjects = <T extends { id: string }>(items: T[]): string[] => items.map(({ id }) => id);

// checkArrayElements checks if all of the origin array's elements do exist in array to check
export const checkArrayElements = <T extends { id: string }>(arrayToCheck: T[], originArray: T[]): boolean =>
  !!originArray.length && originArray.every(({ id }) => arrayToCheck.find((object) => object.id === id));

export const checkIsUserEnabled = ({
  id,
  selectionMode,
  selectedUsers,
  deselectedUsers,
  isAllUsers,
  isFollowedByMe,
  isSearch,
  isAllFollowings,
  haveAllUsersNoFollowings,
}: {
  id: string;
  selectionMode: ESelectionMode;
  selectedUsers: string[];
  deselectedUsers: string[];
  haveAllUsersNoFollowings: boolean;
  isFollowedByMe?: boolean | null;
  isSearch?: boolean;
  isAllUsers?: boolean;
  isAllFollowings?: boolean;
}) => {
  const hasUserInSelected = selectedUsers.includes(id);
  const hasUserInDeselected = deselectedUsers.includes(id);

  if (selectionMode === All) {
    return true;
  }

  if (isSearch && haveAllUsersNoFollowings && !hasUserInSelected && !hasUserInDeselected) {
    return !isFollowedByMe;
  }

  if (isSearch && haveAllUsersNoFollowings && hasUserInSelected) {
    return true;
  }

  if (isSearch && haveAllUsersNoFollowings && hasUserInDeselected) {
    return false;
  }

  if (!isSearch && haveAllUsersNoFollowings && !hasUserInSelected) {
    return false;
  }

  if (isSearch && !isFollowedByMe && isAllFollowings && !isAllUsers) {
    return selectedUsers.includes(id);
  }

  if (isSearch && isFollowedByMe && isAllFollowings) {
    return !deselectedUsers.includes(id);
  }

  if (selectionMode === Select && !isAllUsers) {
    return selectedUsers.includes(id);
  }

  if (isAllFollowings && !hasUserInDeselected) {
    return true;
  }

  if (hasUserInDeselected) {
    return false;
  }

  if (hasUserInSelected) {
    return true;
  }

  if (selectionMode === Deselect && !hasUserInDeselected) {
    return true;
  }

  return false;
};
