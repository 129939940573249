export const getIndex = (index: number, count: number): number => {
  if (index === count - 2) {
    return 0;
  }

  if (index === count - 1) {
    return 1;
  }

  return index + 2;
};
