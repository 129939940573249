import { styled } from 'linaria/react';
import { BREAKPOINTS } from 'styles/constants';
import { StyledLabel as ImportLabel } from 'components/AddContent/CreateList/styled';
import { StyledTitle as Title } from 'components/AddContent/Menu/styled';

export const StyledSubTitle = styled(Title)`
  display: block;
  padding-bottom: 16px;
`;

export const StyledUserLabel = styled(ImportLabel)<{ disabled?: boolean }>`
  border-top: none;
  margin: 0;
  padding: 8px 0;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    padding: calc(32px * ${BREAKPOINTS.retinaScale}) 0;
  }

  > div:last-child {
    margin-left: auto;
  }
`;

export const StyledSectionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;
