import React, { FC } from 'react';

import useTheme from 'helpers/useTheme';
import UserPreview from 'components/ActivityItem/Elements/UserPreview';
import Toggle from 'components/UI/Toggle';
import { StyledText } from 'components/UI/ListItem/styled';

import { UserObjectType } from 'constants/graphqlTypes';
import { useUpdateSpecificUserStatus } from 'graphQL/profile/notifications/hooks';
import { generateName } from 'components/Profile/ProfileTop/helpers';

import { StyledUserLabel } from './styled';

interface ISpecificUserProps {
  user: UserObjectType;
  isNotificationsEnable: boolean;
}
const SpecificUser: FC<ISpecificUserProps> = ({ user, isNotificationsEnable }) => {
  const isDark = useTheme();
  const { userInfo, username, id } = user;
  const { firstName } = userInfo ?? {};
  const { updateSpecificUserNotificationsStatus, loading } = useUpdateSpecificUserStatus();

  return (
    <StyledUserLabel disabled={loading}>
      <UserPreview user={user} />
      <StyledText isDark={isDark}>{generateName(username, firstName)}</StyledText>
      <Toggle
        isDark={isDark}
        checked={isNotificationsEnable}
        handler={() => updateSpecificUserNotificationsStatus({ userId: id, isEnable: !isNotificationsEnable })}
      />
    </StyledUserLabel>
  );
};

export default SpecificUser;
