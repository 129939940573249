import { Dispatch, SetStateAction } from 'react';
import { ESelectionMode } from 'components/CustomFeeds/constants';

import { IHandleTopicClickArgs, IIsTopicCheckedArgs } from './models';

const { All, Select, Deselect } = ESelectionMode;

export const handleTopicClick = ({
  topic,
  selectedTopics,
  setSelectedTopics,
  deselectedTopics,
  setDeselectedTopics,
  setTotalSelectedTopics,
  setIsAllTopics,
  setSelectionMode,
  allExistingTopicsCount,
  setClickedTopicIds,
}: IHandleTopicClickArgs): void => {
  const { id, isSelectedInFeed } = topic;
  setClickedTopicIds(topic);

  // if topic was manually selected previously
  // and now is being clicked again(deselected)
  const wasManuallySelectedInFeed = selectedTopics.includes(id);
  if (wasManuallySelectedInFeed) {
    setSelectedTopics((prev) => prev.filter((val) => val !== id));
    setTotalSelectedTopics((prev) => {
      setSelectionMode((prevMode) => (prevMode === All || (prevMode === Deselect && prev !== 1) ? Deselect : Select));
      return prev - 1;
    });
    // we remove selection from isAllTopics when we are deselecting topic(topic was selected manually)
    setIsAllTopics(null);
    return;
  }

  // if topic was manually deselected previously
  // and now is being clicked again(selected)
  const wasManuallyDeselectedInFeed = deselectedTopics.includes(id);
  if (wasManuallyDeselectedInFeed) {
    setDeselectedTopics((prev) => prev.filter((val) => val !== id));
    setTotalSelectedTopics((prev) => {
      const newTotalSelectedTopics = prev + 1;
      if (newTotalSelectedTopics === allExistingTopicsCount) {
        // we set selectionMode to All when we select all deselected topics manually
        setIsAllTopics(true);
        setSelectionMode(All);
      }
      return newTotalSelectedTopics;
    });
    return;
  }

  // if topic was NOT manually selected/deselected previously
  // and is originally selected from BE
  // and now is being clicked for the first time
  const wasOriginallySelectedInFeed = !wasManuallySelectedInFeed && !wasManuallyDeselectedInFeed && isSelectedInFeed;
  if (wasOriginallySelectedInFeed) {
    setDeselectedTopics((prev) => [...prev, id]);
    setTotalSelectedTopics((prev) => {
      setSelectionMode((prevMode) => (prevMode === All || (prevMode === Deselect && prev !== 1) ? Deselect : Select));
      return prev - 1;
    });
    // we remove selection from isAllTopics when we are deselecting topic(topic was selected originally on BE)
    setIsAllTopics(null);
    return;
  }

  // if topic was NOT manually selected/deselected previously
  // and is originally deselected from BE
  // and now is being clicked for the first time
  const wasOriginallyDeselectedInFeed = !wasManuallySelectedInFeed && !wasManuallyDeselectedInFeed && !isSelectedInFeed;
  if (wasOriginallyDeselectedInFeed) {
    setSelectedTopics((prev) => [...prev, id]);
    setTotalSelectedTopics((prev) => {
      const newTotalSelectedTopics = prev + 1;
      if (newTotalSelectedTopics === allExistingTopicsCount) {
        // we set selectionMode to All when we select all deselected topics manually
        setIsAllTopics(true);
        setSelectionMode(All);
      }
      return newTotalSelectedTopics;
    });
  }
};

export const isTopicChecked = ({ topic, selectedTopics, deselectedTopics }: IIsTopicCheckedArgs): boolean => {
  // if topic was manually selected previously
  const wasManuallySelectedInFeed = selectedTopics.includes(topic.id);
  if (wasManuallySelectedInFeed) {
    return true;
  }

  // if topic was manually deselected previously
  const wasManuallyDeselectedInFeed = deselectedTopics.includes(topic.id);
  if (wasManuallyDeselectedInFeed) {
    return false;
  }

  // if topic was NOT manually selected or deselected
  // we set the default state that was originally taken from BE,
  // otherwise - false
  if (!wasManuallySelectedInFeed && !wasManuallyDeselectedInFeed) {
    return topic.isSelectedInFeed ?? false;
  }
  return false;
};

export const handleClickAllTopics = (
  setSelectionMode: Dispatch<SetStateAction<ESelectionMode>>,
  setIsAllTopics: Dispatch<SetStateAction<boolean | null>>
): void => {
  setSelectionMode((prev) => {
    const isPrevModeAllTopics = prev === All;

    setIsAllTopics(!isPrevModeAllTopics);
    return isPrevModeAllTopics ? Select : All;
  });
};

export const removeTopicIdFromArray = (setter: Dispatch<SetStateAction<string[]>>, id: string): void =>
  setter((prev) => prev.filter((val) => val !== id));
