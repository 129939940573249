import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { parseToEmoji } from 'components/Reactions/helpers/helpers';
import { IAnimationItem } from './models';
import { createAnimation } from './helpers';
import { ANIMATION_DURATION, EMOJI_COUNT } from './constants';

export const FullScreenAnimaionContext = createContext({});

export const { Provider: FullScreenAnimationProvider } = FullScreenAnimaionContext;

interface IUseFullScreenAnimationContext {
  animations?: IAnimationItem[];
  makeAnimation?: (emoji: string) => void;
  shouldPlayAnimation?: string | null;
  setShouldPlayAnimation?: Dispatch<SetStateAction<string | null>>;
}

export const useFullScreenAnimationContext = (): IUseFullScreenAnimationContext => {
  const context = useContext<IUseFullScreenAnimationContext>(FullScreenAnimaionContext);

  return context;
};

export const useFullScreenAnimationState = () => {
  const [animations, setAnimations] = useState<IAnimationItem[]>([]);

  const [shouldPlayAnimation, setShouldPlayAnimation] = useState<string | null>(null);

  const makeAnimation = (emoji: string) => {
    const animationId = createAnimation(emoji, EMOJI_COUNT, setAnimations);
    setTimeout(() => {
      setAnimations?.((prev) => prev.filter(({ id }) => id !== animationId));
      setShouldPlayAnimation(null);
    }, ANIMATION_DURATION);
  };

  return {
    animations,
    makeAnimation,
    shouldPlayAnimation,
    setShouldPlayAnimation,
  };
};

export const useFullScreenAnimation = (messageId: string, emojiCode?: string): void => {
  const { makeAnimation, shouldPlayAnimation } = useFullScreenAnimationContext();

  useEffect(() => {
    if (shouldPlayAnimation && emojiCode && shouldPlayAnimation === messageId) {
      makeAnimation?.(parseToEmoji(emojiCode));
    }
  }, [shouldPlayAnimation, emojiCode]);
};
