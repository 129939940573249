import React, { Dispatch, FC } from 'react';
import { useParams } from 'react-router-dom';

import Badge from 'components/UI/Badge';

import { useHandleInvite } from 'graphQL/list/invite/hooks';
import { CollaborationInviteSchema } from 'constants/graphqlTypes';
import { StyledBtnWrap, StyledButtonAccept, StyledButtonDecline, StyledInviteText, StyledWrap } from './styled';

interface IListCollabInvite {
  isDark: boolean;
  invite: CollaborationInviteSchema;
  setDeclinedInviteId: Dispatch<React.SetStateAction<string>>;
}

const ListCollabInvite: FC<IListCollabInvite> = ({ invite, isDark, setDeclinedInviteId }) => {
  const { listId } = useParams<{ listId: string }>();
  const { id, inviter, message } = invite;
  const { username } = inviter ?? {};
  const handleInvite = useHandleInvite(id, listId);
  const handleDecline = () => {
    handleInvite(false);
    setDeclinedInviteId(id);
  };

  return username ? (
    <StyledWrap>
      {inviter && <Badge user={inviter} isDark={isDark} />}
      {message && <StyledInviteText>{message}</StyledInviteText>}
      <StyledBtnWrap>
        <StyledButtonDecline isDark={isDark} onClick={handleDecline}>
          Decline
        </StyledButtonDecline>
        <StyledButtonAccept isDark={isDark} onClick={() => handleInvite(true)}>
          Accept
        </StyledButtonAccept>
      </StyledBtnWrap>
    </StyledWrap>
  ) : null;
};

export default ListCollabInvite;
