import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ConfirmationModal from 'components/UI/ConfirmationModal';
import { useAddContentLinks } from 'components/AddContent/helpers/hooks';
import { useAnswerLinks } from 'components/AddAnswer/hooks';
import { IBlockedByAdminReason, IExtractionError } from 'components/AddContent/AddPick/AddPick';

import { useSparkResponseLinks } from 'components/Sparks/Routes/AddResponseToSpark/hooks';
import { EExtractionError, GENERAL_ERRORS } from './constants';

interface IExtractionErrorProps {
  extractionError: IExtractionError;
  setUrl: Dispatch<SetStateAction<string>>;
  reason: IBlockedByAdminReason;
  fromQotd?: boolean;
  fromSpark?: boolean;
  listId?: string;
}

const ExtractionError: FC<IExtractionErrorProps> = ({
  extractionError,
  setUrl,
  reason,
  listId,
  fromQotd,
  fromSpark,
}) => {
  const [error, setError] = useState('');
  const { errorType, isPrivateSource } = extractionError;
  const { reasonTitle, reasonDescription } = reason;

  const { replace } = useHistory();
  const { addToList, addPick, addStyle } = useAddContentLinks();
  const { addAnswerStyle, addAnswerLink } = useAnswerLinks();
  const { addResponsePick, addResponseStyle } = useSparkResponseLinks();

  const handleAddAnotherPick = () => {
    setUrl('');
    setError('');

    if (fromQotd) {
      replace(addAnswerLink);
    } else if (fromSpark) {
      replace(addResponsePick);
    } else {
      replace(addPick);
    }
  };

  const handleCancel = () => {
    if (fromQotd) {
      replace(addAnswerStyle);
    } else if (fromSpark) {
      replace(addResponseStyle);
    } else {
      replace(listId ? addStyle : addToList);
    }
  };

  useEffect(() => {
    if (GENERAL_ERRORS.includes(errorType || '')) {
      setError(EExtractionError.GeneralError);
    } else if (errorType === 'BLOCKED_BY_ADMIN') {
      setError(EExtractionError.BlockedByAdminError);
    } else if (isPrivateSource) {
      setError(EExtractionError.PrivateSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractionError]);

  return (
    <>
      {error === EExtractionError.GeneralError && (
        <ConfirmationModal
          handleAccept={handleAddAnotherPick}
          buttonText="Add another pick"
          handleClickOutside={handleAddAnotherPick}
          subTitle="We’re sorry, something went wrong with adding your pick. Your attempt has been logged with support as we improve how we import."
          titleText="Adding error"
          isWarning={false}
        />
      )}
      {error === EExtractionError.BlockedByAdminError && (
        <ConfirmationModal
          handleAccept={handleAddAnotherPick}
          buttonText="Add another pick"
          handleClickOutside={handleAddAnotherPick}
          subTitle={reasonDescription}
          titleText={reasonTitle}
          isWarning={false}
        />
      )}
      {error === EExtractionError.PrivateSource && (
        <ConfirmationModal
          buttonText="Cancel"
          cancelButtonText="Add anyway"
          handleClickOutside={handleAddAnotherPick}
          handleAccept={handleAddAnotherPick}
          handleCancel={handleCancel}
          subTitle="Heads up, this link is behind a login wall. So the content might not appear as intended or may not be accessible to everyone."
          titleText="Private source"
          isWarning={false}
        />
      )}
    </>
  );
};

export default ExtractionError;
