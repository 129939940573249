import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { ColorSchema, ECardLayoutOptions, UserObjectType } from 'constants/graphqlTypes';
import { changeCardStyle, createStyleItems } from 'components/AddContent/helpers/helpers';
import { useMoveSavedToPicks } from 'graphQL/savedPicks/hooks';

import Avatar from 'components/UI/Avatar';
import Button from 'components/UI/Button';
import TextInput from 'components/UI/TextInput';
import Items from 'components/AddContent/AddStyle/Items';
import Colors from 'components/AddContent/AddStyle/Colors';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import getImages from 'helpers/getImages';
import { EActionType, ECurrentMode } from './utils/constants';
import { IAction, IState } from './utils/models';

import {
  StyledComment,
  StyledComplete,
  StyledDrag,
  StyledFooter,
  StyledStyleSelection,
  StyledStyleSelectionContainer,
} from './styled';

interface IFooterProps {
  userData?: UserObjectType;
  dispatch: Dispatch<IAction>;
  currentThought: string;
  setCurrentThought: Dispatch<SetStateAction<string>>;
  state: IState;
}

const { Default, Style } = ECurrentMode;
const { Borderless, Framed } = ECardLayoutOptions;

const Footer: FC<IFooterProps> = ({
  userData,
  dispatch,
  currentThought,
  setCurrentThought,
  state: { mode, cards, currentCardIndex, newStyleItems, savedStyleItems, dataToSend, listId, listCardId, listTitle },
}) => {
  const currentCard = cards[currentCardIndex];
  const { linkImageSets } = currentCard ?? {};
  const showImages = linkImageSets?.items?.some(({ images }) => images?.[0]?.url);
  const items = createStyleItems([
    ...(showImages && linkImageSets?.items ? linkImageSets.items : []),
    ...(linkImageSets?.items[0]?.colors?.sourceGradient?.items ?? []),
  ]);

  const itemId = newStyleItems[currentCardIndex]?.activeStyleOptionId ?? 0;

  const currentItem = items.find(({ id }) => id === itemId);
  const colors = currentItem?.colors;
  const currentColor = currentItem?.colors?.[0];

  const layout = currentCard?.mediaTags?.items[0]?.name === 'video' ? Borderless : Framed;

  const updatedStyle = changeCardStyle(layout, currentCard?.cardStyle, currentColor, currentItem?.image);

  const handleChangeStyle = (selectedId: number) => {
    const selectedItem = items.find(({ id }) => id === selectedId);
    const selectedCurrentColor = selectedItem?.colors?.[0];

    const newUpdatedStyle = changeCardStyle(layout, currentCard?.cardStyle, selectedCurrentColor, selectedItem?.image);

    if (newUpdatedStyle) {
      dispatch({
        type: EActionType.AddStyle,
        variable: {
          cardStyle: newUpdatedStyle,
          activeStyleOptionId: selectedId,
          cardId: currentCard.id,
        },
      });
    }
  };

  const { moveSavedToPicks } = useMoveSavedToPicks({
    data: dataToSend,
    listCardId,
    listId,
    listTitle,
    cardImageUrl: getImages(savedStyleItems[0]?.cardStyle)?.imageUrl || undefined,
  });

  useEffect(() => {
    if (updatedStyle) {
      dispatch({
        type: EActionType.AddStyle,
        variable: {
          cardStyle: updatedStyle,
          activeStyleOptionId: itemId,
          cardId: currentCard.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return mode !== Style ? (
    <StyledFooter>
      <StyledDrag />
      <StyledComment>
        {userData && <Avatar user={userData} />}
        <TextInput
          inputHeight={80}
          placeholder="Add your thought"
          setText={(value: string) => setCurrentThought(value)}
          text={currentThought}
          type="thought"
          error={undefined}
          isMultiLine
          autoCapitalize={EAutoCapitalize.On}
          autoFocus={false}
        />
      </StyledComment>
      <StyledComplete hideButton={mode !== Default}>
        <Button text={`Share ${cards.length} pick${cards.length > 1 ? 's' : ''}`} handler={() => moveSavedToPicks()} />
      </StyledComplete>
    </StyledFooter>
  ) : (
    <StyledStyleSelectionContainer>
      <span>Select your style</span>
      {colors && colors.length > 1 && (
        <Colors
          items={colors}
          color={newStyleItems[currentCardIndex]?.cardStyle?.bgColor?.colorValue}
          dispatch={(color: ColorSchema) => {
            dispatch({
              type: EActionType.ChangeColor,
              variable: {
                color,
                cardId: currentCard.id,
              },
            });
          }}
        />
      )}
      <StyledStyleSelection>
        <Items items={items} itemId={itemId} setItemId={() => {}} handler={handleChangeStyle} />
      </StyledStyleSelection>
    </StyledStyleSelectionContainer>
  );
};

export default Footer;
