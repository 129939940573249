import { useEffect } from 'react';

const useDisableContextMenu = (): void => {
  const disableContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    window.addEventListener('contextmenu', disableContextMenu);

    return () => window.removeEventListener('contextmenu', disableContextMenu);
  }, []);
};

export default useDisableContextMenu;
