import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

import { StyledCardWrap } from 'components/Sliders/styled';

export const StyledModalWrap = styled.div`
  .modal {
    padding: 24px 0px;
  }
  .modal--header {
    padding: 0 24px 0 12px;
  }
  .modal--content {
    padding: 0;
  }
`;

export const StyledHeader = styled.p`
  margin: 0;
  font: 400 22px/130% ${FONTS.primary};
  color: ${COLORS.brown.dark[100]};
  padding: 16px 0 0 24px;
`;

export const StyledBadgeContainer = styled.div`
  padding: 16px 24px 0;
`;

export const StyledSliderContainer = styled.div<{ hasOneCard: boolean }>`
  padding-top: 16px;
  width: 100%;

  ${StyledCardWrap} {
    padding: ${({ hasOneCard }) => (hasOneCard ? '0 24px' : 0)};
    max-width: ${({ hasOneCard }) => (hasOneCard ? '342px' : 'unset')};
  }
`;
