import React, { FC } from 'react';

import Grain from 'components/UI/Grain';
import useFixedViewport from 'helpers/useFixedViewport';

import { StyledBackground, StyledContent } from './styled';

interface IAnimatedBackgroundProps {
  gradient?: { start: string; end: string };
  enableInnerScroll?: boolean;
}

const AnimatedBackground: FC<IAnimatedBackgroundProps> = ({ children, gradient, enableInnerScroll }) => {
  useFixedViewport();

  return (
    <StyledBackground gradient={gradient}>
      <div className="rotating-bg" />
      <Grain />
      <StyledContent enableInnerScroll={enableInnerScroll}>{children}</StyledContent>
    </StyledBackground>
  );
};

export default AnimatedBackground;
