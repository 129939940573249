import React, { Dispatch, FC, SetStateAction } from 'react';
import { Waypoint } from 'react-waypoint';

import LoaderContent from 'components/UI/LoaderContent';
import useTabsScroll from 'helpers/useTabsScroll';

import { EVisibilityStatusOptions, UserObjectType, UserShortSchema } from 'constants/graphqlTypes';
import { getSearchCardTestId } from 'constants/aqa/search';
import { StyledList, StyledNoContent } from './styled';

import UserListItem from './UserListItem';
import { EButtonType } from './constants';

interface IUserList {
  users: UserObjectType[] | UserShortSchema[];
  isDark?: boolean;
  loading: boolean;
  loadMore: () => void;
  emptyMessage?: string;
  currentTab?: number;
  buttonType?: EButtonType;
  handleUser?: (user: UserObjectType) => void;
  selected?: UserObjectType[];
  hasUsername?: boolean;
  hasNameOnly?: boolean;
  fromInviteCollaborators?: boolean;
  emptyLabelTestId?: string;
  setError?: Dispatch<SetStateAction<boolean>>;
  pageTestId?: string;
  paddingVertical?: number;
  itemBtndataTestIdTemplate?: (name: string) => string;
}

const UserList: FC<IUserList> = ({
  users,
  isDark = true,
  loading,
  loadMore,
  emptyMessage,
  currentTab,
  buttonType = EButtonType.Follow,
  handleUser,
  selected,
  hasUsername,
  hasNameOnly,
  fromInviteCollaborators,
  emptyLabelTestId,
  setError,
  pageTestId,
  paddingVertical,
  itemBtndataTestIdTemplate,
}) => {
  const getButtonType = (user: UserObjectType) =>
    fromInviteCollaborators &&
    (user.userSettings?.collaborativeListsVisibility === EVisibilityStatusOptions.NoOne ||
      (user.userSettings?.collaborativeListsVisibility === EVisibilityStatusOptions.Following && !user.isFollowingMe))
      ? EButtonType.None
      : buttonType;

  return (
    <>
      <StyledList ref={useTabsScroll(currentTab)}>
        {(users as UserObjectType[]).map((user, index) => (
          <UserListItem
            buttonDataTestId={itemBtndataTestIdTemplate?.(user.username)}
            isDark={isDark}
            isSelected={!!selected?.some(({ id }) => id === user.id)}
            handleUser={handleUser ? () => handleUser(user) : undefined}
            buttonType={getButtonType(user)}
            key={user.id}
            user={user}
            hasUsername={hasUsername}
            hasNameOnly={hasNameOnly}
            setError={setError}
            dataTestId={`${getSearchCardTestId(index, pageTestId)}-${user.username}`}
            paddingVertical={paddingVertical}
          />
        ))}
      </StyledList>
      {!users.length && !loading && emptyMessage && (
        <StyledNoContent data-testid={emptyLabelTestId} isDark={isDark}>
          {emptyMessage}
        </StyledNoContent>
      )}
      {loading ? <LoaderContent isDark={isDark} isFullScreen={!users.length} /> : <Waypoint onEnter={loadMore} />}
    </>
  );
};

export default UserList;
