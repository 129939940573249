import { ApolloCache } from '@apollo/client';
import { isSameDay } from 'date-fns';

import {
  ChatUnionSchema,
  GetAllChatsCountDocument,
  GetAllChatsCountQuery,
  GetAllChatsDocument,
  GetAllChatsQuery,
  GetMessagesDocument,
  GetMessagesQuery,
  GetUnreadChatsNotificationDocument,
  GetUnreadChatsNotificationQuery,
  MessageSchema,
  TabOptions,
  GetMessageContentDocument,
  GetMessageContentQuery,
  GetMessageContentQueryVariables,
  MessageItemType,
  MessageContentStatus,
  GetDetailListDocument,
  GetDetailListQuery,
  GetDetailListQueryVariables,
} from 'constants/graphqlTypes';
import { getWebDate } from 'helpers/getDateFormat';
import { MESSAGES_TAB_KEY } from 'components/Messages/helpers/constants';
import { CHAT_MESSAGES_LIMIT, EChatField } from './constants';
import { IChatQueryType } from './models';

export const getUserIdFromMessage = (message?: MessageSchema): string =>
  message?.sender?.__typename === 'UserShortSchema' ? message?.sender?.id : '';

export const groupMessages = (messages: MessageSchema[]): MessageSchema[][] => {
  const groups: MessageSchema[][] = [];
  let group: MessageSchema[] = [];

  messages.forEach((message, index) => {
    const prev = messages[index - 1];

    const hasTheSameUser = getUserIdFromMessage(prev) === getUserIdFromMessage(message);
    const hasTheSameDay = isSameDay(getWebDate(prev?.createdAt), getWebDate(message?.createdAt));

    if (!hasTheSameUser || !hasTheSameDay) {
      group = [];
      groups.push(group);
    }

    group.push(message);
  });

  return groups;
};

export const updateMessages = (chatId: string, message: MessageSchema, cache: ApolloCache<unknown>): void => {
  const messages: GetMessagesQuery | null = cache?.readQuery({
    query: GetMessagesDocument,
    variables: { chatId, offset: 0, limit: CHAT_MESSAGES_LIMIT },
  });

  if (!messages) {
    return;
  }

  cache.writeQuery({
    query: GetMessagesDocument,
    variables: { offset: 0, chatId, limit: CHAT_MESSAGES_LIMIT },
    data: {
      allMessages: {
        ...messages.allMessages,
        items: messages.allMessages?.items.find((mess) => mess.id === message.id)
          ? messages.allMessages?.items ?? []
          : [message, ...(messages.allMessages?.items ?? [])],
        totalCount: (messages.allMessages?.totalCount ?? 0) + 1,
      },
    },
  });
};

export const addChatToCache = (chat: ChatUnionSchema, tab: TabOptions | null, cache: ApolloCache<unknown>): void => {
  const messagesList: GetAllChatsQuery | null = cache.readQuery({
    query: GetAllChatsDocument,
    variables: { offset: 0, tab },
  });
  const chatsCount: GetAllChatsCountQuery | null = cache.readQuery({
    query: GetAllChatsCountDocument,
  });

  if (!messagesList) {
    return;
  }

  const chats = messagesList.allChats?.items ?? [];

  if (chat) {
    cache?.writeQuery({
      query: GetAllChatsDocument,
      variables: { offset: 0, tab },
      data: {
        allChats: {
          ...messagesList.allChats,
          items: [chat, ...chats.filter(({ id }) => id !== chat.id)],
          totalCount: (messagesList.allChats?.totalCount ?? 0) + 1,
        },
      },
    });
    cache?.writeQuery({
      query: GetAllChatsCountDocument,
      data: {
        allChats: {
          ...chatsCount?.allChats,
          totalCount: (chatsCount?.allChats?.totalCount ?? 0) + 1,
        },
      },
    });
  }
};

export const deleteChatFromCache = (deletedChatId: string, cache: ApolloCache<unknown>): void => {
  const localTab = JSON.parse(localStorage.getItem(MESSAGES_TAB_KEY) ?? 'null');
  const messagesList: GetAllChatsQuery | null = cache?.readQuery({
    query: GetAllChatsDocument,
    variables: { offset: 0, tab: localTab },
  });
  const chatsCount: GetAllChatsCountQuery | null = cache.readQuery({
    query: GetAllChatsCountDocument,
  });

  if (!messagesList) {
    return;
  }

  cache?.writeQuery({
    query: GetAllChatsDocument,
    variables: { offset: 0, tab: localTab },
    data: {
      allChats: {
        ...messagesList.allChats,
        items: messagesList.allChats?.items.filter(({ id }) => id !== deletedChatId),
        totalCount: messagesList.allChats?.totalCount ? messagesList.allChats?.totalCount - 1 : 0,
      },
    },
  });
  cache?.writeQuery({
    query: GetAllChatsCountDocument,
    data: {
      allChats: {
        ...chatsCount?.allChats,
        totalCount: chatsCount?.allChats?.totalCount ? chatsCount?.allChats?.totalCount - 1 : 0,
      },
    },
  });

  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: 'allMessages',
    args: {
      chatId: deletedChatId,
      limit: CHAT_MESSAGES_LIMIT,
      offset: 0,
    },
  });
};

// TODO DELETE MESSAGE - refactor later
export const deleteCachedMessage = (cache: ApolloCache<unknown>, id: string): void => {
  cache.modify({
    id: cache.identify({
      id,
      __typename: 'MessageSchema',
    }),
    fields: {
      deletedAt: () => '0000000000',
    },
  });
};

export const updateChats = (field: EChatField, query: IChatQueryType, newQuery: IChatQueryType): IChatQueryType => ({
  ...query,
  ...(query[field] && {
    [field]: {
      ...query[field],
      totalCount: query?.[field]?.totalCount ?? 0,
      items: [...(query?.[field]?.items ?? []), ...(newQuery?.[field]?.items ?? [])],
    },
  }),
});

export const updateMessageReactions = ({ id, reactions }: MessageSchema, cache: ApolloCache<unknown>): void => {
  cache.modify({
    id: cache.identify({ id, __typename: 'MessageSchema' }),
    fields: { reactions: () => reactions },
  });
};

export const updateUnreadChatsCount = (count: number, cache: ApolloCache<unknown>): void => {
  const unreadChatsCount: GetUnreadChatsNotificationQuery | null = cache?.readQuery({
    query: GetUnreadChatsNotificationDocument,
  });

  if (!unreadChatsCount) {
    return;
  }

  cache?.writeQuery({
    query: GetUnreadChatsNotificationDocument,
    variables: { offset: 0 },
    data: {
      allChats: {
        ...unreadChatsCount.allChats,
        totalCount: count,
      },
    },
  });
};

export const updateReadMessage = (id: string, cache: ApolloCache<unknown>): void => {
  cache.modify({
    id: cache.identify({
      id,
      __typename: 'MessageSchema',
    }),
    fields: {
      isViewed: () => true,
    },
  });
};

export const updateInviteStatus = (listPickId: string, cache: ApolloCache<unknown>): void => {
  const listData = cache.readQuery<GetDetailListQuery, GetDetailListQueryVariables>({
    query: GetDetailListDocument,
    variables: {
      id: listPickId,
      referrerId: undefined,
      coverLimit: undefined,
    },
  });
  const inviteId = listData?.allCards?.items?.[0]?.invite?.id;

  cache.updateQuery<GetMessageContentQuery, GetMessageContentQueryVariables>(
    {
      query: GetMessageContentDocument,
      variables: {
        itemType: MessageItemType.Invite,
        pickId: listPickId,
        inviteId,
      },
    },
    (oldMessageContent) => ({
      ...oldMessageContent,
      getMessageContent: {
        ...oldMessageContent?.getMessageContent,
        messageContentStatus: MessageContentStatus.Unavailable,
      },
    })
  );
};
