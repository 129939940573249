import { FC, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import usePageType from 'helpers/usePageType';
import { useContentNotFound } from 'helpers/routingHelper';

const ContentNotFound: FC = () => {
  const { push } = useHistory();
  const { search, state, pathname } = useLocation<{ backgroundModal: any }>();
  const handlerContentNotFound = useContentNotFound();
  const { isThoughtsPage } = usePageType();

  useEffect(() => {
    if (isThoughtsPage && !state?.backgroundModal) {
      const pickDetailPath = pathname.replace('/thoughts', '');
      push({
        pathname: pickDetailPath,
        state: {
          variable: {
            shouldOpenThoughts: true,
          },
        },
      });
    } else if (!search.includes('withModalBg')) {
      handlerContentNotFound();
    }
  }, [search, state]);

  return null;
};

export default ContentNotFound;
