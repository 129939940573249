import React, { FC } from 'react';
import { StyledButtonWrapper, StyledCevronIcon, StyledScrollButton, StyledScrollButtonText } from './styled';

interface IScrollToLatestButtonProps {
  text?: string;
  handler: () => void;
}

const ScrollToLatestButton: FC<IScrollToLatestButtonProps> = ({ text, handler }) => (
  <StyledButtonWrapper>
    <StyledScrollButton onClick={handler}>
      <StyledCevronIcon />
      {text && <StyledScrollButtonText>{text}</StyledScrollButtonText>}
    </StyledScrollButton>
  </StyledButtonWrapper>
);

export default ScrollToLatestButton;
