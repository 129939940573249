import React, { FC, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ESelectionMode } from 'components/CustomFeeds/constants';
import { ROUTE_EDIT_FEED, ROUTE_EDIT_FEED_NAME, ROUTE_EDIT_FEED_TOPICS, ROUTE_EDIT_FEED_USERS } from 'routes';
import SelectFeedEditUsers from '../Views/SelectFeedEditUsers';

import EditFeedModal from '../Views/EditFeedModal';
import EditFeedName from '../Views/EditFeedName';
import SelectFeedEditTopics from '../Views/SelectFeedEditTopics';

const EditFeed: FC = () => {
  const { Select } = ESelectionMode;

  const [selectionTopicMode, setSelectionTopicMode] = useState<ESelectionMode>(Select);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [deselectedTopics, setDeselectedTopics] = useState<string[]>([]);
  const [totalSelectedTopics, setTotalSelectedTopics] = useState(0);

  const [selectionUserMode, setSelectionUserMode] = useState<ESelectionMode>(Select);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [deselectedUsers, setDeselectedUsers] = useState<string[]>([]);
  const [totalSelectedUsers, setTotalSelectedUsers] = useState(0);

  const [isAllFollowings, setAllFollowings] = useState(false);
  const [isAllUsers, setAllUsers] = useState(false);

  return (
    <Switch>
      <Route exact path={ROUTE_EDIT_FEED_TOPICS}>
        <SelectFeedEditTopics
          selectionMode={selectionTopicMode}
          setSelectionMode={setSelectionTopicMode}
          selectedTopics={selectedTopics}
          setSelectedTopics={setSelectedTopics}
          deselectedTopics={deselectedTopics}
          setDeselectedTopics={setDeselectedTopics}
          totalSelectedTopics={totalSelectedTopics}
          setTotalSelectedTopics={setTotalSelectedTopics}
        />
      </Route>
      <Route exact path={ROUTE_EDIT_FEED_USERS}>
        <SelectFeedEditUsers
          selectionMode={selectionUserMode}
          setSelectionMode={setSelectionUserMode}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          deselectedUsers={deselectedUsers}
          setDeselectedUsers={setDeselectedUsers}
          totalSelectedUsers={totalSelectedUsers}
          setTotalSelectedUsers={setTotalSelectedUsers}
          isAllFollowings={isAllFollowings}
          setAllFollowings={setAllFollowings}
          isAllUsers={isAllUsers}
          setAllUsers={setAllUsers}
        />
      </Route>
      <Route exact path={ROUTE_EDIT_FEED}>
        <EditFeedModal setTotalSelectedTopics={setTotalSelectedTopics} setSelectionTopicMode={setSelectionTopicMode} />
      </Route>
      <Route path={ROUTE_EDIT_FEED_NAME}>
        <EditFeedName />
      </Route>
    </Switch>
  );
};

export default EditFeed;
