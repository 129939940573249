import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  padding: 0 24px;
`;

export const StyledTitle = styled.h1`
  font: 400 32px/120% ${FONTS.primary};
  margin: 8px 0 16px;
  text-transform: capitalize;
`;

export const StyledSubtitle = styled.div<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 14px/140% ${FONTS.secondary};

  b {
    font-weight: 600;
  }
`;

export const StyledAvatarWrap = styled.div`
  margin-bottom: 40px;

  li:first-child {
    margin-left: 0;
  }
`;

export const StyledAttributeWrap = styled.div`
  margin-top: -16px;
  padding-bottom: 24px;
`;

export const StyledThumbnailWrap = styled.div`
  margin-right: -4px;
`;
