import { styled } from 'linaria/react';
import {
  StyledButton,
  StyledRoleList as ImportedList,
} from 'components/ListSettings/EditListCollaborators/RoleSelect/styled';
import { COLORS } from 'styles/constants';

export const StyledRolesButton = styled(StyledButton)<{ isDark: boolean }>`
  background-color: ${({ isDark }) => (isDark ? COLORS.white[10] : COLORS.white.off[100])};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  text-transform: none;

  svg > path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;

export const StyledWrap = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 16px 0;
`;

export const StyledButtonName = styled.span`
  white-space: nowrap;
`;
export const StyledListWrap = styled.div`
  padding: 32px 0 96px;
`;

export const StyledRoleList = styled(ImportedList)`
  background-color: ${COLORS.white.off[100]};
  margin: 16px 0;
  opacity: 0.9;
  padding: 0;
`;
