import React, { FC, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Button from 'components/UI/MenuButton';
import getAuthUser from 'helpers/getAuthUser';

import { useMenuModal } from 'components/Navigation/helpers/hooks';
import { useThought } from 'helpers/useContextMenu';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import { CardSchema, MessageType } from 'constants/graphqlTypes';
import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { ReactComponent as GridIcon } from 'assets/icons/Grid.svg';
import { StyledItem } from 'components/AddContent/Menu/styled';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_LIST_DETAIL, ROUTE_USER_ALL_PICKS } from 'routes';
import { useGetComments } from 'graphQL/card/comments/hooks';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';

import { EReportTypes } from 'components/Report/constants';
import RemovePick from 'components/RemovePick';
import { useListsWithCard } from 'graphQL/list/hooks';
import { StyledSettingsLabel } from 'components/Navigation/styled';
import MoveCardButtons from '../MoveCardButtons';
import MenuWrap from '../MenuWrap';
import RemoveCardButton from '../RemoveCardButton';
import { checkIsAdmin } from './helpers';
import { StyledList, StyledListItem } from './styled';
import ShareButtons from '../ShareButtons';

const CardButton: FC<{ card: CardSchema }> = ({ card }) => {
  const { listId } = useParams<{ listId: string }>();
  const { isModalOpen, handleModal } = useMenuModal();
  const { pathname, state } = useLocation<{ prevPath?: string } | undefined>();
  const { prevPath } = state ?? {};
  const { username, userId } = getAuthUser();
  const isListPrev = prevPath?.includes('/lists');
  const isTopicPrev = prevPath?.includes('/topics');
  const isMyAllPicksPrev = prevPath?.includes('/all-picks') && prevPath?.includes(username);
  const { isPickedByMe, id: pickId, cardId, parentCards, permissions } = card;
  const { id: parentListId, cardId: listCardId, title: parentListTitle, isCollaborative } = parentCards?.items[0] ?? {};
  const showViewInYourList = isMyAllPicksPrev && isPickedByMe && !listCardId;
  const isAdmin = checkIsAdmin(permissions);
  const [typeOfCard, setTypeOfCard] = useState('');
  const { card: list } = useGetDetailCard(listId);
  const { listCardIdsWithCard } = useListsWithCard(pickId);

  const { push } = useHistory();

  const { totalCount: myThought } = useGetComments(pickId, { referrerUserId: userId });
  const { Icon: ThoughtIcon, text: thoughtText, toggle: openThought } = useThought(pickId, cardId, listId, !!myThought);
  const isCardInAnotherList = !!listId && listId !== parentListId && !isCollaborative;
  const toReport = useNavigateToReport({ cardId, pathname, type: EReportTypes.Card });

  const handleReport = () => {
    handleModal(false);
    toReport();
  };

  const handleThought = () => {
    handleModal(false);
    openThought?.();
  };

  const handleViewMyList = () => {
    push(
      transformRoute(parentListId ? ROUTE_LIST_DETAIL : ROUTE_USER_ALL_PICKS, {
        listId: parentListId,
        profileName: username,
      })
    );
  };

  return (
    <MenuWrap isOpen={isModalOpen} handleModal={handleModal}>
      {!(isTopicPrev && isPickedByMe) ? (
        <>
          <StyledSettingsLabel>Share</StyledSettingsLabel>
          <ShareButtons
            closePreviousModal={() => handleModal(false)}
            sharedId={pickId}
            messageType={MessageType.Pick}
          />
          {!isPickedByMe && (
            <StyledItem hideBorder>
              <Button text="Report" Icon={ReportIcon} handler={handleReport} />
            </StyledItem>
          )}
          {(isPickedByMe || isAdmin) && (
            <StyledListItem>
              <StyledList>
                <StyledSettingsLabel>More options</StyledSettingsLabel>
                {!isCardInAnotherList && (
                  <StyledItem>
                    <Button text={thoughtText} Icon={ThoughtIcon} handler={handleThought} />
                  </StyledItem>
                )}
                {isPickedByMe && (
                  <MoveCardButtons
                    closePreviousModal={() => handleModal(false)}
                    isCardInAnotherList={isCardInAnotherList}
                    card={card}
                    parentListCardId={listCardId}
                    parentListTitle={parentListTitle}
                    handleViewInMyList={handleViewMyList}
                    isUserInList={!!permissions?.length}
                  />
                )}
                {!(isListPrev || showViewInYourList) && (
                  <StyledItem>
                    <Button text="View in your list" Icon={GridIcon} handler={handleViewMyList} />
                  </StyledItem>
                )}
                {((isCardInAnotherList && isAdmin) || !isCardInAnotherList) && (
                  <RemoveCardButton card={card} setTypeOfCard={setTypeOfCard} />
                )}
              </StyledList>
            </StyledListItem>
          )}
          {typeOfCard && (
            <RemovePick
              card={card}
              handleCancel={() => setTypeOfCard('')}
              handleClose={() => handleModal(false)}
              listCardId={(listCardId || list?.cardId) ?? ''}
              listCardsIds={listCardIdsWithCard}
              typeOfCard={typeOfCard}
              setTypeOfCard={setTypeOfCard}
            />
          )}
        </>
      ) : (
        <StyledListItem>
          <StyledList>
            <StyledSettingsLabel>More options</StyledSettingsLabel>
            <StyledItem>
              <Button text={thoughtText} Icon={ThoughtIcon} handler={handleThought} />
            </StyledItem>
            <MoveCardButtons
              closePreviousModal={() => handleModal(false)}
              isCardInAnotherList={isCardInAnotherList}
              card={card}
              parentListCardId={listCardId}
              parentListTitle={parentListTitle}
              handleViewInMyList={handleViewMyList}
              isUserInList={!!permissions?.length}
            />
            <StyledItem>
              <Button text="View in your list" Icon={GridIcon} handler={handleViewMyList} />
            </StyledItem>
            <RemoveCardButton card={card} setTypeOfCard={setTypeOfCard} />
          </StyledList>
        </StyledListItem>
      )}
    </MenuWrap>
  );
};

export default CardButton;
