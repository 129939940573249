import { useEffect } from 'react';

import { ESelectionMode } from 'components/CustomFeeds/constants';
import { UserObjectType } from 'constants/graphqlTypes';
import { checkObjectInArray, removeObjectFromArray } from './helpers';
import { IUseCreFeedEventsArgs, IUseTotalCount } from './models';

const { Followings, All, Deselect, Select } = ESelectionMode;

export const useCreateFeedSelections = ({
  selectionMode,
  selectedUsers,
  deselectedUsers,
  setSelectionMode,
  setSelectedUsers,
  setDeselectedUsers,
  setSelectFollowings,
  setHaveAllUsersNoFollowings,
  setAllUsers,
  setTotalSelectedUsers,
}: IUseCreFeedEventsArgs) => {
  const handleSelection = (mode: ESelectionMode.All | ESelectionMode.Followings) => {
    setSelectionMode((prev) => {
      if ((prev === Select || prev === Deselect) && mode === Followings) {
        setHaveAllUsersNoFollowings(false);
        setAllUsers(false);
        setSelectFollowings(true);
      }

      if (prev === Followings) {
        setHaveAllUsersNoFollowings(false);
        setSelectFollowings(false);
      }

      if (prev === All && mode === Followings) {
        setHaveAllUsersNoFollowings(true);
        setSelectFollowings(false);
        return Deselect;
      }

      if (prev !== All && mode === All) {
        setAllUsers(true);
        setHaveAllUsersNoFollowings(false);
      }

      if (prev === All) {
        setAllUsers(false);
        setTotalSelectedUsers(0);
      }

      setSelectedUsers([]);
      setDeselectedUsers([]);

      return prev === mode ? Select : mode;
    });
  };

  const handleSelectItem = (user: UserObjectType, isSearch?: boolean, hadCheckMark?: boolean) => {
    if (selectionMode === All) {
      setSelectionMode(Deselect);
      setDeselectedUsers([user]);
      setSelectedUsers([]);

      return;
    }

    if (selectionMode === Followings && !isSearch) {
      setSelectionMode(Deselect);
      setDeselectedUsers([user]);
      setSelectedUsers((prev) => prev.filter((selectedUser) => !selectedUser.isFollowedByMe));

      return;
    }

    if (selectionMode === Followings && isSearch && !hadCheckMark) {
      setSelectedUsers((prev) => [...prev, user]);

      return;
    }

    if (selectionMode === Followings && isSearch && hadCheckMark) {
      setSelectionMode(Deselect);
      setDeselectedUsers((prev) => [...prev, user]);

      return;
    }

    const userExistsInSelectedArray = checkObjectInArray(selectedUsers, user);
    const userExistsInDeselectedArray = checkObjectInArray(deselectedUsers, user);

    if (hadCheckMark && userExistsInSelectedArray) {
      removeObjectFromArray(setSelectedUsers, user);
      return;
    }

    if (hadCheckMark && !userExistsInSelectedArray && !userExistsInDeselectedArray) {
      setDeselectedUsers((prev) => [...prev, user]);
      return;
    }

    if (!hadCheckMark && userExistsInDeselectedArray) {
      removeObjectFromArray(setDeselectedUsers, user);
      return;
    }

    if (!hadCheckMark && !userExistsInSelectedArray && !userExistsInDeselectedArray) {
      setSelectedUsers((prev) => [...prev, user]);
    }
  };

  return { handleSelection, handleSelectItem };
};

export const useTotalCount = (args: IUseTotalCount) => {
  const {
    selectionMode,
    setTotalSelectedUsers,
    summaryTotal,
    selectedUsers,
    deselectedUsers,
    totalSelectedUsers,
    totalFollowingsCount,
    isAllUsers,
    isAllFollowings,
    haveAllUsersNoFollowings,
  } = args;

  useEffect(() => {
    // 'all users' button is clicked
    if (selectionMode === All) {
      setTotalSelectedUsers(summaryTotal || totalSelectedUsers);

      return;
    }

    // no users are selected, select some users
    if (selectedUsers.length && selectionMode === Select) {
      setTotalSelectedUsers(selectedUsers.length);

      return;
    }

    // select all followings, deselect some of the followings (selection mode Deselect)
    if (isAllFollowings && !isAllUsers && totalFollowingsCount) {
      const selectedUsersNotFollowedByMe = selectedUsers.filter((user) => !user.isFollowedByMe);
      setTotalSelectedUsers(totalFollowingsCount + selectedUsersNotFollowedByMe.length - deselectedUsers.length);

      return;
    }

    // isAllusers and deselecting some of them
    if (isAllUsers && deselectedUsers.length && !haveAllUsersNoFollowings) {
      setTotalSelectedUsers(summaryTotal - deselectedUsers.length);

      return;
    }

    // crazyMode
    if (isAllUsers && haveAllUsersNoFollowings && totalFollowingsCount) {
      setTotalSelectedUsers(summaryTotal - totalFollowingsCount + selectedUsers.length - deselectedUsers.length);
      return;
    }

    // initial state or when we selected some users and then deselected all of them
    if (selectionMode === Select && selectedUsers.length === 0) {
      setTotalSelectedUsers(0);
    }
  }, [selectionMode, selectedUsers.length, deselectedUsers.length, isAllUsers, summaryTotal, haveAllUsersNoFollowings]);
};
