export enum EAutoCapitalize {
  On = 'on',
  Off = 'off',
  Words = 'words',
}

// next regexp finds either a first lowercase letter in the beginning of the string,
// or the first lowercase letter after space symbol
export const FIRST_LOWERCASE_LETTER_IN_WORD = /^[a-z]|(\s[a-z])/g;

// next regexp finds the first lowercase letter in the beginning of the string
export const FIRST_LOWERCASE_LETTER_IN_STRING = /^[a-z]/;

export const DOUBLE_DASH = '--';
export const LONG_DASH = '\u2014';
