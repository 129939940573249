export const PROFILE_BUTTON = 'profile_button';

export const HOME_BUTTON = 'home-button';

export const PICKER_BUTTON = 'picker-button';

export const SAVED_FOR_LATER = 'saved-for-later';

export const LAST_STEP = 'last-step';

export const NOTIFICATION_BUTTON = 'notification-button';

export const PICKER = 'picker';

export const SHARE_THOUGHT = 'share-thought';
