import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { BIO_LENGTH } from 'constants/common';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import EditTextFieldModal from 'components/EditTextFieldModal';

import { IEditTextFieldModalDataTestIds } from 'components/EditTextFieldModal/EditTextFieldModal';
import {
  TEST_ID_BIO_EDIT_VIEW_BIO_LABEL,
  TEST_ID_BIO_EDIT_VIEW_CANCEL_BUTTON,
  TEST_ID_BIO_EDIT_VIEW_DONE_BUTTON,
} from 'constants/aqa/profile';
import useChangeProfileBio from './hooks';
import { useProfileSettingsLinks } from '../../hooks';

interface IChangeBioProps {
  handleClose: () => void;
  handlePrev?: () => void;
}

const dataTestIds: IEditTextFieldModalDataTestIds = {
  inputDataTestId: TEST_ID_BIO_EDIT_VIEW_BIO_LABEL,
  cancelBtnDataTestId: TEST_ID_BIO_EDIT_VIEW_CANCEL_BUTTON,
  doneBtnDataTestId: TEST_ID_BIO_EDIT_VIEW_DONE_BUTTON,
};

const ChangeBio: FC<IChangeBioProps> = ({ handleClose, handlePrev }) => {
  const { replace } = useHistory();
  const { editProfile } = useProfileSettingsLinks();

  const handleNext = () => replace(editProfile);

  const { shortBio, error, loadingUserUpdate, handleError, handleChange, handleDone } = useChangeProfileBio(handleNext);

  return (
    <EditTextFieldModal
      text={shortBio}
      setText={handleChange}
      label="Short bio"
      handleClose={handleClose}
      handleDone={handleDone}
      handlePrev={handlePrev}
      error={error}
      loading={loadingUserUpdate}
      setError={handleError}
      maxLength={BIO_LENGTH}
      autoCapitalize={EAutoCapitalize.On}
      dataTestIds={dataTestIds}
    />
  );
};

export default ChangeBio;
