import React, { FC, useEffect } from 'react';

import useTheme from 'helpers/useTheme';
import useReferrerId from 'helpers/useReferrerId';
import usePageType from 'helpers/usePageType';
import { useGetUserRecentPick } from 'graphQL/cards/userCards/hooks';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import { ReactionsProvider, useReactionContextState } from 'components/Reactions/hooks';

import CardList from 'components/Card/List';
import InteractionBlock from 'components/InteractionBlock';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import ReactionsModal from 'components/Reactions/ReactionsModal';

import { StyledRecentPickContainer, StyledTitle } from './styled';

const ProfileRecentPick: FC = () => {
  const isDark = useTheme();
  const { recentPick, hasSpotlights } = useGetUserRecentPick();
  const referrerId = useReferrerId();
  const { isMyProfile } = usePageType();

  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  const { setPickerId, pickerId, cardId, setCardId, setThoughtId, thoughtId } = useReactionContextState();

  useEffect(() => {
    if (recentPick && referrerId) {
      setCardId(recentPick.id);
      setPickerId(referrerId);

      if (recentPick.comments) {
        setThoughtId(recentPick.comments?.items[0]?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentPick]);

  return hasSpotlights && recentPick ? (
    <ReactionsProvider
      value={{
        isQuickReactionOpen,
        setPickerId,
        pickerId,
        cardId,
        setCardId,
        thoughtId,
        setThoughtId,
      }}
    >
      <StyledRecentPickContainer ref={infoRef}>
        <StyledTitle>Recent pick</StyledTitle>
        <InteractionBlock
          tapCallback={() => setIsQuickReactionOpen(false)}
          longTapCallback={() => !isMyProfile && setIsQuickReactionOpen(true)}
          getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
        >
          <div>
            <CardList card={recentPick} isDark={isDark} hideActivity />
          </div>
        </InteractionBlock>
      </StyledRecentPickContainer>
      {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && (
        <ReactionMenu
          isShown={isQuickReactionOpen}
          reactionCoordinates={reactionCoordinates}
          handleClickOutside={() => setIsQuickReactionOpen(false)}
          minusTop={minusTop}
          setIsReactionsModalOpen={setIsReactionsModalOpen}
          setIsQuickReactionOpen={setIsQuickReactionOpen}
        />
      )}
      {isReactionsModalOpen && (
        <ReactionsModal id={recentPick?.id} handleClose={() => setIsReactionsModalOpen(false)} />
      )}
    </ReactionsProvider>
  ) : null;
};

export default ProfileRecentPick;
