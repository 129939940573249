import { styled } from 'linaria/react';
import Button from 'components/UI/Button/styled';
import { COLORS } from 'styles/constants';

export const StyledFilterBtns = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 24px;
`;

export const StyledFilterBtn = styled(Button)<{ isDark: boolean }>`
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.white.off[100])};
  display: flex;
  align-items: center;
  padding: 12px 20px 12px 18px;

  svg {
    margin-right: 10px;
  }

  path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;
