import useToast from 'helpers/useToast';

import { EMPTY_ITEMS, EQueryFetchPolicy, SHOW_FETCH_MORE_LOADER } from 'constants/common';
import {
  GetAllChatsDocument,
  GetBlockedUsersQuery,
  GetSingleChatDocument,
  useBlockUserMutation,
  useGetBlockedUsersQuery,
  useUnblockUserMutation,
} from 'constants/graphqlTypes';
import getAuthUser from 'helpers/getAuthUser';
import { IUseBlockedUsers, IUseBlockUser } from './models';
import { deleteBlockedUserFromCache, deleteUserFromConnections } from './helpers';

export const useBlockUser = (blockedId: string, chatId?: string, onCompleted?: () => void): IUseBlockUser => {
  const { userId } = getAuthUser();
  const { setToast } = useToast();
  const [blockUserMutation, { loading: blockMutationLoading }] = useBlockUserMutation({
    update: (cache) => deleteUserFromConnections(blockedId, userId, cache),
    refetchQueries: [
      { query: GetAllChatsDocument, variables: { offset: 0 } },
      { query: GetSingleChatDocument, variables: { id: chatId } },
    ],
    onCompleted: () =>
      setTimeout(() => {
        onCompleted?.();
      }, 1000),
  });
  const [unblockUserMutation, { loading: unblockMutationLoading }] = useUnblockUserMutation({
    update: (cache, { data }) => {
      const { isBlockedByMe } = data?.unblockUser ?? {};
      deleteBlockedUserFromCache(blockedId, cache);
      if (chatId) {
        cache.modify({
          id: cache.identify({ __typename: 'SingleChatSchema', id: chatId }),
          fields: {
            isBlockedByMe: () => isBlockedByMe,
          },
        });
      }
    },
    onCompleted,
  });

  const blockUser = () =>
    blockUserMutation({ variables: { blockedId } })
      .then(() => setToast?.({ isToastOpen: true, toastItemName: 'User blocked' }))
      .catch(({ message }) => setToast({ isToastOpen: true, toastItemName: message, toastError: true }));

  const unblockUser = () => unblockUserMutation({ variables: { blockedId } });

  return { blockUser, unblockUser, loading: blockMutationLoading || unblockMutationLoading };
};

const mergeUsers = (prev: GetBlockedUsersQuery, { fetchMoreResult }: { fetchMoreResult?: GetBlockedUsersQuery }) => ({
  ...prev,
  ...(prev.allUsers && {
    allUsers: {
      ...prev.allUsers,
      items: [...(prev.allUsers?.items ?? []), ...(fetchMoreResult?.allUsers?.items ?? [])],
    },
  }),
});

export const useBlockedUsers = (): IUseBlockedUsers => {
  const { data, loading, fetchMore } = useGetBlockedUsersQuery({
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.allUsers ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: mergeUsers,
    });

  return { items, loading, loadMore };
};
