import React, { FC, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import getAuthUser from 'helpers/getAuthUser';
import useOpenModal from 'helpers/useOpenModal';
import usePageType from 'helpers/usePageType';

import { StyledQuickReactionsButtonWrap } from 'components/Thoughts/Thought/styled';
import ReactionQuickButton from 'components/Reactions/ReactionQuickButton';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import { useIsUserAllowedToReact } from 'components/Card/helpers/hooks';
import ReactionsToken from 'components/Reactions/ReactionsToken';
import ReactionsModal from 'components/Reactions/ReactionsModal/ReactionsModal';

import { UserObjectType } from 'constants/graphqlTypes';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_LIST_EDIT_DESCRIPTION, ROUTE_USER_PROFILE } from 'routes';

import InteractiveReactionMenu from 'components/Reactions/InteractiveReactionMenu';
import UserDescription from './UserDescription';
import UserAvatar from './UserAvatar';
import {
  StyledInfo,
  StyledUserInfo,
  StyledPlaceholder,
  StyledThought,
  StyledWrap,
  StyledContend,
  StyledReactionsWrap,
} from './styled';

interface IListDescription {
  listCardId?: string;
  description?: string | null;
  isDark: boolean;
  user: UserObjectType;
  isDisabled?: boolean;
}

const ListDescription: FC<IListDescription> = ({ listCardId, user, isDark, description, isDisabled }) => {
  const { listId } = useParams<{ listId: string }>();
  const [isReactionButtonMenuShown, setIsReactionButtonMenuShown] = useState(false);

  const { userId } = getAuthUser();
  const { isAllPicks } = usePageType();
  const { id, username } = user;
  const isMyList = userId === id;
  const isUserAllowedToReact = useIsUserAllowedToReact(user);

  const showReactionButton = !isMyList && isUserAllowedToReact;

  const { setIsReactionsModalOpen, isReactionsModalOpen } = useReactionMenu();

  const toggle = useOpenModal(transformRoute(ROUTE_LIST_EDIT_DESCRIPTION, { listId }), false, {
    description: description ?? '',
    isSingleModal: true,
    listCardId,
  });

  const contentIds = { listId };

  return (
    <StyledWrap>
      <InteractiveReactionMenu entityId={listId} isReactionDisabled={!showReactionButton}>
        <StyledContend>
          {user && <UserAvatar user={user} isDisabled={isDisabled} />}
          <StyledInfo isDark={isDark}>
            {isDisabled ? (
              <div className={StyledUserInfo}>
                <UserDescription user={user} />
              </div>
            ) : (
              <Link className={StyledUserInfo} to={transformRoute(ROUTE_USER_PROFILE, { profileName: username })}>
                <UserDescription user={user} />
              </Link>
            )}
            {!isAllPicks && (
              <>
                {description && <StyledThought isDark={isDark}>&quot;{description}&quot;</StyledThought>}
                {!description && isMyList && (
                  <StyledPlaceholder isDark={isDark} onClick={() => toggle?.()}>
                    Tap to add a thought to your list and let others know what you think
                  </StyledPlaceholder>
                )}
              </>
            )}
          </StyledInfo>
        </StyledContend>
      </InteractiveReactionMenu>
      {listId && (
        <StyledReactionsWrap isMyList={!showReactionButton}>
          <StyledQuickReactionsButtonWrap hasNoReactionButton={!showReactionButton}>
            {showReactionButton && (
              <ReactionQuickButton
                isReactionButtonMenuShown={isReactionButtonMenuShown}
                setIsReactionButtonMenuShown={setIsReactionButtonMenuShown}
                isDark={isDark}
                entityId={listId}
                setIsReactionsModalOpen={setIsReactionsModalOpen}
              />
            )}
            <ReactionsToken contentIds={contentIds} isDark={isDark} />
          </StyledQuickReactionsButtonWrap>
        </StyledReactionsWrap>
      )}
      {isReactionsModalOpen && <ReactionsModal id={listId} handleClose={() => setIsReactionsModalOpen(false)} />}
    </StyledWrap>
  );
};

export default ListDescription;
