import React, { Dispatch, FC, SetStateAction } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import { StyledItem, StyledCardsContainer } from './styled';

interface IProfileSliderProps {
  items: {
    bgColor: {
      start: string;
      end: string;
    };
    imgUrl: string;
  }[];
  setIndex: Dispatch<SetStateAction<number>>;
}

const ProfileSlider: FC<IProfileSliderProps> = ({ items, setIndex }) => {
  return (
    <StyledCardsContainer>
      <Swiper
        allowTouchMove={false}
        speed={400}
        onSlideChange={({ realIndex }) => setIndex(realIndex)}
        direction="vertical"
        mousewheel={{ sensitivity: 0.6, thresholdTime: 100 }}
        modules={[Autoplay]}
        loop
        autoplay={{ delay: 1500, disableOnInteraction: false }}
      >
        {items.map((item) => (
          <SwiperSlide key={item.imgUrl}>
            <StyledItem>
              <img src={item.imgUrl} alt="" width={252} />
            </StyledItem>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledCardsContainer>
  );
};

export default ProfileSlider;
