import { styled } from 'linaria/react';
import { COLORS, FONTS } from '../../../../styles/constants';

export const StyledInfo = styled.article<{ isDark?: boolean }>`
  font-weight: 400;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};

  p {
    margin: 0;
    padding-bottom: 5px;

    &:before {
      content: ' · ';
      font-weight: 800;
      padding: 0 7px;
    }
  }

  > span {
    display: block;
    padding-bottom: 5px;
  }
`;

export const StyledContactInfo = styled.div<{ isDark?: boolean }>`
  padding-top: 27px;
  padding-bottom: 142px;

  span {
    display: block;
  }
`;

export const StyledText = styled.span<{ isBold?: boolean; isDark?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? '600' : '400')};

  a {
    color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;
