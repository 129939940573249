import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import { StyledTitle as StyledListTitle } from '../List/Content/styled';

import { BREAKPOINTS, COLORS, FONTS } from '../../styles/constants';

export const StyledTitle = styled(StyledListTitle)<{ removePaddingTop?: boolean; isSmall?: boolean }>`
  display: flex;
  font-size: ${({ isSmall }) => `${isSmall ? 16 : 24}px`};
  line-height: 130%;
  padding: ${({ isSmall, removePaddingTop }) => (isSmall ? '8px 0' : `${removePaddingTop ? 0 : '32px'} 0 17px 13px`)};
  max-width: 100%;
  white-space: nowrap;
  word-wrap: unset;

  &::after {
    top: 0;
  }
`;

export const StyledTitleTextWrap = styled.span`
  max-width: calc(100% - 24px);
  white-space: normal;
`;

export const StyledCreatorsWrap = styled.div`
  padding: 0 0 16px;
`;

export const StyledCreators = styled.div<{ alignToTop?: boolean }>`
  display: flex;
  align-items: ${({ alignToTop }) => (alignToTop ? 'start' : 'center')};
`;

export const StyledCreatorsAvatars = styled.div<{ isSmall?: boolean }>`
  padding: 0 8px 0 ${({ isSmall }) => `${isSmall ? 8 : 35}px`};
`;

export const StyledCreatorsInfo = styled.div<{ circlesCount: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ circlesCount }) => `calc(100% - ${circlesCount * 24 + 67}px`};
`;

export const StyledCreatorsText = styled.span<{ isBold?: boolean; isDark: boolean }>`
  font: ${({ isBold }) => (isBold ? 600 : 400)} 14px/140% ${FONTS.secondary};
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
`;

export const StyledBody = styled.div<{ bgColor: string }>`
  align-items: center;
  aspect-ratio: 1;
  background: ${({ bgColor }) => bgColor};
  border: 1px solid ${({ bgColor }) => (bgColor.length ? '' : COLORS.white[30])};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const StyledImagesList = styled.ul<{ isMultiple: boolean }>`
  display: grid;
  gap: 5%;
  grid-template-columns: 1fr ${({ isMultiple }) => (isMultiple ? '1fr' : '')};
  list-style: none;
  margin: 0;
  overflow: hidden;
  width: 68%;
  height: 68%;
`;

export const StyledImageItem = styled.li<{ src?: string | null }>`
  background-image: ${({ src }) => `url(${src ?? ''})`};
  background-position: center;
  border-radius: 8px;
  background-size: cover;
  overflow: hidden;
  position: relative;
`;

export const StyledTextsList = styled.ul`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  height: 86.9%;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding-top: 6.55%;
  width: 86.9%;
`;

export const StyledTextItem = styled.li<{ opacity: number; color: string; fontSize?: number }>`
  color: ${({ color }) => color};
  font-size: 22px;
  line-height: 130%;
  opacity: ${({ opacity }) => opacity};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    font-size: ${({ fontSize }) => `${fontSize ?? 32}px`};
  }
`;

export const StyledMetadata = styled.div`
  display: flex;
  gap: 16px;
  padding: 18px 12px 0;
`;

export const StyledWrap = styled.div<{ disableLinks?: boolean; isDark?: boolean; isNewInFeed?: boolean }>`
  position: relative;

  a,
  .card,
  .card-link {
    pointer-events: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
    user-select: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
  }

  &::after {
    align-items: center;
    background: ${COLORS.green.light};
    border-radius: 50%;
    border: ${({ isDark }) => `2px solid ${isDark ? COLORS.black[100] : COLORS.white[100]}`};
    color: ${COLORS.brown.dark[100]};
    content: '';
    display: ${({ isNewInFeed }) => (isNewInFeed ? 'flex' : 'none')};
    font: 400 12px/140% ${FONTS.secondary};
    height: 22px;
    justify-content: center;
    position: absolute;
    right: -5px;
    top: -5px;
    width: 22px;
    z-index: 1;
  }
`;

export const StyledBio = styled.span`
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
`;
