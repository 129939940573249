import { styled } from 'linaria/lib/react';
import { BREAKPOINTS } from 'styles/constants';

export const StyledTitle = styled.p`
  display: block;
  font-size: 22px;
  margin: 0;

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(22px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledWrap = styled.div`
  padding: 90px 12px 80px;
  margin: 0 auto;
  max-width: 528px;
`;

export const StyledListItem = styled.div`
  padding: 6px;
`;

export const StyledHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 12px 35.5px;
`;
