import cardCover1 from 'assets/images/onboarding/success/cardCover1.webp';
import cardThought1 from 'assets/images/onboarding/success/cardThought1.webp';
import cardCover2 from 'assets/images/onboarding/success/cardCover2.webp';
import cardThought2 from 'assets/images/onboarding/success/cardThought2.webp';
import cardCover3 from 'assets/images/onboarding/success/cardCover3.webp';
import cardThought3 from 'assets/images/onboarding/success/cardThought3.webp';
import listCover1_1 from 'assets/images/onboarding/success/listCover1_1.webp';
import listCover1_2 from 'assets/images/onboarding/success/listCover1_2.webp';
import listCover1_3 from 'assets/images/onboarding/success/listCover1_3.webp';
import listCover1_4 from 'assets/images/onboarding/success/listCover1_4.webp';
import listCover2_1 from 'assets/images/onboarding/success/listCover2_1.webp';
import listCover2_2 from 'assets/images/onboarding/success/listCover2_2.webp';
import listCover2_3 from 'assets/images/onboarding/success/listCover2_3.webp';
import listCover2_4 from 'assets/images/onboarding/success/listCover2_4.webp';
import listCover3_1 from 'assets/images/onboarding/success/listCover3_1.webp';
import listCover3_2 from 'assets/images/onboarding/success/listCover3_2.webp';
import listCover3_3 from 'assets/images/onboarding/success/listCover3_3.webp';
import listCover3_4 from 'assets/images/onboarding/success/listCover3_4.webp';
import user1 from 'assets/images/onboarding/success/user1.webp';
import user2 from 'assets/images/onboarding/success/user2.webp';
import user3 from 'assets/images/onboarding/success/user3.webp';
import { UserObjectType } from 'constants/graphqlTypes';

export const BG_COLORS = ['#1F63C9', '#D16F14', '#84AB5C'];

export const HEADINGS = [
  ['Share what you love,', 'and add your thoughts', 'ㅤ'],
  ['Follow experts and', 'friends to see what', 'they’re excited about'],
  ['Quickly discover what’s cutting-edge, tailored to', 'your interests'],
];

export const CARDS = [
  {
    card: {
      mediaTag: 'Music',
      customTitle: 'Jubilee',
      imgUrl: cardCover1,
    },
    thought: { avatar: cardThought1, text: '“Tipping this for a Grammy”' },
  },
  {
    card: {
      mediaTag: 'Podcasts',
      customTitle: 'The Sunday Read: ‘Inside the PushNotifications to Diversify the Book Business’',
      imgUrl: cardCover2,
    },
    thought: { avatar: cardThought2, text: '“I did not expect this to change my mind”' },
  },
  {
    card: {
      mediaTag: 'Editorial',
      customTitle: 'The things you must see when visiting Japan',
      imgUrl: cardCover3,
    },
    thought: { avatar: cardThought3, text: '“So many hidden gems in here for art lovers”' },
  },
];

const MOCK_ID = 'mock';

export const USERS: UserObjectType[] = [
  {
    id: MOCK_ID,
    username: 'sandra',
    cardId: 'cardId',
    userInfo: {
      firstName: 'Sandra',
      lastName: 'Goldstein',
      bio: 'Makeup Artist',
    },
    pickedCards: { totalCount: 2483, items: [] },
    userCounters: { connectionsCount: 145 },
    userStyle: {
      id: MOCK_ID,
      thumbnailImageSet: {
        id: MOCK_ID,
        imageId: MOCK_ID,
        images: [{ url: user1, id: MOCK_ID }],
      },
    },
    __typename: 'UserObjectType',
  },
  {
    id: MOCK_ID,
    username: 'weskamp',
    cardId: 'cardId',
    userInfo: {
      firstName: 'Marcos',
      lastName: 'Weskamp',
      bio: 'Art director at a start-up',
    },
    pickedCards: { totalCount: 589, items: [] },
    userCounters: { connectionsCount: 97 },
    userStyle: {
      id: MOCK_ID,
      thumbnailImageSet: {
        id: MOCK_ID,
        imageId: MOCK_ID,
        images: [{ url: user2, id: MOCK_ID }],
      },
    },
    __typename: 'UserObjectType',
  },
  {
    id: MOCK_ID,
    username: 'tomdever',
    cardId: 'cardId',
    userInfo: {
      firstName: 'Thomas',
      lastName: 'Dever',
      bio: 'Astrologist',
    },
    pickedCards: { totalCount: 483, items: [] },
    userCounters: { connectionsCount: 34 },
    userStyle: {
      id: MOCK_ID,
      thumbnailImageSet: {
        id: MOCK_ID,
        imageId: MOCK_ID,
        images: [{ url: user3, id: MOCK_ID }],
      },
    },
    __typename: 'UserObjectType',
  },
];

export const LISTS = [
  {
    title: 'Fashion',
    cover: [listCover1_1, listCover1_2, listCover1_3, listCover1_4],
  },
  {
    title: 'Food',
    cover: [listCover2_1, listCover2_2, listCover2_3, listCover2_4],
  },
  {
    title: 'Music',
    cover: [listCover3_1, listCover3_2, listCover3_3, listCover3_4],
  },
];
