import React, { Dispatch, FC, SetStateAction } from 'react';

import {
  DndContext,
  closestCenter,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Waypoint } from 'react-waypoint';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';

import { ChannelAdminFeedSchema, ChannelCustomFeedSchema } from 'constants/graphqlTypes';

import LoaderContent from 'components/UI/LoaderContent';
import { StyledContainer } from 'components/ListSettings/EditOrder/DndCardList/styled';
import ListItem from './ListItem';

interface IDndListProps {
  items: (ChannelCustomFeedSchema | ChannelAdminFeedSchema)[];
  loading: boolean;
  setItems: Dispatch<SetStateAction<(ChannelCustomFeedSchema | ChannelAdminFeedSchema)[]>>;
  loadMore?: () => void;
  onDragEnd: (newItems: (ChannelCustomFeedSchema | ChannelAdminFeedSchema)[]) => void;
}

const DndFeedsList: FC<IDndListProps> = ({ items, loading, setItems, loadMore, onDragEnd }) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((prev) => {
        const oldIndex = prev.findIndex(({ id }) => id === active?.id);
        const newIndex = prev.findIndex(({ id }) => id === over?.id);

        const newItems = arrayMove(prev, oldIndex, newIndex);
        onDragEnd(newItems);

        return newItems;
      });
    }
  };

  return (
    <StyledContainer>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((feed) => (
            <ListItem key={feed.id} feed={feed} id={feed.id} />
          ))}
          {loading ? (
            <LoaderContent isDark isFullScreen={!items.length} />
          ) : (
            <>{items.length && loadMore && <Waypoint onEnter={loadMore} />}</>
          )}
        </SortableContext>
      </DndContext>
    </StyledContainer>
  );
};

export default DndFeedsList;
