import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';

import { useCheckInviteCode } from 'graphQL/auth/hooks';
import { useGetSearchParam } from 'helpers/routingHelper';
import { CODE_LENGTH } from './constants';

interface IUseInviteCode {
  value: string;
  inputRef: RefObject<HTMLInputElement>;
  isErrorShown: boolean;
  isRepeatedErrorShown: boolean;
  handleSend: (value: string) => void;
  setValue: Dispatch<SetStateAction<string>>;
}

export const useInviteCode = (): IUseInviteCode => {
  const [value, setValue] = useState('');

  const { checkInvite, isErrorShown, isRepeatedErrorShown } = useCheckInviteCode({
    onError: () => setValue(''),
  });

  const code = useGetSearchParam('code')?.toLocaleUpperCase();

  useEffect(() => {
    if (code) {
      setValue(code);
      checkInvite(code);
    }
  }, [code]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onPaste = (e: ClipboardEvent) => {
    e.preventDefault();
    const data = e.clipboardData?.getData('text/plain');
    const pasteCode = data?.slice(0, CODE_LENGTH).toUpperCase();
    if (pasteCode) {
      setValue(pasteCode);
      if (pasteCode.length === CODE_LENGTH) {
        checkInvite(pasteCode);
      }
    }
  };

  useEffect(() => {
    inputRef.current?.addEventListener('paste', onPaste);

    return () => {
      inputRef.current?.removeEventListener('paste', onPaste);
    };
  }, []);

  return {
    value,
    setValue,
    handleSend: checkInvite,
    inputRef,
    isErrorShown,
    isRepeatedErrorShown,
  };
};
