import React, { FC, useEffect, useRef, useState } from 'react';

import useDisableScroll from 'helpers/useDisableScroll';

import { useDeleteMessage } from 'graphQL/messages/hooks';
import { createPortal } from 'react-dom';
import { getMenuCoords } from './helpers';
import { IGetMenuCoords } from './models';
import { StyledBlurArea, StyledMenu, StyledMenuItem, StyledAbsWrap, StyledBlurWrap } from './styled';

interface IContextMenuProps {
  id: string;
  text?: string | null;
  isDark: boolean;
  isMine: boolean;
  messageCoords: DOMRect;
  handleHide: () => void;
  shouldDisableScroll: boolean;
}

const ContextMenu: FC<IContextMenuProps> = ({
  id,
  text,
  isMine,
  isDark,
  messageCoords,
  shouldDisableScroll,
  handleHide,
}) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);
  const [coords, setCoords] = useState<IGetMenuCoords>({});

  useDisableScroll(!shouldDisableScroll);

  const deleteMessage = useDeleteMessage(id, handleHide);

  const copyMessage = () => {
    if (text) {
      navigator.clipboard?.writeText(text);
    }
    handleHide();
  };

  useEffect(() => {
    document.querySelector('.navigation')?.classList.add('hidden');
    document.querySelector('.input')?.classList.add('hidden');

    setCoords(getMenuCoords(messageCoords, isMine));

    return () => {
      document.querySelector('.navigation')?.classList.remove('hidden');
      document.querySelector('.input')?.classList.remove('hidden');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const portalContainer = document.getElementById('modal-root');

  const threadWrapPortalContainer = document.getElementById('thread-wrap');

  const threadContainer = document.getElementById('thread');

  return (
    <StyledAbsWrap onClick={handleHide}>
      {portalContainer &&
        createPortal(
          <div ref={wrapRef} onClick={(e) => e.target === wrapRef.current && handleHide()}>
            {(isMine || !!text) && (
              <StyledMenu ref={menuRef} isDark={isDark} isMine={isMine} coords={coords}>
                {text && <StyledMenuItem onClick={copyMessage}>Copy</StyledMenuItem>}
                {isMine && (
                  <StyledMenuItem onClick={deleteMessage} isRed>
                    Delete
                  </StyledMenuItem>
                )}
              </StyledMenu>
            )}
          </div>,
          portalContainer
        )}
      {threadContainer && createPortal(<StyledBlurArea isDark={isDark} />, threadContainer)}
      {threadWrapPortalContainer && createPortal(<StyledBlurWrap isDark={isDark} />, threadWrapPortalContainer)}
    </StyledAbsWrap>
  );
};

export default ContextMenu;
