import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { IAdminCustomFeedFilters } from 'providers/helpers/models';

export const AdminFeedFiltersContext = createContext({});

export const { Provider: AdminFeedFiltersProvider } = AdminFeedFiltersContext;

interface IUseAdminFiltersContext {
  newAdminFeedFilters?: IAdminCustomFeedFilters[];
  setNewAdminFeedFilters?: Dispatch<SetStateAction<IAdminCustomFeedFilters[]>>;
}

export const useAdminFeedFiltersContext = (): IUseAdminFiltersContext => {
  const context = useContext<IUseAdminFiltersContext>(AdminFeedFiltersContext);

  return context;
};

export const useAdminFeedFiltersContextState = () => {
  const [newAdminFeedFilters, setNewAdminFeedFilters] = useState<IAdminCustomFeedFilters[]>([]);

  return {
    newAdminFeedFilters,
    setNewAdminFeedFilters,
  };
};

interface IAddNewAdminFeedFilters {
  newIncluded: string[];
  newExcluded: string[];
  feedId: string;
  setNewAdminFeedFilters?: Dispatch<SetStateAction<IAdminCustomFeedFilters[]>>;
}

export const addNewAdminFeedFilters = ({
  newIncluded,
  newExcluded,
  feedId,
  setNewAdminFeedFilters,
}: IAddNewAdminFeedFilters): void => {
  setNewAdminFeedFilters?.((prev) => {
    const thisFeedFilterInState = prev.find((filter) => filter.feedId === feedId);
    const withoutFilter = prev.filter((filter) => filter !== thisFeedFilterInState);

    return thisFeedFilterInState
      ? [...withoutFilter, { excluded: newExcluded, included: newIncluded, feedId }]
      : [...prev, { excluded: newExcluded, included: newIncluded, feedId }];
  });
};
