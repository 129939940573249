import { createContext, useContext } from 'react';
import * as PusherPushNotifications from '@pusher/push-notifications-web';

import config from 'constants/config';
import getAuthUser from 'helpers/getAuthUser';

const PusherContext = createContext<PusherPushNotifications.Client | null>(null);
export const { Provider: PusherProvider } = PusherContext;

const useContextPusher = () => {
  const beamsClient = useContext(PusherContext);

  const startPusher = () =>
    beamsClient
      ?.start()
      .then(() =>
        beamsClient.setUserId(
          getAuthUser().userId,
          new PusherPushNotifications.TokenProvider({
            url: `${config.baseApiUrl}api/v1/auth/beams-auth`,
            headers: { Authorization: `Bearer ${getAuthUser().token}` },
          })
        )
      )
      // eslint-disable-next-line no-console
      .then(() => console.log('User ID has been set'));

  const checkCurrentPusherSession = () =>
    beamsClient?.getUserId().then((userId) => {
      if (userId && userId !== getAuthUser().userId) {
        return beamsClient.stop();
      }

      if (!userId && !!getAuthUser().userId) {
        startPusher();
      }

      return null;
    });

  const stopPusher = () => beamsClient?.stop();

  return { beamsClient, startPusher, checkCurrentPusherSession, stopPusher };
};

export default useContextPusher;
