import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

export const StyledInputWrapper = styled.div<{ isDark: boolean }>`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding-left: 5px;

  &::before {
    content: ':';
    display: block;
    margin-left: -5px;
    color: ${({ isDark }) => (isDark ? COLORS.brown.light[100] : COLORS.brown.dark[100])};
  }
`;
