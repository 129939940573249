import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_SAVED_PICKS } from 'routes';

import Button from 'components/UI/MenuButton';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { useMenuModal } from 'components/Navigation/helpers/hooks';
import { CardSchema } from 'constants/graphqlTypes';
import { StyledItem } from 'components/AddContent/Menu/styled';

import { ReactComponent as TrashIcon } from 'assets/icons/TrashCan.svg';
import { useDeleteSavedPicks } from 'graphQL/savedPicks/hooks';
import useToast from 'helpers/useToast';

import MenuWrap from './MenuWrap';

const SavedPicksButtons: FC<{ card: CardSchema }> = ({ card }) => {
  const { isModalOpen, handleModal } = useMenuModal();
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const { setToast } = useToast();
  const { replace } = useHistory();

  const { cardId } = card;
  const onComplete = () => {
    replace(ROUTE_SAVED_PICKS);
    setToast({
      isToastOpen: true,
      toastItemName: 'Pick deleted',
    });
  };

  const { deleteSavedPicks, loading } = useDeleteSavedPicks([cardId], onComplete);

  const handleDelete = () => {
    deleteSavedPicks();
    handleModal(false);
  };

  return (
    <MenuWrap isOpen={isModalOpen} handleModal={handleModal}>
      <StyledItem hideBorder>
        <Button isDark text="Delete" Icon={TrashIcon} handler={() => setIsConfirmationModal(true)} disabled={loading} />
      </StyledItem>
      {isConfirmationModal && (
        <ConfirmationModal
          handleCancel={() => setIsConfirmationModal(false)}
          handleAccept={handleDelete}
          buttonText="Delete pick"
          titleText="delete saved picks"
        />
      )}
    </MenuWrap>
  );
};

export default SavedPicksButtons;
