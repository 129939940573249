import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as ThoughtIcon } from 'assets/icons/Thought.svg';
import { ReactComponent as ImmersiveIcon } from 'assets/icons/Immersive.svg';
import { ReactComponent as OverviewIcon } from 'assets/icons/Overview.svg';
import Button from 'components/UI/MenuButton';
import useOpenModal from 'helpers/useOpenModal';
import { ROUTE_EDIT_FEED } from 'routes';
import { useGetChannelSectionItem, useUpdateItemView } from 'graphQL/feeds/hooks';
import { CardTypeOptions, EViewTypeOptions } from 'constants/graphqlTypes';
import useToast from 'helpers/useToast';

import { StyledActions } from 'components/ListSettings/EditList/styled';
import { StyledItem } from 'components/AddContent/Menu/styled';
import { StyledItem as StyledItemWithCheck } from 'components/List/ChangeLayout/styled';
import { StyledLabel } from 'components/ProfileSettings/EditProfile/styled';
import MenuWrap from './MenuWrap';
import { useMenuModal } from '../helpers/hooks';

const FeedDetailButton: FC = () => {
  const { isModalOpen, handleModal } = useMenuModal();
  const { feedId } = useParams<{ feedId: string }>();
  const { item } = useGetChannelSectionItem(feedId) ?? {};
  const { title, type } = item ?? {};

  const [currentLayout, setCurrentLayout] = useState<EViewTypeOptions>(EViewTypeOptions.Immersive);
  const { setToast } = useToast();

  const gotoEditFeed = useOpenModal(ROUTE_EDIT_FEED, false, { feedId, title });

  const updateFeedViewMutation = useUpdateItemView(feedId, currentLayout);

  const handleUpdateView = (layout: EViewTypeOptions) => {
    if (!feedId || layout === currentLayout) {
      return;
    }
    updateFeedViewMutation({ variables: { itemId: feedId, viewType: layout } })
      .then(() => {
        setCurrentLayout(layout);
        handleModal(false);
      })
      .catch(({ message }) => setToast({ isToastOpen: true, toastItemName: message, toastError: true }));
  };

  const handleEditFeed = () => {
    handleModal(false);
    gotoEditFeed();
  };

  useEffect(() => {
    if (item?.cardStyle?.viewType) {
      setCurrentLayout(item?.cardStyle?.viewType);
    }
  }, [item]);

  return (
    <MenuWrap isOpen={isModalOpen} handleModal={handleModal}>
      <StyledActions>
        <StyledLabel>Change view</StyledLabel>
        <StyledItemWithCheck isSelected={currentLayout === EViewTypeOptions.Immersive}>
          <Button text="Immersive" Icon={ImmersiveIcon} handler={() => handleUpdateView(EViewTypeOptions.Immersive)} />
        </StyledItemWithCheck>
        <StyledItemWithCheck isSelected={currentLayout === EViewTypeOptions.Overview}>
          <Button text="Overview" Icon={OverviewIcon} handler={() => handleUpdateView(EViewTypeOptions.Overview)} />
        </StyledItemWithCheck>
      </StyledActions>
      {type === CardTypeOptions.Feed && (
        <StyledActions>
          <StyledLabel>Actions</StyledLabel>
          <StyledItem>
            <Button text="Edit feed" Icon={ThoughtIcon} handler={handleEditFeed} />
          </StyledItem>
        </StyledActions>
      )}
    </MenuWrap>
  );
};

export default FeedDetailButton;
