import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_ACCOUNT_MANAGEMENT_DETAILS, ROUTE_ACCOUNT_MANAGEMENT_TOPICS } from 'routes';

import useTheme from 'helpers/useTheme';

import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import { StyledList } from 'pages/Settings/styled';

import { StyledItem } from 'components/AddContent/Menu/styled';
import SinglePageLayout from 'components/SinglePageLayout';
import MenuButton from 'components/UI/MenuButton';
import {
  TEST_ACCOUNT_MANAGEMENT_TOPICS,
  TEST_ACCOUNT_MANAGEMENT_DETAILS,
  TEST_ID_ACCOUNT_MANAGEMENT_START,
} from 'constants/aqa/settings/accountManagement';

const AccountManagement: FC = () => {
  const isDark = useTheme();
  const { push } = useHistory();

  return (
    <SinglePageLayout pageTestId={TEST_ID_ACCOUNT_MANAGEMENT_START} title="Account management">
      <StyledList isDark={isDark}>
        <StyledItem>
          <MenuButton
            dataTestId={TEST_ACCOUNT_MANAGEMENT_DETAILS}
            text="Account details"
            handler={() => push(ROUTE_ACCOUNT_MANAGEMENT_DETAILS)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
          />
        </StyledItem>
        <StyledItem>
          <MenuButton
            dataTestId={TEST_ACCOUNT_MANAGEMENT_TOPICS}
            text="Adjust topics"
            handler={() => push(ROUTE_ACCOUNT_MANAGEMENT_TOPICS)}
            isDark={isDark}
            Icon={ChevronLeft}
            isReversed
          />
        </StyledItem>
      </StyledList>
    </SinglePageLayout>
  );
};

export default AccountManagement;
