import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

export const StyledWrapper = styled.div`
  align-items: center;
  color: ${COLORS.white[100]};
  display: flex;
  font-size: 22px;
  justify-content: center;
  height: 100%;
`;
