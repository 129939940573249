import { styled } from 'linaria/react';

import { BREAKPOINTS, FONTS, COLORS } from '../../../styles/constants';

export const StyledIcon = styled.div<{
  isRed?: boolean;
  hasStroke?: boolean;
  isDark?: boolean;
  hasBorder?: boolean;
  hasBackground?: boolean;
}>`
  align-items: center;
  background-color: ${({ hasBackground, isDark }) => {
    if (!hasBackground) {
      return COLORS.transparent;
    }
    return isDark ? COLORS.white[10] : COLORS.white.off[100];
  }};
  border: ${({ hasBorder, isDark = true }) =>
    hasBorder ? `1px solid ${isDark ? COLORS.white[30] : COLORS.brown.dark[10]}` : `1px solid ${COLORS.transparent}`};
  border-radius: 50%;
  display: flex;
  font-family: ${FONTS.secondary};
  justify-content: center;
  height: 44px;
  min-width: 44px;

  path {
    fill: ${({ isRed, hasStroke, isDark }) => {
      if (hasStroke) {
        return 'none';
      }
      if (isRed) {
        return COLORS.red[100];
      }
      return isDark ? COLORS.white[100] : COLORS.brown.dark[100];
    }};
    stroke: ${({ hasStroke, isDark, isRed }) => {
      if (!hasStroke) {
        return 'none';
      }

      if (isRed) {
        return COLORS.red[100];
      }
      return isDark ? COLORS.white[100] : COLORS.brown.dark[100];
    }};
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    height: calc(44px * ${BREAKPOINTS.retinaScale});
    margin-right: calc(12px * ${BREAKPOINTS.retinaScale});
    width: calc(44px * ${BREAKPOINTS.retinaScale});

    svg {
      transform: scale(${BREAKPOINTS.retinaScale});
    }
  }
`;
