import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';

import { CardTypeOptions, EDiscoverSlotHeadTypeOptions } from 'constants/graphqlTypes';
import { useFollowingItems } from 'graphQL/cards/exploreCards/hooks';
import Heading from '../Heading';
import { getKey } from './helpers';
import TabItem from './TabItem';

import { StyledContent, StyledSection } from '../styled';

const FollowingTab: FC = () => {
  const { items, loading, loadMore, shouldLoadPrevDay, loadPrevDay } = useFollowingItems();

  const isSuggestedUserFirst =
    items?.[0]?.__typename === 'CardSchema' && items?.[0].type === CardTypeOptions.SuggestedUsers;

  return items ? (
    <>
      <StyledSection>
        {!loading && (
          <Heading
            section={{
              headType: EDiscoverSlotHeadTypeOptions.Simple,
              title: isSuggestedUserFirst ? 'Follow people that inspire you' : 'Latest picks from everyone you follow',
            }}
          />
        )}
        <StyledContent>
          {items?.map((item) => (
            <TabItem key={getKey(item)} currentItem={item} items={items} />
          ))}
        </StyledContent>
      </StyledSection>
      {!loading && <Waypoint onEnter={shouldLoadPrevDay ? loadPrevDay : loadMore} />}
    </>
  ) : null;
};

export default FollowingTab;
