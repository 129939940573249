import { ESearchTag } from 'components/Search/helpers/constants';
import { USERNAME_CHARS } from '../../Authentication/constants';

const { User, Time, Type } = ESearchTag;
const USER_TIME_TYPE_GROUP = `(${User}|${Time}|${Type})`;

export const INPUT_REGEX = new RegExp(`^${USER_TIME_TYPE_GROUP}.+$`);
export const LAST_SEARCH_TAG_REGEX = new RegExp(`${USER_TIME_TYPE_GROUP}$`, 'g');

const USER_GROUP = `(?:${User}|@)`;

export const USER_SEARCH_TEXT = new RegExp(`${USER_GROUP}([\\w${USERNAME_CHARS}]+)$`);
export const FIND_USER_REGEX = new RegExp(`${USER_GROUP}([\\w${USERNAME_CHARS}]{2,})$`);
