import { styled } from 'linaria/react';

import { COLORS } from '../../../styles/constants';

export const StyledFooter = styled.footer`
  align-items: center;
  background-color: ${COLORS.brown.dark[100]};
  border-radius: 20px 20px 0 0;
  border-top: 1px solid ${COLORS.white[30]};
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  left: 0;
  padding: 0 24px calc(24px + var(--keyboard-offset));
  position: absolute;
  transition: 0.2s padding ease-in-out;
  width: 100%;
`;

export const StyledComment = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledDrag = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 100%;

  &::after {
    background-color: ${COLORS.white[30]};
    border-radius: 20px;
    content: '';
    height: 4px;
    width: 56px;
  }
`;

export const StyledCardWrap = styled.div`
  padding-bottom: var(--keyboard-offset);
`;
