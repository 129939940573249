import { styled } from 'linaria/react';
import { COLORS } from 'styles/constants';

export const StyledListItem = styled.li<{ background: string; isActive: boolean }>`
  border-radius: 48px;
  background: ${({ background }) => background} center;
  background-size: cover;
  cursor: pointer;
  height: 48px;
  min-width: 48px;
  position: relative;
  width: 48px;

  &:after {
    content: ' ';
    border: 1px solid ${({ background, isActive }) => background && (isActive ? COLORS.white[100] : COLORS.white[30])};
    border-radius: 50%;
    height: 48px;
    position: absolute;
    top: 0;
    width: 48px;
  }
`;

export const StyledListWrap = styled.div`
  ul {
    gap: 24px;
    padding-bottom: 28px;
  }
`;
