import React, { FC } from 'react';

import { COLORS } from 'styles/constants';
import { CardSchema, HighlightSchema } from 'constants/graphqlTypes';
import { TEST_CARD_FIRST_UP, getCardImageId } from 'constants/aqa/card';
import getImages from 'helpers/getImages';
import { StyledWrap, StyledFooter } from '../Common/styled';

import Body from '../Common/Body';
import Source from '../Common/Source';
import Highlight from '../Common/Highlight';
import Actions from '../Actions';
import QuestionAttribute from '../QuestionAttribute';
import SparkAttribute from '../SparkAttribute/SparkAttribute';

interface IFirstUp {
  card: CardSchema;
  isDark?: boolean;
  highlight?: HighlightSchema | null;
  dataTestId?: string;
}

const FirstUp: FC<IFirstUp> = ({ card, isDark = true, highlight, dataTestId }) => {
  const color = isDark ? COLORS.white[100] : COLORS.brown.dark[100];
  const { responseToQuestion, responseToSpark } = card;
  const { id: questionId, title: questionTitle, questionGuestList } = responseToQuestion ?? {};
  const { imageUrl } = getImages(questionGuestList?.cardStyle);

  return (
    <StyledWrap>
      <Body dataTestId={dataTestId} card={card} isFirstUp />
      <StyledFooter hasPaddings isFirstUp color={color}>
        <Source dataTestId={getCardImageId(dataTestId ?? TEST_CARD_FIRST_UP)} card={card} />
      </StyledFooter>
      <Actions item={card} fontColor={color} />
      {highlight && <Highlight highlight={highlight} isDark={isDark} />}
      {questionId && questionTitle && (
        <QuestionAttribute
          isDark={isDark}
          questionId={questionId}
          text={`Response to ${
            questionGuestList?.title ? `The guest:list with ${questionGuestList?.title}` : 'Question of the Day'
          }`}
          title={questionTitle}
          imageUrl={imageUrl}
        />
      )}
      {responseToSpark && <SparkAttribute spark={responseToSpark} isDark={isDark} />}
    </StyledWrap>
  );
};

export default FirstUp;
