import { useEffect, useRef, useState, RefObject } from 'react';

const useTabsScroll = (currentTab = 0): RefObject<HTMLUListElement & HTMLDivElement> => {
  const elRef = useRef<HTMLUListElement & HTMLDivElement>(null);
  const element = elRef?.current;
  const scrollTop = element?.scrollTop ?? 0;

  const [firstTab, setFirstTab] = useState(0);
  const [secondTab, setSecondTab] = useState(0);

  useEffect(() => {
    if (currentTab === 0) {
      setSecondTab(scrollTop);
      element?.scrollTo(0, firstTab);
    } else {
      setFirstTab(scrollTop);
      element?.scrollTo(0, secondTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return elRef;
};

export default useTabsScroll;
