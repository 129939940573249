import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useDisableScroll from 'helpers/useDisableScroll';
import Modal from 'components/Modal';
import TextInput from 'components/UI/TextInput';
import ContentTitle from 'components/UI/ContentTitle';

import { ROUTE_CHECK_EMAIL, ROUTE_WELCOME } from 'routes';
import { validateEmailRegex, validateLength } from 'components/Authentication/helpers';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import { StyledInputScreenContainer, StyledButtonWrap } from 'components/Authentication/styled';
import Button from 'components/UI/Button';
import { TEST_MAGIC_LINK_CONTINUE_BTN, TEST_MAGIC_LINK_INPUT } from 'constants/aqa/login';
import { EMAIL_LENGTH } from '../constants';

const MagicLink: FC = () => {
  const { replace } = useHistory();
  const location = useLocation();

  const [emailText, setEmailText] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const handleSetText = (value: string) => {
    setEmailText(value.toLocaleLowerCase().trim());
    setIsErrorVisible(false);
  };

  const handleNext = () => {
    if (!emailText || !!emailError) {
      setIsErrorVisible(true);
    } else {
      replace({
        pathname: ROUTE_CHECK_EMAIL,
        state: { ...location.state, variable: { email: emailText } },
      });
    }
  };

  const handleClose = (isPrev?: boolean) =>
    replace({
      pathname: ROUTE_WELCOME,
      state: { ...location.state, variable: { openAuthModal: isPrev } },
    });

  useEffect(() => {
    setEmailError(validateLength(EMAIL_LENGTH, emailText.length));
    setEmailError(validateEmailRegex(emailText));
  }, [emailText]);

  useDisableScroll();

  return (
    <Modal handlePrev={() => handleClose(true)} fadeIn={false} handleClose={handleClose}>
      <StyledInputScreenContainer>
        <ContentTitle isDark>What&apos;s your email address</ContentTitle>
        <TextInput
          dataTestId={TEST_MAGIC_LINK_INPUT}
          placeholder="hello@email.co"
          isDark
          text={emailText}
          setText={(value: string) => handleSetText(value)}
          handleEnter={handleNext}
          error={isErrorVisible && emailError}
          autoCapitalize={EAutoCapitalize.Off}
          longDash={false}
        />
        <StyledButtonWrap>
          <Button
            dataTestId={TEST_MAGIC_LINK_CONTINUE_BTN}
            text="Continue"
            isDisabled={!emailText}
            handler={handleNext}
          />
        </StyledButtonWrap>
      </StyledInputScreenContainer>
    </Modal>
  );
};

export default MagicLink;
