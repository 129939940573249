import { FC } from 'react';

import { ReactComponent as RecentlyUsedIcon } from 'assets/icons/reactions/RecentlyUsed.svg';
import { ReactComponent as SmileysIcon } from 'assets/icons/reactions/Smileys.svg';
import { ReactComponent as AnimalsIcon } from 'assets/icons/reactions/Animals.svg';
import { ReactComponent as FoodIcon } from 'assets/icons/reactions/Food.svg';
import { ReactComponent as ActivityIcon } from 'assets/icons/reactions/Activity.svg';
import { ReactComponent as TravelIcon } from 'assets/icons/reactions/Travel.svg';
import { ReactComponent as ObjectsIcon } from 'assets/icons/reactions/Objects.svg';
import { ReactComponent as SymbolsIcon } from 'assets/icons/reactions/Symbols.svg';
import { ReactComponent as FlagsIcon } from 'assets/icons/reactions/Flags.svg';

export enum EReactionGroupTitles {
  Recent = 'Recent',
  Activity = 'Activity',
  Objects = 'Objects',
  Flags = 'Flags',
  Animals = 'Animals & Nature',
  Smileys = 'Smileys & People',
  Symbols = 'Symbols',
  Food = 'Food & Drink',
  Travel = 'Travel & Places',
}

interface IReactionIcons {
  [key: string]: FC;
}

const reactionIcons: IReactionIcons = {
  [EReactionGroupTitles.Recent]: RecentlyUsedIcon,
  [EReactionGroupTitles.Activity]: ActivityIcon,
  [EReactionGroupTitles.Objects]: ObjectsIcon,
  [EReactionGroupTitles.Flags]: FlagsIcon,
  [EReactionGroupTitles.Animals]: AnimalsIcon,
  [EReactionGroupTitles.Smileys]: SmileysIcon,
  [EReactionGroupTitles.Symbols]: SymbolsIcon,
  [EReactionGroupTitles.Food]: FoodIcon,
  [EReactionGroupTitles.Travel]: TravelIcon,
};

export default reactionIcons;
