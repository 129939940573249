import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

interface IChatProvider {
  shouldPlay1stMessageAnimation?: boolean;
  setShouldPlay1stMessageAnimations?: Dispatch<SetStateAction<boolean>>;
}

const ChatContext = createContext<IChatProvider>({});

export const { Provider: ChatContextProvider } = ChatContext;

export const useMessageChatContext = (): IChatProvider => {
  const context = useContext(ChatContext);

  return context;
};

export const useMessagesChatContextState = (): Required<IChatProvider> => {
  const [shouldPlay1stMessageAnimation, setShouldPlay1stMessageAnimations] = useState(false);

  return { shouldPlay1stMessageAnimation, setShouldPlay1stMessageAnimations };
};
