import { styled } from 'linaria/react';

export const StyledInputWrap = styled.div`
  margin: 20px 0 28px;

  input {
    font-size: 14px;
  }
`;

export const StyledItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  button {
    margin-left: 12px;
    min-width: 81px;
  }
`;
