import React, { FC } from 'react';

import { ECardRoleTypeOptions, UserCardRoleSchema, UserObjectType } from 'constants/graphqlTypes';

import BadgeWithChildren from 'components/UI/BadgeWithChildren';
import FollowButton from 'components/FollowButton';
import RoleSelect from './RoleSelect';

interface ICollaborator {
  authUserId: string;
  creators: UserCardRoleSchema[];
  isUserAViewier?: boolean;
  isUserAnAdmin?: boolean;
  role: ECardRoleTypeOptions;
  user: UserObjectType;
  listUserId?: string | null;
  handleClose: () => void;
}

const Collaborator: FC<ICollaborator> = ({
  isUserAViewier,
  isUserAnAdmin,
  user,
  authUserId,
  handleClose,
  role,
  creators,
  listUserId,
}) => {
  const { id, username } = user;

  return (
    <BadgeWithChildren user={user}>
      {!isUserAViewier ? (
        <RoleSelect
          handleClose={handleClose}
          userId={id}
          username={username}
          isUserAnAdmin={!!isUserAnAdmin}
          isCurrentUser={authUserId === id}
          role={role}
          users={creators}
          listUserId={listUserId}
        />
      ) : (
        <FollowButton isDark user={user} />
      )}
    </BadgeWithChildren>
  );
};

export default Collaborator;
