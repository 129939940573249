import {
  useGetNotificationSettingsQuery,
  useUpdateNotificationSettingsMutation,
  UserNotificationSettingFilter,
  useUpdateStatusUserNotificationsMutation,
  useUpdateCollaborationListMutation,
} from 'constants/graphqlTypes';
import getAuthUser from 'helpers/getAuthUser';
import { getNotifications } from 'graphQL/cardOptions/helpers';

import {
  IUpdateListNotifications,
  IUseGetNotifications,
  IUseUpdateNotificationSettingsData,
  IUseUpdateSpecificUserNotificationsStatus,
  IUseUpdateSpecificUserNotificationStatusData,
} from './models';

export const useNotifications = (filter?: UserNotificationSettingFilter): IUseGetNotifications => {
  const { userId } = getAuthUser();
  const { data } = useGetNotificationSettingsQuery({
    variables: { id: userId, filter },
  });

  const [mutation, { loading }] = useUpdateNotificationSettingsMutation();
  const updateNotificationSetting = (variables: IUseUpdateNotificationSettingsData) => mutation({ variables });
  return {
    notificationSettings: data?.allUsers?.items[0].notificationSettings?.items ?? [],
    updateNotificationSetting,
    loading,
  };
};

export const useUpdateSpecificUserStatus = (
  notificationMessage?: string
): IUseUpdateSpecificUserNotificationsStatus => {
  const [mutation, { loading }] = useUpdateStatusUserNotificationsMutation({
    ...(notificationMessage &&
      getNotifications({
        isToastOpen: true,
        message: notificationMessage,
        linesCount: 2,
      })),
  });

  const updateSpecificUserNotificationsStatus = (variables: IUseUpdateSpecificUserNotificationStatusData) =>
    mutation({ variables });
  return {
    updateSpecificUserNotificationsStatus,
    loading,
  };
};

export const useListNotifications = (notificationMessage?: string): IUpdateListNotifications => {
  const [mutation, { loading }] = useUpdateCollaborationListMutation({
    ...(notificationMessage &&
      getNotifications({
        isToastOpen: true,
        message: notificationMessage,
        linesCount: 2,
      })),
  });

  return {
    updateListNotifications: (collectionPickId: string, isEnable: boolean) =>
      mutation({ variables: { collectionPickId, isEnable } }),
    loading,
  };
};
