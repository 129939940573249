import { styled } from 'linaria/lib/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  .modal {
    padding: 24px 0px;
  }

  .modal--header {
    padding: 0 12px;
  }
`;

export const StyledModalHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${COLORS.white[10]};
  display: flex;
  padding: 20px 24px 20px 36px;
  margin: 0 -24px;

  > span {
    font: 400 14px/140% ${FONTS.secondary};
    padding-left: 8px;
  }
`;

export const StyledTags = styled.div`
  position: sticky;
  z-index: 200;
  top: 0px;
  margin: 0 -24px;
  padding: 24px 0;
  background: ${COLORS.brown.dark[100]};

  height: 44px;
  box-sizing: content-box;

  &::after {
    height: 24px;
    left: 0;
    width: 100%;
    bottom: -24px;
    position: absolute;
    background: linear-gradient(180deg, ${COLORS.brown.dark[100]} 0%, ${COLORS.transparent} 100%);
    content: '';
    display: block;
  }
`;

export const StyledContainer = styled.div`
  scrollbar-width: none;
  min-height: 100px;
  margin: 0 -8px;
  padding: 12px 12px 0;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const StyledCard = styled.div<{ showCheck: boolean; isShowCheckbox: boolean }>`
  cursor: pointer;
  margin: 0;
  padding: 0 8px 9px;
  width: 100%;

  .card {
    display: block;
    pointer-events: none;

    &::before {
      border-radius: 10px;
      border: 1.5px solid ${COLORS.white[100]};
      bottom: 0;
      content: '';
      display: ${({ showCheck }) => (showCheck ? 'block' : 'none')};
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100;
    }

    &::after {
      background: ${({ showCheck }) => (showCheck ? COLORS.white[100] : COLORS.white[30])};
      border: 1px solid ${COLORS.white[100]};
      border-radius: 4px;
      bottom: 8px;
      content: ${({ showCheck }) =>
        showCheck
          ? `url('data:image/svg+xml,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_8_2)"><path d="M1 5.5L4.28694 8.62259C4.50022 8.82521 4.84147 8.80081 5.02375 8.56991L11 1" stroke="%23181800" stroke-linecap="round"/></g><defs><clipPath id="clip0_8_2"><rect width="12" height="10" fill="white"/></clipPath></defs></svg>')`
          : '""'};
      display: ${({ showCheck, isShowCheckbox }) => (isShowCheckbox || showCheck ? 'flex' : 'none')};
      height: 24px;
      justify-content: center;
      position: absolute;
      right: 8px;
      vertical-align: middle;
      width: 24px;
    }
  }
`;

export const StyledButtonWrap = styled.div`
  bottom: 24px;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 100;

  > button {
    box-shadow: 0px 19px 8px rgba(0, 0, 0, 0.04), 0px 11px 6px rgba(0, 0, 0, 0.13), 0px 5px 5px rgba(0, 0, 0, 0.21),
      0px 1px 3px rgba(0, 0, 0, 0.25), 0px 0px 0px rgba(0, 0, 0, 0.25);
    max-width: 342px;
    width: 100%;
  }
`;
