import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal';
import Card from 'components/Card';
import Colors from 'components/AddContent/AddStyle/Colors';
import Items from 'components/AddContent/AddStyle/Items';
import { CardStyleSchema, ECardLayoutOptions } from 'constants/graphqlTypes';
import { findNextColor } from 'components/AddContent/helpers/helpers';
import { useAddStyleLogic } from 'components/AddContent/AddStyle/hooks';

import { StyledFooter, StyledPreview, StyledWrap } from 'components/AddContent/AddStyle/styled';
import { StyledListTitle } from 'components/AddContent/AddToList/styled';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { useSparkResponseLinks } from '../hooks';

interface IAddStyleProps {
  setColor: Dispatch<SetStateAction<string>>;
  setItemId: Dispatch<SetStateAction<number>>;
  setStyle: Dispatch<SetStateAction<CardStyleSchema | undefined>>;
  handleClose: () => void;
  listTitle: string;
  cardId: string;
  pickId: string;
  preExtractTitle?: string | null;
  preExtractImage?: string | null;
  itemId: number;
  color: string;
}

const AddStyle: FC<IAddStyleProps> = ({
  preExtractTitle,
  preExtractImage,
  handleClose,
  cardId,
  pickId,
  setStyle,
  itemId,
  setItemId,
  color,
  setColor,
}) => {
  const { addResponsePick, addResponseThought } = useSparkResponseLinks();
  const { replace } = useHistory();

  const { isExtracting, currentColor, imageUrl, itemColors, updatedStyle, previewCard, items } = useAddStyleLogic({
    pickId,
    cardId,
    itemId,
    color,
    preExtractImage,
    preExtractTitle,
    setStyle,
  });

  useEffect(() => {
    if (!cardId) {
      replace(addResponsePick);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showExitWarning, setShowExitWarning] = useState(false);
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  return (
    <Modal
      fadeIn={false}
      handleClose={handleClose}
      handleDone={() => replace(addResponseThought)}
      handlePrev={() => replace(addResponsePick)}
      buttonText="Next"
      isExitConfirmed={isExitConfirmed}
      setShowConfirm={setShowExitWarning}
    >
      <StyledPreview
        bgColor={currentColor?.colorValue}
        hasNoImage={updatedStyle?.layout !== ECardLayoutOptions.Plain && !imageUrl && isExtracting}
        onClick={() => currentColor && setColor(findNextColor(itemColors, currentColor).colorValue)}
      >
        <Card disableLinks card={{ ...previewCard, cardStyle: updatedStyle }} shouldDisableLongTap />
      </StyledPreview>
      <StyledFooter>
        <StyledWrap>
          <StyledListTitle>Select your style</StyledListTitle>
          {itemColors.length > 1 && <Colors items={itemColors} color={currentColor?.colorValue} setColor={setColor} />}
        </StyledWrap>
        <Items items={items} itemId={itemId} setItemId={setItemId} isSpark />
      </StyledFooter>
      {showExitWarning && (
        <ConfirmationModal
          handleCancel={() => setShowExitWarning(false)}
          handleClickOutside={() => setShowExitWarning(false)}
          handleAccept={() => setIsExitConfirmed(true)}
          isWarning
          buttonText="Discard"
          titleText="Discard pick?"
          subTitle="Your edits and thoughts will be discarded if you leave before posting."
        />
      )}
    </Modal>
  );
};

export default AddStyle;
