import React, { FC, useEffect, useRef, useState } from 'react';

import useDisableScroll from 'helpers/useDisableScroll';
import useClickOutside from 'helpers/useClickOutside';
import usePageType from 'helpers/usePageType';

import { StyledWrap, StyledButton, StyledChildWrap } from './ styled';

interface HighlighterProps {
  id: string;
  handler?: () => void;
  borderRadius?: string;
  highlight: boolean;
  children: JSX.Element;
  heightOffset?: boolean;
  highlighterOffset?: number;
  elementOffset?: number;
  handleClose: () => void;
}

const Highlighter: FC<HighlighterProps> = ({
  id,
  handler,
  borderRadius = '48px',
  highlighterOffset = 6,
  elementOffset = 0,
  highlight,
  children,
  heightOffset = true,
  handleClose,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<null | DOMRect>(null);

  const { isProfile } = usePageType();
  useDisableScroll(isProfile);
  useClickOutside(ref, handleClose, true);

  const update = () => {
    const el = document.querySelector(`[data-tour="${id}"]`);

    setPosition(el?.getBoundingClientRect() ?? null);
  };

  useEffect(() => {
    update();

    const interval = setInterval(update, 0);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!position) {
    return null;
  }

  const { top, left, height, width, bottom } = position;
  const { innerHeight } = window;

  const verticalOffset = heightOffset ? highlighterOffset : 0;
  const buttonHeight = height + verticalOffset * 2;
  const isAtTheTop = bottom < innerHeight / 2;

  return (
    <StyledWrap isOpen hasTransition={false}>
      {highlight && (
        <StyledButton
          onClick={handler}
          top={top - verticalOffset}
          left={left - highlighterOffset}
          height={buttonHeight}
          width={width + highlighterOffset * 2}
          borderRadius={borderRadius}
        />
      )}
      <StyledChildWrap
        className="highlighter-child-wrap"
        ref={ref}
        top={isAtTheTop ? top + buttonHeight + elementOffset : null}
        bottom={!isAtTheTop ? innerHeight - bottom + buttonHeight + elementOffset : null}
      >
        {children}
      </StyledChildWrap>
    </StyledWrap>
  );
};

export default Highlighter;
