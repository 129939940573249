import React, { ClipboardEvent, Dispatch, FC, KeyboardEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import DOMPurify from 'dompurify';

import Button from 'components/UI/Button';

import { isSafariIos } from 'helpers/useUserAgent';
import { useInputHeight } from 'components/UI/TextInput/helpers/hooks';
import { UserShortSchema } from 'constants/graphqlTypes';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { useSendMessage, useTyping } from 'graphQL/messages/hooks';
import { BREAKPOINTS } from 'styles/constants';
import MentionSuggestions from 'pages/Messages/MentionSuggestions';
import { useMessageChatContext } from 'helpers/useChatProvider';
import { hasMentions, generateTextWithMention, formatMessageBeforeSending } from 'helpers/messagesMentions';
import { StyledFooter, StyledFooterContent, StyledInputWrap, StyledPlaceholder, StyledSubmit } from './styled';
import { HTML_TAG_CONTENT_EXPRESSION } from '../Message/constants';

interface IMessageInputProps {
  isDark: boolean;
  isKeyboardOpen: boolean;
  setIsKeyboardOpen: Dispatch<SetStateAction<boolean>>;
  isGroupChat?: boolean;
  setScroll: Dispatch<SetStateAction<boolean>>;
}

const MessageInput: FC<IMessageInputProps> = ({
  isDark,
  setIsKeyboardOpen,
  isKeyboardOpen,
  isGroupChat,
  setScroll,
}) => {
  const [text, setText] = useState('');
  const [mentions, setMentions] = useState<UserShortSchema[]>([]);
  const [isMentionMode, setIsMentionMode] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [newPosition, setNewPosition] = useState(0);
  const selection = window.getSelection();
  const { setShouldPlay1stMessageAnimations } = useMessageChatContext();

  const sendMessage = useSendMessage();
  const ref = useRef<HTMLDivElement>(null);
  const endTyping = useTyping(text);

  const resetAfterMessageSend = () => {
    setText('');
    setIsMentionMode(false);
    setMentions([]);
    endTyping();
    ref.current?.focus();
    setShouldPlay1stMessageAnimations?.(true);
    document.querySelector('#thread')?.scrollTo(0, document.querySelector('#thread')?.scrollHeight ?? 0);
  };

  const handleSend = async (innerHTML: string) => {
    if (!ref.current || !innerHTML) return;

    if (innerHTML.trim()) {
      const message = formatMessageBeforeSending(innerHTML, mentions);
      sendMessage(message);
    }

    resetAfterMessageSend();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend(e.currentTarget.innerHTML);
    }

    if (e.key === '@' && ref.current?.innerHTML[ref.current?.innerHTML.length - 1] !== ' ') {
      setIsMentionMode(false);
    }

    if (e.key === 'Space' || e.key === ' ') {
      setIsMentionMode(false);
    }
  };

  useEffect(() => {
    setText(
      text.replaceAll(/<span>.*?<\/span>/g, (match) => {
        const spanContent = match.match(HTML_TAG_CONTENT_EXPRESSION);
        if (spanContent) {
          const insideMention = mentions.find((mention) => spanContent[0].includes(generateFullName(mention)));
          const [preMentionText, afterMentionText] = spanContent[0].slice(1, -1).split(generateFullName(insideMention));
          if (preMentionText) {
            const currentPosition = selection?.getRangeAt(0).endOffset;
            setNewPosition(currentPosition ?? 1);
            return `${preMentionText}<span>${generateFullName(insideMention)}</span>${afterMentionText}`;
          }
        }
        return match;
      })
    );

    if ((text && !hasMentions(text) && isMentionMode) || !text) {
      setIsMentionMode(false);
    }
    if (hasMentions(text)) {
      setIsMentionMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    if (newPosition && ref.current) {
      const newRange = document.createRange();
      const actualPosition = Math.min(newPosition, ref.current.childNodes.length - 1);
      newRange.setStart(ref.current, actualPosition);
      newRange.collapse(true);
      selection?.removeAllRanges();
      selection?.addRange(newRange);
      setNewPosition(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPosition]);

  useEffect(() => {
    if (window.innerWidth < BREAKPOINTS.s) {
      setScroll(!isMentionMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMentionMode]);

  useEffect(() => {
    setIsExpanded(!!text);
  }, [text]);

  useInputHeight(text, 140, ref.current);

  const handleMention = (mentionedUser: UserShortSchema, inputText: string, mentionText: string) => {
    const newText = generateTextWithMention(mentionedUser, inputText, mentionText);
    setText(newText);
    setMentions([...mentions, mentionedUser]);
    ref.current?.focus();
    setIsMentionMode(false);
  };

  const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text/plain');
    const range = window.getSelection()?.getRangeAt(0);

    if (range && ref.current) {
      if (!ref.current.innerText) {
        setText(pastedText);
        return;
      }
      const pastedTextNode = document.createTextNode(pastedText);
      range.deleteContents();
      range.insertNode(pastedTextNode);

      range.setStartAfter(pastedTextNode);
      range.collapse(true);
    }
  };

  return (
    <StyledFooter isFixed={isSafariIos()} isDark={isDark} isKeyboardOpen={isKeyboardOpen} className="input">
      <StyledFooterContent isFixed={isSafariIos()} isDark={isDark} isKeyboardOpen={isKeyboardOpen}>
        {isMentionMode && !!isGroupChat && (
          <MentionSuggestions isDark={isDark} text={text} handleMention={handleMention} />
        )}
        <StyledInputWrap isDark={isDark}>
          {!text && <StyledPlaceholder isDark={isDark}>Write a message</StyledPlaceholder>}
          <ContentEditable
            className={`editable ${isExpanded ? 'expanded' : ''}`}
            innerRef={ref}
            tagName="div"
            html={DOMPurify.sanitize(text)}
            onChange={({ currentTarget: { innerHTML } }) => setText(innerHTML)}
            onBlur={() => setIsKeyboardOpen(false)}
            onKeyDown={handleKeyDown}
            onFocus={() => window.innerWidth < BREAKPOINTS.s && setIsKeyboardOpen(true)}
            onPaste={handlePaste}
          />
        </StyledInputWrap>
        <StyledSubmit isShown={!!text}>
          <Button text="Send" handler={() => handleSend(text)} isDark={isDark} />
        </StyledSubmit>
      </StyledFooterContent>
    </StyledFooter>
  );
};

export default MessageInput;
