import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal';
import Toggle from 'components/UI/Toggle';
import TextInput from 'components/UI/TextInput';
import ContentTitle from 'components/UI/ContentTitle';
import useDisableScroll from 'helpers/useDisableScroll';
import useCreateList from 'graphQL/addContent/createList/hook';

import { LIST_DESCRIPTION_LENGTH, LIST_NAME_LENGTH } from 'constants/common';
import {
  TEST_CREATE_COLLECTION_INPUT,
  TEST_CREATE_COLLECTION_PRIVATE_BTN,
  TEST_CREATE_COLLECTION_TITLE,
} from 'constants/aqa/addPick';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import { CreateListMutation } from 'constants/graphqlTypes';
import { useAddContentLinks } from '../helpers/hooks';
import { StyledLabel, StyledInputWrapper } from './styled';

export const DESCRIPTION_INPUT_HEIGHT = 143;

interface ICreateListProps {
  handleClose: (collectionCardId?: string, title?: string | null, collectionId?: string) => void;
  handlePrev?: () => void;
}

const CreateList: FC<ICreateListProps> = ({ handleClose, handlePrev }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isDescription, setIsDescription] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [error, setError] = useState('');

  const { replace } = useHistory();
  const { home } = useAddContentLinks();

  const onCompleted = (data?: CreateListMutation | null) => {
    handleClose(data?.createCollection?.cardId, data?.createCollection?.title, data?.createCollection?.id);
  };
  const { createList, loading, listError } = useCreateList(title.trim(), isPrivate, description.trim(), onCompleted);

  useEffect(() => {
    if (listError) {
      setError(listError);
    }
  }, [listError]);

  const handleGoBack = () => {
    setError('');

    if (isDescription) {
      return setIsDescription(false);
    }
    return handlePrev ? handlePrev() : replace(home);
  };

  const handleChange = (value: string) => {
    setError('');
    return isDescription
      ? setDescription(value.slice(0, LIST_DESCRIPTION_LENGTH))
      : setTitle(value.slice(0, LIST_NAME_LENGTH));
  };

  useDisableScroll();

  const [showExitWarning, setShowExitWarning] = useState(false);
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  return (
    <Modal
      fadeIn={false}
      handleClose={handleClose}
      handleDone={isDescription ? createList : () => setIsDescription(true)}
      handlePrev={handleGoBack}
      buttonText={isDescription ? 'Create' : 'Next'}
      disabled={!title}
      loading={loading}
      isExitConfirmed={isExitConfirmed}
      setShowConfirm={setShowExitWarning}
    >
      <StyledInputWrapper>
        <ContentTitle dataTestId={TEST_CREATE_COLLECTION_TITLE} isDark>
          {isDescription ? 'Add a description' : 'What do you want to name your list?'}
        </ContentTitle>
        <TextInput
          dataTestId={TEST_CREATE_COLLECTION_INPUT}
          inputHeight={DESCRIPTION_INPUT_HEIGHT}
          counterNumber={isDescription ? LIST_DESCRIPTION_LENGTH : LIST_NAME_LENGTH}
          placeholder={isDescription ? 'Keep it short and sweet' : 'List name'}
          setText={handleChange}
          text={isDescription ? description : title}
          error={error}
          autoCapitalize={EAutoCapitalize.On}
        />
      </StyledInputWrapper>
      {!isDescription && (
        <StyledLabel data-testid={TEST_CREATE_COLLECTION_PRIVATE_BTN}>
          Make this list private
          <Toggle isDark checked={isPrivate} handler={() => setIsPrivate((prev) => !prev)} />
        </StyledLabel>
      )}
      {showExitWarning && (
        <ConfirmationModal
          handleCancel={() => setShowExitWarning(false)}
          handleClickOutside={() => setShowExitWarning(false)}
          handleAccept={() => setIsExitConfirmed(true)}
          isWarning
          buttonText="Discard"
          titleText="Discard pick?"
          subTitle="Your edits and thoughts will be discarded if you leave before posting."
        />
      )}
    </Modal>
  );
};

export default CreateList;
