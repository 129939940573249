import React, { FC } from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';
import useTheme from 'helpers/useTheme';

import { StyledFooter, StyledCircle, StyledWrapCircle, StyledTitle, StyledCheckIcon } from './styled';

const Footer: FC = () => {
  const isDark = useTheme();

  return (
    <StyledFooter isDark={isDark}>
      <StyledWrapCircle>
        <StyledCircle isDark={isDark}>
          <StyledCheckIcon isDark={isDark}>
            <CheckIcon width={45} height={45} viewBox="0 0 24 24" />
          </StyledCheckIcon>
        </StyledCircle>
      </StyledWrapCircle>
      <StyledTitle>You’re all caught up</StyledTitle>
      <br />
      Check back tomorrow to explore more
    </StyledFooter>
  );
};

export default Footer;
