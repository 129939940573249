import React, { FC } from 'react';

import { EVisibilityStatusOptions, Maybe } from 'constants/graphqlTypes';
import { generateSettingsTestIds } from 'constants/aqa/settings/accountManagement';
import { StyledTextPickTitle } from 'components/Card/QuestionCard/styled';
import RolesButton from '../RolesButton';

import { StyledWrap } from '../styled';
import { EVisibilityRoles } from '../constants';

interface IPermission {
  text: string;
  role?: Maybe<EVisibilityStatusOptions>;
  type: EVisibilityRoles;
  dataTestId?: string;
}

const Permission: FC<IPermission> = ({ text, role, type, dataTestId }) => {
  const { titleTestId, selectButtonTestId } = generateSettingsTestIds(dataTestId);

  return (
    <StyledWrap>
      <StyledTextPickTitle data-testid={titleTestId}>{text}</StyledTextPickTitle>
      <RolesButton dataTestId={selectButtonTestId} type={type} role={role} />
    </StyledWrap>
  );
};
export default Permission;
