import { styled } from 'linaria/react';
import { BREAKPOINTS, COLORS, FONTS } from '../../styles/constants';

const INTRO_XS_SCREEN_WIDTH = 380;
const INTRO_S_SCREEN_WIDTH = 415;

export const StyledIntroWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;

  > video {
    object-fit: contain;

    @media screen and (min-width: ${INTRO_XS_SCREEN_WIDTH}px) {
      object-fit: cover;
      object-position: 0 -40px;
    }

    @media screen and (min-width: ${INTRO_S_SCREEN_WIDTH}px) {
      object-fit: contain;
      object-position: center;
      height: 100vh;
    }
  }
`;

export const StyledSkipIntro = styled.p`
  bottom: 4vh;
  color: ${COLORS.white[100]};
  cursor: pointer;
  font: 600 14px/140% ${FONTS.secondary};
  position: absolute;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    bottom: 3vh;
  }
`;

export const FullScreenWrapper = styled.div`
  bottom: 1.5vh;
  color: ${COLORS.white[100]};
  cursor: pointer;
  position: absolute;
  right: 70px;

  @media screen and (min-width: ${INTRO_XS_SCREEN_WIDTH}px) {
    right: 30px;
  }

  @media screen and (min-width: ${INTRO_S_SCREEN_WIDTH}px) {
    display: none;
  }
`;

export const StyledContent = styled.div`
  align-items: center;
  color: ${COLORS.white[100]};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  position: relative;

  .lottie {
    width: 220px;

    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      width: 250px;
    }
  }
`;

export const StyledIconWrapper = styled.div`
  cursor: pointer;
  left: 22px;
  position: absolute;
  top: 22px;
`;

export const StyledSubHeading = styled.h3`
  animation: textUp 1s ease-in-out 200ms forwards;
  font: 400 14px/140% ${FONTS.primary};
  margin: 0;
  opacity: 0;

  @keyframes textUp {
    0% {
      opacity: 0.4;
      transform: translateY(120%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(14px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledSliderWrapper = styled.div`
  animation: sliderShow 1s ease 1s forwards;
  opacity: 0;
  margin-top: 50px;
  padding-bottom: 130px;

  @keyframes sliderShow {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    margin-top: 82px;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    margin-top: 20vh;
    max-width: 528px;
    padding-bottom: 60px;
  }
`;

export const StyledButtonWrap = styled.div`
  bottom: 5vh;
  display: flex;
  justify-content: center;
  left: 0;
  margin-top: 5vh;
  padding: 0 24px;
  position: absolute;
  width: 100%;
  z-index: 200;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    bottom: 0;
    position: relative;
  }

  > button {
    max-width: 342px;
    width: 100%;
    box-shadow: 0 19px 8px rgba(0, 0, 0, 0.04), 0 11px 6px rgba(0, 0, 0, 0.13), 0 5px 5px rgba(0, 0, 0, 0.21),
      0 1px 3px rgba(0, 0, 0, 0.25), 0 0 0 rgba(0, 0, 0, 0.25);
  }
`;
