import React, { FC } from 'react';
import { v4 } from 'uuid';

import { EReactionTypeOptions } from 'constants/graphqlTypes';
import { StyledEmojiWrap, StyledEmoji } from './styled';

const Emoji: FC<{ reactions: EReactionTypeOptions[]; isLeft?: boolean }> = ({ reactions, isLeft }) => (
  <StyledEmojiWrap isLeft={isLeft}>
    {reactions.map((item) => (
      <StyledEmoji key={v4()}>{item}</StyledEmoji>
    ))}
  </StyledEmojiWrap>
);

export default Emoji;
