import { styled } from 'linaria/react';

import grain from '../../../assets/images/grain.webp';

const StyledGrain = styled.div`
  background-image: url(${grain});
  background-repeat: repeat repeat;
  background-size: 300px 300px;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
`;

export default StyledGrain;
