import React, { FC } from 'react';

import { SingleChatSchema } from 'constants/graphqlTypes';
import { useSingleChatTitle } from 'components/Messages/helpers/hooks';
import { getThreadLastMessageText } from 'components/Messages/helpers/helpers';

import Avatar from 'components/UI/Avatar';
import ContentItem from './ContentItem';

interface ISingleTheadItemProps {
  item: SingleChatSchema;
  isDark: boolean;
  isSearchActive?: boolean;
}

const SingleTheadItem: FC<ISingleTheadItemProps> = ({ item, isDark, isSearchActive }) => {
  const { title, user } = useSingleChatTitle(item);
  const { id: chatId, lastMessage, lastActionAt, member, lastReaction, isBlockedByMe } = item;
  const { isViewed } = lastMessage ?? {};
  const { isViewed: isLastReactionViewed } = lastReaction ?? {};

  const hasUnreaded = (!!lastMessage && !isViewed) || (!!lastReaction && !isLastReactionViewed);

  return user ? (
    <ContentItem
      chatId={chatId}
      isDark={isDark}
      hasUnreaded={hasUnreaded}
      title={title}
      text={getThreadLastMessageText({ lastMessage, receiver: member?.user, lastReaction })}
      lastActionAt={lastReaction && lastActionAt < lastReaction.createdAt ? lastReaction.createdAt : lastActionAt}
      username={isSearchActive ? `:${user?.username}` : null}
      isUserVerified={!!user.isVerified}
      userId={user.id}
      isBlockedByMe={isBlockedByMe}
    >
      <Avatar user={user} />
    </ContentItem>
  ) : null;
};

export default SingleTheadItem;
