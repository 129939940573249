import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { FILE_MAX_SIZE } from 'constants/common';
import { IToast } from 'providers/helpers/models';

export const handleFileUploading = (
  setUrl: Dispatch<SetStateAction<string | File>>,
  setToast: (toast: IToast) => void,
  { target: { files } }: ChangeEvent<HTMLInputElement>
): void => {
  const file = files?.[0];
  const fileName = file?.name.toLowerCase();
  const extension = fileName?.split('.').pop();
  const isSupported = 'png jpg jpeg'.includes(extension || '');
  if (file && isSupported) {
    if (file.size > FILE_MAX_SIZE * 1000000) {
      setToast({
        isToastOpen: true,
        toastItemName: 'File size is too large',
        toastError: true,
      });
    } else {
      setUrl(file);
    }
  } else if (!isSupported) {
    setToast({
      isToastOpen: true,
      toastItemName: 'Supported formats are .png, .jpg, .jpeg',
      toastError: true,
    });
  }
};
