import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import BackButton from 'components/Navigation/LeftMenu/BackButton';
import ListButton from 'components/Navigation/RightMenu/ListButton';
import NotificationsButton from 'components/Navigation/RightMenu/NotificationsButton';
import useOpenModal from 'helpers/useOpenModal';
import IconCircle from 'components/UI/IconCircle';
import FeedDetailButton from 'components/Navigation/RightMenu/FeedDetailButton';

import { ReactComponent as PlaneIcon } from 'assets/icons/PaperPlane.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg';
import { StyledNavigation, StyledButton } from 'components/Navigation/styled';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { COLORS } from 'styles/constants';
import { ROUTE_OFF_PLATFORM_SHARING, ROUTE_SELECT_CHAT_MODAL } from 'routes';
import { ESearchParams } from 'constants/common';
import { useProfileContext } from 'helpers/useProfileProvider';
import { ECardPermissionsOptions, MessageType } from 'constants/graphqlTypes';
import { StyledImmersiveBottomMenu } from './styled';

import ImmersiveExploreButton from './ImmersiveExploreButton';

interface IImmersiveNavigationProps {
  id: string;
  isFeed: boolean;
  handleSwipe: () => void;
  isExploreAllowed: boolean;
  isReactionsModalOpen?: boolean;
  isReactionButtonMenuShown?: boolean;
}

const ImmersiveNavigation: FC<IImmersiveNavigationProps> = ({
  id,
  isFeed,
  handleSwipe,
  isExploreAllowed,
  isReactionButtonMenuShown,
  isReactionsModalOpen,
}) => {
  const { card } = useGetDetailCard(id);
  const { push } = useHistory();
  const { permissions, title, isCollaborative, isNotificationsEnable, isInChannel, isPrivate } = card ?? {};
  const { gradientStart: profileGradientStart, gradientEnd: profileGradientEnd } = useProfileContext();

  const hasEditPermission = permissions?.includes(ECardPermissionsOptions.EditCollectionItems);

  const isChannelNotificationsEnable =
    (!!isNotificationsEnable && hasEditPermission && !!isInChannel) || (!!isInChannel && !hasEditPermission);

  const goToChatSelect = useOpenModal(ROUTE_SELECT_CHAT_MODAL, false, {
    entityId: id,
    messageType: MessageType.List,
    collectionId: isPrivate && isCollaborative ? id : null,
    profileGradientStart,
    profileGradientEnd,
  });

  const handleShareContent = () =>
    push({
      pathname: ROUTE_OFF_PLATFORM_SHARING,
      search: `?${ESearchParams.Type}=${MessageType.List}&${ESearchParams.Id}=${id}`,
    });

  return (
    <>
      <StyledNavigation isHide={false} color={COLORS.white[100]}>
        <BackButton />
        <div>
          {!isFeed && (
            <StyledButton onClick={handleShareContent}>
              <ShareIcon />
            </StyledButton>
          )}
          {isFeed ? <FeedDetailButton /> : <>{card && <ListButton hasShare={false} list={card} />}</>}
        </div>
      </StyledNavigation>
      <StyledImmersiveBottomMenu>
        {!isFeed ? (
          <NotificationsButton
            isNotificationsEnable={!!isChannelNotificationsEnable}
            hasEditPermission={permissions?.includes(ECardPermissionsOptions.EditCollectionItems)}
            isCollaborative={isCollaborative}
            name={title}
            listId={id}
          />
        ) : (
          <div />
        )}
        {isExploreAllowed && (
          <ImmersiveExploreButton
            handleSwipe={handleSwipe}
            isReactionsModalOpen={isReactionsModalOpen}
            isReactionButtonMenuShown={isReactionButtonMenuShown}
          />
        )}
        {!isFeed ? <IconCircle hasBorder Icon={PlaneIcon} isDark handleClick={goToChatSelect} /> : <div />}
      </StyledImmersiveBottomMenu>
    </>
  );
};

export default ImmersiveNavigation;
