import React, { FC } from 'react';

import Avatar from 'components/UI/Avatar';

import { UserObjectType } from 'constants/graphqlTypes';
import { StyledList, StyledListItem } from './styled';

interface IAvatarsProps {
  users: UserObjectType[];
  limit: number;
  total?: number;
  isBig?: boolean;
  hideNumber?: boolean;
}

const Avatars: FC<IAvatarsProps> = ({ users, total, isBig, hideNumber, limit }) => (
  <StyledList>
    {users.slice(0, limit).map((user) => (
      <StyledListItem key={user.id} isBig={isBig}>
        <Avatar dimension={isBig ? 44 : 20} user={user} />
      </StyledListItem>
    ))}
    {!!total && total > limit && !hideNumber && (
      <StyledListItem className="total" isEmpty isBig={isBig}>
        +{total - limit}
      </StyledListItem>
    )}
  </StyledList>
);

export default Avatars;
