import { styled } from 'linaria/lib/react';

import { StyledInfoContainer as ImportedStyledContainer } from 'components/Profile/ProfileTop/styled';
import { StyledSparkTitleWrap, StyledUserText } from 'components/Sparks/Views/Card/styled';

export const StyledUserInfo = styled(StyledUserText)`
  padding: 16px 4px;
`;

export const StyledSpark = styled(StyledSparkTitleWrap)`
  padding: 16px 0;
`;

export const StyledInfoContainer = styled(ImportedStyledContainer)`
  margin: 0 -5.2%;
`;
