import { styled } from 'linaria/react';

export const StyledRowWrap = styled.div<{ isDark: boolean }>`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 14px;
  }
`;

export const StyledNavigationWrap = styled.section<{ bgColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 2vw 8px;
  background-color: ${({ bgColor }) => bgColor ?? 'none'};
  transition: top 0.3s ease-in-out;
  width: 100%;
`;

export const StyledListWrap = styled.div`
  padding-bottom: 32px;
`;
