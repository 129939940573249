import { StyledBody } from 'components/Card/Common/styled';
import { styled } from 'linaria/react';

import { COLORS, FONTS } from 'styles/constants';

export const StyledWrap = styled.div`
  padding: 0 0 48px;
`;

export const StyledTitleView = styled(StyledBody)`
  cursor: pointer;

  &::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const StyledDetail = styled.div<{ isDark: boolean }>`
  padding: 12px 12px 0;

  > span {
    margin-bottom: 12px;

    a {
      color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
      font-family: ${FONTS.secondary};
      font-size: 12px;
      font-weight: 400;
      line-height: 140%;
      overflow: hidden;
      position: relative;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const StyledQuestion = styled.div`
  padding-bottom: 24px;
`;
