import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';

import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import useGuideTour from 'helpers/useGuideTour';
import { ETutorialStatus } from 'constants/graphqlTypes';

import { useUpdateUserSettings } from 'graphQL/contextualIntro/hooks';
import { EExploreTabs } from 'components/Explore/ExploreTabs/helpers/constants';
import useDiscoveryTab from 'helpers/useDiscoveryTab';

import { ROUTE_DISCOVERY, ROUTE_HELP } from 'routes';
import { PROFILE_BUTTON, HOME_BUTTON, PICKER_BUTTON, SAVED_FOR_LATER, LAST_STEP } from './constants';

interface IGuideTourSteps {
  target: string;
  title: string;
  text?: string;
  borderRadius?: string;
  highlighterOffset?: number;
  elementOffset?: number;
  handleNext?: () => void;
}

export const useGetSteps = (): IGuideTourSteps[] => {
  const { push } = useHistory();

  return [
    {
      target: PROFILE_BUTTON,
      title: 'You and what you’re into',
      text:
        'Your profile is how you express your unique passions with others. Keep it fresh with thoughts & recommendations.',
      borderRadius: '50%',
      highlighterOffset: 18,
      elementOffset: -12,
    },
    {
      target: HOME_BUTTON,
      title: 'Discover what others are into',
      text:
        'Home is where you find the latest and most interesting picks on guide:human. Picked by humans, not algorithms.',
      borderRadius: '50%',
      highlighterOffset: 18,
      elementOffset: -12,
    },
    {
      target: PICKER_BUTTON,
      title: 'Create something new',
      text: 'Share a new pick, start a new list or spark a discussion with your followers.',
      borderRadius: '50%',
      highlighterOffset: 18,
      elementOffset: -12,
      handleNext: () => push(ROUTE_DISCOVERY),
    },
    {
      target: SAVED_FOR_LATER,
      title: 'Share things you’ve ‘Saved for later’',
      text: 'Anything you’ve quick saved using the guide:human extension, is here for you to review.',
      borderRadius: '50%',
      highlighterOffset: 8,
    },
    {
      target: LAST_STEP,
      title: 'Let humans be your guide',
    },
  ];
};

export const useExitGuideTour = (
  setModalState: Dispatch<SetStateAction<boolean>>
): {
  handelTourExit: (isDelayed: boolean | undefined) => void;
  handelHelpPage: () => void;
} => {
  const { push } = useHistory();
  const posthogCapture = usePostHogCapture();
  const { updateUserSettings: delayTour } = useUpdateUserSettings({ tutorialStatus: ETutorialStatus.Delayed });
  const { updateUserSettings: finishTour } = useUpdateUserSettings({ tutorialStatus: ETutorialStatus.Finished });
  const { setIsGuideTour, setGuideTourStep } = useGuideTour();
  const { setDiscoveryTab } = useDiscoveryTab();

  const handelTourExit = (isDelayed?: boolean) => {
    if (isDelayed) {
      delayTour();
      push(ROUTE_DISCOVERY);
    } else {
      finishTour();
      posthogCapture(POSTHOG_EVENTS.Onboarding);
      posthogCapture(POSTHOG_EVENTS.CompleteGuideTour);
    }
    setGuideTourStep?.(0);
    setDiscoveryTab?.(EExploreTabs.Featured);
    setIsGuideTour?.(false);
    setModalState(false);
  };

  const handelHelpPage = () => {
    handelTourExit(true);
    push(ROUTE_HELP);
  };

  return { handelTourExit, handelHelpPage };
};
