import React, { FC } from 'react';

import { TypeAnimation } from 'react-type-animation';

import { UserObjectType } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';

import Avatar from 'components/UI/Avatar';
import getAuthUser from 'helpers/getAuthUser';
import useDocumentTitle from 'helpers/useDocumentTitle';
import FollowButton from 'components/FollowButton';
import AvatarPlaceholder from 'components/UI/Avatar/AvatarPlaceholder';
import {
  TEST_ID_PROFILE_VIEW_NAME_LABEL,
  TEST_ID_PROFILE_VIEW_USERNAME_LABEL,
  TEST_PROFILE_ACTION_BTN,
} from 'constants/aqa/profile';
import ConnectionsButton from './ConnectionsButton';

import {
  StyledFollowButtonContainer,
  StyledFullName,
  StyledInfoContainer,
  StyledText,
  StyledUserInfo,
  StyledVerificationWrap,
} from './styled';
import { AVATAR_DIMENSION } from './constants';
import { generateFullName } from './helpers';
import VerificationBadge from './VerificationBadge';

interface IInfoProps {
  noContent: boolean;
  userData?: UserObjectType;
  fontColor: string;
  hideGradient: boolean;
}

const Info: FC<IInfoProps> = ({ noContent, userData, fontColor, hideGradient }) => {
  const { username, userInfo, id: userId, isVerified } = userData ?? {};
  const { firstName, lastName, bio } = userInfo ?? {};
  const { userId: currentUserId } = getAuthUser();

  useDocumentTitle(generateFullName(userData));

  return (
    <StyledInfoContainer hasWhiteAvatar={!noContent && !hideGradient} hasPaddingBottom={noContent}>
      {userData ? (
        <Avatar user={userData} dimension={AVATAR_DIMENSION} />
      ) : (
        <AvatarPlaceholder dimension={AVATAR_DIMENSION} />
      )}
      <StyledFullName key={`${firstName}${lastName}`} data-testid={TEST_ID_PROFILE_VIEW_USERNAME_LABEL}>
        {userData ? (
          <TypeAnimation wrapper="span" cursor={false} sequence={[`${firstName} ${lastName}`, 100]} speed={35} />
        ) : (
          'Sorry, this profile cannot be found'
        )}
      </StyledFullName>
      {isVerified && (
        <StyledVerificationWrap>
          <VerificationBadge hasCaption />
        </StyledVerificationWrap>
      )}
      <StyledUserInfo>
        {userData && <StyledText data-testid={TEST_ID_PROFILE_VIEW_NAME_LABEL}>:{username}</StyledText>}
        {bio && <StyledText shouldBreak>{bio}</StyledText>}
        {username && (
          <ConnectionsButton username={username} connectionsCount={userData?.userCounters?.connectionsCount ?? 0} />
        )}
      </StyledUserInfo>
      {userId !== currentUserId && username && userData && (
        <StyledFollowButtonContainer>
          <FollowButton dataTestId={TEST_PROFILE_ACTION_BTN} user={userData} isDark={fontColor === COLORS.white[100]} />
        </StyledFollowButtonContainer>
      )}
    </StyledInfoContainer>
  );
};

export default Info;
