import { styled } from 'linaria/react';

import { StyledBody as ImportBody, StyledFooter as ImportFooter } from '../Common/styled';

export const StyledBody = styled(ImportBody)`
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  padding-bottom: 24px;
`;

export const StyledImage = styled.img`
  border-radius: 8px;
  flex: 1;
  height: 80px;
  object-fit: cover;
  object-position: center;
  min-width: 80px;
  position: relative;
  width: 80px;
  margin-bottom: -12px;
`;

export const StyledFooter = styled(ImportFooter)`
  padding: 8px 12px;
`;
