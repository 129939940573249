import { styled } from 'linaria/react';

import { COLORS } from 'styles/constants';

import ImportWrap from 'components/UI/Wrap/styled';

export const StyledWrap = styled(ImportWrap)`
  width: calc(100% - 48px);
`;

export const StyledHeader = styled.header`
  padding: 20px 0 16px;
`;

export const StyledTitle = styled.h1<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  padding: 0;

  ::first-letter {
    text-transform: uppercase;
  }
`;
