import { useEffect } from 'react';

import useIsModalOpen from './useIsModalOpen';

const useFixedViewport = (ignore?: boolean): void => {
  const { body } = document;

  const isModalOpen = useIsModalOpen();

  useEffect(() => {
    if (!ignore) {
      body.classList.add('fixed');
      document.documentElement.classList.add('fixed');
    }

    return () => {
      if (!ignore) {
        body.classList.remove('fixed');
        document.documentElement.classList.remove('fixed');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);
};

export default useFixedViewport;
