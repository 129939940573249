import { styled } from 'linaria/react';
import { FONTS } from 'styles/constants';

export const StyledListPreviewWrap = styled.div`
  align-items: center;
  box-sizing: content-box;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  overflow: hidden;
  padding: 24px 0;
`;

export const StyledListPreviewContainer = styled.div`
  height: 236px;
  width: 177px;

  .cover-text-item {
    font: 400 13px/130% ${FONTS.primary};
  }
`;

export const StyledContentContainer = styled.ul`
  margin: 0 -12px;
  overflow: auto;
  padding: 24px 0 48px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
