import { useEffect, SetStateAction, Dispatch, useState } from 'react';

import { MediaTagSchema } from 'constants/graphqlTypes';
import { ESelectionMode } from '../constants';
import { IUseCreateFeedTopicsSelectionArgs, IUseCreateFeedUsersSelectionArgs, IUseCreateFeedUsersArgs } from './models';
import { useAdminFeedFiltersContext } from '../FilterFeedModal/hooks';

const { Followings, All, Deselect, Select } = ESelectionMode;

export const useCreateFeedUsersSelection = (args: IUseCreateFeedUsersSelectionArgs): void => {
  const {
    selectionMode,
    setTotalSelectedUsers,
    summaryTotal,
    selectedUsers,
    deselectedUsers,
    setSelectionMode,
    totalSelectedUsers,
    totalFollowingsCount,
    isSearch,
    isAllUsers,
    isAllFollowings,
    editedTotalCount,
    isEditStarted,
    isAllFollowingsEdit,
    isAllUsersEdit,
  } = args;
  const [prevDeselectedItems, setPrevDeselectedItems] = useState(deselectedUsers);

  useEffect(() => {
    if (selectionMode === All) {
      setTotalSelectedUsers(summaryTotal || totalSelectedUsers);
      setPrevDeselectedItems([]);
    } else if (selectionMode === Followings && totalFollowingsCount) {
      setPrevDeselectedItems([]);
      if (isEditStarted !== false && editedTotalCount && selectionMode !== Followings) {
        setTotalSelectedUsers(editedTotalCount + selectedUsers.length - deselectedUsers.length);
      }
      if (isEditStarted !== false && editedTotalCount && selectionMode === Followings) {
        setTotalSelectedUsers(totalFollowingsCount + selectedUsers.length - deselectedUsers.length);
      }
      if (isEditStarted !== false && !editedTotalCount) {
        setTotalSelectedUsers(totalFollowingsCount + selectedUsers.length - deselectedUsers.length);
      }
    } else if (selectedUsers.length && isEditStarted === undefined) {
      setTotalSelectedUsers(selectedUsers.length);
    }
    // Edit Mode. Condition when Users were saved with isAllFollowings=false and isAllUsers=false
    else if (isEditStarted && editedTotalCount && !isAllFollowings && !isAllFollowingsEdit) {
      if (totalSelectedUsers !== 0 || (totalSelectedUsers === 0 && !!selectedUsers.length)) {
        setTotalSelectedUsers(selectedUsers.length + editedTotalCount - deselectedUsers.length);
      }
      if (totalSelectedUsers === summaryTotal || editedTotalCount + selectedUsers.length === summaryTotal) {
        setSelectionMode(All);
      }
    }
    // Edit Mode. Condition when Users where saved with isAllFollowings=true or isAllUsers=true, but we need to include only some items
    else if (isAllFollowingsEdit && !isAllFollowings && !isAllUsersEdit) {
      setTotalSelectedUsers(selectedUsers.length - deselectedUsers.length);
    }
    // Edit Mode. Condition when Users where saved with isAllFollowings=true or isAllUsers=false and we need to include/exclude some items
    else if (
      isAllFollowingsEdit &&
      isAllFollowings &&
      totalFollowingsCount &&
      !isAllUsersEdit &&
      !isAllUsers &&
      isEditStarted &&
      editedTotalCount
    ) {
      setTotalSelectedUsers(editedTotalCount + selectedUsers.length - deselectedUsers.length);
    }
    // Edit Mode.
    // 1. Condition when Users where saved with isAllUsers=true, but we need to change this for isAllFollowing=true
    // 2. Condition when Users where saved with some items and we need to change for isAllFollowing=true
    else if (
      (isAllUsersEdit && !isAllUsers && isAllFollowings && totalFollowingsCount) ||
      (isAllFollowings && !isAllUsers && !isAllFollowingsEdit && totalFollowingsCount)
    ) {
      setTotalSelectedUsers(totalFollowingsCount + selectedUsers.length - deselectedUsers.length);
    }
    // Edit Mode. Condition when Users where saved with isAllUsers=true and we need to exclude some items
    else if (
      (isAllUsersEdit && isAllUsers && isEditStarted && deselectedUsers.length && editedTotalCount) ||
      (isAllUsersEdit && isAllUsers && editedTotalCount && editedTotalCount < summaryTotal)
    ) {
      setTotalSelectedUsers(editedTotalCount - deselectedUsers.length + selectedUsers.length);
    }
    // Edit Mode. Condition when Users where saved with isAllUsers=true and we need to include only some items
    else if (
      (isAllUsersEdit && !isAllUsers && !isAllFollowings) ||
      (isAllUsersEdit && isAllFollowings && isEditStarted && isAllUsers && selectionMode === Select)
    ) {
      setTotalSelectedUsers(selectedUsers.length);
    } else if (deselectedUsers.length) {
      const deletedItems = deselectedUsers.length - prevDeselectedItems.length;
      setTotalSelectedUsers(totalSelectedUsers - deletedItems);
      setPrevDeselectedItems(deselectedUsers);
    } else if (
      selectionMode === Deselect &&
      !deselectedUsers.length &&
      isAllFollowings &&
      totalFollowingsCount &&
      !isAllFollowingsEdit
    ) {
      setSelectionMode(isAllUsers ? All : Followings);
      if (!isAllUsers) {
        setTotalSelectedUsers(totalFollowingsCount);
      }
    } else if (
      (selectionMode === Deselect && !deselectedUsers.length && !isAllFollowingsEdit) ||
      (isAllUsers && totalSelectedUsers === summaryTotal)
    ) {
      if (!isSearch && !!summaryTotal) {
        const areAllUsersSelected = totalSelectedUsers === summaryTotal && selectionMode === Select;
        const newSelectionModeBeforeEdit = areAllUsersSelected ? Select : All;
        const newSelectionModeAfterEdit = areAllUsersSelected && isEditStarted ? Select : All;
        const newTotalSelectedUsersBeforeEdit = areAllUsersSelected ? 0 : totalSelectedUsers;
        const newTotalSelectedUsersAfterEdit = areAllUsersSelected && isEditStarted ? 0 : totalSelectedUsers;
        setSelectionMode(isEditStarted !== undefined ? newSelectionModeAfterEdit : newSelectionModeBeforeEdit);
        setTotalSelectedUsers(
          isEditStarted !== undefined ? newTotalSelectedUsersAfterEdit : newTotalSelectedUsersBeforeEdit
        );
      } else {
        if (isEditStarted === undefined) {
          setTotalSelectedUsers(totalSelectedUsers + 1);
        }
        setPrevDeselectedItems([]);
      }
    } else {
      setTotalSelectedUsers((prev) => (prev === editedTotalCount && !isSearch ? editedTotalCount : 0));
    }
  }, [selectionMode, selectedUsers.length, deselectedUsers.length, isAllUsers, summaryTotal]);
};

export const useCreateFeedTopicsSelection = (args: IUseCreateFeedTopicsSelectionArgs) => {
  const {
    selectionMode,
    setTotalSelectedTopics,
    summaryTotal,
    selectedTopics,
    deselectedTopics,
    setSelectionMode,
    totalSelectedTopics,
    isSearch,
  } = args;
  const [prevDeselectedItems, setPrevDeselectedItems] = useState(deselectedTopics);

  useEffect(() => {
    if (selectionMode === All) {
      setTotalSelectedTopics(summaryTotal || totalSelectedTopics);
      setPrevDeselectedItems([]);
    } else if (selectedTopics.length) {
      setPrevDeselectedItems([]);
      setTotalSelectedTopics(selectedTopics.length);
    } else if (deselectedTopics.length) {
      const deletedItems = deselectedTopics.length - prevDeselectedItems.length;
      setTotalSelectedTopics(totalSelectedTopics - deletedItems);
      setPrevDeselectedItems(deselectedTopics);
    } else if (selectionMode === Deselect && !deselectedTopics.length) {
      if (!isSearch && !!summaryTotal) {
        setSelectionMode(All);
        setTotalSelectedTopics(summaryTotal);
      } else {
        setTotalSelectedTopics(totalSelectedTopics + 1);
        setPrevDeselectedItems([]);
      }
    } else {
      setTotalSelectedTopics(0);
    }
  }, [selectionMode, selectedTopics.length, deselectedTopics.length, summaryTotal]);
};

export const useCreateFeedEvents = (
  selectionMode: ESelectionMode,
  setSelectionMode: Dispatch<SetStateAction<ESelectionMode>>,
  setSelectedItems: Dispatch<SetStateAction<string[]>>,
  setDeselectedItems: Dispatch<SetStateAction<string[]>>,
  setSelectFollowings?: Dispatch<SetStateAction<boolean>>,
  setIsEditStarted?: Dispatch<SetStateAction<boolean>>,
  setTotalSelectedUsers?: Dispatch<SetStateAction<number>>,
  isAllFollowings?: boolean,
  totalSelectedUsers?: number,
  selectedUsers?: string[],
  deselectedUsers?: string[],
  isAllFollowingsEdit?: boolean
) => {
  const handleSelection = (mode: ESelectionMode) => {
    setIsEditStarted?.(true);
    setSelectionMode((prev) => {
      setSelectedItems([]);
      setDeselectedItems([]);
      return prev === mode ? Select : mode;
    });
  };

  const handleSelectItem = (
    id: string,
    isFollowedByMe?: boolean | null,
    isSearch?: boolean,
    feedId?: string,
    isSelectedInFeed?: boolean
  ) => {
    setIsEditStarted?.(true);
    if (selectionMode === All || (selectionMode === Followings && !isSearch && isSelectedInFeed !== false)) {
      setSelectionMode(Deselect);
      setDeselectedItems([id]);
      if (isSelectedInFeed === undefined) {
        setSelectedItems([]);
      }
      if (isSearch) {
        setSelectFollowings?.(!isFollowedByMe);
      }
    } else if (
      (selectionMode === Followings && isSearch && !isFollowedByMe) ||
      (feedId && !isSelectedInFeed && isSearch)
    ) {
      setSelectedItems((prev) => (prev.includes(id) ? prev.filter((val) => val !== id) : [...prev, id]));
    } else if (
      (selectionMode === Followings && isSearch && isFollowedByMe) ||
      (!isAllFollowingsEdit && isAllFollowings && !isSelectedInFeed)
    ) {
      setDeselectedItems((prev) => (prev.includes(id) ? prev.filter((val) => val !== id) : [...prev, id]));
      setSelectFollowings?.(false);
    }
    // Edit Mode. Condition uses when Users saved with isAllUsers=true or isAllFollowings=true
    else if (
      (selectionMode === Select &&
        isSelectedInFeed &&
        selectedUsers !== undefined &&
        totalSelectedUsers === selectedUsers.length &&
        isAllFollowingsEdit) ||
      // Edit Mode. Condition when edit users with isAllUsers=false and isAllFollowings=false
      (selectionMode === Select && !isSelectedInFeed && totalSelectedUsers === 0 && !!deselectedUsers?.length)
    ) {
      setSelectedItems((prev) => {
        const newSelectedItems = prev.includes(id) ? prev.filter((val) => val !== id) : [...prev, id];
        if (!prev.includes(id)) {
          setTotalSelectedUsers?.((p) => p + 1);
        }
        return newSelectedItems;
      });
    } else if (selectionMode === Select && !isSelectedInFeed) {
      setSelectedItems((prev) => (prev.includes(id) ? prev.filter((val) => val !== id) : [...prev, id]));
    }

    // Edit Mode. Add to deselectedItems new items and cancel that
    else if (
      (selectionMode === Select && isSelectedInFeed) ||
      (selectionMode === Deselect && isSelectedInFeed !== false && isAllFollowings)
    ) {
      setDeselectedItems((prev) => {
        const newDeselectedItems = prev.includes(id) ? prev.filter((val) => val !== id) : [...prev, id];
        if (prev.includes(id)) {
          setTotalSelectedUsers?.((p) => p + 1);
        }
        return newDeselectedItems;
      });
    } else if (selectionMode === Deselect && isSelectedInFeed !== false) {
      setDeselectedItems((prev) => (prev.includes(id) ? prev.filter((val) => val !== id) : [...prev, id]));
      setSelectFollowings?.(!isFollowedByMe);
    } else if (selectionMode === Deselect && isSelectedInFeed === false) {
      setSelectedItems((prev) => (prev.includes(id) ? prev.filter((val) => val !== id) : [...prev, id]));
    }
  };
  return { handleSelection, handleSelectItem };
};

export const useCreateFeedUsers = (args: IUseCreateFeedUsersArgs) => {
  const {
    selectionMode,
    setAllFollowings,
    setAllUsers,
    summaryTotalUsers,
    totalSelectedUsers,
    deselectedUsers,
    selectedUsers,
    isAllUsersEdit,
    isAllFollowingsEdit,
    isAllFollowings,
    isEditStarted,
    setTotalSelectedUsers,
  } = args;
  useEffect(() => {
    if (
      selectionMode === Followings ||
      (isAllFollowingsEdit && selectionMode === Select && !isAllUsersEdit && !isEditStarted)
    ) {
      setAllFollowings(true);
      setAllUsers(false);
    }
    if (
      selectionMode === All ||
      summaryTotalUsers - deselectedUsers.length === totalSelectedUsers ||
      (isAllUsersEdit && selectionMode === Select && !isAllFollowings && isAllFollowingsEdit)
    ) {
      setAllUsers(true);
      setAllFollowings(true);
    }
    if (isAllUsersEdit && selectionMode === Select && !isAllFollowings && !isAllFollowingsEdit) {
      setAllUsers(true);
      setAllFollowings(false);
    }
    if (
      (selectedUsers.length === totalSelectedUsers && !isAllFollowingsEdit && !isAllUsersEdit) ||
      (isAllFollowingsEdit && selectionMode === Select && totalSelectedUsers === 0) ||
      (isAllUsersEdit && selectionMode === Select && totalSelectedUsers === 0) ||
      (selectionMode === Select && totalSelectedUsers === selectedUsers.length)
    ) {
      setAllFollowings(false);
      setAllUsers(false);
    }
    if (
      (isEditStarted && isAllFollowingsEdit && selectionMode === Select && !selectedUsers.length) ||
      (isEditStarted && isAllFollowings && selectionMode === Select && !selectedUsers.length)
    ) {
      setAllFollowings(false);
      setAllUsers(false);
      setTotalSelectedUsers?.(0);
    }
  }, [selectionMode, selectedUsers, deselectedUsers, totalSelectedUsers, isAllFollowingsEdit, isAllFollowingsEdit]);
};

export const useFilterFeeds = (
  currentTags: MediaTagSchema[],
  feedTags: MediaTagSchema[],
  setFeedTags: Dispatch<SetStateAction<MediaTagSchema[]>>,
  includedFilters: string[],
  setIncludedFilters: Dispatch<SetStateAction<string[]>>,
  excludedFilters: string[],
  setExcludedFilters: Dispatch<SetStateAction<string[]>>,
  totalPicks: number,
  setTotalPicks: Dispatch<SetStateAction<number>>,
  totalCount: number,
  loading: boolean,
  feedId: string
) => {
  const { newAdminFeedFilters, setNewAdminFeedFilters } = useAdminFeedFiltersContext();
  const { included, excluded } = newAdminFeedFilters?.find((filter) => filter.feedId === feedId) ?? {};

  const handleClearAll = () => {
    setFeedTags((prev) =>
      prev.map((mediaTag) => {
        return { ...mediaTag, isSelectedInFeedFilters: false };
      })
    );
    setIncludedFilters([]);
    setExcludedFilters(
      currentTags.reduce(
        (prev: string[], curr: MediaTagSchema) => (curr.isSelectedInFeedFilters ? prev.concat(curr.id) : prev),
        []
      )
    );
    if (setNewAdminFeedFilters) {
      setNewAdminFeedFilters((prev) => {
        const currentFeed = prev.find((adminFeed) => adminFeed.feedId === feedId);

        return currentFeed ? [...prev, { ...currentFeed, excluded: [], included: [] }] : prev;
      });
    }
  };

  const handleSelectItem = (tagId: string) => {
    if (feedTags.find(({ id }) => id === tagId)?.isSelectedInFeedFilters) {
      if (includedFilters.includes(tagId)) {
        setIncludedFilters(includedFilters.filter((id) => id !== tagId));
      } else {
        setExcludedFilters((prev) => [...prev, tagId]);
      }
    }
    if (!feedTags.find(({ id }) => id === tagId)?.isSelectedInFeedFilters) {
      if (excludedFilters.includes(tagId)) {
        setExcludedFilters(excludedFilters.filter((id) => id !== tagId));
      } else {
        setIncludedFilters((prev) => [...prev, tagId]);
      }
    }

    setFeedTags((prev) =>
      prev.map((mediaTag) =>
        mediaTag.id === tagId ? { ...mediaTag, isSelectedInFeedFilters: !mediaTag.isSelectedInFeedFilters } : mediaTag
      )
    );
  };

  useEffect(() => {
    if (currentTags) {
      setFeedTags(
        currentTags.map((tag) => {
          if (includedFilters?.includes(tag.id)) {
            return { ...tag, isSelectedInFeedFilters: true };
          }
          if (excludedFilters?.includes(tag.id)) {
            return { ...tag, isSelectedInFeedFilters: false };
          }
          return tag;
        })
      );
    }
    if (excluded?.length || included?.length) {
      setFeedTags((prev) => {
        return prev.map((tag) => {
          if (included?.includes(tag.id)) {
            return { ...tag, isSelectedInFeedFilters: true };
          }
          if (excluded?.includes(tag.id)) {
            return { ...tag, isSelectedInFeedFilters: false };
          }
          return tag;
        });
      });
    }
  }, [currentTags, excluded?.length, included?.length]);

  useEffect(() => {
    if (!loading) {
      setTotalPicks(totalCount);
    }
  }, [totalCount, loading]);

  return { handleClearAll, handleSelectItem, totalPicksCount: totalPicks };
};
