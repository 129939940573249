import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useCheckUsernameAvailable } from 'graphQL/auth/hooks';
import { useUpdateOnboardingStep, useUpdateCachedOnboardingStep } from 'graphQL/onboarding/hooks';

import { TEST_USER_INFO_TITLE, TEST_USER_INFO_USERNAME_TEXT_FIELD } from 'constants/aqa/signup';
import { AuthSchema, useUpdateUserMutation, EOnboardingStepOptions } from 'constants/graphqlTypes';
import { ROUTE_ONBOARDING_BIO } from 'routes';

import getAuthUser from 'helpers/getAuthUser';
import useDebouncedValue from 'helpers/useDebounced';
import useTheme from 'helpers/useTheme';

import { USERNAME_LENGTH } from 'components/Authentication/constants';
import Layout from 'components/Onboarding/Layout';
import TextInput from 'components/UI/TextInput';
import { validateUsername } from 'components/Authentication/helpers';
import ContentTitle from 'components/UI/ContentTitle';

import { StyledInputWrapper } from './styled';

export const changeLocalStorageUsername = (newUsername: string): void => {
  const localDataJson = localStorage.getItem('userData');
  const { authToken }: { authToken: AuthSchema } = localDataJson ? JSON.parse(localDataJson) : {};
  const newData: AuthSchema = { ...authToken, userAuth: { ...authToken.userAuth, username: newUsername } };
  localStorage.setItem('userData', JSON.stringify({ authToken: newData }));
};

const reservedError = (newUsername: string) => (
  <span>
    This username is reserved. To claim it, please contact us at{' '}
    <a href={`mailto:hello@guidehuman.co?subject=Username reservation: ${newUsername}`}>hello@guidehuman.co</a>
  </span>
);

const Username: FC = () => {
  const { push } = useHistory();
  const { username: storageUsername } = getAuthUser();
  const isDark = useTheme();

  const [username, setUsernameText] = useState(storageUsername);
  const [usernameError, setUsernameError] = useState<string | ReactNode>('');

  const hasChanges = storageUsername !== username;

  const [availabilityHint, setAvailabilityHint] = useState<[string] | undefined>(undefined);
  const debouncedUsername = useDebouncedValue(username, 500);
  const updateCache = useUpdateCachedOnboardingStep(EOnboardingStepOptions.Description);

  const [updateUserMutation] = useUpdateUserMutation({
    onCompleted: ({ updateUser }) => {
      if (updateUser?.username) {
        changeLocalStorageUsername(updateUser?.username);
      }
      updateCache();
      push(ROUTE_ONBOARDING_BIO);
    },
    onError: ({ message }) => {
      setUsernameError(message);
      setAvailabilityHint(undefined);
    },
  });

  const skipCheckUsernameAvailable = !hasChanges || storageUsername === debouncedUsername || !debouncedUsername;
  const { loading, errorCode } = useCheckUsernameAvailable(debouncedUsername, skipCheckUsernameAvailable);

  const handleDone = () => {
    if (!hasChanges) {
      push(ROUTE_ONBOARDING_BIO);
    } else if (!usernameError && !loading) {
      updateUserMutation({
        variables: {
          username,
        },
      });
    }
  };

  useEffect(() => {
    if (errorCode) {
      switch (errorCode) {
        case 1004:
          setUsernameError(reservedError(username));
          return;
        case 1005:
          setUsernameError(validateUsername(username));
          return;
        default:
          setUsernameError('Username is unavailable');
      }
    } else {
      setUsernameError(hasChanges ? validateUsername(username) : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, username]);

  useEffect(() => {
    if (usernameError) {
      setAvailabilityHint(undefined);
    } else {
      setAvailabilityHint(loading ? ['Checking availability...'] : ['Username is available']);
    }
  }, [loading, usernameError]);

  useUpdateOnboardingStep(EOnboardingStepOptions.Username);

  return (
    <>
      <Layout primaryBtnText="Continue" handlePrimaryBtn={handleDone}>
        <ContentTitle dataTestId={TEST_USER_INFO_TITLE} isDark={isDark}>
          What should we call you?
        </ContentTitle>
        <StyledInputWrapper isDark={isDark}>
          <TextInput
            dataTestId={TEST_USER_INFO_USERNAME_TEXT_FIELD}
            hints={availabilityHint}
            maxLength={USERNAME_LENGTH[1] + 1}
            isDark={isDark}
            placeholder="username"
            text={username}
            setText={(value: string) => setUsernameText(value.toLocaleLowerCase())}
            error={usernameError}
            longDash={false}
          />
        </StyledInputWrapper>
      </Layout>
    </>
  );
};

export default Username;
