import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import useTheme from 'helpers/useTheme';
import usePageType from 'helpers/usePageType';
import useDocumentTitle from 'helpers/useDocumentTitle';
import ListGrid from 'components/List/Grid';
import ListHeader from 'components/List/Header';
import ListContent from 'components/List/Content';

import { useGetUserId, useProfile } from 'graphQL/profile/hooks';
import { getCoverGradient } from 'components/List/Header/helper';
import { useAllPicksCover, useGetUserCards } from 'graphQL/cards/userCards/hooks';
import { COLORS } from 'styles/constants';
import { ALL_PICKS } from 'constants/common';
import { ECollectionLayoutOptions, CardTypeOptions } from 'constants/graphqlTypes';
import { TEST_ALL_PICKS_PAGE } from 'constants/aqa/profile';

const AllPicks: FC = () => {
  const { profileName } = useParams<{ profileName: string }>();
  const { userId } = useGetUserId(profileName);
  const { userData } = useProfile(userId);
  const isDark = useTheme();
  const { userStyle } = userData ?? {};
  const type = userStyle?.layoutCollection === ECollectionLayoutOptions.ImageGrid ? CardTypeOptions.Card : undefined;
  const { recentCards, loadMore, totalCount, loading: loadingItems, wasCompleted } = useGetUserCards({
    type,
    includeQuestionResponse: true,
  });
  const { images, titles } = useAllPicksCover();
  const { isMyProfile } = usePageType();

  const color = isDark ? COLORS.black[100] : COLORS.white[100];
  const hasPicks = !!recentCards.length;

  useDocumentTitle('All picks');
  const bgColor = getCoverGradient(recentCards, !!images.length) ?? color;

  return (
    <>
      <ListHeader titles={titles.slice(0, 4)} images={images} bgColor={bgColor} />
      {(wasCompleted || hasPicks) && <ListContent title={ALL_PICKS} totalCount={totalCount} user={userData} />}
      <ListGrid
        hideActionsInfo={isMyProfile}
        layout={userStyle?.layoutCollection ?? ECollectionLayoutOptions.Default}
        items={recentCards}
        loadMore={loadMore}
        loading={loadingItems}
        pageTestId={TEST_ALL_PICKS_PAGE}
      />
    </>
  );
};

export default AllPicks;
