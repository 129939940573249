import { styled } from 'linaria/lib/react';

export const StyledEmoji = styled.div<{ xCoord: number; yCoord: number }>`
  position: fixed;
  top: ${({ yCoord }) => `${yCoord}dvh`};
  left: ${({ xCoord }) => xCoord}%;

  animation: fullscreen 1500ms linear forwards;
  will-change: transform opacity;
  font: 400 48px/120%;
  pointer-events: none;
  user-select: none;
  z-index: 1000;

  &:nth-child(even) {
    font-size: 36px;
  }
  @keyframes fullscreen {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      transform: translateY(-90dvh);
      opacity: 0;
    }
  }
`;
