import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS } from '../../styles/constants';

export const StyledRightArrowBtn = styled.button<{ color: string; isDisabled?: boolean }>`
  background: ${COLORS.transparent};
  border-radius: 50%;
  border: 0 transparent;
  cursor: pointer;
  height: 48px;
  padding: 0;
  pointer-events: ${({ isDisabled }) => (!isDisabled ? 'all' : 'none')};
  visibility: ${({ isDisabled }) => (!isDisabled ? 'visible' : 'hidden')};
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${({ color }) => (color === COLORS.white[100] ? COLORS.white[10] : COLORS.black[10])};
  }

  path {
    fill: ${({ color }) => color};
  }
`;

export const StyledLeftArrowBtn = styled(StyledRightArrowBtn)`
  svg {
    transform: rotate(180deg);
  }
`;

export const StyledSlider = styled.div<{ showBtnsSm?: boolean; arrowsOffset?: number }>`
  max-width: 100vw;
  width: 100%;
  position: relative;

  .slider-prev,
  .slider-next {
    display: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.m}px) {
    display: flex;
    position: relative;

    .swiper {
      flex: 1;
    }

    .swiper-slide:first-of-type {
      padding-left: 0;
    }

    .slider-prev,
    .slider-next {
      display: ${({ showBtnsSm }) => (showBtnsSm ? 'flex' : 'none')};
      top: ${({ arrowsOffset }) => (arrowsOffset ? `${arrowsOffset}px` : '50%')};
      transform: translateY(-50%);
    }

    .slider-prev {
      position: absolute;
      left: calc(-1 * min(14vw, 150px));
    }

    .slider-next {
      position: absolute;
      right: calc(-1 * min(14vw, 150px));
    }
  }
`;
