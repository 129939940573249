import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal';

import { useProfileSettingsLinks } from '../../hooks';
import UploadPhoto from './UploadPhoto';
import CropImage from './CropImage';
import AddFromLink from '../AddFromLink';
import { useUploadImage } from './hooks';

const ChangePhoto: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const { replace } = useHistory();
  const { editProfile } = useProfileSettingsLinks();
  const handleNext = () => replace(editProfile);

  const { url, isAddFromLink, loading, setUrl, handleDone, setIsAddFromLink, setCropper } = useUploadImage(handleNext);

  return !isAddFromLink ? (
    <Modal
      fadeIn={false}
      handleClose={handleClose}
      handlePrev={() => replace(editProfile)}
      buttonText="Save"
      loading={loading}
      handleDone={url ? handleDone : undefined}
    >
      {!url ? (
        <UploadPhoto setUrl={setUrl} setIsAddFromLink={setIsAddFromLink} />
      ) : (
        <CropImage url={url} setCropper={setCropper} />
      )}
    </Modal>
  ) : (
    <AddFromLink handleClose={handleClose} setUrl={setUrl} setIsAddFromLink={setIsAddFromLink} />
  );
};

export default ChangePhoto;
