export const BREAKPOINTS = {
  xs: 410,
  s: 528,
  m: 746,
  l: 1280,
  xl: 1640,
  xxl: 3000,
  retinaScale: 2.4,
};

export const FONTS = {
  primary: "'CaslonIonic', serif",
  secondary: "'Yellix', sans-serif",
  appleDevises: 'SF Pro Text',
};

const BLACK = '#000000';
const BROWN = '#362E0B';
const DARK_BROWN = '#181800';
const LIGHT_BROWN = '#7A7359';
const GRAY = '#C2BDB2';
const OFF_WHITE = '#EFEEEA';
const WHITE = '#FFFFFF';
const RED = '#E86351';

export const BRANDED_GRADIENTS = [
  'linear-gradient(180deg, #C169C8 0%, #2F7FAA 100%)',
  'linear-gradient(180deg, #BDD900 0%, #0071D9 100%)',
];

export const ALLPICKS_ALLSPARKS_SHARING_BRANDED_GRADIENT = 'linear-gradient(180deg, #2A657E 0%, #B977AD 100%)';
export const GUEST_LIST_GRADIENT = `linear-gradient(180deg, #0c3059 0%, #d3a0e0 100%)`;
export const DEFAULT_TOPIC_GRADIENT = 'linear-gradient(0deg, #BDD900 20%, #EFEEEA 100%)';

export const COLORS = {
  black: {
    '10': `${BLACK}1A`,
    '20': `${BLACK}33`,
    '25': `${BLACK}40`,
    '40': `${BLACK}66`,
    '50': `${BLACK}80`,
    '60': `${BLACK}99`,
    '100': BLACK,
  },
  blue: '#007AFF',
  brown: {
    '40': `${BROWN}66`,
    '100': BROWN,
    dark: {
      '1': `${DARK_BROWN}03`,
      '8': `${DARK_BROWN}14`,
      '10': `${DARK_BROWN}1A`,
      '30': `${DARK_BROWN}4D`,
      '40': `${DARK_BROWN}66`,
      '70': `${DARK_BROWN}B3`,
      '100': DARK_BROWN,
    },
    mid: '#5B5643',
    light: {
      '30': `${LIGHT_BROWN}4D`,
      '100': LIGHT_BROWN,
    },
  },
  gold: '#DB9C03',
  gray: {
    '50': `${GRAY}80`,
    '100': GRAY,
    light: '#F2F2F2',
    dark: '#6f6f7b',
  },
  green: {
    light: '#BDD900',
    dark: '#6B7A00',
  },
  red: {
    '100': RED,
    danger: '#D73C28',
    dark: '#FF3B30',
    semiLight: '#F8C8C5',
    light: '#FFE7E6',
  },
  transparent: '#FFFFFF00',
  white: {
    '3': `${WHITE}08`,
    '5': `${WHITE}0d`,
    '8': `${WHITE}14`,
    '10': `${WHITE}1A`,
    '16': `${WHITE}29`,
    '20': `${WHITE}33`,
    '30': `${WHITE}4D`,
    '40': `${WHITE}66`,
    '50': `${WHITE}80`,
    '60': `${WHITE}99`,
    '80': `${WHITE}CC`,
    '90': `${WHITE}E6`,
    '100': WHITE,
    off: {
      '50': `${OFF_WHITE}80`,
      '100': OFF_WHITE,
    },
  },
  yellow: '#FFF2CC',
};
