import { styled } from 'linaria/react';
import { COLORS, FONTS } from '../../../styles/constants';

export const StyledWrap = styled.div<{ isTransparent: boolean; hasBottomMargin: boolean; isDark: boolean }>`
  background-color: ${({ isTransparent }) => (isTransparent ? COLORS.transparent : COLORS.green.light)};
  border: ${({ isTransparent, isDark }) => {
    if (!isTransparent) {
      return 'none';
    }
    return `1px solid ${isDark ? COLORS.white[30] : COLORS.white.off[100]}`;
  }};
  border-radius: 20px;
  padding: 20px 16px;
  position: relative;
  margin-bottom: ${({ hasBottomMargin }) => (hasBottomMargin ? '24px' : 0)};
`;

export const StyledTitle = styled.span<{ titleSize?: number; isTransparent: boolean; isDark: boolean }>`
  color: ${({ isTransparent, isDark }) => (isTransparent && isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  display: block;
  font-family: ${FONTS.primary};
  font-weight: 400;
  font-size: ${({ titleSize }) => titleSize ?? 14}px;
  line-height: 140%;
  padding-right: 16px;
`;

export const StyledText = styled(StyledTitle)<{ isFirst: boolean; isTransparent: boolean; isDark: boolean }>`
  color: ${({ isTransparent, isDark }) => (isTransparent && isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font-family: ${FONTS.secondary};
  padding: ${({ isFirst }) => (isFirst ? 0 : 16)}px 0 0;
  overflow-wrap: break-word;
`;

export const StyledButtons = styled.div<{ isFirst: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: ${({ isFirst }) => (isFirst ? 0 : 20)}px;
`;

export const StyledCross = styled.button`
  background-color: ${COLORS.transparent};
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 16px;
`;
