import React, { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import useTheme from 'helpers/useTheme';
import SinglePageLayout from 'components/SinglePageLayout';

import { useCreateHiddenWords, useGetUserHiddenWords, useRemoveHiddenWords } from 'graphQL/hiddenWords/hooks';

import LoaderContent from 'components/UI/LoaderContent';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';

import { StyledDescription } from 'components/UI/MenuButton/styled';
import { StyledInputWrap, StyledItem } from './styled';

const CustomWords: FC = () => {
  const isDark = useTheme();
  const [text, setText] = useState('');
  const { items, loading, loadMore } = useGetUserHiddenWords();
  const { createHiddenWords } = useCreateHiddenWords(text);
  const removeHiddenWords = useRemoveHiddenWords();

  const onEnter = () => {
    if (text.trim().length) {
      createHiddenWords();
      setText('');
    }
  };

  return (
    <SinglePageLayout title="Custom words and phrases">
      <StyledInputWrap>
        <Input
          text={text}
          setText={setText}
          placeholder="Add words separated by commas..."
          isDark={isDark}
          hasCrossIcon
          handleKeyPress={onEnter}
        />
      </StyledInputWrap>
      <StyledDescription>
        Add multiple words, phrases and emojis, separated by commas. Your list can be updated anytime.
      </StyledDescription>
      <div>
        {items?.map((item) => (
          <StyledItem key={item?.id}>
            <span>{item?.content}</span>
            <Button text="Delete" handler={() => removeHiddenWords(item?.id)} isDark={isDark} />
          </StyledItem>
        ))}
        {loading && items ? <LoaderContent isFullScreen={!items.length} /> : <Waypoint onEnter={loadMore} />}
      </div>
    </SinglePageLayout>
  );
};

export default CustomWords;
