import { WEB_PREFIX } from 'constants/common';

export const BURGER_MENU = 'burger-menu';

export const TEST_ID_BURGER_MENU_START = `${WEB_PREFIX}${BURGER_MENU}`;
export const TEST_BURGER_MENU_ACTIVITY_BTN = `${TEST_ID_BURGER_MENU_START}_activity-button`;
export const TEST_BURGER_MENU_ABOUT_US_BTN = `${TEST_ID_BURGER_MENU_START}_about-us-button`;
export const TEST_BURGER_MENU_SETTINGS_BTN = `${TEST_ID_BURGER_MENU_START}_settings-button`;
export const TEST_BURGER_MENU_HELP_BTN = `${TEST_ID_BURGER_MENU_START}_help-button`;
export const TEST_BURGER_MENU_LOG_OUT_BTN = `${TEST_ID_BURGER_MENU_START}_log-out-button`;
export const TEST_BURGER_MENU_CLOSE_BTN = `${TEST_ID_BURGER_MENU_START}_close-button`;
