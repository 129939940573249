import React, { FC } from 'react';

import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';
import { UserObjectType, UserShortSchema } from 'constants/graphqlTypes';
import { generateFullName, getUserInfo } from 'components/Profile/ProfileTop/helpers';
import { ROUTE_USER_PROFILE } from 'routes';
import { StyledUsername as StyledFullname } from 'components/Sparks/Views/Card/styled';
import { StyledContent, StyledWrapper, StyledWrap, StyledUsername, StyledName } from './styled';

import Avatar from '../Avatar';
import UILink from '../Link';

interface IBadgeProps {
  user: UserObjectType | UserShortSchema;
  isDark?: boolean;
  hasUsername?: boolean;
  hasNameOnly?: boolean;
  hasVerificationMark?: boolean;
  isDisabled?: boolean;
  isBold?: boolean;
}

const Badge: FC<IBadgeProps> = ({ user, isDark = true, hasUsername, hasNameOnly, isDisabled, isBold = true }) => {
  const { isVerified } = user;
  const { username, bio } = getUserInfo(user);
  const name = generateFullName(user);

  return (
    <StyledWrap className="badge">
      <UILink route={ROUTE_USER_PROFILE} params={{ profileName: username }} isDisabled={isDisabled}>
        <StyledWrapper>
          <Avatar user={user} />
          <StyledContent isDark={isDark} className="badge-content">
            {hasNameOnly ? (
              <StyledName isBold={isBold}>
                <StyledFullname>{name}</StyledFullname>
                {isVerified && <VerificationBadge />}
              </StyledName>
            ) : (
              <>
                <StyledName isBold={isBold}>
                  <span className="badge-title">{name}</span>
                  {isVerified && <VerificationBadge />}
                </StyledName>
                {hasUsername ? <StyledUsername className="badge-subtitle">{`:${username}`}</StyledUsername> : bio}
              </>
            )}
          </StyledContent>
        </StyledWrapper>
      </UILink>
    </StyledWrap>
  );
};

export default Badge;
