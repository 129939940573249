import { UnionExtraDataListObjectType } from 'constants/graphqlTypes';

export const addListenerMulti = (element: HTMLDivElement | null, events: string[], listener: () => void): void => {
  events.forEach((event) => element?.addEventListener(event, listener));
};

export const removeListenerMulti = (element: HTMLDivElement | null, events: string[], listener: () => void): void => {
  events.forEach((event) => element?.removeEventListener(event, listener));
};

export const getFilteredActions = (extraData?: UnionExtraDataListObjectType | null, isDiscovery?: boolean) => {
  if (!extraData || !extraData.items) return [];

  const hasCommentsInFallbacks =
    extraData.items.length > 1 && extraData.items.some((action) => action?.__typename === 'CardCommentSchema');

  if (hasCommentsInFallbacks && isDiscovery) {
    return extraData.items.filter(
      (action) =>
        (action.__typename === 'CardCommentSchema' && !action.isSuspicious) ||
        action.__typename === 'CardPickSchema' ||
        action.__typename === 'CollectionCardLinkSchema'
    );
  }

  if (hasCommentsInFallbacks && !isDiscovery) {
    return extraData.items.filter((action) => action.__typename === 'CardCommentSchema' && !action.isSuspicious);
  }

  return extraData.items;
};
