import { styled } from 'linaria/react';

import { StyledType } from 'components/CardDetails/styled';
import { MIN_BLOCK_HEIGHT } from 'components/CardDetails/helpers/constants';

export const StyledWrap = styled.div`
  img {
    max-height: 40vh;
  }
`;

export const StyledTitle = styled.span`
  display: block;
  font-size: 22px;
  line-height: 120%;
  padding: 0 0 20px;
  white-space: pre-wrap;
`;

export const StyledTag = styled(StyledType)`
  padding: 0 0 2px;
  text-transform: none;
`;

export const StyledDescription = styled.p`
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  white-space: pre-wrap;
`;

export const StyledImageWrap = styled.div<{ isImageSmall: boolean }>`
  max-height: 100%;
  min-height: 166px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledImage = styled.img<{ hasFullWidth?: boolean }>`
  width: ${({ hasFullWidth }) => (hasFullWidth ? '100%' : 'auto')};
  border-radius: 8px;
  min-height: ${MIN_BLOCK_HEIGHT}px;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  object-fit: cover;
`;
