import { styled } from 'linaria/react';
import { COLORS, FONTS } from 'styles/constants';

export const StyledSectionWrap = styled.section<{ isDark?: boolean }>`
  align-items: center;
  display: flex;
  font: 400 14px/140% ${FONTS.secondary};
  gap: 12px;
  margin-top 24px;

  svg {
    width: 24px;
    height: 24px;
     
    path {
      fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
    }
  }
`;

export const StyledIconWrap = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  min-width: 24px;
  width: 24px;
`;

export const StyledSectionHeader = styled.h2`
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;

  > button {
    display: flex;
    justify-content: center;
    margin: auto auto 0;
    max-width: 528px;
    width: 100%;
  }
`;

export const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100svh;
  padding: 0 24px 46px;
  max-width: 528px;
  margin: 0 auto;

  #single-page-layout {
    margin: 0;
  }
`;
