import { styled } from 'linaria/react';
import { StyledHeader as Header } from 'components/TabModal/styled';
import { StyledTitle } from 'components/GuestList/Card/styled';

export const StyledWrap = styled.div`
  .badge {
    padding: 16px 0;
  }
`;

export const StyledHeader = styled(Header)`
  padding: 0 0 20px;

  img {
    margin: 0;
  }
`;

export const StyledText = styled(StyledTitle)`
  word-spacing: normal;
  -webkit-line-clamp: 6;
`;
