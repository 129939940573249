import { ApolloCache } from '@apollo/client';
import {
  GetListItemsDocument,
  GetListItemsQuery,
  GetListItemsQueryVariables,
  useUnpickOnboardingCardsMutation,
} from 'constants/graphqlTypes';

interface IUseUnpickCards {
  loading: boolean;
  handleUnpick: () => void;
}

export const useUnpickCards = (selectedCards: string[], onComplete: () => void, listId?: string): IUseUnpickCards => {
  const [unpickCards, { loading }] = useUnpickOnboardingCardsMutation();

  const handleUnpick = (): void => {
    unpickCards({
      variables: {
        listId,
        pickIds: selectedCards,
      },
      update: (cache: ApolloCache<GetListItemsQuery>, { data }) =>
        cache.updateQuery<GetListItemsQuery, GetListItemsQueryVariables>(
          {
            query: GetListItemsDocument,
            variables: {
              id: listId,
              refListId: listId,
              offset: 0,
            },
          },
          (query: GetListItemsQuery | null) => {
            if (!query?.allCards?.items?.[0]?.cardsFromCollection?.items) {
              return { ...query };
            }

            const cardsIds = data?.contentTutorialBulkUnpickCard?.cardsFromCollection?.items.map(({ id }) => id) || [];
            const list = query.allCards.items[0] || {};
            const { cardsFromCollection } = list;
            const totalCount = data?.contentTutorialBulkUnpickCard?.cardsFromCollection?.totalCount || 0;
            const items = cardsFromCollection?.items?.filter((item) => cardsIds.includes(item.id)) || [];

            return {
              ...query,
              allCards: {
                ...query.allCards,
                items: [
                  {
                    ...list,
                    cardsFromCollection: {
                      ...cardsFromCollection,
                      totalCount,
                      items,
                    },
                  },
                ],
              },
            };
          }
        ),
      onCompleted: onComplete,
    });
  };

  return {
    loading,
    handleUnpick,
  };
};
