import { useLocation, useParams, matchPath } from 'react-router-dom';
import {
  ROUTE_ADD_STYLE,
  ROUTE_ADD_THOUGHT,
  ROUTE_ANSWER_ADD_STYLE,
  ROUTE_ANSWER_ADD_THOUGHT,
  ROUTE_BLOCKED_ACCOUNTS,
  ROUTE_CHECK_EMAIL,
  ROUTE_COMMUNITY,
  ROUTE_DISCOVERY,
  ROUTE_INVITE_USER_SHARING,
  ROUTE_MESSAGES_CHAT,
  ROUTE_MESSAGES_NEW,
  ROUTE_MESSAGES_SETTINGS,
  ROUTE_MESSAGES,
  ROUTE_MUTED_ACCOUNTS,
  ROUTE_ONBOARDING_FULLNAME,
  ROUTE_ONBOARDING_IMAGE,
  ROUTE_ONBOARDING_SUCCESS,
  ROUTE_ONBOARDING_WELCOME,
  ROUTE_ONBOARDING,
  ROUTE_PRIVACY,
  ROUTE_SPARK_RESP_ADD_STYLE,
  ROUTE_SPARK_RESP_ADD_THOUGHT,
  ROUTE_TERMS,
  ROUTE_ONBOARDING_PROFILE,
  ROUTE_ONBOARDING_COMPLETION,
  ROUTE_ONBOARDING_NETWORKING,
  ROUTE_ONBOARDING_NETWORKING_CONFIRMATION,
  ROUTE_ONBOARDING_PREVIEW,
  ROUTE_ONBOARDING_NETWORKING_USER_SUGGESTION,
  ROUTE_ONBOARDING_CONTENT,
  ROUTE_ONBOARDING_CONTENT_LIST_PREVIEW,
  ROUTE_ONBOARDING_CONTENT_CONFIRMATION_FINISH,
  ROUTE_ONBOARDING_CONTENT_LIST_DETAIL,
  ROUTE_ONBOARDING_PROFILE_INTRO,
  ROUTE_ONBOARDING_PROFILE_INTERESTS,
  ROUTE_ARCHIVE,
  ROUTE_CUSTOM_FEEDS,
  ROUTE_FEEDS_BY_TYPE,
  ROUTE_FEEDS_BY_TOPIC,
} from 'routes';
import { ILocationStateType } from 'helpers/useReferrerId';
import getAuthUser from './getAuthUser';

interface IUsePageType {
  isAboutUs: boolean;
  isActivity: boolean;
  isAddAnswer: boolean;
  isAddSparkResponse: boolean;
  isAddSparkResponseStyle: boolean;
  isAddSparkResponseThought: boolean;
  isAddThought: boolean;
  isAllPicks: boolean;
  isAllPicksItem: boolean;
  isAllSparks: boolean;
  isAnotherProfile: boolean;
  isAnswerAddStyle: boolean;
  isAnswerAddThought: boolean;
  isBlockedAccounts: boolean;
  isCardDetail: boolean;
  isCheckEmail: boolean;
  isDetail: boolean;
  isDiscovery: boolean;
  isEveryoneTab: boolean;
  isExactlyProfile: boolean;
  isFollowingTab: boolean;
  isGuestList: boolean;
  isGuestListSeries: boolean;
  isHelp: boolean;
  isInvite: boolean;
  isItemDetail: boolean;
  isLegalPage: boolean;
  isListDetail: boolean;
  isListItem: boolean;
  isMessages: boolean;
  isMessagesChat: boolean;
  isMessagesList: boolean;
  isMessagesNew: boolean;
  isMessagesSearch: boolean;
  isMessagesSettings: boolean;
  isMutedAccounts: boolean;
  isMyProfile: boolean;
  isOnboarding: boolean;
  isOnboardingContent: boolean;
  isOnboardingContentListPreview: boolean;
  isOnboardingContentConfirmation: boolean;
  isOnboardingFullname: boolean;
  isOnboardingProfileOrNested: boolean;
  isOnboardingProfileIntro: boolean;
  isOnboardingProfileInterests: boolean;
  isOnboardingImage: boolean;
  isOnboardingListDetail: boolean;
  isOnboardingNetworkingJoin: boolean;
  isOnboardingNetworkingConfirmation: boolean;
  isOnboardingNetworkingUserSuggestion: boolean;
  isOnboardingProfileCompletion: boolean;
  isOnboardingPreview: boolean;
  isOnboardingSuccess: boolean;
  isOnboardingVerification: boolean;
  isOnboardingWelcome: boolean;
  isPickAddStyle: boolean;
  isPickAddThought: boolean;
  isProfile: boolean;
  isSearch: boolean;
  isSettings: boolean;
  isShareSheet: boolean;
  isSharingPage: boolean;
  isSparkDetail: boolean;
  isThoughtsPage: boolean;
  isTopics: boolean;
  isSavedPicks: boolean;
  isArchive: boolean;
  isChannelsLists: boolean;
  isCustomFeeds: boolean;
  isFeedsByType: boolean;
  isFeedsByTopic: boolean;
  isFeedDetail: boolean;
}

const usePageType = (): IUsePageType => {
  const { pathname } = useLocation<ILocationStateType>();
  const { profileName } = useParams<{ profileName: string }>();
  const { username } = getAuthUser();
  const isThirdLevel = pathname.split('/').length > 3;

  const isAboutUs = pathname.includes('/about-us');
  const isActivity = pathname.includes('/activity');

  // ADD ANSWER ROUTES
  const isAddAnswer = pathname.includes('/add-answer');
  const isAnswerAddStyle = pathname === ROUTE_ANSWER_ADD_STYLE;
  const isAnswerAddThought = pathname === ROUTE_ANSWER_ADD_THOUGHT;

  // ADD SPARK RESPONSE ROUTES
  const isAddSparkResponse = pathname.includes('/spark-response');
  const isAddSparkResponseStyle = pathname === ROUTE_SPARK_RESP_ADD_STYLE;
  const isAddSparkResponseThought = pathname === ROUTE_SPARK_RESP_ADD_THOUGHT;

  const isAddThought = pathname.includes('/add-thought');

  // ALL PICKS ROUTES
  const isAllPicks = pathname.includes('/all-picks');
  const isAllPicksItem = isAllPicks && isThirdLevel;

  const isAllSparks = pathname.includes('/all-sparks');
  const isBlockedAccounts = pathname === ROUTE_BLOCKED_ACCOUNTS;
  const isCardDetail = pathname.includes('/picks/');
  const isCheckEmail = pathname === ROUTE_CHECK_EMAIL;
  const isDiscovery = pathname === ROUTE_DISCOVERY;
  const isEveryoneTab = pathname.includes('/everyone/');
  const isFollowingTab = pathname.includes('/following/');
  const isHelp = pathname.includes('/help');
  const isInvite = pathname.includes('/invite');

  // GUEST LIST ROUTES
  const isGuestList = pathname.includes('/guest-lists/');
  const isGuestListSeries = pathname.includes('/guest-lists-series');

  // TOPICS
  const isTopics = pathname.includes('/topics');
  const isTopicsItem = isTopics && isThirdLevel;

  // FEEDS
  const isFeedsByType = pathname.includes(ROUTE_FEEDS_BY_TYPE);
  const isFeedsByTopic = pathname.includes(ROUTE_FEEDS_BY_TOPIC);
  const isFeedDetail = pathname.includes('/feed/');
  const isFeedItem = isFeedDetail && isThirdLevel;

  // LIST ITEM ROUTES
  const isListDetail = pathname.includes('/lists/');
  const isListItem = isListDetail && isThirdLevel;
  const isItemDetail =
    isListItem || isCardDetail || isAllPicksItem || isFollowingTab || isEveryoneTab || isTopicsItem || isFeedItem;
  const isDetail = isCardDetail || isListDetail || isAllPicks;

  const isLegalPage = pathname === ROUTE_PRIVACY || pathname === ROUTE_TERMS || pathname === ROUTE_COMMUNITY;

  // MESSAGES ROUTES
  const isMessages = pathname.includes('/messages');
  const isMessagesList = pathname === ROUTE_MESSAGES;
  const isMessagesNew = pathname === ROUTE_MESSAGES_NEW;
  const isMessagesSettings = pathname === ROUTE_MESSAGES_SETTINGS;
  const isMessagesSearch = pathname.includes('/messages/search');
  const isMessagesChat =
    !!matchPath(pathname, {
      path: ROUTE_MESSAGES_CHAT,
      exact: true,
      strict: true,
    }) &&
    !isMessagesNew &&
    !isMessagesSearch &&
    !isMessagesSettings;

  const isMutedAccounts = pathname === ROUTE_MUTED_ACCOUNTS;

  // ONBOARDING ROUTES
  const isOnboarding = pathname.includes(ROUTE_ONBOARDING);
  const isOnboardingVerification = pathname === ROUTE_ONBOARDING;
  const isOnboardingWelcome = pathname === ROUTE_ONBOARDING_WELCOME;
  const isOnboardingFullname = pathname === ROUTE_ONBOARDING_FULLNAME;
  const isOnboardingImage = pathname === ROUTE_ONBOARDING_IMAGE;
  const isOnboardingSuccess = pathname === ROUTE_ONBOARDING_SUCCESS;

  // ONBOARDING V2 ROUTES
  const isOnboardingPreview = pathname.includes(ROUTE_ONBOARDING_PREVIEW);
  const isOnboardingProfileOrNested = pathname.includes(ROUTE_ONBOARDING_PROFILE);
  const isOnboardingProfileIntro = pathname.includes(ROUTE_ONBOARDING_PROFILE_INTRO);
  const isOnboardingProfileInterests = pathname.includes(ROUTE_ONBOARDING_PROFILE_INTERESTS);
  const isOnboardingProfileCompletion = pathname.includes(ROUTE_ONBOARDING_COMPLETION);
  const isOnboardingNetworkingJoin = pathname === ROUTE_ONBOARDING_NETWORKING;
  const isOnboardingNetworkingConfirmation = pathname === ROUTE_ONBOARDING_NETWORKING_CONFIRMATION;
  const isOnboardingNetworkingUserSuggestion = pathname === ROUTE_ONBOARDING_NETWORKING_USER_SUGGESTION;
  const isOnboardingContent = new RegExp(`${ROUTE_ONBOARDING_CONTENT}/*$`).test(pathname);
  const isOnboardingContentConfirmation = pathname.includes(ROUTE_ONBOARDING_CONTENT_CONFIRMATION_FINISH);
  const isOnboardingContentListPreview = pathname.includes(ROUTE_ONBOARDING_CONTENT_LIST_PREVIEW);
  const isOnboardingListDetail = pathname === ROUTE_ONBOARDING_CONTENT_LIST_DETAIL;

  // PICK ADD ROUTES
  const isPickAddStyle = pathname === ROUTE_ADD_STYLE;
  const isPickAddThought = pathname === ROUTE_ADD_THOUGHT;

  // PROFILE ROUTES
  const isProfile = pathname.includes('/@');
  const isMyProfile = profileName === username;
  const isAnotherProfile = isProfile && !isMyProfile;
  const isExactlyProfile = isProfile && !isAllPicks && !isAllPicksItem && !isAllSparks;

  const isSearch = pathname.includes('/search');
  const isSettings = pathname.includes('/settings');
  const isShareSheet = pathname === ROUTE_INVITE_USER_SHARING;
  const isSharingPage = pathname.includes('/join');
  const isSparkDetail = pathname.includes('/sparks/');
  const isThoughtsPage = pathname.includes('/thoughts');
  const isSavedPicks = pathname.includes('/saved-picks');
  const isArchive = pathname === ROUTE_ARCHIVE;
  const isChannelsLists = pathname.includes('/channels-lists/');
  const isCustomFeeds = pathname.includes(ROUTE_CUSTOM_FEEDS);

  return {
    isAboutUs,
    isActivity,
    isAddAnswer,
    isAddSparkResponse,
    isAddSparkResponseStyle,
    isAddSparkResponseThought,
    isAddThought,
    isAllPicks,
    isAllPicksItem,
    isAllSparks,
    isAnotherProfile,
    isAnswerAddStyle,
    isAnswerAddThought,
    isBlockedAccounts,
    isCardDetail,
    isCheckEmail,
    isDetail,
    isDiscovery,
    isEveryoneTab,
    isExactlyProfile,
    isFollowingTab,
    isGuestList,
    isGuestListSeries,
    isHelp,
    isInvite,
    isItemDetail,
    isLegalPage,
    isListDetail,
    isListItem,
    isMessages,
    isMessagesChat,
    isMessagesList,
    isMessagesNew,
    isMessagesSearch,
    isMessagesSettings,
    isMutedAccounts,
    isMyProfile,
    isOnboarding,
    isOnboardingContent,
    isOnboardingContentListPreview,
    isOnboardingContentConfirmation,
    isOnboardingFullname,
    isOnboardingNetworkingJoin,
    isOnboardingNetworkingConfirmation,
    isOnboardingNetworkingUserSuggestion,
    isOnboardingImage,
    isOnboardingListDetail,
    isOnboardingPreview,
    isOnboardingProfileOrNested,
    isOnboardingProfileIntro,
    isOnboardingProfileInterests,
    isOnboardingProfileCompletion,
    isOnboardingSuccess,
    isOnboardingVerification,
    isOnboardingWelcome,
    isPickAddStyle,
    isPickAddThought,
    isProfile,
    isSearch,
    isSettings,
    isShareSheet,
    isSharingPage,
    isSparkDetail,
    isThoughtsPage,
    isTopics,
    isSavedPicks,
    isArchive,
    isChannelsLists,
    isCustomFeeds,
    isFeedsByType,
    isFeedsByTopic,
    isFeedDetail,
  };
};

export default usePageType;
