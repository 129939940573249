import React, { FC, useEffect, useState } from 'react';

import { ESearchParams } from 'constants/common';
import { ECardLayoutOptions, MessageType } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';
import { useGetSearchParam, useGoBack } from 'helpers/routingHelper';
import SharingNavigation from 'components/Sharing/SharingNavigation';
import ImageBackground from 'components/Sharing/ImageBackground';
import PickContent from 'components/Sharing/PickContent';
import ProfileContent from 'components/Sharing/ProfileContent';
import ThoughtContent from 'components/Sharing/ThoughtContent';
import ListContent from 'components/Sharing/ListContent';
import GuestList from 'components/Sharing/GuestList';
import SparkContent from 'components/Sharing/SparkContent';
import SharingStylesList from 'components/Sharing/SharingStylesList';
import Button from 'components/UI/Button';
import { useGetContent } from 'components/Sharing/helpers/hooks';
import {
  getShareContent,
  getSharingImageId,
  getSharingStyle,
  getSharingTitle,
  getSharingUrl,
  getSparkImagesWithColorIds,
} from 'components/Sharing/helpers/helpers';
import { StyledCloseButton } from 'components/Modal/styled';
import { SHARE_TYPE_ALL_PICKS, SHARE_TYPE_ALL_SPARKS } from 'components/Navigation/RightMenu/constants';
import { ESharingContentType } from './constants';
import AllPicksUi from '../../components/Sharing/AllPicks';
import AllSparksUi from '../../components/Sharing/AllSparks';
import SharingList from './ShareSheet/SharingList';

import { StyledContent, StyledContentWrap, StyledHeader, StyledSharingWrap, StyledWrap } from './styled';
import { getListStyles } from '../../components/Collection/helpers';

const { Id, Type, UserId, UserName } = ESearchParams;

const OffPlatformSharing: FC = () => {
  const itemId = useGetSearchParam(Id) ?? '';
  const itemType = useGetSearchParam(Type) ?? '';
  const userId = useGetSearchParam(UserId) ?? '';
  const username = useGetSearchParam(UserName) ?? undefined;
  const isAllPicks = itemType === SHARE_TYPE_ALL_PICKS;
  const isAllSparks = itemType === SHARE_TYPE_ALL_SPARKS;
  const goBack = useGoBack();

  const [selectedStyle, setSelectedStyle] = useState('');
  const [background, setBackground] = useState('');
  const [isStylingMode, setIsStylingMode] = useState(false);

  const {
    pick,
    list,
    comment,
    user,
    thoughtCard,
    guestList,
    guestListSeriesItems,
    spark,
    allPicksCoverData,
    allPicksGradients,
    allSparksCards,
    loading,
  } = useGetContent({
    type: itemType,
    id: itemId,
    userId,
    username,
  });

  const hasTextAllPicks = !!allPicksCoverData?.titles.length;

  const isGuestList = itemType === MessageType.GuestList || itemType === MessageType.GuestListSeries;

  const { images: allSparksImages, sparksImagesAndColorIds: allSparksImagesWithCardIds } = getSparkImagesWithColorIds(
    allSparksCards ?? []
  );

  const { bgColor, bgImage, title, sourceStyles } = getShareContent(
    itemType,
    user,
    thoughtCard,
    pick,
    list,
    spark,
    guestList,
    allPicksCoverData,
    allPicksGradients,
    allSparksImages
  );

  const { hasItems: isListWithItems, images: listImageCover } = getListStyles(list);

  const isEditStyleNotAllowed =
    (pick && pick?.cardStyle?.layout === ECardLayoutOptions.Plain) ||
    (thoughtCard && thoughtCard?.cardStyle?.layout === ECardLayoutOptions.Plain) ||
    itemType === MessageType.GuestListSeries ||
    (itemType === MessageType.GuestList && !guestList?.cardStyle?.thumbnailImageSet?.id) ||
    (itemType === MessageType.List && (!isListWithItems || !listImageCover.length)) ||
    (isAllPicks && hasTextAllPicks);

  const getImageId = () => {
    if (itemType === MessageType.List) {
      return getSharingImageId(background, listImageCover);
    }
    if (allPicksCoverData && !!allPicksCoverData.images.length) {
      return getSharingImageId(background, allPicksCoverData.images);
    }
    if (allSparksCards?.length) {
      return allSparksImagesWithCardIds?.find(({ img }) => background.includes(img))?.id;
    }
    return undefined;
  };

  const sharingUrl = getSharingUrl({
    type: itemType,
    itemId: itemType === MessageType.Thought ? comment?.id : itemId,
    userName: username ?? user?.username ?? '',
    style: getSharingStyle(itemType, background),
    color: !background.includes('url') ? background : undefined,
    imageId: getImageId(),
    guestListId: guestList?.id,
  });

  useEffect(() => {
    if (bgImage) {
      setBackground(`url('${bgImage}')`);
    } else if (bgColor) {
      setBackground(bgColor);
    }
  }, [bgImage, bgColor]);

  const handleLeft = () => {
    if (isStylingMode) {
      setIsStylingMode(false);
      setSelectedStyle('');
    } else {
      goBack();
    }
  };

  const handleDone = () => {
    setIsStylingMode(!isStylingMode);
    if (!isStylingMode) {
      setSelectedStyle(background);
    } else {
      setBackground(selectedStyle);
    }
  };

  const selectedBackground = (selectedStyle || background) ?? COLORS.green.dark;

  return (
    <ImageBackground key={selectedBackground} background={selectedBackground} isGuestList={isGuestList}>
      <SharingNavigation color={COLORS.white[100]}>
        <StyledCloseButton isDark isPrev={isStylingMode} onClick={handleLeft} />
        {!isEditStyleNotAllowed && !loading && (
          <Button text={isStylingMode ? 'Done' : 'Edit style'} handler={handleDone} />
        )}
      </SharingNavigation>
      <StyledWrap>
        <StyledContentWrap>
          <StyledContent>
            {pick && <PickContent card={pick} />}
            {user && <ProfileContent user={user} />}
            {comment && <ThoughtContent title={title} image={bgImage} item={comment} />}
            {list && <ListContent list={list} />}
            {guestList && <GuestList guestList={guestList} guestListSeriesItems={guestListSeriesItems} />}
            {spark && <SparkContent spark={spark} />}
            {isAllPicks && (!!allPicksCoverData?.images.length || !!allPicksCoverData?.titles.length) && (
              <AllPicksUi allPicksCoverData={allPicksCoverData} username={username} allPicksGradient={background} />
            )}
            {isAllSparks && !!allSparksCards?.length && (
              <AllSparksUi allSparksCards={allSparksCards} username={username} />
            )}
          </StyledContent>
        </StyledContentWrap>

        <StyledSharingWrap className="sharing-list">
          <StyledHeader isStylingMode={isStylingMode}>
            {isStylingMode ? 'Select your style' : getSharingTitle(itemType)}
          </StyledHeader>
          {isStylingMode ? (
            <SharingStylesList
              guestList={guestList}
              currentStyle={selectedStyle ?? background}
              styles={sourceStyles}
              setStyle={setSelectedStyle}
            />
          ) : (
            <SharingList
              url={sharingUrl}
              contentType={
                (pick && ESharingContentType.Pick) ??
                (list && ESharingContentType.List) ??
                (comment && ESharingContentType.Thought) ??
                (user && ESharingContentType.User) ??
                (spark && ESharingContentType.Spark) ??
                (guestList && ESharingContentType.GuestList)
              }
            />
          )}
        </StyledSharingWrap>
      </StyledWrap>
    </ImageBackground>
  );
};

export default OffPlatformSharing;
