import { MediaTagSchema, TagSchema } from 'constants/graphqlTypes';

export const generateEditFeedTopicsText = (totalCount: number, topics: TagSchema[]): string => {
  if (totalCount === 1) {
    return `${topics[0].name}`;
  }
  if (totalCount === 2) {
    return `${topics[0].name} and ${topics[1].name}`;
  }
  if (totalCount === 3) {
    return `${topics[0].name}, ${topics[1].name} and ${topics[2].name}`;
  }
  if (totalCount > 3) {
    return `${topics[0].name}, ${topics[1].name}, ${topics[2].name} and more`;
  }
  return '';
};

export const getAdminCustomFeedTags = (feedTags: MediaTagSchema[], isExcluded?: boolean): string[] =>
  feedTags.reduce((prev: string[], curr) => {
    if (isExcluded) {
      return !curr.isSelectedInFeedFilters ? prev.concat(curr.id) : prev;
    }
    return curr.isSelectedInFeedFilters ? prev.concat(curr.id) : prev;
  }, []);
