import React, { Dispatch, FC, SetStateAction, useRef } from 'react';

import getStyles from 'helpers/getStyles';
import getImages from 'helpers/getImages';
import { isVideoCard } from 'helpers/isVideoCard';

import { CardSchema, ECardLayoutOptions } from 'constants/graphqlTypes';

import { StyledImageWrap } from './styled';

const Image: FC<{ card: CardSchema; setIsVertical: Dispatch<SetStateAction<boolean>>; dataTestId?: string }> = ({
  card,
  dataTestId,
  setIsVertical,
}) => {
  const { cardStyle, title, sourceName } = card;
  const isVideo = isVideoCard(card);
  const isTiktokVideo = isVideo && sourceName === 'tiktok';
  const { layout } = getStyles(cardStyle);
  const { imageUrl } = getImages(cardStyle);

  const imageRef = useRef<HTMLImageElement>(null);

  const showImage = imageUrl && layout !== ECardLayoutOptions.Plain;
  const isBorderless = layout === ECardLayoutOptions.Borderless;

  const handleLoad = () => {
    if (imageRef?.current) {
      setIsVertical(imageRef?.current.width < imageRef?.current?.height);
    }
  };

  return showImage ? (
    <StyledImageWrap isBorderless={isBorderless} isTiktokVideo={isTiktokVideo}>
      <img onLoad={handleLoad} ref={imageRef} data-testid={dataTestId} src={imageUrl} alt={title ?? ''} />
    </StyledImageWrap>
  ) : null;
};

export default Image;
