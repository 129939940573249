import React, { FC, useState } from 'react';

import Button from 'components/UI/Button';

import useTheme from 'helpers/useTheme';

import { IUseCardsBaseSelection } from 'pages/OnboardingV2/ContentTutorial/ListFill/models';

import { EUiButtonType } from 'components/UI/Button/constants';
import { StyledButtonWrapper } from 'pages/OnboardingV2/ContentTutorial/ListDetail/styled';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { useDeleteSavedPicks } from 'graphQL/savedPicks/hooks';
import useToast from 'helpers/useToast';

const RightMenu: FC<{
  isDeleteMode: boolean;
  setDeleteMode: React.Dispatch<React.SetStateAction<boolean>>;
  cardsBaseSelection: IUseCardsBaseSelection;
}> = ({ isDeleteMode, setDeleteMode, cardsBaseSelection }) => {
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const isDark = useTheme();
  const { selectedCards, resetSelectedCards } = cardsBaseSelection;

  const { setToast } = useToast();

  const onComplete = () => {
    setToast({
      isToastOpen: true,
      toastItemName: 'Pick deleted',
    });
  };

  const { deleteSavedPicks } = useDeleteSavedPicks(selectedCards, onComplete);

  const closeDeleteMode = () => {
    resetSelectedCards();
    setDeleteMode(false);
  };

  const handleDelete = () => {
    deleteSavedPicks();
    closeDeleteMode();
    setIsConfirmationModal(false);
  };

  return (
    <>
      {isDeleteMode ? (
        <StyledButtonWrapper>
          {!!selectedCards.length && (
            <Button
              text="Delete"
              handler={() => setIsConfirmationModal(true)}
              isDark={isDark}
              type={EUiButtonType.Primary}
            />
          )}
          <Button text="Cancel" handler={closeDeleteMode} isDark={isDark} type={EUiButtonType.Primary} />
        </StyledButtonWrapper>
      ) : (
        <Button text="Select" type={EUiButtonType.Primary} handler={() => setDeleteMode(true)} isDark={isDark} />
      )}
      {isConfirmationModal && (
        <ConfirmationModal
          handleCancel={() => setIsConfirmationModal(false)}
          handleAccept={handleDelete}
          buttonText={`Delete ${selectedCards.length} pick${selectedCards.length > 1 ? 's' : ''}`}
          titleText="delete saved picks"
        />
      )}
    </>
  );
};

export default RightMenu;
