import React, { FC } from 'react';

import { UserObjectType } from 'constants/graphqlTypes';
import { ROUTE_USER_PROFILE } from 'routes';

import UILink from 'components/UI/Link';
import Avatar from 'components/UI/Avatar';

const UserPreview: FC<{ user: UserObjectType; dataTestId?: string }> = ({ user, dataTestId }) => {
  const { username: profileName } = user;

  return (
    <UILink route={ROUTE_USER_PROFILE} params={{ profileName }}>
      <Avatar dataTestId={dataTestId} user={user} />
    </UILink>
  );
};

export default UserPreview;
