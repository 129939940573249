import { useEffect, useRef, useState } from 'react';

interface PausebleTimerProps {
  onCompleted: () => void;
  duration: number;
  shouldPause?: boolean;
  disable?: boolean;
}

export const usePausebleTimer = ({ onCompleted, duration, shouldPause = false, disable }: PausebleTimerProps): void => {
  const TIME_STEP = 100;
  // Initial state is true because we want to start timer on first render
  const [shouldRestartTimer, setShouldRestartTimer] = useState<boolean>(true);

  const intervalRef = useRef<number>(0);
  const currentTimeRef = useRef<number>(0);

  const pauseTimer = () => {
    clearInterval(intervalRef.current);
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
    currentTimeRef.current = 0;
  };

  const startTimer = () => {
    if (disable) {
      return;
    }

    setShouldRestartTimer(false);
    intervalRef.current = +setInterval(() => {
      currentTimeRef.current += TIME_STEP;
      if (currentTimeRef.current >= duration && !shouldPause) {
        onCompleted();
        stopTimer();
        // Restart timer
        setShouldRestartTimer(true);
      }
    }, TIME_STEP);
  };

  // Pause Timer Effect
  useEffect(() => {
    if (!shouldPause) {
      setShouldRestartTimer(true);
      return;
    }

    pauseTimer();
  }, [shouldPause]);

  // Start Timer Effect
  useEffect(() => {
    if (!shouldRestartTimer || disable) {
      return;
    }

    startTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRestartTimer, disable]);

  useEffect(() => {
    if (disable) {
      stopTimer();
    }
  }, [disable]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
};
