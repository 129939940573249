import React, { FC } from 'react';

import Truncate from 'react-truncate-markup';

import { CardSchema } from 'constants/graphqlTypes';
import useTheme from 'helpers/useTheme';
import Grain from 'components/UI/Grain';
import { DEFAULT_GRADIENT } from 'components/Collection/helpers';
import Collaborators from 'components/Collection/Collaborators';
import Label from 'components/UI/Label';
import { StyledTitle } from '../styled';

import { StyledEmptyCardWrapper, StyledMetaData, StyledSquareCard } from './styled';

interface IEmptyCardProps {
  item: CardSchema;
}

const EmptyCard: FC<IEmptyCardProps> = ({ item }) => {
  const { title, isPrivateList, isCollaborative } = item;
  const isDark = useTheme();
  return (
    <StyledEmptyCardWrapper>
      {title && (
        <Truncate lines={2}>
          <StyledTitle isDark={isDark} total={0} isSmall>
            {title}
          </StyledTitle>
        </Truncate>
      )}
      <StyledSquareCard bgColor={DEFAULT_GRADIENT}>
        <Grain />
      </StyledSquareCard>
      <StyledMetaData>
        {!!isPrivateList && <Label title="Private" isDark={isDark} />}
        {!!isCollaborative && <Collaborators item={item} />}
      </StyledMetaData>
    </StyledEmptyCardWrapper>
  );
};

export default EmptyCard;
