import React from 'react';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { useHistory } from 'react-router-dom';

const UnknownError: React.FC = () => {
  const { goBack } = useHistory();

  return (
    <ConfirmationModal
      buttonText="okay"
      handleAccept={goBack}
      isWarning={false}
      titleText=""
      subTitle="Something went wrong. Please try again. If this problem persists, please reach out to support@guidehuman.co"
    />
  );
};

export default UnknownError;
