import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTE_MESSAGES_SETTINGS, ROUTE_MESSAGES_SETTINGS_ADD_MEMBERS, ROUTE_MESSAGES_SETTINGS_TITLE } from 'routes';

import ChatSettings from './ChatSettings';
import ChangeGroupName from './ChangeGroupName';
import AddMembers from './AddMembers';

const ChatSettingsModal: FC = () => (
  <Switch>
    <Route exact path={ROUTE_MESSAGES_SETTINGS} component={ChatSettings} />
    <Route exact path={ROUTE_MESSAGES_SETTINGS_TITLE} component={ChangeGroupName} />
    <Route exact path={ROUTE_MESSAGES_SETTINGS_ADD_MEMBERS} component={AddMembers} />
  </Switch>
);
export default ChatSettingsModal;
