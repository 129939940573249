import React, { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { useReactionContext } from 'components/Reactions/hooks';
import { parseToEmoji } from 'components/Reactions/helpers/helpers';
import ReactionsEditModal from 'components/Reactions/ReactionsEditModal';
import { useContentReactions, IContentIds } from 'helpers/useContentReactions';

import TokenAnimation from 'components/Reactions/Animations/ReactionToken/Animation';
import { useAnimationContext } from 'components/Reactions/Animations/hooks';
import { StyledWrap } from './styled';

interface IReactionsTokenProps {
  contentIds: IContentIds;
  isDark?: boolean;
  handleClose?: () => void;
  isDisabled?: boolean | null;
}

const ReactionsToken: FC<IReactionsTokenProps> = ({ contentIds, isDark, handleClose, isDisabled }) => {
  const { messageReactions } = useContentReactions(contentIds);
  const { reactions, loadMoreReactions } = messageReactions;

  const getEmojis = () => {
    if (!reactions) return [];
    return reactions.items?.map((emoji) =>
      emoji?.reactionSymbol?.symbol ? parseToEmoji(emoji?.reactionSymbol?.symbol) : undefined
    );
  };

  const { isQuickReactionOpen } = useReactionContext();

  const emojis = getEmojis();
  const portalContainer = document.getElementById('modal-root');

  const { isPlaying, emoji } = useAnimationContext();
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!reactions?.items.length) {
      setModalOpen(false);
    }
  }, [reactions]);

  return reactions?.totalCount ? (
    <>
      <StyledWrap
        onClick={() => !isQuickReactionOpen && setModalOpen(true)}
        isDisabled={!!isDisabled || !!isQuickReactionOpen}
        isDark={!!isDark}
        isSingle={emojis.length <= 1}
      >
        {emojis[0] && (
          <span className="token-item">
            {isPlaying && emoji && <TokenAnimation emoji={emoji} />}
            {emojis[0]}
          </span>
        )}
        {emojis[1] && <span className="token-item">{emojis[1]}</span>}
        {reactions?.totalCount > 2 && <span className="token-item">+{reactions.totalCount - 2}</span>}
      </StyledWrap>
      {isModalOpen &&
        portalContainer &&
        createPortal(
          <ReactionsEditModal
            setModalOpen={setModalOpen}
            handleClose={handleClose}
            reactions={reactions}
            loadMoreReactions={loadMoreReactions}
            contentIds={contentIds}
          />,
          portalContainer
        )}
    </>
  ) : null;
};

export default ReactionsToken;
