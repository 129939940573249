import { styled } from 'linaria/react';
import StyledButtonImported from 'components/UI/Button/styled';
import { COLORS } from 'styles/constants';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding-top: 32px;
  position: relative;
  text-align: center;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const StyledButtonContainer = styled.div`
  margin: 0 auto;
  max-width: 342px;
  padding-top: 64px;
  width: 100%;
`;

export const StyledButton = styled(StyledButtonImported)`
  width: 100%;
`;

export const StyledModalContainer = styled.div`
  & .modal {
    padding: 24px 0 0;
  }

  & .modal--header {
    padding: 0 12px;
  }
`;

export const StyledDotsContainer = styled.div`
  margin: 0 auto;
  display: flex;
  padding: 12px 0;
`;

export const StyledDot = styled.div<{ isActive: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 20px;
  transition: background-color 0.6s ease-in-out;
  background-color: ${({ isActive }) => (isActive ? COLORS.white[100] : COLORS.white[60])};

  :not(:first-child) {
    margin-left: 10px;
  }
`;
