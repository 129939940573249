import { convertTypes } from 'components/Thoughts/helpers/helpers';
import { CardTypeOptions, ECardSortingOptions, MessageType } from 'constants/graphqlTypes';
import { useGetComments } from 'graphQL/card/comments/hooks';
import { useGetDetailCard, useGetThoughtsCard } from 'graphQL/card/helpers/hooks';
import { useGetDetailList } from 'graphQL/list/hooks';
import { useGetUser } from 'graphQL/profile/hooks';
import { useGuestList, useGuestListSeriesCard } from 'graphQL/guestList/hooks';
import { useGetSparkDetail } from 'graphQL/sparks/hooks';

import { useAllPicksCover, useGetUserCards } from 'graphQL/cards/userCards/hooks';
import getAuthUser from 'helpers/getAuthUser';
import { SHARE_TYPE_ALL_PICKS, SHARE_TYPE_ALL_SPARKS } from 'components/Navigation/RightMenu/constants';
import { IAllpicksShareData, IUseGetSharingAllPicksData, SharingContent } from './models';

interface IUseGetContentArgs {
  type: string;
  id: string;
  userId: string;
  username?: string;
}

export const useGetSharingAllPicksData = (isAllPicks: boolean, userId?: string): IUseGetSharingAllPicksData => {
  const allPicksCoverData = useAllPicksCover({
    customUserId: userId ?? getAuthUser().userId,
    skipQuery: !isAllPicks,
    customLimit: 4,
  });
  const isTextAllPicks = !!allPicksCoverData?.titles?.length;

  const { recentCards: allPicksCards, loading } =
    useGetUserCards({
      limit: 4,
      type: CardTypeOptions.Card,
      customUserId: userId ?? getAuthUser().userId,
      sorting: ECardSortingOptions.CardCreatedAtDesc,
      customSkip: !isAllPicks && !isTextAllPicks,
    }) ?? {};

  const items = allPicksCards.reduce((prev: IAllpicksShareData[], curr) => {
    const { colorValue, gradientEnd, gradientStart } = curr.cardStyle?.bgColor ?? {};

    return colorValue && gradientEnd && gradientEnd
      ? prev.concat({ bgColor: colorValue, gradientEnd, gradientStart })
      : prev;
  }, []);

  return {
    allPicksCoverData,
    allPicksDataLoading: loading,
    items,
  };
};

export const useGetContent = ({ id, type, userId }: IUseGetContentArgs): SharingContent => {
  const { card, loading: cardLoading } = useGetDetailCard(type === MessageType.Pick ? id : undefined);
  const { card: list, loading: listLoading } = useGetDetailList({ id: type === MessageType.List ? id : undefined });

  const { comments, loading: commentsLoading } = useGetComments(type === MessageType.Thought ? id : undefined, {
    referrerUserId: userId,
  });
  const thoughtCard = useGetThoughtsCard(type === MessageType.Thought ? id : undefined);
  const { user, loading } = useGetUser(type === MessageType.User ? id : undefined);
  const { card: guestList, loading: headerLoading } = useGuestList(
    id,
    CardTypeOptions.GuestList,
    type !== MessageType.GuestList
  );

  const { card: guestListSeriesCard, guestListSeriesItems, loading: seriesLoading } = useGuestListSeriesCard(
    type === MessageType.GuestListSeries ? id : undefined
  );

  const { spark } = useGetSparkDetail(type === MessageType.Spark ? id : undefined);

  const { thoughts } = convertTypes(undefined, comments);

  const { allPicksCoverData, items: allPicksGradients, allPicksDataLoading } = useGetSharingAllPicksData(
    type === SHARE_TYPE_ALL_PICKS,
    userId
  );

  const { recentCards: recentSparks, loading: allSparksLoading } = useGetUserCards({
    customSkip: type !== SHARE_TYPE_ALL_SPARKS,
    customUserId: userId ?? getAuthUser().userId,
    type: CardTypeOptions.Spark,
    limit: 4,
    sorting: ECardSortingOptions.CardCreatedAtAsc,
  });

  return {
    pick: card,
    list,
    comment: thoughts[0],
    user,
    thoughtCard,
    guestList: guestList ?? guestListSeriesCard,
    guestListSeriesItems,
    spark,
    loading:
      cardLoading ||
      listLoading ||
      commentsLoading ||
      headerLoading ||
      seriesLoading ||
      loading ||
      allPicksDataLoading ||
      allSparksLoading,
    allPicksCoverData,
    allPicksGradients,
    allSparksCards: recentSparks,
  };
};
