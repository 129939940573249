import React, { Dispatch, FC } from 'react';

import Modal from 'components/Modal';
import { StyledTitle } from 'components/AddContent/AddStyle/styled';
import { StyledList } from 'components/ProfileSettings/Menu/styled';
import Button from 'components/UI/MenuButton';
import { ChannelSectionItemsSortTypeOptions } from 'constants/graphqlTypes';

import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';
import { StyledCheckIcon } from 'components/Explore/Footer/styled';

import { getSortingTitle } from './helpers';
import { StyledItem } from './styled';

const { Created, Custom, RecentlyUpdated, AlphaAsc } = ChannelSectionItemsSortTypeOptions;

const SortingMenuModal: FC<{
  selectedSorting?: ChannelSectionItemsSortTypeOptions;
  setSorting: Dispatch<React.SetStateAction<ChannelSectionItemsSortTypeOptions | undefined>>;
  handleClose: () => void;
  handleOpenCustom: () => void;
  hideCustom?: boolean;
  hideDate?: boolean;
}> = ({ hideDate, hideCustom, selectedSorting, handleClose, handleOpenCustom, setSorting }) => (
  <Modal handleClose={handleClose} enableInnerScroll>
    <StyledTitle>{`Sort ${hideCustom ? 'lists' : 'feeds'} by`}</StyledTitle>
    <StyledList>
      <StyledItem>
        <Button
          text={getSortingTitle(RecentlyUpdated)}
          handler={() => {
            setSorting(RecentlyUpdated);
            handleClose();
          }}
        />
        {selectedSorting === RecentlyUpdated && (
          <StyledCheckIcon isDark>
            <CheckIcon width={45} height={45} viewBox="0 0 24 24" />
          </StyledCheckIcon>
        )}
      </StyledItem>
      {!hideDate && (
        <StyledItem>
          <Button
            text={getSortingTitle(Created)}
            handler={() => {
              setSorting(Created);
              handleClose();
            }}
          />
          {selectedSorting === Created && (
            <StyledCheckIcon isDark>
              <CheckIcon width={45} height={45} viewBox="0 0 24 24" />
            </StyledCheckIcon>
          )}
        </StyledItem>
      )}
      <StyledItem>
        <Button
          text={getSortingTitle(AlphaAsc)}
          handler={() => {
            setSorting(AlphaAsc);
            handleClose();
          }}
        />
        {selectedSorting === AlphaAsc && (
          <StyledCheckIcon isDark>
            <CheckIcon width={45} height={45} viewBox="0 0 24 24" />
          </StyledCheckIcon>
        )}
      </StyledItem>
      {!hideCustom && (
        <StyledItem>
          <Button text={getSortingTitle(Custom)} handler={handleOpenCustom} />
          {!selectedSorting && (
            <StyledCheckIcon isDark>
              <CheckIcon width={45} height={45} viewBox="0 0 24 24" />
            </StyledCheckIcon>
          )}
        </StyledItem>
      )}
    </StyledList>
  </Modal>
);

export default SortingMenuModal;
