import { CardSchema } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';

export const getColor = (field: 'gradientStart' | 'gradientEnd', card?: CardSchema): string =>
  card?.cardStyle?.bgColor?.[field] ?? COLORS.black[100];

export const getIdByIndex = (index: number, id: string, prevId?: string, nextId?: string): string => {
  switch (index) {
    case 0:
      return prevId ?? id;
    case 2:
      return nextId ?? id;
    default:
      return id;
  }
};
