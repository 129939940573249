import { useHistory } from 'react-router-dom';
import useGetExtractionCard from 'graphQL/addContent/addStyle/hook';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  CardSchema,
  CardStyleSchema,
  CardTypeOptions,
  ECardExtractionOptions,
  ECardLayoutOptions,
} from 'constants/graphqlTypes';
import getImages from 'helpers/getImages';
import { useReports } from 'graphQL/cardOptions/hooks';
import { useSparkResponseLinks } from 'components/Sparks/Routes/AddResponseToSpark/hooks';
import { useAddContentLinks, useOfferReportLinks, useStyleItems } from '../helpers/hooks';
import { changeCardStyle, getPreExtractImage } from '../helpers/helpers';
import { IUseReportStylesData } from '../helpers/models';

// @typescript-eslint/explicit-module-boundary-types
export const useAddStyleLogic = ({
  pickId,
  cardId,
  itemId,
  color,
  preExtractTitle,
  preExtractImage,
  setStyle,
}: {
  pickId: string;
  cardId: string;
  itemId: number;
  color: string;
  preExtractTitle?: string | null;
  preExtractImage?: string | null;
  setStyle: Dispatch<SetStateAction<CardStyleSchema | undefined>>;
}) => {
  const { card } = useGetExtractionCard(pickId);

  const [isPreExtraction, setIsPreExtraction] = useState(true);
  const { cardStyle, title, extractionStatus, mediaTags } = card ?? {};
  const { items, currentItem, itemColors, currentColor } = useStyleItems(itemId, color, card);

  const isExtracting = extractionStatus !== ECardExtractionOptions.Complete;
  const layout =
    card?.mediaTags?.items[0]?.name === 'video' ? ECardLayoutOptions.Borderless : ECardLayoutOptions.Framed;
  const updatedStyle = changeCardStyle(
    layout,
    cardStyle,
    currentColor,
    isPreExtraction && !currentItem?.image ? getPreExtractImage(preExtractImage) : currentItem?.image
  );
  const { imageUrl } = getImages(updatedStyle);

  const previewCard: CardSchema = {
    id: pickId,
    cardId,
    type: CardTypeOptions.Card,
    ...card,
    title: title || preExtractTitle,
  };

  useEffect(() => {
    if (updatedStyle) {
      setIsPreExtraction(false);
      setStyle(updatedStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentColor, currentItem, mediaTags]);

  return {
    currentItem,
    isExtracting,
    currentColor,
    imageUrl,
    itemColors,
    updatedStyle,
    previewCard,
    items,
  };
};

export const useReportStyles = (variable: {
  cardId: string;
  reportComment?: string;
  type?: string;
  isSpark?: boolean;
}): IUseReportStylesData => {
  const { cardId, reportComment, type, isSpark } = variable;
  const { replace } = useHistory();
  const { addStyle } = useAddContentLinks();
  const { styleDescription, reportStyleIssue, reportStyleOther } = useOfferReportLinks();

  const {
    sparkResponseReportStyleIssue,
    sparkResponseStyleDescription,
    sparkResponseReportStyleOther,
    addResponseStyle,
  } = useSparkResponseLinks();

  const handleNavigateAfterReport = () => replace(isSpark ? addResponseStyle : addStyle);
  const { options, report } = useReports({ cardId, reportComment, type }, handleNavigateAfterReport);

  const handleSubmitReport = (id?: string) => {
    if (id) {
      report(id);
    } else {
      const questionId = options.find(({ title }) => title === 'Other')?.id ?? '';
      report(questionId);
    }
  };

  const handleReplaceModal = () => {
    if (type) {
      replace(isSpark ? sparkResponseReportStyleOther : reportStyleOther);
    } else {
      replace(isSpark ? sparkResponseReportStyleIssue : reportStyleIssue);
    }
  };

  return {
    options,
    handleSubmitReport,
    closeReportStyleModal: handleNavigateAfterReport,
    handleOpenDescription: () => replace(isSpark ? sparkResponseStyleDescription : styleDescription),
    handleReplaceModal,
  };
};
