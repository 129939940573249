import React, { FC } from 'react';

import Card from 'components/Card';

import { CardSchema, EDiscoverItemLayoutOptions } from 'constants/graphqlTypes';
import { isBigItem } from 'components/List/Grid/helpers';
import { StyledDefaultViewCardWrap } from './styled';

interface IListDetailPreviewCardProps {
  item: CardSchema;
  index: number;
  isDefaultView: boolean;
}

const ListDetailPreviewCard: FC<IListDetailPreviewCardProps> = ({ item, index, isDefaultView }) => {
  const defaultViewLayout = isBigItem(index) ? EDiscoverItemLayoutOptions.FullBleed : undefined;

  return (
    <StyledDefaultViewCardWrap isBig={isDefaultView && isBigItem(index)} key={item.id}>
      <Card
        layout={isDefaultView ? defaultViewLayout : EDiscoverItemLayoutOptions.FirstUp}
        card={item}
        disableLinks
        isDark={false}
      />
    </StyledDefaultViewCardWrap>
  );
};

export default ListDetailPreviewCard;
