import {
  CardSchema,
  GetFeaturedSparkCardsQuery,
  GetSparkCardsQuery,
  GetSparkDetailCardsQuery,
} from 'constants/graphqlTypes';

export const updateSparkCardsQueryCache = (
  prev: GetSparkDetailCardsQuery,
  fetchMoreResult: GetSparkDetailCardsQuery
): GetSparkDetailCardsQuery =>
  prev?.allCards?.items?.[0].sparkResponses?.items.length && fetchMoreResult?.allCards?.items?.[0].sparkResponses?.items
    ? {
        ...prev,

        allCards: {
          ...prev.allCards,
          totalCount: 1,
          items: [
            {
              ...prev.allCards?.items?.[0],
              sparkResponses: {
                ...prev.allCards?.items?.[0].sparkResponses,
                items: [
                  ...prev.allCards.items?.[0].sparkResponses.items,
                  ...fetchMoreResult.allCards.items?.[0].sparkResponses.items,
                ],
              },
            },
          ],
        },
      }
    : prev;

export const updateAllSparksCardsQueryCache = (
  prev: GetSparkCardsQuery,
  fetchMoreResult: GetSparkCardsQuery
): GetSparkCardsQuery =>
  prev?.allCards?.items.length && fetchMoreResult?.allCards?.items
    ? {
        ...prev,
        allCards: {
          ...prev.allCards,
          items: [...prev.allCards?.items, ...fetchMoreResult.allCards.items],
        },
      }
    : prev;

export const updateFeaturedSparksCardsQueryCache = (
  prev: GetFeaturedSparkCardsQuery,
  fetchMoreResult: GetFeaturedSparkCardsQuery
): GetFeaturedSparkCardsQuery =>
  prev?.allCards?.items.length && fetchMoreResult?.allCards?.items
    ? {
        ...prev,
        allCards: {
          ...prev.allCards,
          items: [...prev.allCards?.items, ...fetchMoreResult.allCards.items],
        },
      }
    : prev;

export const addCardToExistingCards = (
  existingCards: CardSchema[],
  newCard: CardSchema,
  totalCount: number
): { cards: CardSchema[]; totalCount: number } => {
  const isCardAlreadyAdded = existingCards.some((existingCard) => existingCard.cardId === newCard.cardId);
  if (isCardAlreadyAdded) {
    return { cards: existingCards, totalCount };
  }

  return { cards: [newCard, ...existingCards], totalCount: totalCount + 1 };
};
