import React, { FC, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_MESSAGES_CHAT } from 'routes';

import Avatar from 'components/UI/Avatar';
import AvatarDouble from 'components/UI/AvatarDouble';
import UILink from 'components/UI/Link';
import VerificationBadge from 'components/Profile/ProfileTop/VerificationBadge';
import { useGroupChatTitle } from 'components/Messages/helpers/hooks';
import { GroupChatSchema } from 'constants/graphqlTypes';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { transformRoute } from 'helpers/routingHelper';

import { StyledTitleWrap } from 'components/Messages/List/styled';

import { StyledUsername as StyledFullname } from 'components/Sparks/Views/Card/styled';
import { StyledUsername } from 'components/Messages/Navigation/styled';
import { StyledInfo, StyledText, StyledWrapper } from './styled';
import SendButton from '../SendButton';

const GroupChatItem: FC<{ item: GroupChatSchema }> = ({ item }) => {
  const { users, moreNumber } = useGroupChatTitle(item);
  const { push } = useHistory();

  return users ? (
    <StyledWrapper>
      <UILink route={ROUTE_MESSAGES_CHAT} params={{ id: item.id }}>
        {users.length > 1 ? <AvatarDouble users={[users[1], users[0]]} /> : <Avatar user={users[0]} />}
      </UILink>
      <StyledInfo onClick={() => push(transformRoute(ROUTE_MESSAGES_CHAT, { id: item.id }))}>
        {item?.title && (
          <StyledText isDark isBold>
            {item.title}
          </StyledText>
        )}
        <StyledTitleWrap>
          <StyledUsername>
            {users.map((user, index) => (
              <Fragment key={user.username}>
                <StyledFullname isDark minWidth={26}>
                  {!!index && ', '}
                  {generateFullName(user).trim()}
                </StyledFullname>
                {user.isVerified && <VerificationBadge />}
              </Fragment>
            ))}
          </StyledUsername>
          {!!moreNumber && (
            <StyledText isDark isBold={!item.title} fixedWidth>
              {`and ${moreNumber} more`}
            </StyledText>
          )}
        </StyledTitleWrap>
      </StyledInfo>
      <SendButton chatId={item.id} />
    </StyledWrapper>
  ) : null;
};

export default GroupChatItem;
