import React, { FC } from 'react';

import { getGradientImage } from 'components/Sparks/constants';
import { CardSchema } from 'constants/graphqlTypes';
import { EFeaturedFlags } from 'constants/posthogEvents';
import { ReactComponent as SparkIcon } from 'assets/icons/AddSpark.svg';
import Attribute from 'components/UI/Attribute';
import { transformRoute } from 'helpers/routingHelper';
import { useFeaturedFlagEnabled } from 'helpers/posthogHooks';
import { ROUTE_SPARK_DETAIL } from 'routes';
import Grain from 'components/UI/Grain';

import { StyledThumbnail } from './styled';
import { getTitle } from './helpers';

interface ISparkAttributeProps {
  spark: CardSchema;
  hasBorder?: boolean;
  isFullBleed?: boolean;
  isDark?: boolean;
}

const SparkAttribute: FC<ISparkAttributeProps> = ({ spark, hasBorder = true, isFullBleed, isDark }) => {
  const isViewSparks = useFeaturedFlagEnabled(EFeaturedFlags.ViewSparks);

  const { title, user, cardStyle, id } = spark;
  const bgImage = getGradientImage(cardStyle?.bgColor?.predefinedGradientName ?? '');
  const sparkRoute = transformRoute(ROUTE_SPARK_DETAIL, { sparkId: id });

  return isViewSparks && user && title ? (
    <Attribute
      title={getTitle(user)}
      text={title}
      route={sparkRoute}
      isFullBleed={isFullBleed}
      hasBorder={hasBorder}
      isDark={isDark}
      isSpark
    >
      <StyledThumbnail bgUrl={bgImage}>
        <Grain />
        <SparkIcon />
      </StyledThumbnail>
    </Attribute>
  ) : null;
};

export default SparkAttribute;
