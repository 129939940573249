import React, { FC } from 'react';

import useTheme from 'helpers/useTheme';
import { IReportOption } from 'graphQL/cardOptions/models';

import { StyledList } from 'pages/Settings/styled';

import Layout from 'components/Onboarding/Layout/Layout';
import ContentSubtitle from 'components/UI/ContentSubtitle';
import MenuButton from 'components/UI/MenuButton';
import Button from 'components/UI/Button';
import { StyledItem } from 'components/AddContent/Menu/styled';
import { EUiButtonType } from 'components/UI/Button/constants';
import { EMenuButtonType } from 'components/UI/MenuButton/constants';

import { COLORS } from 'styles/constants';
import { StyledListWrap, StyledRowWrap } from './styled';

const ExitFormContentPage: FC<{
  options: (IReportOption & { isSelected?: boolean })[];
  inputText?: string;
  handleSubmit: () => void;
  handleSkip: () => void;
  handleSelect: (optionId: string) => void;
  openCommentPage: () => void;
}> = ({ options, inputText, handleSubmit, handleSkip, handleSelect, openCommentPage }) => {
  const isDark = useTheme();

  return (
    <Layout
      heading="We'd love to know why you're leaving"
      primaryBtnText="Submit"
      secondaryBtnText="Skip"
      handlePrimaryBtn={handleSubmit}
      handleSecondaryBtn={handleSkip}
      isSecondaryBtnTransparent
      blockScroll={false}
      isPageScrollable
    >
      <StyledListWrap>
        <StyledList isDark={isDark}>
          {options.map(({ id, title, isSelected }) => (
            <StyledItem key={id}>
              <MenuButton
                isDark={isDark}
                text={title}
                checked={isSelected}
                handler={() => handleSelect(id)}
                type={EMenuButtonType.Radio}
              />
            </StyledItem>
          ))}
          <StyledItem>
            <StyledRowWrap isDark={isDark}>
              <p>Anything else?</p>
              <Button
                isDark={isDark}
                type={EUiButtonType.Primary}
                text={inputText ? 'Edit' : 'Add a comment'}
                handler={() => openCommentPage()}
              />
            </StyledRowWrap>
            {inputText && (
              <ContentSubtitle color={!isDark ? COLORS.brown.dark[100] : ''} isDark={isDark} text={inputText} />
            )}
          </StyledItem>
        </StyledList>
      </StyledListWrap>
    </Layout>
  );
};

export default ExitFormContentPage;
