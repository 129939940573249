import React, { FC } from 'react';

import LoaderContent from 'components/UI/LoaderContent';

import { GuestListSeriesSchema } from 'constants/graphqlTypes';
import { useGuestListSeries } from 'graphQL/guestList/hooks';
import { StyledList } from './styled';

import SeriesItem from './SeriesItem';

const SeriesList: FC<{ series: GuestListSeriesSchema; isDark: boolean }> = ({ series, isDark }) => {
  const { id } = series;

  const { cards, loading } = useGuestListSeries(id);

  return loading ? (
    <LoaderContent isDark={isDark} isFullScreen />
  ) : (
    <StyledList>
      {cards.map((card) => (
        <li key={card.id}>
          <SeriesItem item={card} isDark={isDark} />
        </li>
      ))}
    </StyledList>
  );
};

export default SeriesList;
