import React, { FC, useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Toggle from 'components/UI/Toggle';
import { useToggleListPrivacy } from 'graphQL/list/update/hooks';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_LIST_EDIT } from 'routes';

import ConfirmationModal from 'components/UI/ConfirmationModal';
import { StyledInfoText, StyledLabel, StyledTitle } from '../styled';

const EditPrivacy: FC = () => {
  const { replace } = useHistory();
  const { listId } = useParams<{ listId: string }>();
  const { state } = useLocation<{ variable: { listCardId: string; isPrivateList: boolean } }>();
  const { listCardId, isPrivateList } = state?.variable ?? {};

  const [isPrivateValue, setIsPrivate] = useState(false);
  const [isPrivacyConfirm, setIsPrivacyConfirm] = useState(false);
  const updatePrivacyConfirm = (confirmValue: boolean) => () => setIsPrivacyConfirm(confirmValue);
  const useMutation = useToggleListPrivacy(
    listCardId,
    listId,
    !isPrivateValue,
    setIsPrivate,
    updatePrivacyConfirm(false)
  );

  useEffect(() => {
    setIsPrivate(!!isPrivateList);
  }, [isPrivateList]);

  const handleClose = () =>
    replace({
      pathname: transformRoute(ROUTE_LIST_EDIT, { listId }),
      state: {
        ...state,
        variable: { ...state?.variable, isPrivateList: isPrivateValue },
      },
    });

  return (
    <Modal fadeIn={false} handlePrev={handleClose} handleClose={handleClose}>
      <StyledTitle>Privacy settings</StyledTitle>
      <StyledLabel>
        Make this list private
        <Toggle isDark checked={isPrivateValue} handler={isPrivateValue ? updatePrivacyConfirm(true) : useMutation} />
      </StyledLabel>
      <StyledInfoText>Only you and your collaborators will be able to see this list</StyledInfoText>
      {isPrivacyConfirm && (
        <ConfirmationModal
          handleCancel={() => setIsPrivacyConfirm(false)}
          handleAccept={useMutation}
          subTitle="This will make your list visible to anyone"
          titleText="Make this list public"
          buttonText="Make public"
          ignoreUseModal
        />
      )}
    </Modal>
  );
};

export default EditPrivacy;
