import { useParams } from 'react-router-dom';

import getAuthUser from 'helpers/getAuthUser';

import { EQueryFetchPolicy } from 'constants/common';
import { ENavElements, updateCollab, EActionType, addActions } from 'graphQL/card/helpers/helpers';
import { updateInviteStatus } from 'graphQL/messages/helpers';
import {
  ECardRoleTypeOptions,
  GetCreatorsDocument,
  GetDetailListDocument,
  GetDetailListQuery,
  GetTotalCountCardsDocument,
  useChangeRoleMutation,
  useGetCreatorsQuery,
  UserCardRoleSchema,
  useRemoveCreatorMutation,
} from 'constants/graphqlTypes';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { POSTHOG_EVENTS } from 'constants/posthogEvents';

export const useGetCreators = (
  collaborariveListId?: string
): { creators: UserCardRoleSchema[]; loadMore: () => void; loading: boolean } => {
  const { listId: id } = useParams<{ listId: string }>();

  const { data, fetchMore, loading } = useGetCreatorsQuery({
    variables: { id: collaborariveListId ?? id, offset: 0 },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const items =
    data?.allCards?.items?.[0].creators?.items.reduce(
      (acc: UserCardRoleSchema[], item) => (item ? acc.concat(item) : acc),
      []
    ) ?? [];
  const totalCount = data?.allCards?.items?.[0].creators?.totalCount ?? 0;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        const card = prev?.allCards?.items?.[0];
        const creators = card?.creators?.items;
        const total = card?.creators?.totalCount ?? 0;
        const newItems = fetchMoreResult?.allCards?.items?.[0]?.creators?.items;

        return addActions({
          prev,
          type: EActionType.Creators,
          field: ENavElements.Creators,
          card,
          items: creators,
          totalCount: total,
          newItems,
        });
      },
    });

  return { creators: items, loadMore, loading };
};

export const useCollaborators = (
  userId: string,
  listCardId: string
): { handleRemove: () => void; handleChange: (role: ECardRoleTypeOptions) => void } => {
  const posthogCapture = usePostHogCapture();
  const { userId: authUserId, username } = getAuthUser();
  const { listId } = useParams<{ listId: string }>();

  const isCurrentUser = authUserId === userId;

  const [changeMutation] = useChangeRoleMutation();
  const [removeMutation] = useRemoveCreatorMutation();

  const handleRemove = () =>
    removeMutation({
      variables: { id: userId, listCardId },
      update: (cache) => {
        updateInviteStatus(listId ?? '', cache);

        const detailList: GetDetailListQuery | null = cache.readQuery({
          query: GetDetailListDocument,
          variables: { id: listId, isDetail: true, coverLimit: undefined },
        });

        const collaborators = detailList?.allCards?.items?.[0]?.creators
          ? detailList?.allCards?.items?.[0]?.creators?.items.filter(
              (creator) =>
                creator.roleName === ECardRoleTypeOptions.Owner ||
                creator.roleName === ECardRoleTypeOptions.Collaborator
            )
          : [];

        const isCollaborative = collaborators.length > 2;

        return updateCollab({ cache, id: userId, listId, isCurrentUser, isCollaborative });
      },
      refetchQueries: [
        {
          query: GetTotalCountCardsDocument,
          variables: { id: authUserId },
        },
      ],
      onCompleted: () => !isCurrentUser && posthogCapture(POSTHOG_EVENTS.RemUserFromList),
    });

  const handleChange = (role: ECardRoleTypeOptions) =>
    changeMutation({
      variables: { id: userId, listCardId, role },
      refetchQueries: [
        {
          query: GetCreatorsDocument,
          variables: {
            id: listId,
            offset: 0,
          },
        },
      ],
      onCompleted: () =>
        role === ECardRoleTypeOptions.Owner
          ? posthogCapture(POSTHOG_EVENTS.CollabBecomesAdmin)
          : posthogCapture(POSTHOG_EVENTS.AdminBecomesCollab),
      update: (cache) => updateCollab({ cache, id: userId, listId, isCurrentUser, role }),
    });

  return { handleChange, handleRemove };
};
