import { styled } from 'linaria/react';
import { StyledListItem as ListItem } from 'components/ActivityList/styled';
import { StyledButton } from 'components/Navigation/styled';
import { COLORS } from 'styles/constants';
import { StyledAvatarWrapper } from 'components/ProfileSettings/EditProfile/styled';
import { StyledFilterBtns } from '../FilterBtn/styled';

export const StyledListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledContent = styled(StyledAvatarWrapper)`
  flex-grow: 1;
  width: calc(100% - 44px);
  margin: 0;
`;

export const StyledRecentSearches = styled(StyledFilterBtns)`
  display: block;
  padding-top: 32px;
`;

export const StyledSearchIconWrapper = styled(StyledButton)<{ isDark: boolean }>`
  border-radius: 50%;
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.gray[50])};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 44px;

  path {
    fill: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  }
`;

export const StyledCloseIconWrapper = styled(StyledSearchIconWrapper)<{ shouldRotate: boolean }>`
  border: none;
  border-radius: 0;
  margin-left: auto;

  svg {
    transform: ${({ shouldRotate }) => (shouldRotate ? 'rotate(-135deg)' : 'rotate(0)')};
  }
`;
