import { useState } from 'react';

import { EQueryFetchPolicy } from 'constants/common';
import {
  SortOrderEnum,
  useChangePreferredTagIsEnableStatusMutation,
  useGetProfileTopicsQuery,
} from 'constants/graphqlTypes';
import getAuthUser from 'helpers/getAuthUser';
import { updateIsTopicEnabled } from 'graphQL/profile/topics/helpers';
import { IGetTopicsResult } from './models';

export const useGetProfileTopics = (
  fetchPolicy?: EQueryFetchPolicy,
  sorting?: SortOrderEnum,
  feedId?: string
): IGetTopicsResult => {
  const [isQueryCalled, setIsQueryCalled] = useState(false);
  const { userId } = getAuthUser();
  const { data, fetchMore, loading } = useGetProfileTopicsQuery({
    variables: { id: userId, sorting, feedId },
    onCompleted: () => setIsQueryCalled(true),
    ...(fetchPolicy && { fetchPolicy }),
  });

  const topics = data?.allUsers?.items?.[0]?.userPreferredTags?.items ?? [];
  const totalCount = data?.allUsers?.items?.[0]?.userPreferredTags?.totalCount ?? 0;
  const allowLoadMore = topics.length < totalCount;

  const loadMoreTopics = () => {
    if (allowLoadMore) {
      fetchMore({
        variables: { offset: topics.length ?? 0 },
        updateQuery: (prev, { fetchMoreResult }) => {
          const prevTopics = prev.allUsers?.items?.[0]?.userPreferredTags?.items;
          const incomingTopics = fetchMoreResult?.allUsers?.items[0].userPreferredTags?.items;

          return prevTopics && incomingTopics && prev.allUsers?.items?.[0]?.userPreferredTags
            ? {
                ...prev,
                allUsers: {
                  ...prev.allUsers,
                  items: [
                    {
                      ...prev.allUsers?.items[0],
                      userPreferredTags: {
                        ...prev.allUsers?.items[0].userPreferredTags,
                        items: [...prevTopics, ...incomingTopics],
                      },
                    },
                  ],
                },
              }
            : prev;
        },
      });
    }
  };

  return { topics, totalCount, loadMoreTopics, isLoading: loading, isQueryCalled };
};

export const useUpdateProfileTopic = (): {
  updateStatus: (newStatus: boolean, topicId: string, topicName?: string | null) => void;
} => {
  const [updateStatus] = useChangePreferredTagIsEnableStatusMutation();
  const { userId } = getAuthUser();

  return {
    updateStatus: (newStatus: boolean, topicId: string) =>
      updateStatus({
        variables: { isEnable: newStatus, tagIds: [topicId] },
        update: (cache) => updateIsTopicEnabled(cache, userId, topicId, newStatus),
      }),
  };
};
