import { useEffect, useState } from 'react';

import { CardSchema, UserObjectType, useGetCardPreviewLazyQuery } from 'constants/graphqlTypes';

export const useMixedGroupCards = (ids?: string[] | null, user?: UserObjectType | null) => {
  const [cards, setCards] = useState<CardSchema[]>([]);
  const [getCard, { loading }] = useGetCardPreviewLazyQuery();

  useEffect(() => {
    ids?.forEach((cardId) => {
      if (cardId && !cards.find((card) => card.id === cardId)) {
        getCard({
          variables: { cardId },
        }).then((onfullfilled) => {
          const card = onfullfilled.data?.allCards?.items?.[0];
          if (card && user) {
            setCards((prev) => [...prev, { ...card, pickedByUsers: { items: [user], totalCount: 1 } }]);
          }
        });
      }
    });
  }, [ids]);

  const sortedCards =
    ids
      ?.map((id) => cards.find(({ id: pickId }) => id === pickId))
      .reduce((acc: CardSchema[], curr) => (curr ? acc.concat(curr) : acc), []) ?? [];

  return { cards: sortedCards, loading };
};
