import { useMemo } from 'react';
import { Maybe, ReactionGroupSchema, ReactionSymbolSchema } from 'constants/graphqlTypes';
import { EReactionGroupTitles } from './helpers';

export const useEmojis = (
  allEmojis: Maybe<ReactionGroupSchema[]>,
  text: string
): { sections: string[]; searchedEmojis: ReactionSymbolSchema[] } => {
  const sections = allEmojis?.length
    ? [EReactionGroupTitles.Recent, ...allEmojis?.map((section) => section.title)]
    : [];

  const allsymbols = useMemo(
    () =>
      allEmojis
        ? allEmojis?.reduce((symbols: ReactionSymbolSchema[], section) => {
            if (section && section.symbols) {
              return [...symbols, ...section.symbols.items];
            }
            return symbols;
          }, [])
        : [],
    [allEmojis]
  );

  const searchedEmojis = useMemo(
    () => allsymbols.filter((symbol) => symbol.keywords?.find((keyword) => keyword.includes(text.toLowerCase()))),
    [allsymbols, text]
  );
  return { sections, searchedEmojis };
};
