import { styled } from 'linaria/react';
import { FONTS } from 'styles/constants';

export const StyledWrap = styled.div<{ isRight: boolean; isFullWidth?: boolean; hasBottomMargin?: boolean }>`
  margin-bottom: ${({ hasBottomMargin }) => (hasBottomMargin ? '8px' : 0)};
  margin-left: ${({ isRight, isFullWidth }) => (isRight && !isFullWidth ? 'auto' : 0)}px;
  margin-top: 14px;
  position: relative;
  width: ${({ isFullWidth }) => (isFullWidth ? 308 : 250)}px;

  a {
    padding: 16px;
    justify-content: end;
  }
`;

export const StyledText = styled.span`
  display: block;
  font: 400 12px/140% ${FONTS.secondary};
`;

export const StyledTitle = styled.span`
  display: block;
  font: 400 14px/140% ${FONTS.primary};
`;
