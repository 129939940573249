import React, { FC, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { CardTypeOptions } from 'constants/graphqlTypes';
import { useContentNotFound } from 'helpers/routingHelper';
import { useNavigationRef } from 'components/Navigation/helpers/hooks';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import useThoughtsModal from 'helpers/useThoughtsModal';
import { COLORS } from 'styles/constants';

import CardDetails from 'components/CardDetails';

const CardDetail: FC = () => {
  const { pickId } = useParams<{ pickId?: string }>();
  const handlerContentNotFound = useContentNotFound();
  const location = useLocation<{ variable: { shouldOpenThoughts: boolean } }>();
  const handleOpen = useThoughtsModal({ id: pickId || '', openFromCardDetailPage: true });
  useEffect(() => {
    if (location?.state?.variable?.shouldOpenThoughts) {
      handleOpen();
    }
  }, [location?.state?.variable]);

  const { card, loading } = useGetDetailCard(pickId);

  useNavigationRef(COLORS.white[100]);

  if (card && card.type !== CardTypeOptions.Card && !loading) {
    handlerContentNotFound();
  }

  return card ? <CardDetails item={card} /> : null;
};

export default CardDetail;
